import React from 'react';
import { bool, func, object } from 'prop-types';
import { Modal } from 'antd';
import { Button } from '@seekube-tech/ui-kit';
import Icon from '@/components/Icon';
import Separator from '@/components/Separator';
import messages from '../../../../../Event/scenes/Recruiter/scenes/Preparation/scenes/Offer/messages';


const CancelModal = ({ visible, intl, offer, onCancel, onOk }) => (
  visible ? <Modal
    visible={visible}
    footer={false}
    maskClosable
    width={470}
    className="customConfirm"
    onCancel={onCancel}
    cancelText={intl.formatMessage({ id: 'cancel' })}
  >
    <a role="button" tabIndex={0} className="modal-close" onClick={onCancel}>
      <Icon name="close" className="modal-close-icon" />
    </a>

    {offer && <h4 className="ant-confirm-title">{['draft', 'expired'].includes(offer.status) ? intl.formatMessage(messages.offerFormWarningClose) : intl.formatMessage(messages.offerPublishedFormUpdate)}</h4>}

    <Separator height={30} />

    <div className="confirm-actions">
      <Button onClick={onOk}>{intl.formatMessage(messages.exit)}</Button>
    </div>
  </Modal> : null
);

CancelModal.propTypes = {
  visible: bool,
  intl: object,
  offer: object,
  onCancel: func,
  onOk: func,
};

export { CancelModal };
