import { useQuery } from 'react-query';

import request from '@/utils/request';
import { objectToParams } from '@/utils/url';

export function useGetUsersSelect({ eventId, search = {}, page, keywords }) {
  const searchParams = objectToParams({ ...search, page: 1, keywords });
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/exponents/preparation/users-select?${searchParams}`;

  const queryFn = () => request(requestUrl, { method: 'GET' });

  const query = useQuery({
    queryKey: ['eventId', eventId, 'exponents', 'preparation', 'usersSelect', searchParams],
    queryFn,
    enabled: !!eventId,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  return query;
}
