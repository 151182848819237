import React from 'react';
import { injectIntl, FormattedHTMLMessage } from 'react-intl';
import { object } from 'prop-types';
import moment from 'moment';
import { ANALYTICS_RECRUITER } from '@/utils/constants';
import { H1 } from '@/components/Title';
import P from '@/components/P';
import AvatarWithDate from '@/components/AvatarWithDate';
import messages from '@/scenes/Event/scenes/Recruiter/scenes/Jobdating/scenes/Appointment/messages';
import AddToCalendar from '@/components/AddToCalendar';
import addToCalendarTracking from '@/utils/tracking';
import styles from './styles.less';

const appointmentMedium = (medium, intl) => {
  switch (medium) {
    case 'phone':
      return intl.formatMessage(messages.phone);
    case 'visio':
      return intl.formatMessage(messages.visio);
    case 'physical':
      return intl.formatMessage(messages.physical);
    default:
      return intl.formatMessage(messages.none);
  }
};

const AppointmentConfirm = ({ appointment, event, intl }) => {
  const timeslot = appointment._timeslot;
  const startDate = moment(timeslot.beginAt).utc();
  const endDate = moment(timeslot.beginAt).add(timeslot.duration, 'minutes').utc();

  const eventParams = {
    title: `${intl.formatMessage(messages.appointmentWith)} ${appointment._user.fullName}`,
    description: `${intl.formatMessage(messages.appointmentWith)} ${appointment._user.fullName} ${intl.formatMessage(messages.on)} #${event.name}.\n${intl.formatMessage(messages.resumeLink)}${appointment._user.resumeUrl}`,
    location: appointment.settings.medium === 'physical' ? appointment.settings.location : intl.formatMessage(messages[appointment.settings.medium]),
    startDate: startDate.format('YYYY-MM-DD'),
    endDate: endDate.format('YYYY-MM-DD'),
    startTime: startDate.format('LT'),
    endTime: endDate.format('LT'),
  };

  return (
    <>
      <div className={styles.appointmentConfirmation}>
        <H1 bold>
          <span role="img" aria-label="congratz">🎉</span>
          {intl.formatMessage(messages.appointmentConfirmed)}
        </H1>

        <AvatarWithDate
          src={appointment._organization.profile.pictureUrl}
          alt={appointment._organization.name}
          date={timeslot.beginAt}
        />

        <P>
          <FormattedHTMLMessage
            {...messages.appointmentReminder}
            values={{ candidate: appointment._user.firstName, medium: appointmentMedium(appointment.settings.medium, intl), time: moment(timeslot.beginAt).format('HH:mm') }}
          />
        </P>
          <AddToCalendar
            params={eventParams}
            variant="fill"
            className="flex justify-center"
            onSelectCalendarType={(calendar) => addToCalendarTracking(event, calendar, ANALYTICS_RECRUITER.EXPORTED_APPOINTMENT_CALENDAR)}
          />
      </div>
    </>
  );
};

AppointmentConfirm.propTypes = {
  appointment: object,
  event: object,
  intl: object,
};

export default injectIntl(AppointmentConfirm);
