import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { any, func, string } from 'prop-types';
import { compact, keys } from 'lodash';
import { Select } from '@seekube-tech/ui';

import styles from './styles.less';

const Medium = ({
  value,
  eventFormat,
  onMediumChange,
  style,
  participationMode,
}) => { // eslint-disable-line
  const t = useFormatMessage();

  const MEDIUMS = {
    phone: {
      icon: 'phone',
      value: 'phone',
      label: t({ id: 'appointment.medium.phone' }),
      formats: ['virtual'],
    },
    visio: {
      icon: 'seeklive',
      value: 'visio',
      label: t({ id: 'appointment.medium.visio' }),
      formats: ['virtual'],
    },
    physical: {
      icon: 'location',
      value: 'physical',
      label: t({ id: 'appointment.medium.physical' }),
      formats: ['physical'],
    },
  };

  const handleMediumChange = (medium) => {
    if (onMediumChange) {
      onMediumChange(medium);
    }
  };

  const options = () => (
    compact(keys(MEDIUMS).map((mediumsKey) => {
      const { value, label, formats, icon } = MEDIUMS[mediumsKey]
      return (formats.includes(eventFormat) || (eventFormat === 'hybrid' && formats.includes(participationMode))) ? ({ id: value, label, value, icon }) : null
    }))
  )

  return (
    <div className={styles.mediumContainer} id="medium" style={style}>
      <Select
        fullWidth="100%"
        input={{
          onChange: handleMediumChange,
          value,
          defaultValue: value,
        }}
        options={options()}
        label={t({ id: 'event.recruiter.preparation.timeslots.slot.medium' })}
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.value}
        renderValue={() => <>{/* <Icon name={option.icon}/> */} {options().find(option => option.value === value)?.label}</>}
        meta={
          { error: '' }
        }
      />
    </div>
  );
};

Medium.propTypes = {
  value: string,
  eventFormat: string,
  onMediumChange: func,
  style: any,
  participationMode: string,
};

export default Medium;
