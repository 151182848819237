import React, { useState } from 'react';
import { object } from 'prop-types';
import { Button, H4, H5 } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import ModalV2 from '@/components/ModalV2';
import Separator from '@/components/Separator';
import { organizationActions } from '@/store/organization';
import { Bloc, BlocHeader } from '@/components/Bloc';
import thisStyles from '@/scenes/Admin/scenes/Organizations/scenes/Organization/scenes/Details/styles.less';

const RemoveOrganization = ({
  organization,
}) => {
  const t = useFormatMessage();
  const [confirmRemoveOrganizationModal, setConfirmRemoveOrganizationModal] = useState(false);
  const dispatch = useDispatch();

  const handleDeleteOrganization = () => {
    setConfirmRemoveOrganizationModal(false);

    dispatch(organizationActions.deleteOrganization({
      organizationId: organization._id,
      callback: () => {
        dispatch(push('/admin/organizations'));
      },
    }));
  };

  return (
    <>
      <Bloc>
        <BlocHeader className={thisStyles.heading}>
          <H4>{t({ id: 'admin.organizations.remove.title' })}</H4>
        </BlocHeader>
        <Button variant="fill" color="error" onClick={() => { setConfirmRemoveOrganizationModal(true); }}>
          <FormattedMessage id="admin.organizations.modal.delete.organization" />
        </Button>
      </Bloc>
      <ModalV2
        template="default"
        visible={confirmRemoveOrganizationModal}
        onCancel={() => setConfirmRemoveOrganizationModal(false)}
        onClose={() => setConfirmRemoveOrganizationModal(false)}
      >
        <H5>
          <span role="img" aria-label="warning">⚠️ </span><FormattedHTMLMessage id="admin.organizations.detail.confirm.delete" />
        </H5>

        <Separator height={20} />

        <div className="confirm-actions">
          <Button
            type="primary"
            variant="tonal"
            onClick={() => setConfirmRemoveOrganizationModal(false)}
          >
            {t({ id: 'admin.organizations.cancel' })}
          </Button>
          <Button
            type="primary"
            variant="fill"
            onClick={() => handleDeleteOrganization()}
          >
            {t({ id: 'admin.organizations.confirm' })}
          </Button>
        </div>
      </ModalV2>
    </>
  )
}

RemoveOrganization.propTypes = {
  organization: object,
}

export { RemoveOrganization }