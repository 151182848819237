import { defineMessages } from 'react-intl';

export default defineMessages({
  imageAddNotificationError: {
    id: 'imageAdd.notification.error',
  },
  imageAddModalTitle: {
    id: 'imageAdd.modal.title',
  },
  imageAddInputPlaceholder: {
    id: 'imageAdd.input.placeholder',
  },
  imageAddDrag: {
    id: 'imageAdd.drag',
  },
  locationAddNotificationError: {
    id: 'locationAdd.notification.error',
  },
  locationAddModalTitle: {
    id: 'locationAdd.modal.title',
  },
  videoAddNotificationError: {
    id: 'videoAdd.notification.error',
  },
  videoAddModalTitle: {
    id: 'videoAdd.modal.title',
  },
  videoAddInputPlaceholder: {
    id: 'videoAdd.input.placeholder',
  },
});
