import { useQuery } from 'react-query';
import request from '@/utils/request';

export function useGetLandingTabsCounters({ eventId, ...props }) {
  const requestUrl = `${process.env.FRONT_API_URL}/landing/tabs-counters?eventId=${eventId}`;
  const queryFn = () => request(requestUrl, { method: 'GET' });

  const query = useQuery({
    queryKey: ['landing-tabs-counters', eventId],
    queryFn,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    ...props
  });
  return query;
}
