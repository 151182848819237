import React from 'react';
import { string, object, number, oneOf, oneOfType, func, any, bool } from 'prop-types';
import classnames from 'classnames';
import { isEmpty } from 'lodash';

// Components
import ReactAvatar from 'react-avatar';
import Icon from '@/components/Icon';
import ImgCdn from '@/components/ImgCdn';

// Styles & Translations
import styles from './styles.less';

const backgroundColor = 'transparent';
const defaultColors = [
  '#63D0A8',
  '#6C30F4',
  '#C5357D',
  '#E8AF40',
  '#C7493B',
  '#252D47',
];


const Avatar = ({ className, style, src, size, type, status, onClick, defaultColor, imageFit, user, withMedal, counter }) => {
  const imgClass = type === 'circle' ? styles.circle : styles.rounded;
  let iconName = '';
  let statusClass = 'statusPending';

  switch (status) {
    case 'accepted':
      iconName = 'accept';
      break;

    case 'refused':
      iconName = 'remove';
      break;

    case 'canceled':
      iconName = 'close';
      break;
    case 'default':
      iconName = 'clock';
      break;

    default:
      iconName = 'clock';
  }

  if (status) {
    statusClass = `status${status.charAt(0).toUpperCase() + status.slice(1)}`;
  }

  const randomInt = !isEmpty(user) && user._id ? user._id.charCodeAt(23) % (defaultColors.length - 1) : 0;
  const color = isEmpty(user) ? '#FFF' : defaultColors[randomInt];

  const renderImg = (user === 'default' || (typeof src !== 'undefined' && src !== '' && src !== null)) ? (
    <>
      <ImgCdn className={imgClass} src={user === 'default' ? 'https://cdn-app-assets.seekube.com/user/blank-user.png' : src} alt="Profile" size={size < 130 ? 130 : size} style={{ backgroundColor, objectFit: imageFit }} />
    </>
  ) : (
    <div className={status ? styles[status] : styles.emptyStatus}>
      {
        isEmpty(user) ?
          <Icon name="picture" style={{ width: `${size}px`, height: `${size}px` }} /> :
          <ReactAvatar
            name={`${user.firstName} ${user.lastName}`}
            round={`${size}px` || false}
            size={size}
            textSizeRatio={!isEmpty(user) && isEmpty(user.lastName) ? 3 : 2}
            textMarginRatio={0.2}
            maxInitials={2}
            fgColor={isEmpty(user) ? '#CCC' : '#FFF'}
            value={isEmpty(user) ? (<Icon name="picture" style={{ width: `${size}px`, height: `${size}px` }} />) : null}
            // src={isEmpty(user) ? 'https://cdn-app-assets.seekube.com/user/picture.png' : null}
            color={defaultColor === null ? color : defaultColor}
            style={isEmpty(user) ? { background: 'transparent' } : null}
          />
      }

    </div>
  );

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={onClick}
      className={classnames(styles.avatarContainer, className, styles[status])}
      style={{ width: size, minWidth: size, height: size, ...style }}
    >
      {renderImg}
      {status ? (<div className={classnames(styles.iconStatus, styles[statusClass])}><Icon name={iconName} /></div>) : ''}
      {withMedal ? (<div className={styles.medal}><Icon name="medal" /></div>) : ''}
      {counter ? (<div className={styles.counter}><span>+{counter > 999 ? 999 : counter}</span></div>) : ''}
    </div>
  );
};

Avatar.propTypes = {
  src: string,
  className: string,
  type: oneOf(['circle', 'rounded']),
  style: object,
  withMedal: bool,
  defaultColor: string,
  user: oneOfType([object, string]),
  size: oneOfType([number, string]),
  onClick: func,
  counter: number,
  status: any,
  imageFit: oneOf(['contain', 'cover']),
};

Avatar.defaultProps = {
  imageFit: 'contain',
  type: 'circle',
  size: 110,
  defaultColor: null,
  counter: null,
};

export default React.memo(Avatar);
