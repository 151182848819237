import React from 'react';
import { func, object, bool } from 'prop-types';
import { Switch, Route, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import moment from 'moment';
import 'moment-timezone';
import { createStructuredSelector } from 'reselect';
import { replace } from 'connected-react-router';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { isEmpty, filter, find, omit, get } from 'lodash';
import { Button } from '@seekube-tech/ui-kit';
import { ConfigProvider } from 'antd';
import frFR from 'antd/lib/locale-provider/fr_FR';
import enGB from 'antd/lib/locale-provider/en_GB';
import { LinkedInPopUp } from 'react-linkedin-login-oauth2';
import ReactPixel from 'react-facebook-pixel';
import LinkedInTag from 'react-linkedin-insight';
import { CookieConsent } from '@seekube-tech/cookie-consent';
import { injectIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import { Banner } from '@seekube-tech/ui';
import timezones from '@/scenes/Settings/components/General/components/Timezone/timezone.json';
import AdminPage from '@/scenes/Admin';
import DemoPage from '@/scenes/Demo';
import ParticipantPage from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Participant';
import EventLandingPage from '@/scenes/Event';
import AuthPage from '@/scenes/Auth';
import SyncPage from '@/scenes/Sync';
import HomePage from '@/scenes/Home';
import NotFound from '@/scenes/NotFound/Loadable';
import NoShowRedirect from '@/scenes/NoShowRedirect';
import ErrorScene from '@/scenes/Error';
import { authSelectors, authActions } from '@/store/auth';
import { userActions, userSelectors } from '@/store/user';
import { actionActions } from '@/store/action';
import { eventActions } from '@/store/event';
import { participantSelectors } from '@/store/participant';
import { sectorActions } from '@/store/sector';
import { roleActions } from '@/store/role';
import { appSelectors, appActions } from '@/store/app';
import { getCookieConsent } from '@/utils/cookie';
import EventsPage from '@/scenes/Events';
import ResetPasswordPage from '@/scenes/ResetPassword';
import ThankYouPage from '@/scenes/ThankYou';
import SettingsScene from '@/scenes/Settings';
import CalendarPage from '@/scenes/Calendar';
import ClientPage from '@/scenes/Client';
import SeekalendarsPage from '@/scenes/Seekalendars';
import SeekalendarPage from '@/scenes/Seekalendars/scenes/Seekalendar';
import { objectToParams, queryStringToObject } from '@/utils/url';
import { listenPages, trackRecruiterClickedQuitCreatingSlots, trackRecruiterQuitCreatingSlots } from '@/utils/analytics';
import { toJS } from '@/utils';
import ModalBig from '@/components/ModalBig';
import ModalV2 from '@/components/ModalV2';
import CustomEmpty from '@/components/CustomEmpty';
import { LOCALES } from '@/utils/constants';
import { trackError } from '@/utils/analytics/helpers';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from '@/utils/localStorage';
import FailedToFetchModal from './components/FailedToFetchModal';
import UploadStatus from './containers/UploadStatus';
import pkg from '../../../package.json';
import '@seekube-tech/cookie-consent/dist/index.css';
import OAuthScene from "@/scenes/Oauth";
import styles from './styles.less';
import RequestPlansInfo from '../../scenes/Plan';
import Footer from '../../components/Footer';
import Modal from '../../components/Modal';
import Sync from './components/Sync';
import SmartApply from '@/scenes/SmartApply';
import MobileDetect from 'mobile-detect';
import AcceptOwnerAccess from '@/scenes/Event/scenes/Recruiter/scenes/Preparation/scenes/AcceptOwnerAccess';
import { trackGtmEvent } from "@/utils/pixels/helloWork";
import ModalCgu from '@/containers/App/components/ModalCgu';
import RedirectEvent from "@/redirectEvent";
import { criterionActions } from '@/store/criterion';
import { redirectUnAuthUser } from '@/utils/user';
import { NavConnectedAs } from '@/containers/App/containers/NavConnectedAs';

const cookieConsent = getCookieConsent();
const md = new MobileDetect();

class App extends React.Component {
  static propTypes = {
    replace: func,
    authUserNotConnectedAs: object,
    authUserConnectedAs: object,
    authUser: object,
    history: object,
    match: object,
    location: object,
    getUser: func,
    getSectors: func,
    getRoles: func,
    cleanFetchError: func,
    patchMe: func,
    currentApp: object,
    getApp: func,
    showSettings: bool,
    fetchError: object,
    closeSettings: func,
    toggleSettings: func,
    getCountAuthUserEvents: func,
    switchAuth: func,
    setAgendaView: func,
    isUserFetching: bool,
    isParticipantFetching: bool,
    postAction: func,
    getDefaultCriteria: func,
    intl: object,
  };

  state = {
    isQuitWithoutSavingModalVisible: false,
    shouldShowVersionBanner: false,
    userHidVersionBanner: false,
    showSyncModal: true
  }

  constructor(props) {
    super(props);

    listenPages(props.history, props.authUser);
  }

  componentDidMount() {
    const { props: { authUser, getUser, patchMe, getApp, toggleSettings, getCountAuthUserEvents, closeSettings, getSectors, switchAuth, postAction, currentApp, getDefaultCriteria } } = this;

    const urlQuery = queryStringToObject(window.location.search)

    location.query = urlQuery;

    if (!isEmpty(authUser)) {
      if (authUser.timezone) { moment.tz.setDefault(authUser.timezone.utc[0]); } else { moment.tz.setDefault(); }

      getUser();
      getCountAuthUserEvents();

      if (isEmpty(authUser.timezone)) {
        const timezone = find(timezones, (timezone) => filter(timezone.utc, (utc) => utc === moment.tz.guess()).length);

        patchMe({
          userParams: { timezone },
          notificationParams: {
            success: {
              message: this.props.intl.formatMessage({ id: 'notifications.update.success' }),
              kind: 'info',
              style: {
                bottom: '5%',
                top: 'inherit',
              },
            },
          }
        });
      } else {
        moment.tz.setDefault(authUser.timezone.utc[0]);
      }
      const locale = authUser.locale || LOCALES.fr;

      moment.locale(locale);

      setLocalStorage('locale', locale);
    }

    window.moment = moment;

    getApp();
    getSectors();
    getDefaultCriteria();

    this.props.getRoles();

    const intercomSettings = {
      app_id: process.env.FRONT_INTERCOM_APP_ID,
      hide_default_launcher: false,
    };

    // closeSettings();
    if (process.env.NODE_ENV === 'production' && window.Intercom && !md.mobile()) {
      window.Intercom('boot', intercomSettings);
    }

    closeSettings();

    if (!isEmpty(location.query) && !isEmpty(location.query.editTemplate) && location.query.editTemplate !== 'null') {
      toggleSettings('settings');
    }

    if (window.location.hostname === 'app.seekube.com') {
      ReactPixel.init('2488336321189882', {}, {
        autoConfig: true, // set pixel's autoConfig
        debug: false, // enable logs
      });

      if (cookieConsent && cookieConsent.marketing) {
        ReactPixel.grantConsent();
        ReactPixel.pageView();
        LinkedInTag.init('1372258');
      } else {
        ReactPixel.revokeConsent();
        LinkedInTag.init('1372258', 'dc', false);
      }
    }

    if (cookieConsent && cookieConsent.marketing) {
      const excludePath = ['/seekalendar'];

      const pathSize = location.pathname.split('/').length;

      // Prevent to track /:eventSlug && /seekalendar/:eventSlug
      if (pathSize > 2 || excludePath.includes(location.pathname)) {
        if (location.pathname.indexOf('/seekalendar/') === -1) {
          trackGtmEvent('page.display', {
            'user_id_back': authUser?._id || '',
            'path': location.pathname,
          });
        }
      }
    }

    if (authUser) {
      postAction({
        actionParams: {
          name: 'USER_PAGE_VIEW',
          _user: authUser._id,
          _organization: authUser?._currentOrganization?._id,
          data: {
            firstLoad: true,
            isActive: authUser.isActive,
            url: location.pathname,
          }
        },
      });

      if (!isEmpty(urlQuery.track_action)) {
        postAction({
          actionParams: {
            name: urlQuery.track_action,
            _user: authUser._id,
            _organization: authUser?._currentOrganization?._id,
            data: {
              url: location.pathname,
              source: urlQuery.track_source,
            }
          },
        });
      }
    }

    window.moment = moment;
    if (authUser && authUser.isAdmin && queryStringToObject(window.location.search).switch) {
      switchAuth({
        email: queryStringToObject(window.location.search).switch,
        notificationParams: {
          success: {
            message: this.props.intl.formatMessage({ id: 'toasters.switch.success' }),
            kind: 'success',
            style: {
              top: '7%',
            },
          },
          error: {
            message: this.props.intl.formatMessage({ id: 'toasters.switch.error' })
          },
        },
      });
    }

    // Detect new version app
    if (pkg?.version && currentApp?.version) {
      this.updateVersionBanner();
    }
  }

  shouldComponentUpdate({ location, currentApp }) {
    const { props } = this;

    return !(location.pathname === props.location.pathname && currentApp && props.currentApp && currentApp._id === props.currentApp._id && currentApp.showSettings === props.showSettings);
  }

  componentDidUpdate(prevProps) {
    const { props: { authUser, location, patchMe, currentApp, isUserFetching, isParticipantFetching, showSettings, authUserConnectedAs, toggleSettings, postAction } } = this;

    if (!showSettings && location.search.indexOf('settings') > -1) {
      toggleSettings('settings');
    }

    if (location.pathname !== prevProps.location.pathname && location.pathname !== '/auth/logout') {
      // window.location.reload(true); TODO: fix reload if version front/back mismatch
      if (pkg?.version && currentApp?.version && location.pathname.split('/')[1] !== prevProps.location.pathname.split('/')[1]) {
        this.fetchAppVersion();
        this.updateVersionBanner();
      }

      const excludePath = ['/seekalendar'];
      const pathSize = location.pathname.split('/').length;

      if (pathSize > 2 || excludePath.includes(location.pathname)) {
        if (location.pathname.indexOf('/seekalendar/') === -1) {
          trackGtmEvent('page.display', {
            'user_id_back': authUser?._id || '',
            'path': location.pathname,
          });
        }
      }

      if (authUser && !isUserFetching && !isParticipantFetching) {
        // Do not update lastView on switchUser
        let userParams = isEmpty(authUserConnectedAs) ? { lastView: new Date() } : {};

        // TODO: Si switch user on update pas la timezone
        if (isEmpty(authUser.timezone)) {
          const timezone = find(timezones, (timezone) => filter(timezone.utc, (utc) => utc === moment.tz.guess()).length);
          userParams = { ...userParams, timezone };
          moment.tz.setDefault(timezone.utc[0]);
        } else {
          moment.tz.setDefault(authUser.timezone.utc[0]);
        }

        moment.locale(authUser.locale || 'fr');

        window.moment = moment;

        if (authUser) {
          patchMe({
            userId: authUser._id,
            userParams,
            notificationParams: null,
          });

          postAction({
            actionParams: {
              name: 'USER_PAGE_VIEW',
              _user: authUser._id,
              _organization: authUser?._currentOrganization?._id,
              data: {
                firstLoad: false,
                isActive: authUser.isActive,
                url: location.pathname,
              }
            },
          });
        }
      }
    }
  }

  componentDidCatch(err) {
    const uuid = uuidv4()
    trackError(err, { 500: true, uuid });
    // this.props.replace(`/500?id=${uuid}`); TODO: reactivate after check
  }

  /**
   * ComponentWillUnmount
   *
   * @description
   * Reset allIds participants in redux store
   */
  componentWillUnmount() {
    if (window.Intercom) {
      window.Intercom('shutdown');
    }
  }

  fetchAppVersion() {
    const { props: { getApp } } = this;
    getApp();
    this.setState({ userHidVersionBanner: false });
  }

  updateVersionBanner() {
    const { props: { currentApp } } = this;

    const packageVersionArray = pkg.version.split('.');
    const appVersionArray = currentApp.version.split('.');
    // Remove patch version from comparison. We will only notify user when a minor version is available
    packageVersionArray.pop();
    appVersionArray.pop();

    const appVersion = parseInt(appVersionArray.join(''), 10);
    const packageVersion = parseInt(packageVersionArray.join(''), 10);


    const shouldShowVersionBanner = appVersion > packageVersion;

    // eslint-disable-next-line react/no-did-update-set-state
    this.setState({ shouldShowVersionBanner });
  }

  handleCloseFetchModal = (redirect = false) => {
    const { props: { cleanFetchError, authUser } } = this;

    cleanFetchError();
    if (redirect) {
      redirectUnAuthUser(authUser);
    } else {
      window.location.reload();
    }
  }

  handleCloseCalendar = (byPassPreventModal = true) => {
    const { setAgendaView, authUser } = this.props;
    const isEditingCalendar = JSON.parse(getLocalStorage('is_editing_calendar'));

    if (isEditingCalendar) {
      if (byPassPreventModal) {
        this.setState({ isQuitWithoutSavingModalVisible: false });
        removeLocalStorage('is_editing_calendar');
      } else {
        trackRecruiterClickedQuitCreatingSlots({ authUser });
        return this.setState({ isQuitWithoutSavingModalVisible: true });
      }
    }

    this.cleanUrlParameter('calendar');

    return setTimeout(() => setAgendaView({ view: 'calendar' }), 500);
  }

  cleanUrlParameter = (param) => {
    const { history: { location }, replace } = this.props;

    const params = queryStringToObject(location.search);

    replace(`${location.pathname}${Object.keys(params).length > 1 ? '?' : ''}${objectToParams(omit(params, [param]))}`);
  }

  /**
   * Reload page for get new version
   */
  handleReloadApp = () => {
    window.location.reload(true);
  };

  /**
   * Close banner version notification
   */
  handleCloseBannerVersion = () => {
    this.setState({ shouldShowVersionBanner: false });
  };

  shouldDisplayModalCgu = () => {
    const { props: { authUser, authUserConnectedAs, currentApp } } = this;

    return (
        authUser?.isActive && !isEmpty(currentApp))
      && (!authUser.termsAcceptedAt || moment(authUser.termsAcceptedAt).isBefore(moment(currentApp?.termsUpdatedAt)))
      && isEmpty(authUserConnectedAs)
  }

  render() {
    const {
      props: { intl, fetchError, authUser, authUserConnectedAs, authUserNotConnectedAs, showSettings, closeSettings, history: { location } },
      state: { shouldShowVersionBanner, userHidVersionBanner, showSyncModal },
      handleCloseFetchModal, cleanUrlParameter, handleReloadApp, handleCloseBannerVersion, shouldDisplayModalCgu,
    } = this;

    const user = {
      user_id: authUser ? authUser._id : null,
      email: authUser ? authUser.username : null,
      firstName: authUser ? authUser.firstName : null,
      lastName: authUser ? authUser.lastName : null,
      name: authUser ? authUser.fullName : null,
      role: authUser && authUser._currentOrganization ? 'company' : 'candidate',
      version: pkg.version || null,
      lastLogin: moment().unix(),
    };

    if (authUser && authUser._currentOrganization) {
      user.institution_name = authUser._currentOrganization.name;
      user.organization = authUser._currentOrganization.name;
    }

    const hideIntercom = process.env.NODE_ENV !== 'production' || !isEmpty(authUserConnectedAs) || md.mobile();

    if (authUser && window.datadogRum) {
      window.datadogRum.setUser({
        id: authUser ? authUser._id : null,
        role: authUser && authUser._currentOrganization ? 'company' : 'candidate',
        organization: authUser && authUser._currentOrganization ? authUser._currentOrganization.name : null,
      });
    }

    if (authUser && window.analytics) {
      window.analytics.identify(authUser._id, user);
      if (window.Intercom) {
        window.Intercom('update', user);
      }
    }

    if (hideIntercom && window.Intercom) {
      window.Intercom('shutdown');
    }

    const isOfferPreview = window.location.pathname.indexOf('/owner/offers/') > -1 && window.location.pathname.indexOf('/preview') > -1;

    /**
     * TODO : Remove when sync v2 migration is over
     */
    const timezone = (authUser && authUser.timezone) ? authUser.timezone.utc[0] : null;
    const POPUP_MEP_DATE = moment.tz(new Date('2022-02-15'), timezone || null);
    const hasSyncedWithNylas =
      authUser &&
      !isEmpty(get(authUser, 'syncCalendar.accessToken')) &&
      get(authUser, 'syncCalendar.syncState') === 'running';

    const POPUP_LIMIT_DATE = moment.tz(new Date('2022-06-01'), timezone || null);
    const userHasNotViewWebsiteSinceSyncUpdate = authUser
      && authUser.isActive
      && isEmpty(authUserConnectedAs)
      && !hasSyncedWithNylas
      && moment.tz(authUser.lastView, timezone).isBefore(POPUP_LIMIT_DATE)
      && moment.tz(new Date(), timezone).isAfter(POPUP_MEP_DATE)
      && authUser._organizations.length > 0
      && authUser.syncCalendar?.provider !== 'seekube'
      && window.location.pathname.indexOf('/auth/invitation/') === -1; // Disable sync modal during onboarding step

    /**
     * End of todo
     */
    if (shouldDisplayModalCgu()) return <ModalCgu />

    return (
      <ConfigProvider locale={authUser?.locale === 'en' ? enGB : frFR} renderEmpty={() => <CustomEmpty />}>
        <div className={styles.app}>
          <div className={styles.banner}>
            <Banner
              variant="info"
              text={(
                <span>
                  {intl.formatMessage({ id: 'app.new-version.title' })}
                  <a role="button" tabIndex={0} onClick={handleReloadApp} className={styles.reloadButton}>
                    {intl.formatMessage({ id: 'app.new-version.action' })}
                  </a>
                </span>
              )}
              visible={shouldShowVersionBanner && !userHidVersionBanner}
              onClose={handleCloseBannerVersion}
            />
          </div>

          <ModalBig
            isOpen={showSettings}
            hideSideContent
            full
            onClose={() => { closeSettings(); cleanUrlParameter('settings'); }}
            className={styles.modalSettings}
            zindex={1001}
          >
            <SettingsScene isOpen={showSettings} />
          </ModalBig>


          {userHasNotViewWebsiteSinceSyncUpdate ? (
            <ModalV2
              visible={showSyncModal}
              onClose={() => { this.setState({ showSyncModal: false }) }}
              className={styles.syncModal}
            >
              <Sync />
            </ModalV2>
          ) : null}

          <Helmet>
            <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no" />
          </Helmet>

          <Modal
            visible={this.state.isQuitWithoutSavingModalVisible}
            footer={false}
            maskClosable
            width={470}
            className="customConfirm"
            onCancel={() => { }}
          >
            <h4 style={{ fontWeight: 600 }}>
              {intl.formatMessage({ id: 'event.recruiter.preparation.offer.published.form.update' })}
            </h4>

            <div className="confirm-actions mt-40">
              <Button
                className="mr-6"
                variant="tonal"
                onClick={() => this.setState({ isQuitWithoutSavingModalVisible: false })}
              >
                {intl.formatMessage({ id: 'cancel' })}
              </Button>
              <Button
                onClick={() => {
                  trackRecruiterQuitCreatingSlots({ authUser });
                  this.setState({ isQuitWithoutSavingModalVisible: false });
                  this.handleCloseCalendar();
                }}
              >
                {intl.formatMessage({ id: 'btn.confirm' })}
              </Button>
            </div>
          </Modal>
          {/* Upload Status : Display loading bar when we upload something */}
          <UploadStatus />

          <NavConnectedAs
            authUserConnectedAs={authUserConnectedAs} 
            authUserNotConnectedAs={authUserNotConnectedAs}
          />

          {location.pathname.indexOf('/auth/invitation/') === -1 ? (
            <CookieConsent
              labels={{
                cookieModal: {
                  description: {
                    first: intl.formatMessage({ id: 'cookieModal.description.first' }),
                    second: intl.formatMessage({ id: 'cookieModal.description.second' }),
                  },
                  actions: {
                    refuse: intl.formatMessage({ id: 'cookieModal.actions.refuse' }),
                    customize: intl.formatMessage({ id: 'cookieModal.actions.customize' }),
                    accept: intl.formatMessage({ id: 'cookieModal.actions.accept' }),
                  },
                },
                customizeModal: {
                  title: intl.formatMessage({ id: 'customizeModal.title' }),
                  description: intl.formatMessage({ id: 'customizeModal.description' }),
                  fonctional: {
                    label: intl.formatMessage({ id: 'customizeModal.fonctional.label' }),
                    description: intl.formatMessage({ id: 'customizeModal.fonctional.description' }),
                  },
                  statistics: {
                    label: intl.formatMessage({ id: 'customizeModal.statistics.label' }),
                    description: intl.formatMessage({ id: 'customizeModal.statistics.description' }),
                  },
                  marketing: {
                    label: intl.formatMessage({ id: 'customizeModal.marketing.label' }),
                    description: intl.formatMessage({ id: 'customizeModal.marketing.description' }),
                  },
                  actions: {
                    findMore: intl.formatMessage({ id: 'customizeModal.actions.findMore' }),
                    refuse: intl.formatMessage({ id: 'customizeModal.actions.refuse' }),
                    accept: intl.formatMessage({ id: 'customizeModal.actions.accept' }),
                  },
                },
              }}
            />
          ) : null}

          <FailedToFetchModal visible={fetchError} handleClose={handleCloseFetchModal} />

          <div className={styles.appContainer}>
            <Switch>
              <Route exact path="/appointment-feedback-success" component={NoShowRedirect} />
              <Route path="/smartApply" component={SmartApply} />
              <Route path="/acceptOwnerAccess" component={AcceptOwnerAccess} />
              <Route path="/500" component={ErrorScene} />
              <Route path="/404" component={NotFound} />
              <Route path="/admin" component={AdminPage} />
              <Route path="/demo" component={DemoPage} />
              <Route path="/calendar" component={CalendarPage} />
              <Route path="/auth" component={AuthPage} />
              <Route path="/oauth" component={OAuthScene} />
              <Route path="/redirectEvent" component={RedirectEvent} />
              <Route path="/sync" component={SyncPage} />
              <Route path="/resetpassword" component={ResetPasswordPage} />
              <Route path="/events" component={EventsPage} />
              <Route path="/thankyou" component={ThankYouPage} />
              <Route path="/client" component={ClientPage} />
              <Route path="/seekalendar/:eventSlug" component={SeekalendarPage} />
              <Route
                path="/seekalendar"
                render={(routeProps) => {
                  const paramObject = queryStringToObject(routeProps.history.location.search);
                  if (isEmpty(paramObject)) {
                    routeProps.history.push('seekalendar?page=1&limit=10&offset=0');
                    return null;
                  }
                  return <SeekalendarsPage {...routeProps} {...paramObject} />;
                }}
              />
              <Route
                path="/plan/:plan/info"
                render={(routeProps) =>
                  <RequestPlansInfo {...routeProps} {...queryStringToObject(routeProps.history.location.search)} />
                }
              />
              <Route path="/participant/:participantId" component={ParticipantPage} />
              <Route exact path="/linkedin" component={LinkedInPopUp} />
              <Route path="/:eventSlug" component={EventLandingPage} />
              <Route exact path="/" component={HomePage} />
            </Switch>
          </div>
          <Footer />
        </div>
      </ConfigProvider>);
  }
}

const mapDispatchToProps = {
  patchMe: userActions.patchMe,
  getUser: userActions.getUser,
  getApp: appActions.getApp,
  getSectors: sectorActions.getSectors,
  getRoles: roleActions.getRoles,
  cleanFetchError: appActions.cleanFetchError,
  closeSettings: authActions.closeSettings,
  toggleSettings: authActions.toggleSettings,
  setAgendaView: authActions.setAgendaView,
  switchAuth: authActions.switchAuth,
  postAction: actionActions.postAction,
  getCountAuthUserEvents: eventActions.getCountAuthUserEvents,
  getDefaultCriteria: criterionActions.getDefaultCriteria,
  replace,
};

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser,
  authUserConnectedAs: authSelectors.getAuthUserConnectedAs,
  authUserNotConnectedAs: authSelectors.getAuthUserNotConnectedAs,
  fetchError: appSelectors.getFetchError,
  showSettings: authSelectors.getShowSettings,
  currentApp: appSelectors.getCurrentApp,
  isUserFetching: userSelectors.getUsersIsFetching,
  isParticipantFetching: participantSelectors.getParticipantsFetching,
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

// Global Saga

export default compose(
  withConnect,
  withRouter,
)(toJS(injectIntl(App)));
