import React, { Fragment } from 'react';
import { array } from 'prop-types';
import 'moment-business-days';
import 'moment-countdown';
import classNames from 'classnames';

// Styles & Translations
import styles from './styles.less';

/**
 * Progress
 */

const Progress = ({ items }) => {
  const limit = items.length - 1;

  return (
    <div className={styles.progressContainer}>
      {items.map((item, index) => (
        <Fragment key={`progressItem${index}`}>
          <div key={`progressStep${index}`} className={classNames(styles.progressItem, item.isActive ? styles.activeItem : '')}>
            <span className={styles.bullet} />
            <var className={styles.stepLabel}>{item.label}</var>
          </div>
          {index < limit ? (
            <div key={`progressBar${index}`} className={styles.progressBar} />
          ) : null}
        </Fragment>
      ))}
    </div>
  );
};

Progress.propTypes = {
  items: array,
};

export default Progress;
