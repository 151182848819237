import { AvatarBusiness, Body2, H5 } from '@seekube-tech/ui-kit';
import React from 'react';
import { string, array } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { Bloc } from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/components/Bloc';
import Wrapper from '@/components/Wrapper';
import { useIsMobile } from '@/utils/MediaQueries';
import styles from './styles.less';

const EventSummary = ({
  organizations = [],
  eventName = ''
}) => {
  const t = useFormatMessage();
  const plannersNames = organizations.map(organization => organization.name).join(', ');
  const isWiderThanMobile = !useIsMobile();
  
  return (
    <Wrapper size="large" className={styles.wrapper}>
      <Bloc maxWidth="100%">
        <div className={styles.container}>
          {isWiderThanMobile && 
            <div className={styles.header}>
              {organizations.map(organization => (
                <AvatarBusiness pictureUrl={organization.profile?.pictureUrl || ''} size="medium" />
              ))}
            </div>
          }
          <div className={styles.content}>
            <H5>{eventName}</H5>
            <Body2 className="text-neutral-400">{t({ id: 'organizedBy' }, { name: plannersNames })}</Body2>
          </div>
        </div>
      </Bloc>
    </Wrapper>
  )
}

EventSummary.propTypes = {
  organizations: array,
  eventName: string
}

export { EventSummary };