import React, { useEffect, useState } from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { injectIntl } from 'react-intl';
import { array, func, object } from 'prop-types';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import 'moment-timezone';
import { debounce, isEmpty, times, join } from 'lodash';
import { Row, Col } from 'antd';
import { toJS } from '@/utils';
import { getId, getIds } from '@/utils/global';
import { LOCAL_STORAGE, setLocalStorage } from '@/utils/localStorage';
import { DEBOUNCE, SLOT_TYPE, EVENT_FORMAT } from '@/utils/constants'
import {
  setDuration,
  createAllCalendarEvents,
  getSlotsCleaned,
  createFreeSlots, getExponentUserParticipation,
} from '@/features/agenda/recruiter/helpers';
import {
  trackRecruiterClickedQuitCreatingSlots,
  trackRecruiterQuitCreatingSlots,
} from '@/utils/analytics';
import { actionActions } from "@/store/action";
import BigCalendar from '@/features/agenda/recruiter/components/BigCalendar/BigCalendar';
import { getEventKeyMoments } from '@/helpers/event/getEventKeyMoments';
import { useGetEventsSelect } from '@/features/agenda/recruiter/api/useGetEventsSelect';
import { useGetUsersSelect } from '@/features/agenda/recruiter/api/useGetUsersSelect';
import { useGetExponentUser } from '@/features/agenda/recruiter/api/useGetExponentUser';
import { trackRecruiterDeleteSlots, trackRecruiterClickedCreatedSlots, trackRecruiterCreatedSlots } from '@/features/agenda/recruiter/analytics';
import { CalendarFooter } from '@/features/agenda/recruiter/components/CalendarFooter/CalendarFooter';
import { CalendarHeader } from '@/features/agenda/recruiter/components/CalendarHeader/CalendarHeader';
import { AlertTimestamp } from '@/features/agenda/recruiter/ui/Alerts/AlertTimestamp';
import { CalendarSettings } from '@/features/agenda/recruiter/components/CalendarSettings/CalendarSettings';
import Wrapper from '@/components/Wrapper';
import { useGetTimeSlots } from '@/features/agenda/recruiter/api/useGetTimeslots';
import { useCreateTimeslots } from '@/features/agenda/recruiter/api/useCreateTimeslots';
import { useDeleteTimeslots } from '@/features/agenda/recruiter/api/useDeleteTimeslots';
import { getIsSlotRemovable } from '@/features/agenda/recruiter/helpers/getIsSlotRemovable';
import styles from '@/features/agenda/recruiter/components/styles.less';
import { ModalConfirmation } from '@/features/agenda/recruiter/ui/Modals/ModalConfirmation';
import {
  BlankUserDoesNotParticipateInEvent
} from '@/features/agenda/recruiter/ui/Blankstates/BlankUserDoesNotParticipateInEvent';
import { getMessageSlotProhibited } from '@/features/agenda/recruiter/helpers/getMessageSlotProhibited';
import { sendNotification } from '@/store/notification/actions';
import { Prompt } from 'react-router';

const DEFAULT_VALUE_MODAL_CONTEXT = { isVisible: false, context: 'cancelEditing', onSubmit: () => { } };

const DEFAULT_SETTINGS = {
  participationMode: 'physical',
  duration: 15,
  medium: 'visio',
  type: SLOT_TYPE.INTERVIEW,
  location: null,
  isMultiEvents: false,
  informalDescription: null,
  isParticipationHybrid: false,
  isVisible: false
}


const debounceFunc = debounce((fct) => fct(), DEBOUNCE, { leading: true });

const getIsOfferModuleEnable = (event) => event?.modules?.offer?.enable || false;

const getIsInformal1to1ModuleEnable = (event) => event?.modules?.informal1to1?.enable || false;

const getEventDefaultSlotType = (event) => {
  const isOfferModuleEnable = getIsOfferModuleEnable(event);
  const isInformal1to1ModuleEnable = getIsInformal1to1ModuleEnable(event);
  // If only informal1to1 module is enabled, so default slot type is informal1to1
  return !isOfferModuleEnable && isInformal1to1ModuleEnable ? SLOT_TYPE.INFORMAL1TO1 : SLOT_TYPE.INTERVIEW;
}

const Calendar = ({
  authUser,
  currentEvent: currentEventProp,
  events,
  intl,
  postAction,
  downloadAllCv,
}) => {
  const t = useFormatMessage();
  const dispatch = useDispatch();
  const [isInit, setIsInit] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(currentEventProp);
  const [selectedEventId, setSelectedEventId] = useState(currentEvent?._id);
  const defaultSlotType = getEventDefaultSlotType(currentEvent);
  const [currentWeek, setCurrentWeek] = useState(0);
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(getId(authUser));
  const [settings, setSettings] = useState({ ...DEFAULT_SETTINGS, type: defaultSlotType });
  const [tmpSlots, setTmpSlots] = useState([]);
  const [modalContext, setModalContext] = useState(DEFAULT_VALUE_MODAL_CONTEXT);
  const [eventKeyMoments, setEventKeyMoments] = useState({});
  const [isDeleteModeActive, setIsDeleteModeActive] = useState(false);
  const [slotSelectedForDelete, setSlotSelectedForDelete] = useState([]);
  const [isBlocking, setIsBlocking] = useState(false);
  const eventsSelectQuery = useGetEventsSelect({
    userId: selectedUserId,
    eventId: selectedEventId,
    onSuccess: ({ selected: selectedEvent }) => {
      const eventDefaultSlotType = getEventDefaultSlotType(selectedEvent);
      const currentDate = moment();
      let week = 0;
      const exponentFormat = settings.participationMode;
      changeEventKeyMoment(exponentFormat)

      const isExponentHybrid = exponentFormat === EVENT_FORMAT.HYBRID;
      const physicalPeriod = selectedEvent.weeks.physical;
      const virtualPeriod = selectedEvent.weeks.virtual;
      let isBetweenTwoKeymoment = false;
      let furthestFormat = virtualPeriod;
      let keyMomentPhysical;
      let keyMomentVirtual;
      if (isExponentHybrid && selectedEvent.format === EVENT_FORMAT.HYBRID) {
        keyMomentPhysical = selectedEvent.keyMoments.jobfair.find(keyMoment => keyMoment.format === 'physical')
        keyMomentVirtual = selectedEvent.keyMoments.jobfair.find(keyMoment => keyMoment.format === 'virtual')
        if (physicalPeriod && virtualPeriod) {
          furthestFormat = moment(physicalPeriod[0].beginAt).isAfter(moment(virtualPeriod[0].beginAt)) ? physicalPeriod : virtualPeriod;
        }
        // (!isBetweenPhysical && !isBetweenVirtual && one format has begun)
        isBetweenTwoKeymoment = !(moment(keyMomentPhysical.beginAt) <= currentDate && moment(keyMomentPhysical.endAt) >= currentDate) &&
          !(moment(keyMomentVirtual.beginAt) <= currentDate && moment(keyMomentVirtual.endAt) >= currentDate) &&
          (currentDate >= moment(keyMomentPhysical.beginAt) || currentDate >= moment(keyMomentVirtual.beginAt))
      }

      if (selectedEvent.weeks[isExponentHybrid ? 'all' : exponentFormat]) {
        week = selectedEvent.weeks[isExponentHybrid ? 'all' : exponentFormat].findIndex((week) => {
          if (isBetweenTwoKeymoment) {
            return furthestFormat.filter(w => w.beginAt === week.beginAt).length > 0;
          }

          return (
            moment(week.beginAt) <= currentDate && moment(week.endAt) >= currentDate
          )

        });
        if (week === -1) {
          week = 0;
        }
      }

      setCurrentWeek(week);

      setSettings({
        ...settings,
        isVisible: false,
        location: (selectedEvent?.format === EVENT_FORMAT.PHYSICAL || selectedEvent?.format === EVENT_FORMAT.HYBRID) && exponentUserQuery?.data?.location,
        medium: (selectedEvent?.format === EVENT_FORMAT.PHYSICAL || selectedEvent?.format === EVENT_FORMAT.HYBRID) ? 'physical' : 'phone',
        isMultiEvents: false,
        type: eventDefaultSlotType,
        isParticipationHybrid: isExponentHybrid,
      });

      setCurrentEvent(selectedEvent)
    }
  });

  const usersSelectQuery = useGetUsersSelect({ eventId: currentEvent?._id });

  const exponentUserQuery = useGetExponentUser({
    eventId: currentEvent?._id,
    userId: selectedUserId,
    onSuccess: ({ data: exponentUser }) => {
      setSettings({
        ...settings,
        participationMode: getExponentUserParticipation(currentEvent, exponentUser),
      });
    }
  });

  const exponentFormat = getExponentUserParticipation(currentEvent, exponentUserQuery?.data);

  const { data: timeslots, ...timeslotsQuery } = useGetTimeSlots({ userId: selectedUserId, event: currentEvent });

  const mutateDeleteTimeslots = useDeleteTimeslots({
    userId: selectedUserId,
    eventId: currentEvent?._id,
    onSuccess: () => {
      setModalContext({ ...modalContext, isVisible: false });
      setIsDeleteModeActive(false);
    }
  });

  const mutateCreateTimeslots = useCreateTimeslots({
    userId: selectedUserId,
    eventId: currentEvent?._id,
    onSuccess: () => {
      setTmpSlots([])
      setSettings({ ...settings, isVisible: false })
      eventsSelectQuery.refetch();
    } });



  const currentExponentUserEvents = events.filter((event) => getIds(eventsSelectQuery?.data?.docs).includes(event._id));

  const selectedUser = usersSelectQuery?.data?.docs?.find((user) => user._id === selectedUserId);

  const calendarSlots = calendarEvents ?
    [...calendarEvents, ...createAllCalendarEvents({ slots: tmpSlots, currentEvent })]
    : createAllCalendarEvents({ slots: tmpSlots, currentEvent });

  useEffect(() => {
    const deviceOffset = new Date().getTimezoneOffset();

    if (deviceOffset > 10) {
      console.warn('Your device timezone is different of your Seekube account timezone. Please sync your device timezone to prevent calendar issues', deviceOffset);
    }
  }, [])


  useEffect(() => {
    if (currentEvent?.format === EVENT_FORMAT.HYBRID) {
      if (exponentFormat) {
        changeEventKeyMoment(exponentFormat);
      }
      else {
        changeEventKeyMoment(EVENT_FORMAT.HYBRID);
      }
    }
    else {
      setEventKeyMoments(currentEvent?.keyDates?.jobfair)
    }
  }, [currentEvent, exponentFormat])


  const changeEventKeyMoment = (format) => {
    if (format === EVENT_FORMAT.PHYSICAL) {
      setEventKeyMoments({
        beginAt: getEventKeyMoments(currentEvent, { type: 'jobfair', format: EVENT_FORMAT.PHYSICAL })?.beginAt,
        endAt: getEventKeyMoments(currentEvent, { type: 'jobfair', format: EVENT_FORMAT.PHYSICAL })?.endAt
      });
    }
    else if (format === EVENT_FORMAT.VIRTUAL) {
      setEventKeyMoments({
        beginAt: getEventKeyMoments(currentEvent, { type: 'jobfair', format: EVENT_FORMAT.VIRTUAL })?.beginAt,
        endAt: getEventKeyMoments(currentEvent, { type: 'jobfair', format: EVENT_FORMAT.VIRTUAL })?.endAt
      });
    }
    else {
      setEventKeyMoments({ beginAt: currentEvent?.readOnly?.jobfairBeginAt, endAt: currentEvent?.readOnly?.jobfairEndAt })
    }
  }

  useEffect(() => {
    setLocalStorage(LOCAL_STORAGE.recruiter.isEditingCalendar, !isEmpty(tmpSlots));
    setIsBlocking(!isEmpty(tmpSlots));
  }, [tmpSlots]);


  useEffect(() => {
    const areQueriesSuccess = timeslotsQuery?.isSuccess && eventsSelectQuery?.isSuccess && exponentUserQuery?.isSuccess;

    if (!areQueriesSuccess) return;

    const allCalendarEvents = createAllCalendarEvents({
      slots: timeslots,
      jobfairs: eventsSelectQuery?.data?.docs,
      currentEvent,
      isSaved: true,
      exponentFormat,
      isEventHybrid: currentEvent.format === EVENT_FORMAT.HYBRID
    });

    setCalendarEvents(allCalendarEvents);
  }, [timeslots, selectedUser, eventsSelectQuery?.data?.docs]);

  useEffect(() => {
    const currentDate = moment();
    let week = 0;
    const isExponentHybrid = exponentFormat === EVENT_FORMAT.HYBRID;
    const physicalPeriod = currentEvent.weeks.physical;
    const virtualPeriod = currentEvent.weeks.virtual;
    let isBetweenTwoKeymoment = false;
    let furthestFormat = virtualPeriod;
    let keyMomentPhysical;
    let keyMomentVirtual;
    if (isExponentHybrid && currentEvent.format === EVENT_FORMAT.HYBRID) {
      keyMomentPhysical = currentEvent.keyMoments.jobfair.find(keyMoment => keyMoment.format === EVENT_FORMAT.PHYSICAL)
      keyMomentVirtual = currentEvent.keyMoments.jobfair.find(keyMoment => keyMoment.format === 'virtual')
      if (physicalPeriod && virtualPeriod) {
        furthestFormat = moment(physicalPeriod[0].beginAt).isAfter(moment(virtualPeriod[0].beginAt)) ? physicalPeriod : virtualPeriod;
      }
      // (!isBetweenPhysical && !isBetweenVirtual && one format has begun)
      isBetweenTwoKeymoment = !(moment(keyMomentPhysical.beginAt) <= currentDate && moment(keyMomentPhysical.endAt) >= currentDate) &&
        !(moment(keyMomentVirtual.beginAt) <= currentDate && moment(keyMomentVirtual.endAt) >= currentDate) &&
        (currentDate >= moment(keyMomentPhysical.beginAt) || currentDate >= moment(keyMomentVirtual.beginAt))
    }

    if (currentEvent.weeks[isExponentHybrid ? 'all' : exponentFormat] && currentWeek === 0) {
      week = currentEvent.weeks[isExponentHybrid ? 'all' : exponentFormat].findIndex((week) => {
        if (isBetweenTwoKeymoment) {
          return furthestFormat.filter(w => w.beginAt === week.beginAt).length > 0;
        }

        return (
          moment(week.beginAt) <= currentDate && moment(week.endAt) >= currentDate
        )

      });
      if (week === -1) {
        week = 0;
      }
      setCurrentWeek(week)
    }
  }, [currentEvent, exponentFormat]);

  useEffect(() => {
    if (exponentUserQuery.data) {
      setSettings({
        ...settings,
        location: exponentUserQuery?.data?.location,
        informalDescription: exponentUserQuery?.data.location?.informalDescription,
      })
    }

  }, [exponentUserQuery?.data?._id]);

  useEffect(() => {
    if (!isEmpty(authUser) && exponentUserQuery?.data && !isInit) {
      handleSelectRecruiter(authUser._id);

      const newState = {};

      if (authUser) {
        const currentDate = moment();
        const isExponentHybrid = exponentFormat === EVENT_FORMAT.HYBRID;
        const physicalPeriod = currentEvent?.weeks.physical;
        const virtualPeriod = currentEvent?.weeks.virtual;
        let isBetweenTwoKeymoment = false;
        let furthestFormat;
        let keyMomentPhysical;
        let keyMomentVirtual;
        if (isExponentHybrid) {
          furthestFormat = moment(physicalPeriod[0].beginAt).isAfter(moment(virtualPeriod[0].beginAt)) ? physicalPeriod : virtualPeriod;
          keyMomentPhysical = currentEvent.keyMoments.jobfair.find(keyMoment => keyMoment.format === EVENT_FORMAT.PHYSICAL)
          keyMomentVirtual = currentEvent.keyMoments.jobfair.find(keyMoment => keyMoment.format === 'virtual')
          // (!isBetweenPhysical && !isBetweenVirtual && one format has begun)
          isBetweenTwoKeymoment = !(moment(keyMomentPhysical.beginAt) <= currentDate && moment(keyMomentPhysical.endAt) >= currentDate) &&
            !(moment(keyMomentVirtual.beginAt) <= currentDate && moment(keyMomentVirtual.endAt) >= currentDate) &&
            (currentDate >= moment(keyMomentPhysical.beginAt) || currentDate >= moment(keyMomentVirtual.beginAt))
        }
        if (currentEvent.weeks[isExponentHybrid ? 'all' : exponentFormat] && currentWeek === null) {
          const week = currentEvent.weeks[isExponentHybrid ? 'all' : exponentFormat].findIndex((week) => {
              if (isBetweenTwoKeymoment) {
                return furthestFormat.filter(w => w.beginAt === week.beginAt).length > 0;
              }

              return (
                moment(week.beginAt) <= currentDate && moment(week.endAt) >= currentDate
              )

            }
          );
          newState.currentWeek = week === -1 ? 0 : week;
        }
      }

      if (!isEmpty(newState)) {
        setCurrentWeek(newState.currentWeek);
        setSettings({
          participationMode: EVENT_FORMAT.PHYSICAL,
          duration: 15,
          medium: currentEvent?.format === EVENT_FORMAT.PHYSICAL ? 'physical' : 'visio',
          location: exponentUserQuery?.data?.location,
          isMultiEvents: false,
          type: defaultSlotType,
        });
      }

      setIsInit(true);
    }
  }, [authUser]);

  const getModalProperty = () =>
    ({
      deleteAllSlots: {
        text: t({ id: 'calendar.slot.confirmDeleteAll' }),
        onSubmit: handleOnDeleteTimeslots,
      },
      deleteSelectedSlots: {
        text: t({ id: 'calendar.slot.confirmDeleteSelected' }),
        onSubmit: handleOnDeleteSelectedTimeslots,
      },
      deleteSlot: {
        text: t({ id: 'calendar.slot.confirmDelete' }),
        onSubmit: modalContext.onSubmit,
      },
      quitWithoutSavingDelete: {
        text: t({ id: 'calendar.slot.quit.delete.mode' }),
        onSubmit: modalContext.onSubmit,
      },
      quitWithoutSaving: {
        text: t({ id: 'event.recruiter.preparation.offer.published.form.update' }),
        onSubmit: () => {
          setSettings({ ...settings, isVisible: false })
          trackRecruiterQuitCreatingSlots({ authUser, event: currentEvent });
          modalContext.onSubmit();
        },
      },
      cancelEditing: {
        text: t({ id: 'modal.cancel.confirmation' }),
        helpText: t({ id: 'modal.cancel.confirmationText' }),
        okCta: t({ id: 'modal.calendar.cancel' }),
        cancelCta: t({ id: 'modal.calendar.cancelConfirm' }),
        onSubmit: () => {
          setTmpSlots([]);
          setModalContext({ ...modalContext, isVisible: false });
          setSettings({ ...settings, isVisible: false })
        },
      },
    }[modalContext.context]);

  const handleOnParticipationModeChange = (participationMode) => {
    if (exponentFormat === EVENT_FORMAT.HYBRID) {
      let newSlots = tmpSlots;
      if (participationMode === EVENT_FORMAT.VIRTUAL) {
        newSlots.forEach((slot) => {
          slot._keyMomentLink = {
            _keyMoment: {
              _id: currentEvent.keyMoments.jobfair.find(e => e.format === EVENT_FORMAT.VIRTUAL)._id
            },
            type: 'jobfair',
          }
        })
      }
      else {
        newSlots.forEach((slot) => {
          slot._keyMomentLink = {
            _keyMoment: {
              _id: currentEvent.keyMoments.jobfair.find(e => e.format === EVENT_FORMAT.PHYSICAL)._id
            },
            type: 'jobfair',
          }
          slot._event = currentEvent._id;
        })
      }
      const medium = participationMode === EVENT_FORMAT.VIRTUAL ? 'visio' : 'physical';

      newSlots = newSlots.map((slot) => ({ ...slot, medium }));
      if (!isEmpty(tmpSlots)) {
        setTmpSlots(newSlots);
      }
      // update medium
      setSettings({ ...settings, participationMode, medium });

      changeEventKeyMoment(participationMode);
    }
  }

  const handleOnDurationChange = (duration) => {
    const cleanTmpSlots = getSlotsCleaned(tmpSlots, timeslots, eventKeyMoments, duration);

    const newSlots = setDuration([...timeslots, ...cleanTmpSlots], timeslots, duration);

    setTmpSlots(newSlots);

    setSettings({ ...settings, duration });
  }

  const handleOnDeleteTimeslots = () => {
    const timeslotsToDelete = getIds(calendarEvents.filter((slot) => getIsSlotRemovable(slot, eventKeyMoments, currentEvent)));

    mutateDeleteTimeslots.mutate({ timeslots: timeslotsToDelete });

    trackRecruiterDeleteSlots({ authUser, event: currentEvent, isAllSlots: true });
  }

  const handleOnDeleteSelectedTimeslots = () => {
    const timeslotsToDelete = getIds(calendarEvents.filter((slot) =>
      slotSelectedForDelete.includes(slot._id)
      && getIsSlotRemovable(slot, eventKeyMoments, currentEvent)));

    mutateDeleteTimeslots.mutate({ timeslots: timeslotsToDelete });

    trackRecruiterDeleteSlots({ authUser, event: currentEvent, isAllSlots: false });
  }

  const handleOnMediumChange = (medium) => {
    const newSlots = tmpSlots.map((slot) => ({ ...slot, medium }));

    setTmpSlots(newSlots);

    setSettings({ ...settings, medium });
  }

  const handleOnLocationChange = (location) => {
    const newSlots = tmpSlots.map((slot) => ({ ...slot, location }));
    setTmpSlots(newSlots);

    setSettings({ ...settings, location });
  }

  const handleOnTypeChange = (type) => {
    const isMultiEvents = type === SLOT_TYPE.INFORMAL1TO1 ? false : settings.isMultiEvents;
    const newEvent = isMultiEvents ? null : getId(currentEvent)

    const newSlots = tmpSlots.map((slot) => ({ ...slot, type, isMultiEvents, _event: newEvent }));

    setTmpSlots(newSlots);

    setSettings({
      ...settings,
      type,
      isMultiEvents
    });
  }

  const handleOnInformalDescriptionChange = (informalDescription) => {
    const newSlots = tmpSlots.map((slot) => ({ ...slot, informalDescription }));
    setTmpSlots(newSlots);

    setSettings({ ...settings, informalDescription });
  }


  // Create the slot
  const handleOnSelectSlot = debounce((slot) => {
    if (isDeleteModeActive) return null;

    if (authUser.timezone) { moment.tz.setDefault(authUser.timezone.utc[0]); } else { moment.tz.setDefault(); }

    let { slots } = slot;
    let newSettings = settings;
    if (slot.action === 'click') {
      slots.pop(); // calendar fix we remove the last slot
    }

    const messageSlotProhibited = getMessageSlotProhibited({
      slot,
      eventKeyMoments,
      event: currentEvent,
      settings,
      exponentFormat,
      allSlots: [...tmpSlots, ...timeslots],
      t,
    })

    if (messageSlotProhibited) {
      return dispatch(sendNotification(({ message: messageSlotProhibited, kind: 'error' })));
    }

    const physicalDates = getEventKeyMoments(currentEvent, { type: 'jobfair', format: EVENT_FORMAT.PHYSICAL });
    const virtualDates = getEventKeyMoments(currentEvent, { type: 'jobfair', format: EVENT_FORMAT.VIRTUAL });

    // Block pour l'hybridation pour griser les dates non autorisées
    if (currentEvent.format === EVENT_FORMAT.HYBRID) {
      if (exponentFormat === EVENT_FORMAT.HYBRID) {
        if ([...slots, ...tmpSlots.map((slot) => slot.beginAt)].reduce((acc, slot) => acc &&  moment(slot).isBetween(virtualDates.beginAt, virtualDates.endAt) && moment(slot).isBetween(physicalDates.beginAt, physicalDates.endAt)
          ,true)) {
          changeEventKeyMoment(settings.participationMode);
          newSettings = { ...settings, isParticipationHybrid: true };

        }
        else if ([...slots, ...tmpSlots.map((slot) => slot.beginAt)].reduce((acc, slot) => acc && moment(slot).isBetween(virtualDates.beginAt, virtualDates.endAt)
          ,true)) {
          // changeEventKeyMoment(EVENT_FORMAT.VIRTUAL);
          handleOnParticipationModeChange(EVENT_FORMAT.VIRTUAL);
          newSettings = { ...settings, participationMode: EVENT_FORMAT.VIRTUAL, isParticipationHybrid: false, isVisible: true, medium: settings.medium === 'physical' ? 'visio' : settings.medium };
        }
        else if ([...slots, ...tmpSlots.map((slot) => slot.beginAt)].reduce((acc, slot) => acc && moment(slot).isBetween(physicalDates.beginAt, physicalDates.endAt)
          ,true)) {
          // changeEventKeyMoment(EVENT_FORMAT.PHYSICAL);
          handleOnParticipationModeChange(EVENT_FORMAT.PHYSICAL);
          newSettings = { ...settings, participationMode: EVENT_FORMAT.PHYSICAL, isParticipationHybrid: false, isVisible: true, medium: 'physical' };
        }
      }
      else {
        changeEventKeyMoment(exponentFormat);
        handleOnParticipationModeChange(exponentFormat);
        newSettings = { ...settings, participationMode: exponentFormat, isParticipationHybrid: false, isVisible: true, medium: exponentFormat === EVENT_FORMAT.PHYSICAL ? 'physical' : (settings.medium === 'physical' ? 'visio' : settings.medium) };
      }
    }
    // If settings are closed track the click on mixpanel
    if (!settings.isVisible) {
      if (currentEvent.format !== EVENT_FORMAT.HYBRID) {
        setSettings({ ...settings, isVisible: true });
      }
      trackRecruiterClickedCreatedSlots({ authUser, clickedButton: false });
    }

    if (slots.length > 1) {
      slots.pop(); // remove the end slot cause we don't need it

      // ARRAY OF INDEX FOR KEEPING  A CAUSE DE LA DUREE
      const iterate = settings.duration / 15;

      const indexListToKeep = times(Math.ceil(slots.length / iterate), (index) => index * iterate);

      slots = indexListToKeep.map((index) => slots[index]);

      // Handle hybrid
      if (exponentFormat === EVENT_FORMAT.HYBRID && !settings.isVisible) {
        if (moment(slots[0]).isBetween(virtualDates.beginAt, virtualDates.endAt)) {
          slots.forEach((slot, index) => {
            if (!moment(slot).isBetween(virtualDates.beginAt, virtualDates.endAt)) {
              return slots.splice(index, slots.length - (index));
            }
          })
        }
        else if (moment(slots[0]).isBetween(physicalDates.beginAt, physicalDates.endAt)) {
          slots.forEach((slot, index) => {
            if (!moment(slot).isBetween(physicalDates.beginAt, physicalDates.endAt)) {
              return slots.splice(index, slots.length - (index));
            }
          })
        }
      }
    }

    let newTimeslots = createFreeSlots({
      slots,
      settings: newSettings,
      currentEvent,
      userId: getId(exponentUserQuery?.data?._user),
      ownerId: getId(authUser),
      isHybrid: exponentFormat === EVENT_FORMAT.HYBRID,
    });

    if (currentEvent.format === EVENT_FORMAT.HYBRID) {
      const isSlotBetweenVirtualEvent = moment(slots[0]).isSameOrAfter(virtualDates.beginAt) && moment(slots[0]).isSameOrBefore(virtualDates.endAt) && exponentFormat !== EVENT_FORMAT.PHYSICAL;
      const isVirtualSelectedInSettings = settings.participationMode === EVENT_FORMAT.VIRTUAL;

      if (!newSettings.isMultiEvents) {
        if (isSlotBetweenVirtualEvent && isVirtualSelectedInSettings) {
          newTimeslots = newTimeslots.map((slot) => ({
            ...slot,
            medium: newSettings.medium,
            _event: currentEvent._id,
            _keyMomentLink: {
              _keyMoment: {
                _id: currentEvent.keyMoments.jobfair.find(e => e.format === EVENT_FORMAT.VIRTUAL)._id
              },
              type: 'jobfair',
            }
          }))
          setSettings({
            ...newSettings,
            isVisible: true,
          })
        }
        else {
          newTimeslots = newTimeslots.map((slot) => ({
            ...slot,
            medium: newSettings.medium,
            _event: currentEvent._id,
            _keyMomentLink: {
              _keyMoment: {
                _id: currentEvent.keyMoments.jobfair.find(e => e.format === EVENT_FORMAT.PHYSICAL)._id
              },
              type: 'jobfair',
            }
          }))
          setSettings({
            ...newSettings,
            isVisible: true,
          })
        }
      }
      if (settings.isMultiEvents) {
        if (isSlotBetweenVirtualEvent && isVirtualSelectedInSettings) {
          newTimeslots = newTimeslots.map((slot) => ({
            ...slot,
            medium: newSettings.medium,
          }))
          setSettings({
            ...newSettings,
            isVisible: true,
          })
        }
        else {
          newTimeslots = newTimeslots.map((slot) => ({
            ...slot,
            medium: newSettings.medium,
            _event: currentEvent._id,
            _keyMomentLink: {
              _keyMoment: {
                _id: currentEvent.keyMoments.jobfair.find(e => e.format === EVENT_FORMAT.PHYSICAL)._id
              },
              type: 'jobfair',
            }
          }))
          setSettings({
            ...newSettings,
            isVisible: true,
          })
        }
      }
    }

    const cleanTmpSlots = getSlotsCleaned(newTimeslots, [...tmpSlots, ...timeslots], eventKeyMoments, newSettings.duration);
    setTmpSlots([...tmpSlots, ...cleanTmpSlots]);

    return true;
  }, 0);

  const handleOnDeleteFreeSlot = (id) => {

    mutateDeleteTimeslots.mutate({ timeslots: [id] });
    trackRecruiterDeleteSlots({ authUser, event: currentEvent, isAllSlots: false });

  }

  const handleOnDeleteTmpSlot = (eventToDelete) => {
    const newSlots = tmpSlots
      .filter((timeslot, index) => index !== eventToDelete.groupIndex || timeslot.allDay === true);
    setTmpSlots(newSlots);
  }


  const resizeEvent = (resizeType, { event, start, end }) => {
    const nextEvents = calendarEvents.map((existingEvent) => existingEvent.id === event.id
      ? { ...existingEvent, start, end }
      : existingEvent);

    setCalendarEvents(nextEvents);
  }

  const handleSelectRecruiter = (value, evt, showWarningModal = true) => {
    if (!isEmpty(tmpSlots) && showWarningModal) {
      trackRecruiterClickedQuitCreatingSlots({ authUser, event: currentEvent });
      setModalContext({
        isVisible: true,
        context: 'quitWithoutSaving',
        onSubmit: () => {
          handleSelectRecruiter(value, evt, false);
          setModalContext({ ...modalContext, isVisible: false });
        },
      });
    } else {
      setSelectedUserId(value);
    }
  }

  const handleSelectEvent = (value, evt, showWarningModal = true) => {
    if (!isEmpty(tmpSlots) && showWarningModal) {
      trackRecruiterClickedQuitCreatingSlots({ authUser, event: currentEvent });
      setModalContext({
        isVisible: true,
        context: 'quitWithoutSaving',
        onSubmit: () => {
          handleSelectEvent(value, evt, false);
          setModalContext({ ...modalContext, isVisible: false });
        },
      });
    } else {
      const event = eventsSelectQuery?.data?.docs.find((e) => getId(e) === value);
      setSelectedEventId(event?._id);
    }
  };

  const handleOnSettingsChange = (inputName, val) => {
    switch (inputName) {
      case 'duration': return handleOnDurationChange(val);
      case 'medium': return handleOnMediumChange(val);
      case 'isMultiEvents': return handleOnCheckSpecificEvent(val);
      case 'location': return handleOnLocationChange(val);
      case 'informalDescription': return handleOnInformalDescriptionChange(val);
      case 'type': return handleOnTypeChange(val);
      case 'participationMode': return handleOnParticipationModeChange(val);

      default: return null;
    }
  }

  const handleOnSubmit = () => {
    let tmpSlotsWithFixedLocation = null
    if (tmpSlots[0]._event === null) {
      tmpSlots.forEach((slot) => {
        delete slot._keyMomentLink;
      })
    }
    if (tmpSlots[0].medium !== EVENT_FORMAT.PHYSICAL) {
      tmpSlots.forEach((slot) => {
        delete slot.location;
      })
    } else {
      tmpSlotsWithFixedLocation = tmpSlots.map((slot) => ({
        ...slot, location: currentEvent.location?.formattedAdress
      }))
    }

    debounceFunc(
      () => {
        mutateCreateTimeslots.mutate({ timeslots: tmpSlots });

        trackRecruiterCreatedSlots({
          authUser,
          currentEvent,
          currentExponentUserEvents,
          settings,
          selectedUser: selectedUserId,
          currentUserInExponent: exponentUserQuery?.data,
          newTimeslots: tmpSlotsWithFixedLocation ?? tmpSlots,
        });
      }
    );
  }

  const handleOnCheckSpecificEvent = (evt) => {
    const isMultiEvents = !evt.target.checked;
    const _event = isMultiEvents ? null : currentEvent._id;
    const newTimesSlots = tmpSlots
      .filter((slot) => isEmpty(slot._id))
      .map((slot) => ({ ...slot, _event }));

    if (!isMultiEvents && currentEvent.format === EVENT_FORMAT.HYBRID) {
      newTimesSlots.forEach((slot) => {
        slot._keyMomentLink = {
          _keyMoment: {
            _id: currentEvent.keyMoments.jobfair.find(e => e.format === EVENT_FORMAT.VIRTUAL)._id
          },
          type: 'jobfair',
        }
      })
    }

    setSettings({ ...settings, isMultiEvents });

    setTmpSlots(newTimesSlots);
  }

  const handleDeleteEvent = (event) => {
    if (event.isSaved) {
      setModalContext({
        isVisible: true,
        context: 'deleteSlot',
        onSubmit: () => {
          handleOnDeleteFreeSlot(getId(event));
        },
      });
    } else {
      handleOnDeleteTmpSlot(event);
    }
  }

  const handleOpenSetting = (participationMode) => {
    let formatOngoing = participationMode;
    const currentDate = moment();

    if (participationMode === EVENT_FORMAT.HYBRID) {
      if (moment(getEventKeyMoments(currentEvent, { type: 'jobfair', format: EVENT_FORMAT.VIRTUAL }).endAt) < currentDate) {
        formatOngoing = EVENT_FORMAT.PHYSICAL;
      }
      else {
        formatOngoing = EVENT_FORMAT.VIRTUAL;
      }
    }

    const medium = formatOngoing === EVENT_FORMAT.PHYSICAL ? 'physical' : 'visio';

    setSettings({
      ...settings,
      participationMode: formatOngoing
      , medium, isParticipationHybrid: participationMode === EVENT_FORMAT.HYBRID,
      isVisible: !settings.isVisible
    });

    changeEventKeyMoment(formatOngoing);

    trackRecruiterClickedCreatedSlots({ authUser, clickedButton: true, event: currentEvent });
  }

  if (!currentEvent) {
    return null;
  }

  if (authUser.timezone) { moment.tz.setDefault(authUser.timezone.utc[0]); } else { moment.tz.setDefault(); }
  // Define currentDate base on the week selected
  const isCalendarVisible = !exponentUserQuery?.isLoading && !eventsSelectQuery?.isLoading && currentExponentUserEvents?.length;

  const intlMoment = moment;

  intlMoment.locale(intl.locale === 'en' ? 'en' : intl.locale, {
    week: {
      dow: 1, // Monday is the first day of the week.
    },
  });

  const handleSettingSlotCancel = () => {
    if (!isEmpty(tmpSlots)) {
      setModalContext({ isVisible: true, context: 'cancelEditing' })
    }
    else {
      setSettings({ ...settings, isVisible: false })
    }
  }

  const handleOnOpenSlotSetting = () => {
    handleOpenSetting(exponentFormat);
  }

  const handleOnDeleteModeChange = (isActive) => {
    setIsDeleteModeActive(isActive);

    if (isActive === false) {
      setSlotSelectedForDelete([])
    }
  }

  return (
    <div className={styles.calendarContainer} id='calendarContainer'>
      <Prompt
        when={isBlocking}
        message={() =>
         t({ id: 'event.recruiter.preparation.offer.published.form.update' })
        }
      />
      <Wrapper size="full">
        <CalendarHeader
          handleOnOpenSlotSetting={handleOnOpenSlotSetting}
          settings={settings}
          handleSelectRecruiter={handleSelectRecruiter}
          eventsSelectQuery={eventsSelectQuery}
          usersSelectQuery={usersSelectQuery}
          handleSelectEvent={handleSelectEvent}
          exponentFormat={exponentFormat}
          currentEvent={currentEvent}
          selectedUserId={selectedUserId}
          isDeleteModeActive={isDeleteModeActive}
        />
        {isCalendarVisible ? (
          <>
            <AlertTimestamp
              authUser={authUser}
              currentUserInExponent={exponentUserQuery?.data}
            />
            <>
              <Row type="flex" className={styles.rbcContainer} gutter={24}>
                <Col className={settings.isVisible ? styles.settingsSlotColumnActive : styles.settingsSlotColumn}>
                  <CalendarSettings
                    style={{ maxWidth: '360px', position: "relative" }}
                    authUser={authUser}
                    currentUserInExponent={exponentUserQuery?.data}
                    handleOnSettingsChange={handleOnSettingsChange}
                    handleOnSubmit={handleOnSubmit}
                    isSubmitLoading={mutateCreateTimeslots.isLoading}
                    settings={settings}
                    timeslots={tmpSlots}
                    countEventAvailable={eventsSelectQuery?.data?.total}
                    currentEvent={currentEvent}
                    postAction={postAction}
                    handleOnCancel={handleSettingSlotCancel}
                    exponentFormat={exponentFormat}
                  />
                </Col>

                <Col style={{ flex: 1 }}>
                  <BigCalendar
                    downloadAllCv={() => downloadAllCv(selectedUser, currentEvent.slug)}
                    indexWeek={currentWeek}
                    currentEvent={eventsSelectQuery?.data?.selected}
                    handleWeekChange={setCurrentWeek}
                    handleDeleteTimeSlots={() => setModalContext({ isVisible: true, context: 'deleteAllSlots' })}
                    calendarSlots={calendarSlots}
                    handleOpenSetting={handleOpenSetting}
                    settings={settings}
                    onSelectSlot={handleOnSelectSlot}
                    handleEventResize={resizeEvent}
                    handleDeleteEvent={handleDeleteEvent}
                    handleOnInformalDescriptionChange={handleOnInformalDescriptionChange}
                    exponentFormat={exponentFormat}
                    eventKeyMoments={eventKeyMoments}
                    changeEventKeyMoment={changeEventKeyMoment}
                    isDeleteModeActive={isDeleteModeActive}
                    setSlotSelectedForDelete={setSlotSelectedForDelete}
                    slotSelectedForDelete={slotSelectedForDelete}
                  />
                </Col>
              </Row>
            </>
          </>
        ) : (
          <BlankUserDoesNotParticipateInEvent
            isLoading={exponentUserQuery?.isLoading || eventsSelectQuery?.isLoading}
            user={selectedUser}
            isExponentUser={!isEmpty(eventsSelectQuery?.data?.docs)}
          />)
        }
      </Wrapper>
      <CalendarFooter
        calendarSlots={calendarSlots}
        isDeleteModeActive={isDeleteModeActive}
        exponentUser={exponentUserQuery?.data}
        indexWeek={currentWeek}
        event={currentEvent}
        exponentFormat={exponentFormat}
        setIsDeleteModeActive={handleOnDeleteModeChange}
        setModalContext={setModalContext}
        slotSelectedForDelete={slotSelectedForDelete}
        settings={settings}
      />

      <ModalConfirmation
        isVisible={modalContext.isVisible}
        onCancel={() => setModalContext({ ...modalContext, isVisible: false })}
        onConfirm={() => setTmpSlots([])}
        modalProperty={getModalProperty()}
        mutateDeleteTimeslots={mutateDeleteTimeslots}
      />
    </div>
  );
};

Calendar.propTypes = {
  authUser: object,
  currentEvent: object,
  events: array,
  intl: object,
  postAction: func,
  downloadAllCv: func,
};

const mapDispatchToProps = {
  postAction: actionActions.postAction,
};

const withConnect = connect(null, mapDispatchToProps);

export default compose(
  withConnect,
  injectIntl,
)(toJS(Calendar));
