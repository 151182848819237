import React from 'react';
import { compact, find, join, map } from 'lodash';
import { getId } from '@/utils/global';
import { IconBriefcase, IconLocalisationMap } from '@seekube-tech/ui-kit';
import classnames from 'classnames';

import Icon from '@/components/Icon';

import styles from './styles.less';

// const DEFAULT_CRITERION_KEYS = ['CONTRACT', 'AVAILABILITY', 'DURATION', 'MOBILITY', 'POSITION'];

const getContracts = (matching, _criteria, withJoin = false) => {
  const { CONTRACT } = matching;
  const contracts = [];
  const contractCriteria = find(_criteria, (item) => item.key === 'CONTRACT');
  if (contractCriteria) {
    contractCriteria._choices.forEach((item) => {
      if (item.enable && CONTRACT.includes(getId(item))) {
        contracts.push(item.label);
      }
    });
  }
  return withJoin ? join(contracts, ', ') : contracts;
};

const getMobility = (matching, _criteria, withJoin = false) => {
  const { MOBILITY } = matching;
  const contracts = [];
  const contractCriteria = find(_criteria, (item) => item.key === 'MOBILITY');


  if (contractCriteria) {
    contractCriteria._choices.forEach((choice) => {
      if (choice.enable && MOBILITY.includes(getId(choice))) {
        contracts.push(choice.label);
      }
    });
  }

  return withJoin ? join(contracts, ', ') : contracts;
};

const Mobilities = ({ matching, _criteria, className }) => {
  const mobilites = getMobility(matching, _criteria, true);

  return mobilites && (
    <div className={classnames(styles.criterion, className)}>
      <IconLocalisationMap size={16} /> <span>{mobilites}</span>
    </div>
  );
};

const Contracts = ({ matching, _criteria, className }) => {
  const contracts = getContracts(matching, _criteria, true);

  return contracts && (
    <div className={classnames(styles.criterion, className)}>
      <IconBriefcase size={16} /> <span>{contracts}</span>
    </div>
  );
};

const Locations = ({ locations, formatAddress = 'formattedAdress' }) => {
  const loc = join(compact(map(locations, (o) => o[formatAddress])), ', ');

  return loc && (
    <div className={classnames(styles.criterion)}>
      <IconLocalisationMap size={16} /> <span>{loc}</span>
    </div>
  );
};

export { getContracts, getMobility, Contracts, Mobilities, Locations };
