import { makeStyles } from '@seekube-tech/ui';

export default makeStyles(theme => ({
  single: {
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.neutral[100]}`,
    boxSizing: 'border-box',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.07)',
    borderRadius: 10,
    backgroundColor: theme.palette.basic.white,
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: 0,
      width: '100%',
    },
  },
  active: {
    padding: theme.spacing(2, 3),

    '& $month': {
      marginTop: theme.spacing(1),

      [theme.breakpoints.down('sm')]: {
        marginTop: 0,
        marginLeft: theme.spacing(1),
      },
    },

    '& $hour': {
      marginTop: theme.spacing(2),

      [theme.breakpoints.down('sm')]: {
        marginTop: 0,
        marginLeft: theme.spacing(1),
      },
    },
  },
  month: {
    marginTop: theme.spacing(0.5),
    fontWeight: 700,
    color: theme.palette.neutral[300],

    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      marginLeft: theme.spacing(1),
    },
  },
  hour: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(0.5),
    padding: theme.spacing(0.5, 1.5),
    background: theme.palette.neutral[100],
    borderRadius: 30,

    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      marginLeft: theme.spacing(1),
    },
  },
  icon: {
    height: 16,
    width: 16,
    marginRight: 5,
    fill: 'transparent',
    stroke: theme.palette.common.blue[500],
  },
  multiple: {
    background: theme.palette.basic.white,
    border: `1px solid ${theme.palette.neutral[100]}`,
    boxSizing: 'border-box',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.07)',
    borderRadius: 10,
    padding: theme.spacing(1, 2),

    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0.5, 3),
      padding: theme.spacing(1),
      width: '100%',

      '& $month': {
        marginLeft: 0,
      },
    },
  },
  day: {
    height: 45,

    [theme.breakpoints.down('sm')]: {
      paddingTop: 10,
      lineHeight: '100%',
    },
  },
  arrow: {
    [theme.breakpoints.down('sm')]: {
      height: 76,
      margin: theme.spacing(-1, 0),
    },
  }
}));
