import React, { useEffect, useState } from 'react';
import { func } from 'prop-types';
import { debounce } from 'lodash';

import { IconSearch } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import InputMaterial from '@/components/Input/InputMaterial'
import { SearchResult } from '@/scenes/Admin/components/Kpi/components/InputSearchAdmin/SearchResult';

import styles from './styles.less';


const InputSearchAdmin = ({
  onClick,
  onEnter,
}) => {
  const t = useFormatMessage();
  const [dropdownIsVisible, setDropdownIsVisible] = useState(false);
  const [keywords, setKeywords] = useState('');


  useEffect(() => {
    function handleDocumentClick () {
      setDropdownIsVisible(false);
    }

    document.addEventListener('click', handleDocumentClick, false);
    document.addEventListener('touchend', handleDocumentClick, false);

    return () => {
      document.removeEventListener('click', handleDocumentClick, false);
      document.removeEventListener('touchend', handleDocumentClick, false);
    }
  }, [dropdownIsVisible]);


  const searchItems = debounce((searchTerm) => {setKeywords(searchTerm)}, 300);

  const handleOnChange = (event) => {
    const { value } = event.target;
    const hasValue = value.trim().length > 0 && Boolean(value);

    setDropdownIsVisible(hasValue)

    if (hasValue) {
      searchItems(value);
    }
  };

  return (
    <div className={styles.inputSearchContainer}>
      <InputMaterial
        autoFocus
        onPressEnter={(e) => { onEnter('user', { username: e.currentTarget.value } ); }}
        label={t({ id: 'search' })}
        placeholder=""
        type="string"
        onChange={handleOnChange}
      />
      <IconSearch size={14} className={styles.icon} />
      <SearchResult keywords={keywords} onClick={onClick} dropdownIsVisible={dropdownIsVisible} />
    </div>
  );
}

InputSearchAdmin.propTypes = {
  onClick: func,
  onEnter: func,
}

export default InputSearchAdmin;
