import { defineMessages } from 'react-intl';

export default defineMessages({
  noSlots: {
    id: 'event.candidate.appointment.mainContent.noSlots',
  },
  offer: {
    id: 'event.candidate.appointment.mainContent.offer',
  },
  noSlotsText: {
    id: 'event.candidate.appointment.mainContent.noSlotsText',
  },
  slotUnavailable: {
    id: 'event.candidate.appointment.mainContent.slotUnavailable',
  },
  refuseAppointmentSubmit: {
    id: 'event.candidate.appointment.mainContent.no',
  },
  sendEmail: {
    id: 'event.candidate.appointment.mainContent.sendEmail',
  },
  addToCalendar: {
    id: 'event.candidate.appointment.mainContent.addToCalendar',
  },
  titleOrganization: {
    id: 'event.candidate.appointment.mainContent.titleOrganization',
  },
  titleRecruiter: {
    id: 'event.candidate.appointment.mainContent.titleRecruiter',
  },
  confirmText: {
    id: 'event.candidate.appointment.mainContent.confirmText',
  },
  confirmText2: {
    id: 'event.candidate.appointment.mainContent.confirmText.withTimezone',
  },
  acceptedTextLocation: {
    id: 'event.candidate.appointments.card.status.acceptedText[1]',
  },
  acceptedTextLocation2: {
    id: 'event.candidate.appointments.card.status.acceptedText.withTimezone[1]',
  },
  subject: {
    id: 'event.candidate.appointment.mainContent.subject',
  },
  congratulation: {
    id: 'event.candidate.appointment.mainContent.congratulation',
  },
  congratulationAuto: {
    id: 'event.candidate.appointment.mainContent.auto.congratulation',
  },
  validate: {
    id: 'event.candidate.appointment.slots.validate',
  },
  slotsText: {
    id: 'event.candidate.appointment.slots.text',
  },
  slotsTitle: {
    id: 'event.candidate.appointment.slots.title',
  },
  informal1to1Title: {
    id: 'event.candidate.appointment.slots.informal1to1.title',
  },
  reasonTitle: {
    id: 'event.candidate.appointment.reason.title'
  },
  phone: {
    id: 'appointment.medium.phone',
  },
  visio: {
    id: 'appointment.medium.visio',
  },
  physical: {
    id: 'appointment.medium.physical',
  },
  cancel: {
    id: 'cancel',
  },
  yes: {
    id: 'yes',
  },
  cancelAppointmentSubmit: {
    id: 'appointment.cancel',
  },
  cancelAppointment: {
    id: 'event.candidate.appointment.cancel',
  },
  cancelAppointmentModalTitle: {
    id: 'event.candidate.appointments.cancelAppointment.title',
  },
  refuseAppointmentModalTitle: {
    id: 'event.candidate.appointments.refuseProposition.title',
  },
  confirmCancel: {
    id: 'event.candidate.appointment.confirmCancel',
  },
  placeholderCancelTextArea: {
    id: 'event.candidate.appointment.cancel.textarea.placeholder',
  },
  labelRefuseTextArea: {
    id: 'event.candidate.appointment.refuse.textarea.label',
  },
  labelCancelTextArea: {
    id: 'event.candidate.appointment.cancel.textarea.label',
  },
  cancelBtn: {
    id: 'cancel',
  },
  relaunchAppointmentModalTitle: {
    id: 'event.candidate.appointments.relaunch.title',
  },
  relaunchAppointmentSubmit: {
    id: 'event.candidate.appointments.relaunch.title',
  },
  recruiterAtCompany: {
    id: 'event.candidate.appointments.recruiterAtCompany',
  },
  defaultRelaunchMessage: {
    id: 'event.candidate.appointment.relaunch.defaultMessage',
  },
  change: {
    id: 'change',
  },
  step1: {
    id: 'appointment.footer.step.criterion',
  },
  step2: {
    id: 'appointment.footer.step.slot',
  },
});
