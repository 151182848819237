import { defineMessages } from 'react-intl';

export default defineMessages({
  navPreparation: {
    id: 'header.recruiter.preparation',
  },
  navJobdating: {
    id: 'header.recruiter.jobdating',
  },
  navLive: {
    id: 'live.pl',
  },
});
