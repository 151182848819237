import React from 'react';
import { object } from 'prop-types';

import { useFormatMessage } from 'react-intl-hooks';
import { useGetAppointmentsMade } from '@/queries/dashboardRealtime/useGetAppointmentsMade';

import { UserCheck } from 'react-feather';
import styles from './styles.less'

const SmallStatMeetingsCard = ({event, view}) => {

  const t = useFormatMessage();
  const stat = useGetAppointmentsMade({eventId: event._id, view})

  return (
    <div className={styles.smallStatCardContainer}>
      <div className={styles.leftContent}>
        <p className={styles.title}>{t({ id: `dashboard.realtime.smallCard.appointments.title`})}</p>
        <p className={`${styles.description} ${styles.descriptionAppointments}`}>{t({ id: `dashboard.realtime.smallCard.appointments.description`})}</p>
        {event.modules.informal1to1?.enable && 
          <div className={styles.statsDetailsContainer}>
            <div className={styles.statsDetailsElement}>
              <div className={styles.statsDetailsNumber}>
                {stat?.data?.countAppointmentsCompleted.interview}
              </div>
              <p>{t({ id: `dashboard.realtime.smallCard.appointments.interview`})}</p>
            </div>
            <div className={styles.statsDetailsElement}>
              <div className={styles.statsDetailsNumber}>
                {stat?.data?.countAppointmentsCompleted.informal1to1}
              </div>
              <p>{t({ id: `dashboard.realtime.smallCard.appointments.informal1to1`})}</p>
            </div>
          </div>
        }
      </div>
      <div className={styles.rightContent}>
        <div className={`${styles.statNumberContainer} ${styles.statNumberContainerAppointment}`}>
          <UserCheck size={16} />
          <span>{stat?.data?.countAppointmentsCompleted.total}</span>
        </div>
      </div>
    </div>
  )
}

SmallStatMeetingsCard.propTypes = {
  event: object,
};

export default SmallStatMeetingsCard;