import React from 'react';
import { func, bool, object } from 'prop-types';

import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';

import logo from '@/assets/images/mainLogo.svg';
import logoColor from '@/assets/images/logo-color.svg';
import Spotlight from '../../../../../../scenes/Admin/components/Spotlight';
import { EventInfos, Auth, SeekubeLogo, Agenda } from '../components';

import styles from './styles.less';
import messages from './messages';


const ClientHeader = (props) => (
  <div className={styles.headerStructure} >
    <div className={styles.leftSide}>
      <SeekubeLogo logo={logo} logoColor={logoColor} context="default" />
      {props.showEvent && location.href.indexOf('client/') === -1 ? (
        <div className={styles.colEvent}>
          <EventInfos {...props} />
        </div>
      ) : ''}
    </div>

    <div className={styles.rightSide}>
      <div className={styles.colRight}>
        <div className={styles.colAuth}>
          <Auth {...props} />
        </div>
        <Spotlight className={styles.colAuth} />
        <Agenda {...props} />
        <ClientNav {...props} />
      </div>
    </div>
  </div>
);

const ClientNav = () => (
  <div id="clientNav" className={styles.clientNav}>
    <NavLink to="/events" activeClassName="itemSelected"><FormattedMessage {...messages.jobdatings} tagName="div" /></NavLink>
    <NavLink to="/client/stats/by-period" activeClassName="itemSelected"><FormattedMessage {...messages.stats} values={{ count: 2 }} tagName="div" /></NavLink>
    <NavLink to="/client/offers" activeClassName="itemSelected"><FormattedMessage {...messages.offers} tagName="div" /></NavLink>
    <NavLink to="/client/conferences/discovery" activeClassName="itemSelected"><FormattedMessage {...messages.live} values={{ count: 2 }} tagName="div" /></NavLink>
    <NavLink to="/client/stand" activeClassName="itemSelected"><FormattedMessage {...messages.stand} tagName="div" /></NavLink>
    <NavLink to="/client/member/by-collaborator" isActive={() => window.location.href.indexOf('/client/member') > -1} activeClassName="itemSelected"><FormattedMessage {...messages.members} tagName="div" /></NavLink>
  </div>
);


ClientHeader.propTypes = {
  renderAuth: func,
  renderEvent: func,
  showEvent: bool,
  props: object,
  authUser: object,
};

export default ClientHeader;
