import React from 'react';
import { get } from 'lodash';
import { AvatarBusiness, Caption1 } from '@seekube-tech/ui-kit';
import { Tag } from 'antd';
import { useFormatMessage } from 'react-intl-hooks';
import { string, object, bool } from 'prop-types';
import Checkbox from '@/components/Checkbox';
import styles from '@/scenes/Events/scenes/components/JobdatingsExplore/components/JobdatingCard/styles.less';
import { JobdatingStatus } from '@/scenes/Events/scenes/components/JobdatingStatus';

export const Header = ({
  context,
  event,
  isChecked,
}) => {
  const t = useFormatMessage();
  const criteria = [event.visibility, event.dateStatus];
  const pictureUrl = event?.planners?._organizations?.[0]?.profile?.pictureUrl;

  return (
    <>
      {context === 'default' ? (
        <JobdatingStatus criteria={criteria} />
      ) : (
        <div className={styles.checkboxContainer}>
          <Checkbox checked={isChecked}/>
        </div>
      )}

      {context === 'default' && pictureUrl ? (
        <div className={styles.organizationLogo}>
          <Caption1 fontWeight={900}>
            {t({ id: 'event.landing.organizedBy' })}&nbsp;<br/>
            {get(event, 'planners._organizations.0.name')}
          </Caption1>
          <div>
            <AvatarBusiness
              pictureUrl={pictureUrl}
              size="small"
            />
          </div>
        </div>
      ) : ''}

      {context === 'onboarding' ? (
        <div className={styles.targetContainer}>
          {event.targets.map((target, index) => (
            <Tag className={styles.targetTag} key={index}>
              {t({ id: `event.target.${target}` })}
            </Tag>
          ))}
        </div>
      ) : ''}
    </>
  )
}

Header.propTypes = {
  event: object,
  isChecked: bool,
  context: string,
}

