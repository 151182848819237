import { isEmpty } from 'lodash';
import moment from 'moment';
import 'moment-timezone';

/**
 * Class for transform data from the form to the API
 */
export default class FormToApi {
  /**
   * Return new Array with all modules
   *
   * @param {object} eventModules
   * @param {Array} moduleNameWant
   *
   * @returns {Array}
   */
  static modules = (eventModules = {}, moduleNameWant = []) => {
    const modules = ['jobdating', 'live', 'hunt', 'feedback', 'offer'];

    // POST
    return modules
      .reduce((acc, moduleName) => {
        acc[moduleName] = { // eslint-disable-line
          type: moduleName,
          enable: moduleNameWant.includes(moduleName),
          label: moduleName,
          labelMultiple: `${moduleName}s`,
        };
        return acc;
      }, {});
  };

  /**
   * Return new array of object with the type and the key beginAt and endAt
   * @description Convert date in local timezone to jobdating timezone
   *
   * @param {object} datesForm
   *
   * @returns {object}
   */
  // eslint-disable-next-line arrow-body-style
  static keyDates = (datesForm, timezone) => {
    if (!datesForm) {
      return undefined;
    }

    let dates = {
      jobfair: {
        beginAt: moment.tz(datesForm.jobfair[0]?.format('YYYY-MM-DD HH:mm:ss'), timezone).toISOString(),
        endAt: moment.tz(datesForm.jobfair[1]?.format('YYYY-MM-DD HH:mm:ss'), timezone).toISOString(),
      },
      discovering: {
        beginAt: moment.tz(datesForm.discovering[0]?.format('YYYY-MM-DD HH:mm:ss'), timezone).toISOString(),
        endAt: moment.tz(datesForm.discovering[1]?.format('YYYY-MM-DD HH:mm:ss'), timezone).toISOString(),
      },
      hunt: {
        beginAt: moment.tz(datesForm.hunt[0]?.format('YYYY-MM-DD HH:mm:ss'), timezone).toISOString(),
        endAt: moment.tz(datesForm.hunt[1]?.format('YYYY-MM-DD HH:mm:ss'), timezone).toISOString(),
      },
    };

    if (datesForm.live?.[0] && datesForm.live?.[1]) {
      dates = {
        ...dates,
        live: {
          beginAt: moment.tz(datesForm.live[0].format('YYYY-MM-DD HH:mm:ss'), timezone).toISOString(),
          endAt: moment.tz(datesForm.live[1].format('YYYY-MM-DD HH:mm:ss'), timezone).toISOString(),
        },
      };
    }

    if (datesForm.applicationsClosing) {
      const applicationsClosing = moment.tz(datesForm.applicationsClosing.format('YYYY-MM-DD HH:mm:ss'), timezone).toISOString();

      dates = {
        ...dates,
        applicationsClosing: {
          beginAt: applicationsClosing,
          endAt: applicationsClosing,
        },
      };
    }

    if (datesForm.interactionsClosing) {
      const interactionsClosing = moment.tz(datesForm.interactionsClosing.format('YYYY-MM-DD HH:mm:ss'), timezone).toISOString();

      dates = {
        ...dates,
        interactionsClosing: {
          beginAt: interactionsClosing,
          endAt: interactionsClosing,
        },
      };
    }

    return dates;
  };

  /**
   * If the localization is not virtual so we return the localization from google
   *
   * @param {string} localizationType
   * @param {object} localization
   *
   * @returns {object|null}
   */
  static localization = (localizationType, localization) => {
    if (localizationType !== 'virtual') {
      return localization;
    }
    return null;
  };
}

/**
 *
 * TODO: modify keyMoments to patch an event. Format should be :
 * keyMoments.jobfair = [
 * {
 *  beginAt: Date,
 *  endAt: Date,
 *  type: string | undefined
 * }
 * ]
 */
export function keyMomentsFormToApi(keyMoments, timezone) {
  const localMoments = { ...keyMoments }
  const dates = {};

  /**
   * Filter out empty keys. It's a complicated bit but it only does three things :
   * - Verify that the key is not undefined
   * - Verify that every element of the array is not undefined
   * - Verify that at least one element of the array contained in one object of the property is not undefined
   */
  Object.keys(localMoments).filter(keyMoment => localMoments[keyMoment] && (
    (localMoments[keyMoment] instanceof Array && localMoments[keyMoment].reduce((prev, date) => prev && date !== undefined, true))
    || (typeof localMoments[keyMoment] === 'object' && Object.keys(localMoments[keyMoment]).filter(format => localMoments[keyMoment][format]).length > 0)
  )).forEach(keyMoment => {
    dates[keyMoment] = []
  })

  dates.jobfair = [
    {
      beginAt: moment.tz(keyMoments.jobfair.physical[0]?.format('YYYY-MM-DD HH:mm:ss'), timezone.utc[0]).toISOString(),
      endAt: moment.tz(keyMoments.jobfair.physical[1]?.format('YYYY-MM-DD HH:mm:ss'), timezone.utc[0]).toISOString(),
      format: 'physical'
    },
    {
      beginAt: moment.tz(keyMoments.jobfair.virtual[0]?.format('YYYY-MM-DD HH:mm:ss'), timezone.utc[0]).toISOString(),
      endAt: moment.tz(keyMoments.jobfair.virtual[1]?.format('YYYY-MM-DD HH:mm:ss'), timezone.utc[0]).toISOString(),
      format: 'virtual'
    }
  ]

  if (dates.live && !isEmpty(keyMoments.live)) {
    dates.live = [
      {
        beginAt: moment.tz(keyMoments.live[0]?.format('YYYY-MM-DD HH:mm:ss'), timezone.utc[0]).toISOString(),
        endAt: moment.tz(keyMoments.live[1]?.format('YYYY-MM-DD HH:mm:ss'), timezone.utc[0]).toISOString(),
        format: 'hybrid',
      }
    ]
  }

  dates.discovering = [
    {
      beginAt: moment.tz(keyMoments.discovering[0]?.format('YYYY-MM-DD HH:mm:ss'), timezone.utc[0]).toISOString(),
      endAt: moment.tz(keyMoments.discovering[1]?.format('YYYY-MM-DD HH:mm:ss'), timezone.utc[0]).toISOString(),
      format: 'hybrid',
    }
  ]

  if (dates.applicationsClosing) {
    dates.applicationsClosing = []

    if (keyMoments.applicationsClosing.physical) {
      dates.applicationsClosing.push({
        beginAt: moment.tz(keyMoments.applicationsClosing.physical?.format('YYYY-MM-DD HH:mm:ss'), timezone.utc[0]).toISOString(),
        endAt: moment.tz(keyMoments.applicationsClosing.physical?.format('YYYY-MM-DD HH:mm:ss'), timezone.utc[0]).toISOString(),
        format: 'physical'
      })
    }

    if (keyMoments.applicationsClosing.virtual) {
      dates.applicationsClosing.push({
        beginAt: moment.tz(keyMoments.applicationsClosing.virtual?.format('YYYY-MM-DD HH:mm:ss'), timezone.utc[0]).toISOString(),
        endAt: moment.tz(keyMoments.applicationsClosing.virtual?.format('YYYY-MM-DD HH:mm:ss'), timezone.utc[0]).toISOString(),
        format: 'virtual'
      })
    }
  }

  if (dates.hunt && !isEmpty(keyMoments.hunt)) {
    dates.hunt = [
      {
        beginAt: moment.tz(keyMoments.hunt[0]?.format('YYYY-MM-DD HH:mm:ss'), timezone.utc[0]).toISOString(),
        endAt: moment.tz(keyMoments.hunt[1]?.format('YYYY-MM-DD HH:mm:ss'), timezone.utc[0]).toISOString(),
        format: 'hybrid',
      }
    ]
  }


  if (dates.interactionsClosing) {
    dates.interactionsClosing = []
    if (keyMoments.interactionsClosing.physical) {
      dates.interactionsClosing.push({
        beginAt: moment.tz(keyMoments.interactionsClosing.physical?.format('YYYY-MM-DD HH:mm:ss'), timezone.utc[0]).toISOString(),
        endAt: moment.tz(keyMoments.interactionsClosing.physical?.format('YYYY-MM-DD HH:mm:ss'), timezone.utc[0]).toISOString(),
        format: 'physical'
      })
    }

    if (keyMoments.interactionsClosing.virtual) {
      dates.interactionsClosing.push({
        beginAt: moment.tz(keyMoments.interactionsClosing.virtual?.format('YYYY-MM-DD HH:mm:ss'), timezone.utc[0]).toISOString(),
        endAt: moment.tz(keyMoments.interactionsClosing.virtual?.format('YYYY-MM-DD HH:mm:ss'), timezone.utc[0]).toISOString(),
        format: 'virtual'
      })
    }
  }

  return dates;
}
