import { isEmpty } from 'lodash';
import { EVENT_FORMAT } from './constants';

const checkParticipant = (participant, event) => {
  const { participants, cvIsRequired } = event || {};
  const { onboarding } = participants || {};

  if (participant && onboarding) {
    if (onboarding[1] && isEmpty(participant._user.phone)) {
      return 'presentation';
    }

    if (cvIsRequired && isEmpty(participant._user.resumeUrl)) {
      return 'cv';
    }

    if (onboarding[2]?.enabled && isEmpty(participant._user.educations)) {
      return 'experience';
    }
    if (onboarding[3]?.enabled && isEmpty(participant._keyMomentLinks) && event.format === EVENT_FORMAT.HYBRID) {
      return 'participation';
    }
  }

  return 'ok';
};

export default checkParticipant;
