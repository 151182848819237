import { Modal as ModalAntd } from 'antd';
import { any, bool, func, oneOf, string } from 'prop-types';
import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import styles from './styles.less';

import Icon from '../Icon';

const ModalV2 = (props) => {
  const { width, closable = true } = props;

  const [visible, setVisible] = useState(props.visible);
  useEffect(() => { setVisible(props.visible); }, [props.visible]);

  return (
    <>
      {props.triggerComponent && <span role="button" tabIndex={0} onClick={() => setVisible(true)}>{props.triggerComponent}</span>}
      <ModalAntd
        footer={false}
        closable={false}
        width={width}
        wrapClassName={classnames(styles.wrapClassName, styles[props.template])}
        className={classnames(styles.wrapClassName, styles[props.template])}
        {...props}
        visible={visible}
      >
        {closable === true && <a role="button" tabIndex={0} className="modal-close"
            onClick={() => (typeof props.onClose === 'function' ? props.onClose() : setVisible(false))}>
          <Icon name="close" className="modal-close-icon"/>
        </a>}
        {props.children}
      </ModalAntd>
    </>
  );
};

ModalV2.defaultProptypes = {
  template: 'default',
  width: '720px',
};

ModalV2.propTypes = {
  children: any,
  width: string,
  template: oneOf(['default', 'center', 'confirm', 'defaultSophie']),
  triggerComponent: any,
  visible: bool,
  onClose: func,
};

export default ModalV2;
