import {useQuery} from 'react-query';
import request from '@/utils/request';

export function useSendResumeOnboardingMail({ participantId, eventSlug, ...props }) {
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventSlug}/participants/${participantId}/sendresumeonboarding`;
  const queryFn = () => request(requestUrl, { method: 'POST' });

  return useQuery({
    queryKey: ['sendResumeOnboarding'],
    queryFn,
    refetchOnWindowFocus: false,
    enabled: false,
    ...props,
  });
}
