import { isEmpty } from 'lodash';
import { ANALYTICS_ALL, ANALYTICS_RECRUITER } from '@/utils/constants';
import { trackGtmEvent } from "@/utils/pixels/helloWork";
import { track } from '../helpers';

const trackRegistration = ({ user, event, method, forum }) => {
  track({
    name: ANALYTICS_ALL.REGISTRATION,
    user,
    event,
    properties: {
      method,
      forum,
    },
  });
};

const trackSignin = ({ user, event, method, forum }) => {
  trackGtmEvent('login', {
    'user_id_back': user?._id || '',
    'jobdating_name': event?.slug || '',
    'isCandidate': isEmpty(user._currentOrganization),
  });

  track({
    name: ANALYTICS_ALL.SIGN_IN,
    user,
    event,
    properties: {
      method,
      forum,
    },
  });
};

const trackSignout = () => {
  track({
    name: ANALYTICS_ALL.SIGN_OUT,
  });
};

const trackRecruiterSentNegativeMail = ({ authUser, event }) => (
  track({
    name: ANALYTICS_RECRUITER.SENT_NEGATIVE_EMAIL,
    user: authUser,
    event,
  })
);

const trackRecruiterQuitCreatingSlots = ({ authUser, event = null }) => (
  track({
    name: ANALYTICS_RECRUITER.QUIT_CREATING_SLOTS,
    user: authUser,
    event,
  })
);

const trackRecruiterClickedQuitCreatingSlots = ({ authUser, event, creatingSlots }) => (
  track({
    name: ANALYTICS_RECRUITER.CLICKED_QUIT_CREATING_SLOTS,
    user: authUser,
    event,
    properties: {
      creatingSlots,
    },
  })
);

const trackRecruiterClickedNylasConnect = ({ authUser }) => (
  track({
    name: ANALYTICS_RECRUITER.CLICKED_CONNECT_NYLAS,
    user: authUser,
  })
);

const trackRecruiterClickedNylasDisconnect = ({ authUser }) => (
  track({
    name: ANALYTICS_RECRUITER.CLICKED_DISCONNECT_NYLAS,
    user: authUser,
  })
);

const trackRecruiterToggleSyncSlots = ({ authUser, checked }) => (
  track({
    name: ANALYTICS_RECRUITER.TOGGLE_SYNC_SLOTS,
    user: authUser,
    properties: {
      checked,
    },
  })
);

const trackRecruiterToggleSyncAppointments = ({ authUser, checked }) => (
  track({
    name: ANALYTICS_RECRUITER.TOGGLE_SYNC_APPOINTMENTS,
    user: authUser,
    properties: {
      checked,
    },
  })
);

const trackRecruiterClickedLinkToSync = ({ authUser, alertType = 'info' }) => (
  track({
    name: ANALYTICS_RECRUITER.CLICKED_REDIRECT_TO_SYNC,
    user: authUser,
    properties: {
      alertType,
    },
  })
);
const trackRecruiterEditedSyncCalendar = ({ authUser, isPrimary }) => (
  track({
    name: ANALYTICS_RECRUITER.EDITED_SYNC_CALENDAR,
    user: authUser,
    properties: {
      'Primary calendrier': isPrimary,
    },
  })
);

const trackRecruiterDownloadedSeveralCvs = ({ authUser, count }) => (
  track({
    name: ANALYTICS_RECRUITER.DOWNLOADED_SEVERAL_CVS,
    user: authUser,
    properties: {
      count,
    },
  })
);

const trackRecruiterClickedLearnPlan = ({ authUser, plan }) => (
  track({
    name: ANALYTICS_RECRUITER.CLICKED_LEARN_PLAN,
    user: authUser,
    properties: {
      plan,
    },
  })
);

const trackRecruiterClickedInfoPlan = ({ authUser, plan }) => (
  track({
    name: ANALYTICS_RECRUITER.CLICKED_INFO_PLAN,
    user: authUser,
    properties: {
      plan,
    },
  })
);

const trackRecruiterClickedSendRequestPlan = ({ authUser, plan, message, phone }) => (
  track({
    name: ANALYTICS_RECRUITER.CLICK_SEND_REQUEST_PLAN,
    user: authUser,
    properties: {
      plan,
      message,
      phone,
    },
  })
);

const trackRecruiterClickedViewAllAppointments = ({ authUser }) => (
  track({
    name: ANALYTICS_RECRUITER.CLICKED_VIEW_ALL_APPOINTMENTS,
    user: authUser,
    properties: {
      'User Type': 'manager',
    },
  })
);

const trackOwnerExportedLiveData = ({ dataType }) => (
  track({
    name: ANALYTICS_RECRUITER.EXPORTED_LIVES_DATA,
    properties: {
      'Data Type': dataType,
    },
  })
);

const trackRecruiterClickedViewKeyDates = ({ authUser }) => (
  track({
    name: ANALYTICS_RECRUITER.CLICKED_VIEW_KEY_DATES,
    user: authUser,
  })
);

const trackRecruiterDeleteTemplate = ({ authUser }) => (
  track({
    name: ANALYTICS_RECRUITER.DELETED_TEMPLATE,
    user: authUser,
  })
);

const trackRecruiterCreatedNewTemplate = ({ authUser }) => (
  track({
    name: ANALYTICS_RECRUITER.CREATED_NEW_TEMPLATE,
    user: authUser,
  })
);

const trackRecruiterUpdatedTemplate = ({ authUser }) => (
  track({
    name: ANALYTICS_RECRUITER.UPDATED_TEMPLATE,
    user: authUser,
  })
);

const trackRecruiterDraftOffer = ({ authUser, event }) => (
  track({
    name: ANALYTICS_RECRUITER.DRAFTED_OFFER,
    user: authUser,
    event,
  })
);

const trackRecruiterClickedAccessSchoolEvents = ({ authUser }) => {
  track({
    name: ANALYTICS_RECRUITER.CLICKED_ACCESS_SCHOOL_EVENTS,
    user: authUser,
  })

  trackGtmEvent('seekalendar_click', {
    'page_category': 'home',
    'page_subject': 'home_page',
    'user_id_back': authUser._id,
    'role': authUser.currentRole?._role?.key,
    'company': authUser._currentOrganization.name,
    isClient: true,
  });
}

const trackRecruiterClickedDiscoverSchoolEvents = ({ authUser }) => {
  track({
    name: ANALYTICS_RECRUITER.CLICKED_DISCOVER_SCHOOL_EVENTS,
    user: authUser,
  })

  trackGtmEvent('seekalendar_contact', {
    'page_category': 'home',
    'page_subject': 'home_page',
    'user_id_back': authUser._id,
    'role': authUser.currentRole?._role?.key,
    'company': authUser._currentOrganization?.name,
    'isClient': false,
  });
};


const trackRecruiterClickedDiscoverOurOffers = ({ authUser }) => {
  track({
    name: ANALYTICS_RECRUITER.CLICKED_DISCOVER_OUR_OFFERS,
    user: authUser,
  })

  trackGtmEvent('seekalendar_contact', {
    'page_category': 'home',
    'page_subject': 'home_page',
    'user_id_back': authUser._id,
    'role': authUser.currentRole?._role?.key,
    'company': authUser._currentOrganization?.name,
    'isClient': false,
  });
};


const trackRecruiterDownloadedSeekubeCalendar = ({ authUser }) => {
  track({
    name: ANALYTICS_RECRUITER.DOWNLOADED_SEEKUBE_CALENDAR,
    user: authUser,
  })

  trackGtmEvent('seekalendar_download', {
    'page_category': 'home',
    'page_subject': 'home_page',
    'user_id_back': authUser._id,
    'role': authUser.currentRole?._role?.key,
    'company': authUser._currentOrganization.name,
    'isClient': true,
  });
};

const trackRecruiterDownloadedAppointmentPlanning = ({ authUser, event, owner }) => (
  track({
    name: ANALYTICS_RECRUITER.DOWNLOADED_APPOINTMENT_PLANNING,
    user: authUser,
    event,
    properties: {
      owner,
    },
  })
);

export {
  trackRegistration,
  trackSignin,
  trackSignout,
  trackRecruiterSentNegativeMail,
  trackRecruiterClickedNylasConnect,
  trackRecruiterClickedNylasDisconnect,
  trackRecruiterToggleSyncSlots,
  trackRecruiterToggleSyncAppointments,
  trackRecruiterClickedLinkToSync,
  trackRecruiterEditedSyncCalendar,
  trackRecruiterQuitCreatingSlots,
  trackRecruiterClickedQuitCreatingSlots,
  trackRecruiterDownloadedSeveralCvs,
  trackRecruiterClickedLearnPlan,
  trackRecruiterClickedInfoPlan,
  trackRecruiterClickedSendRequestPlan,
  trackRecruiterClickedViewAllAppointments,
  trackOwnerExportedLiveData,
  trackRecruiterClickedViewKeyDates,
  trackRecruiterDeleteTemplate,
  trackRecruiterCreatedNewTemplate,
  trackRecruiterUpdatedTemplate,
  trackRecruiterDraftOffer,
  trackRecruiterClickedAccessSchoolEvents,
  trackRecruiterClickedDiscoverSchoolEvents,
  trackRecruiterDownloadedSeekubeCalendar,
  trackRecruiterClickedDiscoverOurOffers,
  trackRecruiterDownloadedAppointmentPlanning,
};
