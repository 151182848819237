import { defineMessages } from 'react-intl';

export default defineMessages({
  inputFileBtnMultiple: {
    id: 'inputFile.btn.file.multiple',
  },
  inputFileBtnSingle: {
    id: 'inputFile.btn.file.single',
  },
  inputMaterialAlertMessage: {
    id: 'inputMaterial.alert.message',
  },
  inputOrganizationsSelectCompanyMultiple: {
    id: 'inputMaterial.select.company.multiple',
  },
  inputOrganizationsSelectCompanyCount: {
    id: 'inputMaterial.select.company.count',
  },
  inputUserPlaceholder: {
    id: 'inputUser.placeholder',
  },
  add: {
    id: 'add',
  },
});
