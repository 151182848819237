import React, { useState } from 'react';
import { array, func, string } from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { head, get, find, map, isEmpty } from 'lodash';
import { Form } from 'antd';
import { Button } from '@seekube-tech/ui-kit';
import { toJS } from '@/utils';
import { getId } from '@/utils/global';

import { exponentSelectors } from '@/store/exponent';

import { Events } from '../../../../../EditOffer/components/EditOfferStep2/components';

import styles from './styles.less';
import messages from '../messages';

const EditLivetalkStep3 =
  ({ handleNext, exponents, eventId }) => {
    const [eventSelected, setEventSelected] = useState(getId(eventId));

    const handleEventsSelected = (eventId) => {
      setEventSelected(head(eventId));
    };

    const getEventName = () => get(find(exponents, (exponent) => getId(exponent._event) === eventSelected), '_event.name');

    return (
      <div className={styles.editLivetalkStep2}>
        <FormattedMessage {...messages.eventsTitle} tagName="h1" />
        <FormattedMessage {...messages.eventsSubtitle} tagName="p" />
        <div />
        <Events
          exponents={exponents}
          events={map(exponents, (exponent) => exponent._event)}
          context="live"
          defaultValue={[eventSelected]}
          handleEventsSelected={handleEventsSelected}
          multi={false}
        />
        <div className={styles.actions}>
          <Button disabled={isEmpty(eventSelected)} onClick={() => handleNext({ _event: eventSelected }, getEventName())}>
            <FormattedMessage {...messages.next} />
          </Button>
        </div>
      </div>
    );
  };

EditLivetalkStep3.propTypes = {
  handleNext: func,
  exponents: array,
  eventId: string,
};



export default toJS(EditLivetalkStep3);
