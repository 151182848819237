import { defineMessages } from 'react-intl';

export default defineMessages({
  appointmentStatus: {
    id: 'appointment.status',
  },
  applyTo: {
    id: 'searchEngine.applyTo',
  },
  sourcingFor: {
    id: 'searchEngine.sourcingFor',
  },
  profiles: {
    id: 'event.content.profils.label',
  },
  scores: {
    id: 'scores',
  },
  reset: {
    id: 'reset',
  },
  acceptedByCandidate: {
    id: 'accepted.by.candidate',
  },
  refusedByCandidate: {
    id: 'refused.by.candidate',
  },
  pending: {
    id: 'pending',
  },
  accepted: {
    id: 'accepted',
  },
  profileAccepted: {
    id: 'profile.accepted',
  },
  profileHideOrRefused: {
    id: 'profile.hide.or.refused',
  },
  moreFilters: {
    id: 'more.filters',
  },
  feedbackScore0: {
    id: 'recruiter.feedback.score[0]',
  },
  feedbackScore1: {
    id: 'recruiter.feedback.score[1]',
  },
  feedbackScore2: {
    id: 'recruiter.feedback.score[2]',
  },
  filters: {
    id: 'filters',
  },
  myOffers: {
    id: 'offers.mine',
  },
  teamOffers: {
    id: 'offers.team',
  },
  availableToday: {
    id: 'available.today',
  },
  month: {
    id: 'month',
  },
  schools: {
    id: 'schools',
  },
  search: {
    id: 'search',
  },
  appointmentAdminStatus: {
    id: 'appointment.admin.status',
  },
  adminStatus: {
    id: 'admin.status',
  },
  adminStatusSave: {
    id: 'admin.statusSave',
  },
  adminStatusTop: {
    id: 'admin.statusTop',
  },
  filtersAdmin: {
    id: 'admin.filters',
  },
  noResult: {
    id: 'noResult',
  },
});
