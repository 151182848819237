import { makeStyles } from '@seekube-tech/ui';

export default makeStyles(theme => ({
  root: {
    textAlign: 'center',
    color: theme.palette.grey[400],
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.common.white,
  },
  categoryIconContainer: {
    marginRight: theme.spacing(1),
  },
  categoryIcon: {
    fill: theme.palette.neutral[300],
    marginRight: theme.spacing(1),
    height: '12px',
    width: '12px',
  },
  categoryTitle: {
    color: theme.palette.neutral[400],

  }
}));
