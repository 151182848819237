import React, { useState } from 'react';
import { object, string, oneOfType } from 'prop-types';
import { isEmpty } from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// Store
import { makeSelectLocation } from '@/containers/App/selectors';
import { authSelectors } from '@/store/auth';
import { toJS } from '@/utils';
import OverlayContainer from '@/components/OverlayContainer';
import Icon from '@/components/Icon';
// Components
import { Menu } from './components'
import Kpi from '../Kpi';

// Styles & Translations

import useStyles from './styles'

const Spotlight = ({ authUser, authUserConnectedAs, className }) => {
  const [spotlightIsVisible, setSpotlightIsVisible] = useState(false);
  const [selectedKey, setSelectedKey] = useState('kpi');
  const classes = useStyles();

  const connectedUser = !isEmpty(authUserConnectedAs) ? authUserConnectedAs : authUser;

  if (isEmpty(authUser) || !connectedUser.isAdmin) {
    return null;
  }

  return (
    <div className={className}>
      <div>
        <a onClick={() => setSpotlightIsVisible(true)} role="button" tabIndex={0}>
          <Icon name="metal" style={{ width: '30px', height: '30px' }} />
        </a>
      </div>
      <OverlayContainer
        visible={spotlightIsVisible}
        onCancel={() => setSpotlightIsVisible(false)}
      >
        <div className={classes.root}>
          <Menu
            selectedKey={selectedKey}
            handleSelectedKey={setSelectedKey}
          />
          <div className={classes.content}>
            {
              selectedKey === 'kpi' && <Kpi isSpotlightVisible={spotlightIsVisible} />
            }
          </div>
        </div>
      </OverlayContainer>
    </div>
  );
};

Spotlight.propTypes = {
  authUser: object,
  className: oneOfType([object, string]),
  authUserConnectedAs: object,
};

const mapStateToProps = createStructuredSelector({
  location: makeSelectLocation(),
  authUserConnectedAs: authSelectors.getAuthUserConnectedAs,
  authUser: authSelectors.getAuthUserNotConnectedAs,
});

const withConnect = connect(mapStateToProps, null);

export default compose(
  withConnect,
)(toJS(Spotlight));
