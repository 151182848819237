import React from 'react';
import { FormattedMessage } from 'react-intl';
import { array, func, object } from 'prop-types';
import { get, isEmpty, map } from 'lodash';

import { useFormatMessage } from 'react-intl-hooks';
import classnames from 'classnames';

import { Switch, Spin, Form, Select as SelectAnt } from 'antd';
import { Alert, Tag } from '@seekube-tech/ui-kit';
import { If } from '@/components/If';
import { trackRecruiterToggleSyncAppointments, trackRecruiterToggleSyncSlots, trackRecruiterEditedSyncCalendar } from '../../../../../utils/analytics';

import syncAgendaStyles from '../styles.less';
import styles from '../../../styles.less';
import Select from '../../../../../components/Form/Select';
import InputMaterial from '../../../../../components/Input/InputMaterial';

import 'moment-timezone';


const PROVIDERS_ICONS = ['eas', 'gmail', 'outlook', 'yahoo'];

const FormItem = Form.Item;
const { Option } = SelectAnt;

function NylasSync({
  authUser,
  form,
  handleOnSubmit,
  getSyncConnectUrl,
  getSyncDisconnectUrl,
  isCalendarsFetching,
  calendars,
  getCalendars
}) {
  const t = useFormatMessage();

  function onCalendarChange(calendarId) {
    const isPrimary = get(calendars.find((calendar) => calendar.id === calendarId), 'is_primary');
    trackRecruiterEditedSyncCalendar({ authUser, isPrimary });
    handleOnSubmit({ calendarId });
  };

  const status = get(authUser, 'syncCalendar.syncState');
  const provider = get(authUser, 'syncCalendar.provider');

  return (
    <>
      <FormItem className={syncAgendaStyles.mb10}>
        <FormattedMessage tagName="h5" id="profile.settings.syncCalendar.connect.title" />
        <div>
          {form.getFieldDecorator('syncCalendar.email', {
            initialValue: get(authUser, 'syncCalendar.email'),
          })(
            <InputMaterial
              addOnIcon={PROVIDERS_ICONS.includes(provider) ? provider : 'mail'}
              label={t({ id: "profile.settings.syncCalendar.connect.input.placeholder" })}
              placeholder={t({ id: "profile.settings.syncCalendar.connect.input.placeholder" })}
              rightContent={
                <Tag variant="fill" className={classnames(syncAgendaStyles.tag, syncAgendaStyles[status === 'running' ? 'running' : 'stopped'])}>
                  <If condition={status === 'running'} otherwise={<FormattedMessage id="profile.settings.syncCalendar.connect.input.tag.disconnected" />}>
                    <FormattedMessage id="profile.settings.syncCalendar.connect.input.tag.connected" />
                  </If>
                </Tag>
              }
              type="mail"
            />
          )}
          <div className={syncAgendaStyles.disconnect}>
            <If condition={status && status !== 'running'} otherwise={<div />}>
              <div className={syncAgendaStyles.expired}>
                <span><FormattedMessage id="profile.settings.syncCalendar.connect.expired.text" /> <a tabIndex={0} role="button" onClick={getSyncConnectUrl}> <FormattedMessage id="event.recruiter.syncCalendar.expired.alert.link" /></a></span>
              </div>
            </If>
            <a tabIndex={0} role="button" onClick={getSyncDisconnectUrl}>
              <FormattedMessage id="disconnection" />
            </a>
          </div>
        </div>
      </FormItem>

      <FormItem className={syncAgendaStyles.mb20}>
        <FormattedMessage tagName="h5" id="profile.settings.syncCalendar.selectCalendar.title" />
        <If condition={status === 'running' && isEmpty(calendars) && isEmpty(get(authUser, 'syncCalendar.calendarId'))}>
          <Alert className={syncAgendaStyles.alertError} color="error">
            <span>
              <FormattedMessage id="profile.settings.syncCalendar.selectCalendar.refresh" />
              <a
                role="button"
                tabIndex={0}
                onClick={getCalendars}><FormattedMessage
                id="profile.settings.syncCalendar.selectCalendar.refresh.link"
              />
              </a>
            </span>
          </Alert>
        </If>
        {form.getFieldDecorator('syncCalendar.calendarId', {
          initialValue: status === 'running' ? get(authUser, 'syncCalendar.calendarId') : null,
          rules: [{ required: true, message: t({ id: "form.error.required" }) }],
        })(
          <Select
            disabled={isEmpty(get(authUser, 'syncCalendar.accessToken'))}
            showSearch
            // eslint-disable-next-line react/jsx-no-bind
            onChange={onCalendarChange}
            addOnIcon={isCalendarsFetching ? <Spin style={{ lineHeight: 0 }} /> : 'calendar'}
            label={t({ id: 'profile.settings.syncCalendar.selectCalendar.chooseCalendar'})}
            placeholder={t({ id: 'profile.settings.syncCalendar.selectCalendar.chooseCalendar' })}
            filterOption={(input, option) => option.props.children && option.props.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {
              map(calendars, (obj, i) => <Option key={i} value={obj.id}>{obj.name}</Option>)
            }
          </Select>
        )}
      </FormItem>
      <FormattedMessage tagName="h5" id="profile.settings.syncCalendar.sync.title" />
      <div className={styles.switchInline}>
        {form.getFieldDecorator('syncCalendar.syncAppointment', {
          initialValue: get(authUser, 'syncCalendar.syncAppointment'),
        })(
          <Switch
            defaultChecked={get(authUser, 'syncCalendar.syncAppointment')}
            onChange={(checked) => { trackRecruiterToggleSyncAppointments({ authUser, checked }); handleOnSubmit({ syncAppointment: checked }); }}
            checkedChildren="ON"
            unCheckedChildren="OFF"
          />
        )}
        <FormattedMessage tagName="p" id="profile.settings.syncCalendar.syncAppointments.label" />
      </div>
      <div className={styles.switchInline}>
        {form.getFieldDecorator('syncCalendar.syncSlot', {
          initialValue: get(authUser, 'syncCalendar.syncSlot'),
        })(
          <Switch
            defaultChecked={get(authUser, 'syncCalendar.syncSlot')}
            onChange={(checked) => { trackRecruiterToggleSyncSlots({ authUser, checked }); handleOnSubmit({ syncSlot: checked }); }}
            checkedChildren="ON"
            unCheckedChildren="OFF"
          />
        )}
        <FormattedMessage tagName="p" id="profile.settings.syncCalendar.syncSlotsLabel.label" />
      </div>
    </>
  )
}

NylasSync.propTypes = {
  authUser: object.isRequired,
  form: object.isRequired,
  handleOnSubmit: func.isRequired,
  getSyncConnectUrl: func.isRequired,
  getSyncDisconnectUrl: func.isRequired,
  isCalendarsFetching: func.isRequired,
  calendars: array.isRequired,
  getCalendars: func.isRequired
}

export default NylasSync;
