import React from 'react';
import { object } from 'prop-types';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

import styles from './styles.less';

const LocationMap = ({ location, containerStyle }) => {
  if (!location?.formattedAdress) {
    return null;
  }

  const center = {
    lat: location.geometry.coordinates[1],
    lng: location.geometry.coordinates[0],
  };

  const marker = { ...center };

  const locationItems = location.formattedAdress.split(', ');
  const street = locationItems[0];
  const city = locationItems[1];

  return (
    <LoadScript
      googleMapsApiKey={process.env.FRONT_GOOGLE_MAPS_API_KEY}
    >
      <GoogleMap
        mapContainerStyle={{ ...containerStyle, position: 'relative' }}
        center={center}
        zoom={14}
        options={{
          fullscreenControl: false,
          mapTypeControl: false,
          streetViewControl: false,
        }}
      >
        <div className={styles.markerInfo}>
          <h3>{street}</h3>
          <h4>{city}</h4>
        </div>
        <Marker
          position={marker}
        />
      </GoogleMap>
    </LoadScript>
  );
};

LocationMap.propTypes = {
  location: object,
  containerStyle: object,
};

export default React.memo(LocationMap);
