import React from 'react';

// Components
import NavScene from './containers/NavScene';

/**
 * SceneHeader
 */

const SceneHeader = () => (
  <NavScene />
);

export default React.memo(SceneHeader);
