import Moment from 'moment';
import { extendMoment } from 'moment-range/dist/moment-range';
const moment = extendMoment(Moment);

/**
 * @description
 * Check if the period can contain the slot
 * We need the duration to check that the slot doesn't exceed the period
 */

export function eventContainsSlot(
  period = { beginAt: null, endAt: null },
  virtualPhase,
  physicalPhase,
  slot,
  slotDuration = 30,
) {
  // beginAt will be used if it's a slot group
  const slotStart = slot.start || slot.beginAt;
  const slotEnd = moment(slotStart).add(slotDuration, 'minutes');
  if (physicalPhase && virtualPhase) {
    return (moment(physicalPhase.beginAt).isSameOrBefore(slotStart) && moment(slotEnd).isSameOrBefore(physicalPhase.endAt)) ||
    (moment(virtualPhase.beginAt).isSameOrBefore(slotStart) && moment(slotEnd).isSameOrBefore(virtualPhase.endAt));
  }
  return moment(period.beginAt).isSameOrBefore(slotStart) && moment(slotEnd).isSameOrBefore(period.endAt);
}
