import * as React from 'react';
import { getKeyMomentFormats } from "@/helpers/exponent/getKeyMomentFormats";
import { EVENT_FORMAT } from "@/utils/constants";
import { arrayContainAll } from "@/utils/math";
import { object } from "prop-types";
import { useFormatMessage } from 'react-intl-hooks';
import moment from 'moment/moment';
import styles from '../../../../../styles.less';

function AdditionalRow({ row, rowProps }) {
  const t = useFormatMessage();
  if (row.original.format === 'hybrid' &&
    arrayContainAll(
      getKeyMomentFormats(row.original._exponent), [EVENT_FORMAT.PHYSICAL, EVENT_FORMAT.VIRTUAL])
  ) {
    const keyMomentJobfairPhysicalDates = row.original.keyMoments.jobfair.find((keyMoment) => keyMoment.format === 'physical');
    const keyMomentJobfairVirtualDates = row.original.keyMoments.jobfair.find((keyMoment) => keyMoment.format === 'virtual');

    return (
      <>
        {[{
          name: <div style={{ fontSize: '12px', padding: '12px 14px' }}>
            {t({ id: "calendar.settingsSlot.participationMode.physical" })} -
            {moment(keyMomentJobfairPhysicalDates.beginAt).format('DD MMMM YYYY')}
            - {moment(keyMomentJobfairPhysicalDates.endAt).format('DD MMMM YYYY')}
          </div>,
          stats: row.original.stats.physical,
          context: 'additionalRow',
        },
        {
          name: <div style={{ fontSize: '12px', padding: '12px 14px' }}>
            {t({ id: "virtual" })} -
            {moment(keyMomentJobfairVirtualDates.beginAt).format('DD MMMM YYYY')}
            - {moment(keyMomentJobfairVirtualDates.endAt).format('DD MMMM YYYY')}
          </div>,
          stats: row.original.stats.virtual,
          context: 'additionalRow',
        },

        ].map((x, i) => (
          <tr
            {...rowProps}
            key={`${rowProps.key}-expanded-${i}`}
          >
            {row.cells.map((cell) => {
              const isExpanderColumn = cell.column.id === "expander";

              return (
                <td
                  className={isExpanderColumn && styles.additionalRowNotVisible}
                  {...cell.getCellProps()}
                >
                  {cell.render('Cell', {
                    value:
                      cell.column.accessor &&
                      cell.column.accessor(x, i),
                    row: { ...row, original: x }
                  })}
                </td>
              );
            })}
          </tr>
        ))}
      </>
    )
  }
  return ''
}

AdditionalRow.propTypes = {
  row: object,
  rowProps: object
}

export default AdditionalRow
