import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { map } from 'lodash';
import { Map, List } from 'immutable';

// Store
import { Button } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { eventActions, eventSelectors } from '@/store/event';
import { criterionSelectors, criterionActions } from '@/store/criterion';
import { jobActions, jobSelectors } from '@/store/job';

import CriteriaTable from '../../components/CriteriaTable';

import SelectDefaultCriteria from '../../containers/SelectDefaultCriteria';

// Styles & Translations
import styles from '../../styles.less';
import parentStyles from '../../../../styles.less';
import messages from '../../messages';


const MatchingCriteriaScene = ({
  event= new Map(),
  eventCriteria = new List(),
  jobs,
  defaultCriteria,
  getJobs,
  getDefaultCriteria,
  deleteCriterion,
  createCriterion,
  patchEvent,
  intl,
}) => {
  const t = useFormatMessage();

  useEffect(() => {
    getDefaultCriteria();
    getJobs({
      page: 1,
      offset: 0,
      limit: 500,
    });
  }, []);


  const handleOnSubmit = (criteria = {}, callback) => {
    patchEvent({
      eventId: event.get('slug'),
      event: { _criteria: criteria.toJS() },
      notificationParams: {
        success: {
          message: t({ id: 'notifications.update.success' }),
          kind: 'info',
          style: {
            bottom: '5%',
            top: 'inherit',
          },
        },
      },
      callback,
    });
  };


  const handleOnDeleteCriterion = (criterionId) => {
    deleteCriterion({
      eventId: event.get('_id'),
      criterionId,
      notificationParams: {
        success: {
          message: t({ id: 'notifications.update.success' }),
          kind: 'info',
          style: {
            bottom: '5%',
            top: 'inherit',
          },
        },
      },
      callback: () => getDefaultCriteria(),
    });
  };


  const handleCreateNewCriterion = () => {
    createCriterion(event.get('_id'));
  };

  const handleNewDefaultCriteriaSelected = (defaultCriteriaSelected = []) => {
    let criteriaSize = eventCriteria.size;

    // Create new List with current criteria and default criteria added
    const newCriteria = eventCriteria.concat(defaultCriteriaSelected.map((criterion) => {
      criteriaSize = criteriaSize + 1; // eslint-disable-line

      return criterion
      .setIn(['modules', 'searchEngineParticipant', 'position'], criteriaSize) // Set position based on current list
        .setIn(['modules', 'searchEngineExponent', 'position'], criteriaSize) // Set position based on current list
        .setIn(['modules', 'offer', 'position'], criteriaSize) // Set position based on current list
        .setIn(['modules', 'onboarding', 'position'], criteriaSize) // Set position based on current list
        .set('_choices', criterion.get('_choices'));
    }));

    // Send to the parent the new list of criteria
    handleOnSubmit(newCriteria);
  };

  return (
    <div className={parentStyles.formWrapperSmallContainer}>
      <h1 className={styles.title}><FormattedMessage {...messages.matchingDashboardTitle} /></h1>
      <div className={styles.helper}>
        <span role="img" aria-labelledby="info">ℹ️</span> <FormattedMessage id="event.matching.dashboard.help.1" />
      </div>
      <div className={styles.helper}>
        <span role="img" aria-labelledby="warning">⚠️️</span> <FormattedMessage id="event.matching.dashboard.help.2" />
      </div>

      <CriteriaTable
        jobs={jobs}
        criteria={eventCriteria}
        defaultCriterionKeys={map(defaultCriteria.toJS(), (defaultCriterion) => defaultCriterion.key)}
        onSubmit={handleOnSubmit}
        onDeleteCriterion={handleOnDeleteCriterion}
      />

      <div className={styles.actions}>
        <SelectDefaultCriteria
          eventCriteria={eventCriteria}
          className={styles.addNewCriterionBtn}
          criteriaSelected={handleNewDefaultCriteriaSelected}
        />
        <Button
          className={styles.defaultMarginLeft}
          onClick={handleCreateNewCriterion}
        >
          {t({ id: 'event.owner.settings.criteria.create' })}
        </Button>
      </div>
    </div>
  );
}


MatchingCriteriaScene.propTypes = {
  event: PropTypes.object,
  eventCriteria: PropTypes.object,
  jobs: PropTypes.array,
  defaultCriteria: PropTypes.array,
  getJobs: PropTypes.func,
  patchEvent: PropTypes.func,
  getDefaultCriteria: PropTypes.func,
  deleteCriterion: PropTypes.func,
  createCriterion: PropTypes.func,
  intl: PropTypes.object
};


const mapStateToProps = createStructuredSelector({
  event: eventSelectors.getCurrentEvent,
  jobs: jobSelectors.getJobs,
  defaultCriteria: criterionSelectors.getDefaultCriteria,
  eventCriteria: eventSelectors.getCurrentEventCriteria,
});


const mapDispatchToProps = {
  getDefaultCriteria: criterionActions.getDefaultCriteria,
  getJobs: jobActions.getJobs,
  patchEvent: eventActions.patchEvent,
  deleteCriterion: criterionActions.deleteCriterion,
  createCriterion: criterionActions.postCriterion,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(MatchingCriteriaScene);
