const urlRegex = /^(https|http):\/\/[^\s$.?#].[^\s]*$/;

/**
 * Check if url is valid
 * @description return false when value is not undefined and url is not valid
 * @param {string} value - String url
 * @returns {boolean}
 */
export default function isUrl(value) {
  return Boolean(value) === false || urlRegex.test(value);
}
