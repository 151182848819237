import React, { useCallback, useEffect, useState } from 'react';
import { object, func, oneOf, string, array, bool } from 'prop-types';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { IconUserPlus, Tag, Button} from '@seekube-tech/ui-kit';
import { Link } from 'react-router-dom';
import { useFormatMessage } from 'react-intl-hooks';
import { isEmpty, isEqual } from 'lodash';
import classnames from 'classnames';
import { Popover, Modal, Menu, Dropdown } from 'antd';
import classNames from 'classnames';

import { IconEdit2, IconUserShare } from '@seekube-tech/ui-kit';
import { usePrevious } from '@/utils/hooks';

// Components
import Icon from '@/components/Icon';
import Separator from '@/components/Separator';
import { Tooltip } from '@/components/Tooltip';
import Checkbox from '@/components/Checkbox';
import { getLabel } from '@/components/Corners/utils/label';
import { isRecruiterLimitExceeded } from '@/utils/pipeDrive';

// Styles & Translations
import { useUserAclHandler } from '@/store/role/helpers';
import { ACL } from '@/utils/constants';
import { authSelectors } from '@/store/auth';
import { useSelector } from 'react-redux';
import messages from '../../messages';
import styles from './styles.less';

const { Item: MenuItem } = Menu;

// Icons

function ActionColumn({
  removeExponent,
  handleExponentActions,
  record,
  context,
  containerClass,
  previewUrl,
  corners,
  onToggleCorner,
  partner,
  locale,
  onTogglePartner,
  isHybrid,
}) {
  /* Vars */

  const t = useFormatMessage();
  const authUser = useSelector(authSelectors.getAuthUser)?.toJS();
  const [confirmExponentModalIsVisible, setConfirmExponentModalIsVisible] = useState(false);
  const [cornerIds, setCornerIds] = useState(record.corners);
  const prevCornerIds = usePrevious(cornerIds);
  const [isVisible, setIsVisible] = useState(false);
  const [isEditCorner, setIsEditCorner] = useState(false);
  const isPlannerContext = ['owner_planners'].includes(context);
  const isOwnerCompaniesContext = ['owner_companies'].includes(context);

  const { checkAcl } = useUserAclHandler(authUser);

  /* Effects */

  useEffect(() => {
    setCornerIds(record.corners);
  }, [record.corners]);

  /* Functions */

  /**
   * Return a boolean when can add collaborator
   * @returns {Boolean}
   */
  const canAddCollaborator = () => {
    const { collaboratorsLength, tool } = record;

    return !isRecruiterLimitExceeded(tool, collaboratorsLength + 1);
  };

  /**
   * Associate or unassociate corner in exponents
   * @param {Event} e
   */
  const handleOnToggleCorner = useCallback((e) => {
    const { value, checked } = e.target;
    let updateCornerIds = [...cornerIds];

    if (checked && cornerIds.indexOf(value) === -1) {
      updateCornerIds = [...updateCornerIds, value];
    } else if (checked === false) {
      updateCornerIds = updateCornerIds.filter((id) => id !== value);
    }

    setCornerIds(updateCornerIds);
  }, [cornerIds]);

  /**
   * Update data when user go out menu and update edit boolean
   * @param {Boolean} visible
   */
  const handleVisibilityCorner = (visible) => {
    if (visible === false && isEqual(prevCornerIds, cornerIds) === false) {
      onToggleCorner(record, cornerIds);
    }

    setIsEditCorner(visible);
  };

  /**
   * Update visibility parent popover
   * @param {Boolean} visible
   */
  const handleVisibilityChange = (visible) => {
    if (visible) {
      setIsVisible(true);
    } else if (visible === false && isEditCorner === false) {
      setIsVisible(false);
    }
  };

  const handleTogglePartner = () => {
    onTogglePartner(record, record?.tool?.isPartner === false);

    setIsVisible(false);
  };

  return (
    <div className={classnames(styles.actionContainer, containerClass)} role="button" tabIndex={0}>
      <>
        {canAddCollaborator() ? (
          <a className={classNames(styles.buttonIcon,styles.buttonIconUserPlus)} role="button" tabIndex={0} onClick={() => handleExponentActions('onCreateUser', record.tool)}>
            <IconUserPlus size={16} />
          </a>
        ) : (
          <Tooltip
            key={0}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            overlayClassName={styles.popover}
            placement="bottom"
            title={<span><FormattedMessage {...messages.warningMaxUsers} /></span>}
            color="dark"
          >
            <a className={styles.addUserDisabled}><Icon name="createUser" /></a>
          </Tooltip>
        )}
        {context !== 'client' && (!isOwnerCompaniesContext || isPlannerContext) && (
          <a className={classNames(styles.buttonIcon,styles.buttonIconTrash)} role="button" tabIndex={0} onClick={() => setConfirmExponentModalIsVisible(true)}>
            <Icon className={styles.removeIcon} name="trash" />
          </a>
        )}

        { checkAcl(ACL.ATS) && record.tool?._organization?.syncAts?.enable ? (
          <Dropdown
            overlay={
              <Menu>
                <MenuItem key="0">
                  <a role="button" tabIndex={0} onClick={() => handleExponentActions('onEditAtsSource', record.tool)} style={{ display: 'block' }}>
                    <IconUserShare size={16} /> { record.atsSource ? (<>
                    <span>{t({ id: 'ats.source.edit' })}</span>
                    <div style={{ paddingLeft: '28px', paddingTop: '4px' }}>
                      <Tag variant="tonal">{record.atsSource}</Tag>
                    </div>
                  </>) : t({ id: 'ats.source.add' })}
                  </a>
                </MenuItem>
              </Menu>
            }
            trigger={['click']}
            placement="bottomRight"
          >
            <a role="button" tabIndex={0} onClick={() => {}}>
              <Icon
                name="more-vertical"
                style={{ width: '24px', height: '24px', margin: '0' }}
              />
            </a>
          </Dropdown>
        ) : null}
      </>

      {['owner_companies', 'owner_planners'].includes(context) && (
        <Popover
          placement="bottomRight"
          trigger="click"
          visible={isVisible}
          onVisibleChange={handleVisibilityChange}
          content={
            <ul className={styles.menu}>
              {context !== 'owner_planners' && isHybrid && (
                <li className={styles.item}>
                  <a role="button" tabIndex={0} onClick={() => record.actions.openExponentModal(record.key)}>
                    <IconEdit2 />
                    <FormattedMessage {...messages.modifyParticipationMode} />
                  </a>

                </li>
              )}

              {partner?.enable && (
                <li className={styles.item}>
                  <a role="button" tabIndex={0} onClick={handleTogglePartner}>
                    <Icon className={styles.partnerIcon} name="partners" />

                    <FormattedMessage
                      id={record?.tool?.isPartner ?
                        'event.owner.exponents.dropdown.partner.disable' :
                        'event.owner.exponents.dropdown.partner.enable'
                      }
                      values={{ label: locale === 'fr' ? partner?.label_fr : partner?.label_en }}
                    />
                  </a>
                </li>
              )}
              {isEmpty(corners) === false && (
                <Popover
                  placement="right"
                  onVisibleChange={handleVisibilityCorner}
                  content={
                    <ul className={styles.subMenu}>
                      {cornerIds && corners.map((corner) => (
                        <li className={styles.subItem} key={corner._id}>
                          <Checkbox
                            value={corner._id}
                            checked={cornerIds.indexOf(corner._id) > -1}
                            onChange={handleOnToggleCorner}
                          >
                            {getLabel(corner)}
                          </Checkbox>
                        </li>
                      ))}
                    </ul>
                  }
                >
                  <li className={classnames(styles.item, styles.itemCorners)}>
                    <div>
                      <Icon name="link" />

                      {t({ id: 'event.owner.exponents.dropdown.company.corner' })}
                    </div>

                    <Icon name="arrow-right" />
                  </li>
                </Popover>
              )}
              {
                (
                  (context !== 'owner_planners' && isHybrid) ||
                  partner?.enable ||
                  isEmpty(corners) === false
                ) && <hr />
              }

              {context !== 'owner_planners' && previewUrl && (
                <li className={styles.item}>
                  <Link target="_blank" to={previewUrl}>
                    <Icon className={styles.removeIcon} name="house" />

                    <FormattedMessage {...messages.previewStand} />
                  </Link>
                </li>
              )}
              <li className={styles.item}>
                <a role="button" tabIndex={0} onClick={() => handleExponentActions('openAvatar', record.tool)}>
                  <Icon name="photo" />

                  <FormattedMessage {...messages.modifyLogo} />
                </a>
              </li>

              {isOwnerCompaniesContext && (
                <li className={styles.item}>
                  <Link target="_blank" to={`/admin/organizations/${record.tool._organization._id}/info`}>
                    <Icon className={styles.removeIcon} name="building" />
                    <FormattedMessage {...messages.infoCompany} />
                  </Link>
                </li>
              )}


              { isOwnerCompaniesContext && <hr /> }

              {isOwnerCompaniesContext && (
                <li className={classnames(styles.item, styles.removeIcon)}>
                  <a role="button" tabIndex={0} onClick={() => setConfirmExponentModalIsVisible(true)}>
                    <Icon name="trash" />

                    <span>{t({ id: 'delete' })}</span>
                  </a>
                </li>
              )}
            </ul>
          }
        >
          <a role="button" tabIndex={0}>
            <Icon
              name="more-vertical"
              style={{ width: '24px', height: '24px', margin: '0' }}
            />
          </a>
        </Popover>
      )}

      <Modal
        visible={confirmExponentModalIsVisible}
        footer={false}
        maskClosable
        width={470}
        className="customConfirm"
        onCancel={() => setConfirmExponentModalIsVisible(false)}
      >
        <a role="button" tabIndex={0} className="modal-close" onClick={() => setConfirmExponentModalIsVisible(false)}>
          <Icon name="close" className="modal-close-icon" />
        </a>

        <h4 className="ant-confirm-title">
          <span role="img" aria-label="warning">⚠️ </span><FormattedHTMLMessage {...messages.deleteExponentModalConfirm} />
        </h4>

        <Separator height={15} />

        <div className="flex justify-end">
          <Button
            variant="tonal"
            className="mr-6"
            onClick={() => setConfirmExponentModalIsVisible(false)}
          >
            <FormattedMessage {...messages.cancel} />
          </Button>
          <Button onClick={(exponent) => {
            removeExponent(exponent);
            setConfirmExponentModalIsVisible(false);
          }}>
            <FormattedMessage {...messages.btnConfirm} />
          </Button>
        </div>
      </Modal>
    </div>
  );
}

ActionColumn.propTypes = {
  corners: array,
  previewUrl: string,
  removeExponent: func,
  intl: object,
  containerClass: object,
  handleExponentActions: func,
  onToggleCorner: func,
  record: object,
  context: oneOf(['owner_companies', 'owner_planners', '']),
  partner: object,
  locale: string,
  onTogglePartner: func,
  isHybrid: bool,
};

ActionColumn.defaultProps = {
  record: {},
  context: '',
  partner: null,
  locale: '',
  onTogglePartner: null,
};


export default React.memo(ActionColumn);
