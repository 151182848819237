import React from 'react';
import { FormattedMessage } from 'react-intl';
import { object, number, string } from 'prop-types';
import { isEmpty } from 'lodash';
import { IconBuilding } from '@seekube-tech/ui-kit';
import styles from '@/scenes/Event/containers/Landing/components/Contents/components/GeneralInfos/components/LandingContent/components/Header/components/OrganizationCount/styles.less';

function OrganizationCount({ event, totalExponents, locale }) {
  if (event.type !== 'jpo' && (totalExponents < 2)) {
    return null;
  }

  let label = <FormattedMessage id="event.landing.exponent" values={{ count: totalExponents }} />;

  if (event.modules.organization && !isEmpty(event.modules.organization[`labelMultiple_${locale}`])) {
    label = totalExponents > 1 ? event.modules.organization[`labelMultiple_${locale}`] : event.modules.organization[`label_${locale}`];
  }

  return (
    <div className={styles.eventDateContainer}>
      <IconBuilding className={styles.calendarIcon} />
      {totalExponents} {label}
    </div>
  );
}

OrganizationCount.propTypes = {
  event: object,
  totalExponents: number,
  locale: string
}

export { OrganizationCount }
