import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { Form } from 'antd';
import { H3, Body2, Body1, Button, Alert } from '@seekube-tech/ui-kit';
import colors from '@seekube-tech/ui-kit/dist/colors';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { bool, func, object, string } from 'prop-types';
import { createStructuredSelector } from 'reselect';

import { If } from 'react-if';
import { toJS } from '@/utils';

import { userActions } from '@/store/user';
import { authSelectors } from '@/store/auth';
import { FormattedHTMLMessage } from 'react-intl';
import ToggleInput from './components/toggleInput';

import styles from './styles.less';

const Outlook = ({ authUser, patchUser, onUpdate, title, subtitle, presetValue = false, context = '' }) => {
  const t = useFormatMessage();

  const [options, setOptions] = React.useState({
    provider: 'seekube',
    syncEvent: typeof authUser.syncCalendar?.syncEvent !== 'undefined' ? authUser.syncCalendar?.syncEvent : presetValue,
    syncAppointment: typeof authUser.syncCalendar?.syncAppointment !== 'undefined' ? authUser.syncCalendar?.syncAppointment : presetValue,
    syncSlot: typeof authUser.syncCalendar?.syncSlot !== 'undefined' ? authUser.syncCalendar?.syncSlot : false
  })

  const [isModifying, setIsModifying] = React.useState(false);

  function updateOption({ key, value }) {
    setOptions((state) => ({
      ...state,
      [key]: value
    }))
  }

  function saveSyncOptions() {
    setIsModifying(true);

    patchUser({
      userId: authUser._id,
      userParams: {
        syncCalendar: {
          ...options
        }
      },
      callback: () => {
        if (onUpdate) {
          onUpdate({
            data: {
              ...options
            },
            error: false,
            message: null
          });
        }
        setIsModifying(false)
      },
      notificationParams: {
        success: {
          message: t({ id: 'notifications.update.success' }),
          kind: 'info',
          style: {
            bottom: '5%',
            top: 'inherit',
          },
        },
      }
    })
  }

  return <div className={styles.container}>
    <Form>
      <div className="mb-24">
        <H3 className="mb-20">
          {title || t({ id: 'profile.settings.syncAgenda.title' })}
        </H3>
        <Body2 color={colors.neutral['400']}>
          {subtitle || t({ id: 'profile.settings.syncAgenda.subtitle' })}<br />
          <FormattedHTMLMessage id="profile.settings.syncAgenda.helpcenter.link" />
        </Body2>
      </div>

      <ToggleInput value={options.syncEvent} onChange={value => updateOption({ key: 'syncEvent', value })} title={t({ id: "profile.settings.syncAgenda.keyDates.title" })}>
        <Body1 color={colors.neutral['400']}>{t({ id: 'profile.settings.syncAgenda.keyDates.subtitle' })}</Body1>
        <ul className={styles.list}>
          <li><Body1>{t({ id: 'profile.settings.syncAgenda.keyDates.list[0]' })}</Body1></li>
          <li><Body1>{t({ id: 'profile.settings.syncAgenda.keyDates.list[1]' })}</Body1></li>
        </ul>
      </ToggleInput>

      <ToggleInput value={options.syncSlot} onChange={value => updateOption({ key: 'syncSlot', value })} title={t({ id: "profile.settings.syncAgenda.slots.title" })}>
        <Body1>{t({ id: 'profile.settings.syncAgenda.slots.subtitle' })}</Body1>
        <If condition={options.syncSlot}>
          <Alert className="mt-8" isClosable={false}>
            {t({ id: 'profile.settings.syncAgenda.slots.alert' })}
          </Alert>
        </If>
      </ToggleInput>
      <ToggleInput value={options.syncAppointment} onChange={value => updateOption({ key: 'syncAppointment', value })} title={t({ id: "profile.settings.syncAgenda.meetings.title" })}>
        <Body1>{t({ id: 'profile.settings.syncAgenda.meetings.subtitle' })}</Body1>
      </ToggleInput>
      {
        context === 'settings' ? (
          <div className="flex mt-20">
            <Button color="primary" onClick={() => saveSyncOptions()} loading={isModifying}>
              {t({ id: 'save' })}
            </Button>
          </div>
        ) : (
          <div className="flex justify-end mt-20">
            <Button color="primary" onClick={() => saveSyncOptions()} loading={isModifying}>
              {t({ id: 'complete.registration' })}
            </Button>
          </div>
        )
      }
    </Form>
  </div>
}

Outlook.propTypes = {
  authUser: object,
  patchUser: func,
  onUpdate: func,
  presetValue: bool,
  title: string,
  subtitle: string
}

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser
})

const mapDispatchToProps = {
  patchUser: userActions.patchUser
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect
)(toJS(Outlook));
