import { defineMessages } from 'react-intl';

export default defineMessages({
  titleLabel: {
    id: 'offer.form.title.label',
  },
  titlePlaceholder: {
    id: 'offer.form.title.placeholder',
  },
  titleHelp: {
    id: 'offer.form.title.help',
  },
  titleSubHelp: {
    id: 'offer.form.title.subhelp',
  },
  _userPlaceholder: {
    id: 'offer.form.user.placeholder',
  },
  _userHelp: {
    id: 'offer.form.user.help',
  },
  _userSubHelp: {
    id: 'offer.form.user.subhelp',
  },
  locationsPlaceholder: {
    id: 'offer.form.locations.placeholder',
  },
  locationsHelp: {
    id: 'offer.form.locations.help',
  },
  descriptionLabel: {
    id: 'offer.form.description.label',
  },
  descriptionHelp: {
    id: 'offer.form.description.help',
  },
  profiledescriptionLabel: {
    id: 'offer.form.profileDescription.label',
  },
  profiledescriptionHelp: {
    id: 'offer.form.profileDescription.help',
  },
  contractHelp: {
    id: 'offer.form.contract.help',
  },
  durationHelp: {
    id: 'offer.form.duration.help',
  },
  availabilityTitle: {
    id: 'offer.form.availability.help',
  },
  availabilityHelp: {
    id: 'offer.form.availability.help',
  },
  jobsHelp: {
    id: 'offer.form.jobs.help',
  },
  functionHelp: {
    id: 'offer.form.function.help',
  },
  ownerChangeWarning: {
    id: 'event.recruiter.preparation.offer.form.owner.change.warning',
  },
  infosStandWarning: {
    id: 'event.recruiter.preparation.offer.form.infos.stand.warning',
  },
  optionalPartTitle: {
    id: 'event.recruiter.preparation.offer.form.optional.part.title',
  },
  optional: {
    id: 'event.recruiter.preparation.offer.form.optional.part.optional',
  },
  optionalPartWarning: {
    id: 'event.recruiter.preparation.offer.form.optional.part.warning',
  },
  preview: {
    id: 'preview',
  },
  saveDraft: {
    id: 'save.draft',
  },
  publishOffer: {
    id: 'publish.offer',
  },
  warningMaxOffers: {
    id: 'event.recruiter.preparation.offer.form.warning.max.offers',
  },
});
