import { number, bool } from 'prop-types';
import * as React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { IconBriefcase } from '@seekube-tech/ui-kit';
import styles from '../styles.less';

function WaitingListNumber({ countApplications, skipAppointmentValidation }) {
  return countApplications ?
    <span key="renderWaitingListNumberNumber" className={countApplications > 0 && !skipAppointmentValidation ? styles.highlight : ''}>
      <IconBriefcase size={16} />
      <FormattedHTMLMessage values={{ count: countApplications }} id={skipAppointmentValidation ? 'statsWaitingList' : 'statsApplications'} />
    </span> : null
}

WaitingListNumber.propTypes = {
  countApplications: number,
  skipAppointmentValidation: bool
}

export default WaitingListNumber;
