import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { Form, Radio } from 'antd';
import { func, object } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { Body1 } from '@seekube-tech/ui-kit';

const FormItem = Form.Item;
const RadioGroup = Radio.Group;

export function NotifyJDOverview ({ getFieldDecorator, notifs = {} }) {
  const t = useFormatMessage();

  return (
    <>
      <Body1 className="mb-10 text-neutral-400"><FormattedHTMLMessage id="profile.settings.notifications.new.applications.label" /></Body1>
      <FormItem>
        {getFieldDecorator('recruiter_jd_overview', {
          initialValue: notifs.recruiter_jd_overview,
        })(
          <RadioGroup>
            <Radio value>{t({ id: 'yes' })}</Radio>
            <Radio value={false}>{t({ id: 'no' })}</Radio>
          </RadioGroup>
        )}
      </FormItem>
    </>

  );
}

NotifyJDOverview.propTypes = {
  notifs: object,
  getFieldDecorator: func,
};
