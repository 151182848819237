import { isHybrid } from '@/helpers/event/isHybrid';
import {
  isParticipantFormatVirtualAndPhysical
} from '@/store/availableActions/utils/user/isParticipantFormatVirtualAndPhysical';
import { getEventKeyMoments } from '@/helpers/event/getEventKeyMoments';

export function getEventParticipation (event, participant) {
  // if Event is Hybrid
  if (isHybrid(event)) {
    if (isParticipantFormatVirtualAndPhysical(participant)) return { beginAt: event.keyDates.jobfair.beginAt, endAt: event.keyDates.jobfair.endAt};

    return getEventKeyMoments(event, { type: 'jobfair', format: participant.keyMomentFormats[0] });
  }



  return { beginAt: event.keyDates.jobfair.beginAt, endAt: event.keyDates.jobfair.endAt};
}