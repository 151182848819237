import React from 'react';
import { object, any, func, bool } from 'prop-types';
import { Field } from 'react-final-form';
import { Autocomplete } from '@seekube-tech/ui';
import moment from 'moment';

import useValidations from '@/utils/validations/useValidations';

import Icon from '@/components/Icon';


import { createStructuredSelector } from 'reselect';
import { authSelectors } from '@/store/auth';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { toJS } from '@/utils';
import { LOCALES } from '@/utils/constants';
import styles from '../../../styles.less';
import { getCriterionLabel } from '../utils';

function AvailabilityField({ criterion, endAt, showHelper, authUser, disabled = false, required = true }) {
  /* Vars */

  const { isFilled } = useValidations();

  /* Functions */

  const getOptions = () => {
    const date = moment(endAt).toDate();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);

    return Array.from(Array(12).keys(), (item) => {
      const now = item > 0 ? moment(firstDay).add(item, 'M') : moment(firstDay);

      return {
        index: item,
        value: now.unix(),
        label: now.format('MMMM YYYY'),
      };
    });
  };

  const getOptionLabel = (option) => authUser.locale === LOCALES.en && option.label_en ? option.label_en : option.label

  return (
    <div className={styles.marginTop}>
      <Field
        name="date"
        id="date"
        disabled={disabled}
        label={getCriterionLabel(criterion)}
        icon={<Icon name={criterion.icon} />}
        component={Autocomplete}
        required={required}
        options={getOptions()}
        fullWidth
        getOptionLabel={getOptionLabel}
        getOptionValue={(option) => option.value}
        validate={value => isFilled(value)}
        showHelper={showHelper}
      />
    </div>
  );
}

AvailabilityField.propTypes = {
  criterion: object,
  endAt: any.isRequired,
  showHelper: func,
  authUser: object,
  disabled: bool
};

AvailabilityField.defaultProps = {
  criterion: {},
};

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser
})

const mapDispatchToProps = {};

const withConnect = connect(mapStateToProps, mapDispatchToProps);


export default compose(
  withConnect
)(toJS(AvailabilityField));
