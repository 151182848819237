import React, { PureComponent } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { isEmpty } from 'lodash';
import { injectIntl } from 'react-intl';
import { array, object, func, bool } from 'prop-types';
import { exponentActions, exponentSelectors } from '@/store/exponent';
import { authSelectors } from '@/store/auth';
import LoadingIndicator from '@/components/LoadingIndicator';
import { getId } from '@/utils/global';
import { toJS } from '@/utils';

const defaultParams = {
  withLoader: true,
  withBlank: {
    enable: true,
    action: () => {},
  },
};

export const withExponents = (params) => (WrappedComponent) => {
  const layoutParams = {
    ...defaultParams,
    ...params,
  };
  class RequiredExponents extends PureComponent {
    static propTypes = {
      exponents: array,
      getExponentsByOrganization: func,
      isExponentsFetching: bool,
      authUser: object,
      offer: object,
    };

    constructor(props) {
      super(props);
      props.getExponentsByOrganization({ organizationId: getId(props.authUser._currentOrganization), page: 1, offset: 0, limit: 100, archive: false });
    }

    render() {
      const { props } = this;

      if (this.props.isExponentsFetching) return <LoadingIndicator />;
      const blank = layoutParams.withBlank.enable && isEmpty(props.exponents) ? <h3>{props.authUser.fullName} ne participe a aucun forum</h3> : null;

      return <WrappedComponent {...this.props} blank={blank} />;
    }
  }
  const mapStateToProps = createStructuredSelector({
    exponents: exponentSelectors.getExponents,
    isExponentsFetching: exponentSelectors.getExponentsFetching,
    authUser: authSelectors.getAuthUser,
  });

  const mapDispatchToProps = {
    getExponentsByOrganization: exponentActions.getExponentsByOrganization,
  };

  const withConnect = connect(mapStateToProps, mapDispatchToProps);
  return compose(
    withConnect,
    injectIntl,
  )(toJS(RequiredExponents));
};
