import React from 'react';
import { object, func } from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { compose } from 'redux';
import { get } from 'lodash';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { participantActions, participantSelectors } from '@/store/participant/index';
import { userActions } from '@/store/user/index';
import StaticOffers from '@/scenes/Event/components/StaticOffers';
import { withLayout } from '@/containers/Layout';
import Wrapper from '@/components/Wrapper';
import Icon from '@/components/Icon';
import ParticipantCards from '@/components/ParticipantCards';
import ModalFull from '@/components/ModalFull';
import { injectIntl } from 'react-intl';


class ParticipantScene extends React.PureComponent {
  static propTypes = {
    match: object,
    push: func,
    getParticipant: func,
    patchParticipant: func,
    participant: object,
    patchMe: func,
    intl: object
  };


  componentDidMount() {
    const { getParticipant, match } = this.props;

    getParticipant({
      eventId: match.params.eventSlug,
      participantId: match.params.participantId,
      userId: match.params.participantId,
    });
  }

  onCloseModal = () => {
    const { props: { match, push } } = this;

    push(`/${match.params.eventSlug}/candidate/jobdating/jobs`);
  };


  handleOnSave = (participant) => {
    const { props: { patchParticipant } } = this;

    patchParticipant({
      eventId: participant._event._id,
      participantId: participant._id,
      participantParams: participant,
    });
  };


  handleOnSaveUser = (user) => {
    const { props: { patchMe, participant, patchParticipant } } = this;

    patchMe({
      ...user,
      callback: () => {
        if (participant) {
          patchParticipant({
            eventId: get(participant.toJS(), '_event._id'),
            participantId: get(participant.toJS(), '_id'),
            participantParams: { updatedAt: new Date() },
          });
        }
      },
      notificationParams: {
        success: {
          message: this.props.intl.formatMessage({ id: 'notifications.update.success' }),
          kind: 'info',
          style: {
            bottom: '5%',
            top: 'inherit',
          },
        },
      }
    });
  };


  render() {
    const { props: { participant, match }, handleOnSave, handleOnSaveUser, onCloseModal } = this;

    if (!participant) {
      return '';
    }

    return (
      <Wrapper>
        <StaticOffers />

        <ModalFull
          visible
          style={{ width: '1000px' }}
          footer={null}
          onCancel={onCloseModal}
        >
          <a role="button" tabIndex={0} className="modal-close" onClick={onCloseModal}>
            <Icon name="close" className="modal-close-icon" />
          </a>

          <ParticipantCards
            component="big"
            status="reject"
            participant={participant.toJS()}
            viewBy={participant.toJS()._user}
            onSave={handleOnSave}
            handleSaveUser={handleOnSaveUser}
            eventSlug={match.params.eventSlug}
          />
        </ModalFull>
      </Wrapper>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  participant: participantSelectors.getCurrentParticipant,
});

const mapDispatchToProps = {
  push,
  getParticipant: participantActions.getParticipant,
  patchParticipant: participantActions.patchParticipant,
  patchMe: userActions.patchMe,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withRouter,
  withLayout({ size: 'default', withHeader: false, withBlur: true }),
  withConnect,
  injectIntl
)(ParticipantScene);
