import { makeStyles } from '@seekube-tech/ui';

export default makeStyles(theme => ({
    resultItem: {
      cursor: 'pointer',
    },
    resultIcon: {
      position: 'relative',
      minWidth: '30px',
      marginRight: theme.spacing(1),
    },
    resultInfo: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'flex-end',
      borderBottom: '1px solid rgba(0, 0, 0, 0)',

      '&:hover': {
        borderBottom: '1px solid rgba(255, 255, 255, 0.15)',
      },

      '& em': {
        fontWeight: 700,
        fontStyle: 'normal',
      },
    },
    eventName: {
      marginRight: theme.spacing(1),
      fontWeight: 500,
    },
    plannerAvatar: {
      width: '18px',
      height: '18px',
    },
    avatar: {
      color: theme.palette.basic.white,
    },
    orga: {
      width: '19px',
      height: '19px',
      position: 'absolute',
      bottom: -6,
      right: -1,
      '& > div': {
        borderWidth: '1px',
        borderRadius: '1px',
      }
    },
    username: {
      marginRight: theme.spacing(2),
      display: 'flex',
    },
    fullName: {
      color: theme.palette.neutral['400'],
      display: 'flex',
    },
    borderWidth: {
      '& > div': {
        borderWidth: '1px',
      }
    }
  })
);
