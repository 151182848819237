import React from "react";
import { Item, Checkbox, Tag } from '@seekube-tech/ui-kit';
import { object, string, func, bool } from 'prop-types';

const CustomOption = ({
  opt,
  onSelect,
  selected,
  disabled = false,
  id,
  withCheckbox = false,
  active = false
}) => (
  <Item 
    key={id}
    onClick={() => onSelect?.(opt)}
    endElement={<Tag variant="tonal" color="neutral">{opt.count}</Tag>}
    disabled={disabled}
    active={active && !disabled}
  >
    {withCheckbox ? <Checkbox 
      onChange={() => onSelect(opt)}
      checked={selected}
      id={id}
      name={id}
      label={opt.label}
      disabled={disabled}
    /> : opt.label}
  </Item>
)

CustomOption.propTypes = {
  opt: object,
  id: string,
  onSelect: func,
  selected: bool,
  disabled: bool,
  withCheckbox: bool,
  active: bool
}

export { CustomOption };