import { PHASE_STATE, PHASES } from '@/store/availableActions/utils/const';
import { getKeyDatePhases } from '@/store/availableActions/utils/event/getKeyDatePhases';
import { getKeyMomentPhases } from '@/store/availableActions/utils/event/getKeyMomentPhases';

// return table possible serveral phases
export function getEventPhases(event) {
  /**
   * If no event or no keyDates there is no phase :shrug:
   */
  if (!event || (!event.keyDates && !event.keyMoments)) {
    return {
      [PHASES.NONE]: {
        [PHASE_STATE.NONE]: true
      }
    };
  }

  if (event.keyMoments) {
    return getKeyMomentPhases(event);
  }

  return getKeyDatePhases(event);
}
