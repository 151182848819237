import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { Form } from 'antd';
import { InputOrganizationsSuggest } from '@/components/Input';

const FormItem = Form.Item;

export const SchoolName = ({ form, education, order }) => {
  const t = useFormatMessage();
  const initialValue = education?._organization?.name || '';

  return (
    <>
      <FormItem>
        {form.getFieldDecorator(`educations.${order}.school`, {
          initialValue,
          rules: [{ required: true, message: t({ id: 'event.candidate.preparation.signup.userExperience.formation.school.mandatory' }) }],
        })(
          <InputOrganizationsSuggest
            id={`schoolName${order}`}
            initialValue={initialValue}
            label={t({ id: 'event.candidate.preparation.signup.userExperience.formation.school.label' })}
            placeholder={<span>{t({ id: 'event.candidate.preparation.signup.userExperience.formation.school.label' })} <span className="mandatory">*</span></span>}
            addOnIcon="legal"
            validations={['required']}
            createAlert
            params={{ type: 'school', isMerged: false, noMongo: true }}
          />
        )}
      </FormItem>
    </>
  )
}