import React from 'react';
import classnames from 'classnames';
import { Hidden } from '@seekube-tech/ui';
import { H2, H3, Body2, Body1, Subtitle1 } from '@seekube-tech/ui-kit';
import { Clock } from 'react-feather';
import { bool, string } from 'prop-types';
import { Row, Col } from 'antd';

import arrow from '../../../../../../../../assets/images/arrow.svg';

import useStyles from './styles';

function CardDate({ begin, end, active, single }) {
  /* Vars */

  const styles = useStyles();

  /* Render */

  return single ? (
    <div className={classnames(styles.single, { [styles.active]: active })}>
      <Hidden smDown>
        <H2 className={styles.day}>
          {begin.format('D')}
        </H2>
      </Hidden>

      <div className='show-mobile'>
        <H3 align="center">
          {begin.format('D')}
        </H3>
      </div>

      <Subtitle1 fontWeight={700} className={styles.month}>
        {begin.format('MMMM')}
      </Subtitle1>

      <div className={styles.hour}>
        <Clock className={styles.icon} />
        <Body2 variant="body2">
          {`${begin.format('HH')}h${begin.format('mm')}`}
        </Body2>
      </div>
    </div>
  ) : (
    <Row type="flex" justify="center" align="middle" gutter={24} className={styles.multiple}>
      <Col>
        <Hidden smDown>
          <H2 className={styles.day}>
            {begin.format('D')}
          </H2>
        </Hidden>

        <div className='show-mobile'>
          <H3 className={styles.day}>
            {begin.format('D')}
          </H3>
        </div>

        <Body1 fontWeight={700} className={styles.month}>
          {begin.format('MMMM')}
        </Body1>
      </Col>

      <Col>
        <img src={arrow} alt="arrow" className={styles.arrow} />
      </Col>

      <Col>
        <Hidden smDown>
          <H2 className={styles.day}>
            {end.format('D')}
          </H2>
        </Hidden>

        <div className='show-mobile'>
          <H3 className={styles.day}>
            {end.format('D')}
          </H3>
        </div>

        <Body1 fontWeight={700} className={styles.month}>
          {end.format('MMMM')}
        </Body1>
      </Col>
    </Row>
  );
}

CardDate.propTypes = {
  begin: string.isRequired,
  end: string.isRequired,
  active: bool,
  single: bool,
};

CardDate.defaultProps = {
  active: false,
  single: false,
};

export default CardDate;
