
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { isEmpty } from 'lodash';
import { normalize } from 'normalizr';
import request, { isInternalServerError } from '@/utils/request';
import { objectToParams } from '@/utils/url';
import { getIsUserAuthorizedToAccessEvent } from '@/utils/event';
import * as actions from './actions';
import * as types from './types';
import { entitiesActions } from '../entities';
import { exponentActions } from '../exponent';
import { appointmentActions } from '../appointment';
import { interactionActions } from '../interaction';
import { offerListSchema, offerSchema } from './schema';
import { participantSchema } from '../participant/schema';
import { authSelectors } from '../auth';
import { notificationActions } from '../notification';
import { participantActions } from '../participant';
import { eventSelectors } from '../event';
import { appActions } from '../app';
import { trackError } from '../../utils/analytics/helpers';

function* getEventOffers({ payload: { offset, page, eventSlug } }) {
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventSlug}/offers?limit=50&status=published`;

  try {
    const { docs, limit, total } = yield call(request, requestUrl, {
      method: 'GET',
    });

    const dataNormalized = normalize(docs, offerListSchema);

    // Save entities
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    // Merge allIds
    yield put(actions.getEventOffersSuccess({
      result: dataNormalized.result,
      currentPage: page,
      offset,
      limit,
      total,
    }));
  } catch (err) {
    trackError(err);
  }
}

function* getEventOffersByMatching({ payload: { offset, page, search, matching, eventSlug, facetKey, callback } }) {
  const event = yield select(eventSelectors.getCurrentEvent);
  const slug = eventSlug || event.get('slug');

  const query = { ...search, ...matching, page, facetKey };
  const requestUrl = `${process.env.FRONT_API_URL}/events/${slug}/offers/byMatching?${objectToParams(query)}`;

  try {
    const { docs, limit, total, facets, totalOffers } = yield call(request, requestUrl, {
      method: 'GET',
    });

    const dataNormalized = normalize(docs, offerListSchema);

    // Save entities
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    // Merge allIds
    yield put(actions.getEventOffersByMatchingSuccess({
      result: dataNormalized.result,
      currentPage: page,
      offset,
      total,
      limit,
      facets,
      totalOffers,
    }));

    if (typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    trackError(err);
  }
}

/**
 * GetOrganizationOffers
 *
 * @description
 * Get all offers of the current event & the currentOrganization of the authUser
 */
function* getOrganizationOffers({ payload: { offset, page, eventSlug, organizationId, status, purgeCache = false, selectData = null, callback } }) {
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventSlug}/offers/organization/${organizationId}?limit=300&status=${status}&selectData=${selectData}`;

  try {
    const { docs, limit, total } = yield call(request, requestUrl, {
      method: 'GET',
      headers: {
        KoaPurgeCache: purgeCache,
      },
    });

    const dataNormalized = normalize(docs, offerListSchema);

    // Save entities
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    // Merge allIds
    yield put(actions.getOrganizationOffersSuccess({
      result: dataNormalized.result,
      currentPage: page,
      offset,
      limit,
      total,
    }));
    if (typeof callback === 'function') {
      callback(dataNormalized.result);
    }
  } catch (err) {
    trackError(err);
  }
}

function* getCrossForumOffer({ payload: { eventSlug } }) {
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventSlug}/offers/past/past`;

  try {
    const result = yield call(request, requestUrl, {
      method: 'GET',
    });
    yield put(actions.getCrossForumOfferSuccess({
      offers: result,
    }));
  } catch (err) {
    trackError(err);
  }
}

function* getOrganizationUserOffers({ payload: { recruiterId = null, search = {}, status = 'published', keywords = '', checkValidateFields = [], callback } }) {
  const params = `?offset=${search.offset}&limit=${search.limit}&page=${search.page}&status=${status}&keywords=${keywords}`;
  let additionalParam = '';

  if (search.allUsers === true) {
    additionalParam += '&allUsers=true';
  }

  if (!isEmpty(search.updatedAt)) {
    additionalParam += `&updatedAt=${search.updatedAt}`;
  }

  if (!isEmpty(search.recruiterSelectedIds)) {
    if (typeof search.recruiterSelectedIds === 'string') {
      additionalParam += `&userIds=${search.recruiterSelectedIds}`;
    } else {
      additionalParam += `&userIds=${encodeURIComponent(search.recruiterSelectedIds.join(','))}`;
    }
  }

  if (!isEmpty(search.eventSelectedIds)) {
    if (typeof search.eventSelectedIds === 'string') {
      additionalParam += `&eventIds=${search.eventSelectedIds}`;
    } else {
      additionalParam += `&eventIds=${encodeURIComponent(search.eventSelectedIds.join(','))}`;
    }
  }

  if (!isEmpty(search.contractSelectedIds)) {
    if (typeof search.contractSelectedIds === 'string') {
      additionalParam += `&contractIds=${search.contractSelectedIds}`;
    } else {
      additionalParam += `&contractIds=${encodeURIComponent(search.contractSelectedIds.join(','))}`;
    }
  }

  if (!isEmpty(checkValidateFields)) {
    if (typeof checkValidateFields === 'string') {
      additionalParam += `&checkValidateFields=${checkValidateFields}`;
    } else {
      additionalParam += `&checkValidateFields=${encodeURIComponent(checkValidateFields.join(','))}`;
    }
  }

  const authUser = yield select(authSelectors.getAuthUser);
  const organizationId = authUser.getIn(['_currentOrganization', '_id']);
  const authUserId = authUser.get('_id');
  const requestUrl = `${process.env.FRONT_API_URL}/organizations/${organizationId}/users/${recruiterId || authUserId}/offers${params}${additionalParam}`;

  try {
    const { docs, total, limit, offset, stats } = yield call(request, requestUrl, {
      method: 'GET',
    });
    const dataNormalized = normalize(docs, offerListSchema);

    // Save entities
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));
    yield put(actions.getOrganizationUserOffersSuccess({
      result: dataNormalized.result,
      total,
      limit,
      offset,
      stats,
    }));

    if (typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    trackError(err);
  }
}

/**
 * GetOffer
 *
 * @description
 * Get all offers of the current event & the currentOrganization of the authUser
 */
function* getOffer({ payload: { eventId, offerId } }) {
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/offers/${offerId}`;

  try {
    const result = yield call(request, requestUrl, {
      method: 'GET',
    });

    // Normaliz data
    const dataNormalized = normalize(result, offerSchema);
    yield put(entitiesActions.replaceEntities({ id: offerId, type: 'offers', entities: dataNormalized.entities }));
    yield put(actions.setParticipantSelectedOffer(offerId));
  } catch (err) {
    trackError(err);
  }
}

function* getGlobalOffer({ payload: { offerId } }) {
  const authUser = yield select(authSelectors.getAuthUser);
  const organizationId = authUser.getIn(['_currentOrganization', '_id']);
  const authUserId = authUser.get('_id');
  const requestUrl = `${process.env.FRONT_API_URL}/organizations/${organizationId}/users/${authUserId}/offers/${offerId}`;


  try {
    const result = yield call(request, requestUrl, {
      method: 'GET',
    });

    // Normaliz data
    const dataNormalized = normalize(result, offerSchema);
    yield put(entitiesActions.replaceEntities({ id: offerId, type: 'offers', entities: dataNormalized.entities }));
    yield put(actions.getGlobalOfferSuccess({
      result: dataNormalized.result,
    }));
  } catch (err) {
    trackError(err);
  }
}

/**
 * GetOffer
 *
 * @description
 * Get an offer
 */
function* getParticipantOffers({ payload: { eventId, participantId, limit, offset, page, search, params, facetKey, formIsValid, callback } }) {
  const searchParams = objectToParams(search);
  const authUser = yield select(authSelectors.getAuthUser);

  const limitClean = limit <= 20 ? limit : 20;

  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/participants/jobs?participantId=${participantId}&limit=${limitClean}&offset=${offset && offset > -1 ? offset : 0}&page=${page || 1}&${searchParams}`;

  try {
    const { docs, limit, total, facets, totalOffers, countSlots } = yield call(request, requestUrl, {
      method: 'POST',
      body: JSON.stringify({ ...params, facetKey }),
    });

    let results = docs;

    if (formIsValid && !getIsUserAuthorizedToAccessEvent(eventId, authUser.get('gender'))) {
      results = [];
    }

    // Normaliz data
    const dataNormalized = normalize(results, offerListSchema);

    // Save entities
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    // Merge allIds
    yield put(actions.getParticipantOffersSuccess({
      result: dataNormalized.result,
      currentPage: page,
      withOrganization: search.organization,
      offset,
      limit,
      total,
      facets,
      totalOffers,
      countSlots,
      lastSearch: !isEmpty(params) ? { matchingTmp: params.matchingTmp || {}, search: params.search || {} } : {},
    }));

    if (typeof callback === 'function') {
      callback(facets, dataNormalized.result);
    }
  } catch (err) {
    trackError(err);

    if (err.message && !isInternalServerError(err.code)) {
      yield put(notificationActions.sendNotification({
        message: err.message,
        kind: 'error',
        style: {},
      }));
    }
  }
}

function* postGlobalOffer({ payload: { params, notificationParams, callback } }) {
  const authUser = yield select(authSelectors.getAuthUser);
  const organizationId = authUser.getIn(['_currentOrganization', '_id']);
  const authUserId = authUser.get('_id');
  const requestUrl = `${process.env.FRONT_API_URL}/organizations/${organizationId}/users/${authUserId}/offers`;


  try {
    const result = yield call(request, requestUrl, {
      method: 'POST',
      body: JSON.stringify(params),
    });


    const dataNormalized = normalize(result, offerSchema);

    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    yield put(actions.postOfferSuccess(dataNormalized.result));

    if (typeof callback === 'function') {
      callback(result);
    }

    if (notificationParams && notificationParams.success) {
      yield put(notificationActions.sendNotification({
        message: notificationParams.success.message,
        kind: notificationParams.success.kind,
        style: notificationParams.success.style,
      }));
    }
  } catch (err) {
    trackError(err);

    yield put(notificationActions.sendNotification({
      message: err.message,
      kind: 'error',
      style: {},
    }));
  }
}
function* postOffer({ payload: { eventId, offerParams, notificationParams, callback } }) {
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/offers`;

  // Post the offer
  try {
    const result = yield call(request, requestUrl, {
      method: 'POST',
      body: JSON.stringify(offerParams),
    });

    // Normaliz data
    const dataNormalized = normalize(result, offerSchema);

    // Save entities
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    // Put id in allIds
    yield put(actions.postOfferSuccess(dataNormalized.result));

    if (typeof callback === 'function') {
      callback();
    }

    // Push success notification
    if (notificationParams && notificationParams.success) {
      yield put(notificationActions.sendNotification({
        message: notificationParams.success.message,
        kind: notificationParams.success.kind,
        style: notificationParams.success.style,
      }));
    }
  } catch (err) {
    trackError(err);

    yield put(notificationActions.sendNotification({
      message: err.message,
      kind: 'error',
      style: {},
    }));
  }
}

/**
 * PatchOffer
 *
 * @description
 * Patch offer
 */
function* patchOffer({ payload: { offerId, _event, offerParams, notificationParams, callback } }) {
  const requestUrl = `${process.env.FRONT_API_URL}/events/${_event}/offers/${offerId}`;
  const authUser = yield select(authSelectors.getAuthUser);

  const organizationId = authUser.get('_currentOrganization').get('_id');

  // Patch the offer
  try {
    const result = yield call(request, requestUrl, {
      method: 'PATCH',
      body: JSON.stringify(offerParams),
    });

    // Normaliz data
    const dataNormalized = normalize(result, offerSchema);

    // Save entities
    yield put(entitiesActions.replaceEntities({ id: offerId, type: 'offers', entities: dataNormalized.entities }));

    yield put(actions.getOrganizationOffers({
      offset: 0,
      eventSlug: _event,
      organizationId,
      purgeCache: true,
    }));

    if (typeof callback === 'function') {
      callback();
    }

    // Push success notification
    if (notificationParams && notificationParams.success) {
      yield put(notificationActions.sendNotification({
        message: notificationParams.success.message,
        kind: notificationParams.success.kind,
        style: notificationParams.success.style,
      }));
    }
  } catch (err) {
    trackError(err);

    yield put(notificationActions.sendNotification({
      message: err.message,
      kind: 'error',
      style: {},
    }));
  }
}

function* patchGlobalOfferByEvents({ payload: { offerId, params, notificationParams, callback } }) {
  const authUser = yield select(authSelectors.getAuthUser);
  const organizationId = authUser.getIn(['_currentOrganization', '_id']);
  const authUserId = authUser.get('_id');
  const requestUrl = `${process.env.FRONT_API_URL}/organizations/${organizationId}/users/${authUserId}/offers/${offerId}/batch-by-events`;

  // Patch the offer
  try {
    const result = yield call(request, requestUrl, {
      method: 'PATCH',
      body: JSON.stringify(params),
    });

    // Normaliz data
    normalize(result, offerSchema);


    if (typeof callback === 'function') {
      callback();
    }

    // Push success notification
    if (notificationParams && notificationParams.success) {
      yield put(notificationActions.sendNotification({
        message: notificationParams.success.message,
        kind: notificationParams.success.kind,
        style: notificationParams.success.style,
      }));
    }
  } catch (err) {
    trackError(err);

    yield put(notificationActions.sendNotification({
      message: err.message,
      kind: 'error',
      style: {},
    }));
  }
}

function* patchGlobalOffer({ payload: { offerId, params, notificationParams, callback } }) {
  const authUser = yield select(authSelectors.getAuthUser);
  const organizationId = authUser.getIn(['_currentOrganization', '_id']);
  const authUserId = authUser.get('_id');
  const requestUrl = `${process.env.FRONT_API_URL}/organizations/${organizationId}/users/${authUserId}/offers/${offerId}`;

  // Patch the offer
  try {
    yield call(request, requestUrl, {
      method: 'PATCH',
      body: JSON.stringify(params),
    });

    // Normaliz data

    // Save entities
    yield put(actions.deleteGlobalOfferSuccess({
      offerId,
    }));
    if (typeof callback === 'function') {
      callback();
    }

    // Push success notification
    if (notificationParams && notificationParams.success) {
      yield put(notificationActions.sendNotification({
        message: notificationParams.success.message,
        kind: notificationParams.success.kind,
        style: notificationParams.success.style,
      }));
    }
  } catch (err) {
    trackError(err);

    yield put(notificationActions.sendNotification({
      message: err.message,
      kind: 'error',
      style: {},
    }));
  }
}

function* patchDraftOffers({ payload: { offersId, search = '', batchAll = false, notificationParams, callback } }) {
  const authUser = yield select(authSelectors.getAuthUser);
  const organizationId = authUser.getIn(['_currentOrganization', '_id']);
  const authUserId = authUser.get('_id');
  const requestUrl = `${process.env.FRONT_API_URL}/organizations/${organizationId}/users/${authUserId}/offers/batch-draft${search}`;


  try {
    yield call(request, requestUrl, {
      method: 'PATCH',
      body: JSON.stringify({ parentIds: offersId, batchAll }),
    });

    // Normaliz data

    // Save entities
    if (typeof callback === 'function') {
      callback();
    }

    // Push success notification
    if (notificationParams && notificationParams.success) {
      yield put(notificationActions.sendNotification({
        message: notificationParams.success.message,
        kind: notificationParams.success.kind,
        style: notificationParams.success.style,
      }));
    }
  } catch (err) {
    trackError(err);

    yield put(notificationActions.sendNotification({
      message: err.message,
      kind: 'error',
      style: {},
    }));
  }
}

function* postPublishOffers({ payload: { offers = [], eventId = null, notificationParams, callback } }) {
  const authUser = yield select(authSelectors.getAuthUser);
  const organizationId = authUser.getIn(['_currentOrganization', '_id']);
  const authUserId = authUser.get('_id');
  const requestUrl = `${process.env.FRONT_API_URL}/organizations/${organizationId}/users/${authUserId}/offers/batch-publish`;


  try {
    yield call(request, requestUrl, {
      method: 'POST',
      body: JSON.stringify({ offers, eventId }),
    });

    // Normaliz data

    // Save entities
    if (typeof callback === 'function') {
      callback();
    }

    // Push success notification
    if (notificationParams && notificationParams.success) {
      yield put(notificationActions.sendNotification({
        message: notificationParams.success.message,
        kind: notificationParams.success.kind,
        style: notificationParams.success.style,
      }));
    }
  } catch (err) {
    trackError(err);

    yield put(notificationActions.sendNotification({
      message: err.message,
      kind: 'error',
      style: {},
    }));
  }
}


function* deleteOffers({ payload: { offersId, search = '', batchAll = false, notificationParams, callback } }) {
  const authUser = yield select(authSelectors.getAuthUser);
  const organizationId = authUser.getIn(['_currentOrganization', '_id']);
  const authUserId = authUser.get('_id');

  const requestUrl = `${process.env.FRONT_API_URL}/organizations/${organizationId}/users/${authUserId}/offers${search}`;


  try {
    yield call(request, requestUrl, {
      method: 'DELETE',
      body: JSON.stringify({ parentIds: offersId, batchAll }),
    });

    // Normaliz data

    // Save entities
    if (typeof callback === 'function') {
      callback();
    }

    // Push success notification
    if (notificationParams && notificationParams.success) {
      yield put(notificationActions.sendNotification({
        message: notificationParams.success.message,
        kind: notificationParams.success.kind,
        style: notificationParams.success.style,
      }));
    }
  } catch (err) {
    trackError(err);

    yield put(notificationActions.sendNotification({
      message: err.message,
      kind: 'error',
      style: {},
    }));
  }
}

/**
 * ApplyOffer
 *
 * @description
 * Apply offer
 */
function* applyOffer({ payload: { offerId, eventId, participantId, notificationParams, callback } }) {
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/offers/${offerId}/apply`;
  const authUser = yield select(authSelectors.getAuthUser);

  // Apply the offer
  try {
    const result = yield call(request, requestUrl, {
      method: 'POST',
      body: '',
    });

    // Normaliz data
    const dataNormalized = normalize(result, participantSchema);

    // Save entities
    yield put(entitiesActions.replaceEntities({ id: result._id, type: 'participants', entities: dataNormalized.entities }));
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));
    yield put(interactionActions.getInteractions({ eventSlug: eventId, body: { users: [authUser.get('_id')] } }));

    if (participantId) {
      yield put(participantActions.patchParticipant({ eventId, participantId, participantParams: { isActive: true } }));
    }

    if (typeof callback === 'function') {
      callback();
    }

    // Push success notification
    if (notificationParams && notificationParams.success) {
      yield put(notificationActions.sendNotification({
        message: notificationParams.success.message,
        kind: notificationParams.success.kind,
        style: notificationParams.success.style,
      }));
    }
  } catch (err) {
    trackError(err);

    yield put(notificationActions.sendNotification({
      message: err.message,
      kind: 'error',
      style: {},
    }));
  }
}

function* applyOfferWithoutValidation({ payload: { appointmentId, offerId, eventId, notificationParams, callback } }) {
  if (appointmentId) return yield put(push(`/${eventId}/candidate/jobdating/appointment/${appointmentId}`));
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/offers/${offerId}/applyWithoutValidation`;

  // Apply the offer
  try {
    const result = yield call(request, requestUrl, {
      method: 'POST',
      body: '',
    });

    // Normaliz data
    const dataNormalized = normalize(result.participant, participantSchema);

    // Save entities
    yield put(entitiesActions.replaceEntities({ id: result._id, type: 'participants', entities: dataNormalized.entities }));
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    if (result.appointmentId) {
      yield put(appointmentActions.getAppointment({
        appointmentID: result.appointmentId,
        eventSlug: eventId,
        redirect: true,
      }));
    }

    if (typeof callback === 'function') {
      callback();
    }

    // Push success notification
    if (notificationParams && notificationParams.success) {
      yield put(notificationActions.sendNotification({
        message: notificationParams.success.message,
        kind: notificationParams.success.kind,
        style: notificationParams.success.style,
      }));
    }
  } catch (err) {
    trackError(err);

    yield put(notificationActions.sendNotification({
      message: err.message,
      kind: 'error',
      style: {},
    }));
  }

  return appointmentId;
}


/**
 * DuplicateOffer
 *
 * @description
 * Create new offer
 */
function* duplicateOffer({ payload: { offerId, eventId, notificationParams } }) {
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/offers/${offerId}/duplicate`;

  // Post the offer
  try {
    const result = yield call(request, requestUrl, {
      method: 'POST',
      body: '',
    });

    // Normaliz data
    const dataNormalized = normalize(result, offerSchema);

    // Save entities
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    // Put id in allIds
    yield put(actions.postOfferSuccess(dataNormalized.result));

    // Push success notification
    if (notificationParams && notificationParams.success) {
      yield put(notificationActions.sendNotification({
        message: notificationParams.success.message,
        kind: notificationParams.success.kind,
        style: notificationParams.success.style,
      }));
    }
  } catch (err) {
    trackError(err);

    yield put(notificationActions.sendNotification({
      message: err.message,
      kind: 'error',
      style: {},
    }));
  }
}

function* duplicateGlobalOffer({ payload: { offerId, organizationId, notificationParams, callback } }) {
  const authUser = yield select(authSelectors.getAuthUser);
  const requestUrl = `${process.env.FRONT_API_URL}/organizations/${organizationId}/users/${authUser.get('_id')}/offers/${offerId}/duplicate`;

  // Post the offer
  try {
    const result = yield call(request, requestUrl, {
      method: 'POST',
      body: '',
    });

    // Normaliz data
    const dataNormalized = normalize(result, offerSchema);

    // Save entities
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    // Put id in allIds
    yield put(actions.postOfferSuccess(dataNormalized.result));

    if (typeof callback === 'function') {
      callback(result);
    }

    // Push success notification
    if (notificationParams && notificationParams.success) {
      yield put(notificationActions.sendNotification({
        message: notificationParams.success.message,
        kind: notificationParams.success.kind,
        style: notificationParams.success.style,
      }));
    }
  } catch (err) {
    trackError(err);

    yield put(notificationActions.sendNotification({
      message: err.message,
      kind: 'error',
      style: {},
    }));
  }
}

/**
 * DuplicateOffer
 *
 * @description
 * Create new offer
 */
function* duplicateCrossOffers({ payload: { offers, eventId, notificationParams, callback } }) {
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/offers/duplicateCrossOffers`;

  // Post the offer
  try {
    const result = yield call(request, requestUrl, {
      method: 'POST',
      body: JSON.stringify({ offers }),
    });

    // Normaliz data
    const dataNormalized = normalize(result, offerListSchema);

    // Save entitiesle.log(dataNormalize
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    // Put id in allIds
    yield put(actions.postOffersSuccess(dataNormalized.result));

    if (typeof callback === 'function') {
      callback();
    }

    if (notificationParams && notificationParams.success) {
      yield put(notificationActions.sendNotification({
        message: notificationParams.success.message,
        kind: notificationParams.success.kind,
        style: notificationParams.success.style,
      }));
    }
  } catch (err) {
    trackError(err);
  }
}
/**
 * DeleteOffer
 *
 * @description
 * Delete offer
 */
function* deleteOffer({ payload: { offerId, eventId, organizationId, notificationParams, callback } }) {
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/offers/${offerId}`;

  // Delete the offer
  try {
    yield call(request, requestUrl, {
      method: 'DELETE',
    });

    // Update entities
    yield put(actions.getOrganizationOffers({
      offset: 0,
      page: 1,
      eventSlug: eventId,
      organizationId,
    }));

    yield put(exponentActions.getExponentByOrganization({ eventId, organizationId }));

    if (typeof callback === 'function') {
      callback();
    }

    // Push success notification
    if (notificationParams && notificationParams.success) {
      yield put(notificationActions.sendNotification({
        message: notificationParams.success.message,
        kind: notificationParams.success.kind,
        style: notificationParams.success.style,
      }));
    }
  } catch (err) {
    trackError(err);

    yield put(notificationActions.sendNotification({
      message: err.message,
      kind: 'error',
      style: {},
    }));
  }
}

function* deleteGlobalOffer({ payload: { offerId, organizationId, query = {}, notificationParams, callback } }) {
  const authUser = yield select(authSelectors.getAuthUser);
  const queries = objectToParams(query);
  const requestUrl = `${process.env.FRONT_API_URL}/organizations/${organizationId}/users/${authUser.get('_id')}/offers/${offerId}?${queries}`;

  // Delete the offer
  try {
    yield call(request, requestUrl, {
      method: 'DELETE',
    });

    // Update entities
    yield put(actions.deleteGlobalOfferSuccess({
      offerId,
    }));

    // yield put(exponentActions.getExponentByOrganization({ eventId, organizationId }));

    if (typeof callback === 'function') {
      callback();
    }

    // Push success notification
    if (notificationParams && notificationParams.success) {
      yield put(notificationActions.sendNotification({
        message: notificationParams.success.message,
        kind: notificationParams.success.kind,
        style: notificationParams.success.style,
      }));
    }
  } catch (err) {
    trackError(err);

    yield put(notificationActions.sendNotification({
      message: err.message,
      kind: 'error',
      style: {},
    }));
  }
}

/**
 * Listen Actions
 */
export default [
  takeLatest(types.GET_EVENT_OFFERS, getEventOffers),
  takeLatest(types.GET_EVENT_OFFERS_BY_MATCHING, getEventOffersByMatching),
  takeLatest(types.GET_ORGANIZATION_OFFERS, getOrganizationOffers),
  takeLatest(types.GET_ORGANIZATION_USER_OFFERS, getOrganizationUserOffers),
  takeLatest(types.GET_OFFER, getOffer),
  takeLatest(types.GET_GLOBAL_OFFER, getGlobalOffer),
  takeLatest(types.GET_CROSSFORUM_OFFER, getCrossForumOffer),
  takeLatest(types.GET_PARTICIPANT_OFFERS, getParticipantOffers),
  takeLatest(types.POST_OFFER, postOffer),
  takeLatest(types.POST_GLOBAL_OFFER, postGlobalOffer),
  takeLatest(types.DUPLICATE_OFFER, duplicateOffer),
  takeLatest(types.DUPLICATE_GLOBAL_OFFER, duplicateGlobalOffer),
  takeLatest(types.DUPLICATE_CROSS_OFFERS, duplicateCrossOffers),
  takeLatest(types.PATCH_OFFER, patchOffer),
  takeLatest(types.PATCH_GLOBAL_OFFER, patchGlobalOffer),
  takeLatest(types.PATCH_GLOBAL_OFFER_BY_EVENTS, patchGlobalOfferByEvents),
  takeLatest(types.DELETE_OFFER, deleteOffer),
  takeLatest(types.DELETE_GLOBAL_OFFER, deleteGlobalOffer),
  takeLatest(types.APPLY_OFFER, applyOffer),
  takeLatest(types.APPLY_OFFER_WITHOUT_VALIDATION, applyOfferWithoutValidation),
  takeLatest(types.PATCH_DRAFT_OFFERS, patchDraftOffers),
  takeLatest(types.POST_PUBLISH_OFFERS, postPublishOffers),
  takeLatest(types.DELETE_OFFERS, deleteOffers),
];
