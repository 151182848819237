import React from 'react';
import { bool, func, object } from 'prop-types';
import { Button, IconRocket } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';

export const PublishBtn = ({ hasFieldChanged, activityToUpdate, mutatePostActivity, onClick }) => {
  const t = useFormatMessage();
  const isLoading = mutatePostActivity.isLoading && mutatePostActivity.variables.data.status === 'published';
  const isDisabled = mutatePostActivity.isLoading || !hasFieldChanged && activityToUpdate.status === 'published';

  return (
    <Button
      loading={isLoading}
      disabled={isDisabled }
      onClick={onClick}
      imageComponentLeft={<IconRocket size={16}/>}
    >
      {t({ id: 'publish' })}
    </Button>
  )
}

PublishBtn.propTypes = {
  hasFieldChanged: bool,
  activityToUpdate: func,
  mutatePostActivity: object,
  onClick: func,
}
