import React from 'react';
import { object, func } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { toJS } from '@/utils';

// Store
import { eventActions, eventSelectors } from '@/store/event';

// Components
import { injectIntl } from 'react-intl';
import Form from './components/Form';

// Services
import FormToApi from './services/formMapping/FormToApi';
import styles from '../../styles.less';

/**
 * Exponents
 */
class ExponentsScene extends React.PureComponent {
  static propTypes = {
    event: object,
    patchEvent: func,
    intl: object
  };

  /**
   * Handle the form submit
   *
   * @param {object} err
   * @param {object} values
   */
  handleSubmit = (err, values) => {
    const { props: { event, patchEvent } } = this;

    if (!err) {
      const eventParams = { exponents: FormToApi.exponents(values.exponent) };

      patchEvent({
        eventId: event.slug,
        event: eventParams,
        notificationParams: {
          success: {
            message: this.props.intl.formatMessage({ id: 'notifications.update.success' }),
            kind: 'info',
            style: {
              bottom: '5%',
              top: 'inherit',
            },
          },
        }
      });
    }
  }

  /**
   * Render
   */
  render() {
    const { props: { event }, handleSubmit } = this;

    return (
      <div className={styles.formWrapper}>
        <Form
          initialValues={event.exponents}
          onSubmit={handleSubmit}
        />
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  event: eventSelectors.getCurrentEvent,
});

const mapDispatchToProps = {
  patchEvent: eventActions.patchEvent,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  injectIntl,
)(toJS(ExponentsScene));
