import React from 'react';
import { isEmpty } from 'lodash';
import { any, string, array } from 'prop-types';
import Highlighter from 'react-highlight-words';
// eslint-disable-next-line import/extensions
import Icon from '@/components/Icon';

import styles from '../../styles.less';

export const Matching = ({ highlightWords = [], str, name, icon }) => {
  if (!isEmpty(str)) {
    const highlighter = (word) => (
      <Highlighter
        highlightClassName="highlightText"
        searchWords={highlightWords}
        autoEscape
        textToHighlight={word}
      />
    );

    return (
      <div className={styles.matchingTag}>
        <Icon name={icon} className={styles.iconStyle} />
        <span style={{ lineHeight: 'normal' }}>
          <span className={styles.iconName}>{name}</span>
          {typeof str === 'object' ? str : highlighter(str)}
        </span>
      </div>
    );
  }
  return '';
};


Matching.propTypes = {
  str: any,
  icon: string,
  name: string,
  highlightWords: array,
};
