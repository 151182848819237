import React from 'react';
import { bool, object } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { Form } from 'antd';
import PhoneInput from 'react-phone-number-input';
import { isEmpty } from 'lodash';
import styles from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/Profile/Presentation/Phone/styles.less';
import { getLocale } from '@/utils/localStorage';

const FormItem = Form.Item;

export const Phone = ({ form, user, required = true }) => {
  const t = useFormatMessage();
  const locale = getLocale();
  const initialValue = user.phone || window.localStorage.getItem('signup-phone');
  const validations = required ? ['required'] : [];

  const validatePhoneNumber = (rule, value, callback) => {
    if (isEmpty(value) && !required) { callback(); }

    const result = parseInt(value, 10);

    if (Number.isInteger(result) && value && value.length >= 6) {
      callback();
    }

    callback(t({ id: required ? 'form.phone.mandatory' : 'form.phone.error' }));
  };

  return (
    <>
      <FormItem>
        {form.getFieldDecorator('phone', {
          initialValue,
          rules: [
            { validator: validatePhoneNumber, required },
          ],
        })(
          <PhoneInput
            defaultCountry={locale === 'en' ? 'GB' : 'FR'}
            className={styles.inputPhone}
            validations={validations}
            label={t({ id: 'phone' })}
            placeholder={`${t({ id: 'phone' })} ${required ? '*' : ''}`}
          />
        )}
      </FormItem>
    </>
  )
}

Phone.propTypes = {
  form: object,
  user: object,
  required: bool,
}
