import { useInfiniteQuery } from 'react-query';
import request from '@/utils/request';

export function useInfiniteGetOrganizationProfilesPreviewsOffers(
  {
    eventId = null,
    organizationId,
    limit = 15,
  }) {

  const queryFn = (pageParams) => {
    const { pageParam: page } = pageParams;
    const params = `?eventId=${eventId}&limit=${limit}&page=${page}`;
    const requestUrl = `${process.env.FRONT_API_URL}/organizations/${organizationId}/profiles/previews/offers${params}`;

    return request(requestUrl,
      { method: 'GET' });
  };

  const query = useInfiniteQuery({
    queryKey: ['organizationProfilesPreviewsOffers', eventId, organizationId],
    keepPreviousData: true,
    getNextPageParam: (lastPage, pages) => {
      if ((lastPage.page * lastPage.limit) <= lastPage.total) {
        return (lastPage.page + 1);
      }
    },
    select: (data) => ({
      pages: [...data.pages],
      pageParams: [...data.pageParams],
    }),
    queryFn,
    enabled: !!organizationId,
    refetchOnWindowFocus: false,
  });

  return query;
}
