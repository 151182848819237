import moment from 'moment';

import { getIsModuleLiveEnable } from '@/scenes/Event/utils/getIsModuleLiveEnable';
import { SINGLE_DATE, KEY_DATES_TYPE } from './constants';

/**
 * Convert event dates in array filtered and shorted
 * @param {Object} dates
 * @returns {Array<Object>}
 */
// eslint-disable-next-line arrow-body-style
export default (dates = {}, modules = {}, context) => {
  return Object.keys(dates)
    .map(key => {
     if (key === KEY_DATES_TYPE.LIVE && !getIsModuleLiveEnable(context, modules?.live)) return null;

      return (
        {
          ...dates[key], type: key, isSingle: SINGLE_DATE.includes(key),

        });
    }
  )
    .filter(keyDate => {
    if (keyDate?.type === KEY_DATES_TYPE.LIVE && !modules?.live?.adminEnable) return false;

    return (Object.values(KEY_DATES_TYPE).includes(keyDate?.type) && keyDate?.beginAt !== null);
    })
    // https://www.notion.so/seekube/Flexibilit-des-actions-autour-de-la-date-de-fermeture-de-planning-c4bbe66fd7194980800f34192eedb484#5fde14d38a764663b04511b127db1add
    .sort((a, b) => {
      const aDate = moment(a.beginAt);
      const bDate = moment(b.beginAt);

      if (aDate.isSame(bDate, 'day')) {
        if (b.isSingle && a.isSingle) {
          return 0;
        }

        if (b.isSingle) {
          return 1;
        }

        if (a.isSingle) {
          return -1;
        }
      }

      return aDate.unix() - bDate.unix();
    });
}
