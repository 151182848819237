import React from 'react';
import classnames from 'classnames';
import { array, bool, func, object, oneOf } from 'prop-types';
import { get, intersection, isNumber, take } from 'lodash';
import { IconCalendar, IconInfo } from '@seekube-tech/ui-kit';
import { FormattedMessage } from 'react-intl';
import { useFormatMessage } from 'react-intl-hooks';
import { useSelector } from 'react-redux';
import { isOfferLimitExceeded } from '@/utils/pipeDrive';
import { dateFormat } from '@/utils/format';
import { CardOverlay } from '@/components/CardOverlay';
import { If } from '@/components/If';
import { Tooltip } from '@/components/Tooltip';
import Icon from '@/components/Icon';
import Html from '@/components/Html';
import { ShowMore } from '@/components/ShowMore';
import { mapAndJoin } from '@/utils/text';
import {
  KeyMomentsTag
} from '@/scenes/Client/scenes/EditOffer/components/EditOfferStep2/components/Events/components/EventCard/KeyMomentsTag';
import styles from '@/scenes/Client/scenes/EditOffer/components/EditOfferStep2/components/Events/styles.less';
import { getAuthUser } from '@/store/auth/selectors';
import messages from './messages';

const EventCard = ({ event, exponent, context, defaultChecked, onEventSelected, offer }) => {
  
  const t = useFormatMessage();
  const contractCriteria = event?._criteria?.filter((cri) => cri.key === "CONTRACT") ?? [];
  const ids = contractCriteria[0]?._choices?.filter((c) => c.enable)?.map(c => c._id) ?? [];
  const contractCriteriaIds = contractCriteria?.[0]?._choices.map(({ _id }) => _id) || [];
  const contractsSelected = intersection(contractCriteriaIds, offer?.matching?.filters || []);
  const contractNotMatching = contractCriteria[0]?.enable && intersection(ids, contractsSelected).length !== contractsSelected.length && ids.length > 0;
  const authUser = useSelector(getAuthUser)?.toJS();

  const onCardToggle = () => {
    const isChecking = !defaultChecked;
    onEventSelected(isChecking, event._id);
  };

  const isOfferContext = ['offer'].includes(context) ;
  const exceededLimitOffer = ['offer'].includes(context) && isOfferLimitExceeded(exponent, exponent.stats.countOffers);
  const exceededLimitLive = ['live'].includes(context) && isLiveLimitExceeded(exponent);
  const isLimitExceeded = exceededLimitOffer || exceededLimitLive;
  const cssDisabled = isLimitExceeded || contractNotMatching ? styles.disabledEvent : '';
  const getPopupContainer = () => ['offer'].includes(context) ? document.getElementById('editOfferContainer') : document.getElementById('editLiveContainer');
  const title = <FormattedMessage {...messages[['offer'].includes(context) ? 'popoverOfferMax' : 'popoverLiveMax']} />;

  const NoContractTitle = () => {
    const contractTitle = t({ id: 'joboffer.create.event.nocontract.pt1' });
    let contractInfos = '';

    const contracts = event?._criteria?.filter(cri => cri.key === 'CONTRACT')[0]?._choices.filter(({ enable }) => enable);

    contracts.forEach((contract, index) => {
      if (contracts.length > 1 && index > 0) {
        if (index + 1 === contracts.length) {
          contractInfos += ` ${t({ id: 'and' })} `;
        } else {
          contractInfos += ', ';
        }
      }
      contractInfos += contract[`label_${authUser.locale}`] ?? contract.label;
    });

    return (<div>
      {contractTitle} <span style={{fontWeight: 700}}>{t({ id: 'joboffer.create.event.nocontract.contract' }, { contracts: contractInfos })}</span>
    </div>)
  }

  return (
    <CardOverlay isChecked={defaultChecked} onCardToggle={onCardToggle} containerClass={classnames(styles.eventContainer, cssDisabled)} disabledToggle={(!defaultChecked && isLimitExceeded) || contractNotMatching}>
      <div className={styles.cardContent}>
        <div className={styles.cardMainContent}>
          <div className="flex justify-between" >
            <JobdatingDate {...event} />
            {isOfferContext && <KeyMomentsTag exponent={exponent}/>}
          </div>
          <div className={styles.jobdatingInfos}>
            <div>
              <div className={styles.jobdatingName}>
                <p>{event.name}</p>
              </div>
              <div className={styles.planners}>
                <p>
                  <FormattedMessage id="by" />
                  {mapAndJoin(take(event.planners._organizations, 3), 'name', ', ')}
                </p>
              </div>
            </div>
            {contractNotMatching && <Tooltip
              isVisible
              getPopupContainer={getPopupContainer}
              overlayClassName={styles.tooltip}
              color="dark" placement="bottom"
              title={<NoContractTitle />}
            >
              <span className={styles.cardInfo} key="footerDate"><IconInfo /></span>
            </Tooltip>
            }
          </div>
          <Tooltip isVisible={isLimitExceeded} getPopupContainer={getPopupContainer} overlayClassName={styles.tooltip} color="dark" placement="bottom" title={title} ><span className={styles.cardInfo} key="footerDate"><Icon name="information" /></span></Tooltip>
        </div>
        <If condition={get(exponent, '_event.descriptionCandidatesTarget')}>
          <div className={styles.eventSpecificity}>
            <ShowMore>
              <Html value={get(exponent, '_event.descriptionCandidatesTarget')} />
            </ShowMore>
          </div>
        </If>
      </div>
    </CardOverlay>
  );
};

const isLiveLimitExceeded = (exponent) => {
  const liveMax = get(exponent, 'limits.liveMax');

  return !isNumber(liveMax) ? false : liveMax <= get(exponent, 'stats.countConferences');
};

EventCard.propTypes = {
  defaultChecked: bool,
  onEventSelected: func,
  event: object,
  exponent: object,
  context: oneOf(['offer', 'live']),
  offer: object,
  offerContracts: array
};

const JobdatingDate = ({ keyDates }) => {
  const dateString = dateFormat(get(keyDates, 'jobfair.beginAt'), get(keyDates, 'jobfair.endAt'), false);
  return <div key="footerDate" className={styles.jobdatingDate}><IconCalendar size={16} />{dateString}</div>;
};

JobdatingDate.propTypes = {
  keyDates: object
};

export { EventCard };
