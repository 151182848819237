import React from 'react';

import { EVENT_STATUS } from '@/utils/constants';
import Countdown from '@/scenes/Event/components/Countdown';
import Progress from '@/scenes/Event/components/Progress';

import { getUserRole } from '../../../helpers';

import messages from '../../../messages';
import styles from '../../../styles.less';

const EventNavigation = ({ event, authUser, location, context, intl, authUserConnectedAs, targetDate }) => {
  if (!event || context !== 'event' || !targetDate) return '';

  const role = getUserRole(authUser, authUserConnectedAs);
  const {pathname} = location;

  let dateText = intl.formatMessage(messages.beforeEnd);

  if (event.dateStatus === EVENT_STATUS.DISCOVERING || event.dateStatus === EVENT_STATUS.OPEN || event.dateStatus === EVENT_STATUS.PREREGISTRATION) {
    dateText = intl.formatMessage(messages.beforeStart);
  } else if (event.dateStatus === EVENT_STATUS.CLOSED) {
    dateText = intl.formatMessage(messages.closed);
  }

  if (role === 'candidate') {
    return (
      <div className="largeCountdown">
        <Countdown
          type="flat"
          targetDate={targetDate}
          icon="clockColor"
        />
        <div className={styles.eventStatus}>{event.dateStatus !== EVENT_STATUS.CLOSED ? dateText : ''}</div>
      </div>
    );
  }

  if (pathname.includes(`/${role}/preparation/`)) {
    return (
      <Progress activeItem="preparation" eventSlug={event.slug} role={role} />
    );
  } if (pathname.includes(`/${role}/jobdating/`) || !authUser) {
    return (
      <Progress activeItem="jobdating" eventSlug={event.slug} role={role} />
    );
  } if (pathname.includes(`/${role}/report`)) {
    return (
      <Progress activeItem="report" eventSlug={event.slug} role={role} />
    );
  }

  return '';
};

export { EventNavigation };
