import { getContrastingColor } from 'react-color/lib/helpers/color';

import { LOCALES } from '@/utils/constants';
import { getLocale } from '@/utils/localStorage';

/**
 * Return true when label is dark color
 * @param {String} color
 * @returns {Boolean}
 */
export function isLabelDark(color) {
  return getContrastingColor(color) === '#fff';
}

/**
 * Return good label translating
 * @param {Object} corner
 * @param {Boolean} isCrop
 * @returns {String}
 */
export function getLabel(corner, isCrop = false) {
  let label;

  const locale = getLocale();

  if (locale === LOCALES.fr) {
    label = corner.label_fr || corner.label_en;
  } else {
    label = corner.label_en || corner.label_fr;
  }

  if (isCrop && label.length > 25) {
    return `${label.substring(0, 23)}...`;
  }

  return label;
}
