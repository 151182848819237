import React, { useCallback, useEffect, useState } from 'react';
import { compose } from 'redux';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Menu, Dropdown, Modal } from 'antd';
import { bool, object, func, array } from 'prop-types';
import { connect } from 'react-redux';
import { size, get, compact, isEmpty } from 'lodash';
import { createStructuredSelector } from 'reselect';
import classnames from 'classnames';
import { Table, Button, IconChevronDown, Pagination } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { toJS } from '@/utils';

// Store
import { organizationActions } from '@/store/organization';
import { exponentActions, exponentSelectors } from '@/store/exponent';
import { userActions } from '@/store/user';
import { authActions, authSelectors } from '@/store/auth';

// Components
import Separator from '@/components/Separator';
import Icon from '@/components/Icon';
import InputMaterial from '@/components/Input/InputMaterial';
import ColumnsUi from '@/scenes/Admin/scenes/Organizations/scenes/Organization/scenes/Users/ColumnsUi';
import SubColumnsUi from '@/scenes/Admin/scenes/Organizations/scenes/Organization/scenes/Users/SubColumnsUi';
import { ACL, EVENT_FORMAT } from '@/utils/constants';
import { eventActions } from '@/store/event';
import { useGetOrganizationUsers } from '@/queries/organizations/useGetOrganizationUsers';
import { useDeleteUser } from '@/queries/organizations/useDeleteUser';
import { getDataSource } from './components';

import EditUserModal from '../../../../../../../Event/scenes/Recruiter/scenes/Preparation/scenes/Team/containers/EditUserModal';
import CollaboratorModal from '../../../../../../../Event/scenes/Owner/scenes/OnBoarding/containers/CollaboratorModal';
import RemoveUserModal from '../../components/RemoveUserModal';

import Wrapper from '../../../../../../../../components/Wrapper';
import AdditionalRow from './components/AdditionalRow';
import AddJobDatingModal from '../../components/AddJobdatingModal';
import DeleteMemberModal from '../../components/DeleteMemberModal';
import EditPasswordModal from '../../components/editPasswordModal';


// Styles & Translations
import messages from '../../../../messages';
import styles from '../../../../styles.less';

// Antd
const { Item: MenuItem } = Menu;

const DEFAULT_SEARCH = {
  page: 1,
  offset: 0,
  limit: 20,
  current: 1,
  q: ''
}

function OrganizationMembers({
  authUser,
  organization,
  match,
  getOrganization,
  deleteUserFromOrganization,
  resendInvitationEmail,
  patchUser,
  patchExponent,
  setCurrentExponent,
  exponents,
  getExponentsByOrganization,
  postExponents,
  getEvents,
  switchAuth
}) { // eslint-disable-line
  const [currentUser, setCurrentUser] = useState(null)
  const [currentEventId, setCurrentEventId] = useState(null)
  const [addJobDatingModalIsVisible, setAddJobDatingModalIsVisible] = useState(false)
  const [deleteMemberModalIsVisible, setDeleteMemberModalIsVisible] = useState(false)
  const [deleteExponentUserModalIsVisible, setDeleteExponentUserModalIsVisible] = useState(false)
  const [collaboratorModalIsVisible, setCollaboratorModalIsVisible] = useState(false)
  const [editUserModalProperties, setEditUserModalProperties] = useState({ visible: false, context: ACL.EDIT_ROLE })
  const [editPasswordModalIsVisible, setEditPasswordModalIsVisible] = useState(false)
  const [isGroupActionsDisabled, setIsGroupActionsDisabled] = useState(true)
  const [checkedUsers, setCheckedUsers] = useState([])
  const [localExponent, setLocalExponent] = useState(null)
  const [localExponentUserId, setLocalExponentUserId] = useState(null)
  const context = location.pathname.indexOf('/admin') > -1 ? 'admin' : 'client';
  const [search, setSearch] = useState(DEFAULT_SEARCH);
  const [sort, setSort] = useState('');
  const t = useFormatMessage();

  const { data: recruiters, ...organizationUsersQuery } = useGetOrganizationUsers({ 
    search, 
    organization,
    sort
  });
  const mutateDeleteUser = useDeleteUser({
    search,
    organization,
    onSuccess: () => {
      setDeleteMemberModalIsVisible(false)
      setCheckedUsers([])
      setIsGroupActionsDisabled(true)
      organizationUsersQuery.refetch();
    },
  });

  useEffect(() => {
    const organizationId = getOrganizationId();
    getEvents({ offset: 0, page: 1, limit: 1000, context: 'jobdating' });
    getOrganization(organizationId);
    getExponentsByOrganization({
      ...DEFAULT_SEARCH,
      organizationId,
      archive: false,
    });
  }, [])

  const adminContext = 'admin_users';

  const getOrganizationId = () => context === 'admin' ? get(match, 'params.organizationId') : get(authUser, '_currentOrganization._id');

  const handleOnChangeSearchText = (e) => {
    setSearch({ ...search, page: 1, q: e.target.value });
  }

  const handleSendMultipleInvitations = () => {
    checkedUsers.forEach((user) => {
      resendInvitationEmail({
        user,
        eventId: null,
        notificationParams: {
          success: {
            message: t(messages.resendInvitationEmailSuccess, { count: checkedUsers.length }),
            kind: 'info',
            style: {
              bottom: '5%',
              top: 'inherit',
            },
          },
        },
      });
    });
  };

  const handleDeleteUserFromOrganization = () => {
    const usersToDeleteFromOrg = compact([...checkedUsers, currentUser]);

    const organizationId = getOrganizationId();

    usersToDeleteFromOrg.forEach((user) =>
      deleteUserFromOrganization({
        organizationId,
        userId: user._id,
        notificationParams: {
          success: {
            message: t(messages.removeFromJobdatingSuccess),
            kind: 'info',
            style: {
              bottom: '5%',
              top: 'inherit',
            },
          },
        },
        callback: () => {
          setDeleteMemberModalIsVisible(false)
          setCheckedUsers([])
          setIsGroupActionsDisabled(true)
        },
      })
    );

    return true;
  };

  const handleAddEvent = (eventIds) => {
    setAddJobDatingModalIsVisible(false)
    postExponents({
      organizationId: organization._id,
      eventIds,
      userIds: currentUser ? [currentUser._id] : checkedUsers.map((u) => u._id),
      notificationParams: {
        success: {
          message: t(messages.addEventSuccess, { count: eventIds.length }),
          kind: 'info',
          style: {
            bottom: '5%',
            top: 'inherit',
          },
        },
        error: true,
      },
      callback: () => {
        getExponentsByOrganization({
          organizationId: organization._id,
          page: 1,
          offset: 0,
          limit: 100,
          archive: false,
        });
        organizationUsersQuery.refetch();
      },
    });
  };

  const handleDeleteUser = () => {
    const usersToDelete = compact([...checkedUsers, currentUser]);

    usersToDelete.forEach((user) => mutateDeleteUser.mutate({ userId: user._id })
    );
  };

  const handleDeleteExponentUser = () => {
    setLocalExponent(null)
    setCurrentEventId(null)
    setLocalExponentUserId(null)
    setDeleteExponentUserModalIsVisible(false)

    patchExponent({
      exponentId: localExponent._id,
      eventId: currentEventId,
      exponentParams: {
        deleteUserId: localExponentUserId,
      },
      notificationParams: {
        success: {
          message: t(messages.removeFromJobdatingSuccess),
          kind: 'info',
          style: {
            bottom: '5%',
            top: 'inherit',
          },
        },
        error: true,
      },
      callback: () => {
        setCurrentExponent({ exponentId: null });
      },
    });
  };

  const handleOnAddUser = () => {
    setCollaboratorModalIsVisible(false);
    organizationUsersQuery.refetch();
  };

  const handleSwitchRole = (userId, role) => {
    patchUser({
      userId,
      userParams: {
        role,
      },
      loader: false,
      notificationParams: {
        success: {
          message: t(messages.changeUserRoleSuccess),
          kind: 'info',
          style: {
            bottom: '5%',
            top: 'inherit',
          },
        },
        error: true,
      },
      callback: organizationUsersQuery.refetch,
    });
  }

  const handleSendInvitation = (user) => {
    resendInvitationEmail({
      user,
      notificationParams: {
        success: {
          message: t(messages.resendInvitationEmailSuccess, { count: 1 }),
          kind: 'info',
          style: {
            bottom: '5%',
            top: 'inherit',
          },
        },
      },
    });
  };


  const handleOpenEdit = (user, context) => {
    setCurrentUser(user);
    setCheckedUsers([]);
    setEditUserModalProperties({ ...editUserModalProperties, visible: true, context});
  }

  const handleOpenAddJobdating = (user) => {
    setCurrentUser(user);
    setCheckedUsers([]);
    setAddJobDatingModalIsVisible(true);
  }

  const handleOpenEditPassword = (user) => {
    setCurrentUser(user);
    setCheckedUsers([]);
    setEditPasswordModalIsVisible(true);
  }

  const handleOpenDeleteUser = (user) => {
    setCurrentUser(user);
    setCheckedUsers([]);
    setDeleteMemberModalIsVisible(true);
  }

  const handleSelectedUsers = React.useCallback((selection) => {
    const usersToCheck = selection.map(user => user.original.user);
    setCheckedUsers(usersToCheck)
    setIsGroupActionsDisabled(usersToCheck.length === 0)
  }, [])

  /**
   * We can only expand if event is hybrid & has two keyMomentLinks
   * ie: the exponent participate in two phases
   */
  const canEventRowExpand = ({ original }) => original?.format === EVENT_FORMAT.HYBRID && original?._exponent?._keyMomentLinks?.length > 1

  /**
   * We only expand user if he participate in an event
   */
  const canExponentUserExpand = ({ original }) => original?.user?._events?.length > 0

  const tableData = React.useMemo(() => getDataSource(recruiters?.docs,
    {
      authUser,
      context: adminContext,
      actions: {
        handleOpenEditPassword,
        handleSendInvitation,
        handleOpenEdit,
        handleOpenAddJobdating,
        handleOpenDeleteUser,
        onToolsClick: () => { },
        handleSwitchRole,
      }
    }), [recruiters?.docs, adminContext, authUser])

  const handleSwitchAuth = (user) => {
    if (authUser.isAdmin) {
      switchAuth({
        email: user.username,
        notificationParams: {
          success: {
            message: t({ id: 'toasters.switch.success' }),
            kind: 'success',
            style: {
              top: '7%',
            },
          },
          error: {
            message: t({ id: 'toasters.switch.error' })
          },
        },
      });
    }
  }

  const tableColumns = React.useMemo(() => ColumnsUi(adminContext, handleSwitchAuth, organization), [adminContext])

  const onChangePagination = (newPagination) => {
    setSearch({ ...search, current: newPagination, page: newPagination })

    window.scrollTo(0, 0);
  }
  
  const handleSort = (sortBy) => {
    const newSortBy = sortBy[0];
    
    if (isEmpty(newSortBy) || newSortBy.desc === undefined) {
      return null;
    }
    
    const newSortParam = newSortBy.desc ? `-${newSortBy.id}` : newSortBy.id;
    
    if (newSortParam === sort) {
      return null
    }
    
    setSort(newSortParam);
  }
  
  useEffect(() => {
    organizationUsersQuery.refetch();
  }, [sort])

  return (
    <Wrapper type="scene">
      {organization &&
        <div className={classnames(styles.usersContainer, styles[context])}>
          <>
            <div className={styles.topContainer}>
              <h1 className="mainTitle" style={{ marginBottom: 0 }}>
                <FormattedMessage {...messages.organizationMembersTitle} values={{ name: organization.name, count: recruiters?.total || size(recruiters?.docs) }} />
              </h1>
              <div className={styles.actions}>
                <Dropdown
                  disabled={isGroupActionsDisabled}
                  overlay={
                    <Menu>
                      <MenuItem key="0">
                        <a role="button" tabIndex={0} onClick={() => handleSendMultipleInvitations()}>
                          <Icon name="message" />
                          <span>{t(messages.resendInvitationEmail)}</span>
                        </a>
                      </MenuItem>
                      <MenuItem key="1">
                        <a role="button" tabIndex={0} onClick={() => { setAddJobDatingModalIsVisible(true) }}>
                          <Icon name="add-plus-rounded" />
                          <span>{t(messages.addToJobdatings)}</span>
                        </a>
                      </MenuItem>
                    </Menu>
                  }
                  trigger={['click']}
                  placement="bottomRight"
                >
                  <Button imageComponentRight={<IconChevronDown size={16} />}>
                    {t(messages.groupedActions)}
                  </Button>
                </Dropdown>

                <Button tabIndex={0} onClick={() => setCollaboratorModalIsVisible(true)}>
                  {t(messages.createMember)}
                </Button>
              </div>
            </div>
          </>
          <div>
            <div className={styles.searchOrganization}>
              <InputMaterial
                type="string"
                placeholder={t(messages.searchByRecruiter)}
                addOnIcon="search"
                onChange={handleOnChangeSearchText}
              />
            </div>
          </div>
          {/* members Table */}

          <Table
            getCustomTableProps={() => ({ style: { width: '100%' } })}
            enableSort
            data={tableData}
            columns={tableColumns}
            enableExpansion
            getCanRowExpand={canExponentUserExpand}
            enableSelection
            handleSelectedRows={handleSelectedUsers}
            isLoadingData={organizationUsersQuery.isFetching}
            isSortByManual={true}
            manuallySortBy={handleSort}
            renderRowSubComponent={({ row: { original } }) => (
              <Table
                getCustomTableProps={() => ({ style: { width: '100%' } })}
                data={original.user._events}
                columns={SubColumnsUi()}
                enableExpansion
                getCanRowExpand={canEventRowExpand}
                renderAdditionalRow={AdditionalRow}
                hasSeparator={false}
              />
            )}
          />
        </div>
      }

      <Pagination
        className="flex justify-center my-10"
        current={search.current ? parseInt(search.current, 10) : 1}
        pageSize={DEFAULT_SEARCH.limit}
        total={recruiters?.total || 0}
        onChange={onChangePagination}
      />

      <AddJobDatingModal
        visible={addJobDatingModalIsVisible}
        handleClose={() => { setAddJobDatingModalIsVisible(false) }}
        onSubmit={handleAddEvent}
        exponents={exponents}
        countMemberSelected={checkedUsers.length || 1}
        context="team"
        maxRecruiter={context !== 'admin' ? organization && get(organization, '_plan.recruiterMax') : 10000}
        user={currentUser}
      />


      {context === 'admin' ? (
        <DeleteMemberModal
          visible={deleteMemberModalIsVisible}
          handleClose={() => { setDeleteMemberModalIsVisible(false) }}
          onDeleteUser={() => handleDeleteUser()}
          onDeleteUserFromOrganization={() => handleDeleteUserFromOrganization()}
        />
      ) : (
        <RemoveUserModal
          visible={deleteMemberModalIsVisible}
          handleCancel={() => {
            setDeleteMemberModalIsVisible(false);
            mutateDeleteUser.reset();
          }}
          onOk={handleDeleteUser}
          error={mutateDeleteUser.isError}
        />
      )}

      {collaboratorModalIsVisible ?
        <CollaboratorModal
          context="all"
          organization={organization}
          isOpen={collaboratorModalIsVisible}
          onCancel={() => setCollaboratorModalIsVisible(false)}
          onOk={handleOnAddUser}
        /> : null
      }
      <Modal
        visible={deleteExponentUserModalIsVisible}
        footer={false}
        maskClosable
        width={470}
        className="customConfirm"
        onCancel={() => { setDeleteExponentUserModalIsVisible(false) }}
      >
        <a role="button" tabIndex={0} className="modal-close" onClick={() => setDeleteExponentUserModalIsVisible(false)}>
          <Icon name="close" className="modal-close-icon" />
        </a>

        <h4 className="ant-confirm-title">
          <span role="img" aria-label="warning">⚠️ </span><FormattedHTMLMessage {...messages.deleteCollaboratorConfirm} />fds
        </h4>

        <Separator height={15} />

        <div className="confirm-actions">
          <a
            className="ant-btn"
            role="button"
            tabIndex={0}
            onClick={() => setDeleteExponentUserModalIsVisible(false)}
          >
            {t(messages.cancel)}
          </a>
          <Button onClick={() => handleDeleteExponentUser()}
          >{t(messages.confirm)}</Button>
        </div>
      </Modal>

      <EditPasswordModal
        visible={editPasswordModalIsVisible}
        user={currentUser}
        handleClose={() => { setEditPasswordModalIsVisible(false) }}
      />

      <EditUserModal
        context={editUserModalProperties.context}
        isOpen={editUserModalProperties.visible}
        onSubmit={organizationUsersQuery.refetch}
        onClose={() => {
          setEditUserModalProperties({ ...editUserModalProperties, visible: false });
          setCurrentUser(null);
        }}
        user={currentUser}
        organization={organization}
      />
    </Wrapper>
  );
}

const mapStateToProps = createStructuredSelector({
  exponentsIsFetching: exponentSelectors.getExponentsFetching,
  exponents: exponentSelectors.getExponents,
  authUser: authSelectors.getAuthUser,
  authUserConnectedAs: authSelectors.getAuthUserConnectedAs,
});

const mapDispatchToProps = {
  getOrganization: organizationActions.getOrganization,
  getExponentsByOrganization: exponentActions.getExponentsByOrganization,
  postExponents: organizationActions.postExponents,
  patchExponent: exponentActions.patchExponent,
  setCurrentExponent: exponentActions.setCurrentExponent,
  getUsersOrganization: userActions.getUsersOrganization,
  getExponent: exponentActions.getExponent,
  patchUser: userActions.patchUser,
  deleteUser: userActions.deleteUser,
  resetPassword: authActions.authResetPassword,
  deleteUserFromOrganization: userActions.deleteUserFromOrganization,
  resendInvitationEmail: userActions.resendInvitationEmail,
  switchAuth: authActions.switchAuth,
  getEvents: eventActions.getEvents,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

OrganizationMembers.propTypes = {
  authUser: object,
  organization: object,
  match: object,
  getOrganization: func,
  getUsersOrganization: func,
  deleteUserFromOrganization: func,
  resendInvitationEmail: func,
  resetPassword: func,
  patchUser: func,
  patchExponent: func,
  setCurrentExponent: func,
  deleteUser: func,
  exponents: array,
  getExponentsByOrganization: func,
  postExponents: func,
  switchAuth: func,
  exponentsIsFetching: bool,
  getEvents: func,
};

export default compose(
  withConnect,
)(toJS(OrganizationMembers));
