import { defineMessages } from 'react-intl';

export default defineMessages({
  team: {
    id: 'recruiter.preparation.team',
  },
  offers: {
    id: 'recruiter.preparation.offers',
  },
  timeslots: {
    id: 'recruiter.preparation.timeslots',
  },
  stand: {
    id: 'recruiter.preparation.stand',
  },
});
