import React from 'react';
import { bool, string, object } from 'prop-types';
import Card from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Exponents/scenes/Exponent/components/Card';
import CardConference from './CardConference';
import CardConferencesFooter from './CardConferencesFooter';
import styles from '../styles.less';

const CardConferences = ({
  event,
  conferences,
  link,
  profile,
  isPartner,
  labels
}) => <Card>
  {conferences.map((conf, index) => <div key={conf._id}>
    <CardConference
      event={event}
      conference={conf}
      isPartner={isPartner}
      organizationProfile={profile}
      partnerColor={event.modules.partner?.color}
      partnerIcon={event.modules.partner?.icon}
    />
    {index < conferences.length - 1 ? <hr className={styles.separator}/> : <></>}
  </div>)}
  <CardConferencesFooter
    link={link}
    conferences_count={conferences.length}
    organizationName={profile?.name}
    labels={labels}
  />
</Card>

CardConferences.propTypes = {
  event: object,
  conferences: object,
  profile: object,
  link: string,
  isPartner: bool,
  labels: object
}

export default CardConferences;
