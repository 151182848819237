import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { Field } from 'react-final-form';
import { bool } from 'prop-types';
import { TextField } from '@seekube-tech/ui';
import { H5, Button, IconTrash2, IconPlusCircle, IconChevronUp, IconChevronDown, Body1, H6 } from '@seekube-tech/ui-kit';
import { FieldArray } from 'react-final-form-arrays';
import styles from '../styles.less';


const Benefits = ({ disabledFields, push}) => {
  const t = useFormatMessage();

  return (
    <div className={styles.benefitsContainer}>
      <div className={styles.headerBenefit}>
        <H5>{t({id: 'benefits.title'})}</H5>
        <Body1>{t({id: 'benefits.description'})}</Body1>
        <H6>{t({id: 'benefits.subtitle'})}</H6>
      </div>
      <div className={styles.keyNumbersList}>
        <FieldArray name="benefitsJoin">
          {({ fields }) =>
            fields.map((name, index) => (
              <div className={styles.keyNumberField} key={name}>
                <Field
                  name={`${name}`}
                  id={`${name}`}
                  label={`${t({ id: 'benefit'})} ${index + 1}`}
                  placeholder={t({ id: 'benefit'})}
                  component={TextField}
                  fullWidth
                  disabled={disabledFields}
                />
                <Button
                  onClick={() => fields.remove(index)}
                  disabled={disabledFields}
                  variant='tonal'
                  color='error'
                  imageComponentLeft={<IconTrash2 size={16}/>}
                >
                  {t({id: 'delete'})}
                </Button>
              </div>
            ))
          }
        </FieldArray>
      </div>
      <div className={styles.addKeyNumbersContainer}>
        <Button
          disabled={disabledFields}
          onClick={() => push('benefitsJoin', undefined)}
          variant='outline'
          imageComponentLeft={<IconPlusCircle size={16} />}
        >
          {t({ id: 'add.benefit' })}
        </Button>
      </div>
    </div>
  );
}

Benefits.propTypes = {
  disabledFields: bool,
};

export default Benefits;
