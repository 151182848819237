import React from 'react';
import { object, func, array } from 'prop-types';
import { injectIntl } from 'react-intl';

// components
import { Form, Select as SelectAnt } from 'antd';
import { Button, H5 } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import Select from '@/components/Form/Select';

import styles from '../../../../styles.less';

const FormItem = Form.Item;
const { Option } = SelectAnt;

const Language = ({
  languages,
  authUser,
  form,
  onSave,
}) => {
  const t = useFormatMessage();

  const handleOnSubmit = (e) => {
    e.preventDefault();

    form.validateFields((err, values) => {
      if (!err) {
        onSave({
          locale: values.locale,
          notificationParams: {
            success: {
              message: `${t({ id: 'form.information.success' })}`,
              kind: 'info',
              style: {
                bottom: '5%',
                top: 'inherit',
              },
            },
          },
        });
      }
    });
  };

    return (
      <Form className={styles.emailFormWrapper} onSubmit={handleOnSubmit}>
        <FormItem>
          <H5 className="mb-20">{t({ id: 'profile.settings.general.language.title' })}</H5>

          {form.getFieldDecorator('locale', {
            initialValue: authUser.locale,
            rules: [{ required: true, message: t({ id: 'form.error.required' }) }],
          })(
            <Select
              showSearch
              addOnIcon="langage"
              label={t({ id: 'profile.settings.general.language.placeholder' })}
              placeholder={t({ id: 'profile.settings.general.language.placeholder' })}
              optionFilterProp="children"
              filterOption={(input, option) => option.props && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {
                languages.map((language) => <Option key={language.id}>{language.label}</Option>)
              }
            </Select>
          )}
        </FormItem>

          <Button type="submit">
            {t({ id: 'save' })}
          </Button>
      </Form>
    );
}

Language.propTypes = {
  languages: array,
  authUser: object,
  form: object,
  onSave: func,
}

export default Form.create()(injectIntl(Language));
