import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { Field } from 'react-final-form';
import { bool } from 'prop-types';
import { Body1, H5, H6 } from '@seekube-tech/ui-kit';
import { FieldArray } from 'react-final-form-arrays';
import Pictures from '@/forms/Stand/components/Pictures';
import Videos from '@/forms/Stand/components/Videos';
import styles from './styles.less';

const Medias = ({ disabledFields, organizationId}) => {
  const t = useFormatMessage();

  return (
    <div className={styles.mediasContainer}>
      <div className={styles.headerMedias}>
        <H5>{t({id: 'medias.bloc'})}</H5>
        <Body1>{t({id: 'event.recruiter.preparation.stand.item.pictures.label'})}</Body1>
      </div>
      <div className={styles.picturesContainer}>
        <div className={styles.picturesTitles}>
          <H6>{t({id: 'stand.key. pictures'})}</H6>
          <Body1>{t({id: 'picture.upload.advices'})}</Body1>
        </div>
        <Field
          name="pictures"
          component={Pictures}
          organizationId={organizationId}
          disabled={disabledFields}
        />
      </div>
      <div className={styles.videosContainer}>
        <FieldArray
          name="videos"
          component={Videos}
          disabled={disabledFields}
        />
      </div>
    </div>
  );
}

Medias.propTypes = {
  disabledFields: bool,
};

export default Medias;
