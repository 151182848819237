import React, { useEffect, useState } from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { Button, H4, ItemCard, Tag } from '@seekube-tech/ui-kit';
import { Modal } from 'antd';
import styles from './styles.less';
import { getStandsVersions, restoreVersion } from '../../request';

const ModalVersionAdmin = ({organization, handleClose, setProfile, eventId}) => {

  const t = useFormatMessage();

  const [selectedVersion, setSelectedVersion] = useState()
  const [versionList, setVersionList] = useState()

  useEffect(() => {
    getStandsVersions({orgaId: organization._id}).then((res) => {
      setVersionList(res)
    })
  }, [])

  const handleSubmit = () => {
    restoreVersion({orgaId: organization._id, body: {actionVersionId: selectedVersion}, eventId}).then(() => {
      setProfile();
      handleClose();
    })
  }

  return (
    <Modal
      visible
      onCancel={handleClose}
      closable
      footer={false}
      maskClosable
      width={720}
      className={styles.ModalVersionAdmin}
    >
      <H4 className={styles.editStandTitle}>{t({id: 'version.history'})}</H4>
      <div className={styles.versionListContainer}>
        {versionList?.map((version, index) => (
            <ItemCard
              selectable
              className={styles.editStandRadioCard}
              key={version.actionVersionId}
              onSelect={() => setSelectedVersion(version.actionVersionId)}
              selected={selectedVersion === version.actionVersionId}
              description={<div>{version._user.fullName} @{version._organization.name}</div>}
              bottomLeftElement={<div>{version.keys.map((_key, index) => (
                  <Tag className={styles.tagListElement} key={index} variant='tonal' color='neutral' size='small'>{t({id: `stand.key.${_key}`})}</Tag>
                ))}</div>}
            >
              {moment(version.updatedAt).format('DD MMMM YYYY HH:mm')} <span>{index === 0 && t({id: 'current.version'})}</span>
            </ItemCard>
          ))}
      </div>
      <div className={styles.btnContainer}>
        <Button onClick={handleClose} variant='outline' color='neutral'>{t({id: 'cancel'})}</Button>
        <Button disabled={!selectedVersion} onClick={handleSubmit} className={styles.submitStand}>{t({id: 'submit'})}</Button>
      </div>
    </Modal>
  );
}

ModalVersionAdmin.propTypes = {
};

export default ModalVersionAdmin;
