import Moment from 'moment';
import { extendMoment } from 'moment-range/dist/moment-range';
const moment = extendMoment(Moment);

/**
 * Slot Overlaps
 *
 * @param {object} newSlot From react-big-calendar
 * @param {Array} timeslots Timeslots grouped
 * @param {Number} slotDuration
 */
export const slotOverlaps = (
  newSlot,
  timeslots = [],
  slotDuration = 30
) => {
  // beginAt will be used if it's a slot group
  const slotStart = newSlot.start || newSlot.beginAt;

  const rangeToTest = moment.range(
    slotStart,
    moment(slotStart).add(slotDuration, 'minutes')
  );

  return timeslots.findIndex((slot) => {
    const groupRange = moment.range(
      slot.beginAt,
      moment(slot.beginAt)
        .add(slot.duration, 'minutes')
    );

    return rangeToTest.overlaps(groupRange);
  }) !== -1;
};
