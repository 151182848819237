import { makeStyles } from '@seekube-tech/ui';

export default makeStyles(theme => ({
  description: {
    marginTop: theme.spacing(0.5),
  },
  field: {
    marginTop: theme.spacing(2),
  },
  switch: {
    marginTop: theme.spacing(0.5),
  },
  button: {
    marginTop: theme.spacing(3),
  },
}));
