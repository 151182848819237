import { getEventKeyMoments } from '@/helpers/event/getEventKeyMoments';
import { EVENT_FORMAT } from '@/utils/constants';
import participation from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Participation';

export function getKeyMomentLinks(form, event) {
  const physicalParticipation =  form.getFieldValue('physicalKeyMoment') ? EVENT_FORMAT.PHYSICAL : null;
  const virtualParticipation =  form.getFieldValue('virtualKeyMoment') ? EVENT_FORMAT.VIRTUAL : null;
  return [physicalParticipation, virtualParticipation]
    .filter((participationFormat) => participationFormat)
    .map((participationFormat) => ({
      type: 'jobfair',
      _keyMoment: {
        _id: getEventKeyMoments(event, { type: 'jobfair', format: participationFormat })._id
      }
    }));

}