import React from 'react';
import moment from 'moment';
import { object, array, oneOf } from 'prop-types';
import { filter, get } from 'lodash';
import { injectIntl } from 'react-intl';

// Components
import classnames from 'classnames';
import { Matching } from './components';

// Styles & Translations
import styles from './styles.less';
import messages from '../../messages';

// TODO: Généraliser la partie otherFilters
const Matchings = ({ participant, highlightWords, intl, authUser, defaultCriteria, context }) => {
  if (!participant) {
    return null;
  }

  const criteria = participant._event ? filter(participant._event._criteria, (criteria) => get(criteria, 'modules.profileParticipant.isVisible')) : defaultCriteria;
  const filters = participant.matching.filters ? participant.matching.filters : [];
  const levelFilter = criteria.find((item) => item.key === 'LEVEL');
  const tempFilters = [];

  if (levelFilter) {
    tempFilters.push(levelFilter);
  }

  const otherLevelFilters = criteria.filter((item) => (!item.key) || item.key === 'LICENSE' || item.key === 'EXPERIENCE' || item.key === 'REGEXID' || item.key === 'POSITION' || item.key === 'TECHNOS');
  const otherFilters = [...tempFilters, ...otherLevelFilters];

  moment.locale(intl.locale);

  const filtersTag = otherFilters.map((filter) => {
    let str = '';

    if (filter.key === 'LICENSE') {
      str = participant.matching.license ? intl.formatMessage(messages.yes) : intl.formatMessage(messages.no);
    } else if (filter.key === 'REGEXID') {
      str = get(participant, '_user.studentId');
    } else if (filter.key === 'EXPERIENCE') {
      if (participant.matching.experiences > -1) {
        str = authUser.locale === 'fr' ? filter._choices[participant.matching.experiences].label : (authUser.locale === 'en' ? filter._choices[participant.matching.experiences].label_en : '');
      }
    } else if (filter.key === 'LEVEL') {
      str = filter._choices
        .filter((choice) => choice.enable && participant.matching.level === choice._id.toString())
        .map((item) => authUser.locale === 'fr' ? item.label : (authUser.locale === 'en' && item.label_en ? item.label_en : '')).join(', ');
    } else {
      str = filter._choices
        .filter((choice) => choice.enable && filters.includes(choice._id.toString()))
        .map((item) => authUser.locale === 'fr' ? item.label : (authUser.locale === 'en' && item.label_en ? item.label_en : '')).join(', ');
    }

    const functions = filter._choices.map((choice) => {

      let str2 = '';
      if (filter.key === 'LICENSE') {
        str2 = participant.matching.license ? intl.formatMessage(messages.yes) : intl.formatMessage(messages.no);
      } else {
        str2 = filter._choices
          .filter((newChoice) => newChoice.enable && newChoice._id && filters.includes(newChoice._id.toString()) && newChoice._parent && newChoice._parent === choice._id)
          .map((item) => authUser.locale === 'fr' ? item.label : (authUser.locale === 'en' && item.label_en ? item.label_en : '')).join(', ');
      }

      if (!choice._parent && filter.name === 'Fonctions' && !str2.includes(', ,')) {
        return (
          <Matching
            key={choice._id}
            name={authUser.locale === 'fr' ? `${filter.modules.profileParticipant.label} ${choice.label}` : (authUser.locale === 'en' && filter.modules.profileParticipant.label_en && choice.label_en ? `${filter.modules.profileParticipant.label_en} ${choice.label_en}` : `${filter.modules.profileParticipant.label} ${choice.label}`)}
            participant={participant}
            highlightWords={highlightWords}
            icon={filter.icon}
            str={str2}
          />
        );
      } return null;
    });

    if (str && !str.includes(', ,')) {
      return (
        <div key={`matchingWrapper${filter._id}`} className={styles.firstMatchingsWrapper}>
          {functions}
          {filter.key !== 'POSITION' &&
          <Matching
            name={authUser.locale === 'fr' ? filter.modules.profileParticipant.label : (authUser.locale === 'en' && filter.modules.profileParticipant.label_en ? filter.modules.profileParticipant.label_en : filter.modules.profileParticipant.label)}
            participant={participant}
            highlightWords={highlightWords}
            icon={filter.icon}
            str={str}
          />}
        </div>);
    } return null;
  });

  return (
    <div className={classnames(styles.matchingsWrapper, styles[context])}>
      <div className={styles.matchings}>
      {filtersTag}
    </div></div>
  );
};

Matchings.propTypes = {
  intl: object,
  participant: object,
  highlightWords: array,
  defaultCriteria: array,
  authUser: object,
  context: oneOf(['noSearchAndNoExperience', undefined]),
};

Matchings.defaultProps = {
  type: 'default',
};

export default injectIntl(Matchings);
