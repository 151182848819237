import React from 'react';
import { any, string } from 'prop-types';

import styles from './styles.less';

const TextSeparator = ({ fontSize = '14px', children }) => (
    <div className={styles.separator}>
    <span style={{ fontSize }}>
      {children}
    </span>
  </div>
  );

TextSeparator.propTypes = {
  children: any,
  fontSize: string,
};

export default TextSeparator;
