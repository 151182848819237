import { defineMessages } from 'react-intl';

export default defineMessages({
  blankDefaultDescriptionLivetalks: {
    id: 'recruiter.livetalks.blank.default.description',
  },
  blankDefaultTitleLivetalks: {
    id: 'recruiter.livetalks.blank.default.title',
  },
  blankDefaultLivetalks: {
    id: 'recruiter.livetalks.blank.default.text',
  },
});
