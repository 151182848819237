import { number } from 'prop-types';
import * as React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { IconCandidates } from '@seekube-tech/ui-kit';

function ParticipantsClose({ countParticipants }) {
  return countParticipants > 10 ?
    <span key="participantsClose">
      <IconCandidates size={16} />
      <strong>{`${countParticipants} `}</strong>
      <FormattedHTMLMessage values={{ count: countParticipants }} id="candidates" />
    </span> : null
}

ParticipantsClose.propTypes = {
  countParticipants: number
}

export default ParticipantsClose;
