import React from 'react';
import { any, number, object, string } from 'prop-types';
import { FormattedHTMLMessage, injectIntl } from 'react-intl';
import { useFormatMessage } from 'react-intl-hooks';
import classnames from 'classnames';

import styles from './styles.less';

function SearchResultsStats({ variant, count, total, intlId, style }) {
  const t = useFormatMessage();

  if (variant === 'full') {
    return (
      <div className={classnames(styles.searchResultsStats, styles.variant)} style={style}>
        {t(
          { id: "x.result.on.y" },
          {
            countResult: count,
            totalCount: total,
            name: t({ id: intlId }, { count: total })
          })}
      </div>
    )
  }

  return (
    // eslint-disable-next-line react/no-danger
    <div className={styles.searchResultsStats} style={style}>
      <FormattedHTMLMessage id="indication.results" values={{ count: total, filtered: count }} />
    </div>
  )
}

SearchResultsStats.propTypes = {
  count: number,
  total: number,
  style: any,
};

export default injectIntl(SearchResultsStats);
