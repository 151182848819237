import React from 'react';
import { push } from 'connected-react-router';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { func, object, string } from 'prop-types';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

import SplitViewsComponent from '../../components/SplitView';
import { Info, Request, Success } from './components';

import SideContent from '../Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/components/SideContent';
import styles from './styles.less';
import { withAuth } from '../../containers/RequiredAuth';

const RequestPlansInfo = ({ match, push, step }) => {
  const plan = get(match, 'params.plan');

  const getView = () => {
    if (step === 'request') return <Request plan={plan} />;
    if (step === 'success') return <Success plan={plan} />;

    return <Info plan={plan} {...match} />;
  };

  return (
    <div id="editOfferContainer">
      <SplitViewsComponent
        classModalContainer={styles.planInfoSplitContainer}
        leftContent={getView()}
        handleCloseAppointment={() => push('/events')}
        closePosition="none"
        logo
        rightContent={
          <SideContent
            showSearch={false}
            context="plansInfo"
            title={<FormattedMessage id="recruiter.planInfo.sideContent.title" />}
            description={<FormattedHTMLMessage id="recruiter.planInfo.sideContent.description" />}
          />
        }
      />
    </div>
  );
};

RequestPlansInfo.propTypes = {
  match: object,
  step: string,
};

RequestPlansInfo.propTypes = {
  push: func,
};

const mapDispatchToProps = {
  push,
};

const withConnect = connect(null, mapDispatchToProps);

export default compose(
  withConnect,
  withAuth(),
)(RequestPlansInfo);
