import React from 'react';
import { object, func } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useFormatMessage } from 'react-intl-hooks';
import { Row, Col } from 'antd';
import { Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { Button } from '@seekube-tech/ui-kit';
import { toJS } from '@/utils';

// Store
import { eventActions, eventSelectors } from '@/store/event';
import { authSelectors } from '@/store/auth';

// Components
import { H1 } from '@/components/Title';
import Table from './components/Table';

import styles from './styles.less';
import FormToApi from '../../services/formMapping/FormToApi';

/**
 * Validate form
 * @param {Func} t
 * @param {Object} values
 * @returns {Object} errors
 */
// eslint-disable-next-line arrow-body-style
const validate = (t) => (values = []) => {
  return values.map((value) => {
    const valueErrors = {};

    if (value && !value.label_fr && !value.label_en) {
      valueErrors.label_fr = t({ id: 'event.modules.corner.error.label' });
      valueErrors.label_en = t({ id: 'event.modules.corner.error.label' });
    }

    return valueErrors;
  });
};
function CornerPage({ event, patchEvent, authUser }) {
  const t = useFormatMessage();
  const initialValues = event?.modules?.corner || { items: [] };

  const onSubmit = ({ event, patchEvent }) => (values = {}) => {

    const eventParams = {
      modules: FormToApi.modules(event.modules, 'corner', values),
    };

    patchEvent({
      event: eventParams,
      notificationParams: {
        success: {
          message: t({ id: 'notifications.update.success' }),
          kind: 'info',
          style: {
            bottom: '5%',
            top: 'inherit',
          },
        },
      }
    });
  }

  return (
    <div className={styles.root}>
      <Row type="flex" align="middle" gutter={22}>
        <Col>
          <H1 bold>{t({ id: 'corner.pl' }, { count: 2 })}</H1>
        </Col>

        {/* TODO: display when help page is created */}
        {false && (
          <Col>
            <a href="" target="_blank">
              {t({ id: 'help' })}
            </a>
          </Col>
        )}
      </Row>

      <p className={styles.description}>
        {t({ id: 'event.modules.corner.description' })}
      </p>

      <Form
        initialValues={initialValues}
        subscription={{}}
        onSubmit={onSubmit({ event, patchEvent })}
        mutators={{
          ...arrayMutators,
          handleSortRow: ([value], state, { changeValue }) => {
            changeValue(state, 'items', () => value);
          },
        }}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit} noValidate className={styles.form}>
            <FieldArray
              name="items"
              component={Table}
              validate={validate(t)}
              locale={authUser.locale}
              handleSortRow={(items) => form.mutators.handleSortRow(items)}
              handleSubmit={handleSubmit}
            />

            <Button
              type="submit"
              className={styles.submitButton}
            >
              {t({ id: 'save' })}
            </Button>
          </form>
        )}
      />
    </div>
  );
}

CornerPage.propTypes = {
  event: object,
  patchEvent: func.isRequired,
  authUser: object,
};

CornerPage.defaultProps = {
  event: null,
  authUser: {},
};

const mapStateToProps = createStructuredSelector({
  event: eventSelectors.getCurrentEvent,
  authUser: authSelectors.getAuthUser,
});

const mapDispatchToProps = {
  patchEvent: eventActions.patchEvent,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  toJS,
)(CornerPage);
