import React, { useEffect } from 'react';
import { object, func } from 'prop-types';
import { compose } from 'redux';
import { toJS } from '@/utils';
import { createStructuredSelector } from 'reselect';
import { replace } from 'connected-react-router';
import { connect } from 'react-redux';

// store
import { exponentSelectors, exponentActions } from '@/store/exponent';
import { eventSelectors } from '@/store/event';
import { authSelectors } from '@/store/auth';

// components
import LoadingIndicator from '@/components/LoadingIndicator';
import StandFormV2 from '@/forms/Stand';

function RecruiterPreparationStandScene({ authUser, exponent, getExponentByOrganization, replace, event }) {
  /* Effects */

  useEffect(() => {
    if (!exponent) {
      getExponentByOrganization({
        eventId: event._id,
        organizationId: authUser._currentOrganization._id,
      });
    }
  }, [event, authUser]);

  useEffect(() => {
    if (exponent) {
      const currentExponentUser = exponent.users.find((item) => authUser && item._user && item._user._id === authUser._id);

      if (!authUser.isAdmin && currentExponentUser) {
        // replace(`/${exponent._event.slug}/recruiter/preparation/offers`);
      }
    }
  }, [exponent, authUser, replace]);

  /* Render */

  return exponent ? (
    <StandFormV2 organization={exponent._organization} exponent={exponent} />
  ) : (
    <LoadingIndicator />
  );
}

RecruiterPreparationStandScene.propTypes = {
  authUser: object,
  exponent: object,
  getExponentByOrganization: func.isRequired,
  replace: func.isRequired,
  event: object,
};

RecruiterPreparationStandScene.defaultProps = {
  authUser: null,
  exponent: null,
  event: null,
};

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser,
  exponent: exponentSelectors.getCurrentExponent,
  event: eventSelectors.getCurrentEvent,
});

const mapDispatchToProps = {
  getExponentByOrganization: exponentActions.getExponentByOrganization,
  replace,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(toJS(RecruiterPreparationStandScene));
