import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { func, string, bool } from 'prop-types';
import { Select as SelectAntd } from 'antd';
import Select from '@/components/Form/Select';
import Icon from '@/components/Icon';
import styles from './styles.less';

const { Option } = SelectAntd;

const SortFilter = ({ onSort, searchText, hideReadyFilter = false }) => {

  const t = useFormatMessage();

  const getSortParam = (selected) => {
    switch (selected) {
      case 'ascending':
        return '_organization.name'
      case 'ready':
        return 'isReady'
      default:
        return '-createdAt'
    }
  }

  const handleSelect = (selected) => {
    onSort({
      search: { keywords: searchText },
      sort: getSortParam(selected)
    })
  }

  return (
    <div className={styles.sortFilter}>
      <Select
        defaultValue='last'
        className={styles.inputSelect}
        suffixIcon={<Icon name="chevron" />}
        placeholder={t({ id: 'sortBy' })}
        onSelect={handleSelect}
      >
        {[(
          <Option key="last" value="last">
            {t({ id: 'event.owner.exponents.sort.last' })}
          </Option>
        ), (
          <Option key="ascending" value="ascending">
            {t({ id: 'event.owner.exponents.sort.ascending' })}
          </Option>
        ), (!hideReadyFilter && <Option key="ready" value="ready">
            {t({ id: 'event.owner.exponents.sort.ready' })}
          </Option>)]}
      </Select>
    </div>
  );
}

SortFilter.propTypes = {
  hideReadyFilter: bool,
  onSort: func,
  searchText: string,
};

export { SortFilter }
