import { defineMessages } from 'react-intl';

export default defineMessages({
  preregistrationTitle: {
    id: 'preregistration.title',
  },
  preregistrationCtaLabel: {
    id: 'preregistration.cta',
  },
  preregistrationText: {
    id: 'preregistration.text',
  },
  errorAlreadyRegistered: {
    id: 'preregistration.error.alreadyRegistered',
  },
  errorFieldMandatory: {
    id: 'form.mandatory',
  },
  successTitle: {
    id: 'preregistration.success.title',
  },
  successText: {
    id: 'preregistration.success.text',
  },
});
