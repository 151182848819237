import React from 'react';
import { number, object, string } from 'prop-types';
import { isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';
import Avatar from '@/components/Avatar';

import styles from '../../styles.less';

export const ModalHeader = ({ user, title, count }) => (
  <div className="participantHeader">
    <div className="participantHeaderContent">
      <div className={styles.avatar}>
        <Avatar src={user.pictureUrl} imageFit="cover" user={user} size={64} isDefault={isEmpty(user.pictureUrl)} />
      </div>
      <div style={{ width: '100%', display: 'flex', alignItems: 'center', paddingLeft: '15px' }}>
        <h3><FormattedMessage {...title} values={{ name: user.firstName || '', count }} /></h3>
      </div>
    </div>
  </div>
);

ModalHeader.propTypes = {
  count: number,
  user: object,
  title: string,
};
