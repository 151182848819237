import { makeStyles } from '@seekube-tech/ui';

export default makeStyles(theme => ({
  root: {
    borderRadius: '5px',
    display: 'flex',
    minHeight: '100vh',

  },
  content: {
    background: theme.palette.neutral['100'],
    flexGrow: 10,
  },
  selected: {
    border: `1px solid ${theme.palette.primary['500']}`,
    background: theme.palette.primary['100'],
    borderRadius: '5px'
  },
  description: {
    marginTop: theme.spacing(0.5),
  },
  field: {
    marginTop: theme.spacing(2),
  },
  switch: {
    marginTop: theme.spacing(0.5),
  },
  button: {
    marginTop: theme.spacing(3),
  },
}));
