import React from 'react';
import { string, func } from 'prop-types';

import { Body1, IconX } from '@seekube-tech/ui-kit';
import styles from './styles.less';

export const RecipientItem = ({ email, handleRemoveRecipient }) => {


  const handleClick = (e) => {
    e.preventDefault();

    handleRemoveRecipient(e.currentTarget.value);
  }

  return (
    <div className={styles.recipientItem}>
      <Body1 className="text-neutral-600">
        {email}
      </Body1>
      <i
        tabIndex={0}
        role="button"
        onKeyDown={handleClick}
        onClick={handleClick}
        className="flex cursor-pointer text-neutral-500 ml-6"
      >
        <IconX size="12" />
      </i>
    </div>
  )
}

RecipientItem.propTypes = {
  email: string,
  handleRemoveRecipient: func,
}