import React, { useState } from 'react';
import { string, func, bool } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { debounce } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { Overline } from '@seekube-tech/ui-kit';
import { TextField } from '@seekube-tech/ui';
import Icon from '@/components/Icon';
import { toJS } from '@/utils';

const debounceChange = debounce((fct) => fct(), 200);

const Location = ({
  handleLocationChange,
  initialValue,
  disabled = false
}) => {
  const t = useFormatMessage();
  let metaData;
  let helperText;
  const [submitFailed, setSubmitFailed] = useState(isEmpty(initialValue));
  if (!disabled) {
    metaData = {
      error: t({ id: 'event.recruiter.preparation.timeslots.location.required' }),
      submitFailed,
    }
  } else {
    helperText = t({ id: 'event.recruiter.preparation.timeslots.location.message' })
  }

  const onChange = ({ target: { value } }) => {
    debounceChange(() => {
      handleLocationChange(value);
      setSubmitFailed(isEmpty(value));
    });
  };


  return (
    <div style={{ marginBottom: '16px' }}>
      <TextField
        InputProps={{
          defaultValue: initialValue,
          onChange
        }}
        icon={<Icon style={{ width: '21px', height: '21px' }} name="calendar" />}
        meta={metaData}
        input={{
          label: t({ id: 'event.recruiter.preparation.timeslots.location.label' }),
          variant: 'filled',
          required: true,
          disabled
        }}
        fullWidth="100%"
      ></TextField>
      {helperText && <Overline className="text-neutral-400 mt-10">{helperText}</Overline>}
    </div>
  );
};

Location.propTypes = {
  handleLocationChange: func,
  initialValue: string,
  disabled: bool,
};


export default toJS(Location);
