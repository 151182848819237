import React from 'react';
import { node } from 'prop-types';

import { Form } from 'antd';

import styles from './styles.less';

const SearchEngineWrapper = ({ topComponent, bottomComponent }) => (
  <Form className={`${styles.searchFormContainer}`}>
    <div className={styles.searchFormTop}>{topComponent}</div>
    <div className={styles.searchFormBottom}>{bottomComponent}</div>
  </Form>
);

SearchEngineWrapper.propTypes = {
  topComponent: node,
  bottomComponent: node,
};

export default SearchEngineWrapper;
