import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { Col, Row } from 'antd';
import { object } from 'prop-types';
import { useIsDesktop } from '@/utils/MediaQueries';
import informalHelpPicture from '@/assets/images/informalHelpPicture.svg';
import {
  useInfiniteGetOrganizationProfilesPreviewsInformal1to1s
} from '@/queries/informals1to1/useInfiniteGetOrganizationProfilesPreviewsInformal1to1s';
import CardInfo
  from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Exponents/scenes/Exponent/components/CardInfo';
import { getLocale } from '@/utils/localStorage';
import styles from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Exponents/scenes/Exponent/stylesV2.less';
import {
  ShowMoreButton
} from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Exponents/scenes/Exponent/components/ShowMoreButton';
import Informal1to1sList
  from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Exponents/scenes/Exponent/components/Informal1to1s/Informal1to1sList';
import { getId } from '@/utils/global';

const Informal1to1s = ({
  exponent,
  event,
  children
}) => {
  if (!event || !exponent) {
    return null;
  }
  const isDesktop = useIsDesktop();
  const t = useFormatMessage();
  const informal1to1sInfiniteQuery = useInfiniteGetOrganizationProfilesPreviewsInformal1to1s({
    organizationId: getId(exponent._organization),
    eventId: getId(exponent._event)
  });

  if (!informal1to1sInfiniteQuery.data) {
    return null;
  }

  return (
    <Row gutter={[32, 32]}>
      <Col xs={12} xl={4} className={isDesktop ? 'float-right' : ''}>
        <CardInfo title={t({ id: 'stand.sidebar.popoverTitle.informal1to1' }, { label: event.modules?.informal1to1[`labelMultiple_${getLocale()}`] })}
                  description={t({ id: 'candidate.dashboard.stats.informal1to1.popover.description' })}
                  img={informalHelpPicture}
        />
      </Col>

      <Col xs={12} xl={8}>
        <div className={styles.tabLeftContent}>
          {children}
          <Row gutter={[24, 24]}>
            <Informal1to1sList
              informal1to1s={informal1to1sInfiniteQuery}
              event={event}
              isLight
            />
          </Row>
          <ShowMoreButton entityInfiniteQuery={informal1to1sInfiniteQuery} />
        </div>
      </Col>
    </Row>
  )
}

Informal1to1s.propTypes = {
  exponent: object,
  event: object,
  children: object
}

export default Informal1to1s;
