import React from 'react';
import { oneOf, string, object, func } from 'prop-types';
import classnames from 'classnames';

// Components
import Icon from '@/components/Icon';

// Styles & Translations
import styles from './styles.less';

/**
 * Button
 */
const ButtonInline = (props) => {
  const buttonClass = classnames(
    styles[`${props.size}`] || styles.medium,
    styles.buttonInline,
    props.className,
  );

  // Render
  return (
    <a role="button" tabIndex="0" onClick={props.onClick} className={buttonClass} style={props.style}>
      <Icon name={props.icon} className={styles.icon} />
      <span className={styles.text}>{props.text}</span>
    </a>
  );
};

ButtonInline.propTypes = {
  size: oneOf(['small', 'medium', 'large']),
  style: object,
  text: string,
  icon: string,
  onClick: func,
  className: string,
};

export default ButtonInline;
