import React, { useCallback } from 'react';
import { Row, Col } from 'antd';
import { isEmpty } from 'lodash';
import { array, func, string } from 'prop-types';
import classnames from 'classnames';
import { useFormatMessage } from 'react-intl-hooks';

import { Button } from '@seekube-tech/ui-kit';

import { isLabelDark, getLabel } from '../../utils/label';

import styles from './styles.less';

function CornersButton({ corners, onChange, selecteds }) {
  /* Vars */

  const t = useFormatMessage();

  /* Callbacks */

  /**
   * Return true when corner is selected
   * @param {String} id
   * @returns {Boolean}
   */
  // eslint-disable-next-line arrow-body-style
  const isSelected = useCallback((id) => {
    return selecteds.indexOf(id) !== -1;
  }, [selecteds]);

  /* Function */

  const handleClick = (id) => () => {
    let newSelecteds = [...selecteds];

    if (isSelected(id)) {
      newSelecteds = newSelecteds.filter((selectedId) => selectedId !== id);
    } else {
      newSelecteds.push(id);
    }

    onChange(newSelecteds);
  };

  const handleClickAll = () => {
    onChange([]);
  };

  /* Render */

  return !isEmpty(corners) > 0 && (
    <Row type="flex" align="middle" justify="center" className={styles.root} gutter={8}>
      <Col>
        <Button
          onClick={handleClickAll}
          className={classnames(styles.button, styles.all, styles.dark, {
            [styles.notSelected]: selecteds && selecteds.length > 0,
          })}
        >
          {t({ id: 'all' })}
        </Button>
      </Col>

      {corners.map((corner) => (
        <Col key={corner._id}>
          <Button
            onClick={handleClick(corner._id)}
            className={classnames(styles.button, {
              [styles.dark]: isLabelDark(corner.color),
              [styles.notSelected]: isSelected(corner._id) === false,
            })}
            style={{ backgroundColor: corner.color }}
          >
            {getLabel(corner)}
          </Button>
        </Col>
      ))}
    </Row>
  );
}

CornersButton.propTypes = {
  corners: array,
  onChange: func.isRequired,
  selecteds: string,
};

CornersButton.defaultProps = {
  corners: [],
  selecteds: [],
};

export default CornersButton;
