export const CONTEXT_ROLES = {
  RECRUITER: 'recruiter',
  CANDIDATE: 'candidate',
  OWNER: 'owner',
};

export const KEY_DATES_TYPE = {
  DISCOVERING: 'discovering',
  LIVE: 'live',
  JOBFAIR: 'jobfair',
  HUNT: 'hunt',
  INTERACTIONS_CLOSING: 'interactionsClosing',
  APPLICATIONS_CLOSING: 'applicationsClosing',
};

export const SINGLE_DATE = [
  KEY_DATES_TYPE.DISCOVERING,
  KEY_DATES_TYPE.HUNT,
  KEY_DATES_TYPE.INTERACTIONS_CLOSING,
  KEY_DATES_TYPE.APPLICATIONS_CLOSING,
];
