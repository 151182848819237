import request from '@/utils/request';
import { objectToParams } from '@/utils/url'; // eslint-disable-line

const getCalendars = ({ pricing, offset, page, limit = 25, keywords, format, targets, targetContracts, targetLevels, organizationsName, beginAtString, archive }) => {
  const searchParams = objectToParams({ limit, keywords, offset, page, pricing, format, targets, targetContracts, targetLevels, organizationsName, archive, beginAtString });

  // const requestUrl = `${process.env.FRONT_API_URL}/talents/users/${onlyTalentAccess}?${searchParams}&offset=${offset}&page=${page}&timeout=${timeout}&limit=${limit}`;
  const requestUrl = `${process.env.FRONT_API_URL}/events/calendar?${searchParams}`;
  return request(requestUrl, {
    method: 'GET',

  });
};

export { getCalendars };
