import * as React from 'react';
import { func, object } from 'prop-types';
import { Body1, Body2, Caption1, IconArrowRight, IconInfo } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import colors from '@seekube-tech/ui-kit/dist/colors';
import classNames from 'classnames';
import HybridTag from '@/components/HybridTag';
import { groupBy } from 'lodash';
import { EVENT_FORMAT } from '@/utils/constants';
import { Reports } from '../Report/Reports';
import { REPORT_PHASES } from '../../../../const';
import { AddReport } from '../Report/AddReport';
import useStyles from '../../styles';
import styles from './styles.less';

function groupByFormats(reports) {
  return groupBy(reports, 'keyMomentFormat');
}

function HybridScheduling({ reports, onUpdateReport, onDeleteReport, onCreateReport }) {
  const t = useFormatMessage();
  const classes = useStyles();

  const formats = [EVENT_FORMAT.PHYSICAL, EVENT_FORMAT.VIRTUAL];

  return (<>
    <Body1 color={colors.neutral['500']}>
      {t({ id: 'owner.reporting.sending.schedule.subtitle' })}
    </Body1>
    <Caption1 color={colors.neutral['400']}>
      <span className={styles.iconInfo}><IconInfo size="11px" /></span>
      {t({ id: "owner.reporting.sending.schedule.info" })}
    </Caption1>
    <div className={classes.section}>
      <div className={classes.itemCenter}>
        <IconArrowRight className={classes.icon} />
        <Body2 color={colors.neutral['500']} fontWeight={600}>
          {t({ id: 'owner.reporting.registrationAndPreparationOfCompanies.title' })}
        </Body2>
      </div>
      <Body1 color={colors.neutral['400']}>
        {t({ id: 'owner.reporting.registrationAndPreparationOfCompanies.description' })}
      </Body1>
      <Reports
        reports={reports[REPORT_PHASES.registrationAndPreparationOfCompanies]}
        onChange={onUpdateReport}
        onRemove={onDeleteReport}
      />
    </div>

    <div className={classes.section}>
      <div className={classes.itemCenter}>
        <IconArrowRight className={classes.icon} />
        <Body2 color={colors.neutral['500']} fontWeight={600}>
          {t({ id: 'owner.reporting.registrationAndActivitiesOfCandidates.title' })}
        </Body2>
        <AddReport
          phase={REPORT_PHASES.registrationAndActivitiesOfCandidates}
          handleClick={onCreateReport}
          format={EVENT_FORMAT.HYBRID}
        />
      </div>
      <Body1 color={colors.neutral['400']}>
        {t({ id: 'owner.reporting.registrationAndActivitiesOfCandidates.description' })}
      </Body1>

      <Reports
        reports={reports[REPORT_PHASES.registrationAndActivitiesOfCandidates]}
        onRemove={onDeleteReport}
        onChange={onUpdateReport}
      />
    </div>

    <div className={classes.section}>
      <div className={classes.itemCenter}>
        <IconArrowRight className={classes.icon} />
        <Body2 color={colors.neutral['500']} fontWeight={600}>
          {t({ id: 'owner.reporting.appointmentProcessing.title' })}
        </Body2>
      </div>
      <Body1 color={colors.neutral['400']}>
        {t({ id: 'owner.reporting.appointmentProcessing.description' })}
      </Body1>
      <div className={classNames(styles.columns, styles.two)}>
        {formats.map((format, i) => {
          const reportsInFormat = groupByFormats(reports[REPORT_PHASES.appointmentProcessing]);
          return (<div className={classNames({ [styles.first]: i === 0, [styles.last]: i === 1 })}>
            <div className={styles.row}>
              <div className={styles.tagContainer}>
                <HybridTag name={format} />
              </div>
              <AddReport
                phase={REPORT_PHASES.appointmentProcessing}
                handleClick={onCreateReport}
                format={format}
              />
            </div>
            <div className={classNames(styles.reportsContainer, styles.twoForOneColumn)}>
              <Reports
                reports={reportsInFormat[format]}
                onRemove={onDeleteReport}
                onChange={onUpdateReport}
              />
            </div>
          </div>)
        })}
      </div>
    </div>

    <div className={classes.section}>
      <div className={classes.itemCenter}>
        <IconArrowRight className={classes.icon} />
        <Body2 color={colors.neutral['500']} fontWeight={600}>
          {t({ id: 'event.keydates.interactionsClosing.title' })}
        </Body2>
      </div>
      <Body1 color={colors.neutral['400']}>
        {t({ id: 'owner.reporting.interactionsClosing.subtitle' })}
      </Body1>
      <div className={classNames(styles.columns, styles.two)}>
        {formats.map((format, i) => {
          const reportsInFormat = groupByFormats(reports[REPORT_PHASES.closingOfAppointmentSchedules]);
          return (<div className={classNames({ [styles.first]: i === 0, [styles.last]: i === 1 })}>
            <div className={styles.row}>
              <div className={styles.tagContainer}>
                <HybridTag name={format} />
              </div>
            </div>
            <div className={classNames(styles.reportsContainer)}>
              <Reports
                reports={reportsInFormat[format]}
                onRemove={onDeleteReport}
                onChange={onUpdateReport}
              />
            </div>
          </div>)
        })}
      </div>
    </div>

    <div className={classes.section}>
      <div className={classes.itemCenter}>
        <IconArrowRight className={classes.icon} />
        <Body2 color={colors.neutral['500']} fontWeight={600}>
          {t({ id: 'report.pl' }, { count: 1 })}
        </Body2>
      </div>
      <Body1 color={colors.neutral['400']}>
        {t({ id: 'owner.reporting.report.subtitle' })}
      </Body1>
      <div className={classNames(styles.columns, styles.two)}>
        {formats.map((format, i) => {
          const reportsInFormat = groupByFormats(reports[REPORT_PHASES.review]);
          return (<div className={classNames({ [styles.first]: i === 0, [styles.last]: i === 1 })}>
            <div className={styles.row}>
              <div className={styles.tagContainer}>
                <HybridTag name={format} />
              </div>
            </div>
            <div className={classNames(styles.reportsContainer)}>
              <Reports
                reports={reportsInFormat[format]}
                onRemove={onDeleteReport}
                onChange={onUpdateReport}
              />
            </div>
          </div>)
        })}
      </div>
    </div>
  </>)
}

HybridScheduling.propTypes = {
  reports: object,
  onUpdateReport: func,
  onDeleteReport: func,
  onCreateReport: func
}


export default HybridScheduling
