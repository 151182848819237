import { defineMessages } from 'react-intl';

export default defineMessages({
  modalTitle: { // not use
    id: 'event.owner.team.add.recruiters.title',
  },
  modalTitle2: {
    id: 'event.owner.team.add.recruiters.title.b',
  },
  modalTitlePlanners: {
    id: 'event.owner.add.planners.title',
  },
  warningUserMaxExceeded: {
    id: 'preparation.team.recruiter.warning.users.max.exceeded',
  },
});
