import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { TextField } from '@seekube-tech/ui';
import { Field, FormSpy, useField, useFormState } from 'react-final-form';
import { isEmpty } from 'lodash';
import useValidations from '@/utils/validations/useValidations';

import { Button, IconAlignLeft, IconLink, IconTrash2 } from '@seekube-tech/ui-kit';
import styles from './styles.less';

function Video({ field, index, onRemove, disabled }) {
  const t = useFormatMessage();
  const { isUrl, isFilled } = useValidations();
  
  const isTitleValid = (value, { videos }) => {
    const urlValue = videos[index]?.url;
    
    if (!isEmpty(urlValue)) {
      return isFilled(value);
    }
  }
  
  const isUrlValid = (value, { videos }) => {
    const titleValue = videos[index]?.title;
    
    if (!isEmpty(titleValue)) {
      return isFilled(value) || isUrl(value, t({ id: 'form.error.url-video.format' }));
    }
    return isUrl(value, t({ id: 'form.error.url-video.format' }));
  }

  return (
    <Row type="flex" gutter={18} justify='space-between' align='center' className={styles.root}>
      <Col span={4}>
        <Field
          disabled={disabled}
          name={`${field}.title`}
          component={TextField}
          label={t({ id: 'event.recruiter.preparation.stand.item.videos.label' })}
          fullWidth
          validate={isTitleValid}
          icon={<IconAlignLeft />}
        />
      </Col>

      <Col span={6}>
          <Field
            disabled={disabled}
            name={`${field}.url`}
            component={TextField}
            label={t({ id: 'event.recruiter.preparation.stand.item.videos.address.label' })}
            icon={<IconLink />}
            placeholder={t({ id: 'event.recruiter.preparation.stand.item.videos.address.placeholder' })}
            fullWidth
            validate={isUrlValid}
          />
      </Col>

      <Col span={2} className={styles.removeButton}>
        <Button disabled={disabled}
                imageComponentLeft={<IconTrash2 size={16} />}
                variant='tonal' color='error'
                onClick={() => onRemove(index)}>{t({ id: 'delete' })}
        </Button>
      </Col>
    </Row>
  );
}

Video.propTypes = {
  field: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default Video;
