import { useMutation, useQueryClient } from 'react-query';
import request from '@/utils/request';
import requestExternal from '@/utils/requestExternal';

export function useUploadFileToAws(
  {
    onSuccess = () => {},
    ...props
  }) {

  // do not change props cause it populates mutationVariables
  const mutationFn = ({ ...props } ) => {
    const { signedRequest, file } = props;

    return requestExternal(signedRequest, {
      method: 'PUT',
      headers: {
        'Content-Type': file.type,
      },
      body: file,
    })
  }

  return useMutation({
    mutationFn,
    onSuccess: async (_, mutationVariables) => onSuccess(_, mutationVariables),
    ...props,
  });
}
