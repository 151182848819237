import React from 'react';
import { AvatarBusiness, AvatarCandidate, Body1, CardOffer as OfferCard } from '@seekube-tech/ui-kit';
import { object, string } from 'prop-types';
import { Contracts, Locations } from '@/helpers/criterion';
import { ParticipationsIcons } from '@/components/ParticipationsIcons';
import { InteractionsTags } from '@/components/CardOffer/InteractionsTags';
import styles from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Exponents/scenes/Exponent/components/Offers/components/CardOffer/styles.less';
import { getLocale } from '@/utils/localStorage';

const CardOffer = ({
  offer,
  event,
  interaction,
  avatar,
  onClick,
}) => {
  const locale = getLocale();
  const criteria = offer?._event?._criteria || event._criteria;
  const Tags = () => (
    <div className={styles.tags}>
      <InteractionsTags interaction={interaction} />
      <ParticipationsIcons
        context='offer'
        formats={offer.keyMomentFormats}
        className={styles.participationsIcons}
        name={offer._organization.name}
        visible
      />
    </div>
  )

  return (
    <OfferCard
      user={
        <div className="flex items-center mb-4">
          <AvatarCandidate user={offer._user} pictureUrl={offer._user?.pictureUrl} size={40} />
          <Body1 className="ml-16 text-neutral-400">{offer._user.shortName}  @{offer._organization.name}</Body1>
        </div>}
      title={offer.title}
      onClick={onClick}
      moreInfos={
        <div className="flex">
          <Contracts _criteria={criteria} matching={offer.matching} className="mr-12" />
          <Locations locations={offer.locations} formatAddress="shortAddress" />
        </div>}
      tag={<Tags />}
      avatarBusinessProps={avatar && {
        pictureUrl: avatar,
        color: offer._organization?.isPartner ? event.modules.partner?.color : 'default',
        tooltip: event.modules.partner?.[locale === 'fr' ? 'label_fr' : 'label_en'],
      }}
    />
  )
}

CardOffer.propTypes = {
  offer: object,
  event: object,
  interaction: object,
  avatar: string,
}

export default CardOffer;
