import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { func, string } from 'prop-types';
import { Button } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import Html from '@/components/Html';
import styles from './styles.less';
import parentStyles from '../../styles.less';

const copyToClipboard = (value) => {
  function fallbackCopyTextToClipboard(text) {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();

    document.execCommand('copy');

    document.body.removeChild(textArea);
  }
  fallbackCopyTextToClipboard(value);
};

const Success = ({ onValueSelected, testUrl }) => {
  const t = useFormatMessage();

  const [copyPaste, setCopyPasteText] = useState(false);
  const btnText = {
    text: t({ id : copyPaste ? 'copy.success' : 'copy.link' }),
    type: copyPaste ? styles.success : '',
  };

  return (
    <div>
      <FormattedMessage tagName="h2" id="teams.recommendation.success.title" />
      <hr />
      <FormattedMessage tagName="div" id="teams.recommendation.success.text.1" />
      <div className={styles.shareTestContainer}>
        <span>{`${testUrl.substring(0, 50)}...`}</span>
        <a
          role="button"
          className={btnText.type}
          tabIndex={0}
          onClick={() => { copyToClipboard(testUrl); setCopyPasteText(true); }}
        >
          {btnText.text}
        </a>
      </div>
      <Html
        value={t({ id: 'seeklive.recommendation.success.text.2'})
        }
      />
      <div className={parentStyles.actions}>
        <Button onClick={() => { onValueSelected('visio'); }}>
          <FormattedMessage id="seeklive.recommendation.success.btn" />
        </Button>
      </div>
    </div>
  );
};

Success.propTypes = {
  testUrl: string,
  onValueSelected: func,
};

export default Success;
