import React from 'react';
import {
  IconLocationWorld, IconLock, IconMessageCircle2,
  IconXCircle,
} from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { Menu, MenuItem, SubMenuItem } from '@/scenes/Settings/MenuSettings';

export const MenuSettings = ({ handleOnNavigate, view }) => {
  const t = useFormatMessage();
  const isMenuActive = view === 'settings';

  return (

    <Menu>
      <MenuItem
        isActive={isMenuActive}
        onClick={(e) => handleOnNavigate(e, 'settings', 'userSettings')}
      >
        {t({ id: 'event.owner.settings' })}
      </MenuItem>
      <SubMenuItem onClick={(e) => handleOnNavigate(e, 'settings', 'timezone')}>
        <IconLocationWorld />{t({ id: 'profile.settings.timezone.title' })}
      </SubMenuItem>
      <SubMenuItem onClick={(e) => handleOnNavigate(e, 'settings', 'language')}>
        <IconMessageCircle2 />{t({ id: 'event.basic.input.language.label' })}
      </SubMenuItem>
      <SubMenuItem onClick={(e) => handleOnNavigate(e, 'settings', 'password')}>
        <IconLock />{t({ id: 'form.password' })}
      </SubMenuItem>
      <SubMenuItem onClick={(e) => handleOnNavigate(e, 'settings', 'deletion')}>
        <IconXCircle />{t({ id: 'form.accountDeletion' })}
      </SubMenuItem>
    </Menu>
  )
}
