import { track } from '@/utils/analytics';
import { SUPPORTED_CALENDARS } from '@/utils/constants';

const addToCalendarTracking = (event, calendar, trackEventType) => {
  if (SUPPORTED_CALENDARS[calendar]) {
    track({
      name: trackEventType,
      event,
      properties: {
        Calendar: SUPPORTED_CALENDARS[calendar],
      },
    });
  }
}

export default addToCalendarTracking;
