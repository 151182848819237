import React, { useState } from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { Field } from 'react-final-form';
import { bool } from 'prop-types';
import { TextField } from '@seekube-tech/ui';
import Popover from '@/components/Popover';
import {
  H5,
  Button,
  IconTrash2,
  IconPlusCircle,
  IconChevronUp,
  IconChevronDown,
  IconInstagram,
  IconTiktok,
  IconBriefcase,
  IconFacebookFill,
  IconYoutubeFill,
  IconTwitterFill,
  IconLinkedinFill,
  IconGlobe
} from '@seekube-tech/ui-kit';
import classNames from 'classnames';
import styles from './styles.less';

const ExternalLink = ({ disabledFields, values, change}) => {
  const t = useFormatMessage();
  const externalLinkList = Object.keys(values).filter((e) => values[e] !== null);
  const [visible, setVisible] = useState(false);

  const getIcons = (name) => {
    switch (name) {
      case 'facebook':
        return <IconFacebookFill size={16} />
      case 'youtube':
        return <IconYoutubeFill size={16} />
      case 'twitter':
        return <IconTwitterFill size={16} />
      case 'tiktok':
        return <IconTiktok size={16} />
      case 'instagram':
        return <IconInstagram size={16} />
      case 'linkedin':
        return <IconLinkedinFill size={16} />
      case 'website':
        return <IconGlobe size={16} />
      default:
        return <IconBriefcase size={16} />
    }
  }

  const removeExternalLink = (name) => {
    change(`externalLinks.${name}`, null)
  }

  const addExternalLink = (name) => {
    change(`externalLinks.${name}`, '')
  }

  return (
    <div className={styles.keyNumbersContainer}>
      <div className={styles.headerKeyNumbers}>
        <H5>{t({id: 'externalLink.title'})}</H5>
      </div>
      <div className={styles.keyNumbersList}>
        {externalLinkList.map((value, key) => {
          if (values[value] !== null) {
            return (
              <div className={styles.keyNumberField} key={key}>
                <Field
                  name={`externalLinks[${value}]`}
                  id={value}
                  label={t({ id: value })}
                  component={TextField}
                  fullWidth
                  icon={getIcons(value)}
                  disabled={disabledFields}
                  parse={(v) => v}
                />
                <Button
                  disabled={disabledFields}
                  onClick={() => removeExternalLink(value)}
                  variant='tonal'
                  color='error'
                  imageComponentLeft={<IconTrash2 size={16}/>}
                >
                  {t({id: 'delete'})}
                </Button>
              </div>
            )
          }
        })}
      </div>
      <div className={styles.addKeyNumbersContainer}>
        <Popover
          content={
            <div className={styles.popoverFormats}>
              {Object.entries(values).map((value, key) => (
                  <div className={classNames(styles.popoverOption, value[1] !== null && styles.popoverOptionUsed)} onClick={() => value[1] === null && addExternalLink(value[0])} key={key}>
                    <span className={value[1] !== null && styles.iconDisabled}>{getIcons(value[0])}</span>
                    {t({id: value[0]})}
                  </div>
                ))}
            </div>
          }
          trigger="click"
          overlayStyle={{ maxWidth: '430px' }}
          visible={visible}
          placement="bottom"
          getPopupContainer={(trigger) => trigger.parentNode}
          onVisibleChange={() => setVisible(!visible)}
        >
          <Button
            disabled={disabledFields}
            variant='outline'
            imageComponentLeft={<IconPlusCircle size={16} />}
            imageComponentRight={visible ? <IconChevronUp size={16} /> : <IconChevronDown size={16} />}
          >
            {t({ id: 'links.add' })}
          </Button>
        </Popover>
      </div>
    </div>
  );
}

ExternalLink.propTypes = {
  disabledFields: bool,
};

export default ExternalLink;
