import React from 'react';
import { Tag, Caption2 } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { bool, number, string } from 'prop-types';
import { EVENT_FORMAT } from '@/utils/constants';

export const NonHybridSlotsNumber = ({
  exponentFormat,
  isModuleOfferEnable,
  interviewNumberOfTimeslotAvailable,
  informal1to1NumberOfTimeslotAvailable,
}) => {
  const t = useFormatMessage();

  const getExponentAvailableTimeslots = (exponentFormat, timeslotsAvailable) =>
    exponentFormat === EVENT_FORMAT.PHYSICAL ? timeslotsAvailable.physical : timeslotsAvailable.virtual

  const availableSlotsNumber = getExponentAvailableTimeslots(
    exponentFormat, 
    isModuleOfferEnable ? interviewNumberOfTimeslotAvailable : informal1to1NumberOfTimeslotAvailable
  );

  return (
    <>
      <Tag
        className="mr-4"
        variant="tonal"
        color="neutral"
      >
        {availableSlotsNumber.toString()}
      </Tag>
      <Caption2 className="text-neutral-500">
        {t({ id: 'components.bigCalendar.toolbar.available-timeslot-interview' },
          { count: availableSlotsNumber })}
      </Caption2>
    </>
  )
}

NonHybridSlotsNumber.propTypes = {
  exponentFormat: string,
  isModuleOfferEnable: bool,
  interviewNumberOfTimeslotAvailable: number,
  informal1to1NumberOfTimeslotAvailable: number,
}
