const sortOptions = (options, selectedOptions = []) => {
  
  const checked = options.filter((item) => selectedOptions.includes(item.value));
  const notChecked = options.filter((item) => !selectedOptions.includes(item.value));
  
  const sortFunction = (optionA, optionB) => optionB.count - optionA.count
  
  checked.sort(sortFunction);
  notChecked.sort(sortFunction);
  
  return checked.concat(notChecked);
};

const getFacet = (facets, type, id) => facets[`matching.${type}`]?.[id]?.toString() || "0";

const choiceToSelectOption = (facets, items, facetType) => items.map(item => ({ label: item.label, value: item._id, count: getFacet(facets, facetType, item._id) }));

const getSelectedContracts = (contracts, selected) => contracts.filter(contract => selected?.includes(contract._id)).map(contract => contract._id);

const getSelectedLocalisations = (localisations, selected) => localisations.filter(localisation => selected?.includes(localisation._id)).map(localisation => localisation._id);

const getAlgoliaHitsChoices = (hits) => sortOptions(hits.map(hit => ({ label: hit.name, value: hit.objectID, count: hit.countOffers }))); 

export {
  sortOptions,
  choiceToSelectOption,
  getSelectedContracts,
  getSelectedLocalisations,
  getAlgoliaHitsChoices
}
