import React from 'react';
import { any, func, node } from 'prop-types';
import classnames from 'classnames';
import styles from '@/scenes/Settings/MenuSettings/styles.less';


export const MenuItem = ({ children, onClick, isActive = false }) =>(
  <a
    className={classnames(styles.menuItem, isActive ? styles.active : '')}
    onClick={onClick}
    role="button"
    tabIndex={0}
  >
    {children}
  </a>
)


MenuItem.propTypes = {
  children: node,
  onClick: func,
  isActive: any,
}
