import React, { useState } from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { Form } from 'antd';
import Textarea from '@/components/Textarea';

const FormItem = Form.Item;

export const Description = ({ form, user }) => {
  const t = useFormatMessage();
  const [description, setTitle] = useState('');

  return (
    <>
      <FormItem className="textareaWrapper">
        {form.getFieldDecorator('description', {
          initialValue: user?.title || '',
          rules: [{ required: false }],
        })(
          <Textarea
            label={`${t({ id: 'event.recruiter.preparation.signup.identity.form.description.label' })}`}
            onChange={(e) => setTitle(e.target ? e.target.value : '')}
            style={{ height: '8em' }}
          />
        )}
      </FormItem>
    </>
  )
}
