import request from '@/utils/request';

const getExponentsStats = async ({ eventId, eventFormat = 'all' }) => {
  const requestUrl = `${process.env.FRONT_API_URL}/statistics/kpi/event-light?eventId=${eventId}&eventFormat=${eventFormat}`;

  return request(requestUrl);
};

const getExponentsUsers = async ({ eventId, notReady }) => {
  const requestUrl = `${process.env.FRONT_API_URL}/statistics/exponents-users-list?eventId=${eventId}&notReady=${notReady}`;

  return request(requestUrl);
};

const getExponents = async ({ eventId }) => {
  const requestUrl = `${process.env.FRONT_API_URL}/statistics/exponents-list?eventId=${eventId}`;

  return request(requestUrl);
};

const getParticipants = async ({ eventId, eventFormat = 'all' }) => {
  const requestUrl = `${process.env.FRONT_API_URL}/statistics/kpi/event-participants?eventId=${eventId}&eventFormat=${eventFormat}`;

  return request(requestUrl);
};

export { getExponentsStats, getExponentsUsers, getExponents, getParticipants };
