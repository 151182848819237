import React, { useRef, useState } from 'react';
import { object } from 'prop-types';
import classnames from 'classnames';
import { Button, H4, IconArrowUpRight, IconInfo, IconPlusCircle, Table, Pagination } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { useGetDeals } from '@/queries/deals/useGetDeals';
import { Bloc, BlocHeader } from '@/components/Bloc';
import { Columns } from '@/scenes/Admin/scenes/Organizations/scenes/Organization/scenes/Details/components/DealsHandler/Columns';
import CreateOrUpdateDealModal from '@/scenes/Admin/scenes/Organizations/scenes/Organization/scenes/Details/components/DealsHandler/CreateOrUpdateDealModal';

import thisStyles from '@/scenes/Admin/scenes/Organizations/scenes/Organization/scenes/Details/styles.less';

const NOTION_DEAL_URL = "https://www.notion.so/seekube/57bb4f915a32428c882fd4c481aaba4b?v=dd806b3aed3b47b88a1c0f5b13114c18";

const DealsHandler = ({
  organization,
}) => {
  const t = useFormatMessage();
  const containerRef = useRef(null)
  const [currentPage, setCurrentPage] = useState(1);
  const [handleDealModal, setHandleDealModal] = useState({ visible: false, id: null });

  const dealsQuery = useGetDeals({organizationId: organization._id, page: currentPage });

  const { docs: deals, ...pagination } = dealsQuery?.data;


  const handlePaginationChange = (page) => {
    setCurrentPage(page);
    containerRef.current.scrollIntoView();
  }

  return (
    <div ref={containerRef}>
      <Bloc>
        <BlocHeader className={classnames(thisStyles.heading, thisStyles.handleDeals)}>
          <H4>{t({ id: 'admin.organizations.deals.title' })}</H4>
          <div style={{ display: 'flex' }} className={thisStyles.dealsActions}>
            <Button
              onClick={() => window.open(`https://redash.sawkiube.fr/queries/65?p_organizationId=${organization.id}`,'_blank')}
              variant="tonal"
              imageComponent={<IconArrowUpRight size={16} />}
            >
              {t({ id: 'see.in.redash' })}
            </Button>
            <Button
              onClick={() => setHandleDealModal({ visible: true, id: null })}
              variant="fill"
              imageComponent={<IconPlusCircle size={16} />}
            >
              {t({ id: 'event.recruiter.preparation.offer.form.add' })}
            </Button>
          </div>
        </BlocHeader>

        <Table
          isLoadingData={dealsQuery.isFetching}
          columns={Columns({
            actions: {
              onEditClick: (deal) => setHandleDealModal({ visible: true, id: deal._id, deal })
            }})
          }
          data={deals}
          getCustomTableProps={() => ({ style: { width: '100%' } })}
        />
        <div className={thisStyles.seeMoreDeals}>
          <Button
            size="small"
            onClick={() => window.open(NOTION_DEAL_URL, '_blank')}
            variant="tonal"
            imageComponent={<IconInfo />}
          >
            {t({ id: 'see.more.on.deals' })}
          </Button>
        </div>
        <Pagination
          className="flex justify-center mt-8"
          current={pagination ? parseInt(currentPage, 10) : 1}
          pageSize={pagination.limit}
          total={pagination.total || 0}
          onChange={handlePaginationChange}
        />
      </Bloc>
      <CreateOrUpdateDealModal
        currentPage={currentPage}
        organization={organization}
        handleDealModal={handleDealModal}
        setHandleDealModal={setHandleDealModal}
      />
    </div>
  )
}

DealsHandler.propTypes = {
  organization: object,
}

export { DealsHandler }
