import React from 'react';
import { fromJS } from 'immutable';
import PropTypes from 'prop-types';
import { debounce, isEmpty, keys, each } from 'lodash';
import { injectIntl } from 'react-intl';

// Components
import { Table, Input, Tooltip, Select, message } from 'antd';
import { Button, IconX, Toggle } from '@seekube-tech/ui-kit';
import { ALIAS_MAILCHIMP } from '@/utils/constants';
import { icons } from '@/components/Icon/icons';
import Icon from '@/components/Icon';
import EditChoiceModal from '../EditChoiceModal';
import AddChoiceModal from '../AddChoiceModal';
import SpecificModal from '../SpecificModal';
import JobsModal from '../JobsModal';

// Styles & Translations
import styles from './styles.less';

const {Option} = Select;


class DefaultCriteriaTable extends React.PureComponent {
  /**
   * Constructor
   *
   * @param {object} props
   */
  constructor(props) {
    super(props);

    this.state = {
      criteria: props.criteria,
      choicesModal: fromJS({
        editModalIsVisible: false,
        addModalIsVisible: false,
        specificModalIsVisible: false,
        specificModalType: 'regexFormat',
        criterionIndex: null,
        choices: [],
      }),
      jobModalIsOpen: false,
      jobsCriterionSelected: null,
    };
  }

  /**
   * When we receive new props, update all inputs values
   *
   * @param {Array} criteria
   */
  componentWillReceiveProps({ criteria }) {
    this.setState({ criteria });
  }

  /**
   * Handle basic input changed
   *
   * @param {Array} key
   */
  handleBasicInputChange = (key) => (value) => {
    const criteria = this.state.criteria.setIn(key, value);

    this.setState({ criteria }, this.submit);
  };

  /**
   * Handle the switch for enable the criterion
   *
   * @param {Array} key
   */
  handleEnableCriterion = (key) => (value) => {
    let {criteria} = this.state;
    let criterion = this.state.criteria.get(key[0]);

    if (!isEmpty(criterion.getIn(['name']))) {
      if (isEmpty(criterion.getIn(['modules', 'onboarding', 'label']))) {
        criterion = criterion.setIn(['modules', 'onboarding', 'label'], criterion.getIn(['name']));
      }

      if (isEmpty(criterion.getIn(['modules', 'profileParticipant', 'label']))) {
        criterion = criterion.setIn(['modules', 'profileParticipant', 'label'], criterion.getIn(['name']));
      }

      if (isEmpty(criterion.getIn(['modules', 'searchEngineParticipant', 'label']))) {
        criterion = criterion.setIn(['modules', 'searchEngineParticipant', 'label'], criterion.getIn(['name']));
      }

      if (isEmpty(criterion.getIn(['modules', 'searchEngineExponent', 'label']))) {
        criterion = criterion.setIn(['modules', 'searchEngineExponent', 'label'], criterion.getIn(['name']));
      }

      if (isEmpty(criterion.getIn(['modules', 'offer', 'label']))) {
        criterion = criterion.setIn(['modules', 'offer', 'label'], criterion.get('name'));
      }
    }

    const valuesSize = [
      criterion.getIn(['name']).length,
      (criterion.getIn(['icon']) || '').length,
      criterion.getIn(['modules', 'onboarding', 'label']).length,
      criterion.getIn(['modules', 'profileParticipant', 'label']).length,
      criterion.getIn(['modules', 'searchEngineParticipant', 'label']).length,
      criterion.getIn(['modules', 'searchEngineExponent', 'label']).length,
      criterion.getIn(['modules', 'offer', 'label']).length,
    ].reduce((acc, value) => {
      if (value <= 0) {
        acc = false; // eslint-disable-line
      }
      return acc;
    }, true);

    if (!valuesSize) {
      return message.error(this.props.intl.formatMessage({ id: 'owner.settings.criteriaTable.activate.error' }));
    }

    criteria = criteria.set(key[0], criterion);
    // Enable the criterion
    criteria = criteria.setIn(key, value);

    // Update the state
    return this.setState({ criteria }, this.submit);
  };

  /**
   * Handle input type changed
   *
   * @param {Array} key
   */
  handleInputIconChange = (key) => (value) => {
    const criteria = this.state.criteria.setIn([key, 'icon'], value);

    // Update the state
    return this.setState({ criteria }, this.submit);
  };

  /**
   * Set choices in choiceModal and call the function for toggle the modal
   *
   * @param {Number} index
   * @param {string} modalVisible
   */
  handleChoicesClick = (index, modalVisible) => (value, modalType = '') => {
    const choicesModal = this.state.choicesModal
      .set('choices', fromJS(value))
      .set('specificModalType', modalType)
      .set('criterionIndex', index);

    this.setState({ choicesModal }, () => this.toggleChoicesModal(modalVisible));
  };


  handleChoiceModalOnOk = (choices, callback) => {
    const { state: { choicesModal } } = this;

    const criteria = this.state.criteria
      .setIn([choicesModal.get('criterionIndex'), '_choices'], fromJS(choices));

    this.setState({ criteria }, () => {
      this.submit(callback);
    });
  };

  handleSpecificModalOnOk = ({ keysValues, callback }) => {
    const { state: { choicesModal, criteria } } = this;
    let newCriteria = criteria;

    each(
      keys(keysValues),
      (key) => {
        newCriteria = newCriteria.setIn([choicesModal.get('criterionIndex'), key], fromJS(keysValues[key]));
      }
    );

    this.setState({ criteria: newCriteria }, () => {
      this.submit(callback);
    });
  };

  /**
   * Set new choices and close the modal
   *
   * @param {Array} choices
   */
  handleJobsModalOnOk = (choices) => {
    const { state: { choicesModal } } = this;

    const criteria = this.state.criteria
      .setIn([choicesModal.get('criterionIndex'), '_jobs'], fromJS(choices));

    this.setState({ criteria }, () => {
      this.closeJobsModal();
      this.submit();
    });
  };


  toggleChoicesModal = (modalVisible) => {
    const choicesModal = this.state.choicesModal.set(modalVisible, !this.state.choicesModal.get(modalVisible));

    this.setState({ choicesModal });
  };


  closeJobsModal = () => {
    this.setState({ jobsModalIsOpen: false });
  };

  /**
   * Send to the props submit
   *
   * @type {Function}
   */
  submit = debounce((callback) => {
    const { props: { onSubmit }, state: { criteria } } = this;

    onSubmit(criteria, callback);
  }, 1000);

  regexEntries = (value, index) => (
    <div className={styles.choiceActions}>
      <a role="button" tabIndex={0} onClick={() => this.handleChoicesClick(index, 'specificModalIsVisible')(value, 'regexFormat')}>Gérer le format</a>
      <a role="button" tabIndex={0} onClick={() => this.handleChoicesClick(index, 'specificModalIsVisible')(value, 'criteriaLabel')}>Gérer la tooltip</a>
    </div>
  );

  columns = [
    {
      dataIndex: 'enable',
      key: 'enable',
      width: '5%',
      render: (value, criterion, index) => (
        <Toggle
          checked={value}
          onChange={this.handleEnableCriterion([index, 'enable'])}
        />
      ),
    },
    {
      title: this.props.intl.formatMessage({ id: 'owner.settings.criteriaTable.title' }),
      dataIndex: 'name',
      key: 'name',
      width: '27%',
      render: (value, criterion, index) => (
        <Input
          value={value}
          onChange={(e) => this.handleBasicInputChange([index, 'name'])(e.target.value)}
        />
      ),
    },
    {
      title: this.props.intl.formatMessage({ id: 'owner.settings.criteriaTable.choices' }),
      dataIndex: '_choices',
      key: '_choices',
      width: '15%',
      render: (value, criterion, index) => {
        if (criterion.key === 'REGEXID') return this.regexEntries(value, index);
        if (criterion.type === 'MIDDLE') {
          return (
            <div className={styles.choiceActions}>
              <a role="button" tabIndex={0} disabled={this.props.defaultCriterionKeys.includes(criterion.key)} onClick={() => this.handleChoicesClick(index, 'addModalIsVisible')(value)}>{this.props.intl.formatMessage({ id: 'owner.settings.criteriaTable.addChoices' })}</a>
              <a role="button" tabIndex={0} onClick={() => this.handleChoicesClick(index, 'editModalIsVisible')(value)}>{this.props.intl.formatMessage({ id: 'owner.settings.criteriaTable.manageChoices' })}</a>
            </div>
          );
        }

        return <a className={styles.choiceAction} role="button" tabIndex={0} onClick={() => this.handleChoicesClick(index, 'editModalIsVisible')(value)}>{this.props.intl.formatMessage({ id: 'owner.settings.criteriaTable.viewChoices' })}</a>;
      },
    },

    {
      title: this.props.intl.formatMessage({ id: 'owner.settings.criteriaTable.icon' }),
      dataIndex: 'icon',
      key: 'type',
      width: '12%',
      render: (value, criterion, index) => {
        const options = icons.map((icon) => (
          <Option key={icon} value={icon} title={icon}>
            <Icon name={icon} />
          </Option>
        ));

        return (
          <Select style={{ width: '100%' }} value={value} onChange={(e) => this.handleInputIconChange(index)(e)}>
            {options}
          </Select>
        );
      },
    },
    {
      title: this.props.intl.formatMessage({ id: 'owner.settings.criteriaTable.alias' }),
      dataIndex: 'alias',
      key: 'alias',
      width: '20%',
      render: (value, criterion, index) => {
        const options = ALIAS_MAILCHIMP.map((alias) => (
          <Option key={alias} value={alias} title={alias}>
            <span>{alias}</span>
          </Option>
        ));
        return (
          <Select
            value={value}
            onChange={(value) => this.handleBasicInputChange([index, 'alias'])(value)}
          >{options}</Select>
        );
      },
    },
    {
      dataIndex: '_id',
      key: '_id',
      width: '5%',
      render: (value, criterion) => (
        <Tooltip placement="left" title={this.props.intl.formatMessage({ id: 'owner.settings.criteriaTable.deleteTooltip' })}>
          <Button color="error" size="small" onClick={() => this.props.onDeleteCriterion(criterion._id)}><IconX size={16} /></Button>
        </Tooltip>
      ),
    },
  ];

  render() {
    const {
      columns,
      state: { criteria, choicesModal, jobsModalIsOpen, jobsCriterionSelected },
      props: { jobs },
    } = this;

    const criterionIndex = choicesModal.get('criterionIndex');

    return (
      <div>
        <div className={styles.tableContainer}>
          <EditChoiceModal
            isVisible={choicesModal.get('editModalIsVisible')}
            choices={choicesModal.get('choices').toJS()}
            criterion={criteria.toJS()[criterionIndex]}
            onOk={this.handleChoiceModalOnOk}
            onCancel={() => this.toggleChoicesModal('editModalIsVisible')}
            editable={criterionIndex !== null && criteria.toJS()[criterionIndex] ? criteria.toJS()[criterionIndex].type === 'MIDDLE' : false}
          />
          <AddChoiceModal
            isVisible={choicesModal.get('addModalIsVisible')}
            choices={choicesModal.get('choices').toJS()}
            onOk={this.handleChoiceModalOnOk}
            onCancel={() => this.toggleChoicesModal('addModalIsVisible')}
          />
          <SpecificModal
            isVisible={choicesModal.get('specificModalIsVisible')}
            type={choicesModal.get('specificModalType')}
            choices={choicesModal.get('choices').toJS()}
            criterion={criteria.toJS()[criterionIndex]}
            onOk={this.handleSpecificModalOnOk}
            onCancel={() => this.toggleChoicesModal('specificModalIsVisible')}
          />
          <JobsModal
            isVisible={jobsModalIsOpen}
            jobs={jobs.toJS()}
            jobsSelected={jobsCriterionSelected ? jobsCriterionSelected._jobs : []}
            onOk={this.handleJobsModalOnOk}
            onCancel={this.closeJobsModal}
          />
          <Table
            className={styles.criteriaTableContainer}
            rowKey="_id"
            columns={columns}
            dataSource={criteria.toJS()}
            pagination={false}
          />
        </div>
      </div>
    );
  }
}

DefaultCriteriaTable.propTypes = {
  criteria: PropTypes.object,
  jobs: PropTypes.array,
  defaultCriterionKeys: PropTypes.array,
  onSubmit: PropTypes.func,
  onDeleteCriterion: PropTypes.func,
  intl: PropTypes.object
};

export default injectIntl(DefaultCriteriaTable);
