import React, { useMemo, useCallback } from 'react';
import { arrayOf, func, number, object, any, string } from 'prop-types';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment-timezone';
import { useSelector } from 'react-redux';
import { EVENT_FORMAT } from '@/utils/constants';
import { getEventKeyMoments } from '@/helpers/event/getEventKeyMoments';
import BigCalendarToolbar from '@/features/agenda/recruiter/components/BigCalendar/BigCalendarToolbar/BigCalendarToolbar';
import BigCalendarEvents from '@/features/agenda/recruiter/components/BigCalendar/BigCalendarEvents/BigCalendarEvents';
import BigCalendarWeekHeader from '@/features/agenda/recruiter/components/BigCalendar/BigCalendarWeekHeader';
import { EVENT_TYPE, EVENTS_COLORS } from '@/features/agenda/recruiter/components/BigCalendar/const';
import './styles.less';
import { getAuthUser } from '@/store/auth/selectors';
import { getCurrentDate } from '@/features/agenda/recruiter/helpers/getCurrentDate';

function BigCalendar({
  indexWeek,
  currentEvent,
  handleWeekChange,
  handleDeleteTimeSlots,
  calendarSlots,
  handleOpenSetting,
  settings,
  onSelectSlot,
  handleEventResize,
  handleDeleteEvent,
  handleOnInformalDescriptionChange,
  exponentFormat,
  eventKeyMoments,
  changeEventKeyMoment,
  downloadAllCv,
  isDeleteModeActive,
  setSlotSelectedForDelete,
  slotSelectedForDelete
}) {
  const authUser = useSelector(getAuthUser)?.toJS();
  const timezone = authUser.timezone ? authUser.timezone.utc[0] : null;
  const jobfairs = calendarSlots?.filter(event => event.type === EVENT_TYPE.JOBFAIR);

  const intlMoment = moment.tz.setDefault(timezone);

  const currentDate = getCurrentDate(currentEvent, indexWeek, exponentFormat);

  if (!currentDate) return null;

  // Force default settings and force monday at first day of week
  intlMoment.locale(authUser.locale, {
    dow: 1,
  });
  intlMoment.locale(authUser.locale);

  const localizer = momentLocalizer(intlMoment);

  const jobfairsColor = useMemo(() => {
    const hashmap = {};

    jobfairs.forEach((jobfair, index) => {
      hashmap[jobfair._event] = EVENTS_COLORS[(index % 10)];
    });

    return hashmap;
  }, [jobfairs]);

  /* Callbacks */

  /**
   * Return a className for disabled date when jobfair select out of the date param
   * @returns {Object}
   */
  const handleSlotPropGetter = useCallback((date) => {
    const newDate = moment.tz(date, timezone);

    if (currentEvent) {
      let isBetween;

      // Dans le cas d'un jobfair hybride si un format est définit on disable les dates de l'autre format
      if (exponentFormat === EVENT_FORMAT.HYBRID && currentEvent.format === EVENT_FORMAT.HYBRID && !settings.isVisible) {
        const isBetweenPhysical = newDate.isSameOrAfter(getEventKeyMoments(currentEvent, { type: 'jobfair', format: EVENT_FORMAT.PHYSICAL }).beginAt) && newDate.isSameOrBefore(getEventKeyMoments(currentEvent, { type: 'jobfair', format: EVENT_FORMAT.PHYSICAL }).endAt);
        const isBetweenVirtual = newDate.isSameOrAfter(getEventKeyMoments(currentEvent, { type: 'jobfair', format: EVENT_FORMAT.VIRTUAL }).beginAt) && newDate.isSameOrBefore(getEventKeyMoments(currentEvent, { type: 'jobfair', format: EVENT_FORMAT.VIRTUAL }).endAt);
        isBetween = isBetweenPhysical || isBetweenVirtual;
      }
      else {
        isBetween = newDate.isSameOrAfter(eventKeyMoments.beginAt) && newDate.isSameOrBefore(eventKeyMoments.endAt);
      }
      if (isBetween === false || newDate.isSameOrBefore(moment())) {
        return {
          className: 'disableDay',
        };
      }
    }

    return {};
  }, [timezone, currentEvent, eventKeyMoments, settings.isVisible]);

  const handleSelectSlot = (slot) => {
    const start = moment(slot.start);

    if (start.isAfter(moment())) {
      onSelectSlot(slot);
    }
  };

  const getTimezoneOffset = () => {
    if (currentEvent.timezone) {
      return authUser?.timezone?.offset - currentEvent?.timezone?.offset > -7 ? authUser?.timezone?.offset - currentEvent?.timezone?.offset : -7;
    }
    return 0;
  }

  const timezoneOffset = getTimezoneOffset();

  return (
    <Calendar
      selectable={!isDeleteModeActive}
      localizer={localizer}
      events={calendarSlots}
      exponentFormat={exponentFormat}
      defaultView={Views.WEEK}
      timeslots={4}
      step={15}
      min={moment().hours(timezoneOffset < 0 ? 7 + timezoneOffset : 7).minutes(0).seconds(0).milliseconds(0).toDate()}
      drilldownView={false}
      components={{
        toolbar: () => (
          <BigCalendarToolbar
            downloadAllCv={downloadAllCv}
            currentEvent={currentEvent}
            indexWeek={indexWeek}
            handleWeekChange={handleWeekChange}
            handleDeleteTimeSlots={handleDeleteTimeSlots}
            timeslots={calendarSlots}
            handleOpenSetting={handleOpenSetting}
            exponentFormat={exponentFormat}
            changeEventKeyMoment={changeEventKeyMoment}
          />),
        week: {
          event: (props) => (
            <BigCalendarEvents
              settings={settings}
              jobfairsColor={jobfairsColor}
              jobfairs={jobfairs}
              timezone={timezone}
              handleDeleteEvent={handleDeleteEvent}
              currentEvent={currentEvent}
              handleOnInformalDescriptionChange={handleOnInformalDescriptionChange}
              informalDescription={settings.informalDescription}
              isDeleteModeActive={isDeleteModeActive}
              setSlotSelectedForDelete={setSlotSelectedForDelete}
              slotSelectedForDelete={slotSelectedForDelete}
              {...props}
            />
          ),
          header: (props) => (
            <BigCalendarWeekHeader exponentFormat={exponentFormat} event={currentEvent} {...props} />
          ),
        },
      }}
      formats={{
        dateFormat: 'LLL',
        timeGutterFormat: 'HH[h]',
      }}
      onSelectSlot={handleSelectSlot}
      date={currentDate}
      onEventResize={handleEventResize}
      slotPropGetter={handleSlotPropGetter}
    />
  );
}

BigCalendar.propTypes = {
  indexWeek: number,
  currentEvent: object,
  handleWeekChange: func,
  handleDeleteTimeSlots: func,
  calendarSlots: arrayOf(object), // Events compose timeslots and jobfair event (all day)
  handleOpenSetting: func,
  settings: object,
  onSelectSlot: func,
  currentDate: any,
  handleEventResize: func,
  handleDeleteEvent: func,
  handleOnInformalDescriptionChange: func,
  informalDescription: string,
  exponentFormat: string,
  eventKeyMoments: object,
  changeEventKeyMoment: func,
  downloadAllCv: func,
  isDeleteModeActive: func,
  setSlotSelectedForDelete: func,
  slotSelectedForDelete: func,
};

export default BigCalendar;
