import React from 'react';
import { func, object } from 'prop-types';

// Components
import { FormattedMessage } from 'react-intl';
import { Form, Input } from 'antd';
import { Button } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { Bloc, BlocHeader } from '@/components/Bloc';

// Services
import ApiToForm from '../../../../services/formMapping/ApiToForm';

// Antd
const FormItem = Form.Item;

const HuntForm = ({
  form: { validateFields, getFieldDecorator },
  initialValues,
  onSubmit,
}) => {
  const t = useFormatMessage();
  
  const handleSubmit = (e) => {
    e.preventDefault();

    return validateFields(onSubmit);
  };

  return (
    <Bloc>
      <Form onSubmit={handleSubmit} style={{ maxWidth: '500px', textAlign: 'left' }} layout="vertical">
        {/*  FormName */}
        <BlocHeader>
          <FormattedMessage id="company" values={{ count: 2 }} tagName="h2" />
        </BlocHeader>

        {/* FR Label */}
        <FormItem label={<FormattedMessage id="event.modules.activation.and.tabs.label.fr" />}>
          {getFieldDecorator('label_fr', {
            initialValue: ApiToForm.module(initialValues, 'organization', 'label_fr'),
            rules: [{ required: true, message: 'Merci de renseigner le label' }],
          })(
            <Input placeholder="Entreprise" />
          )}
        </FormItem>

        {/* FR label multiple */}
        <FormItem label={<FormattedMessage id="event.modules.activation.and.tabs.label.fr.pl" />}>
          {getFieldDecorator('labelMultiple_fr', {
            initialValue: ApiToForm.module(initialValues, 'organization', 'labelMultiple_fr'),
            rules: [{ required: true, message: 'Merci de renseigner le label' }],
          })(
            <Input placeholder="Entreprises" />
          )}
        </FormItem>

        {/* EN Label */}
        <FormItem label={<FormattedMessage id="event.modules.activation.and.tabs.label.en" />}>
          {getFieldDecorator('label_en', {
            initialValue: ApiToForm.module(initialValues, 'organization', 'label_en'),
            rules: [{ required: true, message: 'Merci de renseigner le label' }],
          })(
            <Input placeholder="Company" />
          )}
        </FormItem>

        {/* EN label multiple */}
        <FormItem label={<FormattedMessage id="event.modules.activation.and.tabs.label.en.pl" />}>
          {getFieldDecorator('labelMultiple_en', {
            initialValue: ApiToForm.module(initialValues, 'organization', 'labelMultiple_en'),
            rules: [{ required: true, message: 'Merci de renseigner le label' }],
          })(
            <Input placeholder="Companies" />
          )}
        </FormItem>

        {/* Submit */}
        <div>
          <Button type="submit">
            {t({ id: 'save' })}
          </Button>
        </div>
      </Form>
    </Bloc>
  );
};

HuntForm.propTypes = {
  onSubmit: func.isRequired,
  form: object,
  initialValues: object,
};

export default Form.create()(HuntForm);
