import React from 'react';
import { object } from 'prop-types';
import { Badge } from 'antd';
import { NavLink } from 'react-router-dom';
import { useFormatMessage } from 'react-intl-hooks';
import { NavScenePortal } from '@/containers/Layout';
import styles from './styles.less';


const overflowCount = 9999;

const NavJobdating = ({ event, match, stats }) => {
  const t = useFormatMessage();

  return (
    <NavScenePortal>
      <div className={styles.container}>
        <div className={styles.navEventContainer}>
          <div className={styles.navEvent}>
            <div className={styles.navEventItem}>
              <NavLink to={`${match.url}/sourcing`} activeClassName="activeNavLink">
                <Badge count={stats.sourcing} showZero overflowCount={overflowCount}/>
                <span>{t({ id: 'participants.results.sourcing' })}</span>
              </NavLink>
            </div>

            <div className={styles.navEventItem}>
              <NavLink to={`${match.url}/applications`} activeClassName="activeNavLink">
                <Badge count={stats.applications} showZero overflowCount={overflowCount}/>
                <span>{t({ id: `participants.results.${event.skipAppointmentValidation ? 'applications' : 'applications2'}` }, { count: stats.applications })}</span>
              </NavLink>
            </div>

            <div className={styles.navEventSeparator} />

            <div className={styles.navEventItem}>
              <NavLink
                to={`${match.url}/interview${(event.areInteractionsClosed && event.skipAppointmentValidation) || !event.skipAppointmentValidation ? '/confirmed' : ''}`}
                activeClassName="activeNavLink"
                className={window.location.pathname.indexOf('/interview/') > -1 ? 'activeNavLink' : null}
              >
                <Badge count={stats.allInterview} showZero overflowCount={overflowCount}/>
                <span>{t({ id: 'participants.results.interview' }, { count: stats.allInterview })}</span>
              </NavLink>
            </div>

            <div className={styles.navEventSeparator}/>

            <div className={styles.navEventItem}>
              <NavLink to={`${match.url}/top`} activeClassName="activeNavLink">
                <Badge count={stats.top} showZero overflowCount={overflowCount}/>
                <span>{t({ id: 'participants.results.top' })}</span>
              </NavLink>
            </div>

            <div className={styles.navEventItem}>
              <NavLink to={`${match.url}/save`} activeClassName="activeNavLink">
                <Badge count={stats.save} showZero overflowCount={overflowCount}/>
                <span>{t({ id: 'participants.results.save' })}</span>
              </NavLink>
            </div>

            <div className={styles.navEventItem}>
              <NavLink to={`${match.url}/refuse`} activeClassName="activeNavLink">
                <Badge count={stats.refuse} showZero overflowCount={overflowCount}/>
                <span>{t({ id: 'participants.results.refuse' })}</span>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </NavScenePortal>
  )
};

NavJobdating.propTypes = {
  event: object,
  match: object,
  stats: object,
};

export default NavJobdating;
