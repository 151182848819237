import React from 'react';
import { func } from 'prop-types';
import { IconMapPin, IconWifi, Tab } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { EVENT_FORMAT } from '@/utils/constants';

export function HybridTabs({ changeView }) {
  const t = useFormatMessage();

  return (
    <Tab.Group defaultIndex={0} className="grow mr-5">
      <Tab.List>
        <Tab as="span" icon={<IconMapPin size={16} />} onClick={() => changeView(EVENT_FORMAT.PHYSICAL)}>
          {t({ id: 'exponent.changeView.physical' })}
        </Tab>
        <Tab as="span" icon={<IconWifi size={16} />} onClick={() => changeView(EVENT_FORMAT.VIRTUAL)}>
          {t({ id: 'exponent.changeView.virtual' })}
        </Tab>
      </Tab.List>
    </Tab.Group>
  )
}

HybridTabs.propTypes = {
  changeView: func
}
