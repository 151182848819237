import React from 'react';
import { string, func } from 'prop-types';
import { connect } from 'react-redux';
import { Dropdown, Menu } from 'antd';
import { IconDownload, IconChevronDown,  Button } from '@seekube-tech/ui-kit';

import { trackOwnerExportedLiveData } from '@/utils/analytics';

import { useFormatMessage } from 'react-intl-hooks'; // eslint-disable-line
import { useDownloadFile } from '@/utils/hooks';

import { notificationActions } from '@/store/notification';

const { Item: MenuItem } = Menu;

const DownloadLives = ({ slug, sendNotification }) => {
  const url = `${process.env.FRONT_API_URL}/events/${slug}/export/conferences?apiKey=41a1289e-fee1-430e-a849-5b2b7ed23f1a`;
  const fileName = `Liste des lives-${slug}.xlsx`;

  const urlParticipants = `${process.env.FRONT_API_URL}/events/${slug}/export/conferences-attendees?apiKey=41a1289e-fee1-430e-a849-5b2b7ed23f1a`;
  const fileNameParticipants = `Liste des participants des lives-${slug}.xlsx`;

  const t = useFormatMessage();
  const [onDownload] = useDownloadFile(
    url,
    fileName,
    () => {sendNotification({ message: t({ id: 'download.error' }), kind: 'success',})},
    () => {
      trackOwnerExportedLiveData({ dataType: 'all' })
      sendNotification({ message: t({ id: 'download.upcoming' })
      })},
  )

  const [onDownloadParticipants] = useDownloadFile(
    urlParticipants,
    fileNameParticipants,
    () => {sendNotification({ message: t({ id: 'download.error' }), kind: 'success',})},
    () => {
      trackOwnerExportedLiveData({ dataType: 'all' })
      sendNotification({ message: t({ id: 'download.upcoming' })
      })},
  )

  return (

    <Dropdown
    overlay={
      <Menu>
        <MenuItem key={0}>
          <a role="button" tabIndex={0} onClick={onDownload}>
            {t({ id: 'export.data.lives' }) }</a>
        </MenuItem>
        <MenuItem key={1}>
          <a role="button" tabIndex={0} onClick={onDownloadParticipants}>
            {t({ id: 'export.data.live.participants' })}</a>
        </MenuItem>
      </Menu>
    }
    trigger={['click']}
    placement="bottomRight"
    >
    <Button
      variant="outline"
      color="neutral"
      imageComponentLeft={<IconDownload size={16} />}
      imageComponentRight={<IconChevronDown size={16} />}
    >
      {t({ id: 'export' })}
    </Button>
    </Dropdown>
  )
}

DownloadLives.propTypes = {
  slug: string,
  sendNotification: func,
}

const mapDispatchToProps = {
  sendNotification: notificationActions.sendNotification,
};
const withConnect = connect(null, mapDispatchToProps);

export default withConnect(DownloadLives)
