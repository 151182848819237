import React, { useEffect } from 'react';
import { array, func, object } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { isEmpty } from 'lodash';

import { toJS } from '@/utils';

// Store
import { eventSelectors, eventActions } from '@/store/event';
import { exponentSelectors, exponentActions } from '@/store/exponent';
import { sectorSelectors, sectorActions } from '@/store/sector';

import LoadingIndicator from '@/components/LoadingIndicator';

import StandCandidate
  from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Exponents/scenes/Exponent/StandCandidate';

const Stand = ({ getEvent, getExponent, exponent, getSectors, event, sectors, match }) => {
  useEffect(() => {
    getSectors();

    getEvent(match.params.eventSlug);

    getExponent({
      exponentId: match.params.exponentID,
      eventId: match.params.eventSlug,
      postVisit: false,
    });
  }, []);

  if (!event || !exponent || isEmpty(sectors)) {
    return (<LoadingIndicator />);
  }

  return (
    <StandCandidate
      sectors={exponent._organization.profile.sectors}
      showSideBar={false}
      match={match}
    />
  );
};

Stand.propTypes = {
  event: object,
  match: object,
  exponent: object,
  sectors: array,
  getEvent: func,
  getExponent: func,
  getSectors: func,
};

const mapStateToProps = createStructuredSelector({
  event: eventSelectors.getCurrentEvent,
  exponent: exponentSelectors.getCurrentExponent,
  sectors: sectorSelectors.getAllSectors,
});

const mapDispatchToProps = {
  getEvent: eventActions.getEvent,
  getExponent: exponentActions.getExponent,
  getSectors: sectorActions.getSectors,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(toJS(Stand));
