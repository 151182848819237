import { useMutation } from 'react-query';
import request from '@/utils/request';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { trackSignout } from '@/utils/analytics';
import Cookies from 'js-cookie';
import { isEmpty, isUndefined } from 'lodash';
import * as actions from '@/store/auth/actions';
import { resetParticipant } from '@/store/participant/actions';
import { USERS_TYPE } from '@/utils/constants';

export function usePostUserLogout({ authUser }) {
  const dispatch = useDispatch();
  const history = useHistory();
  
  const mutationFn = () => {
    const requestUrl = `${process.env.FRONT_API_URL}/users/logout`;

    return request(requestUrl, { method: 'POST' });
  }

  return useMutation({
    mutationFn,
    onSuccess: async () => {
      const { mixpanel } = window;
      const analytics = window.analytics || null;
      trackSignout();
      
      Cookies.remove("seekube.id");
      Cookies.remove("seekube.id.sig");
      Cookies.remove("remember_me");
      Cookies.remove("remember_me.sig");

      if (!isEmpty(mixpanel) && !isEmpty(mixpanel.cookie) && !isUndefined(mixpanel.cookie)) {
        mixpanel.cookie.clear();
      }
    
      if (!isEmpty(analytics)) {
        analytics.reset();
      }
      
      dispatch(actions.authLogoutUser());
      dispatch(resetParticipant());
      
      const userType = authUser?._currentOrganization ? USERS_TYPE.recruiter : USERS_TYPE.candidate;
      
      history.replace(`/auth/login/${userType}`);
    }
  });
}
