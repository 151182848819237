import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { func, array, any, number } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { map, intersection, isEmpty, head, filter, compact, size, get } from 'lodash';
import { withRouter } from 'react-router-dom';
import { Button } from '@seekube-tech/ui-kit';
import { notificationActions } from '@/store/notification';
import { toJS } from '@/utils';
import { getId } from '@/utils/global';
import { isUpcoming } from '@/utils/date';


import { withAuth } from '@/containers/RequiredAuth';
import { Events } from '../../../EditOffer/components/EditOfferStep2/components';
import { TargetFilter } from '../../../../../Events/scenes/components/JobdatingsExplore/components';


import messages from '../../../EditOffer/components/EditOfferStep2/messages';
import stylesBtn from '../../../EditOffer/styles.less';
import styles from './styles.less';
import { WarningNoDescription } from '../Warnings';

const Step1 = (
  {
    defaultTargets,
    exponents,
    offers,
    setOffer,
    saveEventSelected,
    handleNext,
    handleActions,
    offersWithoutDescriptionSize,
    blank,
}) => {
  const exponentsAvailable = filter(exponents, (expo) => !isEmpty(expo.users) && !isEmpty(filter(expo.users, (u) => get(u, '_user.isActive'))) && isUpcoming(expo._event.keyDates.jobfair.endAt) && expo._event.withOfferModule);
  const [eventsIds, setEventsIds] = useState(compact([window.localStorage.getItem('eventSelected')]));
  const [exponentsVisible, setExponentsVisible] = useState(exponentsAvailable);
  const [targets, setTargets] = useState(blank ? [] : defaultTargets);

  const offersSelectedSize = size(offers);

  const getEvents = () => (map(exponentsAvailable, (exponent) => exponent._event));

  const saveForm = () => {
    if (size(eventsIds)) {
      const events = getEvents();

      const eventSelected =
        eventsIds
          .filter((eventId) => !isEmpty(events.find((evnt) => getId(evnt._id) === eventId)))
          .map((eventId) => ({ _event: events.find((evnt) => getId(evnt) === eventId) }));

      window.localStorage.setItem('eventSelected', getId(head(eventSelected)._event));

      saveEventSelected(head(eventSelected)._event);
      handleNext(offers);
    }
  };

  const handleOnTargetsFilter = (targets) => {
    const exponentsVisible = filter(exponentsAvailable, (exponent) => intersection(exponent._event.targets, targets).length);
    setExponentsVisible(exponentsVisible);
    setTargets(targets);
  };

  const handleEventsSelected = (eventsId) => {
    setOffer({ _events: eventsId });
    setEventsIds(eventsId);
  };

  const disableNextBtn = isEmpty(offers) || isEmpty(exponentsVisible.find((expo) => expo && eventsIds.indexOf(getId(expo._event)) > -1));

  return (
    <div className={styles.step2Container}>
      <FormattedMessage id="recruiter.offers.spread.select.event.title" values={{ count: offersSelectedSize }} tagName="h1" />
      <WarningNoDescription count={offersWithoutDescriptionSize} offersSelectedSize={offersSelectedSize} />
      <FormattedMessage id="recruiter.offers.spread.select.event.subtitle" tagName="h3" />
      <div className={styles.targetFilterContainer}>
        <TargetFilter targetFilters={['tech', 'business', 'engineer']} targets={targets} onTargetsChange={handleOnTargetsFilter} />
      </div>
      {blank || <Events
        defaultValue={eventsIds}
        exponents={exponentsVisible.map((exp) => ({ ...exp, offersSelectedSize }))}
        events={exponentsVisible.map((expo) => expo._event)}
        handleEventsSelected={handleEventsSelected}
        context="offer"
        multi={false}
      />}
      <div className={stylesBtn.submitContainer}>
        <Button variant="tonal" className="mr-6" onClick={() => handleActions('openBackModal')}>
          <FormattedMessage id="cancel" />
        </Button>
        <Button disabled={disableNextBtn} onClick={saveForm}>
          <FormattedMessage {...messages.next} />
        </Button>
      </div>
    </div>
  );
};

Step1.propTypes = {
  defaultTargets: array,
  exponents: array,
  offers: array,
  saveEventSelected: func,
  handleNext: func,
  setOffer: func,
  handleActions: func,
  blank: any,
  offersWithoutDescriptionSize: number,
};


const withConnect = connect(null, {
  sendNotification: notificationActions.sendNotification,
});

export default compose(
  withRouter,
  withConnect,
  withAuth(),
)(toJS(Step1));
