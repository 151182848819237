import React from 'react';
import { object } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { Tag } from '@seekube-tech/ui-kit';
import { getKeyMomentFormats } from '@/helpers/exponent/getKeyMomentFormats';
import { EVENT_FORMAT } from '@/utils/constants';

const KeyMomentsTag = ({ exponent }) => {
  const formats = exponent._event.format === EVENT_FORMAT.HYBRID ? getKeyMomentFormats(exponent) : [exponent._event.format];
  const t = useFormatMessage();

  return (
    <Tag variant="outline" color="neutral">
      {formats.map((format, i) =>
        <span key={i}>{i === 1 && ' + '} {t({ id: `event.owner.settings.keyMoments.input.tags.${format}` })}</span>
      )}
    </Tag>
  );
}

KeyMomentsTag.propTypes = {
  exponent: object,
}

export { KeyMomentsTag }