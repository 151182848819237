import React from 'react';
import { Field, useForm } from 'react-final-form';
import { any, bool, func, string } from 'prop-types';
import { Textarea } from '@seekube-tech/ui-kit';
import { isEmpty } from 'lodash';
import useValidations from '@/utils/validations/useValidations';
import { getFieldError } from '@/utils/forms/final-form/helpers';

export const FormTextAreaField = ({
  name,
  id,
  label,
  placeholder = '',
  defaultValue,
  validate,
  maxLength,
  required
}) => {
  const form = useForm();
  const error = getFieldError(form.getState().errors, name);
  const { isFilled } = useValidations();
  
  const validation = (value) => {
    const isFilledError = isFilled(value);
    
    if (required && !isEmpty(isFilledError)) {
      return isFilledError;
    }
    if (validate) {
      return validate(value);
    }
  }
  
  const onChange = (event) => {
    form.change(name, event.target.value);
  }
  
  return (
    <Field
      name={name}
      id={id}
      label={label}
      placeholder={placeholder}
      defaultValue={defaultValue}
      validate={validation}
    >
      {props => (
        <Textarea
          value={props.input.value}
          onChange={onChange}
          maxLength={maxLength}
          placeholder={null}
          className="w-full"
          label={label}
          message={error}
          color={error && 'error'}
          required={required}
        />
      )}
    </Field>
  )
}

FormTextAreaField.propTypes = {
  name: string,
  id: string,
  label: string,
  placeholder: string,
  defaultValue: any,
  validate: func,
  required: bool,
  maxLength: string
}