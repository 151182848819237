import * as React from 'react';
import { IconMapPin, IconWifi, Tooltip, TooltipContent, TooltipTrigger  } from '@seekube-tech/ui-kit';
import { oneOf, string } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { EVENT_FORMAT } from '@/utils/constants';

const IconsHybrid = ({ hybridFormat, name }) => {
  const t = useFormatMessage();

  function getTitle () {
    switch (hybridFormat) {
      case 'all': return t({ id: 'hybridIcons.tooltip.candidate.participate.virtual.and.physical' }, { name })
      case 'physical': return t({ id: 'hybridIcons.tooltip.candidate.participate.physical' }, { name })
      case 'virtual': return t({ id: 'hybridIcons.tooltip.candidate.participate.virtual' }, { name })

      default: return undefined;
    }
  }
  return (
    <Tooltip placement="top">
      <TooltipTrigger>
      {
        (() => {
        switch (hybridFormat) {
          case 'all':
            return (
              <div className="flex justify-center items-center border rounded-md border-neutral-600 text-neutral-600 bg-basic-white px-8 py-4">
                <div className="flex justify-center items-center h-16 mr-6">
                  <IconWifi size={16} />
                </div>
                <div className="border-neutral-600 border-l flex justify-center items-center h-16 pl-6">
                  <IconMapPin size={16} />
                </div>
              </div>
            )
          case 'physical':
            return (
              <div className="flex justify-center items-center border rounded-md border-neutral-600 text-neutral-600 bg-basic-white px-8 py-4">
                <IconMapPin size={16} />
              </div>
            )
          case 'virtual':
            return (
              <div className="flex justify-center items-center border rounded-md border-neutral-600 text-neutral-600 bg-basic-white px-8 py-4">
                <IconWifi size={16} />
              </div>
            )
          default:
            return <div />;
        }})()
      }
      </TooltipTrigger>
      <TooltipContent>
        {getTitle()}
      </TooltipContent>
    </Tooltip>
  )
}


IconsHybrid.propTypes = {
  hybridFormat: oneOf(['all', EVENT_FORMAT.PHYSICAL, EVENT_FORMAT.VIRTUAL]),
  name: string,
}


export { IconsHybrid };