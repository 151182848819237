import React from 'react';
import { string, func } from 'prop-types';

// Components
import { Modal as ModalAntd } from 'antd';

// Styles & Translations
import styles from './styles.less';

/**
 * ModalFooter
 * @param {object} props
 */
const ModalFooter = (props) => (
  <div className={styles.footerContainer}>
    <button className={styles.valid} onClick={props.onOk} >{props.okText}</button>
  </div>
);

/**
 * Modal
 * @param {object} props
 */
const Modal = (props) => (
  <ModalAntd
    footer={<ModalFooter {...props} />}
    {...props}
  />
);

ModalFooter.propTypes = {
  okText: string,
  onOk: func,
};

ModalFooter.defaultProps = {
  okText: 'OK',
};

export default Modal;
