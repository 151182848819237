import moment from 'moment';
import { compact, uniq } from 'lodash';

/**
 * CreateCalendarEvents
 *
 * @description
 * Return all slugs where a batch of slots are added
 *
 */
export const getSlugsWhereSlotsAreAdded = ({
  slots = [],
  events,
  currentEvent,
  isMultiEvents = false,
}) => {
  const slugs = isMultiEvents ? [] : [currentEvent.slug];

  if (isMultiEvents) {
    slots
      .forEach(
        (slot) => {
          slugs
          .push(
            ...events
              .filter((event) => moment(slot.beginAt).isBetween(event.keyDates.jobfair.beginAt, event.keyDates.jobfair.endAt))
              .map((event) => event.slug)
          );
        });
  }

  return uniq(compact(slugs));
};
