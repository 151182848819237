import React from 'react';
import { compact, isEmpty, keys } from 'lodash';
import { bool, func, object, shape, string } from 'prop-types';

import { Menu } from 'antd';
import { FormattedMessage } from 'react-intl';
import Icon from '@/components/Icon';

import messages from './messages';

const OverlayWithObject = ({ properties = {} }) => {
  const defaultActions = { condition: true, onClick: () => {}, icon: null, text: messages.empty };
  return (
    <Menu>
      {compact(
        keys(properties).map((key, i) => {
          const action = { ...defaultActions, ...properties[key] };

          if (action.condition && !isEmpty(action.href) && action.targetBlank) {
            return (
              <Menu.Item key={i}>
                <a tabIndex={i} role="button" href={action.href} target="_blank"><Icon name={action.icon} /><FormattedMessage {...action.text} /></a>
              </Menu.Item>
            );
          }

          return (
            action.condition ?
              <Menu.Item key={i}>
                <a tabIndex={i} role="button" onClick={action.onClick}><Icon name={action.icon} /><FormattedMessage {...action.text} /></a>
              </Menu.Item>
              : null
          );
        }))
      }
    </Menu>
  );
};

OverlayWithObject.propTypes = {
  properties: shape({ condition: bool, onClick: func, icon: string, text: object }),
};

export { OverlayWithObject };
