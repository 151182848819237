import React from 'react';
import { Link } from 'react-router-dom';
import { If, Then } from 'react-if';
import { isEmpty } from 'lodash';

// Components
import { OfferCardListParticipant, OfferCardParticipant } from '@/components/OfferCard';
import { H1, H2 } from '@/components/Title';
import Icon from '@/components/Icon';
import ModalBig from '@/components/ModalBig';
import Filters from './components/Filters';
import OfferSideContent from './components/OfferSideContent';

import styles from './styles.less';

import { offers, participant } from './mocks';

class StaticOffers extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      offer: {},
    };
  }

  _handleOpenOffer = (e, offer) => {
    e.preventDefault();
    this.setState({
      modalOpen: true,
      offer,
    });
  }

  _handleCloseOffer = () => {
    this.setState({
      modalOpen: false,
      offer: null,
    });
  }

  render() {
    const { state: { modalOpen, offer }, _handleOpenOffer, _handleCloseOffer } = this;

    return (
      <>
        <div className={styles.jobsHead}>
          <a>
            <Icon name="menu-burger-2" style={{ width: 20, height: 20 }} />
          </a>
          <H2 bold>
            Offres
          </H2>
        </div>

        <If condition={!isEmpty(offer)}>
          <Then>
            <ModalBig
              isOpen={modalOpen}
              onClose={_handleCloseOffer}
              sideContent={<OfferSideContent offer={offer} />}
            >
              <OfferCardParticipant offer={offer} />
            </ModalBig>
          </Then>
        </If>

        <div className={styles.jobsContainer} style={{ margin: 'auto' }}>
          <div className={styles.jobsHeader}>
            <H1 bold>Voici les offres qui vous correspondent !</H1>
            <div className={styles.resume}>
              <span className={styles.bold}>4 offres</span> correspondent à votre cherche d’un <span className={styles.bold}>CDI</span> à partir de <span className={styles.bold}>Mars 2018</span> pour <span className={styles.bold}>6 mois</span>
              <button className={styles.countCriteria}>+3</button>
              <span className={styles.bold}>autres critères</span>
              <Link to="/">Modifier ma recherche</Link>
            </div>

            <Filters />

          </div>
          {offers.map((offer, i) => (
            <OfferCardListParticipant
              key={i}
              offer={offer}
              participant={participant}
              highlightWords={[]}
              handleOpenOffer={(e) => _handleOpenOffer(e, offer)}
            />
          ))}

        </div>

        <div className={styles.footer} data-cy="jobs-footer">
          <button className={styles.footerActive}><Icon name="offers" /></button>
          <button><Icon name="building" /></button>
          <button><Icon name="star" /></button>
          <button><Icon name="camera" /></button>
        </div>
      </>
    );
  }
}

export default StaticOffers;
