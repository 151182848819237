import React, { useState } from 'react';
import { shape, func, object } from 'prop-types';
import { Body1, Button, H5, IconMail } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { useHistory } from 'react-router-dom';
import styles from './styles.less';
import { Form } from 'react-final-form';
import { FormTextField } from '@/components/FinalFormFields/FormTextField';
import useValidations from '@/utils/validations/useValidations';

const EmailForm = ({
  resetPasswordQuery,
}) => {
  const [errorMessage, setErrorMessage] = useState();
  const t = useFormatMessage();
  const history = useHistory();
  const { isEmail } = useValidations();

  const handleOnSubmit = () => ((values) => {
    const username = values.username
    resetPasswordQuery.mutate({
      username,
      url: window.location.href
    }, {
      onError: () => {
        setErrorMessage(t({ id: 'error.generic' }));
      }
    });
  });
  
  return (
    <>
      <H5 className="mb-24">{t({ id: 'resetPassword.title' })}</H5>
      <Form
        onSubmit={handleOnSubmit()}
        initialValues={{ username: '' }}
        render={({ handleSubmit, form }) => {
          const state = form.getState();
          
          return (
            <form onSubmit={handleSubmit}>
            <div className={styles.inputsContainer}>
              <FormTextField
                name="username"
                id="username"
                label={t({ id: 'form.email' })}
                leftIcon={<IconMail />}
                required
                validate={(value) => isEmail(value)}
                showErrorOnMount={false}
              />
              <Body1 className={styles.error}>
                {errorMessage}
              </Body1>
              <div className={styles.submitContainer}>
                <Button
                  onClick={() => history.push('/')}
                  variant="outline"
                  color="neutral"
                >
                  {t({ id: 'resetPassword.backToLogin' })}
                </Button>
                <Button
                  disabled={resetPasswordQuery.isLoading || state.invalid}
                  loading={resetPasswordQuery.isLoading}
                  type="submit"
                >
                  {t({ id: 'resetPassword.reset'})}
                </Button>
              </div>
            </div>
          </form>
          )
        }}
      />
    </>
  )
}

EmailForm.propTypes = {
  resetPasswordQuery: object,
}

export default EmailForm;
