import React from 'react';
import { object } from 'prop-types';
import styles from '@/components/InteractionCard/styles.less';
import AvatarWithDate from '@/components/AvatarWithDate';

function AvatarRecruiter({ _appointment, _user, _organization }) {
  return <div className={styles.recruiter}>
    <AvatarWithDate
      src={_user.pictureUrl}
      altSrc={_organization.profile?.pictureUrl || ''}
      alt={_user.shortName}
      date={(['accepted', 'unconfirmed'].includes(_appointment?.status) && _appointment?.date) || ''}
      user={_user}
      rounded
    />
  </div>
}

AvatarRecruiter.propTypes = {
  _appointment: object,
  _user: object,
  _organization: object,
}

export { AvatarRecruiter }
