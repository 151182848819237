import React from 'react';
import { isEmpty } from 'lodash';
import { func } from 'prop-types';
import { Form } from 'react-final-form';
import { useFormatMessage } from 'react-intl-hooks';
import { Button } from '@seekube-tech/ui-kit';
import { useSelector } from 'react-redux';
import { useGetDefaultCriteria } from '@/queries/criterion/useGetDefaultCriteria';
import { getAuthUser } from '@/store/auth/selectors';
import MatchingTargets from '@/forms/candidate/MatchingTargets';
import MatchingContracts from '@/forms/candidate/MatchingContracts';
import styles from './styles.less';

const CandidateProfileSearchForm = ({ onSubmit }) => {
  const defaultCriteria = useGetDefaultCriteria();
  const t = useFormatMessage();
  const authUser = useSelector(getAuthUser)?.toJS();

  const getTargetCriterion = () => {
    if (isEmpty(defaultCriteria.data)) return {};
    const targetsCriterion = defaultCriteria.data?.find((criterion) => criterion.key === 'TARGETS');
    if (!targetsCriterion) return {};
    const _choices = targetsCriterion?._choices?.map((choice) => ({
      ...choice,
      _id: choice.id,
    }));

    return ({
        ...targetsCriterion,
        _choices
      }
    )
  }

  const getContractCriterion = () => {
    if (isEmpty(defaultCriteria.data)) return {};
    const targetsCriterion = defaultCriteria.data?.find((criterion) => criterion.key === 'CONTRACT');
    if (!targetsCriterion) return {};
    const _choices = targetsCriterion?._choices?.filter((choice) => choice.enable);
    const modules ={
      ...targetsCriterion.modules,
      onboarding: {
        ...targetsCriterion.modules.onboarding,
        choiceMax: 2,
      }
    };

    return ({
        ...targetsCriterion,
        modules,
        isCustomContract: true,
        _choices
      }
    )
  }
  const targetsCriterion = getTargetCriterion(defaultCriteria);
  const contractsCriterion = getContractCriterion(defaultCriteria);

  return (
      <Form
        onSubmit={onSubmit}
        initialValues={{filters: authUser.matching.filters, targets: authUser.matching.targets }}
        render={({ handleSubmit, values, invalid }) => (
            <form onSubmit={handleSubmit} className={styles.formContainer}>
              <MatchingTargets
                options={targetsCriterion}
                values={values.targets}
                name="targets"
              />
              <MatchingContracts
                options={contractsCriterion}
                values={values.filters}
                name="filters"
              />
              {onSubmit &&
                <div>
                  <Button disabled={invalid} type="submit">{t({ id: 'save' })}</Button>
                </div>
              }
            </form>
          )}
      >
      </Form>);
}

CandidateProfileSearchForm.propTypes = {
  onSubmit: func,
}

export default CandidateProfileSearchForm;
