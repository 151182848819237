import React from 'react';

import Exponents from '../../../Candidate/scenes/JobDating/scenes/Exponents';

const OwnerExponentsScene = (props) => (
  <Exponents
    {...props}
    context="owner"
  />
);

export default OwnerExponentsScene;
