import React from 'react';
import { BlankState, H5 } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import styles from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Dashboard/components/Posts/styles.less';

export function BlankNoPost() {
  const t = useFormatMessage();

  return (
    <div className={styles.blankState}>
      <BlankState style={{ width: '100%' }} className="w-full" />
      <H5>{t({ id: 'candidate.dashboard.posts.blankstate.nopost' })}</H5>
    </div>
  );
}