import { Body1, Subtitle2 } from '@seekube-tech/ui-kit';
import Card from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Exponents/scenes/Exponent/components/Card';
import React from 'react';

// styles
import styles from './styles.less';

const CardInfo = ({
  title,
  description,
  img
}) => (
    <div className={styles.sideContent}>
      <Card>
        <div className={styles.cardContent}>
          {img ? <img src={img} alt="interview" width={160} height={122}/> : <></>}
          <div className={styles.description}>
            <Subtitle2 className="text-neutral-500" fontWeight={700}>{title}</Subtitle2>
            <Body1 className="text-neutral-400">{description}</Body1>
          </div>
        </div>
      </Card>
    </div>
  )

export default CardInfo;
