import { Body1, Button, H4, Tag } from '@seekube-tech/ui-kit';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import colors from '@seekube-tech/ui-kit/dist/colors';
import { object } from 'prop-types';
import { useSelector } from 'react-redux';
import { useSendReport } from '@/queries/reports/useSendReport';
import ModalV2 from '@/components/ModalV2';
import styles from '@/scenes/Event/scenes/Owner/scenes/Setting/scenes/Reporting/components/DataOverview/styles.less';
import { getCurrentEvent } from '@/store/event/selectors';

export const ReportPostNow = ({ nextReportToSend }) => {
  const t = useFormatMessage();
  const event = useSelector(getCurrentEvent)?.toJS();

  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [hasConfirmed, setHasConfirmed] = useState(false);

  const { mutate: postReport, isLoading, isSuccess, isError, data: report, reset } = useSendReport({ eventId: event._id });

  // effect to manage the confirm modal
  useEffect(() => {
    if (hasConfirmed)  {
      postReport({ _id: nextReportToSend?._id  });
    }

    if (isSuccess) {
      setIsConfirmModalVisible(false);
    }

    return () => { setHasConfirmed(false); }

  }, [hasConfirmed, isLoading]);


  const onCloseModal = () => {
    setIsConfirmModalVisible(false);

    if (isError) { reset(); }
  }

  return (
    <>
    <div className={styles.spaceBetween}>
      {
        isSuccess ?
          <Tag variant="tonal" color="success" className={styles.mr16}>
            {t({ id: 'sent.at.hour' },
              {
                date: moment(report.scheduledAt).
                  format('DD/MM/YYYY'),
                hour: moment(report.scheduledAt).format('HH:mm'),
              })
            }
          </Tag>
          :
          <Tag variant="tonal" color="primary" className={styles.mr16}>
            {t({ id: 'scheduled.at.date' },
              {
                date: moment(nextReportToSend?.scheduledAt).
                  format('DD/MM/YYYY'),
                hour: moment(nextReportToSend?.scheduledAt).utc().format('HH:mm'),
              })
            }
          </Tag>
      }

      <Button
        disabled={isSuccess || isLoading}
        size="small"
        color="primary"
        variant="fill"
        onClick={() => setIsConfirmModalVisible(true)}
        loading={isLoading}
      >
        {isSuccess && t({ id: 'sent.plgr' }, { gender: 'male', count: 1 })}
        {!isSuccess && t({ id: 'send.now' })}
      </Button>
    </div>


  <ModalV2
    visible={isConfirmModalVisible}
    className={styles.confirmModal}
    template="confirm"
    onClose={onCloseModal}
  >
    <H4 className="mb-20">{t({ id: 'report.modal.confirm.send.now.title' })}</H4>

    <Body1 color={colors.neutral['500']}>
      {t({ id: 'report.modal.confirm.send.now.body.a' },
        {
          date: moment(nextReportToSend?.scheduledAt).
            format('DD/MM/YYYY'),
          hour: moment(nextReportToSend?.scheduledAt).utc().format('HH:mm'),
        })}
    </Body1>
    <br />
    <Body1 color={colors.neutral['500']}>
      {t({ id: 'report.modal.confirm.send.now.body.b' })}
    </Body1>

    <div className={styles.modalAction}>
      <Button
        variant="fill"
        onClick={() => setHasConfirmed(true)}
        disabled={isSuccess || isLoading}
        loading={isLoading}
      >
        {isError ? t({ id: 'resend' }) : t({ id: 'send' })}
      </Button>
      <Button variant="tonal" onClick={onCloseModal} color="primary">
        {t({ id: 'cancel' })}
      </Button>
    </div>

    {isError &&
      <Body1 className={styles.callbackError} color={colors.error['500']}>
        {t({ id: 'download.error.b' })}
      </Body1>}
  </ModalV2>
    </>
  )
}

ReportPostNow.propTypes = {
  nextReportToSend: object,
}
