import { all } from 'redux-saga/effects';
import { actionSagas } from "./store/action";
import { appSagas } from "./store/app";
import { appointmentSagas } from "./store/appointment";
import { authSagas } from "./store/auth";
import { criterionSagas } from "./store/criterion";
import { eventSagas } from "./store/event";
import { exponentSagas } from "./store/exponent";
import { interactionSagas } from "./store/interaction";
import { jobSagas } from "./store/job";
import { offerSagas } from "./store/offer";
import { organizationSagas } from "./store/organization";
import { participantSagas } from "./store/participant";
import { sectorSagas } from "./store/sector";
import { surveySagas } from "./store/survey";
import { timeslotSagas } from "./store/timeslot";
import { userSagas } from "./store/user";
import { v1KpiSagas } from "./store/v1Kpi";
import { roleSagas } from "./store/role";

function* rootSaga() {
  yield all([
    ...roleSagas,
    ...appSagas,
    ...authSagas,
    ...userSagas,
    ...eventSagas,
    ...participantSagas,
    ...interactionSagas,
    ...exponentSagas,
    ...offerSagas,
    ...appointmentSagas,
    ...timeslotSagas,
    ...organizationSagas,
    ...sectorSagas,
    ...v1KpiSagas,
    ...criterionSagas,
    ...jobSagas,
    ...actionSagas,
    ...surveySagas,
  ])
}

export default rootSaga;
