import { defineMessages } from 'react-intl';

export default defineMessages({
  signupIdentityTitle: {
    id: 'event.candidate.preparation.signup.identity.title',
  },
  signupIdentityTitleModify: {
    id: 'event.candidate.preparation.signup.identity.title.verify',
  },
  signupIdentityFirstName: {
    id: 'firstName',
  },
  signupIdentityLastName: {
    id: 'lastName',
  },
  signupIdentityBirthday: {
    id: 'birthday',
  },
  signupIdentityMadam: {
    id: 'madam',
  },
  signupIdentitySir: {
    id: 'sir',
  },
  signupIdentityGenderMandatory: {
    id: 'form.gender.mandatory',
  },
  signupPhoneMandatory: {
    id: 'form.phone.mandatory',
  },
  signupIdentityLinkedinImport: {
    id: 'event.recruiter.preparation.signup.identity.linkedin.import',
  },
  signupIdentityLinkedinLoading: {
    id: 'event.recruiter.preparation.signup.identity.linkedin.loading',
  },
  signupIdentityLinkedinSuccess: {
    id: 'event.recruiter.preparation.signup.identity.linkedin.success',
  },
  signupIdentityLinkedinError: {
    id: 'event.recruiter.preparation.signup.identity.linkedin.error',
  },
  signupIdentityLinkedinImportPartial: {
    id: 'event.recruiter.preparation.signup.identity.linkedin.importPartial',
  },
  signupIdentityLinkedinSecurity: {
    id: 'event.recruiter.preparation.signup.identity.linkedin.security',
  },
  signupIdentityPictureUrlUpdate: {
    id: 'pictureUrl.update',
  },
  signupIdentityPictureUrlFormat: {
    id: 'pictureUrl.format',
  },
  signupIdentityPictureUrlSecurity: {
    id: 'pictureUrl.security',
  },
  signupIdentityPictureUrlChoose: {
    id: 'pictureUrl.choose',
  },
  signupIdentityPhone: {
    id: 'phone',
  },
  signupIdentityGender: {
    id: 'gender',
  },
  next: {
    id: 'next',
  },
  presentationTitle: {
    id: 'event.candidate.preparation.signup.step.presentation',
  },
  save: {
    id: 'save',
  },
  emailLabel: {
    id: 'event.recruiter.preparation.signup.login.form.username.label',
  },
  emailInvalid: {
    id: 'form.error.required',
  },
  formInvalid: {
    id: 'form.invalid',
  },
  emailErrorExist: {
    id: 'signup.usernameExist',
  },
  emailConfirm: {
    id: 'profile.settings.notification.email.confirm',
  },
});
