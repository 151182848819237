import React from 'react';
import { join, take, uniq } from 'lodash';
import { SchoolName, RowToggle, ActionsColumn } from './components';
import styles from '../../styles.less';

export default () => (
  [
    {
      title: '',
      dataIndex: 'school',
      key: 'id',
      className: 'checkboxTable',
      width: '2',
      render: (school, record) => <RowToggle key={record.school._id} school={school} checkedItems={record.checkedSchools} onToggle={record.onToggleSchool} />,
    },
    { title: 'ECOLE',
      dataIndex: 'ecole',
      key: 'schools',
      render: (text, record) => <SchoolName key={record.school._id} school={record.school} handleEditName={record.handleEditName} />,
      editable: true,
      width: '48%',

    },
    { title: 'OCC',
      dataIndex: 'occ',
      key: 'occ',
      render: (text, record) => record.school.occurrences,
      width: '10%',
    },
    { title: 'ALIAS',
      dataIndex: 'alias',
      key: 'alias',
      render: (text, record) => <span className={styles.relative} title={join(uniq(record.school.alias), ', ')}>{join(take(uniq(record.school.alias), 3), ', ')}</span>,
      width: '30%',
    },
    { title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => <ActionsColumn key={record.school._id} school={record.school} onMergeClick={record.handleMergeClick} onReferentClick={record.handleReferentClick} />,
      width: '12%',
    },
  ]
);
