import React from 'react';
import { object, func } from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from 'react-intl'
import { isEmpty } from 'lodash';
import { Toggle } from '@seekube-tech/ui-kit';
import { toJS } from '@/utils';

// Store
import { eventSelectors, eventActions } from '@/store/event';

// Components
import Radio, { RadioGroup } from '@/components/Radio';
import { Bloc, BlocHeader } from '@/components/Bloc';

// Styles & Translations
import styles from './styles.less';
import messages from './messages';

/**
 * OwnerSettingsOnboardingParticipants
 */
class OwnerSettingsOnboardingParticipants extends React.PureComponent { // eslint-disable-line
  static propTypes = {
    intl: object,
    event: object,
    patchEvent: func
  };

  state = {
    initialValues: [],
  };

  constructor(props) {
    super(props);

    const { event } = props;

    this.state.initialValues = event.participants.onboarding.map((step) => step.enabled);
    this.state.cvIsRequired = !(event.participants.cvIsRequired === false);
  }

  handleSubmit = (e) => {
    const {
      props: { event, patchEvent, intl },
    } = this;

    const { value } = e.target;
    const index = e.target.name;

    const { participants } = event;

    if (index === 'cvIsRequired') {
      participants.cvIsRequired = value === '1';
    } else {
      participants.onboarding[index].enabled = value === '1';
    }

    patchEvent({
      eventId: event.slug,
      event: { participants },
      notificationParams: {
        success: {
          message: intl.formatMessage({ id: 'notifications.update.success' }),
          kind: 'info',
          style: {
            bottom: '5%',
            top: 'inherit',
          },
        },
        error: true,
      },
    });

    this.setState({ initialValues: event.participants.onboarding.map((step) => step.enabled), cvIsRequired: participants.cvIsRequired });
  };

  submitToggleReminderOpeningEvent = (val) => {
    const { props: { event, patchEvent, intl } } = this;
    const participants = {...event.participants, enableReminderOpeningEvent: val}

    patchEvent({
      eventId: event.slug,
      event: { participants },
      notificationParams: {
        success: {
          message: intl.formatMessage({ id: 'notifications.update.success' }),
          kind: 'info',
          style: {
            bottom: '5%',
            top: 'inherit',
          },
        },
        error: true,
      },
    });
  }

  render() {
    const {
      props: { intl, event },
      state: { initialValues, cvIsRequired },
      handleSubmit, submitToggleReminderOpeningEvent,
    } = this;

    if (isEmpty(event)) {
      return null;
    }

    return (
      <>
        <Bloc>
          <div className={styles.onboardingSettings}>
            <BlocHeader>
              <FormattedMessage {...messages.title} values={{ count: 2 }} tagName="h2" />
            </BlocHeader>
            {/*  Resume */}
            <div>
              <h3 className={styles.title}>{intl.formatMessage(messages.step1)}</h3>
              <a
                href={intl.formatMessage({ id: 'event.participants.onboarding.screenshot1' })}
                target="_blank"
              ><FormattedMessage {...messages.infos} /></a>
              <RadioGroup
                className="onboarding-settings-radio"
                value={initialValues[1] ? '1' : '0'}
                onChange={handleSubmit}
                name="1"
              >
                <Radio value="1">
                  {intl.formatMessage(messages.yes)}
                </Radio>
                <Radio value="0">
                  {intl.formatMessage(messages.no)}
                </Radio>
              </RadioGroup>
            </div>

            {/*  Parcours */}
            <div>
              <h3 className={styles.title}>{intl.formatMessage(messages.step3)}</h3>
              <RadioGroup
                className="onboarding-settings-radio"
                value={cvIsRequired === true ? '1' : '0'}
                onChange={handleSubmit}
                name="cvIsRequired"
              >
                <Radio value="1">
                  {intl.formatMessage(messages.yes)}
                </Radio>
                <Radio value="0">
                  {intl.formatMessage(messages.no)}
                </Radio>
              </RadioGroup>
            </div>

            <div>
              <h3 className={styles.title}>{intl.formatMessage(messages.step2)}</h3>
              <a
                href={intl.formatMessage({ id: 'event.participants.onboarding.screenshot2' })}
                target="_blank"
              ><FormattedMessage {...messages.infos} /></a>
              <RadioGroup
                className="onboarding-settings-radio"
                value={initialValues[2] ? '1' : '0'}
                onChange={handleSubmit}
                name="2"
              >
                <Radio value="1">
                  {intl.formatMessage(messages.yes)}
                </Radio>
                <Radio value="0">
                  {intl.formatMessage(messages.no)}
                </Radio>
              </RadioGroup>
            </div>
          </div>
        </Bloc>
        <Bloc>
          <div className={styles.onboardingSettings}>
            <BlocHeader>
              <FormattedMessage id="event.participants.onboarding.preregistration.title" tagName="h2" />
              <Toggle
                onChange={submitToggleReminderOpeningEvent}
                checked={event.participants.enableReminderOpeningEvent}
              />
            </BlocHeader>
            {/*  Resume */}
            <div>
              <h3 className={styles.description}>
                {intl.formatMessage({id: 'event.participants.onboarding.preregistration.description'})}
              </h3>
              <FormattedHTMLMessage id="see.mail.opening.registration" />
            </div>
          </div>
        </Bloc>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  event: eventSelectors.getCurrentEvent,
});

const mapDispatchToProps = {
  patchEvent: eventActions.patchEvent,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(toJS(injectIntl(OwnerSettingsOnboardingParticipants)));
