import * as React from 'react';
import { IconAlertCircle } from '@seekube-tech/ui-kit';
import classNames from 'classnames';
import { number } from 'prop-types';
import { FormattedHTMLMessage } from 'react-intl';
import styles from '../styles.less';

function CountOffersAndFreeSlots({ countFreeSlots, countOffers }) {
  if (countFreeSlots > 0 && countOffers > 0) return null;
  return countFreeSlots >= 0 ?
    <span key="countOffersAndFreeSlots" className={countOffers === 0 || countFreeSlots === 0 ? classNames(styles.highlight, styles.svg) : ''}>
      <IconAlertCircle size={16} />
      <span className={countOffers === 0 ? styles.highlight : ''}><FormattedHTMLMessage values={{ count: countOffers }} id="events.stats.recruiter.offers" /></span>
      {' - '}<span className={countFreeSlots === 0 ? styles.highlight : ''}><FormattedHTMLMessage values={{ count: countFreeSlots }} id="events.stats.recruiter.freeSlots" /></span>
    </span> : null;
};

CountOffersAndFreeSlots.propTypes = {
  countFreeSlots: number,
  countOffers: number
}

export default CountOffersAndFreeSlots;
