import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { Row, Col } from 'antd';
import { Button, H6, IconPlusCircle } from '@seekube-tech/ui-kit';
import PropTypes from 'prop-types';

import Video from './components/Video';

import styles from './styles.less';

function Videos({ fields, disabled }) {

  /* Vars */

  const t = useFormatMessage();

  /* Functions */

  /**
   * Remove a video from list
   * @param {Number} index
   */
  const handleRemoveVideo = (index) => {
    if (!disabled) {
      fields.remove(index);
    }
  };

  /**
   * Add a video in list
   */
  const handleAddVideo = () => {
    if (!disabled) {
      fields.push({
        title: '',
        url: '',
      });
    }
  };

  /* Render */

  return (
    <div>
      <p className={styles.panelText}>
        <H6>{t({ id: 'link.videos' })}</H6>
        {t({ id: 'event.recruiter.preparation.stand.item.videos.text' })}
      </p>

      {fields.map((field, index) => (
        <Video key={field} field={field} index={index} onRemove={handleRemoveVideo} disabled={disabled}/>
      ))}

      <Row type="flex" justify="end" className={styles.addContainer}>
        <Col>
          <Button
            disabled={disabled}
            variant="outline"
            className="addAddress"
            onClick={handleAddVideo}
            imageComponentLeft={<IconPlusCircle
              size={16} />}
          >
            {t({ id: 'event.recruiter.preparation.stand.item.videos.add' })}
          </Button>
        </Col>
      </Row>
    </div>
  );
}

Videos.propTypes = {
  fields: PropTypes.array.isRequired,
};

export default Videos;
