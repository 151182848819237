import * as React from 'react';
import { number } from "prop-types";
import { FormattedHTMLMessage } from 'react-intl';
import { IconBriefcase } from '@seekube-tech/ui-kit';

function Opportunities({ countOpportunities }) {
  return countOpportunities ?
    <span key="opportunities">
      <IconBriefcase size={16} /><FormattedHTMLMessage values={{ count: countOpportunities }} id="events.stats.opportunities" />
    </span> : null
};

Opportunities.propTypes = {
  countOpportunities: number
}

export default Opportunities;
