import request from '@/utils/request';

const editStand = ({ orgaId, body }) => {
  const requestUrl = `${process.env.FRONT_API_URL}/organizations/${orgaId}/profiles`;
  return request(requestUrl, {
    method: 'POST',
    body: JSON.stringify(body),
  })
}

const resetStand = ({ orgaId, body }) => {
  const requestUrl = `${process.env.FRONT_API_URL}/organizations/${orgaId}/profiles/reset`;
  return request(requestUrl, {
    method: 'POST',
    body: JSON.stringify(body),
  })
};

const restoreVersion = ({ orgaId, body, eventId }) => {
  let requestUrl = `${process.env.FRONT_API_URL}/organizations/${orgaId}/profiles/restore`;
  if (eventId) {
    requestUrl = `${process.env.FRONT_API_URL}/organizations/${orgaId}/profiles/restore?eventId=${eventId}`;
  }
  return request(requestUrl, {
    method: 'POST',
    body: JSON.stringify(body),
  })
};

const getCurrentStand = ({ orgaId, eventId }) => {
  let requestUrl = `${process.env.FRONT_API_URL}/organizations/${orgaId}/profiles/current`;
  if (eventId) {
    requestUrl = `${process.env.FRONT_API_URL}/organizations/${orgaId}/profiles/current?eventId=${eventId}`;
  }

  return request(requestUrl, {
    method: 'GET',
  })
};

const getAllStands = ({ orgaId }) => {
  const requestUrl = `${process.env.FRONT_API_URL}/organizations/${orgaId}/profiles`;

  return request(requestUrl, {
    method: 'GET',
  })
};

const getStandsVersions = ({ orgaId }) => {
  const requestUrl = `${process.env.FRONT_API_URL}/organizations/${orgaId}/profiles/versions`;

  return request(requestUrl, {
    method: 'GET',
  })
};

export { resetStand, editStand, getCurrentStand, getAllStands, getStandsVersions, restoreVersion };
