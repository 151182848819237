import React from 'react';
import { any, array } from 'prop-types';

// components
import { Steps } from 'antd';

import './styles.less';
import { useFormatMessage } from 'react-intl-hooks';
import messages from './messages';

const { Step } = Steps;

/**
 * <StepIndicator />
 *
 * @description
 * Show all steps of signup process
 */
const StepsIndicator = ({ currentStep, eventSteps }) => {
  const t = useFormatMessage();
  const enabledSteps = eventSteps.filter(step => step.enabled)
  const progressDotStep = enabledSteps.findIndex(step => step.name === eventSteps[currentStep]?.name)

  return (
    <div className="steps-indicator-wrapper">
      <Steps progressDot current={progressDotStep}>
        {eventSteps && eventSteps[0].enabled ? <Step title={t(messages.presentation)} /> : ''}
        {eventSteps && eventSteps[1].enabled ? <Step title={t(messages.resume)} /> : ''}
        {eventSteps && eventSteps[2].enabled ? <Step title={t(messages.path)} /> : ''}
        {eventSteps && eventSteps[3]?.enabled ? <Step title={t(messages.participation)} /> : ''}
        {eventSteps && eventSteps[4]?.enabled ? <Step title={t(messages.search)} /> : ''}
      </Steps>
    </div>
  )
};

StepsIndicator.propTypes = {
  currentStep: any,
  eventSteps: array,
};

export default StepsIndicator;
