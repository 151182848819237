import React, { memo } from 'react';
import moment from 'moment';
import { array, func, object, string } from 'prop-types';
import { Select, IconStand, IconCalendar, Item } from '@seekube-tech/ui-kit';
import { injectIntl } from 'react-intl';
import classnames from 'classnames';
import Html from '@/components/Html';


const SelectEvent = ({
  value,
  events,
  placeholder,
  onSelect,
  intl,
}) => {
  const eventOptions = ({ value, label, event }) => {
    const { countAvailableTimeslots = 0 } = event;
    const beginAt = moment(event.readOnly.jobfairBeginAt);
    const endAt = moment(event.readOnly.jobfairEndAt);

    return (
      <Item
        key={value}
        onClick={() => onSelect(value)}
        endElement={
          <span className={classnames(countAvailableTimeslots === 0 ? 'text-warning-500' : 'text', 'flex justify-end flex-1')}>
            <Html raw value={intl.formatHTMLMessage({ id: 'events.stats.recruiter.freeSlots' }, { count: countAvailableTimeslots })} />
          </span>}
        startElement={
          <span className="flex"><IconCalendar className="mr-6" size="20px" /> {beginAt.format('DD MMM')} - {endAt.format('DD MMM')}</span>}
      >
        {label}
      </Item>
    );
  };

  return (
    <>
      <Select
        id="selectEvent"
        value={value}
        label={placeholder}
        onChange={onSelect}
        leftIcon={<IconStand />}
        options={events?.map((event) => ({
          value: event._id,
          label: event.name,
          event,
        }))}
        renderOption={eventOptions}
      />
    </>
  );
};

SelectEvent.propTypes = {
  value: object,
  events: array,
  intl: object,
  onSelect: func,
  placeholder: string,
};

export default memo(injectIntl(SelectEvent));
