import React from 'react';
import { Button } from '@seekube-tech/ui-kit';
import { isEmpty } from 'lodash';
import { useFormatMessage } from 'react-intl-hooks';
import { func, object } from 'prop-types';
import EventAccessCta, {
  EVENT_ACCESS_CTA_COMPONENT_TYPE,
  EVENT_ACCESS_CTA_CONTEXT,
} from '@/scenes/Event/components/EventAccessCta';
import { getIds } from '@/utils/global';
import { USERS_TYPE } from '@/utils/constants';
import styles from './styles.less';
import { Link } from 'react-router-dom';
import useSearchEngineTracker from '@/utils/analytics/trackers/category/eventSearchEngine/useSearchEngineTracker';

export const Actions = ({ authUser, event }) => {
  if (!event) {
    return null;
  }
  
  const t = useFormatMessage();
  const orgIds = getIds(event.planners?._organizations);
  const role = authUser?._currentOrganization ? USERS_TYPE.recruiter : USERS_TYPE.candidate;
  const isAdminOrOwner = authUser?.isAdmin || (role === USERS_TYPE.recruiter && isEmpty(event.stats) && (orgIds?.indexOf(authUser._currentOrganization._id) > -1));
  const { onTrackCta, onTrackShowMore } = useSearchEngineTracker();

  return (
    <div className={styles.container}>
      <Link
        className={styles.actionButton}
        role="button"
        to={`/${event.slug}?utm_source=seekube&utm_campaign=learnMore&utm_medium=BtoC_calendar`}
      >
        <Button
          color="neutral"
          variant="outline"
          size="medium"
          onClick={() => onTrackShowMore(event)}
          className="w-full"
        >
          {t({ id: 'knowMore' })}
        </Button>
      </Link>
      <EventAccessCta
        authUser={authUser}
        context={EVENT_ACCESS_CTA_CONTEXT.LANDING_SEARCH_ENGINE}
        ctaComponentType={EVENT_ACCESS_CTA_COMPONENT_TYPE.BUTTON}
        editMode={false}
        event={event}
        eventUserStats={event.stats}
        role={isAdminOrOwner ? USERS_TYPE.owner : role}
        linkCustomClass={styles.actionButton}
        customClass={styles.actionButtonLink}
        utmMedium="BtoC_calendar"
        customTrack={onTrackCta}
      />
    </div>
  )
}

Actions.propTypes = {
  event: object,
  onActionClick: func,
  authUser: object,
}
