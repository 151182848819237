import { defineMessages } from 'react-intl';

export default defineMessages({
  offerNotificationSuccessDuplicate: {
    id: 'event.recruiter.preparation.offer.notification.success.duplicate',
  },
  offerNotificationSuccessDraft: {
    id: 'event.recruiter.preparation.offer.notification.success.draft',
  },
  offerNotificationSuccessDelete: {
    id: 'event.recruiter.preparation.offer.notification.success.delete',
  },
  offerDraftCount: {
    id: 'event.recruiter.preparation.offer.draft.count',
  },
  offerBtnDraft: {
    id: 'event.recruiter.preparation.offer.btn.draft',
  },
  offerBtnEdit: {
    id: 'edit',
  },
  offerBtnDuplicate: {
    id: 'event.recruiter.preparation.offer.btn.duplicate',
  },
  offerBtnDelete: {
    id: 'event.recruiter.preparation.offer.btn.delete',
  },
  offerBadSearch: {
    id: 'event.recruiter.preparation.offer.badSearch',
  },
  blankStateOfferTitle: {
    id: 'blankState.preparation.offer.title',
  },
  blankStateCollaboratorOfferTitle: {
    id: 'blankState.preparation.offer.collaborator.title',
  },
  blankStateOfferContent: {
    id: 'blankState.preparation.offer.content',
  },
  blankStateOfferBtn: {
    id: 'blankState.preparation.offer.btn',
  },
  offerTitle: {
    id: 'event.recruiter.preparation.offer.title',
  },
  offerCreate: {
    id: 'event.recruiter.preparation.offer.create',
  },
  offerHelp: {
    id: 'event.recruiter.preparation.offer.help',
  },
  modalDeletionText: {
    id: 'event.recruiter.preparation.offer.deletion.confirm',
  },
  offerModalDeletion1: {
    id: 'event.recruiter.preparation.offer.modal.deletion.1',
  },
  offerModalDeletion2: {
    id: 'event.recruiter.preparation.offer.modal.deletion.2',
  },
  cancel: {
    id: 'cancel',
  },
  btnConfirm: {
    id: 'btn.confirm',
  },
  offerDuplicateExisting: {
    id: 'offer.duplicate.existing',
  },
  offerFormNotificationSuccessSubmit: {
    id: 'event.recruiter.preparation.offer.form.notification.success.submit',
  },
  offerFormNotificationSuccessAddition: {
    id: 'event.recruiter.preparation.offer.form.notification.success.addition',
  },
  offerFormCompatible: {
    id: 'event.recruiter.preparation.offer.form.compatible',
  },
  offerFormWarningClose: {
    id: 'event.recruiter.preparation.offer.form.warning.close',
  },
  offerFormAdd: {
    id: 'event.recruiter.preparation.offer.form.add',
  },
  offerFormUpdate: {
    id: 'event.recruiter.preparation.offer.form.update',
  },
  offerPublishedFormUpdate: {
    id: 'event.recruiter.preparation.offer.published.form.update',
  },
  save: {
    id: 'save',
  },
  exit: {
    id: 'exit',
  },
  separator: {
    id: 'signin.or',
  },
  warningNonPublishedStand: {
    id: 'event.recruiter.preparation.offer.form.warning.non.published.stand',
  },
  warningMaxOffers: {
    id: 'event.recruiter.preparation.offer.form.warning.max.offers',
  },
  warningMaxOffersClient: {
    id: 'event.recruiter.preparation.offer.form.warning.max.offers.client',
  },
  numberOfAuthorizedOffer: {
    id: 'event.recruiter.preparation.offer.form.authorized.offers',
  },
  titleHelpDefault: {
    id: 'offer.form.title.help.default',
  },
  delete: {
    id: 'delete',
  },
});
