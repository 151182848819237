import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { object, func } from 'prop-types';
import { replace } from 'connected-react-router';
import { Route, Switch } from 'react-router-dom';
import { toJS } from '@/utils';
import { createStructuredSelector } from 'reselect';
import { eventSelectors } from '@/store/event';

import ParticipantsView from '@/scenes/Event/scenes/Recruiter/scenes/Jobdating/container/ParticipantsView';


const RecruiterJobdatingInterviewScene = ({ match, event, replace }) => {
  if (
    (event.areInteractionsClosed && window.location.href.indexOf('unconfirmed') !== -1) ||
    (!event.skipAppointmentValidation && window.location.href.indexOf('unconfirmed') !== -1)
  ) {
    // replace(`${match.url}/confirmed`);
  }

  if (match.isExact) {
    replace(`${match.url}/unconfirmed`);
  }

  return (
    <div>
      <Switch>
        <Route path={`${match.path}/confirmed`} render={(props) => <ParticipantsView context="confirmed" {...props} />} />
        <Route path={`${match.path}/unconfirmed`} render={(props) => <ParticipantsView context="unconfirmed" {...props} />} />
        <Route path={`${match.path}/refused`} render={(props) => <ParticipantsView context="refused" {...props} />} />
        <Route path={`${match.path}/interview`} render={(props) => <ParticipantsView context="interview" {...props} />} />
        <Route path={`${match.path}/transferred`} render={(props) => <ParticipantsView context="transferred" {...props} />} />
        <Route path={`${match.path}`} render={(props) => <ParticipantsView context="interview" {...props} />} />
      </Switch>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  event: eventSelectors.getCurrentEvent,
});

const mapDispatchToProps = {
  replace,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

RecruiterJobdatingInterviewScene.propTypes = {
  match: object,
  event: object,
  replace: func,
};

export default compose(withConnect, toJS)(RecruiterJobdatingInterviewScene);
