import React from 'react';
import { func, object } from 'prop-types';
import { Field } from 'react-final-form';
import { Autocomplete } from '@seekube-tech/ui';

import useValidations from '@/utils/validations/useValidations';

import Icon from '@/components/Icon';


import { createStructuredSelector } from 'reselect';
import { authSelectors } from '@/store/auth';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { LOCALES } from '@/utils/constants';
import { toJS } from '@/utils';
import styles from '../../../styles.less';
import { getCriterionLabel } from '../utils';
import { CRITERION_KEY } from '../../../utils/constants';

function BaseField({ criterion, showHelper, authUser, disabled = false }) {
  const { isFilled, isMin, isMax, isMissingInOptions } = useValidations();
  const choices = criterion._choices.filter((c) => c?.enable);

  /**
   * Return an error message if input is invalid
   * @param {Any} value - Value of input
   * @returns {String | null}
   */
  const handleValidate = (value) => {
    if (criterion.modules.offer.choiceMin) {
      return (
        isMissingInOptions(value, choices) ||
        isFilled(value) ||
        isMin(value, criterion.modules.offer.choiceMin) ||
        isMax(value, criterion.modules.offer.choiceMax)
      );
    }

    return value && isMax(value, criterion.modules.offer.choiceMax);
  }

  /**
   * Return an input name
   * @param {string} fieldName
   * @returns {string}
   */
  const getFieldName = (fieldName) => {
    if (/^\d+$/.test(fieldName)) {
      return `custom${fieldName}`;
    }

    return fieldName;
  };

  const getOptionLabel = (option) => authUser.locale === LOCALES.en && option.label_en ? option.label_en : option.label

  const fieldName = getFieldName(criterion.fieldName);
  const label = getCriterionLabel(criterion);

  return (
    <div className={styles.marginTop}>
      <Field
        name={fieldName}
        id={fieldName}
        label={label}
        icon={<Icon name={criterion.icon} />}
        component={Autocomplete}
        required={Boolean(criterion.modules.offer.choiceMin)}
        isMultiple
        disabled={disabled}
        options={choices}
        fullWidth
        getOptionLabel={getOptionLabel}
        getOptionValue={(option) => option._id}
        validate={handleValidate}
        showHelper={(id) => showHelper(id, label)}
      />
    </div>
  );
}

BaseField.propTypes = {
  criterion: object,
  showHelper: func,
  authUser: object
};

BaseField.defaultProps = {
  criterion: {},
};


const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser
})

const mapDispatchToProps = {};

const withConnect = connect(mapStateToProps, mapDispatchToProps);


export default compose(
  withConnect
)(toJS(BaseField));
