import React, { useState } from 'react';
import { string, any, object } from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import copyToClipboard from '@/utils/copyToClipboard';
import Popover from '../Popover';
import styles from './styles.less';
import messages from './messages';

const ShareVisioLink = ({ children, url }) => {
  const t = useFormatMessage();
  const [copyPaste, setCopyPasteText] = useState(false);
  const btnText = {
    text: t(copyPaste ? messages.copySuccess : messages.copyLink),
    color: copyPaste ? 'success' : 'primary',
  };

  return (
    <Popover
      placement="bottom"
      overlayStyle={{ maxWidth: '435px', cursor: 'pointer', zIndex: '9999999999' }}
      popoverContent={
        <div className={styles.shareVisioLinkContainer}>
          <FormattedMessage tagName="h2" {...messages.shareVisioTitle} />
          <div className={styles.shareTestContainer}>
            <span>{url}</span>
            <Button color={btnText.color} onClick={() => { copyToClipboard(url); setCopyPasteText(true); }}>
              {btnText.text}
            </Button>
          </div>
        </div>}
      trigger="click"
    >
      <div style={{ cursor: 'pointer' }}>{children}</div>
    </Popover>
  );
};

ShareVisioLink.propTypes = {
  children: any,
  url: string,
  intl: object,
};

export default injectIntl(ShareVisioLink);
