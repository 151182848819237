import React from 'react';
import { any, func, number, bool } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { Select } from '@seekube-tech/ui';
import Icon from '@/components/Icon';


const DURATIONS = [15, 30, 45, 60];

const Duration = ({
  onDurationChange,
  value,
  disabled,
  style,
}) => {
  const t = useFormatMessage();

  const onSelectChange = (value) => {
    onDurationChange(value);
  };

  return (
    <div  className="w-full" id="duration" style={style}>
      <Select
        fullWidth="100%"
        disabled={disabled}
        input={{
          label: t({ id: 'event.recruiter.preparation.timeslots.slot.duration' }),
          onChange: onSelectChange,
          value,
        }}
        options={DURATIONS}
        label={t({ id: 'event.recruiter.preparation.timeslots.slot.duration' })}
        getOptionLabel={(option) => `${option} minutes`}
        renderValue={(option) => <span style={{ display: 'flex', alignItems: 'center' }}><Icon name="clock" style={{ marginRight: '4px' }} />{option}</span>}
        meta={
          { error: '' }
        }
      />
    </div>
  );
}

Duration.propTypes = {
  onDurationChange: func,
  value: number,
  disabled: bool,
  style: any,
}

export default Duration;
