import { isArray, isEmpty } from 'lodash';
import styles from '@/scenes/Event/components/StaticOffers/components/Filters/styles.less';
import Selector from '@/components/Form/Selector';
import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { Form } from 'antd';
import { sortByOccurence } from '@/scenes/Event/scenes/Candidate/scenes/JobDating/components/SearchEngine/helpers';
import {
  getLabel
} from '@/scenes/Event/scenes/Recruiter/scenes/Preparation/scenes/Offer/containers/CreateOfferModal/components/CreateOfferForm/components/CriteriaFields/utils';

const FormItem = Form.Item;

export const LocationSelector = ({
  isMobile,
  facetKey,
  matching,
  updateSearch,
  updateMatching,
  search,
  criterias,
  context,
  facetsExponents,
  facets,
}) => {
  const t = useFormatMessage();
  const locationOptions = criterias.find((c) => c.key === 'MOBILITY');

  if (!locationOptions?.modules?.searchEngineParticipant) return '';

  const options = locationOptions._choices
      .filter((o) => o.enable)
      .map((o) => ({
        value: o._id,
        label: getLabel(o) === 'Europe' ? 'Europe (excl. France)' : getLabel(o),
        parent: o._parent,
        badgeCount: getFacetFromKey('MOBILITY', o._id.toString(), context),
      }))

  const filtredOptions = options.filter((o) => o !== undefined);

  let locationMatching = matching.filters.flat().filter((v) => filtredOptions.find((c) => c?.value === v));

  if (context === 'exponent') {
    if (isEmpty(search) || isEmpty(search.localization)) {
      locationMatching = [];
    } else if (isArray(search.localization)) {
      locationMatching = search.localization;
    } else {
      locationMatching = search.localization.split(',');
    }
  }

  return (
    <FormItem className={[styles.formItem, styles.formItemBordered].join(' ')} style={{ width: '160px' }}>
      <Selector
        position="center"
        isMobile={!!isMobile}
        isLocationInternational={!['landingOffer'].includes(context)}
        isLocation
        label={getLabel(locationOptions.modules.searchEngineParticipant)}
        noLabel={t({ id: 'searchEngine.noLabel' })}
        options={sortByOccurence(filtredOptions)}
        values={locationMatching}
        onChange={(e) => {
          if (context === 'exponent') {
            updateSearch('localization', e, facetKey, context);
          } else {
            updateMatching((context === 'exponent' ? locationMatching : matching.filters).flat().filter((v) => !filtredOptions.find((c) => c.value === v)), e, facetKey);
          }
        }}
      />
    </FormItem>
  );

  function getFacetFromKey (key, value) {
    const items = ['exponent'].includes(context) ? facetsExponents : facets;

    return (items && typeof items[`matching.${key}`] === 'object') ? items[`matching.${key}`][value] || 0 : 0;
  };
}
