import React, { useState } from 'react';
import { Typography, Grid, Link } from '@seekube-tech/ui';
import { object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useFormatMessage } from 'react-intl-hooks';

import { toJS } from '@/utils';

// Store
import { eventSelectors } from '@/store/event';

import Card from '@/components/newComponents/Card';

import FormSettings from './components/FormSettings';
import FormScore from './components/FormScore';

import useStyles from './styles';

function Partner({ event }) {
  /* Vars */

  const styles = useStyles();
  const [isEnablePartner, setIsEnablePartner] = useState(Boolean(event?.modules?.partner?.enable));
  const t = useFormatMessage();

  /* Functions */

  /**
   * Toogle partners
   * @param {bool} isEnable 
   */
  const handleToogleEnable = (isEnable) => {
    setIsEnablePartner(isEnable);
  };

  /* Render */

  return (
    <div className={styles.root}>
      <Grid container alignItems="center" spacing={3}>
        <Grid item>
          <Typography variant="h3"> {t({ id: 'event.modules.partner.title' })}</Typography>
        </Grid>

        <Grid item>
          <Link href="#">{t({ id: 'help' })}</Link>
        </Grid>
      </Grid>

      <Typography variant="body1" className={styles.description}>
        {t({ id: 'event.modules.partner.description' })}
      </Typography>

      <Card className={styles.card}>
        <FormSettings
          isEnable={isEnablePartner}
          onToogleEnable={handleToogleEnable}
          event={event}
        />

        <FormScore isEnable={isEnablePartner} />
      </Card>
    </div>
  );
}

Partner.propTypes = {
  event: object,
};

Partner.defaultProps = {
  event: {},
};


const mapStateToProps = createStructuredSelector({
  event: eventSelectors.getCurrentEvent,
});

const mapDispatchToProps = {};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  toJS,
)(Partner);
