import React, { useState } from 'react';
import { object, array, bool } from 'prop-types';
import { Body1, IconEye, IconLock, IconEyeOff } from '@seekube-tech/ui-kit';
import colors from '@seekube-tech/ui-kit/dist/colors';
import { useFormatMessage } from 'react-intl-hooks';
import { Form } from 'antd';
import { isPasswordPolicyCompliant } from '@/utils/validations/password';
import InputMaterial from '@/components/Input/InputMaterial';
import styles from '@/forms/components/common/Password/styles.less';
import styles2 from '@/scenes/Auth/scenes/Login/styles2.less';

const FormItem = Form.Item;

export const Password = ({ form, withPasswordPolicy = false, rules = [] }) => {
  const t = useFormatMessage();

  const [passwordFieldType, setPasswordFieldType] = useState('password')

  const passwordError = form.isFieldTouched('password') && form.getFieldError('password');


  const handleOnRevealPassword = () => {
    setPasswordFieldType(passwordFieldType === 'password' ? 'text' : 'password')
  };

  return (
    <>
      <FormItem className={styles.item}>
        <div className={styles.passwordWrapper}>
          <button tabIndex={-1} type="button" className={styles.passwordReveal} onClick={handleOnRevealPassword}>
            {
              passwordFieldType === 'password' ?
                <IconEyeOff color={colors.primary['500']} size={16} />
                : <IconEye color={colors.primary['500']} size={16} />
            }
          </button>
          {form.getFieldDecorator('password', {
            rules: [
              { required: true, message: t({ id: 'form.mandatory' }) },
              { validator: (_, value) => withPasswordPolicy ? isPasswordPolicyCompliant(value) : true, message: ' '},
              ...rules
            ],
            validateTrigger: 'onSubmit',
          })(
            <InputMaterial
              addOnIcon={<IconLock size={24} />}
              label={t({ id: 'form.password' })}
              placeholder={t({ id: 'form.password' })}
              type={passwordFieldType}
              validations={['required']}
            />
          )}
        </div>
      </FormItem>
      {withPasswordPolicy &&
        <Body1 className="mb-20" color={passwordError ? colors.error['500'] : colors.neutral['400']}>
          {t({ id: 'password.error.policy' }, { countMin: process.env.FRONT_APP_PASSWORD_MIN_LENGHT, countMax: process.env.FRONT_APP_PASSWORD_MAX_LENGHT })}
        </Body1>}
    </>
  )
}

Password.propTypes = {
  form: object,
  withPasswordPolicy: bool,
  rules: array
}
