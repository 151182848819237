import React from 'react';
import { string, object } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { Form } from 'antd';
import InputMaterial from '@/components/Input/InputMaterial';
const FormItem = Form.Item;

export const Title = ({ form, initialValue }) => {
  const t = useFormatMessage();

  return (
    <FormItem className="w-7/12 mr-20">
      {form.getFieldDecorator('title', {
        initialValue,
      })(
        <InputMaterial
          max={100}
          addOnIcon="star"
          placeholder={t({ id: 'owner.settings.criteriaTable.title' })}
          label={t({ id: 'owner.settings.criteriaTable.title' })}
          type="text"
          validations={['required']}
        />
      )}
    </FormItem>
  )
}

Title.propTypes = {
  initialValue: string,
  form: object,
}
