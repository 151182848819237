import React, { useState } from 'react';
import { func, object, array } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';

import LinesEllipsis from 'react-lines-ellipsis';
import { partition, toLower, isEmpty } from 'lodash';
import classnames from 'classnames';
import { Select as SelectAntd } from 'antd';
import Select from '@/components/Form/Select';
import Icon from '@/components/Icon';

// Styles & Translations
import styles from '../../styles.less';

const { OptGroup, Option } = SelectAntd;

const SearchOffer = ({
  onOfferSelected,
  event,
  currentUserInExponent,
  offers,
}) => {
  const [offerSearched, setOfferSearched] = useState(offers);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const t = useFormatMessage();

  const displayContract = (arr) => (
    `${arr.join(', ')} -`
  );

  const onSearchOffer = (search) => {
    setOfferSearched(offers.filter((offer) => (toLower(offer.title).includes(toLower(search)))));
  };

  const handleOnOfferChange = (selectedOffer) => {
    setSelectedOffer(selectedOffer);
    onOfferSelected(selectedOffer);
  };

  const renderLocations = (option) => (option?.locations?.[0]?.city || '');

  const renderContracts = (offer) => {
    const contracts = [];

    if (event) {
      const contractCriteria = event._criteria.filter((item) => item.key === 'CONTRACT')[0];
      if (contractCriteria) {
        contractCriteria._choices.forEach((item) => {
          if (item.enable && offer.matching.filters.includes(item._id.toString())) {
            contracts.push(item.label);
          }
        });
      }
    }

    return contracts.length ? displayContract(contracts) : '';
  };

  const renderSubText = (option) => (`${renderContracts(option)} ${renderLocations(option)}`);

  const renderOptions = (offers, lineBreak) => (
    offers.map((offer, key) =>
      (
        <Option key={offer._id} value={offer._id} className={classnames(styles.optionOffer, (lineBreak && key === 0) ? styles.borderTop : '')}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className={styles.optionTitle}>
              <LinesEllipsis
                text={offer.title}
                maxLine="2"
                ellipsis="..."
                trimRight
                basedOn="letters"
              />
            </span>
            <span className={styles.subText}>{renderSubText(offer)}</span>
          </div>
        </Option>
      )
    )
  );

  const currentExponentOffers = offerSearched.filter((offer) => (!(offer.status === 'draft')) && currentUserInExponent);
  const offersGroupByCurrentUser = partition(currentExponentOffers, (offer) => currentUserInExponent._user && offer._user && offer._user._id === currentUserInExponent._user._id);
  const exponentsOffers = offersGroupByCurrentUser[0];
  const otherOffers = offersGroupByCurrentUser[1];

  return (
    <div className="formItem">
      <label htmlFor={styles.inputSelect}>
        <Select
          showSearch
          value={selectedOffer}
          onSearch={onSearchOffer}
          className={styles.inputSelect}
          addOnIcon="attachment"
          placeholder={selectedOffer ? t({ id: 'offer.form.title.label' }) : t({ id: 'event.recruiter.appointmentModal.offer.placeholder.default' })}
          suffixIcon={<Icon name="chevron" />}
          optionFilterProp="children"
          filterOption={false}
          onSelect={handleOnOfferChange}
        >
          {!isEmpty(exponentsOffers) && (
            <OptGroup label={t({ id: 'offers.mine' }).toUpperCase()}>
              {renderOptions(exponentsOffers)}
            </OptGroup>
          )}

          {!isEmpty(otherOffers) && (
            <OptGroup label={t({ id: 'offers.team' }).toUpperCase()}>
              {renderOptions(otherOffers)}
            </OptGroup>
          )}
        </Select>
      </label>
    </div>
  );
}

SearchOffer.propTypes = {
  onOfferSelected: func,
  event: object,
  currentUserInExponent: object,
  offers: array,
  selectedOffer: object,
}

export default SearchOffer;
