import * as React from 'react';
import { number } from 'prop-types';
import { FormattedHTMLMessage } from 'react-intl';
import { IconPhone } from '@seekube-tech/ui-kit';

function AppointmentForCandidate({ countAppointments }) {
  return countAppointments > 0 ?
    <span key="appointmentForCandidate">
      <IconPhone size={16} /><FormattedHTMLMessage values={{ count: countAppointments }} id="events.stats.candidate.appointments.upcoming" />
    </span> : null
}

AppointmentForCandidate.propTypes = {
  countAppointments: number
}

export default AppointmentForCandidate;
