import { call, put, takeLatest, takeEvery, select } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { isEmpty } from 'lodash';
import { push } from 'connected-react-router';
import request, { isInternalServerError } from '@/utils/request';
import * as actions from './actions';
import * as types from './types';
import { getCurrentEvent } from './selectors';
import { entitiesActions } from '../entities';
import { eventSchema, eventListSchema } from './schema';
import { notificationActions } from '../notification';
import { authSelectors } from '../auth';
import { isFromHelloWorkUtm, objectToParams } from '../../utils/url';
import { trackError } from '../../utils/analytics/helpers';

/**
 * Get an event
 */
function* getEvent({ payload: eventIdOrEventSlug }) {
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventIdOrEventSlug}`;

  try {
    const result = yield call(request, requestUrl);

    // Normaliz data
    const dataNormalized = normalize(result, eventSchema);

    // Save entities
    yield put(entitiesActions.replaceEntities({ id: result._id, type: 'events', entities: dataNormalized.entities }));
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    // Set the current event
    yield put(actions.setCurrentEvent(result._id));
  } catch (err) {
    trackError(err);
  }
}

/**
 * GET all events
 */
function* getEvents({ payload: { search = {}, limit = 100, offset, page, context, eventsId = [], callback } }) {
  const searchParams = objectToParams(search);

  const requestUrl = `${process.env.FRONT_API_URL}/events?${searchParams}&offset=${offset}&page=${page}&limit=${limit}&context=${context}&eventsId=${encodeURIComponent(eventsId.join(','))}`;

  try {
    const { docs, limit, total } = yield call(request, requestUrl, {
      method: 'GET',
    });

    // Normaliz data
    const dataNormalized = normalize(docs, eventListSchema);

    // Save entities
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    // Merge allIds
    yield put(actions.getEventsSuccess({
      result: dataNormalized.result,
      currentPage: page,
      context,
      offset,
      limit,
      total,
    }));

    if (typeof callback === 'function') {
      callback(dataNormalized.result);
    }
  } catch (err) {
    trackError(err);

    if (!isInternalServerError(err.code)) {
      yield put(notificationActions.sendNotification({
        message: err.message,
        kind: 'error',
        style: {},
      }));
    }
  }
}

/**
 * Patch an event
 * Description: eventId is slug for redis cache url
 */
function* patchEvent({ payload: { eventId, event, params = {}, notificationParams, callback } }) {
  const currentEvent = yield select(getCurrentEvent);
  const slug = eventId || currentEvent.get('slug');
  const eventParams = objectToParams(params);

  const requestUrl = `${process.env.FRONT_API_URL}/events/${slug}?${eventParams}`;

  // Patch the event
  try {
    const result = yield call(request, requestUrl, {
      method: 'PATCH',
      headers: {
        KoaUpdateCache: 'true',
      },
      body: JSON.stringify(event),
    });

    // Normaliz data
    const dataNormalized = normalize(result, eventSchema);

    // Save entities
    yield put(entitiesActions.replaceEntities({ id: result._id, type: 'events', entities: dataNormalized.entities }));
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    // Push success notification
    if (notificationParams && !isEmpty(notificationParams.success)) {
      yield put(notificationActions.sendNotification({
        message: notificationParams.success.message,
        kind: notificationParams.success.kind,
        style: notificationParams.success.style,
      }));
    }

    if (typeof callback === 'function') {
      callback();
    }

    yield put(actions.patchEventSuccess(dataNormalized.result));
  } catch (err) {
    trackError(err);

    yield put(actions.patchEventError());

    if (notificationParams && notificationParams.error) {
      yield put(notificationActions.sendNotification({
        message: err.message,
        kind: 'error',
        style: {},
      }));
    }
  }
}

/**
 * Post an event
 */
function* postEvent({ payload: { event, callback, notificationParams } }) {
  const requestUrl = `${process.env.FRONT_API_URL}/events`;

  // Post the event
  try {
    const result = yield call(request, requestUrl, {
      method: 'POST',
      body: JSON.stringify(event),
    });

    // Normaliz data
    const dataNormalized = normalize(result, eventSchema);

    // Save entities
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    // Put id in allIds
    yield put(actions.postEventSuccess(dataNormalized.result));

    // Push success notification
    yield put(notificationActions.sendNotification({
      message: notificationParams?.success?.message || '',
      kind: 'success',
    }));

    // Set current event
    yield put(actions.setCurrentEvent(dataNormalized.result));

    if (typeof callback === 'function') {
      callback();
    }
    // Go to the settings page

    yield put(push(`/${result.slug}/owner/settings`));
  } catch (err) {
    trackError(err);
    console.log(err); //eslint-disable-line

    yield put(notificationActions.sendNotification({
      message: err.message || notificationParams?.error?.message || '',
      kind: 'error',
      style: {},
    }));
  }
}

function* duplicateEvent({ payload: { event, callback, notificationParams } }) {
  const requestUrl = `${process.env.FRONT_API_URL}/events/${event._id}/duplicate`;

  // Post the event
  try {
    const result = yield call(request, requestUrl, {
      method: 'POST',
      body: JSON.stringify(event),
    });

    // Normaliz data
    const dataNormalized = normalize(result, eventSchema);

    // Save entities
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    // Put id in allIds
    yield put(actions.duplicateEventSuccess(dataNormalized.result));

    // Push success notification
    yield put(notificationActions.sendNotification({
      message: notificationParams?.success?.message || '',
      kind: 'success',
    }));

    // Set current event
    yield put(actions.setCurrentEvent(dataNormalized.result));

    // Go to the settings page
    yield put(push(`/${result.slug}/owner/settings`));

    if (typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    trackError(err);
    console.log(err); //eslint-disable-line

    yield put(notificationActions.sendNotification({
      message: err.message,
      kind: 'error',
      style: {},
    }));
  }
}

/**
 * Get all events of the auth user
 */
function* getAuthUserEvents({ payload: { getPastEvent, limit, userId = null, context, offset, page, eventId = null } }) {
  let authUser = yield select(authSelectors.getAuthUserConnectedAs);

  if (!authUser) {
    authUser = yield select(authSelectors.getAuthUser);
  }

  const query = typeof getPastEvent === 'boolean' ? '?past=' : '?date=';
  const requestUrl = `${process.env.FRONT_API_URL}/users/${userId || authUser.get('_id')}/events${query}${getPastEvent}&offset=${offset}&limit=${limit}&page=${page}&context=${context}${eventId ? `&event=${eventId}` : ''}`;
  try {
    const { docs, limit, total, eventIds, computedEventsData } = yield call(request, requestUrl);
    let computedDocs;

    if (!isEmpty(docs) && !isEmpty(computedEventsData)) {
      computedDocs = (docs || []).map((doc, index) => ({ ...doc, ...computedEventsData[index] }));
    }
    // Normaliz data
    const dataNormalized = normalize(computedDocs || docs, eventListSchema);

    // Save entities
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    // Save eventIds
    yield put(actions.getAuthUserEventsSuccess({
      userId: authUser._id,
      result: dataNormalized.result,
      currentPage: page,
      context,
      offset,
      limit,
      total,
      eventIds: !isEmpty(eventIds) ? eventIds : null,
    }));
  } catch (err) {
    trackError(err);
    console.log(err); //eslint-disable-line
  }
}

/**
 * Count all events of the auth user
 */
function* countAuthUserEvents() {
  let authUser = yield select(authSelectors.getAuthUserConnectedAs);

  if (!authUser) {
    authUser = yield select(authSelectors.getAuthUser);
  }

  const requestUrl = `${process.env.FRONT_API_URL}/users/${authUser?.get('_id')}/events/count`;

  try {
    const result = yield call(request, requestUrl);

    // Save result
    yield put(actions.countAuthUserEventsSuccess(result));

    // We should not redirect user that has no event if it comes from a HelloWork invite
    if (!authUser.get('_currentOrganization') &&
      result === 0 && !authUser.get('isActive') &&
      !window.location.pathname.includes('/auth/signup') &&
      !isFromHelloWorkUtm()) {
      yield put(push('/auth/signup'));
    }
  } catch (err) {
    trackError(err);
    console.log(err); //eslint-disable-line
  }
}

function* isAuthUserPreregistered({ payload: { eventId, username, phone, callback } }) {
  const query = `?event=${eventId}&username=${username}`;
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/is-preregistered${query}`;

  try {
    const result = yield call(request, requestUrl);

    if (typeof callback === 'function') {
      callback(result);
    }
    // Save result
    yield put(actions.isAuthUserPreregisteredSuccess(result));
  } catch (err) {
    trackError(err);
    console.log(err); //eslint-disable-line
  }
}

function* exportResumes({ payload: { event, participantIds, context, search, callback } }) {
  const requestUrl = `${process.env.FRONT_API_URL}/events/${event._id}/export/resume`;

  // Post the event
  try {
    const result = yield call(request, requestUrl, {
      method: 'POST',
      body: JSON.stringify({ participantIds, context, search }),
    });

    if (typeof callback === 'function') {
      callback(result);
    }
  } catch (err) {
    trackError(err);
    console.log(err); //eslint-disable-line

    yield put(notificationActions.sendNotification({
      message: err.message,
      kind: 'error',
      style: {},
    }));
  }
}

/**
 * Listen Actions
 */
export default [
  takeEvery(types.GET_AUTH_USER_EVENTS, getAuthUserEvents),
  takeLatest(types.COUNT_AUTH_USER_EVENTS, countAuthUserEvents),
  takeLatest(types.IS_AUTH_USER_PREREGISTERED, isAuthUserPreregistered),
  takeLatest(types.GET_EVENT, getEvent),
  takeLatest(types.GET_EVENTS, getEvents),
  takeLatest(types.PATCH_EVENT, patchEvent),
  takeLatest(types.POST_EVENT, postEvent),
  takeLatest(types.DUPLICATE_EVENT, duplicateEvent),
  takeLatest(types.EXPORT_RESUMES, exportResumes),
];
