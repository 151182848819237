import {useQuery} from 'react-query';
import request from '@/utils/request';

export function useGetDeals(
  {
    organizationId,
    page = 1,
  }) {
  const requestUrl = `${process.env.FRONT_API_URL}/organizations/${organizationId}/deals?page=${page}`;

  const queryFn = () => request(requestUrl, {
    method: 'GET',
  });

  return useQuery({
    queryKey: ['deals', page],
    queryFn,
    initialData: [],
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
}
