import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Tag } from '@seekube-tech/ui-kit';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { any, bool, func, object, string } from 'prop-types';
import styles from '@/scenes/Event/scenes/Owner/scenes/Dashboard/styles.less';
import { DASHBOARD_MODE, EVENT_FORMAT } from '@/utils/constants';
import {
  RealTimeActivityViewSwitcher
} from '@/scenes/Event/scenes/Owner/scenes/Dashboard/components/Header/RealTimeActivityViewSwitcher';
import { Subtitle } from '@/scenes/Event/scenes/Owner/scenes/Dashboard/components/Header/Subtitle';
import { HybridTabs } from '@/scenes/Event/scenes/Owner/scenes/Dashboard/components/Header/HybridTabs';

export const Header = ({
  title,
  subtitle,
  withTag = false,
  action = null,
  event, history,
  setView,
  availableActions,
  isLinkVisible
}) => {
  const realtimeDashboard = `/${event.slug}/owner/dashboard/realtime`;
  const defaultDashboard = `/${event.slug}/owner/dashboard`;
  const today = moment(new Date());
  const isJobDatingBegun = moment(event.keyDates?.jobfair.beginAt).isSameOrBefore(today)
    && moment(event.keyDates?.jobfair.endAt).isAfter(today);

  const [dashboardMode, setDashboardMode] = useState(DASHBOARD_MODE.STATIC)

  useEffect(() => {
    if (event.format !== EVENT_FORMAT.HYBRID && isJobDatingBegun) {
      setDashboardMode(DASHBOARD_MODE.DYNAMIC)
    }
    if (event.format === EVENT_FORMAT.HYBRID && isLinkVisible) {
      setDashboardMode(DASHBOARD_MODE.DYNAMIC)
    }
  }, [])

  useEffect(() => {
    if (dashboardMode === DASHBOARD_MODE.DYNAMIC) {
      history.push(realtimeDashboard)
    }
    else {
      history.push(defaultDashboard)
    }
  }, [dashboardMode])


  const handleChangeView = (newView) => {
    const newDashBoardMode = availableActions
      .dashboardView
      .getNewDashboardMode({ event, view: newView, dashboardMode })

    setDashboardMode(newDashBoardMode)
    setView(newView)
  }

  const changeDashboardMode = () => {
    if (dashboardMode === DASHBOARD_MODE.DYNAMIC) {
      setDashboardMode(DASHBOARD_MODE.STATIC)
    }
    else {
      setDashboardMode(DASHBOARD_MODE.DYNAMIC)
    }
  }

  return (
    <div className={styles.header}>
      <div className={styles.title}>
        <div className={styles.leftCol}>
          <h2>{title}</h2>
          <Tag color="error" size="medium" className={classnames('ml-16', !withTag ? 'hide' : '')}>
            <FormattedMessage id="past.plgr" values={{ count: 1, gender: 'male' }} />
          </Tag>
        </div>
        {action ? (<div className={styles.rightCol}>{action}</div>) : null}
      </div>
      <Subtitle
        event={event}
        changeDashboardMode={changeDashboardMode}
        subtitle={subtitle}
        isLinkVisible={isLinkVisible}
      />

      {event.format === EVENT_FORMAT.HYBRID &&
        <div className="flex my-20 justify-between">
          <HybridTabs changeView={handleChangeView} />
          <RealTimeActivityViewSwitcher isLinkVisible={isLinkVisible} handleDashboardMode={changeDashboardMode} />
        </div>
      }
    </div>
  )
};


Header.propTypes = {
  title: string,
  subtitle: any,
  action: any,
  withTag: bool,
  push: func,
  history: object,
  setView: func,
  event: object,
  availableActions: object,
  isLinkVisible: bool
};
