import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import SSO from './scenes/SSO';

const OAuthScene = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/sso`} component={SSO} />
    </Switch>
  );
};

export default OAuthScene
