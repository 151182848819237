import { defineMessages } from 'react-intl';

export default defineMessages({
  popoverOfferMax: {
    id: 'client.create.offer.events.popover.offerMax',
  },
  popoverLiveMax: {
    id: 'client.create.offer.events.popover.liveMax',
  },
});
