import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { useFormatMessage } from 'react-intl-hooks';
import { object, array, func, bool } from 'prop-types';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { If, Then, Else } from 'react-if';
import { Button, H1 } from '@seekube-tech/ui-kit';
import { ANALYTICS_CANDIDATE, EVENT_FORMAT } from '@/utils/constants';
import { track } from '@/utils/analytics';
import { getCdnUrl } from '@/components/ImgCdn';

// Components
import { H2, H3 } from '@/components/Title';
import { Tag } from '@/components/Tag';
import Html from '@/components/Html';
import P from '@/components/P';
import Carousel from '@/components/Carousel';
import Avatar from '@/components/Avatar';
import Separator from '@/components/Separator';
import { getCurrentStand } from '@/forms/Stand/request';
import { arrayContainAll } from '@/utils/math';
import { ParticipationsIcons } from '@/components/ParticipationsIcons';
import Search from '../components/Search';

import styles from './styles.less';
import messages from '../messages';

const onBtnApplyClick = (event, offer, handleApply) => () => {
  track({
    name: ANALYTICS_CANDIDATE.APPLICATION_CLICK,
    event,
    properties: {
      offerId: offer._id,
      offerTitle: offer.title,
    },
  });

  if (handleApply) {
    handleApply();
  }
};

function OfferCardParticipant({ context, offer, event, organization, highlightWords, participant, interaction, handleApply, previewMode, hideOrganizationPicture, showOrganizationDescription, handleShowExponent, intl }) {
  const application = interaction || null;
  const _organization = organization || offer._organization;
  const t = useFormatMessage();
  const handleOnEnlargeCarousel = () => {
    track({
      event,
      name: ANALYTICS_CANDIDATE.COMPANY_PICTURES_CLICK,
    });
  };

  const [profile, setProfile] = useState()

  useEffect(() => {
    getCurrentStand({orgaId: organization?._id, eventId: event._id})
      .then((res) => {
        setProfile(res.profile)
      })
  }, [])

  const renderPictures = () => {
    const pictures = offer && profile && profile.pictures ? profile.pictures : [];
    if (pictures.length > 0) {
      return (
        <div className={styles.carousel}>
          <Carousel
            arrows={false}
            event={event}
            onEnlargeCarousel={handleOnEnlargeCarousel}
            pictures={pictures}
            withEnlargeBtn
            dots
            customPaging={() => <a></a>}
          >
            {pictures.map((src, index) => (<div key={`pictureImg${index}`}><img src={getCdnUrl(src, 999)} alt={_organization.name} /></div>))}
          </Carousel>
        </div>
      );
    }

    return '';
  };

  return (
    <div className={styles.offer}>
      <div className={styles.header}>
        {hideOrganizationPicture ? (
          <H1 className={styles.title} fontWeight={700}>{offer.title}</H1>
        ) : (
          <>
            <img src={offer && profile ? profile.pictureUrl : ''} alt={_organization.name} className={styles.logo} />
            <div className={styles.name}>
              <div className={styles.subtitle}>{offer._organization.name} {t(messages.hire)}</div>
              <H2 bold>{offer.title}</H2>
            </div>
          </>
        )}
        <ParticipationsIcons
          formats={offer.keyMomentFormats}
          name={offer._organization.name}
          className={styles.participationsIcons}
          visible={context === 'owner' || arrayContainAll(participant?.keyMomentFormats, [EVENT_FORMAT.PHYSICAL, EVENT_FORMAT.VIRTUAL])}
        />
      </div>

      <div className={styles.highlights}>
        {offer.matching && <Search offer={offer} event={event} highlightWords={highlightWords} />}
      </div>

      <div className={styles.tags}>
        {offer.tags && offer.tags.map((tag) => (<Tag>{tag}</Tag>))}
      </div>

      <div className={styles.content}>
        {showOrganizationDescription &&
          <>
            {event?.modules?.offer?.hideOrganizationLink !== true && (
              <>
                <H3 bold>{t(messages.about)}</H3>
                {profile ? (<P><Html value={profile.presentation.content} /></P>) : null}
                {renderPictures()}
              </>
            )}
            <Separator height={10} />
          </>
       }

        {event?.modules?.offer?.hideOrganizationLink !== true && typeof handleShowExponent === 'function' &&
            <Button
              className={styles.companyMoreInfosBtn}
              onClick={handleShowExponent}
            >
              {t(messages.companyMoreInfos)}
            </Button>
        }

        {event?.modules?.offer?.hideLabels !== true && (
          <H3 bold>
            {
              !isEmpty(event?.modules?.offer[`labelDescription_${intl.locale}`]) ?
              event?.modules?.offer[`labelDescription_${intl.locale}`] :
              t(messages.offerDescription)
            }
          </H3>
        )}

        <P><Html value={offer.description} /></P>

        {event?.modules?.offer?.hideProfileText !== true ? (
          <>
            { event?.modules?.offer?.hideLabels !== true && (
              <H3 bold>
                {
                  !isEmpty(event?.modules?.offer[`profileDescription_${intl.locale}`]) ?
                  event?.modules?.offer[`profileDescription_${intl.locale}`] :
                  t(messages.profileDescription)
                }
              </H3>
            )}
            <P><Html value={offer.profileDescription} /></P>
          </>
        ) : null}

        {!showOrganizationDescription ? renderPictures() : ''}
      </div>

      {!previewMode ? (
        <div className={styles.bottomAction}>
          <div className={styles.contact}>
            <div className={styles.title}>
              <Avatar size={40} src={offer._user ? offer._user.pictureUrl : null} />
              {offer.title}
            </div>
            <If condition={isEmpty(application)}>
              <If condition={!isEmpty(participant) && !isEmpty(participant._user) && participant.isActive}>
                <Then>
                  <Button onClick={onBtnApplyClick(event, offer, handleApply)}>{t(messages.askAJob)}</Button>
                </Then>
                <Else>
                  <Link to={`/${event.slug}/candidate/preparation/onboarding/signup/${offer._id}`}>
                    <Button onClick={onBtnApplyClick(event, offer)}>{t(messages.askAJob)}</Button>
                  </Link>
                </Else>
              </If>
            </If>
          </div>
        </div>
      ) : ''}
    </div>
  );
}

OfferCardParticipant.propTypes = {
  highlightWords: array,
  offer: object,
  event: object,
  participant: object,
  organization: object,
  interaction: array,
  handleApply: func,
  handleShowExponent: func,
  hideOrganizationPicture: bool,
  showOrganizationDescription: bool,
  previewMode: bool,
  intl: object,
};

OfferCardParticipant.defaultPropTypes = {
  previewMode: false,
  hideOrganizationPicture: false,
};


export default injectIntl(OfferCardParticipant);
