import { useQuery } from 'react-query';
import request from '@/utils/request';
import { objectToParams } from '@/utils/url';

export function useGetOrganizationsCurrentProfile({ orgaId, eventId, enabled = true, ...props  }) {
  const searchParams = objectToParams({ eventId });

  const requestUrl = `${process.env.FRONT_API_URL}/organizations/${orgaId}/profiles/current?${searchParams}`;
  const queryFn = () => request(requestUrl, { method: 'GET' });

  const query = useQuery({
    queryKey: ['organizationCurrentProfile', orgaId, ...searchParams],
    queryFn,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    enabled: !!orgaId && enabled,
    ...props,
  });
  return query;
}
