import React from 'react';
import { number, string } from 'prop-types';

import { cssWarning } from '@/utils/text';

import { Tooltip } from '@/components/Tooltip';

import styles from './styles.less';


const WithPercentage = ({ value, tooltip = '', warningFrom = 100 }) => (
  <Tooltip title={tooltip} color="dark">
    <span>
      <span className={styles.separatorAdditionalText}>-</span><span
      className={cssWarning(warningFrom < value)}>{value}%</span>
    </span>
  </Tooltip>
)

WithPercentage.propTypes = {
  value: number,
  tooltip: string,
  warningFrom: number,
}

export { WithPercentage }
