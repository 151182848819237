import React from 'react';

// components
import { func, string, object } from 'prop-types';
import { Form } from 'antd';
import WysiwygEditor from '@/components/WysiwygEditor';

import messages from '../../messages';

const FormItem = Form.Item;

const OfferProfileDescriptionForm = ({ initialValue, handleOnProfileDescriptionChange, showHelper, intl }) => {// eslint-disable-line
  const onDescriptionChange = (value) => handleOnProfileDescriptionChange(value);

  return (
    <FormItem label={intl.formatMessage(messages.profileDescriptionLabel)} required>
      <WysiwygEditor
        initialValue={initialValue}
        showHelper={showHelper}
        onChange={onDescriptionChange}
      />
    </FormItem>
  );
};
OfferProfileDescriptionForm.propTypes = {
  initialValue: string,
  handleOnProfileDescriptionChange: func,
  showHelper: func,
  intl: object,
};

export { OfferProfileDescriptionForm };
