
function copyToClipboard(value) {
  function fallbackCopyTextToClipboard(text) {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();

    document.execCommand('copy');
    document.body.removeChild(textArea);
  }

  fallbackCopyTextToClipboard(value);
}

export default copyToClipboard;
