import React from 'react';
import { H5, Button } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { useHistory } from 'react-router-dom';

const NewPasswordUpdatedSuccessfully = () => {
  const t = useFormatMessage();
  const history = useHistory();

  return (
    <div className="finish-step">
      <H5 className="mb-20">
        <span>{t({ id: 'forgotPassword.completeTitle' })}</span>
      </H5>

      <Button onClick={() => history.push('/')}>
        {t({ id: 'forgotPassword.completeAction' })}
      </Button>
    </div>
  );
};

export { NewPasswordUpdatedSuccessfully }
