import { isEmpty } from 'lodash';
import moment from 'moment/moment';

export function getFarthestKeyMoment(event, momentType = "jobfair", format) {
  /**
   * Do not filter if format is undefined
   */
  const keyMoments = format ?
    event?.keyMoments?.[momentType].filter(keyMoment => keyMoment.format === format) :
    event?.keyMoments?.[momentType];

  if (!isEmpty(keyMoments)) {
    return keyMoments.reduce((prev, keyMoment) => {
      if (moment(prev).isAfter(moment(keyMoment.endAt))) {
        return prev
      }

      return moment(keyMoment.endAt)
    }, moment(keyMoments[0].endAt))
  }

  if (event?.keyDates) {
    return event.keyDates?.[momentType]?.endAt ? moment(event.keyDates?.[momentType]?.endAt) : undefined;
  }

  return undefined;
}
