import { defineMessages } from 'react-intl';

export default defineMessages({
  offerAvailableToday: {
    id: 'offer.available.today',
  },
  draft: {
    id: 'event.status.draft',
  },
  applicationPending: {
    id: 'application.pending',
  },
  applicationRefuse: {
    id: 'application.refuse',
  },
  contactByPhone: {
    id: 'contact.by.phone',
  },
  contactByVisio: {
    id: 'contact.by.visio',
  },
  goToAppointment: {
    id: 'go.to.appointment',
  },
  showCurrentOffer: {
    id: 'show.offer',
  },
  recruiterManagerAppointment: {
    id: 'recruiter.manager.appointment',
  },
  showRecruiterOffer: {
    id: 'show.recruiter.offer',
  },
  appointmentChooseSlot: {
    id: 'event.candidate.appointments.card.chooseSlot',
  },
  preview: {
    id: 'preview',
  },
  offerUpdate: {
    id: 'offer.update',
  },
  hire: {
    id: 'hire',
  },
  companyMoreInfos: {
    id: 'company.more.infos',
  },
  offerDescription: {
    id: 'offer.form.description.label',
  },
  profileDescription: {
    id: 'offer.form.profileDescription.label',
  },
  askAJob: {
    id: 'ask.a.job',
  },
  about: {
    id: 'about',
  },
  search: {
    id: 'search.inclusive',
  },
  searchFrom: {
    id: 'search.availability',
  },
  month: {
    id: 'month',
  },
  accepted: {
    id: 'accepted',
  },
  refused: {
    id: 'refused',
  },
  pending: {
    id: 'pending',
  },
  noSlot: {
    id: 'noSlot',
  },
  publishOnXJobdating: {
    id: 'client.offers.card.published.on.x.jobdating',
  },
});
