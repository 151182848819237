import React from 'react';
import { object } from 'prop-types';
import { AvatarBusiness, Body2, H4 } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import classNames from 'classnames';
import { mapAndJoin } from '@/utils/text';
import styles from '@/scenes/Event/containers/Landing/components/Title/styles.less';
import { getLocale } from '@/utils/localStorage';
import { useIsMobile } from '@/utils/MediaQueries';

const Title = ({
  event,
}) => {
  const t = useFormatMessage();
  const isWiderThanMobile = !useIsMobile();


  const getAvatars = (size) => (
      <div className={classNames(styles.landingPlannersAvatars, size === 'large' ? 'mr-24' : 'mr-16')}>
        {
          event.planners._organizations.map((orga) =>
            <AvatarBusiness
              size={size}
              pictureUrl={(orga.profile?.logo || orga.profile?.pictureUrl)}
              variant="business"
              color={orga.isPartner ? event?.modules?.partner?.color : 'default'}
              badge={orga.isPartner ? event?.modules?.partner?.icon : null}
              tooltip={event?.modules?.partner?.[`label_${getLocale()}`]}
              className={styles.landingPlannersAvatar}
            />
          )
        }
      </div>
    )

  return (
    <div className={styles.landingTitleContainer}>
      {(event.planners._organizations.length < 3 && isWiderThanMobile) &&  getAvatars('large')}
      <div className="w-full">
        <H4 fontWeight={isWiderThanMobile ? 900 : 700}>{event.name}</H4>
        <div className="flex items-center mt-8">
          {((event.planners._organizations.length > 2) || !isWiderThanMobile) &&
            getAvatars('small')
          }
          <Body2 className="text-neutral-400">
            {t({id: 'event.landing.organizedBy.test'}, {count: event.planners._organizations.length})} {mapAndJoin(event.planners._organizations, 'name', ', ')}
          </Body2>
        </div>
      </div>
    </div>
  );
}

Title.propTypes = {
  event: object,
}

export { Title };
