// used colors are "Annexes", 200 for light, 600 for main
export const EVENTS_COLORS = [
  // Apple
  {
    main: '#359349',
    light: '#D9F1DE',
  },
  // Blue
  {
    main: '#0099E0',
    light: '#D1F0FF',
  },
  // Cherry
  {
    main: '#DD0336',
    light: '#FED2DC',
  },
  // Chocolate
  {
    main: '#9D6443',
    light: '#F1E6DF',
  },
  // Orange
  {
    main: '#E03400',
    light: '#FFDCD1',
  },
  // Orchid
  {
    main: '#8516CA',
    light: '#ECD6FA',
  },
  // Pink
  {
    main: '#CA166A',
    light: '#FAD6E7',
  },
  // Royal Blue
  {
    main: '#0249AE',
    light: '#C5DDFE',
  },
  // Yellow
  {
    main: '#F5B200',
    light: '#FFF4D6',
  },
  // Teal
  {
    main: '#16CA88',
    light: '#D6FAED',
  },
  // Gold
  {
    main: '#AD8434',
    light: '#F4ECDC',
  },
];

export const EVENT_TYPE = {
  JOBFAIR: 'jobfair',
  TIMESLOT: 'timeslot',
};

export const EVENT_MEDIUM = {
  PHONE: 'phone',
  VISIO: 'visio',
  PHYSICAL: 'physical',
};
