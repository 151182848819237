import React from 'react';
import { object, func, array } from 'prop-types';
import { Button, IconTrash2 } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import styles from '@/features/agenda/recruiter/components/CalendarFooter/styles.less';

const FooterDeleteMode = ({ setModalContext, setIsDeleteModeActive, slotSelectedForDelete }) => {
  const t = useFormatMessage();

  return (
    <div className={styles.footerDeleteMode}>
      <Button
        onClick={() => setModalContext({ isVisible: true, context: 'deleteAllSlots' })}
        variant="tonal"
        size="small"
        color="error"
        imageComponentLeft={<IconTrash2 size={16} />}
      >
        {t({ id: 'action.delete-all' })}
      </Button>
      <Button
        onClick={() => setIsDeleteModeActive(false)}
        variant="outline"
        size="small"
        color="neutral"
      >
        {t({ id: 'cancel' })}
      </Button>
      <Button
        onClick={() => setModalContext({ isVisible: true, context: 'deleteSelectedSlots' })}
        variant="fill"
        size="small"
        color="error"
        disabled={slotSelectedForDelete.length === 0}
      >
        {t({ id: 'delete.selected.slots' })}
      </Button>
    </div>
  )
}

FooterDeleteMode.propTypes = {
  setIsDeleteModeActive: func,
  slotSelectedForDelete: array,
  setModalContext: object,
}

export { FooterDeleteMode }
