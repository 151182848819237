import React from 'react';
import { array, func, number, bool, object, node } from 'prop-types';
import './styles.less';
import { isEmpty } from 'lodash';
import { Pagination } from '@seekube-tech/ui-kit';
import styles from '@/scenes/Events/scenes/components/JobdatingsYours/styles.less';
import { JobdatingCard } from '@/scenes/Events/scenes/components/JobdatingsYours/components/JobdatingCard';
import { getId } from '@/utils/global';
import { getCTARole } from '@/utils/event';
import { NoJobdating } from '@/scenes/Events/scenes/components/JobdatingsYours/components/NoJobdating';
import { useGetUserEventsParticipation } from '@/queries/users/useGetUserEventsParticipation';

const DEFAULT_PAGINATION = {
  pageSize: 5,
  limit: 5,
  page: 1,
  offset: 0,
  total: 0,
};

const JobdatingsYours = (props) => {
  const {
    events,
    authEvents,
    isFetching,
    onPaginationChange,
    userPagination,
    authUser,
    total,
    filters,
    goTo,
    downloadAllCV2,
    loadingDownload,
    isArchiveView
  } = props;
  const isCandidate = isEmpty(authUser?._currentOrganization);
  const participations = useGetUserEventsParticipation({ userId: authUser._id, enabled: isCandidate });
  const { hasPastParticipations, hasFutureParticipations } = participations.data || {};

  if (isCandidate && !hasPastParticipations && !hasFutureParticipations) {
    return null;
  }

  return (
    <div>
      <div className={styles.eventsContainer}>
        {filters}

        {isFetching ? (
          <div>
            <JobdatingCard key={0} isSkeleton />
            {authUser._currentOrganization ? (<JobdatingCard key={1} isSkeleton />) : ''}
            {authUser.isAdmin ? (<JobdatingCard key={2} isSkeleton />) : ''}
            {authUser.isAdmin ? (<JobdatingCard key={3} isSkeleton />) : ''}
            {authUser.isAdmin ? (<JobdatingCard key={4} isSkeleton />) : ''}
          </div>) : (
          <div>
            <div>
              {total > 0 ? (events || []).map((event) => {
                const role = getCTARole(event);

                return (
                  <JobdatingCard
                    key={getId(event._id)}
                    event={event}
                    goTo={goTo}
                    role={role}
                    authUser={authUser}
                    downloadAllCV2={() => downloadAllCV2(event)}
                    loadingDownload={loadingDownload}
                  />);
              }) : <NoJobdating authUser={authUser} isArchiveView={isArchiveView} goTo={goTo} />}
            </div>
          </div>
        )}
      </div>
      {(!isFetching || authEvents.isLoading) && authEvents.data?.total > DEFAULT_PAGINATION.limit &&
        (<Pagination
          className="flex justify-center"
          current={userPagination ? parseInt(userPagination.page || userPagination, 10) : 1}
          pageSize={authEvents.data?.limit}
          total={total}
          onChange={onPaginationChange}
        />)
      }
    </div>
  )

}

JobdatingsYours.propTypes = {
  events: array,
  authEvents: object,
  goTo: func,
  isFetching: bool,
  authUser: object,
  downloadAllCV2: func,
  loadingDownload: bool,
  isArchiveView: bool,
  total: number,
  onPaginationChange: func,
  userPagination: object,
  filters: node
};

export default React.memo(JobdatingsYours);
