import React from 'react';
import { node, string, oneOfType, oneOf } from 'prop-types';
import omit from 'lodash/omit';
import classnames from 'classnames';

// Styles & Translations
import styles from './styles.less';

/**
 * Wrapper
 * @param {object} props
 */
const Wrapper = (props) => (
  <div
    className={classnames(styles[props.size], props.className, styles.wrapper, styles[props.type], styles[props.context])}
    {...omit(props, ['className', 'children', 'bold'])}
  >
    {props.children}
  </div>
);

Wrapper.propTypes = {
  children: oneOfType([string, node]).isRequired,
  size: oneOf(['medium', 'large', 'full']),
  type: oneOf(['scene']),
  context: oneOf(['owner']),
  className: string,
};

export default Wrapper;
