import React from 'react';
import classnames from 'classnames';
import { array, object, string, bool, number } from 'prop-types';
import { isEmpty, get } from 'lodash';
import { Tag } from '@seekube-tech/ui-kit';

import { useFormatMessage } from 'react-intl-hooks'

// Components
import { ShowMore } from '@/components/ShowMore';

// Styles & Translations
import styles from './styles.less';
import messages from '../../messages';

const MAX_CHARACTER = 50;

const Applications = ({ interaction, appointment, currentUser, size, forceRender, count, offers }) => {
  const t = useFormatMessage();
  let offerItems = offers;

  const { type } = interaction;

  if (type === 'proposition' && isEmpty(interaction._offers)) {
    return null;
  }

  if (type === 'proposition') {
    offerItems = interaction._offers;
  }

  if (type === 'informal1to1') {
    offerItems =[{ title: t({ id: 'chat.oneToOne.pl' }, { count: 0 })}]
  }

  if ((isEmpty(interaction) && isEmpty(offerItems)) || (isEmpty(offerItems) && !forceRender)) {
    return null;
  }

  if (!['proposition', 'application', 'informal1to1'].includes(type) && get(appointment, '_organizationUser._id') === currentUser._id) return null;


  // Cut offer in 2 arrays to prevents too long list of offers
  // If offers length is greater than 6, show 6 offers and add a link "show more" to display the others
  // Else, show all offers at the same time
  const defaultDisplayedOffers = offerItems?.length > 6 ? offerItems.slice(0, 6) : offerItems;
  const otherOffers = offerItems?.length > 6 ? offerItems.slice(6) : [];

  return (
    <div className={classnames(styles.applicationsContainer, styles[size], forceRender ? styles.agendaCard : '')}>
      <div className={styles.offerContainer}>
        <span>{type === 'proposition' && type !== 'informal1tot1' ? t(messages.applicationsSourcing) : t(messages.applicationsApply) }</span>
        <div className={styles.list}>
          {defaultDisplayedOffers.map((item, index) => item && (
            <RenderTag count={count} title={item.title} key={`application_${index + 1}`} />
          ))}

          <ShowMore maxHeight={0}>
            {otherOffers.map((item, index) => item && (
              <RenderTag count={count} title={item.title} key={`application_${defaultDisplayedOffers.length + index + 1}`} />
            ))}
          </ShowMore>
        </div>
      </div>
    </div>
  );
};

const RenderTag = ({ title = '', withBorder = true, count }) => {
  if (isEmpty(title)) return '';

  return (
    withBorder ?
      <div className={styles.tagContainer} title={title}>
        <Tag
          variant="outline"
          color="grey"
          key={title}
          className={count ? styles.noMargin : ''}
        >{
          sliceLongText(title || '')}
        </Tag>
      </div> : null
  );
};

const sliceLongText = (text) => text.length > MAX_CHARACTER ? `${text.slice(0, MAX_CHARACTER)}...` : text;

Applications.propTypes = {
  size: string,
  count: number,
  offers: array,
  currentUser: object,
  appointment: object,
  interaction: object,
  forceRender: bool,
};

RenderTag.propTypes = {
  title: string,
  count: number,
  withBorder: bool,
};

Applications.defaultProps = {
  currentUser: null,
  forceRender: false,
};

export default React.memo(Applications);
