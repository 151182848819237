import React from 'react';
import { Checkbox } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';

const RememberMe = ({
  onChange,
  checked
}) => {
  const t = useFormatMessage();
  
  return (
    <Checkbox
      label={t({ id: 'rememberMe' })} 
      id="rememberMe"
      name="rememberMe"
      onChange={onChange}
      checked={checked}
    />
  )
}

export { RememberMe };