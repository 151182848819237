import React, { useState } from 'react';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { object, func, bool, string, array, number, any } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { isEmpty, isUndefined, uniq, includes, debounce } from 'lodash';
import { Form, Input, Icon as IconAntd } from 'antd';
import { useFormatMessage } from 'react-intl-hooks';
import { offerSelectors } from '@/store/offer';
import { exponentSelectors } from '@/store/exponent';
import { participantActions, participantSelectors } from '@/store/participant';
import { eventSelectors } from '@/store/event';
import { userActions } from '@/store/user';
import { authSelectors } from '@/store/auth';
import { percentage } from '@/utils/math';
import { trackEditedSearchClick } from '@/utils/analytics';
import { toJS } from '@/utils/index';
import ApiToForm from '@/services/Form/ApiToForm';
import { If } from '@/components/If';
import Modal from '@/components/Modal';
import Icon from '@/components/Icon';
import { CornersButton } from '@/components/Corners';
import { EVENT_TYPE } from '@/utils/constants';
import { queryStringToObject } from '@/utils/url';
import styles from '@/scenes/Event/components/StaticOffers/components/Filters/styles.less';
import { useIsMobile } from '@/utils/MediaQueries';
import {
  DurationSelector,
  JobSelector,
  LocationSelector,
  SectorSelector,
  SizeSelector,
  ContractSelector,
  CustomSelector,
  ConferenceOrganizationSelector,
  TopicSelector,
  ConferenceSizeSelector,
  OrganizationStatusSelector, SearchBar,
} from '@/scenes/Event/scenes/Candidate/scenes/JobDating/components/SearchEngine/components';
import {
  AvailabilitySelector
} from '@/scenes/Event/scenes/Candidate/scenes/JobDating/components/SearchEngine/components/AvailabilitySelector';
import {
  InformalOrganizationSelector
} from '@/scenes/Event/scenes/Candidate/scenes/JobDating/components/SearchEngine/components/InformalOrganizationSelector';
import ResultsActions from '@/scenes/Event/scenes/Candidate/scenes/JobDating/components/ResultsActions';

const FormItem = Form.Item;

const DEFAULT_KEYS = ['CONTRACT', 'MOBILITY', 'POSITION', 'DURATION', 'AVAILABILITY'];

const debounceSearch = debounce((fct) => fct(), 500);

const SearchEngine  = (props) => {
  const {
    event,
    participant,
    defaultSectors,
    participantOffersLastSearch,
    participantExponentsLastSearch,
    context,
    authUser,
    facetsConferences,
    statusFilter,
    facetsExponents
  } = props;

  const t = useFormatMessage();
  const isMobile = useIsMobile();
  const facetSelector = useSelector(offerSelectors.getFacets);
  const facets = props.facets ?? facetSelector;

  const lastSearch = context === 'exponent' ? participantExponentsLastSearch : participantOffersLastSearch;

  const sortCriteria = event._criteria
    .filter((criterion) => criterion.enable && criterion.modules.searchEngineParticipant.isVisible)
    .sort((a, b) => a.modules.onboarding.position - b.modules.onboarding.position);

  const criterias = sortCriteria.filter((c) => c.key && c.modules.offer.isVisible);
  const customCriterias = sortCriteria.filter((c) => !DEFAULT_KEYS.includes(c.key) && c.modules.offer.isVisible);

  const [isModalFilter, setIsModalFilter] = useState(false);
  const [search, setSearch] = useState(getInitSearch());
  const [keywords, setKeywords] = useState(getInitKeywords());

  const [matching, setMatching] = useState(ApiToForm
    .matchingFiltersParticipant(sortCriteria,
      !isEmpty(lastSearch) ?
        { ...lastSearch.matchingTmp, dateStr: lastSearch.matchingTmp.dateStr || lastSearch.matchingTmp.availability } : ((participant && participant.matching) || {})
    ));

  const sectorsOptions = defaultSectors;

  function getInitSearch() {
    if (!isEmpty(lastSearch?.search?.keywords)) {
      return ({ ...props.search, keywords: lastSearch.search.keywords })
    }

    if (['conference', 'landingOffer'].includes(context)) {
      return ({ ...props.search, ...queryStringToObject(window.location.search) })
    }

    return props.search || {}
  }

  function getInitKeywords() {

    if (lastSearch && lastSearch.search && !isEmpty(lastSearch.search.keywords)) {
      return lastSearch.search.keywords;
    }
    if (['conference', 'landingOffer'].includes(context)) {
      return queryStringToObject(window.location.search).keywords;
    }
    return null;
  }

  const analyticsEditSearchBtn = () => {
    trackEditedSearchClick({
      user: authUser,
      event,
    });

    props.onShowMatchingModal();
  };

  const updateMatching = (matchingFilters, values, facetKey) => {
    const newMatching = { ...matching, filters: uniq([...matchingFilters, ...values]), [facetKey]: values };
    const newSearch = { ...search, page: 1 };

    setMatching(newMatching);

    setSearch(newSearch)

    props.onSearch(newSearch, newMatching);
  };

  const updateMatchingCustom = (criterion, matchingFilters, values) => {
    const newMatching = { ...matching, availability: uniq([...matchingFilters, ...values]) };

    if (criterion.key === 'AVAILABILITY') {
      setMatching(newMatching);
    }

    setSearch(search);

    props.onSearch(search, newMatching, criterion.key);
  };

  const updateSearch = (key, value, facetKey) => {
    const newSearch = { ...search, [key]: value, page: 1 };

    setSearch(newSearch);

    if (key === 'keywords') {
      setKeywords(value);
      debounceSearch(() => props.onSearch(newSearch, matching, facetKey), 1000);
    } else {
      props.onSearch(newSearch, matching, facetKey)
    }
  }

  const resetSearch = () => {
    delete matching.availability;

    const iniSearch = {
      keywords: '',
      sector: '',
      localization: '',
      organizations: '',
      topics: '',
      size: '',
      page: 1,
    };

    const newMatching = { ...matching, filters: [], dateStr: null };

    setSearch(iniSearch)
    setMatching(newMatching)

    props.onSearch(iniSearch, newMatching);
  }

  const searchIsEmpty = () => isEmpty(search.keywords) && isEmpty(matching.filters.filter((f) => !isEmpty(f))) && isEmpty(matching.dateStr) &&
    isEmpty(search.size) && isEmpty(search.sector) && isEmpty(search.organizations) && isEmpty(search.topics)

  const corners = event.modules?.corner?.items || [];

  return (
    <>
      {includes(['offer', 'owner'], context) ? (
        <Form className={`${styles.searchFormContainer}`}>
          <div className={styles.searchFormTop}>
            <FormItem className={styles.formSearchItem}>
              <Input
                className={styles.searchInput}
                placeholder={t({ id: 'search' })}
                value={keywords}
                prefix={<IconAntd type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
                onChange={(e) => updateSearch('keywords', e.target.value, null, context)}
                onPressEnter={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              />
              <button onClick={() => setIsModalFilter(true)} className="openFiltersIcon show-mobile">
                <Icon name="filters" />
              </button>
            </FormItem>
            <ContractSelector
              matching={matching}
              updateMatching={updateMatching}
              criterias={criterias}
              facets={facets}
            />
            {includes(['offer', 'owner', 'admin'], context) ?
              <LocationSelector
                isMobile={false}
                facetKey="MOBILITY"
                facets={facets}
                matching={matching}
                updateSearch={updateSearch}
                updateMatching={updateMatching}
                search={search}
                criterias={criterias}
                context={context}
                facetsExponents={facetsExponents}
              />
              : ''}
            <JobSelector
              isMobile={false}
              facetKey="POSITION"
              matching={matching}
              updateMatching={updateMatching}
              facets={facets}
              criterias={criterias}
            />
          </div>
          <div className={styles.searchFormBottom}>
            {event.modules?.offer?.searchEngineFilters?.sectors === false ? null :
              <SectorSelector
                isMobile={false}
                facetKey="sectors"
                matching={matching}
                updateSearch={updateSearch}
                updateMatching={updateMatching}
                search={search}
                facetsExponents={facetsExponents}
                facets={facets}
                context={context}
                sectorsOptions={sectorsOptions}
              />
            }
            {event.modules?.offer?.searchEngineFilters?.size === false ? null :
              <SizeSelector
                isMobile={false}
                facetKey="size"
                statusFilter={statusFilter}
                matching={matching}
                updateSearch={updateSearch}
                updateMatching={updateMatching}
                search={search}
                facetsExponents={facetsExponents}
                facets={facets}
                context={context}
              />
            }
            <DurationSelector
              isMobile={false}
              facetKey="DURATION"
              matching={matching}
              updateMatching={updateMatching}
              criterias={criterias}
              facets={facets}
            />
            <AvailabilitySelector
              isMobile={false}
              facetKey="AVAILABILITY"
              matching={matching}
              updateSearch={updateSearch}
              updateMatchingCustom={updateMatchingCustom}
              criterias={criterias}
              context={context}
              facets={facets}
            />
            <CustomSelector
              matching={matching}
              updateMatching={updateMatching}
              customCriterias={customCriterias}
              context={context}
              facets={facets}
              event={event}
            />
          </div>
        </Form>
      ) : ''}
      {includes(['landingOffer'], context) ? (
        <Form className={`${styles.searchFormContainer}`}>
          <div className={styles.searchFormTop}>
            <FormItem className={styles.formSearchItem}>
              <Input
                className={styles.searchInput}
                placeholder={t({ id: 'search' })}
                value={keywords}
                prefix={<IconAntd type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
                onChange={(e) => updateSearch('keywords', e.target.value, null, context)}
                onPressEnter={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              />
              <button onClick={() => setIsModalFilter(true)} className="openFiltersIcon show-mobile">
                <Icon name="filters" />
              </button>
            </FormItem>
            <ContractSelector
              matching={matching}
              updateMatching={updateMatching}
              criterias={criterias}
              facets={facets}
            />
            <LocationSelector
              isMobile={false}
              facetKey="MOBILITY"
              facets={facets}
              matching={matching}
              updateSearch={updateSearch}
              updateMatching={updateMatching}
              search={search}
              criterias={criterias}
              context={context}
              facetsExponents={facetsExponents}
            />
          </div>
        </Form>
      ) : ''}

      {context === 'exponent' ? (
        <Form className={`${styles.searchFormContainer}`}>
          <div className={styles.searchFormTop}>
            <SearchBar
              updateSearch={updateSearch}
              onIconClick={() => setIsModalFilter(true)}
              keywords={keywords}
            />
            {event.modules?.organization?.searchEngineFilters?.sectors === false ? null :
              <SectorSelector
                isMobile={false}
                facetKey="sectors"
                matching={matching}
                updateSearch={updateSearch}
                updateMatching={updateMatching}
                search={search}
                facetsExponents={facetsExponents}
                facets={facets}
                context={context}
                sectorsOptions={sectorsOptions}
              />
            }
            <LocationSelector
              isMobile={false}
              facetKey="MOBILITY"
              matching={matching}
              updateSearch={updateSearch}
              updateMatching={updateMatching}
              search={search}
              criterias={criterias}
              context={context}
              facetsExponents={facetsExponents}
              facets={facets}
            />
            {event.modules?.organization?.searchEngineFilters?.size === false ? null :
              <SizeSelector
                isMobile={false}
                facetKey="size"
                statusFilter={statusFilter}
                matching={matching}
                updateSearch={updateSearch}
                updateMatching={updateMatching}
                search={search}
                facetsExponents={facetsExponents}
                facets={facets}
                context={context}
              />
            }
            {statusFilter ?
              <OrganizationStatusSelector
                isMobile={false}
                facetKey="status"
                updateSearch={updateSearch}
                search={search}
                facetsExponents={facetsConferences}
                context={context}
              />
              : null}
          </div>
        </Form>
      ) : null}

      {context === 'conference' ? (
        <Form className={`${styles.searchFormContainer}`}>
          <div className={styles.searchFormTop}>
            <SearchBar
              updateSearch={updateSearch}
              onIconClick={() => setIsModalFilter(true)}
              keywords={keywords}
            />
          </div>
          <div className={styles.searchFormBottom}>
            {event.type !== EVENT_TYPE.jpo &&
              <TopicSelector
                isMobile={false}
                facetKey="topics"
                updateSearch={updateSearch}
                search={search}
                facetsConferences={facetsConferences}
                context={context}
              />}
            <ConferenceOrganizationSelector
              isMobile={false}
              facetKey="organizations"
              updateSearch={updateSearch}
              search={search}
              facetsConferences={facetsConferences}
              context={context}
            />
            {event.type !== EVENT_TYPE.jpo &&
              <ConferenceSizeSelector
                isMobile={false}
                facetKey="size"
                updateSearch={updateSearch}
                search={search}
                facetsConferences={facetsConferences}
              />
            }
          </div>
        </Form>
      ) : null}

      {context === 'informal1to1' && (
        <Form className={`${styles.searchFormContainer}`}>
          <div className={styles.searchFormTop}>
            <SearchBar
              updateSearch={updateSearch}
              onIconClick={() => setIsModalFilter(true)}
              keywords={keywords}
            />
            <InformalOrganizationSelector
              isMobile={false}
              facetKey="organizations"
              updateSearch={updateSearch}
              search={search}
              facetsInformal={props.facetsInformal}
              context={context}
            />
          </div>
        </Form>
      )}

      <If condition={!props.hideReset}>
        <div className={[styles.searchResult, (isUndefined(event)) ? 'hidden-mobile' : ''].join(' ')}>
          {context === 'owner' ?
            <h3 className={styles.countResult}>
              <FormattedMessage id="result.count" values={{ count: props.countOffers }} /> | {percentage(props.countOffers, props.totalOffers, 0, true)}%</h3> : null}
          {includes(['offer', 'owner', 'exponent', 'conference', 'informal1to1'], context) ?
            <ResultsActions
              event={event}
              context={context}
              preview={props.preview}
              searchIsEmpty={searchIsEmpty}
              totalOffers={props.totalOffers}
              resetSearch={resetSearch}
              analyticsEditSearchBtn={analyticsEditSearchBtn}
            /> : ''}
        </div>
      </If>

      {props.isShowCorners && (
        <div className={styles.corners}>
          <CornersButton
            corners={corners}
            onChange={(cornersId) => updateSearch('corners', cornersId)}
            selecteds={search.corners}
            locale={authUser.locale}
          />
        </div>
      )}

      <Modal
        visible={isModalFilter}
        footer={null}
        className={styles.modalFilters}
      >
        <a role="button" tabIndex={0} className="modal-close" onClick={() => setIsModalFilter(false)}>
          <Icon name="close" className="modal-close-icon" />
        </a>
        <div className={styles.header}>
          <h1>Filters</h1>
        </div>
        <div className={styles.modalContent}>
          {includes(['offer', 'owner'], context) ? (<div>
            <ContractSelector
              isMobile
              facetKey="CONTRACT"
              matching={matching}
              updateMatching={updateMatching}
              criterias={criterias}
              facets={facets}
            />
            <LocationSelector
              isMobile
              facetKey="MOBILITY"
              matching={matching}
              updateSearch={updateSearch}
              updateMatching={updateMatching}
              search={search}
              criterias={criterias}
              context={context}
              facetsExponents={facetsExponents}
              facets={facets}
            />
            <JobSelector
              isMobile
              facetKey="POSITION"
              matching={matching}
              updateMatching={updateMatching}
              facets={facets}
              criterias={criterias}
            />
            <SectorSelector
              isMobile
              facetKey="sectors"
              matching={matching}
              updateSearch={updateSearch}
              updateMatching={updateMatching}
              search={search}
              facetsExponents={facetsExponents}
              facets={facets}
              context={context}
              sectorsOptions={sectorsOptions}
            />
            <SizeSelector
              isMobile
              facetKey="size"
              statusFilter={statusFilter}
              matching={matching}
              updateSearch={updateSearch}
              updateMatching={updateMatching}
              search={search}
              facetsExponents={facetsExponents}
              facets={facets}
              context={context}
            />
            <DurationSelector
              isMobile
              facetKey="DURATION"
              matching={matching}
              updateMatching={updateMatching}
              criterias={criterias}
              facets={facets}
            />
            <AvailabilitySelector
              isMobile
              facetKey="AVAILABILITY"
              matching={matching}
              updateSearch={updateSearch}
              updateMatchingCustom={updateMatchingCustom}
              criterias={criterias}
              context={context}
              facets={facets}
            />
            <CustomSelector
              isMobile
              matching={matching}
              updateMatching={updateMatching}
              customCriterias={customCriterias}
              context={context}
              facets={facets}
              event={event}
            />
          </div>) : ''}
          {includes(['exponent'], context) ? (
            <div>
              <SectorSelector
                isMobile
                matching={matching}
                updateSearch={updateSearch}
                updateMatching={updateMatching}
                search={search}
                facetsExponents={facetsExponents}
                facets={facets}
                context={context}
                sectorsOptions={sectorsOptions}
              />
              <LocationSelector
                isMobile
                matching={matching}
                updateSearch={updateSearch}
                updateMatching={updateMatching}
                search={search}
                criterias={criterias}
                context={context}
                facetsExponents={facetsExponents}
                facets={facets}
              />
              <SizeSelector
                isMobile
                statusFilter={statusFilter}
                matching={matching}
                updateSearch={updateSearch}
                updateMatching={updateMatching}
                search={search}
                facetsExponents={facetsExponents}
                facets={facets}
                context={context}
              />
            </div>
          ) : ''}

          {context === 'conference' ? (<div>
            {event.type !== EVENT_TYPE.jpo &&
              <TopicSelector
                isMobile
                updateSearch={updateSearch}
                search={search}
                facetsConferences={facetsConferences}
                context={context}
              />}
            <ConferenceOrganizationSelector
              isMobile
              updateSearch={updateSearch}
              search={search}
              facetsConferences={facetsConferences}
            />
            <ConferenceSizeSelector
              isMobile
              updateSearch={updateSearch}
              search={search}
              facetsConferences={facetsConferences}
            />
            {isMobile && props.calendarLive}

          </div>) : ''}
          {includes(['landingOffer'], context) ? (
            <div>
              <ContractSelector
                isMobile
                matching={matching}
                updateMatching={updateMatching}
                criterias={criterias}
                facets={facets}
              />
              <LocationSelector
                isMobile
                facetKey="MOBILITY"
                facets={facets}
                matching={matching}
                updateSearch={updateSearch}
                updateMatching={updateMatching}
                search={search}
                criterias={criterias}
                context={context}
                facetsExponents={facetsExponents}
              />
            </div>
          ) : ''}
        </div>
        <div className={styles.modalFooter}>
          <button className={styles.modalFooterApply} onClick={() => setIsModalFilter(false)}>
            {t({ id: 'apply.filters' })}
          </button>
          {searchIsEmpty() ? ''
            : (
              <button className={styles.modalFooterReset} onClick={resetSearch}>
                {t({ id: 'reset' })}
              </button>
            )}
        </div>
      </Modal>
    </>
  );
}

SearchEngine.propTypes = {
  participant: object,
  search: object,
  onSearch: func,
  onShowMatchingModal: func,
  event: object,
  context: string,
  facets: object,
  defaultSectors: array,
  facetsExponents: object,
  facetsConferences: object,
  preview: bool,
  hideReset: bool,
  statusFilter: bool,
  participantOffersLastSearch: object,
  participantExponentsLastSearch: object,
  authUser: object,
  countOffers: number,
  totalOffers: number,
  isShowCorners: bool,
  calendarLive: any,
  facetsInformal: object,
};

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser,
  participant: participantSelectors.getCurrentParticipant,
  event: eventSelectors.getCurrentEvent,
  facetsExponents: exponentSelectors.getFacets,
});

const mapDispatchToProps = {
  patchParticipant: participantActions.patchParticipant,
  patchUser: userActions.patchUser,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(toJS(SearchEngine));
