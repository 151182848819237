import React from 'react';

// components
import { func, object, oneOf } from 'prop-types';
import { Form, Radio } from 'antd';
import InputCheckbox from '@/components/Input/InputCheckbox';
import { RadioGroup } from '@/components/Radio';
import { useFormatMessage } from 'react-intl-hooks';

const FormItem = Form.Item;

export const Licence = ({ initialValue, handleLicenseChange, context, criterion, getFieldDecorator, setFieldsValue, authUser }) => {// eslint-disable-line
  const label = authUser.locale === 'fr' ? criterion.modules[context].label : (authUser.locale === 'en' && criterion.modules[context].label_en ? criterion.modules[context].label_en : criterion.modules[context].label);
  const isRequired = criterion.modules[context].choiceMin > 0;
  const radios = criterion._choices.map((choice, index) => authUser.locale === 'fr' ? <Radio value={index === 0}>{choice.label}</Radio> : (authUser.locale === 'en' && choice.label_en ? <Radio value={index === 0}>{choice.label_en}</Radio> : null));
  const t = useFormatMessage();

  const onLicenceChance = (values) => {
    handleLicenseChange(values.target.value);
    setFieldsValue({ license: values.target.value });
  };

  return (
    <div id="matchingLicenceFormItem" style={{ display: 'inline' }}>
      <FormItem>
        {getFieldDecorator('license', {
          initialValue,
          rules: [{ required: isRequired, message: t({ id: 'form.mandatory' }) }],
        })(
          <InputCheckbox
            required={isRequired}
            addOnIcon={criterion.icon}
            label={label}
            placeholder={label}
            validations={['required']}
          >
            <RadioGroup defaultValue={initialValue} onChange={onLicenceChance}>
              {radios}
            </RadioGroup>
          </InputCheckbox>
        )}
      </FormItem>
    </div>
  );
};

Licence.propTypes = {
  getFieldDecorator: func,
  setFieldsValue: func,
  context: oneOf(['offer', 'onboarding']),
  criterion: object,
  authUser: object,
};
