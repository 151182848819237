import React, { useState, useRef, useEffect } from 'react';
import classnames from 'classnames';
import { isEmpty } from 'lodash';
import { useFormatMessage } from 'react-intl-hooks';
import { Button } from '@seekube-tech/ui';
import { Button as ButtonUiKit } from '@seekube-tech/ui-kit';
import { Modal, Row, Col } from 'antd';
import Dropzone from 'react-dropzone';
import AvatarEditor from 'react-avatar-editor';
import b64ToBlob from 'b64-to-blob';
import { bool, func, string } from 'prop-types';

import Icon from '@/components/Icon';

import styles from './styles.less';

const AUTORIZED_TYPES = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/gif',
];

function CoverEditor({ value, onChange, hasError, id, loading }) {
  /* Vars */

  const [imageUrl, setImageUrl] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDisplayCropper, setIsDisplayCropper] = useState(false);
  const [error, setError] = useState('');
  const editor = useRef(null);
  const t = useFormatMessage();

  /* Effects */

  /**
   * When value is changed, update imageUrl
   */
  useEffect(() => {
    setImageUrl(value);
  }, [value]);

  /* Functions */

  /**
   * Display or hide modal
   * @param {Boolean} isVisible 
   */
  const handleToogleDisplayModal = (isVisible) => () => {
    setIsModalVisible(isVisible);

    if (isVisible === false) {
      setIsDisplayCropper(false);
    }
  }

  /**
   * Handle Change when user choose new picture
   *
   * @param {Array} files
   */
  const handleOnChangeFile = (files) => {
    if (files?.[0]) {
      const file = files[0];

      if (AUTORIZED_TYPES.includes(file.type)) {
        setError('');
        setIsDisplayCropper(true);
        setImageUrl(file.preview);
      } else {
        setError(t({ id: 'error.file.format' }));
        setIsDisplayCropper(false);
      }

      return file;
    }

    return false;
  };

  /**
   * When user click to save button
   */
  const handleSave = () => {
    if (editor.current?.getImage()) {
      const dataUrl = editor.current.getImage().toDataURL();
      const dataUrlSplited = dataUrl.split(',');
      const file = b64ToBlob(dataUrlSplited[1], dataUrlSplited[0].replace('data:', '').replace(';base64', ''));

      setIsDisplayCropper(false);
      setError('');
      setImageUrl(dataUrl);

      onChange([file], () => {
        handleToogleDisplayModal(false)();
      });
    }
  };

  /* Render */

  return (
    <>
      <div
        className={classnames(styles.coverWrapper, {
          [styles.withImage]: isEmpty(imageUrl) === false,
          [styles.error]: hasError,
        })}
        style={{ backgroundImage: `url(${imageUrl})`, backgroundSize: 'cover' }}
        id={id}
      >
        {isEmpty(imageUrl) && (
          <div className={styles.help}>
            <div>
              <Icon name="upload" />
            </div>

            {t({ id: 'coverEditor.help' })}
          </div>
        )}

        <div className={styles.overlayButton}>
          <ButtonUiKit
            variant="tonal"
            onClick={handleToogleDisplayModal(true)}
          >
            {isEmpty(imageUrl) ? t({ id: 'coverEditor.add' }) : t({ id: 'coverEditor.update' })}
          </ButtonUiKit>
        </div>
      </div>

      {isModalVisible && (
        <Modal
          width="80%"
          visible
          footer={false}
          closable={false}
        >
          <a
            role="button"
            tabIndex={0}
            className="modal-close"
            onClick={handleToogleDisplayModal(false)}
          >
            <Icon name="close" className="modal-close-icon" />
          </a>

          <div className={classnames(styles.coverWrapper, { [styles.withImage]: isEmpty(imageUrl) === false })}>
            <Dropzone
              accept={AUTORIZED_TYPES.join(', ')}
              onDrop={handleOnChangeFile}
              className={styles.drag}
              activeClassName={styles.activeDropzone}
              multiple={false}
              style={{ backgroundImage: `url(${imageUrl})`, backgroundSize: 'cover', display: isDisplayCropper ? 'none' : 'flex' }}
            >
              <div className={styles.activeDropzoneBorder} />

              <div
                role="button"
                tabIndex={0}
                className={styles.overlayButton}
                onClick={handleToogleDisplayModal(true)}
              >
                <ButtonUiKit variant="outline">
                  {isEmpty(imageUrl) ? t({ id: 'coverEditor.add' }) : t({ id: 'coverEditor.update' })}
                </ButtonUiKit>
              </div>

              <div className={styles.help}>
                <div>
                  <Icon name="upload" />
                </div>

                  {t({ id: 'coverEditor.help' })}
              </div>
            </Dropzone>

            {isDisplayCropper && (
              <>
                <div className={styles.overlayButton}>
                  {loading && (
                    <Button>{t({ id: 'save.loading' })}</Button>
                  )}
                </div>

                <div className={styles.cropper}>
                  <AvatarEditor
                    ref={editor}
                    image={imageUrl}
                    width={950}
                    height={260}
                    border={0}
                    color={[255, 255, 255, 0.6]} // RGBA
                    rotate={0}
                  />

                  <span className={styles.btnPosition}>
                    <span role="img" aria-label="drag">↕️</span> {t({ id: 'coverEditor.crop.help' })}
                  </span>
                </div>
              </>
              )}
          </div>

          <Row type="flex" gutter={24} style={{ marginTop: '60px' }}>
            <Col>
              <Button
                disabled={isDisplayCropper === false}
                loading={loading}
                onClick={handleSave}
                color="primary"
                variant="contained"
              >
                {t({ id: 'save' })}
              </Button>
            </Col>

            <Col>
              <Button
                color="secondary"
                variant="contained"
                onClick={handleToogleDisplayModal(false)}
              >
                {t({ id: 'cancel' })}
              </Button>
            </Col>
          </Row>
        </Modal>
      )}
    </>
  );
}

CoverEditor.propTypes = {
  value: string,
  onChange: func.isRequired,
  hasError: string,
  id: string,
  loading: bool,
}

export default CoverEditor;
