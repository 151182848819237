import { useQuery } from 'react-query';
import request from '@/utils/request';

export function useGetPosts({ eventId, search, page, ...props }) {
  const offset = (page - 1) * 10;
  const requestUrl = `${process.env.FRONT_API_URL}/posts/event/${eventId}?offset=${offset}`;

  const queryFn = () => request(requestUrl, { method: 'GET' });

  const query = useQuery({
    queryKey: ['owner', 'dashboard', 'posts', eventId, page],
    queryFn,
    refetchOnWindowFocus: false,
    cacheTime: 3000,
    ...props,

  });
  return query;
}
