import React from 'react';
import ReactDOM from 'react-dom';
import { any, string } from 'prop-types';
import { canUseDom } from '@/utils';
import { isNil } from 'lodash';

/**
 * NavScenePortal
 */
export class NavScenePortal extends React.PureComponent {
  static propTypes = {
    children: any,
    portalId: string,
  };

  /**
   * State
   */
  state = {
    navSceneEl: null,
  }

  /**
   * ComponentDidMount
   */
  componentDidMount() {
    this.getNavSceneElement();
  }

  /**
   * ComponentWillUnmount
   */
  componentWillUnmount() {
    const { state: { navSceneEl } } = this;
    if (!isNil(navSceneEl)) {
      navSceneEl.style.display = 'none';
    }
  }

  /**
   * GetNavSceneElement
   *
   * @description
   * Get navScene element and save it in the state
   */
  getNavSceneElement = () => {
    const navSceneEl = document.getElementById(this.props.portalId || 'navScene');

    return this.setState({ navSceneEl });
  }

  /**
   * Render
   */
  render() {
    const { props: { children }, state: { navSceneEl } } = this;

    if (!canUseDom || !navSceneEl) return null;

    navSceneEl.style.display = 'block';

    return ReactDOM.createPortal(children, navSceneEl);
  }
}

export default NavScenePortal;
