import React from 'react';
import { object, func, string, bool } from 'prop-types';

import { useFormatMessage } from 'react-intl-hooks';

import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { ButtonRound, IconCheck } from '@seekube-tech/ui-kit';
import { Tooltip } from '@/components/Tooltip';
import { trackRecruiterClickedViewKeyDates } from '../../../../../../../../../../utils/analytics';


const AcceptButton = ({ authUser, context, event, isParticipantActionBtnDisabled, match, handleOnClick }) => {
  const t = useFormatMessage();

  const isBtnDisabled = isParticipantActionBtnDisabled === true;
  const titleContextRefuse =  context === 'refused' ? 'recruiter.participantsList.appointment.propose.new': 'confirm';
  const titleContextUnconfirmed = context === 'unconfirmed' ? 'recruiter.participantsList.appointment.confirm' : titleContextRefuse;
  const title1 = !isBtnDisabled ? t({ id: titleContextUnconfirmed }) :
    <>
      <FormattedHTMLMessage
        id="recruiter.participantsList.appointment.propose.disabled"
      />
      <a
        href={`${document.location.origin}/${match.params.eventSlug}/recruiter/keydates`}
        target="_blank"
        onClick={() => trackRecruiterClickedViewKeyDates({ authUser })}
      >
        <FormattedMessage id="see.keyDates" />
      </a>
    </>

  return (
    <Tooltip
      overlayStyle={{ minWidth: !isBtnDisabled ? 'auto' : '365px', whiteSpace: isBtnDisabled ? 'normal' : 'nowrap',
      }}
      color="dark" placement="bottom"
      align="left"
      getPopupContainer={(triggerNode) => triggerNode.parentNode}
      title={title1}
    >
    <span style={{ display: 'inline-block' }}>
      <ButtonRound
        disabled={isBtnDisabled}
        color="success"
        onClick={handleOnClick}
        variant="fill"
      >
        <IconCheck />
      </ButtonRound>
          </span>
    </Tooltip>
  )
}

AcceptButton.propTypes = {
  context: string,
  event: object,
  authUser: object,
  isParticipantActionBtnDisabled: bool,
  match: object,
  handleOnClick: func,
}

export { AcceptButton }
