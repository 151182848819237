import React from 'react';
import { string, object } from 'prop-types';
import classnames from 'classnames';
import Avatar from '@/components/Avatar';
import styles from './styles.less';


const TagWithAvatar = ({ src, name, user, className, withBorder = true, style }) => (
  <div className={classnames(styles.tagContainer, className, withBorder ? styles.tagContainer : null)} style={style}>
    <Avatar size={22} src={src} user={user} />
    <span
      className={
        classnames(
          styles.contentText,
          (typeof src !== 'undefined' && src !== '') ? '' : styles.verticalAlignTextBottom
        )
      }
    >
      {name}
    </span>
  </div>
);

TagWithAvatar.propTypes = {
  src: string,
  user: object,
  name: string,
  className: string,
  style: string,
};

TagWithAvatar.defaultProps = {
  src: '',
  className: '',
};

export default TagWithAvatar;
