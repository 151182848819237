import { makeStyles } from '@seekube-tech/ui';

export default makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 5.5),
  },
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2.5),
  },
  dates: {
    display: 'flex',
  },
  stat: {
    lineHeight: '18px',
  },
  bloc: {
    display: 'flex',
    borderRadius: '5px',
    height: '72px',
    flexGrow: 1,
    background: theme.palette.common.white,
    padding: theme.spacing(1.5, 2, 2, 2),

  },
  blocLong: {
    paddingTop: theme.spacing(0.5),
  },
  blocUserConnected: {
    height: '105px',
    flexDirection: 'column'
  },
  countUserConnected: {
    marginTop: 'auto',
  },
  blocShort: {
    flexDirection: 'column',
    height: '105px',
  },
  blocName: {
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginRight: theme.spacing(1.5)
    },
    '& p': {
      fontWeight: 600
    },
  },
  blocEnd: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  blocEndShort: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  iconCamera: {
    color: theme.palette.primary['500']
  },
  iconHouse: {
    color: theme.palette.common.yellow['500']
  },
  iconCandidate: {
    width: '16px',
    height: '16px',
    fill: 'transparent',
    color: theme.palette.common.pink['500']
  },
  iconBuilding: {
    color: theme.palette.common.orchid['500']
  },
}));
