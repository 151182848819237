import { useQuery } from 'react-query';

import request from '@/utils/request';

export function useGetExponentOffers({ eventSlug, organizationId, status, selectData = null }) {

  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventSlug}/offers/organization/${organizationId}?limit=300&status=${status}&selectData=${selectData}`;

  const queryFn = () => request(requestUrl, { method: 'GET' });

  const query = useQuery({
    queryKey: ['exponentOffers', organizationId],
    enabled: !!organizationId,
    queryFn,
    initialData: { docs: [] },
    refetchOnWindowFocus: false,
  });

  return query;
}