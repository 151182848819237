import React, { useCallback, useEffect, useRef, useState } from 'react';
import { array, string } from 'prop-types';
import { Row, Col } from 'antd';
import { isEmpty } from 'lodash';

import { Tooltip } from '@/components/Tooltip';

import styles from './styles.less';
import CornerTag from '../CornerTag';

function CornersTag({ corners, className }) {
  /* Vars */

  const parentRef = useRef();
  const [tagOverflow, setTagOverflow] = useState([]);

  /* Callbacks */

  /**
   * Hide tag overflow parent and create tag counter
   */
  const calculateOverflowTag = useCallback(() => {
    if (parentRef && parentRef.current && parentRef.current.offsetWidth) {
      const { offsetWidth, offsetLeft, childNodes } = parentRef.current;
      const newTagOverflow = [];

      childNodes[0].childNodes.forEach((child) => {
        const childOffsetLeft = (child.offsetLeft + child.offsetWidth) - offsetLeft;

        if (childOffsetLeft > (offsetWidth - 50) && child.id !== 'overflow') {
          newTagOverflow.push(child.innerText);
          // eslint-disable-next-line no-param-reassign
          child.style.display = 'none';
        }
      });

      setTagOverflow(newTagOverflow);
    }
  }, []);

  /* Effects */

  // FIXME : Add resize method
  useEffect(() => {
    calculateOverflowTag();
  }, []);

  /* Render */

  return !isEmpty(corners) > 0 && (
    <div ref={parentRef} className={className}>
      <Row
        type="flex"
        align="middle"
        gutter={8}
        className={styles.root}
      >
        {corners.map((corner) => (
          <Col key={corner._id}>
            <CornerTag corner={corner} />
          </Col>
        ))}

        {tagOverflow.length > 0 && (
          <Col id="overflow">
            <Tooltip title={tagOverflow.join(', ')} placement="bottom" color="dark">
              <div className={styles.tag}>
                {`+${tagOverflow.length}`}
              </div>
            </Tooltip>
          </Col>
        )}
      </Row>
    </div>
  );
}

CornersTag.propTypes = {
  corners: array.isRequired,
  className: string,
};

CornersTag.defaultProps = {
  className: null,
};

export default CornersTag;
