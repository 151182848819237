import React, { useEffect } from 'react';
import { object } from 'prop-types';
import { add, reduce } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { eventSelectors } from '@/store/event';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { toJS } from '@/utils';
import { reduceWithOperator } from '@/utils/math';


import { Stats } from './components/Stats';
import { IframesRedash } from './components/IframesRedash';

import { refresh } from '../../requests';
import { authSelectors } from '@/store/auth';

const ExponentsScene = ({
  event,
  stats: { exponentsActivitiesStats, exponentsStats, exponentsUsersStats, appointmentsStats },
}) => {
  useEffect(() => {
    refresh({ eventId: event.slug });
  }, []);

  const pendingApplications = reduceWithOperator(exponentsActivitiesStats, 'pendingApplications', add);
  const pendingInformal1to1Appointments = reduceWithOperator(exponentsActivitiesStats, 'pendingInformal1to1Appointments', add);
  const refusedApplications = reduceWithOperator(exponentsActivitiesStats, 'refusedApplications', add);
  const refusedInformal1to1Appointments = reduceWithOperator(exponentsActivitiesStats, 'refusedInformal1to1Appointments', add);
  const acceptedApplications = reduceWithOperator(exponentsActivitiesStats, 'acceptedApplications', add);
  const acceptedInformal1to1Appointments = reduceWithOperator(exponentsActivitiesStats, 'acceptedInformal1to1Appointments', add);
  const applications = reduceWithOperator(exponentsActivitiesStats, 'applications', add);
  const informal1to1 = reduceWithOperator(exponentsActivitiesStats, 'informal1to1', add);
  const totalSlotsCreated = reduceWithOperator(exponentsUsersStats, 'countSlots', add);
  const countInformal1to1Slots = reduceWithOperator(exponentsUsersStats, 'countInformal1to1Slots', add);
  const totalInterviewCanceledByRecruiters = reduceWithOperator(exponentsActivitiesStats, 'canceledAppointments', add);
  const totalInformal1to1CanceledByRecruiters = reduceWithOperator(exponentsActivitiesStats, 'canceledInformal1to1Appointments', add);
  const exponentsThatCanceledInterviewOnce = reduce(exponentsActivitiesStats, (previousValue, currentValue) => currentValue.canceledAppointments > 0 ? previousValue + 1 : previousValue, 0);
  const exponentsThatCanceledInformal1to1Once = reduce(exponentsActivitiesStats, (previousValue, currentValue) => currentValue.canceledInformal1to1Appointments > 0 ? previousValue + 1 : previousValue, 0);

  const eventStats = {
    totalVisitParticipants: reduceWithOperator(exponentsActivitiesStats, 'countVisits', add),
    visitedAtLeastOneParticipant: reduce(exponentsActivitiesStats, (previousValue, currentValue) => currentValue.countVisits > 0 ? previousValue + 1 : previousValue, 0),
    averageTotalVisitAndOneParticipant: 0,
    sourcingAppointments: reduceWithOperator(exponentsActivitiesStats, 'sourcingAppointments', add),
    exponentsThatSourcedOnce: reduce(exponentsActivitiesStats, (previousValue, currentValue) => currentValue.sourcingAppointments > 0 ? previousValue + 1 : previousValue, 0),
    averageExponentsThatSourcedOnce: 0,
    totalCvUnique: reduceWithOperator(exponentsActivitiesStats, 'countDownloads', add),
    exponentWhoDownloadCv: reduce(exponentsActivitiesStats, (previousValue, currentValue) => currentValue.countDownloads > 0 ? previousValue + 1 : previousValue, 0),
    averageCvDownloadedByExponent: 0,
    pendingAppointments: add(pendingApplications, pendingInformal1to1Appointments),
    refusedAppointments: add(refusedApplications, refusedInformal1to1Appointments),
    acceptedAppointments: add(acceptedApplications, acceptedInformal1to1Appointments),
    allAppointments: add(applications, informal1to1),
    totalSlotsCreated: add(totalSlotsCreated, countInformal1to1Slots),
    freeSlots: reduceWithOperator(exponentsUsersStats, 'countSlotsFromNow', add) + reduceWithOperator(exponentsUsersStats, 'countInformal1to1SlotsFromNow', add),
    activitiesTotalCvUnique: 0,
    activitiesTotalProfileViewed: 0,
    averageInterview: 0,
    totalCanceledByRecruiters: add(totalInterviewCanceledByRecruiters, totalInformal1to1CanceledByRecruiters),
    exponentsThatCanceledInterviewOnce: add(exponentsThatCanceledInterviewOnce, exponentsThatCanceledInformal1to1Once),
    averageInterviewCanceled: 0,
    test: 0,
  };

  return (
    <div>
      <Stats stats={{ ...exponentsStats, ...appointmentsStats, ...eventStats }} event={event} />
      <IframesRedash slug={event.slug} />
    </div>
  );
};

ExponentsScene.propTypes = {
  event: object,
  stats: object,
};

const mapStateToProps = createStructuredSelector({
  event: eventSelectors.getCurrentEvent,
});

const withConnect = connect(mapStateToProps, null);

export default compose(
  withConnect,
  injectIntl,
  toJS,
)(ExponentsScene);
