import { useQuery } from 'react-query';
import request from '@/utils/request';
import { objectToParams } from '@/utils/url';

export function useGetExponentUser({ eventId, userId, search, onSuccess = () => {} }) {
  const searchParams = objectToParams(search);

  const requestUrl = `${process.env.FRONT_API_URL}/organizations/agenda/calendar/exponent-user?${searchParams}&eventId=${eventId}&userId=${userId}`

  const queryFn = () => request(requestUrl, { method: 'GET' });

  const query = useQuery({
    queryKey: ['agenda', 'calendar', 'exponentUser', eventId, userId],
    queryFn,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess
  });

  return query;
}
