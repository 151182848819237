import React from 'react';
import { shape, func, string } from 'prop-types';
import { Form } from 'antd/lib/index';
import { Button, H5, Body2, H3 } from '@seekube-tech/ui-kit';
import colors from '@seekube-tech/ui-kit/dist/colors';
import classnames from 'classnames';
import { useFormatMessage } from 'react-intl-hooks';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import moment from 'moment';
import { Cgu, Password, PasswordConfirmation } from '@/forms/components';
import { authChangePassword } from '@/store/auth/actions';
import styles from './styles.less';

const PasswordConfig = (props) => {
  const { form, handleGoToNextStep } = props;

  const t = useFormatMessage();
  const dispatch = useDispatch();
  const { token } = useParams();

  const handleOnSubmit = (e) => {
    e.preventDefault();

    return form.validateFields((err, values) => {
      if (!err) {
        if ((values.cgu)) {
          dispatch(authChangePassword({
            password: values.password,
            resetToken: token,
            termsAcceptedAt: moment()?.toISOString(),
            callback: () => {
              handleGoToNextStep(null, 2)
            }
          }));
        }
      }
    });
  };

  return (
    <div className={classnames(styles.passwordModal, 'wrapperModal')}>
      <div>
        <H3 color={colors.neutral['500']}>{t({ id: 'recruiter.preparation.welcome.invitation2' })}</H3>

        <Body2 color={colors.neutral['400']}>
          {t({ id: 'recruiter.preparation.welcome.invitation2.subtitle'})}
        </Body2>
        <H5 color={colors.neutral['500']} className="my-20">
          {t({ id: 'recruiter.preparation.welcome.invitation2.description'})}
        </H5>

        <Form onSubmit={handleOnSubmit}>
          <Password form={form} withPasswordPolicy />
          <PasswordConfirmation form={form} />

          {props.invitationFrom !== 'smartapply' && <Cgu form={form} /> }

          <Button
            type="submit"
            className="w-full"
          >
            {t({ id: 'next' })}
          </Button>
        </Form>
      </div>
    </div>
  );
}

PasswordConfig.propTypes = {
  form: shape({
    getFieldDecorator: func,
    getFieldsError: func,
    getFieldError: func,
    isFieldTouched: func,
  }),
  invitationFrom: string,
  handleGoToNextStep: func,
};

export default Form.create()(PasswordConfig);
