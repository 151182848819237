import { node, oneOf, string } from 'prop-types';
import * as React from 'react';
import {
  Users as IconUsers,
  Unlock as IconUnlock,
  Lock as IconLock,
} from 'react-feather';
import styles from '../styles.less';

function FormSection({ icon, children, title }) {
  return (
    <><div className={styles.keyDatesTitle}>
      {icon === 'users' && <IconUsers className={styles.icon} size="16" />}
      {icon === 'unlock' && <IconUnlock className={styles.icon} size="16" />}
      {icon === 'lock' && <IconLock className={styles.icon} size="16" />}
      <h3>{title}</h3>
    </div>
      <div className={styles.keyDatesContent}>
        {children}
      </div>
    </>
  )
}

FormSection.propTypes = {
  children: node,
  icon: oneOf(['users', 'unlock']),
  title: string
}

export default FormSection;
