import React from 'react';
import { node, string } from 'prop-types';
import { Popover as PopoverAntd } from 'antd';

class Popover extends React.PureComponent {
  static propTypes = {
    popoverContent: node,
    children: node,
    placement: string,
  }

  state = {
    visible: false,
  }

  hide = () => {
    this.setState({
      visible: false,
    });
  }

  handleVisibleChange = (visible) => {
    this.setState({ visible });
  }

  render() {
    const { popoverContent, placement, children } = this.props;

    return (
      <PopoverAntd
        placement={placement}
        content={popoverContent}
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
        {...this.props}
      >
        { children }
      </PopoverAntd>
    );
  }
}


export default Popover;
