import { EVENT_FORMAT } from "@/utils/constants";
import { isEmpty } from "lodash";
import { PHASES, PHASE_STATE } from "../../utils/const";
import {
  getEventPhases,
  isEventClosed,
  isInteractionClosed,
  isJobfairPast,
} from "../../utils/event";


import {
  isParticipantFormatPhysical,
  isParticipantFormatVirtual,
} from "../../utils/user";

function isDisabled({
  event,
  participant = {
    keyMomentFormats: [EVENT_FORMAT.PHYSICAL, EVENT_FORMAT.VIRTUAL]
  },
  exponent,
}) {
  const eventPhases = getEventPhases(event);

  if (isEventClosed(eventPhases)) {
    return true;
  }
  if (isParticipantFormatPhysical(participant) && isParticipantFormatVirtual(participant)) {
    if (exponent.keyMomentFormats.length > 1) {

      if (isInteractionClosed(eventPhases) && eventPhases.interactionsClosing.length === 2) {
        return true;
      }

      if (isEmpty(eventPhases.interactionsClosing) && isJobfairPast(eventPhases)) {
        return true;
      }

      if ((isInteractionClosed(eventPhases, EVENT_FORMAT.PHYSICAL)) && (!eventPhases.interactionsClosing.find(e => e.format === EVENT_FORMAT.VIRTUAL) && isJobfairPast(eventPhases, EVENT_FORMAT.VIRTUAL))) {
        return true;
      }

      if (isInteractionClosed(eventPhases, EVENT_FORMAT.VIRTUAL) && (!eventPhases.interactionsClosing.find(e => e.format === EVENT_FORMAT.PHYSICAL) && isJobfairPast(eventPhases, EVENT_FORMAT.PHYSICAL))) {
        return true;
      }
      return false;
    }
    if (exponent.keyMomentFormats[0] === EVENT_FORMAT.PHYSICAL) {
      if (isInteractionClosed(eventPhases, EVENT_FORMAT.PHYSICAL)) {
        return true;
      }

      if (!eventPhases.interactionsClosing.find(e => e.format === EVENT_FORMAT.PHYSICAL) && isJobfairPast(eventPhases, EVENT_FORMAT.PHYSICAL)) {
        return true;
      }
      return false;
    }
    if (exponent.keyMomentFormats[0] === EVENT_FORMAT.VIRTUAL) {
      if (isInteractionClosed(eventPhases, EVENT_FORMAT.VIRTUAL)) {
        return true;
      }

      if (!eventPhases.interactionsClosing.find(e => e.format === EVENT_FORMAT.VIRTUAL) && isJobfairPast(eventPhases, EVENT_FORMAT.VIRTUAL)) {
        return true;
      }
      return false;
    }
    return false;
  }
  if (isParticipantFormatPhysical(participant)) {
    if (isInteractionClosed(eventPhases, EVENT_FORMAT.PHYSICAL)) {
      return true;
    }

    if (!eventPhases.interactionsClosing.find(e => e.format === EVENT_FORMAT.PHYSICAL) && isJobfairPast(eventPhases, EVENT_FORMAT.PHYSICAL)) {
      return true;
    }
    return false
  }
  if (isParticipantFormatVirtual(participant)) {
    if (isInteractionClosed(eventPhases, EVENT_FORMAT.VIRTUAL)) {
      return true;
    }

    if (!eventPhases.interactionsClosing.find(e => e.format === EVENT_FORMAT.VIRTUAL) && isJobfairPast(eventPhases, EVENT_FORMAT.VIRTUAL)) {
      return true;
    }

    return false;
  }
  if (isInteractionClosed(eventPhases)) {
    return true;
  }
  return false;
}

// FIXME: add unit test on this
function isRecruiterHasAvailableTimeslots({
  event,
  participant = {
    keyMomentFormats: [EVENT_FORMAT.PHYSICAL, EVENT_FORMAT.VIRTUAL]
  },
  exponent,
  recruiter,
}) {
  const eventPhases = getEventPhases(event);

  if (event.format !== EVENT_FORMAT.HYBRID) {
    return recruiter?.countSlotsAvailable > 0 || recruiter?.countInformalSlotsAvailable > 0;
  }

  if (isParticipantFormatPhysical(participant) && isParticipantFormatVirtual(participant)) {
    if (exponent.keyMomentFormats.length === 2) {
      if (isInteractionClosed(eventPhases, EVENT_FORMAT.VIRTUAL) || (!eventPhases.interactionsClosing.find(e => e.format === EVENT_FORMAT.VIRTUAL) && isJobfairPast(eventPhases, EVENT_FORMAT.VIRTUAL))) {
        return recruiter.physical.countSlotsAvailable > 0 || recruiter.physical.countInformalSlotsAvailable > 0;
      }
      if (isInteractionClosed(eventPhases, EVENT_FORMAT.PHYSICAL) || (!eventPhases.interactionsClosing.find(e => e.format === EVENT_FORMAT.PHYSICAL) && isJobfairPast(eventPhases, EVENT_FORMAT.PHYSICAL))) {
        return recruiter.virtual.countSlotsAvailable > 0 || recruiter.virtual.countInformalSlotsAvailable > 0;
      }
      return recruiter.countSlotsAvailable > 0 || recruiter.countInformalSlotsAvailable > 0;
    }

    if (exponent.keyMomentFormats[0] === EVENT_FORMAT.PHYSICAL) {
      return recruiter.physical.countSlotsAvailable > 0 || recruiter.physical.countInformalSlotsAvailable > 0;
    }
    if (exponent.keyMomentFormats[0] === EVENT_FORMAT.VIRTUAL) {
      return recruiter.virtual.countSlotsAvailable > 0 || recruiter.virtual.countInformalSlotsAvailable > 0;
    }
  }

  if (isParticipantFormatPhysical(participant)) {
    return recruiter.physical.countSlotsAvailable > 0 || recruiter.physical.countInformalSlotsAvailable > 0;
  }

  if (isParticipantFormatVirtual(participant)) {
    return recruiter.virtual.countSlotsAvailable > 0 || recruiter.virtual.countInformalSlotsAvailable > 0;
  }
  return false;
}

/**
 * Do not forget to specify strict function params (instead of destructuring) to avoid issues when calling
 */
export default {
  isAvailable: [
    [
      {
        phase: PHASES.ALL,
        state: PHASE_STATE.ALL
      },
    ],
  ],
  generateActions: ({ authUser }) => {
    const user = authUser?.toJS();

    return {
      isDisabled: ({ event, participant, exponent }) => isDisabled({ event, user, participant, exponent }),
      isRecruiterHasAvailableTimeslots: ({ event, participant, exponent, recruiter }) => isRecruiterHasAvailableTimeslots({ event, user, participant, exponent, recruiter }),
    }
  }
}
