import moment from 'moment';
import { requestFile } from '@/utils/request';
import { objectToParams } from '@/utils/url';

/**
 * Returns dates as UTC values for live talks on an event
 * 
 * @param {*} date 
 * @param {*} eventId 
 * @param {*} conferenceId 
 * @returns 
 */
const getAvailableHours = async (date, eventId, conferenceId) => {
  const date2 = moment(date)._isValid ? moment(date) : moment(date, 'DD MMMM YYYY');
  const params = objectToParams({ eventId, conferenceId, date: date2.format('YYYY-MM-DD') });
  const requestUrl = `${process.env.FRONT_API_URL}/conferences/checkDate?${params}`;

  return requestFile(requestUrl);
};

export { getAvailableHours };
