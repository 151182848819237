import React from 'react';
import { node, string, oneOfType, bool } from 'prop-types';
import omit from 'lodash/omit';
import classnames from 'classnames';

// Styles & Translations
import styles from './index.less';

/**
 * H1
 * @param {object} props
 */
const H1 = (props) => (
  <h1
    className={classnames(styles.h1, props.bold ? styles.bold : null, props.className)}
    {...omit(props, ['className', 'children', 'bold'])}
  >
    {props.children}
  </h1>
);

H1.propTypes = {
  children: oneOfType([string, node]),
  className: string,
  bold: bool,
};

export default H1;
