/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { object, func, array, bool } from 'prop-types';
import classnames from 'classnames';
import { isEmpty, get } from 'lodash';

// components
import { FormattedHTMLMessage, injectIntl } from 'react-intl';
import { Form } from 'antd';
import { Alert } from '@seekube-tech/ui-kit';
import { H3 } from '@/components/Title';
import 'moment-timezone';
import P from '@/components/P';
import { If } from '@/components/If';

import styles from '../../styles.less';
import syncAgendaStyles from './styles.less';
import NylasSync from './components/nylas';
import OutlookSync from './components/outlook';

// https://docs.nylas.com/reference#supported-providers
function SyncCalendar(props) {
  const {
    calendars,
    intl,
    form,
    authUser,
    isCalendarsFetching,
    onSave,
    getCalendars,
    getSyncConnectUrl,
    getSyncDisconnectUrl,
  } = props;

  function handleOnSubmit(value) {
    form.validateFields((err, { syncCalendar }) => {
      if (!err) {
        onSave({
          syncCalendar: { ...authUser.syncCalendar, ...syncCalendar, ...value },
        });
      }
    });
  };

  const hasSyncedWithNylas = authUser && !isEmpty(get(authUser, 'syncCalendar.accessToken')) && get(authUser, 'syncCalendar.syncState') === 'running';

  return (
    <>
      {hasSyncedWithNylas && (
        <Form className={classnames(styles.emailFormWrapper, syncAgendaStyles.syncAgendaContainer)}>
          <H3 bold>
            {intl.formatMessage({ id: 'profile.settings.syncAgenda.title' })} <span role="img" aria-label="">  🗓</span>
          </H3>
          <If condition={window.localStorage.getItem('nylas_status') === 'access_denied'}>
            <Alert className={syncAgendaStyles.alertError} color="error">
              <FormattedHTMLMessage id="profile.settings.syncCalendar.connect.alert.access.denied" />
            </Alert>
          </If>

          <P className={styles.text} style={{ lineHeight: '24px', marginBottom: '5px' }}>
            {intl.formatMessage({ id: 'profile.settings.syncAgenda.subtitle' })}
          </P>

          <NylasSync
            authUser={authUser}
            form={form}
            handleOnSubmit={handleOnSubmit}
            calendars={calendars}
            getSyncConnectUrl={getSyncConnectUrl}
            getSyncDisconnectUrl={getSyncDisconnectUrl}
            isCalendarsFetching={isCalendarsFetching}
            getCalendars={getCalendars}
          />
        </Form>
      )}

      {!hasSyncedWithNylas && (
        <div className={styles.emailFormWrapper}>
          <OutlookSync context="settings" />
        </div>

      )}
    </>
  )
}

SyncCalendar.propTypes = {
  calendars: array,
  intl: object,
  form: object,
  authUser: object,
  isCalendarsFetching: bool,
  onSave: func,
  getCalendars: func,
  getSyncConnectUrl: func,
  getSyncDisconnectUrl: func,
};

export default Form.create()(injectIntl(SyncCalendar));
