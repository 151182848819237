import React, { useRef, useState } from 'react';
import { object } from 'prop-types';
import { isEmpty } from 'lodash';
import { Form, Row } from 'antd';
import { Body1, H3, Button, IconAtSign } from '@seekube-tech/ui-kit';
import colors from '@seekube-tech/ui-kit/dist/colors';
import { useFormatMessage } from 'react-intl-hooks';
import { compose } from 'redux';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import InputMaterial from '@/components/Input/InputMaterial/index';
import { Bloc } from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/components/Bloc';
import { authLogin } from '@/store/auth/actions';
import Wrapper from '@/components/Wrapper';
import { queryStringToObject } from '@/utils/url';
import { withLayout } from '@/containers/Layout';
import { Footer } from '@/scenes/Auth/scenes/Login/Footer';
import { AlertNoAccount } from '@/scenes/Auth/scenes/Login/AlertNoAccount';
import { SubscriptionRedirectionBloc } from '@/scenes/Auth/scenes/Login/SubscriptionRedirectionBloc';
import { Password } from '@/forms/components';
import { API_CUSTOM_ERRORS } from '@/utils/constants';
import styles2 from './styles2.less';
import { Captcha } from '@/scenes/Auth/scenes/Login/components/Captcha';
import { RememberMe } from '@/scenes/Auth/scenes/Login/components/RememberMe';
import { story } from '@/utils/manageVisibilityStory';

const FormItem = Form.Item;

const Login = ({
  form,
  match
}) => {
  const t = useFormatMessage();
  const dispatch = useDispatch();
  const history = useHistory();
  const { role } = match.params;
  const [errorMessage, setErrorMessage] = useState();
  const captchaWidget = useRef();
  const [catchpa, setCaptcha] = useState({
    success: false,
    solution: null
  });
  const [rememberMe, setRememberMe] = useState(false);
  const isCaptchaVisible = story.captcha.isVisible;

  const handleOnSubmitRegistration = (e) => {
    e.preventDefault();

    return form.validateFields((err, values) => {
      if (!err) {
        
        dispatch(authLogin({
          ...values,
          solution: catchpa.solution,
          rememberMe,
          callback:(response = {}) => {
            if (response.message === 'blocked_account') {
              setErrorMessage(t({ id: `api.error.${API_CUSTOM_ERRORS.blockedAccount}` }));
            }

            if ([
              API_CUSTOM_ERRORS.wrongPassword, 
              API_CUSTOM_ERRORS.userNotFound, 
              API_CUSTOM_ERRORS.invalidCaptcha
            ].includes(response.message)) {
              setErrorMessage(t({ id: 'signin.error' }));
            }

            if (isEmpty(response?.error)) {
              return history.replace(queryStringToObject(window.location.search)?.redirect)
            }
            captchaWidget.current?.reset?.();
            captchaWidget.current?.start?.();
            setCaptcha({ ...catchpa, success: false, solution: null });
          },
          notificationParams: {
            error: {
              message: t({ id: "error.generic" })
            }
          }
        }))
      }
    });
  };

  return (
    <Wrapper size="large">
      <div className={styles2.container}>
        <Bloc maxWidth={500}>
          <div className={styles2.blocContent}>
            <Form className={styles2.signupForm} onSubmit={handleOnSubmitRegistration}>
                <H3 className={styles2.title}>{t({ id: 'login' })}</H3>
  
                <Row>
                  <FormItem className={styles2.item}>
                    {form.getFieldDecorator('username', {
                      initialValue: window.localStorage.getItem('signup-email'),
                      rules: [{ required: true, type: 'email', message: t({ id: 'form.mandatory' }) }],
                      validateTrigger: 'onSubmit',
                    })(
                      <InputMaterial addOnIcon={<IconAtSign size={24} />} label={t({ id: 'form.email' })} placeholder={t({ id: 'form.email' })} type="mail" validations={['required']} />
                    )}
                  </FormItem>
                </Row>
                <Row>
                  <Password form={form} />
                </Row>
  
                {errorMessage &&
                  <Body1 className="mb-20" color={colors.error['500']}>
                    {errorMessage}
                  </Body1>
                }
                
                <Row>
                  <FormItem className={styles2.item}>
                    {form.getFieldDecorator('rememberMe', {
                      initialValue: false,
                    })(
                      <RememberMe
                        onChange={setRememberMe}
                        checked={rememberMe}
                      />
                    )}
                  </FormItem>
                </Row>

                { 
                  isCaptchaVisible && <Row>
                    <Captcha
                      onSuccess={(solution) => {
                        setCaptcha({ ...catchpa, success: true, solution })
                      }}
                      onError={() => {
                        setCaptcha({ ...catchpa, success: false })
                      }}
                      widget={captchaWidget}
                    />
                  </Row>
                }
  
                <div className="mb-20 login-actions">
                  <Button
                    type="submit"
                    className="w-full"
                    id="submit-login"
                    disabled={isCaptchaVisible && !catchpa.success}
                  >
                    {t({ id: 'login' })}
                  </Button>
                </div>
            </Form>
            <Footer role={role} />

          </div>
        </Bloc>

        <SubscriptionRedirectionBloc role={role} />

      </div>
      <AlertNoAccount role={role} />
    </Wrapper>
  )
}

Login.propTypes = {
  form: object,
  match: object,
}

const withForm = Form.create();

export default compose(
  withForm,
  withLayout({ size: 'full', navType: 'candidate' }),
)(Login);
