import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { debounce, isEmpty } from 'lodash';
import { useFormatMessage } from 'react-intl-hooks';

import { Form, Rate, Select as SelectAntd } from 'antd';
import { Typography } from '@seekube-tech/ui';
import { Button, Tag, Alert } from '@seekube-tech/ui-kit';
import ModalBig from '@/components/ModalBig';
import request from "@/utils/request";
import { setAtsDefaultReviewer } from '@/store/auth/actions';
import { getAtsDefaultReviewer } from '@/store/auth/selectors';

import Separator from '@/components/Separator';
import { Select } from '@/components/Form';
import Textarea from '@/components/Textarea';
import Input from '@/components/Input/InputMaterial';
import {
  UserCard
} from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Appointment/components/AppointmentDetails';
import { Matchings, SearchRow } from '@/components/ParticipantCards/components/components';
import { Matching } from '@/components/ParticipantCards/components/components/Matchings/components';

const { Option } = SelectAntd;
const FormItem = Form.Item;

const MAX_CHARACTER = 35;
const sliceLongText = (text) => text.length > MAX_CHARACTER ? `${text.slice(0, MAX_CHARACTER)}...` : text;

const formatOfferTitle = (offer) => {
  if (offer.contract_type) {
    if (offer.reference) {
      return `[${offer.contract_type}] ${offer.reference} - ${offer.title || offer.name}`;
    }

    return `[${offer.contract_type}] ${offer.title || offer.name}`;
  }

  return offer.name || offer.title;
}

const ModalAtsImport = ({ syncAts, participant, authUser, visible, onClose, source, onImportFinished, context, participantOffers }) => {
  const defaultReviewer = useSelector(getAtsDefaultReviewer);
  const dispatch = useDispatch();

  const t = useFormatMessage();
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const [reviewer, setReviewer] = useState(!isEmpty(defaultReviewer) ? defaultReviewer : authUser.fullName);
  const [offerSelected, setOfferSelected] = useState(null);
  const [offers, setOffers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [importIsLoading, setImportIsLoading] = useState(false);

  const getOffers = (q) => {
    request(`${process.env.FRONT_API_URL}/ats/offers?q=${q}`, {
      method: 'GET',
    }).then((res) => {
      setIsLoading(false);
      setOffers(res.docs);
    }).catch(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getOffers('');
  }, []);

  const handleSearch = (q) => {
    setIsLoading(true);
    getOffers(q);
  };

  const handleSelectOffer = (id) => {
    const offer = offers.find((o) => o.id === id);

    if (offer) {
      setOfferSelected({
        offerId: offer.id,
        offerRef: offer.refNumber || offer.reference,
        reference: offer.reference,
      });
    }
  };

  const handleImport = () => {
    setImportIsLoading(true);

    if (!offerSelected || !participant) {
      return false;
    }

    const requestBody = {
      participantId: participant._id,
      offerId: syncAts?.atsConnectIdField && offerSelected[syncAts.atsConnectIdField] ? offerSelected[syncAts.atsConnectIdField] : offerSelected.offerId,
      offerRef: offerSelected.reference || offerSelected.offerRef,
      rating,
      syncAts,
      comment,
      reviewer,
      sourceId: source,
      context,
      origin: window.location.href,
    };

    dispatch(setAtsDefaultReviewer({ reviewer }));
    let requestUrl = `${process.env.FRONT_API_URL}/ats/candidate`;

    if (syncAts.provider !== 'smartrecruiters') {
      requestUrl = `${process.env.FRONT_API_URL}/ats/connect/application`;
    }

    request(requestUrl, {
      method: 'POST',
      body: JSON.stringify(requestBody),
    }).catch(() => {
      setImportIsLoading(false);
      setIsError(true);
    });

    setImportIsLoading(false);
    setIsError(false);
    handleOnClose();

    if (typeof onImportFinished === 'function') {
      onImportFinished()
    }
  };

  const sideContent = participant?._user ? (
    <div className="appointment-details" style={{ maxWidth: '350px', margin: 'auto' }}>
      <UserCard
        user={participant?._user}
      />

      {!isEmpty(participantOffers) ? (
          <Matching
            name={t({ id: 'candidate.jobdating.offers' })}
            icon="tag"
            str={(<>{participantOffers.map((o) => <Tag variant="outline" color="neutral">{sliceLongText(o.title)}</Tag>)}</>)}
          />
      ) : null}

      <Matching
        name={t({ id: 'ats.importModal.candidate.mail' })}
        icon="mail"
        str={participant?._user.username}
      />

      <Matching
        name={t({ id: 'ats.importModal.candidate.phone' })}
        icon="phone"
        str={participant?._user.phone}
      />

      <Separator height={32} />
      <Typography variant="h4">{t({ id: 'ats.importModal.candidate.criteriaTitle' })}</Typography>
      <Separator height={24} />
      <SearchRow participant={participant} authUser={authUser} />
      <Matchings
        participant={participant}
        authUser={authUser}
      />
    </div>
  ) : null;

  const handleOnClose = () => {
    setRating(0);
    setComment('');
    setOfferSelected(null);
    onClose();
  }

  return (
    <ModalBig
      isOpen={visible}
      onClose={handleOnClose}
      syncAts={syncAts}
      sideContent={sideContent}
      footer={<div style={{ textAlign: 'center' }}>
        <Button loading={importIsLoading} disabled={!offerSelected} onClick={handleImport}>
          {t({ id: 'ats.importModal.cta' })}
        </Button>
      </div>}
    >
      <div className="">
        <Typography variant="h2">{`${t({ id: 'ats.importModal.title'})} ${t({ id: `ats.provider.${syncAts?.provider}`})}`}</Typography>

        <Separator height={32} />

        <FormItem
          required
          label={t({ id: 'ats.importModal.searchLabel'})}
        >
          <Select
            showSearch
            addOnIcon="search"
            mode="default"
            style={{ width: '100%' }}
            placeholder={t({ id: 'ats.importModal.search' })}
            onChange={handleSelectOffer}
            onSearch={debounce((q) => handleSearch(q), 400)}
            loading={isLoading}
            className="searchSelect"
            filterOption={false}
            value={offerSelected?.offerId}
            notFoundContent={isLoading ? t({ id: 'loading' }) : null}
          >
            {offers?.map((o) => (<Option key={o.id}>{formatOfferTitle(o)}</Option>))}
          </Select>
        </FormItem>

        {syncAts?.provider === 'smartrecruiters' && (
          <>
            <FormItem
              label={t({ id: 'ats.importModal.review' })}
            >
              <Rate onChange={setRating} value={rating} />
            </FormItem>

            <FormItem
              required
              label={t({ id: 'ats.importModal.reviewer' })}
            >
              <Input onChange={(e) => setReviewer(e.currentTarget.value)} placeholder="" value={reviewer} label={t({ id: 'ats.importModal.reviewerHelp' })}/>
            </FormItem>

            <FormItem
              label={t({ id: 'ats.importModal.comment' })}
            >
              <Textarea onChange={(e) => setComment(e.currentTarget.value)} rows={6} value={comment} />
            </FormItem>
          </>
        )}

        {
          isError && (
            <Alert color="error">
              {t({ id: 'ats.importModal.error.title' })}<br />
              {t({ id: 'ats.importModal.error.contact.support' })}
            </Alert>
          )
        }
      </div>
    </ModalBig>
  );
}

export default ModalAtsImport;
