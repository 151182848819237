import { getId } from '@/utils/global';


export function createFreeSlots({
  slots,
  settings,
  currentEvent,
  userId,
  ownerId,
}) {
  return slots
    .map((slot) => {
      const beginAt = slot;

      // Fix issue with the first slot of the day
      beginAt.setSeconds(0);
      beginAt.setMilliseconds(0);

      return {
        beginAt,
        duration: settings.duration,
        medium: settings.medium,
        location: settings.location,
        _event: settings.isMultiEvents ? null : getId(currentEvent),
        _user: userId,
        _owner: ownerId,
        type: settings.type,
        informalDescription: settings.informalDescription,
      };
    }, []);
}
