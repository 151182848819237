import React from 'react';
import { object } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { useHistory, useLocation } from 'react-router-dom';
import { IconCalendar, Link2 } from '@seekube-tech/ui-kit';
import classnames from 'classnames';
import styles from '../../../styles.less';

const Agenda = ({ event }) => {
  const t = useFormatMessage();
  const history = useHistory();
  const location = useLocation();
  const hasActiveNavlink =
    location.pathname.indexOf('/client/agenda') > -1
    || location.pathname.indexOf('/recruiter/agenda') > -1;

  const handleOnClick = () => {
    if (hasActiveNavlink) return null;
    const isOwnerUrl = location.pathname.indexOf(`${event?.slug}/owner`) > -1;

    if (location.pathname.indexOf(event?.slug) > -1 && !isOwnerUrl) {
      return history.push(`/${event.slug}/recruiter/agenda`);
    }

    return history.push(`/client/agenda`);
  }

  return (
    <div className={styles.colAuth}>
      <Link2
        onClick={handleOnClick}
        className={classnames('whitespace-nowrap', { 'activeNavLink': hasActiveNavlink })}
        role="button"
        tabIndex={0}
      >
        <IconCalendar />
        <span className="ml-5">{t({ id: 'profile.settings.calendar.title' })}</span>
      </Link2>
    </div>
  );
};

Agenda.propTypes = {
  event: object,
};

export { Agenda };
