import { defineMessages } from 'react-intl';

export default defineMessages({
  submit: {
    id: 'save',
  },
  cancel: {
    id: 'cancel',
  },
});
