import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import { injectIntl } from 'react-intl';
import { array, func, object } from 'prop-types';

import Icon from '@/components/Icon';
import { If } from '@/components/If';

import styles from './styles.less';

const Contacts = ({ items, intl, onDisplayContact }) => {
  const [displayContact, setDisplayContact] = useState(false);

  if (isEmpty(items)) {
    return intl.formatMessage({ id: 'seekalendar.noContact' });
  }

  const handleDisplayContact = () => {
    setDisplayContact(true);

    if (typeof onDisplayContact === 'function') {
      onDisplayContact();
    }
  };

  return items.map((c, index) => (
    <div key={`contact${index}`} className={styles.contactItem}>
      <h4>{c.fullName}</h4>
      <div className={styles.title}>{c.title}</div>

      <If condition={(!isEmpty(c.phone) || !isEmpty(c.email) || !isEmpty(c.phone2)) && !displayContact}>
        <div className="infoItem">
          <span className="infoItemIcon"><Icon name="phone" /></span><a role="button" tabIndex={0} onClick={handleDisplayContact}>{intl.formatMessage({ id: 'seekalendar.displayContact' })}</a>
        </div>
      </If>
      <If condition={!isEmpty(c.phone) && displayContact}>
        <div className="infoItem">
          <><span className="infoItemIcon"><Icon name="phone" /></span> {c.phone}</>
        </div>
      </If>

      <If condition={!isEmpty(c.phone2) && displayContact}>
        <div className="infoItem">
          <span className="infoItemIcon"><Icon name="phone" /></span> {c.phone2}
        </div>
      </If>

      <If condition={!isEmpty(c.email) && displayContact}>
        <div className="infoItem">
          <span className="infoItemIcon"><Icon name="mail" style={{ width: '12px' }} /></span> <a href={`mailto:${c.email}`}>{c.email}</a>
        </div>
      </If>
    </div>
  ));
};

Contacts.propTypes = {
  intl: object,
  items: array,
  onDisplayContact: func,
};

export default injectIntl(Contacts);
