import React from 'react';
import { func, bool, array, number, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Route, Switch } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { toJS } from '@/utils';

// Store
import { actionActions, actionSelectors } from '@/store/action';
import { organizationActions, organizationSelectors } from '@/store/organization';
import { criterionActions, criterionSelectors } from '@/store/criterion';

// Components
import { Row, Col } from 'antd';
import MergeMenu from '../../components/MergeMenu';
import Log from '../../components/Log';

// Styles & Translations
import styles from './styles.less';
import MergeSchoolsPage from '../MergeSchools';
import MergeCriteriaPage from '../MergeCriteria';
import HandleSchools from './components/HandleSchools';
import { withLayout } from '../../../../containers/Layout';

class MergeSchoolsScene extends React.PureComponent { // eslint-disable-line
  static propTypes = {
    push: func,
    getMergeList: func,
    getMergeListCriterion: func,
    mergeListTotal: number,
    mergeListTotalCriterion: number,
    match: object,
    getActions: func,
    deleteAction: func,
    isFetching: bool,
    actions: array,
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedMergeMenu: 'new',
      isLoading: false,
      cancelTimeoutId: null,
    };

    props.getMergeList();
    props.getMergeListCriterion();
    props.getActions({ name: 'MERGE_SCHOOL' });
  }


  handleCancelMerge = (actionId) => {
    const {
      props: { deleteAction, getActions },
      state: { cancelTimeoutId },
    } = this;

    clearTimeout(cancelTimeoutId);

    this.setState({ cancelTimeoutId: null });

    deleteAction({
      actionId,
      callback: () => { getActions({ name: 'MERGE_SCHOOL' }); },
    });
  };

  render() {
    const {
      props: { mergeListTotal, isFetching, actions, mergeListTotalCriterion, push, match },
      state: { cancelTimeoutId },
    } = this;

    if (isFetching) {
      return ('fetching');
    }

    return (
      <div>
        <Row gutter={128}>
          <Col span={3}>
            <div className={styles.menuColumn}>
              <MergeMenu
                push={push}
                countNewSchools={mergeListTotal}
                countCriteria={mergeListTotalCriterion}
              />
              <Log
                items={actions}
                displayCancel={cancelTimeoutId !== null}
                onCancel={(itemId) => this.handleCancelMerge(itemId)}
              />
            </div>
          </Col>
          <Col span={9}>
            <Switch>
              <Route path={`${match.path}/merge-schools`} component={MergeSchoolsPage} />
              <Route path={`${match.path}/merge-criteria`} component={MergeCriteriaPage} />
              <Route path={`${match.path}/handle-schools`} component={HandleSchools} />
            </Switch>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  actions: actionSelectors.getActions,
  isFetching: organizationSelectors.getOrganizationFetching,
  mergeList: organizationSelectors.getMergeList,
  mergeListTotal: organizationSelectors.getMergeListTotal,
  mergeListTotalCriterion: criterionSelectors.getMergeListTotal,
  suggestions: organizationSelectors.getSuggestions,
});

const mapDispatchToProps = {
  getActions: actionActions.getActions,
  deleteAction: actionActions.deleteAction,
  postAction: actionActions.postAction,
  getOrganizations: organizationActions.getOrganizations,
  mergeOrganization: organizationActions.mergeOrganization,
  getMergeList: organizationActions.getMergeList,
  getMergeListCriterion: criterionActions.getMergeList,
  push,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  withLayout({
    navType: 'owner',
    withHeader: true,
  }),
)(toJS(MergeSchoolsScene));

