import React from 'react';
import { injectIntl } from 'react-intl';
import { array, bool, object, func, string, node, oneOfType } from 'prop-types';
import { ANALYTICS_CANDIDATE } from '@/utils/constants';
import { track } from '@/utils/analytics';
import classnames from 'classnames';

// Components
import AvatarWithDate from '@/components/AvatarWithDate';
import Card from '@/components/Card';
import Avatar from '@/components/Avatar';
import { Tag } from '@/components/Tag';
import ImgCdn from '@/components/ImgCdn';
import OfferItem from './components/OfferItem';
import OfferContent from './components/OfferContent';
import OfferAd from './components/OfferAd';

// Styles
import styles from './styles.less';
import messages from '../messages';

const onOpenOffer = (event, offer, handleOpenOffer) => () => {
  track({
    name: ANALYTICS_CANDIDATE.OFFER_VISIT,
    event,
    properties: {
      offerId: offer._id,
      offerTitle: offer.title,
    },
  });

  handleOpenOffer();
};

/**
 * OfferCardListParticipant
 *
 * @param {object} propsmodalAppointmentIsVisible
 */
const OfferCardListParticipant = ({ offer, event, appointment, participant, highlightWords, handleOpenOffer, context, popoverContent, type, date, withBackground, status, intl, onClick }) => {
  const interaction = participant && participant.interactions ? participant.interactions
    .filter((interaction) =>
      (offer && interaction._applicant && interaction._applicant._offer && interaction._applicant._offer._id === offer._id) ||
      (offer && interaction._appointment && interaction._appointment._offer && interaction._appointment._offer._id === offer._id) ||
      (appointment && interaction._appointment && interaction._appointment._id === appointment._id)
    )[0] : null;

  const organization = offer ? offer._organization : appointment && appointment._organization;

  const dateBlock = organization && date ? (
    <div className={styles.dateBlock}>
      <AvatarWithDate
        src={organization.profile.pictureUrl}
        alt={organization.name}
        date={date}
      />
    </div>
  ) : '';

  const content = type === 'default' ? (
    <OfferItem
      handleOpenOffer={onOpenOffer(event, offer, handleOpenOffer)}
      offer={offer}
      highlightWords={highlightWords}
      participant={participant}
      interaction={interaction}
      event={event}
      appointment={interaction ? interaction._appointment : ''}
      context={context}
      popoverContent={popoverContent}
      style={date ? { paddingLeft: '130px' } : ''}
      type={type}
    />
  ) : (
    <OfferContent
      offer={offer}
      event={event}
      highlightWords={highlightWords}
      handleOpenOffer={onOpenOffer(event, offer, handleOpenOffer)}
      interaction={interaction}
      context={context}
      type={type}
    />);

  const offerContent = offer && offer.ad ? (<OfferAd />) : content;

  return (
    <Card
      noBorder
      onClick={onClick}
      containerClassName={classnames(styles.card, type !== 'default' ? styles.cardLight : '', withBackground ? 'withBackground' : '', status != null ? 'withStatus' : '')}
      style={{ cursor: typeof onClick === 'function' ? 'pointer' : 'default' }}
    >
      {withBackground ? (
        <>
          <div className={styles.backgroundCover}>
            {organization.profile ? (<ImgCdn size={800} src={offer._organization.profile.cover} alt={organization.name} />) : ''}
          </div>
          <div className={styles.backgroundOverlay} />
        </>
      ) : ''}

      <div className={styles.wrapper}>
        {dateBlock}
        {offerContent}
      </div>

      {type === 'light' ? (
        <>
          <div className={styles.organization}>
            {offer._user ? (<Avatar size={34} src={offer._user.pictureUrl} user={offer._user} />) : ''}
            <div>{offer._user.shortName}</div>
          </div>
          {status && status !== 'withSlot' && event.skipAppointmentValidation ? (
            <div className={styles.status}>
              <Tag type={status === 'noSlot' ? 'pending' : status}>{intl.formatMessage(messages[status])}</Tag>
            </div>
          ) : ''}
        </>

      ) : ''}
    </Card>
  );
};

OfferCardListParticipant.propTypes = {
  intl: object,
  event: object,
  offer: object,
  appointment: object,
  date: oneOfType([object, string]),
  participant: object,
  highlightWords: array,
  handleOpenOffer: func,
  context: string,
  type: string,
  status: string,
  withBackground: bool,
  popoverContent: node,
  onClick: func,
};

OfferCardListParticipant.defaults = {
  withBackground: false,
  context: 'offer',
  type: 'default',
  highlightWords: [],
};

export default injectIntl(OfferCardListParticipant);
