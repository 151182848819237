import React from 'react';
import { func } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import InputMaterial from '@/components/Input/InputMaterial';
import styles from './styles.less';

const SearchOffer = ({ onSearchChange }) => {
  const t = useFormatMessage();

  const onSearchChanged = (val) => {
    onSearchChange(val.target.value);
  };

  return (
    <div className={styles.searchOffer}>
      <InputMaterial
        type="string"
        placeholder={t({ id: 'event.recruiter.preparation.offer.search' })}
        addOnIcon="search"
        onChange={onSearchChanged}
      /></div>
  );
}

SearchOffer.propTypes = {
  onSearchChange: func,
};

export default SearchOffer;
