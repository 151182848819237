import React, { useEffect, useState } from 'react';
import { object } from 'prop-types';
import { List } from 'immutable';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pick } from 'lodash';

// Ducks
import { Button } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { criterionActions, criterionSelectors } from '@/store/criterion';

// Components
import Modal from './components/Modal/index';


const SelectDefaultCriteria = ({
  defaultCriteria = new List(),
  getDefaultCriteria,
  criteriaSelected,
  eventCriteria,
}) => {
  const t = useFormatMessage();

  const [modalIsVisible, setModalIsVisible] = useState(false);

  useEffect(() => {
    getDefaultCriteria();
  }, []);

  /**
   * Toggle the modal
   */
  const toggleModal = () => setModalIsVisible(!modalIsVisible);

  /**
   * Handle when click on save on the modal
   *
   * @param {Array} criteriaIds
   */
  const handleOnOk = (criteriaIds) => {

    // Create new list with all criteria based on criterionId on select
    const criteria = criteriaIds.reduce((acc, criterionId) => {
      const criterion = defaultCriteria.filter((criterion) => !criterion.get('deleted') && criterion.get('_id') === criterionId);

      if (criterion.size > 0) {
        acc = acc.push(criterion.get(0)); // eslint-disable-line
      }

      return acc;
    }, new List());

    criteriaSelected(criteria); // Return criteria List to the parent
    toggleModal(); // Close the modal
  };

  const props = pick({ defaultCriteria, criteriaSelected, eventCriteria }, ['className']);

  return (
    <div {...props}>
      <Modal
        isVisible={modalIsVisible}
        defaultCriteria={defaultCriteria}
        onCancel={toggleModal}
        onOk={handleOnOk}
        eventCriteria={eventCriteria}
        cancelText={t({ id: 'cancel' })}
      />
      <Button
        onClick={toggleModal}
      >
        {t({ id: 'event.owner.settings.criteria.add' })}
      </Button>
    </div>
  );
}

SelectDefaultCriteria.propTypes = {
  defaultCriteria: object,
  getDefaultCriteria: object,
  criteriaSelected: object,
  eventCriteria: object,
}

const mapStateToProps = createStructuredSelector({
  defaultCriteria: criterionSelectors.getDefaultCriteria,
});

const mapDispatchToProps = {
  getDefaultCriteria: criterionActions.getDefaultCriteria,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectDefaultCriteria);
