import * as React from 'react';
import ReactDOM from 'react-dom';
import { Banner } from "@seekube-tech/ui";
import { useFormatMessage } from 'react-intl-hooks';
import { isFromHelloWorkUtm } from '@/utils/url';

function FromHelloWorkBanner({ offer, event }) {
  const [showFromHelloWorkBanner, setShowFromHelloWorkBanner] = React.useState(true);
  const t = useFormatMessage();

  return ReactDOM.createPortal(<Banner
    variant="success"
    text={t(
      { id: 'candidate.offer.banner.fromHelloWork' },
      { offerName: offer.title, eventName: event.name }
    )}
    visible={showFromHelloWorkBanner && isFromHelloWorkUtm()}
    onClose={() => setShowFromHelloWorkBanner(false)}
  />, document.getElementById("banner"))
}

export default FromHelloWorkBanner;
