import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import chunk from 'lodash/chunk';
import size from 'lodash/size';
import Slider from 'react-slick';
import { Row, Col } from 'antd';
import { useFormatMessage } from 'react-intl-hooks';
import { authSelectors } from '@/store/auth';
import Separator from '@/components/Separator';
import OrganizationCard from '@/scenes/Event/containers/Landing/components/Contents/components/GeneralInfos/components/LandingContent/components/Organizations/components/OrganizationCard';
import Title from '@/scenes/Event/components/Title';
import styles from '@/scenes/Event/containers/Landing/components/Contents/components/GeneralInfos/components/LandingContent/styles.less';

const isMobile = window.innerWidth <= 650;
const itemsPerSlide = isMobile ? 3 : 4;

const Organizations = ({ event, contentIndex, editMode, onChange, locale, authUser, exponents, exponentsStats, onInit }) => {
  const t = useFormatMessage();
  const [corner, setCorner] = useState([]);
  const [sliderPages, setSliderPages] = useState([]);

  useEffect(() => {
    if (onInit) {
      onInit(contentIndex)({
        inNav: true,
        label: t({ id: 'event.content.text.label' }),
        h2: t({ id: 'event.content.text.h2' }),
        h3: t({ id: 'event.content.text.h3' }),
      });
    }
  }, []);

  useEffect(() => {
    const exponentsWithLogo = exponents
      .filter((item) => item?._organization?.profile?.pictureUrl);

    setSliderPages(chunk(exponentsWithLogo, itemsPerSlide));
  }, [exponents]);

  useEffect(() => {
    const exponentsWithLogo = exponents
      .filter((item) =>
      (
        item._organization.profile &&
        item._organization.profile.pictureUrl &&
        (corner.length === 0 || item.corners.find((itemCorner) => corner.indexOf(itemCorner) !== -1))
      )
      );

    setSliderPages(chunk(exponentsWithLogo, itemsPerSlide));
  }, [corner]);

  const renderSliderPages = () => sliderPages.map((exponentsInPage, i) => (
    <Row type="flex" justify="space-around" align="middle" key={`page-${i}`} className={styles.organizationsLogoWrapper} style={{ display: 'flex' }}>
      {exponentsInPage
        .map((exponent, index) => {
          const stats = exponentsStats && exponentsStats[exponent._id] ? exponentsStats[exponent._id] : null;
          return (
            <Col xs={12} sm={12} md={12} lg={6} key={index}>
              <OrganizationCard
                exponent={exponent}
                stats={stats}
                hideStatus
                dateStatus={event.dateStatus}
                withOpportunities={event.withOfferModule}
                event={event}
              />
            </Col>
          );
        })}
    </Row>
  ));

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
  };

  return (
    <div className={styles.organizationContainer}>
      <div className={styles.organizationsWrapper}>
        <Title
          event={event}
          contentIndex={contentIndex}
          editMode={editMode}
          onChange={onChange}
          locale={locale}
        />
      </div>

      <Row type="flex" justify="space-around" align="middle" className={styles.organizationsLogoWrapper}>
        <div style={{ width: '100%' }}>
          {size(sliderPages) > 1 ? (
            <Slider {...settings} customPaging={() => <a />}>
              {renderSliderPages()}
            </Slider>
          ) : renderSliderPages()}
        </div>
      </Row>
      <Separator height={20} />
    </div>
  )
};

Organizations.propTypes = {
  event: PropTypes.object,
  authUser: PropTypes.object,
  exponents: PropTypes.any,
  exponentsStats: PropTypes.object,
  contentIndex: PropTypes.number,
  editMode: PropTypes.bool,
  onChange: PropTypes.func,
  onInit: PropTypes.func,
  locale: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser,
});

const withConnect = connect(mapStateToProps, null);

export default compose(
  withConnect,
)(Organizations);
