import React, { Fragment } from 'react';
import { object, func, string } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { interactionSelectors } from '@/store/interaction';
import CardOffer
  from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Exponents/scenes/Exponent/components/Offers/components/CardOffer';
import { trackCandidateClickedOnCardToSignup } from '@/utils/analytics';
import { HASH } from '@/scenes/Event/containers/Landing/const';

const CardOffers = ({
  event,
  offersInfiniteQuery,
  redirectionHref,
}) => {
  const { push } = useHistory();
  const interactions = useSelector(interactionSelectors.getInteractions)?.toJS();

  const t  = useFormatMessage();

  const getInteraction = (offer) => interactions?.find(int => int._offer?._id === offer._id);

  const handleClick = () => {
    trackCandidateClickedOnCardToSignup({ from : HASH.offers });
    push(redirectionHref);
  }

  return (
    offersInfiniteQuery?.data?.pages?.map(page => page?.docs?.map((offer, key) => (
      <Fragment key={key}>
        <CardOffer
          onClick={handleClick}
          offer={offer}
          event={event}
          interaction={getInteraction(offer)}
          avatar={offer.organizationProfile.pictureUrl}
          action={t({ id: 'show.offer' })}
        />
      </Fragment>
    ))) || null
  )
}

CardOffers.propTypes = {
  event: object,
  offersInfiniteQuery: func,
  redirectionHref: string
}

export { CardOffers };
