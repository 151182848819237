import React from 'react';
import { keyBy, isInteger, isEmpty, get, size } from 'lodash';
import moment from 'moment';
import { array, object, string } from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';

import messages from '../../messages';

const filters = {
  CONTRACT: {
    wording: (text) => { if (!text || text.includes(', ,') || text.includes(' ,')) { return null; } return <FormattedMessage {...messages.one} values={{ text: <strong>{text} </strong> }} />; },
    getText: (arr) => arr.join(', '),
  },
  AVAILABILITY: {
    wording: (date) => <FormattedMessage {...messages.date} tagName="strong" values={{ searchDate: date }} />,
    getText: (date) => date === 1 ? 1 : moment.unix(date).format('MMMM YYYY'),
  },
  DURATION: {
    wording: ({ duration, withPrefix }) => { if (duration.includes(', ,')) { return null; } return <FormattedMessage values={{ duration: <strong>{duration}</strong>, withPrefix: <strong>{withPrefix}</strong> }} {...messages.duration} />; },
    getText: (arr) => arr[0] !== null ? ({ duration: arr[0].toLowerCase(), withPrefix: arr[0].charAt(0) !== 'D' }) : '',
  },
  MOBILITY: {
    wording: (mobility) => { if (mobility.includes(', ,')) { return null; } return <FormattedMessage {...messages.mobility} values={{ mobility: <strong>{mobility}</strong> }} />; },
    getText: (arr) => arr.join(', '),
  },
};

const renderFilter = (name, choices, index) => {
  const filter = filters[name];
  const text = choices[name] && choices[name].label ? filter.getText(choices[name].label) : null;

  return (
    <React.Fragment key={index}>
      {index === 0 ?
        <>
          <FormattedMessage {...messages.search} /><span style={{ paddingRight: '5px' }} />
          {text !== '' ? filter.wording(text) : null}
        </>
          : (text !== '' ? filter.wording(text) : null)}
    </React.Fragment>);
};

const renderSearch = (choices, list, authUser) => list.map((filterName, index) => renderFilter(filterName, choices, index, authUser));

const MySearch = ({ participant, authUser, defaultCriteria }) => {
  const searchKeys = ['CONTRACT', 'AVAILABILITY', 'DURATION', 'MOBILITY'];

  if (size(participant.matching.CONTRACT) === 1 && participant.matching.CONTRACT.indexOf('598826added2cd00b2ad666a') > -1) {
    searchKeys.splice(2, 1); // on delete la key duration id contract is only CDI
  }

  const filters = participant.matching.filters ? participant.matching.filters : [];
  const searchChoices = keyBy(searchKeys.map((key) => {
    const criteria = isEmpty(participant._event) ? defaultCriteria.filter((item) => item.key === key)[0] : participant._event._criteria.filter((item) => item.key === key)[0];
    const choicesAvailable = criteria ? criteria._choices.filter((c) => c.enable) || criteria.jobs : [];

    if (['AVAILABILITY'].includes(get(criteria, 'key')) && moment(participant.matching.dateStr).isAfter(moment().startOf('year'))) {
      return { key, label: participant.matching.date };
    }

    return getChoicesThatUserSelected(key, choicesAvailable, filters, authUser);
  }), 'key');

  const searchChoicesToDisplay = searchKeys.filter((key) => isInteger(searchChoices[key].label) ? searchChoices[key].label : searchChoices[key].label && searchChoices[key].label.length);
  return (
    <>
      {renderSearch(searchChoices, searchChoicesToDisplay, authUser)}.&nbsp;
    </>
  );
};

const getChoicesThatUserSelected = (key, choices, filters, authUser) => {
  let label = '';
  const userChoices = choices.filter((choice) => filters.includes((choice._id || choice).toString()));
  if (authUser.locale === 'fr') {
    label = userChoices.map((item) => item.label);
  } else {
    label = userChoices.map((item) => item.label_en ? item.label_en : null);
  }
  return { key, label };
};

MySearch.propTypes = {
  defaultCriteria: array,
  participant: object,
  authUser: object,
};

filters.DURATION.wording.propTypes = {
  duration: string,
  withPrefix: object,
};

export default injectIntl(MySearch);
