import React from 'react';
import { bool, object } from 'prop-types';
import { injectIntl } from 'react-intl';
import { isEmpty } from 'lodash';

// Containers
import { NavScenePortal } from '@/containers/Layout/index';
import NavEvent from '@/components/Nav/NavEvent/index';

import messages from './messages';

/**
 * NavJobdating
 *
 * @param {object} props
 * @param {object} props.match
 */
const NavJobdating = ({ match, css, intl: { locale, formatMessage }, withOfferModule, modules }) => {
  const elems = [];

  if (modules?.dashboard?.enable) {
    const labelDashboard = modules?.dashboard?.[`label_${locale}`] ?? formatMessage({ id: 'event.owner.dashboard' });

    elems.push({ url: `${match.url}/dashboard`, label: labelDashboard, icon: 'home', type: 'blue' });
  }

  if (withOfferModule) {
    const labelOffers = modules.offer && modules.offer[`labelMultiple_${locale}`] ? modules.offer[`labelMultiple_${locale}`] : formatMessage(messages.offers);
    elems.push({ url: `${match.url}/jobs`, label: labelOffers, icon: 'offers', type: 'default' });
  }

  let labelOrganizations = formatMessage(messages.exponents);

  if (modules && modules.organization && !isEmpty(modules.organization[`labelMultiple_${locale}`])) {
    labelOrganizations = modules.organization[`labelMultiple_${locale}`];
  }


  elems.push({ url: `${match.url}/exponents`, label: labelOrganizations, icon: 'building', type: 'blue' });

  if (modules.live && modules.live.participantEnable) {
    const message = modules.live && modules.live[`labelMultiple_${locale}`] ? modules.live[`labelMultiple_${locale}`] : formatMessage(messages.lives, { count: 2 });
    elems.push({ url: `${match.url}/conferences`, label: message, icon: 'camera', type: 'blue' });
  }

  if (modules.informal1to1?.enable) {
    const message = modules.informal1to1?.[`labelMultiple_${locale}`];
    elems.push({ url: `${match.url}/informal1to1`, label: message, icon: 'language', type: 'blue' });
  }

  if (modules.appointment?.enable) {
    const message = modules.appointment?.[`labelParticipant_${locale}`] || formatMessage(messages.appointments);
    elems.push({ url: `${match.url}/appointments`, label: message, icon: 'star' +
        '', type: 'blue' });
  } else {
    elems.push({ url: `${match.url}/appointments`, label: formatMessage(messages.appointments), icon: 'star', type: 'blue' });
  }

  return (
    <NavScenePortal>
      <div style={css}>
        <NavEvent items={elems} />
      </div>
    </NavScenePortal>
  );
};

NavJobdating.propTypes = {
  match: object,
  css: object,
  intl: object,
  withOfferModule: bool,
  modules: object,
};

export default injectIntl(NavJobdating);
