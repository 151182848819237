import { USERS_TYPE } from '@/utils/constants';
import { authLogoutUser } from '@/store/auth/actions';
import { store } from '@/configureStore';
import Cookies from 'js-cookie';
import { isEmpty } from 'lodash';
import { initialState } from '@/store/auth/reducer';

export const redirectUnAuthUser = (user) => {
  store.dispatch(authLogoutUser());
  
  if (isEmpty(user)) {
    return window.location.replace('/auth/login/candidate');
  }
  
  const savedLocation = window.location.pathname;
  const userType = user?._currentOrganization ? USERS_TYPE.recruiter : USERS_TYPE.candidate;

  Cookies.remove("seekube.id");
  Cookies.remove("seekube.id.sig");
  Cookies.remove("remember_me");
  Cookies.remove("remember_me.sig");
  
  localStorage.setItem('redux', JSON.stringify({ ...JSON.parse(window.localStorage.getItem('redux')), auth: initialState }));
  return window.location.replace(`/auth/login/${userType}?redirect=${savedLocation}`);
}