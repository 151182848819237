import React from 'react';
import { node } from 'prop-types';
import styles from '@/scenes/Settings/MenuSettings/styles.less';


export const Menu = ({ children }) => (
  <div className={styles.menu}>
    {children}
  </div>
)

Menu.propTypes = {
  children: node,
}
