import React from 'react';
import { object } from 'prop-types';
import { IconLocationWorld } from '@seekube-tech/ui-kit';
import styles from './styles.less';

function Location ({ event }) {
  if (!event?.location?.formattedAdress || event.format === 'virtual') {
    return null;
  }

  return (
    <div className={styles.eventDateContainer} style={{ marginBottom: 29 }}>
      <IconLocationWorld className={styles.locationIcon} />
      <div>
        {event.location.formattedAdress}, {event.location.countryCode}
      </div>
    </div>
  );
}

Location.propTypes = {
  event: object,
}

export { Location }
