import React, { useEffect, useState } from 'react';
import { string, bool } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import { Typography } from '@seekube-tech/ui';
import { Button, IconArrowUp } from '@seekube-tech/ui-kit';
import { connect } from 'react-redux';
import { If } from '@/components/If';
import { useGetFeeds } from '@/queries/kpis/useGetFeeds';
import { FeedItem } from '@/scenes/Admin/components/Kpi/components/Feeds/FeedItem';
import {
  useGetShouldRefreshFeeds
} from '@/queries/kpis/useGetShouldRefreshFeeds';

import useStyles from '@/scenes/Admin/components/Kpi/components/Feeds/styles';

const second = 1000;

let date = moment().utc().format();

const Feeds = ({ isSpotlightVisible }) => {
  const t = useFormatMessage();
  const classes = useStyles();
  const [autoRefresh, setAutoRefresh] = useState(true);

  const feedsQuery = useGetFeeds();
  const shouldRefreshFeedsQuery = useGetShouldRefreshFeeds({
    refetchInterval: autoRefresh && isSpotlightVisible && (5 * second),
    date,
  })

  useEffect(() => {
    setAutoRefresh(!autoRefresh);
    date = moment().utc().format();
  }, [shouldRefreshFeedsQuery?.data?.refresh])

  const onRefresh = () => {
    date = moment().utc().format();
    feedsQuery.refetch();
    shouldRefreshFeedsQuery.refetch();
    document.getElementById('feeds').scrollTo(0,0)
  }

  return (
    <div>
      <div className={classes.header}>
        <Typography variant="h6" color="neutral.500">
          {t({ id: 'feed.pl' }, { count: 1 })}
        </Typography>
        <If condition={shouldRefreshFeedsQuery?.data?.refresh}>
          <div className={classes.refresh}>
            <Button
              size="small"
              color="neutral"
              variant="outline"
              onClick={onRefresh}
              imageComponentRight={<IconArrowUp size={16} />}
            >
              {t({ id: 'novelty.pl' }, { count: 2 })}
            </Button>
          </div>
        </If>
      </div>
      <div id="feeds" className={classes.scroll}>
        {feedsQuery?.data?.map((feedItem) => <FeedItem data={feedItem} />)}
      </div>
    </div>
  )
}

Feeds.propTypes = {
  isSpotlightVisible: bool,
}

const mapStateToProps = createStructuredSelector({
})

const withConnect = connect(mapStateToProps, null);


export default withConnect(Feeds)
