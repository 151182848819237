import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import moment from 'moment';
import { get, isEmpty } from 'lodash';
import { func, bool, object } from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { push } from 'connected-react-router';
import { Modal } from 'antd';
import { Button } from '@seekube-tech/ui-kit';
import Icon from '@/components/Icon';
import Textarea from '@/components/Textarea';
import { queryStringToObject } from '@/utils/url';
import { UserCard } from '../../../Appointment/components/AppointmentDetails';

import messages from '../../../Appointment/components/MainContent/messages';
import styles from './styles.less';

class CancelAppointmentModal extends PureComponent {
  constructor(props) {
    super(props);

    const { appointment, intl, onVisibleChange } = props;

    if (queryStringToObject(window.location.search).modal === 'refuse') {
      onVisibleChange({ refuseModalVisible: true });
    }

    let context = 'refuse';
    let cancelMessage = '';
    const appointmentHasATimeslot = !isEmpty(appointment._timeslot);
    const appointmentIsPast = appointment.date && moment(appointment.date) < moment();

    if (appointmentHasATimeslot) {
      context = appointmentIsPast ? 'relaunch' : 'cancel';
      cancelMessage = appointmentIsPast ? intl.formatMessage(messages.defaultRelaunchMessage) : '';
    }

    this.state = {
      cancelMessage,
      refuseModalVisible: this.props.visible,
      fullWidth: document.documentElement.clientWidth < 800,
      context,
    };
  }

  componentWillReceiveProps({ appointment, visible, intl }) {
    if (!isEmpty(appointment) && visible && this.props.visible === false) {
      let context = 'refuse';
      let cancelMessage = '';
      const appointmentHasATimeslot = !isEmpty(appointment._timeslot);
      const appointmentIsPast = appointment.date && moment(appointment.date) < moment();

      if (appointmentHasATimeslot) {
        context = appointmentIsPast ? 'relaunch' : 'cancel';
        cancelMessage = appointmentIsPast ? intl.formatMessage(messages.defaultRelaunchMessage) : '';
      }

      const element = document.getElementById('ok');
      if (element) {
        element.value = cancelMessage;
      }
      this.setState({ context, cancelMessage });
    }
  }

  onCancelMessageChange = (e) => {
    this.setState({ cancelMessage: e.target.value });
  };

  handleCancelSelection = () => {
    this.props.handleCancelMessage(this.state.cancelMessage);
  };

  handleOnVisibleChange = (refuseModalVisible) => {
    this.props.onVisibleChange(refuseModalVisible);
    this.setState({ refuseModalVisible });
  };

  responsiveWidth = () => {
    if (document.documentElement.clientWidth > 800 && this.state.fullWidth) {
      this.setState({ fullWidth: false });
    } else if (document.documentElement.clientWidth < 800 && this.state.fullWidth) {
      this.setState({ fullWidth: true });
    }
  };

  hasTimeslot = () => !isEmpty(get(this.props.appointment, '_timeslot'));

  render() {
    const {
      props: { appointment, intl, isRefuseLoading },
      state: { fullWidth, context, cancelMessage },
      props, handleCancelSelection, onCancelMessageChange, handleOnVisibleChange, responsiveWidth, hasTimeslot } = this;

    responsiveWidth();
    const appointmentHasATimeslot = hasTimeslot();

    return (
      <Modal
        zIndex={100}
        width={fullWidth ? '100%' : 750}
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
        overlayClassName={styles.test2}
        wrapClassName={styles.modalWrap}
        onCancel={() => handleOnVisibleChange(false)}
        visible={this.props.visible}
        onVisibleChange={handleOnVisibleChange}
        footer={
          <div>
            <div>
              <Button
                onClick={handleCancelSelection}
                loading={isRefuseLoading}
                disabled={isRefuseLoading || context === 'relaunch' ? isEmpty(cancelMessage) : false}
              >
                <FormattedMessage {...messages[`${context}AppointmentSubmit`]} values={{ name: '' }} />
              </Button>
              {
                appointmentHasATimeslot && context !== 'relaunch' &&
                <a role="button" tabIndex="0" onClick={() => handleOnVisibleChange(false)}><FormattedMessage {...messages.cancelBtn} /></a>
              }
            </div>
          </div>
        }
        title={
          <div className="appointment-details">
            <UserCard
              className={styles.recruiterCard}
              user={appointment._organizationUser}
              title={intl.formatMessage(messages[`${context}AppointmentModalTitle`], { name: appointment._organizationUser.firstName })}
              content={intl.formatMessage(messages.recruiterAtCompany, { recruiterTitle: appointment._organizationUser.title, company: appointment._organization.name })}
            />
            <hr />
          </div>
        }
        trigger="click"
        placement="top"
        {...props}
        maskClosable

      >
        <div className={styles.modalBody}>
          <a role="button" tabIndex={0} className="modal-close" onClick={() => handleOnVisibleChange(false)}>
            <Icon name="close" className="modal-close-icon" />
          </a>
          {context === 'relaunch' ? '' : (
            <h2><FormattedMessage {...messages[appointmentHasATimeslot ? 'labelCancelTextArea' : 'labelRefuseTextArea']} /></h2>
          )}
          <Textarea
            onChange={onCancelMessageChange}
            placeholder={context !== 'relaunch' ? intl.formatMessage(messages.placeholderCancelTextArea) : ''}
            id="ok"
            defaultValue={cancelMessage}
          />
        </div>
      </Modal>
    );
  }
}

CancelAppointmentModal.propTypes = {
  appointment: object,
  handleCancelMessage: func,
  onVisibleChange: func,
  visible: bool,
  intl: object,
};

const withConnect = connect(null, { push });

export default compose(withConnect, injectIntl)(CancelAppointmentModal);

