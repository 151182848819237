const algoliasearch = require('algoliasearch/lite');

const client = algoliasearch(process.env.FRONT_ALGOLIA_APP_ID, process.env.FRONT_ALGOLIA_API_KEY);
const index = client.initIndex(process.env.FRONT_ALGOLIA_PUBLIC_INDEX);

const algoliaSearch = (search, callback = () => {}) => {
  index
  .search(search, {
    attributesToHighlight: []
  })
  .then(({ hits }) => {
    callback(hits);
  });
}

export { algoliaSearch };