import React from 'react';
import { object, func } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Form, Input, Radio } from 'antd';
import { H4, Button, Body1 } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { Bloc, BlocHeader } from '@/components/Bloc';
import styles from '@/scenes/Event/scenes/Owner/scenes/Setting/styles.less';
import messages from '@/scenes/Event/scenes/Owner/scenes/Setting/scenes/Diffusion/messages';
import { story } from '@/utils/manageVisibilityStory';

const { TextArea } = Input;

const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;

const PublishOnBtoC = ({
  form: { getFieldDecorator, validateFields, getFieldValue },
  initialValues,
  onSubmit,
}) => {
  const t = useFormatMessage();

  const handleSubmit = (e) => {
    e.preventDefault();

    return validateFields(onSubmit);
  };

  return (
    <Bloc>
      <Form onSubmit={handleSubmit} style={{ width: '100%', textAlign: 'left' }} layout="vertical">
        <BlocHeader>
          <H4 className="mb-20">{t({ id: 'calendar.b2c' })}</H4>
        </BlocHeader>
        <span className={styles.textGrey}>
          {t({ id: 'event.diffusion.publications.b2c.description' })}
        </span>
        <br /><br />
        <FormItem label={t({ id: 'event.diffusion.publications.b2c.label' })}>
          {getFieldDecorator('calendarB2C', {
            initialValue: initialValues ? initialValues.calendarB2C : false,
          })(
            <RadioGroup>
              <RadioButton value ><FormattedMessage {...messages.yes} /></RadioButton>
              <RadioButton value={false}><FormattedMessage {...messages.no} /></RadioButton>
            </RadioGroup>
          )}
        </FormItem>

        {getFieldValue('calendarB2C') &&
          <div className={styles.requiredFields}>{t({ id: 'form.helper.multi-required' })}</div>
        }

        {getFieldValue('calendarB2C') &&
          <>
            <Body1 className='mb-8'>{t({ id: 'settings.descriptionB2C.label.fr' })}<span className={styles.required}> *</span></Body1>
            <FormItem>
              {getFieldDecorator('calendarB2CDescription.fr', {
                initialValue: initialValues?.calendarB2CDescription?.fr || false,
                rules: [{ required: true, message: t({ id: 'form.error.required' }) }],
              })(
                <TextArea
                  showCount
                  maxLength={300}
                  style={{ height: 120, resize: 'none' }}
                  placeholder={t({ id: 'descriptionB2C.placeholder' })}
                />
              )}
            </FormItem>
            <div className={styles.count}>
              <span>{getFieldValue('calendarB2CDescription.fr')?.length}/300</span>
            </div>
          </>
        }

        {getFieldValue('calendarB2C') &&
          <>
            <Body1 className='mb-8'>{t({ id: 'settings.descriptionB2C.label.en' })}<span className={styles.required}> *</span></Body1>
            <FormItem>
              {getFieldDecorator('calendarB2CDescription.en', {
                initialValue: initialValues?.calendarB2CDescription?.en || false,
                rules: [{ required: true, message: t({ id: 'form.error.required' }) }],
              })(
                <TextArea
                  showCount
                  maxLength={300}
                  style={{ height: 120, resize: 'none' }}
                  placeholder={t({ id: 'descriptionB2C.placeholder' })}
                />
              )}
            </FormItem>
            <div className={styles.count}>
              <span>{getFieldValue('calendarB2CDescription.en')?.length}/300</span>
            </div>
          </>
        }
        <div>
          <Button type="submit" className='mt-16'>
            {t({ id: 'save' })}
          </Button>
        </div>
      </Form>
    </Bloc>
  );
};

PublishOnBtoC.propTypes = {
  form: object,
  initialValues: object,
  onSubmit: func.isRequired,
};

export default Form.create()(PublishOnBtoC);
