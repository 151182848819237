import React, { useEffect, useState } from 'react';
import { object } from 'prop-types';
import { compose } from 'redux';
import { Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { Button, H3, Caption1, IconChevronRight } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import moment from 'moment';
import { useHistory } from 'react-router';
import { toJS } from '@/utils';
import { getCurrentParticipant } from '@/store/participant/selectors';
import Educations from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/Profile/Educations';
import {
  FooterNav
} from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/components/FooterNav/FooterNav';
import { userActions } from '@/store/user';
import Wrapper from '@/components/Wrapper';
import Search from '@/scenes/Auth/scenes/CandidateScenes/Profile/Search';
import CvCatcher from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/Profile/CvCatcher/CvCatcher';
import { story } from '@/utils/manageVisibilityStory';
import {
  BtnFinishLater
} from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/Signup/components/SignupModal/components/FormFooter/components';
import { useIsMobile } from '@/utils/MediaQueries';
import {
  trackOnboardingStart, trackPageDisplayProfile,
} from '@/utils/analytics/trackers/category/eventOnboarding';
import styles from './styles.less';
import Presentation from './Presentation';
import Cv from './Cv';
import {
  getTrackers,
} from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/Profile/trackers';

const Profile = (props) => {
  const t = useFormatMessage();
  const { event, authUser } = props;
  const [initialValues, setInitialValues] = useState(authUser);
  const history = useHistory();
  const dispatch = useDispatch();
  const participant = useSelector(getCurrentParticipant)?.toJS();
  const [isCvCatcherAnalyzing, setIsCvCatcherAnalyzing] = useState(false);
  const isWiderThanMobile = !useIsMobile();

  const trackers = getTrackers(props.form, authUser, event);

  useEffect(() => {
    if (event) {
      trackPageDisplayProfile(authUser, event);
      trackOnboardingStart(authUser, event);
    }
  }, []);

  useEffect(() => {
    trackers.presentation();
    trackers.cv();
    trackers.experience();
    trackers.search();
  }, [props.form]);


  const handleOnSubmit = (e) => {
    e.preventDefault();

    return props.form.validateFieldsAndScroll({
        scroll: { offsetTop: document.getElementById('headerWrapper').offsetHeight + 3 } },
      (err, values) => {
        if (!err) {
          const defaultValues = {
            links: [],
            mediaItems: [],
          }
          const educations = values.educations ? {
            educations: values.educations.map((education) => ({
              ...education,
              beginAt: moment(education.startYear).format('YYYY-MM-DD'),
              endAt: moment(education.endYear).format('YYYY-MM-DD'),
            }))
          } : {};

          const userParams = {
            ...defaultValues,
            ...values,
            ...educations,
            notifs: {...authUser.notifs, ...values.notifs },
          }

          dispatch(userActions.patchMe({
            userParams,
            callback: () => {
              history.push('matching');
            }
          }));
        }
      });
  }

  const onGetCvData = (data) => {
    if (data === null) {
      return setIsCvCatcherAnalyzing(false);
    }

    setInitialValues({
      ...initialValues,
      title: '',
      firstName: '',
      lastName: '',
      phone: '',
      matching: { filters: authUser.matching.filters, targets: authUser.matching.targets, level: data.level?._id },
      description: '',
      links: [],
      ...data,
      educations: data.educations?.splice(0, 2).map((education) => ({
        ...education,
        beginAt: education.startAt
      })) ?? [],
      pictureUrl: '',
      educationLevel: data.level?._id ?? '',
    });

    setIsCvCatcherAnalyzing(false);

    return props.form.validateFields();
  }

  if (isEmpty(participant)) return null;

  return (
    <Wrapper size="large">
      <div className={styles.pageTitle}>
        <H3>{participant?._user?.isActive ? t({ id: 'edit.profile' }) : t({ id: 'complete.your.profile' })}</H3>
        <Caption1 className={styles.textMandatory}>{t({ id: 'form.helper.multi-required'})}</Caption1>
      </div>

      {story.cvCatcher.isVisible &&
        <CvCatcher
          onGetCvFinished={onGetCvData}
          onClickUpload={()=> setIsCvCatcherAnalyzing(true)}
        />
      }
      {!isCvCatcherAnalyzing &&
        <Form onSubmit={handleOnSubmit}>
          <Presentation user={initialValues} {...props} />
          <Cv user={initialValues} {...props} />
          <Educations user={initialValues} {...props} />
          <Search user={initialValues} {...props} />
          <FooterNav>
            <BtnFinishLater show={!isWiderThanMobile} />
            <Button type="submit" imageComponentRight={<IconChevronRight size={16} />}>
              {t({ id: 'next' })}
            </Button>
          </FooterNav>
        </Form>
      }
    </Wrapper>
  )
}

Profile.propTypes = {
  event: object,
  form: object,
  authUser: object,
}

const withForm = Form.create()

export default compose(
  withForm,
)(toJS(Profile));
