import React from 'react';
import classNames from 'classnames';
import { string, func, bool } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { useGetSpotlight } from '@/queries/kpis/useGetSpotlight';

import List from '@/components/List';
import { Events, Exponents, Users } from '@/scenes/Admin/components/Kpi/components/InputSearchAdmin/components';


import styles from '@/scenes/Admin/components/Kpi/components/InputSearchAdmin/styles.less';



const SearchResult = ({ keywords, dropdownIsVisible, onClick }) => {
  const t = useFormatMessage();
  const spotlightQuery = useGetSpotlight({ keywords, keepPreviousData: true });

  const dropdownClass = classNames(
    styles.dropdown,
    dropdownIsVisible ? styles.visible : null
  );

  return (
    <div className={dropdownClass}>
      <div style={{ background: 'white' }}>
        {spotlightQuery?.data?.events.length > 0 && (
          <>
            <List title={t({ id: 'header.userDropdown.events' }, { count: 2 })} icon="house">
              <ul>
                <Events
                  onClick={onClick}
                  events={spotlightQuery?.data?.events}
                />
              </ul>
            </List>
            <hr/>
          </>
        )}

        {spotlightQuery?.data?.organizations.length > 0 && (
          <>
            <List title={t({ id: 'event.menu.exponents' }, { count: 2 })} icon="building">
              <ul>
                <Exponents
                  onClick={onClick}
                  value={keywords}
                  exponents={spotlightQuery?.data?.organizations}
                />
              </ul>
            </List>
            <hr/>
          </>
        )}

        <hr/>

        {spotlightQuery?.data?.users.length > 0 && (
          <List title={t({ id: 'user.pl' }, { count: 2 })} icon="user">
            <ul>
              <Users
                onClick={onClick}
                value={keywords}
                users={spotlightQuery?.data?.users}
              />
            </ul>
          </List>
        )}
      </div>
    </div>
  )
}

SearchResult.propTypes = {
  keywords: string,
  dropdownIsVisible: bool,
  onClick: func,
}

export { SearchResult }