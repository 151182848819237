import React, { useState } from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { H3, Body2, Button, IconPlusCircle } from '@seekube-tech/ui-kit';
import colors from '@seekube-tech/ui-kit/dist/colors';
import { useGetExponentOffers } from '@/queries/exponent/useGetExponentOffers';
import { useGetExponent } from '@/queries/exponent/useGetExponent';
import { OffersList } from '@/scenes/Event/scenes/Owner/scenes/Exponents/Offers/OffersList';
import Wrapper from '@/components/Wrapper';


const modalDraftOfferDefault = { isVisible: false, offer: null };

const ExponentOffers = ({ event, match: { params: { exponentId, eventSlug } } }) => {
  const t = useFormatMessage();

  const { data: exponent } = useGetExponent({ exponentId, eventSlug });
  const { data: { docs: offers } } = useGetExponentOffers({ page: 1, limit: 300, organizationId: exponent?._organization?._id, eventSlug});
  const [modalCreateOffer, setModalCreateOffer] = useState({ isVisible: false, offer: null });
  const [modalPreviewOffer, setModalPreviewOffer] = useState({ ...modalDraftOfferDefault, context: null });

  return (
    <Wrapper type="scene" context="owner">
      <div>
        {!modalPreviewOffer.isVisible && (
          <>
            <div className="flex justify-between justify-center">
              <H3>{t({ id: 'offers.of' }, { name: exponent?._organization?.name })}</H3>
              <Button onClick={() => setModalCreateOffer({ ...modalCreateOffer, isVisible: true })}
                      imageComponentLeft={<IconPlusCircle size={16}/>}>
                {t({ id: 'event.recruiter.preparation.offer.create' })}
              </Button>
            </div>

            <Body2 color={colors.neutral['400']} className="mb-24">
              {t({id: 'event.recruiter.preparation.offer.form.authorized.offers'}, {
                total: exponent?.limits?.offerMax,
                value: offers?.filter(off => off.status === 'published')?.length
              })}
            </Body2>
          </>)}
        <OffersList
          offers={offers}
          event={event}
          exponent={exponent}
          modalCreateOffer={modalCreateOffer}
          setModalCreateOffer={setModalCreateOffer}
          modalPreviewOffer={modalPreviewOffer}
          setModalPreviewOffer={setModalPreviewOffer}
        />
      </div>

    </Wrapper>
  )
}

export default ExponentOffers;
