import React from 'react';
import { array, object, string, func, number } from 'prop-types';
import { isEmpty } from 'lodash';
import { Tag } from '@seekube-tech/ui-kit';
import { injectIntl } from 'react-intl';
import moment from 'moment';

import { Col, Row } from 'antd';
import { If } from '@/components/If';
import { H1, H3 } from '@/components/Title';
import Icon from '@/components/Icon';
import Wrapper from '@/components/Wrapper';

import Separator from '@/components/Separator';
import TargetItems from '../TargetItems';
import Contacts from '../Contacts';

import styles from './styles.less';

const EventInformation = ({
  keyDates,
  description,
  targets,
  contracts,
  levels,
  positions,
  pricing,
  format,
  location,
  contacts,
  intl,
  onDisplayContact,
  countOrganizations,
  externalUrl,
}) => (
  <div className={styles.eventInformationWrapper}>
    <Wrapper>
      <Separator height={40} />
      <Row type="flex" align="top" justify="center">
        <Col span={7} className={styles.content}>
          {/* Title */}
          <H1 bold>{intl.formatMessage({ id: 'seekalendar.presentation' }, { count: countOrganizations })}</H1>
          <If condition={!isEmpty(description)}>
            <div dangerouslySetInnerHTML={{ __html: description }} className={styles.description} />
          </If>

          <Separator height={40} />

          <H1 bold>{intl.formatMessage({ id: 'seekalendar.targets' })}</H1>

          <If condition={targets}>
            {targets.map((t, index) => (
              <Tag color="grey" key={index}>{intl.formatMessage({ id: `event.target.${t}` })}</Tag>
            ))}
          </If>

          <Separator height={20} />

          <If condition={contracts}>
            <TargetItems label={intl.formatMessage({ id: 'contracts' })} items={contracts} icon="pencil" blankLabel={intl.formatMessage({ id: 'upcoming' })} />
          </If>

          <If condition={levels}>
            <TargetItems label={intl.formatMessage({ id: 'education.level' })} items={levels} icon="formation" blankLabel={intl.formatMessage({ id: 'upcoming' })} />
          </If>

          <If condition={positions}>
            <TargetItems label={intl.formatMessage({ id: 'positions' })} items={positions} icon="offers" blankLabel={intl.formatMessage({ id: 'upcoming' })} />
          </If>
        </Col>
        <Col span={5} className={styles.rightSide}>
          <H3 bold>{intl.formatMessage({ id: 'seekalendar.infos' })}</H3>

          <div className="infoItem">
            <span className="infoItemIcon"><Icon name="calendar" /></span>
            <div><strong>{intl.formatMessage({ id: 'seekalendar.limitDate' })}</strong> {keyDates && keyDates.discovering ? moment(keyDates.discovering.beginAt).subtract(1, 'day').format('LL') : intl.formatMessage({ id: 'upcoming' })}</div>
          </div>

          <If condition={format && format === 'physical'}>
            <div className="infoItem">
              <span className="infoItemIcon"><Icon name="map" /></span>
              <div><strong>{intl.formatMessage({ id: 'seekalendar.place' })}</strong> {location && location.name ? location.name : intl.formatMessage({ id: 'upcoming' })}</div>
            </div>
          </If>

          <div className="infoItem">
            <span className="infoItemIcon"><Icon name="light" /></span>
            <div><strong>{intl.formatMessage({ id: 'seekalendar.pricing' })}</strong> {pricing ? intl.formatMessage({ id: `pricing.${pricing}` }) : intl.formatMessage({ id: 'pricing.undefined' })}</div>
          </div>

          <If condition={!isEmpty(externalUrl)}>
            <div className="infoItem">
              <span className="infoItemIcon"><Icon name="link" /></span>
              <div><a href={externalUrl} target="_blank">{intl.formatMessage({ id: 'seekalendar.externalUrl' })}</a></div>
            </div>
          </If>

          <Separator height={30} />

          <H3 bold>Contacts</H3>

          <Contacts
            items={contacts}
            onDisplayContact={onDisplayContact}
          />
        </Col>
      </Row>
    </Wrapper>
  </div>
);

EventInformation.propTypes = {
  keyDates: object,
  format: string,
  externalUrl: string,
  pricing: string,
  description: string,
  targets: array,
  contacts: array,
  contracts: array,
  levels: array,
  positions: array,
  location: object,
  intl: object,
  onDisplayContact: func,
  countOrganizations: number,
};

export default injectIntl(EventInformation);
