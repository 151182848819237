import React from 'react';
import { object} from 'prop-types';
import { Clock} from 'react-feather';

import { useFormatMessage } from 'react-intl-hooks';
import { NavLink } from 'react-router-dom';

import styles from './styles.less'

const LiveBlankStateCard = ({event}) => {

  const t = useFormatMessage();

  return (
    <div className={styles.liveBlankStateCardContainer}>
      <div className={styles.leftContent}>
        <div className={styles.picturesContainer}>
          <div className={styles.pictureElement}>
            <img src={event.theme.pictureUrl} alt='livePicture' />
          </div>
        </div>
      </div>
      <div className={styles.rightContent}>
        <div className={styles.disclaiming}>
          <Clock size={16} />
          <p>{t({ id: `dashboard.realtime.blankState.disclaiming`})}</p>
        </div>
        <NavLink to={`/${event._id}/owner/conferences`}>
          <div className={styles.button}>{t({ id: `dashboard.realtime.blankState.button`})}</div>
        </NavLink>
      </div>
    </div>
  )
}


LiveBlankStateCard.propTypes = {
  event: object,
};

export default LiveBlankStateCard;