import React from 'react';
import { string, array, object, bool } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { get, isEmpty, map, size } from 'lodash';
import thisStyles
  from '@/scenes/Admin/scenes/Organizations/scenes/Organization/scenes/Jobdating/components/styles.less';
import { If } from '@/components/If';
import Avatars from '@/components/Avatars';
import RecruiterMaxColumn from '@/scenes/Event/scenes/Owner/scenes/OnBoarding/containers/Dashboard/components/RecruiterMaxColumn';
import {
  OfferMaxColumn
} from '@/scenes/Event/scenes/Owner/scenes/OnBoarding/containers/Dashboard/components/OfferMaxColumn';
import {
  LiveMaxColumn
} from '@/scenes/Event/scenes/Owner/scenes/OnBoarding/containers/Dashboard/components/LiveMaxColumn';
import { getInfinityFormatter } from '@/utils/text';
import { Tooltip } from '@/components/Tooltip';
import messages from '@/scenes/Admin/scenes/Organizations/scenes/Organization/scenes/Jobdating/components/messages';
import Icon from '@/components/Icon';
import { EventColumn } from '@/scenes/Event/scenes/Owner/scenes/OnBoarding/containers/Dashboard/components/EventColumn';
import ActionColumn from '@/scenes/Event/scenes/Owner/scenes/OnBoarding/containers/Dashboard/components/ActionColumn';

import styles from '../../../../../../../Event/scenes/Owner/scenes/OnBoarding/containers/Dashboard/styles.less';

const ColumnsUi = (byOrganization, context, isUpComingEvents = true, organization) => (
context !== 'admin' ?
    [
    {
      id: 'upcomingEvents',
      Header: <FormattedMessage id="upcoming.events" />,
      accessor: (record) => (
          <EventColumn key={record.tool.key} {...record.tool._event} _exponent={record.tool} keyMomentFormats={record.keyMomentFormats} />
        )
    },
    {
      id: 'collaborator',
      disableSortBy: true,
      Header: <FormattedMessage id="collaborater.pl" values={{ count: 2 }} />,
      className: 'text-center',
      accessor: ({ tool }) => (
        <div className={styles.collaborators}>
          <div className={styles.countCollaborators}>{size(tool.users)}</div>
          <Avatars
            className={styles.avatars}
            users={map(tool.users, ((user) => user._user))}
            maxPictures={2}
            others={size(tool.users) - (size(tool.users) < 2 ? size(tool.users) : 2)}
          />
        </div>
      )
    },
    {
      id: 'userMax',
      disableSortBy: true,
      Header: <div>
        <FormattedMessage id="users.max" />
        <Tooltip color="dark" placement="bottom" title={<FormattedMessage id="client.member.tooltip.users.max.info" />}>
          <span className={styles.info} key="footerDate"><Icon name="information" /></span></Tooltip>
      </div>,
      accessor: ({ tool, actions }) => (
        <span role="button" tabIndex={0} onClick={actions.preventExpand}>
            {getInfinityFormatter(get(tool, 'limits.recruiterMax'))}
        </span>
      ),
    },
    {
      id: 'offersMax',
      disableSortBy: true,
      Header:<div> <FormattedMessage id="offers.max" />
      <Tooltip color="dark" placement="bottom" title={<FormattedMessage {...messages.tooltipOffersMaxInfo} />} >
        <span className={styles.info} key="footerDate"><Icon name="information" /></span></Tooltip>
</div>,
      accessor: ({ tool , actions = {} }) => (
        <span role="button" tabIndex={0} onClick={actions.preventExpand}>
            {getInfinityFormatter(get(tool, 'limits.offerMax'))}
          </span>
      ),
    },
    {
      id: 'liveMax',
      disableSortBy: true,
      Header: <div><FormattedMessage id="live.max"  />
      <Tooltip color="dark" placement="bottom" title={<FormattedMessage {...messages.tooltipLivesMaxInfo} />} >
        <span className={styles.info} key="footerDate"><Icon name="information" /></span></Tooltip>
</div>,
      accessor: ({ tool }) => (
        <Tooltip placement="bottom" className={styles.w100} color="dark" title={<FormattedMessage id="client.member.tooltip.not.allow.to.publish.live" />} >
          <span style={{ display: 'flex' }}>
            {
              get(tool, '_event.modules.live.enable') ?
                getInfinityFormatter(get(tool, 'limits.liveMax'))
                : '-'
            }
          </span>
        </Tooltip>
      ),
    },
    {
      id: 'actions',
      disableSortBy: true,
      Header:  <FormattedMessage id="action.pl" values={{ count: 2 }} />,
      accessor: (record) => (
        <span>
          <ActionColumn
            context={context}
            record={record}
            handleExponentActions={record.actions.handleExponentActions}
            addCollaborator={() => record.actions.openCollaboratorModal(record.tool)}
            removeExponent={() => record.actions.removeExponent(record.tool)}
            onToolsClick={get(record, 'actions.onToolsClick')}
            patchOrganization={get(record, 'actions.patchOrganization')}
            openAvatarModal={() => record.actions.openAvatarModal(record.tool)}
            byOrganization={byOrganization}
            organization={organization}
          />
        </span>
      ),
    },
  ] : [
      {
        id: 'upcomingEvents',
        Header: isUpComingEvents ? <FormattedMessage id="upcoming.events" /> : <FormattedMessage id="upcoming.past" />,
        accessor: ({ tool }) => {
          const plannersOrgas = get(tool, '_event.planners._organizations');

          return (
            <div className={thisStyles.eventContainer}>
              <div style={{ whiteSpace: 'nowrap', fontWeight: 600, overflow: 'hidden', textOverflow: 'ellipsis' }}>
              <span className={thisStyles.eventName}>
                {get(tool, '_event.name')}
              </span>
                <If condition={!isEmpty(plannersOrgas)}>
                <span className={thisStyles.planners} title={map(plannersOrgas, (orga) => orga.name).join(', ')}>
                  <FormattedMessage id="by" /> {map(plannersOrgas, (orga) => orga.name).join(', ')}
                </span>
                </If>
              </div>
              <div
                className={thisStyles.eventJobfair}
              >{moment(get(tool, '_event.keyDates.jobfair.beginAt')).format('MMMM YYYY')} - {moment(
                get(tool, '_event.keyDates.jobfair.endAt')).format('MMMM YYYY')}</div>
            </div>
          );
        },
      },
      {
        id: 'collaborator',
        disableSortBy: true,
        Header: <FormattedMessage id="collaborater.pl" values={{ count: 2 }} />,
        className: 'text-center',
        accessor: ({ tool }) => (
          <div className={styles.collaborators}>
            <div className={styles.countCollaborators}>{size(tool.users)}</div>
            <Avatars
              className={styles.avatars}
              users={map(tool.users, ((user) => user._user))}
              maxPictures={2}
              others={size(tool.users) - (size(tool.users) < 2 ? size(tool.users) : 2)}
            />
          </div>
        )
      },
      {
        id: 'userMax',
        disableSortBy: true,
        Header: <FormattedMessage id="users.max" />,
        accessor: ({ tool, actions }) => (
          <span>
          <RecruiterMaxColumn recruiterMax={get(tool, 'limits.recruiterMax')} exponent={tool} patchExponent={actions.patchExponent} />
        </span>
        ),
      },
      {
        id: 'offersMax',
        disableSortBy: true,
        Header: <FormattedMessage id="offers.max" />,
        accessor: ({ tool, actions = {} }) => (
          <span>
          <OfferMaxColumn exponent={tool} patchExponent={actions.patchExponent} />
        </span>
        ),
      },
      {
        id: 'liveMax',
        disableSortBy: true,
        Header: <FormattedMessage id="live.max"  />,
        accessor: ({ tool, actions }) => (
          <span>
          <LiveMaxColumn disabled={!get(tool, '_event.modules.live.recruiterEnable')} exponent={tool} patchExponent={actions.patchExponent} />
        </span>
        ),
      },
      {
        id: 'actions',
        Header: <FormattedMessage id="action.pl" values={{ count: 2 }} />,
        accessor:  (record) => (
          <span>
            <ActionColumn
              context={context}
              record={record}
              handleExponentActions={record.actions.handleExponentActions}
              addCollaborator={() => record.actions.openCollaboratorModal(record.tool)}
              removeExponent={() => record.actions.removeExponent(record.tool)}
              onToolsClick={get(record, 'actions.onToolsClick')}
              patchOrganization={get(record, 'actions.patchOrganization')}
              byOrganization={byOrganization}
              organization={organization}
            />
          </span>
        ),
      },
    ]
)

ColumnsUi.propTypes = {
  tool: object,
  createdAt: string,
  _organization: object,
  users: array,
  locale: string,
  event: object,
  isHybrid: bool,
};

export default ColumnsUi;
