import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { object, string, array, func, number, bool } from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'redux';
import { replace } from 'connected-react-router';
import { createStructuredSelector } from 'reselect';
import { find } from 'lodash';
import { Select as SelectAntd } from 'antd';


// Store
import { eventActions, eventSelectors } from '@/store/event';
import { actionActions } from '@/store/action';
import { authSelectors } from '@/store/auth';
import { criterionActions, criterionSelectors } from '@/store/criterion';
import { getId } from '@/utils/global';
import { objectToParams, queryStringToObject } from '@/utils/url';

import Select from '@/components/Form/Select';
import Icon from '@/components/Icon';
import Wrapper from '@/components/Wrapper';
import Table from '@/components/Table';

import { withLayout } from '@/containers/Layout';

import { getCalendars } from './request';

import DataSource from './components/DataSource';
import Columns from './components/Columns';

import SearchEngineSeekalendar from './components/SearchEngineSeekalendar';

import styles from './styles.less';
import messages from './messages';

const { Option } = SelectAntd;

const PAGE_SIZE = 25;

const SeekalendarsScene = ({
  authUser,
  getDefaultCriteria,
  history,
  page,
  keywords,
  pricing,
  format,
  targets,
  targetContracts,
  targetLevels,
  organizationsName,
  beginAtString,
  defaultCriteria,
  archive,
  intl,
  postAction,
}) => {
  const [seekalendar, setSeekalendar] = useState([]);
  const [total, setTotal] = useState(0);
  const [countAll, setCountAll] = useState(0);
  const [facets, setFacets] = useState(({}));

  useEffect(
    () => {
      getDefaultCriteria();

      if (!authUser.isAdmin) {
        postAction({
          actionParams: {
            name: 'VISIT_SEEKALENDAR',
            _user: getId(authUser),
            _organization: getId(authUser._currentOrganization),
          },
        });
      }
    }
    , []);

  useEffect(() => {
    getCalendars({
      limit: PAGE_SIZE, page, offset: (page - 1) * PAGE_SIZE, keywords, pricing, format, targets, targetContracts, targetLevels, organizationsName, beginAtString, archive
    }).then(
      (res) => {
        setSeekalendar(res.docs);
        setTotal(res.total);
        setCountAll(res.countAll);
        setFacets(res.facets);
      }
    );
  }, [page, keywords, pricing, format, targets, targetContracts, targetLevels, organizationsName, beginAtString, archive]);

  const handleOnChangePagination = (pagination) => {
    const paramsObject = { ...queryStringToObject(history.location.search), page: pagination.current, offset: (pagination.current - 1) * PAGE_SIZE };
    history.push(`seekalendar?${objectToParams(paramsObject)}`);
  };

  const defaultPagination = { page: 1, limit: PAGE_SIZE, offset: 0 };

  const onSearchChange = ({ keywords, pricing, format, targets, targetContracts, targetLevels, organizationsName, beginAtString }) => {
    const paramsObject = { ...queryStringToObject(history.location.search), ...defaultPagination, keywords, pricing, format, targets, targetContracts, targetLevels, organizationsName, beginAtString };
    history.push(`seekalendar?${objectToParams(paramsObject)}`);
  };

  return (
    <Wrapper type="scene">
      <div className={styles.seekalendars}>
        <div className={styles.header}>
          <FormattedMessage {...messages.seekalendar} tagName="h1" />
          <div className={styles.subNavContainer}>
            <Select
              defaultValue={archive === 'true' ? 'past' : 'current'}
              className={styles.inputSelect}
              suffixIcon={<Icon name="chevron" />}
              onSelect={(val) => history.push(`seekalendar?${objectToParams({ ...queryStringToObject(history.location.search), archive: val === 'past' })}`)}
            >
              {[(
                <Option key="current" value="current">
                  <FormattedMessage {...messages.now} />
                </Option>
              ), (
                <Option key="past" value="past">
                  <FormattedMessage {...messages.past} />
                </Option>
              )]}
            </Select>
          </div>
        </div>
        <div className={styles.pageDescription}>
          <FormattedMessage {...messages.seekalendarPageDescription} tagName="p" />
        </div>
        <SearchEngineSeekalendar
          authUser={authUser}
          defaultCriteria={defaultCriteria}
          onSearch={onSearchChange}
          history={history}
          values={{ keywords, pricing, format, targets, targetContracts, targetLevels, organizationsName, beginAtString }}
          facets={facets}
        />
        <div className={styles.resultStats}>{intl.formatMessage({ id: 'seekalendar.results' }, { total, countAll })}</div>
        <div className={styles.table}>
          <Table
            size="default"
            rowClassName={styles.tableRow}
            dataSource={DataSource(seekalendar, find(defaultCriteria, (criteria) => criteria.key === 'CONTRACT') || [], intl) || []}
            columns={Columns(history)}
            onChange={handleOnChangePagination}
            pagination={{
              total,
              page,
              pageSize: PAGE_SIZE,
            }}
          />
        </div>
      </div>
    </Wrapper>
  );
};

SeekalendarsScene.propTypes = {
  authUser: object,
  getDefaultCriteria: func,
  history: object,
  page: number,
  keywords: string,
  pricing: string,
  format: string,
  targets: array,
  targetContracts: array,
  targetLevels: array,
  organizationsName: string,
  beginAtString: array,
  defaultCriteria: array,
  archive: bool,
  intl: object,
  postAction: func,
};

const mapStateToProps = createStructuredSelector({
  event: eventSelectors.getCurrentEvent,
  authUser: authSelectors.getAuthUser,
  defaultCriteria: criterionSelectors.getDefaultCriteria,
});

const mapDispatchToProps = {
  getEvent: eventActions.getEvent,
  getDefaultCriteria: criterionActions.getDefaultCriteria,
  postAction: actionActions.postAction,
  replace,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  injectIntl,
  withLayout({ size: 'full', navType: 'recruiter' }),
)(SeekalendarsScene);
