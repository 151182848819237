/**
 * Class for transform data from the form to the API
 */
export default class FormToApi {
  /**
   * Return only exponent who have a type
   *
   * @param {Array} exponents
   *
   * @returns {Array}
   */
  static exponents = (exponents) => exponents
    .reduce((acc, exponent) => {
      if (exponent.type) {
        acc.push(exponent);
      }
      return acc;
    }, []);
}
