import * as React from 'react';
import { IconArrowRight, Body2, Body1, Caption1, IconInfo } from '@seekube-tech/ui-kit';
import colors from '@seekube-tech/ui-kit/dist/colors';
import { Reports } from '@/scenes/Event/scenes/Owner/scenes/Setting/scenes/Reporting/components/EmailingSchedule/components/Report/Reports';
import {
  AddReport
} from '@/scenes/Event/scenes/Owner/scenes/Setting/scenes/Reporting/components/EmailingSchedule/components/Report/AddReport';
import { REPORT_PHASES } from '@/scenes/Event/scenes/Owner/scenes/Setting/scenes/Reporting/const';
import { useFormatMessage } from 'react-intl-hooks';
import { func, object } from 'prop-types';
import useStyles from '../../styles';
import styles from './styles.less';

function NonHybridScheduling({ reports, onUpdateReport, onDeleteReport, onCreateReport }) {
  const t = useFormatMessage()
  const classes = useStyles();

  return (<>
    <Body1 color={colors.neutral['500']}>
      {t({ id: 'owner.reporting.sending.schedule.subtitle' })}
    </Body1>
    <Caption1 color={colors.neutral['400']}>
      <span className={styles.iconInfo}><IconInfo size="11px" /></span>
      {t({ id: "owner.reporting.sending.schedule.info" })}
    </Caption1>
    <div className={classes.section}>
      <div className={classes.itemCenter}>
        <IconArrowRight className={classes.icon} />
        <Body2 color={colors.neutral['500']} fontWeight={600}>
          {t({ id: 'owner.reporting.registrationAndPreparationOfCompanies.title' })}
        </Body2>
      </div>
      <Body1 color={colors.neutral['400']}>
        {t({ id: 'owner.reporting.registrationAndPreparationOfCompanies.description' })}
      </Body1>
      <Reports
        reports={reports[REPORT_PHASES.registrationAndPreparationOfCompanies]}
        onChange={onUpdateReport}
        onRemove={onDeleteReport}
      />
    </div>

    <div className={classes.section}>
      <div className={classes.itemCenter}>
        <IconArrowRight className={classes.icon} />
        <Body2 color={colors.neutral['500']} fontWeight={600}>
          {t({ id: 'owner.reporting.registrationAndActivitiesOfCandidates.title' })}
        </Body2>
        <AddReport
          phase={REPORT_PHASES.registrationAndActivitiesOfCandidates}
          handleClick={onCreateReport}
        />
      </div>
      <Body1 color={colors.neutral['400']}>
        {t({ id: 'owner.reporting.registrationAndActivitiesOfCandidates.description' })}
      </Body1>

      <Reports
        reports={reports[REPORT_PHASES.registrationAndActivitiesOfCandidates]}
        onRemove={onDeleteReport}
        onChange={onUpdateReport}
      />
    </div>

    <div className={classes.section}>
      <div className={classes.itemCenter}>
        <IconArrowRight className={classes.icon} />
        <Body2 color={colors.neutral['500']} fontWeight={600}>
          {t({ id: 'owner.reporting.appointmentProcessing.title' })}
        </Body2>
        <AddReport
          phase={REPORT_PHASES.appointmentProcessing}
          handleClick={onCreateReport}
        />
      </div>
      <Body1 color={colors.neutral['400']}>
        {t({ id: 'owner.reporting.appointmentProcessing.description' })}
      </Body1>
      <Reports
        reports={reports[REPORT_PHASES.appointmentProcessing]}
        onRemove={onDeleteReport}
        onChange={onUpdateReport}
      />
    </div>

    <div className={classes.section}>
      <div className={classes.itemCenter}>
        <IconArrowRight className={classes.icon} />
        <Body2 color={colors.neutral['500']} fontWeight={600}>
          {t({ id: 'event.keydates.interactionsClosing.title' })}
        </Body2>
      </div>
      <Body1 color={colors.neutral['400']}>
        {t({ id: 'owner.reporting.interactionsClosing.subtitle' })}
      </Body1>
      <Reports
        reports={reports[REPORT_PHASES.closingOfAppointmentSchedules]}
        onChange={onUpdateReport}
      />
    </div>

    <div className={classes.section}>
      <div className={classes.itemCenter}>
        <IconArrowRight className={classes.icon} />
        <Body2 color={colors.neutral['500']} fontWeight={600}>
          {t({ id: 'report.pl' }, { count: 1 })}
        </Body2>
      </div>
      <Body1 color={colors.neutral['400']}>
        {t({ id: 'owner.reporting.report.subtitle' })}
      </Body1>
      <Reports
        reports={reports[REPORT_PHASES.review]}
        onRemove={onDeleteReport}
        onChange={onUpdateReport}
      />
    </div>
  </>)
}

NonHybridScheduling.propTypes = {
  reports: object,
  onUpdateReport: func,
  onDeleteReport: func,
  onCreateReport: func
}

export default NonHybridScheduling;
