import React, { PureComponent } from 'react';
import { object, func } from 'prop-types';
import { replace } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Switch, Route, withRouter } from 'react-router-dom';
import { toJS } from '@/utils/index';


// Scenes
import DashboardPage from './scenes/Dashboard';
import OnboardingPage from './scenes/Onboarding';
import CandidateProfilePage from './scenes/CandidateProfile';
import SearchEngineExponentPage from './scenes/SearchEngineExponent';
import SearchEngineParticipantPage from './scenes/SearchEngineParticipant';
import OfferPage from './scenes/Offer';
import PreviewPage from './scenes/Preview';

class OwnerSettingsScene extends PureComponent {
  static propTypes = {
    match: object,
    replace: func,
  };

  componentDidMount() {
    const { props: { match, replace } } = this;

    if (match.isExact) {
      replace(`${match.url}/dashboard`);
    }
  }


  render() {
    const { props: { match } } = this;

    return (
      <Switch>
        <Route path={`${match.path}/dashboard`} component={DashboardPage} />
        <Route path={`${match.path}/onboarding`} component={OnboardingPage} />
        <Route path={`${match.path}/candidate-profile`} component={CandidateProfilePage} />
        <Route path={`${match.path}/search-participant`} component={SearchEngineParticipantPage} />
        <Route path={`${match.path}/search-exponent`} component={SearchEngineExponentPage} />
        <Route path={`${match.path}/offer`} component={OfferPage} />
        <Route path={`${match.path}/preview/:module`} render={(props) => <PreviewPage {...props} match={match} />} />
      </Switch>
    );
  }
}

const mapDispatchToProps = {
  replace,
};

const withConnect = connect(null, mapDispatchToProps);

export default compose(
  withRouter,
  withConnect,
)(toJS(OwnerSettingsScene));
