import styles from '@/scenes/Event/components/StaticOffers/components/Filters/styles.less';
import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { Form, Input } from 'antd';
import { IconSearch, IconSettings2 } from '@seekube-tech/ui-kit';

const FormItem = Form.Item;

export const SearchBar = ({
  updateSearch,
  onIconClick,
  keywords,
}) => {
  const t = useFormatMessage();

  return (
    <FormItem className={styles.formSearchItem}>
      <Input
        className={styles.searchInput}
        placeholder={t({ id: 'search' })}
        value={keywords}
        prefix={<IconSearch size={20} />}
        onChange={(e) => updateSearch('keywords', e.target.value)}
        onPressEnter={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      />
      <button onClick={onIconClick} className="openFiltersIcon show-mobile">
        <IconSettings2 size={20} />
      </button>
    </FormItem>
  );
}
