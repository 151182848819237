import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { IconCompany, IconStar, IconVideo, IconUser, IconCalendar, IconCalendarValid, IconCalendarRemove, IconMessageCircle2 } from '@seekube-tech/ui-kit';
import { REPORT_PHASES } from '@/scenes/Event/scenes/Owner/scenes/Setting/scenes/Reporting/const';

export const getDataSource = (phase, snapshot, event) => {

  switch (phase) {
    case REPORT_PHASES.registrationAndPreparationOfCompanies: return getRegistrationAndPreparation(snapshot, event);
    case REPORT_PHASES.registrationAndActivitiesOfCandidates: return getRegistrationAndActivitiesOfCandidates(snapshot, event);
    case REPORT_PHASES.appointmentProcessing: return getAppointmentProcessing(snapshot, event);
    case REPORT_PHASES.closingOfAppointmentSchedules: return getClosingOfAppointmentSchedules(snapshot, event);
    case REPORT_PHASES.review: return getReview(snapshot, event);

    default: return []
  }
}


const getRegistrationAndPreparation = (snapshot, event) => {
  const t = useFormatMessage();

  return (
    [
      {
        key: 1,
        name: t({ id: 'stats.exponentsRegistered' }),
        total: snapshot.countRegisteredOrganization,
        goal: snapshot.countGoalRegisteredCompanies,
      },
      {
        key: 2,
        name: t({ id: 'stats.exponentsReady' }),
        total: snapshot.countReadyOrganizations,
      },
      {
        key: 3,
        name: t({ id: 'stats.recruitersReady' }),
        total: snapshot.countReadyRecruiters,
      },
      {
        key: 4,
        name: t({ id: 'offer.publish.pl' }, { count: 2}),
        total: snapshot.countPublishedOffers,
      },
      {
        key: 4,
        name: t({ id: 'number.interview.slots' }),
        total: snapshot.countInterviewSlots,
      },

      {
        key: 5,
        isVisible: event.modules?.informal1to1?.enable,
        name: t({ id: 'number.slots.informal1to1' }),
        total: snapshot.countInformal1to1Slot,
      },

      {
        key: 6,
        isVisible: event.modules?.live?.adminEnable,
        name: t({ id: 'conferences.added' }),
        total: snapshot.countAddedConferences,
      },
    ].filter(blocItem => !(blocItem.isVisible === false))
  )
}

const getRegistrationAndActivitiesOfCandidates = (snapshot, event) => {
  const t = useFormatMessage();

  return (
    [
      {
        key: 1,
        name: t({ id: 'candidate.registered.pl' }, { count: 1 }),
        total: snapshot.countRegisteredCandidates,
        goal: snapshot.countGoalTotalParticipants,
      },
      {
        key: 2,
        name: t({ id: 'candidates.with.accepted.interviews' }),
        total: snapshot.countCandidatesWithInterviewsAccepted,
      },
      {
        key: 3,
        isVisible: event.modules.live.adminEnable,
        name: t({ id: 'candidates.registered.conferences' }),
        total: snapshot.countCandidatesRegisteredConferences,
      },
      {
        key: 4,
        isVisible: event.modules?.informal1to1?.enable,
        name: t({ id: 'candidates.with.informal1to1' }),
        total: snapshot.countCandidateWithInformal1to1,
      },
    ].filter(blocItem => !(blocItem.isVisible === false))
  )
}

const getAppointmentProcessing = (snapshot) => {
  const t = useFormatMessage();

  return (
    [
      {
        key: 1,
        name: t({ id: 'event.accepted.appointments' }),
        total: snapshot.countAcceptedAppointments,
      },
      {
        key: 2,
        name: t({ id: 'appointments.from.sourcing' }),
        total: snapshot.countAcceptedAppointmentsFromSourcing,
      },
      {
        key: 3,
        name: t({ id: 'appointments.from.applications' }),
        total: snapshot.countAcceptedAppointmentsFromApplication,
      },
      {
        key: 5,
        name: t({ id: 'appointments.unconfirmed.recruiters' }),
        total: snapshot.countUnconfirmedAppointments,
      },
      {
        key: 6,
        name: t({ id: 'appointments.refused.by.recruiters' }),
        total: snapshot.countRefusedAppointmentsByRecruiter,
      },
      {
        key: 7,
        name: t({ id: 'appointments.refused.by.candidates' }),
        total: snapshot.countRefusedAppointmentsByCandidate,
      },
    ].filter(blocItem => !(blocItem.isVisible === false))
  )
}

const getClosingOfAppointmentSchedules = (snapshot, event) => {
  const t = useFormatMessage();

  return (
    [
      [ // Bloc 1: Préparation des entreprises
        {
          key: 1,
          icon: <IconCompany className="text-neutral-300" size={16} />,
          name: t({ id: 'stats.report.compare.exponentReady' }, {
            countTotal: snapshot.countOrganizations,
            countTotalHtml: <strong>{snapshot.countOrganizations}</strong>,
            countReadyHtml: <strong>{snapshot.countReadyOrganizations}</strong>,
            countReady: snapshot.countReadyOrganizations,
            percentageHtml: <strong>{`${snapshot.countPercentReadyOrganizations}%`}</strong>}),
        },
        { // Nombre d’offres publiées par les entreprises
          key: 2,
          icon: <IconStar className="text-neutral-300" size={16} />,
          name: t({ id: 'stats.report.countPublishedOffers' }, {
            countHtml: <strong>{snapshot.countPublishedOffers}</strong>,
            count: snapshot.countPublishedOffers,
          }),
        },
        { // Nombre de recruteurs & Nombre de créneaux entretiens créés
          key: 3,
          icon: <IconStar className="text-neutral-300" size={16} />,
          name: t({ id: 'stats.report.countReadyRecruiters.and.countInterviewSlots' }, {
            countReadyRecruiters: snapshot.countRecruitersWithInterviewSlots,
            countReadyRecruitersHtml: <strong>{snapshot.countRecruitersWithInterviewSlots}</strong>,
            countInterviewSlotsHtml: <strong>{snapshot.countInterviewSlots}</strong>,
            countInterviewSlots : snapshot.countInterviewSlots,
          }),
        },
        { // Nombre de recruteurs + Nombre de créneaux 1/1 Chats créés (si fonctionnalité activée)
          key: 4,
          isVisible: event.modules?.informal1to1?.enable,
          icon: <IconMessageCircle2 className="text-neutral-300" size={16} />,
          name: t({ id: 'stats.report.countReadyRecruiters.and.countInformal1to1Slot' }, {
            countReadyRecruiters: snapshot.countRecruitersWithInformal1to1Slot,
            countReadyRecruitersHtml: <strong>{snapshot.countRecruitersWithInformal1to1Slot}</strong>,
            countInformal1to1SlotHtml: <strong>{snapshot.countInformal1to1Slot}</strong>,
            countInformal1to1Slot: snapshot.countInformal1to1Slot }),
        },
        { // Nombre de Lives créés + Nombre entreprises qui ont créés des Lives
          key: 5,
          isVisible: event.modules.live.adminEnable,
          icon: <IconVideo className="text-neutral-300" size={16} />,
          name: t({ id: 'stats.report.countAddedConferences.and.countOrganizationsWithConferences' }, {
            countAddedConferences: snapshot.countAddedConferences,
            countAddedConferencesHtml: <strong>{snapshot.countAddedConferences}</strong>,
            countOrganizationsWithConferencesHtml: <strong>{snapshot.countOrganizationsWithConferences}</strong>,
            countOrganizationsWithConferences: snapshot.countOrganizationsWithConferences,
          }),
        },
      ].filter(blocItem => !(blocItem.isVisible === false)),
      [ // Bloc 2: Inscription candidats
        {
          key: 1,
          icon: <IconUser className="text-neutral-300" size={16} />,
          name: t({ id: 'stats.report.countRegisteredCandidates' }, {
            count: snapshot.countRegisteredCandidates,
            countHtml: <strong>{snapshot.countRegisteredCandidates}</strong>,
          }),
        },
        { // Nombre de candidats qui ont postulés à au moins 1 offre avec % sur le total des inscrits
          key: 2,
          icon: <IconStar className="text-neutral-300" size={16} />,
          name: t({ id: 'report.snapshot.countCandidatesWithOffersHtml.with.percentage' }, {
            countCandidatesWithOffers: snapshot.countCandidatesWithOffers,
            countCandidatesWithOffersHtml: <strong>{snapshot.countCandidatesWithOffers}</strong>,
            percentageHtml: <strong>{`${snapshot.countPercentCandidatesWithOffers}%`}</strong>
          }),
        },
        { // Total de participations aux Lives
          key: 3,
          isVisible: event.modules.live.adminEnable,
          icon: <IconVideo className="text-neutral-300" size={16} />,
          name: t({ id: 'report.snapshot.countCandidatesRegisteredConferences' }, {
            count: snapshot.countCandidatesRegisteredConferences,
            countHtml: <strong>{snapshot.countCandidatesRegisteredConferences}</strong>,
          })
        },
        { // Nbre de candidats avec des créneaux 1/1 Chats réservés (si fonctionnalité activée)
          key: 4,
          isVisible: event.modules?.informal1to1?.enable,
          icon: <IconMessageCircle2 className="text-neutral-300" size={16} />,
          name: t({ id: 'report.snapshot.countCandidateWithInformal1to1' }, {
            count: snapshot.countCandidateWithInformal1to1,
            countHtml: <strong>{snapshot.countCandidateWithInformal1to1}</strong>,
          }),
        },
      ].filter(blocItem => !(blocItem.isVisible === false)),
      [ // Bloc 3: Rendez-vous
        { // Total de RDVs validés + Total entretiens + Total 1/1 chats (si fonctionnalité activée)
          key: 1,
          isVisible: event.modules?.informal1to1?.enable,
          icon: <IconCalendar className="text-neutral-300" size={16} />,
          name: t({ id: 'report.snapshot.countAcceptedAppointments.with.details' }, {
            countAcceptedAppointments: snapshot.countAcceptedAppointments,
            countAcceptedAppointmentsHtml: <strong>{snapshot.countAcceptedAppointments}</strong>,
            countAcceptedAppointmentsFromInterview: snapshot.countAcceptedAppointmentsFromInterview,
            countAcceptedAppointmentsFromInterviewHtml: <strong>{snapshot.countAcceptedAppointmentsFromInterview}</strong>,
            countInformal1to1Appointments: snapshot.countAcceptedAppointmentsFromInformal1to1,
            countInformal1to1AppointmentsHtml: <strong>{snapshot.countAcceptedAppointmentsFromInformal1to1}</strong>,
            percentageHtml: <strong>{`${snapshot.countPercentCandidatesWithOffers}%`}</strong>
          }),
        },
        { // Nombre de candidats ayant au moins 1 RDV validé (avec % sur le total des inscrits) + Moyenne de RDV par candidats
          key: 2,
          icon: <IconCalendarValid className="text-neutral-300" size={16} />,
          name: t({ id: 'report.snapshot.countCandidatesWithAcceptedAppointments.with.details' }, {
            countCandidatesWithAcceptedAppointments: snapshot.countCandidatesWithAcceptedAppointments,
            countCandidatesWithAcceptedAppointmentsHtml: <strong>{snapshot.countCandidatesWithAcceptedAppointments}</strong>,
            countAverageParticipantsAppointmentsHtml: <strong>{snapshot.countAverageParticipantsAppointments}</strong>,
            countPercentCandidatesWithAcceptedAppointmentsHtml: <strong>{`${snapshot.countPercentCandidatesWithAcceptedAppointments}%`}</strong>
          }),
        },
        { // Nombre d’entreprises ayant au moins 1 RDV validé (avec % sur le total des inscrits) + Moyenne de RDV par entreprises
          key: 3,
          icon: <IconCalendarValid className="text-neutral-300" size={16} />,
          name: t({ id: 'report.snapshot.countOrganizationsWithAcceptedAppointments.with.details' }, {
            countOrganizationsWithAcceptedAppointments: snapshot.countOrganizationsWithAcceptedAppointments,
            countOrganizationsWithAcceptedAppointmentsHtml: <strong>{snapshot.countOrganizationsWithAcceptedAppointments}</strong>,
            countAverageOrganizationsAppointmentsHtml: <strong>{snapshot.countAverageOrganizationsAppointments}</strong>,
            countPercentOrganizationsWithAcceptedAppointmentsHtml: <strong>{`${snapshot.countPercentOrganizationsWithAcceptedAppointments}%`}</strong>
          })
        },
        { // Nombre de RDVs demandés par les candidats + Nombre de RDVs issus du sourcing
          key: 4,
          icon: <IconCalendar className="text-neutral-300" size={16} />,
          name: t({ id: 'report.snapshot.countApplicationAppointments.with.details' }, {
            countApplicationAppointments: snapshot.countAcceptedAppointmentsFromApplication,
            countApplicationAppointmentsHtml: <strong>{snapshot.countAcceptedAppointmentsFromApplication}</strong>,
            countSourcingAppointments: snapshot.countAcceptedAppointmentsFromSourcing,
            countSourcingAppointmentsHtml: <strong>{snapshot.countAcceptedAppointmentsFromSourcing}</strong>,
          }),
        },
      ].filter(blocItem => !(blocItem.isVisible === false))
    ]
  )
}

const getReview = (snapshot, event) => {
  const t = useFormatMessage();
  const [bloc1, bloc2, bloc3] = getClosingOfAppointmentSchedules(snapshot, event)

  return (
    [
      bloc1,
      bloc2,
      [
        ...bloc3,
        { // Nombre de RDVs annulés par les recruteurs + Nombre de RDVs annulés par les candidats
          key: 5,
          icon: <IconCalendarRemove className="text-neutral-300" size={16} />,
          name: t({ id: 'report.snapshot.countCanceledAppointmentsByCandidate.and.countCanceledAppointmentsByRecruiter' }, {
            countCanceledAppointmentsByCandidate: snapshot.countCanceledAppointmentsByCandidate,
            countCanceledAppointmentsByCandidateHtml: <strong>{snapshot.countCanceledAppointmentsByCandidate}</strong>,
            countCanceledAppointmentsByRecruiter: snapshot.countCanceledAppointmentsByRecruiter,
            countCanceledAppointmentsByRecruiterHtml: <strong>{snapshot.countCanceledAppointmentsByRecruiter}</strong>,
          }),
        }]
    ]
  )}
