import { useMutation, useQueryClient } from 'react-query';
import request from '@/utils/request';

export function useCreateOffer({ organizationId, eventSlug, onSuccess = () => {}, }) {
  const queryClient = useQueryClient();

  const mutationFn = ({ offerValues }) => {
    const requestUrl = `${process.env.FRONT_API_URL}/events/${eventSlug}/offers`;

    return (
      request(requestUrl, {
        method: 'POST',
        body: JSON.stringify({ ...offerValues, _organization: organizationId }),
      })
    )
  }

  return useMutation({
    mutationFn,
    onSuccess: async (offer) => {
      queryClient.setQueryData(['exponentOffers', organizationId], (old) => ({...old, docs: [offer, ...old.docs ]}) );onSuccess();
    },
    refetchOnWindowFocus: false,
  });
}
