import React from 'react';
import { injectIntl } from 'react-intl';
import { node, number, object } from 'prop-types';
import { isEmpty } from 'lodash';

// Components
import { Input } from 'antd';
import Icon from '@/components/Icon';

// Styles & Translations
import styles from './styles.less';
import messages from '../../../../messages';

const filterList = (children, searchText) => {
  const li = children.props.children;

  li.forEach((item) => {
    const el = document.getElementById(item.props.id);

    const label = item.props.children || item.props.label;
    const span = label && label.props && Array.isArray(label.props.children) ? label.props.children.find((child) => child.type === 'span') : null;

    if (el) {
      let labelText = el.innerText && el.innerText.toLowerCase().trim();

      if (!labelText) {
        labelText = span === null && label ? label.toLowerCase() : typeof span.props.children === 'string' ? span.props.children.toLowerCase() : span.props.children.join().toLowerCase();
      }

      if (!isEmpty(searchText) && labelText.indexOf(searchText.toLowerCase()) === -1) {
        el.style.display = 'none';
      } else {
        el.style.display = 'block';
      }
    }
  });
};

class SearchableList extends React.PureComponent {
  static propTypes = {
    children: node,
    limit: number,
    intl: object,
  };

  static defaultProps = {
    children: node,
    limit: number,
  };

  state = {
    searchText: null,
  };

  static getDerivedStateFromProps({ children, limit }, state) {
    const { searchText } = state;

    if (!isEmpty(searchText) && children && children.type === 'ul' && children.props.children.length > limit) {
      filterList(children, searchText);
    }
  }

  onSearch = (e) => {
    const searchText = e.currentTarget.value;

    this.setState({ searchText });
    filterList(this.props.children.props.children, searchText);
  };

  clearSearch = () => {
    this.setState({ searchText: null });
    filterList(this.props.children.props.children, null);
  };

  render() {
    const {
      props: { children, limit, intl },
      state: { searchText },
      onSearch, clearSearch,
    } = this;

    if (children && children.type === 'ul' && children.props.children.length > limit) {
      return (
        <div className={styles.searchableWrapper}>
          <Input
            type="text"
            value={searchText}
            onChange={onSearch}
            prefix={<Icon name="search" />}
            suffix={searchText !== null ? (<span role="button" tabIndex={0} onClick={clearSearch}><Icon name="close" /></span>) : null}
            placeholder={intl.formatMessage(messages.search)}
          />
          <div className={styles.searchableList} ref={(node) => { this.mainNode = node; }}>
            {children}
          </div>
        </div>
      );
    }

    return (
      <div>
        {children}
      </div>
    );
  }
}

export default injectIntl(SearchableList);
