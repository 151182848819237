import { APPOINTMENT_STATUS, EVENT_FORMAT } from "@/utils/constants";
import { isEmpty } from "lodash";
import { PHASES, PHASE_STATE } from "../../utils/const";
import {
  getEventPhases,
  isAdmin,
  isApplicationClosed,
  isEventClosed,
  isInteractionClosed,
  isJobfairPast,
  isRoleCandidate,
  isRoleOwner,
  isRoleRecruiter
} from "../../utils/event";


import {
  isParticipantFormatPhysical,
  isParticipantFormatVirtual,
} from "../../utils/user";

function isDisabled({
  event = {},
  user,
  participant,
  role,
  exponentKeyMomentFormats = [],
}) {
  const eventPhases = getEventPhases(event);
  if (event.isAuthUserBanned) {
    return true;
  }

  if (isRoleOwner(role) || isRoleRecruiter(role) || isAdmin(user)) {
    return true;
  }

  if (isRoleCandidate(role)) {
    // Candidate who particpate to both format
    if (isParticipantFormatPhysical(participant) && isParticipantFormatVirtual(participant)) {
      // With an offer with both formats
      if (exponentKeyMomentFormats.length > 1) {
        if ((isApplicationClosed(eventPhases, EVENT_FORMAT.PHYSICAL) || isInteractionClosed(eventPhases, EVENT_FORMAT.PHYSICAL)) && (isApplicationClosed(eventPhases, EVENT_FORMAT.VIRTUAL) || isInteractionClosed(eventPhases, EVENT_FORMAT.VIRTUAL))) {
          return true;
        }

        if ((isApplicationClosed(eventPhases, EVENT_FORMAT.PHYSICAL) || isInteractionClosed(eventPhases, EVENT_FORMAT.PHYSICAL)) && (!eventPhases.applicationsClosing.find(e => e.format === EVENT_FORMAT.VIRTUAL) && !eventPhases.interactionsClosing.find(e => e.format === EVENT_FORMAT.VIRTUAL) && isJobfairPast(eventPhases, EVENT_FORMAT.VIRTUAL))) {
          return true;
        }

        if ((isApplicationClosed(eventPhases, EVENT_FORMAT.VIRTUAL) || isInteractionClosed(eventPhases, EVENT_FORMAT.VIRTUAL)) && (!eventPhases.applicationsClosing.find(e => e.format === EVENT_FORMAT.PHYSICAL) && !eventPhases.interactionsClosing.find(e => e.format === EVENT_FORMAT.PHYSICAL) && isJobfairPast(eventPhases, EVENT_FORMAT.PHYSICAL))) {
          return true;
        }

        return false;
      }
      // With an offer only physical
      if (exponentKeyMomentFormats[0] === EVENT_FORMAT.PHYSICAL) {
        if (isApplicationClosed(eventPhases, EVENT_FORMAT.PHYSICAL) || isInteractionClosed(eventPhases, EVENT_FORMAT.PHYSICAL)) {
          return true
        }

        if (!eventPhases.applicationsClosing.find(e => e.format === EVENT_FORMAT.PHYSICAL) && !eventPhases.interactionsClosing.find(e => e.format === EVENT_FORMAT.PHYSICAL) && isJobfairPast(eventPhases, EVENT_FORMAT.PHYSICAL)) {
          return true;
        }

        return false;
      }
      // With an offer only virtual
      if (exponentKeyMomentFormats[0] === EVENT_FORMAT.VIRTUAL) {
        if (isApplicationClosed(eventPhases, EVENT_FORMAT.VIRTUAL) || isInteractionClosed(eventPhases, EVENT_FORMAT.VIRTUAL)) {
          return true
        }

        if (!eventPhases.applicationsClosing.find(e => e.format === EVENT_FORMAT.VIRTUAL) && !eventPhases.interactionsClosing.find(e => e.format === EVENT_FORMAT.VIRTUAL) && isJobfairPast(eventPhases, EVENT_FORMAT.VIRTUAL)) {
          return true;
        }
        return false;
      }
      return false;
    }
    // Candidate who particpate to physical format only
    if (isParticipantFormatPhysical(participant)) {
      if (isApplicationClosed(eventPhases, EVENT_FORMAT.PHYSICAL) || isInteractionClosed(eventPhases, EVENT_FORMAT.PHYSICAL)) {
        return true
      }

      if (!eventPhases.applicationsClosing.find(e => e.format === EVENT_FORMAT.PHYSICAL) && !eventPhases.interactionsClosing.find(e => e.format === EVENT_FORMAT.PHYSICAL) && isJobfairPast(eventPhases, EVENT_FORMAT.PHYSICAL)) {
        return true;
      }

      return false;
    }
    // Candidate who particpate to virtual format only
    if (isParticipantFormatVirtual(participant)) {
      if (isApplicationClosed(eventPhases, EVENT_FORMAT.VIRTUAL) || isInteractionClosed(eventPhases, EVENT_FORMAT.VIRTUAL)) {
        return true
      }

      if (!eventPhases.applicationsClosing.find(e => e.format === EVENT_FORMAT.VIRTUAL) && !eventPhases.interactionsClosing.find(e => e.format === EVENT_FORMAT.VIRTUAL) && isJobfairPast(eventPhases, EVENT_FORMAT.VIRTUAL)) {
        return true;
      }
      return false;
    }
    // For event non hybrid
    if (isApplicationClosed(eventPhases) || isInteractionClosed(eventPhases)) {
      return true;
    }
    if (isEventClosed(eventPhases)) {
      return true;
    }
  }

  return false;
}

function getStatus({
  event = {},
  participant,
  role,
  interaction,
  appointment,
  currentOffer,
  interactions,
  applicationLimits
}) {

  if (appointment?.canceledBy === 'recruiter') {
    return APPOINTMENT_STATUS.REFUSED
  }

  // Appointment accepted
  if (!isEmpty(interaction) && !isEmpty(appointment)) {
    if (appointment.status === APPOINTMENT_STATUS.ACCEPTED || appointment.status === APPOINTMENT_STATUS.UNCONFIRMED) {
      return APPOINTMENT_STATUS.ACCEPTED;
    }
  }

  // waiting list rdv auto
  if (event.skipAppointmentValidation) {
    if (participant?.recruitersStatus?.[interaction?._recruiter._id] === 'applied') {
      return APPOINTMENT_STATUS.WAITING_LIST;
    }
  }

  // Check the phases
  if (isDisabled({event, participant, role, exponentKeyMomentFormats: currentOffer?.keyMomentFormats})) {
    return APPOINTMENT_STATUS.DISABLED
  }

  // If an activity already exists with this recruiter
  const isAlreadyInteraction = interactions ? Boolean(interactions.find(
    (int) => int.enable && int._appointment && int._recruiter?.id === currentOffer?._user?.id && !interaction
  )) : false;

  if (isAlreadyInteraction) {
    return APPOINTMENT_STATUS.ALREADY_INTERACTION;
  }

  // Check the interaction
  if (event.skipAppointmentValidation) {
    if (!isEmpty(interaction) && !isEmpty(appointment)) {

      if (participant.recruitersStatus?.[interaction._recruiter._id] === 'applied' ) {
        return APPOINTMENT_STATUS.WAITING_LIST;
      }
      if (interaction.type === 'application-auto' && isEmpty(appointment._timeslot)) {
        if (getCountSlotsAvailables({event, participant, offer: currentOffer}) === 0) {
          if (applicationLimits && applicationLimits.enableLimitApplications && applicationLimits.remainingApplications === 0) {
            return APPOINTMENT_STATUS.DISABLED;
          }
          return APPOINTMENT_STATUS.NO_SLOT;
        }
        return APPOINTMENT_STATUS.SKIP;
      }

      if (interaction._appointment.status === APPOINTMENT_STATUS.REFUSED) {
        // candidature annulé par le candidat
        if (!isEmpty(interaction.messageRefused)) {
          return APPOINTMENT_STATUS.REFUSED;
        }
      }

      if (interaction._appointment.status === APPOINTMENT_STATUS.PENDING) {
        return APPOINTMENT_STATUS.PENDING;
      }
    }

    if (getCountSlotsAvailables({event, participant, offer: currentOffer}) > 0) {
      if (applicationLimits?.enableLimitApplications && applicationLimits.remainingApplications === 0) {
        return APPOINTMENT_STATUS.DISABLED;
      }
      if (interaction && interaction.type === 'application' && interaction.pending) {
        return APPOINTMENT_STATUS.WAITING_LIST;
      }
      return APPOINTMENT_STATUS.SKIP;
    }

    if (interaction && interaction.type === 'application') {
      return APPOINTMENT_STATUS.WAITING_LIST;
    }

    if (applicationLimits?.enableLimitApplications && applicationLimits.remainingApplications === 0) {
      return APPOINTMENT_STATUS.DISABLED;
    }
    return APPOINTMENT_STATUS.NO_SLOT;

  }
  if (!isEmpty(interaction)) {
    if (!appointment) {
      return APPOINTMENT_STATUS.PENDING;
    }
    if (isEmpty(appointment._timeslot)) {
      return APPOINTMENT_STATUS.NO_DATE;
    }
    if (applicationLimits?.enableLimitApplications && applicationLimits.remainingApplications === 0) {
      return APPOINTMENT_STATUS.DISABLED;
    }

    return APPOINTMENT_STATUS.ACCEPTED;
  }

  if (applicationLimits?.enableLimitApplications && applicationLimits.remainingApplications === 0) {
    return APPOINTMENT_STATUS.DISABLED;
  }
}



function getCountSlotsAvailables({
  event = {},
  participant,
  offer
}) {
  const eventPhases = getEventPhases(event);

  if (event.format === EVENT_FORMAT.HYBRID) {
    if (isParticipantFormatPhysical(participant) && isParticipantFormatVirtual(participant)) {

      if (isApplicationClosed(eventPhases, EVENT_FORMAT.PHYSICAL) || isInteractionClosed(eventPhases, EVENT_FORMAT.PHYSICAL)) {
        return offer.countVirtualSlotsAvailable;
      }
      if (!eventPhases.applicationsClosing.find(e => e.format === EVENT_FORMAT.PHYSICAL) && !eventPhases.interactionsClosing.find(e => e.format === EVENT_FORMAT.PHYSICAL) && isJobfairPast(eventPhases, EVENT_FORMAT.PHYSICAL)) {
        return offer.countVirtualSlotsAvailable;
      }
      if (!eventPhases.applicationsClosing.find(e => e.format === EVENT_FORMAT.VIRTUAL) && !eventPhases.interactionsClosing.find(e => e.format === EVENT_FORMAT.VIRTUAL) && isJobfairPast(eventPhases, EVENT_FORMAT.VIRTUAL)) {
        return offer.countPhysicalSlotsAvailable;
      }
      if (isApplicationClosed(eventPhases, EVENT_FORMAT.VIRTUAL) || isInteractionClosed(eventPhases, EVENT_FORMAT.VIRTUAL)) {
        return offer.countPhysicalSlotsAvailable;
      }
      return offer.countSlotsAvailable;
    }
    if (isParticipantFormatPhysical(participant)) {
      return offer.countPhysicalSlotsAvailable;
    }
    if (isParticipantFormatVirtual(participant)) {
      return offer.countVirtualSlotsAvailable;
    }
  }
  return offer?.countSlotsAvailable;
}

/**
 * Do not forget to specify strict function params (instead of destructuring) to avoid issues when calling
 */
export default {
  isAvailable: [
    [
      {
        phase: PHASES.ALL,
        state: PHASE_STATE.ALL
      },
    ],
  ],
  generateActions: ({ authUser }) => {
    const user = authUser?.toJS();

    return {
      isDisabled: ({ event, role, participant, exponentKeyMomentFormats }) => isDisabled({ event, user, role, participant, exponentKeyMomentFormats }),
      getStatus: ({ event, role, participant, interaction, interactions, appointment, currentOffer, applicationLimits }) => getStatus({ event, role, participant, interaction, interactions, appointment, currentOffer, applicationLimits }),
      getCountSlotsAvailables: ({event, participant, offer}) => getCountSlotsAvailables({event, participant, offer}),
    }
  }
}
