import React, { PureComponent } from 'react';
import { object, func, number } from 'prop-types';
import { debounce } from 'lodash';
import { injectIntl } from 'react-intl';
import { InputNumber } from '@/components/Input';

class RecruiterMaxColumn extends PureComponent {
  state = {
    val: this.props.recruiterMax,
  };

  onSave = debounce((val) => {
    const {
      props: {
        patchExponent,
        exponent: { _event, _id, limits } },
    } = this;


    patchExponent({
      eventId: _event._id,
      exponentId: _id,
      exponentParams: {
        limits: {
          ...limits,
          recruiterMax: val < 0 ? 1000 : val,
        },
      },
      notificationParams: {
        success: {
          message: this.props.intl.formatMessage({ id: 'toaster.edit.success' }),
          kind: 'success',
          style: {
            bottom: '5%',
            top: 'inherit',
          },
        }
      }
    });
  }, 600);

  onNumberChange = (val) => {
    this.setState({ val });
    this.onSave(val);
  };

  getSnapshotBeforeUpdate(prevProps) {
    if (prevProps.recruiterMax !== this.props.recruiterMax) {
      this.setState({ val: this.props.recruiterMax });
    }
  }

  render() {
    const {
      props: { exponent: { _id } },
      onNumberChange,
    } = this;

    return (
      <div id={`recruiterMax${_id}`}>
        <InputNumber onChange={onNumberChange} formatter={(val) => val < 0 || val > 999 ? '∞' : val} width={50} min={-1} value={this.state.val} customSize="small" />
      </div>
    );
  }
}

RecruiterMaxColumn.propTypes = {
  exponent: object,
  recruiterMax: number,
  patchExponent: func,
  intl: object,
};

RecruiterMaxColumn.defaultProps = {
  exponent: {},
};

export default injectIntl(RecruiterMaxColumn);
