import { createJobfairEventsSlots } from './createJobfairEventsSlots';
import { createTimeSlots } from './createTimeSlots';

/**
 * CreateCalendarEvents
 *
 * @description
 * Convert all events in calendar format
 *
 * @param {Array} timeslotsGrouped
 */
export const createAllCalendarEvents = ({
  slots = [],
  jobfairs = [],
  currentEvent,
  isSaved = false,
  exponentFormat,
  isEventHybrid,
}) => {
  const calendarEvents = createTimeSlots({
    slots,
    currentEvent,
    isSaved,
    jobfairs,
  });

  const events = createJobfairEventsSlots(jobfairs, isEventHybrid, exponentFormat);

  return [...events, ...calendarEvents];
};
