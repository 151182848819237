import { isEmpty } from 'lodash';
import moment from 'moment';
import { getFarthestKeyMoment } from '@/utils/keyMoments';

export function getFarthestKeyMomentFromFormats(event, momentType = 'jobfair', formats = []) {
  if (isEmpty(formats)) {
    return getFarthestKeyMoment(event, momentType);
  }

  const farthestKeyMoments = formats.map(format => getFarthestKeyMoment(event, momentType, format))

  return moment.max(farthestKeyMoments)
}

