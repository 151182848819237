import React from 'react';
import { Body2 } from '@seekube-tech/ui-kit';
import { string, any } from 'prop-types';

export function Stat ({ icon = null, title= '', children = null}) {
  return (
    <div className="flex flex-col">
      <div className="flex items-center">
        <span className="mr-10">{icon}</span><Body2>{title}</Body2>
      </div>
      <div className="ml-34">
        {children}
      </div>
    </div>);
}

Stat.propTypes = {
  icon: any,
  title: string,
  children: any,
}