import randtoken from 'rand-token';
import mime from 'mime-types';
import request from '@/utils/request';
import requestExternal from '@/utils/requestExternal';

/**
 * Class for transform data from the form to the API
 */
class S3 {
  static getS3Url(file, type, objectId) {
    let url = '';

    return S3.askAWSUrl(file, type, objectId)
      .then((result) => {
        url = result.url; // Save public URL

        S3.uploadFile(result.signedRequest, file); // Upload the file on AWS
        return S3.checkUrl(url).then(() => url);
      });
  }

  /**
   * AskAWSUrl
   *
   * @description
   * Get signedUrl from our API
   */
  static askAWSUrl(file, type, objectId) {
    let url = '';
    let options = {};

    let fileName = file.name;

    if (!fileName) {
      fileName = `${randtoken.generate(16)}.${mime.extension(file.type)}`;
    }

    switch (type) {
      case 'user':
        url = `${process.env.FRONT_API_URL}/users/${objectId}/upload?fileName=${fileName}`;
        break;

      case 'event':
        url = `${process.env.FRONT_API_URL}/events/${objectId}/upload?fileName=${fileName}`;
        break;

      case 'organization':
        url = `${process.env.FRONT_API_URL}/organizations/${objectId}/upload?fileName=${fileName}`;
        break;

      case 'posts':
        url = `${process.env.FRONT_API_URL}/posts/${objectId}/upload?fileName=${fileName}`;
        break;

      default:
        url = `${process.env.FRONT_API_URL}/users/${objectId}/upload?fileName=${fileName}`;
    }

    return request(url, options);
  }

  /**
   * CheckUrl
   *
   * @description
   * Check url every 250ms while file exist in S3 bucket
   */
  static checkUrl(url) {
    return new Promise((resolve) => {
      const intervalId = setInterval(() => {
        fetch(url).then((response) => {
          if (response.status >= 200 && response.status < 400) {
            clearInterval(intervalId);
            resolve(url);
          }
        });
      }, 250);
    });
  }

  /**
   * UploadFile
   *
   * @description
   * Upload file on AWS
   */
  static uploadFile = (awsUrl, file) => requestExternal(awsUrl, {
    method: 'PUT',
    headers: {
      'Content-Type': file.type,
    },
    body: file,
  });
}

export default S3;
