import React from 'react';
import { injectIntl } from 'react-intl';
import { Button } from '@seekube-tech/ui-kit';
import { any, string, func, bool, object } from 'prop-types';

// Components

import messages from '../messages';
/**
 * InputFile
 */
class InputFile extends React.PureComponent {
  static propTypes = {
    children: any,
    placeholder: string,
    accept: string,
    onChange: func,
    multiple: bool,
    intl: object,
    getInputFileReference: func
  };

  static defaultProps = {
    multiple: false,
    placeholder: '',
    accept: '',
  };

  /**
   * Open fileBrowser from input file hidden
   */
  handleOnClick = () => {
    const { inputFileElement } = this;

    inputFileElement.click();
  };

  /**
   * Handle change and send files
   */
  handleOnChange = () => {
    const { props: { onChange, multiple }, inputFileElement: { files } } = this;

    onChange(multiple ? files : files[0]);
  };

  /**
   * Display label based on the props multiple
   */
  displayButtonLabel = () => {
    const { props: { multiple, placeholder, intl } } = this;

    if (placeholder !== '') {
      return placeholder;
    }

    return multiple ? intl.formatMessage(messages.inputFileBtnMultiple) : intl.formatMessage(messages.inputFileBtnSingle);
  };

  /**
   * Render button or children and listen onClick event
   */
  renderChildren = () => {
    const { props: { children }, handleOnClick, displayButtonLabel } = this;

    if (children) {
      return React.cloneElement(this.props.children, { onClick: handleOnClick });
    }

    return <Button style={{ color: '#2980b9'}} onClick={handleOnClick}>{displayButtonLabel()}</Button>;
  };

  setInputfileElement = (input) => {
    if (!input) {
      return;
    }
    this.inputFileElement = input;
    this.props.getInputFileReference?.(input);
  };

  /**
   * Render
   */
  render() {
    const { props: { multiple, accept }, handleOnChange, renderChildren, setInputfileElement } = this;

    return (
      <div>
        {renderChildren()}

        <input
          ref={(input) => setInputfileElement(input)}
          type="file"
          multiple={multiple}
          style={{ display: 'none' }}
          onChange={handleOnChange}
          accept={accept}
        />
      </div>
    );
  }
}

export default injectIntl(InputFile);
