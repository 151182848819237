import React, { useState } from 'react';
import { object } from 'prop-types';
import { compose } from 'redux';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import request from "@/utils/request";
import { withAuth } from '@/containers/RequiredAuth';
import { Button } from "@seekube-tech/ui-kit";
import { withLayout } from "@/containers/Layout";
import BlankState from "@/components/BlankState";
import styles from "@/scenes/Admin/scenes/Demo/styles.less";

import { switchAuthSuccess } from '@/store/auth/actions';

const ActionsMode = ({ mode, pendingRole, isLoading, handleDemo, hideNotClient = false }) => (
  <div style={{ display: "flex", maxWidth: "40vw", margin: '16px auto', justifyContent: "space-around" }}>
    <Button color="primary" size="large" loading={pendingRole === `candidate-${mode}` && isLoading} onClick={() => handleDemo('candidate', mode)}>Candidat</Button>
    <Button color="primary" size="large" loading={pendingRole === `recruiter-${mode}` && isLoading} onClick={() => handleDemo('recruiter', mode)}>Recruteur</Button>
    {hideNotClient !== true ? (<Button color="primary" size="large" loading={pendingRole === `recruiter-no-deal-${mode}` && isLoading} onClick={() => handleDemo('recruiter-no-deal', mode)}>Recruteur non client</Button>) : null}
    <Button color="primary" size="large" loading={pendingRole === `planner-${mode}` && isLoading} onClick={() => handleDemo('planner', mode)}>Organisateur</Button>
  </div>
)

const DemoScene = ({ authUser }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [pendingRole, setPendingRole] = useState(null);
  const dispatch = useDispatch();

  localStorage.setItem('switch_quit_url', '/demo');

  const handleDemo = async (role, mode) => {
    setIsLoading(true);
    setPendingRole(role + mode);

    const res = await request(`${process.env.FRONT_API_URL}/users/demo?type=${role}&mode=${mode}`);

    setTimeout(() => {
      setIsLoading(false);
      setPendingRole(null);

      dispatch(switchAuthSuccess(res));
      dispatch(push('/events'));

      window.location.reload();
    }, 2000);
  }

  return (
    <>
      <div style={{ textAlign: 'center', marginBottom: '44px' }}>
        <div style={{ maxWidth: '700px', margin: 'auto', marginTop: '24px' }} className={isLoading ? styles.demoIsLoading : null}>
          <BlankState
            title=""
            content=""
            icon="blank-state-rocket"
          />
        </div>
        <h2 variant="h2">Lancer une démo</h2>
      </div>

      <div style={{ textAlign: 'center', marginBottom: '16px' }}>
        <h3 variant="h3">Demo classique</h3>
        <ActionsMode mode="classic" isLoading={isLoading} hideNotClient handleDemo={handleDemo} />
      </div>

      <div style={{ textAlign: 'center', marginBottom: '16px' }}>
        <h3 variant="h3">Demo hybride</h3>
        <ActionsMode mode="hybrid" isLoading={isLoading} hideNotClient handleDemo={handleDemo} />
      </div>

      {authUser?.isAdmin && (
        <div style={{ textAlign: 'center', marginBottom: '16px' }}>
          <h3 variant="h3">Demo Ops</h3>
          <ActionsMode mode="ops" isLoading={isLoading} handleDemo={handleDemo} />
        </div>
      )}
    </>
  );
}

DemoScene.propTypes = {
  authUser: object,
};

export default compose(
  withLayout({ size: 'large', withHeader: true, withNav: false }),
  withAuth(),
)(DemoScene);
