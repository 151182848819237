import React from 'react';
import { object } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import FormItem from 'antd/lib/form/FormItem';
import { Body1 } from '@seekube-tech/ui-kit';
import colors from '@seekube-tech/ui-kit/dist/colors';
import Checkbox from '@/components/Checkbox';

export function NotificationBySmsForUpcomingEvent({ form, user }) {
  const t = useFormatMessage();
  const initialValue = user.notifs.candidate_next_events_by_sms;

  return (
    <>
      <FormItem className="mb-20">
        <div className="mb-20 leading-[15px]">
          {form.getFieldDecorator('notifs.candidate_next_events_by_sms', {
            initialValue,
            validateTrigger: 'onSubmit',
          })(
            <Checkbox defaultChecked={initialValue}>
              <Body1 className="inline" color={colors.neutral['500']}>
                {t({ id: 'event.recruiter.preparation.signup.identity.form.candidate_next_events_by_sms' })}
              </Body1>
            </Checkbox>
          )}
        </div>
      </FormItem>
    </>)
}

NotificationBySmsForUpcomingEvent.propTypes = {
  form: object,
  user: object,
}
