import React from 'react';
import moment from 'moment';
import { useFormatMessage } from 'react-intl-hooks';
import { useSelector } from 'react-redux';
import { getAuthUser } from '@/store/auth/selectors';
import { LOCALES } from '@/utils/constants';

function getEventDate({ beginAt = '', endAt = '', format = 'Do MMMM YYYY' }) {
  let beginDate;

  const authUserImmutable = useSelector(getAuthUser);
  const authUser = authUserImmutable?.toJS();

  moment.locale(authUser?.locale ?? LOCALES.fr);

  const t = useFormatMessage();

  const momentBegin = moment(beginAt);
  const momentEnd = moment(endAt);
  const endDate = momentEnd?.format(format);

 if (momentBegin.format('MMMM') !== momentEnd?.format('MMMM')) {
    beginDate = `${momentBegin.format('Do').slice(0, 3)} ${momentBegin.format('MMMM').slice(0, 3)}`;
  } else {
    beginDate = momentBegin.format('Do');
  }

  if (momentBegin.format('Do MMMM YYYY') === endDate) {
    return momentEnd?.format('Do MMMM YYYY');
  }

  return ` ${t({ id: 'date.from' })} ${beginDate} ${t({ id: 'date.to' })} ${momentEnd?.format('Do')} ${momentEnd?.format('MMMM').slice(0, 3)} ${momentEnd?.format('YYYY')}`
}

export { getEventDate }
