import { defineMessages } from 'react-intl';

export default defineMessages({
  shareVisio: {
    id: 'appointment.share.visio',
  },
  inCharge: {
    id: 'appointment.inCharge',
  },
  appointmentSectionTitle: {
    id: 'appointment.status.title',
  },
  unconfirmed: {
    id: 'appointment.status.unconfirmed',
  },
  accepted: {
    id: 'appointment.status.accepted',
  },
  confirmed: {
    id: 'appointment.status.accepted',
  },
  acceptedAppointment: {
    id: 'appointment.status.accepted[0]',
  },
  refused: {
    id: 'appointment.status.refused',
  },
  refusedAppointment: {
    id: 'appointment.status.refused[0]',
  },
  canceled: {
    id: 'appointment.status.refused',
  },
  pending: {
    id: 'appointment.status.pending',
  },
  pendingAppointment: {
    id: 'appointment.status.pending[0]',
  },
  recruiterInCharge: {
    id: 'appointment.recruiter.inCharge',
  },
  recruiterInvitation: {
    id: 'recruiter.invitation',
  },
  finish: {
    id: 'finish',
  },
  edit: {
    id: 'edit',
  },
  profileVisible: {
    id: 'profile.visible',
  },
  profileInvisible: {
    id: 'profile.visible',
  },
  compatibleWith: {
    id: 'compatible.with',
  },
  isNewApplications: {
    id: 'participant.profile.isnew.applications',
  },
  isNewSourcing: {
    id: 'participant.profile.isnew.sourcing',
  },
  editSearch: {
    id: 'event.candidate.jobdating.jobs.editSearch',
  },
  activity_accepted: {
    id: 'activity.accepted',
  },
  activity_pending: {
    id: 'activity.pending',
  },
  activity_application: {
    id: 'activity.accepted',
  },
  activity_application_accepted: {
    id: 'activity.application.accepted.by.recruiter',
  },
  activity_proposition: {
    id: 'activity.proposition',
  },
  activity_transferred: {
    id: 'activity.transferred',
  },
  refused_by_candidate: {
    id: 'activity.refused.by.candidate',
  },
  application_refused_by_recruiter: {
    id: 'activity.application.refused.by.recruiter',
  },
  refused_by_recruiter: {
    id: 'activity.refused.by.recruiter',
  },
  canceled_by_candidate: {
    id: 'activity.canceled.by.candidate',
  },
  canceled_by_recruiter: {
    id: 'activity.canceled.by.recruiter',
  },
  activityConsult: {
    id: 'activity.consult',
  },
  activityOffer: {
    id: 'activity.offer[0]',
  },
  activityVisitStand: {
    id: 'activity.visit.stand',
  },
  activityAppointmentPropose: {
    id: 'activity.appointment.propose',
  },
  activityAppointmentProposition: {
    id: 'activity.appointment.proposition',
  },
  activityForOffer: {
    id: 'activity.forOffer',
  },
  activityReceiveProposition: {
    id: 'activity.receive.proposition',
  },
  activityAppointmentWith: {
    id: 'activity.appointment.with',
  },
  activityAcceptProposition: {
    id: 'activity.accept.proposition',
  },
  activityAppointmentOf: {
    id: 'activity.appointment.of',
  },
  activityApplication: {
    id: 'activity.application',
  },
  activityOffer2: {
    id: 'activity.offer[1]',
  },
  activityReceivePropositionWith: {
    id: 'activity.receive.proposition.with',
  },
  activityRefuse: {
    id: 'activity.refuse',
  },
  activityRefuseByUserForOffer: {
    id: 'activity.refuse.by.user.for.offer',
  },
  activityRefuseByUser: {
    id: 'activity.refuse.by.user',
  },
  activityReceiveRate: {
    id: 'activity.receive.rate',
  },
  activityMeet: {
    id: 'activity.meet',
  },
  activityTitle: {
    id: 'activity.title',
  },
  activityBlankState: {
    id: 'activity.blankState',
  },
  activityStand: {
    id: 'activity.stand',
  },
  activityDeclined: {
    id: 'activity.declined',
  },
  activityRecruiterCanceled: {
    id: 'activity.cancel.by.recruiter',
  },
  activityInterviewCanceled: {
    id: 'activity.interview.cancel.by.recruiter',
  },
  activityCandidateRefused: {
    id: 'activity.refuse.by.candidate',
  },
  applicationsApply: {
    id: 'applications.apply',
  },
  applicationsSourcing: {
    id: 'applications.sourcing',
  },
  applicationsManage: {
    id: 'applications.manage',
  },
  addComment: {
    id: 'add.comment',
  },
  displayInfoContact: {
    id: 'display.info.contact',
  },
  description: {
    id: 'descriptionParticipant',
  },
  save: {
    id: 'save',
  },
  cancel: {
    id: 'cancel',
  },
  addLink: {
    id: 'links.add',
  },
  linksLabelError: {
    id: 'links.label.error',
  },
  linksLabelPlaceholder: {
    id: 'links.label.placeholder',
  },
  linksLabelWebsite: {
    id: 'links.label.website',
  },
  linksLabelPortfolio: {
    id: 'links.label.portfolio',
  },
  linksLabelBlog: {
    id: 'links.label.blog',
  },
  linksUrlError: {
    id: 'links.url.error',
  },
  linksUrlPlaceholder: {
    id: 'links.url.placeholder',
  },
  educations: {
    id: 'educations',
  },
  educationsAdd: {
    id: 'educations.add',
  },
  experiences: {
    id: 'experiences',
  },
  experiencesAdd: {
    id: 'experiences.add',
  },
  formError: {
    id: 'form.error.required',
  },
  schoolName: {
    id: 'school.name',
  },
  companyName: {
    id: 'event.recruiter.preparation.stand.item.company.label',
  },
  schoolCertificateName: {
    id: 'school.certificate.name',
  },
  schoolJobName: {
    id: 'school.job.name',
  },
  schoolCurrentExperience: {
    id: 'school.current.experience',
  },
  start: {
    id: 'start',
  },
  end: {
    id: 'end',
  },
  since: {
    id: 'since',
  },
  inProgress: {
    id: 'events.inProgress',
  },
  searchOther: {
    id: 'search.others',
  },
  resultSave: {
    id: 'participants.results.save',
  },
  resultRefuse: {
    id: 'participants.results.refuse',
  },
  resultTop: {
    id: 'participants.results.top',
  },
  resultApplication: {
    id: 'participants.results.applications',
  },
  resultAll: {
    id: 'participants.results.all',
  },
  resultSourcing: {
    id: 'participants.results.sourcing',
  },
  resultInterview: {
    id: 'participants.results.interview',
  },
  activityAppointmentAccepted: {
    id: 'activity.appointment.accepted',
  },
  activityAppointmentUnconfirmed: {
    id: 'activity.appointment.unconfirmed',
  },
  activityWithColleague: {
    id: 'participant.profile.activityWithColleague',
  },
  appointmentStatus: {
    id: 'participant.profile.appointment.status',
  },
  appointmentMedium: {
    id: 'participant.profile.appointment.medium',
  },
  appointmentDate: {
    id: 'participant.profile.appointment.date',
  },
  appointmentAddress: {
    id: 'participant.profile.appointment.address',
  },
  interestedBy: {
    id: 'participant.profile.interestedBy',
  },
  blankNoExperience: {
    id: 'participant.profile.blankstate.noExperience',
  },
  appointment_accepted: {
    id: 'accepted',
  },
  appointment_refused: {
    id: 'refused',
  },
  appointment_pending: {
    id: 'pending',
  },
  appointment_canceled: {
    id: 'canceled',
  },
  appointmentDateFormatted: {
    id: 'participant.profile.appointment.date.formatted',
  },
  appointmentMediumPhone: {
    id: 'participant.profile.appointment.medium.phone',
  },
  appointmentMediumSeeklive: {
    id: 'visio',
  },
  appointmentOwner: {
    id: 'participant.profile.appointment.owner',
  },
  yes: {
    id: 'yes',
  },
  no: {
    id: 'no',
  },
  present: {
    id: 'present',
  },
  noshow: {
    id: 'noshow',
  },
});
