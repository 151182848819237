import React from 'react';
import { object, node } from 'prop-types';
import { isUndefined } from 'lodash';
import classnames from 'classnames';
import { useFormatMessage } from 'react-intl-hooks';
import { injectIntl } from 'react-intl';
import { Avatar } from '@seekube-tech/ui';

import ImgCdn from '@/components/ImgCdn';
import styles from './styles.less';
import Wrapper from '../Wrapper';

import Icon from '../Icon';
import exponentMessages
  from '../../scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Exponents/scenes/Exponent/messages';

const renderSectors = (sectors) => {
  const t = useFormatMessage();

  return (
    sectors ? sectors.filter((item) => !isUndefined(item)).map((sector) => sector?.key ? t({ id: `sector[${sector.key}]` }) : sector.name).join(' - ') : ''
  )
}

const OrganizationLayout = ({ organization, children, intl, event, cover, pictureUrl }) => (
  <div className={classnames(styles.offerPreviewContainer, styles.isPreview)}>
    <div className={styles.cover}>
      <ImgCdn
        size={1400}
        withshadow
        backgroundImageCss="linear-gradient(0deg, rgba(56, 80, 119, 0.4), rgba(56, 80, 119, 0.4)), linear-gradient(180deg, rgba(37, 45, 71, 0.3) 35.08%, #252D47 100%)"
        src={cover || ''}
        className={styles.img}
      />
    </div>
    <Wrapper size="large" className={styles.wrapper}>
      <div className={styles.organizationInfos}>
        <div className={styles.avatar}>
          <Avatar
            variant="business"
            size="extra-large"
            src={pictureUrl || organization?.profile?.pictureUrl}
            color={organization?.isPartner ? event?.modules?.partner?.color : 'default'}
            badge={organization?.isPartner ? event?.modules?.partner?.icon : null}
            tooltip={event?.modules?.partner?.[intl?.locale === 'fr' ? 'label_fr' : 'label_en']}
          />
        </div>
        <div className={styles.descriptionCover} style={event?.type === 'jpo' ? {
          display: 'flex',
          alignItems: 'center',
        } : null}>
          <h1>{organization?.name}</h1>
          <div className={styles.data}>
            {organization?.profile && event?.type !== 'jpo' ? (
              <>
                <div className={styles.iconData}>
                  <Icon name="tag" className={styles.iconSvg}/>{renderSectors(organization?.profile.sectors)}
                </div>
                <div className={styles.iconData}>
                  <Icon name="user" className={styles.iconSvg}/>{organization?.profile && organization?.profile.size
                  ? `${intl.formatMessage(exponentMessages[`size${organization.profile.size}`])} ${intl.formatMessage(
                    exponentMessages.employees).toLowerCase()}`
                  : '-'}
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>

      <div className={styles.content}>
        {children}
      </div>
    </Wrapper>
  </div>
);

OrganizationLayout.propTypes = {
  organization: object,
  children: node,
  intl: object,
  event: object,
};

export default injectIntl(OrganizationLayout);
