import React from 'react';
import { object, string } from 'prop-types';
import { IconMinus, Tag }  from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import styles from '@/scenes/Event/scenes/Owner/scenes/OnBoarding/containers/Dashboard/styles.less';

const StatusOfPreparationAccessor =  ({ actions, tool, _organization, format }) => {
  const t = useFormatMessage();

  const formatMoment = tool._keyMomentLinks.find((keyMomentLink) => keyMomentLink._keyMoment.format === format);

  const onTagClick = () => actions.onTagReadyClick(format, _organization.name);

  if (formatMoment?.isReady === true) {
    return (
      <Tag
        variant="tonal"
        color="neutral"
        onClick={onTagClick}
      >
        {t({ id: 'ready' })}
      </Tag>
    );
  }

  if (formatMoment?.isReady === false) {
    return (
      <Tag
        variant="tonal"
        onClick={onTagClick}
        color="warning"
      >
        {t({ id: 'incomplete' })}
      </Tag>
    )
  }

  return <span ><IconMinus size={12} className={styles.none} /></span>
};

StatusOfPreparationAccessor.propTypes = {
  actions: object,
  tool: object,
  _organization: object,
  format: string,
}

export { StatusOfPreparationAccessor }