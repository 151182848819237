import React from 'react';
import { object, func } from 'prop-types';

// Styles
import styles from './styles.less';

const ActionsColumn = ({ school, onReferentClick }) => (
  <div className={styles.actionsContainer}>
    <a role="button" tabIndex={0} onClick={() => onReferentClick(school)}>Referent</a>
  </div>
);


ActionsColumn.propTypes = {
  school: object,
  onReferentClick: func,
};

ActionsColumn.defaultPros = {
  record: {},
};

export { ActionsColumn };
