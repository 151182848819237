import { defineMessages } from 'react-intl';

export default defineMessages({
  signin: {
    id: 'header.signin',
  },
  signup: {
    id: 'header.signup',
  },
  participate: {
    id: 'header.participate',
  },
  browserAlert: {
    id: 'header.browserAlert',
  },
  browserAlertDownload: {
    id: 'header.browserAlertDownload',
  },
  beforeStart: {
    id: 'header.event.beforeStart',
  },
  beforeEnd: {
    id: 'header.event.beforeEnd',
  },
  closed: {
    id: 'header.event.closed',
  },
  preregistration: {
    id: 'event.landing.action.preregistration',
  },
});
