import React from 'react';
import { string } from 'prop-types';
import { Typography } from '@seekube-tech/ui'
import { useFormatMessage } from 'react-intl-hooks'
import moment from 'moment';

import useStyles from './styles';


const Informal1To1Message = ({
  messageInformal1to1Candidate,
  messageInformal1to1CandidateAt
}) => {
  const t = useFormatMessage();
  const classes = useStyles();
  const sendAt = moment(messageInformal1to1CandidateAt).format('DD.MM.YYYY');

  return (
    <div className={classes.root}>
      <div className={classes.heading}>
        <Typography className={classes.subtitle} color="neutral.500" variant="subtitle1">
          {t({ id: 'modal.bigParticipant.informal1to1.title' })}
          <Typography className={classes.sendAt} color="grey[400]" variant="caption2">
            {t({ id: 'send.at' }, { date: sendAt })}
          </Typography>
        </Typography>
      </div>
      <Typography color="neutral.500" variant="body2">
        {messageInformal1to1Candidate}
      </Typography>

    </div>
  )
}

Informal1To1Message.propTypes = {
  messageInformal1to1Candidate: string,
  messageInformal1to1CandidateAt: string,
}

export { Informal1To1Message }