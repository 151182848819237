import React, { useState } from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Radio, { RadioGroup } from '@/components/Radio';
import { If } from '@/components/If';
import { Iframe } from '@/components/Iframe';

import messages from '../Stats/messages';
import styles from './styles.less';

const IframesRedash = ({ slug = '' }) => {
  const [iframeVisible, setIframeVisible] = useState('interviews');
  const s = encodeURIComponent(slug);

  return (
    <div>
      <RadioGroup className={styles.radioGroup} onChange={(e) => { e.preventDefault(); setIframeVisible(e.target.value); }} defaultValue={iframeVisible}>
        <Radio value="interviews"><FormattedMessage {...messages.interviewValidated} /></Radio>
        <Radio value="applications"><FormattedMessage {...messages.processingApplicationsTitle} /></Radio>
      </RadioGroup>
      <If condition={iframeVisible === 'interviews'}>
        <Iframe src={`https://redash.sawkiube.fr/embed/query/46/visualization/92?api_key=GjRWZQ54fNP6ww9TzHCsVw2ohc8Giv5W28CqFHn7&p_eventId=${s}&hide_parameters&hide_header&hide_link`} />
      </If>
      <If condition={iframeVisible === 'applications'}>
        <Iframe src={`https://redash.sawkiube.fr/embed/query/47/visualization/93?api_key=GjRWZQ54fNP6ww9TzHCsVw2ohc8Giv5W28CqFHn7&p_eventId=${s}&hide_parameters&hide_header&hide_link`} />
      </If>
      <Iframe src={`https://redash.sawkiube.fr/embed/query/38/visualization/87?api_key=GjRWZQ54fNP6ww9TzHCsVw2ohc8Giv5W28CqFHn7&p_eventId=${s}&hide_parameters&hide_header&hide_link`} />
    </div>);
};

IframesRedash.propTypes = {
  slug: string,
};

export { IframesRedash };
