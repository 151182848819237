import React from 'react';
import PropTypes from 'prop-types';
import { Form, InputNumber, Select } from 'antd';
import { If, Then } from 'react-if';
import { FormattedMessage } from 'react-intl';

// Components
import { Button, IconXCircle } from '@seekube-tech/ui-kit';
import Icon from '@/components/Icon';
import { H1 } from '@/components/Title';

// Styles & Translations
import styles from '../../../../styles.less';
import messages from '../../messages';

// Antd
const { Option } = Select;
const FormItem = Form.Item;

/**
 * ExponentsForm
 */
class ExponentsForm extends React.PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    form: PropTypes.object,
    initialValues: PropTypes.array,
  };

  /**
   * Handle Submit
   *
   * @param {object} e
   */
  handleSubmit = (e) => {
    e.preventDefault();
    const { form: { validateFields }, onSubmit } = this.props;

    return validateFields(onSubmit);
  };

  /**
   * Reset a field
   *
   * @param {number} index
   */
  resetFieldValue = (index) => {
    const { props: { initialValues, form: { resetFields } } } = this;

    // Because resetFields used the initialValue
    initialValues[index].type = null;
    initialValues[index].goal = null;

    resetFields([`exponent[${index}].type`, `exponent[${index}].goal`]);

    // Force the refresh
    this.forceUpdate();
  };

  /**
   * Build the form
   *
   * @returns {Array}
   */
  buildTheForm = () => {
    const { props: { initialValues, form: { getFieldDecorator, getFieldValue } } } = this;
    const exponentTypes = ['company', 'school', 'cfa'];

    return exponentTypes
    .map((exponentType, index) => (
      <div key={index} className={styles.inlineItem}>
        <FormItem>
          {getFieldDecorator(`exponent[${index}].type`, {
            initialValue: (initialValues && initialValues[index]) ? initialValues[index].type : undefined,
            rules: [
              { required: (index === 0), message: 'Merci de remplir au minimum un exposant' },
            ],
          })(
            <Select style={{ width: 200 }} suffixIcon={<Icon name="chevron" />}>
              { exponentTypes.map((item, index) => ((
                <Option key={item}><FormattedMessage {...messages[`exponentType${index + 1}`]} /></Option>
              ))) }
            </Select>
          )}
        </FormItem>

        <FormItem>
          {getFieldDecorator(`exponent[${index}].goal`, {
            initialValue: (initialValues && initialValues[index]) ? initialValues[index].goal : undefined,
          })(
            <InputNumber placeholder="Objectif" />
          )}
        </FormItem>

        <If condition={getFieldValue(`exponent[${index}].type`) !== undefined}>
          <Then>
            <Button color="error" onClick={() => this.resetFieldValue(index)}>
              <IconXCircle size={16} />
            </Button>
          </Then>
        </If>
      </div>
    ));
  };

  /**
   * Render
   */
  render() {
    return (
      <Form onSubmit={this.handleSubmit} style={{ maxWidth: '500px', textAlign: 'left' }} layout="inline">
        {/*  name */}
        <H1>Exposants</H1>
        {this.buildTheForm()}
        {/* Submit */}
        <Button className="mt-20" type="submit">Enregistrer</Button>
      </Form>
    );
  }
}

export default Form.create()(ExponentsForm);
