import React, { useState } from 'react'
import { func, object, number } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { H4, } from '@seekube-tech/ui-kit';
import { Form } from 'antd';
import { useSelector } from 'react-redux';
import { getAuthUser } from '@/store/auth/selectors';
import {
  usePostPost
} from '@/scenes/Event/scenes/Owner/scenes/Dashboard/components/ModalActivities/queries/usePostPost';
import {
  useUpdatePost
} from '@/scenes/Event/scenes/Owner/scenes/Dashboard/components/ModalActivities/queries/useUpdatePost';
import { PublishBtn } from '@/scenes/Event/scenes/Owner/scenes/Dashboard/components/AddActivity/PublishBtn';
import { DraftBtn } from '@/scenes/Event/scenes/Owner/scenes/Dashboard/components/AddActivity/DraftBtn';
import { PreviewBtn } from '@/scenes/Event/scenes/Owner/scenes/Dashboard/components/AddActivity/PreviewBtn';
import { Title } from '@/scenes/Event/scenes/Owner/scenes/Dashboard/components/AddActivity/Title';
import { Author } from '@/scenes/Event/scenes/Owner/scenes/Dashboard/components/AddActivity/Author';
import { Content } from '@/scenes/Event/scenes/Owner/scenes/Dashboard/components/AddActivity/Content';
import { useGetOrganizationUsers } from '@/queries/organizations/useGetOrganizationUsers';
import {
  ModalDraftActivity
} from '@/scenes/Event/scenes/Owner/scenes/Dashboard/components/AddActivity/ModalDraftActivity';
import {
  ModalQuitWithoutSaving
} from '@/scenes/Event/scenes/Owner/scenes/Dashboard/components/AddActivity/ModalQuitWithoutSaving';
import { CloseActivityBtn } from '@/scenes/Event/scenes/Owner/scenes/Dashboard/components/AddActivity/CloseActivityBtn';
import { ActivityStatus } from '@/scenes/Event/scenes/Owner/scenes/Dashboard/components/AddActivity/ActivityStatus';
import {
  ModalSendNotificationActivity
} from '@/scenes/Event/scenes/Owner/scenes/Dashboard/components/AddActivity/ModalSendNotificationActivity';
import styles from './styles.less'

const AddActivity = ({
  handleOnClose,
  event,
  activityToUpdate,
  onPost,
  page,
  form,
  onPreview
}) => {
  const t = useFormatMessage()

  const authUser = useSelector(getAuthUser)?.toJS();
  const orgSeekube = authUser?._organizations?.find((orga) => orga.name === 'Seekube')

  const [isModalPutOnDraftOpen, setIsModalPutOnDraftOpen] = useState(false)
  const [isModalExitOpen, setIsModalExitOpen] = useState(false)
  const [isModalNotificationOpen, setIsModalNotificationOpen] = useState(false)

  const mutatePostActivity = usePostPost({
    eventId: event._id,
    page,
    onSuccess: (res) => {
      onPost(res);

      if (res.status === 'published') {
        setIsModalNotificationOpen(true);
      }
    } });

  const mutateUpdateActivity = useUpdatePost({ eventId: event._id, page, onSuccess: (res) => {
      onPost(res);
      closeModalDraft();
    } });

  const closeModalDraft = () => {
    setIsModalPutOnDraftOpen(false);
    mutateUpdateActivity.reset();
  }

  const { data: usersOrganization } = useGetOrganizationUsers(
    { search: { limit: 200 }, organization: orgSeekube });

  const exponentUsers = usersOrganization?.docs ? [...event.planners._users, ...usersOrganization.docs] :
    event.planners._users.filter((_user) => _user._currentOrganization === authUser._currentOrganization._id);

  const hasFieldChanged = form.isFieldsTouched() || form.getFieldValue('content') !== activityToUpdate?.content;

  const handleSubmit = (status) => {
    form.validateFields((err, values) => {
      if (!err) {
        const data = {
          ...values,
          status,
          _events: event._id,
        }

        if (activityToUpdate?._id) {
          return mutateUpdateActivity.mutate({ activityToUpdate: data, _id: activityToUpdate._id });
        }

        return mutatePostActivity.mutate({ data });
      }
      return null;
    })
  }

  const handleSubmitDraft = () => {
    if (activityToUpdate.status === 'published' && !isModalPutOnDraftOpen) {
      return setIsModalPutOnDraftOpen(true)
    }

    return handleSubmit('draft');
  }

  return (
    <div className={styles.addActivity}>
      <ModalQuitWithoutSaving
        onCancel={() => setIsModalExitOpen(false)}
        onConfirm={handleOnClose}
        isVisible={isModalExitOpen}
      />

      <ModalDraftActivity
        onConfirm={handleSubmitDraft}
        mutateUpdateActivity={mutateUpdateActivity}
        onCancel={() => setIsModalPutOnDraftOpen(false)}
        isVisible={isModalPutOnDraftOpen}
      />

      <ModalSendNotificationActivity
        isVisible={isModalNotificationOpen}
        activityToUpdate={activityToUpdate}
        onConfirm={() => {
          setIsModalNotificationOpen(false);
          handleOnClose();
        }}
      />

      <div className={styles.header}>
        <H4>{t({ id: 'modal.add.activity.title' })}</H4>
        <CloseActivityBtn onClose={handleOnClose} />
      </div>


      <div className={styles.body}>
        <ActivityStatus activityToUpdate={activityToUpdate} />

        <Form>
          <div className={styles.topInputs}>
            <Title form={form} initialValue={activityToUpdate?.title} />
            <Author
              exponentUsers={exponentUsers}
              form={form}
              initialValue={activityToUpdate?._author?._id || authUser?._id}
              authUser={authUser}
              event={event}
            />
          </div>
          <Content
            form={form}
            event={event}
            activityToUpdate={activityToUpdate}
          />
        </Form>

        <div className={styles.btnContainer}>
          <PreviewBtn
            onClick={() => onPreview({ ...form.getFieldsValue(), _author: exponentUsers.find((expUser) =>
                expUser._id === form.getFieldValue('_author'), ) })}
          />
          <DraftBtn
            hasFieldChanged={hasFieldChanged}
            mutateUpdateActivity={mutateUpdateActivity}
            activityToUpdate={activityToUpdate}
            mutatePostActivity={mutatePostActivity}
            onClick={handleSubmitDraft}
          />

          <PublishBtn
            hasFieldChanged={hasFieldChanged}
            activityToUpdate={activityToUpdate}
            onClick={() => handleSubmit('published')}
            mutatePostActivity={mutatePostActivity}
          />
        </div>
      </div>
    </div>
  )
}

AddActivity.propTypes = {
  handleOnClose: func,
  page: number,
  onPost: func,
  event: object,
  form: object,
  activityToUpdate: object,
  onPreview: func,
};

export default Form.create()(AddActivity);
