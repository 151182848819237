import { handleActions } from 'redux-actions';
import { fromJS, Map } from 'immutable';
import * as types from './types';

export const initialState = fromJS({
  error: {},
  user: null,
  inviteUser: null,
  resetStatus: null,
  showSettings: false,
  defaultSettingsView: 'profile',
  agendaView: 'calendar',
  hasClosedIeAlert: false,
  resetIsFetching: false,
  hasClosedCookieAlert: false,
  hasClosedFeedbackAboutCandidates: false,
  token: null,
  atsDefaultReviewer: null,
  isChecking: null,
  connectedAs: {
    user: null,
    token: null,
  },
});

window.localStorage.setItem('agenda_view', 'calendar');

//TODO: clear tokens occurrences here

/**
 * Auth reducer
 */
const authReducer = handleActions({
  [types.AUTH_LOGIN_SUCCESS]: (state, { payload }) => state
    .set('user', fromJS(payload.user))
    .set('token', payload.token)
    .set('error', new Map()),
  [types.AUTH_REFRESH_USER]: (state, { payload }) => {
    const connectedUser = state.getIn(['connectedAs', 'user']);
    const userId = state.getIn(['user', '_id']);

    // if there is no user connected, we don't refresh the user in the store
    if (!userId) {
      return state
    }

    if (connectedUser) {
      return state.setIn(['connectedAs', 'user'], fromJS(payload.user));
    }

    return state.set('user', fromJS(payload.user));
  },
  [types.CLOSE_SETTINGS]: (state) => state
    .set('showSettings', false),
  [types.TOGGLE_SETTINGS]: (state, { payload }) => state
    .set('showSettings', !state.get('showSettings'))
    .set('defaultSettingsView', payload),
  [types.AUTH_RESET_PASSWORD]: (state) => state
    .set('resetIsFetching', true),
  [types.AUTH_CHECK_INVITATION]: (state) => state
    .set('isChecking', true),
  [types.AUTH_CHECK_INVITATION_SUCCESS]: (state, { payload }) => state
    .set('inviteUser', fromJS(payload.user))
    .set('isChecking', false),
  [types.AUTH_CHECK_RESET_TOKEN]: (state) => state
    .set('isChecking', true),
  [types.AUTH_CHECK_RESET_TOKEN_SUCCESS]: (state, { payload }) => state
    .set('inviteUser', fromJS(payload.user))
    .set('isChecking', false),
  [types.SWITCH_AUTH_SUCCESS]: (state, { payload }) => state
    .setIn(['connectedAs', 'user'], fromJS(payload.user))
    .setIn(['connectedAs', 'token'], payload.token),
  [types.SWITCH_EXIT_SUCCESS]: (state) => state
    .setIn(['connectedAs', 'user'], null)
    .setIn(['connectedAs', 'token'], null),
  [types.AUTH_LOGIN_ERROR]: (state, { payload }) => state
    .set('error', fromJS(payload)),
  [types.AUTH_RESET_PASSWORD_STATUS]: (state, { payload }) => state
    .set('resetStatus', payload)
    .set('resetIsFetching', false),
  [types.AUTH_LOGOUT_USER]: (state, { payload }) => {
    if (payload?.redirectV1) {
      window.location.href = 'https://www.seekube.com';
    }

    return initialState;
  },
  [types.CLOSE_IE_ALERT]: (state) => state
    .set('hasClosedIeAlert', true),
  [types.CLOSE_COOKIE_ALERT]: (state) => state
    .set('hasClosedCookieAlert', true),
  [types.CLOSE_FEEBACK_ABOUT_CANDIDATES]: (state, { payload }) => state.set('hasClosedFeedbackAboutCandidates', payload || false),
  [types.SET_AGENDA_VIEW]: (state, { payload: { view } }) => state
    .set('agendaView', view),
  [types.SET_ATS_DEFAULT_REVIEWER]: (state, { payload }) => state
    .set('atsDefaultReviewer', payload.reviewer)
}, initialState);

export default authReducer;
