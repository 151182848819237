import React from 'react';
import { func } from 'prop-types';
import { Button, IconDownload } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';

export const DownloadAllCv = ({ downloadAllCv }) => {
  const t = useFormatMessage();

  return (
    <Button variant="tonal" size="small" imageComponentLeft={<IconDownload size={14} />} onClick={downloadAllCv}>
      {t({ id: 'agenda.download.cv' })}
    </Button>
  )
}

DownloadAllCv.propTypes = {
  downloadAllCv: func,
}
