import React from 'react';

const getTitle = ({ appointment, skipValidation }) => {
  if (appointment.origin === 'informal1to1') {
    if (skipValidation) {
      if ((appointment.wasAcceptedAndUpdatedAt && appointment.status === 'pending') || appointment.status === 'accepted') {
        return { id: 'event.candidate.appointment.slots.informal1to1.classic.title' };
      }
      return { id: 'event.candidate.appointment.slots.informal1to1.title' };
    }
    return { id: 'event.candidate.appointment.slots.informal1to1.classic.title' };
  }

  if (skipValidation) {
    if ((appointment.wasAcceptedAndUpdatedAt && appointment.status === 'pending') || appointment.status === 'accepted') {
      return { id: 'event.candidate.appointment.slots.title' };
    }

    if (appointment.status === 'pending' && appointment.origin === 'sourcing') {
      return { id: 'event.candidate.appointment.slots.title' };
    }

    if (appointment.status === 'accepted' && appointment.origin === 'sourcing') {
      return { id: 'event.candidate.appointment.slots.title' };
    }

    return { id: 'event.candidate.appointment.slots.skipAppointmentValidation.title' };
  }

  return { id: 'event.candidate.appointment.slots.title' };
}

export { getTitle }