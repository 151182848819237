import React from 'react';
import { injectIntl } from 'react-intl';
import { object } from 'prop-types';

// Components
import Separator from '@/components/Separator';

// Styles & Translations
import messages from './messages';

/**
 * RecruiterJobdatingDashboardScene
 */
class RecruiterJobdatingDashboardScene extends React.PureComponent { // eslint-disable-line
  static propTypes = {
    intl: object,
  };

  /**
   * Render
   */
  render() {
    const { props: { intl } } = this;

    return (
      <div>
        <h1 className="mainTitle" style={{ textAlign: 'center' }}>{intl.formatMessage(messages.welcome)}</h1>
        <Separator height={30} />
        <h2 className="mainTitle" style={{ textAlign: 'center', fontWeight: 300, opacity: 0.8 }}>{intl.formatMessage(messages.inProgress)}</h2>
      </div>
    );
  }
}

export default injectIntl(RecruiterJobdatingDashboardScene);
