import React from 'react';
import { object, func } from 'prop-types';
import { compose } from 'redux';
import { push } from 'connected-react-router';
import { isEmpty } from 'lodash';
import { injectIntl } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { appActions, appSelectors } from '@/store/app';
import { userActions } from '@/store/user';
import { authSelectors } from '@/store/auth';
import { toJS } from '@/utils';
import { withLayout } from '@/containers/Layout';
import { withAuth } from '@/containers/RequiredAuth';
import { queryStringToObject } from '../../utils/url';
import LoadingIndicator from '../../components/LoadingIndicator';

class SyncScene extends React.PureComponent {
  static propTypes = {
    app: object,
    authUser: object,
    location: object,
    patchMe: func,
    postSyncCode: func,
    push: func,
    intl: object
  };

  componentDidMount() {
    const { authUser, location, postSyncCode, push, patchMe, intl } = this.props;

    const query = queryStringToObject(location.search);

    if (!isEmpty(query.code)) {
      window.localStorage.removeItem('nylas_status');
      postSyncCode({ code: query.code });
    }

    if (query.error === 'access_denied' && authUser) {
      window.localStorage.setItem('nylas_status', 'access_denied');
      const syncCalendar = authUser.syncCalendar || { createdAt: new Date() };
      syncCalendar.syncState = 'access_denied';
      syncCalendar.updatedAt = new Date();

      patchMe({
        userId: authUser._id,
        userParams: { syncCalendar },
        notificationParams: {
          success: {
            message: intl.formatMessage({ id: 'notifications.update.success' }),
            kind: 'info',
            style: {
              bottom: '5%',
              top: 'inherit',
            },
          },
        }
      });

      push('/events?settings=syncCalendar');
    }
  }

  render() {
    const { props: { app, intl } } = this;

    if (!app) {
      return null;
    }

    return (
      <div style={{ textAlign: 'center', marginTop: '40px' }}>
        <LoadingIndicator />
        <strong>{intl.formatMessage({ id: 'synchronization.loader' })}</strong>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  app: appSelectors.getCurrentApp,
  authUser: authSelectors.getAuthUser,
});

const mapDispatchToProps = {
  patchApp: appActions.patchApp,
  postSyncCode: userActions.postSyncCode,
  patchMe: userActions.patchMe,
  push,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withLayout({ size: 'large', withHeader: true, withNav: false }),
  withAuth({ isAdmin: false }),
  withConnect,
  injectIntl,
)(toJS(SyncScene));
