import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Link as ScrollLink, scrollSpy } from 'react-scroll';
import styles from './styles.less';

const Nav = ({
  event: { _contents }, displayResponsiveNavigation, locale,
}) => {
  const navContainerCSS = classnames(styles.navContainer, displayResponsiveNavigation && styles.navContainerResponsive);

  scrollSpy.update();

  return (
    <ul className={navContainerCSS}>
      {
        _contents
          .map((content, index) => {
            if ((content[locale].h3 && content.inNav) || (content.name === 'header' && content.inNav)) {
              return (
                <li key={index}>
                  <ScrollLink to={`content_${index}`} smooth isDynamic duration={1000} offset={0} spy delay={0}>
                    {content[locale].h3 || 'Intro'}
                  </ScrollLink>
                </li>
              );
            }

            return null;
          })
      }
    </ul>
  );
};

Nav.propTypes = {
  event: PropTypes.object,
  locale: PropTypes.string,
  displayResponsiveNavigation: PropTypes.bool,
};

export default Nav;
