import React, { useState } from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { compose } from 'redux';
import { array, func, shape, object, string } from 'prop-types';
import { H5 } from '@seekube-tech/ui-kit';
import { toJS } from '@/utils/index';
import { find } from 'lodash';
import { Bloc } from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/components/Bloc';
import EducationLevel from './EducationLevel';
import EducationForm from './EducationForm';
import './styles.less';

const Educations = ({ context = 'event', user, defaultCriteria, form, event }) => {
  const isBlocEnabled = find(event.participants.onboarding, (e) => e.name === 'experience').enabled;
  const t = useFormatMessage();
  const [educationsCount, setEducationsCount] = useState(1);
  const [educations, setEducations] = useState(user.educations?.length > 0 ? user.educations : [null]);

  const handleOnAddEducation = (e, educationForm) => {
    if (educations.length < 2) {
      const newEducations = [...educations, educationForm];
      setEducationsCount(educationsCount + 1)
      setEducations(newEducations);
    }
  }

  const handleOnDeleteEducation = (order) => {
    const newEducations = educations;
    newEducations.splice(order, 1);

    setEducationsCount(1);
    setEducations(newEducations)
  };

  return (
    isBlocEnabled && (
      <Bloc>
        <div className="commonStep">
          <div>
            <div className="educationLevel">
              <H5 className="mb-24">
                <span>{t({ id: 'education.level' })}</span>
              </H5>

              <EducationLevel
                form={form}
                matching={user.matching || { filters: [] }}
                defaultCriteria={defaultCriteria}
              />
            </div>

            <H5 className="educationTitle mb-24">
              <span>{t({ id: 'educations' })}</span>
              <span className="maxField ml-5">{`(2 ${t({ id: 'max' })})`}</span>
            </H5>

            {educations.map((education, i) => (
              <EducationForm
                form={form}
                key={i}
                order={i}
                education={education}
                handleAddEducation={handleOnAddEducation}
                handleDeleteEducation={handleOnDeleteEducation}
                educationsCount={educationsCount}
                context={context}
                user={user}
              />
            ))}
          </div>
        </div>
      </Bloc>)
  );
}

Educations.propTypes = {
  form: shape({
    getFieldDecorator: func,
    getFieldsError: func,
    getFieldError: func,
    isFieldTouched: func,
  }),
  user: object,
  event: object,
  defaultCriteria: array,
  context: string,
}

export default compose(
)(toJS(Educations));
