import * as React from 'react';
import moment from 'moment/moment';
import { object } from 'prop-types';
import { IconClock } from '@seekube-tech/ui-kit';
import { FormattedMessage } from 'react-intl';
import { getEarliestKeyMomentFromFormats, getKeyMomentsFormatsFromEvent } from '@/utils/keyMoments';
import { PHASES } from '@/store/availableActions/utils/const';

function HuntDate({ event }) {
  const formats = getKeyMomentsFormatsFromEvent(event);
  const beginAt = getEarliestKeyMomentFromFormats(event, PHASES.HUNT, formats);
  const beginAtMonth = moment(beginAt).format('MMM').slice(0, 3);
  const beginAtDay = moment(beginAt).format('D');

  return (
    <span key="huntDate">
      <IconClock size={16} />
      <FormattedMessage id="events.tags.hunt.beginAt" />
      <strong>{beginAtDay}</strong> {beginAtMonth}
    </span>
  );
}

HuntDate.propTypes = {
  event: object
}

export default HuntDate;
