import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import request from '@/utils/request';
import { objectToParams } from '@/utils/url';
import { getCurrentOrganization } from '@/store/organization/selectors';

export function useGetOrganizationUsers(props) {
  const organization = props.organization || useSelector(getCurrentOrganization)?.toJS();

  const searchParams = objectToParams(props.search);
  
  const requestUrl = `${process.env.FRONT_API_URL}/organizations/${organization?._id}/users?${searchParams}&sort=${props.sort}&stats=true`;

  const queryFn = () => request(requestUrl, { method: 'GET' });

  const query = useQuery({
    queryKey: ['organizationUsers', props.search.page, organization?._id, props.search.q, props.sort],
    queryFn,
    initialData: { docs: [] },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    cacheTime: 1000,
    enabled: !!organization?._id,
  });

  return query;
}
