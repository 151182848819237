import React from 'react';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { Col } from 'antd';
import { isEmpty } from 'lodash';
import { array, object, func, string } from 'prop-types';
import { Link } from 'react-router-dom';
import Informal1to1Card
  from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Informal1to1/components/Informal1to1Card';
import { participantActions } from '@/store/participant';
import { appointmentActions } from '@/store/appointment';
import { useGetInteractions } from '@/queries/interactions/useGetInterations';
import { getAuthUser } from '@/store/auth/selectors';
import { useGetParticipant } from '@/queries/participants/useGetParticipant';
import Card from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Exponents/scenes/Exponent/components/Card';
import { trackCandidateClickedOnCardToSignup } from '@/utils/analytics';
import { HASH } from '@/scenes/Event/containers/Landing/const';

const CardInformal1to1s = ({
  informal1to1s,
  event,
  createInformal1To1,
  cancelAppointment,
  redirection
}) => {
  const authUser = useSelector(getAuthUser)?.toJS();
  const interactions = useGetInteractions({ eventSlug: event.slug, body: { users: authUser?._id }, enabled: !isEmpty(authUser)});
  const participant = useGetParticipant({ eventId: event._id, userId: authUser?._id, enabled: !isEmpty(authUser) });

  return (
    (informal1to1s?.data?.pages)?.map(page => page?.docs?.map((informal1to1, i) => (
        <Col xs={12} sm={6} key={`${i}informal1to1.informalDescription`}>
          <Card>
            <Link
              to={redirection ?? ''}
              onClick={() => { trackCandidateClickedOnCardToSignup({ from: HASH.informal1to1s });}}
              target="_blank"
            >
              <Informal1to1Card
                exponentUser={informal1to1}
                event={event}
                createInformal1To1={createInformal1To1}
                cancelAppointment={cancelAppointment}
                participant={participant.data ?? []}
                interactions={interactions.data ?? []}
                isLight
              />
            </Link>
          </Card>
        </Col>
      )
    )) || null
  )
}

CardInformal1to1s.propTypes = {
  informal1to1s: array,
  event: object,
  createInformal1To1: func,
  cancelAppointment: func,
  redirection: string
}

const mapDispatchToProps = {
  createInformal1To1: participantActions.createInformal1To1,
  cancelAppointment: appointmentActions.cancelAppointment,
};

const withConnect = connect(undefined, mapDispatchToProps);

export default compose(
  withConnect,
)(injectIntl(CardInformal1to1s));
