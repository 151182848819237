import { defineMessages } from 'react-intl';

export default defineMessages({
  accepted: {
    id: 'appointment.status.accepted',
  },
  confirmed: {
    id: 'appointment.status.accepted',
  },
  acceptedAppointment: {
    id: 'appointment.status.accepted[0]',
  },
  refused: {
    id: 'appointment.status.refused',
  },
  refused_by_candidate: {
    id: 'appointment.status.refused.by.candidate',
  },
  refused_by_recruiter: {
    id: 'appointment.status.refused.by.recruiter',
  },
  canceled_by_candidate: {
    id: 'appointment.status.canceled.by.candidate',
  },
  canceled_by_recruiter: {
    id: 'appointment.status.canceled.by.recruiter',
  },
  refusedAppointment: {
    id: 'appointment.status.refused[0]',
  },
  transferred: {
    id: 'recruiter.participantView.interview.transferred',
  },
  canceled: {
    id: 'appointment.status.refused',
  },
  pending: {
    id: 'appointment.status.pending',
  },
  pendingAppointment: {
    id: 'appointment.status.pending[0]',
  },
});
