import React from 'react';
import { any, bool, object, string } from 'prop-types';
import classnames from 'classnames';
import { useFormatMessage } from 'react-intl-hooks';
import { IconCalendar } from '@seekube-tech/ui-kit';
import { dateFormat } from '@/utils/format';
import { getEarliestKeyMoment, getFarthestKeyMoment, hasValidKeyMoments } from '@/utils/keyMoments';
import { StatPreparationCompanies } from '../StatPreparationCompanies';
import { StatsParticipants } from '../StatsParticipants';
import { StatsAppointments } from '../StatsAppointments';
import styles from '../../styles.less';

const StatsSections = ({ event, stats, phases, view }) => {
  const t = useFormatMessage();
  const format = view === 'all' ? undefined : view;
  const hasInteractionsClosingDates = hasValidKeyMoments(event, 'interactionsClosing')

  const goals = event?.keyMomentsGoals?.[format || event.format];

  return (
    <div className={styles.container}>
      <StepSection
        title={t({ id: 'owner.dashboard.stats.title.company.onboarding'})}
        open={phases.enableAddingExponent}
        active={phases.activeStep === 0}
      >
        <StatPreparationCompanies stats={stats.exponents} phases={phases} active={phases.enableAddingExponent} goals={goals} view={view} />
      </StepSection>
      <StepSection
        open={phases.subscriptionCandidate}
        active={phases.activeStep === 1}
        title={t({ id: 'owner.dashboard.stats.title.candidate.registration' })}
        subtitle={<><IconCalendar />{dateFormat(getEarliestKeyMoment(event, 'discovering', format), null)}</>}
      >
        <StatsParticipants stats={stats.participants} phases={phases} active={phases.subscriptionCandidate} goals={goals} view={view} />
      </StepSection>
      <StepSection
        open={phases.appointments}
        active={phases.activeStep === 2}
        title={t({ id: 'owner.dashboard.stats.title.rdv' })}
        subtitle={<><IconCalendar />{dateFormat(getEarliestKeyMoment(event, 'hunt', format), event.skipAppointmentValidation && hasInteractionsClosingDates ? getEarliestKeyMoment(event, 'interactionsClosing', format) : getFarthestKeyMoment(event, 'jobfair', format))}</>}
      >
        <StatsAppointments stats={stats.exponents} active={phases.subscriptionCandidate} goals={goals} showUnconfirmed={event.skipAppointmentValidation} view={view} />
      </StepSection>
    </div>
  );
};


const StepSection = ({ title, subtitle, active = false, open, children }) => {
  const activeSection = active ? styles.active : null;
  const openSection = !open ? styles.pending : null;

  return (
    <div className={classnames(styles.stepSection, activeSection, openSection)}>
      <div className={styles.stepSectionHeader}>
        <h3 className={styles.title}>{title}</h3>
        <h3 className={styles.subtitle}>{subtitle}</h3>
      </div>
      {children}
    </div>
  );
};

StepSection.propTypes = {
  title: string,
  subtitle: any,
  active: bool,
  open: bool,
  children: any,
};

StatsSections.propTypes = {
  event: object,
  phases: object,
  stats: object,
  view: string
};

export { StatsSections };
