import React, { useState } from 'react';
import { array, func, oneOf } from 'prop-types';
import { Checkbox } from 'antd';
import { includes } from 'lodash';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import styles from './styles.less';

const CheckboxGroup = Checkbox.Group;

const TARGET_FILTERS = ['tech', 'business', 'engineer', 'other'];

const TARGET_FILTERS_INTL = {
  tech: 'technical.short',
  business: 'business',
  engineer: 'engineer.short',
  other: 'other',
};

const TargetFilter = ({ targetFilters = TARGET_FILTERS, targets, onTargetsChange }) => {
  const [targetsChecked, setTargetChecked] = useState(targets || [])

  const setTargetsChecked = (targetsChecked) => {
    setTargetChecked(targetsChecked);
    onTargetsChange(targetsChecked)
  };

  const getTargetCheckedClassname = (value) => isTargetChecked(value) ? styles.selected : '';

  const isTargetChecked = (value) => includes(targetsChecked, value);

  return (
    <CheckboxGroup defaultValue={targets} onChange={setTargetsChecked} className={styles.targets}>
      {
        targetFilters.map((datum, i) =>
          <Checkbox
            key={i}
            className={classnames(styles.target, getTargetCheckedClassname(datum))}
            value={datum}
          >
            <FormattedMessage id={TARGET_FILTERS_INTL[datum]} />
          </Checkbox>)
      }
    </CheckboxGroup>
  );
}

TargetFilter.propTypes = {
  targets: array,
  onTargetsChange: func,
  targetFilters: oneOf(TARGET_FILTERS),
};

export { TargetFilter };
