import { makeStyles } from '@seekube-tech/ui';

export default makeStyles(theme => ({
  asterisk: {
    color: theme.palette.error[500],
  },
  container: {
    position: 'relative',
    padding: theme.spacing(1.5),
    width: 138,
    border: `1px solid ${theme.palette.neutral[200]}`,
    borderRadius: 5,
    backgroundColor: theme.palette.basic.white,
  },
  arrow: {
    position: 'absolute',
    top: -5,
    left: 'calc(50% - 5px)',
    width: 10,
    height: 10,
    border: `1px solid ${theme.palette.neutral[200]}`,
    borderRadius: 2,
    backgroundColor: theme.palette.basic.white,
    transform: 'rotate(45deg)',

    '&:before': {
      content: '""',
      position: 'absolute',
      zIndex: 1,
      height: 12,
      width: 12,
      margin: 2,
      backgroundColor: theme.palette.basic.white,
      transform: 'rotate(-45deg)',
    },
  },
  button: {
    height: 22,
    width: 22,
    borderRadius: 3,
    border: 'none',
    cursor: 'pointer',
  },
  preview: {
    height: 32,
    width: 32,
    borderRadius: 16,
    marginTop: theme.spacing(1.5),
  }
}));