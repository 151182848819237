import React, { useEffect, useState } from 'react';
import { createStructuredSelector } from 'reselect';
import { object, array, func } from 'prop-types';
import { map, filter, find, isEmpty, get, size, reduce, forEach, intersection } from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { Form } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button } from '@seekube-tech/ui-kit';
import { Contracts, Locations } from '@/helpers/criterion';
import { eventActions } from '@/store/event';
import { userSelectors } from '@/store/user';
import { notificationActions } from '@/store/notification';
import { withAuth } from '@/containers/RequiredAuth';
import { getId, getIds } from '@/utils/global';
import { toJS } from '@/utils';


import Icon from '@/components/Icon';
import { Tooltip } from '@/components/Tooltip';

import {
  OfferContractsForm
} from '@/scenes/Client/scenes/EditOffer/components/EditOfferStep1/components/OfferContractsForm';
import { OfferOwnerForm } from '../../../EditOffer/components/EditOfferStep1/components';

import messages from '../../../EditOffer/components/EditOfferStep1/messages';
import styles from './components/PickRecruiterOffer/styles.less';
import stylesBtn from '../../../EditOffer/styles.less';

const Step2 = ({
  offers,
  intl,
  form,
  handleNext,
  exponents,
  getEvents,
  handleActions,
  sendNotification,
  eventContracts = []
}) => {
  const eventId = window.localStorage.getItem('eventSelected');
  const exponentUsers = filter(find(exponents, (exp) => getId(exp._event) === eventId).users, (exp) => !isEmpty(exp._user));
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    getEvents({ eventsId: getIds(JSON.parse(window.localStorage.getItem('offersSelected'))) });
  }, [offers]);

  useEffect(() => {
    checkIfBtnIsDisabled();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, fields) => {
      window.localStorage.setItem('offers', JSON.stringify(fields));
      handleNext(offers.map((off) => ({ ...off, _user: findInExponentUser(fields[getId(off)]._user), matching: { ...off.matching, CONTRACT: fields[getId(off)]._contract } })));
    });
  };

  const findInExponentUser = (userId) => get(find(exponentUsers, (expU) => getId(expU._user) === userId), '_user');

  const checkIfBtnIsDisabled = (userId, offerId) => {
    if (isEmpty(userId)) {
      forEach(offers,
        (offer) => {
          if (isEmpty(get(find(exponentUsers, (expU) => getId(expU._user) === getId(offer._user)), '_user._id'))) {
            setIsButtonDisabled(true);
            return false;
          }
          return true;
        }
      );
    } else {
      form.setFieldsValue({ [`${offerId}._user`]: userId });

      const fieldsValues = form.getFieldsValue();
      const areFieldsValid = Object.values(fieldsValues).filter(values => values).every(({ _user, _contract }) => !isEmpty(_user) && (isEmpty(eventContracts) || !isEmpty(_contract)));

      setIsButtonDisabled(!areFieldsValid);
    }
  };

  const changeContracts = (offerId, name, values) => {
    form.setFieldsValue({ [name]: values });
    checkIfBtnIsDisabled(form.getFieldValue(`${offerId}._user`), offerId);
  }

  return (
    <div className={styles.step2Container}>
      <FormattedMessage id="recruiter.offers.spread.select.recruiters.title" values={{ count: size(offers) }} tagName="h1" />
      <FormattedMessage id="recruiter.offers.spread.select.recruiters.subtitle" values={{ count: size(offers) }} tagName="h3" />

      <div className={styles.recruiterContainer}>
        <Form layout="vertical" onSubmit={handleSubmit}>

          {
            map(offers, (offer, i) => {
              const defaultRecruiter = get(find(exponentUsers, (expU) => getId(expU._user) === getId(offer._user)), '_user._id') || null;
              const contractsSelected = intersection(offer.matching.CONTRACT, eventContracts.map(contract => contract._id));

              return (
                <div key={i}>
                  <div className={styles.offerTitle}>
                    <a href={`/client/offer-edit?offerId=${getId(offer)}&preview=true`} target="_blank">
                      <Tooltip
                        title={intl.formatMessage({ id: 'recruiter.offers.spread.select.recruiter.tooltip.preview' })}
                        color="dark"
                        placement="bottom"
                      >
                        {offer.title}
                      </Tooltip>
                    </a>
                    <Tooltip
                      title={<FormattedMessage id="recruiter.offers.spread.remove.tooltip" />}
                      color="dark"
                      key={0}
                    >
                      <a
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                          handleActions('setOffersSelected', offer);
                          sendNotification({
                            message: <span><span
                              aria-label=""
                              role="img"
                              style={{ marginRight: '10px' }}
                            >🙌</span>{intl.formatMessage({ id: 'recruiter.offers.spread.remove.toaster.success' })}</span>,
                          });
                        }}
                      >
                        <Icon name="trash" className={styles.trashIcon} />
                      </a>
                    </Tooltip>
                  </div>
                  <Contracts matching={offer.matching} _criteria={get(offer, '_event._criteria')} />
                  <Locations locations={offer.locations} />
                  <OfferOwnerForm
                    initialValue={defaultRecruiter}
                    fieldDecoratorName={`${getId(offer)}._user`}
                    selectProps={{ addOnIcon: null }}
                    handleOnOfferOwnerChange={(userId) => checkIfBtnIsDisabled(userId, getId(offer))}
                    placeholder={intl.formatMessage(messages.placeholderOfferOwner)}
                    intl={intl}
                    users={map(exponentUsers, (u) => u._user) || []}
                    context="offer"
                    {...form}
                  />
                  <OfferContractsForm
                    initialValue={contractsSelected}
                    users={[]}
                    contracts={eventContracts}
                    fieldDecoratorName={`${getId(offer)}._contract`}
                    disabled={isEmpty(eventContracts)}
                    handleChange={(value) => changeContracts(getId(offer), `${getId(offer)}._contract`, value)}
                    { ...form }
                  />

                </div>
              );
            })
          }
          <div className={stylesBtn.submitContainer}>
            <Button variant="tonal" className="mr-6" onClick={() => handleActions('openBackModal')}>
              {intl.formatMessage({ id: 'cancel' })}
            </Button>
            <Button disabled={isButtonDisabled} type="submit">
              {intl.formatMessage({ id: 'next' })}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

Step2.propTypes = {
  offers: array,
  intl: object,
  form: object,
  handleNext: func,
  handleActions: func,
  exponents: array,
  getEvents: func,
  sendNotification: func,
  eventContracts: array
};

const mapStateToProps = createStructuredSelector({
  users: userSelectors.getUsersOrganization,
});


const mapDispatchToProps = {
  getEvents: eventActions.getEvents,
  sendNotification: notificationActions.sendNotification,
};


const withForm = Form.create();

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withForm,
  withRouter,
  withConnect,
  withAuth(),
  injectIntl,
)(toJS(Step2));
