import { createAction } from 'redux-actions';
import * as types from './types';

export const getAppointment = createAction(types.GET_APPOINTMENT);
export const getAppointmentSuccess = createAction(types.GET_APPOINTMENT_SUCCESS);

export const getAppointments = createAction(types.GET_APPOINTMENTS);
export const getAppointmentsSuccess = createAction(types.GET_APPOINTMENTS_SUCCESS);

export const selectAppointmentSlot = createAction(types.SELECT_APPOINTMENT_SLOT);
export const selectAppointmentSlotSuccess = createAction(types.SELECT_APPOINTMENT_SLOT_SUCCESS);

export const cancelAppointment = createAction(types.CANCEL_APPOINTMENT);
export const modifyAppointment = createAction(types.MODIFY_APPOINTMENT);
export const modifyAppointmentSuccess = createAction(types.MODIFY_APPOINTMENT_SUCCESS);

export const downloadAppointments = createAction(types.DOWNLOAD_APPOINTMENTS);
export const downloadAppointmentsSuccess = createAction(types.DOWNLOAD_APPOINTMENTS_SUCCESS);


/**
 * Action for get appointments of a in a event
 */
export const getUserAppointments = createAction(types.GET_USER_APPOINTMENTS);
export const getUserAppointmentsSuccess = createAction(types.GET_USER_APPOINTMENTS_SUCCESS);
export const getUserAppointmentsError = createAction(types.GET_USER_APPOINTMENTS_ERROR);


/**
 * Action for post new action
 */
export const patchAppointment = createAction(types.PATCH_APPOINTMENT);
export const patchAppointmentSuccess = createAction(types.PATCH_APPOINTMENT_SUCCESS);
export const patchAppointmentError = createAction(types.PATCH_APPOINTMENT_ERROR);

/**
 * Action for refuse an appointment
 */
export const refuseAppointment = createAction(types.REFUSE_APPOINTMENT);
export const refuseAppointmentSuccess = createAction(types.REFUSE_APPOINTMENT_SUCCESS);
export const refuseAppointmentError = createAction(types.REFUSE_APPOINTMENT_ERROR);

/**
 * Action for refuse an appointment
 */
export const patchAppointmentDate = createAction(types.PATCH_APPOINTMENT_DATE);
export const patchAppointmentDateSuccess = createAction(types.PATCH_APPOINTMENT_DATE_SUCCESS);
export const patchAppointmentDateError = createAction(types.PATCH_APPOINTMENT_DATE_ERROR);

/**
 * Action for post new action
 */
export const changeAppointmentOwner = createAction(types.CHANGE_APPOINTMENT_OWNER);
export const changeAppointmentOwnerSuccess = createAction(types.CHANGE_APPOINTMENT_OWNER_SUCCESS);
export const changeAppointmentOwnerError = createAction(types.CHANGE_APPOINTMENT_OWNER_ERROR);

/**
 * Action for relaunch an appointment
 */
export const relaunchAppointment = createAction(types.RELAUNCH_APPOINTMENT);
export const relaunchAppointmentSuccess = createAction(types.RELAUNCH_APPOINTMENT_SUCCESS);
export const relaunchAppointmentError = createAction(types.RELAUNCH_APPOINTMENT_ERROR);

/**
 * Action for confirm an appointment
 */
export const confirmAppointment = createAction(types.CONFIRM_APPOINTMENT);
export const confirmAppointmentSuccess = createAction(types.CONFIRM_APPOINTMENT_SUCCESS);
export const confirmAppointmentError = createAction(types.CONFIRM_APPOINTMENT_ERROR);
