import React, { memo } from 'react';
import { H3, Subtitle1 } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import styles from './styles.less';
import SearchEvents from '@/scenes/Events/scenes/Explore/Candidate/components/SearchEvents';
import { object } from 'prop-types';

const CandidateExplorer = ({ authUser }) => {
  const t = useFormatMessage();
  
  return (
    <div className={styles.container}>
      <div>
        <H3>{t({ id: 'events.explore.title' })}</H3>
        <Subtitle1 className="text-neutral-400">{t({ id: 'events.explore.subtitle' })}</Subtitle1>
      </div>
      <SearchEvents authUser={authUser} />
    </div>
  )
}

CandidateExplorer.propTypes = {
  authUser: object
}

export default memo(CandidateExplorer);