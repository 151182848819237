import React, { useState } from 'react';
import { Alert, Body1, H5 } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { func } from 'prop-types';
import { Bloc } from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/components/Bloc';
import TagNew from '@/components/TagNew/TagNew';
import CvUpload from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/Profile/CvCatcher/CvUpload';
import { useAnalyzeCv } from '@/queries/cvc/useAnalyzeCv';
import styles from './styles.less';

const CvCatcher = ({
  onGetCvFinished,
  onClickUpload
}) => {
  const t = useFormatMessage();
  const [file, setFile] = useState(null);
  const mutateAnalizeCv = useAnalyzeCv({ onSuccess: () => {} });

  const handleChangeFile = ({ file }) => {
    if (file !== null) {
      onClickUpload();
      setFile(file);

      mutateAnalizeCv.mutate({ file, saveFile: true }, {
        onSuccess: (data) => {
          onGetCvFinished(data);
        },
        onError: () => onGetCvFinished(null)
      });
    }
  }

  const AlertFeedback = () => {
    const errorsLabelsFromHttpCode = {
      504: 'timeout',
      413: 'toolarge',
      500: 'defaulterror'
    }

    const errorsLabelsFromMessage = {
      toolarge: 'toolarge',
    }

    let color;
    let label = 'event.candidate.preparation.signup.step.cvc.feedback.';

    if (!mutateAnalizeCv.isError && !mutateAnalizeCv.isSuccess) {
      return null;
    }

    if (mutateAnalizeCv.isError) {
      color = 'error';
      label += errorsLabelsFromMessage[mutateAnalizeCv.error?.message] ?? errorsLabelsFromHttpCode[mutateAnalizeCv.error?.code] ?? 'defaulterror';
    }
    if (mutateAnalizeCv.isSuccess) {
      color = 'info';
      label += 'success';
    }

    return (
      <Alert color={color}>
        {t({ id: label })}
      </Alert>
    )
  }

  return (
    <Bloc>
      <div className={styles.cvcBloc}>
        <div className={styles.header}>
          <div className={styles.title}>
            <H5>
              {t({ id: 'event.candidate.preparation.signup.step.cvc' })}
            </H5>
            {/* TODO: this should be removed once the feature is not new anymore */}
            <TagNew />
          </div>
          <Body1 className="text-neutral-400">{t({ id: 'event.candidate.preparation.signup.step.cvc.help' })}</Body1>
        </div>
        <CvUpload
          onFileChange={handleChangeFile}
          fileTitle={file?.name}
          isLoading={mutateAnalizeCv.isLoading}
          analyzeSucceded={mutateAnalizeCv.isSuccess}
        />
        <AlertFeedback />
      </div>
    </Bloc>
  )
}

CvCatcher.propTypes = {
  onGetCvFinished: func,
  onClickUpload: func
}

export default CvCatcher;
