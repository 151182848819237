import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { bool, func, object } from 'prop-types';

import { Button } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import Separator from '@/components/Separator';
import ModalV2 from '@/components/ModalV2';

import messages from './messages';
import styles from './styles.less';

const RemoveExponentUserModal = ({ visible, handleCancel, onOk }) => {
  const t = useFormatMessage();

  return (
    <ModalV2
      template="default"
      visible={visible}
      onClose={handleCancel}
      className={styles.removeUserModal}
      handleCancel={handleCancel}
    >
      <div className={styles.modalHeader}>
        <FormattedMessage {...messages.removeAccount} tagName="h3"/>
      </div>

      <div className={styles.modalContent}>
        <FormattedHTMLMessage id="recruiter.delete.recruiter.from.jd" tagName="p"/>
      </div>
      <Separator height={15}/>

      <div className="confirm-actions">
        <Button
          variant="tonal"
          className="mr-6"
          onClick={handleCancel}
        >
          {t({ id: 'cancel'})}
        </Button>
        <Button onClick={onOk}>
          {t({ id: 'btn.confirm' })}
        </Button>
      </div>
    </ModalV2>
  );
};

RemoveExponentUserModal.propTypes = {
  visible: bool,
  onOk: func,
  handleCancel: func,
  intl: object,
};

export default React.memo(RemoveExponentUserModal);
