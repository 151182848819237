import React from 'react';
import { any, object, string, number } from 'prop-types';
import classnames from 'classnames';

import { Typography } from '@seekube-tech/ui';
import Avatar from '@/components/Avatar';

import styles from './styles.less';

export const RecruiterToContact = ({
  _organizationUser,
  title = _organizationUser.fullName || `${_organizationUser.firstName} ${_organizationUser.lastName}`,
  content,
  className,
  style,
  size = 60,
}) => (
  <div className={classnames(styles.recruiterCard, className)} style={style}>
    <div className={styles.leftSide}>
      <Avatar size={size} src={_organizationUser.pictureUrl} user={_organizationUser} />
    </div>
    <div className={styles.rightSide}>
      <div>
        <Typography variant="h5">{title}</Typography>
        <Typography variant="body2" color="neutral[300]">{content || _organizationUser.title}</Typography>
      </div>
    </div>
  </div>
);

RecruiterToContact.propTypes = {
  _organizationUser: object,
  title: string,
  content: string,
  className: any,
  size: number,
  style: any,
};

