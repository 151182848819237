import React from 'react';
import moment from 'moment';
import { string } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { IconCalendar } from '@seekube-tech/ui-kit';
import { useLocalStorage } from 'react-use';
import styles from '@/scenes/Event/containers/Landing/components/Contents/components/GeneralInfos/components/LandingContent/components/Header/components/JobfairDate/styles.less';

const JobfairDate = ({ beginAt, endAt, type = 'long', locale }) => {
  let beginDate;

  moment.locale(locale);

  const t = useFormatMessage();
  const momentBegin = moment(beginAt);
  const momentEnd = moment(endAt);
  const endDate = momentEnd?.format('Do MMMM YYYY');

  if (momentBegin.format('YYYY') !== momentEnd?.format('YYYY')) {
    beginDate = momentBegin.format('Do MMMM YYYY');
  } else if (momentBegin.format('MMMM') !== momentEnd?.format('MMMM')) {
    beginDate = momentBegin.format('Do MMMM');
  } else {
    beginDate = momentBegin.format('Do');
  }

  if (type === 'short') {
    return (
      <div className={styles.eventDateContainer} style={{ textTransform: 'capitalize' }}>
        <IconCalendar size={24} className={styles.calendarIcon} />
        <span>{momentEnd?.format('MMMM YYYY')}</span>
      </div>
    );
  }

  if (momentBegin.format('Do MMMM YYYY') === endDate) {
    return (
      <div className={styles.eventDateContainer}>
        <IconCalendar size={24} className={styles.calendarIcon} />
        <span>{momentEnd?.format('Do MMMM YYYY')}</span>
      </div>
    );
  }

  return (
    <div className={styles.eventDateContainer}>
      <IconCalendar size={24} className={styles.calendarIcon} />
      <span>{` ${t({ id: 'date.from' })} ${beginDate} ${t({ id: 'date.to' })} ${momentEnd?.format('Do MMMM YYYY')}`}</span>
    </div>
  );
};

JobfairDate.propTypes = {
  beginAt: string,
  type: string,
  endAt: string,
  locale: string
};

export default React.memo(JobfairDate);
