import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'event.candidate.appointments.title',
  },
  blankTitle: {
    id: 'event.candidate.appointments.blank.title',
  },
  blankAction: {
    id: 'event.candidate.appointments.blank.action',
  },
  blankText: {
    id: 'event.candidate.appointments.blank.text',
  },
  refuseProposition: {
    id: 'event.candidate.appointment.cancel',
  },
  cancelApplication: {
    id: 'appointment.cancelApplication',
  },
  cancelAppointment: {
    id: 'appointment.cancel',
  },
  cancel: {
    id: 'appointment.cancel',
  },
  edit: {
    id: 'appointment.edit',
  },
  becoming: {
    id: 'event.candidate.appointments.becoming',
  },
  pending: {
    id: 'event.candidate.appointments.pending',
  },
  accepted: {
    id: 'event.candidate.appointments.accepted',
  },
  refused: {
    id: 'event.candidate.appointments.refused',
  },
  done: {
    id: 'event.candidate.appointments.done',
  },
  cancelApplicationModal: {
    id: 'event.candidate.jobdating.application.cancel.modal',
  },
  cancelModal: {
    id: 'cancel',
  },
  btnConfirmModal: {
    id: 'btn.confirm',
  },
  noShow: {
    id: 'event.candidate.appointment.noShow',
  },
});
