import { defineMessages } from 'react-intl';

export default defineMessages({
  registrationTitle: {
    id: 'event.owner.statistics.exponents.registration.title',
  },
  registrationCompaniesReady: {
    id: 'event.owner.statistics.exponents.registration.companies.ready',
  },
  consultationTitle: {
    id: 'event.owner.statistics.exponents.consultation.title',
  },
  consultationTotalTooltip: {
    id: 'event.owner.statistics.exponents.consultation.total.tooltip',
  },
  consultationCompaniesTooltip: {
    id: 'event.owner.statistics.exponents.consultation.companies.tooltip',
  },
  consultationCompaniesPercentageTooltip: {
    id: 'event.owner.statistics.exponents.consultation.companies.percentage.tooltip',
  },
  consultationAverageTooltip: {
    id: 'event.owner.statistics.exponents.consultation.average.tooltip',
  },
  propositionTitle: {
    id: 'event.owner.statistics.exponents.proposition.title',
  },
  propositionTotalTooltip: {
    id: 'event.owner.statistics.exponents.proposition.total.tooltip',
  },
  propositionCompaniesTooltip: {
    id: 'event.owner.statistics.exponents.proposition.companies.tooltip',
  },
  propositionCompaniesPercentageTooltip: {
    id: 'event.owner.statistics.exponents.proposition.companies.percentage.tooltip',
  },
  propositionAverageTooltip: {
    id: 'event.owner.statistics.exponents.proposition.average.tooltip',
  },
  cvDownloadedTitle: {
    id: 'event.owner.statistics.exponents.cvDownloaded.title',
  },
  cvDownloadedTotalTooltip: {
    id: 'event.owner.statistics.exponents.cvDownloaded.total.tooltip',
  },
  cvDownloadedCompaniesTooltip: {
    id: 'event.owner.statistics.exponents.cvDownloaded.companies.tooltip',
  },
  cvDownloadedCompaniesPercentageTooltip: {
    id: 'event.owner.statistics.exponents.cvDownloaded.companies.percentage.tooltip',
  },
  cvDownloadedAverageTooltip: {
    id: 'event.owner.statistics.exponents.cvDownloaded.average.tooltip',
  },
  processingApplicationsTitle: {
    id: 'event.owner.statistics.exponents.processingApplications.title',
  },
  processingApplicationsPendingTitle: {
    id: 'event.owner.statistics.exponents.processingApplications.pending.title',
  },
  processingApplicationsPendingTooltip: {
    id: 'event.owner.statistics.exponents.processingApplications.pending.tooltip',
  },
  processingApplicationsPendingPercentageTooltip: {
    id: 'event.owner.statistics.exponents.processingApplications.pending.percentage.tooltip',
  },
  processingApplicationsRefusedTooltip: {
    id: 'event.owner.statistics.exponents.processingApplications.refused.tooltip',
  },
  processingApplicationsRefusedPercentageTooltip: {
    id: 'event.owner.statistics.exponents.processingApplications.refused.percentage.tooltip',
  },
  processingApplicationsAcceptedTooltip: {
    id: 'event.owner.statistics.exponents.processingApplications.accepted.tooltip',
  },
  processingApplicationsAcceptedPercentageTooltip: {
    id: 'event.owner.statistics.exponents.processingApplications.accepted.percentage.tooltip',
  },
  interviewValidated: {
    id: 'event.owner.statistics.exponents.interview.validated',
  },
  timeslotsTitle: {
    id: 'event.owner.statistics.exponents.timeslots.title',
  },
  timeslotsCreatedTitle: {
    id: 'event.owner.statistics.exponents.timeslots.created.title',
  },
  timeslotsEnableTitle: {
    id: 'event.owner.statistics.exponents.timeslots.enable.title',
  },
  timeslotsEnablePercentageTooltip: {
    id: 'event.owner.statistics.exponents.timeslots.enabled.percentage.tooltip',
  },
  appointmentsValidatedTitle: {
    id: 'event.owner.statistics.stats.jobdating.interview.title',
  },
  interviewCompaniesTooltip: {
    id: 'event.owner.statistics.stats.exponents.interview.companies.tooltip',
  },
  interviewCompaniesPercentageTooltip: {
    id: 'event.owner.statistics.stats.exponents.interview.companies.percentage.tooltip',
  },
  interviewCompaniesAverageTooltip: {
    id: 'event.owner.statistics.stats.interview.average.tooltip',
  },
  interviewCanceledCompaniesTooltip: {
    id: 'event.owner.statistics.stats.exponents.interviewCanceled.companies.tooltip',
  },
  interviewCanceledCompaniesPercentageTooltip: {
    id: 'event.owner.statistics.stats.exponents.interviewCanceled.companies.percentage.tooltip',
  },
  interviewCanceledCompaniesAverageTooltip: {
    id: 'event.owner.statistics.stats.exponents.interviewCanceled.companies.average.tooltip',
  },
});
