import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { Subtitle2, H3 } from '@seekube-tech/ui-kit';
import colors from '@seekube-tech/ui-kit/dist/colors';
import classNames from 'classnames';
import { object } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { Col, Form, Row } from 'antd';
import { useHistory } from 'react-router-dom';
import styles
  from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/ParticipationMode/styles.less';
import { participantActions } from '@/store/participant';
import { toJS } from '@/utils';
import {
  ParticipationPhysical
} from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/ParticipationMode/ParticipationPhysical';
import {
  ParticipationVirtual
} from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/ParticipationMode/ParticipationVirtual';
import { Bloc } from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/components/Bloc';
import { getCurrentParticipant } from '@/store/participant/selectors';
import {
  FooterActions
} from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/ParticipationMode/FooterActions';
import {
  getKeyMomentLinks
} from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/ParticipationMode/utils';
import Wrapper from '@/components/Wrapper';
import { trackOnboardingEnd } from '@/utils/analytics/trackers/category/eventOnboarding';
import { LOCAL_STORAGE, setLocalStorage } from '@/utils/localStorage';

const ParticipationMode = ({ event, form, authUser }) => {
  const t = useFormatMessage();
  const dispatch = useDispatch();
  const participant = useSelector(getCurrentParticipant)?.toJS();
  const history = useHistory();


  const handleOnSubmit = (e) => {
    e.preventDefault();

    return form.validateFields((err, values) => {
      if (err) { return null }
      const _keyMomentLinks = getKeyMomentLinks(form, event);

      return dispatch(participantActions.patchParticipant({
        participantId: participant._id,
        eventId: event._id,
        participantParams: {
          isActive: true,
          _keyMomentLinks: _keyMomentLinks || participant._keyMomentLinks,
        },
        slug: event.slug,
        callback: () => {
          trackOnboardingEnd(authUser, event, 'participation_mode');
          
          setLocalStorage(LOCAL_STORAGE.event.signUpSuccess, 'true');

          history.replace(`/${event.participantRedirectionUrl}`);
        }
      }))
    })
  }

  return (
    <Wrapper size="large">
      <div className={classNames(styles.formatChoiceContainer)}>
        <Form onSubmit={handleOnSubmit}>
          <Bloc>
            <H3 className="mb-8">{t({ id: 'event.recruiter.preparation.timeslots.event.format' })}</H3>
            <Subtitle2 className={styles.subtitle} color={colors.neutral['400']}>
              {t({ id: 'event.recruiter.preparation.timeslots.event.format.description' }, { name: event.name })}
            </Subtitle2>

            {participant &&
              <div>
                <Row gutter={[16, 16]} type="flex">
                  <Col xs={12}>
                    <ParticipationPhysical event={event} form={form} participant={participant} />
                  </Col>
                  <Col xs={12}>
                    <ParticipationVirtual event={event} form={form} participant={participant} />
                  </Col>
                </Row>
              </div>}
          </Bloc>

          <FooterActions form={form} />
        </Form>
      </div>
    </Wrapper>
  );
}

ParticipationMode.propTypes = {
  event: object,
  authUser: object,
  form: object,
}

const withForm = Form.create();

export default compose(
  withForm,
)(toJS(ParticipationMode));
