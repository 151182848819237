import styles from '@/scenes/Event/components/StaticOffers/styles.less';
import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import BlankState from '@/components/BlankState';

const OffersBlankState = ({
  countSearchOffers
}) => {
  const t = useFormatMessage();

  if (countSearchOffers > 0 || countSearchOffers === null) {
    return null;
  }

  return (
    <BlankState
      className={styles.blankState}
      title={
        <>
          <div>{t({ id: 'event.candidate.noOffer.title' })}</div>
          <div>{t({ id: 'event.candidate.noOffer.content' })}</div>
        </>}
      largeIcon
      icon="blank-state-cactus"
    />
  )
}

export default OffersBlankState;
