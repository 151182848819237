import React, { useEffect } from 'react';
import { array, func, object } from 'prop-types';
import validator from 'validator';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import renderHTML from 'react-render-html';
import { isEmpty, isEqual, size } from 'lodash';
import { Button, Alert } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { useSelector } from 'react-redux';
import { isRecruiterLimitExceeded, doesRecruiterSelectedExceedLimit } from '@/utils/pipeDrive';
import isEmail from '@/utils/validations/email';

// Components

import { If } from '@/components/If';
import Avatar from '@/components/Avatar';
import InputMaterial from '@/components/Input/InputMaterial';
import Icon from '@/components/Icon';


// Styles & Translations
import {
  SetRoleButton
} from '@/scenes/Event/scenes/Owner/scenes/OnBoarding/containers/CollaboratorModal/components/OrganizationUsers/components';
import { getRolesByKey } from '@/store/role/selectors';
import { roles } from '@/store/role/helpers';
import { getId, getIds } from '@/utils/global';
import { strictIncludes } from '@/utils/math';
import styles from './styles.less';

// Antd
let mainNode = null;
let mounted = false;

const OrganizationUsers  = (props) => {
  const { organization, usersInExponent = [], exponent = { users: [] }, defaultUsersToAdd = [], error, usersOrganization, onAddUserInExponent, authUser, planners = [] } = props;
  const t = useFormatMessage();
  const rolesByKey = useSelector(getRolesByKey);

  const [dropdownIsVisible, setDropdownIsVisible] = React.useState(false);
  const [value, setValue] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isFetching, setIsFetching] = React.useState(false);
  const [usersToAdd, setUsersToAdd] = React.useState(defaultUsersToAdd);
  const [formatError, setFormatError] = React.useState('');

  useEffect(() => {
    mainNode = null;
    mounted = true;
    document.addEventListener('click', handleDocumentClick, false);
    document.addEventListener('touchend', handleDocumentClick, false);

    return () => {
      setUsersToAdd([])
      mounted = false;
      document.removeEventListener('click', handleDocumentClick, false);
      document.removeEventListener('touchend', handleDocumentClick, false);
    }
  }, []);


  const getContext = () => {
    const { location: { pathname } } = window;
    if (pathname.indexOf('admin/organizations') > -1 && pathname.indexOf('exponents') > -1) return 'admin_exponents';
    if (pathname.indexOf('client/member/by-event')  > -1) return 'client_exponents';
    if (pathname.indexOf('client/member/by-collaborator')  > -1) return 'client_users';
    if (pathname.indexOf('client') > -1) return 'client';
    if (pathname.indexOf('owner/exponents') > -1) return 'owner_exponents';
    if (pathname.indexOf('owner/settings') > -1) return 'owner_settings';
    if (pathname.indexOf('users') > -1) return 'admin_users';

    return 'team';
  };

  const getUserAvailable = (users) => {
    const context = getContext();

    switch (context) {
      case 'admin_exponents':
      case 'owner_exponents':
      case 'client_exponents':
        return users.filter((user) => !strictIncludes(exponent.users.map(u => getId(u._user)), getId(user)));

      case 'owner_settings':
        return users.filter((user) => !strictIncludes(getIds(planners?._users), getId(user)));

      case 'admin_users':
      case 'client_users':
        return [];

      default: return users;
    }

  };

  function handleDocumentClick (event) {
    if (mounted) {
      if (!mainNode.contains(event.target) && dropdownIsVisible) {
        setDropdownIsVisible(false);
      }
    }
  }

  const toggleDropdown = () => {
    if (authUser.isAdmin) {
      setDropdownIsVisible(!dropdownIsVisible);
    }
  };


  const handleOnChangeInput = (event) => {
    const value = event.target.value.toLowerCase().replace(/ /g, '');
    const dropdownIsVisible = authUser.isAdmin || value.length >= 3;

    setValue(value);
    setErrorMessage('');
    setDropdownIsVisible(dropdownIsVisible);
  };


  const hideUser = ({ fullName, username }) => {
    if (value === '') {
      return true;
    }

    return (fullName && fullName.toLowerCase().includes(value)) || (username && username.toLowerCase().includes(value));
  };


  const userIsInExponent = (user) => usersInExponent.find((item) => user && item._user && item._user._id === user._id) !== undefined;

  const handleOnAddUserInArray = (user) => {
    setDropdownIsVisible(false);
    const index = usersToAdd.findIndex((item) => isEqual(item._user, user));

    if (index === -1) {
      setUsersToAdd([{ _user: user }, ...usersToAdd]);
    }
  };

  const handleOnRemoveUserInArray = (user) => {
    const newUsersToAdd = usersToAdd.filter((userToAdd) => !isEqual(userToAdd._user.username, user.username));

    setUsersToAdd(newUsersToAdd);
  };

  const handleOnChangeRole = (user, roleId) => {
    const newUsersToAdd = usersToAdd.map((item) => {
      if (item._user.username === user.username) {
        return ({ ...item, _user: { ...item._user, roleId }, roleId })
      }

      return ({ ...item })
    });

    setUsersToAdd(newUsersToAdd);
  };


  const handleOnAddUser = () => {
    setIsFetching(true);
    setDropdownIsVisible(false);

    return onAddUserInExponent(usersInExponent, usersToAdd);
  };


  const handleCreateUser = () => {
    if (!validator.isEmail(value) || (!isEmail(value) && !isEmpty(value))) {
      setFormatError(t({ id: 'form.error.email.format' }));
      return false;
    }

    const exist = usersInExponent.findIndex((item) => item._user && item._user.username === value);

    if (exist > -1) {
      setErrorMessage('Collaborateur déjà ajouté au forum')
      return false;
    }

    handleOnAddUserInArray({
      username: value,
      fullName: value,
      title: '',
      firstName: '',
      lastName: '',
      roles: [],
      roleId: exponent?._organization?.isClient ? rolesByKey[roles.member]._id : undefined,
    });

    setValue('');
    setFormatError('');

    return true;
  };

  const highlightText = (text, value) => {
    if (value.trim().length > 0) {
      return renderHTML(text.replace(new RegExp(value, 'ig'), `<em>${value}</em>`));
    }

    return text;
  };

  const returnUsersInTheOrganization = (users, value) => {
    const usersList = usersToAdd.map((item) => item._user);

    const filterUsers = getUserAvailable(users)
    .filter((user) => !userIsInExponent(user) && !usersList.includes(user));

    return (
      <ul className={styles.checkboxList}>
        {
          filterUsers.length !== 0 ?
            filterUsers.map((user, index) => (
              <li
                key={index}
                style={{ display: hideUser(user) ? 'flex' : 'none' }}
                className={styles.userItem}
                onClick={() => handleOnAddUserInArray(user)}
                role="button"
              >
                <Avatar src={user.pictureUrl} size={28} user={user} />
                <strong className={styles.userLabel}>{highlightText(user.fullName, value)}
                  <small>{authUser.isAdmin && user.fullName !== user.username ? highlightText(user.username, value) : ''}</small></strong>
              </li>
            ))
            : <li className={styles.noResult}>{t({ id: 'noResult' })}</li>
        }
      </ul>
    );
  };

  const returnUsersToAddInOrganization = (isUserLimitExceeded) => {
    if (isEmpty(usersToAdd)) return '';

    return usersToAdd.map((item, index) => (
      <div key={index} className={classNames(styles.userCard, isUserLimitExceeded && doesRecruiterSelectedExceedLimit(index, exponent) && ['team', 'admin_exponents'].includes(getContext()) ? styles.recruiterLimitExceeded : '')}>
        <div className={styles.userInfos}>
          <Avatar src={item._user.pictureUrl} user={item._user} size={30} />
          <div className={styles.userIdentity}>
            <div className={item._user.firstName ? styles.fullName : styles.email}>{item._user.fullName}</div>
            {item._user.title ? <div className={styles.userTitle}>{item._user.title}</div> : null}
          </div>
        </div>
        <div className={styles.userActions}>
          <If condition={organization?.isClient && ['client_exponents', 'client_users', 'admin', 'admin_users', 'admin_exponents'].includes(getContext())}>
            <SetRoleButton authUser={authUser} user={item._user} selectedRoleId={item.roleId} onRoleChange={(value) => handleOnChangeRole(item._user, value)} organization={organization} />
          </If>
          <a className="ml-34" role="button" tabIndex={0} onClick={() => handleOnRemoveUserInArray(item._user)}>
            <Icon name="trash" className={styles.trashIcon} />
          </a>
        </div>
      </div>
    ));
  };

  const isUserInOrganizationUsersToAdd = usersToAdd.findIndex((item) => item && item._user && isEqual(item._user.username, value));
  const isUserInOrganization = usersOrganization?.findIndex((user) => user && isEqual(user.username.toLowerCase(), value));
  const isUserLimitExceeded = getContext() !== 'owner_settings' && isRecruiterLimitExceeded(exponent, usersToAdd.length + size(exponent.users));

  return (
    <div className={styles.container} ref={(node) => { mainNode = node; }}>
      <div className={styles.inputContainer} id="organizationUsersInputText">
        <InputMaterial
          addOnIcon="createUser"
          placeholder={t({ id: 'preparation.team.createUserHelp' })}
          value={value}
          onChange={handleOnChangeInput}
          onFocus={toggleDropdown}
          withInputErrorStyle={false}
          autoComplete="new-password"
        />

        {error && error.message ? (
          <div className={styles.error}>{error.message}</div>
        ) : ''}
      </div>

      {/* List all users */}
      <div className={classNames(styles.results, dropdownIsVisible ? styles.visible : null)}>
        <If condition={value !== '' && isUserInOrganizationUsersToAdd === -1 && isUserInOrganization === -1}>
          <div className={styles.newUser} onClick={handleCreateUser} role="button" tabIndex={0}>
            <div className={styles.userItem}>
              <span className={styles.createUser}><Icon name="member" /></span>
              <strong>{t({ id: 'add' })} {value}</strong>
              <a role="button" tabIndex={0}>{t({ id: 'add' })}</a>
            </div>
          </div>
          {!isEmpty(formatError) ? (
            <div style={{ padding: '20px 18px' }}>
              <div className={styles.error}>{formatError}</div>
            </div>
          ) : ''}
        </If>
        {returnUsersInTheOrganization(usersOrganization, value)}
      </div>
      <div className={styles.userCardContainer}>
        <div className={usersToAdd.length > 5 ? styles.userCards : ''}>
          {returnUsersToAddInOrganization(isUserLimitExceeded)}
        </div>
      </div>
      <If condition={isUserLimitExceeded && ['admin_exponents', 'owner_exponents', 'client_users', 'client_exponents'].includes(getContext())}>
        <Alert color="warning">
          {t({ id: 'client.member.warning.users.max.exceeded' })}
        </Alert>
      </If>
      {!isEmpty(usersToAdd) && (
        <Button
          id="btnAddUsers"
          disabled={isUserLimitExceeded && ['team', 'client_users', 'client_exponents'].includes(getContext())}
          loading={isFetching}
          className="w-full mt-20"
          onClick={handleOnAddUser}
        >
          {t({ id: 'preparation.team.recruiter.submit' }, { count: usersToAdd.length })}
        </Button>
      )}
    </div>
  );
}

OrganizationUsers.propTypes = {
  usersInExponent: array,
  exponent: object,
  organization: object,
  defaultUsersToAdd: array,
  error: object,
  usersOrganization: array,
  planners: array,
  onAddUserInExponent: func,
  authUser: object,
};

export default injectIntl(OrganizationUsers);

