import React, { useState } from 'react';
import {difference, keyBy, keys, orderBy, size, union} from 'lodash';
import { Spin } from 'antd';
import moment from 'moment';
import Highlighter from 'react-highlight-words';
import { array, func, object, string, bool } from 'prop-types';
import { Checkbox } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { track } from '@/utils/analytics';
import { ANALYTICS_RECRUITER } from '@/utils/constants';
import styles from './styles.less';

const OfferList = ({
  search,
  offers,
  events,
  event,
  visibleOffers,
  offersChecked,
  onImportOffers,
  isImporting,
}) => {
  const t = useFormatMessage();
  const [checkedList, setCheckedList] = useState([]);
  const [checkAll, setCheckAll] = useState(false);

  const onChange = (option) => {
    let newCheckedList = [];

    if (checkedList.includes(option)) {
      newCheckedList = checkedList.filter(element => element !== option);
    } else {
      newCheckedList = [...checkedList, option];
    }
    setCheckedList(newCheckedList);
    setCheckAll(keys(offers).map((id) => id).length === newCheckedList.length);

    offersChecked(newCheckedList)
  };

  const onCheckAllChange = () => {
    const newCheckedList = !checkAll ? union(checkedList, keys(offers).map((id) => id)) : difference(checkedList, keys(offers).map((id) => id));
    setCheckedList(newCheckedList);
    setCheckAll(!checkAll);

    offersChecked(newCheckedList)
  };

  const getAllSelectNumber = () => <span className={styles.badgeContainer}><span>{keys(offers).length}</span></span>;

  const getOffersAsc = (orders) => orderBy(visibleOffers, (offer) => offer.title.toLowerCase(), [orders]);

  const importOffers = () => {
    track({
      name: ANALYTICS_RECRUITER.CLICKED_EXISTING_OFFER,
      event,
      properties: { count: checkedList.length }
    });

    onImportOffers(checkedList);
  };

  const hasOffer = () => size(offers) > 0;

  const resetCheckedOffers = () => {
    setCheckedList([]);
    setCheckAll(false);
  };


  const blankState = (type) => {
    switch (type) {
      case 'noOffer': return <p className={styles.noOffer}>{t({ id: 'event.recruiter.preparation.offer.badSearch' })}</p>;
      default: return '';
    }
  };

  const displayContract = (arr) => (
    `${arr.join(', ')} -`
  );

  const renderLocations = (option) => {
    if (search) return '';

    return option.locations?.length ? option.locations?.[0].city : '';
  };

  const renderContracts = (offer) => {
    const event = keyBy(events, '_id')[offer._event];
    const contracts = [];

    if (search) return '';

    if (event) {
      const contractCriteria = event._criteria.filter((item) => item.key === 'CONTRACT')[0];
      if (contractCriteria) {
        contractCriteria._choices.forEach((item) => {
          if (offer.matching.filters.includes(item._id.toString())) {
            contracts.push(item.label);
          }
        });
      }
    }

    return contracts.length ? displayContract(contracts) : '';
  };

  const renderSubText = (option) => {
    if (search) return '';

    return `${renderContracts(option)} ${renderLocations(option)} - ${t({ id: 'publishedAt' })} ${moment(option.publishedAt).format('DD/MM/YYYY')}`;
  };

  const renderCheckboxGroup = () => {
    const offersSorted = getOffersAsc('asc');

    return (
      <>
        {
          offersSorted.map((option) => (
            <div className={styles.checkboxOption} key={option._id}>
              <Checkbox
                id={`checkbox_${option._id}`}
                checked={checkedList.includes(option._id)}
                onChange={() => onChange(option._id)}
                name={`checkbox_${option._id}`}
              >
                <Highlighter
                  highlightClassName="highlightText"
                  searchWords={[search]}
                  textToHighlight={option.title}
                  unhighlightStyle={{ fontWeight: '500', color: '#385077', padding: 0 }}
                />
                <span className={styles.subText}>{renderSubText(option)}</span>
              </Checkbox>
            </div>)
          )
        }
      </>
    );
  };

  const renderOfferList = () => (
    <div className={styles.offerList}>
      <div className={styles.checkboxHeader}>
        <Checkbox
          id="checkbox_all_check"
          name="checkbox_all_check"
          indeterminate={checkAll}
          onChange={onCheckAllChange}
        >
          <span className={styles.allSelect}>{t({ id: 'event.recruiter.preparation.offer.selectAll' })} {getAllSelectNumber()}</span>
        </Checkbox>
        <a role="button" tabIndex={0} onClick={resetCheckedOffers}>{t({ id: 'event.recruiter.preparation.offer.clearSelection' })}</a>
      </div>
      <br />
      <div className={styles.checkboxBody}>
        {renderCheckboxGroup()}
      </div>
      <div className={styles.footer}>
        <a role="button" tabIndex={0} onClick={importOffers}>{isImporting ? <Spin size="small" /> : `${t({ id: 'add' })} (${checkedList.length})`}</a>
      </div>
    </div>
  );


  return (hasOffer() ? renderOfferList() : blankState('noOffer'));
}

OfferList.propTypes = {
  search: string,
  offers: object,
  events: array,
  event: object,
  visibleOffers: array,
  offersChecked: func,
  onImportOffers: func,
  isImporting: bool,
};

export default OfferList;
