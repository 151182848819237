import { Button, InputSearch, Select } from '@seekube-tech/ui-kit';
import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { array, func, string } from 'prop-types';
import styles from './styles.less';
import { CustomOption } from '../CustomOption/CustomOption';

const Mobile = ({
  selectedSearch,
  setSelectedSearch,
  inputSearch,
  setInputSearch,
  searchHits,
  contractsSelected,
  setContractsSelected,
  contractOptions,
  localisationSelected,
  setLocalisationSelected,
  localisationOptions,
  handleSearchEvents
}) => {
  const t = useFormatMessage();

  const isOptionSelected = (options, opt) => options.includes(opt.value)
  
  const renderOption = (options, opt, { key, onSelect, active } = {}, withCheckbox = false) => {
    const isSelected = withCheckbox && isOptionSelected(options, opt);
    
    return (
      <CustomOption
        withCheckbox={withCheckbox}
        disabled={parseInt(opt.count, 10) === 0 && !isSelected}
        opt={opt}
        onSelect={onSelect}
        id={key}
        selected={withCheckbox && isSelected}
        active={active}
    />
    )
  }

  return (
    <div className={styles.search}>
      <form className={styles.content} onSubmit={(e) => e.preventDefault()}>
        <div className={styles.customSearch}>
          <InputSearch
            autocomplete
            value={selectedSearch}
            onChange={setSelectedSearch}
            inputValue={inputSearch}
            onInputChange={setInputSearch}
            name="test"
            id="test"
            label={t({ id: 'searchEngine.keywords.label' })}
            required={false}
            options={searchHits}
            className={styles.input}
            renderOption={(opt, { key, onSelect, active }) => renderOption(null, opt, { key, onSelect, active }, false)}
          />
        </div>
        <div className={styles.customSelect}>
          <Select
            name="searchEngine.contracts"
            id="searchEngine.contracts"
            value={contractsSelected}
            onChange={setContractsSelected}
            label={t({ id: 'searchEngine.contracts.label' })}
            required={false}
            options={contractOptions}
            multiple
            showCounter
            className={styles.selectLeft}
            renderOption={(opt, { key, onSelect }) => renderOption(contractsSelected, opt, { key, onSelect }, true)}
          />
        </div>
        <div className={styles.customSelect}>
          <Select
            name="searchEngine.localisation"
            id="searchEngine.localisation"
            value={localisationSelected}
            onChange={setLocalisationSelected}
            label={t({ id: 'searchEngine.localisation.label' })}
            required={false}
            options={localisationOptions}
            multiple
            showCounter
            className={styles.selectRight}
            renderOption={(opt, { key, onSelect }) => renderOption(localisationSelected, opt, { key, onSelect }, true)}
          />
        </div>
      </form>
      <Button type="submit" onClick={handleSearchEvents} className={styles.customSearchBtn}>{t({ id: 'search' })}</Button>
    </div>
  )
}

Mobile.propTypes = {
  selectedSearch: func,
  setSelectedSearch: func,
  inputSearch: string,
  setInputSearch: func,
  searchHits: array,
  contractsSelected: array,
  setContractsSelected: func,
  contractOptions: array,
  localisationSelected: array,
  setLocalisationSelected: func,
  localisationOptions: array,
  handleSearchEvents: func,
}

export { Mobile };