import React from 'react';
import { object } from 'prop-types';
import { Alert, Body1 } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';

export function AlertNoAdmin({ organization }){
  const t = useFormatMessage();

  return (
    organization.countAdminUsers < 1 && organization.isClient && (
      <Alert className="mb-20" color="error" isClosable={false}>
        <Body1 className="mb-6" fontWeight={600}>{t({ id: 'admin.organizations.alert.no.admin.title' })}</Body1>
        {t({ id: 'admin.organizations.alert.no.admin.subtitle' })}
      </Alert>
    )
  )
}

AlertNoAdmin.propTypes = {
  organization: object,
}