import React from 'react';
import languageList from '@/utils/languages.json';

// components
import { array, func, object } from 'prop-types';
import { Col, Row, Select as SelectAnt } from 'antd';
import { Select } from '@/components/Form';

import Icon from '@/components/Icon';
import Separator from '@/components/Separator';

import messages from '../../messages';

const {Option} = SelectAnt;

export const Languages = ({ languageItems, handleLanguageChange, showHelper, handleEditLanguage, criterion, getFieldDecorator, fieldDecoratorKey, setFieldsValue, intl, context }) => {// eslint-disable-line
  const levels = [
    { note: 0, label: intl.formatMessage(messages.languageLevel0) },
    { note: 1, label: intl.formatMessage(messages.languageLevel1) },
    { note: 2, label: intl.formatMessage(messages.languageLevel2) },
    { note: 3, label: intl.formatMessage(messages.languageLevel3) },
  ];

  const addLanguage = () => {
    handleLanguageChange([...languageItems, { lang: '', level: '' }]);
  };

  const editLanguage = (index, key) => (value) => {
    const newLanguageItems = languageItems;
    newLanguageItems[index][key] = value;
    handleEditLanguage(newLanguageItems);
  };

  const removeLanguage = (index) => {
    languageItems.splice(index, 1);

    if (languageItems.length === 0) {
      setFieldsValue({
        'matching-languages-lang-0': '',
        'matching-languages-level-0': '',
      });
      handleLanguageChange([languageItems, { lang: '', level: '' }]);
    }
  };

  const levelOptions = levels.map((item) => (
    <Option key={`level${item.note}`} value={item.note.toString()}>{item.label}</Option>
  ));

  const isRequired = criterion.modules[context].choiceMin > 0;

  const languagesCode = Object.keys(languageList);

  return (
    <div style={context === 'onboarding' ? { marginBottom: '10px' } : null} className="list-fields">
      {
        languageItems.map((item, index) => {
          const langOptions = languagesCode.map((lang) => (
            <Option key={`language${lang}`} value={lang}>{`${languageList[lang].name} (${languageList[lang].nativeName})`}</Option>
          ));

          return (
            <Row id={fieldDecoratorKey} key={`languageFormItem${index}`} gutter={16} style={{ position: 'relative' }}>
              {/* Formations */}
              <Col span={7} className="langItem">
                {getFieldDecorator(`matching-languages-lang-${index}`, {
                  initialValue: item ? item.lang : '',
                  rules: [{ required: isRequired, message: ' ' }],
                })(
                  <Select
                    showSearch
                    addOnIcon={context === 'onboarding' ? 'language' : undefined}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    placeholder={intl.formatMessage(messages.languagePlaceholder)}
                    getPopupContainer={() => document.getElementById(fieldDecoratorKey)}
                    onChange={editLanguage(index, 'lang')}
                    required={isRequired}
                  >
                    {langOptions}
                  </Select>
                )}
              </Col>
              <Col span={5} className="lang-levelItem">
                {getFieldDecorator(`matching-languages-level-${index}`, {
                  initialValue: item ? item.level.toString() : '',
                  rules: [{ required: isRequired, message: ' ' }],
                })(
                  <Select
                    placeholder={intl.formatMessage(messages.languageLevelLabel)}
                    getPopupContainer={() => document.getElementById(fieldDecoratorKey)}
                    onChange={handleEditLanguage(index, 'level')}
                    showHelper={showHelper}
                    required={isRequired}
                  >
                    {levelOptions}
                  </Select>
                )}
                <Separator height={10} />
                {index > 0 ? (
                  <a className="btn-remove-item" role="button" tabIndex={0} onClick={() => removeLanguage(index)}>
                    <Icon name="less" className="btn-icon" />
                  </a>
                ) : ''}
              </Col>
            </Row>
          );
        })
      }

      <div className="list-fields-add">
        <a role="button" tabIndex={0} onClick={addLanguage} className="btn-add-item">{intl.formatMessage(messages.languageAdd)} <Icon name="add" /></a>
      </div>
    </div>
  );
};

Languages.propTypes = {
  criterion: object,
  intl: object,
  languageItems: array,
  getFieldDecorator: func,
};
