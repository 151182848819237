import { makeStyles } from '@seekube-tech/ui';

export default makeStyles(theme => ({
  recipientsList: {
    margin: theme.spacing(2.5, 0),
  },
  recipientItem: {
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.neutral['100'],
    borderRadius: '4px',
    padding: '2px 6px 2px 8px',

    '& svg': {
      width: theme.spacing(1.5),
      height: theme.spacing(1.5),
      marginLeft: theme.spacing(2),
      color: theme.palette.neutral['500'],
    },
  },
  btnClose: {
    border: 'none',
    fill: 'none',
    cursor: 'pointer',
  }
}));
