import * as React from 'react';
import { bool, func, number } from "prop-types";
import { Icon as IconAntd, Spin } from 'antd';
import { FormattedMessage } from 'react-intl';
import styles from '../styles.less';

const antIcon = <IconAntd type="loading" style={{ fontSize: 20 }} />;

function DownloadCVTheque({ countTotalAppointments, downloadAllCV2, loadingDownload }) {
  return countTotalAppointments ? <div className={styles.downloadCVThequeLabel}>
    <div role="button" tabIndex={0} onClick={downloadAllCV2} ><FormattedMessage id="event.card.download.cv" /></div>
    <Spin indicator={antIcon} spinning={loadingDownload} style={{ marginLeft: '10px' }} />
  </div> : null
}

DownloadCVTheque.propTypes = {
  countTotalAppointments: number,
  downloadAllCV2: func,
  loadingDownload: bool
}

export default DownloadCVTheque;
