import { find, isEmpty, trim } from "lodash";
import { LOCALES } from "@/utils/constants";
import addUtmToLink from '@/store/availableActions/utils/event/addUtmToLink';
import { PHASES, PHASE_STATE } from '../utils/const';
import {
  addPasswordToLink,
  getEventPhases,
  isAdmin,
  isCandidateParticipating,
  isCandidatePreRegistered,
  isEventBeforeHunting,
  isEventClosed,
  isEventInDiscovering,
  isEventInHuntingPostEvent,
  isEventInPreregistration,
  isEventInProgress,
  isEventOpen,
  isEventParticipationDisabled,
  isJobfairPast,
  isRoleCandidate,
  isRoleOwner,
  isRoleRecruiter
} from "../utils/event";

export const ACTIONS = {
  PRE_REGISTRATION: 'preregistration'
}

function isHidden({
  event,
  role
}) {
  const eventPhases = getEventPhases(event);
  const { daysBeforeCvthequeIsClosed } = event.userContext?.stats || {};
  const isCvThequeOpen = (daysBeforeCvthequeIsClosed || 0) > 0;

  if (isRoleCandidate(role) && isJobfairPast(eventPhases)) {
    return true;
  }

  if (isRoleOwner(role) || isRoleRecruiter(role)) {
    if (isEventClosed(eventPhases) && !isCvThequeOpen) {
      return true;
    }
  }

  return false;
}

function isDisabled({
  event,
  user,
  role,
  editMode = false,
}) {
  const eventPhases = getEventPhases(event);
  const { daysBeforeCvthequeIsClosed } = event.userContext?.stats || {};
  const isCvThequeOpen = (daysBeforeCvthequeIsClosed || 0) > 0;

  if (event.isAuthUserBanned) {
    return true;
  }

  if (isRoleOwner(role) || isRoleRecruiter(role) || isAdmin(user)) {
    if (isEventInHuntingPostEvent(eventPhases) || isEventClosed(eventPhases)) {
      if (!isCvThequeOpen) {
        return true;
      }
    }

    return false;
  }

  if (isRoleCandidate(role)) {
    if (isEventInPreregistration(eventPhases)) {
      // If candidate is already pre-registered and event is still in pre-registration phase, disable the button
      if (isCandidatePreRegistered(event)) {
        return true;
      }
    }

    if (isEventOpen(eventPhases) || isEventInDiscovering(eventPhases) || isEventInProgress(eventPhases) || isEventInHuntingPostEvent(eventPhases)) {
      if (isEventParticipationDisabled({ editMode, eventPhases, event, user })) {
        return true;
      }
    }

    if (isJobfairPast(eventPhases)) {
      return true;
    }
  }

  return false;
}

function getLabel({
  event,
  user,
  t,
  role,
  editMode
}) {
  const userLocale = user?.locale || localStorage.getItem('locale') || LOCALES.fr;
  const eventPhases = getEventPhases(event);
  const customCtaText = event._contents?.[0]?.[userLocale]?.cta;
  const isParticipationDisabled = isEventParticipationDisabled({ event, eventPhases, user, editMode });

  const { countOffers, countAppointments, countPropositions, daysBeforeCvthequeIsClosed, countFreeSlots } = event.userContext?.stats || {};
  const isCvThequeOpen = (daysBeforeCvthequeIsClosed || 0) > 0;
  const hasOffers = (countOffers || 0) !== 0
  const hasAppointments = (countAppointments || 0) > 0;
  const hasPropositions = (countPropositions || 0) > 0;

  const isOfferModuleEnable = event.modules?.offer?.enable || false;
  const isInformal1to1ModuleEnable = event?.modules?.informal1to1?.enable || false;

  if (isAdmin(user)) {
    if (isEmpty(trim(customCtaText))) {
      return t({ id: "event.card.cta.label[8]"})
    }
    // Go to the Jobdating
    return customCtaText
  }

  if (isRoleOwner(role)) {
    return t({ id: "event.card.cta.label[0]" })
  }

  if (isRoleCandidate(role)) {
    /**
     * Set usual participation text if auth user is banned
     */
    if (event.isAuthUserBanned) {
      return t({ id: "event.card.cta.label[8]"})
    }

    if (isParticipationDisabled) {
      return t({ id: "event.landing.action.participate.disabled" })
    }

    if (isEventInPreregistration(eventPhases)) {
      if (isCandidatePreRegistered(event)) {
        return t({ id: "event.card.cta.label[10]" })
      }

      return t({ id: "preregistration.cta" })
    }

    if (isEventInDiscovering(eventPhases) || isEventOpen(eventPhases) || isEventInProgress(eventPhases)) {
      if (isCandidatePreRegistered(event) && !isCandidateParticipating(event)) {
        return t({ id: "event.card.cta.label[7]" })
      }

      if (isCandidateParticipating(event)) {
        return customCtaText || t({ id: "event.card.cta.label[0]" })
      }

      // Else register
      if (isEmpty(trim(customCtaText))) {
        return t({ id: "event.landing.action.registration"})
      }

      return customCtaText
    }

    if (isEventInHuntingPostEvent(eventPhases)) {
      if (hasAppointments) {
        // Go to appointments
        return t({ id: "event.card.cta.label[3]" })
      }

      if (hasPropositions) {
        // Go to applications
        return t({ id: "event.card.cta.label[4]" })
      }
    }

    if (isEventClosed(eventPhases)) {
      return t({ id: "event.card.cta.label[9]" })
    }
  }

  if (isRoleRecruiter(role)) {
    if (isEventInPreregistration(eventPhases)) {
      if (isEventBeforeHunting(eventPhases) || (isOfferModuleEnable && !hasOffers) || (!isOfferModuleEnable && isInformal1to1ModuleEnable && countFreeSlots === 0)) {
        return t({ id: "event.card.cta.label[6]" })
      }
      return t({ id: "event.card.cta.label[0]" })
    }

    if (isEventOpen(eventPhases) || isEventInDiscovering(eventPhases) || isEventInProgress(eventPhases)) {
      if ((isOfferModuleEnable && !hasOffers) || (!isOfferModuleEnable && isInformal1to1ModuleEnable && countFreeSlots === 0)) {
        return t({ id: "event.card.cta.label[6]" })
      }
      return t({ id: "event.card.cta.label[5]" })
    }

    if (isEventBeforeHunting(eventPhases)) {
      return t({ id: "event.card.cta.label[6]" })
    }

    if (isEventInHuntingPostEvent(eventPhases) || isEventClosed(eventPhases)) {
      if (isCvThequeOpen) {
        return t({ id: "event.card.cta.label[1]" })
      }

      // cvtheque closed
      return t({ id: "event.card.cta.label[2]" })
    }
  }

  return ""
}

function getLinkTarget({
  event,
  user,
  role,
}) {
  const eventPhases = getEventPhases(event);
  if (isRoleCandidate(role)) {
    if (isEventInPreregistration(eventPhases)) {
      return "noblank"
    }
  }
  return "_blank"
}

function getLinkHref({
  event,
  user,
  role,
  editMode,
  utm
}) {
  const eventPhases = getEventPhases(event);
  const isParticipationDisabled = isEventParticipationDisabled({ event, eventPhases, user, editMode });

  const isAuthUserPreregistered = editMode ? true : isCandidatePreRegistered(event);
  const isAuthUserParticipating = editMode ? false : isCandidateParticipating(event);
  const doesAuthUserParticipate = event.isAuthUserParticipate;
  const doesUserParticipateWithMatching = event.isAuthUserParticipateWithMatching;
  const isOfferModuleEnable = event.modules?.offer?.enable || false;
  const isInformal1to1ModuleEnable = event?.modules?.informal1to1?.enable || false;
  const isClientOrganization = user?._currentOrganization?.isClient;

  const { countOffers, countAppointments, countPropositions, daysBeforeCvthequeIsClosed, areInteractionsClosed, skipAppointmentValidation, countFreeSlots } = event.userContext?.stats || {};

  const areInteractionsOpen = !areInteractionsClosed;
  const doesSkipAppointmentValidation = !!skipAppointmentValidation;
  const isCvThequeOpen = (daysBeforeCvthequeIsClosed || 0) > 0;
  const hasOffers = (countOffers || 0) !== 0;
  const hasAppointments = (countAppointments || 0) > 0;
  const hasPropositions = (countPropositions || 0) > 0;

  if (isRoleOwner(role)) {
    if (isEventInPreregistration(eventPhases)) {
      if (!hasOffers) {
        return `/${event.slug}/owner/settings`
      }

      return `/${event.slug}/owner/candidates`
    }

    if (isEventOpen(eventPhases) || isEventInDiscovering(eventPhases)) {
      return `/${event.slug}/owner/candidates`
    }

    if (isEventInProgress(eventPhases)) {
      return `/${event.slug}/owner/dashboard/realtime`
    }

    if (isEventInHuntingPostEvent(eventPhases) || isEventClosed(eventPhases)) {
      if (isCvThequeOpen) {
        return `/${event.slug}/owner/candidates`
      }
    }
  }

  if (isRoleCandidate(role)) {
    if (isParticipationDisabled) {
      return addPasswordToLink('/events', event, eventPhases);
    }

    if (isEventInPreregistration(eventPhases)) {
      if (isCandidatePreRegistered(event)) {
        return addPasswordToLink(`/${event.slug}/candidate/preparation/participation`, event, eventPhases);
      }
      return addPasswordToLink('/', event, eventPhases);
    }

    if (isEventOpen(eventPhases) || isEventInDiscovering(eventPhases) || isEventInProgress(eventPhases)) {
      if (isAuthUserPreregistered && !doesAuthUserParticipate) {
        return addPasswordToLink(`/${event.slug}/candidate/preparation/onboarding/profile`, event, eventPhases);
      }

      if (doesUserParticipateWithMatching || isAuthUserParticipating) {
        switch (event.participantRedirection) {
          case "live":
            return addPasswordToLink(`/${event.slug}/candidate/jobdating/conferences/discovery`, event, eventPhases);
          case "company":
            return addPasswordToLink(`/${event.slug}/candidate/jobdating/exponents`, event, eventPhases);
          case "informal1to1":
            return addPasswordToLink(`/${event.slug}/candidate/jobdating/informal1to1`, event, eventPhases);
          case "offer":
            return addPasswordToLink(`/${event.slug}/candidate/jobdating/jobs`, event, eventPhases);
          case "dashboard":
            return addPasswordToLink(`/${event.slug}/candidate/jobdating/dashboard`, event, eventPhases);
          default:
            return addPasswordToLink(`/${event.slug}/candidate/jobdating/jobs`, event, eventPhases);
        }
      }

      // incomplete participant
      const ssoConnector = event?._connectors ? find(event._connectors, (c) => c.type === 'sso' && c.enable) : null;

      if (ssoConnector && !user) {
        const { origin } = window.location;
        return {
          pathname: `${ssoConnector.config?.loginUrl}&redirect_uri=${origin}/oauth/sso&state=${event.slug}`
        };
      }

      if (!user) {
        return addPasswordToLink(`/${event.slug}/candidate/preparation/onboarding/signup`, event, eventPhases)
      }

      return `${addUtmToLink(addPasswordToLink(`/${event.slug}/candidate/preparation/onboarding/profile-check`, event, eventPhases), utm)}`;
    }

    if (isEventInHuntingPostEvent(eventPhases) || isEventClosed(eventPhases)) {
      if (hasAppointments) {
        // Go to appointments
        return addPasswordToLink(`/${event.slug}/candidate/jobdating/appointments`, event, eventPhases);
      }

      if (hasPropositions) {
        // Go to applications
        return addPasswordToLink(`/${event.slug}/candidate/jobdating/appointments`, event, eventPhases);
      }
    }
  }

  if (isRoleRecruiter(role) && !isClientOrganization) {
    if (isEventInPreregistration(eventPhases)) {
      return `/${event.slug}/recruiter/preparation/offers`
    }

    if (isEventBeforeHunting(eventPhases)) {
      if (isOfferModuleEnable) {
        return `/${event.slug}/recruiter/preparation/offers`
      }

      if (!isOfferModuleEnable && isInformal1to1ModuleEnable && countFreeSlots === 0) {
        return `/${event.slug}/recruiter/agenda`
      }

      return `/${event.slug}/recruiter/preparation`
    }

    if (isEventOpen(eventPhases) || isEventInDiscovering(eventPhases)) {
      if (isOfferModuleEnable && !hasOffers) {
        return `/${event.slug}/recruiter/preparation/offers`
      }

      if (!isOfferModuleEnable && isInformal1to1ModuleEnable && countFreeSlots === 0) {
        return `/${event.slug}/recruiter/agenda`
      }

      return `/${event.slug}/recruiter/jobdating/sourcing`
    }

    if (isEventInProgress(eventPhases)) {
      // If appointments are confirmed (automatically or interactions are closed) redirect to confirmed tab
      if (!areInteractionsOpen || doesSkipAppointmentValidation) {
        return `/${event.slug}/recruiter/jobdating/interview/confirmed`
      }

      return `/${event.slug}/recruiter/jobdating/interview/unconfirmed`
    }

    if (isEventInHuntingPostEvent(eventPhases) || isEventClosed(eventPhases)) {
      if (isCvThequeOpen) {
        return `/${event.slug}/recruiter/jobdating/sourcing`
      }

      // cvtheque closed
      return `/`
    }
  }

  if (isRoleRecruiter(role) && isClientOrganization) {
    if (isEventInPreregistration(eventPhases)) {
      return `/client/offers`
    }

    if (isEventBeforeHunting(eventPhases)) {
      if (isOfferModuleEnable) {
        return `/client/offers`
      }

      if (!isOfferModuleEnable && isInformal1to1ModuleEnable && countFreeSlots === 0) {
        return `/${event.slug}/client/offers?calendar=true`
      }

      return `/${event.slug}/client/offers`
    }

    if (isEventOpen(eventPhases) || isEventInDiscovering(eventPhases)) {
      if (isOfferModuleEnable && !hasOffers) {
        return `/${event.slug}/recruiter/preparation/offers`
      }

      if (!isOfferModuleEnable && isInformal1to1ModuleEnable && countFreeSlots === 0) {
        return `/${event.slug}/recruiter/agenda`
      }

      return `/${event.slug}/recruiter/jobdating/sourcing`
    }

    if (isEventInProgress(eventPhases)) {
      // If appointments are confirmed (automatically or interactions are closed) redirect to confirmed tab
      if (!areInteractionsOpen || doesSkipAppointmentValidation) {
        return `/${event.slug}/recruiter/jobdating/interview/confirmed`
      }

      return `/${event.slug}/recruiter/jobdating/interview/unconfirmed`
    }

    if (isEventInHuntingPostEvent(eventPhases) || isEventClosed(eventPhases)) {
      if (isCvThequeOpen) {
        return `/${event.slug}/recruiter/jobdating/sourcing`
      }

      // cvtheque closed
      return `/`
    }
  }

  if (isAdmin(user)) {
    return `/${event.slug}/owner/settings`
  }

  return undefined;
}

function getAction({
  event,
  user,
  role
}) {
  const eventPhases = getEventPhases(event);
  // Candidate not pre-registered can pre-register
  if (isRoleCandidate(role)) {
    if (!isCandidatePreRegistered(event) && isEventInPreregistration(eventPhases)) {
      return ACTIONS.PRE_REGISTRATION;
    }
  }

  return undefined;
}

function getStyle() {
  return "default"
}

/**
 * Do not forget to specify strict function params (instead of destructuring) to avoid issues when calling
 */
export default {
  isAvailable: [
    [
      {
        phase: PHASES.ALL,
        state: PHASE_STATE.ALL
      },
    ],
  ],
  generateActions: ({ authUser }) => {
    const user = authUser?.toJS();

    return {
      isHidden: ({ event, role }) => isHidden({ event, user, role }),
      isDisabled: ({ event, role, editMode }) => isDisabled({ event, user, role, editMode }),
      getLabel: ({ event, role, t, editMode }) => getLabel({ event, user, role, t, editMode }),
      getLinkTarget: ({ event, role }) => getLinkTarget({ event, user, role }),
      getLinkHref: ({ event, role, editMode, utm }) => getLinkHref({ event, user, role, editMode, utm }),
      getAction: ({ event, role }) => getAction({ event, user, role }),
      getStyle: () => getStyle(),
    }
  }
}
