import { isObject } from 'lodash';

const getExponentRole = (exponent, authUser, ignoreAdminRole = true) => {
  if (!ignoreAdminRole && authUser.isAdmin) return false;

  const currentExponent
    = isObject(exponent)
    && exponent?.users?.find((user) => user?._user?._id === authUser._id);

  return currentExponent ? currentExponent.role : false;
};

export { getExponentRole };
