export const PHASES = {
  JOBFAIR: 'jobfair',
  HUNT: 'hunt',
  DISCOVERING: 'discovering',
  INTERACTIONS_CLOSING: 'interactionsClosing',
  APPLICATIONS_CLOSING: 'applicationsClosing',
  LIVE: 'live',
  PRE_REGISTRATION: 'preregistration',
  ALL: '*', // Include all phases
  NONE: null // No keyDates set
}

export const PHASE_STATE = {
  IS_PAST: 'isPast',
  IS_UPCOMING: 'isUpcoming',
  IS_IN_PROGRESS: 'isInProgress',
  ALL: '*', // Include all states
  NONE: null // No keyDates set
}

export const PHASE_FORMAT = {
  HYBRID: 'hybrid',
  PHYSICAL: 'physical',
  VIRTUAL: 'virtual'
}

export const NULL_OBJECT = {
  [PHASES.NONE]: {
    [PHASE_STATE.NONE]: true
  }
}

