import { defineMessages } from 'react-intl';

export default defineMessages({
  search: {
    id: 'search',
  },
  contract: {
    id: 'contract',
  },
  job: {
    id: 'job',
  },
  countResult: {
    id: 'result.count',
  },
  size: {
    id: 'size',
  },
  location: {
    id: 'location',
  },
  sector: {
    id: 'sector',
  },
  exponents: {
    id: 'event.landing.exponent',
  },
  toDiscover: {
    id: 'toDiscover',
  },
  opportunities: {
    id: 'opportunities',
  },
  reset: {
    id: 'reset',
  },
  sectors: {
    id: 'sectors',
  },
  size0: {
    id: 'organization.size[0]',
  },
  size1: {
    id: 'organization.size[1]',
  },
  size2: {
    id: 'organization.size[2]',
  },
  size3: {
    id: 'organization.size[3]',
  },
  size4: {
    id: 'organization.size[4]',
  },
  availableToday: {
    id: 'available.today',
  },
});
