import { defineMessages } from 'react-intl';

export default defineMessages({
  confirmDownload: {
    id: 'event.download.cvs.confirm.text',
  },
  closeWindow: {
    id: 'event.download.cvs.success.close',
  },
  downloadedOto100: {
    id: 'event.download.cvs.success.less.100',
  },
  downloaded10Oto1000: {
    id: 'event.download.cvs.success.100.1000',
  },
  downloadedMore1000: {
    id: 'event.download.cvs.success.more.1000',
  },
  downloadXCvs: {
    id: 'download.x.cvs',
  },
  downloadCvs: {
    id: 'download.cvs',
  },
  downloadConfirmed: {
    id: 'download.confirmed',
  },
  btnConfirm: {
    id: 'btn.confirm',
  },
});
