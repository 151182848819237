import React, { useState } from 'react';
import { object, func } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { Button, H4 } from '@seekube-tech/ui-kit';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'redux';
import InputMaterial from '@/components/Input/InputMaterial';
import { Bloc, BlocHeader } from '@/components/Bloc';
import styles from '@/scenes/Admin/scenes/Organizations/scenes/Organization/scenes/Details/styles.less';
import { organizationActions } from '@/store/organization';
import { API_CUSTOM_ERRORS } from '@/utils/constants';
import { notificationActions } from '@/store/notification';

const UpdateOrganizationName = ({ patchOrganization, organization }) => {
  const t = useFormatMessage();
  const [name, setName] = useState(organization?.name);
  const dispatch = useDispatch();


  const onSubmit = () => {
    patchOrganization({
      organizationId: organization._id,
      organizationParams: {
        name,
      },
      syncExponents: false,
      callback: (error) => {
        if (error?.statusCode === 409 && error?.error === API_CUSTOM_ERRORS.conflict) {
          return dispatch(notificationActions.sendNotification({
            message: t({ id: 'event.recruiter.preparation.stand.edit.existError' }),
            kind: 'error',
            style: {
              bottom: '10%',
              top: 'inherit',
            },
          }))
        }
        if (error?.error) {
          return dispatch(notificationActions.sendNotification({
            message: `Request failed : ${error?.statusCode}`,
            kind: 'error',
            style: {
              bottom: '10%',
              top: 'inherit',
            },
          }))
        }
        return null;
      },
      notificationParams: {
        success: {
          message: t({ id: 'toaster.edit.success' }),
          kind: 'info',
          style: {
            bottom: '10%',
            top: 'inherit',
          },
        },
      },
    });
  };

  return (
    <div className={styles.updateOrgaContainer}>
      <Bloc>
        <BlocHeader className={styles.heading}>
          <H4>{t({ id: 'about' })}</H4>
        </BlocHeader>
        <div>
          <InputMaterial
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
            addOnIcon="building"
            label={t({ id: 'admin.organizations.modal.input.organization.name' })}
            placeholder={t({ id: 'admin.organizations.modal.input.organization.name' })}
            type="text"
            validations={['required', 'validator']}
          />
        </div>

        <Button className="mb-1" onClick={onSubmit}>{t({ id: 'save' })}</Button>

      </Bloc>
    </div>
  )
}

UpdateOrganizationName.propTypes = {
  patchOrganization: func,
  organization: object,
}


const mapDispatchToProps = {
  patchOrganization: organizationActions.patchOrganization,
  sendNotification: notificationActions.sendNotification,
};


const withConnect = connect(null, mapDispatchToProps);

export default compose(
  withConnect,
)(UpdateOrganizationName);

