import React, { useState } from 'react';
import { Col, Form, Row, Input } from 'antd';
import { get, size } from 'lodash'
import { func, object, array } from 'prop-types';

import { FormattedMessage } from 'react-intl';
import { emailValidator } from '@/utils/text';
import { removeWhitespace } from '@/utils/format'
import Icon from '@/components/Icon';
import { If } from '@/components/If';

import styles from './styles.less';
import messages from './messages';

const FormItem = Form.Item;

const defaultValues = {
  fullName: '',
  phone: '',
  phone2: '',
  email: '',
  title: '',
};

const Contacts = (props) => {
  const { getFieldDecorator, formatMessage } = props;
  const [contacts, setContacts] = useState(size(props.contacts) === 0 ? [defaultValues] : props.contacts);

  return (
    <div className={styles.container}>
      <FormattedMessage {...messages.diffusionContactsTitle} tagName="p" />
      <div style={{ width: '50%' }} className={styles.contactsContainer}>
        {
          contacts.map(
            (contact, i) => (
              <div key={i} className={styles.contactContainer}>
                <Row gutter={30}>
                  <Col span={6}>
                    <FormItem>
                      {
                        getFieldDecorator(`contacts.${i}.fullName`, {
                          initialValue: get(contact, 'fullName'),
                          rules: [{ required: false, message: <FormattedMessage {...messages.mandatory} /> }],
                        })(
                          <Input placeholder={formatMessage(messages.contactFullName)} label={formatMessage(messages.contactFullName)} type="text" />
                        )
                      }
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem>
                      {
                        getFieldDecorator(`contacts.${i}.email`, {
                          initialValue: get(contact, 'email'),
                          normalize: removeWhitespace,
                          rules: [
                            { validator: emailValidator },
                            {
                              required: false, message: <FormattedMessage {...messages.mandatory} />,
                            }],
                        })(
                          <Input
                            addOnIcon="mail"
                            placeholder={formatMessage(messages.formFirstEmailLabel)}
                            abel={formatMessage(messages.formFirstEmailLabel)}
                            type="text"
                          />
                        )
                      }
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <FormItem>
                    {
                      getFieldDecorator(`contacts.${i}.title`, {
                        initialValue: get(contact, 'title'),
                        rules: [{ required: false, message: <FormattedMessage {...messages.mandatory} /> }],
                      })(
                        <Input addOnIcon="createUser" placeholder={formatMessage(messages.contactsTitleLabel)} label={formatMessage(messages.contactsTitleLabel)} type="text" />
                      )
                    }
                  </FormItem>
                </Row>
                <Row gutter={30}>
                  <Col span={6}>
                    <FormItem>
                      {
                        getFieldDecorator(`contacts.${i}.phone`, {
                          initialValue: get(contact, 'phone'),
                          rules: [{ required: false, message: <FormattedMessage {...messages.mandatory} /> }],
                        })(
                          <Input placeholder={formatMessage(messages.contactPhone, { count: 1 })} label={<FormattedMessage {...messages.contactPhone} values={{ count: 2 }} />} type="text" />
                        )
                      }
                    </FormItem>
                  </Col>
                  <Col span={6}>

                    <FormItem>
                      {
                        getFieldDecorator(`contacts.${i}.phone2`, {
                          initialValue: get(contact, 'phone2'),
                          rules: [{ required: false, message: <FormattedMessage {...messages.mandatory} /> }],
                        })(
                          <Input addOnIcon="phone" placeholder={formatMessage(messages.contactPhone, { count: 2 })} label={formatMessage(messages.contactPhone, { count: 2 })} type="text" />
                        )
                      }
                    </FormItem>
                  </Col>
                </Row>
                <If condition={i > 0}>
                  <a className={styles.removeContact} tabIndex={0} role="button" onClick={() => setContacts(contacts.filter((a, index) => index !== i))}>
                    <Icon name="trash" />
                  </a>
                </If>
              </div>
            )
          )
        }

        <div className={styles.alignRight}>
          <a tabIndex={0} role="button" onClick={() => size(contacts) < 10 && setContacts([...contacts, defaultValues])}>
            <Icon name="add-plus-rounded" /> <FormattedMessage {...messages.contactsAdding} />
          </a>
        </div>
      </div>
    </div>
  );
};

Contacts.propTypes = {
  getFieldDecorator: func,
  formatMessage: func,
  contacts: array,
  intl: object,
};

export { Contacts };
