import { defineMessages } from 'react-intl';

export default defineMessages({
  educationLevel: {
    id: 'education.level',
  },
  educationLevelMandatory: {
    id: 'education.level.mandatory',
  },
  educationLevelHelp: {
    id: 'education.level.help',
  },
  experienceTitle: {
    id: 'event.candidate.preparation.signup.userExperience.experience.title',
  },
  experienceTitleVerify: {
    id: 'event.candidate.preparation.signup.userExperience.experience.title.verify',
  },
  experienceTitleMobile: {
    id: 'event.candidate.preparation.signup.userExperience.experience.mobile.title',
  },
  experiencePositionOrganization: {
    id: 'event.candidate.preparation.signup.userExperience.experience.position.organization.label',
  },
  experiencePositionCurrent: {
    id: 'event.candidate.preparation.signup.userExperience.experience.position.current',
  },
  experiencePositionTitle: {
    id: 'event.candidate.preparation.signup.userExperience.experience.position.title.label',
  },
  formationTitle: {
    id: 'event.candidate.preparation.signup.userExperience.formation.title',
  },
  formationTitleVerify: {
    id: 'event.candidate.preparation.signup.userExperience.formation.title.verify',
  },
  formationTitleMobile: {
    id: 'event.candidate.preparation.signup.userExperience.formation.mobile.title',
  },
  formationSchoolLabel: {
    id: 'event.candidate.preparation.signup.userExperience.formation.school.label',
  },
  formationSchoolMandatory: {
    id: 'event.candidate.preparation.signup.userExperience.formation.school.mandatory',
  },
  formationProgramMandatory: {
    id: 'event.candidate.preparation.signup.userExperience.formation.program.mandatory',
  },
  experiencePositionOrganizationMandatory: {
    id: 'event.candidate.preparation.signup.userExperience.experience.organization.mandatory',
  },
  experiencePositionTitleMandatory: {
    id: 'event.candidate.preparation.signup.userExperience.experience.title.mandatory',
  },
  formationDiploma: {
    id: 'event.candidate.preparation.signup.userExperience.formation.diploma.label',
  },
  endDateError0: {
    id: 'endDate.error[0]',
  },
  endDate: {
    id: 'endDate',
  },
  startDate: {
    id: 'startDate',
  },
  formationAdd: {
    id: 'event.candidate.preparation.signup.userExperience.formation.add',
  },
  formationDelete: {
    id: 'event.candidate.preparation.signup.userExperience.formation.delete',
  },
  experienceAdd: {
    id: 'event.candidate.preparation.signup.userExperience.position.add',
  },
  experienceDelete: {
    id: 'event.candidate.preparation.signup.userExperience.position.delete',
  },
  max: {
    id: 'max',
  },
  fieldRequired: {
    id: 'form.error.required',
  },
  year: {
    id: 'year',
  },
  month: {
    id: 'month',
  },
  next: {
    id: 'next',
  },
  save: {
    id: 'save',
  },
  requiredNamePosition: {
    id: 'event.candidate.preparation.signup.userExperience.position.name.mandatory',
  },
  requiredTitlePosition: {
    id: 'event.candidate.preparation.signup.userExperience.position.title.mandatory',
  },
  requiredStartMonth: {
    id: 'event.candidate.preparation.signup.userExperience.position.startMonth.mandatory',
  },
  requiredStartYear: {
    id: 'event.candidate.preparation.signup.userExperience.position.startYear.mandatory',
  },
  requiredEndMonth: {
    id: 'event.candidate.preparation.signup.userExperience.position.endMonth.mandatory',
  },
  requiredEndYear: {
    id: 'event.candidate.preparation.signup.userExperience.position.endYear.mandatory',
  },
});
