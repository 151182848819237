import React, { useEffect, useState } from 'react';
import { bool } from 'prop-types';
import { Grid, Typography } from '@seekube-tech/ui';
import { Users as IconUsers, } from 'react-feather';
import { createStructuredSelector } from 'reselect';
import { useFormatMessage } from 'react-intl-hooks';
import { connect } from 'react-redux';
import { DatePicker, Select as SelectAnt } from 'antd';
import moment from 'moment';
import classnames from 'classnames';
import { Select } from '@/components/Form';
import Icon from '@/components/Icon';
import { useGetKpis } from '@/queries/kpis/useGetKpis';
import useStyles from './styles';
import { Progress } from './components/Progress';
import UsersConnected from './components/UsersConnected';

import styles from './styles.less';

const { RangePicker } = DatePicker;
const { Option } = SelectAnt;

const rangePickerParams = {
  format: 'DD/MM/YYYY',
  allowClear: false,
};

const periods = {
  thisMonth: 'thisMonth',
  lastMonth: 'lastMonth',
  thisYear: 'thisYear',
  lastYeast: 'lastYeast',
  custom: 'custom',
}

const Kpis = ({
  isSpotlightVisible,
}) => {
  const [rangePickerValue, setRangePickerValue] = useState([moment().startOf('month'), moment()]);
  const [periodSelected, setPeriodSelected] = useState('thisMonth');
  const t = useFormatMessage();

  const classes = useStyles();
  const kpiQuery = useGetKpis({
    begin: rangePickerValue[0].format('YYYY-MM-DD'),
    end: rangePickerValue[1].format('YYYY-MM-DD'),
  });

  const { thisMonth, lastYeast, lastMonth, custom, thisYear } = periods;

  useEffect(() => {
    switch (periodSelected) {
      case thisMonth: setRangePickerValue([
        moment().startOf('month'), moment()
      ]); break;
      case lastMonth: setRangePickerValue([
        moment().subtract(1, 'month').startOf('month'),
        moment().subtract(1, 'month').endOf('month')
      ]); break;
      case thisYear: setRangePickerValue([
        moment().startOf('year'), moment()
      ]); break;
      case lastYeast: setRangePickerValue([
        moment().subtract(1, 'year').startOf('year'),
        moment().subtract(1, 'year').endOf('year')
      ]); break;
      case custom: break;
      default: break;
    }
  }, [periodSelected])


  return (
    <div>
      <div className={classes.heading}>
        <Typography variant="h6" color="neutral.500">
          {t({ id: 'kpi.pl' }, { count: 2 })}
        </Typography>
        <div className={classes.dates}>
          <Select
            defaultValue={thisMonth}
            value={periodSelected}
            className={styles.inputSelect}
            onChange={(val) => setPeriodSelected(val)}
          >
            <Option value={thisMonth}>{t({ id: 'thisMonth' })}</Option>
            <Option value={lastMonth}>{t({ id: 'lastMonth' })}</Option>
            <Option value={thisYear}>{t({ id: 'thisYear' })}</Option>
            <Option value={lastYeast}>{t({ id: 'lastYear' })}</Option>
            <Option value={custom}>{t({ id: 'cookieModal.actions.customize' })} ...</Option>
          </Select>
          <RangePicker
            style={{ width: '220px' }}
            className={styles.rangePicker}
            onChange={(val) => {
              setPeriodSelected('custom');
              setRangePickerValue(val)}}
            value={rangePickerValue}
            disabledDate={(current) => ( current.isBefore(moment('2021').startOf('year')) || current.isAfter(moment()))}
            {...rangePickerParams}
          />
        </div>
      </div>
      <div>
        <Grid container justifyContent="center" direction="row" spacing={3} >
          <Grid item xs={6}>
            <div className={classes.bloc}>
              <div>
                <div className={classnames(classes.blocName, classes.blocLong)}>
                  <Icon name="camera"  className={classes.iconCamera}  />
                  <Typography variant="body2" color="neutral.500">
                    {t({ id: 'meet.pl' }, { count: 2 })}
                  </Typography>
                </div>
              </div>
              <div className={classes.blocEnd}>
                <div>
                  <Typography variant="h4" color="neutral.600">
                    {kpiQuery?.data?.countMeetings}
                  </Typography>
                </div>
                <Progress count={kpiQuery?.data?.percentProgressMeetings} />
              </div>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.bloc}>
              <div>
                <div className={classnames(classes.blocName, classes.blocLong)}>
                  <Icon name="house" className={classes.iconHouse} />
                  <Typography variant="body2" color="neutral.500">
                    {t({ id: 'header.userDropdown.events' })}
                  </Typography>
                </div>
              </div>
              <div className={classes.blocEnd}>
                <div>
                  <Typography variant="h4" color="neutral.600">
                    {kpiQuery?.data?.countEvents}
                  </Typography>
                </div>
                <Progress count={kpiQuery?.data?.percentProgressEvents} />
              </div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className={classnames(classes.bloc, classes.blocShort)}>
              <div>
                <div className={classes.blocName}>
                  <IconUsers className={classes.iconCandidate} />
                  <Typography variant="body2" color="neutral.500">
                    {t({ id: 'candidate.pl' }, { count: 2 })}
                  </Typography>
                </div>
              </div>
              <div className={classnames(classes.blocEnd,  classes.blocEndShort)}>
                <div>
                  <Typography variant="h4" color="neutral.600" className={classes.stat}>
                    {kpiQuery?.data?.countParticipants}
                  </Typography>
                </div>
                <Progress count={kpiQuery?.data?.percentProgressParticipants} />
              </div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className={classnames(classes.bloc, classes.blocShort)}>
              <div className={classes.blocName}>

                <Icon name="building" className={classes.iconBuilding} />
                <Typography variant="body2" color="neutral.500">
                  {t({ id: 'event.menu.exponents' })}
                </Typography>
              </div>
              <div className={classnames(classes.blocEnd,  classes.blocEndShort)}>
                <div>
                  <Typography variant="h4" color="neutral.600" className={classes.stat}>
                    {kpiQuery?.data?.countExponents}
                  </Typography>
                </div>
                <Progress count={kpiQuery?.data?.percentProgressExponents} />
              </div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className={classnames(classes.bloc, classes.blocUserConnected)}>
              <div className={classes.blocName}>
                <Typography variant="body2" color="neutral.500">
                  {t({ id: 'userConnected.pl' }, { count: 2 })}
                </Typography>
              </div>
              <Typography variant="caption2" color="neutral.300">
                {t({ id: 'inRealTime' })}
              </Typography>
              <div  className={classes.countUserConnected}>
                <Typography variant="h4" color="neutral.600" className={classes.stat}>
                  <UsersConnected isSpotlightVisible={isSpotlightVisible} />
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

Kpis.propTypes = {
  isSpotlightVisible: bool
}

const mapStateToProps = createStructuredSelector({
})
const withConnect = connect(mapStateToProps, null);


export default withConnect(Kpis)
