import React  from 'react';
import { object, func, bool } from 'prop-types';
import { H4, Button } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { size } from 'lodash';
import ModalV2 from '@/components/ModalV2';
import {
  BenefitsList
} from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Exponents/scenes/Exponent/components/Home/BenefitsJoin/BenefitsList';

export const ModalBenefitslist = ({ profile, onClose, visible }) => {
  const t = useFormatMessage();
  const benefitSize = size(profile?.benefitsJoin);

  return (
    <ModalV2
      visible={visible}
      template="default"
      onClose={onClose}
    >
      <div>
        <H4 className='mb-24'>{t({  id: 'stand.join.benefits' })}</H4>
        <BenefitsList profile={profile} maxBenefits={benefitSize} />
      </div>

      <div className="confirm-actions">
        <Button
          color="neutral"
          variant="outline"
          onClick={onClose}
        >
          {t({ id: 'back' })}
        </Button>
      </div>
    </ModalV2>
  )
}

ModalBenefitslist.propTypes = {
  profile: object,
  onClose: func,
  visible: bool,
}
