import React from 'react';
import { object, string, bool, oneOfType, any } from 'prop-types';
import moment from 'moment/moment';
import { Avatar } from '@seekube-tech/ui';
import { toJS } from '@/utils';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { authSelectors } from '@/store/auth';
import UserAvatar from '@/components/Avatar';

// Styles
import { compose } from 'redux';
import styles from './styles.less';

function AvatarWithDate({ src, altSrc, alt, className, date, rounded, user, partner, locale, isPartner, authUser }) {
  const momentLocale = authUser.locale === 'en' ? 'en-gb' : 'fr';

  return (
    <div className={[styles.dateContainer, className].join(' ')}>
      {date ? (
        <div className={styles.date}>
          <span>{moment(date).locale(momentLocale).format('DD')}</span>
          <div className={styles.month}>{moment(date).locale(momentLocale).format('MMM')}</div>
        </div>
      ) : (
        <Avatar
          variant="business"
          src={altSrc}
          color={isPartner ? partner?.color : 'default'}
          badge={isPartner ? partner?.icon : null}
          tooltip={partner?.[locale === 'fr' ? 'label_fr' : 'label_en']}
        />
      )}

      {user ? (
        <UserAvatar src={user.pictureUrl} user={user} size={70} />
      ) : (
        <img src={src} alt={alt} style={{ width: '70px' }} className={rounded ? styles.rounded : ''} />
      )}
    </div>
  )
}



AvatarWithDate.propTypes = {
  src: oneOfType([object, string]),
  alt: string,
  altSrc: any,
  className: string,
  user: object,
  date: string,
  rounded: bool,
  partner: object,
  locale: string,
  isPartner: bool,
  authUser: object
};

AvatarWithDate.defaultProps = {
  alt: '',
  date: '',
  partner: null,
  locale: 'fr',
  isPartner: false,
};

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser
})

const mapDispatchToProps = {};

const withConnect = connect(mapStateToProps, mapDispatchToProps);


export default compose(
  withConnect
)(toJS(AvatarWithDate));
