import React from 'react';
import { array, object, func, bool } from 'prop-types';
import { map, get } from 'lodash';
import { FormattedMessage } from 'react-intl';

import { StatBloc } from '@/components/Stats/StatBloc';
import Avatar from '@/components/Avatar';

import styles from './styles.less';
import messages from '../../messages';

const StatsRecruiters = ({ data, statBlocProperties = {}, loading, onActionClick }) => {
  const dataSource = map(get(data, 'docs'),
    (recruiter) => ({
      item: (
        <div className={styles.center}>
          <Recruiter recruiter={recruiter} />
        </div>
      ),
    })
  );

  const dataSourceModal = map(get(data, 'docs'),
    (recruiter) => ({
      item: (
        <div className={styles.center}>
          <Recruiter recruiter={recruiter} />
          <div style={{ width: '95px', textAlign: 'center' }}>
            {recruiter.countEvents || 0}
          </div>
        </div>
      ),
    })
  );

  return (
    <div>
      <StatBloc
        title={<FormattedMessage {...messages.participationsRecruiter} />}
        classContainer={styles.statsRecruiters}
        loading={loading}
        withList
        withAction
        onActionClick={onActionClick}
        dataSource={dataSource}
        count={get(data, 'total') || 0}
        modalProperties={{
          header: (
            <div className={styles.extraContent}>
              <span><FormattedMessage {...messages.recruiters} /></span>
              <span style={{ display: 'flex', justifyContent: 'flex-end' }}><span style={{ width: '95px' }}><FormattedMessage {...messages.participationsTitle} /></span>
              </span>
            </div>
          ),
          title: <FormattedMessage {...messages.participationsRecruiterTitle} />,
          dataSource: dataSourceModal,
        }}
        {...statBlocProperties}
      />
    </div>
  );
};

const Recruiter = ({ recruiter }) => (
  <div className="flex items-center">
    <span className={styles.avatarContainer}>
      <Avatar size={24} src={recruiter.pictureUrl} user={recruiter} />
    </span>
    <span>{recruiter.firstName ? `${recruiter.firstName} ${recruiter.lastName}` : recruiter.username}</span>
  </div>
);


Recruiter.propTypes = {
  recruiter: object,
};

StatsRecruiters.propTypes = {
  data: array,
  loading: bool,
  statBlocProperties: object,
  onActionClick: func,
};

export { StatsRecruiters };
