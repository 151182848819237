import React, { useState } from 'react';
import { object, string, func } from 'prop-types';
import classnames from 'classnames';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Popover } from 'antd';
import { trackRecruiterDeleteTemplate } from '@/utils/analytics';

import { Button } from '@seekube-tech/ui-kit';
import Icon from '@/components/Icon';

import { useDeleteTemplate } from '@/queries/templates/useDeleteTemplate';
import styles from './styles.less';
import messages from '../../messages';

const data = {
  defaultTemplate: {
    tag: {
      // text: 'Par défaut',
      text: <FormattedMessage id="settings.templatesMail.label.default" />,
      className: styles.tag,
    },
    action: {
      text: <FormattedMessage id="edit" />,
      className: styles.default,
    },
  },
  customTemplate: {
    name: {
      className: styles.custom,
    },
    action: {
      text: <FormattedMessage id="delete" />,
      className: styles.custom,
    },
  },
};


const AlertPopover = ({ children, template }) => {
  const [visible, setVisible] = useState(false);

  const mutateDeleteTemplate = useDeleteTemplate({
    userId: template._user,
    onSuccess: () => { trackRecruiterDeleteTemplate({ authUser: template._user }); }
  });


  const title = <><Icon name="warning-circle" /><FormattedMessage {...messages.deleteTemplateAlertText} /></>;
  const content = <Button className="w-full mt-5" onClick={() => { mutateDeleteTemplate.mutate(template); setVisible(false); }}><FormattedMessage {...messages.delete} /></Button>;

  return (
    <Popover visible={visible} getPopupContainer={(triggerNode) => triggerNode.parentNode} title={title} content={content} trigger="click">
      <div role="button" tabIndex={0} onClick={() => { setVisible(!visible); }}>{children}</div>
    </Popover>
  );

}

const TemplatesItem = ({ template, type = 'defaultTemplate', callback }) => (
  <div className={styles.itemContainer}>
    <div className={styles.itemContent}>
        <span className={classnames(styles.templateName, get(data[type], 'name.className'))} role="button" tabIndex={0}
              style={{ cursor: template._user ? 'pointer' : '' }}
              onClick={() => { if (template._user) { callback(); } }}><p>{template.name}</p></span>
      <span className={get(data[type], 'tag.className')}><p>{get(data[type], 'tag.text')}</p></span>
      <span className={classnames(styles.action, get(data[type], 'action.className'))}>
        {
          type === 'defaultTemplate' ?
            <a role="button" tabIndex={0} onClick={callback}>{get(data[type], 'action.text')}</a>
            : <AlertPopover template={template}><a>{get(data[type], 'action.text')}</a></AlertPopover>
        }
      </span>
    </div>
  </div>
);

TemplatesItem.propTypes = {
  template: object,
  type: string,
  callback: func,
};

export { TemplatesItem };
