import * as React from 'react';
import * as PropTypes from 'prop-types';
import { withErrorBoundary as defaultWithErrorBoundary } from 'react-error-boundary'

function CustomErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}

CustomErrorFallback.propTypes = {
  error: PropTypes.object,
  resetErrorBoundary: PropTypes.func
}

export default function withErrorBoundary(Component) {
  return defaultWithErrorBoundary(
    Component,
    {
      CustomErrorFallback,
      onError(error, info) {
        console.log(info);
        console.error(error)
      },
    }
  )
}
