import { useQuery } from 'react-query';

import request from '@/utils/request';
import { objectToParams } from '@/utils/url';

export function useGetUsersList({ eventId, search = {}, page }) {
  const searchParams = objectToParams({ ...search, page });
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/exponents/preparation/users-list?${searchParams}`;

  const queryFn = () => request(requestUrl, { method: 'GET' });

  const query = useQuery({
    queryKey: ['eventId', eventId, 'exponents', 'preparation', 'usersList', searchParams],
    queryFn,
    enabled: !!eventId,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  return query;
}
