import { Col, Row } from 'antd';
import { number, bool, string } from 'prop-types';
import React from 'react';
import { Tag, Caption2 } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import styles from '@/features/agenda/recruiter/components/BigCalendar/BigCalendarToolbar/styles.less';
import { EVENT_FORMAT } from '@/utils/constants';

export const HybridPhysicalSlotsNumber = ({
  exponentFormat,
  isModuleOfferEnable,
  isModuleInformal1to1Enable,
  interviewNumberOfTimeslotAvailable,
  informal1to1NumberOfTimeslotAvailable,
}) => {
  const t = useFormatMessage();

  if (exponentFormat === 'virtual') return null;

  const isExponentHybrid = exponentFormat === EVENT_FORMAT.HYBRID;
  const hasTimeslotAvailable = interviewNumberOfTimeslotAvailable.physical > 0;
  const hasInformal1to1TimeslotAvailable = informal1to1NumberOfTimeslotAvailable.physical > 0;

  return (
    <Col>
      {isExponentHybrid &&
        <Row type="flex" align="middle" className="mb-4">
          <Tag variant="outline" color="neutral" className="w-full justify-center">
            <span className={styles.formatTag}>
              {t({ id: 'calendar.settingsSlot.participationMode.physical' })}
            </span>
          </Tag>
        </Row>
      }
      <Row type="flex" align="middle">
        {isModuleOfferEnable && (
          <>
            <Tag
              className="mr-4"
              variant="tonal"
              color={hasTimeslotAvailable ? 'neutral' : 'warning' }
            >
              {`${interviewNumberOfTimeslotAvailable.physical}`}
            </Tag>
            <Caption2 className={hasTimeslotAvailable ? 'text-neutral-500' : 'text-warning-500'}>
              {t({ id: 'components.bigCalendar.toolbar.available-timeslot-interview' },
                { count: interviewNumberOfTimeslotAvailable.physical })}
            </Caption2>
          </>
        )}
        {isModuleInformal1to1Enable &&
          <>
            <div className={styles.separator}></div>
            <Tag
              className="mr-4"
              variant="tonal"
              color={hasInformal1to1TimeslotAvailable ? 'neutral' : 'warning' }
            >
              {`${informal1to1NumberOfTimeslotAvailable.physical}`}
            </Tag>
            <Caption2 className={hasInformal1to1TimeslotAvailable ? 'text-neutral-500' : 'text-warning-500'}>
              {t({ id: `components.bigCalendar.toolbar.available-timeslot${isModuleOfferEnable ? '-informal1to1' : ''}`},
                { count: informal1to1NumberOfTimeslotAvailable.physical } )}
            </Caption2>
          </>
        }
      </Row>
    </Col>
  )
}

HybridPhysicalSlotsNumber.propTypes = {
  exponentFormat: string,
  isModuleOfferEnable: bool,
  isModuleInformal1to1Enable: bool,
  interviewNumberOfTimeslotAvailable: number,
  informal1to1NumberOfTimeslotAvailable: number,
}
