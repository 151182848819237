import React from 'react';
import { FormattedMessage } from 'react-intl';
import { object, string } from 'prop-types';
import { get, isEmpty, isNull } from 'lodash';
import { Tag } from 'antd';
import { getId } from '@/utils/global';

import styles from './styles.less';
import messages from './messages';

const getStatus = (interaction, authUserId) => {
  const appointment = get(interaction, '_appointment');
  const status = get(appointment, 'status');

  if (isEmpty(status) && !isNull(interaction.messageRefused)) { return 'refused_by_recruiter'; }
  if (isEmpty(appointment)) return null;

  if (authUserId === getId(appointment._owner) && getId(appointment._owner) !== getId(appointment._organizationUser)) return 'transferred';
  if (['pending', 'unconfirmed'].includes(status)) { return 'pending'; }
  if (status === 'accepted') { return 'accepted'; }
  const actionOwner = interaction._appointment[`${status}By`] || 'recruiter';

  return `${status}_by_${actionOwner}`;
};

const InteractionTag = ({ interaction, authUserId }) => {
  if (isEmpty(interaction)) return null;
  const status = getStatus(interaction, authUserId);

  return status && <Tag className={styles[`${status}`]}><FormattedMessage {...messages[status]} /></Tag>;
};

InteractionTag.propTypes = {
  appointment: object,
  interaction: object,
  authUserId: string,
};

export { InteractionTag };
