import React from 'react';
import { connect } from 'react-redux';
import { object, func } from 'prop-types';
import { find, isEmpty } from 'lodash';
import { useFormatMessage } from 'react-intl-hooks';
import copyToClipboard from '@/utils/copyToClipboard';
import { MEETING_SERVICES } from '@/utils/constants';
import { notificationActions } from '@/store/notification';
import Avatar from '@/components/Avatar';

import styles from './styles.less';


const PresenterCard = ({ presenter, sendNotification }) => {
  const t = useFormatMessage();

  const onCopyToClipboard = () => {
    copyToClipboard(presenter.enterUrl);

    sendNotification({
      message: t({ id: 'copy.link.success' }),
      kind: 'success',
    });
  };

  return (
    <div className={styles.presenterContainer}>
      <div className={styles.presenterCard}>
        <div className={styles.leftSide}>
          <Avatar size={49} src={null} user={{ ...presenter }} />
        </div>
        <div className={styles.rightSide}>
          <div>
            <h3 className={styles.title}>{`${presenter.firstName} ${presenter.lastName}`}</h3>
            <p className={styles.content}>{presenter.title} </p>
          </div>
        </div>
      </div>
      <div className={styles.presenterInfos}>
        <span>
          {t({ id: 'live.modal.presenter.email' })} <span className={styles.email}>{presenter.email}</span>
        </span>
        <span>
          {t({ id: 'live.modal.presenter.link' })}
          <a role="button" tabIndex={0} onClick={onCopyToClipboard}>
            {t({ id: 'copy.link.provider' }, { name: getProvider(presenter.enterUrl) })}
          </a>
        </span>
      </div>
    </div>
  );
};

const getProvider = (url = '') => {
  const providerFound = find(MEETING_SERVICES, (service) => url.indexOf(service.patternInUrl) > -1);

  return isEmpty(providerFound) ? null : providerFound.text;
};

const mapDispatchToProps = {
  sendNotification: notificationActions.sendNotification,
};

const withConnect = connect(null, mapDispatchToProps);

export default withConnect(PresenterCard)

PresenterCard.propTypes = {
  presenter: object,
  sendNotification: func,
};
