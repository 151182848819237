import { useEffect, useState } from 'react';

export const useKeyPress = ({ targetKey, shouldReRender = false, callback = () => {} }) => {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState(false);

  function onkeypress({ key }) {
    if (key === targetKey) {
      if (shouldReRender) { setKeyPressed(true); }
    }
  }

  // If released key is our target key then set to false
  const onkeyup = ({ key }) => {
    if (key === targetKey) {
      callback();
      if (shouldReRender) { setKeyPressed(false); }
    }
  };

  // Add event listeners
  useEffect(() => {
    window.addEventListener('keydown', onkeypress);
    window.addEventListener('keyup', onkeyup);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', onkeypress);
      window.removeEventListener('keyup', onkeyup);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return keyPressed;
};