import React from 'react';

import { node, string, any, oneOf } from 'prop-types';

import { Dropdown } from 'antd';
import classnames from 'classnames';
import { Button, IconMoreVertical } from '@seekube-tech/ui-kit';

import styles from './styles.less';


const MoreDropdown = (props) => (
  <Dropdown overlay={props.overlay} placement={props.placement} trigger={['hover', 'click']} getPopupContainer={(triggerNode) => typeof props.getPopupContainer === 'function' ? props.getPopupContainer() : triggerNode.parentNode}>
    <div className="flex justify-center items-center">
      <Button
        variant={props.variant === 'backgroundWhite' ? 'outline' : 'text'}
        className={classnames(
          styles.moreAction,
          props.variant === 'backgroundWhite' ? styles.backgroundWhite : '')
        }
      >
        <IconMoreVertical size={24} />
      </Button>
    </div>
  </Dropdown>
);

MoreDropdown.propTypes = {
  overlay: node,
  placement: string,
  getPopupContainer: any,
  variant: oneOf(['backgroundWhite']),
};


export default MoreDropdown;

export { OverlayWithObject } from './OverlayWithObject';
