import React from 'react';
import { FormattedMessage } from 'react-intl';
import { object, string } from 'prop-types';
import { getInteractionStatus, DISTINCT_INTERACTION, COLORS } from '@/utils/global';
import { isEmpty } from 'lodash';
import { Tag } from '@/components/Tag';
import { If } from '@/components/If';
import Icon from '@/components/Icon';
import messages from './messages';

const InteractionsTags = ({ interaction }) => {
  if (isEmpty(interaction)) return null;

  const interactionStatus = getInteractionStatus(interaction);

  const tagProperties = getTagProperties(interactionStatus);

  return (
    <If condition={!isEmpty(tagProperties)}>
      <RenderComponent {...tagProperties} />
    </If>);
};

const RenderComponent = ({ iconName, style, message }) => (
  <Tag type="secondary" icon={<Icon name={iconName} style={style} />}>
    <FormattedMessage {...message} />
  </Tag>
);

const getTagProperties = (status) => {
  const { recruiter: { refusedApplication, pendingApplication, acceptedApplication }, candidate: { interviewScheduled } } = DISTINCT_INTERACTION;

  switch (status) {
    case refusedApplication : return { iconName: 'refuse', style: { color: COLORS.error }, message: messages.applicationRefused };
    case interviewScheduled : return { iconName: 'iconAccept', style: { color: COLORS.successIcon }, message: messages.applicationScheduled };
    case acceptedApplication : return { iconName: 'calendar', style: { color: COLORS.greyLight }, message: messages.appointmentPending };
    case pendingApplication : return { iconName: 'live-event', style: { color: COLORS.greyLight, fill: COLORS.greyLight }, message: messages.applicationPending };
    default: return {};
  }
};

InteractionsTags.propTypes = {
  interaction: object,
};

RenderComponent.propTypes = {
  iconName: string,
  style: object,
  message: object,
};

export { InteractionsTags };
