import React from 'react';
import { Form, Typography, TextField, Grid, useTheme, Avatar } from '@seekube-tech/ui';
import { Field, FormSpy } from 'react-final-form';
import { bool, func, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useFormatMessage } from 'react-intl-hooks';
import { Sun, Heart, Star, Zap } from 'react-feather';

// Store
import { Button, H5, Toggle } from '@seekube-tech/ui-kit';
import { eventActions, eventSelectors } from '@/store/event';

import FieldColor from '@/components/newComponents/FieldColor';
import FieldButtons from '@/components/newComponents/FieldButtons';

import useValidations from '@/utils/validations/useValidations';

import seekubeDark from '@/assets/images/seekube-dark.png';
import seekubeWhite from '@/assets/images/seekube-white.png';
import seekubeColor from '@/assets/images/seekube-color.png';

import useStyles from './styles';

const ICON_OPTIONS = [
  {
    icon: <Star size={18} />,
    value: 'star',
  },
  {
    icon: <Heart size={18} />,
    value: 'heart',
  },
  {
    icon: <Zap size={18} />,
    value: 'flash',
  },
  {
    icon: <Sun size={18} />,
    value: 'sun',
  },
];

const getColorPaletteKey = (color = '', palette) => Object.keys(palette.common).find((key) => palette.common[key]?.[500] === color.toUpperCase());
function FormSettings({ onToogleEnable, isEnable, event, patchEvent, isFetching }) {
  const t = useFormatMessage();
  const styles = useStyles();
  const { isFilled } = useValidations();
  const { palette } = useTheme();
  const initialValues = {
    ...event?.modules?.partner || {},
    color: palette.common[event?.modules?.partner?.color]?.[500] || palette.common.yellow[500],
  };

  const handleToogleEnablePartners = (callback) => (isEnable) => {
    onToogleEnable(isEnable);
    callback(isEnable);
  };


  const onSubmit = ({ event, patchEvent, palette }) => (values = {}) => {
    const colorKey = getColorPaletteKey(values.color, palette);

    const newEvent = {
      modules: {
        ...event?.modules || {},
        partner: {
          ...values,
          type: 'partner',
          color: colorKey,
        },
      },
    };

    patchEvent({
      event: newEvent,
      notificationParams: {
        success: {
          message: t({ id: 'notifications.update.success' }),
          kind: 'info',
          style: {
            bottom: '5%',
            top: 'inherit',
          },
        },
      }
    });
  }

  /* Render */

  return (
    <Form
      subscription={{}}
      onSubmit={onSubmit({ event, patchEvent, palette })}
      initialValues={initialValues}
      render={() => (
        <>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <H5>
                {t({ id: 'event.modules.partner.settings.title' })}
              </H5>
            </Grid>

            <Grid item>
              <Field
                name="enable"
                render={({ input }) => (
                  <Toggle checked={input.value} onChange={handleToogleEnablePartners(input.onChange)} />
                )}
              />
            </Grid>
          </Grid>

          {isEnable && (
            <>
              <Grid container justifyContent="flex-end" className={styles.required}>
                <Grid item>
                  <Typography variant="caption1" color="error[500]">
                    {t({ id: 'form.helper.multi-required' })}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <Typography variant="body2" color="grey[500]">
                    {t({ id: 'event.modules.partner.settings.label-fr.label' })} <span className={styles.asterisk}>*</span>
                  </Typography>

                  <div className={styles.field}>
                    <Field
                      name="label_fr"
                      component={TextField}
                      label={t({ id: 'event.modules.partner.settings.label-fr.placeholder' })}
                      fullWidth
                      required
                      validate={(value) => isFilled(value)}
                    />
                  </div>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="body2" color="grey[500]">
                    {t({ id: 'event.modules.partner.settings.label-en.label' })} <span className={styles.asterisk}>*</span>
                  </Typography>

                  <div className={styles.field}>
                    <Field
                      name="label_en"
                      component={TextField}
                      label={t({ id: 'event.modules.partner.settings.label-en.placeholder' })}
                      fullWidth
                      required
                      validate={(value) => isFilled(value)}
                    />
                  </div>
                </Grid>

                <Grid item xs={6}>
                  <Field
                    name="color"
                    component={FieldColor}
                    label={t({ id: 'event.modules.partner.settings.color' })}
                    required
                    validate={(value) => isFilled(value)}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Field
                    name="icon"
                    component={FieldButtons}
                    label={t({ id: 'event.modules.partner.settings.icon' })}
                    required
                    options={ICON_OPTIONS}
                    validate={(value) => isFilled(value)}
                  />
                </Grid>
              </Grid>

              <div className={styles.samplesContainer}>
                <Typography variant="body2" color="grey[500]">
                  {t({ id: 'event.modules.partner.settings.preview' })}
                </Typography>

                <Grid className={styles.samples} container justifyContent="space-between">
                  <Grid item>
                    <Typography variant="body2" color="neutral[400]">
                      {t({ id: 'event.modules.partner.settings.logo.clear' })}
                    </Typography>

                    <Grid container spacing={1} className={styles.logoContainer}>
                      <Grid item>
                        <FormSpy
                          subscription={{ values: true }}
                          render={({ values }) => (
                            <Avatar
                              variant="business"
                              src={seekubeWhite}
                              color={getColorPaletteKey(values.color, palette)}
                              badge={values.icon}
                              tooltip={values.label_fr}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item>
                        <Typography variant="body1" className={styles.seekube}>
                          {t({ id: 'seekube' })}
                        </Typography>

                        <Typography variant="caption2" color="neutral[300]" className={styles.seekubeInfo}>
                          {t({ id: 'event.modules.partner.settings.logo.description' })}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Typography variant="body2" color="neutral[400]">
                      {t({ id: 'event.modules.partner.settings.logo.dark' })}
                    </Typography>

                    <Grid container spacing={1} className={styles.logoContainer}>
                      <Grid item>
                        <FormSpy
                          subscription={{ values: true }}
                          render={({ values }) => (
                            <Avatar
                              variant="business"
                              src={seekubeDark}
                              color={getColorPaletteKey(values.color, palette)}
                              badge={values.icon}
                              tooltip={values.label_fr}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item>
                        <Typography variant="body1" className={styles.seekube}>
                          {t({ id: 'seekube' })}
                        </Typography>

                        <Typography variant="caption2" color="neutral[300]" className={styles.seekubeInfo}>
                          {t({ id: 'event.modules.partner.settings.logo.description' })}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Typography variant="body2" color="neutral[400]">
                      {t({ id: 'event.modules.partner.settings.logo.color' })}
                    </Typography>

                    <Grid container spacing={1} className={styles.logoContainer}>
                      <Grid item>
                        <FormSpy
                          subscription={{ values: true }}
                          render={({ values }) => (
                            <Avatar
                              variant="business"
                              src={seekubeColor}
                              color={getColorPaletteKey(values.color, palette)}
                              badge={values.icon}
                              tooltip={values.label_fr}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item>
                        <Typography variant="body1" className={styles.seekube}>
                          {t({ id: 'seekube' })}
                        </Typography>

                        <Typography variant="caption2" color="neutral[300]" className={styles.seekubeInfo}>
                          {t({ id: 'event.modules.partner.settings.logo.description' })}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </>
          )}
          <Button type="submit" loading={isFetching} className="mt-5">
            {t({ id: 'save' })}
          </Button>
        </>
      )}
    />
  );
}

FormSettings.propTypes = {
  onToogleEnable: func.isRequired,
  isEnable: bool,
  event: object,
  patchEvent: func.isRequired,
  isFetching: bool,
};

FormSettings.defaultProps = {
  isEnable: false,
  event: null,
  isFetching: false,
};


const mapStateToProps = createStructuredSelector({
  isFetching: eventSelectors.getEventsFetching,
});

const mapDispatchToProps = {
  patchEvent: eventActions.patchEvent,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(FormSettings);
