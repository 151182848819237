import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@/components/Icon';
import styles from './styles.less';

const Number = ({ number, text, icon, style }) => (
  <div className={styles.numberContainer} style={style}>
    {typeof icon === 'string' ? (
      <div className={styles.iconWrapper}><Icon name={icon} className={styles.icon} /></div>
    ) : icon}
    <div>
      <span>{number}</span>{text}
    </div>
  </div>
);

Number.propTypes = {
  style: PropTypes.object,
  number: PropTypes.node.isRequired,
  text: PropTypes.node.isRequired,
  icon: PropTypes.node,
};

export default Number;
