import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { Dropdown, Menu } from 'antd';
import { string } from 'prop-types';
import { IconDownload, IconChevronDown, Button } from '@seekube-tech/ui-kit';

const { Item: MenuItem } = Menu;

const ExportBtn = ({ slug }) => {
  const t = useFormatMessage();

  const handleExport = (type) => {
    switch (type) {
      case 'recruiters':
        window.open(`${process.env.FRONT_EXPORT_API_URL}/events/${slug}/export/recruiters?apiKey=41a1289e-fee1-430e-a849-5b2b7ed23f1a`, '_blank');
        break;

      case 'recruitersNotReady':
        window.open(`${process.env.FRONT_EXPORT_API_URL}/events/${slug}/export/recruiters-not-ready?apiKey=41a1289e-fee1-430e-a849-5b2b7ed23f1a`, '_blank');
        break;

      default:
        window.open(`${process.env.FRONT_EXPORT_API_URL}/events/${slug}/export/exponents?apiKey=41a1289e-fee1-430e-a849-5b2b7ed23f1a`, '_blank');
    }
  };

  return (
    <Dropdown
      overlay={
        <Menu>
          <MenuItem key={0}>
            <a role="button" tabIndex={0} onClick={() => handleExport('companies')}>
              {t({ id: 'owner.dashboard.export.allCompanies' }) }</a>
          </MenuItem>
          <MenuItem key={1}>
            <a role="button" tabIndex={0} onClick={() => handleExport('recruitersNotReady')}>
              {t({ id: 'owner.dashboard.export.nonReadyRecruiters' })}</a>
          </MenuItem>
          <MenuItem key={2}>
            <a role="button" tabIndex={0} onClick={() => handleExport('recruiters')}>
              {t({ id: 'owner.dashboard.export.allRecruiters' })}</a>
          </MenuItem>
        </Menu>
      }
      trigger={['click']}
      placement="bottomRight"
    >
      <Button
        variant="outline"
        color="neutral"
        imageComponentLeft={<IconDownload size={16} />}
        imageComponentRight={<IconChevronDown size={16} />}
      >
        {t({ id: 'export' })}
      </Button>
    </Dropdown>
  );
}

ExportBtn.propTypes = {
  slug: string,
};

export { ExportBtn }
