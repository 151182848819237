import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'event.participant.exponent.title',
  },
  titleCustom: {
    id: 'event.participant.exponent.titleCustom',
  },
  titleOwner: {
    id: 'event.participant.exponent.titleOwner',
  },
  blank: {
    id: 'event.candidate.exponents.blank',
  },
  blankEmptySearch: {
    id: 'event.candidate.exponents.blank.emptySearch',
  },
});
