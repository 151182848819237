import React from 'react';
import { array, object, func } from 'prop-types';
import { useDispatch } from 'react-redux';
import { replace, push } from 'connected-react-router';
import { isEmpty } from 'lodash';
import { Button, H5 } from '@seekube-tech/ui-kit';

import { FormattedMessage } from 'react-intl';
import { Col, Row } from 'antd';
import { useFormatMessage } from 'react-intl-hooks';

import { CardLive } from '@/components/CardLive';

import { getId } from '@/utils/global';
import { useGetConferences } from '@/queries/conferences/useGetConferences';
import styles from './styles.less';


const Lives = ({ event, organization, style = {} }) => {
  const t = useFormatMessage();
  const dispatch = useDispatch();

  const conferences = useGetConferences({
    eventId: getId(event),
    organization: getId(organization),
    limit: 3,
    archive: 'false',
  }).data?.docs || [];

  if (isEmpty(conferences)) return null;

  const baseUrl = `${window.location.origin}/${event.slug}/jobdating/conferences`;

  const onCardClick = (conf) => {
    window.open(`${baseUrl}/conference/${conf._id}`);
  }

  return (
    <div style={{ ...style }}>
      <H5 className={styles.mainTitle}><FormattedMessage id="our.lives" /></H5>
      <Row gutter={30}>
        {
          conferences.map((conference, key) => {
            if (key < 3) {
              return (
                <Col span={4} xs={12} md={4}>
                  <CardLive
                    onCardClick={() => onCardClick(conference)}
                    variant="live"
                    conference={conference}
                    context="candidate"
                    event={event}
                  />
                </Col>
              )
            }})
        }
      </Row>
      <div className="flex justify-center my-20">
        <Button size="large" variant="tonal" onClick={() => dispatch(replace(`/${event.slug}/jobdating/conferences/discovery?page=1&organizations=${organization.name}`))}>
          {t({ id: 'see.all.lives' })}
        </Button>
      </div>
    </div>
  )
}

Lives.propTypes = {
  event: object,
  style: object,
  organization: object,
  getConferences: func,
  conferences: array,
}


export default Lives
