import React from 'react';
import { compose } from 'redux';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';


// Components
import { H4, Loader, AvatarBusiness } from '@seekube-tech/ui-kit';
import { toJS } from '@/utils';
import Wrapper from '@/components/Wrapper';
import Separator from '@/components/Separator';

// Store
import { getSpecificOrganization } from '@/store/organization/selectors';


// styles
import styles from './styles.less';

import { RemoveOrganization, UpdateOrganizationName, DealsHandler, AlertNoAdmin } from './components';

const OrganizationDetail = () => {
  const organization = useSelector(getSpecificOrganization)?.toJS();

  if (!organization) {
    return <Loader />;
  }

  return (
    <Wrapper className={styles.dealsContainer}>

      <div className={styles.logoAndNameContainer}>
        <AvatarBusiness className={styles.logo} pictureUrl={organization?.profile?.pictureUrl} size="80px" />
        <H4>{organization.name}</H4>
      </div>

      <AlertNoAdmin organization={organization} />

      <UpdateOrganizationName organization={organization} />

      <Separator height={40} />

      <DealsHandler organization={organization} />

      <RemoveOrganization organization={organization} />
    </Wrapper>
  );
};



export default compose(
  withRouter,
  toJS,
)(OrganizationDetail);
