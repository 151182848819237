import React from 'react';

import Jobs from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Jobs';

const OwnerOffersScene = (props) => (
  <Jobs
    {...props}
    context="owner"
  />
);

export default OwnerOffersScene;
