import {
  SEPARATORS_NAMES
} from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Appointments/components/AppointmentsList/const';
import {
  getSeparatorName
} from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Appointments/components/AppointmentsList/helpers/getSeparatorName';

export const getInteractionsGroupedBySeparator = (interactions, authUser) => {
  const { propositionsModified, accepted, done, proposition, pending, refused} = SEPARATORS_NAMES;

  const interactionsGrouped = {
    [proposition]: [],
    [propositionsModified]: [],
    [accepted]: [],
    [pending]: [],
    [refused]: [],
    [done]: [],
  }

  interactions.forEach((interaction) => {
    const separatorName = getSeparatorName(interaction, authUser);
    const isSeparatorValid = separatorName.length;

    if (isSeparatorValid) {
      interactionsGrouped[separatorName] = [...interactionsGrouped[separatorName], interaction];
    }
  });

  return interactionsGrouped
}
