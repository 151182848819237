import { useState } from 'react';
import { useFormatMessage } from 'react-intl-hooks';

/**
 * Download file
 * @param {String} url
 * @param {String} fileName
 * @param {Func} onError - Callback for display an error message
 * @param {Optional<Func>} onInfo - Callback for display toaster information
 * @returns {Array<Function, Boolean>} - [Download handler, is downloading boolean]
 */
export function useDownloadFile(url, fileName, onError, onInfo) {
  /* Vars */

  const [isDownloading, setIsDownloading] = useState(false);
  const t = useFormatMessage();

  /* Functions */

  const handleDownload = async () => {
    try {
      setIsDownloading(true);

      if (onInfo) {
        onInfo();
      }

      const request = await fetch(url);

      if (request.ok) {
        const blob = await request.blob();

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = fileName;

        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        throw new Error(t({ id: 'app.components.ErrorPage.header' }));
      }
    } catch (error) {
      onError(error.message);
    } finally {
      setIsDownloading(false);
    }
  };

  return [handleDownload, isDownloading];
}
