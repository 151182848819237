import { makeStyles } from '@seekube-tech/ui';

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  datePicker: {
    marginTop: `${theme.spacing(1)}px !important`,
    '& input': { height: '41px' },
    '& svg': {
      color: theme.palette.primary['500'],
    },
    '& :disabled': {
      border: `1px solid ${theme.palette.grey['600']}`,
      borderRadius: '5px',
      color: theme.palette.grey['600'],
    }
  },
  name: {
    margin: theme.spacing(1.5, 0),
    marginRight: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  tagSend: {
    background: theme.palette.success['200'],
    padding: theme.spacing(0.15, 1),
    marginRight: 0,
    borderRadius: '4px',
    border: 'none',

    '& span': {
      fontWeight: 600,
    },

    '& svg': {
      color: theme.palette.success['600'],
      width: '12px',
      height: '12px',
      fill: 'transparent',
      marginRight: theme.spacing(1),
    },
  },
  ko: {
    background: theme.palette.error['200'],
    '& svg': {
      color: theme.palette.error['600'],
    }
  },
  removeReport: {
    display: 'flex',
    alignItems: 'center',

    '& p': {
      color: theme.palette.error['500'],
    },

    '& svg': {
    }
  }
}));
