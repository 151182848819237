import { useMutation, useQueryClient } from 'react-query';
import { filter } from 'lodash';
import request from '@/utils/request';

export function useDeleteReport(
  {
    eventId,
  }) {
  const queryClient = useQueryClient();

  const mutationFn = ({ _id }) => {
    const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/reports/${_id}`;

    return (
      request(requestUrl, {
        method: 'DELETE',
      })
    )
  }

  return useMutation({
    mutationFn,
    onMutate: async (report) => {
      const reports = filter(queryClient.getQueryData('reports'),
        (prevReport) => prevReport._id !== report._id);

      queryClient.setQueryData('reports', reports);

      return reports;
    }
  });
}
