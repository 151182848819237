import React, { useEffect, useState } from 'react';
import { array, func, string } from 'prop-types';
import { isEqual } from 'lodash';

// Components
import { Table } from 'antd';
import DraggableBodyRow from './components/DraggableBodyRow';

const i = 0;
function DragTable({
  columns,
  dataSource,
  onSort,
  className,
  pagination=true,
}) {
  const [localData, setLocalData] = useState(dataSource || []);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    if (!isEqual(dataSource, localData)) {
      setLocalData(dataSource)
    }
  }, [dataSource])

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  }

  useEffect(() => {
    if (!isDragging && !isEqual(dataSource, localData)) {
      save();
    }
  }, [isDragging])

  const save = () => {
    if (typeof onSort === 'function') {
      onSort(localData);
    }
  }

  const startDragging = () => {
    setIsDragging(true)
  }

  const stopDragging = () => {
    setIsDragging(false)
  }

  const moveRow = (fromIndex, toIndex) => {
    const newArray = [...localData];
    const elemToMove = newArray.splice(fromIndex, 1);

    newArray.splice(toIndex, 0, ...elemToMove)
    setLocalData(newArray);
  }

  const findInitialItem = (id) => {
    const index = dataSource.findIndex(item => item._id === id)
    return { item: dataSource[index], index }
  }

  return (
    <Table
      className={className}
      columns={columns}
      dataSource={localData}
      components={components}
      onRow={(record, index) => ({
        index,
        item: record,
        startDragging,
        stopDragging,
        findInitialRow: findInitialItem,
        hoverRow: moveRow,
        isDragging
      })}
      pagination={pagination}
    />
  );
}

DragTable.propTypes = {
  columns: array,
  dataSource: array,
  onSort: func,
  className: string,
};

export default DragTable;
