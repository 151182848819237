import { useMutation, useQueryClient } from 'react-query';
import request from '@/utils/request';

export function useResetReportDates(
  {
    eventId,
  }) {
  const queryClient = useQueryClient();

  const mutationFn = () => {
    const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/reports/reset`;

    return (
      request(requestUrl, {
        method: 'POST',
      })
    )
  }

  return useMutation({
    mutationFn,
    onSuccess: async (reports) => {
      queryClient.setQueryData('reports', () => reports);
    },
  });
}
