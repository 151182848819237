import React from 'react';
import { func, object, array } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { push, replace } from 'connected-react-router';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';
import { isEmpty, isEqual } from 'lodash';
import SideContent from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/components/SideContent';
import { toJS } from '@/utils';
import { eventSelectors } from '@/store/event';
import { authSelectors } from '@/store/auth';
import { offerActions, offerSelectors } from '@/store/offer';
import { sectorActions } from '@/store/sector';
import { participantActions, participantSelectors } from '@/store/participant';
import { criterionActions, criterionSelectors } from '@/store/criterion';
import ModalFull from '@/components/ModalFull';
import ModalBig from '@/components/ModalBig';
import Wrapper from '@/components/Wrapper';
import BlankState from '@/components/BlankState';
import ParticipantCards from '@/components/ParticipantCards';
import SearchEngine from '@/scenes/Event/scenes/Candidate/scenes/JobDating/components/SearchEngine';
import CreateOfferForm from '@/scenes/Event/scenes/Recruiter/scenes/Preparation/scenes/Offer/containers/CreateOfferModal/components/CreateOfferForm';
import ParticipantsView from '@/scenes/Event/scenes/Recruiter/scenes/Jobdating/container/ParticipantsView';
import messages from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Jobs/messages';
import offerFormMessages from '@/scenes/Event/scenes/Recruiter/scenes/Preparation/scenes/Offer/messages';
import SignupModal from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/Signup/components/SignupModal';
import { EVENT_FORMAT } from '@/utils/constants';
import styles from './styles.less';
import Matching from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/Matching/Matching';
const USER_PREVIEW = '5d64f86a2c2ac5000f9ee5a1';
const EVENT_PREVIEW = 'template-seekube1';

class Preview extends React.PureComponent {
  static propTypes = {
    match: object,
    event: object,
    authUser: object,
    intl: object,
    facets: object,
    getParticipantOffers: func,
    getSectors: func,
    sectors: array,
    replace: func,
    getParticipant: func,
    participant: object,
    getDefaultCriteria: func,
    defaultCriteria: array,
  };

  constructor(props) {
    super(props);
    this.state = {
      context: window.location.pathname.split('/').pop(),
      facetLocations: null,
      offerFormHeight: 'auto',
    };
  }

  componentDidMount() {
    const { props: { getSectors, getParticipant, getDefaultCriteria } } = this;

    getParticipant({
      eventId: 'template-seekube1',
      userId: USER_PREVIEW,
    });

    getSectors();
    getDefaultCriteria();
  }

  componentWillReceiveProps({ facets, participant, getParticipantOffers }) {
    if (this.state.facetLocations === null && facets && facets['locations.name']) {
      this.setState({ facetLocations: facets['locations.name'] });
    }

    if (!isEmpty(participant) && !isEqual(participant, this.props.participant)) {
      getParticipantOffers({
        eventId: EVENT_PREVIEW,
        participantId: participant._id,
        offset: 0,
        page: 1,
        search: {},
        loader: true,
      });
    }
  }

  getOfferFormHeight = (height) => (this.setState({ offerFormHeight: height }));

  redirectToMatchStep = (context) => {
    const {
      props: { event, match, sectors, intl, replace, authUser, participant, defaultCriteria },
      state: { facetLocations, offerFormHeight },
      getOfferFormHeight,
    } = this;

    if (isEmpty(participant)) {
      return null;
    }

    switch (context) {
      case 'candidate-profile':
        return (
          <ModalFull
            id="participantModal"
            visible
            footer={null}
            onCancel={() => replace(`/${event.slug}/owner/settings/matching/candidate-profile`)}
            maskClosable={false}
            keyboard
            ref={(modal) => { this.modalRef = modal; }}
          >
            <ParticipantCards
              component="big"
              participant={participant}
              viewBy={authUser}
              actions=""
              onSave={() => { }}
              handleSaveUser={() => { }}
              eventSlug={event.slug}
              status="none"
              context="all"
              participantActionBtnDisabled
            />
          </ModalFull>
        );
      case 'search-participant':
        return (
          <Wrapper>
            <>
              <SearchEngine
                search={{}}
                locations={facetLocations}
                onSearch={() => { }}
                sectors={sectors}
                event={event}
                intl={intl}
                context="offer"
                countOffers={0}
                participant={participant}
                preview
              />
              <BlankState
                title={intl.formatMessage(messages.blankTitle)}
                content={intl.formatMessage(messages.blankContent)}
                icon="blank-state-nothing-found-oops"
              />
            </>
          </Wrapper>
        );

      case 'offer':
        return (
          <ModalBig
            isOpen
            closable={false}
            onClose={() => replace(`/${event.slug}/owner/settings/matching/offer`)}
            offerFormHeight={offerFormHeight}
          >
            <div className="modalBigWrapper">
              <h1 className="mainTitle">{intl.formatMessage(offerFormMessages.offerFormAdd)}</h1>

              <CreateOfferForm
                event={event}
                user={authUser}
                offer={null}
                users={[]}
                onSubmit={() => { }}
                onOpen={() => { }}
                onChange={() => { }}
                authUser={authUser}
                getOfferFormHeight={getOfferFormHeight}
                showHelper={() => { }}
              />
            </div>
          </ModalBig>
        );

      case 'search-exponent':
        return (
          <Wrapper>
            <ParticipantsView context="adminall" />
          </Wrapper>
        );

      default:
        return (
          <ModalBig
            isOpen
            closable={false}
            hideSideContent
          >
            <Matching authUser={authUser} event={event} totalOffers={0} isPreview />
          </ModalBig>
        );
    }
  };

  render() {
    const {
      redirectToMatchStep,
      state: { context },
    } = this;

    return (
      <div className={styles.previewContainer}>
        {redirectToMatchStep(context)}
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser,
  event: eventSelectors.getCurrentEvent,
  facets: offerSelectors.getFacets,
  sectors: offerSelectors.getSectors,
  participant: participantSelectors.getCurrentParticipant,
  defaultCriteria: criterionSelectors.getDefaultCriteria,
});

const mapDispatchToProps = {
  getParticipantOffers: offerActions.getParticipantOffers,
  getParticipant: participantActions.getParticipant,
  getSectors: sectorActions.getSectors,
  getDefaultCriteria: criterionActions.getDefaultCriteria,
  push,
  replace,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  injectIntl,
)(toJS(Preview));
