import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { Form } from 'antd';
import InputMaterial from '@/components/Input/InputMaterial';

const FormItem = Form.Item;

export const ProgramName = ({ form, education, order }) => {
  const t = useFormatMessage();

  return (
    <>
      <FormItem
        className="formItemNoMargin"
      >
        {form.getFieldDecorator(`educations.${order}.title`, {
          initialValue: education?.title || '',
          rules: [{ required: true, message: t({ id: 'event.candidate.preparation.signup.userExperience.formation.program.mandatory' }) }],
        })(
          <InputMaterial
            addOnIcon="formation"
            label={t({ id: 'event.candidate.preparation.signup.userExperience.formation.diploma.label' })}
            placeholder={t({ id: 'event.candidate.preparation.signup.userExperience.formation.diploma.label' })}
            type="text"
            validations={['required']}
          />
        )}
      </FormItem>
    </>
  )
}