import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { Form } from 'antd';
import InputMaterial from '@/components/Input/InputMaterial';
import { upperFirst } from 'lodash';
import { IconContact } from '@seekube-tech/ui-kit';

const FormItem = Form.Item;

export const JobTitle = ({ form, user, onBlur }) => {
  const t = useFormatMessage();

  return (
    <>
      <FormItem>
        {form.getFieldDecorator('title', {
          initialValue: user?.title || '',
          rules: [{ required: true, message: t({ id: 'event.candidate.preparation.signup.userExperience.experience.position.title.mandatory' }) }],
        })(
          <InputMaterial
            addOnIcon={<IconContact size={24} />}
            label={t({ id: 'event.recruiter.preparation.signup.identity.form.jobPosition.label' })}
            placeholder={t({ id: 'event.recruiter.preparation.signup.identity.form.jobPosition.label' })}
            type="text"
            onBlur={onBlur}
            validations={['required']}
            applyFormatValue={upperFirst}
          />
        )}
      </FormItem>
    </>
  )
}
