import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { object, string } from 'prop-types';
import { Caption1, Tag, IconMapPin, IconWifi } from '@seekube-tech/ui-kit';

import { useFormatMessage } from 'react-intl-hooks';
import { EVENT_FORMAT } from '@/utils/constants';
import styles from './BigCalendarToolbar/styles.less';

const BigCalendarWeekHeader = ({ date, exponentFormat, event }) => {
  const t = useFormatMessage();
  const [columnWidth, setColumnWidth] = useState();
  const [tagType, setTagType] = useState();

  const day = moment(date).format('dddd');
  const dateOfMonth = moment(date).format('DD');

  const ref = useRef();
  const expectedWidth = ref?.current?.getBoundingClientRect().width || 0;

  useEffect(() => {
    if(ref.current) {
      setColumnWidth(expectedWidth)
    }
  }, [ref.current])

  useEffect(() => {
    if (event.format === EVENT_FORMAT.HYBRID && exponentFormat === EVENT_FORMAT.HYBRID) {
      const datesPhysical = event.keyMoments.jobfair[0];
      const datesVirtual = event.keyMoments.jobfair[1];
      if (moment(date).isSameOrAfter(moment(datesPhysical.beginAt), 'day') && moment(date).isSameOrBefore(moment(datesPhysical.endAt), 'day')) {
        if (moment(date).isSameOrAfter(moment(datesVirtual.beginAt), 'day') && moment(date).isSameOrBefore(moment(datesVirtual.endAt), 'day')) {
          setTagType(EVENT_FORMAT.HYBRID)
        }
        else {
          setTagType(EVENT_FORMAT.PHYSICAL)
        }
      }
      else if (moment(date).isSameOrAfter(moment(datesVirtual.beginAt), 'day') && moment(date).isSameOrBefore(moment(datesVirtual.endAt), 'day')) {
        setTagType(EVENT_FORMAT.VIRTUAL)
      }
    }
  }, [event, exponentFormat])


  return (
    <div className={styles.weekHeader} ref={ref}>
      <div className="rbc-day">
        <Caption1>
          <span>{day} </span> <strong>{dateOfMonth}</strong>
        </Caption1>
      </div>

      {event.format === EVENT_FORMAT.HYBRID && exponentFormat === EVENT_FORMAT.HYBRID && tagType &&
        <div className={styles.tagContainer} style={{ width: columnWidth || 200 }}>
          {tagType === EVENT_FORMAT.HYBRID && (
            <Tag variant="outline" color="neutral" className="w-full justify-center">
              {t({ id:'event.owner.exponents.input.participationMode.optionHybrid'})}
            </Tag>)}
          {tagType === EVENT_FORMAT.PHYSICAL && (
            <Tag variant="outline" color="neutral" className="w-full justify-center">
              <div className="flex items-center">
                <IconMapPin size={12} className="text-neutral-300 mr-4" />
                {t({ id:`exponent.changeView.physical` })}
              </div>
            </Tag>)}
          {tagType === EVENT_FORMAT.VIRTUAL && (
            <Tag variant="outline" color="neutral" className="w-full justify-center">
              <div className="flex items-center">
                <IconWifi size={12} className="text-neutral-300 mr-4" />
                {t({ id:`exponent.changeView.virtual` })}
              </div>
            </Tag>)}
        </div>
      }
    </div>
  );
}

BigCalendarWeekHeader.propTypes = {
  date: string,
  exponentFormat: string,
  event: object,
};

export default BigCalendarWeekHeader;
