import React from 'react';
import { array, any } from 'prop-types';
import { size } from 'lodash';
import classnames from 'classnames';

import styles from './styles.less';
import ImgCdn from '../ImgCdn';

const ImgCascade = ({
  urls = [],
  className,
}) => {
  const max = 2;
  const countImages = size(urls) >= 2 ? 2 : 1;

  return (
    <div className={classnames(styles.container, className)}>
      <div className={classnames(styles.avatarsContainer, styles[`countImages${countImages}`])}>
        {urls.map(
          (src, index) => {
            if (index < max) {
              return (
                <div key={index} className={classnames(styles.avatarContainer)}>
                  <div className={styles.pictureItem} key={index}>
                    <ImgCdn size={50} src={src} className={styles.imgCdn} />
                  </div>
                </div>

              );
            }
            return null;
          }
        )}
      </div>
    </div>
  );
};

ImgCascade.propTypes = {
  urls: array,
  className: any,
};

export { ImgCascade };
