import { defineMessages } from 'react-intl';

export default defineMessages({
  unconfirmed: {
    id: 'recruiter.participantView.interview.unconfirmed',
  },
  confirmed: {
    id: 'recruiter.participantView.interview.confirmed',
  },
  refused: {
    id: 'recruiter.participantView.interview.refused',
  },
  interview: {
    id: 'recruiter.participantView.interview.pending',
  },
  transferred: {
    id: 'recruiter.participantView.interview.transferred',
  },
  other: {
    id: 'recruiter.participantView.interview.other'
  }
});
