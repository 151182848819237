import React, { useEffect, useState } from 'react';
import { func, string } from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import { Form } from 'antd';
import { useFormatMessage } from 'react-intl-hooks';
import { Button, IconLink } from '@seekube-tech/ui-kit';
import { toJS } from '@/utils';
import { isValidHttpUrl } from '@/utils/url';
import InputMaterial from '@/components/Input/InputMaterial';
import bigmarker from '@/assets/images/logo-bigmarker.svg';
import teams from '@/assets/images/logo-teams.svg';
import zoom from '@/assets/images/logo-zoom.svg';
import meet from '@/assets/images/logo-meet.svg';
import Checkbox from '@/components/Checkbox';
import messages from '../messages';
import styles from './styles.less';


const EditLivetalkStep4 =
  ({ handleNext, provider, providerUrl }) => {
    const t = useFormatMessage();
    const [providerPresenterUrlEntered, setProviderPresenterUrlEntered] = useState(provider === 'seeklive' ? 'seeklive' : providerUrl);
    const [providerCandidateUrlEntered, setProviderCandidateUrlEntered] = useState(provider === 'seeklive' ? 'seeklive' : providerUrl);
    const [areTermsChecked, setAreTermsChecked] = useState([false, false]);
    const [isPresenterUrlValid, setIsPresenterUrlValid] = useState(true);
    const [isCandidateUrlValid, setIsCandidateUrlValid] = useState(true);
    const isNextBtnDisabledBigmarker = !(areTermsChecked[0] && areTermsChecked[1]);
    const isNextBtnEnabledOther = areTermsChecked[0] && areTermsChecked[1] && providerCandidateUrlEntered && providerPresenterUrlEntered;

    const nextStep = () => handleNext({ liveUrl: providerCandidateUrlEntered, providerUrl: providerPresenterUrlEntered });

    const onSubmit = () => {
      if (!isValidHttpUrl(providerPresenterUrlEntered)) {
        setIsPresenterUrlValid(false);
      }
      if (!isValidHttpUrl(providerCandidateUrlEntered)) {
        setIsCandidateUrlValid(false);
      }
      if ((isValidHttpUrl(providerPresenterUrlEntered) && isValidHttpUrl(providerCandidateUrlEntered) || providerCandidateUrlEntered === 'seeklive')) {
        nextStep();
      }
    }

    const getProviderName = () => {
      switch (provider) {
        case 'teams':
          return t({id: 'microsoft.teams'});
        case 'meet':
          return t({id: 'google.meet'})
        case 'zoom':
          return t({id: 'zoom'})
        case 'other':
          return t({id: 'recruiter.participantView.interview.other'})
        default:
          return null;
      }
    }

    const getProviderLogo = () => {
      switch (provider) {
        case 'teams':
          return <img src={teams} alt='teams'/>;
        case 'meet':
          return <img src={meet} alt='meet'/>
        case 'zoom':
          return <img src={zoom} alt='zoom'/>
        case 'other':
          return <IconLink size={34} />
        default:
          return null;
      }
    }

    useEffect(() => {
      if (isValidHttpUrl(providerPresenterUrlEntered)) {
        setIsPresenterUrlValid(true);
      }
    }, [providerPresenterUrlEntered])

    useEffect(() => {
      if (isValidHttpUrl(providerCandidateUrlEntered)) {
        setIsCandidateUrlValid(true);
      }
    }, [providerCandidateUrlEntered])

    return (
      <div className={styles.editLivetalkStep2}>
        {provider === 'seeklive' &&
          <div className={styles.liveStorm}>
            <div className={styles.legendContainer}>
              <p>{t({ id: 'form.helper.multi-required' })}</p>
            </div>
            <div className={styles.infoContainer}>
              <div className={styles.title}>
                <img src={bigmarker} alt="bigmarker"/>
                <p>Bigmarker</p>
              </div>
              <Checkbox className={styles.checkbox}
                        onChange={({ target }) => setAreTermsChecked([target.checked, areTermsChecked[1]])}>{t(
                { id: 'recruiter.live.edit.tool.term.1' })}(<a target="_blank"
                                                               href="https://www.bigmarker.com/system_check">{t(
                { id: 'test.compatibility' })}</a>)<span className={styles.required}>*</span></Checkbox>
              <Checkbox onChange={({ target }) => setAreTermsChecked([areTermsChecked[0], target.checked])}>{t(
                { id: 'recruiter.live.edit.tool.term.2' })}<span className={styles.required}>*</span></Checkbox>
              {/* <Checkbox onChange={({ target }) => setIsReplayActive(target.checked)}>{t({id: 'recruiter.live.edit.tool.term.replay'})}</Checkbox> */}
            </div>
            <div className={styles.actions}>
              <Button disabled={isNextBtnDisabledBigmarker} color="primary" type="fill" onClick={onSubmit}>
                {t({ id: 'next' })}
              </Button>
            </div>
          </div>
        }
        {provider !== 'seeklive' &&
          <div className={styles.otherProviders}>
            <div className={styles.legendContainer}>
              <p>{t({ id: 'form.helper.multi-required' })}</p>
            </div>
            <div className={styles.providerContainer}>
              <div className={styles.header}>
                {getProviderLogo()}
                <p>{getProviderName()}</p>
              </div>
              <p className={styles.description}>{t({ id: 'live.creation.url.advice' })}</p>
              <div className={styles.providerUrl}>
                <InputMaterial
                  hasError={!isPresenterUrlValid}
                  value={providerPresenterUrlEntered}
                  addOnIcon="link"
                  label={<span>{t({ id: 'client.liveTalks.editLiveTalks.2.roomLink.label.presenter' })}<span
                    className={styles.required}>*</span></span>}
                  placeholder={t({ id: 'client.liveTalks.editLiveTalks.2.roomLink.placeholder' })}
                  type="string"
                  onChange={(val) => setProviderPresenterUrlEntered(val.target.value)}
                />
              </div>
              {
                !isPresenterUrlValid && (
                  <span className={styles.error}>
                    <FormattedMessage {...messages.urlFormatNotValid} />
                  </span>
                )}
              <div className={styles.providerUrl}>
                <InputMaterial
                  hasError={!isCandidateUrlValid}
                  value={providerCandidateUrlEntered}
                  addOnIcon="link"
                  label={<span>{t({ id: 'client.liveTalks.editLiveTalks.2.roomLink.label.candidate' })}<span
                    className={styles.required}>*</span></span>}
                  placeholder={t({ id: 'client.liveTalks.editLiveTalks.2.roomLink.placeholder' })}
                  type="string"
                  onChange={(val) => setProviderCandidateUrlEntered(val.target.value)}
                />
              </div>
              {
                !isCandidateUrlValid && (
                  <span className={styles.error}>
                    <FormattedMessage {...messages.urlFormatNotValid} />
                  </span>
                )}
            </div>
            <div className={styles.checkboxGroup}>
              <Checkbox onChange={({ target }) => setAreTermsChecked(
                [target.checked, areTermsChecked[1]])}><FormattedMessage {...messages.toolTerm1} /><span
                className={styles.required}>*</span></Checkbox>
              <Checkbox onChange={({ target }) => setAreTermsChecked(
                [areTermsChecked[0], target.checked])}><FormattedMessage {...messages.toolTerm2} /><span
                className={styles.required}>*</span></Checkbox>
            </div>
            <div className={styles.actions}>
              <Button disabled={!isNextBtnEnabledOther} color="primary" type='fill' onClick={onSubmit}>
                {t({ id: 'next' })}
              </Button>
            </div>
          </div>
        }
      </div>
    );
  };


EditLivetalkStep4.propTypes = {
  provider: string,
  providerUrl: string,
  handleNext: func,
};

const withConnect = connect(null, null);
const withForm = Form.create();

export default compose(
  withConnect,
  withForm
)(toJS(EditLivetalkStep4));
