import React from 'react';
import { string, object } from 'prop-types';

const Iframe = ({
  src,
  title,
  style = { width: '100%', height: '650px', border: 'none', marginTop: '20px' },
  rest }) => (
    <iframe
      style={style}
      title={title}
      src={src}
      {...rest}
    />
);

Iframe.propTypes = {
  src: string,
  title: string,
  rest: object,
  style: object,
};

export { Iframe };
