import React from 'react';
import { object } from 'prop-types';
import moment from 'moment';

import { FormattedHTMLMessage } from 'react-intl';
import { Avatar as AvatarBusiness, Typography } from '@seekube-tech/ui';
import { Zap as ZapIcon } from 'react-feather';
import Icon from '@/components/Icon';
import Avatar from '@/components/Avatar';
import useStyles from './styles';

const FeedItem = ({ data }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Images {...data} />
      <Description {...data} />
    </div>
  )
}

FeedItem.propTypes = {
  data: object,
}

const Images = ({ owner, target, _organization, _event }) => {
  const classes = useStyles();

  const getAvatarProps = ({ type, pictureUrl }) => {
    switch (type) {
      case 'recruiter': return { imageFit: 'contain',type: 'circle', src: pictureUrl };
      case 'event': return { imageFit: 'cover',type: 'rounded', src: _event?.theme?.pictureUrl };
      case 'candidate': return { imageFit: 'contain', type: 'circle', src: pictureUrl };
      case 'organization': return { imageFit: 'contain', type: 'rounded', src: _organization?.profile?.pictureUrl };

      default: return { imageFit: 'contain', type: 'circle', src: pictureUrl };
    }
  }

  const ownerAvatarProps = getAvatarProps({ ...owner });
  const targetAvatarProps = getAvatarProps({ ...target });

  return (
    <div className={classes.images}>
      <div className={classes.image}>
        <Avatar
          className={classes[owner.type]}
          size={40}
          type={ownerAvatarProps.type}
          src={ownerAvatarProps.src}
          imageFit={ownerAvatarProps.imageFit}
        />
        {owner.type === 'recruiter' &&
          <AvatarBusiness
            variant="business"
            size="small"
            src={_organization?.profile?.pictureUrl}
            className={classes.imageWithOrga}
          />}
      </div>
      <div  className={classes.image}>
        <Avatar
          className={classes[target.type]}
          size={40} type={targetAvatarProps.type}
          src={targetAvatarProps.src}
          imageFit={targetAvatarProps.imageFit}
        />
        {target.type === 'recruiter'  && owner.type !== 'organization' &&
          <AvatarBusiness
            variant="business"
            size="small"
            src={_organization?.profile?.pictureUrl}
            className={classes.imageWithOrga}
          />}
      </div>
    </div>
  )
}

Images.propTypes = {
  owner: object,
  target: object,
  _organization: object,
  _event: object,
}

const Description = (props) => {
  const classes = useStyles();
  const descriptionInfos = getFeedText({ ...props });

  return (
    <div>
      <Typography variant="body2"  className={classes.description}  color="neutral.500">
        {typeof descriptionInfos.icon === 'string' ?
          <Icon name={descriptionInfos.icon} className={classes.svg} />
          : descriptionInfos.icon }
        <FormattedHTMLMessage values={descriptionInfos.values} id={descriptionInfos.intlId} />
      </Typography>
    </div>
  )
}

const getFeedText = ({
  name,
  _event,
  _organization = {},
  target,
  _offer,
  owner,
  isInformal1to1,
  _conference,
}) => {
  const classes = useStyles();

  switch (name) {
    case 'ORGANIZATION_PUBLISH_EVENT':
      return ({
        icon: 'house',
        intlId: 'feed.description.organizationPublishEvent',
        values: {
          organizationName: _organization.name,
          eventName: _event?.name,
          jobfairBeginAt: moment(_event?.keyDates.jobfair.beginAt).format('DD/MM/YYYY'),
          jobfairEndAt: moment(_event?.keyDates.jobfair.endAt).format('DD/MM/YYYY'),
        },
      });
    case 'ORGANIZATION_INVITE_RECRUITER':
      return ({
        icon: 'building',
        intlId: 'feed.description.organizationInviteRecruiter',
        values: {
          organizationName: _organization.name,
          eventName: _event?.name,
          recruiterFullName: target.fullName,
        },
      });
    case 'RECRUITER_ACCEPT_APPLICATION':
      return ({
        icon: isInformal1to1 ? 'language' :'star',
        intlId: isInformal1to1 ?
          'feed.description.recruiterAcceptApplication.informal1to1'
          : 'feed.description.recruiterAcceptApplication.interview',
        values: {
          organizationName: _organization.name,
          recruiterFullName: owner.fullName,
          recruiterTitle: owner.title,
          candidateFullName: target.fullName,
          offerName: _offer?.title,
        },
      });
    case 'RECRUITER_CONFIRM_APPOINTMENT':
      return ({
        icon: isInformal1to1 ? 'language' :'star',
        intlId: isInformal1to1 ?
          'feed.description.recruiterConfirmAppointment.informal1to1'
          : 'feed.description.recruiterConfirmAppointment.interview',
        values: {
          organizationName: _organization.name,
          recruiterFullName: owner.fullName,
          recruiterTitle: owner.title,
          candidateFullName: target.fullName,
          offerName: _offer?.title,
        },
      });
    case 'RECRUITER_PROPOSE_SOURCING':
      return ({
        icon: 'calendar',
        intlId: 'feed.description.recruiterProposeSourcing',
        values: {
          recruiterFullName: owner.fullName,
          recruiterTitle: owner.title,
          organizationName: _organization.name,
          candidateFullName: target.fullName,
        },
      });
    case 'RECRUITER_PUBLISH_CONFERENCE':
      return ({
        icon: 'camera',
        intlId: 'feed.description.recruiterPublishConference',
        values: {
          recruiterFullName: owner.fullName,
          recruiterTitle: owner.title,
          organizationName: _organization.name,
          eventName: _event?.name,
          conferenceName: _conference.title,
        },
      });
    case 'CANDIDATE_SUBSCRIBES_EVENT':
      return ({
        icon: <ZapIcon className={classes.svg} />,
        intlId: 'feed.description.candidateSubscribesEvent',
        values: {
          candidateFullName: owner.fullName,
          eventName: _event?.name,
        },
      });
    case 'CANDIDATE_POSITION_APPOINTMENT':
      return ({
        icon: isInformal1to1 ? 'language' :'star',
        intlId: isInformal1to1 ?
          'feed.description.candidatePositionAppointment.informal1to1'
          : 'feed.description.candidatePositionAppointment.interview',
        values: {
          candidateFullName: owner.fullName,
          recruiterFullName: target.fullName,
          recruiterTitle: target.title,
          organizationName: _organization.name,
        },
      });
    case 'CANDIDATE_SUBSCRIBES_WAITING_LIST':
      return ({
        icon: 'illuHourglass',
        intlId: 'feed.description.candidateSubscribesWaitingList',
        values: {
          candidateFullName: owner.fullName,
          recruiterFullName: target.fullName,
          recruiterTitle: target.title,
          organizationName: _organization.name,
        },
      });
    case 'CANDIDATE_ACCEPT_APPLICATION':
      return ({
        icon: isInformal1to1 ? 'language' :'star',
        intlId: isInformal1to1 ?
          'feed.description.candidateAcceptApplication.informal1to1'
          : 'feed.description.candidateAcceptApplication.interview',
        values: {
          candidateFullName: owner.fullName,
          recruiterFullName: target.fullName,
          recruiterTitle: target.title,
          organizationName: _organization.name,
        },
      });
    case 'CANDIDATE_ACCEPT_SOURCING':
      return ({
        icon: 'calendar',
        intlId: 'feed.description.candidateAcceptSourcing',
        values: {
          candidateFullName: owner.fullName,
          recruiterFullName: target.fullName,
          recruiterTitle: target.title,
          organizationName: _organization.name,
        },
      });
    case 'CANDIDATE_PARTICIPATE_CONFERENCE':
      return ({
        icon: 'camera',
        intlId: 'feed.description.candidateParticipateConference',
        values: {
          candidateFullName: owner.fullName,
          conferenceName: _conference.title,
        },
      });
    case 'CANDIDATE_CONNECT_CONFERENCE':
      return ({
        icon: 'camera',
        intlId: 'feed.description.candidateConnectConference',
        values: {
          candidateFullName: owner.fullName,
          conferenceName: _conference.title,
        },
      });

    default:
      return ({
        icon: 'building',
        intlId: 'feed.description.organizationInviteRecruiter',
        values: {
          organizationName: _organization.name,
          eventName: _event?.name,
          recruiterFullName: target.fullName,
        },
      });
  }
}

export { FeedItem }