import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { Form } from 'antd';
import { Tooltip, TooltipTrigger, TooltipContent } from '@seekube-tech/ui-kit';
import InputMaterial from '@/components/Input/InputMaterial';
import styles
  from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/Signup/components/SignupModal/components/Identity/styles.less';
import Icon from '@/components/Icon';

const FormItem = Form.Item;

export const StudentId = ({ form, regexCriteria, user }) => {
  const t = useFormatMessage();
  const label = regexCriteria.modules.onboarding[user.locale === 'en' ? 'label_en' : 'label'];

  return (
    <>
      <FormItem className={styles.studentId}>
        {form.getFieldDecorator('studentId', {
          initialValue: user.studentId,
          rules: [
            {
              required: true,
              message: t({ id: 'form.invalid' }, { name: label }),
              pattern: new RegExp(regexCriteria.value)
            },
          ],
        })(
          <InputMaterial
            maxLength="100"
            addOnIcon={regexCriteria.icon}
            label={label}
            placeholder={label}
            type="text"
            validations={['required']}
          />
        )}
        {regexCriteria.isHintVisible &&
          <Tooltip>
            <TooltipTrigger>
              <span className={styles.hint}><Icon name="info-circle"/></span>
            </TooltipTrigger>
            <TooltipContent>{regexCriteria[user.locale === 'en' ? 'hint_en' : 'hint']}</TooltipContent>
          </Tooltip>}
      </FormItem>
    </>
  )
}