import React, { useState } from 'react';
import { object, string, func } from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { useFormatMessage } from 'react-intl-hooks';
import { AlignLeft } from 'react-feather';
import { Button, Body1, Caption1, IconPhone, IconVideo, IconMapPin, IconUser } from '@seekube-tech/ui-kit';
import { Popover } from 'antd';
import classnames from 'classnames';
import { toJS } from '@/utils';
import copyToClipboard from '@/utils/copyToClipboard';
import Avatar from '@/components/Avatar';
import { getApiUrl } from '@/utils/url';
import styles from './styles.less';
import { getParticipant as getParticipantAction } from '@/store/participant/actions';
import { notificationActions } from '@/store/notification';
import Details from '../Details';
import EditInformal1to1Description from '../../EditInformal1to1Description/EditInformal1to1Description';

function AppointmentDetails({
  event,
  appointmentColor,
  getParticipant,
  timezone,
  closePopover,
  sendNotification,
  handleOnInformalDescriptionChange,
  informalDescription,
}) {
  const t = useFormatMessage();
  const [isPopoverVisible, setPopoverVisible] = useState(false);

  const appointment = event._appointment;
  const appointmentStart = moment.tz(event.start, timezone);
  const appointmentEnd = moment.tz(event.end, timezone);
  const today = moment.tz(new Date(), timezone);
  const isInformal1to1 = event.type === "informal1to1";
  const canSeeProfile = event.jobfair.readOnly
    && moment.tz(event.jobfair.readOnly.jobfairBeginAt, timezone).isBefore(today)
    && moment.tz(event.jobfair.readOnly.jobfairEndAt, timezone).isAfter(today);

  function openProfileInNewTab() {
    getParticipant({
      eventId: appointment._event.slug,
      userId: appointment._user.id,
      noLoader: true,
      openProfile: true,
      redirectUrl: `/${appointment._event.slug}/recruiter/jobdating/`,
    });
  }

  function copyVisioShareUrlToClipboard() {
    copyToClipboard(getApiUrl(appointment.shareVisioUrl));
    sendNotification({
      message: t({ id: 'event.recruiter.preparation.timeslots.event.visioShareLinkCopied' }),
      kind: 'success',
      style: {
        bottom: '7%',
      },
    });
  }
  /**
   * Handle popover visibility
   * @param {Boolean} visibility
   */
  const handleTogglePopover = (visibility = false) => {
    setPopoverVisible(visibility);
  };

  return (
    <Popover
      placement="right"
      content={
        <EditInformal1to1Description
          description={event.informalDescription}
          closePopover={() => handleTogglePopover(false)}
          event={event}
          handleOnInformalDescriptionChange={handleOnInformalDescriptionChange}
          isPopoverVisible={isPopoverVisible}
        />
      }
      visible={isPopoverVisible}
      onVisibleChange={handleTogglePopover}
      trigger="click"
      zIndex={1000} // mandatory because the Agenda Modal is in the hundreds
    >
      <Details type={event.type} closePopover={closePopover} >
        <Body1 className="text-neutral-400">
          <strong className="mr-4">{appointmentStart.format('dddd DD MMMM')} {t({ id: 'time.at' })}</strong>
          {appointmentStart.format('HH:mm')} - {appointmentEnd.format('HH:mm')}
        </Body1>
        <div className="mt-8 flex">
          <div className={styles.colorBadge} style={{ backgroundColor: appointmentColor }} />
          <Caption1 className="text-neutral-500">{appointment._event.name}</Caption1>
        </div>
        <hr className={classnames(styles.hr, styles.lightPadding)} />
        <div className={classnames(styles.row, 'pb-0')}>
          <div>
            <Avatar size={24} user={appointment._user} src={appointment._user.pictureUrl} />
          </div>
          <div className="flex flex-1 flex-nowrap items-center justify-between">
            <Body1 className="text-neutral-500">{appointment._user.fullName}</Body1>
            {
              canSeeProfile && (
                <a
                  role="button"
                  tabIndex={0}
                  onClick={() => openProfileInNewTab()}
                >
                  {t({ id: 'event.recruiter.preparation.timeslots.event.profileLink' })}
                </a>
              )
            }
          </div>
        </div>
        <div className={styles.row}>
          <div><IconUser size={16} className="text-neutral-300" /></div>
          <div><Body1 className="text-neutral-500">{appointment._user.username}</Body1></div>
        </div>
        {
          appointment.settings.medium === 'physical' && (
            <div className={styles.row}>
              <div>
                <IconMapPin className="text-neutral-300" size={12} />
              </div>
              <div>
                <Body1 className="text-neutral-500">{appointment.settings.location}</Body1>
              </div>
            </div>
          )
        }
        <div className={styles.row}>
          <div>
            <IconPhone className="text-neutral-300" size={12} />
          </div>
          <div>
            <Body1 className="text-neutral-500">{appointment._user.phone}</Body1>
          </div>
        </div>
        {
          appointment.settings.medium === 'visio' && (
            <>
              <div className={styles.row}>
                <div>
                  <IconVideo className="text-neutral-300" size={12} />
                </div>
                <div className="flex flex-1 flex-nowrap items-center justify-between">
                  <Body1 className="text-neutral-500">{t({ id: 'event.recruiter.preparation.timeslots.event.visioLabel' })}</Body1>
                </div>
              </div>
              <hr className={styles.hr} />
              <div className="flex items-center justify-between">
                <Button size="small" variant="text" onClick={() => copyVisioShareUrlToClipboard()}>
                  {t({ id: 'event.recruiter.preparation.timeslots.event.shareVisioLink' })}
                </Button>
                <div className={styles.column}>
                  <a href={getApiUrl(appointment.recruiterVisioUrl)} target="_blank">
                    <Button size="small">
                      {t({ id: 'event.recruiter.preparation.timeslots.event.openVisioLink' })}
                    </Button>
                  </a>
                </div>
              </div>
            </>
          )
        }
        {
          isInformal1to1 && (
            <>
              <div className={styles.row}>
                <div>
                  <AlignLeft styles={styles.icon} style={{ width: 16, height: 16 }} />
                </div>
                <div>
                  <p>{informalDescription}</p>
                </div>
              </div>
              <div className={styles.editDesciptionBtnContainer}>
                <button className={styles.editDesciptionBtn} onClick={() => handleTogglePopover(true)}>
                  {t({ id: 'event.recruiter.preparation.timeslots.event.modifyInformalDescription' })}
                </button>
              </div>
            </>
          )
        }
      </Details>
    </Popover>
  );
}

AppointmentDetails.propTypes = {
  event: object.isRequired,
  appointmentColor: string.isRequired,
  timezone: string.isRequired,
  getParticipant: func,
  closePopover: func.isRequired,
  sendNotification: func,
  handleOnInformalDescriptionChange: func,
  informalDescription: string,
};

const mapDispatchToProps = {
  getParticipant: getParticipantAction,
  sendNotification: notificationActions.sendNotification,
};

export default connect(null, mapDispatchToProps)(toJS(AppointmentDetails));
