import { useQuery } from 'react-query';
import request from '@/utils/request';

export function useGetJobdatingDiscoveryAccess() {
  const requestUrl = `${process.env.FRONT_API_URL}/users/me/jobdating-discovery-access`;
  const queryFn = () => request(requestUrl, {
    method: 'GET'
  });

  const query = useQuery({
    queryKey: ['jobdatingDiscoveryAccess'],
    queryFn
  });

  return query;
}
