import request from '@/utils/request';

const getParticipantLimits = ({ event, participant }) => {
  const requestUrl = `${process.env.FRONT_API_URL}/events/${event._id}/participants/${participant._id}/applicationsCounter`;
  return request(requestUrl, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
  })
};

export { getParticipantLimits };
