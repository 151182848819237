import React from 'react';
import { object, array, func, string, node, oneOfType } from 'prop-types';

// Components
import Icon from '@/components/Icon';
import Popover from '@/components/Popover';
import Contact from '../Contact';
import OfferContent from '../OfferContent';

// Styles
import styles from '../../../styles.less';

const OfferItem = ({ offer, event, participant, appointment, highlightWords, handleOpenOffer, interaction, context, popoverContent, style, type }) => {
  let renderMoreAction = '';

  if (!appointment || appointment.status !== 'canceled') {
    renderMoreAction = (
      <div className={styles.more}>
        <Popover popoverContent={popoverContent} placement="bottomRight">
          <button><Icon name="more" className={styles.icon} /></button>
        </Popover>
      </div>
    );
  }

  return (
    <>
      <div className={styles.mainContent} style={{ ...style }}>
        <OfferContent
          offer={offer}
          event={event}
          highlightWords={highlightWords}
          handleOpenOffer={handleOpenOffer}
          interaction={interaction}
          appointment={interaction ? interaction._appointment : null}
          context={context}
          type={type}
        />
      </div>

      <Contact
        offer={offer}
        event={event}
        appointment={appointment}
        participant={participant}
        handleOpenOffer={handleOpenOffer}
        interaction={interaction}
      />
      {renderMoreAction}
    </>
  );
};

OfferItem.propTypes = {
  offer: object,
  event: object,
  appointment: object,
  participant: object,
  interaction: object,
  highlightWords: array,
  handleOpenOffer: func,
  context: string,
  popoverContent: node,
  style: oneOfType([object, string]),
  type: string,
};

OfferItem.defaults = {
  context: 'offer',
  style: null,
  type: 'default',
};

export default OfferItem;
