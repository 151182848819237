import React from 'react';
import { object } from 'prop-types';
import { orderBy, take, map } from 'lodash';
import { compose } from 'redux';
import { useFormatMessage } from 'react-intl-hooks';

import { toJS } from '@/utils';

import { BasicRanking, RankingOfferVisit } from './components';

import styles from './styles.less';

const ExponentsRankingsScene = ({
  stats: { offersStats, exponentsActivitiesStats },
}) => {
  const t = useFormatMessage();

  return (
    <div className={styles.exponentsRankings}>
      <div className={styles.inlineTab}>
        <BasicRanking
          messageTitle={t({ id: 'event.owner.statistics.exponentsRankings.standVisit.title'})}
          dataSource={getXFirstSource({ arr: exponentsActivitiesStats, iterateeToSort: ['countVisitsStand'] })}
        />
        <BasicRanking
          messageTitle={t({ id: 'event.owner.statistics.exponentsRankings.offerVisit.title' })}
          dataSource={getXFirstSource({ arr: exponentsActivitiesStats, iterateeToSort: ['countVisitsOffers'] })}
        />
      </div>
      <RankingOfferVisit
        messageTitle={t({ id: 'event.owner.statistics.exponentsRankings.offerApplications.title' })}
        dataSource={getXFirstSource({
          arr: offersStats,
          iterateeToSort: ['applications'],
          callback: (el) => ({ offer: el.title, name: el.organization }),
        })}
      />
    </div>
  );
};

const getXFirstSource = ({ arr = [], limit = 1000, iterateeToSort = ['countVisits'], order = ['desc'], callback = () => {} }) =>
  map(
    take(orderBy(arr, iterateeToSort, order), limit),
    (el, key) =>
      ({ key, index: `#${key + 1}`, name: `${el.name}`, count: el[iterateeToSort[0]], ...callback(el, key) })
  );

ExponentsRankingsScene.propTypes = {
  stats: object,
};


export default compose(
  toJS,
)(ExponentsRankingsScene);
