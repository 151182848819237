import React from 'react';
import { bool, func, string, oneOf } from 'prop-types';
import classnames from 'classnames';
import is from 'is_js';

// Components
import { If, Else, Then } from 'react-if';
import Nl2br from '@/components/Nl2br';

// Styles & Translations
import styles from './styles.less';

/**
 * InlineEditor
 */
class InlineEditor extends React.PureComponent {
  static propTypes = {
    editorEnabled: bool,
    onChange: func,
    onBlur: func,
    value: string,
    placeholder: string,
    className: string,
    area: bool,
    type: oneOf(['phone', 'email', 'number']),
  };

  static defaultProps = {
    area: false,
    placeholder: '...',
    value: '',
  };

  /**
   * State
   */
  state = {
    value: this.props.value,
    goodType: true,
  };


  handleOnChange = (e) => {
    const { props: { onChange }, checkType } = this;

    if (typeof onChange === 'function') {
      const {value} = e.target;
      const goodType = checkType(value);

      // Update the state
      this.setState({ value, goodType });

      // Send the value to the props if the value has the good type
      onChange(value);
    }
  };

  handleOnBlur = (e) => {
    const { props: { onBlur }, checkType } = this;

    if (typeof onBlur === 'function') {
      const {value} = e.target;
      const goodType = checkType(value);

      // Update the state
      this.setState({ value, goodType });

      // Send the value to the props if the value has the good type
      onBlur(value);
    }
  };


  checkType = (value) => {
    const { props: { type } } = this;

    if (type === 'phone') {
      return is.eppPhone(value);
    } if (type === 'email') {
      return is.email(value);
    } if (type === 'number') {
      return is.number(value);
    }

    return true;
  };

  render() {
    const {
      state: { value, goodType },
      props: { editorEnabled, area, placeholder, className },
      handleOnChange, handleOnBlur,
    } = this;

    const inputClass = classnames(
      styles.parentStyle,
      (!goodType) ? styles.wrongValue : null,
      className,
    );

    let minHeight = '7em';

    if (value.length > 110) {
      minHeight = `${(value.length % 30) + 8}em`;
    }

    return (
      <If condition={editorEnabled}>
        <Then>
          <If condition={area}>
            <Then>
              <textarea
                cols="100"
                style={{ minHeight }}
                placeholder={placeholder}
                className={inputClass}
                value={value}
                onChange={(e) => handleOnChange(e)}
              />
            </Then>
            <Else>
              <input
                placeholder={placeholder}
                className={inputClass}
                value={value}
                onChange={(e) => handleOnChange(e)}
                onBlur={(e) => handleOnBlur(e)}
              />
            </Else>
          </If>
        </Then>
        <Else><p className={styles.parentStyle}><Nl2br text={value} /></p></Else>
      </If>
    );
  }
}

export default InlineEditor;
