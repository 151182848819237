import React from 'react';
import ParticipantsView from '@/scenes/Event/scenes/Recruiter/scenes/Jobdating/container/ParticipantsView';

/**
 * RecruiterJobdatingCandidatesScene
 */
class RecruiterJobdatingSourcingScene extends React.PureComponent { // eslint-disable-line
  /**
   * Render
   */
  render() {
    return (
      <ParticipantsView context="sourcing" />
    );
  }
}

export default RecruiterJobdatingSourcingScene;
