import React from 'react';
import { object, oneOf, bool, any, string, number } from 'prop-types';
import { isEmpty } from 'lodash';
import classnames from 'classnames';

import { Card } from 'antd';
import Avatar from '@/components/Avatar';

// Styles & Translations
import styles from './styles.less';

/**
 * H1
 * @param {object} props
 */
const UserCard = ({ user, organization, titlePrefix, type, showOrganizationPicture, className, userSize }) => {
  if (isEmpty(user)) {
    return '';
  }

  return (
    <Card className={classnames(styles.userCard, styles[type], className)}>
      <div className={styles.header}>
        <Avatar src={user.pictureUrl} size={userSize} user={user} defaultColor="#385077" />

        <div className={styles.userInfo}>
          <h3>{titlePrefix ? `${titlePrefix} ` : ''}{user.firstName} {user.lastName}</h3>
          <p>{user.title}</p>
        </div>

        {showOrganizationPicture && organization ? (
          <div className={styles.organizationPicture}>
            <Avatar size={32} src={organization.profile.pictureUrl} type="rounded" />
          </div>) : ''}
      </div>

      {type === 'full' ? (
        <div className={styles.description}>
          <p>{user.description}</p>
        </div>
      ) : ''}
    </Card>
  );
};

UserCard.propTypes = {
  user: object,
  organization: object,
  titlePrefix: any,
  showOrganizationPicture: bool,
  type: oneOf(['default', 'full']),
  className: string,
  userSize: number,
};

UserCard.defaultProps = {
  type: 'default',
  hideLinkedinUrl: false,
  showOrganizationPicture: false,
  titlePrefix: null,
  className: '',
  userSize: 60,
};

export default UserCard;
