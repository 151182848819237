import React from 'react';
import { IconMapPin, IconWifi } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import moment from 'moment/moment';
import { getEventKeyMoments } from '@/helpers/event/getEventKeyMoments';
import {
  getBeginDate
} from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/ParticipationMode/utils';
import { EVENT_FORMAT } from '@/utils/constants';
import styles
  from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/ParticipationMode/KeyMomentInformation/styles.less';

export const KeyMomentInformation = ({ event, format }) => {
  const t = useFormatMessage();
  const momentBegin = moment(getEventKeyMoments(event, { type: "jobfair", format })?.beginAt);
  const momentEnd = moment(getEventKeyMoments(event, { type: "jobfair", format })?.endAt);
  const endDate = momentEnd.format('Do MMMM YYYY');
  const beginDate = getBeginDate(momentBegin, momentEnd);
  const icon = format === EVENT_FORMAT.PHYSICAL ? <IconMapPin size={16} /> : <IconWifi size={16} />

  return (
    <div className={styles.keyMomentInformation}>
      <div className={styles.title}>
        {icon}
        <p>{t({ id: format })}</p>
      </div>
      <div className={styles.moreInfo}>
        <p>{`${t({ id: 'date.from' })}
                ${beginDate} ${t({ id: 'date.to' })}
                ${endDate}
              `}
        </p>
        {
          format === EVENT_FORMAT.PHYSICAL && (
            <p>{` ${event?.location?.formattedAdress}, ${event?.location?.countryCode}`}</p>)
        }
      </div>
    </div>
  )
}