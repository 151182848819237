import React from 'react';
import ParticipantsView from '@/scenes/Event/scenes/Recruiter/scenes/Jobdating/container/ParticipantsView';

class RecruiterJobdatingSelectionScene extends React.PureComponent { // eslint-disable-line
  render() {
    return (
      <ParticipantsView context="selection" />
    );
  }
}

export default RecruiterJobdatingSelectionScene;
