import { usePostUserLogout } from '@/queries/users/usePostUserLogout';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getAuthUser } from '@/store/auth/selectors';

const LogoutScene = () => {
  const authUser = useSelector(getAuthUser)?.toJS();
  
  const { mutate: logoutUser } = usePostUserLogout({ authUser });

  useEffect(() => {
   logoutUser();
  }, []);
  
  return null;
}

export default LogoutScene;
