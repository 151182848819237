import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { Typography } from '@seekube-tech/ui'

const phases = (event, appointmentStatus, match) => {
  const t = useFormatMessage();

  return ({
    applicationsDisabled: {
      button: {
        disabled: true,
        text: event.skipAppointmentValidation ?
          t({ id: 'event.candidate.jobdating.offer.takeAppointment' }) :
          t({ id: 'event.candidate.jobdating.offer.apply' })
      },
      text: <>
        <Typography variant="body1">
          {event.skipAppointmentValidation ?
            t({ id: 'candidate.offer.applications-disabled.description'}) :
            t({ id: 'event.candidate.jobdating.offer.apply'})}
          <span style={{ marginLeft: '4px' }}>
            <Typography
              variant="link1"
              component="a"
              target="_blank"
              href={`${window.location.origin}/${match.params.eventSlug}/candidate/keydates`}
            >
              {t({ id: 'see.keyDates' })}
            </Typography>
          </span>
        </Typography>

      </>,
    }
  })[appointmentStatus];
}

export { phases };
