import { func } from 'prop-types';
import * as React from 'react';
import Outlook from '@/scenes/Settings/components/SyncCalendar/components/outlook';

function Sync({ onUpdate }) {
  return <Outlook onUpdate={onUpdate} presetValue />
}

Sync.propTypes = {
  onUpdate: func.isRequired
}

export default Sync;
