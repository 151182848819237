import React, { useState } from 'react';
import { compose } from 'redux';
import { object, func, array } from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Form, Input, Radio, Select as SelectAnt } from 'antd';
import { isEmpty } from 'lodash';
import classnames from 'classnames';
import { useFormatMessage } from 'react-intl-hooks';

// Components
import { Button } from '@seekube-tech/ui-kit';
import { Select } from '@/components/Form';
import Icon from '@/components/Icon';
import Html from '@/components/Html';
import { Tooltip } from '@/components/Tooltip';
import WysiwygEditor, { isWysiwygFieldCompleted } from '@/components/WysiwygEditor';
import { Contacts } from './components';
import { Bloc, BlocHeader } from '../../../../../../../../../../components/Bloc';

// Styles & Translations
import styles from '../../../../styles.less';
import messages from '../../messages';


// Antd
const FormItem = Form.Item;
const { Option } = SelectAnt;
const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;

const PublishOnBtoB = ({
  form: { getFieldDecorator, validateFields, getFieldValue },
  initialValues,
  intl: { formatMessage, locale },
  defaultCriteria,
  onSubmit,
}) => {
  const t = useFormatMessage();

  const contractCriteria = defaultCriteria.find((criteria) => criteria.key === 'CONTRACT');
  const levelCriteria = defaultCriteria.find((criteria) => criteria.key === 'LEVEL');
  const positionCriteria = defaultCriteria.find((criteria) => criteria.key === 'POSITION');
  const [val, setVal] = useState(isEmpty(initialValues) ? '' : initialValues.ownerDescription);
  const [ownerDescriptionError, setOwnerDescriptionError] = useState(false);
  const isBtobDisabled = isEmpty(initialValues.planners._organizations) || isEmpty(initialValues.keyDates || initialValues.keyMoments);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isEmpty(val)) return setOwnerDescriptionError(true);

    return validateFields((err, fields) => {
      if (!err) {
        onSubmit(err, {
          ...fields,
          ownerDescription: val,
        });
      }
    });
  };

  const handleChangeWysiwyg = (value) => {
    if (typeof value !== 'object') setVal(isWysiwygFieldCompleted(value) ? value : null);
  };

  return (
    <Bloc>
      <Form onSubmit={handleSubmit} style={{ width: '100%', textAlign: 'left' }} layout="vertical" className={styles.publishOnBtoB}>
        <BlocHeader>
          <FormattedMessage {...messages.b2bTitle} tagName="h2" />
        </BlocHeader>
        <span className={styles.textGrey}>
          <FormattedMessage {...messages.b2bDescription} />
        </span>
        <br /><br />
        <FormItem label={<FormattedMessage {...messages.b2bLabel} />}>
          {getFieldDecorator('calendarB2B', {
            initialValue: initialValues ? initialValues.calendarB2B : false,
          })(
            <RadioGroup>
              <Tooltip color="dark" placement="bottom" title={isBtobDisabled && formatMessage(messages.b2bDisabledMessage)}><RadioButton disabled={isBtobDisabled} value ><FormattedMessage {...messages.yes} /></RadioButton></Tooltip>
              <RadioButton value={false}><FormattedMessage {...messages.no} /></RadioButton>
            </RadioGroup>
          )}
        </FormItem>
        {getFieldValue('calendarB2B') &&
          <div>
            <FormItem label={formatMessage(messages.b2bWysigLabel)} className={classnames(styles.wysig, ownerDescriptionError && styles.error)} required>
              <WysiwygEditor
                value={val}
                onChange={handleChangeWysiwyg}
              />
              {ownerDescriptionError && <span className="textError" style={{ paddingTop: '5px', position: 'absolute' }}>{formatMessage(messages.errorFieldRequired)}</span>}
            </FormItem>
            <FormattedMessage {...messages.b2bTargetLabel} tagName="p" />
            <FormItem>
              {getFieldDecorator('targetContracts', {
                initialValue: initialValues && initialValues.targetContracts,
              })(
                <Select
                  mode="multiple"
                  placeholder={t({ id: 'event.diffusion.b2bCalendar.targetContracts.placeholder' })}
                  addOnIcon={contractCriteria.icon}
                >
                  {
                    contractCriteria._choices.map((choice) =>
                      <Option key={choice._id} value={choice._id}>{choice[`label${locale === 'en' ? '_en' : ''}`]}</Option>)
                  }
                </Select>
              )}
            </FormItem>
            <FormItem>
              {getFieldDecorator('targetLevels', {
                initialValue: initialValues && initialValues.targetLevels,
              })(
                <Select
                  mode="multiple"
                  placeholder={t({ id: 'event.diffusion.b2bCalendar.targetLevels.placeholder' })}
                  addOnIcon={levelCriteria.icon}
                >
                  {
                    levelCriteria._choices.map((choice) =>
                      <Option key={choice._id} value={choice._id}>{choice[`label${locale === 'en' ? '_en' : ''}`]}</Option>)
                  }
                </Select>
              )}
            </FormItem>
            <FormItem>
              {getFieldDecorator('targetPositions', {
                initialValue: initialValues && initialValues.targetPositions,
              })(
                <Select
                  mode="multiple"
                  placeholder={t({ id: 'event.diffusion.b2bCalendar.targetPositions.placeholder' })}
                  addOnIcon={positionCriteria.icon}
                >
                  {
                    positionCriteria._choices.map((choice) =>
                      <Option key={choice._id} value={choice._id}>{choice[`label${locale === 'en' ? '_en' : ''}`]}</Option>)
                  }
                </Select>
              )}
            </FormItem>
            <FormattedMessage {...messages.b2bPricingContactsLabel} tagName="p" />
            <FormItem style={{ width: '24%' }}>
              {getFieldDecorator('pricing', {
                initialValue: initialValues && initialValues.pricing,
                rules: [{ required: true, message: formatMessage(messages.errorFieldRequired) }],
              })(
                <Select
                  required
                  placeholder={formatMessage(messages.pricingPl, { count: 2 })}
                >
                  {
                    ['not-provided', 'free', 'paying', 'info']
                      .map(
                        (key) => <Option key={key} value={key}><FormattedMessage {...messages[`pricing_${key}`]} /></Option>
                      )
                  }
                </Select>
              )}
            </FormItem>

            <Contacts
              getFieldDecorator={getFieldDecorator}
              formatMessage={formatMessage}
              contacts={initialValues.contacts}
            />
            <FormItem
              label={<span>{formatMessage(messages.b2bPublicLinkLabel)} <Tooltip color="dark" title={<Html value={formatMessage(messages.b2bPublicLinkTooltip)} />}><span><Icon name="info-circle" /></span></Tooltip></span>}
            >
              {
                getFieldDecorator('externalUrl', {
                  initialValue: initialValues && initialValues.externalUrl,
                  rules: [
                    { type: 'url', message: formatMessage(messages.videosError) },
                  ],
                })(
                  <Input type="text" />
                )
              }
            </FormItem>
          </div>
        }
        {/* Submit */}
        <div>
          <Button type="submit">
            {t({ id: 'save' })}
          </Button>
        </div>
      </Form>
    </Bloc>
  );
};

PublishOnBtoB.propTypes = {
  form: object,
  defaultCriteria: array,
  intl: object,
  initialValues: object,
  onSubmit: func.isRequired,
};

const withForm = Form.create();
export default compose(
  injectIntl,
  withForm,
)(PublishOnBtoB);
