import React, { useEffect, useState } from 'react'
import { useFormatMessage } from 'react-intl-hooks';
import { Typography } from '@seekube-tech/ui';
import { Button } from '@seekube-tech/ui-kit';
import classnames from 'classnames';
import { object, bool } from 'prop-types'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { compose } from 'redux'
import {
  useSendResumeOnboardingMail
} from '@/queries/onboarding/useSendResumeOnboardingMail'
import ModalV2 from '@/components/ModalV2'
import { participantSelectors } from '@/store/participant'
import { getId } from '@/utils/global'
import toJS from '@/utils/toJS'
import { eventSelectors } from '@/store/event'
import styles from './styles.less';
import { trackOnboardingContinue } from '@/utils/analytics/trackers/category/eventOnboarding';

const BtnFinishLater = ({ participant, event, show }) => {
  const t = useFormatMessage();
  const [visible, setVisible] = useState(false);
  const { refetch, isSuccess } =  useSendResumeOnboardingMail({ eventSlug: event._id, participantId: getId(participant) });

  const sendEmail = () => {
    refetch();
  }

  useEffect(() => {
    if (isSuccess) {
      setVisible(true)
    }

  }, [isSuccess]);

  return (
    <div>
      {show &&
        <>
          <ModalV2
            onClose={() => setVisible(false)}
            visible={visible}
            className="customConfirm"
            template="center">
            <div className={styles.emoji}>🎉</div>
            <Typography variant="body2" className={styles.modalDescription}>
              {t({ id: 'form.profile.description.later.modal.title' })}
            </Typography>
          </ModalV2>
          <Button
            color="neutral"
            variant="outline"
            className={classnames(styles.btnFinishLaterContainer)}
            disabled={isSuccess}
            onClick={() => {
              trackOnboardingContinue();

              if (!participant?.mailing?.resumeOnboardingSentAt) {
                sendEmail();
              }
            }}
          >
            {t({ id: 'form.profile.description.later' })}
          </Button>
        </>
      }
    </div>
  )
}

BtnFinishLater.propTypes = {
  participant: object,
  event: object,
  show: bool
}

const mapStateToProps = createStructuredSelector({
  participant: participantSelectors.getCurrentParticipant,
  event: eventSelectors.getCurrentEvent,
})

const withConnect = connect(mapStateToProps, null);

export default compose(withConnect, toJS)(BtnFinishLater)
