import React from 'react';
import { object, string } from 'prop-types';
import classNames from 'classnames';
import { Body2, H5, AvatarCandidate } from '@seekube-tech/ui-kit';
import moment from 'moment';
import Html from '@/components/Html';
import styles from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Dashboard/components/Posts/styles.less';

export function Post({ post, className }) {

  return (
    <div className={`${styles.post} ${className}`}>
      <div className="flex mb-24">
        <div className="flex grow items-center" style={{ width: '80%' }}>
          <AvatarCandidate pictureUrl={post._author?.pictureUrl} size={48} user={post._author} className="mr-8" />
          <div className={styles.recruiter}>
            <div><Body2 fontWeight={600} className="text-neutral-600">{post._author.fullName}</Body2></div>
            <div className="text-neutral-400 whitespace-preline">{post._author.title}</div>
          </div>
        </div>
        <div className="flex items-end text-neutral-400 whitespace-nowrap">{moment(post.createdAt).fromNow()}</div>
      </div>
      <div className="mb-16">
        <H5>
          {post.title}
        </H5>
      </div>
      <div className={classNames("text-neutral-400 mt-4", styles.postContent)}>
        <Html value={post.content} />
      </div>
    </div>
  )
}

Post.propTypes = {
  post: object,
  className: string,
}
