import React from 'react';
import { object } from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { withLayout } from '@/containers/Layout';
import toJS from '@/utils/toJS';
import { Cover, Todos, MyStats, Posts } from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Dashboard/components';
import MobileHeader from '@/containers/Layout/containers/MobileHeader';
import MobileMenu from '@/containers/Layout/containers/MobileMenu';
import { EVENT_TYPE } from '@/utils/constants';
import styles from './styles.less';

const Dashboard = ({ event, ...props }) => {
  const isJpo = event?.type === EVENT_TYPE.jpo;

  return (
    <>
      <div className={styles.dashboard}>
        <Cover event={event} {...props} />
        {!isJpo && <MyStats event={event} {...props} />}

        <div className={styles.container}>
          {event.modules.dashboard.displayTodo &&
            <div className={styles.todoContainer}><Todos event={event} {...props} /></div>
          }
          <Posts/>
        </div>
      </div>

      <MobileHeader event={event}/>
      <MobileMenu
        displayInformal={event.modules?.informal1to1?.enable}
        displayLive={event.modules?.live && event.modules?.live?.participantEnable}
        liveLabel={event.modules?.live && event.modules?.live?.labelMultiple}
      />
    </>);
}

Dashboard.propTypes = {
  event: object,
}

export default compose(
  withLayout({ size: 'default', withHeader: false, navType: 'candidate' }),
  withRouter,
  toJS,
)(Dashboard);
