import React from 'react';
import { fromJS } from 'immutable';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { Toggle } from '@seekube-tech/ui-kit';

// Components
import { Input, InputNumber, Table } from 'antd';
import Icon from '@/components/Icon';
import DragTable from '@/components/DragTable';
import { injectIntl } from 'react-intl';
import ChoiceModal from '../EditChoiceModal';
import JobsModal from '../JobsModal';

// Styles & Translations
import styles from '../CriteriaTable/styles.less';

class DefaultCriteriaTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      criteria: props.criteria,
      needToSubmit: false,
      choicesModal: fromJS({
        isVisible: false,
        criterionIndex: null,
        choices: [],
      }),
      jobModalIsOpen: false,
      jobsCriterionSelected: null,
    };
  }

  getColumns = () => {
    const {
      props: { disableColumns, preventChangeValue },
      state: { needToSubmit },
    } = this;

    const columns = [
      {
        title: this.props.intl.formatMessage({ id: 'owner.settings.criteriaTable.view.activate' }),
        dataIndex: `modules.${this.props.context}.isVisible`,
        key: `modules.${this.props.context}.isVisible`,
        width: '10%',
        render: (value, criterion, index) => (
          <Toggle
            checked={value}
            onChange={this.handleEnableCriterion([criterion._id, `modules.${this.props.context}.isVisible`])}
          />
        ),
      },
      {
        title: this.props.intl.formatMessage({ id: 'owner.settings.criteriaTable.view.title' }),
        dataIndex: 'name',
        key: 'name',
        width: '15%',
        render: (value) => (
          <span>{value}</span>
        ),
      },
      {
        title: this.props.intl.formatMessage({ id: 'owner.settings.criteriaTable.view.label' }),
        // dataIndex: `modules.${this.props.context}.label`,
        key: `modules.${this.props.context}.label`,
        width: '25%',
        render: (value, criterion, index) => (
          <div>
            <Input
              className={styles.inputLabeSpace}
              value={value.modules[this.props.context].label}
              placeholder={this.props.intl.formatMessage({ id: 'owner.settings.criteriaTable.view.label.placeholder' })}
              onBlur={() => needToSubmit ? this.submit() : null}
              onChange={(e) => this.handleBasicInputChange([criterion._id, 'modules', this.props.context, 'label'])(e.target.value)}
            />
            <Input
              value={value.modules[this.props.context].label_en}
              placeholder={this.props.intl.formatMessage({ id: 'owner.settings.criteriaTable.view.label.question' })}
              onBlur={() => needToSubmit ? this.submit() : null}
              onChange={(e) => this.handleBasicInputChange([criterion._id, 'modules', this.props.context, 'label_en'])(e.target.value)}
            />
          </div>
        ),
      },
    ];

    if (!disableColumns.includes('min')) {
      columns.push({
        title: this.props.intl.formatMessage({ id: 'owner.settings.criteriaTable.view.min' }),
        dataIndex: `modules.${this.props.context}.choiceMin`,
        key: `modules.${this.props.context}.choiceMin`,
        width: '5%',
        render: (value, criterion, index) => (
          <InputNumber
            value={value}
            disabled={preventChangeValue.includes(criterion.key) && this.props.context === 'offer'}
            onChange={this.handleInputChoiceChange('Min', [criterion._id, 'modules', this.props.context])}
            min={0}
          />
        ),
      });
    }

    if (!disableColumns.includes('max')) {
      columns.push({
        title: this.props.intl.formatMessage({ id: 'owner.settings.criteriaTable.view.max' }),
        dataIndex: `modules.${this.props.context}.choiceMax`,
        key: `modules.${this.props.context}.choiceMax`,
        width: '5%',
        render: (value, criterion, index) => (
          <InputNumber
            value={value}
            disabled={preventChangeValue.includes(criterion.key) && this.props.context === 'offer'}
            size="default"
            onChange={this.handleInputChoiceChange('Max', [criterion._id, 'modules', this.props.context])}
            min={criterion.modules[this.props.context].choiceMin}
          />
        ),
      });
    }

    if (!disableColumns.includes('position')) {
      columns.push({
        title: this.props.intl.formatMessage({ id: 'owner.settings.criteriaTable.view.order' }),
        dataIndex: `modules.${this.props.context}.position`,
        key: `modules.${this.props.context}.position`,
        width: '10%',
        render: () => (
          <div className={styles.positionContainer}><Icon className={styles.icon} name="menu-burger" /></div>
        ),
      });
    }

    return columns;
  };

  /**
   * Handle basic input changed
   *
   * @param {Array} key
   */
  handleBasicInputChange = (key) => (value) => {
    const { state } = this;
    const index = state.criteria.toJS().findIndex(e => e._id === key[0]);

    key[0] = index;
    const oldValue = state.criteria.getIn(key)
    const criteria = state.criteria.setIn(key, value);

    console.log(oldValue, value)
    if (oldValue !== value) {
      this.setState({ criteria, needToSubmit: true });
    }
  };

  /**
   * Handle the switch for enable the criterion
   *
   * @param {Array} key
   */
  handleEnableCriterion = (key) => (value) => {
    // Enable the criterion
    const { state } = this;
    const index = state.criteria.toJS().findIndex(e => e._id === key[0]);
    key[0] = index;

    const criteria = state.criteria.setIn(key, value);

    // Update the state
    this.setState({ criteria }, this.submit);
  };

  /**
   * Set new choices and close the modal
   *
   * @param {Array} choices
   */
  handleChoiceModalOnOk = (choices) => {
    const { state: { choicesModal } } = this;

    const criteria = this.state.criteria
      .setIn([choicesModal.get('criterionIndex'), '_choices'], fromJS(choices));

    this.setState({ criteria }, () => {
      this.toggleChoicesModal();
      this.submit();
    });
  };

  /**
   * Set new choices and close the modal
   *
   * @param {Array} choices
   */
  handleJobsModalOnOk = (choices) => {
    const { state: { choicesModal } } = this;

    const criteria = this.state.criteria
      .setIn([choicesModal.get('criterionIndex'), '_jobs'], fromJS(choices));

    this.setState({ criteria }, () => {
      this.closeJobsModal();
      this.submit();
    });
  };

  /**
   * Toggle the Choice modal
   */
  toggleChoicesModal = () => {
    const choicesModal = this.state.choicesModal
      .set('isVisible', !this.state.choicesModal.get('isVisible'));

    // Update the state
    this.setState({ choicesModal });
  };

  /**
   * Toggle the Choice modal
   */
  closeJobsModal = () => {
    this.setState({ jobsModalIsOpen: false });
  };

  /**
   * Send to the props submit
   *
   * @type {DebouncedFunc<(function(*): void)|*>}
   */
  submit = debounce((updatedCriteria) => {
    const { props: { onSubmit }, state: { criteria } } = this;

    this.setState({ needToSubmit: false }, () => onSubmit(updatedCriteria || criteria));
  }, 600);

  handleSortRow = (rows) => {
    const updatedCriteria = this.props.criteria.toJS().map((criterion) => {
      const updatedCriteria = criterion;
      const sortRowIndex = rows.findIndex((row) => criterion._id.toString() === row._id.toString());
      updatedCriteria.modules[this.props.context].position = sortRowIndex + 1;
      return updatedCriteria;
    });

    this.props.setFetching();

    this.setState({ criteria: fromJS(updatedCriteria) }, () => this.submit(fromJS(updatedCriteria)));
  };

  /**
   * Handle input choice changed
   *
   * @param {string} type
   * @param {Array} key
   */
  handleInputChoiceChange = (type, key) => (value) => {
    const { submit, state } = this;
    const index = state.criteria.toJS().findIndex(e => e._id === key[0]);
    key[0] = index;
    const module = state.criteria.getIn(key).toJS();

    let criteria;
    if (value === 0) {
      criteria = state.criteria.setIn(key, fromJS({
        ...module,
        choiceMin: 0,
        choiceMax: 0,
      }));
    } else if ((type === 'Min' && (value > module.choiceMax)) || (type === 'Max' && (value < module.choiceMin))) {
      criteria = state.criteria.setIn(key, fromJS({
        ...module,
        choiceMin: value,
        choiceMax: value,
      }));
    } else {
      criteria = state.criteria
        .setIn(key.concat([`choice${type}`]), value);
    }

    // Update the state
    this.setState({ criteria }, () => submit(criteria));
  };

  /**
   * Render
   */
  render() {
    const {
      getColumns, handleSortRow,
      state: { criteria, choicesModal, jobsModalIsOpen, jobsCriterionSelected },
      props: { jobs, disableColumns, isFetching, context },
    } = this;

    const sortCriteriaForDisplay = criteria.toJS()
      .sort((a, b) => a.modules[context].position - b.modules[context].position);

    return (
      <div>
        <div className={styles.tableContainer}>
          <ChoiceModal
            isVisible={choicesModal.get('isVisible')}
            choices={choicesModal.get('choices').toJS()}
            onOk={this.handleChoiceModalOnOk}
            onCancel={this.toggleChoicesModal}
          />
          <JobsModal
            isVisible={jobsModalIsOpen}
            jobs={jobs.toJS()}
            jobsSelected={jobsCriterionSelected ? jobsCriterionSelected._jobs : []}
            onOk={this.handleJobsModalOnOk}
            onCancel={this.closeJobsModal}
          />
          {!disableColumns.includes('position') && !isFetching ? (
            <DragTable
              onSort={handleSortRow}
              className={styles.criteriaTableContainer}
              rowKey="_id"
              columns={getColumns()}
              dataSource={sortCriteriaForDisplay}
              pagination={false}
            />
          ) : (
            <Table
              className={styles.criteriaTableContainer}
              rowKey="_id"
              columns={getColumns()}
              dataSource={sortCriteriaForDisplay}
              pagination={false}
            />
          )}
        </div>
      </div>
    );
  }
}

DefaultCriteriaTable.propTypes = {
  criteria: PropTypes.object,
  jobs: PropTypes.array,
  onSubmit: PropTypes.func,
  context: PropTypes.string,
  disableColumns: PropTypes.array,
  preventChangeValue: PropTypes.array,
  isFetching: PropTypes.bool,
  setFetching: PropTypes.func,
  intl: PropTypes.object,
}


DefaultCriteriaTable.defaultProps = {
  disableColumns: [],
  preventChangeValue: [],
};

export default injectIntl(DefaultCriteriaTable);
