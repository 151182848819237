import { useEffect, useRef } from 'react';

/**
 * Return a previous value
 * @param {Any} value
 * @returns {Any}
 */
export function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}