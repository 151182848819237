import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { object, func } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { toJS } from '@/utils';

// Store
import { eventActions, eventSelectors } from '@/store/event';
import { surveyActions, surveySelectors } from '@/store/survey';

// Components
import Form from './components/Form/index';

// Styles & Translations
import styles from '../../../../styles.less';


const FeedbackScene = ({
  event,
  patchEvent,
  intl,
  survey,
  match,
  getSurveyEvent,
}) => {
  useEffect(
    () => {
      getSurveyEvent({ eventId: match.params.eventSlug });
    }, [],
  );

  const handleOnSubmit = (err, values) => {
    if (!err) {
      patchEvent({
        event: values,
        callback: () => getSurveyEvent({ eventId: event._id }),
        notificationParams: {
          success: {
            message: intl.formatMessage({ id: 'notifications.update.success' }),
            kind: 'info',
            style: {
              bottom: '5%',
              top: 'inherit',
            },
          },
        },
      });
    }
  };


  return (
    <div className={styles.formWrapper}>
      <Form
        key={survey ? survey._id : 'surveyForm'}
        initialValues={{
          enableRecruiterSurvey: survey && event.enableRecruiterSurvey,
          enableCandidateSurvey: survey && event.enableCandidateSurvey,
          sendRecruiterSurvey: survey && event.sendRecruiterSurvey,
          sendCandidateSurvey: survey && event.sendCandidateSurvey,
        }}
        intl={intl}
        onSubmit={handleOnSubmit}
        survey={survey}
      />
    </div>
  );
};

FeedbackScene.propTypes = {
  event: object,
  match: object,
  intl: object,
  survey: object,
  patchEvent: func,
  getSurveyEvent: func,
};

const mapStateToProps = createStructuredSelector({
  event: eventSelectors.getCurrentEvent,
  survey: surveySelectors.getCurrentSurvey,
});

const mapDispatchToProps = {
  patchEvent: eventActions.patchEvent,
  getSurveyEvent: surveyActions.getSurveyEvent,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  injectIntl,
  toJS,
)(FeedbackScene);
