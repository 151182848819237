import { defineMessages } from 'react-intl';

export default defineMessages({
  inCharge: {
    id: 'appointment.inCharge',
  },
  activityWithColleague: {
    id: 'participant.profile.activityWithColleague',
  },
});
