import * as React from 'react';
import { number, string } from 'prop-types';
import { FormattedHTMLMessage } from 'react-intl';
import { IconBriefcase } from '@seekube-tech/ui-kit';

function CountOffers({ countOffers, dateStatus }) {
  return countOffers && dateStatus === 'in_progress' ?
    <span key="countOffers">
      <IconBriefcase size={16} />
      <FormattedHTMLMessage values={{ count: countOffers }} id="events.stats.opportunities" />
    </span> : null
}


CountOffers.propTypes = {
  countOffers: number,
  dateStatus: string
}

export default CountOffers;
