import React from 'react';
import { string, array, object, bool } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Tag } from '@seekube-tech/ui-kit';
import { ValueWithWarning } from '@/components/ValueWithWarning';
import {
  MoreActions
} from '@/scenes/Event/scenes/Owner/scenes/OnBoarding/containers/Dashboard/components/Recruiters/MoreActions';
import { EVENT_FORMAT } from '@/utils/constants';
import { MemberColumn } from '@/scenes/Admin/scenes/Organizations/scenes/Organization/components/memberColumn';

const ColumnsUi = (contexts, isHybrid, participatingBothVirtualAndPhysical, exponent, authUser) => (
  [
    {
      id: 'exponentUser',
      Header: <FormattedMessage
        id="recruiter.preparation.team"/>,
      disableSortBy: true,
      accessor: ({ exponentUser }) =>
        <MemberColumn
          record={{ ...exponentUser }}
          authUser={authUser}
          onActionClick={() => {}}
          organization={exponent._organization}
        />
    },
    {
      id: 'offers',
      Header: <FormattedMessage id="event.owner.dashboard.organizations.offers"/>,
      className: 'text-center',
      disableSortBy: true,
      accessor: ({ exponentUser }) => <ValueWithWarning value={exponentUser.countOffersPublished} />
    },
    {
      id: 'physicalHybrid',
      Header: <Tag variant="outline" color="neutral" className="w-full justify-center text-lowercase">
        <span style={{ textTransform: 'initial' }}><FormattedMessage id="physical.slots" /></span>
      </Tag>,
      columns: [
        {
          id: 'physicalCountAppointment',
          Header: exponent._event?.modules?.informal1to1?.enable ? <FormattedMessage id="interview.pl" values={{ count: 2 }} /> : '',
          SubHeaders: [<FormattedMessage id="available.pl" values={{ count: 1 }} />, <FormattedMessage id="booked.plgr"  values={{  count: 1, gender: 'male' }} />],
          accessor: ({ exponentUser }) => [
            <ValueWithWarning value={isHybrid && participatingBothVirtualAndPhysical ?  exponentUser[EVENT_FORMAT.PHYSICAL].countSlotsAvailable : exponentUser.countSlotsAvailable} />,
            <ValueWithWarning value={isHybrid && participatingBothVirtualAndPhysical ?  exponentUser[EVENT_FORMAT.PHYSICAL].countAppointmentInterview : exponentUser.countAppointmentInterview} />
          ],
          disableSortBy: true,
        },
        {
          id: 'physicalCountInformal',
          Header: exponent._event?.modules?.informal1to1?.enable ? <FormattedMessage id="chat.oneToOne.pl" values={{ count: 2 }} /> : '',
          SubHeaders: [<FormattedMessage id="available.pl" values={{ count: 1 }} />, <FormattedMessage id="booked.plgr"  values={{  count: 1, gender: 'male' }} />],
          accessor: ({ exponentUser }) => [
            <ValueWithWarning value={isHybrid && participatingBothVirtualAndPhysical ?  exponentUser[EVENT_FORMAT.PHYSICAL].countInformalSlotsAvailable : exponentUser.countInformalSlotsAvailable} />,
            <ValueWithWarning value={isHybrid && participatingBothVirtualAndPhysical ?  exponentUser[EVENT_FORMAT.PHYSICAL].countAppointmentInformal : exponentUser.countAppointmentInformal} />
          ],
          disableSortBy: true,
        },
      ].filter(column => !(column.id === 'physicalCountInformal' && !exponent._event?.modules?.informal1to1?.enable))
    },
    {
      id: 'virtualHybrid',
      Header: <Tag variant="outline" color="neutral" className="w-full justify-center">
        <span style={{ textTransform: 'initial' }}><FormattedMessage id="virtual.slots" /></span>
      </Tag>,
      columns: [
        {
          id: 'virtualCountAppointment',
          Header: exponent._event?.modules?.informal1to1?.enable ? <FormattedMessage id="interview.pl" values={{ count: 2 }} /> : '',
          SubHeaders: [<FormattedMessage id="available.pl" values={{ count: 1 }} />, <FormattedMessage id="booked.plgr"  values={{  count: 1, gender: 'male' }} />],
          accessor: ({ exponentUser }) => [
            <ValueWithWarning value={isHybrid && participatingBothVirtualAndPhysical ?  exponentUser[EVENT_FORMAT.VIRTUAL].countSlotsAvailable : exponentUser.countSlotsAvailable} />,
            <ValueWithWarning value={isHybrid && participatingBothVirtualAndPhysical ?  exponentUser[EVENT_FORMAT.VIRTUAL].countAppointmentInterview : exponentUser.countAppointmentInterview} />
          ],
          disableSortBy: true,
        },
        {
          id: 'virtualCountInformal',
          Header: <FormattedMessage id="chat.oneToOne.pl" values={{ count: 2 }} />,
          SubHeaders: [<FormattedMessage id="available.pl" values={{ count: 1 }} />, <FormattedMessage id="booked.plgr"  values={{  count: 1, gender: 'male' }} />],
          accessor: ({ exponentUser }) => [
            <ValueWithWarning value={isHybrid && participatingBothVirtualAndPhysical ?  exponentUser[EVENT_FORMAT.VIRTUAL].countInformalSlotsAvailable : exponentUser.countInformalSlotsAvailable} />,
            <ValueWithWarning value={isHybrid && participatingBothVirtualAndPhysical ?  exponentUser[EVENT_FORMAT.VIRTUAL].countAppointmentInformal : exponentUser.countAppointmentInformal} />
          ],
          disableSortBy: true,
        },
      ].filter(column => !(column.id === 'virtualCountInformal' && !exponent._event?.modules?.informal1to1?.enable))
    },
    {
      id: 'physicalCountAppointment',
      Header: exponent._event?.modules?.informal1to1?.enable ? <FormattedMessage id="interviews.slots" /> : <FormattedMessage id="informal1to1.stats.timeslot.title" />,
      SubHeaders: [<FormattedMessage id="available.pl" values={{ count: 1 }} />, <FormattedMessage id="booked.plgr"  values={{  count: 1, gender: 'male' }} />],
      accessor: ({ exponentUser }) => [
        <ValueWithWarning value={isHybrid && participatingBothVirtualAndPhysical ?  exponentUser[EVENT_FORMAT.PHYSICAL].countSlotsAvailable : exponentUser.countSlotsAvailable} />,
        <ValueWithWarning value={isHybrid && participatingBothVirtualAndPhysical ?  exponentUser[EVENT_FORMAT.PHYSICAL].countAppointmentInterview : exponentUser.countAppointmentInterview} />
      ],
      disableSortBy: true,
    },
    {
      id: 'physicalCountInformal',
      Header: <FormattedMessage id="slots.informal1to1" />,
      SubHeaders: [<FormattedMessage id="available.pl" values={{ count: 1 }} />, <FormattedMessage id="booked.plgr"  values={{  count: 1, gender: 'male' }} />],
      accessor: ({ exponentUser }) => [
        <ValueWithWarning value={isHybrid && participatingBothVirtualAndPhysical ?  exponentUser[EVENT_FORMAT.PHYSICAL].countInformalSlotsAvailable : exponentUser.countInformalSlotsAvailable} />,
        <ValueWithWarning value={isHybrid && participatingBothVirtualAndPhysical ?  exponentUser[EVENT_FORMAT.PHYSICAL].countAppointmentInformal : exponentUser.countAppointmentInformal} />
      ],
      disableSortBy: true,
    },
    {
      id: 'virtualCountAppointment',
      Header: exponent._event?.modules?.informal1to1?.enable ? <FormattedMessage id="interviews.slots" /> : <FormattedMessage id="informal1to1.stats.timeslot.title" />,
      SubHeaders: [<FormattedMessage id="available.pl" values={{ count: 1 }} />, <FormattedMessage id="booked.plgr"  values={{  count: 1, gender: 'male' }} />],
      accessor: ({ exponentUser }) => [
        <ValueWithWarning value={exponentUser.countSlotsAvailable} />,
        <ValueWithWarning value={exponentUser.countAppointmentInterview} />
      ],
      disableSortBy: true,
    },
    {
      id: 'virtualCountInformal',
      Header: <FormattedMessage id="slots.informal1to1" />,
      SubHeaders: [<FormattedMessage id="available.pl" values={{ count: 1 }} />, <FormattedMessage id="booked.plgr"  values={{  count: 1, gender: 'male' }} />],
      accessor: ({ exponentUser }) => [
        <ValueWithWarning value={exponentUser.countInformalSlotsAvailable} />,
        <ValueWithWarning value={exponentUser.countAppointmentInformal} />
      ],
      disableSortBy: true,
    },
    {
      id: 'actions',
      Header: '',
      disableSortBy: true,
      accessor: (record) => (
        <MoreActions
          _user={record.exponentUser}
          contexts={contexts}
          onActionClick={record.actions}
          organization={exponent._organization}
          role={record.exponentUser.role}
        />
      )
    },
  ]
);

ColumnsUi.propTypes = {
  tool: object,
  createdAt: string,
  _organization: object,
  users: array,
  locale: string,
  event: object,
  isHybrid: bool,
};

export default ColumnsUi;
