import React from 'react';
import { Tag } from '@seekube-tech/ui-kit';
import { object } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { STATUS } from '@/forms/Stand/utils/constants';

const StandStatusAccessor =  ({ tool }) => {
  const t = useFormatMessage();

  const status = tool?.profile?.status || tool?._organization?.profile?.status || 'draft';

  return <>
    {status === STATUS.published ? (
      <Tag
        variant="tonal"
        color="neutral"
      >
        {t({ id: 'admin.organizations.detail.published.stand' })}
      </Tag>
    ) : (
      <Tag
        variant="tonal"
        color="warning"
      >
        {t({ id: 'event.status.draft' })}
      </Tag>
    )}
  </>;
}

StandStatusAccessor.propTypes = {
  tool: object,
}

export { StandStatusAccessor }