import React from 'react';
import moment from 'moment';
import { array, object, string } from 'prop-types';
import { injectIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import classnames from 'classnames';

// Components
import { TooltipCount } from '@/components/Tooltip/components/TooltipCount';
import { MySearch } from './components';

// Styles & Translations
import styles from './styles.less';
import messages from './messages';

const Search = ({ participant, type, intl, authUser, user, defaultCriteria }) => {
  const subject = !isEmpty(participant) ? participant : user;
  moment.locale(intl.locale);

  let countOtherFilters = 0;

  if (!isEmpty(subject.matching.languages)) {
    countOtherFilters += subject.matching.languages.length;
  }

  if (type === 'inline' && countOtherFilters > 0) {
    return (
      <span className={styles.searchInlineContainer}>
        <MySearch participant={subject} type={type} authUser={authUser} defaultCriteria={defaultCriteria} />
        <div className={styles.tooltipContainer}>
          <TooltipCount max={99} count={countOtherFilters} className={styles.tooltip} />
        </div>
        <span><strong> {intl.formatMessage(messages.others)}</strong></span>
      </span>
    );
  }

  return (
    <div className={classnames(styles.searchContainer, styles[type])}>
      <div className={styles.mySearch}>
        <MySearch participant={subject} type={type} authUser={authUser} defaultCriteria={defaultCriteria} />
      </div>
    </div>
  );
};

Search.propTypes = {
  intl: object,
  participant: object,
  user: object,
  defaultCriteria: array,
  type: string,
  authUser: object,
};

Search.defaultProps = {
  type: 'default',
  authUser: {},
};

export default injectIntl(Search);
