import * as React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment-timezone';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FormattedHTMLMessage } from 'react-intl';
import { useFormatMessage } from 'react-intl-hooks';
import { DatePicker } from 'antd';
import { head, get } from 'lodash';
import FormItem from 'antd/lib/form/FormItem';
import { createStructuredSelector } from 'reselect';

import { toJS } from '@/utils';
import { If } from '@/components/If';
import { Alert } from '@seekube-tech/ui-kit';
import { eventSelectors } from '@/store/event';
import ApiToForm from '../../../services/formMapping/ApiToForm';

import messages from '../../../messages';
import styles from '../styles.less';
import InputContainer from "./InputContainer";
import FormSection from './FormSection';

const { RangePicker } = DatePicker;

const datePickerParams = {
  format: 'DD-MM-YYYY HH:mm:ss',
  showTime: {
    defaultValue: moment('07:00:00', 'HH:mm:ss'),
  },
};

const rangePickerParams = {
  format: 'DD-MM-YYYY HH:mm:ss',
  showTime: {
    defaultValue: [moment('07:00:00', 'HH:mm:ss'), moment('23:00:00', 'HH:mm:ss')],
  },
};

function KeyDatesForm({ form, event }) {
  const t = useFormatMessage();
  const { getFieldDecorator, getFieldValue } = form;

  const keyDatesOk = getFieldValue('keyDatesOk');
  const huntDates = getFieldValue('keyDates.hunt');
  const interactionsClosingDates = getFieldValue('keyDates.interactionsClosing');

  return (
    <div className={styles.keyDatesContainer}>
      <FormSection icon="users" title={t({ id: "meet.pl" }, { count: 2 })}>
        <InputContainer
          label={t({ id: "event.basic.input.keydates.jobdating.label" })}
          subLabel={t({ id: "event.basic.input.keydates.jobdating.sublabel" })}
          fieldIsMandatory>
          <FormItem>
            {
              getFieldDecorator('keyDates.jobfair', {
                rules: [
                  { required: true, message: t(messages.inputKeyDatesRequired) },
                ],
                initialValue: ApiToForm.keyDates(event.keyDates, 'jobfair', 'range', event.timezone.utc[0]),
              })(
                <RangePicker
                  {...rangePickerParams}
                />
              )
            }
          </FormItem>
        </InputContainer>
        <InputContainer
          label={t({ id: "event.keydates.live.title-recruiter" })}
          subLabel={t({ id: "event.basic.input.keydates.live.subLabel" })}>
          <FormItem>
            {getFieldDecorator('keyDates.live', {
              initialValue: ApiToForm.keyDates(event.keyDates, 'live', 'range', event.timezone.utc[0]),
            })(
              <RangePicker
                {...rangePickerParams}
              />
            )}
          </FormItem>
        </InputContainer>
      </FormSection>

      <FormSection icon="unlock" title={t({ id: "access.and.actions.candidates" })}>
        <InputContainer
          label={t({ id: 'event.basic.input.keydates.discovering.label' })}
          subLabel={t({ id: 'event.basic.input.keydates.discovering.subLabel' })}
          fieldIsMandatory
        >
          <FormItem>
            {getFieldDecorator('keyDates.discovering', {
              initialValue: ApiToForm.keyDates(event.keyDates, 'discovering', 'range', event.timezone.utc[0]),
              rules: [
                { required: keyDatesOk, message: t(messages.inputKeyDatesRequired) },
              ],
            })(
              <RangePicker
                {...rangePickerParams}
              />
            )}
          </FormItem>
        </InputContainer>
        <InputContainer
          label={t({ id: 'event.basic.input.keydates.applicationsClosing.label' })}
          subLabel={t({ id: 'event.basic.input.keydates.applicationsClosing.subLabel' })}
          notaBene={t({ id: 'event.basic.input.keydates.applicationsClosing.notaBene' })}
        >
          <FormItem>
            {getFieldDecorator('keyDates.applicationsClosing', {
              initialValue: ApiToForm.keyDates(event.keyDates, 'applicationsClosing', 'date', event.timezone.utc[0]),
              rules: [
                { required: false, message: t(messages.inputKeyDatesRequired) },
              ],
            })(
              <DatePicker
                {...datePickerParams}
              />
            )}
          </FormItem>
        </InputContainer>
      </FormSection>

      <FormSection icon="unlock" title={t({ id: "access.and.actions.recruiters" })}>
        <InputContainer
          label={t({ id: 'event.basic.input.keydates.hunt.label' })}
          subLabel={t({ id: 'event.basic.input.keydates.hunt.label.subLabel' })}
          fieldIsMandatory
        >
          <FormItem>
            {getFieldDecorator('keyDates.hunt', {
              rules: [
                { required: keyDatesOk && get(event.modules, 'hunt.enable'), message: t(messages.inputKeyDatesRequired) },
              ],
              initialValue: ApiToForm.keyDates(event.keyDates, 'hunt', 'range', event.timezone.utc[0]),
            })(
              <RangePicker
                {...rangePickerParams}
              />
            )}
          </FormItem>
        </InputContainer>
        <InputContainer
          label={t({ id: 'event.basic.input.keydates.interactionsClosing.label.b' })}
          subLabel={t({ id: 'event.basic.input.keydates.interactionsClosing.label' })}
          notaBene={t({ id: 'event.basic.input.keydates.interactionsClosing.description.nb' })}
        >
          <FormItem>
            {getFieldDecorator('keyDates.interactionsClosing', {
              initialValue: ApiToForm.keyDates(event.keyDates, 'interactionsClosing', 'date', event.timezone.utc[0]),
              rules: [
                { required: false, message: t(messages.inputKeyDatesRequired) },
              ],
            })(
              <DatePicker
                {...datePickerParams}
              />
            )}
          </FormItem>
        </InputContainer>
      </FormSection>

      <If condition={interactionsClosingDates && head(huntDates) && interactionsClosingDates.isBefore(head(huntDates))}>
        <Alert className="mb-20" color="warning">
          <FormattedHTMLMessage {...messages.warningInteractionsClosingIsBeforeHunt} />
        </Alert>
      </If>
    </div>
  )
}

KeyDatesForm.propTypes = {
  form: PropTypes.func,
  event: PropTypes.object
}

const mapDispatchToProps = {};

const mapStateToProps = createStructuredSelector({
  event: eventSelectors.getCurrentEvent
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(toJS(KeyDatesForm));
