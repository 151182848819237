import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { Dropdown, Menu } from 'antd';
import { connect } from 'react-redux';
import { string, func } from 'prop-types';
import { IconCalendar, Button, IconChevronDown } from '@seekube-tech/ui-kit';

import { appointmentActions } from '@/store/appointment';

const { Item: MenuItem } = Menu;

const PlanningBtn = ({ slug, downloadAppointments }) => {
  const t = useFormatMessage();

  const exportAllAppointments = () => {
    const notificationParams = {
      success: {
        message: <p><span role="img" aria-labelledby="">⏱️</span> {t({ id: 'download.running' })} <br /> {t(
          {  id: 'recruiter.export.appointments' })}</p>,
        kind: 'info',
        style: {
          bottom: '5%',
          top: 'inherit',
        },
      },
    };

    downloadAppointments({ eventSlug: slug, queryTarget: 'organization', notificationParams });
  };

  return (
    <Dropdown
      overlay={
        <Menu>
          <MenuItem key={0}><a role="button" tabIndex={0} onClick={exportAllAppointments}>{t({ id: 'owner.dashboard.export.pdfPlanning' })}</a></MenuItem>
          <MenuItem key={1}><a role="button" tabIndex={0} onClick={() => window.open(`${process.env.FRONT_EXPORT_API_URL}/events/${slug}/export/appointments?apiKey=41a1289e-fee1-430e-a849-5b2b7ed23f1a`, '_blank')}>{t({ id: 'owner.dashboard.export.csvPlanning' })}</a></MenuItem>
        </Menu>
      }
      trigger={['click']}
      placement="bottomRight"
    >
      <Button variant="outline" color="neutral" imageComponentRight={<IconChevronDown size={16} />} imageComponentLeft={<IconCalendar size={16} />}>{t({ id: 'client.stats.byjobdating.downloadPlanning' })}</Button>
    </Dropdown>
  );
}

PlanningBtn.propTypes = {
  slug: string,
  downloadAppointments: func,
};

const withConnect = connect(null, {
  downloadAppointments: appointmentActions.downloadAppointments,

});

export default withConnect(PlanningBtn)
