import React from 'react';
import { array, bool, func, object, string, number } from 'prop-types';
import { injectIntl } from 'react-intl';
import { isEmpty, toUpper, startsWith, sortBy, map } from 'lodash';
import classnames from 'classnames';

// Components
import { Badge, Tree } from 'antd';
import Checkbox from '@/components/Checkbox';
import Icon from '@/components/Icon';
import Avatar from '@/components/Avatar';

import styles from './styles.less';
import { Input } from '../../Input';

const { TreeNode } = Tree;

class Selector extends React.PureComponent {
  static propTypes = {
    intl: object,
    label: string,
    noLabel: string,
    position: string,
    options: array,
    values: array,
    onChange: func,
    isMobile: bool,
    searchable: bool,
    withAvatar: bool,
    isTree: bool,
    defaultExpandAll: bool,
    limit: number,
    selectAll: bool,
    isLocation: bool,
    isLocationInternational: bool,
    isInput: bool,
    icon: string,
    required: bool,
    noBadge: bool,
    hasError: bool
  };

  static defaultProps = {
    limit: 99999,
    selectAll: true,
    defaultExpandAll: false,
    isLocationInternational: true,
  };

  static getDerivedStateFromProps({ values, options, isLocation }, state) {
    const newState = {
      value: typeof values === 'object' ? values : [],
    };

    if (isLocation && !isEmpty(options)) {
      newState.location = {
        ...state.location,
        options: options.filter((o) => o.parent === state.location.parentId),
      };
    }

    return newState;
  }

  constructor(props) {
    super(props);

    const { options, isLocation } = props;
    let location;

    if (isLocation) {
      const parents = sortBy(options.filter((o) => o && !o.parent), ['label', 'ASC']);
      const franceOption = !isEmpty(parents) ? parents.find((parent) => startsWith(toUpper(parent.label), 'FR')) : {};
      const franceId = !isEmpty(franceOption) ? franceOption.value : undefined;

      location = {
        parents,
        parentId: franceId,
        franceId,
        options: options.filter((o) => o && o.parent === franceId),
      };
    }

    this.state = {
      isOpen: false,
      value: typeof props.values === 'object' ? props.values : [],
      parents: [],
      location,
      searchText: '',
    };
  }

  componentDidMount() {
    document.addEventListener('click', this.documentClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.documentClick);
  }

  getLabelById = (id) => {
    const { props: { options } } = this;

    const option = options.find((o) => o && o.value === id);
    return option ? option.label : id;
  };

  getTree = () => {
    const { props: { options } } = this;
    const parents = options.filter((o) => !o.parent);
    return parents.map((p) => ({ ...p, childrens: options.filter((o) => o.parent === p.value) }));
  };

  clear(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ value: [], isOpen: false, searchText: '' });
    if (typeof this.props.onChange === 'function') this.props.onChange([]);
  }

  updateValue(e, val) {
    e.preventDefault();
    e.stopPropagation();
    const value = [...this.state.value];
    const valIndex = value.indexOf(val);
    if (valIndex === -1) value.push(val);
    else value.splice(valIndex, 1);
    this.setState({ value });
    if (typeof this.props.onChange === 'function') this.props.onChange(value);
  }

  updateTree(checkedKeys) {
    const { props: { limit, onChange } } = this;

    if (checkedKeys.length <= limit) {
      const value = [...checkedKeys];
      this.setState({ value });
      if (typeof onChange === 'function') onChange(value);
    }
  }

  removeValue(val) {
    const { state: { value } } = this;
    const index = value.indexOf(val);

    if (index !== -1) value.splice(index, 1);
    this.setState({ value });
    if (typeof this.props.onChange === 'function') this.props.onChange(value);
  }

  toggle(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ isOpen: !this.state.isOpen });
  }

  documentClick = (e) => {
    if ((!this.$el || !this.$el.contains(e.target)) && this.state.isOpen) this.setState({ isOpen: false, searchText: '' });
  };

  updateLocationParent = (parentId) => {
    const location = {
      ...this.state.location,
      parentId,
      options: this.props.options.filter((o) => o.parent === parentId),
    };

    this.setState({ location });
  };

  render() {
    const {
      props: { intl, label, noLabel, position, options, isMobile, isTree, isLocation, isInput, icon, required, noBadge, limit, selectAll, defaultExpandAll, searchable, withAvatar, hasError, isLocationInternational },
      state: { isOpen, value, location, searchText },
      getLabelById, getTree, updateLocationParent,
    } = this;

    const optionItems = searchable && !isEmpty(options) ? options.filter((o) => ((o.label.toLowerCase().indexOf(searchText.toLowerCase()) > -1) || (o.subLabel && o.subLabel.toLowerCase().indexOf(searchText.toLowerCase()) > -1))) : options;

    return (
      <div
        ref={(node) => { this.$el = node; }}
        className={[
          styles.selector,
          isOpen ? 'open' : '',
          isTree ? 'tree' : '',
          isLocation ? 'location' : '',
          isInput ? 'input' : '',
          position,
          hasError && 'inputErrorStyle'
        ].join(' ')}
      >
        {isInput ? (
          <div className={styles.icon}>
            <Icon name={icon} />
          </div>
        ) : ''}
        <div role="button" tabIndex={0} className={[styles.labelBox, 'selectorLabelBox'].join(' ')} onClick={(e) => this.toggle(e)}>
          <div className={styles.label}>
            {label}
            {required ? (<span className="mandatory"> *</span>) : ''}
          </div>
          {value.length && !isInput ? (
            <div role="button" tabIndex={0} className={styles.labelBadge} onClick={(e) => !isMobile ? this.clear(e) : ''}>
              {value.length}
              {isMobile ? '' : <Icon className={styles.labelBadgeIcon} name="close" />}
            </div>
          ) : ''}
          {!value.length || isMobile || isInput ? <Icon className={styles.chevron} name="chevron" style={{ width: '24px', height: '24px', minWidth: '24px' }} /> : ''}
        </div>
        {isInput && value.length ? (
          <div className={styles.valuesLabels}>
            {value.map((v, i) => (
              <div
                role="button"
                tabIndex={0}
                key={i}
                className={styles.valueLabel}
                onClick={() => this.removeValue(v)}
              >
                {getLabelById(v)}
                <Icon className={styles.labelBadgeIcon} name="close" />
              </div>
            ))}
          </div>
        ) : ''}

        {!isOpen ? null : (
          <div className={classnames(styles.dropdown, 'dropdown')} style={isLocation ? { marginBottom: '120px' } : {}}>
            {searchable ? (
              <div><Input suffix={<Icon name="search" className={styles.searchInputIcon} />} onChange={(e) => this.setState({ searchText: e.target.value })} /></div>
            ) : null}
            <div className={styles.options}>
              { !optionItems || !optionItems.length ? (
                <div className={styles.optionsNoLabel}>{noLabel}</div>
              ) : '' }

              {!isTree && !isLocation ? map(optionItems, (o) => o && (
                <label htmlFor={`${o.value}`} className={styles.option} key={o.value}>
                  <Checkbox
                    id={`${o.value}`}
                    type="checkbox"
                    checked={value.indexOf(o.value) !== -1}
                    onClick={(e) => this.updateValue(e, o.value)}
                    disabled={value.indexOf(o.value) === -1 && !o.badgeCount}
                  />
                  {withAvatar ? (
                    <div className={[styles.optionLabel, !o.badgeCount ? 'disabled' : '', value.indexOf(o.value) !== -1 ? 'selected' : ''].join(' ')}>
                      <Avatar size={22} src={o.avatarUser.pictureUrl} user={o.avatarUser} /> <div style={{ flex: 1, marginLeft: '8px' }}>{o.label}</div>
                    </div>
                  ) : (
                    <div className={[styles.optionLabel, !o.badgeCount ? 'disabled' : '', value.indexOf(o.value) !== -1 ? 'selected' : '', o.subLabel ? 'withSublabel' : ''].join(' ')}>{o.label}{o.subLabel ? (<small>{o.subLabel}</small>) : null}</div>
                  )}
                  {noBadge ? '' : (<Badge className={styles.optionBadge} count={o.badgeCount || 0} showZero overflowCount={999} />)}
                </label>
              )) : ''}

              {isTree && !isLocation ? (
                <Tree
                  className={styles.tree}
                  checkable
                  defaultExpandAll={defaultExpandAll}
                  defaultCheckedKeys={value}
                  onCheck={(checkedKeys, e) => this.updateTree(checkedKeys, e)}
                >
                  {getTree().map((p) => {
                    const childrenIds = p.childrens.map((c) => c.value.toString());
                    const childrenWithResult = p.childrens.filter((c) => c.badgeCount);
                    const isParentDisable = !p.badgeCount && !value.includes(p.value) && !childrenIds.find((c) => value.includes(c)) && isEmpty(childrenWithResult);

                    return (
                      <TreeNode
                        selectable={false}
                        disableCheckbox={!selectAll}
                        className={[styles.treeNode, isParentDisable ? 'disabled' : ''].join(' ')}
                        title={(<div className={classnames(styles.treeNodeTitle, defaultExpandAll ? styles.parentDisabled : '')}>
                          <h6>{p.label}</h6>
                          {noBadge || !isEmpty(p.childrens) ? '' : (<Badge className={styles.optionBadge} count={p.badgeCount || 0} showZero overflowCount={999} />)}
                        </div>)}
                        key={p.value}
                      >
                        {p.childrens.map((c) => (
                          <TreeNode
                            selectable={false}
                            checkable
                            disableCheckbox={value.length >= limit && !value.includes(c.value)}
                            // disabled={!c.badgeCount && !value.includes(c.value)}
                            className={[styles.treeNode, !c.badgeCount && !value.includes(c.value) ? 'disabled' : ''].join(' ')}
                            title={(<div className={styles.treeNodeTitle}>
                              <h6>{c.label}</h6>
                              {noBadge ? '' : (<Badge className={styles.optionBadge} count={c.badgeCount || 0} showZero overflowCount={999} />)}
                            </div>)}
                            key={c.value}
                          />
                        ))}
                      </TreeNode>
                    );
                  })}
                </Tree>
              ) : ''}
              {isLocation ? (
                <div className={styles.location}>
                  {isLocationInternational && <div className={styles.locationActions}>
                    {location.parents.map((p) => (
                      <button
                        className={location.parentId === p.value ? 'active' : ''}
                        onClick={(e) => {
                          e.preventDefault();
                          updateLocationParent(p.value);
                        }}
                        key={p.value}>{p.label}</button>))
                    }
                  </div>}
                  {location.parentId === location.franceId ? (
                    <div className={styles.locationSearch}>
                      <Icon name="search" />
                      <input
                        type="text"
                        placeholder={intl.formatMessage({ id: 'selector.placeholder.searchRegion' })}
                        onInput={(e) => this.setState({ location: { ...location, q: e.target.value.toLowerCase() } })}
                      />
                    </div>
                  ) : ''}
                  {location.options ? location.options.map((o) => o.parent !== location.franceId || (!location.q || (location.q && o.label.toLowerCase().indexOf(location.q) !== -1)) ? (
                    <label htmlFor={`${o.value}`} className={styles.option} key={o.value}>
                      <Checkbox
                        id={`${o.value}`}
                        type="checkbox"
                        checked={value.indexOf(o.value) !== -1}
                        onClick={(e) => this.updateValue(e, o.value)}
                        // disabled={!o.badgeCount && value.indexOf(o.value) === -1}
                        // disabled={value.indexOf(o.value) === -1 && !o.badgeCount}
                      />
                      <div className={[styles.optionLabel, !o.badgeCount ? 'disabled' : '', value.indexOf(o.value) !== -1 ? 'selected' : ''].join(' ')}>
                        {o.label} {!noBadge ? (
                          <Badge className={styles.optionBadge} count={o.badgeCount || 0} showZero overflowCount={999} />
                      ) : ''}
                      </div>
                    </label>
                  ) : '') : ''}
                </div>
              ) : ''}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default injectIntl(Selector);

