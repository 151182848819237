import React from 'react';
import { object } from 'prop-types';
import { Tag } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import moment from 'moment';

const DealStatusTag = ({ deal, now }) => {
  const t = useFormatMessage();

  if (deal.isActive) {
    return <Tag variant="tonal" color="success">{t({ id: 'events.inProgress' })}</Tag>;
  }

  if (!deal.isActive && moment(deal.beginAt).isAfter(now)) {
    return <Tag variant="tonal" color="primary">{t({ id: 'upcoming' })}</Tag>;
  }

  return <Tag variant="tonal" color="neutral">{t({ id: 'ended' })}</Tag>;
}

DealStatusTag.propTypes = {
  deal: object,
  now: object,
}

export { DealStatusTag }