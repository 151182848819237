import React from 'react';
import { object } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import styles from '@/scenes/Event/scenes/Owner/scenes/Dashboard/components/RealTimeActivity/components/styles.less';

export const MeetingsStats = ({
  countAppointmentsAcceptedToday
}) => {
  const t = useFormatMessage();

  return (
    <div className={styles.meetingsStats}>
      <div className={styles.globalStatsElement}>
        <div className={styles.globalStatsNumberInformal}>{countAppointmentsAcceptedToday?.informal1to1}</div>
        <p>{t({ id: 'dashboard.realtime.meetingList.appointments.informal1to1' })} <span className={styles.percent}>({countAppointmentsAcceptedToday?.percentInformal1to1}%)</span></p>
      </div>
      <div className={styles.globalStatsElement}>
        <div className={styles.globalStatsNumberInterview}>{countAppointmentsAcceptedToday?.interview}</div>
        <p>{t({ id: 'dashboard.realtime.meetingList.appointments.interview' })} <span className={styles.percent}>({countAppointmentsAcceptedToday?.percentInterview}%)</span></p>
      </div>
    </div>
  )
}

MeetingsStats.propTypes = {
  countAppointmentsAcceptedToday: object
}
