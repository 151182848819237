import React from 'react';
import { func } from 'prop-types';
import { IconX } from '@seekube-tech/ui-kit';
import styles from '@/scenes/Event/scenes/Owner/scenes/Dashboard/components/AddActivity/styles.less';

export const CloseActivityBtn = ({ onClose }) => (
    <div tabIndex={0} role="button" className={styles.exit} onClick={onClose}>
      <IconX size={34}/>
    </div>
  )

CloseActivityBtn.propTypes = {
  onClose: func,
}
