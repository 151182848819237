import React from 'react';
import { shape, func } from 'prop-types';
import { Col, Row } from 'antd/lib/index';
import 'react-phone-number-input/style.css';

import {
  LastName
} from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/Profile/Presentation/LastName';
import {
  FirstName
} from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/Profile/Presentation/FirstName';
import {
  Gender
} from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/Profile/Presentation/Gender';
import { Bloc } from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/components/Bloc';
import { H5 } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';


const Presentation = ({ form, user }) => {
  const t = useFormatMessage();

    return (
      <Bloc>
        <div className="commonStep">
          <H5 className="mb-24">{t({ id: 'event.candidate.preparation.signup.step.presentation' })}</H5>

          <div className="fieldsContainer">
            <Row gutter={16}>
              <Col xs={16}>
                <Gender form={form} user={user} />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col md={6}>
                <FirstName form={form} user={user} />
              </Col>
              <Col md={6}>
                <LastName form={form} user={user} />
              </Col>
            </Row>
          </div>
        </div>
      </Bloc>
    );
}

Presentation.propTypes = {
  form: shape({
    getFieldDecorator: func,
    getFieldsError: func,
    getFieldError: func,
    isFieldTouched: func,
  }),
};

export default Presentation;
