import React, { useEffect } from 'react';
import { array, func, object } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { isEmpty } from 'lodash';
import { toJS } from '@/utils';
import { eventSelectors, eventActions } from '@/store/event';
import { exponentSelectors, exponentActions } from '@/store/exponent';
import { sectorSelectors, sectorActions } from '@/store/sector';
import LoadingIndicator from '@/components/LoadingIndicator';
import StandForm from '@/forms/Stand/Stand';

const Edit = ({ getEvent, getExponent, exponent, getSectors, event, sectors, match: { params: { exponentID, eventSlug } } }) => {
  useEffect(() => {
    getSectors();

    getEvent(eventSlug);

    getExponent({
      exponentId: exponentID,
      eventId: eventSlug,
      postVisit: false,
    });
  }, []);

  if (!event || !exponent || isEmpty(sectors)) {
    return (<LoadingIndicator />);
  }

  return (
    <StandForm organization={exponent._organization} exponent={exponent} />
  );
};

Edit.propTypes = {
  event: object,
  match: object,
  exponent: object,
  sectors: array,
  getEvent: func,
  getExponent: func,
  getSectors: func,
};

const mapStateToProps = createStructuredSelector({
  event: eventSelectors.getCurrentEvent,
  exponent: exponentSelectors.getCurrentExponent,
  sectors: sectorSelectors.getAllSectors,
});

const mapDispatchToProps = {
  getEvent: eventActions.getEvent,
  getExponent: exponentActions.getExponent,
  getSectors: sectorActions.getSectors,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(toJS(Edit));
