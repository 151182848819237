import { intersection } from 'lodash';
import { EVENT_FORMAT, USERS_EVENT_ROLES, USERS_TYPE, WORDS_IN_SLUG_FOR_WOMEN_EVENT } from './constants';

export function isEventHybrid(event) {
  return event.format === EVENT_FORMAT.HYBRID;
}

function getIsEventForWomenOnly(slug) {
  return WORDS_IN_SLUG_FOR_WOMEN_EVENT.reduce((prev, currentWord) =>
    (prev || slug.includes(currentWord)), false)
}


export function getIsUserAuthorizedToAccessEvent(slug, gender) {
  const isWomen = gender === '1';

  // Disable registration for men if event is for women only
  if (getIsEventForWomenOnly(slug)) return isWomen;

  return true;
}

export function getCTARole(event) {
  
  if (!event) {
    return null;
  }
  
  const isRecruiterAndOwner = intersection([USERS_TYPE.owner, USERS_TYPE.recruiter], event.userContext?.roles || []).length === 2;
  const isRecruiterAndAdmin = intersection([USERS_TYPE.admin, USERS_TYPE.recruiter], event.userContext?.roles || []).length === 2;
  
  if (isRecruiterAndOwner || isRecruiterAndAdmin) {
    return USERS_TYPE.recruiter;
  }
  
  return USERS_TYPE[event.context.toLowerCase()];
}

export function getIsCandidateOrGuest(event) {
  if (!event) {
    return false;
  }
  
  return intersection([
      USERS_EVENT_ROLES.guest, 
      USERS_EVENT_ROLES.participant, 
      USERS_EVENT_ROLES.public
    ], event.userContext.roles).length > 0;
}

export function getUserRoleFromUserContext(event) {
  if (!event || !event?.userContext?.roles) {
    return
  }
  
  const roles = event.userContext.roles;
  
  if (roles.includes(USERS_EVENT_ROLES.participant)) {
    return USERS_EVENT_ROLES.participant;
  }
  if (roles.includes(USERS_EVENT_ROLES.owner)) {
    return USERS_EVENT_ROLES.owner;
  }
  if (roles.includes(USERS_EVENT_ROLES.recruiter)) {
    return USERS_EVENT_ROLES.recruiter;
  }
  if (roles.includes(USERS_EVENT_ROLES.guest)) {
    return USERS_EVENT_ROLES.guest;
  }
  
  return '';
}