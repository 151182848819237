import React from 'react';
import { object, bool } from 'prop-types';
import { IconCheck, Body1, Button, Link1 } from '@seekube-tech/ui-kit';
import classnames from 'classnames';

import { useFormatMessage } from 'react-intl-hooks';
import styles from './styles.less';

export function Todo ({ todo }) {
  const { title, cta } = getFormatMessage(todo);

  return (
    <div className={classnames(styles.todo, todo.isChecked && styles.checked)}>
      <IconTodo checked={todo.isChecked}/> <Body1> {title}</Body1>
      <div className={classnames(styles.action, todo.isChecked && styles.checked)}>
        <Button variant="outline" size="small"><Link1 href={todo.redirectUrl}>{cta}</Link1></Button>
      </div>
    </div>
  );
}

function getFormatMessage (todo) {
  const t = useFormatMessage();

  switch (todo.name) {
    case 'request-informal1to1':
      return ({
        title: t({ id: 'dashboard.todo.request-informal1to1.title' }, {
          message: todo?.properties?.moduleName }),
        cta: t({ id: 'dashboard.todo.cta.discover.module' }, {
          moduleName: todo?.properties?.moduleName
        }),
      })

    case 'request-offer':
      return ({
        title: t({ id: 'dashboard.todo.request-offer.title' }, {
          message: todo?.properties?.moduleName }),
        cta: t({ id: 'dashboard.todo.cta.discover.module' }, {
          moduleName: todo?.properties?.moduleName
        }),
      })

    case 'request-conference':
      return ({
        title: t({ id: 'dashboard.todo.request-conference.title' }, {
          message: todo?.properties?.moduleName }),
        cta: t({ id: 'dashboard.todo.cta.discover.module' }, {
          moduleName: todo?.properties?.moduleName
        }),
      })
    case 'request-stand-visited':
      return ({
        title: t({ id: 'dashboard.todo.request-stand-visited.title' }),
        cta: t({ id: 'dashboard.todo.cta.discover.module' }, {
          moduleName: todo?.properties?.moduleName
        }),
      })

    case 'request-stand-planner-visited':
      return ({
        title: t({ id: 'dashboard.todo.request-stand-planner-visited.title' }),
        cta: t({ id: 'dashboard.todo.cta.discover.module' }, {
          moduleName: todo?.properties?.moduleName
        })
      })

    case 'request-proposition-with-offer':
      return ({
        title: t({ id: 'dashboard.todo.request-proposition-with-offer.title' }, {
          shortname: todo?.properties?.recruiterShortName,
          company: todo?.properties?.organizationName,
          offer: todo?.properties?.offerTitle,
        }),
        cta: t({ id: 'dashboard.todo.cta.answer.proposition' }),
      })

    case 'request-proposition-without-offer':
      return ({
        title: t({ id: 'dashboard.todo.request-proposition-without-offer.title' }, {
          shortname: todo?.properties?.recruiterShortName,
          company: todo?.properties?.organizationName,
        }),
        cta: t({ id: 'dashboard.todo.cta.answer.proposition' }),
      })

    case `request-appointment-date`:
      return ({
        title: t({ id: 'dashboard.todo.request-appointment-date.title' }, {
          shortname: todo?.properties?.recruiterShortName,
          company: todo?.properties?.organizationName,
        }),
        cta: t({ id: 'dashboard.todo.cta.choose.slot' }),
      })

    case 'request-appointment-change-date':
      return ({
        title: t({ id: 'dashboard.todo.request-appointment-change-date.title' }, {
          shortname: todo?.properties?.recruiterShortName,
          company: todo?.properties?.organizationName,
        }),
        cta: t({ id: 'dashboard.todo.cta.choose.slot' }),
      })

    case 'request-appointment-move-owner':
      return ({
        title: t({ id: 'dashboard.todo.request-appointment-move-owner.title' }, {
          prevShortname: todo?.properties?.prevRecruiterShortName,
          company: todo?.properties?.organizationName,
          newShortname: todo?.properties?.recruiterShortName
        }),
        cta: t({ id: 'dashboard.todo.cta.choose.slot' }),
      })

    case 'request-new-offers':
      return ({
        title: t({ id: 'dashboard.todo.request-new-offers.title' }, {
          moduleName: todo?.properties?.moduleName, countNewOffers: todo?.properties?.countNewOffers }),
        cta: t({ id: 'dashboard.todo.cta.see.news.conferences' }, {
          count: todo?.properties?.countNewOffers,
          moduleName: todo?.properties?.moduleName
        }),
      })

    case 'request-new-conferences':
      return ({
        title: t({ id: 'dashboard.todo.request-new-conferences.title' }, {
          live: todo?.properties?.moduleName,
          countNewConferences: todo?.properties?.countNewConferences }),
        cta: t({ id: 'dashboard.todo.cta.see.news.conferences' }, {
          count: todo?.properties?.countNewConferences,
          moduleName: todo?.properties?.moduleName
        }),
      })
    default: return ({ title: null })
  }
}


Todo.propTypes = {
  todo:object,
}

const IconTodo = ({ checked }) => (
  <div className={classnames(styles.circle, checked && styles.checked)}><IconCheck size={12} /></div>
)

IconTodo.propTypes = {
  checked: bool,
}


