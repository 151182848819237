import { isEmpty } from 'lodash';

const hasErrors = (fieldsError = {}) => (
    Object.keys(fieldsError).some((field) => {
      if (typeof fieldsError[field] === 'object') {
        return hasErrors(fieldsError[field])
      }
      return !isEmpty(fieldsError[field]);
    })
  );

const getHasError = (form, fieldName) => (
  (form.isFieldTouched(fieldName) || form.isSubmitting()) && form.getFieldError(fieldName)
);

export {
  hasErrors,
  getHasError,
}
