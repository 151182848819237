import React, { useState } from 'react';
import { string, func, bool, object } from 'prop-types';

import { compact } from 'lodash';
import { useDebounce } from 'react-use';
import InputMaterial from '@/components/Input/InputMaterial';
import { useGetOrganizations } from '@/queries/organizations/useGetOrganizations';

import './styles.less';

const InputOrganizationsSuggest = ({
  id,
  params = {},
  onChange,
  createAlert = false,
  ...props
}) => {
  const [keywords, setKeywords] = useState(props.initialValue);
  const [debouncedKeywords, setDebouncedKeywords] = useState(props.initialValue);

  useDebounce(() => setDebouncedKeywords(keywords), 300,[keywords]);

  const organizationsQuery = useGetOrganizations({ queryKey: id, keywords: debouncedKeywords, ...params });

  const handleOnFocus = () => {
    setKeywords(keywords);
  }

  const handleOnChange = (e) => {
    const value = typeof e === 'object' ? e.currentTarget.value : e;

    setKeywords(value);

    onChange(value);
  }

  return (
    <InputMaterial
      type="autocomplete"
      onChange={handleOnChange}
      onFocus={handleOnFocus}
      dataSource={compact(organizationsQuery?.data?.docs?.map((organization) => organization.name)) || []}
      size="large"
      createAlert={createAlert}
      isFetching={organizationsQuery?.isLoading}
      {...props}
    />
  );
}

InputOrganizationsSuggest.propTypes = {
  id: string,
  addOnIcon: string,
  onChange: func,
  createAlert: bool,
  placeholder: string,
  initialValue: string,
  label: string,
  params: object,
}

export { InputOrganizationsSuggest };
