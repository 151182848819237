import { useQuery } from 'react-query';
import { objectToParams } from '@/utils/url';
import request from '@/utils/request';

export function useGetExponentsByOrganization(
  {
    organizationId,
    limit = 100,
    context,
    past = 'false',
    lean = false,
    ...props
  }) {
  const params = objectToParams({ limit, context, past, lean });
  const requestUrl = `${process.env.FRONT_API_URL}/organizations/${organizationId.toString()}/exponents?${params}`;

  const queryFn = () => request(requestUrl, { method: 'GET' });

  const query = useQuery({
    queryKey: ['exponentsByOrganization', organizationId],
    queryFn,
    initialData: { docs: [] },
    refetchOnWindowFocus: false,
    ...props,
  });

  return query;
}
