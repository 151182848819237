import React, { useEffect, useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Button } from '@seekube-tech/ui-kit';
import { useRouteMatch } from 'react-router-dom';
import { authSelectors } from '@/store/auth';
import StandCandidate
  from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Exponents/scenes/Exponent/StandCandidate';
import useScrollTop from '@/utils/hooks/scrollTop';
import { dataToDTO } from '../../utils/convertDTO';
import PublishButton from '../PublishButton';
import styles from './styles.less';

function Preview({
  onClose,
  sectorsOptions,
  values,
  pristine,
  status,
  exponent,
  valid,
  isFooterVisible = true,
  handleOnSubmit,
  modified,
  disabledFields
}) {
  const t = useFormatMessage();
  const [disabledBtn, setDisabledBtn] = useState(false)
  const match = useRouteMatch();
  useScrollTop();

  /* Derived vars */
  const profile = dataToDTO(values);

  useEffect(() => {
    if (status === 'draft' && !valid) {
      setDisabledBtn(pristine)
    }
    else {
      setDisabledBtn(!valid)
    }

  }, [])

  /* Render */

  const sectors = sectorsOptions.filter((sector) => (profile.sectors.includes(sector._id)));

  return (
    <>
      <StandCandidate
        profile={values}
        sectors={sectors}
        match={match}
        exponentId={exponent?._id}
        showSideBar={false}
      />
      {
        isFooterVisible && valid &&
        <div className={styles.previewFooter}>
        <div className={styles.previewFooterActions}>
          <Button className="mr-14" variant="tonal" onClick={onClose}>
            {t({ id: 'event.recruiter.preparation.stand.back' })}
          </Button>
            <PublishButton status={status} disabledFields={disabledBtn}/>
        </div>
      </div>
      }
      {
        isFooterVisible && !valid &&
        <div className={styles.previewFooter}>
          <div className={styles.previewFooterActions}>
            <Button className="mr-14" variant="tonal" onClick={onClose}>
              {t({ id: 'event.recruiter.preparation.stand.back' })}
            </Button>
              <Button
                onClick={() => handleOnSubmit({ isPublish: false })(values, modified)}
                type="submit"
                disabled={disabledFields}
              >
                {t({ id: 'save' })}
              </Button>
          </div>
        </div>
      }

    </>
  );
}

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser,
});

const mapDispatchToProps = {};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

Preview.propTypes = {
  onClose: func.isRequired,
  sectorsOptions: object.isRequired,
  values: object.isRequired,
  exponent: object,
  pristine: bool.isRequired,
  status: string.isRequired,
  isFooterVisible: bool,
};

Preview.defaultProps = {
  exponent: {},
};

export default compose(withConnect)(Preview);
