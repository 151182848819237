import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { Form, Select as SelectAntd } from 'antd';
import Select from '@/components/Form/Select';

const FormItem = Form.Item;
const { Option } = SelectAntd;

export const Gender = ({ form, user }) => {
  const t = useFormatMessage();
  const initialValue = user.gender || window.localStorage.getItem('signup-civility');

  return (
    <>
      <FormItem className="gender">
        {form.getFieldDecorator('gender', {
          initialValue,
          rules: [{ required: true, message: t({ id: 'form.gender.mandatory' }) }],
        })(
          <Select
            addOnIcon="gender"
            placeholder={t({ id: 'gender' })}
            label={t({ id: 'gender' })}
            withInputErrorStyle
            hasError={form.getFieldError('gender')}
            required
          >
            <Option key="1">{t({ id: 'madam' })}</Option>
            <Option key="2">{t({ id: 'sir' })}</Option>
            <Option key="3">{t({ id: 'other' })}</Option>
          </Select>
        )}
      </FormItem>
    </>
  )
}
