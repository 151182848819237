import * as React from 'react';
import * as PropTypes from 'prop-types';
import {
  ArrowRight as IconArrowRight,
} from 'react-feather';
import { Typography } from '@seekube-tech/ui';
import styles from './InputContainer.less';

function InputContainer({ fieldIsMandatory, label, subLabel, notaBene, children }) {
  return (
    <div className={styles.iconContainer}>
      <IconArrowRight className={styles.icon} size="16" />
      <div className={styles.labelContainer}>
        <div className={styles.label}>
          <Typography variant="body1" color="neutral.500">{label}</Typography>
          {fieldIsMandatory && <span className="mandatory-symbol"> * </span>}
        </div>
        {subLabel && <Typography variant="body2" color="neutral.400">{subLabel}</Typography>}
        {notaBene && <Typography variant="body2" color="neutral.400">{notaBene}</Typography>}
        <div className={styles.inputContainer}>
          {children}
        </div>
      </div>
    </div>
  )
}

InputContainer.propTypes = {
  fieldIsMandatory: PropTypes.bool,
  label: PropTypes.string.isRequired,
  subLabel: PropTypes.string,
  notaBene: PropTypes.string,
  children: PropTypes.node
}

export default InputContainer
