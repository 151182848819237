import renderHTML from 'react-render-html';
import { flow, isEmpty, join, map } from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import isEmail from '@/utils/validations/email';
import { isValidHttpUrl } from '@/utils/url';

const cropText = (text, number = 145) => text?.length > number ? `${text.slice(0, number)}...` : text;

const cssWarning = (condition) => condition ? 'textWarning' : '';

const cssError = (condition) => condition ? 'textError' : '';

const highlightText = (text, value, color = '#ffc069') => {
  if (text && value.trim().length > 0) {
    return renderHTML(text.replace(new RegExp(value, 'ig'), `<b style="background-color: ${color}">${value}</b>`));
  }

  return text;
};

const mapAndJoin = (array, property, separator = ', ', applyFct = (a) => (a)) => flow(
  (res) => map(res, (item) => item[property]),
  (res) => join(res, separator),
  applyFct
)(array);

const getNotification = (messageIntl, kind, options = { emoji: '', withHtml: false }, intl = {}) => {
  const { emoji, withHtml } = options;
  const messageFormatted = withHtml ? messageIntl : intl.formatMessage(messageIntl);
  const space = ' ';
  const message = emoji ? [emoji, messageFormatted] : [messageFormatted];
  const messageWithHtml = <>{emoji} {messageFormatted}</>;

  return {
    message: withHtml ? messageWithHtml : join(message, space),
    kind,
    style: {
      bottom: '5%',
      top: 'inherit',
    },
  };
};

// Utilisé dans les forms pour afficher le signe infini quand la valeur est > 999 ou < 0
const getInfinityFormatter = (val) => val < 0 || val > 999 ? '∞' : val;


const emailValidator = (rule, value, callback) => {
  if (!isEmail(value) && !isEmpty(value)) {
    return callback(<FormattedMessage id="form.error.email.format" />);
  }
  return callback();
};

const urlValidator = (rule, value, callback) => {
  if (!isValidHttpUrl(value) && !isEmpty(value)) {
    return callback(<FormattedMessage id="form.error.url.format" />);
  }
  return callback();
};

const stripTags = (str) => str.replace(/(<([^>]+)>)/gi, '');

const stripHtml = (html) => {
  const tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
}

export {
  cropText,
  cssWarning,
  highlightText,
  mapAndJoin,
  cssError,
  getNotification,
  getInfinityFormatter,
  emailValidator,
  urlValidator,
  stripTags,
  stripHtml
};
