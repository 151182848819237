import { useMutation } from 'react-query';
import request from '@/utils/request';

export function useDeleteExponentUser({ eventId, exponentId, onSuccess = () => {} }) {

  const mutationFn = ({ _id }) => {
    const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/exponents/${exponentId}/users/${_id}?lean=true`;

    return (
      request(requestUrl, {
        method: 'DELETE',
      })
    )
  };

  return useMutation({
    mutationFn,
    onSuccess,
  });
}
