import React, { useState } from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { Bloc, BlocHeader } from '@/components/Bloc';
import { Button, H5, Toggle } from '@seekube-tech/ui-kit';
import colors from '@seekube-tech/ui-kit/dist/colors';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentEvent } from '@/store/event/selectors';
import { patchEvent } from '@/store/event/actions';
import styles from './styles.less';

const DashboardCandidate = () => {

  const currentEvent = useSelector(getCurrentEvent)?.toJS();

  const t = useFormatMessage();

  const [isEnable, setIsEnable] = useState(currentEvent?.modules?.dashboard?.displayTodo);
  const [isLoading, setIsEnableLoading] = useState(false);

  const handleToggleEnable = (isEnable) => {
    setIsEnable(isEnable);
  };

  const dispatch = useDispatch()

  const handleOnSubmit = () => {
    setIsEnableLoading(true)
    const {modules} = currentEvent
    modules.dashboard.displayTodo = isEnable;
    const data = {
      modules,
    }


    dispatch(patchEvent({
      event: data, notificationParams: {
        success: {
          message: t({ id: 'notifications.update.success' }),
          kind: 'info',
          style: {
            bottom: '5%',
            top: 'inherit',
          },
        },
      },
      callback: setIsEnableLoading(false)
    }))
  }

  /* Render */
  return (
    <div className={styles.dashboardCandidate}>
      <Bloc>
        <BlocHeader>
          <div className={styles.leftHeader}>
            <H5 color={colors.neutral['500']}>
              {t({ id: 'todo.settings' })}
            </H5>
          </div>
          <Toggle
            onChange={handleToggleEnable}
            checked={isEnable}
          />
        </BlocHeader>
        <div className={styles.btnContainer}>
          <Button
            disabled={isEnable === currentEvent?.modules?.dashboard?.displayTodo}
            loading={isLoading}
            onClick={handleOnSubmit}
          >
            {t({ id: 'save' })}
          </Button>
        </div>
      </Bloc>
    </div>
  );
}

DashboardCandidate.propTypes = {
};

export default DashboardCandidate;
