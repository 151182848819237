import { useMutation, useQuery } from 'react-query';
import request from '@/utils/request';
import uuidv1 from 'uuid/v1';

export const useGetUserUpload = ({ userId, enabled, onSuccess }) => {

  const requestUrl = `${process.env.FRONT_API_URL}/users/${userId}/upload?fileName=${uuidv1()}.jpg`;

  const queryFn = () => request(requestUrl, { method: 'GET' });

  const query = useQuery({
    queryKey: ['upload', userId],
    queryFn,
    refetchOnWindowFocus: false,
    enabled,
    onSuccess
  });

  return query;
}