import React from 'react';
import { Dropdown, Menu } from 'antd';
import { FormattedMessage } from 'react-intl';
import { func, oneOf, number } from 'prop-types';
import { Button } from '@seekube-tech/ui-kit';
import Icon from '@/components/Icon';

import { Tooltip } from '@/components/Tooltip';

import commonMessages from '@/messages';
import messages from '../../messages';
import styles from '../../styles.less';

const { Item: MenuItem } = Menu;

const Buttons = ({ onClick, scoreSubmitted }) => {
  const getBtnVariant = (score) => score === scoreSubmitted ? 'fill' : 'tonal';

  const OverlayScoringNo = (
    <Menu>
      <MenuItem key="0" id="withMessage" onClick={() => onClick(0, true)}>
        <a role="button" tabIndex={0} >
          <Icon name="refuse" />
          <FormattedMessage {...messages.dropdownNegative} />
        </a>
      </MenuItem>
      <MenuItem key="1" onClick={() => onClick(0)}>
        <a role="button" tabIndex={0}>
          <Icon name="move" />
          <FormattedMessage {...messages.dropdownMove} />
        </a>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={styles.buttons}>
      <Tooltip title={<FormattedMessage {...messages.emailNotSendToCandidate} />} placement="bottom" color="dark">
        <Button onClick={() => onClick(2)} variant={getBtnVariant(2)} color="primary">
          <FormattedMessage {...commonMessages.yes} />
        </Button>
      </Tooltip>
      <Dropdown
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
        overlay={OverlayScoringNo}
        trigger="click"
      >
        <Button variant={getBtnVariant(0)} color="primary">
          <FormattedMessage {...commonMessages.no} />
        </Button>
      </Dropdown>
      <Tooltip title={<FormattedMessage {...messages.emailNotSendToCandidate} />} color="dark" placement="bottom">
        <Button onClick={() => onClick(1)} variant={getBtnVariant(1)} color="primary">
          <FormattedMessage {...commonMessages.maybe} />
        </Button>
      </Tooltip>
    </div>
  );
};

Buttons.propTypes = {
  onClick: func,
  context: oneOf(['bigParticipantCard', 'smallParticipantCard', null]),
  scoreSubmitted: number,
};

export { Buttons };
