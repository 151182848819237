import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { bool, object } from 'prop-types';
import { get } from 'lodash';

import { PIPE_DRIVE } from '@/utils/pipeDrive';
import { getExponentRole } from '@/utils/exponent';
import { If } from '@/components/If';
import { Alert } from '@seekube-tech/ui-kit';

import messages from '../../messages';


const AlertAppointmentModal = (props) => (
  <>
    <If condition={props.isRefuseContext && window.location.href.indexOf('jobdating/applications') === -1}>
      <Alert className="mt-20">
        <FormattedMessage id="event.recruiter.modal.cancelAppointment.alert" />
      </Alert>
    </If>
    <If condition={props.isMultiple}>
      <Alert className="mt-20">
        <FormattedMessage id="event.recruiter.appointmentModal.help.multiple" />
      </Alert>
    </If>
    <If condition={props.profile?.status === 'draft'}>
      <AlertStandNotPublished exponent={props.exponent} authUser={props.authUser} />
    </If>
  </>
);

AlertAppointmentModal.propTypes = {
  isMultiple: bool,
  isRefuseContext: bool,
  exponent: object,
  authUser: object,
  profile: object,
};

const AlertStandNotPublished = ({ exponent, authUser }) => {
  const isManager = getExponentRole(exponent, authUser) === 'manager';
  const isClient = get(exponent, '_organization.status') === PIPE_DRIVE.status.client;
  const linkTo = isClient ? '/client/stand' : isManager && `/${get(exponent, '_event.slug')}/recruiter/preparation/stand`;

  return (
    <Alert className="mt-20" color="warning">
      <span>
        <FormattedMessage {...messages.warningStandNotPublished} />&nbsp;
        <If condition={linkTo}>
          <Link to={linkTo}><FormattedMessage {...messages.warningRedirectToStand} /></Link>
        </If>
        </span>
    </Alert>
  );
};

AlertStandNotPublished.propTypes = {
  exponent: object,
  authUser: object,
};

export { AlertAppointmentModal };
