/**
 * Return formated url
 * @param {String} url
 * @returns {String}
 */
export default function formatUrl(url) {
  if (url) {
    return (url.startsWith('http://') || url.startsWith('https://')) ? url : `http://${url}`;
  }

  return url;
}
