import React from 'react';
import { isEmpty } from 'lodash';
import PropositionCard from '@/components/PropositionCard';
import InteractionCard from '@/components/InteractionCard';

const Card = ({ interactions, participant, event, handleOpenOffer, type, renderPopoverContent }) => {

  const checkIfPropositionCard = (int) => int.type === 'proposition'
    && int._appointment
    && isEmpty(int._appointment._timeslot)
    && int._appointment.status !== 'refused';

  return (
    interactions.map((interaction, i) => {
      if (checkIfPropositionCard(interaction)) {
        return (
          <PropositionCard
            key={i}
            participant={participant}
            interaction={interaction}
            organization={interaction._organization}
            user={interaction._recruiter || interaction._user}
            popoverContent={type !== 'refused' && type !== 'done' ? renderPopoverContent(interaction) : null}
            event={event}
            handleOpenOffer={(offer) => handleOpenOffer(offer)}
          />
        );
      }
      const appointmentDate = interaction?._appointment?._timeslot?.beginAt || null;
      const tempInt = interaction;

      if (tempInt._appointment && !tempInt._appointment.date) {
        tempInt._appointment.date = appointmentDate;
      }

      return (
        <InteractionCard
          key={i}
          participant={participant}
          handleOpenOffer={(offer) => handleOpenOffer(offer)}
          event={event}
          interaction={tempInt}
          context="appointment"
          popoverContent={type !== 'refused' ? renderPopoverContent(interaction) : null}
          type="default"
        />
      );
    })
  )
}

export { Card }