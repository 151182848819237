import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'event.diffusion.title',
  },
  allowAddingExponent: {
    id: 'event.diffusion.allow.adding.exponent',
  },
  yesAllValidated: {
    id: 'event.diffusion.yes.allValidated',
  },
  yesGo: {
    id: 'event.diffusion.yes.letsGo',
  },
  notYet: {
    id: 'event.diffusion.notYet',
  },
  draft: {
    id: 'event.status.draft',
  },
  published: {
    id: 'event.status.published',
  },
  visibility: {
    id: 'event.visibility.title',
  },
  private: {
    id: 'event.visibility.private.f',
  },
  public: {
    id: 'event.visibility.public',
  },
  status: {
    id: 'event.status.title',
  },
  password: {
    id: 'event.participants.filters.password',
  },
  openAccessToCandidate: {
    id: 'event.diffusion.open.access.to.candidate',
  },
  editSuccess: {
    id: 'toaster.edit.success',
  },
  yes: {
    id: 'yes',
  },
  no: {
    id: 'no',
  },
  eventOpenB2b: {
    id: 'event.open.b2b',
  },
  b2cDescription: {
    id: 'event.diffusion.publications.b2c.description',
  },
  b2cLabel: {
    id: 'event.diffusion.publications.b2c.label',
  },
  b2cTitle: {
    id: 'calendar.b2c',
  },
  b2bTitle: {
    id: 'calendar.b2b',
  },
  b2bDescription: {
    id: 'event.diffusion.publications.b2b.description',
  },
  b2bWysigLabel: {
    id: 'event.diffusion.publications.b2b.wysig.label',
  },
  b2bTargetLabel: {
    id: 'event.diffusion.publications.b2b.target.label',
  },
  b2bPublicLinkLabel: {
    id: 'event.diffusion.publications.b2b.public.link.label',
  },
  b2bPublicLinkTooltip: {
    id: 'event.diffusion.publications.b2b.public.link.tooltip',
  },
  b2bPricingContactsLabel: {
    id: 'event.diffusion.publications.b2b.pricing.label',
  },
  b2bLabel: {
    id: 'event.diffusion.publications.b2b.label',
  },
  b2bDisabledMessage: {
    id: 'event.diffusion.publications.b2b.disabled',
  },
  pricingPl: {
    id: 'pricing.pl',
  },
  'pricing_not-provided': {
    id: 'pricing.not.provided',
  },
  pricing_free: {
    id: 'pricing.free',
  },
  pricing_info: {
    id: 'pricing.info',
  },
  pricing_paying: {
    id: 'pricing.paying',
  },
  errorFieldRequired: {
    id: 'form.error.required',
  },
  videosError: {
    id: 'event.recruiter.preparation.stand.item.videos.error',
  },
  copySuccess: {
    id: 'copy.success',
  },
});
