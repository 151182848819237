import request from '@/utils/request';
import { objectToParams } from '@/utils/url';

const getStatsRequest = async ({ requestName, ...params }) => (
  request(getRequestUrl({ requestName, ...params }))
);

const getRequestUrl = ({ requestName, organization, period = {}, eventId, type, slug }) => {
  const params = objectToParams({ ...period, organization, eventId, type });

  switch (requestName) {
    case 'offers': return `${process.env.FRONT_API_URL}/statistics/offers?${params}`;
    case 'lives': return `${process.env.FRONT_API_URL}/statistics/lives?${params}`;
    case 'activities': return `${process.env.FRONT_API_URL}/statistics/exponents-users-activities?eventSlug=${slug}&${params}`;
    case 'exponentUsers': return `${process.env.FRONT_API_URL}/statistics/exponent-users?${params}`;
    case 'events': return `${process.env.FRONT_API_URL}/statistics/events?${params}`;
    case 'conversionDistribution': return `${process.env.FRONT_API_URL}/statistics/conversion-distribution?${params}`;
    case 'conversionLives': return `${process.env.FRONT_API_URL}/statistics/conversion-lives?${params}`;
    case 'conversionOffers': return `${process.env.FRONT_API_URL}/statistics/conversion-offers?${params}`;
    case 'conversion': return `${process.env.FRONT_API_URL}/statistics/conversion?${params}`;
    case 'interview': return `${process.env.FRONT_API_URL}/statistics/interview?${params}`;

    default: return '';
  }
};


const getExportUrl = ({ requestName, authToken, ...params }) =>
  window.open(`${process.env.FRONT_EXPORT_API_URL}/apps/export?${objectToParams({ url: btoa(getRequestUrl({ requestName, jwt: authToken, ...params })) })}`, '_blank');

export {
  getExportUrl,
  getStatsRequest,
  getRequestUrl,
};
