import { PHASES, PHASE_STATE } from "../utils/const";
import {
  addPasswordToLink,
  getEventPhases,
  isAdmin,
  isCandidateParticipating,
  isCandidatePreRegistered,
  isEventBeforeHunting,
  isEventClosed,
  isEventInDiscovering,
  isEventInHuntingPostEvent,
  isEventInPreregistration,
  isEventInProgress,
  isEventOpen,
  isEventParticipationDisabled,
  isJobfairPast,
  isRoleCandidate,
  isRoleOwner,
  isRoleRecruiter
} from "../utils/event";

export const ACTIONS = {
  PRE_REGISTRATION: 'preregistration'
}

function isHidden({
  event,
  role
}) {

  const eventPhases = getEventPhases(event);
  const { daysBeforeCvthequeIsClosed } = event.stats || {};
  const isCvThequeOpen = (daysBeforeCvthequeIsClosed || 0) > 0;

  if (isRoleCandidate(role) && isJobfairPast(eventPhases)) {
    return true;
  }

  if (isRoleOwner(role) || isRoleRecruiter(role)) {
    if (isEventClosed(eventPhases) && !isCvThequeOpen) {
      return true;
    }
  }

  return false;
}

function isDisabled({
  event,
  user,
  role,
  editMode = false
}) {
  const eventPhases = getEventPhases(event);
  const { daysBeforeCvthequeIsClosed } = event.stats || {};
  const isCvThequeOpen = (daysBeforeCvthequeIsClosed || 0) > 0;

  if (event.isAuthUserBanned) {
    return true;
  }

  if (isRoleOwner(role) || isRoleRecruiter(role) || isAdmin(user)) {
    if (isEventInHuntingPostEvent(eventPhases) || isEventClosed(eventPhases)) {
      if (!isCvThequeOpen) {
        return true;
      }
    }

    return false;
  }

  if (isRoleCandidate(role)) {
    if (isEventInPreregistration(eventPhases)) {
      return true;
    }

    if (isEventOpen(eventPhases) || isEventInDiscovering(eventPhases) || isEventInProgress(eventPhases) || isEventInHuntingPostEvent(eventPhases)) {
      if (isEventParticipationDisabled({ editMode, eventPhases, event, user })) {
        return true;
      }
    }

    if (isJobfairPast(eventPhases)) {
      return true;
    }
  }

  return false;
}

function getLabel({
  event,
  user,
  t,
  role,
  editMode
}) {
  const eventPhases = getEventPhases(event);
  const customCtaText = event._contents?.[0]?.[user.locale]?.cta;
  const isParticipationDisabled = isEventParticipationDisabled({ event, eventPhases, user, editMode });

  const { countOffers, countAppointments, countPropositions, daysBeforeCvthequeIsClosed, countFreeSlots } = event.stats || {};
  const isCvThequeOpen = (daysBeforeCvthequeIsClosed || 0) > 0;
  const hasOffers = (countOffers || 0) !== 0
  const hasAppointments = (countAppointments || 0) > 0;
  const hasPropositions = (countPropositions || 0) > 0;

  const isOfferModuleEnable = event.modules?.offer?.enable || false;
  const isInformal1to1ModuleEnable = event?.modules?.informal1to1?.enable || false;

  if (isRoleOwner(role)) {
    return t({ id: "event.card.cta.label[0]" })
  }

  if (isRoleCandidate(role)) {
    /**
     * Set usual participation text if auth user is banned
     */
    if (event.isAuthUserBanned) {
      return t({ id: "event.card.cta.label[7]" })
    }

    if (isParticipationDisabled) {
      return t({ id: "event.landing.action.participate.disabled" })
    }

    if (isEventInPreregistration(eventPhases)) {
      if (isCandidatePreRegistered(event)) {
        return t({ id: "event.card.cta.label[10]" })
      }

      return t({ id: "preregistration.cta" })
    }

    if (isEventInDiscovering(eventPhases) || isEventOpen(eventPhases) || isEventInProgress(eventPhases)) {
      if (isCandidateParticipating(event)) {
        return customCtaText || t({ id: "event.card.cta.label[0]" })
      }

      if (isCandidatePreRegistered(event)) {
        return t({ id: "event.card.cta.label[7]" })
      }

      // Else register
      return t({ id: "event.landing.action.registration" })
    }

    if (isEventInHuntingPostEvent(eventPhases)) {
      if (hasAppointments) {
        // Go to appointments
        return t({ id: "event.card.cta.label[3]" })
      }

      if (hasPropositions) {
        // Go to applications
        return t({ id: "event.card.cta.label[4]" })
      }
    }

    if (isEventClosed(eventPhases)) {
      return t({ id: "event.card.cta.label[9]" })
    }
  }

  if (isRoleRecruiter(role)) {
    if (isEventInPreregistration(eventPhases)) {
      if (isEventBeforeHunting(eventPhases) || (isOfferModuleEnable && !hasOffers) || (!isOfferModuleEnable && isInformal1to1ModuleEnable && countFreeSlots === 0)) {
        return t({ id: "event.card.cta.label[6]" })
      }
      return t({ id: "event.card.cta.label[0]" })
    }

    if (isEventOpen(eventPhases) || isEventInDiscovering(eventPhases) || isEventInProgress(eventPhases)) {
      if ((isOfferModuleEnable && !hasOffers) || (!isOfferModuleEnable && isInformal1to1ModuleEnable && countFreeSlots === 0)) {
        return t({ id: "event.card.cta.label[6]" })
      }
      return t({ id: "event.card.cta.label[5]" })
    }

    if (isEventBeforeHunting(eventPhases)) {
      return t({ id: "event.card.cta.label[6]" })
    }

    if (isEventInHuntingPostEvent(eventPhases) || isEventClosed(eventPhases)) {
      if (isCvThequeOpen) {
        return t({ id: "event.card.cta.label[1]" })
      }

      // cvtheque closed
      return t({ id: "event.card.cta.label[2]" })
    }
  }

  return ""
}

function getLinkTarget({
  event,
  role,
}) {
  const eventPhases = getEventPhases(event);
  if (isRoleCandidate(role)) {
    if (isEventInPreregistration(eventPhases)) {
      return "noblank"
    }
  }
  return "_blank"
}

function getLinkHref({
  event,
  user,
  role,
  editMode
}) {
  const eventPhases = getEventPhases(event);
  const isParticipationDisabled = isEventParticipationDisabled({ event, eventPhases, user, editMode });

  const isAuthUserPreregistered = editMode ? true : isCandidatePreRegistered(event);
  const isAuthUserParticipating = editMode ? false : isCandidateParticipating(event);
  const doesAuthUserParticipate = event.isAuthUserParticipate;
  const doesUserParticipateWithMatching = event.isAuthUserParticipateWithMatching;
  const isOfferModuleEnable = event.modules?.offer?.enable || false;
  const isInformal1to1ModuleEnable = event?.modules?.informal1to1?.enable || false;
  const isClientOrganization = user?._currentOrganization?.isClient;

  const { countOffers, countAppointments, countPropositions, daysBeforeCvthequeIsClosed, areInteractionsClosed, skipAppointmentValidation, countFreeSlots } = event.stats || {};

  const areInteractionsOpen = !areInteractionsClosed;
  const doesSkipAppointmentValidation = !!skipAppointmentValidation;
  const isCvThequeOpen = (daysBeforeCvthequeIsClosed || 0) > 0;
  const hasOffers = (countOffers || 0) !== 0;
  const hasAppointments = (countAppointments || 0) > 0;
  const hasPropositions = (countPropositions || 0) > 0;

  if (isRoleOwner(role)) {
    if (isEventInPreregistration(eventPhases)) {
      if (!hasOffers) {
        return `/${event.slug}/owner/settings`
      }

      return `/${event.slug}/owner/candidates`
    }

    if (isEventOpen(eventPhases) || isEventInDiscovering(eventPhases)) {
      return `/${event.slug}/owner/candidates`
    }

    if (isEventInProgress(eventPhases)) {
      return `/${event.slug}/owner/dashboard/realtime`
    }

    if (isEventInHuntingPostEvent(eventPhases) || isEventClosed(eventPhases)) {
      if (isCvThequeOpen) {
        return `/${event.slug}/owner/candidates`
      }
    }
  }

  if (isRoleCandidate(role)) {
    if (isParticipationDisabled) {
      return addPasswordToLink('/events', event, eventPhases);
    }

    if (isEventInPreregistration(eventPhases)) {
      return addPasswordToLink('/', event, eventPhases);
    }

    if (isEventOpen(eventPhases) || isEventInDiscovering(eventPhases) || isEventInProgress(eventPhases)) {
      if (isAuthUserPreregistered && !doesAuthUserParticipate) {
        return addPasswordToLink(`/${event.slug}/candidate/preparation/onboarding/profile`, event, eventPhases);
      }

      // cas ou le candidate quitte l'inscription avant d'aller au bout
      if (doesAuthUserParticipate && !isAuthUserParticipating) {
        return addPasswordToLink(`/${event.slug}/candidate/preparation/onboarding/profile-check`, event, eventPhases);
      }

      if (doesUserParticipateWithMatching || isAuthUserParticipating) {
        switch (event.participantRedirection) {
          case "dashboard":
            return addPasswordToLink(`/${event.slug}/candidate/jobdating/dashboard`, event, eventPhases);
          case "live":
            return addPasswordToLink(`/${event.slug}/candidate/jobdating/conferences/discovery`, event, eventPhases);
          case "company":
            return addPasswordToLink(`/${event.slug}/candidate/jobdating/exponents`, event, eventPhases);
          case "informal1to1":
            return addPasswordToLink(`/${event.slug}/candidate/jobdating/informal1to1`, event, eventPhases);
          case "offer":
            return addPasswordToLink(`/${event.slug}/candidate/jobdating/jobs`, event, eventPhases);
          default:
            return addPasswordToLink(`/${event.slug}/candidate/jobdating/jobs`, event, eventPhases);
        }
      }

      // incomplete participant
      return addPasswordToLink(`/${event.slug}/candidate/preparation/onboarding/profile-check`, event, eventPhases);
    }

    if (isEventInHuntingPostEvent(eventPhases) || isEventClosed(eventPhases)) {
      if (hasAppointments) {
        // Go to appointments
        return addPasswordToLink(`/${event.slug}/candidate/jobdating/appointments`, event, eventPhases);
      }

      if (hasPropositions) {
        // Go to applications
        return addPasswordToLink(`/${event.slug}/candidate/jobdating/appointments`, event, eventPhases);
      }
    }
  }

  if (isRoleRecruiter(role) && !isClientOrganization) {
    if (isEventInPreregistration(eventPhases)) {
      if (isOfferModuleEnable) {
        return `/${event.slug}/recruiter/preparation/offers`
      }
      return `/${event.slug}/recruiter/preparation/stand`
    }

    if (isEventBeforeHunting(eventPhases)) {
      if (isOfferModuleEnable) {
        return `/${event.slug}/recruiter/preparation/offers`
      }

      if (!isOfferModuleEnable && isInformal1to1ModuleEnable && countFreeSlots === 0) {
        return `/${event.slug}/recruiter/agenda`
      }

      return `/${event.slug}/recruiter/preparation/stand`
    }

    if (isEventOpen(eventPhases) || isEventInDiscovering(eventPhases)) {
      if (isOfferModuleEnable && !hasOffers) {
        return `/${event.slug}/recruiter/preparation/offers`
      }

      if (!isOfferModuleEnable && isInformal1to1ModuleEnable && countFreeSlots === 0) {
        return `/${event.slug}/recruiter/agenda`
      }

      return `/${event.slug}/recruiter/jobdating/sourcing`
    }

    if (isEventInProgress(eventPhases)) {
      // If appointments are confirmed (automatically or interactions are closed) redirect to confirmed tab
      if (!areInteractionsOpen || doesSkipAppointmentValidation) {
        return `/${event.slug}/recruiter/jobdating/interview/confirmed`
      }

      return `/${event.slug}/recruiter/jobdating/interview/unconfirmed`
    }

    if (isEventInHuntingPostEvent(eventPhases) || isEventClosed(eventPhases)) {
      if (isCvThequeOpen) {
        return `/${event.slug}/recruiter/jobdating/sourcing`
      }

      // cvtheque closed
      return `/`
    }
  }

  if (isRoleRecruiter(role) && isClientOrganization) {
    if (isEventInPreregistration(eventPhases)) {
      if (isOfferModuleEnable) {
        return `/client/offers?eventSelectedIds=${event._id}`
      }
      return `/client/stand?eventSelectedId=${event._id}`
    }

    if (isEventBeforeHunting(eventPhases)) {
      if (isOfferModuleEnable) {
        return `/client/offers?eventSelectedIds=${event._id}`
      }

      if (!isOfferModuleEnable && isInformal1to1ModuleEnable && countFreeSlots === 0) {
        return `/${event.slug}/client/offers?calendar=true`
      }

      return `/client/stand?eventSelectedId=${event._id}`
    }

    if (isEventOpen(eventPhases) || isEventInDiscovering(eventPhases)) {
      if (isOfferModuleEnable && !hasOffers) {
        return `/${event.slug}/recruiter/preparation/offers`
      }

      if (!isOfferModuleEnable && isInformal1to1ModuleEnable && countFreeSlots === 0) {
        return `/${event.slug}/recruiter/agenda`
      }

      return `/${event.slug}/recruiter/jobdating/sourcing`
    }

    if (isEventInProgress(eventPhases)) {
      // If appointments are confirmed (automatically or interactions are closed) redirect to confirmed tab
      if (!areInteractionsOpen || doesSkipAppointmentValidation) {
        return `/${event.slug}/recruiter/jobdating/interview/confirmed`
      }

      return `/${event.slug}/recruiter/jobdating/interview/unconfirmed`
    }

    if (isEventInHuntingPostEvent(eventPhases) || isEventClosed(eventPhases)) {
      if (isCvThequeOpen) {
        return `/${event.slug}/recruiter/jobdating/sourcing`
      }

      // cvtheque closed
      return `/`
    }
  }

  if (isAdmin(user)) {
    return `/${event.slug}/owner/settings`
  }

  return `/${event.slug}`;
}

function getAction({
  event,
  role
}) {
  const eventPhases = getEventPhases(event);
  // Candidate not pre-registered can pre-register
  if (isRoleCandidate(role)) {
    if (!isCandidatePreRegistered(event) && isEventInPreregistration(eventPhases)) {
      return ACTIONS.PRE_REGISTRATION;
    }
  }

  return undefined;
}

function getStyle({
  event,
  role,
  editMode
}) {
  const eventPhases = getEventPhases(event);
  const { countOffers } = event.stats || {};
  const hasOffers = (countOffers || 0) !== 0;

  const isAuthUserPreregistered = editMode ? true : isCandidatePreRegistered(event);
  const isAuthUserParticipating = editMode ? false : isCandidateParticipating(event);

  if (isRoleCandidate(role)) {
    if (isEventOpen(eventPhases) || isEventInDiscovering(eventPhases) || isEventInProgress(eventPhases)) {
      // if user has to confirm his pre-registration he will do so in a modal
      if (isAuthUserPreregistered && !isAuthUserParticipating) {
        return "warning"
      }
    }
  }

  if (isRoleOwner(role)) {
    if (isEventInPreregistration(eventPhases)) {
      if (!hasOffers) {
        return "warning"
      }
    }
  }

  return "default"
}

/**
 * Do not forget to specify strict function params (instead of destructuring) to avoid issues when calling
 */
export default {
  isAvailable: [
    [
      {
        phase: PHASES.ALL,
        state: PHASE_STATE.ALL
      },
    ],
  ],
  generateActions: ({ authUser }) => {
    const user = authUser?.toJS();

    return {
      isHidden: ({ event, role }) => isHidden({ event, role }),
      isDisabled: ({ event, role, editMode }) => isDisabled({ event, user, role, editMode }),
      getLabel: ({ event, role, t, editMode }) => getLabel({ event, user, role, t, editMode }),
      getLinkTarget,
      getLinkHref: ({ event, role, editMode }) => getLinkHref({ event, user, role, editMode }),
      getAction,
      getStyle,
    }
  }
}
