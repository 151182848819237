import * as React from 'react';
import { object, string } from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { IconCalendar } from '@seekube-tech/ui-kit';
import { PHASES } from '@/store/availableActions/utils/const';
import {
  getEarliestKeyMomentFromFormats,
  getFarthestKeyMomentFromFormats,
  getKeyMomentsFormatsFromEvent
} from '@/utils/keyMoments';
import styles from '../styles.less'

function JobdatingDate({ event, locale }) {
  moment.locale(locale)
  const formats = getKeyMomentsFormatsFromEvent(event);
  const beginAt = getEarliestKeyMomentFromFormats(event, PHASES.JOBFAIR, formats);
  const endAt = getFarthestKeyMomentFromFormats(event, PHASES.JOBFAIR, formats);

  return (
    <span key="footerDate" className={classNames(styles.dateJobDating, "hidden-mobile")}>
      <IconCalendar size={16} />
      <span className={styles.dayBold}>{beginAt.format('DD')} </span>{beginAt.format('MMMM')} -
      <span className={styles.dayBold}>{endAt.format('DD')} </span>{endAt.format('MMMM')}
    </span>
  )
};

JobdatingDate.propTypes = {
  event: object,
  locale: string
}

export default JobdatingDate;
