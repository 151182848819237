import { makeStyles } from '@seekube-tech/ui';

export default makeStyles(theme => ({
  sendBtn: {
    marginTop: theme.spacing(4.5),
  },
  divider: {
    margin: theme.spacing(6.5, 0),
  },
  table: {
    marginTop: theme.spacing(4),
  },
}));