import React from 'react';
import ParticipantsView from '@/scenes/Event/scenes/Recruiter/scenes/Jobdating/container/ParticipantsView';

/**
 * AdminCandidatesTopScene
 */
class AdminCandidatesRefuseScene extends React.PureComponent { // eslint-disable-line
  /**
   * Render
   */
  render() {
    return (
      <ParticipantsView context="adminrefuse" />
    );
  }
}

export default AdminCandidatesRefuseScene;
