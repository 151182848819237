import React from 'react';
import { object, func } from 'prop-types';
import { replace } from 'connected-react-router';
import { toJS } from '@/utils';
import { connect } from 'react-redux';
import { compose } from 'redux';

// Components
import { Switch, Route } from 'react-router-dom';

// Scenes
import ParticipantsPage from './scenes/Participants';
import styles from '../../styles.less';

/**
 * OwnerSetting
 */
class OwnerSettingsOnboardingScene extends React.PureComponent { // eslint-disable-line
  static propTypes = {
    match: object,
    replace: func,
  };

  /**
   * ComponentDidMount
   *
   * @description
   *
   */
  componentDidMount() {
    const { props: { match } } = this;

    if (match.isExact) {
      this.props.replace(`${match.url}/participants`);
    }
  }

  /**
   * Render
   */
  render() {
    const { props: { match } } = this;

    return (
      <div className={styles.formWrapper}>
        <Switch>
          <Route path={`${match.path}/participants`} component={ParticipantsPage} />
        </Switch>
      </div>
    );
  }
}

const mapDispatchToProps = {
  replace,
};

const withConnect = connect(null, mapDispatchToProps);

export default compose(
  withConnect,
)(toJS(OwnerSettingsOnboardingScene));
