import React from 'react';
import { func, object, bool } from 'prop-types';
import { Field } from 'react-final-form';
import { Autocomplete } from '@seekube-tech/ui';
import { FormSpy } from 'react-final-form';

import useValidations from '@/utils/validations/useValidations';

import Icon from '@/components/Icon';


import { createStructuredSelector } from 'reselect';
import { authSelectors } from '@/store/auth';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { LOCALES } from '@/utils/constants';
import { toJS } from '@/utils';
import styles from '../../../styles.less';
import { CRITERION_KEY } from '../../../utils/constants';
import { getCriterionLabel } from '../utils';

function DurationField({ criterion, showHelper, authUser, disabled = true, required = true }) {
  const { isFilled, isMin, isMax } = useValidations();

  /**
   * Return an error message if input is invalid
   * @param {Any} value - Value of input
   * @returns {String | null}
   */
  const handleValidate = (value, allValues) => {
    if (isDisabled(allValues.CONTRACT) === false) {
      if (criterion.modules.offer.choiceMin) {
        return (
          isFilled(value) ||
          isMin(value, criterion.modules.offer.choiceMin) ||
          isMax(value, criterion.modules.offer.choiceMax)
        );
      }
      return value && isMax(value, criterion.modules.offer.choiceMax);
    }
    return null;

  }

  // eslint-disable-next-line arrow-body-style
  const isDisabled = (contractValues = []) => {
    return (
      contractValues.length === 1 &&
      contractValues[0] === '598826added2cd00b2ad666a'
    );
  }

  const label = getCriterionLabel(criterion);
  const getOptionLabel = (option) => authUser.locale === LOCALES.en && option.label_en ? option.label_en : option.label

  return (
    <FormSpy
      subscription={{values: true}}
      render={({values}) => (
        <div className={styles.marginTop}>
          <Field
            name={CRITERION_KEY.duration}
            id={CRITERION_KEY.duration}
            label={label}
            icon={<Icon name={criterion.icon} />}
            component={Autocomplete}
            required={(isDisabled(values.CONTRACT) === false) && required}
            isMultiple
            options={criterion._choices}
            fullWidth
            getOptionLabel={getOptionLabel}
            getOptionValue={(option) => option._id}
            validate={(value, allValues) => handleValidate(value, allValues)}
            showHelper={(id) => showHelper(id, label)}
            disabled={disabled || isDisabled(values.CONTRACT)}
          />
        </div>
      )}
    />
  );
}

DurationField.propTypes = {
  criterion: object,
  showHelper: func,
  authUser: object,
  disabled: bool
};

DurationField.defaultProps = {
  criterion: {},
};

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser
})

const mapDispatchToProps = {};

const withConnect = connect(mapStateToProps, mapDispatchToProps);


export default compose(
  withConnect
)(toJS(DurationField));
