import { useRef } from 'react';
import { usePostOauthInfos } from '@/queries/login/usePostOauthInfos';
import { setFormValuesFromObject } from '@/utils/forms/final-form/helpers';
import {
  trackLinkedinImport
} from '@/scenes/Settings/components/IdentityV2/components/ImportLinkedinInformations/tracking';
import { useGetFile } from '@/queries/files/useGetFile';
import { usePutS3File } from '@/queries/files/usePutS3File';
import { useGetUserUpload } from '@/queries/user/useGetUserUpload';

/**
 * This hook is made in order to replace this behavior:
 * https://github.com/Seekube/seekube-v2-front/blob/master/app/scenes/Settings/components/General/components/Identity/index.js#L116
 */

export const useOnImportLinkedinInfos = ({
  authUser,
  form
}) => {
  const fileRef = useRef(null);
  
  // First trigger import of linkedin infos
  const linkedinImport = usePostOauthInfos({
    onSuccess: async (data) => {
      setFormValuesFromObject(form, { ...data.infos, pictureUrl: '' });
      trackLinkedinImport(data, authUser);
    },
    provider: 'linkedin'
  });
  
  // get LinkedIn picture as blob
  const file = useGetFile({
    fileUrl: linkedinImport.data?.infos.pictureUrl
  });
  
  // prepare s3 upload of file
  const putS3File = usePutS3File({
    file: file.data,
    onSuccess: () => {
      form.change('pictureUrl', fileRef.current);
    }
  });
  
  // get the url from user uploads
  const userUpload = useGetUserUpload({ 
    userId: authUser._id,
    enabled: !!file.data,
    onSuccess: (data) => {
      fileRef.current = data.url;
      putS3File.mutate({ s3FileUrl: data.signedRequest });
    }
  });
  
  return {
    importData: linkedinImport.mutate,
    status: linkedinImport.status,
    isLoading: linkedinImport.isLoading || file.isLoading || putS3File.isLoading || userUpload.isLoading,
  }
}