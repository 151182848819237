import React, { useEffect } from 'react';
import { object, func } from 'prop-types';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { replace } from 'connected-react-router';
import { createStructuredSelector } from 'reselect';
import Agenda from '@/features/agenda/recruiter/components/Agenda';
import { toJS } from '@/utils';
import { exponentSelectors } from '@/store/exponent';
import { getExponentByOrganization } from '@/store/exponent/actions';
import { authSelectors } from '@/store/auth';
import { eventSelectors } from '@/store/event';
import NavPreparation from './components/NavOnBoarding';
import RecruiterPreparationTeamPage from './scenes/Team';
import RecruiterPreparationOffersPage from './scenes/Offer';
import RecruiterPreparationStandPage from './scenes/Stand';


function RecruiterPreparationScene({
  match,
  authUser,
  event,
  exponent,
  replace,
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!exponent) {
      dispatch(getExponentByOrganization({
        lean: true,
        eventId: event._id,
        organizationId: authUser._currentOrganization?._id,
      }));
    }
  }, [])

  useEffect(() => {
    if (match.isExact && exponent) {
      replace(`${match.url}/team`);
    }
  }, [exponent])

  return (
    <div>
      <NavPreparation match={match} exponent={exponent} user={authUser} event={event} />

      <Switch>
        <Route path={`${match.path}/team`} component={RecruiterPreparationTeamPage} />
        <Route
          path={`${match.path}/offers`}
          component={RecruiterPreparationOffersPage}
        />
        <Route path={`${match.path}/recruiter/agenda`} component={Agenda} />
        <Route path={`${match.path}/stand`} component={RecruiterPreparationStandPage} />
      </Switch>
    </div>
  );
}

RecruiterPreparationScene.propTypes = {
  match: object,
  authUser: object,
  event: object,
  exponent: object,
  replace: func,
};

const mapStateToProps = createStructuredSelector({
  exponent: exponentSelectors.getCurrentExponent,
  authUser: authSelectors.getAuthUser,
  event: eventSelectors.getCurrentEvent,
});

const mapDispatchToProps = {
  replace,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(toJS(RecruiterPreparationScene));
