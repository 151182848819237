import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { exponentSchema, exponentListSchema } from './schema';
import { sectorListSchema } from '../sector/schema';

const getExponentReducer = (state) => state.get('exponent');
const getEntities = (state) => state.get('entities');
export const getFacets = (state) => state.get('exponent').get('facets');

/**
 * Return current exponent
 */
export const getCurrentExponent = createSelector(
  getExponentReducer,
  getEntities,
  (exponentState, entities) => {
    const exponentId = exponentState.get('currentId');
    const exponent = entities.getIn(['exponents', exponentId]);

    return denormalize(exponent, exponentSchema, entities);
  }
);

/**
 * Return current exponent
 */
export const getCalendarExponent = createSelector(
  getExponentReducer,
  getEntities,
  (exponentState, entities) => {
    const exponentId = exponentState.get('calendarId');
    const exponent = entities.getIn(['exponents', exponentId]);

    return denormalize(exponent, exponentSchema, entities);
  }
);

/**
 * Return exponent
 */
export const getExponents = createSelector(
  getExponentReducer,
  getEntities,
  (exponentState, entities) => {
    const allIds = exponentState.get('allIds');
    const exponentList = allIds ? allIds.map((id) => entities.getIn(['exponents', id])) : [];

    return denormalize(exponentList, exponentListSchema, entities);
  }
);

/**
 * Get exponent pagination
 */
export const getExponentPagination = createSelector(
  getExponentReducer,
  (exponentState) => exponentState.get('pagination'),
);

/**
 * Return current exponent
 */
export const getSectors = createSelector(
  getExponentReducer,
  getEntities,
  getFacets,
  (exponentState, entities, facets) => {
    const sectors = facets ? facets['organizationProfile.sectors._id'] : [];

    let sectorList = [];

    if (sectors) {
      sectorList = Object.keys(sectors).map((id) => entities.getIn(['sectors', id]));
    }

    return denormalize(sectorList, sectorListSchema, entities);
  }
);

/**
 * Get exponent stats
 */
export const getExponentsStats = createSelector(
  getExponentReducer,
  (exponentState) => exponentState.get('stats'),
);

/**
 * Get loader
 */
export const getExponentsFetching = createSelector(
  getExponentReducer,
  (exponentState) => exponentState.get('isFetching'),
);

/**
 * Get error
 */
export const getError = createSelector(
  getExponentReducer,
  (exponentState) => exponentState.get('error'),
);

/**
 * Get participant last search exponent
 */
export const getParticipantExponentsLastSearch = createSelector(
  getExponentReducer,
  (exponentState) => exponentState.get('lastParticipantExponentsSearch'),
);

/**
 * Get exponent is init
 */
export const getExponentIsInit = createSelector(
  getExponentReducer,
  (exponentState) => exponentState.get('isInit'),
);

/**
 * Get exponents partners
 */
export const getExponentsPartners = createSelector(
  getExponentReducer,
  (exponentState) => exponentState.get('partners'),
);

/**
 * Get patching loader
 */
export const getExponentsPatching = createSelector(
  getExponentReducer,
  (exponentState) => exponentState.get('isPatching'),
);