import { useMutation, useQueryClient } from 'react-query';
import request from '@/utils/request';
import { filter } from 'lodash';

export function usePostPost(
  {
    onSuccess = () => {},
    eventId,
    page,
  }) {
  const queryClient = useQueryClient();

  const mutationFn = ({ data }) => {
    const requestUrl = `${process.env.FRONT_API_URL}/posts`;

    return (
      request(requestUrl, {
        method: 'POST',
        body: JSON.stringify(data),
      })
    )
  }

  return useMutation({
    mutationFn,
    onSuccess: async (post) => {
      const posts = queryClient.getQueryData(['owner', 'dashboard', 'posts', eventId, page]);
      const newDocs = [post, ...posts.docs];
      const newPosts = { ...posts, docs: newDocs };

      queryClient.setQueryData(['owner', 'dashboard', 'posts', eventId, page], newPosts);

      onSuccess(post);
    },
  });
}
