import React, { useState } from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { Form } from 'antd';
import Textarea from '@/components/Textarea';

const FormItem = Form.Item;

export const UserDescription = ({ form, user }) => {
  const t = useFormatMessage();
  const [description, setDescription] = useState('');


  return (
    <>
      <FormItem className="textareaWrapper">
        {form.getFieldDecorator('description', {
          initialValue: user?.description || '',
          rules: [{ required: false }],
        })(
          <Textarea
            placeholder={`${t({ id: 'form.profile.description.placeholder' })}`}
            onChange={(e) => setDescription(e.target ? e.target.value : '')}
          />
        )}
      </FormItem>
    </>
  )
}