import React from 'react';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { object } from 'prop-types';
import { getEventKeyMoments } from '@/helpers/event/getEventKeyMoments';
import { CANDIDATE_JD_VIEWS, CONTEXT_URLS, EVENT_FORMAT } from '@/utils/constants';
import { getLastPathInUrl } from '@/utils/math';
import {
  HybridClosingDatesInfos,
} from '@/scenes/Event/scenes/Recruiter/scenes/Jobdating/container/SceneAlerts/components';
import { useSelector } from 'react-redux';

import { getCurrentEvent } from '@/store/event/selectors';
import { getCurrentUser } from '@/store/user/selectors';
import { InteractionsClosing }
  from '@/scenes/Event/scenes/Recruiter/scenes/Jobdating/container/SceneAlerts/components/InteractionsClosing';
import { getCurrentExponent } from '@/store/exponent/selectors';
import { isParticipatingToAllHybridFormats } from '@/helpers/participant/isParticipatingToAllHybridFormats';

const VIEWS = {
  unconfirmed: 'unconfirmed',
}

/*
 getDateToDisplay

* Here defaultDate is initialize to null rather than undefined because :
*   moment(undefined).toDate() return {now}
*   moment(null).toDate() return 'invalid Date'
*
* So moment(undefined).isBefore(moment()) return true
*
* And this we don't wanna this when we compare closing date
* By default if there is no closing date there is no restriction
*
* */
const getDateToDisplay = (firstDate, secondDate, defaultDate = null) => {
  const date1 = firstDate || defaultDate;
  const date2 = secondDate || defaultDate;
  const date1IsEmpty = isEmpty(date1);
  const date2IsEmpty = isEmpty(date2);

  if (date1IsEmpty && date2IsEmpty) return null;

  if (!date1IsEmpty && !date2IsEmpty) {
    return moment(date1).isBefore(date2) ? date1 : date2
  }

  return date1IsEmpty ? date2 : date1;
}

const getHybridDateToDisplay = (event) => {
  const now = moment();
  const jobfairPhysical =  getEventKeyMoments(event, { type: 'jobfair',  format: 'physical' }) ;
  const jobfairVirtual =  getEventKeyMoments(event, { type: 'jobfair',  format: 'virtual' });
  const interactionClosingPhysical = getEventKeyMoments(event, { type: 'interactionsClosing',  format: 'physical' }) || jobfairPhysical;
  const interactionClosingVirtual =  getEventKeyMoments(event, { type: 'interactionsClosing',  format: 'virtual' }) || jobfairVirtual;
  const applicationsClosingPhysical =  getEventKeyMoments(event, { type: 'applicationsClosing',  format: 'physical' }) || jobfairPhysical;
  const applicationsClosingVirtual =  getEventKeyMoments(event, { type: 'applicationsClosing',  format: 'virtual' }) || jobfairVirtual;

  const datePhysicalToDisplay = getDateToDisplay(interactionClosingPhysical?.endAt, jobfairPhysical?.endAt);
  const dateVirtualToDisplay = getDateToDisplay(interactionClosingVirtual?.endAt, jobfairVirtual?.endAt);

  if (isEmpty(datePhysicalToDisplay) && isEmpty(dateVirtualToDisplay)) {
    return undefined;
  }

  return {
    physical: {
      applicationsClosing: {
        isPast: moment(applicationsClosingPhysical?.endAt).isBefore(now),
        isDefined: !isEmpty(applicationsClosingPhysical?.endAt),
      },
      interactionClosing: {
        isPast: moment(interactionClosingPhysical?.endAt).isBefore(now),
        isDefined: !isEmpty(interactionClosingPhysical?.endAt),
      },
      date: moment(datePhysicalToDisplay),
      isPast: moment(datePhysicalToDisplay).isBefore(now),
    },
    virtual: {
      date: moment(dateVirtualToDisplay),
      isPast: moment(dateVirtualToDisplay).isBefore(now),
      applicationsClosing: {
        isPast: moment(applicationsClosingVirtual?.endAt).isBefore(now),
        isDefined: !isEmpty(applicationsClosingVirtual?.endAt),
      },
      interactionClosing: {
        isPast: moment(interactionClosingVirtual?.endAt).isBefore(now),
        isDefined: !isEmpty(interactionClosingVirtual?.endAt),
      },
    },
    bothPhysicalVirtual: {
      applicationsClosing: {
        isPast: moment(applicationsClosingPhysical?.endAt).isBefore(now) && moment(applicationsClosingVirtual?.endAt).isBefore(now)
      },
      interactionClosing: {
        isPast: moment(interactionClosingVirtual?.endAt).isBefore(now) && moment(interactionClosingVirtual?.endAt).isBefore(now)
      }
    }
  }
}

const DateClosingCardInfos = ({ match }) => {
  const event = useSelector(getCurrentEvent)?.toJS()
  const authUser = useSelector(getCurrentUser)?.toJS()
  const exponent = useSelector(getCurrentExponent)?.toJS()
  const { format, phases, keyDates} = event

  const keydatesUrl = `${window.location.origin}/${match.params.eventSlug}/recruiter/keydates`;
  const dateInteractionAvailable = moment(keyDates?.interactionsClosing?.beginAt || null)

  const hybridDateToDisplay = getHybridDateToDisplay(event);
  const currentView = getLastPathInUrl(window.location.pathname);

  if ([EVENT_FORMAT.HYBRID].includes(format) && hybridDateToDisplay) {
    const isExponentParticipatingToAllHybridFormats = isParticipatingToAllHybridFormats(exponent.keyMomentFormats);

    const atLeastOneClosingDateToDisplayIsUpcoming =  !hybridDateToDisplay.physical.isPast || !hybridDateToDisplay.virtual.isPast

    if (isExponentParticipatingToAllHybridFormats) {
      if (atLeastOneClosingDateToDisplayIsUpcoming && event.skipAppointmentValidation && [VIEWS.unconfirmed].includes(currentView)) {
        return <HybridClosingDatesInfos hybridDateToDisplay={hybridDateToDisplay} authUser={authUser} href={keydatesUrl} /> // bandeau 1
      }
    }

    if (!isExponentParticipatingToAllHybridFormats) {
      const date = hybridDateToDisplay[exponent.keyMomentFormats[0]];

      if (!date.isPast && phases.canRecruiterProposeOrEditAppointment  && event.skipAppointmentValidation && [VIEWS.unconfirmed].includes(currentView)) {
        return <InteractionsClosing type={event.type} href={keydatesUrl} authUser={authUser} dateInteractionAvailable={hybridDateToDisplay[exponent.keyMomentFormats[0]].date} /> // bandeau 1
      }
    }
  }

  if (phases.canRecruiterProposeOrEditAppointment && [VIEWS.unconfirmed].includes(currentView)) {
    return <InteractionsClosing type={event.type} href={keydatesUrl} authUser={authUser} dateInteractionAvailable={dateInteractionAvailable} />
  }

  return null;
}

DateClosingCardInfos.propTypes = {
  match: object,
}

export { DateClosingCardInfos }
