import React from 'react';
import { func, object, string, bool } from 'prop-types';
import { invokeMap } from 'lodash';
import { Form, Select as SelectAnt } from 'antd';
import { injectIntl } from 'react-intl';
import { useFormatMessage } from 'react-intl-hooks';
import { Select } from '@/components/Form';
import { getLocale } from '@/utils/localStorage';
import messages from '../../messages';
const FormItem = Form.Item;
const {Option} = SelectAnt;

const Experience = ({
  matchingData,
  onChange,
  showHelper,
  getFieldValue,
  getFieldError,
  criterion,
  getFieldDecorator,
  fieldDecoratorKey = 'matching-experiences',
  context,
  withInputErrorStyle
}) => {
  const t = useFormatMessage();

  const levels = criterion._choices.filter(({ enable }) => enable).map((choice) => ({
    note: choice._id,
    label: choice[`label_${getLocale()}`] || choice.label
  }));

  const levelOptions = levels.map((item) => (
    <Option key={`level${item.note}`} value={item.note.toString()}>{item.label}</Option>
  ));

  const isRequired = criterion.modules[context].choiceMin > 0;
  const isSingleSelect = criterion.modules[context].choiceMax < 2;
  let initialValue = '';

  if (matchingData && matchingData.experiences && matchingData.experiences !== -1) {
    initialValue = isSingleSelect ? matchingData.experiences.toString() : invokeMap(matchingData.experiences, 'toString');
  } else {
    initialValue = isSingleSelect ? '' : [];
  }

  return (
    <div id="matchingExperienceFormItem" style={{ display: 'inline' }}>
      <FormItem>
        {getFieldDecorator(fieldDecoratorKey, {
          initialValue,
          rules: [{ required: isRequired, message: t({ id: 'form.mandatory' }) }],
        })(
          <Select
            mode={isSingleSelect ? 'default' : 'multiple'}
            placeholder={criterion.modules[context].label}
            required={isRequired}
            showHelper={showHelper}
            getPopupContainer={() => document.getElementById('matchingExperienceFormItem')}
            onChange={(values) => onChange ? onChange(fieldDecoratorKey, values) : ''}
            addOnIcon={criterion.icon}
            showDropdown={getFieldValue(fieldDecoratorKey).length < criterion.modules[context].choiceMax || isSingleSelect}
            hasError={getFieldError(fieldDecoratorKey)}
            withInputErrorStyle={withInputErrorStyle}
          >
            {levelOptions}
          </Select>
        )}
      </FormItem>
    </div>
  );
};

export default injectIntl(Experience);

Experience.propTypes = {
  getFieldDecorator: func,
  fieldDecoratorKey: string,
  context: string,
  onChange: func,
  matchingData: object,
  showHelper: func,
  criterion: object,
  intl: object,
  getFieldValue: func,
  getFieldError: func,
  withInputErrorStyle: bool
};
