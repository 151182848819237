import React from 'react';
import {
  FooterNav
} from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/components/FooterNav/FooterNav';
import { Body2, Button, IconCheckCircle, IconChevronLeft, IconChevronRight } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { useHistory } from 'react-router-dom';
import colors from '@seekube-tech/ui-kit/dist/colors';
import { isHybrid } from '@/helpers/event/isHybrid';
import { getIsUserAuthorizedToAccessEvent } from '@/utils/event';

export const FooterActions = ({ event, authUser, isPreview }) => {
  const t = useFormatMessage();
  const history = useHistory();
  const canUserSubmit = getIsUserAuthorizedToAccessEvent(event.slug, authUser.gender);

  return (
    <FooterNav>
      <Button
        onClick={() => history.push('profile')}
        imageComponentLeft={<IconChevronLeft size={16}  />}
        color="neutral"
        variant="outline"
        disabled={isPreview}
      >
        {t({ id: 'previous.step' })}
      </Button>
      {
        !isHybrid(event) && <>
          <Body2 color={colors.neutral['500']}>
            {t({ id: 'candidate.signup.footer.last.step' })}
          </Body2>

          <Button
            disabled={!canUserSubmit || isPreview}
            type="submit" imageComponentLeft={<IconCheckCircle size={16} />}>
            {t({ id: 'submit' })}
          </Button>
        </>
      }
      {
        isHybrid(event) && <>
          <Body2 color={colors.neutral['500']}>
            {t({ id: 'candidate.signup.footer.one.more.step' })}
          </Body2>

          <Button type="submit" imageComponentRight={<IconChevronRight size={16} />}>{t({ id: 'next' })}</Button>
        </>
      }
    </FooterNav>
  )
}
