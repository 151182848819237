import React from 'react';
import { CardInfo, IlluTimer } from '@seekube-tech/ui-kit';
import { Link } from 'react-router-dom';
import { useFormatMessage } from 'react-intl-hooks';
import { isEmpty } from 'lodash';
import { bool, func, object } from 'prop-types';
import BlankState from '@/components/BlankState';
import styles from '@/scenes/Event/scenes/Recruiter/scenes/Jobdating/container/ParticipantsView/styles.less';

const NoJobdating = ({ authUser, isArchiveView, goTo }) => {
  const t = useFormatMessage();
  const isCandidate = isEmpty(authUser?._currentOrganization);
  const DATA = {
    title: t({ id: isArchiveView ? 'event.list.blankState.title.archived' : 'event.list.blankState.title' }),
    content: t({ id: isArchiveView ? 'event.list.blankState.content.archived' : 'event.list.blankState.content' }),
    goto: isArchiveView ? 'events' : '/events/archive',
  };
  

  if (isCandidate && !isArchiveView) {
    return (
      <CardInfo
        action={<Link to="/events/archive" className="w-full text-primary-500">{t({ id: ' candidate.events.no.results.action' })}</Link>}
        icon={<IlluTimer size="100%" />}
        isClosable={false}
        title={t({ id: 'candidate.events.no.results.title' })}
      />
    );
  }
  
  return (
    <BlankState
      className={styles.blankState}
      title={DATA.title}
      content={<a role="button" tabIndex={0} onClick={() => goTo(DATA.goto)}>{DATA.content}</a>}
      icon="blank-state-cactus"
    />
  );
}

NoJobdating.propTypes = {
  authUser: object,
  isArchiveView: bool,
  goTo: func
}

export { NoJobdating };