import React, { useEffect } from 'react';
import { func, object } from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { parse } from 'query-string';
import { createStructuredSelector } from 'reselect';
import { authActions } from '@/store/auth';
import { withAuth } from '@/containers/RequiredAuth';

function Switch({ switchAuth, location }) {
  const { email } = parse(location.search);

  useEffect(() => {
    switchAuth({ email });
  }, [email])

  return (null);
}

Switch.propTypes = {
  switchAuth: func,
  location: object,
}

const mapDispatchToProps = createStructuredSelector({
  switchAuth: authActions.switchAuth,
});

const withConnect = connect(null, mapDispatchToProps);
const SwitchPage = compose(withConnect, withAuth({ isAdmin: true }), (Switch));

export { SwitchPage }