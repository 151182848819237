import React, { useState } from 'react';
import { object } from 'prop-types';
import { Button } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import {
  ModalBenefitslist
} from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Exponents/scenes/Exponent/components/Home/BenefitsJoin/ModalBenifitsList';

export const OpeningBtnModal = ({ profile }) => {
  const t = useFormatMessage();
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <>
      <Button
        color="primary"
        variant="outline"
        onClick={() => setIsModalVisible(true)}
      >
        {t({ id: 'see.all.benefits' })}
      </Button>

      <ModalBenefitslist
        profile={profile}
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
      />
    </>
  )
}

OpeningBtnModal.propTypes = {
  profile: object,
}