import { useQuery } from 'react-query';
import request from '@/utils/request';

export function useGetOrganizationProfiles({ orgaId, ...props  }) {
  const requestUrl = `${process.env.FRONT_API_URL}/organizations/${orgaId}/profiles`;
  const queryFn = () => request(requestUrl, { method: 'GET' });

  const query = useQuery({
    queryKey: ['organizationProfiles', orgaId],
    queryFn,
    refetchOnWindowFocus: false,
    enabled: !!orgaId,
    ...props,
  });
  return query;
}
