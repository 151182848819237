import React from 'react';
import { any, func, number, bool } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { Select } from '@seekube-tech/ui-kit';
import { EVENT_FORMAT } from '@/utils/constants';

const ParticipationMode = ({
  onParticipationModeChange,
  value,
  style,
  disabled,
}) => {
  const t = useFormatMessage();
  const PARTICIPATIONS_MODE = [t({ id: `calendar.settingsSlot.participationMode.virtual`}), t({ id: `calendar.settingsSlot.participationMode.physical`})];

  const onSelectChange = (value) => {
    if(PARTICIPATIONS_MODE[0] === value) {
      onParticipationModeChange(EVENT_FORMAT.VIRTUAL);
    }
    else {
      onParticipationModeChange(EVENT_FORMAT.PHYSICAL);
    }
  };

  return (
    <div id="duration" style={style}>
      <Select
        value={PARTICIPATIONS_MODE.includes(value) ? value : t({ id: `calendar.settingsSlot.participationMode.${value}`})}
        onChange={onSelectChange}
        disabled={disabled}
        label={t({ id: 'event.recruiter.preparation.timeslots.slot.participationMode' })}
        options={PARTICIPATIONS_MODE.map((mode) => ({
          value: mode,
          label: mode,
        }))}
      />
    </div>
  );
}

ParticipationMode.propTypes = {
  onParticipationModeChange: func,
  value: number,
  disabled: bool,
  style: any,
}

export default ParticipationMode;
