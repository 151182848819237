import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'event.recruiter.appointmentModal.title',
  },
  titleMultiple: {
    id: 'event.recruiter.appointmentModal.titleMultiple',
  },
  otherCandidates: {
    id: 'event.recruiter.appointmentModal.title.otherCandidates',
  },
  help: {
    id: 'event.recruiter.appointmentModal.help',
  },
  helpMultiple: {
    id: 'event.recruiter.appointmentModal.help.multiple',
  },
  warningStandNotPublished: {
    id: 'event.recruiter.appointmentModal.warning.stand.notPublished',
  },
  warningRedirectToStand: {
    id: 'event.recruiter.appointmentModal.warning.stand.redirect',
  },
  noSlotError: {
    id: 'event.recruiter.appointmentModal.noSlotError',
  },
  addSlot: {
    id: 'event.recruiter.appointmentModal.addSlot',
  },
  offer: {
    id: 'event.recruiter.appointmentModal.offer',
  },
  offerPlaceholder: {
    id: 'event.recruiter.appointmentModal.offerPlaceholder',
  },
  offerPlaceholderDefault: {
    id: 'event.recruiter.appointmentModal.offer.placeholder.default',
  },
  selectOffer: {
    id: 'event.recruiter.appointmentModal.selectOffer',
  },
  selectOfferPlaceholder: {
    id: 'offer.form.title.label',
  },
  selectOfferLabel: {
    id: 'event.candidate.appointment.mainContent.offer',
  },
  optional: {
    id: 'event.recruiter.preparation.offer.form.optional.part.optional',
  },
  propose: {
    id: 'recruiter.participantsList.appointment.propose',
  },
  from: {
    id: 'event.recruiter.appointmentModal.from',
  },
  recruiterInCharge: {
    id: 'event.recruiter.appointmentModal.inCharge',
  },
  templatePlaceholder: {
    id: 'input.template.placeholder',
  },
  applicationTitle: {
    id: 'event.recruiter.appointmentModal.application.title',
  },
  applicationSubmit: {
    id: 'event.recruiter.appointmentModal.application.submit',
  },
  applicationRefuseTitle: {
    id: 'event.recruiter.appointmentModal.application.refuse.title',
  },
  applicationRefuseSubmit: {
    id: 'event.recruiter.appointmentModal.application.refuse.submit',
  },
  editTemplate: {
    id: 'event.recruiter.appointmentModal.editTemplate',
  },
  cancelAppointmentAlert: {
    id: 'event.recruiter.modal.cancelAppointment.alert',
  },
  appointmentGroupedPropose: {
    id: 'appointment.grouped.propose',
  },
  appointmentGroupedConfirm: {
    id: 'appointment.grouped.confirm',
  },
  appointmentGroupedRefuse: {
    id: 'appointment.grouped.refuse',
  },
  myOffers: {
    id: 'offers.mine',
  },
  teamOffers: {
    id: 'offers.team',
  },
});
