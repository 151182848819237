import React from 'react';
import PropTypes from 'prop-types';

/**
 * CircularProgressbar
 */
class CircularProgressbar extends React.PureComponent {
  static MIN_PERCENTAGE = 0;

  static MAX_PERCENTAGE = 100;

  static propTypes = {
    percentage: PropTypes.number.isRequired,
    className: PropTypes.string,
    classes: PropTypes.objectOf(PropTypes.string),
    strokeWidth: PropTypes.number,
    background: PropTypes.bool,
    backgroundPadding: PropTypes.number,
    initialAnimation: PropTypes.bool,
    classForPercentage: PropTypes.func,
    textForPercentage: PropTypes.func,
    strokeColorBegin: PropTypes.string,
    strokeColorEnd: PropTypes.string,
  };

  static defaultProps = {
    strokeWidth: 8,
    className: '',
    classes: {
      root: 'CircularProgressbar',
      trail: 'CircularProgressbar-trail',
      path: 'CircularProgressbar-path',
      text: 'CircularProgressbar-text',
      background: 'CircularProgressbar-background',
    },
    strokeColorBegin: '#45B9EA',
    strokeColorEnd: '#7CF1D7',
    background: false,
    backgroundPadding: null,
    initialAnimation: false,
    textForPercentage: (percentage) => `${percentage}%`,
  };

  /**
   * State
   */
  state = {
    percentage: this.props.initialAnimation ? 0 : this.props.percentage,
  };

  /**
   * Start animation timeout
   */
  componentDidMount() {
    if (this.props.initialAnimation) {
      this.initialTimeout = setTimeout(() => {
        this.requestAnimationFrame = window.requestAnimationFrame(() => {
          this.setState({
            percentage: this.props.percentage,
          });
        });
      }, 0);
    }
  }

  /**
   * Set percentage
   */
  componentWillReceiveProps(nextProps) {
    this.setState({
      percentage: nextProps.percentage,
    });
  }

  /**
   * Clear animation timeout
   */
  componentWillUnmount() {
    clearTimeout(this.initialTimeout);
    window.cancelAnimationFrame(this.requestAnimationFrame);
  }

  /**
   * Get background padding
   */
  getBackgroundPadding() {
    if (this.props.background) {
      // default padding to be the same as strokeWidth
      // compare to null because 0 is falsy
      if (this.props.backgroundPadding === null) {
        return this.props.strokeWidth;
      }
      return this.props.backgroundPadding;
    }
    // don't add padding if not displaying background
    return 0;
  }

  /**
   * Get path description
   */
  getPathDescription() {
    const radius = this.getPathRadius();
    return `
      M 50,50 m 0,-${radius}
      a ${radius},${radius} 0 1 1 0,${2 * radius}
      a ${radius},${radius} 0 1 1 0,-${2 * radius}
    `;
  }

  /**
   * Get progress style
   */
  getProgressStyle() {
    const diameter = Math.PI * 2 * this.getPathRadius();
    const truncatedPercentage = Math.min(Math.max(this.state.percentage, CircularProgressbar.MIN_PERCENTAGE), CircularProgressbar.MAX_PERCENTAGE);

    return {
      strokeDasharray: `${diameter}px ${diameter}px`,
      strokeDashoffset: `${(((100 - truncatedPercentage) / 100) * diameter)}px`,
    };
  }

  /**
   * Get path radius
   */
  getPathRadius() {
    // the radius of the path is defined to be in the middle, so in order for the path to
    // fit perfectly inside the 100x100 viewBox, need to subtract half the strokeWidth
    return 50 - (this.props.strokeWidth / 2) - this.getBackgroundPadding();
  }

  /**
   * Generate a random id
   */
  generateId() {
    const length = 6;
    const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

    return [...Array(length)].reduce((a) => a + chars[(Math.random() * chars.length)], '');
  }

  /**
   * Render
   */
  render() {
    const {
      textForPercentage,
      percentage,
      className,
      classes,
      strokeWidth,
      strokeColorBegin,
      strokeColorEnd,
    } = this.props;

    const classForPercentage = this.props.classForPercentage ? this.props.classForPercentage(percentage) : '';
    const pathDescription = this.getPathDescription();
    const text = textForPercentage ? textForPercentage(percentage) : null;

    const gradientId = `gradient${this.generateId()}`;

    return (
      <svg
        className={`${classes.root} ${className} ${classForPercentage}`}
        viewBox="0 0 100 100"
      >
        {
          this.props.background ? (
            <circle
              className={classes.background}
              cx={50}
              cy={50}
              r={50}
            />
          ) : null
        }

        <path
          className={classes.trail}
          d={pathDescription}
          strokeWidth={strokeWidth}
          fillOpacity={0}
        />

        <defs>
          <linearGradient id={gradientId} y1="0" y2="1">
            <stop stopColor={strokeColorBegin} offset="0" />
            <stop stopColor={strokeColorEnd} offset="1" />
          </linearGradient>
        </defs>

        <path
          className={classes.path}
          d={pathDescription}
          strokeWidth={strokeWidth}
          fillOpacity={0}
          style={this.getProgressStyle()}
          stroke={`url(#${gradientId})`}
        />

        {
          text ? (
            <text
              className={classes.text}
              x={50}
              y={50}
            >
              {text}
            </text>
          ) : null
        }
      </svg>
    );
  }
}

export default CircularProgressbar;
