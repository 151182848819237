import { get, isEmpty } from 'lodash';
import { object, func, any, array, string } from 'prop-types';
import moment from 'moment';
import React from 'react';
import AppointmentUnconfirmedHelp
  from '@/scenes/Event/scenes/Candidate/scenes/JobDating/components/AppointmentUnconfirmedHelp';
import {
  Card
} from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Appointments/components/AppointmentsList/Card';
import Separator from '@/components/Separator';
import StatusSeparator
  from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Appointments/components/AppointmentsList/StatusSeparator';
import { EVENT_TYPE } from '@/utils/constants';

const CardsBySeparator = ({  type, event, separator, interactions, participant, renderPopoverContent, handleOpenOffer }) => {
  let sortInts = interactions;

  if (isEmpty(participant) || isEmpty(interactions)) {
    return '';
  }


  const displayAlert = event.skipAppointmentValidation && type === 'accepted' && interactions.findIndex((i) => i && i._appointment && i._appointment.status === 'unconfirmed') > -1 && event.type !== EVENT_TYPE.jpo;

  if (type === 'accepted') {
    sortInts = interactions.sort((a, b) => moment(get(a, '_appointment.date')).diff(moment(get(b, '_appointment.date'))));
  }

  return (
    <>
      <StatusSeparator
        label={`${separator.label} (${interactions.length})`}
        icon={separator.icon}
        type={separator.separator}
      />
      {displayAlert &&
        <AppointmentUnconfirmedHelp
          date={event.keyDates.hunt?.beginAt || event.keyDates.jobfair.endAt}
        />
      }
      <Card
        interactions={sortInts}
        participant={participant}
        handleOpenOffer={handleOpenOffer}
        type={separator.separator}
        event={event}
        renderPopoverContent={renderPopoverContent}
      />

      <Separator height={55} />
    </>
  );
};

CardsBySeparator.propTypes = {
  type: string,
  event: object,
  separator: object,
  interactions: array,
  participant: object,
  renderPopoverContent: any,
  handleOpenOffer: func
}

export { CardsBySeparator }
