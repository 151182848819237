import React from 'react';
import {
  Body1,
  IconInfo,
  Popover,
  PopoverContent,
  PopoverTrigger, Subtitle2
} from '@seekube-tech/ui-kit';

// Styles & Translations
import styles from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Exponents/scenes/Exponent/components/Home/Sidebar/styles.less';
import { string, object } from 'prop-types';

const SidebarSectionTitle = ({
  title,
  popover
}) => <div>
    <Popover placement="top">
      <div className={styles.popoverTrigger}>
        <Subtitle2 fontWeight={700} className={styles.title}>
          {title}
        </Subtitle2>
        <PopoverTrigger>
          <div>
            <IconInfo
              className={styles.info}
              size="16"
            />
          </div>
        </PopoverTrigger>
      </div>
      <PopoverContent zIndex={2}>
        <div className="text-neutral-500">
          <div className='flex'>
            <div className="text-neutral-500 mx-20 my-20">
              <Subtitle2 fontWeight={700}>{popover.title}</Subtitle2>
              <Body1 className="text-neutral-400 mt-4 mb-12">{popover.description}</Body1>
            </div>
            {popover.img ? <img src={popover.img} alt="interview"/> : <></>}
          </div>
        </div>
      </PopoverContent>
    </Popover>
  </div>

SidebarSectionTitle.propTypes = {
  title: string,
  popover: object
}

export default SidebarSectionTitle;
