import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { If, Then } from 'react-if';
import { BlockPicker } from 'react-color';
import styles from './styles.less';

/**
 * InputColorPicker
 *
 * @usage
 * <InputColorPicker color="#FFF" onSelected={(color, event)} />
 */
class InputColorPicker extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    color: PropTypes.string,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    color: '#FFF',
    style: {},
    className: '',
  };

  /**
   * Constructor
   *
   * @param {object} props
   */
  constructor(props) {
    super(props);

    this.state = {
      displayColorPicker: false,
      color: 'red',
    };
  }

  /**
   * Toggle the color picker
   */
  handleClick = () => {
    const { state: { displayColorPicker } } = this;

    this.setState({ displayColorPicker: !displayColorPicker });
  };

  /**
   * Handle change
   *
   * @param {object} color
   */
  handleChange = ({ hex }) => {
    const { props: { onChange } } = this;

    onChange(hex);
  };

  /**
   * Render
   */
  render() {
    const {
      state: { displayColorPicker },
      props: { color, style, className },
      handleClick,
      handleChange,
    } = this;

    return (
      <div className={classnames(className)} style={style}>
        <a role="button" tabIndex={0} className={styles.color} style={{ backgroundColor: color }} onClick={handleClick} />
        <If condition={displayColorPicker}>
          <Then>
            <div className={styles.popover}>
              <a role="button" tabIndex={0} className={styles.cover} onClick={handleClick} />
              <BlockPicker
                colors={['#1E2D45', '#385077', '#38D996', '#FF818C', '#36B3EF', '#F77603', '#FF3F4F', '#F7C003', '#71CFDE']}
                disableAlpha
                color={color}
                onChange={handleChange}
              />
            </div>
          </Then>
        </If>
      </div>
    );
  }
}

export default InputColorPicker;
