import React from 'react';
import { array } from 'prop-types';
import Link from './Link';
import styles from './styles.less';

const Links = ({ links }) => (
    <div className={styles.linksContainer}>
      {
        links.map((link, index) => (
          <div className={styles.linkWrapper}>
            <Link
              key={index}
              link={link}
            />
          </div>
        ))
      }
    </div>
  )

Links.propTypes = {
  links: array,
};

export default Links;
