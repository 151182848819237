import { isArray, isEmpty } from 'lodash';
import classnames from 'classnames';
import styles from '@/scenes/Event/components/StaticOffers/components/Filters/styles.less';
import Selector from '@/components/Form/Selector';
import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { Form } from 'antd';

const FormItem = Form.Item;

const CONFERENCE_SIZE = [...Array(4).keys()];

export const ConferenceSizeSelector = ({
  isMobile,
  facetKey,
  updateSearch,
  search,
  facetsConferences,
  context,
}) => {
  const t = useFormatMessage();
  let items = [];
  const options = CONFERENCE_SIZE.map((o) => ({
    value: o,
    label: t({ id: `conference.size[${o}]` }),
    badgeCount: facetsConferences?.size?.[o] || 0
  }));

  if (!isEmpty(search) && !isEmpty(search.size)) {
    if (isArray(search.size)) {
      items = search.size;
    } else {
      items = search.size.split(',');
    }
  }

  return (
    <FormItem className={classnames(styles.formItem, context === 'exponent' ? styles.formItemBordered : '')} style={{ minWidth: '140px', width: '140px' }}>
      <Selector
        position="left"
        isMobile={!!isMobile}
        label={t({ id: 'size' })}
        noLabel={t({ id: 'searchEngine.noLabel' })}
        options={options}
        values={items}
        onChange={(e) => {
          updateSearch('size', e, facetKey, context);
        }}
      />
    </FormItem>
  );
}
