import React from 'react';
import { any } from 'prop-types';
import LinesEllipsis from 'react-lines-ellipsis';
import { stripHtml } from '@/utils/text';
import styles from '../styles.less';

const Description = ({ description }) => (
  <p className={styles.description}>
    <LinesEllipsis
      maxLine="3"
      ellipsis="..."
      trimRight
      basedOn="letters"
      text={stripHtml(description)}
    />
  </p>
);

Description.propTypes = {
  description: any,
};

export { Description };
