import formatUrl from './formatUrl';
import { STATUS } from './constants';

export function dataToDTO(values, isPublish) {
  return {
    ...values,
    website: formatUrl(values.website),
    website_career: formatUrl(values.websiteCareer) || null,
    status: isPublish ? STATUS.published : values.status,
  };
}
