import { isEmpty } from 'lodash';
import { normalize } from 'normalizr';
import { eventListSchema } from '@/store/event/schema';
import mergeEventsEntities from '@/scenes/Events/container/EventsList/utils/mergeEventEntities';

// FIXME: This logic should be done in backend if possible see https://app.asana.com/0/0/1206018744596637/f
const computeEventsData = (events) => {
  const { docs, computedEventsData } = events;
  let computedDocs;

  if (!isEmpty(docs) && !isEmpty(computedEventsData)) {
    computedDocs = (docs || []).map((doc, index) => ({ ...doc, ...computedEventsData[index] }));
  }

  const dataNormalized = normalize(computedDocs || docs, eventListSchema);

  return mergeEventsEntities(dataNormalized.entities);
}

export default computeEventsData;
