import { denormalize } from 'normalizr';
import { createSelector } from 'reselect';
import { organizationListSchema, organizationSchema } from './schema';

const getOrganizationReducer = (state) => state.get('organization');
const getEntities = (state) => state.get('entities');


export const getCurrentOrganization = createSelector(
  getOrganizationReducer,
  getEntities,
  (organizationState, entities) => {
    const organizationId = organizationState.get('currentId');
    const organization = entities.getIn(['organizations', organizationId]);
    return denormalize(organization, organizationSchema, entities);
  }
);


export const getSpecificOrganization = createSelector(
  getOrganizationReducer,
  getEntities,
  (organizationState, entities) => {
    const organizationId = organizationState.get('specificOrganization');
    const organization = entities.getIn(['organizations', organizationId]);
    return denormalize(organization, organizationSchema, entities);
  }
);


export const getAllOrganizationsList = createSelector(
  getOrganizationReducer,
  getEntities,
  (organizationState, entities) => {
    const allIds = organizationState.get('allIds');
    const organizationsList = allIds.map((id) => entities.getIn(['organizations', id]));

    return denormalize(organizationsList, organizationListSchema, entities);
  }
);


export const getOrganizationFetching = createSelector(
  getOrganizationReducer,
  getEntities,
  (organizationState) => organizationState.get('isFetching')
);


export const getOrganizationPagination = createSelector(
  getOrganizationReducer,
  (organizationState) => organizationState.get('pagination'),
);


export const getMergeList = createSelector(
  getOrganizationReducer,
  getEntities,
  (organizationState) => organizationState.get('mergeList')
);


export const getMergeListTotal = createSelector(
  getOrganizationReducer,
  getEntities,
  (organizationState) => organizationState.get('mergeListTotal')
);


export const getSuggestions = createSelector(
  getOrganizationReducer,
  getEntities,
  (organizationState) => organizationState.get('suggestions')
);


export const alreadyExistError = createSelector(
  getOrganizationReducer,
  getEntities,
  (organizationState) => organizationState.get('alreadyExistError')
);

