import React from 'react';
import { string, func } from 'prop-types';
import { MenuProfile } from '@/scenes/Settings/RecruiterSettings/Menu/MenuProfile';
import { MenuSettings } from '@/scenes/Settings/RecruiterSettings/Menu/MenuSettings';
import { useIsMobile } from '@/utils/MediaQueries';
import { MenuContainer } from '@/scenes/Settings/MenuSettings';
import { MenuMobile } from '@/scenes/Settings/RecruiterSettings/Menu/MenuMobile';

export const Menu = ({ handleOnNavigate, currentView }) => {
  const isWiderThanMobile = !useIsMobile();

  return (
    isWiderThanMobile ?
      <MenuContainer>
        <MenuProfile
          handleOnNavigate={handleOnNavigate}
          view={currentView}
        />
        <MenuSettings
          handleOnNavigate={handleOnNavigate}
          view={currentView}
        />
      </MenuContainer>
      : (
        <MenuContainer>
          <MenuMobile handleOnNavigate={handleOnNavigate} currentView={currentView}  />
        </MenuContainer>
      )
  )
}

Menu.propTypes = {
  currentView: string,
  handleOnNavigate: func,
}
