import React from 'react';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { object, array } from 'prop-types';
import { isEmpty, split } from 'lodash';
import languageList from '@/utils/languages.json';

// Components
import Highlighter from 'react-highlight-words';
import Icon from '@/components/Icon';
import Html from '@/components/Html';

// Styles & Translations
import styles from './styles.less';
import messages from '../../../MatchingHelp/messages';

/**
 * Search
 */
const Search = ({ offer, event, highlightWords, intl }) => {
  const {locale} = intl;
  if (offer.isMock) {
    const tags = offer.highlightCriteria.map((item) => `${item.label}`).join(' #');

    return (
      <div className={styles.searchContainer}>
        {/* My search */}
        <div className={styles.mySearch}>
          <Icon name="offers" className={styles.icon} />
          <span>
            <strong>
              <Highlighter
                highlightClassName="highlightText"
                searchWords={highlightWords}
                autoEscape
                textToHighlight={offer.contractType}
              />
            </strong>
          </span>
          <span>{offer.dateBeginContract > 1 && (
            intl.formatMessage(messages.searchAvailability)
          )} <strong>{offer.dateBeginContract}</strong></span>
          <span>{intl.formatMessage(messages.as)}
            <strong>
              <Highlighter
                highlightClassName="highlightText"
                searchWords={highlightWords}
                autoEscape
                textToHighlight={offer.position}
              />
            </strong>
          </span>
        </div>
        {/* Other informations */}
        <div className={styles.otherInformations}>
          <div className={styles.otherBlock}>
            <Icon name="location" className={styles.icon} /> <strong>{offer.location}</strong>
          </div>
          {tags.trim() !== '#' && (
            <div className={styles.otherBlock}>
              <Icon name="tag" className={styles.icon} />#<span>{tags}</span>
            </div>
          )}
        </div>
      </div>
    );
  }

  const criteria = !isEmpty(event) && event._criteria ? event._criteria : [];
  const defaultContractFilter = criteria.find((item) => item.key === 'CONTRACT');
  const defaultDurationFilter = criteria.find((item) => item.key === 'DURATION');
  const defaultAvailabilityFilter = criteria.find((item) => item.key === 'AVAILABILITY');
  const isDefaultAvailabilityFilterEnabled = defaultAvailabilityFilter && defaultAvailabilityFilter.enable;

  const contractChoices = defaultContractFilter ? defaultContractFilter._choices : [];
  const durationChoices = defaultDurationFilter ? defaultDurationFilter._choices : [];
  const languagesChoices = offer.matching.languages;
  const filters = offer.matching.filters ? offer.matching.filters : [];
  const otherFilters = criteria.filter((item) => isEmpty(item.key) || item.key === 'EXPERIENCE');

  let date = null;

  moment.locale(intl.locale);

  // Do not display for custom availability filter
  if (isDefaultAvailabilityFilterEnabled) {
    if (offer.matching.date === 1 || offer.matching.date === '1') {
      date = 1;
    } else if (offer.matching.date) {
      date = moment.unix(offer.matching.date).format('MMMM YYYY');
    }
  }

  const filterStrings = {
    contracts: contractChoices
      .filter((choice) => choice.enable && filters.includes(choice._id.toString()) && ((locale === 'en' && !isEmpty(choice.label_en)) || (locale === 'fr')))
      .map((item) => locale === 'en' ? item.label_en : item.label).join(', '),
    duration: durationChoices
      .filter((choice) => choice.enable && filters.includes(choice._id.toString()) && ((locale === 'en' && !isEmpty(choice.label_en)) || (locale === 'fr')))
      .map((item) => locale === 'en' ? item.label_en : item.label).join(', '),
    date,
  };

  const otherFiltersRender = [];

  if (otherFilters && otherFilters.length > 0) {
    otherFilters.forEach((filter) => {
      if (filter.key === 'EXPERIENCE' && offer.matching && !isEmpty(offer.matching.experiences) && offer.matching.experiences > -1) {
        otherFiltersRender
          .push(<div className={styles.criterion}><Icon name={filter.icon} className={styles.icon} /> {`${intl.formatMessage(messages[`experience${offer.matching.experiences}`])}`}</div>);
      } else {
        const filterChoices = filter._choices.filter((choice) => choice.enable && filters.includes(choice._id.toString()));

        if (filterChoices.length > 0) {
          const otherFiltersStr = filterChoices
            .filter((choice) => choice.enable && ((locale === 'en' && !isEmpty(choice.label_en)) || (locale === 'fr')))
            .map((item) => locale === 'fr' ? item.label : item.label_en).join(', ');

          if (!isEmpty(otherFiltersStr)) {
            otherFiltersRender.push((<div className={styles.criterion} title={filter.name}><Icon name={filter.icon} className={styles.icon} /> {otherFiltersStr} </div>));
          }
        }
      }
    });
  }

  const languagesStr = languagesChoices ? languagesChoices
    .map((item) => `${languageList[item.lang].name} (${intl.formatMessage(messages[`level${item.level}`])})`)
    .join(', ') : null;

  const searchText = filterStrings.contracts.length > 0
  || !isEmpty(filterStrings.date) || !isEmpty(filterStrings.duration) || !isEmpty(filterStrings.jobs) > 0 ? (
    <span>
      {filterStrings.contracts.length > 0 && (
        <span><strong>{filterStrings.contracts} </strong></span>
      )}
      {/* If filterStrings.date is undefined, do not show that block */}
      {filterStrings.date && (
        filterStrings.date === 1
          ? <strong>{intl.formatMessage(messages.availableToday)} </strong>
          : <span>{intl.formatMessage(messages.searchAvailability)} <strong>{filterStrings.date} </strong></span>
      )}
      {filterStrings.duration && (
        <span>
          <Html
            value={
              intl.formatMessage(
                messages.searchDuration,
                { withPrefix: filterStrings.duration.charAt(0) !== 'D', duration: filterStrings.duration.toLowerCase() }
              )}
            raw
          /></span>
      )}
    </span>
  ) : null;

  const displayLocations = () => (
    <>
      <Icon name="location" className={styles.icon} />
      <strong>
        <text> {offer.locations.map((item) => item.city || split(item.name, ',', 1)).join(', ')}</text>
      </strong>
    </>);

  return (
    <div className={styles.searchContainer}>
      {/* My search */}
      <div className={styles.mySearch}>
        {!isEmpty(searchText) && (
          <span><Icon name="offers" className={styles.icon} /> {searchText}</span>
        )}
      </div>
      {!isEmpty(offer.locations) && (
        <div className={styles.mySearch}>{displayLocations()}</div>
      )}
      {/* Other informations */}
      <div className={styles.otherInformations}>
        {languagesStr && (
          <div className={styles.criterion}><Icon name="language" className={styles.icon} /> {languagesStr}</div>
        )}
        {otherFiltersRender.map((item) => item)}
      </div>
    </div>
  );
};

Search.propTypes = {
  intl: object,
  event: object,
  offer: object,
  highlightWords: array,
};

export default injectIntl(Search);
