import React, { useEffect, useState } from 'react';
import { object, func, bool, array } from 'prop-types';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays'
import { connect } from 'react-redux';
import { compose } from 'redux';
import ModalBig from '@/components/ModalBig';
import AppointmentDetails from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Appointment/components/AppointmentDetails';
import { getLabel, getCriterionLabel }
  from '@/scenes/Event/scenes/Recruiter/scenes/Preparation/scenes/Offer/containers/CreateOfferModal/components/CreateOfferForm/components/CriteriaFields/utils';
import { getLocalStorage, LOCAL_STORAGE, setLocalStorage } from '@/utils/localStorage';
import ModalFooter from './components/ModalFooter';
import ModalConfirmation from './components/ModalConfirmation';
import ModalForm from './components/ModalForm';
import styles from './styles.less';

function ApplicationModal ({
offer,
  visible,
  onClose,
  displayProgress,
  confirmationIsVisible,
  candidateMatching,
  onApply,
  saveMatchingCriterias
}) {
  /* Vars */
  const [isLoading, setIsLoading] = useState(false);
  const [criteriasStored, setCriteriaStored] = useState(JSON.parse(getLocalStorage(LOCAL_STORAGE.candidate.jobAppoitmentsCriterias)) ?? {});

  /* Functions */

  const setOfferMatchingInStorage = (index, name, value) => {
    let newMatchingCriteria;

    if (!saveMatchingCriterias) {
      return;
    }

    const offerMatchingCriterias = criteriasStored?.[offer._id] ?
      { ...criteriasStored[offer._id], [index]: { [name]: value } }
      : {
        [index]: {
          [name]: value
        }
      }

    newMatchingCriteria = {
      ...criteriasStored,
      [offer._id]: {
        ...offerMatchingCriterias
      }
    }
    setLocalStorage(LOCAL_STORAGE.candidate.jobAppoitmentsCriterias, JSON.stringify(newMatchingCriteria));
    setCriteriaStored(newMatchingCriteria);
  }

  /**
   * Send data when user valid form
   * @param {Object} values
   */
  const handleApply = (values = {}) => {
    setIsLoading(true);
    const { matching } = values;
    const countBadCriteria = matching.filter((item) => item.isValid === false).length;

    onApply(countBadCriteria);
  };

  /**
   * Return a list of matching item
   * @returns {Array<Object>} - Matching items
   */
  const getMatchingItems = () => {
    if (offer?._event && isEmpty(candidateMatching) === false) {
      const matchingChecked = [];
      const offerFilterIds = offer.matching.filters.filter((id) => !isEmpty(id)).map((id) => id.toString());
      let matchingCheckedCounter = 0;

      offer._event._criteria.forEach((criteria, index) => {
        if (criteria.modules.offer.isVisible) {

          const item = {
            key: criteria.key,
            icon: criteria.icon,
            label: getCriterionLabel(criteria),
            choices: [],
            isValid: criteriasStored[offer._id]?.[matchingCheckedCounter]?.isValid ?? null,
          };

          if (criteria.key !== 'AVAILABILITY' && criteria.key !== 'MOBILITY') {
            item.choices = criteria._choices
              .filter((choice) => choice.enable && offerFilterIds.indexOf(choice._id.toString()) > -1)
              .map((choice) => getLabel(choice));
          } else if (criteria.key === 'AVAILABILITY') {
            const offerDate = moment.unix(offer.matching.date);

            item.choices.push(offerDate.format('MMMM YYYY'));
          } else if (criteria.key === 'MOBILITY') {
            item.choices = offer.locations.map((location) => {
              if (location.countryCode === 'FR' && !isEmpty(location.city)) {
                return location.city;
              }

              return location.formattedAdress;
            });
          }

          if (!isEmpty(item.choices)) {
            matchingCheckedCounter += 1;
            matchingChecked.push(item);
          }
        }
      });

      return matchingChecked;
    }

    return [];
  };

  useEffect(() => {
    if (visible) {
      const matchingItems = getMatchingItems();
      const filledMatchingItems = getMatchingItems().filter(({ isValid }) => isValid !== null);

      if (filledMatchingItems.length === matchingItems.length) {
        onApply(filledMatchingItems.filter(({ isValid })=>!isValid).length);
      }
    }
  }, [visible]);

  return offer?._user && (
    <Form
      initialValues={{
        matching: getMatchingItems(),
      }}
      mutators={{
        ...arrayMutators,
      }}
      onSubmit={handleApply}
      render={({ handleSubmit, handleChange }) => (
          <form onSubmit={handleSubmit} onChange={handleChange} noValidate>
            <ModalBig
              isOpen={visible}
              onClose={onClose}
              className={styles.applicationModal}
              mainPanelClassName={styles.mainPanel}
              sideContent={
                <AppointmentDetails
                  recruiter={offer._user}
                  hideContact
                />
              }
              footer={
                <ModalFooter
                  displayProgress={displayProgress}
                  submitLoading={isLoading}
                  offer={offer}
                  confirmationIsVisible={confirmationIsVisible}
                />
              }
            >
              <div className={styles.wrapper}>
                {confirmationIsVisible ? (
                  <ModalConfirmation offer={offer}/>
                ) : (
                  <ModalForm
                    offer={offer}
                    onClose={onClose}
                    onChangeMatching={setOfferMatchingInStorage}
                  />
                )}
              </div>
            </ModalBig>
          </form>
        )
      }
    />
  );
}

ApplicationModal.propTypes = {
  offer: object,
  visible: bool,
  onClose: func.isRequired,
  displayProgress: bool,
  confirmationIsVisible: bool,
  candidateMatching: array,
  onApply: func,
};

ApplicationModal.defaultProps = {
  offer: null,
  visible: false,
  displayProgress: false,
  confirmationIsVisible: false,
  candidateMatching: [],
};


const mapDispatchToProps = {};

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect)(ApplicationModal);
