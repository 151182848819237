import { defineMessages } from 'react-intl';

export default defineMessages({
  'pricing_not-provided': {
    id: 'pricing.not.provided',
  },
  pricing_free: {
    id: 'pricing.free',
  },
  pricing_info: {
    id: 'pricing.info',
  },
  pricing_paying: {
    id: 'pricing.paying',
  },
  seekalendarPageDescription: {
    id: 'recruiter.seekalendar.description',
  },
  searchPlannerPlaceholder: {
    id: 'recruiter.seekalendar.search.planner.placeholder',
  },
  seekalendar: {
    id: 'seekalendar',
  },
  virtual: {
    id: 'event.localization.virtual',
  },
  physical: {
    id: 'event.localization.physic',
  },
  now: {
    id: 'events.now',
  },
  past: {
    id: 'events.past',
  },
  engineer: {
    id: 'event.target.engineer',
  },
  other: {
    id: 'event.target.other',
  },
  tech: {
    id: 'technical.short',
  },
  business: {
    id: 'event.target.business',
  },
  targetPl: {
    id: 'target.pl',
  },
  formatPl: {
    id: 'format.pl',
  },
  pricingPl: {
    id: 'pricing.pl',
  },
  plannerPlgr: {
    id: 'planner.plgr',
  },
});
