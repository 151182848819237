import React from 'react';
import { func, shape, any, object, string, number } from 'prop-types';
import { isEmpty } from 'lodash';
import { Form } from 'antd';
import { Button, Alert } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { track } from '@/utils/analytics';
import { ANALYTICS_CANDIDATE } from '@/utils/constants';
import { H3 } from '@/components/Title';
import { trackGtmEvent } from "@/utils/pixels/helloWork";
import { UserCv } from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/Profile/Cv/UserCv';
import {
  UserResumeUrl
} from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/Profile/Cv/UserResumeUrl';
import {
  UserTitle
} from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/Profile/Cv/UserTitle';
import {
  UserDescription
} from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/Profile/Cv/UserDescriptions';
import {
  ExternalMedia
} from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/Profile/Cv/ExternalMedia';
import FormFooter from '../FormFooter';



const UserInfos = ({ form, handleDataUpdate, currentStep, user, event, context = 'event', stepPosition, countEnabledSteps, eventSteps }) => {
  const t = useFormatMessage();
  const handleOnSubmit = (e) => {
    e.preventDefault();

    return form.validateFields((err, values) => {
      if (!err) {
        track({
          name: ANALYTICS_CANDIDATE.TOUR_STEP2_SUBMIT,
          user,
          event,
          properties: {
            hasDescription: !!form.getFieldsValue().description,
          },
        });

        if (event) {
          trackGtmEvent('onboarding_cv_complete', {
            'page_category' : 'inscription',
            'page_subject' : 'onboarding' ,
            'user_id_back' : user._id,
            'flow_name' : 'inscription_jobdating',
            'flow_step' : 2,
            'flow_step_total' : event.countOnboardingSteps,
            'flow_modules_list': event.onboardingSteps,
            'flow_step_name': 'cv',
            'jobdating_name': event.slug,
          });
        }
        const defaultValues = {
          links: [],
          mediaItems: [],
        }

        handleDataUpdate({ ...defaultValues, ...values, lastResumeUrlUpdate: new Date() }, 2);
      }
    });
  };



  const cvIsRequired = !event || event.participants.cvIsRequired !== false;



  return (
    <div className="commonStep">
      {context === 'event' ? (
        <H3 bold style={!isEmpty(user) && user.resumeS3Key ? { marginBottom: 15 } : {}}>
          {isEmpty(user) || (!user.resumeUrl && !user.resumeS3Key)? t({ id: 'event.candidate.preparation.signup.userInfos.title' }) : t({ id: 'event.candidate.preparation.signup.userInfos.title.verify' })} <span role="img" aria-label="Clipboard"> 📋</span>
        </H3>
      ) : (
        <H3 bold className="presentationTitle">{t({ id: 'form.resume.title' })} <span role="img" aria-label="Clipboard" style={{ marginLeft: 10 }}> 📋</span></H3>
      )}
      {!isEmpty(user) && (user.resumeUrl || user.resumeS3Key) && context === 'event' && (
        <Alert color="warning" className="mb-20">
          {t({ id: 'event.candidate.preparation.signup.userInfos.alert'})}
        </Alert>
      )}

      <Form className="form" onSubmit={handleOnSubmit}>

        <UserCv
          onClick={() => {}}
          form={form}
          user={user}
          cvIsRequired={cvIsRequired}
        />

        <UserResumeUrl form={form} cvIsRequired={cvIsRequired} user={user} />

        <div className="fieldsContainer mb-40">
          <UserTitle form={form} user={user} />

          <UserDescription form={form} user={user} />
        </div>

        <ExternalMedia form={form} user={user} />

      </Form>

      <div className="flex">
        <Button
          className="changeStep settings mr-6"
          type="submit"
          onClick={handleOnSubmit}
        >
          {t({ id: 'save' })}
        </Button>
      </div>

      <FormFooter
        form={form}
        currentStep={currentStep}
        eventSteps={eventSteps}
        stepPosition={stepPosition}
        countEnabledSteps={countEnabledSteps}
        onSubmit={handleOnSubmit}
      />
    </div>
  );
}

UserInfos.propTypes = {
  form: shape({
    getFieldDecorator: func,
    getFieldsError: func,
    getFieldError: func,
    isFieldTouched: func,
  }),
  handleDataUpdate: func,
  currentStep: any,
  user: object,
  event: object,
  context: string,
  stepPosition: number,
  countEnabledSteps: number,
  eventSteps: object,
}

export default Form.create()(UserInfos);
