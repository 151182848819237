import { defineMessages } from 'react-intl';

export default defineMessages({
  addToSelection: {
    id: 'recruiter.participantsList.add.to.selection',
  },
  addToAccepted: {
    id: 'recruiter.participantsList.add.to.accepted',
  },
  move: {
    id: 'recruiter.participantsList.move',
  },
  downloadCV: {
    id: 'recruiter.participantsList.download.cv',
  },
  appointmentPropose: {
    id: 'recruiter.participantsList.appointment.propose',
  },
  appointmentCancel: {
    id: 'appointment.cancel',
  },
  appointmentChange: {
    id: 'recruiter.participantsList.appointment.change',
  },
  appointmentChangeRecruiterInCharge: {
    id: 'recruiter.participantsList.appointment.change.inCharge',
  },
  appointmentChangeDate: {
    id: 'recruiter.participantsList.appointment.change.date',
  },
  appointmentProposeNew: {
    id: 'recruiter.participantsList.appointment.propose.new',
  },
  propose: {
    id: 'recruiter.participantsList.appointment.propose',
  },
  appointmentConfirm: {
    id: 'recruiter.participantsList.appointment.confirm',
  },
  appointmentConfirmFor: {
    id: 'recruiter.participantsList.appointment.confirm-for',
  },
  confirmFor: {
    id: 'recruiter.participantsList.confirm-for',
  },
  noSlot: {
    id: 'recruiter.participantsList.no-slot',
  },
  btnAddSlot: {
    id: 'recruiter.participantsList.btn.add-slot',
  },
  hide: {
    id: 'hide',
  },
  refuse: {
    id: 'refuse',
  },
  confirm: {
    id: 'confirm',
  },
  btnConfirm: {
    id: 'btn.confirm',
  },
  search: {
    id: 'search',
  },
  addToInterview: {
    id: 'recruiter.participantsList.add.to.interview',
  },
  'addTo.save': {
    id: 'recruiter.participantsList.add.to.save',
  },
  'addTo.top': {
    id: 'recruiter.participantsList.add.to.top',
  },
  'addTo.refuse': {
    id: 'recruiter.participantsList.add.to.refuse',
  },
  previousParticipant: {
    id: 'recruiter.participantsList.participant-previous',
  },
  nextParticipant: {
    id: 'recruiter.participantsList.participant-next',
  },
  recruiterInCharge: {
    id: 'event.recruiter.appointmentModal.inCharge',
  },
  defaultMessage: {
    id: 'event.recruiter.modal.modifyAppointment.defaultMessage',
  },
  defaultMessageModifyRecruiter: {
    id: 'event.recruiter.modal.modifyAppointment.recruiterInCharge.defaultMessage',
  },
  titleProposeAppointment: {
    id: 'event.recruiter.appointmentModal.title',
  },
  titleCannotBanParticipant: {
    id: 'event.owner.candidates.cannotBan.modal.title',
  },
  subtitleCannotBanParticipant: {
    id: 'event.owner.candidates.cannotBan.modal.subtitle',
  },
  titleBanParticipant: {
    id: 'event.owner.candidates.ban.modal.title',
  },
  subtitleBanParticipant: {
    id: 'event.owner.candidates.ban.modal.subtitle',
  },
  title: {
    id: 'event.recruiter.modal.modifyAppointment.title',
  },
  titleModifyRecruiterInCharge: {
    id: 'event.recruiter.modal.modifyAppointment.recruiterInCharge.title',
  },
  titleCancelAppointment: {
    id: 'event.recruiter.modal.cancelAppointment.title',
  },
  titleNegativeScoring: {
    id: 'event.recruiter.modal.negativeScoring.title',
  },
  cancelAppointmentAlert: {
    id: 'event.recruiter.modal.cancelAppointment.alert',
  },
  modifyAppointmentAlert: {
    id: 'event.recruiter.modal.modifyAppointment.alert',
  },
  btnCancelAppointment: {
    id: 'appointment.cancel',
  },
  btnCancel: {
    id: 'cancel',
  },
  tooltipRemoveCandidateFromJb: {
    id: 'event.owner.candidate.tooltip.removeFromJd',
  },
});
