import React from 'react';
import { func, object } from 'prop-types';

// Components
import { Button } from '@seekube-tech/ui-kit';
import { FormattedMessage } from 'react-intl';
import { Form, Input, Radio } from 'antd';
import { useFormatMessage } from 'react-intl-hooks';
import { Tooltip } from '@/components/Tooltip';
import { Bloc, BlocHeader } from '@/components/Bloc';

// Services
import ApiToForm from '../../../../services/formMapping/ApiToForm';

import styles from './styles.less';

// Antd
const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;

const LiveTalksForm = ({
  form: { validateFields, getFieldDecorator },
  initialValues: {
    modules,
    participantRedirection,
  },
  onSubmit,
}) => {
  const t = useFormatMessage();

  const handleSubmit = (e) => {
    e.preventDefault();

    return validateFields(onSubmit);
  };

  return (
    <Bloc>
      <Form onSubmit={handleSubmit} style={{ maxWidth: '500px', textAlign: 'left' }} layout="vertical">
        <BlocHeader>
          <FormattedMessage id="livetalk" tagName="h2" />
        </BlocHeader>

        <FormItem label={<FormattedMessage id="event.modules.lives.enable.admin.label" />}>
          {getFieldDecorator('adminEnable', {
            initialValue: ApiToForm.module(modules, 'live', 'adminEnable'),
          })(
            <RadioGroup>
              <RadioButton value ><FormattedMessage id="yes" /></RadioButton>
              <RadioButton value={false}><FormattedMessage id="no" /></RadioButton>
            </RadioGroup>
          )}
        </FormItem>

        <FormItem label={<FormattedMessage id="event.modules.lives.enable.companies.label" />}>
          {getFieldDecorator('recruiterEnable', {
            initialValue: ApiToForm.module(modules, 'live', 'recruiterEnable'),
          })(
            <RadioGroup>
              <RadioButton value ><FormattedMessage id="yes" /></RadioButton>
              <RadioButton value={false}><FormattedMessage id="no" /></RadioButton>
            </RadioGroup>
          )}
        </FormItem>

        <FormItem label={<FormattedMessage id="event.modules.lives.enable.candidate.label" />}>
          {getFieldDecorator('participantEnable', {
            initialValue: ApiToForm.module(modules, 'live', 'participantEnable'),
          })(

            <RadioGroup>

              <RadioButton value><FormattedMessage id="yes" /></RadioButton>
              <Tooltip
                title={participantRedirection === 'live' && <FormattedMessage id="event.modules.prevent.no.tooltip.text" values={{ text: <FormattedMessage id="offers.plural" values={{ count: 2 }} /> }} />}
                color="dark"
                placement="bottom"
              >
                <RadioButton value={false} disabled={participantRedirection === 'live'}><FormattedMessage id="no" /></RadioButton>
              </Tooltip>

            </RadioGroup>
          )}
        </FormItem>
        {/* FR Label */}
        <FormItem label={<FormattedMessage id="event.modules.activation.and.tabs.label.fr" />}>
          {getFieldDecorator('label_fr', {
            initialValue: ApiToForm.module(modules, 'live', 'label_fr'),
            rules: [{ required: true, message: 'Merci de renseigner le label' }],
          })(
            <Input placeholder="Conférence" />
          )}
        </FormItem>

        {/* FR label multiple */}
        <FormItem label={<FormattedMessage id="event.modules.activation.and.tabs.label.fr.pl" />}>
          {getFieldDecorator('labelMultiple_fr', {
            initialValue: ApiToForm.module(modules, 'live', 'labelMultiple_fr'),
            rules: [{ required: true, message: 'Merci de renseigner le label' }],
          })(
            <Input placeholder="" />
          )}
        </FormItem>
        {/* EN Label */}
        <FormItem label={<FormattedMessage id="event.modules.activation.and.tabs.label.en" />}>
          {getFieldDecorator('label_en', {
            initialValue: ApiToForm.module(modules, 'live', 'label_en'),
            rules: [{ required: true, message: 'Merci de renseigner le label' }],
          })(
            <Input placeholder="Conférence" />
          )}
        </FormItem>

        {/* EN label multiple */}
        <FormItem label={<FormattedMessage id="event.modules.activation.and.tabs.label.en.pl" />}>
          {getFieldDecorator('labelMultiple_en', {
            initialValue: ApiToForm.module(modules, 'live', 'labelMultiple_en'),
            rules: [{ required: true, message: 'Merci de renseigner le label' }],
          })(
            <Input placeholder="" />
          )}
        </FormItem>

        <hr style={{ marginBottom: '18px' }} />

        <p className={styles.activateLiveTitle}>
          <FormattedMessage id="event.modules.lives.enable.by.topic.label" />
        </p>
        <p className={styles.activateLiveDescription}>
          <FormattedMessage id="event.modules.lives.enable.by.topic.description" />
        </p>
        <FormItem label="">
          {getFieldDecorator('filterByTopicEnable', {
            initialValue: ApiToForm.module(modules, 'live', 'filterByTopicEnable'),
          })(
            <RadioGroup>
              <RadioButton value ><FormattedMessage id="yes" /></RadioButton>
              <RadioButton value={false}><FormattedMessage id="no" /></RadioButton>
            </RadioGroup>
          )}
        </FormItem>
        {/* Submit */}
        <div>
          <Button type="submit">
            {t({ id: 'save' })}
          </Button>
        </div>
      </Form>
    </Bloc>
  );
};

LiveTalksForm.propTypes = {
  onSubmit: func.isRequired,
  form: object,
  initialValues: object,
};

export default Form.create()(LiveTalksForm);
