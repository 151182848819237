import React from 'react';

/**
 * NavScene
 */
const NavScene = () => (
  <>
    <div id="navScene" />
    <div id="subNavScene" />
  </>
);

export default NavScene;
