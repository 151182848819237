import React, { useEffect, useState } from 'react';
import { shape, func, object } from 'prop-types';
import { isUndefined, isEmpty } from 'lodash';
import { Col, Form, Row } from 'antd/lib/index';
import { Button, H5 } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import 'react-phone-number-input/style.css';

// components
import AvatarEditorDnd from '@/components/AvatarEditorDnd';
import {
  Phone
} from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/Profile/Presentation/Phone';
import {
  LastName
} from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/Profile/Presentation/LastName';
import {
  FirstName
} from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/Profile/Presentation/FirstName';
import {
  Gender
} from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/Profile/Presentation/Gender';
import {
  StudentId
} from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/Profile/Presentation/StudentId';
import { Bloc } from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/components/Bloc';
import {
  NotificationBySmsForUpcomingEvent
} from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/Profile/Presentation/NotificationBySmsForUpcomingEvent';
import { getLocale } from '@/utils/localStorage';

const FormItem = Form.Item;


const Presentation = ({
  form,
  user,
  event,
}) => {
  const t = useFormatMessage();
  const [linkedinStatus, setLinkedinStatus] = useState(null);
  const [pictureUrl, setPictureUrl] = useState(!isUndefined(user.pictureUrl) ? user.pictureUrl : null);

  useEffect(() => {
    form.setFieldsValue({
      gender: user.gender || window.localStorage.getItem('signup-civility'),
      firstName: user.firstName || window.localStorage.getItem('signup-fn'),
      lastName: user.lastName || window.localStorage.getItem('signup-ln'),
      pictureUrl: user.pictureUrl,
      phone: user.phone || window.localStorage.getItem('signup-phone')
    })
  }, [])


  const updateInput = (fileUrl) => {
    form.setFieldsValue({
      pictureUrl: fileUrl
    });
  }

  const saveFile = (fileUrl) => {
    setPictureUrl(fileUrl);
    updateInput(fileUrl);
  };

  const handleRemoveProfilePicture = () => {
    setPictureUrl(null);
    setLinkedinStatus(linkedinStatus === 'success' ? null : linkedinStatus);

    updateInput(null)
  };

  const btnPictureText = isEmpty(user) || !user.pictureUrl ? t({ id: 'pictureUrl.choose' }) : t({ id: 'pictureUrl.update' });


  const regexCriteria = event?._criteria?.find((criteria) => criteria.key === 'REGEXID');
  const title = event?.participants?.onboarding?.find((o) => o.name === 'presentation')?.[`title_${getLocale()}`];

  return (
    <Bloc>
      <div className="commonStep">
        <H5 className="mb-24">{!isEmpty(title) ? title : t({ id: 'event.candidate.preparation.signup.step.presentation' })}</H5>
        <div className="pictureContainer">
          <div className="picture">
            <AvatarEditorDnd
              src={pictureUrl || undefined}
              onSave={saveFile}
              onRemove={handleRemoveProfilePicture}
              objectId={user._id}
              size={130}
            >
              <div className="pictureBtnAction">
                <Button className="pictureBtn" variant="outline">{btnPictureText}</Button>

                <p className="upload-infos">
                  {t({ id: 'pictureUrl.format' })}
                </p>
              </div>
            </AvatarEditorDnd>
          </div>
        </div>

        <FormItem className="pictureItem">
          {form.getFieldDecorator('pictureUrl', {
            rules: [{ required: false }],
          })(
            <input type="hidden" />
          )}
        </FormItem>

        <div className="fieldsContainer">
          <Row gutter={16}>
            <Col xs={16}>
              <Gender form={form} user={user} />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={6}>
              <FirstName form={form} user={user} />
            </Col>
            <Col xs={6}>
              <LastName form={form} user={user} />
            </Col>
          </Row>

          <Phone form={form} user={user} />

          <NotificationBySmsForUpcomingEvent form={form} user={user} />

          {regexCriteria?.modules?.onboarding?.isVisible && <StudentId form={form} user={user} regexCriteria={regexCriteria} />}
        </div>
      </div>
    </Bloc>
  );
}

Presentation.propTypes = {
  form: shape({
    getFieldDecorator: func,
    getFieldsError: func,
    getFieldError: func,
    isFieldTouched: func,
  }),
  user: object,
  event: object,
};

export default Presentation;
