import React from 'react';
import { IconStar, Tag } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';

const TagNew = () => {
  const t = useFormatMessage();
  return (
    <Tag
      color="yellow"
      variant="tonal"
      icon={<IconStar size={12}/>}
    >
      {t({ id: 'new.feature' })}
    </Tag>
  )
}

export default TagNew;
