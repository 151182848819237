import React, { useEffect, useState } from 'react';
import 'add-to-calendar-button';
import { IconCalendar } from '@seekube-tech/ui-kit';
import ReactDOMServer from 'react-dom/server'
import { object, string, func, bool } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { useSelector } from 'react-redux';
import { getAuthUser } from '@/store/auth/selectors';

const AddToCalendar = ({
  params,
  variant = "tonal",
  className,
  onSelectCalendarType,
  visible = true
}) => {
  const t = useFormatMessage();
  let calendarsSelected = [];
  const [mounted, setMounted] = useState(false);
  const authUser = useSelector(getAuthUser)?.toJS();

  if (!params) {
    return null;
  }

  const getColors = () => {
    const bgColorHover = variant === 'tonal' ? '#f5fbfe' : '#1171a9';
    const bgColor = variant === 'tonal' ? 'white' : '#33a8eb';

    const colorHover = variant === 'tonal' ? '#1171a9' : '#white';
    const color = variant === 'tonal' ? '#33a8eb' : 'white';

    return {
      bgColor,
      bgColorHover,
      colorHover,
      color
    }
  }

  const overrideStyle = (shadowRoot, {
    bgColor,
    bgColorHover,
    colorHover,
    color
  }) => {
    shadowRoot.querySelector('style').innerHTML += `
      <style>

        .customize-atcb {
            display: flex !important;
            align-items: center !important;
        }

        .customize-atcb button {
            box-sizing: border-box;
            height: 41px !important;
            box-shadow: none !important;
            border: none !important;
            background-color: ${bgColor} !important;
            color: ${color} !important;
            font-weight: 500 !important;
            font-family: 'LATO' !important;
            transition-duration: 150ms !important;
            transition-property: all !important;
            cursor: pointer !important;
            padding: 10px 24px !important;
            margin: 0 !important;
            display: flex !important;
            align-items: center !important;
            column-gap: 6px !important;
            line-height: 1em;

            [class*="atcb-icon"] {
                margin: 0;
            }
        }

       .customize-atcb button:not([disabled]):hover {
            color: ${colorHover} !important;
            box-shadow: none !important;
            background-color: ${bgColorHover} !important;
        }

        [class*="atcb-button-wrapper"] {
            padding: 0 !important;
        }

        [class*="atcb-list-wrapper"] {
            top: unset !important;
            border: 1px solid #D3DBE8;
            padding: 0;
            border-radius: 5px !important;
            background-color: white;
            margin-bottom: 10px !important;
            margin-top: 4px !important;

            [class*="atcb-list"] {
                box-shadow: none;
                padding: 12px !important;

                [class*="atcb-list-item"] {
                    background-color: white;
                    color: #3C4E73;
                    padding: 8px !important;
                    transition-duration: 150ms !important;
                    transition-property: all !important;
                    border-radius: 5px !important;
                }

                [class*="atcb-list-item"]:hover {
                    background-color: #F5FBFE !important;
                }

                [class*="atcb-list-item"]:first-child, [class*="atcb-list-item"]:last-child {
                    border-radius: unset;
                }
            }
        }

      </style>
    `;
    const buttonWrapper = shadowRoot?.querySelector('.atcb-initialized');

    buttonWrapper.querySelector('.atcb-icon-trigger').innerHTML = ReactDOMServer.renderToString(<IconCalendar size={12}/>);
    buttonWrapper.className += ` customize-atcb`;
    setMounted(true);
  }

  useEffect(() => {
    const shadowRoot = document.getElementsByClassName('atcb-light')?.[0]?.shadowRoot;
    if (!shadowRoot) {
      return;
    }

    const colors = getColors();
    overrideStyle(shadowRoot, colors);
  }, [visible]);

  const onSelectCalendar = () => {
    const calendars = window.dataLayer.filter((datum)=> datum.event === 'openCalendarLink');

    if (calendars.length > calendarsSelected.length) {
      const lastCalendarSelected = calendars[calendars.length - 1];
      const calendarType = lastCalendarSelected.eventLabel.split('-').pop();

      onSelectCalendarType(calendarType);
      calendarsSelected = calendars;
    }
  }

  return visible && (
    <div role="button" className={className} onClick={onSelectCalendar} style={{visibility: mounted ? 'visible' : 'hidden'}}>
      <add-to-calendar-button
        name={params.title}
        startDate={params.startDate}
        endDate={params.endDate}
        startTime={params.startTime}
        endTime={params.endTime}
        description={params.description}
        location={params.location}
        options={`'Google','Apple|Apple Calendar','Microsoft365|Outlook','iCal|${t({ id: 'other' })}'`}
        trigger="click"
        hideBackground
        hideIconList
        hideCheckmark
        hideIconModal
        styleLight="--overlay-cursor: default"
        label={t({ id: 'event.candidate.appointment.mainContent.addToCalendar' })}
        timezone={authUser?.timezone?.utc?.[0] || null}
      />
    </div>
  )
}

AddToCalendar.propTypes = {
  params: object,
  variant: string,
  className: string,
  onSelectCalendarType: func,
  visible: bool
};

export default AddToCalendar;
