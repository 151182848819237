import React from 'react';
import { array, string } from 'prop-types';
import { isEmpty } from 'lodash';
import classnames from 'classnames';

import Icon from '@/components/Icon';

import styles from './styles.less';

const TargetItems = ({ label, icon, items, blankLabel }) => (
  <div className={classnames(styles.targetItems, isEmpty(items) && styles.blank)}>
    <Icon name={icon} className={styles.icon} />
    <strong>{label} : </strong> <span>{isEmpty(items) ? blankLabel : items.join(', ')}</span>
  </div>
);

TargetItems.propTypes = {
  label: string,
  icon: string,
  items: array,
  blankLabel: string,
};

export default TargetItems;
