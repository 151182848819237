import { IconCheck, IconClock, IconEdit3, IconMail, IconX } from '@seekube-tech/ui-kit';
import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import {
  CardsBySeparator
} from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Appointments/components/AppointmentsList/CardsBySeparator';
import {
  SEPARATORS_NAMES
} from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Appointments/components/AppointmentsList/const';
import {
  getInteractionsGroupedBySeparator,
  getSeparatorNameOrder,
} from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Appointments/components/AppointmentsList/helpers';

const AppointmentsList = ({ authUser, interactions, participant, event, handleOpenOffer, renderPopoverContent }) => {
  const t = useFormatMessage();
  const { propositionsModified, accepted, done, proposition, pending, refused } = SEPARATORS_NAMES;

  const separators = {
    [proposition]: {
      separator: 'becoming',
      label: t({ id: 'event.candidate.appointments.becoming' }),
      icon: <IconMail size={16} />,
    },
    [propositionsModified]: {
      separator: 'propositionsModified',
      label: t({ id: 'event.candidate.appointments.propositionsModified' }),
      icon: <IconEdit3 size="16" />,
    },
    [accepted]: {
      separator: 'accepted',
      label: t({ id: 'event.candidate.appointments.accepted' }),
      icon: <IconCheck size="16" />,
    },
    [pending]: {
      separator: 'pending',
      label: t({ id: 'event.candidate.appointments.pending' }),
      icon: <IconClock size={12} />,
    },
    [done]: {
      separator: 'done',
      label: t({ id: 'event.candidate.appointments.done' }),
      icon: <IconX size={12} />,
    },
    [refused]: {
      separator: 'refused',
      label: t({ id: 'event.candidate.appointments.refused' }),
      icon: <IconX size={12} />,
    },
  };

  const separatorNameOrder =  getSeparatorNameOrder(event)

  const interactionsGroupedBySeparator = getInteractionsGroupedBySeparator(interactions, authUser);

  return separatorNameOrder.map((key) =>
    <CardsBySeparator
      key={key}
      type={key}
      event={event}
      renderPopoverContent={renderPopoverContent}
      handleOpenOffer={handleOpenOffer}
      interactions={interactionsGroupedBySeparator[key]}
      participant={participant}
      separator={separators[key]}
    />
  )
}

export { AppointmentsList }