/**
 * NotFoundPage
 *
 * This is the page we show when the user visits a url that doesn't have a route
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */

import React from 'react';
import { func, object } from 'prop-types';
import { compose } from 'redux';
import { ReactTitle } from 'react-meta-tags';
import BlankState from '@/components/BlankState';
import { useFormatMessage } from 'react-intl-hooks';
import { withRouter } from 'react-router-dom';

import styles from './styles.less';
import goat from '../../assets/images/goat.jpg';

function ErrorScene({ history, location }) {
  const t = useFormatMessage();
  const id = new URLSearchParams(location.search).get('id');

  return (
    <div className={styles.errorContainer}>
      <ReactTitle title="500 Seekube" />
      <BlankState
        content={(
          <h2 className={styles.errorTitle}>
            {t({ id: 'app.components.ErrorPage.header' })}
          </h2>
        )}
        customIcon={<img src={goat} alt="goat" />}
        handleAction={() => history.push('/events')}
        buttonLabel={t({ id: 'app.components.ErrorPage.button' })}
      >
        {!!id && (
          <p>{t({ id: 'app.components.ErrorPage.errorCode' })} : {id}</p>
        )}
        <p>{t({ id: 'app.components.ErrorPage.content' })}</p>
      </BlankState>
    </div>
  );
}

ErrorScene.propTypes = {
  history: func,
  location: object
}

export default compose(
  withRouter
)(ErrorScene)
