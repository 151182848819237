import { makeStyles } from '@seekube-tech/ui';

export default makeStyles(theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  marginRoot: {
    marginTop: -20,
  },
  cover: {
    position: 'relative',
    height: 343,
    width: '100%',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'linear-gradient(0deg, rgba(56, 80, 119, 0.4), rgba(56, 80, 119, 0.4)), linear-gradient(180deg, rgba(37, 45, 71, 0.3) 35.08%, #252D47 100%)',
    padding: '66px 190px',
  },
  wrapper: {
    maxWidth: 1140,
    width: '100%',
    padding: theme.spacing(0, 5),
  },
  container: {
    padding: theme.spacing(5, 5, 4, 5),
    marginBottom: theme.spacing(4),
    maxWidth: 1140,
    width: '100%',
    backgroundColor: theme.palette.basic.white,
    marginTop: '-110px',
    zIndex: 2,

    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      backgroundColor: 'transparent',
    },
  },
  mobileTitle: {
    marginBottom: theme.spacing(4),
  },
  subtitle: {
    marginTop: theme.spacing(1),
  },
  timezone: {
    marginTop: theme.spacing(1),
    color: theme.palette.neutral[300],

    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      color: theme.palette.neutral[400],
    },
  },
  dates: {
    marginTop: theme.spacing(4),
  },
}));
