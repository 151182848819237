import React from 'react';
import { func, array, bool, object, string } from 'prop-types';
import { FormattedHTMLMessage } from 'react-intl';
import { Alert } from '@seekube-tech/ui-kit';
import messages from './messages';

const AlertSelectedParticipant = ({ isBtnPageParticipantsActive, isBtnAllParticipantsChecked, checkedParticipants, onToggleAllParticipants, pagination, intl }) => (
  isBtnPageParticipantsActive ? (
    <div className="mt-20">
      {!isBtnAllParticipantsChecked ?
        <Alert>
          <span><FormattedHTMLMessage{...messages.groupedHintPageSelected} values={{ count: checkedParticipants.length }} /> <a role="button" tabIndex={0} onClick={() => onToggleAllParticipants()}>{intl.formatMessage(messages.groupedProfileSelection, { count: pagination.total }) }</a></span>
        </Alert>
        :
        <Alert>
          <span><FormattedHTMLMessage{...messages.groupedHintPageSelected} values={{ count: pagination.total }} /> <a role="button" tabIndex={0} onClick={() => onToggleAllParticipants()}>{intl.formatMessage(messages.clearSelection)}</a></span>
        </Alert>
      }
    </div>
  ) : null
);

const AlertSelectedOffer = ({ isBtnPageParticipantsActive, isBtnAllParticipantsChecked, checkedParticipants, onToggleAllParticipants, pagination, intl }) => (
  isBtnPageParticipantsActive ? (
    <div className="mt-20">
      {!isBtnAllParticipantsChecked ?
        <Alert>
          <span><FormattedHTMLMessage{...messages.groupedHintPageSelectedOffers} values={{ count: checkedParticipants.length }} /> <a role="button" tabIndex={0} onClick={() => onToggleAllParticipants()}>{intl.formatMessage(messages.groupedProfileSelectionOffers, { count: pagination.total }) }</a></span>
        </Alert>
        :
        <Alert>
          <span><FormattedHTMLMessage{...messages.groupedHintPageSelectedOffers} values={{ count: pagination.total }} /> <a role="button" tabIndex={0} onClick={() => onToggleAllParticipants()}>{intl.formatMessage(messages.clearSelection)}</a></span>
        </Alert>
      }
    </div>
  ) : null
);

AlertSelectedParticipant.propTypes = {
  isBtnPageParticipantsActive: bool,
  isBtnAllParticipantsChecked: bool,
  checkedParticipants: array,
  onToggleAllParticipants: func,
  intl: object,
  pagination: object,
  context: string,
};

AlertSelectedOffer.propTypes = {
  isBtnPageParticipantsActive: bool,
  isBtnAllParticipantsChecked: bool,
  checkedParticipants: array,
  onToggleAllParticipants: func,
  intl: object,
  pagination: object,
  context: string,
};

export { AlertSelectedParticipant, AlertSelectedOffer };
