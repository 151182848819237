import React, { memo } from 'react';
import { object } from 'prop-types';
import { injectIntl } from 'react-intl';
import { isEmpty, get } from 'lodash';

// Containers
import { NavScenePortal } from '@/containers/Layout';
import NavEvent from '@/components/Nav/NavEvent';

// Styles & Translations
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getAvailableActions } from '@/store/availableActions/selectors';
import { createStructuredSelector } from 'reselect';
import messages from './messages';


const NavOwner = memo((
  {
    modules,
    match,
    availableActions,
    css,
    intl: { formatMessage, locale },
  }) => {
  const elems = [];

  let labelOrganizations = formatMessage(messages.companies);

  if (modules.organization && !isEmpty(modules.organization[`labelMultiple_${locale}`])) {
    labelOrganizations = modules.organization[`labelMultiple_${locale}`];
  }

  elems.push({ url: `${match.url}/dashboard`, label: `${formatMessage(messages.dashboard)}`, icon: 'home', type: 'blue' });
  elems.push({ url: `${match.url}/exponents`, label: labelOrganizations, icon: 'building', type: 'blue' });
  elems.push({ url: `${match.url}/stands`, label: formatMessage(messages.stands), icon: 'house', type: 'blue' });

  let labelOffer = formatMessage(messages.offers);

  if (modules.offer && !isEmpty(modules.offer[`labelMultiple_${locale}`])) {
    labelOffer = modules.offer[`labelMultiple_${locale}`];
  }

  if (modules.offer && modules.offer.enable) {
    elems.push({ url: `${match.url}/offers`, label: labelOffer, icon: 'star', type: 'blue' });
  }

  if (modules.live && modules.live.adminEnable) {
    elems.push({
      url: `${match.url}/conferences`,
      label: modules.live && modules.live.labelMultiple ? modules.live[`labelMultiple_${locale}`] : `${formatMessage(messages.live, { count: 2 })}`,
      icon: 'camera',
      type: 'blue',
      disabled: !!availableActions.liveNav,
      tooltipMessage: `${formatMessage(messages.liveDisabledTooltip)}`
    });
  }

  if (modules.informal1to1?.enable) {
    elems.push({ url: `${match.url}/informal1to1`, label: modules.informal1to1[`labelMultiple_${locale}`], icon: 'language', type: 'blue' });
  }

  elems.push({ url: `${match.url}/candidates`, label: `${formatMessage(messages.candidates)}`, icon: 'users', type: 'blue' });

  elems.push({ url: `${match.url}/statistics`, label: `${formatMessage(messages.statistics)}`, icon: 'activity', type: 'blue' });

  elems.push({ url: `${match.url}/settings`, label: `${formatMessage(messages.settings)}`, icon: 'settings', type: 'blue' });

  return (
    <NavScenePortal>
      <div style={css}>
        <NavEvent items={elems} />
      </div>
    </NavScenePortal>
  );
});

NavOwner.propTypes = {
  modules: object,
  match: object,
  css: object,
  intl: object,
  availableActions: object
};

const mapStateToProps = createStructuredSelector({
  availableActions: getAvailableActions
})

const mapDispatchToProps = {}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(
  injectIntl,
  withConnect
)(NavOwner);
