import React from 'react';
import { func, any } from 'prop-types';

// Components
import { Modal as ModalAntd } from 'antd';

// Styles & Translations
import styles from './styles.less';
import { CloseModal } from '../Buttons/components'

/**
 * Modal
 * @param {object} props
 */
const OverlayContainer = (props) => (
  <ModalAntd
    className={styles.overlayContainer}
    footer={null}
    width="100%"
    keyboard
    closable={false}
    {...props}
  >
    <CloseModal onClick={props.onCancel} />

    {props.children}
  </ModalAntd>
);

OverlayContainer.propTypes = {
  onCancel: func,
  children: any,
};

export default OverlayContainer;
