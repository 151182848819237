import { defineMessages } from 'react-intl';

export default defineMessages({
  placeholderOfferOwner: {
    id: 'client.offer.owner',
  },
  titleLabel: {
    id: 'offer.form.title.label',
  },
  titlePlaceholder: {
    id: 'offer.form.title.placeholder',
  },
  profileDescriptionLabel: {
    id: 'offer.form.profileDescription.label',
  },
  blankNoEvent: {
    id: 'offer.form.event.blank',
  },
  title: {
    id: 'client.create.offer.events.title',
  },
  removeFromAllJd: {
    id: 'client.create.offer.events.btn.remove.all.jd',
  },
  subtitle: {
    id: 'client.create.offer.events.subtitle',
  },
  descriptionLabel: {
    id: 'event.recruiter.preparation.stand.item.description.label',
  },
  next: {
    id: 'next',
  },
});
