import { Link, Typography } from '@seekube-tech/ui';
import { Col, Row } from 'antd';
import * as React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import {
  Eye as EyeIcon,
  Copy as CopyIcon,
} from 'react-feather';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { func } from 'prop-types';

import copyToClipboard from '@/utils/copyToClipboard';
import { notificationActions } from '@/store/notification';
import { CONTEXT_ROLES } from '@/scenes/Event/utils/constants';

import styles from './TimelineBlock.less';

function TimelineBlock({ sendNotification }) {
  const t = useFormatMessage();
  /**
   * Copy calendar url in clipboard
   * @param {*} role
   */
  const handleCopyLink = (role) => () => {
    copyToClipboard(
      `${window.location.href.split('/').splice(0, 4).join('/')}/${role}/keydates`
    );

    sendNotification({
      message: t({ id: 'event.basic.keydates.clipboard' }),
      kind: 'success',
      style: {
        bottom: '7%',
      },
    });
  };

  return (
    <div className={styles.keyDatesContainer}>
      <Typography variant="h6">
        {t({ id: 'keyDates.jobfair-calendar.title' })}
      </Typography>

      <Typography variant="subtitle2" color="grey[500]">
        {t({ id: 'keyDates.jobfair-calendar.description' })}
      </Typography>

      <Row type="flex" align="middle" gutter={80} className={styles.links}>
        <Col>
          <Link
            className={styles.link}
            href={`${window.location.href.split('/').splice(0, 5).join('/')}/keydates`}
            target="_blank"
            rel="noopener"
          >
            <EyeIcon className={styles.linkIcon} /> {t({ id: 'preview' })}
          </Link>
        </Col>

        <Col>
          <Link className={styles.link} onClick={handleCopyLink(CONTEXT_ROLES.RECRUITER)}>
            <CopyIcon className={styles.linkIcon} /> {t({ id: 'keydates.jobfair-calendar.recruiter' })}
          </Link>
        </Col>

        <Col>
          <Link className={styles.link} onClick={handleCopyLink(CONTEXT_ROLES.CANDIDATE)}>
            <CopyIcon className={styles.linkIcon} /> {t({ id: 'keydates.jobfair-calendar.candidate' })}
          </Link>
        </Col>
      </Row>
    </div>
  )
}

TimelineBlock.propTypes = {
  sendNotification: func
}

const mapDispatchToProps = {
  sendNotification: notificationActions.sendNotification,
};

const mapStateToProps = createStructuredSelector({});
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(TimelineBlock);
