import React, { useState } from 'react';
import classnames from 'classnames';
import { Form } from 'antd';
import { object } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import Textarea from '@/components/Textarea';
import styles from './styles.less';

const FormItem = Form.Item;

const TemplateMessage = ({ form, template }) => {
  const t = useFormatMessage();
  const [position, setPosition] = useState(0);

  const setCursorPosition = (e) => {
    const startPosition = e.target.selectionStart;
    setPosition(startPosition);
  };

  const addVariableInArea = (variable) => {
    const val = form.getFieldValue('fr');
    const variableSyntax = `{{${variable}}}`;

    form.setFieldsValue({ fr: `${val.slice(0, position)}${variableSyntax}${val.slice(position, val.length)}` });
    setPosition(position + variableSyntax.length);
  };

  return (
    <FormItem className={classnames(styles.TemplateMessage, 'mb-10')} >
      <div className={styles.textVariable}>
        <span role="button" tabIndex={0} onClick={() => { addVariableInArea('first_name'); }}>firstname</span>
        <span role="button" tabIndex={0} onClick={() => { addVariableInArea('last_name'); }}>lastname</span>
      </div>

      {form.getFieldDecorator('fr', {
        initialValue: template.fr || '',
        rules: [
          { required: true, message: '' },
        ],
      })(
        <Textarea
          onBlur={setCursorPosition}
          label=""
          placeholder={t({ id: 'recruiter.email.template.placeholder' }, { first_name: '{{first_name}}' })}
          type="text"
        />
      )}
    </FormItem>
  );
}

TemplateMessage.propTypes = {
  form: object,
  template: object,
};


export { TemplateMessage };
