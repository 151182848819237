import React, { useState } from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { func, object } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { FieldArray } from 'react-final-form-arrays';
import { FormSpy } from 'react-final-form';
import { Typography } from '@seekube-tech/ui';
import { Alert } from '@seekube-tech/ui-kit';

import ModalTitle from '@/components/ModalTitle';
import FieldsMatching from './components/FieldsMatching';

import styles from './styles.less';

function ModalForm({ offer, onClose, onChangeMatching }) {
  /* Vars */

  const t = useFormatMessage();
  const [visible, setVisible] = useState({ warning: true, error: true });

  /* Functions */

  const handleClose = (alertType) => () => {
    setVisible({
      ...visible,
      [alertType]: false,
    });
  };

  /* Render */

  return (
    <>
      <ModalTitle
        imgSrc={offer?._organization?.profile?.pictureUrl}
        title={t({ id: 'event.candidate.jobdating.offer.applicationModal.title' })}
      />

      <div className={styles.offer}>
        <FormattedMessage id="event.candidate.jobdating.offer.applicationModal.offer" />

        <a role="button" tabIndex={0} onClick={onClose}>
          {offer.title}
        </a>
      </div>

      <div className={styles.subtitle}>
        <Typography variant="body1" component="h3">
          {t({ id: 'event.candidate.jobdating.offer.applicationModal.subtitle' })}
        </Typography>

        <Typography variant="caption1">{t({ id: 'form.helper.multi-required' })}</Typography>
      </div>

      <FieldArray
        name="matching"
        component={FieldsMatching}
        handleChangefield={onChangeMatching}
      />

      <FormSpy
        subscription={{ values: true }}
        render={({ values }) => values.matching.some(item => item.isValid === false) && visible.warning && (
          <Alert
            color="warning"
            className="mt-24"
            onClose={handleClose('warning')}
          >
            {t({ id: 'event.candidate.jobdating.offer.applicationModal.warning' })}
          </Alert>
        )}
      />

      <FormSpy
        subscription={{ submitFailed: true, hasValidationErrors: true }}
        render={({ submitFailed, hasValidationErrors }) => hasValidationErrors && submitFailed && visible.error && (
          <Alert
            color="error"
            className="mt-24"
            onClose={handleClose('error')}
          >
            {t({ id: 'event.candidate.jobdating.offer.applicationModal.error' })}
          </Alert>
        )}
      />
    </>
  );
}

ModalForm.propTypes = {
  offer: object,
  onClose: func.isRequired,
};

ModalForm.defaultProps = {
  offer: {},
};

export default ModalForm;
