import { Button, H3 } from '@seekube-tech/ui-kit';
import { bool, func, object } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import React from 'react';
import Modal from '@/components/Modal';
import { Post } from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Dashboard/components/Posts/Post';
import styles from '@/scenes/Event/scenes/Owner/scenes/Dashboard/components/ModalActivities/styles.less';
import ModalV2 from '@/components/ModalV2';

const PreviewActivity = ({
  isVisible,
  post,
  onClose,
  onUpdate
}) => {
  const t = useFormatMessage();

  if (!isVisible || !post) {
    return null;
  }

  return (
    <ModalV2
      visible
      footer={null}
      onCancel={onClose}
      onClose={onClose}
      className={styles.previewModal}
      width={720}
    >
      <div className={styles.preview}>
        <div className={styles.header}>
          <H3>
            {t({ id: 'activities.preview' })}
          </H3>
          <div className={styles.description}>
            {t({ id: 'activities.preview.description' })}
          </div>
        </div>
        <div className={styles.content}>
          <Post post={post} className={styles.postElement} />
        </div>
        <div className={styles.actions}>
          <Button onClick={onClose} variant='outline' color='neutral'>{t({ id: 'close' })}</Button>
          <Button onClick={() => onUpdate(post)}>{t({ id: 'activities.preview.update' })}</Button>
        </div>
      </div>
    </ModalV2>
  )
}

PreviewActivity.propTypes = {
  isVisible: bool,
  post: object,
  onClose: func,
  onUpdate: func
};

export default PreviewActivity;
