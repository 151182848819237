import React from 'react';
import { node, func } from 'prop-types';

class DropZone extends React.Component {
  static propTypes = {
    children: node,
    handleDragEnter: func,
    handleDragLeave: func,
    handleDrop: func,
  }

  constructor() {
    super();
    this.state = {
      className: 'drop-zone-hide',
    };

    this._onDragEnter = this._onDragEnter.bind(this);
    this._onDragLeave = this._onDragLeave.bind(this);
    this._onDragOver = this._onDragOver.bind(this);
    this._onDrop = this._onDrop.bind(this);
  }

  componentDidMount() {
    document.getElementById('dragcontainer').addEventListener('mouseup', this._onDragLeave);
    document.getElementById('dragcontainer').addEventListener('dragleave', this._onDragLeave);
    document.getElementById('dragcontainer').addEventListener('dragover', this._onDragOver);
    document.getElementById('dragcontainer').addEventListener('dragenter', this._onDragEnter);

    window.addEventListener('drop', this._onDrop);
  }

  componentWillUnmount() {
    const dragbox = document.getElementById('dragbox');
    window.removeEventListener('mouseup', this._onDragLeave);
    window.removeEventListener('dragenter', this._onDragEnter);
    window.addEventListener('dragover', this._onDragOver);

    if (dragbox) {
      dragbox.removeEventListener('dragleave', this._onDragLeave);
    }

    window.removeEventListener('drop', this._onDrop);
  }

  _onDragEnter(e) {
    this.props.handleDragEnter();
    e.stopPropagation();
    e.preventDefault();
    return false;
  }

  _onDragOver(e) {
    e.preventDefault();
    e.stopPropagation();
    return false;
  }

  _onDragLeave(e) {
    this.props.handleDragLeave();
    e.stopPropagation();
    e.preventDefault();
    return false;
  }

  _onDrop(e) {
    e.preventDefault();
    const {files} = e.dataTransfer;
    this.props.handleDrop(files[0]);
    return false;
  }

  render() {
    return (
      <div style={{ position: 'relative' }} id="dragcontainer">
        {this.props.children}
        <div id="dragbox" className={this.state.className}></div>
      </div>
    );
  }
}

export default DropZone;
