import React from 'react';
import { injectIntl } from 'react-intl';
import { array, string, object, func, bool } from 'prop-types';
import { without, isEmpty, flattenDeep, isEqual } from 'lodash';
import moment from 'moment';
import { track } from '@/utils/analytics';
import { Button } from '@seekube-tech/ui-kit';
import { ANALYTICS_RECRUITER } from '@/utils/constants';

// Components
import { Badge, Collapse } from 'antd';
import Checkbox from '@/components/Checkbox';
import Icon from '@/components/Icon';
import Separator from '@/components/Separator';
import Filter from './components/Filter';
import SelectOffer from './components/SelectOffer';

// Styles & Translations
import styles from './styles.less';
import messages from './messages';

const { Panel } = Collapse;

/**
 * EventList
 */
class SearchEngine extends React.PureComponent {
  static propTypes = {
    authUser: object,
    event: object,
    exponent: object,
    facets: object,
    criterion: array,
    offers: array,
    context: string,
    search: object,
    onChange: func,
    onReset: func,
    intl: object,
    facetsSyncing: bool,
  };

  static getDerivedStateFromProps(props, state) {
    if (!isEqual(props.search, state.search)) {
      return { search: props.search };
    }

    return null;
  }

  constructor(props) {
    super(props);

    const { event } = props;
    const beginDate = event.keyDates ? moment(event.keyDates.discovering.beginAt).startOf('month') : moment().startOf('month');

    this.state = {
      search: {
        ...this.props.search,
      },
      resetActiveKey: false,
      activeDates: [
        beginDate.unix(),
        beginDate.subtract(1, 'M').unix(),
        beginDate.subtract(2, 'M').unix(),
      ],
    };
  }

  resetFilters = () => {
    const {
      props: { onReset },
      state: { search },
    } = this;

    this.setState({ search: { page: 1 }, selectedOffer: null }, () => onReset(search));
  };

  resetSelectionFilters = () => {
    const {
      props: { onChange },
      state: { search },
    } = this;

    search.scores = [];
    search.page = 1;

    this.setState({ search });
    onChange(search);
  };

  /**
   * updateFilters
   *
   * @description
   * Update search with selected filters
   */
  updateFilters = (key, selectedItems, facetKey) => {
    const {
      props: { onChange, authUser, criterion, event },
      state: { search },
    } = this;

    search[key] = selectedItems;
    search.page = 1;
    search.facetKey = facetKey;

    const getDate = () => (search.date && ((search.date[0] === '1' || search.date === '1') ? 'Today' : search.date));

    const durationCriterion = criterion.find((item) => item.key === 'DURATION');
    const durations = !isEmpty(durationCriterion) && Array.isArray(search.filters) ? flattenDeep(search.filters.map((id) => durationCriterion._choices.filter((choice) => choice.enable && choice._id.toString() === id.toString()))) : null;
    const duration = !isEmpty(durations) ? durations.map((item) => item.label).join(', ') : null;

    this.setState({ search },
      () => {
        track({
          name: ANALYTICS_RECRUITER.SEARCHED_FOR_CANDIDATES,
          user: authUser,
          event,
          properties: {
            Property: facetKey,
            'Contract Length': durationCriterion && facetKey === 'DURATION' ? duration : null,
            Schools: search.schools,
            Availability: search.date ? moment.unix(getDate()).format('MM/DD/YYYY') : null,
          },
        });
      });
    onChange(search);
  };

  withSuffixIcon = <span style={{ top: '11px', right: '18px', position: 'absolute', zIndex: 1, fill: '#385077' }}><Icon name="chevron" style={{ width: '24px', height: '24px' }} /></span>;

  handleToggleByKey = (key, value) => {
    const {
      props: { onChange },
      state: { search },
    } = this;

    if (!Array.isArray(search[key])) {
      if (search[key]) {
        if (search[key] === value) {
          search[key] = [];
        } else {
          search[key] = [search[key], value];
        }
      } else {
        search[key] = [value];
      }
    } else if (search[key].indexOf(value) > -1) {
      search[key].splice(search[key].indexOf(value), 1);
    } else {
      search[key].push(value);
    }

    search.page = 1;

    this.setState({ search });
    onChange(search);
  }

  handleSetMatching = (offer) => {
    const { props: { onChange, event } } = this;

    if (!offer) {
      this.resetFilters();

      return false;
    }

    const { matching } = offer;

    let durationsIds = [];
    const durationCriterion = event._criteria.find((c) => c.key === 'DURATION');

    if (durationCriterion) {
      durationsIds = durationCriterion._choices.filter((c) => c.enable).map((c) => c._id.toString());

      matching.filters = without(offer.matching.filters, ...durationsIds);
    }

    matching.page = 1;
    matching.facetKey = null;
    delete matching._id;
    delete matching.date;

    if (!matching.experiences) {
      matching.experiences = -1;
    }

    this.setState({ search: matching, selectedOffer: offer, resetActiveKey: true });
    onChange(matching);

    return true;
  };

  /**
   * renderOfferFilter
   */
  renderOfferFilter = () => {
    const {
      props: { offers, authUser, facets, facetsSyncing, intl },
      state: { search },
      updateFilters,
    } = this;

    let selectedItems = [];

    if (typeof search.offers === 'string') {
      selectedItems = [search.offers];
    } else if (typeof search.offers === 'object') {
      selectedItems = search.offers;
    }

    return (
      <div>
        <div className={styles.filterTitle}>
          <strong>{intl.formatMessage(messages.applyTo)}</strong>
        </div>
        <Filter
          offers={offers}
          stats={facets ? facets.applyOffers : null}
          authUser={authUser}
          facetsSyncing={facetsSyncing}
          selectedItems={selectedItems}
          searchKey="offers"
          facetKey="offers"
          onChange={updateFilters}
        />
      </div>
    );
  }

  /**
   * renderCriterionFilters
   *
   */
  renderCriterionFilters = () => {
    const {
      state: { search, selectedOffer },
      props: { criterion, context, exponent, offers, facets, authUser, event, intl, facetsSyncing },
      updateFilters, resetFilters, handleSetMatching,
    } = this;

    let selectedItems = [];
    let schoolItems = [];

    if (typeof search.filters === 'string') {
      selectedItems = [search.filters];
    } else if (typeof search.filters === 'object') {
      selectedItems = search.filters;
    }

    if (typeof search.schools === 'string') {
      schoolItems = [search.schools];
    } else if (typeof search.schools === 'object') {
      schoolItems = search.schools;
    }

    const offerFilter = context === 'sourcing' && !isEmpty(offers) ? (
      <div>
        <div className={styles.filterTitle}>
          <strong>{intl.formatMessage(messages.sourcingFor)}</strong>
        </div>
        <SelectOffer
          authUser={authUser}
          event={event}
          suffixIcon={<Icon name="chevron" />}
          offers={offers}
          stats={facets ? facets['interactions._offers'] : ''}
          onChange={handleSetMatching}
          defaultValue={selectedOffer}
        />
      </div>) : '';

    const schoolFilter = event.type !== 'school' && (
      <Filter
        criterion={criterion}
        index={0}
        stats={facets ? facets['_user.educations._organization.name'] : null}
        selectedItems={schoolItems}
        onChange={updateFilters}
        searchKey="schools"
        facetsSyncing={facetsSyncing}
        facetKey="schools"
        key="schools"
        authUser={authUser}
      />
    );

    let customIndex = 0;

    const criteria = [...criterion];

    const criterionFilters = criterion
      .sort((a, b) => a.modules.searchEngineExponent.position - b.modules.searchEngineExponent.position)
      .map((criterion, index) => {
        if (isEmpty(criterion.key)) {
          customIndex = criteria.filter((c) => isEmpty(c.key)).findIndex((c) => criterion._id.toString() === c._id.toString());
        }

        if (criterion.enable && criterion.modules.searchEngineExponent.isVisible) {
          let searchKey = 'filters';
          let stats = !isEmpty(criterion.key) ? facets[`matching.${criterion.key}`] : facets[`matching.custom${customIndex}`];
          let facetKey = `custom${customIndex}`;
          let defaultSelectedItems = [];

          if (criterion.key === 'AVAILABILITY') {
            searchKey = 'date';
            stats = facets['matching.dateStr'];

            if (typeof search.date === 'string') {
              defaultSelectedItems = [search.date];
            } else if (typeof search.date === 'object') {
              defaultSelectedItems = search.date;
            }
          } else if (criterion.key === 'LICENSE') {
            searchKey = 'license';
            stats = facets['matching.license'];

            if (typeof search.license === 'string') {
              defaultSelectedItems = [search.license];
            } else if (typeof search.license === 'object') {
              defaultSelectedItems = search.license;
            }
          } else if (criterion.key === 'EXPERIENCE') {
            if (typeof search.experiences === 'string') {
              defaultSelectedItems = [search.experiences];
            } else if (typeof search.experiences === 'object') {
              defaultSelectedItems = search.experiences;
            }

            searchKey = 'experiences';
            stats = facets['matching.experiences'];
          } else if (criterion.key === 'LEVEL') {
            searchKey = 'level';
            stats = facets['matching.level'];

            if (typeof search.level === 'string') {
              defaultSelectedItems = [search.level];
            } else if (typeof search.level === 'object') {
              defaultSelectedItems = search.level;
            }
          }

          if (!isEmpty(criterion.key)) {
            facetKey = criterion.key;
          }

          return (
            <Filter
              criterion={criterion}
              index={index}
              selectedItems={criterion.key === 'LEVEL' || criterion.key === 'AVAILABILITY' || criterion.key === 'LICENSE' || criterion.key === 'EXPERIENCE' ? defaultSelectedItems : selectedItems}
              onChange={updateFilters}
              searchKey={searchKey}
              facetKey={facetKey}
              facetsSyncing={facetsSyncing}
              stats={stats}
              key={criterion._id.toString()}
              authUser={authUser}
            />
          );
        }

        return null;
      });

    let hybridFilter = null;

    if (event.format === 'hybrid' && (exponent?.keyMomentFormats?.length > 1 || context.indexOf('admin') > -1)) {
      hybridFilter = this.renderHybridFilters()
    }

    return !isEmpty(hybridFilter) || !isEmpty(offerFilter) || !isEmpty(criterionFilters) || !isEmpty(facets['_user.educations._organization.name']) ? (
      <div>
        { offerFilter }
        <div className={styles.filterTitle}>
          <strong>{intl.formatMessage(messages.moreFilters)}</strong>
          <Button variant="text" size="small" onClick={resetFilters}>{intl.formatMessage(messages.reset)}</Button>
        </div>
        <div className="collapse-group">
          { hybridFilter }
          { criterionFilters }
          { schoolFilter }
        </div>
        <Separator height={30} />
      </div>
    ) : null;
  }

  /**
   * renderSelectionFilters
   */
  renderAdminFilters = () => {
    const {
      props: { intl, facets },
      state: { search },
      resetSelectionFilters, handleToggleByKey,
    } = this;

    let statusLength = 0;
    let adminStatusLength = 0;

    if (typeof search.appointmentsStatusAdmin === 'string') {
      statusLength = 1;
    } else if (search.appointmentsStatusAdmin) {
      statusLength = search.appointmentsStatusAdmin.length;
    }

    if (typeof search.adminStatus === 'string') {
      adminStatusLength = 1;
    } else if (search.adminStatus) {
      adminStatusLength = search.adminStatus.length;
    }

    const headerappointmentsStatus = (
      <div className={styles.header}>{intl.formatMessage(messages.appointmentAdminStatus)} <Badge count={statusLength} overflowCount={999} />{this.withSuffixIcon}</div>
    );

    const headerAdminStatus = (
      <div className={styles.header}>{intl.formatMessage(messages.adminStatus)} <Badge count={adminStatusLength} overflowCount={999} />{this.withSuffixIcon}</div>
    );

    return (
      <div className={styles.searchEngineContainer}>
        <div className={styles.filterTitle}>
          <strong>{intl.formatMessage(messages.filtersAdmin)}</strong>
          <Button variant="text" size="small" onClick={resetSelectionFilters}>{intl.formatMessage(messages.reset)}</Button>
        </div>
        <Collapse defaultActiveKey={['1', '2', '3']}>
          <Panel header={headerappointmentsStatus} key="1" showArrow={false}>
            <div className={styles.searchItemsList}>
              <ul>
                <li key="acceptedStatusFilter">
                  <label htmlFor="acceptedStatusFilter">
                    <Checkbox
                      id="acceptedStatusFilter"
                      type="checkbox"
                      value="accepted"
                      checked={search.appointmentsStatusAdmin ? search.appointmentsStatusAdmin.includes('accepted') : false}
                      onClick={() => handleToggleByKey('appointmentsStatusAdmin', 'accepted')}
                    />
                    <span className={styles.label}>{intl.formatMessage(messages.accepted)} </span> {facets && facets.withAppointmentsAccepted && facets.withAppointmentsAccepted.true ? (<Badge count={facets.withAppointmentsAccepted.true} overflowCount={999} />) : ''}
                  </label>
                </li>
                <li key="pendingStatusFilter">
                  <label htmlFor="pendingStatusFilter">
                    <Checkbox
                      id="pendingStatusFilter"
                      type="checkbox"
                      checked={search.appointmentsStatusAdmin ? search.appointmentsStatusAdmin.includes('pending') : false}
                      onClick={() => handleToggleByKey('appointmentsStatusAdmin', 'pending')}
                    />
                    <span className={styles.label}>{intl.formatMessage(messages.pending)}</span> {facets && facets.withAppointmentsPending && facets.withAppointmentsPending.true ? (<Badge count={facets.withAppointmentsPending.true} overflowCount={999} />) : ''}
                  </label>
                </li>
              </ul>
            </div>
          </Panel>
          <Panel header={headerAdminStatus} key="2" showArrow={false}>
            <div className={styles.searchItemsList}>
              <ul>
                <li key="topStatusFilter">
                  <label htmlFor="topStatusFilter">
                    <Checkbox
                      id="topStatusFilter"
                      type="checkbox"
                      value="accepted"
                      checked={search.adminStatus ? search.adminStatus.includes('top') : false}
                      onClick={() => handleToggleByKey('adminStatus', 'top')}

                    />
                    <span className={styles.label}>{intl.formatMessage(messages.adminStatusTop)}</span> {facets && facets.isTop && facets.isTop.true ? (<Badge count={facets.isTop.true} overflowCount={999} />) : ''}
                  </label>
                </li>
                <li key="saveStatusFilter">
                  <label htmlFor="saveStatusFilter">
                    <Checkbox
                      id="saveStatusFilter"
                      type="checkbox"
                      checked={search.adminStatus ? search.adminStatus.includes('save') : false}
                      onClick={() => handleToggleByKey('adminStatus', 'save')}
                    />
                    <span className={styles.label}>{intl.formatMessage(messages.adminStatusSave)}</span> {facets && facets.isSave && facets.isSave.true ? (<Badge count={facets.isSave.true} overflowCount={999} />) : ''}
                  </label>
                </li>
              </ul>
            </div>
          </Panel>
        </Collapse>
        <Separator height={30} />
      </div>
    );
  }

  renderHybridFilters = () => {
    const {
      props: { intl, facets },
      state: { search },
      handleToggleByKey
    } = this;

    let formatsLength = 0;

    if (typeof search.hybridFormats === 'string') {
      formatsLength = 1;
    } else if (search.hybridFormats) {
      formatsLength = search.hybridFormats.length;
    }

    const headerHybrid = (
      <div className={styles.header}>{intl.formatMessage({ id: 'searchEngine.hybridFormat' })} <Badge count={formatsLength} overflowCount={999} />{this.withSuffixIcon}</div>
    );

    return (
        <Collapse defaultActiveKey={['1', '2', '3']}>
          <Panel header={headerHybrid} key="1" showArrow={false}>
            <div className={styles.searchItemsList}>
              <ul>
                {
                  ['virtual', 'physical'].map((format) => (
                    <li key={`${format}HybridFormat`}>
                      <label htmlFor={`${format}HybridFormat`}>
                        <Checkbox
                          id={`${format}HybridFormatFilter`}
                          type="checkbox"
                          value={format}
                          checked={search.hybridFormats?.includes(format) || false}
                          onClick={() => handleToggleByKey('hybridFormats', format)}
                        />
                        <span className={styles.label}>{intl.formatMessage({ id: `searchEngine.hybridFormatChoices.${format}` })} </span> {facets?.keyMomentFormats && facets?.keyMomentFormats[format]? (<Badge count={facets.keyMomentFormats[format]} overflowCount={999} />) : null}
                      </label>
                    </li>
                  ))
                }
              </ul>
            </div>
          </Panel>
        </Collapse>
    );
  }

  /**
   * Render
   */
  render() {
    const {
      props: { context, criterion, authUser, facets },
      renderOfferFilter, renderCriterionFilters, renderAdminFilters,
    } = this;

    if (facets === undefined) {
      return null;
    }

    const contextAdmin = context.indexOf('admin') > -1;

    return (
      <div className={styles.searchEngine}>
        {!contextAdmin && context !== 'sourcing' ? renderOfferFilter() : null}
        {criterion ? renderCriterionFilters() : null}
        {authUser.isAdmin && contextAdmin ? renderAdminFilters() : null}
      </div>
    );
  }
}

export default injectIntl(SearchEngine);

