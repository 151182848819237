import React, { useState, useEffect } from 'react';
import { array, func, number, object } from 'prop-types';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import getEndPublishingOffersOnEventParams from '@/helpers/action/recruiter/endPublishingOffersOnEvent';
import { toJS } from '@/utils';

import LoadingIndicator from '@/components/LoadingIndicator';

import { offerActions } from '@/store/offer';

import { actionActions } from '@/store/action';
import { authSelectors } from '@/store/auth';
import { useFormatMessage } from 'react-intl-hooks';
import styles from './styles.less';

function Step4({
  offers,
  offersSelectedSize,
  postPublishOffers,
  postAction,
  authUser,
}) {
  const [requestStatus, setRequestStatus] = useState('loading'); // oneOf loading, success, error
  const eventId = window.localStorage.getItem('eventSelected');
  const t = useFormatMessage();

  useEffect(() => {
    if (!isEmpty(offers)) {
      postPublishOffers({
        offers,
        eventId,
        callback: () => {
          setRequestStatus('success');
          postAction(
            getEndPublishingOffersOnEventParams({
              authUser,
              _event: eventId,
              countOffers: offersSelectedSize,
            })
          );
        },
        notificationParams: {
          success: {
            message: t({ id: 'notifications.update.success' }),
            kind: 'info',
            style: {
              bottom: '5%',
              top: 'inherit',
            },
          },
        }
      });
    }
  }, []);

  return (
    <div className={styles.step4Container}>
      {
        requestStatus === 'loading' &&
        <div>
          <FormattedMessage id="recruiter.offers.spread.publishing.title" values={{ count: offersSelectedSize }} tagName="h1" />
          <LoadingIndicator />
          <div className={styles.fetchingMessage}>
            <FormattedHTMLMessage id="recruiter.offers.spread.publishing.loader.message" />
          </div>
        </div>
      }
      {
        requestStatus === 'success' &&
        <div>
          <div className={styles.fetchingMessage}>
            <span role="img" aria-label="yeah" style={{ fontSize: '44px' }}>🎉</span>
            <FormattedMessage id="recruiter.offers.spread.publishing.loader.success" values={{ count: offersSelectedSize }} tagName="h1" />
          </div>
        </div>
      }
    </div>
  );
}

Step4.propTypes = {
  offers: array,
  offersSelectedSize: number,
  postPublishOffers: func,
  postAction: func,
  authUser: object,
};

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser,
});

const withConnect = connect(mapStateToProps, {
  postPublishOffers: offerActions.postPublishOffers,
  postAction: actionActions.postAction,
});

export default compose(withConnect)(toJS(Step4));
