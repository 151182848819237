import React from 'react';
import { FormattedMessage } from 'react-intl';
import { object, string } from 'prop-types';
import { get } from 'lodash';
import styles from './styles.less';
import messages from './messages';


const ColleagueInCharge = ({ appointment = {}, context }) =>
  (
    context === 'interview' ?
      <div className={styles.sourceBy}>{get(appointment, '_organizationUser.shortName')}&nbsp; <FormattedMessage {...messages.inCharge} /></div>
      : <div className={styles.sourceBy}><FormattedMessage {...messages.activityWithColleague} /></div>
  );

ColleagueInCharge.propTypes = {
  appointment: object,
  context: string,
};
export { ColleagueInCharge };

