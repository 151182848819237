import * as React from 'react';
import { IconMapPin, IconWifi } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import classnames from 'classnames';
import { oneOf, string } from 'prop-types';
import styles from './styles';

function HybridTag({ name }) {
  const t = useFormatMessage();

  switch (name) {
    case 'physical':
      return (
        <span className={classnames(styles.tag)}>
          <IconMapPin />
          {t({ id: "event.owner.settings.keyMoments.input.tags.physical" })}
        </span>
      )
    case 'virtual':
      return (
        <span className={classnames(styles.tag)}>
          <IconWifi />
          {t({ id: "event.owner.settings.keyMoments.input.tags.virtual" })}
        </span>
      )
    default:
      return false;
  }
}

HybridTag.propTypes = {
  name: oneOf(['virtual', 'physical'])
}

export default HybridTag
