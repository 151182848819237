import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'event.basic.title',
  },
  modulesTitle: {
    id: 'event.basic.modules.title',
  },
  keyDatesTitle: {
    id: 'event.basic.keydates.title',
  },
  skipAppointmentValidation: {
    id: 'event.basic.skipAppointmentValidation.title',
  },
  generalInfosTitle: {
    id: 'event.basic.generalInfos.title',
  },
  inputNameLabel: {
    id: 'event.basic.input.name.label',
  },
  inputNamePlaceholder: {
    id: 'event.basic.input.name.placeholer',
  },
  inputNameRequired: {
    id: 'event.basic.input.name.required',
  },
  inputLanguageLabel: {
    id: 'event.basic.input.language.label',
  },
  inputLocalizationLabel: {
    id: 'event.basic.input.localization.label',
  },
  inputOrganizationsLabel: {
    id: 'event.basic.input.organizations.label',
  },
  inputOrganizationsRequired: {
    id: 'event.basic.input.organizations.required',
  },
  inputModulesLabel: {
    id: 'event.basic.input.modules.label',
  },
  inputKeyDatesRequired: {
    id: 'event.basic.input.keydates.required',
  },
  inputKeyDatesDiscoveringLabel: {
    id: 'event.basic.input.keydates.discovering.label',
  },
  inputKeyDatesInteractionsClosingLabel: {
    id: 'event.basic.input.keydates.interactionsClosing.label',
  },
  inputKeyDatesInteractionsClosingHelp: {
    id: 'event.basic.input.keydates.interactionsClosing.help',
  },
  inputKeyDatesJobdatingLabel: {
    id: 'event.basic.input.keydates.jobdating.label',
  },
  inputKeyDatesJobdatingHelp: {
    id: 'event.basic.input.keydates.jobdating.help',
  },
  inputKeyDatesHuntLabel: {
    id: 'event.basic.input.keydates.hunt.label',
  },
  inputKeyDatesLiveLabel: {
    id: 'event.basic.input.keydates.live.label',
  },
  selectJobdatingTimezoneLabel: {
    id: 'event.basic.select.timezone.label',
  },
  warningInteractionsClosingIsBeforeHunt: {
    id: 'event.basic.input.keydates.interactionsClosing.warning',
  },
  targetsLabel: {
    id: 'event.basic.targets.label',
  },
  targetsEngineer: {
    id: 'engineer.short',
  },
  targetsTech: {
    id: 'technical.short',
  },
  targetsBusiness: {
    id: 'business',
  },
  targetsOther: {
    id: 'other',
  },
  informationLocation: {
    id: 'event.recruiter.preparation.timeslots.location.label',
  },
  jobDatingTypeLabel: {
    id: 'event.basic.type.label',
  },
  jobDatingTypeSchool: {
    id: 'event.basic.type.school',
  },
  jobDatingTypeCompany: {
    id: 'event.basic.type.company',
  },
  errorFieldRequired: {
    id: 'form.error.required',
  },
  whatIsItFor: {
    id: 'what.is.it.for',
  },
  whatIsItForContent: {
    id: 'event.basic.syncAgendaExponents.whatIsItFor.content',
  },
  syncAgendaExponentsTitle: {
    id: 'event.basic.syncAgendaExponents.title',
  },
  yes: {
    id: 'yes',
  },
  no: {
    id: 'no',
  },
  keydatesOk: {
    id: 'event.basic.radio.keydatesOk.yes',
  },
  keydatesNotOk: {
    id: 'event.basic.radio.keydatesOk.no',
  },
});
