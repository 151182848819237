import { useMutation } from 'react-query';
import request from '@/utils/request';

export function useCreateConference(
  {
    onSuccess = () => {},
  }) {
  const mutationFn = (body) => {
    const requestUrl = `${process.env.FRONT_API_URL}/conferences`;

    return (
      request(requestUrl, {
        method: 'POST',
        body: JSON.stringify(body),
      })
    )
  }

  return useMutation({
    mutationFn,
    onSuccess: async () => {
      onSuccess();
    },
  });
}
