import React, { useState } from 'react';
import { Form } from 'antd';
import { get } from 'lodash';

import { Button, H4 } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { Bloc, BlocHeader } from '@/components/Bloc';
import { SpecificityOffer } from './components';


const OffersGoals = Form.create()(
  ({ event, form, onSubmit }) => {
    const t = useFormatMessage();

    const [descriptionCandidatesTarget, setDescriptionCandidatesTarget] = useState(get(event, 'descriptionCandidatesTarget'))

    const handleSubmit = (e) => {
      e.preventDefault();

      return form.validateFields((err, valus) => {
        const values = { ...valus, descriptionCandidatesTarget };
        onSubmit(err, values);
      });
    };

    return (
      <Bloc>
        <BlocHeader>
          <H4 className="mb-20">{t({ id: 'event.owner.setting.goals.title' }, { count: 2 })}</H4>
        </BlocHeader>

        <Form onSubmit={handleSubmit} style={{ minWidth: '350px', textAlign: 'left', marginBottom: '30px' }} layout="vertical">
          <SpecificityOffer
            initialValue={get(event, 'descriptionCandidatesTarget')}
            onChange={setDescriptionCandidatesTarget}
            form={{ getFieldDecorator: form.getFieldDecorator, setFieldsValue: form.setFieldsValue }}
          />
          <Button type="submit">{t({ id: 'save' })}</Button>
        </Form>
      </Bloc>
    );
  });

export { OffersGoals };
