import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { string, any, bool, func, oneOf } from 'prop-types';
import { get } from 'lodash';

import classnames from 'classnames';

import { Tooltip } from '@/components/Tooltip';
import { If } from '@/components/If';
import { Buttons } from './components/Buttons';

import styles from './styles.less';
import messages from './messages';

import Icon from '../Icon';

const ParticipantScoring = ({
  withInfo = true,
  tooltipTitle = <FormattedMessage {...messages.info} />,
  appointment = null,
  className = null,
  context = null,
  onScore,
}) => {
  const [scoreSubmitted, setScoreSubmitted] = useState(get(appointment, 'score'));

  useEffect(() => {
    setScoreSubmitted(get(appointment, 'score'));
  }, [get(appointment, 'score')]);

  const handleOnClick = (score, withMessage = false) => {
    if (score !== scoreSubmitted) {
      onScore(score, appointment, withMessage);
      if (!withMessage) { setScoreSubmitted(score); }
    }
  };

  return (
    <div className={classnames(styles.scoringContainer, className, context === 'bigParticipantCard' ? styles.siderContainer : null)}>
      <div className={styles.title}>
        <FormattedMessage {...messages.title} tagName="h4" />
        <If condition={withInfo}>
          <div className={styles.info}>
            <Tooltip title={tooltipTitle} placement="bottom" color="dark">
              <span><Icon name="information" /></span>
            </Tooltip>
          </div>
        </If>
      </div>
      <Buttons scoreSubmitted={scoreSubmitted} onClick={handleOnClick} context={context} />
    </div>
  );
};


ParticipantScoring.propTypes = {
  _id: string,
  appointment: string,
  onScore: func,
  className: any,
  tooltipTitle: any,
  withInfo: bool,
  context: oneOf(['bigParticipantCard', 'smallParticipantCard', null]),
};


export default ParticipantScoring;
