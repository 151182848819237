import { createSelector } from 'reselect';
import { getCurrentEvent } from '@/store/event/selectors';
import { getAuthUser } from '../auth/selectors';

import { getEventPhases, isPhaseInState } from './utils/event';

import recruiterProposeAppointment from './recruiter/appointment/propose';
import deactivateEventTab from './event/params/deactivateTab';
import updateEventType from './event/params/updateType';
import deleteEvent from './event/delete';
import liveNav from './event/liveNav';
import eventsListCta from './event/eventsListCta';
import eventLandingCta from './event/eventLandingCta';
import bookAppointment from './candidate/appointment/bookAppointment';
import choosingTimeslot from './candidate/appointment/choosingTimeslot'
import candidateModifyAppointment from './candidate/appointment/modifyAppointment';
import dashboardView from './candidate/appointment/dashboardView';
import choosingTimeslotAfterRecruiterAction from './candidate/appointment/choosingTimeslotAfterRecruiterAction';
import confirmAppointment from './recruiter/appointment/confirmAppointment'
import refuseApplication from './recruiter/appointment/refuseApplication'
import refuseAppointmentBooked from './recruiter/appointment/refuseAppointmentBooked'
import acceptApplication from './recruiter/appointment/acceptApplication';
import proposeAppointment from './recruiter/appointment/propose';

const AVAILABLE_ACTIONS = {
  recruiterProposeAppointment,
  deactivateEventTab,
  updateEventType,
  deleteEvent,
  liveNav,
  eventsListCta,
  eventLandingCta,
  bookAppointment,
  choosingTimeslot,
  candidateModifyAppointment,
  dashboardView,
  choosingTimeslotAfterRecruiterAction,
  confirmAppointment,
  refuseApplication,
  refuseAppointmentBooked,
  acceptApplication,
  proposeAppointment,
}

/**
 * Each key defines an action.
 * In each action, the object defines the condition for each criteria.
 * Each condition is as follows :
 * - The first array defines the OR conditions
 * - The second (inner) array defines the AND conditions
 */

export function filterAvailableActions(userActions, eventPhases) {
  return Object.keys(userActions).filter((action) => (
    AVAILABLE_ACTIONS[action] && AVAILABLE_ACTIONS[action].isAvailable.reduce((previous, andArray) => {
      if (previous) {
        return previous;
      }

      return previous || andArray.reduce((previousAnd, andCondition) => {
        if (!previousAnd) {
          return false;
        }

        /**
         * We have to check if event phases has the phase selected because sometimes it doesn't have any keyDates
         */
        return previousAnd && isPhaseInState(eventPhases, andCondition.phase, andCondition.state, andCondition.format)
      }, true)
    }, false)
  ))
}

export function debugPhaseMatrix() {
  const actions = Object.keys(AVAILABLE_ACTIONS).map(action => (
    `${action}:\n${AVAILABLE_ACTIONS[action].isAvailable.map(orCondition => (
      `\t- (${orCondition.map(andCondition => (
        `${andCondition.phase} is in state ${andCondition.state} ${andCondition.format && `for ${andCondition.format} format`}`
      )).join(' AND ')
      })`)).join(',\nOR :')
    }\n`
  ))

  console.info(actions.join('\n'));
}

/**
 *
 * Retrieve all actions from the
 */
function computeAvailableActions(actionParams) {
  const currentEvent = actionParams?.currentEvent?.toJS();
  const eventPhases = getEventPhases(currentEvent);
  const filteredActions = filterAvailableActions(AVAILABLE_ACTIONS, eventPhases);

  const userActions = {};

  filteredActions.forEach(key => {
    userActions[key] = {
      ...AVAILABLE_ACTIONS[key].generateActions(actionParams)
    }
  });
  return userActions;
}

export const getAvailableActions = createSelector(
  getCurrentEvent,
  getAuthUser,
  (currentEvent, authUser) => computeAvailableActions({ currentEvent, authUser })
)
