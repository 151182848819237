import { defineMessages } from 'react-intl';

export default defineMessages({
  appointmentSectionTitle: {
    id: 'event.candidate.jobdating.offer.appointmentSection.title',
  },
  apply: {
    id: 'event.candidate.jobdating.offer.apply',
  },
  back: {
    id: 'event.candidate.jobdating.offer.back',
  },
  chooseSlot: {
    id: 'event.candidate.appointments.card.chooseSlot',
  },
  resumeUrl: {
    id: 'event.candidate.jobdating.offer.resumeUrl',
  },
  appointmentWith: {
    id: 'event.candidate.jobdating.offer.appointmentWith',
  },
  appointmentRefused: {
    id: 'event.candidate.jobdating.offer.refused',
  },
  appointmentWithLight: {
    id: 'event.candidate.appointment.mainContent.titleRecruiter',
  },
  aboutOf: {
    id: 'aboutOf',
  },
  pending: {
    id: 'pending',
  },
  noSlot: {
    id: 'noSlot',
  },
  takeAppointment: {
    id: 'event.candidate.jobdating.offer.takeAppointment',
  },
  publishOffer: {
    id: 'publish.offer',
  },
  saveDraft: {
    id: 'save.draft',
  },
  backEdit: {
    id: 'offer.edit.back',
  },
  phone: {
    id: 'appointment.medium.phone',
  },
  visio: {
    id: 'visio',
  },
  cancelApplicationModal: {
    id: 'event.candidate.jobdating.application.cancel.modal',
  },
  cancelModal: {
    id: 'cancel',
  },
  btnConfirmModal: {
    id: 'btn.confirm',
  },
  cancelAppointment: {
    id: 'cancel.appointment',
  },
  waitingListTitle: {
    id: 'event.candidate.jobdating.application.waitingList.title',
  },
  waitingListCta: {
    id: 'event.candidate.jobdating.application.waitingList.cta',
  },
  waitingListOkTitle: {
    id: 'event.candidate.jobdating.application.waitingList.okTitle',
  },
  waitingListOkTag: {
    id: 'event.candidate.jobdating.application.waitingList.okTag',
  },
  confirmationLightText: {
    id: 'event.candidate.jobdating.jobs.confirmationLight.text',
  },
});
