import React from 'react';
import { object } from 'prop-types';
import { BlockPicker } from 'react-color';
import { Popover } from 'antd';

import styles from './styles.less';

const DEFAULT_COLORS = [
  '#FF7A00',
  '#20D980',
  '#8E00E5',
  '#47D3FF',
  '#F43DCC',
  '#F2CB05',
  '#BF6F41',
  '#0069FF',
  '#FC444A',
  '#009C84',
];

function FieldColor({ input: { value, onChange, onFocus, onBlur } }) {
  /* Functions */

  /**
   * Update corner color
   * @param {String} color
   */
  const handleOnChange = (color) => {
    onChange(color.hex);
  };

  /**
   * Call when popover visibily change
   * @description Set onFucus and onBlur event when is open and closed
   * @param {Boolean} isVisible
   */
  const handleVisibleChange = (isVisible) => {
    if (isVisible) {
      onFocus();
    } else {
      onBlur();
    }
  };

  /* Render */

  return (
    <Popover
      content={(
        <BlockPicker
          color={value}
          onChangeComplete={handleOnChange}
          colors={DEFAULT_COLORS}
        />
      )}
      trigger="click"
      placement="bottom"
      onVisibleChange={handleVisibleChange}
    >
      <div className={styles.preview} style={{ backgroundColor: value }} />
    </Popover>
  );
}

FieldColor.propTypes = {
  input: object.isRequired,
};

export default FieldColor;
