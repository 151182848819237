import { defineMessages } from 'react-intl';

export default defineMessages({
  passwordErrorDifferent: {
    id: 'event.recruiter.preparation.password.form.error',
  },
  informationSuccess: {
    id: 'form.information.success',
  },
  passwordMandatory: {
    id: 'profile.settings.password.mandatory',
  },
  passwordTitle: {
    id: 'profile.settings.password.title',
  },
  passwordChange: {
    id: 'profile.settings.password.change.title',
  },
  currentPassword: {
    id: 'profile.settings.currentPassword',
  },
  newPassword: {
    id: 'profile.settings.newPassword.label',
  },
  confirmPasswordLabel: {
    id: 'profile.settings.password.mandatory',
  },
  placeholderText: {
    id: 'profile.settings.newPassword.placeholder',
  },
  mandatoryField: {
    id: 'form.error.required',
  },
  save: {
    id: 'save',
  },
  availableToday: {
    id: 'available.today',
  },
  passwordFieldError: {
    id: 'form.password.error',
  },
});
