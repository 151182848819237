import { useMutation, useQueryClient } from 'react-query';
import request from '@/utils/request';
import { map } from 'lodash';

export function useSendReport(
  {
    eventId,
  }) {
  const queryClient = useQueryClient();

  const mutationFn = ({ _id }) => {
    const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/reports/${_id}/send`;

    return (
      request(requestUrl, {
        method: 'POST',
      })
    )
  }

  return useMutation({
    mutationFn,
    onSuccess: async (report) => {
      const reports = map(queryClient.getQueryData('reports'),
        (prevReport) => prevReport._id === report._id ? report : prevReport);

      queryClient.setQueryData('reports', () => reports);
    },
  });
}
