import * as React from 'react';
import PropTypes from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { Form } from 'antd';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Typography } from '@seekube-tech/ui';
import Select from '@/components/Form/Select';
import { getTimezoneOptions } from '@/utils/format';
import { eventSelectors } from '@/store/event';
import { toJS } from '@/utils';

const FormItem = Form.Item;

function TimezoneSelector({ form, event }) {
  const { getFieldDecorator } = form;
  const t = useFormatMessage();

  return <>
    <h3>{t({ id: 'event.basic.select.timezone.label' })} <span className="mandatory-symbol"> * </span></h3>
    <Typography variant="body2" color="neutral.400">
      {t({ id: 'event.basic.select.timezone.subLabel' })}
    </Typography>
    <FormItem style={{ marginTop: '16px' }}>
      {getFieldDecorator('timezone', {
        initialValue: event.timezone.text,
        rules: [{ required: true, message: t({ id: 'form.error.required' }) }],
      })(
        <Select
          size="medium"
          showSearch
          label={t({ id: 'profile.settings.timezone.label' })}
        >
          {getTimezoneOptions()}
        </Select>
      )}
    </FormItem></>
}


TimezoneSelector.propTypes = {
  form: PropTypes.func,
  event: PropTypes.object
}

const mapDispatchToProps = {};

const mapStateToProps = createStructuredSelector({
  event: eventSelectors.getCurrentEvent
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(toJS(TimezoneSelector));
