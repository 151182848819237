import uuidv1 from 'uuid/v1';
import mime from 'mime-types';

/**
 * @description Generate a random filename for upload
 * @param {File} file
 * @returns {File}
 */
export default function generateFileName(file) {
  // need to rename file with random name provided by uuid

  if (!file) {
    throw new Error('Invalid File');
  }

  const ext = file.name ? file.name.substr(file.name.lastIndexOf('.') + 1) : mime.extension(file.type);

  const fileName = `${uuidv1()}.${ext}`;

  try {
    return new File([file], fileName, { type: file.type });
  } catch (err) {
    return file;
  }
}
