import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { any, number, array, bool, oneOfType, object, func } from 'prop-types';
import { List } from 'antd';
import { isEmpty, get, isFunction } from 'lodash';
import { Button, H5 } from '@seekube-tech/ui-kit';
import classnames from 'classnames';
import Icon from '@/components/Icon';
import ModalV2 from '@/components/ModalV2';


import messages from './messages';
import styles from './styles.less';
import LoadingIndicator from '../../LoadingIndicator';

const StatBloc = (props) => {
  const [visible, setVisible] = useState(false);

  return (
    <div className={classnames(styles.container, props.classContainer, props.withFunnel && styles.funnel)}>
      {
        props.loading ? <LoadingIndicator className={styles.center} /> : (
          <>
            <div className="flex justify-content mb-10 justify-between">
              <div>
                <h2>{props.title}</h2>
                { !props.noCount && <h3> {props.count}</h3>}
              </div>
              <div>
                {
                  !isEmpty(props.separator) && props.separator
                }
              </div>
            </div>
            {props.withList && (
              isEmpty(props.dataSource) ?
                <div className={styles.emptyText}>
                  <span><Icon style={{ width: '50px', height: '50px' }} name="bilan" /></span>
                  <FormattedMessage {...messages.noResult} />
                </div>
                : (
                  <List
                    locale={{ emptyText: '' }}
                    itemLayout="horizontal"
                    dataSource={props.dataSource}
                    renderItem={(data) => (<List.Item>
                      <div className={styles.item}>{data.item}</div>
                    </List.Item>)}
                  />
                ))
            }
            <div className={styles.description}>{props.description}</div>
            {
              props.withAction && (
                <div className={styles.loadMore}>
                  <Button variant="outline" onClick={() => setVisible(!visible)}>
                    <FormattedMessage {...messages.seeDetail} />
                  </Button>
                </div>
              )
            }

            { props.withAction &&
              <ModalV2
                visible={visible}
                className={styles.statModal}
              >
                <div className={styles.modalHeader}>
                  <H5 className="mb-20">{get(props, 'modalProperties.title') || props.title}</H5>
                  <Button variant="outline" onClick={props.onActionClick} disabled={!isFunction(props.onActionClick)}>
                    <FormattedMessage {...messages.exportBtn} />
                  </Button>
                </div>
                <hr />
                {props.withList && (
                  isEmpty(props.dataSource) ?
                    <div className={styles.emptyText}>
                      <span><Icon style={{ width: '50px', height: '50px' }} name="bilan" /></span>
                      <FormattedMessage {...messages.noResult} />
                    </div>
                    : (
                      <List
                        locale={{ emptyText: '' }}
                        itemLayout="horizontal"
                        dataSource={props.modalProperties.dataSource || []}
                        renderItem={(data) => (
                          <List.Item>
                            <div className={styles.item} style={{ width: '100%' }}>{data.item}</div>
                          </List.Item>)}
                        {...props.modalProperties}
                      />
                    )
                )}

                <div className={styles.description}>{props.description}</div>
              </ModalV2>
            }
          </>
        )
      }
    </div>
  );
};

StatBloc.propTypes = {
  title: any,
  separator: any,
  count: number,
  classContainer: any,
  description: any,
  dataSource: array,
  withList: bool,
  withFunnel: bool,
  loading: bool,
  noCount: bool,
  data: bool,
  withAction: oneOfType([bool, object]),
  modalProperties: object,
  onActionClick: oneOfType([bool, func]),
  actionDisabled: bool,
};

export { StatBloc };
