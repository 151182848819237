import React from 'react';
import { number, bool } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { isEqual } from 'lodash';
import { COLORS } from '@/utils/global';

import styles from '../styles.less';

const LiveStatus = ({ countAttendees, maxAttendees, isNew }) => {
  const t = useFormatMessage();

  // Waning, order of condition is important here
  if (isEqual(countAttendees, maxAttendees)) {
    return (
      <div className={styles.liveStatus} style={{ backgroundColor: COLORS.error }}>
        {t({ id: 'full' })}
      </div>
    )
  }
    if (isNew) {
      return (
        <div className={styles.liveStatus} style={{ backgroundColor: COLORS.warning }}>
        {t({ id: 'new' })}
      </div>
      )
    }

  return <div />
}

LiveStatus.propTypes = {
  countAttendees: number,
  maxAttendees: number,
  isNew: bool,
};

export { LiveStatus };
