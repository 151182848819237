import { useQuery } from 'react-query';
import request from '@/utils/request';

export function useGetRecruiterNumberOnline({eventId, view}) {
  const requestUrl = `${process.env.FRONT_API_URL}/statistics/kpi/event-sessions?eventId=${eventId}&userRole=recruiter&eventFormat=${view}`;
  const queryFn = () => request(requestUrl, { method: 'GET' });

  const query = useQuery({
    queryKey: ['event-sessions-recruiter'],
    queryFn,
    refetchOnWindowFocus: false,
    cacheTime: 3000,
    refetchInterval: 10000,
  });
  return query;
}
