import { defineMessages } from 'react-intl';

export default defineMessages({
  mandatory: {
    id: 'form.mandatory',
  },
  limitCharacter: {
    id: 'form.rules.max.character',
  },
  editTitle_webinar: {
    id: 'recruiter.livetalks.edit.title.webinar',
  },
  editTitle_chat: {
    id: 'recruiter.livetalks.edit.title.chat',
  },
  editTitle_meeting: {
    id: 'recruiter.livetalks.edit.title.meeting',
  },
  subjectTitle: {
    id: 'recruiter.live.edit.main.subject.title',
  },
  organizerTitle: {
    id: 'recruiter.live.edit.main.organizer.title',
  },
  organizerNew: {
    id: 'recruiter.live.edit.main.organizer.new',
  },
  tips1: {
    id: 'recruiter.live.edit.main.tips.1',
  },
  tips2: {
    id: 'recruiter.live.edit.main.tips.2',
  },
  organizationNameLabel: {
    id: 'organization.name.label',
  },
  dateTitle: {
    id: 'recruiter.live.edit.main.date.title',
  },
  contactsTitle: {
    id: 'recruiter.live.edit.main.presenter.title',
  },
  contactsAdding: {
    id: 'add.contact',
  },
  delete: {
    id: 'delete',
  },
  conferenceBeginLabel: {
    id: 'hour',
  },
  conferenceDurationLabel: {
    id: 'duration',
  },
  conferenceTitleLabel: {
    id: 'recruiter.live.edit.main.form.conference.title.label',
  },
  conferenceDescriptionLabel: {
    id: 'recruiter.live.edit.main.form.conference.description.label',
  },
  conferenceCategoryLabel: {
    id: 'recruiter.live.edit.main.form.conference.category.label',
  },
  conferenceMaxAttendeesLabel: {
    id: 'recruiter.live.edit.main.form.conference.maxAttendees.label',
  },
  conferenceBeginAtFullTooltip: {
    id: 'recruiter.live.edit.main.form.conference.beginAt.full.tooltip',
  },
  formFirstNameLabel: {
    id: 'firstName',
  },
  formLastNameLabel: {
    id: 'lastName',
  },
  formFirstEmailLabel: {
    id: 'form.email',
  },
  formErrorCompanyExist: {
    id: 'form.error.company.exist',
  },

  toolTitle: {
    id: 'recruiter.live.edit.tool.title',
  },
  toolText: {
    id: 'recruiter.live.edit.tool.text.description',
  },
  toolNavigatorAlert: {
    id: 'recruiter.live.edit.tool.navigator.alert',
  },
  toolBigMarker: {
    id: 'recruiter.live.edit.tool.bigmarker',
  },
  toolBenchmark: {
    id: 'recruiter.live.edit.tool.text.benchmark',
  },
  toolTerm1: {
    id: 'recruiter.live.edit.tool.term.1',
  },
  toolTerm2: {
    id: 'recruiter.live.edit.tool.term.2',
  },
  recapTitle: {
    id: 'recruiter.live.edit.recap.title',
  },
  recapVerify: {
    id: 'recruiter.live.edit.recap.text.verify',
  },
  recapSuccessTitle: {
    id: 'recruiter.live.edit.recap.success.title',
  },
  recapMaxAttendees: {
    id: 'recruiter.live.edit.recap.maxAttendees',
  },
  recapDate: {
    id: 'recruiter.live.edit.recap.date',
  },
  recapFormat: {
    id: 'recruiter.live.edit.recap.format',
  },
  recapDuration: {
    id: 'recruiter.live.edit.recap.duration',
  },
  recapSuccessUl: {
    id: 'recruiter.live.edit.recap.success.ul',
  },
  recapSubmitting: {
    id: 'recruiter.live.edit.recap.submitting',
  },
  recapJobdating: {
    id: 'recruiter.live.edit.recap.jobdating',
  },
  recapPresenter: {
    id: 'recruiter.live.edit.recap.presenter',
  },
  recapDescription: {
    id: 'recruiter.live.edit.recap.description',
  },
  btnConfirm: {
    id: 'btn.confirm',
  },
  publish: {
    id: 'publish',
  },
  other: {
    id: 'other',
  },
  next: {
    id: 'next',
  },
  eventsTitle: {
    id: 'client.live.edit.events.title',
  },
  eventsSubtitle: {
    id: 'client.live.edit.events.subtitle',
  },
  urlFormatNotValid: {
    id: 'event.recruiter.preparation.stand.item.videos.error',
  },
  full: {
    id: 'full',
  },
  contactsTitleLabel: {
    id: 'school.job.name',
  },
  contactPhone: {
    id: 'event.setting.diffusion.contacts.phone.x',
  },
  contactFullName: {
    id: 'event.setting.diffusion.contacts.fullName',
  },
  diffusionContactsTitle: {
    id: 'event.setting.diffusion.contacts.title',
  },
});
