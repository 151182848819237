import React, { useState } from 'react';
import { shape, func, object } from 'prop-types';
import { Form } from 'antd';
import { Body1, Button, H5 } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { useHistory } from 'react-router-dom';
import colors from '@seekube-tech/ui-kit/dist/colors';
import { Password, PasswordConfirmation } from '@/forms/components';
import { API_CUSTOM_ERRORS } from '@/utils/constants';

const NewPasswordForm = ({ form, changePasswordQuery }) => {
  const t = useFormatMessage();
  const history = useHistory();
  const { token, firstname } = document.location.query;
  const [errorMessage, setErrorMessage] = useState(null);

  const handleOnSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, { password }) => {
      if(!err) {
        changePasswordQuery.mutate({
            password,
            resetToken: token
          },
          {
            onError: async (err) => {
              const error = await err;
              
              switch (error.message) {
                case API_CUSTOM_ERRORS.passwordAlreadyUsed:
                  setErrorMessage(t({ id: `api.error.password_policy_already_used` }));
                  break;
                case API_CUSTOM_ERRORS.tokenAlreadyUsed:
                  setErrorMessage(t({ id: 'api.error.resetPassword.tokenAlreadyUsed' }));
                  break;
                default:
                  break
              }
            }
          });
      }
    })
  };

  return (
    <>
      <H5 className="mb-20">{t({ id: 'forgotPassword.passwordTitle' }, { name: firstname })}</H5>
      <Form onSubmit={handleOnSubmit}>
        <Password form={form} />
        <PasswordConfirmation form={form} withPasswordPolicy />
        
        {errorMessage &&
          <Body1 className="mb-20"  color={colors.error['500']}>
            {errorMessage}
          </Body1>
        }

        <div className="confirm-actions">
          <Button
            onClick={() => history.push('/')}
            variant="outline"
            color="neutral"
          >
            {t({ id: 'resetPassword.backToLogin' })}
          </Button>
          <Button
            loading={changePasswordQuery.isLoading}
            disabled={changePasswordQuery.isLoading}
            type="submit"
            id="submit-reset"
          >
            {t({ id: 'done'})}
          </Button>
        </div>
      </Form>
    </>
  );
}

NewPasswordForm.propTypes = {
  form: shape({
    getFieldDecorator: func,
    getFieldsError: func,
    getFieldError: func,
    isFieldTouched: func,
  }),
  changePasswordQuery: object,
}

export default Form.create()(NewPasswordForm);
