import React from 'react';
import { string, bool } from 'prop-types';
import classnames from 'classnames';
import { isEmpty } from 'lodash';
import { useFormatMessage } from 'react-intl-hooks';
import Icon from '@/components/Icon';
import styles from './styles.less';
import messages from './messages';

const InputHelper = ({ id, title: titleProps, isVisible }) => {
  const t = useFormatMessage();

  let title = '';
  let help = '';
  let subHelp = '';

  if (!isEmpty(id)) {
    const idClean = id.replace('matching-', '').replace('filters-', '').toLowerCase();

    if (titleProps) {
      title = titleProps;
    } else if (messages[`${idClean}Label`]) {
      title = t(messages[`${idClean}Label`]);
    } else if (messages[`${idClean}Placeholder`]) {
      title = t(messages[`${idClean}Placeholder`]);
    }

    if (messages[`${idClean}Help`]) {
      help = t(messages[`${idClean}Help`]);
    }

    if (messages[`${idClean}SubHelp`]) {
      subHelp = t(messages[`${idClean}SubHelp`]);
    }
  }

  const classes = classnames(!isVisible ? styles.hide : '', styles.inputHelper);

  return (
    <div className={classes}>
      {(title || help || subHelp) && (
        <span className={styles.icon}>
            <Icon name="light" />
          </span>
      )}
      {
        !isEmpty(id) && !isEmpty(title) && (
          <h2>{title}</h2>
        )
      }
      {
        !isEmpty(id) && !isEmpty(help) && (
          <p className="help">{help}</p>
        )
      }
      {
        !isEmpty(id) && !isEmpty(subHelp) && (
          <p className="subHelp">{subHelp}</p>
        )
      }
    </div>
  );
}

InputHelper.propTypes = {
  id: string,
  title: string,
  isVisible: bool,
};

export default InputHelper
