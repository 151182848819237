import React from 'react';
import { object, func, array } from 'prop-types';
import { injectIntl } from 'react-intl';
import { isEmpty, isArray, debounce, compact, filter, map, find, get, isNull, size } from 'lodash';
import moment from 'moment';

// Components
import { Form, Input, Icon as IconAntd } from 'antd';
import { queryStringToObject } from '@/utils/url';
import Selector from '@/components/Form/Selector';
import Icon from '@/components/Icon';

// Components
import styles from './styles.less';
import messages from '../../messages';

const FormItem = Form.Item;

/**
 * EventList
 */
const sortByOccurence = (array) => (array.sort((a, b) => !isNull(a) && !isNull(b) && a !== undefined && b !== undefined && parseFloat(a.badgeCount ? a.badgeCount : 0) - parseFloat(b.badgeCount ? b.badgeCount : 0)).reverse());

class SearchEngineSeekalendar extends React.PureComponent {
  static propTypes = {
    search: object,
    onSearch: func,
    intl: object,
    facets: object,
    history: object,
    facetsExponents: object,
    authUser: object,
    values: object,
    defaultCriteria: array,
  };

  static defaultProps = {
    search: {},
    keywords: null,
    defaultSectors: [],
  };

  constructor(props) {
    super(props);
    const { search } = props;

    const paramObject = queryStringToObject(props.history.location.search);

    this.state = {
      isModalFilter: false,
      keywords: paramObject.keywords,
      search,
      sizeOptions: [],
    };

    this.remoteSearch = debounce((search, matching, facetKey) => props.onSearch(search, matching, facetKey), 500);
  }

  getFacetFromKey = (key, value, context = null) => {
    const { props: { facets, facetsExponents } } = this;

    const items = context === 'exponent' ? facetsExponents : facets;

    return (items && typeof items[`${key}`] === 'object') ? items[`${key}`][value] || 0 : 0;
  };

  updateSearch = (key, value, facetKey) => {
    const {
      state: { search, matching },
      remoteSearch,
    } = this;

    const newState = {};

    search[key] = value;
    search.page = 1;

    newState.search = search;

    if (key === 'keywords') {
      newState.keywords = value;
    }

    this.setState(newState, remoteSearch(search, matching, facetKey));
  };

  render() {
    const {
      props: {
        authUser, intl, defaultCriteria, facets,
        values: { format, targets, pricing, targetContracts, targetLevels, organizationsName, beginAtString },
      },
      state: { keywords },
      updateSearch,
    } = this;

    if (isEmpty(defaultCriteria)) return null;
    const contractOptions = find(defaultCriteria, (c) => c.key === 'CONTRACT') || {};
    const options = authUser.locale === 'fr' ?
      map(filter(contractOptions._choices, (o) => o.enable), (o) => ({ value: o._id, label: o.label, badgeCount: this.getFacetFromKey('targetContracts', o._id) }))
      : (map(filter(contractOptions._choices, (o) => o.enable), (o) => (authUser.locale === 'en' && o.label_en && { value: o._id, label: o.label_en, badgeCount: this.getFacetFromKey('targetContracts', o._id) })));
    const filtredOptions = filter(options, (o) => o !== undefined);
    const levelOptions = find(defaultCriteria, (c) => c.key === 'LEVEL') || {};
    const choices = authUser.locale === 'fr' ? map(filter(levelOptions._choices, (o) => o.enable), (o) => ({ value: o._id, parent: o._parent, label: o.label, badgeCount: this.getFacetFromKey('targetLevels', o._id) })) : map(filter(levelOptions._choices, (o) => o.enable), (o) => (authUser.locale === 'en' && o.label_en && { value: o._id, parent: o._parent, label: o.label_en, badgeCount: this.getFacetFromKey('targetLevels', o._id) }));
    const filtreJobdOptions = filter(choices, (o) => o !== undefined);

    return (
      <>
        <Form className={`${styles.searchFormContainer}`}>
          <div className={styles.searchFormTop}>
            <FormItem className={styles.formSearchItem}>
              <Input
                className={styles.searchInput}
                placeholder={intl.formatMessage(messages.searchPlannerPlaceholder)}
                value={keywords}
                prefix={<IconAntd type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
                onChange={(e) => updateSearch('keywords', e.target.value)}
                onPressEnter={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              />
              <button onClick={() => this.setState({ isModalFilter: true })} className="openFiltersIcon show-mobile">
                <Icon name="filters" />
              </button>
            </FormItem>
            <FormItem className={styles.formItem} style={{ width: '170px', flexGrow: 0, zIndex: 3 }}>
              <Selector
                position="right"
                isMobile={false}
                label={intl.formatMessage({ id: 'start' })}
                values={isArray(beginAtString) ? beginAtString : compact([beginAtString])}
                noLabel={intl.formatMessage({ id: 'searchEngine.noLabel' })}
                options={map(facets.beginAtString, (key, i) => ({ value: i, label: moment(i).format('MMMM YYYY'), badgeCount: key })).sort((a, b) => (moment(a.value).unix() - moment(b.value).unix()))}
                onChange={(val) => updateSearch('beginAtString', val)}
              />
            </FormItem>
            <FormItem className={styles.formItem} style={{ width: '170px', flexGrow: 0, zIndex: 3 }}>
              <Selector
                position="right"
                isMobile={false}
                label={intl.formatMessage(messages.plannerPlgr, { count: 1, gender: 'male' })}
                noLabel={intl.formatMessage({ id: 'searchEngine.noLabel' })}
                values={isArray(organizationsName) ? organizationsName : compact([organizationsName])}
                searchable={size(facets.organizationsName) > 8}
                options={map(facets.organizationsName, (key, i) => ({ value: i, label: i, badgeCount: key })).sort((a, b) => a.label.localeCompare(b.label))}
                onChange={(val) => updateSearch('organizationsName', val)}
              />
            </FormItem>
          </div>

          <div className={styles.searchFormBottom}>
            {/* Tarif */}
            <FormItem className={styles.formItem}>
              <Selector
                position="left"
                isMobile={false}
                label={intl.formatMessage(messages.pricingPl, { count: 1 })}
                noLabel={intl.formatMessage({ id: 'searchEngine.noLabel' })}
                values={isArray(pricing) ? pricing : compact([pricing])}
                options={sortByOccurence(
                  ['free', 'paying', 'info', 'not-provided'].map(
                    (pricingKey) =>
                      ({ value: pricingKey, label: intl.formatMessage(messages[`pricing_${pricingKey}`]), badgeCount: this.getFacetFromKey('pricing', pricingKey) })
                  ))}
                onChange={(val) => updateSearch('pricing', val)}
              />
            </FormItem>
            {/* Format */}
            <FormItem className={styles.formItem}>
              <Selector
                position="center"
                isMobile={false}
                label={intl.formatMessage(messages.formatPl, { count: 1 })}
                noLabel={intl.formatMessage({ id: 'searchEngine.noLabel' })}
                values={isArray(format) ? format : compact([format])}
                options={sortByOccurence(map(['virtual', 'physical'],
                  (formatKey) =>
                    ({ value: formatKey, label: intl.formatMessage(messages[formatKey]), badgeCount: this.getFacetFromKey('format', formatKey) })
                ))}
                onChange={(val) => updateSearch('format', val)}
              />
            </FormItem>

            {/* Target */}
            <FormItem className={styles.formItem}>
              <Selector
                position="center"
                isMobile={false}
                label={intl.formatMessage(messages.targetPl, { count: 1 })}
                noLabel={intl.formatMessage({ id: 'searchEngine.noLabel' })}
                values={isArray(targets) ? targets : compact([targets])}
                options={sortByOccurence(['engineer', 'business', 'tech', 'other'].map(
                  (facetValue) =>
                    ({ value: facetValue, label: intl.formatMessage(messages[facetValue]), badgeCount: this.getFacetFromKey('targets', facetValue) })
                ))}
                onChange={(val) => updateSearch('targets', val)}
              />
            </FormItem>
            {/* Contrats */}
            <FormItem className={styles.formItem}>
              <Selector
                position="right"
                isMobile={false}
                label={authUser.locale === 'en' && !isEmpty(get(contractOptions, 'modules.searchEngineParticipant.label_en')) ? get(contractOptions, 'modules.searchEngineParticipant.label_en') : get(contractOptions, 'modules.searchEngineParticipant.label')}
                noLabel={intl.formatMessage({ id: 'searchEngine.noLabel' })}
                values={isArray(targetContracts) ? targetContracts : compact([targetContracts])}
                options={sortByOccurence(filtredOptions)}
                onChange={(val) => updateSearch('targetContracts', val)}
              />
            </FormItem>
            {/* Niveau d'étude */}
            <FormItem className={styles.formItem}>
              <Selector
                position="right"
                isMobile={false}
                label={authUser.locale === 'en' && !isEmpty(get(levelOptions, 'modules.searchEngineParticipant.label_en')) ? get(levelOptions, 'modules.searchEngineParticipant.label_en') : get(levelOptions, 'modules.searchEngineParticipant.label')}
                isTree={filter(levelOptions._choices, (c) => !!c._parent).length}
                noLabel={intl.formatMessage({ id: 'searchEngine.noLabel' })}
                values={isArray(targetLevels) ? targetLevels : compact([targetLevels])}
                options={sortByOccurence(filtreJobdOptions)}
                onChange={(val) => updateSearch('targetLevels', val)}
              />
            </FormItem>
          </div>
        </Form>
      </>
    );
  }
}

export default injectIntl(SearchEngineSeekalendar);
