import { track } from '@/utils/analytics';
import { ANALYTICS_RECRUITER } from '@/utils/constants';

export const trackRecruiterDeleteSlots = ({ authUser, event, isAllSlots }) => (
  track({
    user: authUser,
    name: ANALYTICS_RECRUITER.DELETE_SLOTS,
    event,
    properties: {
      isAllSlots,
    },
  })
);
