import React from 'react';
import { func, bool, array } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { createStructuredSelector } from 'reselect';
import { toJS } from '@/utils';

// Store
import { actionActions, actionSelectors } from '@/store/action';
import { organizationActions, organizationSelectors } from '@/store/organization';
import { criterionSelectors } from '@/store/criterion';

// Components
import MergeNewItem from './components/MergeNewItem';

// Styles & Translations
import styles from './styles.less';

class MergeSchoolsScene extends React.PureComponent { // eslint-disable-line
  static propTypes = {
    getActions: func,
    mergeOrganization: func,
    deleteAction: func,
    postAction: func,
    isFetching: bool,
    suggestions: array,
    mergeList: array,
  };

  state = {
    selectedMergeMenu: 'new',
    isLoading: false,
    cancelTimeoutId: null,
  };

  handleCancelMerge = (actionId) => {
    const {
      props: { deleteAction, getActions },
      state: { cancelTimeoutId },
    } = this;

    clearTimeout(cancelTimeoutId);

    this.setState({ cancelTimeoutId: null });

    deleteAction({
      actionId,
      callback: () => { getActions({ name: 'MERGE_SCHOOL' }); },
    });
  }

  handleMerge = (item, newItem) => {
    const { props: { postAction, getActions }, state: { cancelTimeoutId } } = this;

    if (cancelTimeoutId !== null) {
      return false;
    }

    postAction({
      actionParams: {
        name: 'MERGE_SCHOOL',
        isAdmin: true,
        data: {
          oldId: item._id,
          oldName: item.name,
          newId: newItem ? newItem._id : null,
          newName: newItem ? newItem.name : null,
        },
      },
      callback: () => { getActions({ name: 'MERGE_SCHOOL' }); },
    });

    const id = setTimeout(() => {
      this.props.mergeOrganization({
        oldId: item._id,
        newId: newItem ? newItem._id : null,
      });

      this.setState({ cancelTimeoutId: null });
    }, 100);

    this.setState({ cancelTimeoutId: id });

    return true;
  }

  render() {
    const {
      props: { mergeList, isFetching, suggestions },
      handleMerge,
    } = this;

    if (isFetching) {
      return ('fetching');
    }

    return (
      <div>
        <div className={styles.sceneContainer}>
          <div className={styles.adminContainer}>
            <MergeNewItem
              item={mergeList[0]}
              suggestions={suggestions}
              onMerge={handleMerge}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  actions: actionSelectors.getActions,
  isFetching: organizationSelectors.getOrganizationFetching,
  mergeList: organizationSelectors.getMergeList,
  mergeListTotal: organizationSelectors.getMergeListTotal,
  mergeListTotalCriterion: criterionSelectors.getMergeListTotal,
  suggestions: organizationSelectors.getSuggestions,
});

const mapDispatchToProps = {
  getActions: actionActions.getActions,
  deleteAction: actionActions.deleteAction,
  postAction: actionActions.postAction,
  getOrganizations: organizationActions.getOrganizations,
  mergeOrganization: organizationActions.mergeOrganization,
  push,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(toJS(MergeSchoolsScene));

