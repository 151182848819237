import React from 'react';
import { useHistory } from 'react-router-dom';
import { bool, object, func } from 'prop-types';
import { useSelector } from 'react-redux';
import { useLocalStorage } from 'react-use';
import { Informal1to1s, Conferences, Offers, LandingNavBar, LandingViewContainer } from '@/scenes/Event/containers/Landing/components/Contents/components';
import { LandingContent as GeneralInfos } from '@/scenes/Event/containers/Landing/components/Contents/components/GeneralInfos/components/LandingContent';
import { useGetLandingTabsCounters } from '@/queries/landing/useGetLandingTabsCounters';
import { trackCandidateClickedLandingTabs } from '@/utils/analytics';
import { HASH } from '@/scenes/Event/containers/Landing/const';
import { getAvailableActions } from '@/store/availableActions/selectors';
import { getIsNavbarBarTabsVisible, getUserType } from '@/scenes/Event/containers/Landing/helpers';
import { getAuthUser } from '@/store/auth/selectors';
import { LOCALES } from '@/utils/constants';

const Contents = ({ event, match, editMode, onChange }) => {
  const [locale] = useLocalStorage('locale', localStorage.getItem('locale') || LOCALES.fr, { raw: true });
  const history = useHistory();
  const landingCounters = useGetLandingTabsCounters({ eventId: event._id });
  const availableActions = useSelector(getAvailableActions);
  const authUser = useSelector(getAuthUser)?.toJS();

  const redirectionHref = availableActions.eventLandingCta?.getLinkHref({ event, role: getUserType(authUser, event), editMode: false });
  const availableNavbarTabs = getAvailableNavbarTabs();

  function getAvailableNavbarTabs () {
    const isNavbarBarTabsVisible = getIsNavbarBarTabsVisible(event);

    if (!isNavbarBarTabsVisible) return [];

    const tabs = ['home'];

    if (landingCounters.data?.offers > 0 && event?.modules.offer.enable) {
      tabs.push(HASH.offers);
    }
    if (landingCounters.data?.conferences > 0 && event?.modules.live.participantEnable) {
      tabs.push(HASH.conferences);
    }
    if (landingCounters.data?.informal1to1s > 0 && event?.modules.informal1to1.enable) {
      tabs.push(HASH.informal1to1s);
    }

    return tabs
  }

  const shouldDisplayContent = (tabName) => (
    history.location.hash === `#${tabName}` && availableNavbarTabs.includes(tabName)
  )

  const getCurrentTabContent = () => {
    if (shouldDisplayContent(HASH.offers)) {
      return <Offers event={event} redirectionHref={redirectionHref} />;
    }

    if (shouldDisplayContent(HASH.conferences)) {
      return <Conferences event={event} redirectionHref={redirectionHref} />;
    }

    if (shouldDisplayContent(HASH.informal1to1s)) {
      return <Informal1to1s event={event} redirectionHref={redirectionHref} />;
    }

    return <GeneralInfos currentLocale={locale} event={event} editMode={editMode} onChange={onChange} />;
  }

  return (
    <LandingViewContainer event={event} editMode={editMode}>
      <LandingNavBar
        event={event}
        match={match}
        counters={landingCounters.data}
        availableTabs={availableNavbarTabs}
        onChangeTab={(tabParam) => { trackCandidateClickedLandingTabs({ event, tabParam }); }}
      />
      {getCurrentTabContent()}
    </LandingViewContainer>
  )
}

Contents.propTypes = {
  event: object,
  match: object,
  editMode: bool,
  onChange: func,
}

export { Contents }
