import { useQuery } from 'react-query';

import request from '@/utils/request';
import { objectToParams } from '@/utils/url';

export function useGetConferences({ eventId, participate, offset, page, search, limit = 1000, organization, archive }) {
  const params = objectToParams({ eventId, participate, archive, organization, offset, page, limit, ...search });

  const requestUrl = `${process.env.FRONT_API_URL}/conferences?${params}`;

  const queryFn = () => request(requestUrl, { method: 'GET' });

  const query = useQuery({
    queryKey: ['conferences', params],
    queryFn,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  return query;
}
