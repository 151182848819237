import { Empty } from 'antd';
import * as React from 'react';
import { useFormatMessage } from 'react-intl-hooks';

function CustomEmpty() {
  const t = useFormatMessage();

  return <Empty description={t({ id: 'noData' })} image={Empty.PRESENTED_IMAGE_SIMPLE}/>
}

export default CustomEmpty;
