import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { object, string } from 'prop-types';
import classnames from 'classnames';
import { Body1 } from '@seekube-tech/ui-kit';
import styles from '@/scenes/Events/scenes/components/JobdatingsExplore/components/JobdatingCard/styles.less';
import Tags from '@/scenes/Events/scenes/components/JobdatingsYours/components/Tags';
import Html from '@/components/Html';
import { cropText } from '@/utils/text';
import Avatars from '@/components/Avatars';
import { getLocale } from '@/utils/localStorage';
import { getEventPhases, isEventInProgress } from '@/store/availableActions/utils/event';

export function Infos({
  context,
  event,
}) {
  const t = useFormatMessage();
  const locale = getLocale();
  const description = event.calendarB2CDescription?.[locale];
  const eventPhases = getEventPhases(event);
  const isEventInProgressWithExponents = isEventInProgress(eventPhases) && event.countExponents && context === 'default';

  return (
    <div className={styles.infos}>
      {context === 'default' && (
        <div className={styles.description}>
          <div className={styles.infoHeader}>
            <Tags tagsList={['jobdatingDate', 'countOffers']} event={event} locale={locale} />
          </div>
          <div className={`${styles.descriptionHtml} ${isEventInProgressWithExponents ? styles.clamp6 : styles.clamp8}`}>
            <Html value={cropText(description, 300)} />
          </div>
        </div>
      )}

      {isEventInProgressWithExponents &&
        <div className={styles.discoverBusinesses}>
          <Body1 fontWeight={600}>
            {t({ id: 'events.explore.companyDiscover' }, { count: event.countExponents })}
          </Body1>
          <Avatars
            className={styles.avatarsContainer}
            cssPictureItem={classnames(styles.avatars, context === 'onboarding' ? styles.onboardingAvatars : '')}
            pictures={event.exponentsPicture}
            maxPictures={3}
          />
        </div>
      }
    </div>
  )
}

Infos.propTypes = {
  event: object,
  context: string,
}
