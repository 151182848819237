import { defineMessages } from 'react-intl';

export default defineMessages({
  one: {
    id: 'search.contract.one',
  },
  search: {
    id: 'search.search',
  },
  firstWord: {
    id: 'event.candidate.preparation.signup.step.search',
  },
  jobs: {
    id: 'search.jobs',
  },
  date: {
    id: 'search.date',
  },
  duration: {
    id: 'search.duration',
  },
  mobility: {
    id: 'search.mobility',
  },
  others: {
    id: 'others',
  },
  of: {
    id: 'of',
  },
});
