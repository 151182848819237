import mime from 'mime-types';
import randtoken from 'rand-token';

const getFileExtension = (filename) => filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2); // eslint-disable-line

const dataURItoFile = (dataURI) => {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  const blob = new Blob([ab], { type: mimeString });
  const fileName = `${randtoken.generate(16)}.${mime.extension(mimeString)}`;

  try {
    return new File([blob], fileName, { type: mimeString });
  } catch (err) {
    return blob;
  }
};

const fileBytesToMB = (bytes) => bytes ? parseFloat((bytes / (1024 * 1024)).toFixed(2)) : 0;

export {
  getFileExtension,
  dataURItoFile,
  fileBytesToMB
};
