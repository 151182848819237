import React from 'react';
import { array, any, func, object, bool } from 'prop-types';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { authSelectors } from '@/store/auth';
import { toJS } from '@/utils';
import { isEmpty } from 'lodash';
import { CRITERION_KEY } from '../../utils/constants';

import AvailabilityField from './components/AvailabilityField';
import MobilityField from './components/MobilityField';
import BaseField from './components/BaseField';
import PositionField from './components/PositionField';
import LicenseField from './components/LicenseField';
import DurationField from './components/DurationField';


function CriteriaFields({ criteria, endAt, showHelper, authUser, disabled = false, required = true, context = 'recruiter' }) {

  return criteria.map((criterion) => {
    const isDisabled = disabled && !isEmpty(criterion.key) ;
    /**
     * We have to filter choices because : 
     * - On client offer form, user should have access to all criterion, regardless of their status on the event
     * - On non-client offer form, user should only have access to 
     */
    const filteredChoices = criterion?._choices?.filter(choice => choice.enable || authUser._currentOrganization.isClient) || []
    const criterionWithFilteredChoices = {
      ...criterion,
      _choices: filteredChoices
    }

    switch (criterion.key) {
      case CRITERION_KEY.availability:
        return <AvailabilityField key={criterion._id} criterion={criterionWithFilteredChoices} endAt={endAt} showHelper={showHelper} disabled={isDisabled} />;

      case CRITERION_KEY.mobility:
        return <MobilityField key={criterion._id} criterion={criterionWithFilteredChoices} showHelper={showHelper} disabled={isDisabled} />;

      case CRITERION_KEY.position:
        return <PositionField key={criterion._id} criterion={criterionWithFilteredChoices} showHelper={showHelper} disabled={isDisabled} />;

      case CRITERION_KEY.license:
        return <LicenseField key={criterion._id} criterion={criterionWithFilteredChoices} showHelper={showHelper} disabled={isDisabled} />;

      case CRITERION_KEY.duration:
        return <DurationField key={criterion._id} criterion={criterionWithFilteredChoices} showHelper={showHelper} disabled={isDisabled} required={!isDisabled} />;

      default:
        return <BaseField key={criterion._id} criterion={criterionWithFilteredChoices} showHelper={showHelper} disabled={isDisabled} />;
    }
  }).filter(criterion => !!criterion);
}

CriteriaFields.propTypes = {
  criteria: array,
  endAt: any,
  showHelper: func,
  authUser: object,
  disabled: bool,
};

CriteriaFields.defaultProps = {
  criteria: [],
  endAt: new Date(),
};

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser,
});

const mapDispatchToProps = {
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(toJS(CriteriaFields));
