import React, { useRef } from 'react';
import { Body1, Button, IconFile } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { bool, func, string } from 'prop-types';
import styles from './styles.less';

const CvUpload = ({
  isLoading,
  onFileChange,
  fileTitle,
  analyzeSucceded = false
}) => {
  const t = useFormatMessage();
  const buttonRef = useRef();

  const FilePlaceholder = () => {
    if (!fileTitle) {
      return t({ id: 'cvc.cv.upload.size' })
    }

    return (
      <div className={styles.filenameWrapper}>
        <IconFile/>
        <span className={styles.name}>{fileTitle}</span>
      </div>
    )
  }

  const SectionLabel = () => {
    if (analyzeSucceded) {
      return t({ id: 'document.analyzed' });
    }

    return isLoading ? t({ id: 'document.analyzing' }) : t({ id: 'document' });
  }

  const handleImport = () => {
    buttonRef.current?.click();
  }

  const handleChangeFile = (e) => {
    onFileChange({ file: e.target.files[0] });
    e.target.value = "";
  }

  return (
    <div className={styles.cvcUpload}>
      <div className={styles.head}>
        <Body1 fontWeight={800} className={styles.title}>
          <SectionLabel />
        </Body1>
        <Body1 className={styles.help}>
          <FilePlaceholder />
        </Body1>
      </div>
      <Button disabled={isLoading} variant="outline" color="primary" loading={isLoading} onClick={handleImport}>
        {fileTitle ? t({ id: 'cv.analyze.other' }) : t({ id: 'form.resume.import' })}
        <input
          accept="application/pdf"
          ref={buttonRef}
          type="file"
          style={{ display: 'none' }}
          onChange={handleChangeFile}/>
      </Button>
    </div>
  )
}

CvUpload.propTypes = {
  isLoading: bool,
  fileTitle: string,
  onFileChange: func,
  analyzeSucceded: bool
}

export default CvUpload;
