import React, { memo } from 'react';
import { string, func } from 'prop-types';


import styles from '../../../../styles.less';

const RegexIdFormat = memo(
  ({ defaultValue, onChange }) => {
    const handleInputChange = ({ target: { value: inputValue } }) => {
      onChange({ value: inputValue });
    };

    return (
      <div className={styles.textAreaContainer}>
        <textarea onChange={handleInputChange} defaultValue={defaultValue} />
        <a style={{ display: 'flex', justifyContent: 'flex-end' }} href="https://regex101.com" target="_blank">Tester</a>
        <span>
          <div>Expressions regex usuelles </div><br />
          <div style={{ display: 'flex' }}>
            {'^[0-9]{10}$ : Un champ contenant précisément 10 chiffres aléatoires entre 0 et 9'}
          </div>
          <div>ex. : 128943032</div><br />
          <div style={{ display: 'flex' }}>{'^V[6-9]{9}$ : Un champ commençant par V et suivi de 9 chiffres compris entre 6 et 9'}</div>
          <div>ex. : V678987977</div><br />
          <div style={{ display: 'flex' }}>(@ieseg.fr)$ : Le champ se termine par "@ieseg.fr"</div>
        ex. : seekube18@ieseg.fr
        </span>
      </div>
    );
  }
);

RegexIdFormat.propTypes = {
  defaultValue: string,
  onChange: func,
};

export { RegexIdFormat };
