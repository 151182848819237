import React from 'react';
import { func } from 'prop-types';
import Select from '@/components/Form/Select';
import styles from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Conferences/styles.less';
import Icon from '@/components/Icon';
import { queryStringToObject } from '@/utils/url';
import { useFormatMessage } from 'react-intl-hooks';
import { Select as SelectAntd } from 'antd';

const { Option } = SelectAntd;

export const UpdateArchiveSelect = ({ onSelect }) => {
  const t = useFormatMessage();
  const { archive } = queryStringToObject(location.search);
  const isArchive =  archive === 'true' ? 'archive' : 'current';

  return (
    <Select
      variant="transparent"
      className={styles.select}
      defaultValue={isArchive}
      suffixIcon={<Icon name="chevron" />}
      onSelect={onSelect}
    >
      {[(
        <Option key="current" value="current">
          {t({ id: 'live.upcoming' })}
        </Option>
      ), (
        <Option key="past" value="archive">
          {t({ id: 'event.recruiter.lives.past' })}
        </Option>
      )]}
    </Select>
  )
}

UpdateArchiveSelect.propTypes = {
  onSelect: func,
}
