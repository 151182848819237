import { Bloc } from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/components/Bloc';
import colors from '@seekube-tech/ui-kit/dist/colors';
import styles2 from '@/scenes/Auth/scenes/Login/styles2.less';
import { Body2, Button } from '@seekube-tech/ui-kit';
import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { USERS_TYPE } from '@/utils/constants';
import { useHistory } from 'react-router-dom';
import { useIsMobile } from '@/utils/MediaQueries';

export const SubscriptionRedirectionBloc = ({ role }) => {
  if ([USERS_TYPE.owner, USERS_TYPE.recruiter].includes(role)) return null;

  const t = useFormatMessage();
  const history = useHistory();
  const isWiderThanMobile = !useIsMobile();

  return (
    <Bloc className={styles2.BlocLogin}>
      <div className={styles2.loginContainer}>
        <Body2 color={colors.neutral['400']}>
          {t({ id: isWiderThanMobile ? 'signin.noAccount': 'you.have.no.account' })}
        </Body2>
        <Button className={styles2.login} onClick={() => history.push(`/auth/signup/candidate`)} variant="outline">
          {t({ id: 'signin.signup' })}
        </Button>
      </div>
    </Bloc>
  )
}