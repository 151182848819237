import React from 'react';
import { string, array, object, bool } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useFormatMessage } from 'react-intl-hooks';
import {
  MemberColumn
} from '@/scenes/Admin/scenes/Organizations/scenes/Organization/components/memberColumn';
import { ValueWithWarning } from '@/components/ValueWithWarning';
import { isHybrid } from '@/helpers/event/isHybrid';
import { MoreActionsV2 } from './Recruiters/MoreActionsV2';

const SubColumns = (exponent, handleRecruiterAction, view, authUser, event) => {
  const t = useFormatMessage();

  return (
    [
      {
        id: 'recruiters',
        Header: t({ id: 'recruiters' }, { count: 2 }),
        accessor: (exponentUser) => <MemberColumn
          exponent={exponent}
          organization={exponent._organization}
          authUser={authUser}
          record={{ ...exponentUser._user, role: exponentUser.role }}
          onActionClick={(type) => handleRecruiterAction(type, exponentUser, exponent)}
        />
      },
      {
        id: 'countOffersPublished',
        className: 'text-center',
        Header: <FormattedMessage id="event.owner.dashboard.organizations.offers" />,
        accessor: ({ countOffersPublished }) => <ValueWithWarning value={countOffersPublished} />
      },
      /*
       // TODO: actuellement non géré côté back
       {
       id: 'countConferences',
       Header: <FormattedMessage
       id="event.owner.dashboard.organizations.lives"/>,
       accessor: ({ users }) => 0,
       }, */
      {
        id: 'countSlots',
        Header: <FormattedMessage id="interviews.slots" />,
        SubHeaders: [
          t({ id: 'available.pl' }, { gender: 'male', count: 1 }),
          t({ id: 'booked.plgr' }, { gender: 'male', count: 1 })
        ],
        accessor: (exponentUser) => {
          const { countSlotsAvailable, countSlots, } = isHybrid(exponent._event) ? exponentUser[view] : exponentUser;

          return [
            <ValueWithWarning value={countSlotsAvailable}/>,
            <ValueWithWarning value={countSlots - countSlotsAvailable}/>,
          ];
        },
        disableSortBy: true,
      },
      {
        id: 'countInformalSlots',
        Header: <FormattedMessage id="slots.informal1to1" />,
        SubHeaders: [
          t({ id: 'available.pl' }, { gender: 'male', count: 1 }),
          t({ id: 'booked.plgr' }, { gender: 'male', count: 1 })
        ],
        accessor: (exponentUser) => {
          const { countInformalSlotsAvailable, countInformalSlots, } = isHybrid(exponent._event) ? exponentUser[view] : exponentUser;

          return [
            <ValueWithWarning value={countInformalSlotsAvailable}/>,
            <ValueWithWarning value={countInformalSlots - countInformalSlotsAvailable}/>,
          ];
        },
        disableSortBy: true,
      },
      {
        id: 'actions',
        Header: '',
        disableSortBy: true,
        accessor: (record) => (
          <MoreActionsV2
            {...record}
            exponent={exponent}
            onActionClick={(type) => handleRecruiterAction(type, record, exponent)}
            event={event}
          />
        )
      }
    ]
  );
};

SubColumns.propTypes = {
  tool: object,
  createdAt: string,
  _organization: object,
  users: array,
  locale: string,
  event: object,
  isHybrid: bool,
};

export default SubColumns;
