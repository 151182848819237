import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { object } from 'prop-types';
import {
  Body1,
  IconBuilding,
  IconCalendar,
  IconLives,
  IconStar, IconUsers,
  Link1,
} from '@seekube-tech/ui-kit';
import colors from '@seekube-tech/ui-kit/dist/colors';
import classnames from 'classnames';
import { useGetDashboardIntroduction } from '@/queries/participants/useGetDashboardIntroduction';
import {
  getJobfairData, isMoreThanXPercentage,
} from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Dashboard/components/Cover/utils';
import { Stat } from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Dashboard/components/Cover/Stat';
import styles from './styles.less';


export const Stats = ({ event, participant }) => {
  const t = useFormatMessage();
  const { data: stats } = useGetDashboardIntroduction();
  const { date, location } = getJobfairData(event, participant);
  const isParticipantConnectedTenPercentMore = isMoreThanXPercentage(stats?.countParticipantsConnected, stats?.countParticipantsActive, 10);
  const isRecruitersConnectedTenPercentMore = isMoreThanXPercentage(stats?.countRecruitersConnected, stats?.countRecruiters, 10);

  return (
    <div className={classnames(styles.stats, "rounded-md grid grid-flow-row-dense")}>
      <Stat
        icon={<IconCalendar />}
        title={date}
      >
        <div>
          {location && <Body1 className="text-sm">{location}</Body1>}
          <Link1
            href={`${window.location.origin}/${event.slug}/candidate/keydates`}
            target="_blank" style={{ fontSize: '12px' }}
          >
            {t({ id: 'see.keyDates' })}
          </Link1>
        </div>
      </Stat>
      <Stat
        icon={<IconBuilding />}
        title={t({id: 'company.with.count.pl' }, { count: stats?.countExponents })}
      >
        {isRecruitersConnectedTenPercentMore &&
          <div>
            <Body1 className="text-sm flex items-center" color={colors.common.apple['500']}>
              <IconLives size={12} className="mr-4"/> {t({ id: 'recruiter.realtime.with.count.pl' },
              { count: stats?.countRecruitersConnected })}
            </Body1>
          </div>
        }
      </Stat>
      <Stat
        icon={<IconStar />}
        title={t({ id: 'opportunity.with.count.pl' }, { count: stats?.countOpportunities })}
      />
      <Stat
        icon={<IconUsers />}
        title={t({ id: 'candidate.with.count.pl' }, { count: stats?.countParticipantsActive })}
      >
        {isParticipantConnectedTenPercentMore &&
          <div>
            <Body1 className="text-sm flex items-center" color={colors.common.apple['500']}>
              <IconLives size={12} className="mr-4" /> {t({ id: 'candidate.realtime.with.count.pl' },
              { count: stats?.countParticipantsConnected })}
            </Body1>
          </div>
        }
      </Stat>
    </div>
  )
}

Stats.propTypes = {
  event: object,
  participant: object,
}
