import React, { useState } from 'react';
import { queryStringToObject } from '@/utils/url';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { authActions } from '@/store/auth';
import { useFormatMessage } from 'react-intl-hooks';
import SocialLoginButton from '@/components/SocialLoginButton';

export const LinkedinOauth = ({ children, className = '' }) => {
  const t = useFormatMessage();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);


  const handleSocialLogin = (user) => {
    dispatch(authActions.authLoginOauth({
      id: user._profile.id,
      token: user._token.accessToken,
      method: user._provider,
      provider: user._provider,
      redirect: '/events',
      callback: () => {
        history.push(queryStringToObject(window.location.search).redirect);
      },
      notificationParams: {
        error: {
          message: t({ id: 'signin.social.error' }),
        },
      },
    }));
  };

  const handleSocialLoginFailure = (err) => {
    setIsLoading(false);
    dispatch(authActions.authLoginError(err))
  };

  return (
    <div className="oauthContainer">
      <div data-cy="oauth-linkedin-login-container">
        <SocialLoginButton
          className={className}
          provider="linkedin"
          appId={process.env.FRONT_LINKEDIN_CLIENT_ID}
          onLoginSuccess={handleSocialLogin}
          onLoginFailure={handleSocialLoginFailure}
          onLoading={() => setIsLoading(true)}
        >
          {children}
        </SocialLoginButton>
      </div>
    </div>
  )
}
