import React from 'react';
import { bool, array, func, object } from 'prop-types';
import { isEmpty } from 'lodash';
import { Modal } from 'antd';

// Styles & Translations
import { injectIntl } from 'react-intl';
import styles from '../../styles.less';

class AddChoiceModal extends React.PureComponent {
  state = {
    inputValue: '',
  };

  componentWillReceiveProps({ isVisible }) {
    if (isVisible && !this.props.isVisible) {
      this.setState({ inputValue: '' });
    }
  }


  handleInputChange = ({ target: { value: inputValue } }) => {
    this.setState({ inputValue });
  };

  /**
   * Before send new tag, need to add the choice ID for exist tag
   */
  handleOnOk = () => {
    const { state: { inputValue }, props: { onOk, onCancel, choices } } = this;

    // Create new Array with new/old choices
    inputValue
      .split('\n')
      .filter((value) => !isEmpty(value))
      .forEach((value) => {
        choices.push({ label: value.trim(), label_en: value.trim() });
      });


    // Return the new list of choices
    onOk(choices, onCancel);
  };


  render() {
    const {
      handleInputChange, handleOnOk,
      props: { isVisible, onCancel },
      state: { inputValue },
    } = this;

    return (
      <Modal
        title={this.props.intl.formatMessage({ id: 'owner.settings.criteriaTable.addChoices.title' })}
        visible={isVisible}
        cancelText={this.props.intl.formatMessage({ id: 'owner.settings.criteriaTable.addChoices.cancel' })}
        onOk={handleOnOk}
        onCancel={onCancel}
      >
        <div className={styles.textAreaContainer}>
          <textarea onChange={handleInputChange} value={inputValue} />
        </div>
      </Modal>
    );
  }
}


AddChoiceModal.propTypes = {
  isVisible: bool,
  choices: array,
  onOk: func,
  onCancel: func,
  intl: object
};


export default injectIntl(AddChoiceModal);
