import { getId } from '@/utils/global';


function getActionParams({ authUser, countOffers, _event }) {
  return ({
    actionParams: {
      name: 'RECRUITER_END_PUBLISHING_OFFERS_ON_EVENT',
      isAdmin: authUser.isAdmin,
      _user: getId(authUser._id),
      _organization: getId(authUser._currentOrganization),
      _event,
      data: {
        countOffers,
      },
    },
  });
}

export default getActionParams;

