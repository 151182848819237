import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { func, object } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { useFormatMessage } from 'react-intl-hooks';
import { H5 } from '@seekube-tech/ui-kit';
import ToJS from '@/utils/toJS';
import { BlocHeader } from '@/components/Bloc';
import { RecipientsList } from '@/scenes/Event/scenes/Owner/scenes/Setting/scenes/Reporting/components/EmailingSchedule/components/Recipients/components/RecipientsList';
import AddRecipients from '@/scenes/Event/scenes/Owner/scenes/Setting/scenes/Reporting/components/EmailingSchedule/components/Recipients/components/AddRecipients';
import { eventActions, eventSelectors } from '@/store/event';
import FormToApi from '@/scenes/Event/scenes/Owner/scenes/Setting/scenes/Modules/services/formMapping/FormToApi';
import styles from './styles.less';

const Recipients = ({ event, patchEvent }) => {
  const t = useFormatMessage();

  const onUpdateRecipients = (recipientsEmails) => {
    const modules = FormToApi.modules(event.modules, 'reports', { recipientsEmails })

    patchEvent({
      eventId: event.slug,
      event: { modules },
    });
  }


  return (
    <div>
      <BlocHeader>
        <H5 className={styles.title}>
          {t({ id: 'recipient.pl' }, { count: 2 })}
        </H5>
      </BlocHeader>

      <AddRecipients
        recipientsEmails={event.modules?.reports.recipientsEmails}
        handleAddRecipient={onUpdateRecipients}
      />
      <RecipientsList
        recipientsMails={event.modules?.reports.recipientsEmails}
        handleRemoveRecipient={onUpdateRecipients}
      />
    </div>
  )
}

Recipients.propTypes = {
  patchEvent: func,
  event: object,
}

const mapDispatchToProps = {
  patchEvent: eventActions.patchEvent,
};

const mapStateToProps = createStructuredSelector({
  event: eventSelectors.getCurrentEvent,
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);


export default compose(withConnect, ToJS)(Recipients)