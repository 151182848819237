import React from 'react';
import { object } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { Alert } from '@seekube-tech/ui-kit';

function AlertTimestamp({
  currentUserInExponent,
  authUser,
}) {
  const t = useFormatMessage();

  const exponentTimezone = get(currentUserInExponent, '_user.timezone');
  const authUserTimezone = authUser.timezone;

  if (isEmpty(exponentTimezone) || isEmpty(authUserTimezone)) return null;

  if (exponentTimezone.offset === authUserTimezone.offset) return null;

  return (
    <Alert color="warning">
      {t({ id: 'event.recruiter.preparation.timeslots.timestamp.warning' }, {
        authUserTimezone: authUserTimezone.text,
        userSelected: currentUserInExponent._user.fullName,
        userSelectedTimezone: exponentTimezone.text,
      })}
    </Alert>);
}

AlertTimestamp.propTypes = {
  currentUserInExponent: object,
  authUser: object,
};

export { AlertTimestamp };
