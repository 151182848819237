import React, { useState } from 'react';
import { object, func, number, any } from 'prop-types';
import { isEmpty } from 'lodash';
import Modal from '@/components/ModalV2';
import PasswordConfig from '@/scenes/Auth/scenes/Invit/components/SignupModal/components/PasswordConfig';
import Identity from '@/scenes/Auth/scenes/Invit/components/SignupModal/components/Identity';
import Sync from '@/scenes/Auth/scenes/Invit/components/SignupModal/components/Sync';
import '@/scenes/Auth/scenes/Invit/components/SignupModal/styles.less';
import { useHistory } from 'react-router-dom';
import { Login } from '@/scenes/Auth/scenes/Login';

const SignupModal = (props) => {
  const { handleGoToNextStep, currentStep, invitationFrom, user, handleSubmitIdentity, handleSetPassword, inviteUser, handleLogin } = props;

  const history = useHistory();

  const handleUpdateSync = () => {
    const { redirect } = window.location.query;

    history.push(redirect || '/events');
  }


  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return renderLoginStep();

      case 2:
        return (
          <Identity handleGoToNextStep={handleGoToNextStep} user={user} handleSubmitIdentity={handleSubmitIdentity} />
        );

      case 3:
        return (
          <Sync onUpdate={() => handleUpdateSync()} />
        );

      default:
        return (
          renderLoginStep()
        );
    }
  };

  const renderLoginStep = () => {
    if ((!inviteUser.isActive) || (inviteUser.provider === 'smartapply' && isEmpty(inviteUser.password))) {
      return (
        <PasswordConfig
          handleGoToNextStep={handleGoToNextStep}
          handleSetPassword={handleSetPassword}
          invitationFrom={invitationFrom}
        />
      );
    }

    return (
      <Login
        user={inviteUser}
        handleSignin={handleLogin}
      />
    );
  };

  return (
    <Modal
      template="default"
      visible
      width="720px"
      footer={false}
      closable={false}
    >
      {renderStep()}
    </Modal>
  );
}

SignupModal.propTypes = {
  handleGoToNextStep: func,
  handleLogin: func,
  currentStep: number,
  handleSetPassword: func,
  invitationFrom: any,
  user: object,
  inviteUser: object,
  handleSubmitIdentity: func,
};

export default SignupModal;
