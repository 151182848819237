import React, { memo, useRef } from 'react';
import { object, func } from 'prop-types';
import { Toggle } from '@seekube-tech/ui-kit';

import stylesTable from '../../../../styles.less';
import styles from './styles.less';

const CriteriaLabel = memo(
  ({ criterion, onChange }) => {
    const inputRef = useRef(null);

    const handleInputChange = (checkboc) => {
      const isHintVisible = typeof checkboc === 'boolean' ? checkboc : inputRef.current.ariaChecked;
      const hint = document.getElementById('hint').value;
      // eslint-disable-next-line camelcase
      const hint_en = document.getElementById('hint_en').value;
      onChange({ hint, hint_en, isHintVisible });
    };


    return (
      <div className={styles.criteriaLabelContainer}>
        <Toggle className="mb-20" checked={criterion.isHintVisible} ref={inputRef} onChange={handleInputChange} />
        <div className={stylesTable.textAreaContainer} style={{ display: 'flex' }}>
          <div style={{ flexGrow: 1 }}>
            <h3>Français</h3>
            <textarea id="hint" onChange={handleInputChange} defaultValue={criterion.hint} maxLength="100" />
          </div>
          <div style={{ flexGrow: 1 }}>
            <h3>Anglais</h3>
            <textarea id="hint_en" onChange={handleInputChange} defaultValue={criterion.hint_en} maxLength="100" />
          </div>
        </div>
      </div>
    );
  }
);

CriteriaLabel.propTypes = {
  criterion: object,
  onChange: func,
};

export { CriteriaLabel };
