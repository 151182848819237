import { useEffect, useState } from 'react';
import { isEmpty, reduce } from 'lodash';
import { queryStringToObject } from '@/utils/url';

export const useRequest = (action, requestName = '', mandatoryParams = []) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState(null);
  const areMandatoryParamsDefined = (requestName, mandatoryParams) => (
    requestName.indexOf('undefined') === -1 &&
    reduce(mandatoryParams, (a, b) => !isEmpty(queryStringToObject(requestName)[b]) && a, true)
  );

  useEffect(() => {
    async function loadData() {
      try {
        setIsLoaded(false);
        const actionData = await action();

        setData(actionData);
        setIsLoaded(true);
      } catch (error) {
        setIsLoaded(true);
        setError(error);
      }
    }

    if (areMandatoryParamsDefined(requestName, mandatoryParams)) {
      loadData();
    }
  }, [requestName]);

  return [data, isLoaded, error];
};