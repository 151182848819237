import React from 'react';
import { Alert, Body1 } from '@seekube-tech/ui-kit';
import { USERS_TYPE } from '@/utils/constants';
import { useFormatMessage } from 'react-intl-hooks';

export const AlertNoAccount = ({ role }) => {
  const t = useFormatMessage();
  return (
    <>
      {[USERS_TYPE?.owner, USERS_TYPE.recruiter].includes(role) && (
        <Alert>
          <div>
            <Body1 fontWeight={600}>{t({ id: 'alert.no.account' })}</Body1>
            <Body1>{t({ id: `alert.no.account.description.${role}` })}</Body1>
          </div>
        </Alert>
      )}
    </>
  )
}