import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { object } from 'prop-types';
import { Col, Row } from 'antd';
import ConferencesList from '@/components/ConferencesList/ConferencesList';
import liveHelpPicture from '@/assets/images/liveHelpPicture.svg';
import { useIsDesktop } from '@/utils/MediaQueries';
import {
  useInfiniteGetOrganizationProfilesPreviewsConferences
} from '@/queries/conferences/useInfiniteGetOrganizationProfilesPreviewsConferences';
import CardInfo
  from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Exponents/scenes/Exponent/components/CardInfo';
import {
  ShowMoreButton
} from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Exponents/scenes/Exponent/components/ShowMoreButton';
import styles from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Exponents/scenes/Exponent/stylesV2.less';
import { getLocale } from '@/utils/localStorage';
import { getId } from '@/utils/global';

const StandConferences = ({
  exponent,
  event
}) => {
  const isDesktop = useIsDesktop();
  const t = useFormatMessage();
  const conferencesInfiniteQuery = useInfiniteGetOrganizationProfilesPreviewsConferences({
    organizationId: getId(exponent?._organization),
    eventId: getId(exponent._event)
  });

  if (!conferencesInfiniteQuery.data) {
    return null;
  }

  return (
    <Row gutter={[32, 32]}>
      <Col xs={12} xl={4} className={isDesktop ? 'float-right' : ''}>
        <CardInfo
          title={t({ id: 'stand.sidebar.cardtitle.lives' }, { label: event.modules?.live[`labelMultiple_${getLocale()}`] })}
          description={t({ id: 'stand.lives.sidebar.description' })}
          img={liveHelpPicture}
        />
      </Col>

        <Col xs={12} xl={8}>
          <div className={styles.tabLeftContent}>
            <Row gutter={[24, 24]}>
              <ConferencesList
                conferences={conferencesInfiniteQuery}
              />
            </Row>
            <ShowMoreButton entityInfiniteQuery={conferencesInfiniteQuery} />
          </div>
        </Col>
      </Row>
  )
}

StandConferences.propTypes = {
  exponent: object,
  event: object
}

export default StandConferences;
