import { createAction } from 'redux-actions';
import * as types from './types';

/**
 * Action for set current exponent
 */
export const setCurrentExponent = createAction(types.SET_CURRENT_EXPONENT);

/**
 * Actions for get an exponent
 */
export const getExponent = createAction(types.GET_EXPONENT);
export const getExponentByOrganization = createAction(types.GET_EXPONENT_BY_ORGANIZATION);
export const getExponentByOrganizationSuccess = createAction(types.GET_EXPONENT_BY_ORGANIZATION_SUCCESS);
export const getExponentByOrganizationError = createAction(types.GET_EXPONENT_BY_ORGANIZATION_ERROR);
export const getExponentSuccess = createAction(types.GET_EXPONENT_SUCCESS);
export const getExponentError = createAction(types.GET_EXPONENT_ERROR);

export const getExponentsByOrganization = createAction(types.GET_EXPONENTS_BY_ORGANIZATION);
export const getExponentsByOrganizationSuccess = createAction(types.GET_EXPONENTS_BY_ORGANIZATION_SUCCESS);
export const getExponentsByOrganizationError = createAction(types.GET_EXPONENTS_BY_ORGANIZATION_ERROR);


/**
 * Actions for get all exponent
 */
export const getExponents = createAction(types.GET_EXPONENTS);
export const getExponentsSuccess = createAction(types.GET_EXPONENTS_SUCCESS);
export const getExponentsError = createAction(types.GET_EXPONENTS_ERROR);

export const getExponentsStats = createAction(types.GET_EXPONENTS_STATS);
export const getExponentsStatsSuccess = createAction(types.GET_EXPONENTS_STATS_SUCCESS);

/**
 * Actions for patch an exponent
 */
export const patchExponent = createAction(types.PATCH_EXPONENT);
export const patchExponentSuccess = createAction(types.PATCH_EXPONENT_SUCCESS);
export const patchExponentError = createAction(types.PATCH_EXPONENT_ERROR);

/**
 * Actions for post an exponent
 */
export const postExponent = createAction(types.POST_EXPONENT);
export const postExponentSuccess = createAction(types.POST_EXPONENT_SUCCESS);
export const postExponentError = createAction(types.POST_EXPONENT_ERROR);

/**
 * Actions for delete an exponent
 */
export const deleteExponent = createAction(types.DELETE_EXPONENT);
export const deleteExponentSuccess = createAction(types.DELETE_EXPONENT_SUCCESS);
export const deleteExponentError = createAction(types.DELETE_EXPONENT_ERROR);

/**
 * Actions for get exponents partners
 */
export const getExponentsPartners = createAction(types.GET_EXPONENTS_PARTNERS);
export const getExponentsPartnersSuccess = createAction(types.GET_EXPONENTS_PARTNERS_SUCCESS);
export const getExponentsPartnersError = createAction(types.GET_EXPONENTS_PARTNERS_ERROR);

/**
 * Actions for patch exponents partners
 */
 export const patchExponentsPartners = createAction(types.PATCH_EXPONENTS_PARTNERS);
 export const patchExponentsPartnersSuccess = createAction(types.PATCH_EXPONENTS_PARTNERS_SUCCESS);
 export const patchExponentsPartnersError = createAction(types.PATCH_EXPONENTS_PARTNERS_ERROR);
