import React from 'react';
import { array } from 'prop-types';
import { isEmpty } from 'lodash';
import classnames from 'classnames';
import { useFormatMessage } from 'react-intl-hooks';

import { getLabel } from '@/components/Corners/utils/label';

import styles from '../../styles.less';

function Corners({ corners }) {
  /* Vars */

  const t = useFormatMessage();

  /* Render */

  return isEmpty(corners) === false ? (
    <div className={styles.corners}>
      {corners.map((corner) => getLabel(corner)).join(' - ')}
    </div>
  ) : (
    <div className={classnames(styles.noCorner, 'text-warning-500')}>
      {t({ id: 'event.modules.corner.not-associate' })}
    </div>
  );
}

Corners.propTypes = {
  corners: array,
};

Corners.defaultProps = {
  corners: [],
};

export { Corners };
