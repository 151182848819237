import React from 'react';
import { string, func } from 'prop-types';
import { IconContract, IconExperience, IconGraduationcap, IconSkills, IconUser } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { Menu, MenuItem, SubMenuItem } from '@/scenes/Settings/MenuSettings';

export const MenuProfile = ({ handleOnNavigate, view }) => {
  const t = useFormatMessage();
  const isMenuActive = view === 'profile';

  return (
    <Menu>
      <MenuItem
        onClick={(e) => handleOnNavigate(e, 'profile', 'bigModalMainPanel')}
        isActive={isMenuActive}
      >
        {t({ id: 'header.userDropdown.profile' })}
      </MenuItem>
      <SubMenuItem onClick={(e) => handleOnNavigate(e, 'profile', 'identity')}>
        <IconUser />{t({ id: 'event.candidate.preparation.signup.step.presentation'})}
      </SubMenuItem>
      <SubMenuItem onClick={(e) => handleOnNavigate(e, 'profile', 'userInfos')}>
        <IconContract />{t({ id: 'event.candidate.preparation.signup.step.resume' })}
      </SubMenuItem>
      <SubMenuItem onClick={(e) => handleOnNavigate(e, 'profile', 'userEducationLevel')}><IconSkills />
        {t({ id: 'education.level'})}
      </SubMenuItem>
      <SubMenuItem onClick={(e) => handleOnNavigate(e, 'profile', 'userEducations')}>
        <IconGraduationcap />{t({ id: 'educations' })}
      </SubMenuItem>
      <SubMenuItem onClick={(e) => handleOnNavigate(e, 'profile', 'userPositions')}>
        <IconExperience />{t({ id: 'experiences'})}
      </SubMenuItem>
    </Menu>
  )
}

MenuProfile.propTypes = {
  handleOnNavigate: func,
  view: string,
}
