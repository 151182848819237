import React from 'react';
import { FormattedMessage } from 'react-intl';
import { string, array, object } from 'prop-types';
import moment from 'moment';
import Icon from '@/components/Icon';
import messages from './messages';
import styles from './styles.less';

const Columns = () => (
  [
    {
      title: <FormattedMessage {...messages.plannerPlgr} values={{ count: 1, gender: 'male' }} />,
      className: styles.planners,
      key: 'recruiters',
      dataIndex: 'planners',
      ellipsis: true,
      maxWidth: 200,
    },
    {
      title: <FormattedMessage id="seekalendar.table.headers.event" />,
      key: 'name',
      dataIndex: 'name',
      className: styles.eventName,
    },
    {
      title: <FormattedMessage {...messages.dates} />,
      key: 'sourcingAppointments',
      dataIndex: 'keyDates',
      render: (keyDates, { keyDatesOk }) => (
        <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
          <Icon className={styles.calendarIcon} name="calendar" />
          {keyDatesOk ? (
            moment(keyDates.jobfair.beginAt).isSame(moment(keyDates.jobfair.endAt), 'month') ?
              <div style={{ paddingLeft: '7px' }}>
                {
                  moment(keyDates.jobfair.beginAt).isSame(moment(keyDates.jobfair.endAt), 'day') ?
                    <span>{moment(keyDates.jobfair.beginAt).format('D')}</span> :
                    (<span>
                      <span>{moment(keyDates.jobfair.beginAt).format('D')}</span> - <span>{moment(keyDates.jobfair.endAt).format('D')}</span>
                    </span>)
                }
                <span style={{ paddingLeft: '5px' }}>{moment(keyDates.jobfair.beginAt).format('MMMM YYYY')}</span>
              </div> :
              <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: '7px' }}>
                <span>{moment(keyDates.jobfair.beginAt).format('Do MMM YYYY')}</span>
                <span>{moment(keyDates.jobfair.endAt).format('Do MMM YYYY')}</span>
              </div>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: '7px' }}>
              <span>{moment(keyDates.jobfair.beginAt).format('MMMM YYYY')}</span>
            </div>)
          }
        </div>
      ),
    },
    {
      title: <FormattedMessage id="seekalendar.table.headers.pricing" />,
      key: 'pricing',
      dataIndex: 'pricing',
      className: styles.pricing,
    },
    {
      title:  <FormattedMessage id="seekalendar.table.headers.format" />,
      key: 'format',
      dataIndex: 'format',
      className: styles.format,
    },
    {
      title: <FormattedMessage id="seekalendar.table.headers.target" />,
      key: 'targets',
      dataIndex: 'targets',
      className: styles.targets,
    },
    {
      title: <FormattedMessage id="seekalendar.table.headers.contract" />,
      key: 'targetContracts',
      dataIndex: 'targetContracts',
      className: styles.targetContracts,

    },
  ]
);

Columns.propTypes = {
  tool: object,
  createdAt: string,
  _organization: object,
  users: array,
};

export default Columns;
