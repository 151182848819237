import { defineMessages } from 'react-intl';

export default defineMessages({
  signupConfirmationTitle: {
    id: 'event.recruiter.preparation.signup.confirmation.title',
  },
  signupConfirmationHelp: {
    id: 'event.recruiter.preparation.signup.confirmation.help',
  },
  signupConfirmationEnd: {
    id: 'event.recruiter.preparation.signup.confirmation.end',
  },
  signupIdentityTitle: {
    id: 'event.recruiter.preparation.signup.identity.title',
  },
  signupIdentityLinkedinImport: {
    id: 'event.recruiter.preparation.signup.identity.linkedin.import',
  },
  signupIdentityLinkedinLoading: {
    id: 'event.recruiter.preparation.signup.identity.linkedin.loading',
  },
  signupIdentityLinkedinSuccess: {
    id: 'event.recruiter.preparation.signup.identity.linkedin.success',
  },
  signupIdentityLinkedinError: {
    id: 'event.recruiter.preparation.signup.identity.linkedin.error',
  },
  signupIdentityLinkedinImportPartial: {
    id: 'event.recruiter.preparation.signup.identity.linkedin.importPartial',
  },
  signupIdentityLinkedinSecurity: {
    id: 'event.recruiter.preparation.signup.identity.linkedin.security',
  },
  signupIdentityPictureUrlHelp: {
    id: 'event.recruiter.preparation.signup.identity.pictureUrl.help',
  },
  signupIdentityPhone: {
    id: 'phone',
  },
  signupPhoneMandatory: {
    id: 'form.phone.mandatory',
  },
  pictureUrlChoose: {
    id: 'pictureUrl.choose',
  },
  signupIdentityPictureUrlRecommandation: {
    id: 'event.recruiter.preparation.signup.identity.pictureUrl.recommendation',
  },
  signupIdentityFormFirstNameError: {
    id: 'event.recruiter.preparation.signup.identity.form.firstName.error',
  },
  signupIdentityFormLastNameError: {
    id: 'event.recruiter.preparation.signup.identity.form.lastName.error',
  },
  signupIdentityFormJobPositionError: {
    id: 'event.recruiter.preparation.signup.identity.form.jobPosition.error',
  },
  signupIdentityFormJobPositionLabel: {
    id: 'event.recruiter.preparation.signup.identity.form.jobPosition.label',
  },
  signupIdentityFormDescriptionLabel: {
    id: 'event.recruiter.preparation.signup.identity.form.description.label',
  },
  finish: {
    id: 'finish',
  },
  signupLoginTitle: {
    id: 'event.recruiter.preparation.signup.login.title',
  },
  signupLoginFormUsernameLabel: {
    id: 'event.recruiter.preparation.signup.login.form.username.label',
  },
  signupLoginFormPasswordLabel: {
    id: 'event.recruiter.preparation.signup.login.form.password.label',
  },
  signupLoginFormPasswordForgot: {
    id: 'event.recruiter.preparation.signup.login.form.password.forgot',
  },
  signupPasswordFormError: {
    id: 'event.recruiter.preparation.password.form.error',
  },
  signupPasswordConfigure: {
    id: 'event.recruiter.preparation.password.configure',
  },
  signupPasswordNew: {
    id: 'event.recruiter.preparation.password.new',
  },
  signupPasswordFormPasswordConfirmError: {
    id: 'event.recruiter.preparation.password.form.passwordConfirm.error',
  },
  signupPasswordFormPasswordConfirmLabel: {
    id: 'event.recruiter.preparation.password.form.passwordConfirm.label',
  },
  signupWelcomeInvitation1: {
    id: 'recruiter.preparation.welcome.invitation',
  },
  signupWelcomeInvitation2: {
    id: 'recruiter.preparation.welcome.invitation2',
  },
  signupWelcomeEventEnd: {
    id: 'event.recruiter.preparation.welcome.event.end',
  },
  signupWelcomeEventStart: {
    id: 'event.recruiter.preparation.welcome.event.start',
  },
  signupWelcomeEventHelp: {
    id: 'event.recruiter.preparation.welcome.help',
  },
  signupWelcomeEventGo: {
    id: 'event.recruiter.preparation.welcome.go',
  },
  firstName: {
    id: 'firstName',
  },
  lastName: {
    id: 'lastName',
  },
  formErrorRequired: {
    id: 'form.error.required',
  },
  login: {
    id: 'login',
  },
  next: {
    id: 'next',
  },
  acceptTerms: {
    id: 'signup.recruiter.acceptTerms',
  },
  mandatory: {
    id: 'form.mandatory',
  },
});
