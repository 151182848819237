import React from 'react';
import { object, func } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { toJS } from '@/utils';

// Store
import { eventActions, eventSelectors } from '@/store/event';

// Components
import { injectIntl } from 'react-intl';
import Form from './components/Form';
import styles from '../../styles.less';

/**
 * PartnersScene
 */
class PartnersScene extends React.PureComponent {
  static propTypes = {
    event: object,
    patchEvent: func,
    intl: object,
  };

  /**
   * Handle the form submit
   * (put the getUploadURL dans le forEach)
   *
   * @param {object} err
   * @param {object} values
   *    next partner to add in the partners list
   * @param {File} fileLogo
   *    logo file for the new partner
   * @param {array} partners
   *    list of partners ( ! without the new one ! )
   */
  handleSubmit = (err, values, fileLogo, partners) => {
    const { props: { event, patchEvent } } = this;

    if (!err && values && values.partnerName && fileLogo) {
      partners.push({ url: fileLogo, name: values.partnerName });

      patchEvent({
        eventId: event.slug,
        event: {
          partners
        },
        notificationParams: {
          success: {
            message: this.props.intl.formatMessage({ id: 'notifications.update.success' }),
            kind: 'info',
            style: {
              bottom: '5%',
              top: 'inherit',
            },
          },
        }
      });
    }
  };

  /**
   * Render
   */
  render() {
    const { props: { event }, handleSubmit } = this;

    return (
      <div className={styles.formWrapper}>
        <Form
          initialValues={event.partners}
          onSubmit={handleSubmit}
        />
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  event: eventSelectors.getCurrentEvent,
});

const mapDispatchToProps = {
  patchEvent: eventActions.patchEvent,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  injectIntl,
)(toJS(PartnersScene));
