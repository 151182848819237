import React from 'react';
import { percentage } from '@/utils/math';
import { object } from 'prop-types';
import { pick, keys, map } from 'lodash';
import BlankState from '@/components/BlankState';
import { Stats, WithProgress } from '@/components/Stats';

import { useFormatMessage } from 'react-intl-hooks';
import styles from './styles.less';

function StatPreparationCompanies({ stats = {}, goals = {}, phases = {} }) {
  const t = useFormatMessage();

  const pageStats = {
    countExponents: {
      title: t({ id: 'stats.exponents' }),
      icon: 'building',
      noSeparator: true,
      additionalText: <WithProgress value={percentage(stats.countExponents, goals.registeredCompanies)} visible={goals.registeredCompanies > 0} />,
      count: 0,
    },
    countExponentsReady: {
      title: t({ id: 'stats.exponentsReady' }),
      icon: 'like',
      noSeparator: true,
      count: 0,
      additionalText: <WithProgress value={percentage(stats.countExponentsReady, stats.countExponents)} />,
    },
    countOffers: {
      title: t({ id: 'stats.offers' }),
      icon: 'star',
      count: 0,

    },
  }

  const statsList = keys(pageStats);
  const statsFound = keys(pick(stats, statsList));

  const result = map(statsFound, (key) => ({ ...pageStats[key], count: stats[key] }));

  if (!phases.enableAddingExponent || stats.countExponents === 0) {
    return (
      <BlankState
        className={styles.blankState}
        title={phases.enableAddingExponent ? t({ id: 'stats.AddingExponent.blank.enabled' }) : t({ id: 'stats.AddingExponent.blank.disabled' })}
        icon="blank-state-cactus-disabled"
      />
    );
  }
  return <Stats list={result} containerClass={styles.statsContainer} />;
}

StatPreparationCompanies.propTypes = {
  stats: object,
  goals: object,
  phases: object,
};

export { StatPreparationCompanies };
