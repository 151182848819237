import { PHASES, PHASE_STATE } from "../../utils/const";

function isEventTypeSupported(type) {
  switch (type) {
    case 'virtual': return true;
    case 'physical': return true;
    case 'hybrid': return true;
    default: return false;
  }
}

export default {
  isAvailable: [
    [
      {
        phase: PHASES.ALL,
        state: PHASE_STATE.ALL
      }
    ],
    [
      {
        phase: PHASES.NONE,
        state: PHASE_STATE.NONE
      }
    ]
  ],
  generateActions: () => ({
    isTypeEnabled: (type) => isEventTypeSupported(type),
  })
}
