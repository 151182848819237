import React from 'react';
import { object, func, bool } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { Typography, Form } from '@seekube-tech/ui';
import { Button, Toggle } from '@seekube-tech/ui-kit';
import { Field } from 'react-final-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// Store
import { eventActions, eventSelectors } from '@/store/event';

import { Bloc, BlocHeader } from '@/components/Bloc';

import useStyles from './styles';

function CoreTarget({ event, patchEvent, isFetching }) {
  /* Vars */

  const t = useFormatMessage();
  const styles = useStyles();
  const initialValues = {
    enableCoreTarget: Boolean(event?.enableCoreTarget),
  };

  /* Functions */

  const handleSubmit = (values = {}) => {
    patchEvent({
      eventId: event.slug,
      event: values,
      notificationParams: {
        success: {
          message: `🙌 ${t({ id: 'toaster.edit.success' })}`,
          kind: 'info',
          style: {
            bottom: '5%',
            top: 'inherit',
          },
        },
      },
    });
  };

  /* Render */

  return (
    <Bloc>
      <BlocHeader>
        <Typography variant="h5">
          {t({ id: 'event.owner.setting.participants.coreTarget.title' })}
        </Typography>
      </BlocHeader>

      <Typography variant="body2" className={styles.description}>
        {t({ id: 'event.owner.setting.participants.coreTarget.description' })}
      </Typography>

      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        subscription={{}}
        render={() => (
          <>
            <div className={styles.field}>
              <Typography variant="body2" color="neutral[400]">
                {t({ id: 'event.owner.setting.participants.coreTarget.label' })}
              </Typography>

              <Field
                name="enableCoreTarget"
                render={({ input }) => (
                  <>
                  <Toggle
                    onChange={input.onChange}
                    checked={input.value}
                    className={styles.switch}
                    disabled={isFetching}
                  /></>
                )}
              />
            </div>

            <Button
              type="submit"
              className={styles.button}
              disabled={isFetching}
            >
              {t({ id: 'save' })}
            </Button>
          </>
        )}
      />
    </Bloc>
  );
}

CoreTarget.propTypes = {
  event: object,
  patchEvent: func.isRequired,
  isFetching: bool,
};

CoreTarget.defaultProps = {
  event: {},
  isFetching: false,
};

const mapStateToProps = createStructuredSelector({
  isFetching: eventSelectors.getEventsFetching,
});

const mapDispatchToProps = {
  patchEvent: eventActions.patchEvent,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(CoreTarget);
