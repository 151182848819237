import React, { useState } from 'react';
import { object, array, func, bool } from 'prop-types';
import { map, isEmpty } from 'lodash';
import { Tab } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { StatBloc } from '@/components/Stats/StatBloc';
import { Tooltip } from '@/components/Tooltip';
import styles from '@/components/Stats/Funnels/styles.less';
import thisStyles from './styles.less';


const StatsConversion = ({ statBlocProperties, conversionOffers = [], conversionLives = [], loading, onActionClick }) => {
  const t = useFormatMessage();
  const [view, setView] = useState('conversionOffers');
  const isStatEmpty = isEmpty((view === 'conversionOffers' && conversionOffers) || (view === 'conversionLives' && conversionLives));

  const statsConversionOffers = map(conversionOffers, (offer) => (
    {
      item: (
        <div className={styles.row}>
          <Tooltip
            title={offer.title}
            color="dark"
            placement="bottom"
          >
            <span>{offer.title}</span>
          </Tooltip>
          <div className={thisStyles.columnStats}><span>{offer.countVisits}</span><span>{offer.countApplications}</span></div>
        </div>
      ),
    }));
  const statsConversionLives = map(conversionLives, (live) => (
    {
      item: (
        <div className={styles.row}>
          <Tooltip
            title={live.title}
            color="dark"
            placement="bottom"
          >
            <span>{live.title}</span>
          </Tooltip>
          <div className={thisStyles.columnStats}><span>{live.countAttendees}</span><span>{live.countParticipants}</span></div>
        </div>
      ),
    }));

  const tabs = (
    <Tab.Group defaultIndex={0} onChange={(index) =>  setView(index === 0 ? 'conversionOffers' : 'conversionLives')}>
      <Tab.List className="text-base">
        <Tab as="span" className="px-16">{t({ id: 'offers.plural' }, { count: 2 })}</Tab>
        <Tab as="span" className="px-16">{t({ id: 'live.pl' }, { count: 2 })}</Tab>
        <div className={styles.tabBarExtraContent}>
          <div className={styles.extraContent}>
            {
              view === 'conversionOffers' && (
                <>
                  <span>{t({ id: 'view.pl' }, { count: 2 })}</span>
                  <span>{t({ id: 'application.pl' }, { count: 2 })}</span>
                </>
              ) }
            {
              view === 'conversionLives' && (
                <>
                  <span>{t({ id: 'signup' })}</span>
                  <span>{t({ id: 'came' }, { count: 2, gender: 'male' })}</span>
                </>
              )}
          </div>
        </div>
      </Tab.List>

      <Tab.Panels className="ant-list-split">
        <Tab.Panel>
          {statsConversionOffers.map(({ item }) => <div className={styles.item}>{item}</div>)}
        </Tab.Panel>
        <Tab.Panel>
          {statsConversionLives.map(({ item }) => <div className={styles.item}>{item}</div>)}
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
  );

  return (
    <StatBloc
      title={t({ id: 'client.stats.conversion.title' })}
      classContainer={thisStyles.statsConversion}
      withAction
      noCount
      loading={loading}
      onActionClick={!isStatEmpty && (() => onActionClick(view))}
      description={tabs}
      {...statBlocProperties}
    />
  );
};

StatsConversion.propTypes = {
  statBlocProperties: object,
  conversionOffers: array,
  conversionLives: array,
  loading: bool,
  onActionClick: func,
};

export { StatsConversion };
