import { defineMessages } from 'react-intl';

export default defineMessages({
  candidates: {
    id: 'candidates',
  },
  recruiters: {
    id: 'recruiters',
  },
  enableSurvey: {
    id: 'form.enableSurvey',
  },
  editSurvey: {
    id: 'form.editSurvey',
  },
  sendSurvey: {
    id: 'form.sendSurvey',
  },
  share: {
    id: 'share',
  },
  showResults: {
    id: 'cta.showResults',
  },
  save: {
    id: 'save',
  },
  feedback: {
    id: 'modules.feedback',
  },
});
