import React from 'react';
import { array, number, string } from 'prop-types';
import { isEmpty } from 'lodash';
import ReactAvatar from 'react-avatar';
import classnames from 'classnames';

import ImgCdn from '@/components/ImgCdn';
import Icon from '@/components/Icon';

// Styles & Translations
import styles from './styles.less';

const defaultColors = [
  '#63D0A8',
  '#6C30F4',
  '#C5357D',
  '#E8AF40',
  '#C7493B',
  '#252D47',
];

/**
 * Avatar
 */
const Avatars = ({ pictures, others, maxPictures, users, className, cssPictureItem, size = 28 }) => {
  const max = maxPictures || 6;
  let renderPictures = [];

  if (pictures) {
    renderPictures = pictures.map((src, index) => {
      if (index < max) {
        return (
          <div className={classnames(styles.pictureItem, cssPictureItem)} key={index}>
            <ImgCdn size={50} src={src} />
          </div>
        );
      }

      return '';
    });
  } else if (users) {
    renderPictures = users.map((user, index) => {
      if (!user) {
        return null;
      }

      const randomInt = !isEmpty(user) && user._id ? user._id.charCodeAt(23) % (defaultColors.length - 1) : 0;
      const color = isEmpty(user) ? '#FFF' : defaultColors[randomInt];
      const src = user.pictureUrl;

      if (index < max) {
        return (typeof src !== 'undefined' && src !== '' && src !== null) ? (
          <div className={classnames(styles.pictureItem, cssPictureItem)} key={index}>
            <ImgCdn src={src} alt="Profile" size={50} />
          </div>
        ) : (
          <div className={classnames(styles.pictureItem, cssPictureItem)} key={index}>
            <ReactAvatar
              name={!isEmpty(user) ? `${user.firstName || ''} ${user.lastName || ''}` : ''}
              round={`${size}px` || false}
              size={size}
              textSizeRatio={2}
              maxInitials={2}
              fgColor={isEmpty(user) ? '#CCC' : '#FFF'}
              value={isEmpty(user) ? (<Icon name="picture" style={{ width: `${size}px`, height: `${size}px` }} />) : null}
              color={color}
              style={isEmpty(user) ? { background: 'transparent' } : null}
            />
          </div>
        );
      }

      return null;
    });
  }

  return (
    <div className={classnames(styles.container, className)}>
      <div className={styles.avatarsContainer}>
        {renderPictures}
      </div>
      {others > 0 ? (
        <div className={styles.countRecruiters} style={others > 99 ? { letterSpacing: '-0.1em' } : null}>{`+${others}`}</div>
      ) : ''}
    </div>
  );
};

Avatars.propTypes = {
  pictures: array,
  className: string,
  others: number,
  cssPictureItem: string,
  maxPictures: number,
  users: array,
};

Avatars.defaultProps = {
  pictures: [],
  others: 0,
  maxPictures: 6,
  users: [],
};

export default Avatars;
