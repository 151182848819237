import React from 'react';
import { object, func } from 'prop-types';
import { isUndefined, get } from 'lodash';

import { Button } from '@seekube-tech/ui-kit';
import { H3 } from '@/components/Title';
import Html from '@/components/Html';
import Icon from '@/components/Icon';

import styles from './styles.less';

const OfferSideContent = ({ offer, onShowExponent }) => (
  <div className={styles.company}>
    {
      get(offer, '_organization.profile.cover') ?
        (<img src={get(offer, '_organization.profile.cover')} alt={offer._organization.name} className={styles.cover} />) : ''
    }
    <img src={get(offer, '_organization.profile.pictureUrl')} alt={offer._organization.name} className={styles.logo} />
    <H3 bold>A propos de {get(offer, '_organization.name')}</H3>
    <div className={styles.highlights}>
      <div>
        <Icon name="tag" className={styles.icon} />
        {get(offer, '_organization.profile.sectors') && get(offer, '_organization.profile.sectors').filter((item) => !isUndefined(item)).map((item) => item.name).join(' - ')}
      </div>
      <div>
        <Icon name="user" className={styles.icon} /> {get(offer, '_organization.profile.size')} employés
      </div>
    </div>

    <p style={{ textAlign: 'left' }}><Html value={get(offer, '_organization.profile.presentation.content')} /></p>
    {
      !isUndefined(onShowExponent) ? (<Button onClick={onShowExponent}>Voir le stand entreprise</Button>) : ''
    }
  </div>
);

OfferSideContent.propTypes = {
  offer: object,
  onShowExponent: func,
};

export default OfferSideContent;
