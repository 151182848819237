import React from 'react';
import { array, object, bool } from 'prop-types';
import { map, size } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { StatBloc } from '@/components/Stats/StatBloc';
import { dateFormat } from '@/utils/format';

import styles from './styles.less';
import messages from '../../messages';

const StatsEvents = ({ statBlocProperties = {}, data, loading }) => {
  const stats = map(data,
    (evt) => ({
      item: (
        <div>
          <span className={styles.date}>
            {dateFormat(evt.keyDates.jobfair.beginAt, evt.keyDates.jobfair.endAt)}
          </span>
          <span>{evt.name} </span>
          <span className={styles.planners}>
            <FormattedMessage {...messages.by} /> {map(evt.organizations, (orga) => orga.name).join(', ')}
          </span>
        </div>),
    }));


  return (
    <StatBloc
      title={<FormattedMessage {...messages.jobdatings} />}
      classContainer={styles.statsEvents}
      loading={loading}
      count={size(data)}
      withList
      dataSource={stats}
      {...statBlocProperties}
    />
  );
};

StatsEvents.propTypes = {
  data: array,
  loading: bool,
  statBlocProperties: object,
};

export { StatsEvents };
