import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { string, func } from 'prop-types';
import colors from '@seekube-tech/ui-kit/dist/colors';
import { Link2, Alert } from '@seekube-tech/ui-kit';
import { useHistory } from 'react-router-dom';
import { API_CUSTOM_ERRORS } from '@/utils/constants';

export const ErrorAlert = ({ apiMessage, onClickToLoginRedirection }) => {
  if (!apiMessage) {
    return null;
  }

  const t = useFormatMessage();

  const getErrorMessageHtml = () => {
    if (apiMessage === API_CUSTOM_ERRORS.wrongPassword) {
      const messagePart1 = t({ id: 'api.error.signup.wrongPassword.part1' });
      const messagePart2 = t({ id: 'api.error.signup.wrongPassword.part2' });

      return (
        <>
          {messagePart1}
          <Link2 className="underline" color={colors.error['600']} onClick={onClickToLoginRedirection}>{messagePart2}</Link2>
        </>)
    } else if (apiMessage === API_CUSTOM_ERRORS.invalidCaptcha) {
      return t({ id: 'signup.error' })
    }

    return null;
  }

  return (
    <Alert color="error" className="mb-20">
      {getErrorMessageHtml()}
    </Alert>
  );
}

ErrorAlert.propTypes = {
  apiMessage: string,
  onClickToLoginRedirection: func,
}
