import React from 'react';
import { func, number, object } from 'prop-types';
import { injectIntl } from 'react-intl';
import { isUndefined } from 'lodash';

import { Button } from '@seekube-tech/ui-kit';
import StepsIndicatorMobile from '../StepsIndicatorMobile';
import StepsIndicator from '../StepsIndicator';
import { BtnFinishLater } from './components'
import messages from '../Identity/messages';


const hasErrors = (fieldsError) => (
  Object.keys(fieldsError).some((field) => fieldsError[field])
);

const FormFooter = ({ form, currentStep, eventSteps, stepPosition, countEnabledSteps, intl, onSubmit }) => {
  if (form && !isUndefined(currentStep)) {

    return (
      <div className="formFooter">
        <StepsIndicatorMobile currentStep={stepPosition + 1} maxStep={countEnabledSteps} />
        <div className="commonFooter">
          <div className="step-navigation-container desktop hidden-mobile" style={{ margin: '0 auto' }}>
            <StepsIndicator eventSteps={eventSteps} currentStep={stepPosition} />
          </div>
          <div className={currentStep === 1  ? 'flex' : ''}>
            { currentStep === 1 && <BtnFinishLater /> }
            <Button
              className="changeStep mr-14"
              disabled={hasErrors(form.getFieldsError())}
              type="submit"
              onClick={onSubmit}
            >
              {intl.formatMessage(messages.next)}
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

FormFooter.propTypes = {
  form: object,
  intl: object,
  eventSteps: object,
  countEnabledSteps: number,
  stepPosition: number,
  currentStep: number,
  onSubmit: func,
};

export default injectIntl(FormFooter);
