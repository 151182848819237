export function getParticipantResumeUrl(participant, eventId, light = false) {
  return `${process.env.FRONT_API_URL}/events/${eventId}/participants/${participant._id}/download-cv?shouldLight=${light}`;
}

export function getUserResumeUrl(light = false) {
  return `${process.env.FRONT_API_URL}/users/me/download-cv?shouldLight=${light}`;
}

export function getUserMediaUrl(s3key, light = false) {
  return `${process.env.FRONT_API_URL}/auth/me/download?key=${s3key}&shouldLight=${light}`;
}
