import React from 'react';
import { array, bool, number } from 'prop-types';
import { last } from 'lodash';

import { Progress } from 'antd';
import { COLORS } from '@/utils/global';


const DEFAULT_PROGRESS_COLORS = [[0, 50, COLORS.error], [50, 79, COLORS.warning], [80, 100, COLORS.success]];

/**
 * @param value
 * @param visible
 * @param {array} colors
 * Progress bar with color.
 * Ex with DEFAULT_PROGRESS_COLORS,
 * if value is between 0 - 50 color will be COLORS.error
 * if value is between 50 - 79 color will be COLORS.warning
 * if value is between 80 - 100 color will be COLORS.success
 */

const WithProgress = ({ value, visible = true, colors = DEFAULT_PROGRESS_COLORS }) => {
  const color = last(colors.find((color) => value >= color[0] && value <= color[1]));

  return visible && <Progress percent={value} size="small" strokeColor={color} />;
};

WithProgress.propTypes = {
  value: number,
  visible: bool,
  colors: array,
};

export { WithProgress }
