import React from 'react';
import { any, object, number, func, array, string, oneOfType, bool } from 'prop-types';
import { isEmpty, isEqual, groupBy, find, uniq, toString } from 'lodash';
import { injectIntl } from 'react-intl';
import moment from 'moment';

// Components
import { Collapse } from 'antd';
import Checkbox from '@/components/Checkbox';
import Icon from '@/components/Icon';
import Badge from '@/components/Badge';
import Separator from '@/components/Separator';
import Avatar from '@/components/Avatar';
import SearchableList from './components/SearchableList';
import TreeFilter from './components/TreeFilter';

// Styles & Translations
import styles from '../../styles.less';
import messages from '../../messages';

const {Panel} = Collapse;
const withSuffixIcon = <span style={{ top: '11px', right: '18px', position: 'absolute', zIndex: 1, fill: '#385077' }}><Icon name="chevron" style={{ width: '24px', height: '24px' }} /></span>;

/**
 * ListItem
 */
const ListItem = ({ id, idPrefix, disabled, checked, loading, label, count, onChange }) => (
  <li key={id} id={id}>
    <label htmlFor={`criterionInput${id}`} aria-disabled={disabled}>
      <Checkbox
        id={`${idPrefix}${id}`}
        value={id}
        onChange={onChange}
        disabled={disabled}
        checked={checked}
      />
      <span className={styles.label}>{label}</span>{' '}
      {count !== undefined ? (<Badge count={count} overflowCount={999} loading={loading} />) : ''}
    </label>
  </li>
);

ListItem.propTypes = {
  id: string,
  idPrefix: string,
  disabled: bool,
  checked: bool,
  loading: bool,
  label: string,
  count: any,
  onChange: func,
};

ListItem.defaultProps = {
  disabled: false,
  checked: false,
  count: null,
};

/**
 * ListHeader
 */
const ListHeader = ({ criterion, locale, selectedItems }) => {
  const choiceIds = criterion._choices.map((choice) => choice.enable && choice._id.toString());
  const selectedItemsInList = selectedItems ? selectedItems.filter((item) => choiceIds.includes(item)) : [];

  return (
    <div className={styles.header}>{locale === 'en' && criterion.modules.searchEngineExponent.label_en ? criterion.modules.searchEngineExponent.label_en : criterion.modules.searchEngineExponent.label} <Badge count={selectedItemsInList.length} overflowCount={999} />
      {withSuffixIcon}
    </div>
  );
};

ListHeader.propTypes = {
  criterion: object,
  locale: string,
  selectedItems: array,
};

/**
 * Filter
 */
class Filter extends React.PureComponent {
  static propTypes = {
    criterion: oneOfType([object, array]),
    stats: object,
    offers: array,
    index: number,
    onChange: func,
    selectedItems: array,
    authUser: object,
    searchKey: string,
    facetKey: string,
    intl: object,
    facetsSyncing: bool,
  };

  static getDerivedStateFromProps(props, state) {
    const { selectedItemsState } = state;
    const { selectedItems } = props;

    if (!isEqual(selectedItems, selectedItemsState)) {
      return { selectedItemsState: isEmpty(selectedItems) ? [] : selectedItems, panel: null };
    }

    return null;
  }

  constructor(props) {
    super(props);

    const { offers, authUser, selectedItems } = props;

    const authUserOffers = [];
    const otherOffers = [];

    if (!isEmpty(offers)) {
      offers.forEach((offer) => {
        if (offer._user && offer._user._id === authUser._id) {
          authUserOffers.push(offer._id);
        } else {
          otherOffers.push(offer._id);
        }
      });
    }

    const selectedOffersItems = authUserOffers.filter((offer) => Array.isArray(selectedItems) && selectedItems.includes(offer));
    const selectedCollaboratorsOffersItems = otherOffers.filter((offer) => Array.isArray(selectedItems) && selectedItems.includes(offer));

    this.state = {
      selectedItemsState: isEmpty(selectedItems) ? [] : selectedItems,
      selectedOffersItemsState: isEmpty(authUserOffers) ? [] : selectedOffersItems,
      selectedSchoolItemsState: [],
      selectedLicenseItemsState: [],
      selectedCollaboratorsOffersItemsState: isEmpty(otherOffers) ? [] : selectedCollaboratorsOffersItems,
      resetActiveKey: false,
      panel: null,
    };
  }

  handleOnChange = (event) => {
    const {
      props: { searchKey, selectedItems, onChange, offers, authUser, facetKey },
      state: { selectedItemsState },
    } = this;

    const {target} = event;

    if (target.checked) {
      selectedItems.push(target.value);
    } else {
      selectedItems.splice(selectedItemsState.indexOf(target.value), 1);
    }

    const authUserOffers = [];
    const otherOffers = [];

    if (!isEmpty(offers)) {
      offers.forEach((offer) => {
        if (offer._user && offer._user._id === authUser._id) {
          authUserOffers.push(offer._id);
        } else {
          otherOffers.push(offer._id);
        }
      });
    }

    const selectedOffersItems = authUserOffers.filter((offer) => Array.isArray(selectedItems) && selectedItems.includes(offer));
    const selectedCollaboratorsOffersItems = otherOffers.filter((offer) => Array.isArray(selectedItems) && selectedItems.includes(offer));

    this.setState({
      selectedItemsState: selectedItems,
      selectedOffersItemsState: selectedOffersItems,
      selectedCollaboratorsOffersItemsState: selectedCollaboratorsOffersItems,
    });

    const returnFacetKey = !isEmpty(selectedItems) ? facetKey : null;

    onChange(searchKey, selectedItems, returnFacetKey);
  };

  handleOnTreeChange = (values) => {
    const {
      props: { searchKey, onChange, offers, authUser, facetKey, criterion },
      state: { selectedItemsState },
    } = this;

    const authUserOffers = [];
    const otherOffers = [];

    if (!isEmpty(offers)) {
      offers.forEach((offer) => {
        if (offer._user && offer._user._id === authUser._id) {
          authUserOffers.push(offer._id);
        } else {
          otherOffers.push(offer._id);
        }
      });
    }

    const allIds = criterion._choices.filter((c) => c.enable).map((c) => c._id.toString());
    const cleanIds = selectedItemsState.filter((n) => allIds.indexOf(n) === -1);
    const selectedItems = uniq([...cleanIds, ...values]);

    const selectedOffersItems = authUserOffers.filter((offer) => Array.isArray(values) && values.includes(offer));
    const selectedCollaboratorsOffersItems = otherOffers.filter((offer) => Array.isArray(values) && values.includes(offer));

    this.setState({
      selectedItemsState: selectedItems,
      selectedOffersItemsState: selectedOffersItems,
      selectedCollaboratorsOffersItemsState: selectedCollaboratorsOffersItems,
    });

    const returnFacetKey = !isEmpty(values) ? facetKey : null;

    onChange(searchKey, selectedItems, returnFacetKey);
  };

  toggleIsOpen = (panel) => {
    this.setState({ panel });
  };

  renderOffer = () => {
    const {
      props: { offers, authUser, stats, intl },
      state: { selectedOffersItemsState, selectedCollaboratorsOffersItemsState },
      handleOnChange,
      toggleIsOpen,
    } = this;

    const authUserOffers = [];
    const otherOffers = [];

    offers.forEach((offer) => {
      if (offer._user && toString(offer._user._id) === toString(authUser._id)) {
        authUserOffers.push(offer);
      } else {
        otherOffers.push(offer);
      }
    });

    const renderOfferItem = (offer, selectedItems, withAvatar) => (
      <li key={offer._id.toString()} id={offer._id.toString()}>
        <label htmlFor={`offerInput${offer._id}`} aria-disabled={stats && !stats[offer._id]}>
          <Checkbox
            id={`offerInput${offer._id}`}
            type="checkbox"
            value={offer._id.toString()}
            onChange={handleOnChange}
            disabled={stats && !stats[offer._id]}
            checked={Array.isArray(selectedItems) && selectedItems.includes(offer._id.toString())}
          />
          <span className={styles.label}>
            {withAvatar ? (<><Avatar src={offer._user.pictureUrl} size={22} user={offer._user} /> </>) : ''}
            {offer.title}
          </span> {stats && stats[offer._id] ? (<Badge count={stats[offer._id]} overflowCount={999} />) : ''}
        </label>
      </li>
    );

    return (
      <div>
        {!isEmpty(authUserOffers) && !isEmpty(offers) ? (
          <Collapse onChange={toggleIsOpen} defaultActiveKey="authOffers">
            {!isEmpty(authUserOffers) ? (
              <Panel
                header={
                  <div className={styles.header}>{intl.formatMessage(messages.myOffers)} <Badge count={selectedOffersItemsState.length} overflowCount={999} />
                    {withSuffixIcon}
                  </div>
                }
                key="authOffers"
                showArrow={false}
              >
                <div className={styles.searchItemsList} id="authOffersPanel">
                  <SearchableList limit={10}>
                    <ul>
                      {authUserOffers.map((offer) => renderOfferItem(offer, selectedOffersItemsState, false))}
                    </ul>
                  </SearchableList>
                </div>
              </Panel>
            ) : null}

            {!isEmpty(otherOffers) ? (
              <Panel
                header={
                  <div className={styles.header}>{intl.formatMessage(messages.teamOffers)} <Badge count={selectedCollaboratorsOffersItemsState.length} overflowCount={999} />
                    {withSuffixIcon}
                  </div>
                }
                key="offers"
                showArrow={false}
              >
                <div className={styles.searchItemsList} id="offersPanel">
                  <SearchableList limit={10}>
                    <ul>
                      {otherOffers.map((offer) => renderOfferItem(offer, selectedCollaboratorsOffersItemsState, false))}
                    </ul>
                  </SearchableList>
                </div>
              </Panel>
            ) : null}
          </Collapse>
        ) : null}

        <Separator height={30} />
      </div>
    );
  }

  renderAvailabilityCriterion = () => {
    const {
      props: { criterion, stats, intl, authUser, index },
      state: { selectedItemsState },
      handleOnChange,
      toggleIsOpen,
    } = this;

    moment.locale(intl.locale);

    const date = new Date();
    const y = date.getFullYear();
    const m = date.getMonth();
    const firstDay = new Date(y, m, 1);
    const dates = Array.from(Array(12).keys()).map((item) => {
      const now = moment(firstDay).add(item, 'M').startOf('month');

      return {
        index: item,
        value: item === 0 ? 'now' : now.format('YYYY-MM'),
        label: item === 0 ? intl.formatMessage(messages.availableToday) : now.format('MMMM YYYY'),
      };
    });

    const selectedItemsInList = selectedItemsState
      .filter((item) => dates.map((date) => date.value.toString()).includes(item.toString()));

    const header = (
      <div className={styles.header}>{authUser.locale === 'en' && criterion.modules.searchEngineExponent.label_en ? criterion.modules.searchEngineExponent.label_en : criterion.modules.searchEngineExponent.label} <Badge count={selectedItemsInList.length} overflowCount={999} />
        {withSuffixIcon}
      </div>
    );

    const items = dates.map((date, index) => (
      <li key={date.value.toString()}>
        <label htmlFor={`criterionDateInput${date.value}`} aria-disabled={stats && !stats[date.value]}>
          <Checkbox
            id={`criterionDateInput${date.value}`}
            value={date.value.toString()}
            onChange={handleOnChange}
            disabled={stats && !stats[date.value]}
            checked={selectedItemsState.includes(date.value.toString()) || false}
          />
          <span className={styles.label} style={{ textTransform: index > 0 ? 'capitalize' : 'default' }}>{date.label}</span> {stats && stats[date.value] ? (<Badge count={stats[date.value]} overflowCount={999} />) : ''}
        </label>
      </li>)
    );

    if ((authUser.locale === 'en' && criterion.modules.searchEngineExponent.label_en) || (authUser.locale === 'fr' && criterion.modules.searchEngineExponent.label)) {
      return (
        <Collapse
          onChange={toggleIsOpen}
        >
          <Panel header={header} key={`criterion${index}`} showArrow={false}>
            <div className={styles.searchItemsList} id={criterion._id}>
              <ul>
                {items}
              </ul>
            </div>
          </Panel>
        </Collapse>
      );
    } return null;
  };

  renderCriterion = () => {
    const {
      props: { index, criterion, stats, authUser, facetsSyncing },
      state: { selectedItemsState },
      handleOnChange,
      toggleIsOpen,
    } = this;

    if ((authUser.locale === 'en' && criterion.modules.searchEngineExponent.label_en) || (authUser.locale === 'fr' && criterion.modules.searchEngineExponent.label)) {
      const header = (
        <ListHeader
          criterion={criterion}
          locale={authUser.locale}
          selectedItems={selectedItemsState}
        />
      );

      const items = criterion._choices.filter((item) => item.enable).map((choice, i) => (
        <ListItem
          key={i}
          id={`${choice._id.toString()}`}
          idPrefix="criterionInput"
          onChange={handleOnChange}
          disabled={stats && !stats[choice._id]}
          checked={selectedItemsState.includes(choice._id.toString())}
          count={stats && stats[choice._id] ? stats[choice._id] : null}
          loading={facetsSyncing}
          label={authUser.locale === 'en' && choice.label_en ? choice.label_en : choice.label}
        />
      ));

      return (
        <Collapse
          onChange={toggleIsOpen}
        >
          <Panel header={header} key={`criterion${index}`} showArrow={false}>
            <div className={styles.searchItemsList} id={criterion._id}>
              <SearchableList limit={10}>
                <ul>
                  {items}
                </ul>
              </SearchableList>
            </div>
          </Panel>
        </Collapse>
      );
    } return null;
  };

  renderPositionCriterion = () => {
    const {
      props: { index, criterion, stats, authUser },
      state: { selectedItemsState },
      toggleIsOpen,
    } = this;

    if (isEmpty(find(criterion._choices, '_parent'))) return this.renderCriterion();

    const getLabelLanguage = (choice) => authUser.locale === 'en' && choice.label_en ? choice.label_en : (choice.label_fr || choice.label);
    const getLabel = (choice) => (<><span className={styles.label}>{getLabelLanguage(choice)}</span> {stats && stats[choice._id] ? (
      <Badge count={stats[choice._id]} overflowCount={999} />) : ''}</>);

    const choiceParents = criterion._choices.filter((choice) => isEmpty(choice._parent) && choice.enable).map((choice) => ({ ...choice, key: choice._id, html: getLabel(choice) }));
    const choiceChildren = groupBy(criterion._choices.filter((choice) => choice.enable && choice._parent).map((choice) => ({ ...choice, key: choice._id, html: getLabel(choice), disabled: stats && !stats[choice._id] })), (choice) => choice._parent);

    const choiceIds = criterion._choices.filter((c) => c.enable).map((choice) => choice._id.toString());
    const selectedItemsInList = selectedItemsState.filter((item) => choiceIds.includes(item));

    const header = (
      <div className={styles.header}>{authUser.locale === 'en' && criterion.modules.searchEngineExponent.label_en ? criterion.modules.searchEngineExponent.label_en : criterion.modules.searchEngineExponent.label} <Badge count={selectedItemsInList.length} overflowCount={999} />
        {withSuffixIcon}
      </div>
    );

    if ((authUser.locale === 'en' && criterion.modules.searchEngineExponent.label_en) || (authUser.locale === 'fr' && criterion.modules.searchEngineExponent.label)) {
      return (
        <Collapse
          onChange={toggleIsOpen}
        >
          <Panel header={header} key={`criterion${index}`} showArrow={false}>
            <div className={styles.searchItemsList} id={criterion._id}>
              <SearchableList limit={10}>
                <ul>
                  <TreeFilter checkedKeys={selectedItemsState} onCheckChange={this.handleOnTreeChange} treeParents={choiceParents} treeChildren={choiceChildren} />
                </ul>
              </SearchableList>
            </div>
          </Panel>
        </Collapse>
      );
    } return null;
  };

  renderSchool = () => {
    const {
      props: { stats, intl },
      state: { selectedItemsState },
      handleOnChange,
      // toggleIsOpen,
    } = this;

    const headerOffers = (
      <div className={styles.header}>{intl.formatMessage(messages.schools)} <Badge count={selectedItemsState.length} overflowCount={999} />
        {withSuffixIcon}
      </div>
    );

    return (
      <Collapse>
        <Panel header={headerOffers} key="schools" showArray={false}>
          <div className={styles.searchItemsList} id="schoolsPanel">
            {!isEmpty(stats) ? (
              <SearchableList limit={10}>
                <ul>
                  {Object.keys(stats).sort((a, b) => stats[b] - stats[a]).map((item, index) => (
                    <li key={item} id={`searchable${item}`}>
                      <label htmlFor={`schoolInput${index}`}>
                        <Checkbox
                          id={`schoolInput${index}`}
                          type="checkbox"
                          value={item}
                          onChange={handleOnChange}
                          checked={Array.isArray(selectedItemsState) && selectedItemsState.includes(item)}
                        />
                        <span className={styles.label}>{ item }</span> {stats && stats[item] ? (<Badge count={stats[item]} overflowCount={999} />) : ''}
                      </label>
                    </li>
                  ))}
                </ul>
              </SearchableList>
            ) : (<span>{intl.formatMessage(messages.noResult)}</span>)}
          </div>
        </Panel>
      </Collapse>
    );
  }

  renderLicenseCriterion = () => {
    const {
      props: { criterion, stats, authUser, index, facetsSyncing },
      state: { selectedItemsState },
      handleOnChange,
      toggleIsOpen,
    } = this;

    const choices = [{ ...criterion._choices[0], id: 'true', value: true }, { ...criterion._choices[1], id: 'false', value: false }];

    const header = (
      <ListHeader
        criterion={criterion}
        locale={authUser.locale}
        selectedItems={selectedItemsState}
      />)
    ;

    const items = choices.map((item) => (
      <ListItem
        id={`${item.value.toString()}`}
        idPrefix="criterionLicenseInput"
        onChange={handleOnChange}
        checked={selectedItemsState.includes(item.value.toString())}
        count={stats && stats[item.value.toString()] ? stats[item.value.toString()] : null}
        loading={facetsSyncing}
        label={authUser.locale === 'en' && item.label_en ? item.label_en : item.label}
      />
    ));

    if ((authUser.locale === 'en' && criterion.modules.searchEngineExponent.label_en) || (authUser.locale === 'fr' && criterion.modules.searchEngineExponent.label)) {
      return (
        <Collapse
          onChange={toggleIsOpen}
        >
          <Panel header={header} key={`criterion${index}`} showArrow={false}>
            <div className={styles.searchItemsList} id={criterion._id}>
              <SearchableList limit={10}>
                <ul>
                  {items}
                </ul>
              </SearchableList>
            </div>
          </Panel>
        </Collapse>
      );
    } return null;
  };

  renderExperienceCriterion = () => {
    const {
      props: { index, criterion, stats, authUser, facetsSyncing },
      state: { selectedItemsState },
      handleOnChange,
      toggleIsOpen,
    } = this;

    const choiceIds = criterion._choices.map((choice, index) => index.toString());
    const selectedItemsInList = selectedItemsState.filter((item) => choiceIds.includes(item));
    const header = (
      <div className={styles.header}>{authUser.locale === 'en' && criterion.modules.searchEngineExponent.label_en ? criterion.modules.searchEngineExponent.label_en : criterion.modules.searchEngineExponent.label} <Badge count={selectedItemsInList.length} overflowCount={999} />
        {withSuffixIcon}
      </div>
    );

    const items = criterion._choices.map((choice, index) => {
      const itemId = `${index}`;

      return (
        <ListItem
          id={`${itemId}`}
          idPrefix="criterionInput"
          onChange={handleOnChange}
          disabled={stats && !stats[itemId]}
          checked={selectedItemsState.includes(itemId)}
          count={stats && stats[itemId] ? stats[itemId] : null}
          loading={facetsSyncing}
          label={authUser.locale === 'en' && choice.label_en ? choice.label_en : choice.label}
        />
      );
    });

    if ((authUser.locale === 'en' && criterion.modules.searchEngineExponent.label_en) || (authUser.locale === 'fr' && criterion.modules.searchEngineExponent.label)) {
      return (
        <Collapse
          onChange={toggleIsOpen}
        >
          <Panel header={header} key={`criterion${index}`} showArrow={false}>
            <div className={styles.searchItemsList} id={criterion._id}>
              <SearchableList limit={10}>
                <ul>
                  {items}
                </ul>
              </SearchableList>
            </div>
          </Panel>
        </Collapse>
      );
    } return null;
  };

  render() {
    const {
      props: { offers, criterion, searchKey },
      renderCriterion, renderAvailabilityCriterion, renderPositionCriterion, renderExperienceCriterion, renderOffer, renderSchool, renderLicenseCriterion,
    } = this;

    if (searchKey === 'schools') {
      return renderSchool();
    } if (typeof criterion === 'object') {
      if (criterion.modules.searchEngineExponent.isVisible && criterion.enable) {
        if (criterion.key === 'AVAILABILITY') {
          return renderAvailabilityCriterion();
        } if (criterion.key === 'LICENSE') {
          return renderLicenseCriterion();
        } if (criterion.key === 'POSITION') {
          return renderPositionCriterion();
        } if (criterion.key === 'EXPERIENCE') {
          return renderExperienceCriterion();
        }

        return renderCriterion();
      }

      return null;
    } if (!isEmpty(offers)) {
      return renderOffer();
    }

    return '';
  }
}

export default injectIntl(Filter);

