import React from 'react';
import { bool, func } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Icon from '@/components/Icon';
import { useKeyPress } from '@/utils/hooks';
import messages from '../../messages';

const CardNavigation = ({ isPreviousVisible, isNextVisible, handlePrevious, handleNext }) => {
  useKeyPress({ targetKey: 'ArrowLeft', callback: handlePrevious });
  useKeyPress({ targetKey: 'ArrowRight', callback: handleNext });

  return (
    <>
      {isPreviousVisible ? (<div role="button" tabIndex={0} className="participant-arrow participant-previous" onClick={() => handlePrevious()}><div><Icon name="arrow-left" /><FormattedMessage {...messages.previousParticipant} /></div></div>) : ''}
      {isNextVisible ? (<div role="button" tabIndex={0} className="participant-arrow participant-next" onClick={() => handleNext()}><div><Icon name="arrow-right" /><FormattedMessage {...messages.nextParticipant} /></div></div>) : ''}
    </>
  );
};

CardNavigation.propTypes = {
  isPreviousVisible: bool,
  isNextVisible: bool,
  handlePrevious: func,
  handleNext: func,
};


export { CardNavigation };
