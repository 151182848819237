import React, { PureComponent } from 'react';
import { any, string, bool } from 'prop-types';
import classNames from 'classnames';

// Components
import { If } from 'react-if';

// Styles & Translations
import styles from './styles.less';


class SceneContainer extends PureComponent { // eslint-disable-line
  static defaultProps = {
    size: 'default',
    withOverlay: false,
  };


  render() {
    const { props: { size, children, withOverlay, withNav, withContainer, withFill } } = this;

    const containerClass = classNames(
      withContainer ? styles.container : null,
      withNav ? null : styles.marginTop,
      styles[size],
      withOverlay ? styles.containerOverlay : null,
    );
    const wrapperClass = classNames(
      withFill ? styles.fillPage : null,
    );

    return (
      <div className={wrapperClass}>
        <If condition={withOverlay}>
          <div className={styles.overlay} />
        </If>
        <div className={containerClass}>
          {children}
        </div>
      </div>
    );
  }
}

SceneContainer.propTypes = {
  children: any,
  size: string,
  withOverlay: bool,
  withNav: bool,
  withContainer: bool,
  withFill: bool,
};

export default SceneContainer;
