import React, { useState } from 'react';
import { object, func } from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useFormatMessage } from 'react-intl-hooks';
import { toJS } from '@/utils';
import { checkIfPropertyChanged } from '@/utils/global';
import 'moment-timezone';

// Store
import { eventActions, eventSelectors } from '@/store/event';

// Components
import LoadingIndicator from '@/components/LoadingIndicator';
import { authSelectors } from '@/store/auth';
import { GeneralInfos, KeyDatesBloc, Modules, Planners, SkipAppointmentValidation } from './components';

// Services
import styles from '../../styles.less';


function BasicInformationScene({ event, patchEvent, authUser }) {
  const t = useFormatMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [saved, setSaved] = useState(false);

  const handleSubmit = (err, values) => {
    if (!err) {
      setIsLoading(true);
      patchEvent({
        eventId: event.slug,
        event: values,
        params: {
          type: checkIfPropertyChanged(event, values, 'format') ? 'medium-has-changed' : null,
        },
        notificationParams: {
          success: {
            message: t({ id: 'notifications.update.success' }),
            kind: 'info',
            style: {
              bottom: '5%',
              top: 'inherit',
            },
          },
          error: true,
        },
        callback: () => {
          setIsLoading(false);
          setSaved(true);
        }
      });
    }
  };


  if (!event) {
    return (<LoadingIndicator />);
  }

  return (
    <div className={styles.formWrapper}>
      <GeneralInfos event={event} handleSubmit={handleSubmit} />

      <Planners />

      <Modules {...event} handleSubmit={handleSubmit} />

      <SkipAppointmentValidation {...event} handleSubmit={handleSubmit} />

      <KeyDatesBloc event={event} handleSubmit={handleSubmit} isLoading={isLoading} hasSavedDates={saved} />
    </div>
  );
}

BasicInformationScene.propTypes = {
  event: object,
  patchEvent: func,
  authUser: object
};

const mapStateToProps = createStructuredSelector({
  event: eventSelectors.getCurrentEvent,
  authUser: authSelectors.getAuthUser
});

const mapDispatchToProps = {
  patchEvent: eventActions.patchEvent,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(toJS(BasicInformationScene));
