import { useMutation, useQueryClient } from 'react-query';
import { map } from 'lodash';
import request from '@/utils/request';

export function useUpdateDeal(
  {
    organizationId,
    onSuccess = () => {},
    page,
  }) {
  const queryClient = useQueryClient();
  const mutationFn = ({ _id, body }) => {
    const requestUrl = `${process.env.FRONT_API_URL}/deals/${_id}`;

    return (
      request(requestUrl, {
        method: 'PATCH',
        body: JSON.stringify({ ...body, _organization: organizationId }),
      })
    )
  }

  return useMutation({
    mutationFn,
    onSuccess: async (deal) => {
      const deals = queryClient.getQueryData(['deals', page]);
      const newDocs = map(deals.docs, (prevDeals) => prevDeals._id === deal._id ? deal : prevDeals);
      const newDeals = { ...deals, docs: newDocs };

      queryClient.setQueryData(['deals', page], newDeals);
      onSuccess();
      return newDeals;
    },
  });
}
