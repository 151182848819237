import moment from 'moment';
import { IconEdit } from '@seekube-tech/ui-kit';
import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import {
  DealStatusTag
} from '@/scenes/Admin/scenes/Organizations/scenes/Organization/scenes/Details/components/DealsHandler/DealStatusTag';
import {
  DealTypeTag
} from '@/scenes/Admin/scenes/Organizations/scenes/Organization/scenes/Details/components/DealsHandler/DealTypeTag';

const Columns = ({ actions }) => {
  const t = useFormatMessage();
  const now = moment();

  return (
    [
      {
        id: 'name',
        Header: t({ id: 'offer.type' }),
        accessor: (deal) => (
          <div><DealTypeTag deal={deal}/></div>
        ),
      },
      {
        id: 'isActive',
        Header: t({ id: 'status.b' }),
        accessor: (deal) => (
          <div><DealStatusTag deal={deal} now={now}/></div>
        ),
      },
      {
        id: 'beginAt',
        Header: t({ id: 'beginAt' }),
        accessor: (record) => (
          <div>{moment(record.beginAt).format('DD/MM/YYYY')}</div>
        ),
      },
      {
        id: 'endAt',
        Header: t({ id: 'endAt' }),
        accessor: (deal) => (
          <div>{moment(deal.endAt).format('DD/MM/YYYY')}</div>
        ),
      },
      {
        id: 'actions',
        Header: '',
        disableSortBy: true,
        accessor: (deal) => (
          <div role="button" tabIndex={0} onClick={() => actions.onEditClick(deal)}>
            {!deal.isReadOnly && <IconEdit />}
          </div>
        ),
      },

    ]

  );
}

export { Columns }