import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { compose } from 'redux';
import SignupDev from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/SignupDev/SignupDev';
import Profile from '@/scenes/Auth/scenes/CandidateScenes/Profile';
import { withLayout } from '@/containers/Layout';

const CandidateScenes = (props) => (
    <Switch>
      <Route exact path={`${props.match.path}`} component={(routeProps) => <SignupDev {...routeProps} {...props} />} />
      <Route exact path={`${props.match.path}/profile`} component={(routeProps) => <Profile {...routeProps} {...props} />} />
    </Switch>
  )

export default compose(
  withLayout({ size: 'full', navType: 'candidate' }),
)(CandidateScenes);
