import React from 'react';
import { string } from 'prop-types';
import moment from 'moment';
import { useFormatMessage } from 'react-intl-hooks';
import { IconUser } from '@seekube-tech/ui-kit';
import { useLocalStorage } from 'react-use';
import styles from './styles.less';

const PreregistrationDate = ({ beginAt, locale }) => {
  const t = useFormatMessage();

  moment.locale(locale);

  if (moment().isAfter(moment(beginAt))) return null;

  return (
    <div className={styles.eventDateContainer}>
      <IconUser className={styles.calendarIcon} />
      {`${t({ id: 'events.stats.candidate.opening' })}
          ${moment(beginAt).format('D MMMM YYYY')}
        `}
    </div>
  )
}

PreregistrationDate.propTypes = {
  beginAt: string,
  locale: string
}

export default React.memo(PreregistrationDate);
