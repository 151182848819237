import React from 'react';
import { bool, func, object } from 'prop-types';
import { Button, ModalConfirmation as ModalConfirmationUi } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';

export const ModalConfirmation = ({ isVisible, onCancel, onConfirm, modalProperty }) => {
  const t = useFormatMessage();

  const handleOnConfirm = (e) => {
    modalProperty.onSubmit(e)
    onConfirm();
  }

  return (
    <ModalConfirmationUi
      visible={isVisible}
      title={modalProperty.text}
      description={modalProperty.helpText}
      actions={
        <div className="confirm-actions mt-40">
          <Button className="mr-6" variant="tonal" color="neutral" onClick={onCancel}>
            {modalProperty.okCta || t({ id: 'cancel' })}
          </Button>
          <Button
            color="error"
            onClick={handleOnConfirm}>
            {modalProperty.cancelCta || t({ id: 'btn.confirm' })}
          </Button>
        </div>
      }
    />
  )
}

ModalConfirmation.propTypes = {
  isVisible: bool,
  modalProperty: object,
  onCancel: func,
  onConfirm: func,
}
