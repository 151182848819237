import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import request from '@/utils/request';
import { getCurrentOrganization } from '@/store/organization/selectors';
import { getId } from '@/utils/global';

export function useGetOrganizationUsersSelect({
  organization,
  ...props }) {
  const organizationId = getId(organization) || useSelector(getCurrentOrganization)?.toJS()?._id;

  const requestUrl = `${process.env.FRONT_API_URL}/organizations/${organizationId}/users/users-select?sort=lastName`;

  const queryFn = () => request(requestUrl, { method: 'GET' });

  const query = useQuery({
    queryKey: ['organizationUsersSelect', organizationId],
    queryFn,
    initialData: [],
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: !!organizationId,
    ...props
  });

  return query;
}
