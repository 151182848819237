
const isProduction = process.env.NODE_ENV === 'production';

  export const REPORT_PHASES = {
  registrationAndPreparationOfCompanies: 'registration-and-preparation-of-companies',
  registrationAndActivitiesOfCandidates: 'registration-and-activities-of-candidates',
  appointmentProcessing: 'appointment-processing',
  closingOfAppointmentSchedules: 'closing-of-appointment-schedules',
  review: 'review',
}

export const REPORT_STATUS = {
  pending: 'pending',
  ok: 'ok',
  ko: 'ko',
};

const REPORT_CUSTOMERIO_DEV = {
  [REPORT_PHASES.registrationAndPreparationOfCompanies]: 'https://fly.customer.io/env/17112/composer/actions/1000640',
  [REPORT_PHASES.registrationAndActivitiesOfCandidates]: 'https://fly.customer.io/env/17112/composer/actions/1000644',
  [REPORT_PHASES.appointmentProcessing]: 'https://fly.customer.io/env/17112/composer/actions/1000643',
  [REPORT_PHASES.closingOfAppointmentSchedules]: 'https://fly.customer.io/env/17112/composer/actions/1000642',
  [REPORT_PHASES.review]: 'https://fly.customer.io/env/17112/composer/actions/1000671',
};

const REPORT_CUSTOMERIO_PROD = {
  [REPORT_PHASES.registrationAndPreparationOfCompanies]: 'https://fly.customer.io/env/71417/composer/actions/520',
  [REPORT_PHASES.registrationAndActivitiesOfCandidates]: 'https://fly.customer.io/env/71417/composer/actions/530',
  [REPORT_PHASES.appointmentProcessing]: 'https://fly.customer.io/env/71417/composer/actions/527',
  [REPORT_PHASES.closingOfAppointmentSchedules]: 'https://fly.customer.io/env/71417/composer/actions/524',
  [REPORT_PHASES.review]: 'https://fly.customer.io/env/71417/composer/actions/533',
};

export const REPORT_CUSTOMERIO = isProduction ? REPORT_CUSTOMERIO_PROD : REPORT_CUSTOMERIO_DEV;