import { defineMessages } from 'react-intl';

export default defineMessages({
  pdfErrorUpload: {
    id: 'pdf.error.upload',
  },
  pdfErrorSize: {
    id: 'pdf.error.size',
  },
  resumeErrorSize: {
    id: 'form.resume.error.size',
  },
  pdfZoom: {
    id: 'pdf.zoom',
  },
  pdfDownload: {
    id: 'pdf.download',
  },
  pdfUpload: {
    id: 'pdf.upload',
  },
  pdfError: {
    id: 'pdf.error',
  },
  pdfNone: {
    id: 'pdf.none',
  },
  resumeErrorFormat: {
    id: 'form.resume.error.format',
  },
  notificationBadFormat: {
    id: 'event.recruiter.preparation.stand.notification.bad.format',
  },
});
