import React, { useEffect } from 'react';
import { bool, func, object, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { isEmpty } from 'lodash';
import { useFormatMessage } from 'react-intl-hooks';
import toJS from '@/utils/toJS';
import { track } from '@/utils/analytics';
import { ACL, ANALYTICS_RECRUITER } from '@/utils/constants';

// Store
import { userActions } from '@/store/user';

// Components
import ModalBig from '@/components/ModalBig';
import ModalV2 from '@/components/ModalV2';
import { authSelectors } from '@/store/auth';
import UserForm from './components/UserForm';

// Styles & Translations
import messages from '../../messages';

/**
 * EditUserModal
 */
const EditUserModal = ({
  isOpen,
  onClose,
  authUser,
  user,
  patchUser,
  onSubmit,
  exponent,
  organization,
  context = undefined,
}) => {
  const t = useFormatMessage();
  const [error, setError] = React.useState({ visible: false, title: '', description: '' });

  useEffect(() => {
    if (isOpen) {
      setError({ visible: false, title: '', description: '' });
    } }, [isOpen]);

  const handleOnSubmit = (values) => {

    patchUser({
      noRefresh: true,
      userId: user._id,
      userParams: { ...values, organizationId: organization?._id },
      notificationParams: {
        success: {
          message: `👍 ${t(messages.notificationSuccessCollaboratorUpdate)}`,
          kind: 'info',
          style: {
            bottom: '5%',
            top: 'inherit',
          },
        },
        error: false,
      },
      callback: (err) => {
        if (typeof onSubmit === 'function') {
          if (isEmpty(err?.message)) {
            onSubmit();
            onClose();
          } else {
            setError({ visible: true, title: t({ id: 'form.set.role.error.no.admin.title' }), description: t({ id: 'form.set.role.error.no.admin.description' }) });
          }
        }

        track({
          user,
          event: exponent && exponent._event,
          name: ANALYTICS_RECRUITER.EDIT_PROFILE,
          properties: {
            step: 'Settings',
            photo: !isEmpty(values.pictureUrl),
            role: exponent && exponent.role,
            summary: !isEmpty(values.description),
            myProfile: authUser && authUser._id === user._id,
            full: !isEmpty(values.pictureUrl) && !isEmpty(values.title) && !isEmpty(values.firstName) && !isEmpty(values.lastName),
          },
        });
      },
    });
  }

  return (
    <ModalV2
      visible={isOpen}
      onClose={onClose}
    >
      <div>
        <h1 className="mainTitle" style={{ marginBottom: 30 }}>
          {context === ACL.EDIT_ROLE ?
            t({ id: 'event.recruiter.preparation.team.collaborator.role.update' }) :
            t({ id: 'event.recruiter.preparation.team.collaborator.infos.update' })
          }
        </h1>
        {user && isOpen &&
          <UserForm
            user={user}
            context={context}
            onSubmit={handleOnSubmit}
            organization={authUser?._currentOrganization?.isClient ? organization : authUser._currentOrganization }
            error={error}
          />}
      </div>
    </ModalV2>
  );
}

EditUserModal.propTypes = {
  isOpen: bool,
  onClose: func,
  authUser: object,
  user: object,
  patchUser: func,
  onSubmit: func,
  exponent: object,
  organization: object,
  context: string,
};

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser,
});

const mapDispatchToProps = {
  patchUser: userActions.patchUser,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(toJS(EditUserModal));
