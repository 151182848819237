import { makeStyles } from '@seekube-tech/ui';

export default makeStyles(theme => ({
  input: {
    height: '41px !important',

    '&::placeholder': {
      opacity: '1 !important',
      color: theme.palette.grey['400'],
      fontWeight: 400,
    },
  },
  error: {
    borderColor:  `#F95377 !important`
  }
}));
