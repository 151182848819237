import { isEmpty } from 'lodash';
import moment from 'moment';
import { getId } from '@/utils/global';


export const getIsSlotRemovable = (
  slot = {},
  eventKeyMoments,
  currentEvent
) => (isEmpty(slot._appointment) &&
  slot.isSaved &&
  (getId(currentEvent) === getId(slot._event) ||
    (isEmpty(slot._event) &&
      moment(slot.start).isBetween(moment().toDate(), eventKeyMoments.endAt))
  ));
