import React from 'react';
import { Input } from '@/components/Input';
import Icon from '@/components/Icon';
import { object, func } from 'prop-types';
import styles from './styles.less'

const SchoolName = ({ school, handleEditName }) => {
  const onSaveSchoolNameClick = () => {
    handleEditName(school, document.getElementById(`${school.name}Id`).value);
  };

  return (
    <span className={styles.schoolName}>
      <Input
        id={`${school.name}Id`}
        addonAfter={
          <span role="button" tabIndex={0} onClick={onSaveSchoolNameClick}><Icon name="save" /></span>}
        defaultValue={school.name}
        placeholder={school.name}
      />
    </span>
  );
};

SchoolName.propTypes = {
  school: object,
  handleEditName: func,
};

export { SchoolName };
