import React from 'react';
import { object, string } from 'prop-types';
import { Badge } from 'antd/lib/index';
import { NavLink } from 'react-router-dom';
import { Button } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { NavScenePortal } from '@/containers/Layout';
import styles from '@/scenes/Event/scenes/Recruiter/scenes/Jobdating/components/NavJobdating/styles.less';
import Icon from '@/components/Icon';

const NavAdminCandidates = ({ match, stats, redashUrl }) => {
  const t = useFormatMessage();
  const overflowCount = 9999;

  return (
    <NavScenePortal portalId="subNavScene">
      <div className={styles.container} style={{ borderTop: '1px solid #E6EAF2' }}>
        <div className={styles.navEventContainer}>
          <div className={styles.navEvent}>
            <div className={styles.navEventItem}>
              <NavLink to={`${match.url}/all`} activeClassName="activeNavLink">
                <Badge count={stats.adminall} showZero overflowCount={overflowCount} />
                <span>{t({ id: 'participants.results.all' })}</span>
              </NavLink>
            </div>

            <div className={styles.navEventSeparator} />

            <div className={styles.navEventItem}>
              <NavLink to={`${match.url}/top`} activeClassName="activeNavLink">
                <Badge count={stats.admintop} showZero overflowCount={overflowCount} />
                <span>{t({ id: 'participants.results.top' })}</span>
              </NavLink>
            </div>

            <div className={styles.navEventItem}>
              <NavLink to={`${match.url}/save`} activeClassName="activeNavLink">
                <Badge count={stats.adminsave} showZero overflowCount={overflowCount} />
                <span>{t({ id: 'participants.results.save' })}</span>
              </NavLink>
            </div>

            <div className={styles.navEventItem}>
              <NavLink to={`${match.url}/refuse`} activeClassName="activeNavLink">
                <Badge count={stats.adminrefuse} showZero overflowCount={overflowCount} />
                <span>{t({ id: 'participants.results.refuse' })}</span>
              </NavLink>
            </div>

            <div className={styles.navEventItem}>
              <a href={redashUrl} target="_blank" style={{ padding: 0 }}>
                <Button imageComponentLeft={<Icon name="redash" style={{ marginRight: 10 }} />} style={{ paddingLeft: 10 }}>
                  Redash
                </Button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </NavScenePortal>
  );
};

NavAdminCandidates.propTypes = {
  redashUrl: string,
  match: object,
  stats: object,
};

export default NavAdminCandidates;
