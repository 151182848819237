import React, { useEffect, useState } from 'react';
import { object} from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { useFormatMessage } from 'react-intl-hooks';
import { authActions } from '@/store/auth';
import { userActions } from '@/store/user';
import { queryStringToObject } from '@/utils/url';
import { track } from '@/utils/analytics';
import { ANALYTICS_RECRUITER } from '@/utils/constants';
import Wrapper from '@/components/Wrapper';
import LoadingIndicator from '@/components/LoadingIndicator';
import { getAuthInviteUser, getAuthUser, getIsChecking } from '@/store/auth/selectors';
import request from '@/utils/request';
import SignupModal from '../Invit/components/SignupModal';

/**
 * <RecruiterPreparationSignupScene />
 *
 * @description : Main scene for organization user signup
 *
 */
const AuthSmartApplyScene = ({
  match,
  history,
}) => {
  const t = useFormatMessage();
  const authUser = useSelector(getAuthUser)?.toJS();
  const authInviteUser = useSelector(getAuthInviteUser)?.toJS();
  const isChecking = useSelector(getIsChecking);

  const dispatch = useDispatch();

  const [currentStep, setCurrentStep] = useState(1)
  const [interaction, setInteraction] = useState({})
  const [appointment, setAppointment] = useState({})
  const [event, setEvent] = useState({})

  useEffect(() => {
    dispatch(authActions.authCheckInvitation(match.params.token));
  }, [])

  useEffect(() => {
    // get interaction
    request(`${process.env.FRONT_API_URL}/events/${location.query.eventSlug}/interactions/${location.query.interactionId}`)
      .then((res) => {
        setInteraction(res)
      });

    request(`${process.env.FRONT_API_URL}/events/${location.query.eventSlug}`)
      .then((res) => {
        setEvent(res)
      });
  })

  // When the candidate is authentify, redirect to the offer
  useEffect(() => {
    if (!isEmpty(interaction) && !isEmpty(event)) {
      // get appointment
      request(`${process.env.FRONT_API_URL}/events/${location.query.eventSlug}/appointments/${interaction._appointment}`)
        .then((res) => {
          setAppointment(res.appointment)
        });
    }
    if ((!isEmpty(interaction) && !isEmpty(event) && (!isEmpty(appointment) || interaction?._appointment === null))) {
      handleRedirect()
    }
  }, [interaction, event, location, appointment])

  const handleOnGoToNextStep = (e, nextStep) => {
    setCurrentStep(nextStep)
  }

  const handleOnLogin = (values) => {
    dispatch(authActions.authLogin(values));
  }

  const handleRedirect = () => {
    if (location.query.redirectUrl) {
      location.replace(location.query.redirectUrl)
    }
    else if (appointment?.canceledBy === 'recruiter') {
      history.push(`/${location.query.eventSlug}/candidate/jobdating/jobs/${location.query.offerId}`)
    }
    else if (interaction._appointment && interaction.pending) {
      history.push(`/${location.query.eventSlug}/candidate/jobdating/appointment/${interaction._appointment}?provider=smartapply`)
    }
    else if (!interaction.pending) {
      history.push(`/${location.query.eventSlug}/candidate/jobdating/jobs/${location.query.offerId}`)
    }
    else {
      history.push(`/${location.query.eventSlug}/candidate/jobdating/jobs`)
    }
  }

  /**
   * handleOnSetPassword
   *
   * @description : Handle password submit
   *
   */
  const handleOnSetPassword = (password) => {
    dispatch(authActions.authChangePassword({
        password,
        resetToken: match.params.token,
      },
    ))
  }

  const handleOnSubmitIdentity = (fields) => {
    dispatch(userActions.patchMe({
      ...fields,
      callback: () =>
        track({
          authUser,
          name: ANALYTICS_RECRUITER.EDIT_PROFILE,
          properties: {
            step: 'Sign up',
            photo: !isEmpty(fields.userParams.pictureUrl),
            summary: !isEmpty(fields.userParams.description),
            myProfile: true,
            full: !isEmpty(fields.userParams.pictureUrl) && !isEmpty(fields.userParams.title) && !isEmpty(fields.userParams.firstName) && !isEmpty(fields.userParams.lastName) && !isEmpty(fields.userParams.description),
          },
        }),
      notificationParams: {
        success: {
          message: t({ id: 'notifications.update.success' }),
          kind: 'info',
          style: {
            bottom: '5%',
            top: 'inherit',
          },
        },
      }
    }));
  };

  if (isChecking !== false) {
    return (<LoadingIndicator />);
  }

  const urlParams = queryStringToObject(location.search);

  const { from, pictureUrl } = urlParams;

  return (
    <Wrapper>
      {/* <StaticOffers /> */}
      <SignupModal
        user={authUser}
        inviteUser={authInviteUser}
        currentStep={currentStep}
        handleLogin={handleOnLogin}
        handleGoToNextStep={handleOnGoToNextStep}
        handleSetPassword={handleOnSetPassword}
        invitationFrom="smartapply"
        picture={pictureUrl}
        handleSubmitIdentity={handleOnSubmitIdentity}
      />
    </Wrapper>
  );
}

AuthSmartApplyScene.propTypes = {
  match: object,
  history: object
}

export default AuthSmartApplyScene;
