import { defineMessages } from 'react-intl';

export default defineMessages({
  tableTitle: {
    id: 'upcoming.events',
  },
  collaboratorTh: {
    id: 'collaborater.pl',
  },
  by: {
    id: 'by',
  },
  tooltipLiveNotAllowed: {
    id: 'client.member.tooltip.not.allow.to.publish.live',
  },
  tooltipUsersMaxReached: {
    id: 'client.member.tooltip.users.max.reached',
  },
  tooltipUsersMaxInfo: {
    id: 'client.member.tooltip.users.max.info',
  },
  tooltipOffersMaxInfo: {
    id: 'client.member.tooltip.offers.max.info',
  },
  tooltipLivesMaxInfo: {
    id: 'client.member.tooltip.lives.max.info',
  },
  liveMaxTh: {
    id: 'live.max',
  },
  offerMaxTh: {
    id: 'offers.max',
  },
  usersMaxTh: {
    id: 'users.max',
  },
  actionPl: {
    id: 'action.pl',
  },
});
