import React from 'react';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { object, func, shape, array, string } from 'prop-types';
import { isEmpty } from 'lodash';
import { Form } from 'antd';
import { Button, H5 } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import CandidateProfileSearchForm from '@/components/CandidateProfileSearchForm';
import { toJS } from '@/utils/index';
import { H3 } from '@/components/Title';
import Separator from '@/components/Separator';
import IdentityCandidate from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/Signup/components/SignupModal/components/Identity';
import UserInfos from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/Signup/components/SignupModal/components/UserInfos';
import UserExperience from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/Signup/components/SignupModal/components/UserExperience';
import styles from '@/scenes/Settings/styles.less';
import EducationLevel
  from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/Profile/Educations/EducationLevel';
import UserFormation from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/Signup/components/SignupModal/components/UserFormation';
import Identity from './components/Identity';

function General({ handleSaveUserKey, intl, authUser, form, defaultCriteria }) {
  const t = useFormatMessage();

  const handleOnSaveUser = (values) => {
    const userParams = {
      ...values,
    };

    if (!values.usernameTemp) {
      userParams.notificationParams = {
        success: {
          message: intl.formatMessage({ id: 'form.information.success' }),
          kind: 'info',
          style: {
            bottom: '5%',
            top: 'inherit',
          },
        },
      };
    }
    handleSaveUserKey(userParams);
  };


  const filters = authUser?.matching?.filters || [];

  return (
    <>
      <div id="profile">
        {!isEmpty(authUser._currentOrganization) ? (
          <div id="identity">
            <Identity authUser={authUser} onSave={handleOnSaveUser} />
            <Separator height={40} />
          </div>
        ) : (
          <div id="identity" className={styles.emailFormWrapper}>
            <IdentityCandidate
              handleDataUpdate={handleOnSaveUser}
              user={authUser}
              context="settings"
            />
          </div>
        )}

        {isEmpty(authUser._currentOrganization) ? (
          <div id="userInfos" className={styles.emailFormWrapper}>
            <UserInfos
              handleDataUpdate={handleOnSaveUser}
              user={authUser}
              context="settings"
            />
          </div>
        ) : ''}

        {isEmpty(authUser._currentOrganization) ? (
          <div id="userEducationLevel" className={styles.emailFormWrapper}>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleOnSaveUser({
                  matching: {
                    ...authUser.matching,
                    level: form.getFieldValue('educationLevel'),
                    filters,
                  },
                });
              }}
            >
              <H3 bold>
                <span>{intl.formatMessage({ id: 'education.level' })}</span>
                <span role="img" aria-label="Hands up"> 🙌</span>
              </H3>
              <div style={{ position: 'relative', zIndex: 10 }}>
                <EducationLevel
                  form={form}
                  matching={authUser.matching || { filters: [] }}
                  defaultCriteria={defaultCriteria}
                />
              </div>
              <Button
                type="submit"
                disabled={!form.getFieldValue('educationLevel')}
              >
                {intl.formatMessage({ id: 'save' })}
              </Button>
            </Form>
          </div>
        ) : ''}

        {isEmpty(authUser._currentOrganization) ? (
          <div className={styles.emailFormWrapper}>
            <UserFormation
              handleDataUpdate={handleOnSaveUser}
              user={authUser}
              context="settings"
            />
          </div>
        ) : ''}

        {isEmpty(authUser._currentOrganization) ? (
          <div className={styles.emailFormWrapper}>
            <UserExperience
              handleDataUpdate={handleOnSaveUser}
              user={authUser}
              context="settings"
            />
          </div>
        ) : ''}

        {isEmpty(authUser._currentOrganization) ? (
          <div id="userSearch" className={styles.emailFormWrapper}>
            <H5 className="mb-24">{t({ id: 'signup.search.title' })}</H5>
            <CandidateProfileSearchForm onSubmit={(values) => {
              handleOnSaveUser({
                matching: {
                  ...authUser.matching,
                  ...values
                },
              });
            }} />
          </div>
        ) : ''}
      </div>
    </>
  );
}


General.propTypes = {
  authUser: object,
  intl: object,
  form: shape({
    getFieldDecorator: func,
    getFieldsError: func,
    getFieldError: func,
    isFieldTouched: func,
  }),
  handleSaveUserKey: func,
  defaultCriteria: array,
};

const withForm = Form.create();

export default compose(
  injectIntl,
  withForm,
)(toJS(General));
