import { makeStyles } from '@seekube-tech/ui';

export default makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 5.5),

    '& hr': {
      borderBottom: `0.1px solid ${theme.palette.neutral['200']}`,
      borderTop: 0,
      margin: theme.spacing(1.5, 0),
    }
  },


}));
