import React, { useState } from 'react';
import { string, object, any, number, array, func } from 'prop-types';
import classnames from 'classnames';

import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { toJS } from '@/utils';
import { compose } from 'redux';
import { isEmpty, get } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { Button } from '@seekube-tech/ui-kit';

import { queryStringToObject } from '@/utils/url';

import { authSelectors } from '@/store/auth';
import { eventSelectors, eventActions } from '@/store/event';
import { participantSelectors } from '@/store/participant';
import { trackRecruiterDownloadedSeveralCvs } from '@/utils/analytics';

import ModalV2 from '@/components/ModalV2';
import Icon from '@/components/Icon';

import messages from './messages';
import styles from './styles.less';

const DownloadCvs = ({ exportResumes, participantIds, context, event, authUser, totalParticipants, participantStats, children }) => {
  const [result, setResult] = useState(null);
  const countParticipants = context === 'all' ? get(participantStats, 'all') : totalParticipants;

  const onDownloadClick = (e) => {
    e.preventDefault();

    exportResumes({
      participantIds,
      context,
      search: queryStringToObject(window.location.search),
      event,
      callback: (res) => {
        trackRecruiterDownloadedSeveralCvs({ authUser, count: countParticipants });
        setResult(res);
      },
    });
  };

  return (
    <>
      <ModalV2
        triggerComponent={children || <a><Icon name="download" /><FormattedMessage {...messages.downloadCvs} /></a>}
        className={classnames(styles.statModal, 'customConfirm')}
        afterClose={() => setResult(null)}
        template="center"
      >
        { isEmpty(result) ?
          <div>
            <div className={styles.modalHeader}>
              <FormattedMessage {...messages.downloadXCvs} values={{ count: countParticipants }}>
                {(child) => <h3><Icon name="download" />{child}</h3>}
              </FormattedMessage>
            </div>

            <FormattedMessage {...messages.confirmDownload} tagName="p" />
            <div className="confirm-actions">
              <Button onClick={onDownloadClick}><FormattedMessage {...messages.btnConfirm} /></Button>
            </div>
          </div>
          :
          <DownloadSuccess {...result} email={authUser.username} />
        }
      </ModalV2>
    </>
  );
};

const DownloadSuccess = (props) => (
  <div>
    <div className={styles.modalHeader}>
      <h3 style={{ fontSize: '43px' }}><span aria-label="" role="img">✅</span></h3>
      <FormattedMessage {...messages.downloadConfirmed} tagName="h3" />
    </div>

    <SuccessDownloadContent {...props} />
  </div>
);

const SuccessDownloadContent = ({ data: { countItems }, email }) => {
  if (countItems < 150) return <FormattedMessage {...messages.downloadedOto100} values={{ email }} tagName="p" />;
  if (countItems <= 1000) return <FormattedMessage {...messages.downloaded10Oto1000} values={{ email }} tagName="p" />;

  return <FormattedMessage {...messages.downloadedMore1000} values={{ email }} tagName="p" />;
};

SuccessDownloadContent.propTypes = {
  data: object,
  email: string,
};

DownloadCvs.propTypes = {
  exportResumes: func,
  email: string,
  participantIds: array,
  context: string,
  event: object,
  authUser: object,
  totalParticipants: number,
  participantStats: object,
  children: any,
};

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser,
  event: eventSelectors.getCurrentEvent,
  participantStats: participantSelectors.getParticipantsStats,
});

const mapDispatchToProps = {
  exportResumes: eventActions.exportResumes,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, toJS)(DownloadCvs);
