import React from 'react';
import { object, array, func } from 'prop-types';
import { Tab } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { useHistory } from 'react-router-dom';
import { getLocale } from '@/utils/localStorage';
import styles from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Exponents/scenes/Exponent/stylesV2.less';
import {
  HASH
} from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Exponents/scenes/Exponent/components/const';

const StandCandidateNavBar = ({
  match,
  availableTabs,
  labels,
  onChangeTab
}) => {
  const history = useHistory();
  const t = useFormatMessage();
  const labelMultiple = `labelMultiple_${getLocale()}`

  if (availableTabs?.length === 0) {
    return null
  }

  function getCurrentTabName() {
    switch (history.location.pathname) {
      case `${match.url}/${HASH.offer}`:
        return HASH.offer
      case `${match.url}/${HASH.conference}`:
        return HASH.conference
      case `${match.url}/${HASH.informal1to1}`:
        return HASH.informal1to1
      default:
        return 'home'
    }
  }

  function getCurrentIndex() {
    return tabs.find((tab) => tab.paramValue === getCurrentTabName())?.index || 0;
  }

  const tabs = [
    {
      name: labels?.home?.[labelMultiple] ?? t({ id: `stand.navbar.home` }),
      param: '',
      paramValue: 'home',
    },
    {
      name: labels.offer?.[labelMultiple] ?? t({ id: `stand.navbar.offers` }),
      param: `/offer`,
      paramValue: 'offer',
    },
    {
      name: labels.live?.[labelMultiple] ?? t({ id: `live.pl` }, { count: 2 }),
      param: `/conference`,
      paramValue: 'conference',
    },
    {
      name: labels.informal1to1?.[labelMultiple] ?? t({ id: `stand.navbar.informal1to1s` }),
      param: '/informal1to1',
      paramValue: 'informal1to1',
    },
  ]
    .filter(tab => ['home', ...availableTabs].includes(tab.paramValue))
    .map((tab, index )=> ({ ...tab, index }));

  const currentIndex = getCurrentIndex();

  const onIndexChange = (index) => {
    history.push(`${match.url}${tabs[index].param}`);
    onChangeTab();
  }

  return (
    <>
      <div className={styles.standNavBarContainer}>
        <Tab.Group selectedIndex={currentIndex} onChange={onIndexChange}>
          <Tab.List>
            {tabs.map(({ name }) => (
              <Tab key={name} as="span">
                {name}
              </Tab>
            ))}
          </Tab.List>
        </Tab.Group>
      </div>

    </>
  );
}

StandCandidateNavBar.propTypes = {
  match: object,
  availableTabs: array,
  labels: object,
  onChangeTab: func,
}

export default StandCandidateNavBar;
