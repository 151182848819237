
import { call, put, takeLatest } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import request from '@/utils/request';
import * as actions from './actions';
import * as types from './types';
import { entitiesActions } from '../entities';
import { sectorListSchema } from './schema';
import { trackError } from '../../utils/analytics/helpers';

/**
 * Get exponent
 */
function* getSectors() {
  const requestUrl = `${process.env.FRONT_API_URL}/sectors`;

  try {
    const result = yield call(request, requestUrl);
    const items = result.docs || result;

    // Normaliz data
    const dataNormalized = normalize(items, sectorListSchema);

    // Save entities
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    // Save all ids
    yield put(actions.getSectorsSuccess({
      result: dataNormalized.result,
      items: result.docs,
    }));
  } catch (err) {
    trackError(err);
  }
}

/**
 * Listen Actions
 */
export default [
  takeLatest(types.GET_SECTORS, getSectors),
];

