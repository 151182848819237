import { defineMessages } from 'react-intl';

export default defineMessages({
  shareVisioTitle: {
    id: 'shareVisio.title',
  },
  copyLink: {
    id: 'copy.link',
  },
  copySuccess: {
    id: 'copy.success2',
  },
});

