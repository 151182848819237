import React from 'react';
import { object, func } from 'prop-types';
import { injectIntl } from 'react-intl';
import { H5 } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import Recruiter from '@/scenes/Settings/components/Notifications/Recruiter';
import styles from '@/scenes/Settings/styles.less';

const Notifications = ({ authUser, handleSaveUserKey }) => {
  const t = useFormatMessage();

  return (
    <div className={styles.emailFormWrapper}>
      <H5 className="mb-20">{t({ id: 'profile.settings.notification.title' })}</H5>
      <Recruiter authUser={authUser} handleSaveUserKey={handleSaveUserKey}/>
    </div>
  );
};

Notifications.propTypes = {
  authUser: object,
  handleSaveUserKey: func,
};

export default injectIntl(Notifications);
