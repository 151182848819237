import sectorReducer from './reducer';
import * as sectorTypes from './types';
import * as sectorActions from './actions';
import * as sectorSelectors from './selectors';
import sectorSagas from './sagas';

export {
  sectorTypes,
  sectorActions,
  sectorSelectors,
  sectorSagas,
};

export default sectorReducer;
