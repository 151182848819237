import React from 'react';
import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import StandForm from '@/forms/Stand';
import { toJS } from '@/utils';

import { organizationSelectors } from '@/store/organization';
import { exponentActions, exponentSelectors } from '@/store/exponent';


function ClientStandScene({ organization, isFetching }) {

  return (
    <StandForm organization={organization} isFetching={isFetching} context="owner"/>
  );
}

ClientStandScene.propTypes = {
  organization: object.isRequired,
  isFetching: bool,
};

ClientStandScene.defaultProps = {
  isFetching: false,
};

const mapStateToProps = createStructuredSelector({
  organization: organizationSelectors.getCurrentOrganization,
  isFetching: exponentSelectors.getExponentsFetching,
});

const mapDispatchToProps = {
  getExponentByOrganization: exponentActions.getExponentByOrganization,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(toJS(ClientStandScene));
