import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'client.create.offer.matching.title',
  },
  subtitle: {
    id: 'client.create.offer.matching.subtitle',
  },
  criterionSpecific: {
    id: 'client.create.offer.matching.specific',
  },
});
