import React from 'react';
import { percentage } from '@/utils/math';
import { object, bool } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { COLORS } from '@/utils/global';
import { pick, keys, map, omit } from 'lodash';
import BlankState from '@/components/BlankState';
import { Stats, WithProgress } from '@/components/Stats';

import styles from './styles.less';


const StatsAppointments = ({ stats = {}, goals = {}, active, showUnconfirmed }) => {
  const t = useFormatMessage();

  const pageStats = {
    countAppointmentsUnconfirmed: {
      title: t({ id: 'stats.appointmentsUnconfirmed' }),
      icon: 'calendar',
      noSeparator: true,
      additionalText: <WithProgress value={percentage(stats.countAppointmentsUnconfirmed, goals.appointmentsValidated)} visible={goals.appointmentsValidated > 0} />,
      count: 0,
    },
    countAppointments: {
      title: t({ id: 'stats.appointments' }),
      icon: 'iconAccept',
      noSeparator: true,
      additionalText: <WithProgress value={percentage(stats.countAppointments, goals.appointmentsValidated)} visible={goals.appointmentsValidated > 0} />,
      count: 0,
    },
    countExponentsWithAppointments: {
      title: t({ id: 'stats.exponentsWithAppointments' }),
      icon: 'activity',
      noSeparator: true,
      count: 0,
      additionalText: <WithProgress value={percentage(stats.countExponentsWithAppointments, stats.countExponents)} colors={[[0, 50, COLORS.error], [50, 69, COLORS.warning], [70, 100, COLORS.success]]} />,
    },
  }
  const statsList = showUnconfirmed ? keys(pageStats) : keys(omit(pageStats, ['countAppointmentsUnconfirmed']));
  const statsFound = keys(pick(stats, statsList));

  const result = map(statsFound, (key) => ({ ...pageStats[key], count: stats[key] }));

  if (!active) {
    return (
      <BlankState
        className={styles.blankState}
        title={t({ id: 'stats.appointments.blankState' })}
        icon="blank-state-cactus-disabled"
      />
    );
  }
  return <Stats list={result} containerClass={styles.statsContainer} />;
};

StatsAppointments.propTypes = {
  stats: object,
  goals: object,
  active: bool,
  showUnconfirmed: bool,
};

export { StatsAppointments };
