import React, { useEffect, useRef, useState, useMemo } from 'react'
import { findIndex, get, isEmpty, size } from 'lodash';
import { object, func, array, bool, string, number } from 'prop-types';
import colors from '@seekube-tech/ui-kit/dist/colors';
import classnames from 'classnames';
import { useFormatMessage } from 'react-intl-hooks';
import { FormattedHTMLMessage } from 'react-intl';
import { Button, Body2, Subtitle2, Link2} from '@seekube-tech/ui-kit';
import { getId } from '@/utils/global';
import { EVENT_FORMAT, SLOT_TYPE } from '@/utils/constants'

import { Tooltip } from '@/components/Tooltip';
import Checkbox from '@/components/Checkbox';

import request from '@/utils/request';
import { TestVisioModal } from '@/features/agenda/recruiter/components/CalendarSettings/components/Medium/TestVisioModal';
import Separator from "@/components/Separator";
import { InformalDescription, PickSlotType } from '@/features/agenda/recruiter/components/CalendarSettings/components';

import styles from '../styles.less';
import ParticipationMode from './components/ParticipationMode';
import Location from './components/Location';
import Duration from './components/Duration';
import Medium from './components/Medium';

const LINK_HELP_CREATE_SLOT = 'https://intercom.help/seekube/fr/articles/5300322-fondamentaux-de-la-phase-de-preparation-d-un-jobdating#h_21916b7280';

const CalendarSettings = ({
  authUser,
  currentUserInExponent,
  handleOnSubmit,
  handleOnSettingsChange,
  handleOnCancel,
  currentEvent,
  timeslots,
  settings,
  countEventAvailable,
  postAction,
  style = {},
  exponentFormat,
  isSubmitLoading,
}) => {
  const refButtonSubmit = useRef(null);
  const [testModalIsVisible, setTestModalIsVisible] = useState(false)
  const [visioUrl, setVisioUrl] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const t = useFormatMessage();
  const isPhysicalEvent = settings.medium === 'physical';
  const isInformal1to1ModuleEnable = currentEvent?.modules?.informal1to1?.enable || false;
  const isOfferModuleEnable = currentEvent?.modules?.offer?.enable || false;

  function displayLocation() {
    if (isEmpty(currentUserInExponent)) return null;

    const initialValue = currentEvent.location?.formattedAdress;

    return (
      isPhysicalEvent &&
      <Location
        handleLocationChange={(val) => handleOnSettingsChange('location', val)}
        formKey={currentUserInExponent._id}
        initialValue={initialValue}
        disabled
      />);
  }

  function useIsInViewport(ref) {
    const [isIntersecting, setIsIntersecting] = useState(false);

    const observer = useMemo(
      () =>
        new IntersectionObserver(([entry]) =>
          setIsIntersecting(entry.isIntersecting),
        ),
      [],
    );

    useEffect(() => {
      observer.observe(ref.current);

      return () => {
        observer.disconnect();
      };
    }, [ref, observer]);

    return isIntersecting;
  }

  useEffect(() => {
    if (settings.isVisible) {
      const settingsSlot = document.getElementById('settingsSlot');
      settingsSlot.scroll(0, 0);
    }
  }, [settings.isVisible])

  const createVisioTest = async () => {
    if (!isLoading) {
      setIsLoading(true);

      request(`${process.env.FRONT_API_URL}/appointments/visio-test`, {
        method: 'GET',
      }).then((res) => {
        const { url } = res;
        setVisioUrl(url);
        window.open(url, '_blank');

        setTestModalIsVisible(true);
        setIsLoading(false);
      });

      postAction({
        actionParams: {
          name: 'RECRUITER_TEST_VISIO',
          _user: authUser._id,
          _organization: authUser?._currentOrganization?._id,
          data: {
            url: window.location.href,
            provider: 'Teams',
          }
        },
      });
    }
  }

  const canSubmit =
    // informal slot type without informal description
    ((settings.type === SLOT_TYPE.INFORMAL1TO1 && settings.informalDescription && settings.informalDescription.length > 0) ||
      settings.type !== SLOT_TYPE.INFORMAL1TO1) &&
    // slot for a physical event should have a location
    ((isPhysicalEvent && currentEvent.location?.formattedAdress && currentEvent.location?.formattedAdress.length > 0) || !isPhysicalEvent) &&
    !isEmpty(timeslots);

  return (
    <div>
      <div className={classnames(settings.isVisible && styles.active, styles.settings, !useIsInViewport(refButtonSubmit) && styles.settingsWithBlur)} id="settingsSlot">
        <div style={{ ...style }}>
          <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <div className={styles.settingsHeader} style={{ marginTop: '7px', marginBottom: '14px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Subtitle2 className="text-neutral-500" fontWeight={800}>
                {t({ id: 'calendar.settings.submit.text' }, { count: 0 })}
              </Subtitle2>
              <a href={LINK_HELP_CREATE_SLOT} target="_blank">{t({ id: 'help' })}</a>
            </div>

            {!isInformal1to1ModuleEnable && currentEvent.format === EVENT_FORMAT.HYBRID && exponentFormat === EVENT_FORMAT.HYBRID &&
              <>
                <div className="mb-12">
                  <Body2 fontWeight={700} className="text-neutral-500">
                    {t({ id: 'client.create.offer.step.participationMode' }, { count: 1 })}
                  </Body2>
                </div>
                <ParticipationMode
                  disabled={!settings.isParticipationHybrid || isEmpty(currentUserInExponent)}
                  style={{ opacity: isEmpty(currentUserInExponent) ? 0.8 : 1, marginBottom: '18px' }}
                  className={styles.inputSelect}
                  onParticipationModeChange={(val) => handleOnSettingsChange('participationMode', val)}
                  value={settings.participationMode}
                />
                <p className={styles.inputDescription}>{t({ id: 'calendar.settingsSlots.participationMode.description' })}</p>
              </>
            }
            {
              isInformal1to1ModuleEnable && isOfferModuleEnable && (
                <>
                  {currentEvent.format === EVENT_FORMAT.HYBRID && settings.isParticipationHybrid && exponentFormat === EVENT_FORMAT.HYBRID &&
                    <>
                      <div style={{ marginBottom: '12px' }}>
                        <Body2 fontWeight={700} color={colors.neutral['500']}>
                          {t({ id: 'client.create.offer.step.participationMode' }, { count: 1 })}
                        </Body2>
                      </div>
                      <ParticipationMode
                        disabled={isEmpty(currentUserInExponent)}
                        style={{ opacity: isEmpty(currentUserInExponent) ? 0.8 : 1, marginBottom: '18px' }}
                        className={styles.inputSelect}
                        onParticipationModeChange={(val) => handleOnSettingsChange('participationMode', val)}
                        value={settings.participationMode}
                      />
                      <p className={styles.inputDescription}>Le mode de rencontre contextualise les jours durant lesquelles vous pourrez ajouter des créneaux</p>
                      <div className={styles.separator}></div>
                    </>
                  }
                  <div style={{ marginBottom: '12px' }}>
                    <Body2 fontWeight={700} color={colors.neutral['500']}>
                      {t({ id: 'client.create.offer.step.typeMode' }, { count: currentEvent.format === EVENT_FORMAT.HYBRID && settings.isParticipationHybrid && exponentFormat === EVENT_FORMAT.HYBRID ? 2 : 1 })}
                    </Body2>
                  </div>
                  <PickSlotType slotType={settings.type} handleChangeType={(val) => handleOnSettingsChange('type', val)} />
                </>
              )
            }
            {(currentEvent.format === EVENT_FORMAT.HYBRID || isInformal1to1ModuleEnable) && isOfferModuleEnable &&
              <>
                {(isInformal1to1ModuleEnable || settings.isParticipationHybrid) && <div className={styles.separator}></div>}
                <div style={{ marginBottom: '12px' }}>
                  <Body2 fontWeight={700} color={colors.neutral['500']}>
                    {isInformal1to1ModuleEnable &&
                      t({ id: 'client.create.offer.step.medium' }, { count: settings.isParticipationHybrid ? 3 : 2 })
                    }
                    {!isInformal1to1ModuleEnable &&
                      t({ id: 'client.create.offer.step.medium' }, { count: settings.isParticipationHybrid ? 2 : 1 })
                    }
                  </Body2>
                </div>
              </>
            }
            <div className={styles.slotParameterOptions}>
              <Medium
                disabled={isEmpty(currentUserInExponent)}
                style={{ opacity: isEmpty(currentUserInExponent) ? 0.8 : 1 }}
                onMediumChange={(val) => handleOnSettingsChange('medium', val)}
                value={settings.medium}
                participationMode={settings.participationMode}
                eventFormat={currentEvent.format}
                hasAtLeastOneInterview={findIndex(timeslots, (slot) => getId(currentEvent) === getId(slot._event) && get(slot, '_appointment._id')) > -1}
              />

              <Duration
                disabled={isEmpty(currentUserInExponent)}
                style={{ opacity: isEmpty(currentUserInExponent) ? 0.8 : 1, marginBottom: '18px' }}
                className={styles.inputSelect}
                onDurationChange={(val) => handleOnSettingsChange('duration', val)}
                value={settings.duration}
              />

            </div>

            {settings.medium === 'visio' ? (
              <>
                <Link2 onClick={createVisioTest}>{t({ id: 'calendar.settingsSlot.testVisio' })}</Link2>
                <Separator height={16} />
              </>
            ) : null}

            {settings.medium === 'physical' && displayLocation()}

            <TestVisioModal
              testUrl={visioUrl}
              visible={testModalIsVisible}
              handleSelectedMode={(val) => {
                if (val !== 'visio') {
                  handleOnSettingsChange('medium', val);
                }

                setTestModalIsVisible(false);
              }}
              handleCloseModal={() => setTestModalIsVisible(false)}
            />

            {
              settings.type === SLOT_TYPE.INFORMAL1TO1 && (
                <InformalDescription
                  settings={settings}
                  handleOnBlur={(val) => handleOnSettingsChange('informalDescription', val)} />
              )
            }

            <div style={{ marginTop: '20px', paddingBottom: '20px' }}>
              {
                !isPhysicalEvent && countEventAvailable > 1 &&
                <>
                  <Checkbox
                    checked={!settings.isMultiEvents}
                    onChange={(val) => handleOnSettingsChange('isMultiEvents', val)}
                    disabled={settings.type === SLOT_TYPE.INFORMAL1TO1}
                  >
                    <span style={{ color: '#385077' }}>
                      {t({ id: 'calendar.settings.checkbox.book.event' }, { event: currentEvent.name })}
                    </span>
                  </Checkbox>
                  <a href={LINK_HELP_CREATE_SLOT} target="_blank">{t({ id: 'knowMore' })}</a>
                </>
              }

            </div>
          </div>
        </div>
      </div>

      <div className={classnames(settings.isVisible && styles.actionIsVisible, styles.actionWrapper)}>
        <hr className="mb-16" />
        <div className="flex justify-between items-center w-full p-16">
          <Tooltip
            align="left"
            color="dark"
            title={!canSubmit && <FormattedHTMLMessage id='calendar.settings.submit.disabled.text' />}
          >
            <div ref={refButtonSubmit}>
              <Button
                color="primary"
                variant="fill"
                onClick={handleOnSubmit}
                disabled={!canSubmit}
                loading={isSubmitLoading}
              >
                {t({ id: 'add' }, { count: size(timeslots) })}
              </Button>
            </div>
          </Tooltip>
          <Button
            variant="tonal"
            onClick={handleOnCancel}
          >
            {t({ id: 'cancel' })}
          </Button>
        </div>
      </div>
    </div>
  );
};

CalendarSettings.propTypes = {
  authUser: object,
  currentUserInExponent: object,
  handleOnSettingsChange: func,
  handleOnSubmit: func,
  postAction: func,
  currentEvent: object,
  timeslots: array,
  settings: object,
  handleOnCancel: func,
  style: object,
  countEventAvailable: number,
  isSubmitLoading: bool,
  exponentFormat: string,
};

export { CalendarSettings };
