import React from 'react';
import classnames from 'classnames';
import { Hidden } from '@seekube-tech/ui';
import { Body1, Body2, H5, H4 } from '@seekube-tech/ui-kit';
import { oneOf, shape, string, object, bool } from 'prop-types';
import moment from 'moment';
import { useFormatMessage } from 'react-intl-hooks';
import { FormattedHTMLMessage } from 'react-intl';
import { Row, Col } from 'antd';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { toJS } from '@/utils/index';
import { eventSelectors } from '@/store/event';

import { EVENT_FORMAT } from '@/utils/constants';
import { CONTEXT_ROLES, KEY_DATES_TYPE, SINGLE_DATE } from '../../../../utils/constants';

import CardDate from './components/CardDate';

import polygon from '../../../../../../assets/images/polygon.svg';

import useStyles from './styles';

function KeyDate({ dates, context, event, isUserParticipatingToAllHybridFormats }) {
  /* Vars */

  const { beginAt, endAt, type, isSingle, format } = dates;
  const begin = moment(beginAt);
  const end = moment(endAt);
  const t = useFormatMessage();
  const styles = useStyles();

  const isActive = isSingle ? moment().isSame(begin, 'day') : moment().isBetween(begin, end);
  const isRecruiter = context === CONTEXT_ROLES.RECRUITER || context === CONTEXT_ROLES.OWNER;
  const shouldDisplayFormat = [EVENT_FORMAT.VIRTUAL, EVENT_FORMAT.PHYSICAL].includes(format) && event.format === EVENT_FORMAT.HYBRID && isUserParticipatingToAllHybridFormats;

  /* Functions */
  const getCardIsDisabled = () => {
    const now = moment();

    if (SINGLE_DATE.includes(type)) {
      return now.isSame(begin, 'day') === false && now.isAfter(begin);
    }

    return now.isSame(end, 'day') === false && now.isAfter(end);
  };

  /* Render */
  return (
    <div className={classnames(styles.root, {
      [styles.active]: isActive,
      [styles.disabled]: getCardIsDisabled(),
    })}>
      <CardDate begin={begin} end={end} active={isActive} single={isSingle} />

      <div className={classnames(styles.info, { [styles.infoActive]: isActive })}>
        <Row type="flex" gutter={12}>
          {isActive && (
            <Col>
              <img src={polygon} alt="polygon" className={styles.polygonIcon} />
            </Col>
          )}

          <Col className={styles.title}>
            <Hidden smDown>
              <H4>
                {t({ id: `event.keydates.${type}.title${isRecruiter ? '-recruiter' : ''}` })}
                &#160;{shouldDisplayFormat && t({ id: 'event.keydates.format.info' }, { format })}
              </H4>
            </Hidden>

            <div className='show-mobile'>
              <H5>
                {t({ id: `event.keydates.${type}.title${isRecruiter ? '-recruiter' : ''}` })}
              </H5>
            </div>
          </Col>
        </Row>

        <Hidden smDown>
          <div className={styles.text}>
            <Body2>
              {event.modules.informal1to1?.enable && (type === "discovering" || type === "applicationsClosing") ?
                <>
                  <FormattedHTMLMessage id={`event.keydates.${type}.informal1to1.description${isRecruiter ? '-recruiter' : ''}`} />
                  {shouldDisplayFormat && t({id: 'event.keydates.type.description.format' }, { format })}
                  <span>.</span>
                </>
                :
                <>
                  <FormattedHTMLMessage id={`event.keydates.${type}.description${isRecruiter ? '-recruiter' : ''}`} />
                  {shouldDisplayFormat && t({id: 'event.keydates.type.description.format' }, { format })}
                  {type !== KEY_DATES_TYPE.HUNT &&<span>.</span>}
                  {type === KEY_DATES_TYPE.INTERACTIONS_CLOSING && (
                    t({ id: 'event.keydates.interactionsClosing.description.info.mail' })
                  )}
                </>
              }
            </Body2>
            {type === KEY_DATES_TYPE.LIVE && (
              <Body1 className={styles.liveHelper}>
                {t({ id: `event.keydates.${KEY_DATES_TYPE.LIVE}.helper${isRecruiter ? '-recruiter' : ''}` })}
              </Body1>
            )}

            {type === KEY_DATES_TYPE.APPLICATIONS_CLOSING && (
              <Body1 className={styles.liveHelper}>
                {t({ id: `event.keydates.${KEY_DATES_TYPE.APPLICATIONS_CLOSING}.helper${isRecruiter ? '-recruiter' : ''}` })}
              </Body1>
            )}

            {type === KEY_DATES_TYPE.INTERACTIONS_CLOSING && isRecruiter && (
              <Body1 className={styles.liveHelper}>
                {t({ id: `event.keydates.${KEY_DATES_TYPE.INTERACTIONS_CLOSING}.helper-recruiter` })}
              </Body1>
            )}
          </div>
        </Hidden>

        <div className='show-mobile'>
          <Body1 className={styles.text}>
            <FormattedHTMLMessage id={`event.keydates.${type}.description${isRecruiter ? '-recruiter' : ''}`} />
            {shouldDisplayFormat && t({ id: 'event.keydates.type.description.format' }, { format })}
            <span>.</span>

            {type === KEY_DATES_TYPE.LIVE && (
              <Body2 className={styles.liveHelper}>
                {t({ id: `event.keydates.${KEY_DATES_TYPE.LIVE}.helper${isRecruiter ? '-recruiter' : ''}` })}
              </Body2>
            )}

            {type === KEY_DATES_TYPE.APPLICATIONS_CLOSING && (
              <Body2 className={styles.liveHelper}>
                {t({ id: `event.keydates.${KEY_DATES_TYPE.APPLICATIONS_CLOSING}.helper${isRecruiter ? '-recruiter' : ''}` })}
              </Body2>
            )}

            {type === KEY_DATES_TYPE.INTERACTIONS_CLOSING && isRecruiter && (
              <Body2 className={styles.liveHelper}>
                {t({ id: `event.keydates.${KEY_DATES_TYPE.INTERACTIONS_CLOSING}.helper-recruiter` })}
              </Body2>
            )}
          </Body1>
        </div>
      </div>
    </div>
  );
}

KeyDate.propTypes = {
  dates: shape({
    beginAt: string.isRequired,
    endAt: string.isRequired,
  }),
  context: oneOf(Object.values(CONTEXT_ROLES)),
  event: object,
  isUserParticipatingToAllHybridFormats: bool,
};

KeyDate.defaultProps = {
  dates: null,
  context: null,
};

const mapStateToProps = createStructuredSelector({
  event: eventSelectors.getCurrentEvent,
});

const withConnect = connect(mapStateToProps);

export default compose(
  withConnect,
)(toJS(KeyDate));
