import React from 'react';
import { func } from 'prop-types';
import { Dropdown, Menu } from 'antd';
import MenuItem from 'antd/es/menu/MenuItem';
import {
  Button,
  IconChevronDown,
  IconFilePlus,
  IconLink,
  IconPlusCircle,
} from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';

export function AddExternalMedia ({ onAddMedia, onAddLink }) {
  const t = useFormatMessage();

  return (
    <Dropdown
      overlay={
        <Menu>
          <MenuItem key={1}>
            <a role="button" tabIndex={0} onClick={onAddMedia}>
              <IconFilePlus size={16} />
              {t({ id: 'document' })}
            </a>
          </MenuItem>
          <MenuItem key={2}>
            <a role="button" tabIndex={0} onClick={onAddLink}>
              <IconLink size={16} />
              {t({ id: 'link.pl' }, { count: 0 })}
            </a>
          </MenuItem>
        </Menu>
      }
      trigger={['click']}
      placement="bottomRight"
    >
      <Button
        variant="outline"
        imageComponentLeft={<IconPlusCircle size={16} />}
        imageComponentRight={<IconChevronDown size={16} />}
      >
        {t({ id: 'add.a.new.item' })}
      </Button>
    </Dropdown>
  )
}

AddExternalMedia.propTypes = {
  onAddMedia: func,
  onAddLink: func,
}
