import React from 'react';
import classnames from 'classnames';
import { string, bool, func, oneOfType, node } from 'prop-types';
import styles from './styles.less';

const CardOverlay = ({ isChecked, disabledToggle, onCardToggle, containerClass, children }) => (
  <div className={containerClass} tabIndex={0} role="button" onClick={() => !disabledToggle && onCardToggle(isChecked)}>
    <div className={classnames(styles.cardOverlayContainer, disabledToggle ? styles.disabledToggle : '')}>
      <div className={classnames(styles.cardOverlay, isChecked ? styles.selected : '')}>
        <div className={styles.centerBtn}>
          <span className={styles.participantSelection} />
        </div>
      </div>
    </div>
    {children}
  </div>
);

CardOverlay.propTypes = {
  children: oneOfType([string, node]).isRequired,
  containerClass: string,
  isChecked: bool,
  disabledToggle: bool,
  onCardToggle: func,
};
CardOverlay.defaultProps = {
  disabledToggle: false,
};
export { CardOverlay };
