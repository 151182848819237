import criterionReducer from './reducer';
import * as criterionActions from './actions';
import * as criterionSelectors from './selectors';
import * as criterionTypes from './types';
import criterionSagas from './sagas';

export {
  criterionActions,
  criterionSelectors,
  criterionTypes,
  criterionSagas,
};

export default criterionReducer;
