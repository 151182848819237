import { PHASES, PHASE_STATE } from "../../utils/const";

export default {
  isAvailable: [
    [
      {
        phase: PHASES.ALL,
        state: PHASE_STATE.ALL
      }
    ],
  ],
  generateActions: () => ({})
}
