import React from 'react';
import ParticipantsView from '@/scenes/Event/scenes/Recruiter/scenes/Jobdating/container/ParticipantsView';

class AdminCandidatesAllScene extends React.PureComponent { // eslint-disable-line
  render() {
    return (
      <ParticipantsView context="adminall" />
    );
  }
}

export default AdminCandidatesAllScene;
