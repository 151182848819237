import React from 'react';
import { func, object, bool } from 'prop-types';
import { Button, IconSave } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { Tooltip } from '@/components/Tooltip';

export const DraftBtn = ({ hasFieldChanged, activityToUpdate, mutatePostActivity, onClick }) => {
  const t = useFormatMessage();

  return (
    <>
      <Tooltip
        color="dark"
        title={(!hasFieldChanged && activityToUpdate.status === 'draft') && t({ id: 'already.drafted.activity' })}
        isVisible
      >
        <Button
          loading={mutatePostActivity.isLoading && mutatePostActivity.variables.data.status === 'draft'}
          disabled={mutatePostActivity.isLoading && mutatePostActivity.variables.data.status === 'draft'}
          variant='outline'
          color='neutral'
          onClick={onClick}
          imageComponentLeft={<IconSave size={16}/>}
        >
          {t({ id: 'save.draft' })}
        </Button>
      </Tooltip>
    </>
  )
}

DraftBtn.propTypes = {
  hasFieldChanged: bool,
  activityToUpdate: object,
  mutatePostActivity: object,
  onClick: func,
}
