import { defineMessages } from 'react-intl';

export default defineMessages({
  companies: {
    id: 'companies',
  },
  companiesWaitingForYou: {
    id: 'companies.waiting.for.you',
  },
  signupPanelContent1: {
    id: 'signup.panel.content.1',
  },
  signupPanelContent2: {
    id: 'signup.panel.content.2',
  },
});
