import React from 'react';
import { oneOf } from 'prop-types';

// Components
import { InputNumber as InputNumberAntd } from 'antd';

// Styles
import styles from './styles.less';


const Input = (props) => (
  <InputNumberAntd {...props} className={styles[props.customSize]} />
);
Input.propTypes = {
  customSize: oneOf(['small']),
};

export default Input;
