import React from 'react';
import { array, func, string } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { Select, IconUser, Item } from '@seekube-tech/ui-kit';
import TagWithAvatar from '@/components/Tag/TagWithAvatar';



const SelectRecruiter = ({
  selectedUser = {},
  users = [],
  handleSelectRecruiter,
}) => {
  const t = useFormatMessage();

  const userOptions = ({ value, user }) => (
      <Item key={value} onClick={() => handleSelectRecruiter(value)}>
        <TagWithAvatar user={user} src={user.pictureUrl ?? ''} name={user.fullName} />
      </Item>
    );

  return (
    <div id="users">
      <Select
        value={selectedUser}
        onChange={handleSelectRecruiter}
        leftIcon={<IconUser />}
        label={t({ id: 'calendarOf' })}
        options={users.map((user) => ({
          value: user._id,
          label: user.fullName,
          user,
        }))}
        renderOption={userOptions}
      />
    </div>);
};

SelectRecruiter.propTypes = {
  selectedUser: string,
  users: array,
  handleSelectRecruiter: func,
};

export { SelectRecruiter };
