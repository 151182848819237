const urlRegex = /^\+?[0-9\s-]+$/;

/**
 * Check if phone number is valid
 * @description return false when value is not undefined and phone number is not valid
 * @param {string} value - String phone number
 * @returns {boolean}
 */
export default function phoneNumber(value) {
  return Boolean(value) === false || urlRegex.test(value);
}
