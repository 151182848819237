import * as React from 'react';
import PropTypes from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { DatePicker, Form } from 'antd';
import moment from 'moment';
import { eventSelectors } from '@/store/event';
import { toJS } from '@/utils';
import FormSection from './FormSection';
import InputContainer from './InputContainer';
import { keyMomentsApiToForm } from '../../../services/formMapping/ApiToForm';
import InputWithTitle from './InputWithTitle';

import parentStyles from '../styles.less';
import styles from './KeyMomentsForm.less';

const FormItem = Form.Item;
const { RangePicker } = DatePicker;

const rangePickerParams = {
  format: 'DD-MM-YYYY HH:mm:ss',
  placeholder: ['DD-MM-YYYY HH:mm:ss', 'DD-MM-YYYY HH:mm:ss'],
  showTime: {
    defaultValue: [moment('07:00:00', 'HH:mm:ss'), moment('23:00:00', 'HH:mm:ss')],
  },
};

const datePickerParams = {
  format: 'DD-MM-YYYY HH:mm:ss',
  placeholder: 'DD-MM-YYYY HH:mm:ss',
  showTime: {
    defaultValue: moment('07:00:00', 'HH:mm:ss'),
  },
};

/**
 * 
 * This is a form only used for hybrid events.
 * XXX: migrate all keydates to keymoments
 */
function KeyMomentsForm({ form, event }) {
  const t = useFormatMessage();

  const { getFieldDecorator } = form;

  return (
    <div className={parentStyles.keyDatesContainer}>

      <FormSection icon="users" title={t({ id: "meet.pl" }, { count: 2 })}>
        <InputContainer
          label={t({ id: "event.basic.input.keydates.jobdating.label" })}
          subLabel={t({ id: "event.basic.input.keydates.jobdating.sublabel" })}
          fieldIsMandatory
        >
          <FormItem>
            <div className={styles.inputRow}>
              <InputWithTitle title={t({ id: "event.owner.settings.keyMoments.input.label.jobdating" })} tagName="physical">
                {
                  getFieldDecorator('keyMoments.jobfair.physical', {
                    rules: [
                      { required: true, message: t({ id: "event.basic.input.keydates.required" }) },
                    ],
                    initialValue: keyMomentsApiToForm(event, 'jobfair', 'physical'),
                  })(
                    <RangePicker
                      {...rangePickerParams}
                    />
                  )
                }
              </InputWithTitle>
              <InputWithTitle title={t({ id: "event.owner.settings.keyMoments.input.label.jobdating" })} tagName="virtual">
                {
                  getFieldDecorator('keyMoments.jobfair.virtual', {
                    rules: [
                      { required: true, message: t({ id: "event.basic.input.keydates.required" }) },
                    ],
                    initialValue: keyMomentsApiToForm(event, 'jobfair', 'virtual'),
                  })(
                    <RangePicker
                      {...rangePickerParams}
                    />
                  )
                }
              </InputWithTitle>
            </div>
          </FormItem>
        </InputContainer>
        <InputContainer
          label={t({ id: 'event.keydates.live.title-recruiter' })}
          subLabel={t({ id: 'event.basic.input.keydates.live.subLabel' })}
        >
          <FormItem>
            {getFieldDecorator('keyMoments.live', {
              initialValue: keyMomentsApiToForm(event, 'live', 'hybrid'),
            })(
              <RangePicker
                {...rangePickerParams}
              />
            )}
          </FormItem>
        </InputContainer>
      </FormSection>

      <FormSection icon="lock" title={t({ id: "access.and.actions.candidates" })}>
        <InputContainer
          label={t({ id: 'event.basic.input.keydates.discovering.label.b' })}
          subLabel={t({ id: 'event.basic.input.keydates.discovering.subLabel' })}
          fieldIsMandatory
        >
          <FormItem>
            {getFieldDecorator('keyMoments.discovering', {
              initialValue: keyMomentsApiToForm(event, 'discovering', 'hybrid'),
              rules: [
                { required: true, message: t({ id: "event.basic.input.keydates.required" }) },
              ],
            })(
              <RangePicker
                {...rangePickerParams}
              />
            )}
          </FormItem>
        </InputContainer>
        <InputContainer
          label={t({ id: 'event.basic.input.keydates.applicationsClosing.label' })}
          subLabel={t({ id: 'event.basic.input.keydates.applicationsClosing.subLabel' })}
          notaBene={t({ id: 'event.basic.input.keydates.applicationsClosing.notaBene' })}
        >
          <FormItem>
            <div className={styles.inputRow}>
              <InputWithTitle title={t({ id: "event.owner.settings.keyMoments.input.label.jobdating" })} tagName="physical">
                {
                  getFieldDecorator('keyMoments.applicationsClosing.physical', {
                    rules: [
                      { required: false, message: t({ id: "event.basic.input.keydates.required" }) },
                    ],
                    initialValue: keyMomentsApiToForm(event, 'applicationsClosing', 'physical'),
                  })(
                    <DatePicker
                      {...datePickerParams}
                    />
                  )
                }
              </InputWithTitle>
              <InputWithTitle title={t({ id: "event.owner.settings.keyMoments.input.label.jobdating" })} tagName="virtual">
                {
                  getFieldDecorator('keyMoments.applicationsClosing.virtual', {
                    rules: [
                      { required: false, message: t({ id: "event.basic.input.keydates.required" }) },
                    ],
                    initialValue: keyMomentsApiToForm(event, 'applicationsClosing', 'virtual'),
                  })(
                    <DatePicker
                      {...datePickerParams}
                    />
                  )
                }
              </InputWithTitle>
            </div>
          </FormItem>
        </InputContainer>
      </FormSection>

      <FormSection icon="lock" title={t({ id: "access.and.actions.recruiters" })}>
        <InputContainer
          label={t({ id: 'event.basic.input.keydates.hunt.label' })}
          subLabel={t({ id: 'event.basic.input.keydates.hunt.label.subLabel' })}
          fieldIsMandatory
        >
          <FormItem>
            {
              getFieldDecorator('keyMoments.hunt', {
                rules: [
                  { required: event.modules?.hunt?.enable, message: t({ id: "event.basic.input.keydates.required" }) },
                ],
                initialValue: keyMomentsApiToForm(event, 'hunt', 'hybrid'),
              })(
                <RangePicker
                  {...rangePickerParams}
                />
              )
            }
          </FormItem>
        </InputContainer>
        <InputContainer
          label={t({ id: 'event.basic.input.keydates.interactionsClosing.label.b' })}
          subLabel={t({ id: 'event.basic.input.keydates.interactionsClosing.description' })}
          notaBene={t({ id: 'event.basic.input.keydates.interactionsClosing.description.nb' })}
        >
          <FormItem>
            <div className={styles.inputRow}>
              <InputWithTitle title={t({ id: "event.owner.settings.keyMoments.input.label.jobdating" })} tagName="physical">
                {
                  getFieldDecorator('keyMoments.interactionsClosing.physical', {
                    rules: [
                      { required: false, message: t({ id: "event.basic.input.keydates.required" }) },
                    ],
                    initialValue: keyMomentsApiToForm(event, 'interactionsClosing', 'physical'),
                  })(
                    <DatePicker
                      {...datePickerParams}
                    />
                  )
                }
              </InputWithTitle>
              <InputWithTitle title={t({ id: "event.owner.settings.keyMoments.input.label.jobdating" })} tagName="virtual">
                {
                  getFieldDecorator('keyMoments.interactionsClosing.virtual', {
                    rules: [
                      { required: false, message: t({ id: "event.basic.input.keydates.required" }) },
                    ],
                    initialValue: keyMomentsApiToForm(event, 'interactionsClosing', 'virtual'),
                  })(
                    <DatePicker
                      {...datePickerParams}
                    />
                  )
                }
              </InputWithTitle>
            </div>
          </FormItem>
        </InputContainer>
      </FormSection>
    </div>
  )
}

KeyMomentsForm.propTypes = {
  form: PropTypes.func,
  event: PropTypes.object
}

const mapDispatchToProps = {};

const mapStateToProps = createStructuredSelector({
  event: eventSelectors.getCurrentEvent
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(toJS(KeyMomentsForm));
