import { defineMessages } from 'react-intl';

export default defineMessages({
  organizedBy: {
    id: 'event.landing.organizedBy',
  },
  inProgress: {
    id: 'events.inProgress',
  },
  draft: {
    id: 'event.status.draft',
  },
  auto: {
    id: 'event.status.auto',
  },
  preregistered: {
    id: 'candidate.preregistered',
  },
  private: {
    id: 'event.visibility.private',
  },
  public: {
    id: 'event.visibility.public',
  },
  participate: {
    id: 'event.landing.action.participate',
  },
  candidates: {
    id: 'candidates',
  },
  to: {
    id: 'date.to',
  },
  knowMore: {
    id: 'knowMore',
  },
  discoverCompanyNb: {
    id: 'events.explore.companyDiscover',
  },
  databaseAvailableUntil: {
    id: 'events.databaseAvailableUntil',
  },
  statsAppointmentUpcoming: {
    id: 'events.stats.candidate.appointments.upcoming',
  },
  statsConfirmed: {
    id: 'events.stats.candidate.appointments.confirmed',
  },
  statsUnconfirmed: {
    id: 'events.stats.candidate.appointments.unconfirmed',
  },
  statsAppointmentAccepted: {
    id: 'events.stats.candidate.appointments.accepted',
  },
  statsPropositions: {
    id: 'events.stats.candidate.propositions',
  },
  statsWaitingList: {
    id: 'events.stats.applications',
  },
  statsApplications: {
    id: 'events.stats.applications2',
  },
  statsOpportunities: {
    id: 'events.stats.opportunities',
  },
  statsCandidateOpening: {
    id: 'events.stats.candidate.opening',
  },
  statsRecruiterOffers: {
    id: 'events.stats.recruiter.offers',
  },
  statsRecruiterFreeSlots: {
    id: 'events.stats.recruiter.freeSlots',
  },
  tagsHuntDate: {
    id: 'events.tags.hunt.beginAt',
  },
  titleYourJobdatings: {
    id: 'events.your.title',
  },
  downloadCVTheque: {
    id: 'event.card.download.cv',
  },
});
