import {useQuery} from 'react-query';
import request from '@/utils/request';

export function useGetKpis({ begin, end }) {
  const requestUrl = `${process.env.FRONT_API_URL}/statistics/kpi/platform?begin=${begin}&end=${end}`;
  const queryFn = () => request(requestUrl, { method: 'GET' });

  return useQuery({
    queryKey: ['kpi', begin, end],
    queryFn,
    refetchOnWindowFocus: false,
    cacheTime: 3000,
  });
}
