import React, { useState } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { any, bool, func, string } from 'prop-types';
import { isEmpty } from 'lodash';
import { InputText } from '@seekube-tech/ui-kit';
import { parsePhoneNumber } from 'react-phone-number-input/input'
import Input from 'react-phone-number-input/input'
import useValidations from '@/utils/validations/useValidations';
import { getLocale } from '@/utils/localStorage';
import { getFieldError } from '@/utils/forms/final-form/helpers';
import { CountrySelector } from '@/components/FinalFormFields/CountrySelector';
import { PHONE_DEFAULT_INDICATORS } from '@/utils/constants';

export const FormPhoneField = ({
  name,
  id,
  label,
  placeholder,
  defaultValue,
  validate,
  required
}) => {
  const locale = getLocale();
  const form = useForm();
  const formState = useFormState();
  const [defaultCountry, setDefaultCountry] = useState(PHONE_DEFAULT_INDICATORS[locale]);
  const [country, setCountry] = useState(parsePhoneNumber(formState.values[name] || '', defaultCountry)?.country || defaultCountry);
  const error = getFieldError(form.getState().errors, name);
  const { isFilled, isPhoneNumber } = useValidations();
  
  const validation = (value) => {
    const phoneNumberError = isPhoneNumber(value);
    
    if (!isEmpty(phoneNumberError)) {
      return phoneNumberError;
    }
    if (required && isEmpty(value)) {
      return isFilled(value);
    }
    if (validate) {
      return validate(value);
    }
  }

  const onChangeCountry = (country) => {
    setCountry(country);
    setDefaultCountry(country);
    form.change(name, '');
  }
  
  const onChangeInput = (value = '') => {
    const parsedNumber = parsePhoneNumber(value, defaultCountry);

    if (parsedNumber?.country && value.startsWith('+')) {
      setCountry(parsedNumber.country);
    }
    
    if (isEmpty(value)) {
      setDefaultCountry(PHONE_DEFAULT_INDICATORS[locale]);
      setCountry(PHONE_DEFAULT_INDICATORS[locale]);
    }
    
    form.change(name, value);
  }
  
  return (
    <Field
      name={name}
      id={id}
      label={label}
      placeholder={placeholder}
      defaultValue={defaultValue}
      validate={validation}
    >
      {(props) => (
        <Input
          defaultCountry={defaultCountry}
          value={props.input.value || ''}
          onChange={onChangeInput}
          label={label}
          type="tel"
          message={error}
          color={error && 'error'}
          required={required}
          leftIcon={<CountrySelector
            value={country}
            onChange={onChangeCountry}
          />}
          inputComponent={InputText}
        />
      )}
    </Field>
  );
}

FormPhoneField.propTypes = {
  name: string,
  id: string,
  label: string,
  placeholder: string,
  defaultValue: any,
  validate: func,
  value: string,
  required: bool
}