import React from 'react';
import { object, bool, array, oneOf } from 'prop-types';
import { AvatarBusiness } from '@seekube-tech/ui-kit';
import { If } from '@/components/If';
import styles from './styles.less';
import { Corners } from './components';


function OrganizationColumn({ context, organization, byOrganization, corners, cornersList, isPartner, partner, organizationProfile }) {
  const pictureUrl = organizationProfile?.pictureUrl || organization?.profile?.pictureUrl || 'https://cdn-app-assets.seekube.com/user/picture.png';

  return (
    <div id={`organization${organization._id}`} className="exponent">
      <span className={!byOrganization ? styles.exponent : styles.exponentName}>
        <If condition={!byOrganization}>
          <div className={styles.avatar}>
            <AvatarBusiness
              type="rounded"
              size="small"
              pictureUrl={pictureUrl}
              color={isPartner ? partner?.color : 'default'}
              badge={isPartner ? partner?.icon : null}
            />
          </div>
        </If>

        <div>
          <div title={organization.name}>
            {organization.name}
          </div>

          {context === 'corners' && cornersList.length > 0 && <Corners corners={corners} />}
        </div>
      </span>
    </div>
  );
}


OrganizationColumn.propTypes = {
  organization: object,
  byOrganization: bool,
  corners: array,
  context: oneOf([
    'default',
    'corners', // display the corners under the title
  ]),
  cornersList: array,
  isPartner: bool,
  partner: object,
  organizationProfile: object,
};

OrganizationColumn.defaultProps = {
  context: 'default',
  organization: {},
  corners: [],
  cornersList: [],
  isPartner: false,
  partner: null,
};

export { OrganizationColumn };
