import { getLocale } from '@/utils/localStorage';
import { getId } from '@/utils/global';
import { intersection, isEmpty, size } from 'lodash';
import { useSelector } from 'react-redux';
import { getRolesById, getRolesByKey } from '@/store/role/selectors';

export const roles = {
  supervisor: 'supervisor',
  admin: 'admin',
  member: 'member',
}

export const clientRoles = [roles.admin, roles.supervisor, roles.member];

export function getLocaleName(role) {
  const locale = getLocale();

  return role?.localeName?.[locale];
}

export function getCurrentRole(roles, organizationId) {

  return roles.find((role) => (getId(role._organization) === organizationId));
}

export function getCurrentRoleId(roles = [], organization) {
  return getId(roles?.find((role) => (getId(role._organization) === getId(organization)))?._role);
}

export function checkUserAcl(user, organization, acl = []) {
  const rolesById = useSelector(getRolesById);

  const aclToCheck = typeof aclToCheck === 'string' ? [acl] : acl;

  const organizationUser = user?._organizations?.find((org) => getId(org) === getId(organization));

  const isClient = organizationUser?.isClient || organization?.isClient;

    // Admins can do everything but only in admin context
  if (user.isAdmin && window.location.href.indexOf('admin') > -1) { return true; }

  if (isClient) {
    const roleUserId = getCurrentRoleId(user.roles, organization);

    if (rolesById?.[roleUserId]) {
      return checkAcl(rolesById[roleUserId].acl, aclToCheck);
    }
  }

  return false;
}

export function getUserRole(user, organization = []) {
  const rolesById = useSelector(getRolesById);
  const rolesByKey = useSelector(getRolesByKey);

  const organizationUser = user?._organizations?.find((org) => getId(org) === getId(organization));

  const isClient = organizationUser?.isClient || organization?.isClient;

  // if (user.isAdmin) { return rolesByKey[roles.admin]; }

  if (isClient) {
    const roleUserId = getCurrentRoleId(user.roles, organization);

    return rolesById?.[roleUserId] || rolesByKey?.[roles.member];
  }

  return rolesByKey[roles.member];
}

export function checkAcl(acl, aclToCheck = []) {
  const aclToCheckFormattedInList = typeof aclToCheck === 'string' ? [aclToCheck] : aclToCheck;

  return size(intersection(acl, aclToCheckFormattedInList)) > 0 || acl.indexOf('*') > -1;
}

/** @param {id || string } roleId */
export function getRole(role) {
  const rolesById = useSelector(getRolesById);
  const rolesByKey = useSelector(getRolesByKey);

  if (Object.keys(rolesByKey).includes(role)) {
    return rolesByKey[role];
  }

  return rolesById[role];
}

export function useUserAclHandler(user, organization = user?._currentOrganization) {
  const role =  getUserRole(user, organization);

  return ({
    acl: role?.acl || [],
    role,
    isSAdmin: user.isAdmin,
    getLocaleName: (r) => getLocaleName(r || role),
    checkAcl: (aclToCheck) => organization.isClient ? checkUserAcl(user, organization, aclToCheck) : true,
    checkAclDeep: (aclToCheck) => aclToCheck.reduce((prevVal, currentAcl)=> ({...prevVal, [currentAcl]: checkUserAcl(user, organization, [currentAcl]) }), {})
  })
}
