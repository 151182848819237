import request from '@/utils/request';
import { useInfiniteQuery } from 'react-query';

const useInfiniteGetLandingInformal1to1s = ({ eventId, limit = 15 }) => {
  const queryFn = (pageParams) => {
    const { pageParam: page } = pageParams;
    const params = `?eventId=${eventId}&limit=${limit}&page=${page}`;
    const requestUrl = `${process.env.FRONT_API_URL}/landing/informal1to1s${params}`;

    return request(requestUrl,
      { method: 'GET' });
  };

  const query = useInfiniteQuery({
    queryKey: ['landingInformal1to1s', eventId],
    keepPreviousData: true,
    refetchOnWindowFocus: false,

    getNextPageParam: (lastPage) => {
      if ((lastPage.page * lastPage.limit) <= lastPage.total) {
        return (lastPage.page + 1);
      }
    },
    select: (data) => ({
      pages: [...data.pages],
      pageParams: [...data.pageParams],
    }),
    queryFn,
  });
  return query;
}

export default useInfiniteGetLandingInformal1to1s;
