import React, { useEffect, useState } from 'react';
import { func, number, object, string } from 'prop-types';
import { EVENT_FORMAT } from '@/utils/constants';
import DateSelector from './DateSelector';
import SetTimezone from '@/components/SetTimezone';
import { DownloadAllCv } from '@/features/agenda/recruiter/components/BigCalendar/BigCalendarToolbar/DownloadAllCv';
import styles from '@/features/agenda/recruiter/components/BigCalendar/BigCalendarToolbar/styles.less';

const BigCalendarToolbar = ({
  indexWeek,
  handleWeekChange,
  exponentFormat,
  downloadAllCv,
  currentEvent: { weeks },
}) => {
  const [formatWeeks, setFormatWeeks] = useState(weeks.all);

  useEffect(() => {
    switch(exponentFormat) {
      case EVENT_FORMAT.VIRTUAL:
        setFormatWeeks(weeks.virtual)
        break;
      case EVENT_FORMAT.PHYSICAL:
        setFormatWeeks(weeks.physical)
        break;
      default:
        setFormatWeeks(weeks.all)
        break;
    }
  }, [exponentFormat])

  return (
    <div className={styles.toolbar}>
      <SetTimezone text={null} />
      <DateSelector weeks={formatWeeks} indexWeek={indexWeek} handleWeekChange={handleWeekChange} />
      <DownloadAllCv downloadAllCv={downloadAllCv} />
    </div>
  );
}

BigCalendarToolbar.propTypes = {
  indexWeek: number,
  handleWeekChange: func,
  downloadAllCv: func,
  currentEvent: object,
  exponentFormat: string,
};

export default BigCalendarToolbar;
