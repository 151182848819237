import React from 'react';
import { node, boolean, string } from 'prop-types';
import classnames from 'classnames';
import styles from './styles.less';

const Bloc = ({ children, className }) => (
  <div className={classnames({
      [styles.bloc]: true,
  }, className)}>
    {children}
  </div>
);

const BlocHeader = ({ children, className = ''}) => (
  <div className={classnames(styles.blocHeader, className)}>
    {children}
  </div>
);

Bloc.propTypes = {
  children: node,
  className: string,
};

BlocHeader.propTypes = {
  children: node,
  className: string,
};

export { Bloc, BlocHeader };
