import React, { useState } from 'react';
import { object } from 'prop-types';
import { Body1, Button, H3 } from '@seekube-tech/ui-kit';

import { useFormatMessage } from 'react-intl-hooks';
import classNames from 'classnames';
import ModalV2 from '@/components/ModalV2';
import styles from './styles.less'

const StandProcessRecruitment = ({
  profile,
}) => {

  const t = useFormatMessage()

  const [isModalVisible, setIsModalVisible] = useState(false);
  const steps = profile?.stepsJoin?.filter(n => n);

  if (!steps?.length && !profile?.stepPicture) {
    return null;
  }

  const getSteps = () => (
      steps.map((step, key) => {
        if (key > 5) {
          return null;
        }
        return (
          <div key={key} className={classNames(styles.step, (key === 5 || steps.length === key + 1) && styles.lastStep) }>
            <Body1>{step}</Body1>
          </div>
        )
      })
    )

  return (
    <div className={styles.standProcessRecruitmentContainer}>
      <ModalV2
        visible={isModalVisible}
        template="default"
        onClose={() => setIsModalVisible(false)}
        width='695px'
        className={styles.modalSteps}
      >
        <H3 className='mb-24'>{t({id: 'stand.recruitment.title'})}</H3>
        <div>
          {getSteps()}
        </div>
        <div className={styles.btnContainer}>
          <Button onClick={() => setIsModalVisible(false)} variant='outline' color='neutral'>{t({id: 'back'})}</Button>
        </div>
      </ModalV2>

      <H3 className='mb-24'>{t({id: 'stand.recruitment.title'})}</H3>
      <div className={styles.stepsContainer}>
        {profile.stepPicture &&
          <div className={styles.picture}>
            <img src={profile.stepPicture} />
          </div>
        }
        <div className={styles.stepsContent}>
          {getSteps()}
          {steps.length > 6 &&
            <Button onClick={() => setIsModalVisible(true)} className='mt-24' variant='tonal'>{t({id: 'stand.recruitment.button.seeMore'})}</Button>
          }
        </div>
      </div>
    </div>
  )
}

StandProcessRecruitment.propTypes = {
  profile: object
}

export default StandProcessRecruitment;
