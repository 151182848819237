import { makeStyles } from '@seekube-tech/ui';

export default makeStyles(theme => ({
  root: {
    borderBottom: `4px solid ${theme.palette.neutral[100]}`,
    borderTop: `4px solid ${theme.palette.neutral[100]}`,
    padding: theme.spacing(1, 2.5),
    display: 'flex',
    alignItems: 'center',
  },
  images: {
    display: 'flex',
    borderRadius: '5px',
    border: `1px solid ${theme.palette.neutral[100]}`,
    padding: theme.spacing(1, 1),
    marginRight: theme.spacing(2.5),
    maxWidth: '105px',
    height: '58px',
  },
  image: {
    position: 'relative',
    marginRight: theme.spacing(0.5),
  },
  imageWithOrga: {
    width: '19px',
    height: '19px',
    position: 'absolute',
    padding: '1px',
    bottom: -3,
    right: -1,
    borderRadius: '1px',
    '& > div': {
      borderWidth: '1px',
      borderRadius: '1px',
    }
  },
  description: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(0.5),
    fontWeight: 500,
    color: theme.palette.neutral[500]
  },
  svg: {
    width: '16px',
    height: '16px',
    color: theme.palette.neutral[300],
    position: 'relative',
    bottom: '2px',
    marginRight: theme.spacing(0.5),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '4px',
  },
  refresh: {
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1,
  },
  scroll: {
    overflow: 'auto',
    maxHeight: '44vh',
    background: `
        linear-gradient(#ffffff 33%, rgba(255,255,255, 0)),
        linear-gradient(rgba(255,255,255, 0), #ffffff 66%) 0 100%,
        radial-gradient(farthest-side at 50% 0, rgba(167,182,210, 0.5), rgba(0,0,0,0)),
        radial-gradient(farthest-side at 50% 100%, rgba(167,182,210, 0.5), rgba(0,0,0,0)) 0 100%`,
        backgroundColor: '#ffffff',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'local, local, scroll',
        backgroundSize: '100% 51px, 100% 51px, 100% 17px, 100% 17px'
  },
  event: {
    '& img': {
      borderRadius: '2.8px',
      border: `1px solid ${theme.palette.neutral[200]}`,
    }
  },
  organization: {
    '& img': {
      borderRadius: '2.8px',
      border: `1px solid ${theme.palette.neutral[200]}`,
    }
  }
}));
