import React from 'react';
import { object, func, oneOf } from 'prop-types';
import { injectIntl } from 'react-intl';
import { Col, Row } from 'antd';
import { Pagination } from '@seekube-tech/ui-kit';
import { useSelector } from 'react-redux';
import { toJS } from '@/utils/index';
import { CardLive } from '@/components/CardLive';
import { getAuthUser } from '@/store/auth/selectors';


const ConferencesList = ({
  onSearch,
  pagination,
  conferencesQuery,
  handleActions,
  context,
}) => {
  const authUser = useSelector(getAuthUser)?.toJS();

  const handleOnChangePagination = (page) => {
    onSearch({ page, offset: (pagination * conferencesQuery.data?.limit) - conferencesQuery.data?.limit });
    window.scrollTo(0, 0);
  }
  return (
    <div>
      <Row gutter={25} className="gutter-row">
        {
          conferencesQuery.data?.docs?.map((conf) => (
            <Col xs={12} sm={6} md={6} lg={4} key={conf._id}>
              <CardLive
                conference={conf}
                context={context}
                handleAction={handleActions}
                event={conf._event}
                authUser={authUser}
              />
            </Col>
          ))
        }
      </Row>
      <div className="pagination-container">
        <Pagination
          className="flex justify-center"
          current={conferencesQuery.data?.page + 1}
          pageSize={conferencesQuery.data?.limit}
          total={conferencesQuery.data?.total}
          onChange={handleOnChangePagination}
        />
      </div>
    </div >
  );
}


ConferencesList.propTypes = {
  onSearch: func,
  pagination: object,
  conferencesQuery: object,
  handleActions: object,
  context: oneOf(['nonClient', 'client']),
};

export default toJS(injectIntl(ConferencesList));
