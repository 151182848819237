import React from 'react';
import { injectIntl, FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { bool, func, object } from 'prop-types';

import { Alert, Body1, Button } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import Separator from '@/components/Separator';
import ModalV2 from '@/components/ModalV2';

import messages from './messages';
import styles from './styles.less';

const RemoveUserModal = ({ visible, handleCancel, onOk, intl, error }) => {
  const t = useFormatMessage();

  return(
    <ModalV2
      template="default"
      visible={visible}
      onClose={handleCancel}
      className={styles.removeUserModal}
      handleCancel={handleCancel}
    >
      <div className={styles.modalHeader}>
        <FormattedMessage {...messages.removeAccount} tagName="h3"/>
      </div>

      <div className={styles.modalContent}>
        <FormattedHTMLMessage {...messages.deleteRecruiterFromJds} tagName="p"/>
      </div>
      <Separator height={15}/>

      {error &&
        <Alert className="mb-24" color="error">
          <Body1 className="mb-6" fontWeight={600}>{t({ id: 'form.set.role.error.no.admin.title' })}</Body1>
          {t({ id: 'form.set.role.error.no.admin.description' })}
        </Alert>
      }

      <div className="confirm-actions">
        <Button
          variant="tonal"
          className="mr-6"
          onClick={handleCancel}
        >
          {t({ id: 'cancel'})}
        </Button>
        <Button onClick={onOk}>
          {t({ id: 'btn.confirm' })}
        </Button>
      </div>
    </ModalV2>
  );
};

RemoveUserModal.propTypes = {
  visible: bool,
  onOk: func,
  handleCancel: func,
  intl: object,
};

export default React.memo(injectIntl(RemoveUserModal));
