import { makeStyles } from '@seekube-tech/ui';

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 2,
    background: theme.palette.neutral['700'],
    padding: theme.spacing(3),
    width: '220px',
    '& li': {
      width: '170px',
      padding: theme.spacing(1, 2.5),
      marginBottom: theme.spacing(1),
      color: theme.palette.common.white,
      transition: 'all ease-in 50ms',

      '&:hover': {
        background: theme.palette.primary['500'],
        borderRadius: '5px'
      },
    },
    '& a': {
      color: 'inherit',
      '&:hover': {
        color: theme.palette.common.white,
      },
      '& p': {
        color: 'inherit',
      },
      '& svg': {
        color: 'inherit',
        marginRight: theme.spacing(1),
        width: '16px',
        height: '16px',
      },
    },
  },
  transparent: { fill: 'transparent' },
  logo: {
    width: '128px',
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  selected: {
    background: theme.palette.primary['500'],
    borderRadius: '5px'
  },
  description: {
    marginTop: theme.spacing(0.5),
  },
  field: {
    marginTop: theme.spacing(2),
  },
  switch: {
    marginTop: theme.spacing(0.5),
  },
  button: {
    marginTop: theme.spacing(3),
  },
}));
