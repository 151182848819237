import React from 'react';
import { injectIntl } from 'react-intl';
import { string, object } from 'prop-types';

// Components
import Linkify from 'react-linkify';
import InlineEditor from '../InlineEditor';

// Styles & Translations
import styles from './styles.less';
import messages from '../../messages';

const Description = ({ description, intl }) => (
  <div className={styles.descriptionContainer}>
    <h5 className="sectionTitle">{intl.formatMessage(messages.description)}</h5>
    {/* 355 max */}
    <InlineEditor
      isTextArea
      limit={250}
      value={<Linkify properties={{ target: '_blank' }}>{description} </Linkify>}
      content={<p />}
      tooltipPlacement="bottom"
    />
  </div>
);

Description.propTypes = {
  description: string,
  intl: object,
};

export default injectIntl(Description);
