import exponentReducer from './reducer';
import * as exponentTypes from './types';
import * as exponentActions from './actions';
import * as exponentSelectors from './selectors';
import exponentSagas from './sagas';

export {
  exponentTypes,
  exponentActions,
  exponentSelectors,
  exponentSagas,
};

export default exponentReducer;
