import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import { Steps } from 'antd';
import { compose } from 'redux';
import { object } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { toJS } from '@/utils';
import { queryStringToObject } from '@/utils/url';

import messages from './messages';
import styles from './styles.less';

const { Step } = Steps;

class OfferSteps extends PureComponent {
  static propTypes = {
    match: object,
    location: object,
    history: object,
    intl: object,
  };

  static getDerivedStateFromProps(props, state) {
    const oldStep = queryStringToObject(props.location.search).step;
    if (oldStep !== state.currentStep) {
      return { currentStep: parseInt(oldStep, 10) };
    }
    return state;
  }

  state = {
    currentStep: parseInt(queryStringToObject(this.props.location.search).step, 10),
  };

  onChange = (currentStep) => {
    const { props: { match, history, location } } = this;
    const { offerId } = queryStringToObject(location.search);
    if (currentStep <= this.state.currentStep) {
      history.push(`${match.path}?step=${currentStep}&offerId=${offerId}`);
      this.setState({ currentStep });
    }
  };

  getStepClassName = (currentStep) => (currentStep > this.state.currentStep) ? styles.disable : styles.default;

  isStepDisabled = (currentStep) => currentStep < this.state.currentStep;

  isStepEnabled = (step) => {
    const { state: { currentStep } } = this;
    return currentStep < step;
  };


  render() {
    const { props: { intl }, state: { currentStep }, onChange, getStepClassName } = this;

    return (
      <div className={styles.steps}>
        <Steps current={currentStep} direction="vertical">
          <Step className={getStepClassName(0)} title={intl.formatMessage(messages.step, { count: 1 })} disabled onClick={() => onChange(0)} description={intl.formatMessage(messages.step1Label)} />
          <Step className={getStepClassName(1)} title={intl.formatMessage(messages.step, { count: 2 })} onClick={() => onChange(1)} description={intl.formatMessage(messages.step2Label)} />
          <Step className={getStepClassName(2)} title={intl.formatMessage(messages.step, { count: 3 })} onClick={() => onChange(2)} description={intl.formatMessage(messages.step3Label)} />
        </Steps>
      </div>
    );
  }
}

export default compose(
  injectIntl,
  withRouter
)(toJS(OfferSteps));
