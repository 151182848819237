import React from 'react';
import { oneOf } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { IconX, IconCheck, Tag } from '@seekube-tech/ui-kit';
import { REPORT_STATUS } from '@/scenes/Event/scenes/Owner/scenes/Setting/scenes/Reporting/const';


export const Status = ({ status }) => {
  const t = useFormatMessage();

  switch (status) {
    case REPORT_STATUS.ok:
      return (
        <Tag variant="tonal" color="success">
          <IconCheck size={12} className="mr-6" />
          {t({ id: 'sent.plgr' }, { count: 1, gender: 'male' })}
        </Tag>
      );

    case REPORT_STATUS.ko:
      return (
        <Tag variant="tonal" color="error">
          <IconX size={12} className="mr-6"  />
          {t({ id: 'sent.plgr' }, { count: 1, gender: 'male' })}
        </Tag>
      );

    case REPORT_STATUS.pending:
    default: return null;
  }
}


Status.propTypes = {
  status: oneOf(Object.keys(REPORT_STATUS))
}