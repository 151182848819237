import { defineMessages } from 'react-intl';

export default defineMessages({
  participationsTitle: {
    id: 'client.stats.participations.title',
  },
  funnelConversionTitle: {
    id: 'client.stats.funnel.title',
  },
  interviewTitle: {
    id: 'interview.pl',
  },
  activityTitle: {
    id: 'client.stats.activity.title',
  },
  recapTitle: {
    id: 'client.stats.recap.title',
  },
  offerPublished: {
    id: 'client.offers.title.published',
  },
  livePublished: {
    id: 'event.recruiter.lives.upcoming',
  },
  jobdatings: {
    id: 'header.client.jobdatings',
  },
  participationsRecruiter: {
    id: 'client.stats.participations.recruiters',
  },
  participationsRecruiterTitle: {
    id: 'client.stats.participations.recruiters.title',
  },
  offers: {
    id: 'offers.plural',
  },
  lives: {
    id: 'live.pl',
  },
  interviewTitles: {
    id: 'client.stats.interview.title',
  },
  interviewText: {
    id: 'client.stats.interview.text',
  },
  interviewFurtherTitle: {
    id: 'client.stats.interview.further.title',
  },
  interviewFurtherText: {
    id: 'client.stats.interview.further.text',
  },
  attendeeTitle: {
    id: 'client.stats.attendee.title',
  },
  attendeeText: {
    id: 'client.stats.attendee.text',
  },
  sourcingTitle: {
    id: 'client.stats.sourcing.title',
  },
  sourcingText: {
    id: 'client.stats.sourcing.text',
  },
  conversionTitle: {
    id: 'client.stats.conversion.title',
  },
  conversionParticipantTitle: {
    id: 'client.stats.conversion.participant.title',
  },
  conversionParticipantText: {
    id: 'client.stats.conversion.participant.text',
  },
  conversionParticipantVisitTitle: {
    id: 'client.stats.conversion.participant.visit.title',
  },
  conversionParticipantVisitText: {
    id: 'client.stats.conversion.participant.visit.text',
  },
  conversionParticipantParticipationTitle: {
    id: 'client.stats.conversion.participant.participation.title',
  },
  conversionParticipantParticipationText: {
    id: 'client.stats.conversion.participant.participation.text',
  },
  distributionTitle: {
    id: 'client.stats.distribution.title',
  },
  toggleViewByPeriod: {
    id: 'client.stats.view.toggle.byPeriod',
  },
  toggleViewByEvent: {
    id: 'client.stats.view.toggle.byEvent',
  },
  offerTitle: {
    id: 'client.stats.offers.title',
  },
  offerEventNumber: {
    id: 'client.stats.offers.event.number',
  },
  offerApplications: {
    id: 'client.stats.offers.applications',
  },
  viewPl: {
    id: 'view.pl',
  },
  applicationPl: {
    id: 'application.pl',
  },
  eventsThematicsPl: {
    id: 'events.thematics.pl',
  },
  eventsSchoolsPl: {
    id: 'events.schools.pl',
  },
  signup: {
    id: 'signup',
  },
  camePl: {
    id: 'came',
  },
  offerPublish: {
    id: 'offer.publish.pl',
  },
  livePublish: {
    id: 'live.publish.pl',
  },
  recruiters: {
    id: 'companies',
  },
  by: {
    id: 'by',
  },
  selectEvent: {
    id: 'select.event',
  },
  exportAppointmentsNotif: {
    id: 'recruiter.export.appointments',
  },
  downloadRunning: {
    id: 'download.running',
  },
});
