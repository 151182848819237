import { useMutation } from 'react-query';
import requestExternal from '@/utils/requestExternal';

export function usePutS3File({ onSuccess, file }) {
  
  const mutationFn = ({ s3FileUrl }) => (
    requestExternal(s3FileUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': file.type,
        },
        body: file,
      })
    )

  return useMutation({
    mutationFn,
    onSuccess
  });
}