import React from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
import { string, func, number, oneOf, object } from 'prop-types';
import { Caption1, Link2 } from '@seekube-tech/ui-kit';
import colors from '@seekube-tech/ui-kit/dist/colors';
import { useFormatMessage } from 'react-intl-hooks';
import classnames from 'classnames';
import {
  RemoveReport
} from '@/scenes/Event/scenes/Owner/scenes/Setting/scenes/Reporting/components/EmailingSchedule/components/Report/RemoveReport';
import {
  Status
} from '@/scenes/Event/scenes/Owner/scenes/Setting/scenes/Reporting/components/EmailingSchedule/components/Report/Status';
import { REPORT_CUSTOMERIO, REPORT_PHASES, REPORT_STATUS  } from '@/scenes/Event/scenes/Owner/scenes/Setting/scenes/Reporting/const';
import styles from './styles.less';
import useStyles from './styles.js';

const datePickerParams = {
  format: 'DD/MM/YYYY',
  allowClear: true,
};

const Report = ({
  handleChange = () => {},
  handleRemove = () => {},
  report,
  currentIndex,
  totalIndex,
}) => {
  const classes = useStyles();
  const t = useFormatMessage();

  const defaultValue = report.scheduledAt ? moment(report.scheduledAt) : null;
  const isDatePickerDisabled = report.status === REPORT_STATUS.ok;
  const isPending = report.status === REPORT_STATUS.pending;
  const currentNumber = totalIndex === 1 ? null : currentIndex + 1;
  const canAddCustomReport = getCanAddCustomReport();

  function getCanAddCustomReport () {
    switch (report.phase) {
      case REPORT_PHASES.registrationAndActivitiesOfCandidates:
      case REPORT_PHASES.registrationAndPreparationOfCompanies:
      case REPORT_PHASES.appointmentProcessing:
        return true
      case REPORT_PHASES.closingOfAppointmentSchedules:
      case REPORT_PHASES.review:
      default:
        return false;
    }
  }

  const onScheduleChange = (date) => {
    const scheduledAt = date === undefined ? null : date?.format('YYYY-MM-DD');

    handleChange({ ...report, scheduledAt });
  }

  const onRemove = () => {
    handleRemove({ ...report });
  }

  return (
    <div>
      <div
        className={
          classnames(
          styles.name,
          { [styles.none]: !canAddCustomReport && isPending }
        )}
      >
        {currentNumber && (
        <Caption1
          color={colors.neutral['500']}
          className={!canAddCustomReport ? styles.opacity0 : ''}
        >
          {t({ id: 'form.email' })} {currentNumber}
        </Caption1>
        )}

        {!report.isDefault && isPending &&
          <RemoveReport {...report} handleClick={onRemove}/>
        }

        <Status status={report.status} />
      </div>
      <div className={classnames(styles.pickerContainer, { [styles.disabled]: isDatePickerDisabled })}>
      <DatePicker
        className={classes.datePicker}
        onChange={onScheduleChange}
        defaultValue={defaultValue}
        value={defaultValue}
        disabled={isDatePickerDisabled}
        placeholder="jj/mm/aaaa"
        {...datePickerParams}
      />

      {isDatePickerDisabled &&
        <Link2 href={REPORT_CUSTOMERIO[report.phase]} target="_blank" className={classnames(styles.linkCustomer)} >
          {t({ id: 'see.campaign' })}
        </Link2>
      }
      </div>
    </div>
  )
}


Report.propTypes = {
  report: object,
  scheduledAt: string,
  status: oneOf(Object.keys(REPORT_STATUS)),
  handleChange: func,
  handleRemove: func,
  currentIndex: number,
  totalIndex: number,
}

export { Report }
