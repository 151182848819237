import React, { useState } from 'react';
import { Input } from 'antd';
import { useFormatMessage } from 'react-intl-hooks';
import { array, func }  from 'prop-types';
import { uniq, isEmpty } from 'lodash';
import classnames from 'classnames';
import useStyles from '@/scenes/Event/scenes/Owner/scenes/Setting/scenes/Reporting/components/EmailingSchedule/components/Recipients/styles';
import isEmail from '@/utils/validations/email';


const AddRecipients = ({ recipientsEmails, handleAddRecipient }) => {
  const classes = useStyles();
  const t = useFormatMessage();
  const [currentValue, setCurrentValue] = useState();
  const [errorMessage, setErrorMessage] = useState('');

  const handlePressEnter = (e) => {
    e.preventDefault();

    if (isEmpty(e.currentTarget.value)) return 0;

    if (!isEmail(currentValue)) {
      return setErrorMessage(t({ id: 'mail.error' }));
    }

    if (recipientsEmails.includes(currentValue)) {
      return setErrorMessage(t({ id: 'mail.error.already.in.use' }));
    }


    const emails = [currentValue, ...uniq(recipientsEmails)];

    handleAddRecipient(emails);

    setCurrentValue(null);
    setErrorMessage('');

    return 0;
  }

  const handleChange = (e) => {
    e.preventDefault();

    setCurrentValue(e.currentTarget.value);
    setErrorMessage('');
  }

  return (
    <>
      <Input
        value={currentValue}
        onChange={handleChange}
        onPressEnter={handlePressEnter}
        className={classnames(classes.input, errorMessage && classes.error )}
        placeholder={t({ id: 'owner.reporting.add.recipient.placeholder' })}
      />
      <span className="textError">{errorMessage}</span>
    </>
  )
}

AddRecipients.propTypes = {
  recipientsEmails: array,
  handleAddRecipient: func,
}


export default AddRecipients