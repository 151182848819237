import React from 'react'
import { object, bool } from 'prop-types'
import { useFormatMessage } from 'react-intl-hooks'

const Title = ({ interaction, hasAppointmentBeenModified, event }) => {
  const t = useFormatMessage();
  const { _appointment, _organization } = interaction;
  const _user = interaction._recruiter || interaction._user;

  return (
    <>
      {interaction.type === 'informal1to1' &&
        <Informal1to1Title interaction={interaction} event={event}/>
      }
      { interaction.type !== 'informal1to1' && (
        <h3>
          {_user.firstName} {_user.lastName} @{_organization?.name}<br />{_appointment?.status === 'pending' && !hasAppointmentBeenModified && t({id: 'event.candidate.appointments.card.status.accepted'})}
        </h3>
      )}
    </>
  )
}

const Informal1to1Title = ({ interaction, event }) => {
  const t = useFormatMessage();
  const { _appointment, _organization } = interaction;
  const _user = interaction._recruiter || interaction._user;
  const recruiterHtml = `${_user.firstName} ${_user.lastName} @${_organization?.name}`

  // Candidate applied to informal1to1 and recruiter accepted, candidate can choose a slot
  if (!event.skipAppointmentValidation && _appointment?.status === 'pending' && _appointment.acceptedAt && _appointment.wasAcceptedAndUpdatedAt) {
    return <h3>{recruiterHtml}<br /> </h3>
  }

  // Candidate applied to informal1to1
  if (!event.skipAppointmentValidation && _appointment?.status === 'pending' && !_appointment.acceptedAt) {
    return <h3>{recruiterHtml}<br /></h3>
  }

  // Candidate applied to informal1to1 and recruiter accepted, candidate can choose a slot
  if (!event.skipAppointmentValidation && _appointment?.status === 'pending' && _appointment.acceptedAt) {
    return <h3>{recruiterHtml}<br /> {t({ id: 'event.candidate.appointments.card.status.accepted' })}</h3>
  }

  // Final step: A timeslot has been taken
  if (!event.skipAppointmentValidation && _appointment?.status === 'accepted' && _appointment.acceptedAt) {
    return <h3>{recruiterHtml}<br /></h3>
  }

  // Rdv canceled or refused
  if (!event.skipAppointmentValidation && ['canceled', 'refused'].includes(_appointment?.status)) {
    return <h3>{recruiterHtml}<br /></h3>
  }

  return (
    <h3>
      {recruiterHtml}<br />
    </h3>);
}

Title.propTypes = {
  interaction: object,
  event: object,
  hasAppointmentBeenModified: bool,
}

Informal1to1Title.propTypes = {
  interaction: object,
  event: object,
}

export { Title }