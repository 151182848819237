import React, { useEffect, useState } from 'react';
import { Collapse as CollapseAntd, Row, Col, Spin } from 'antd';
import { useFormatMessage } from 'react-intl-hooks';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { replace } from 'connected-react-router';
import { connect, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import S3 from '@/services/S3';

import { authSelectors } from '@/store/auth';
import { notificationActions } from '@/store/notification';


import { Body1, Button, IconPlusCircle, IconTrash2, IconUpload } from '@seekube-tech/ui-kit';
import { InputFile } from '@/components/Input';
import generateFileName from '@/forms/Stand/utils/generateFileName';
import classNames from 'classnames';
import styles from '../styles.less';

function PictureStep({ input: { value, onChange }, input, organizationId, sendNotification }) {
  /* Vars */
  const t = useFormatMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    setImageUrl(value);
  }, [value]);

  /* Functions */

  /**
   * Upload pictures to S3
   * @param {Files} files
   */
  const handleOnDropPicture = async (files) => {
    try {
      setIsLoading(true);

      const [file] = files;

      const url = await S3.getS3Url(generateFileName(file), 'organization', organizationId);
      setImageUrl(url)

      onChange(url);
    } catch (error) {
      sendNotification({
        message: t({ id: 'event.recruiter.preparation.stand.notification.bad.format' }),
        kind: 'error',
        style: {},
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnChangeInputFile = async (files) => {
    try {
      setIsLoading(true);

      const url = await S3.getS3Url(generateFileName(files), 'organization', organizationId);
      setImageUrl(url)

      onChange(url);
    } catch (error) {
      sendNotification({
        message: t({ id: 'event.recruiter.preparation.stand.notification.bad.format' }),
        kind: 'error',
        style: {},
      });
    } finally {
      setIsLoading(false);
    }
  };

  /* Render */

  return (
    <Row type="flex" align="middle" gutter={10}>
      <Col>
        <Dropzone
          accept="image/png, image/jpeg, image/gif"
          onDrop={handleOnDropPicture}
          className={styles.logoZone}
          activeClassName={styles.activeDropzone}
          style={{ backgroundImage: `url(${imageUrl})` }}
        >
          {isLoading && (
            <div className={styles.loader}><Spin /></div>
          )}

          <div className={classNames(styles.help, { [styles.filled]: Boolean(value)})}>
            <IconUpload />

            <Body1>{t({ id: 'stand.step.picture.add' })}</Body1>
          </div>
        </Dropzone>
      </Col>
      <Col>
        <div className={styles.pictureStepText}>
          <Body1>{t({id: 'stand.step.picture.description'})}</Body1>
          <Body1>{t({id: 'stand.step.picture.size'})}</Body1>

          <div className={styles.btnContainer}>
            <InputFile
              onChange={handleOnChangeInputFile}
              accept=".png,.jpeg,.gif,.jpg"
            >
              <Button imageComponentLeft={<IconPlusCircle size={16} />} variant='outline'>{t({id: imageUrl === '' ? 'stand.step.picture.add' : 'modify.image'})}</Button>
            </InputFile>
            {imageUrl !== '' &&
              <Button variant='tonal' color='error' onClick={() => onChange('')} imageComponentLeft={<IconTrash2 size={16} />}>{t({id: 'delete'})}</Button>
            }
          </div>
        </div>
      </Col>
    </Row>
  );
}

const mapStateToProps = createStructuredSelector({
});

const mapDispatchToProps = {
  sendNotification: notificationActions.sendNotification,
  replace,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

PictureStep.propTypes = {
  input: PropTypes.object.isRequired,
  organizationId: PropTypes.string,
  sendNotification: PropTypes.func.isRequired,
};

PictureStep.defaultProps = {
  organizationId: null,
};

export default compose(withConnect)(PictureStep);
