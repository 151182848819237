import { defineMessages } from 'react-intl';

export default defineMessages({
  mandatory: {
    id: 'form.mandatory',
  },
  limitCharacter: {
    id: 'form.rules.max.character',
  },
  editTitle_webinar: {
    id: 'recruiter.livetalks.edit.title.webinar',
  },
  editTitle_chat: {
    id: 'recruiter.livetalks.edit.title.chat',
  },
  editTitle_meeting: {
    id: 'recruiter.livetalks.edit.title.meeting',
  },
  subjectTitle: {
    id: 'recruiter.live.edit.main.subject.title',
  },
  organizerTitle: {
    id: 'recruiter.live.edit.main.organizer.title',
  },
  organizerNew: {
    id: 'recruiter.live.edit.main.organizer.new',
  },
  tips1: {
    id: 'recruiter.live.edit.main.tips.1',
  },
  tips2: {
    id: 'recruiter.live.edit.main.tips.2',
  },
  organizationNameLabel: {
    id: 'organization.name.label',
  },
  dateTitle: {
    id: 'recruiter.live.edit.main.date.title',
  },
  presentersTitle: {
    id: 'recruiter.live.edit.main.presenter.title',
  },
  presentersAdding: {
    id: 'recruiter.live.edit.main.presenter.adding',
  },
  delete: {
    id: 'delete',
  },
  conferenceBeginLabel: {
    id: 'hour',
  },
  conferenceDurationLabel: {
    id: 'duration',
  },
  conferenceTitleLabel: {
    id: 'recruiter.live.edit.main.form.conference.title.label',
  },
  conferenceDescriptionLabel: {
    id: 'recruiter.live.edit.main.form.conference.description.label',
  },
  conferenceCategoryLabel: {
    id: 'recruiter.live.edit.main.form.conference.category.label',
  },
  conferenceMaxAttendeesLabel: {
    id: 'recruiter.live.edit.main.form.conference.maxAttendees.label',
  },
  conferenceTopicsLabel: {
    id: 'recruiter.live.edit.main.form.conference.topics.label',
  },
  conferencePresentersTitleLabel: {
    id: 'recruiter.live.edit.main.form.conference.presenters.title.label',
  },
  conferenceBeginAtFullTooltip: {
    id: 'recruiter.live.edit.main.form.conference.beginAt.full.tooltip',
  },
  formFirstNameLabel: {
    id: 'firstName',
  },
  formLastNameLabel: {
    id: 'lastName',
  },
  formFirstEmailLabel: {
    id: 'form.email',
  },
  formErrorCompanyExist: {
    id: 'form.error.company.exist',
  },
  toolTitle: {
    id: 'recruiter.live.edit.tool.title',
  },
  toolText: {
    id: 'recruiter.live.edit.tool.text.description',
  },
  toolNavigatorAlert: {
    id: 'recruiter.live.edit.tool.navigator.alert',
  },
  toolBigMarker: {
    id: 'recruiter.live.edit.tool.bigmarker',
  },
  toolBenchmark: {
    id: 'recruiter.live.edit.tool.text.benchmark',
  },
  toolTerm1: {
    id: 'recruiter.live.edit.tool.term.1',
  },
  toolTerm2: {
    id: 'recruiter.live.edit.tool.term.2',
  },
  btnConfirm: {
    id: 'btn.confirm',
  },
  publish: {
    id: 'publish',
  },
  edit: {
    id: 'edit',
  },
  other: {
    id: 'other',
  },
  next: {
    id: 'next',
  },
  eventsTitle: {
    id: 'client.live.edit.events.title',
  },
  eventsSubtitle: {
    id: 'client.live.edit.events.subtitle',
  },
  urlFormatNotValid: {
    id: 'event.recruiter.preparation.stand.item.videos.error',
  },
  full: {
    id: 'full',
  },
  limitPresenters: {
    id: 'live.creation.limit.presenters',
  },
  canDuplicate: {
    id: 'live.creation.info.duplication'
  },
});
