import React from 'react';
import { FormattedMessage } from 'react-intl';
import { string, array, object } from 'prop-types';
import { cssError } from '@/utils/text';
import moment from 'moment';
import { MemberColumn } from '../../../../../../Admin/scenes/Organizations/scenes/Organization/components/memberColumn';
import messages from './messages';
import styles from './styles.less';

const Columns = ({ authUser }) => (
  [
    {
      Header: <FormattedMessage {...messages.recruitersPl} values={{ count: 2 }} />,
      id: 'recruiters',
      disableSortBy: true,
      accessor: (record) => <MemberColumn record={{ ...record, isActive: true }} authUser={authUser} />,
    },
    {
      Header: <FormattedMessage {...messages.offersPublishPl} values={{ count: 2 }} />,
      disableSortBy: true,
      id: 'offers',
      accessor: ({ offers }) => <span className={cssError(offers === 0)}>{offers}</span>,
    },
    {
      Header: <FormattedMessage {...messages.applicationPl} values={{ count: 2 }} />,
      id: 'CANDIDATURES',
      disableSortBy: true,
      SubHeaders: [
        <FormattedMessage {...messages.receivedPlgr} values={{ count: 2, gender: 'female' }} />,
        <FormattedMessage {...messages.acceptedPlgr} values={{ count: 2, gender: 'female' }} />,
        <FormattedMessage {...messages.refusedPlgr} values={{ count: 2, gender: 'female' }} />,
        <FormattedMessage {...messages.untreatedPlgr} values={{ count: 2, gender: 'female' }} />,
      ],
      accessor: (stats) => ([
        stats.applications,
        stats.acceptedApplications,
        stats.refusedApplications,
        stats.pendingApplications,
      ]),
    },
    {
      Header: 'Sourcing',
      disableSortBy: true,
      id: 'sourcingAppointments',
      accessor: ({ sourcingAppointments }) => <span className={cssError(sourcingAppointments === 0)}>{sourcingAppointments}</span>,
    },
    {
      Header: <FormattedMessage {...messages.rdv} />,
      disableSortBy: true,
      id: 'acceptedAppointments',
      accessor: ({ acceptedAppointments }) => (acceptedAppointments),
    },
    {
      Header: <FormattedMessage {...messages.cvDownloaded} />,
      disableSortBy: true,
      id: 'countDownloads',
      accessor: ({ countDownloads }) => (countDownloads),

    },
    {
      Header: <span className={styles.tableHeader}><FormattedMessage {...messages.livePublishPl} values={{ count: 2 }} /></span>,
      disableSortBy: true,
      id: 'countConferences',
      accessor: ({ countConferences }) => <span className={cssError(countConferences === 0)}>{countConferences || 0}</span>,
    },
    {
      Header: <span className={styles.tableHeader}><FormattedMessage {...messages.lastConnection} /></span>,
      disableSortBy: true,
      id: 'lastLogin',
      accessor: ({ lastLogin }) => <span style={{ fontSize: '11px' }}>{(moment(lastLogin).fromNow() || 0)}</span>,
    },
  ]
);

Columns.propTypes = {
  tool: object,
  createdAt: string,
  _organization: object,
  users: array,
};

export default Columns;
