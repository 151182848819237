import React from 'react';
import { object, number, func } from 'prop-types';
import { Button, Toggle } from '@seekube-tech/ui-kit';
import S3Upload from '@/containers/S3Upload';

/**
 * Header
 *
 * @description
 * Panel for the content 'header' display in the MenuItem
 *
 * @param {object} params
 * @param {object} params.event
 * @param {number} params.contentIndex
 * @param {Function} params.onChange
 */
const Header = ({ event, contentIndex, onChange }) => (
    <div className="menuItemContent">
      {/* Display Label */}
      <div>
        <label htmlFor="mainColor">Afficher label dans le menu :</label>
        <Toggle
          size="small"
          checked={event._contents[contentIndex].inNav}
          onChange={(checked) => onChange(['_contents', contentIndex, 'inNav'], checked)}
        />
      </div>

      {/* Change the pictureUrl & coverPictureUrl */}
      <div>
        <label htmlFor="actions">Images</label>
        <S3Upload
          uploadType="event"
          onChange={(url) => {
            onChange(['theme', 'pictureUrl'], `https://aqfjyrmoen.cloudimg.io/width/100/x/${url}`);
            onChange(['theme', 'coverPictureUrl'], url);
          }
          }
        >
          <Button variant="tonal">Photo de couverture (2624x760px)</Button>
        </S3Upload>
      </div>
    </div>
  );

Header.propTypes = {
  event: object,
  contentIndex: number,
  onChange: func,
};

export default Header;
