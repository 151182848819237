import React from 'react';
import { func, object, bool } from 'prop-types';
import { Button } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';


export const AddSlotBtn = ({
  handleOnOpenSlotSetting,
  settings,
  isDeleteModeActive,
}) => {
  const t = useFormatMessage();
  const isDisabled = settings.isVisible || isDeleteModeActive;

  return (
    <Button
      disabled={isDisabled}
      size="large"
      className="mr-16"
      onClick={handleOnOpenSlotSetting}
    >
      {t({ id: 'recruiter.participantsList.btn.add-slot' })}
    </Button>
  );
};

AddSlotBtn.propTypes = {
  handleOnOpenSlotSetting: func,
  settings: object,
  isDeleteModeActive: bool,
}
