import { schema } from 'normalizr';
// import { organizationSchema } from '../organization/schema';
// import { userSchema } from '../user/schema';
// import { eventSchema } from '../event/schema';

export const offerSchema = new schema.Entity('offers', {

}, { idAttribute: '_id' });

export const offerListSchema = [offerSchema];
