import React from 'react';
import { object, func } from 'prop-types';
import { Button, IconEye, IconEyeOff } from '@seekube-tech/ui-kit';
import { FormattedMessage } from 'react-intl';

export const RealTimeActivityViewSwitcher = ({ isLinkVisible, handleDashboardMode }) => {
  if (!isLinkVisible) {
    return null;
  }

  if (location.pathname.includes('realtime')) {
    return (
      <Button variant="text" imageComponentLeft={<IconEyeOff size={16} />} onClick={handleDashboardMode}>
        <FormattedMessage id="dashboard.link.realtime.close" />
      </Button>
    )
  }

  return (
      <Button variant="text" imageComponentLeft={<IconEye size={16} />} onClick={handleDashboardMode}>
        <FormattedMessage id="dashboard.link.realtime.open" />
      </Button>)
}

RealTimeActivityViewSwitcher.propTypes = {
  isLinkVisible: object,
  handleDashboardMode: func
}
