import React from 'react';
import { H6, Body1 } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import moment from 'moment';
import { isEmpty } from 'lodash';

export const Positions = ({ participant }) => {
  if (isEmpty(participant._user.positions)) { return null }

  const t = useFormatMessage();

  function getPeriodDate (position) {
    const isCurrentStr = position.isCurrent ? `(${t({ id: 'current' })})` : '';

    if (moment(position.beginAt).year() === moment(position.endAt).year()) {
      return `${moment(position.beginAt).format('YYYY')} ${isCurrentStr}`;
    }
    return `${moment(position.beginAt).format('YYYY')} - ${moment(position.endAt).format('YYYY')} ${isCurrentStr}`
  }

  return (
    <div>
      <H6 className="text-neutral-500 mb-8">{t({ id: 'experiences' })}</H6>

      {participant._user.positions.map((position) =>
        <Body1 key={position._id} className="text-neutral-400">
          {position.title}
          <span className="text-neutral-500 mx-5"> {`@${position._organization.name}`}</span>
          {getPeriodDate(position)}
        </Body1>
      )
      }
    </div>
  )
}