import React from 'react';
import { array, object, func, bool, number, oneOfType, oneOf, string } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { withRouter } from 'react-router-dom';
import { Map } from 'immutable';
import moment from 'moment';
import { isEmpty, omit, isEqual, capitalize, get, find, size, isUndefined, findIndex } from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Avatar } from '@seekube-tech/ui';
import { CardInfo, Pagination } from '@seekube-tech/ui-kit';
import { Row, Col } from 'antd';
import request from '@/utils/request';
import { toJS } from '@/utils';
import { queryStringToObject, objectToParams } from '@/utils/url';
import { CONTEXTS_ADMIN_SELECTION, CONTEXTS, CONTEXTS_ALL } from '@/utils/global';
import { setLocalStorage, LOCAL_STORAGE } from '@/utils/localStorage';
import { track } from '@/utils/analytics';
import { ANALYTICS_RECRUITER } from '@/utils/constants';

// Store
import { authSelectors } from '@/store/auth';
import { eventSelectors } from '@/store/event';
import { participantSelectors, participantActions } from '@/store/participant';
import { offerSelectors, offerActions } from '@/store/offer';
import { exponentSelectors, exponentActions } from '@/store/exponent';
import { appointmentActions } from '@/store/appointment';
import { actionActions } from '@/store/action';
import { interactionActions, interactionSelectors } from '@/store/interaction';

// Components
import Wrapper from '@/components/Wrapper';
import BlankState from '@/components/BlankState';
import LoadingIndicator from '@/components/LoadingIndicator';
import Countdown from '@/scenes/Event/components/Countdown';
import { Select } from '@/components/Form';
import Separator from '@/components/Separator';
import ResultsContainer from '@/components/ResultsContainer';
import TextSeparator from '@/components/TextSeparator';
import Checkbox from '@/components/Checkbox';
import { ModalConfirm } from '@/components/ModalConfirm';
import { getCurrentRole, getUserRole } from '@/store/role/helpers';
import { getParticipantResumeUrl } from '@/helpers/resume';
import { changeInteractionOwner } from '@/store/interaction/actions';
import PageDescription from './components/PageDescription';
import { InterviewAutoNav } from './components/InterviewAutoNav';
import SearchEngine from './components/SearchEngine';
import ParticipantList from '../../components/ParticipantsList';
import GroupedActions from './components/GroupedActions';
import AppointmentModal from '../../../../../../containers/AppointmentModal';
import { analyticsRefuseCandidate } from './analytics';

// Styles & Translations
import styles from './styles.less';
import messages from './messages';


class ParticipantsView extends React.PureComponent {
  static propTypes = {
    isLoading: bool,
    actionIsPending: bool,
    authUser: object,
    pagination: object,
    event: object,
    stats: object,
    facets: object,
    exponent: object,
    countNew: number,
    push: func,
    openedParticipant: object,
    visitParticipant: func,
    getParticipants: func,
    getParticipantsStats: func,
    getOrganizationOffers: func,
    resetParticipantsList: func,
    refuseParticipant: func,
    refuseParticipants: func,
    selectParticipant: func,
    selectParticipants: func,
    moveParticipant: func,
    moveParticipants: func,
    banParticipant: func,
    getParticipant: func,
    postAction: func,
    participants: array,
    interactions: array,
    offers: array,
    match: object,
    context: oneOf(CONTEXTS_ALL),
    location: object,
    schools: oneOfType([array, object]),
    history: object,
    modifyAppointment: func,
    cancelAppointment: func,
    patchAppointmentDate: func,
    patchAppointmentOwner: func,
    patchInteractionOwner: func,
    goToEditPage: func,
    setOpenParticipant: func,
    confirmAppointment: func,
    intl: object,
    toogleCoreTarget: func,
    toogleCoreTargets: func,
  };

  static defaultProps = {
    participants: null,
  };

  static getDerivedStateFromProps({ pagination, history, participants, openedParticipant }, state) {
    const newState = {};

    if (!isEqual(state.search, location.query)) {
      newState.search = queryStringToObject(history.location.search);
    }

    if (!isEmpty(participants)) {
      const participantActionBtnDisabled = {};

      participants.forEach((participant) => {
        if (participant) {
          participantActionBtnDisabled[participant._id] = false;
        }
      });

      newState.participantActionBtnDisabled = participantActionBtnDisabled;
    }

    if (isEmpty(state.openParticipant) && openedParticipant) {
      newState.openParticipant = openedParticipant;
    }

    if (!isEmpty(newState)) {
      return newState;
    }

    return null;
  }

  constructor(props) {
    super(props);
    const { location } = props;

    location.query = queryStringToObject(location.search);

    this.state = {
      context: props.context,
      openParticipant: null,
      nextProfile: null,
      profileNavigation: null,
      search: {
        filters: [],
        schools: [],
        experiences: [],
        ...location.query,
      },
      modalAppointmentIsVisible: false,
      highlightWords: [],
      isMultipleSelectAction: false,
      selectedParticipants: [],
      groupedSelection: false,
      allSelected: false,
      callback: null,
      collectedPage: [],
      currentPage: 1,
      queryParams: {
        page: 1,
        ...location.query,
      },
      participantActionBtnDisabled: {},
      isSyncing: false,
      isMoving: false,
      confirmModalSyncIntercomIsVisible: false,
      syncIntercomUsers: [],
      notSyncIntercomUsers: [],
      isRefuseContext: false,
      checkedParticipants: [],
      isAllChecked: false,
      isBtnAllParticipantsActive: false,
      isBtnPageParticipantsActive: false,
      isModifyAppointmentModalVisible: false,
      modifyAppointmentModalType: false,
      isConfirmGroupedActionsModalVisible: false,
      confirmedModalType: { type: 'hide', moveType: null },
      reload: false,
      isCardInfoVisible: false,
    };
  }


  componentDidMount() {
    const {
      props: {
        match, getParticipants, getOrganizationOffers, authUser, resetParticipantsList, offers, getParticipant, event,
      }, getSearchParticipantsParams, getDynamicContext,
    } = this;

    resetParticipantsList();

    const { page, offset, search, getprofile, participant } = getSearchParticipantsParams();

    if (getprofile === 'true' && !isEmpty(participant)) {
      getParticipant({
        eventId: match.params.eventSlug,
        userId: participant,
        openProfile: true,
        noLoader: true,
      });
    }

    // anticipate dynamic url from /interview to /interview/confirmed or /interview/unconfirmed
    if (authUser) {
      getParticipants({
        eventId: match.params.eventSlug,
        context: getDynamicContext(),
        offset,
        page,
        search,
        loader: true,
        clear: !(getprofile === 'true' && !isEmpty(participant)),
      });

      if (isEmpty(offers) || findIndex(offers, (offer) => !isUndefined(offer.childrenOffers)) > -1) {
        getOrganizationOffers({
          page: 1,
          offset: 0,
          eventSlug: match.params.eventSlug,
          status: 'published',
          selectData: 'light',
          organizationId: authUser._currentOrganization._id,
        });
      }

      if (event) {
        const data = JSON.parse(localStorage.getItem(`${authUser.id}//${event.slug}`) || '{}');

        this.setState({ isCardInfoVisible: data.isCardInfoVisible });
      }
    }

    window.scrollTo(0, 0);
  }

  componentDidUpdate() {
    const {
      state: { profileNavigation },
      props: { pagination, participants },
      handleOnShowParticipant,
    } = this;

    if (profileNavigation !== null && participants.length > 0 && profileNavigation.page === pagination.current) {
      const participantOpened = profileNavigation.type === 'next' ? participants[0] : participants[participants.length - 1];

      if (participantOpened) {
        handleOnShowParticipant(participantOpened);
        window.location.reload();
      }
    }
  }

  /**
   * ComponentWillUnmount
   *
   * @description
   * Reset allIds participants in redux store
   */
  componentWillUnmount() {
    this.props.resetParticipantsList();

    this.setState({
      selectedParticipants: [],
    });
  }

  onReset = () => {
    const { props: { event, context, push } } = this;

    if (CONTEXTS_ADMIN_SELECTION.includes(context)) {
      push(`/${event.slug}/owner/candidates/${context.replace('admin', '')}`);
    } else {
      push(`/${event.slug}/recruiter/jobdating/${context}`);
    }

    window.location.reload();
  }

  onCloseModalSyncIntercomIsVisible = () => this.setState({ confirmModalSyncIntercomIsVisible: false });

  getSearchParticipantsParams = () => {
    const {
      props: { pagination, location },
    } = this;

    location.query = queryStringToObject(location.search);

    const page = location.query.page ? parseInt(location.query.page, 10) : 1;
    const offset = page === 1 ? 0 : (page * pagination.pageSize) - pagination.pageSize;
    const search = omit(location.query, ['page', 'context']);

    return {
      page,
      offset,
      search,
      getprofile: location.query.getprofile,
      participant: location.query.participant,
    };
  };

  getDynamicContext = () => {
    const { props: { context } } = this;

    if (window.location.href.indexOf('interview/unconfirmed') !== -1) return 'unconfirmed';
    if (window.location.href.indexOf('interview/confirmed') !== -1) return 'confirmed';
    if (window.location.href.indexOf('interview/interview') !== -1) return 'interview';
    return context;
  };

  getCountParticipantChecked = () => {
    const { props: { pagination }, state: { checkedParticipants, isBtnAllParticipantsActive, isMultipleSelectAction } } = this;

    if (isBtnAllParticipantsActive) {
      return pagination.total || 1;
    }

    return isMultipleSelectAction ? checkedParticipants.length : 1;
  };

  handleOnChangePagination = (pagination) => {
    this.setState({
      currentPage: pagination,
    });

    const { props: { history } } = this;
    const location = { ...history.location };

    location.query = queryStringToObject(location.search);

    this.processQueryChange({ page: pagination });

    window.scrollTo(0, 0);
  };

  /**
   * Handle sort
   *
   * @param {string} sort
   */
  handleOnSort = (sort) => {
    const { authUser, event } = this.props;

    this.processQueryChange({ sort });

    if (sort === 'new') {
      track({
        name: ANALYTICS_RECRUITER.SORT_NEW_CANDIDATES,
        user: authUser,
        event,
        properties: {
          roles: getCurrentRole(authUser.roles, authUser._currentOrganization._id)?._role?.key,
        },
      });
    }
  }

  /**
   * Handle change
   *
   * @param {array} participants
   */
  handleOnSelectParticipant = (participants) => {
    this.setState({
      selectedParticipants: participants,
      groupedSelection: this.state.groupedSelection && participants.length > 0,
    });
  };

  /**
   * handleOnGroupedSelectionChange
   * @param {object} e
   */
  handleOnGroupedSelectionChange = (e) => {
    const { props: { participants }, state: { selectedParticipants, currentPage, collectedPage } } = this;
    const toErase = [];

    let newSelectedParticipants = [...selectedParticipants];

    participants.forEach((participant) => {
      // If checkbox is checked, and participant does not currently exists in array, add it
      if (e.target.checked && !newSelectedParticipants.includes(participant._id)) {
        newSelectedParticipants.push(participant._id);
      } else {
        toErase.push(participant._id);
      }
    });

    newSelectedParticipants = newSelectedParticipants.filter((participant) => !toErase.includes(participant)).map((participant) => participant);

    // Collected Page - Used for checkbox in all pages
    let newCollectedPages = [...collectedPage];
    if (e.target.checked) {
      if (!collectedPage.includes(currentPage)) {
        newCollectedPages.push(currentPage);
      }
    } else {
      newCollectedPages = newCollectedPages.filter((page) => currentPage !== page).map((page) => page);
    }

    this.setState((state) => ({
      groupedSelection: !state.groupedSelection,
      selectedParticipants: newSelectedParticipants,
      collectedPage: newCollectedPages,
    }));
  }

  handleOnShowAppointmentModal = (participant, nextProfile, callback, isRefuseContext) => {
    this.setState({
      modalAppointmentIsVisible: true,
      selectedParticipant: participant,
      isMultipleSelectAction: false,
      nextProfile,
      callback,
      isRefuseContext,
    });
  };

  handleOnSubmitAppointment = (values) => {
    const {
      state: { nextProfile, isRefuseContext },
      handleSubmitProposition, handleOnRefuse,
    } = this;

    if (isRefuseContext) {
      handleOnRefuse(values, nextProfile);
    } else {
      handleSubmitProposition(values);
    }
  };

  /**
   * handleSubmitProposition
   *
   * @param {object} values
   */
  handleSubmitProposition = (values) => {
    const {
      props: { event, context, selectParticipant, selectParticipants, getParticipants, exponent, location, intl, pagination, getParticipantsStats },
      state: { isMultipleSelectAction, callback, isBtnAllParticipantsActive, checkedParticipants },
      getSearchParticipantsParams, getCountParticipantChecked,
    } = this;

    const locationQuery = queryStringToObject(location.search);
    const { page, offset, search } = getSearchParticipantsParams();

    const notificationParams = {
      success: {
        message: `🙌 ${intl.formatMessage(messages.appointmentConfirm)}`,
        kind: 'info',
        style: {
          bottom: '5%',
          top: 'inherit',
        },
      },
      error: true,
    };

    if (isMultipleSelectAction) {
      const countCheckedParticipants = checkedParticipants.length;

      this.setState({ isMoving: countCheckedParticipants > 2 });

      if (!isBtnAllParticipantsActive && countCheckedParticipants === pagination.pageSize) {
        setTimeout(() => getParticipants({
          eventId: event.slug,
          context,
          offset,
          page,
          search,
          callback: () => {
            this.setState({ checkedParticipants: [], isBtnAllParticipantsActive: false, isBtnPageParticipantsActive: false, isMultipleSelectAction: false, isMoving: false });
          },
        }), 100);
      } else {
        setTimeout(() => getParticipantsStats({ eventId: event._id, context }), 400);
      }

      selectParticipants({
        recruiterId: values.user._user._id,
        participantIds: checkedParticipants,
        offerId: values.offer,
        message: values.message,
        eventId: event._id,
        batchAll: isBtnAllParticipantsActive,
        notificationParams,
        locationQuery,
        context,
        page,
        offset,
        search,
        count: getCountParticipantChecked(),
        exponent,
        clearList: countCheckedParticipants === pagination.pageSize || countCheckedParticipants === pagination.total,
        callback: () => {
          this.setState({ checkedParticipants: [], isMoving: false, isBtnAllParticipantsActive: false, isBtnPageParticipantsActive: false });

          if (isBtnAllParticipantsActive || countCheckedParticipants < pagination.pageSize) {
            getParticipants({
              eventId: event._id,
              context,
              offset,
              page: location.query.page,
              search,
            });
          }
        },
      });
    } else {
      selectParticipant({
        eventId: event._id,
        participantId: this.state.selectedParticipant._id,
        _candidate: this.state.selectedParticipant._user._id,
        context,
        body: values,
        notificationParams,
        locationQuery,
        page,
        offset,
        search,
      });
    }

    if (typeof callback === 'function') {
      callback();
    }

    this.setState({ modalAppointmentIsVisible: false, selectedParticipant: null, isMultipleSelectAction: false, nextProfile: null });
  };

  handleOnMove = (participant, status, scoring = null, callback) => {
    const {
      props: { event, context, moveParticipant, location, moveParticipants, getParticipantsStats, getParticipants, pagination },
      state: { isMultipleSelectAction, checkedParticipants, isBtnAllParticipantsActive },
      getSearchParticipantsParams,
    } = this;

    const { page, offset, search } = getSearchParticipantsParams();
    const locationQuery = queryStringToObject(location.search);
    const countCheckedParticipants = size(checkedParticipants);

    if (isMultipleSelectAction) {
      if (countCheckedParticipants > 1 || isBtnAllParticipantsActive) {
        this.setState({ isMoving: countCheckedParticipants > 2, isConfirmGroupedActionsModalVisible: false });

        if (countCheckedParticipants === pagination.pageSize) {
          setTimeout(() => getParticipants({
            eventId: event.slug,
            context,
            offset,
            page,
            search,
            callback: () => {
              this.setState({ checkedParticipants: [], isBtnAllParticipantsActive: false, isBtnPageParticipantsActive: false, isMultipleSelectAction: false, isMoving: false });
            },
          }), 100);
        } else {
          setTimeout(() => getParticipantsStats({ eventId: event._id, context }), 400);
        }
      }

      this.modals.confirmGroupedActions.close();

      moveParticipants({
        eventId: event._id,
        participantIds: checkedParticipants,
        batchAll: isBtnAllParticipantsActive,
        context,
        page,
        offset,
        search,
        status,
        locationQuery,
        clearList: countCheckedParticipants === pagination.pageSize || countCheckedParticipants === pagination.total,
        callback: () => {
          this.setState({ checkedParticipants: [], isBtnAllParticipantsActive: false, isBtnPageParticipantsActive: false, isMultipleSelectAction: false, isMoving: false });
        },
      });
    } else {
      moveParticipant({
        eventId: event._id,
        participantId: participant._id,
        context,
        page,
        offset,
        search,
        scoring,
        status,
        locationQuery,
        callback,
      });
    }
  };

  handleToogleCoreTarget = (participantId, isCoreTarget) => () => {
    const { event, toogleCoreTarget } = this.props;

    toogleCoreTarget({
      eventId: event?._id,
      participantId,
      isCoreTarget: !isCoreTarget,
    });
  };

  handleCoreTargets = () => () => {
    const {
      props: { event, toogleCoreTargets },
      state: { isBtnAllParticipantsActive, checkedParticipants },
    } = this;

    toogleCoreTargets({
      eventId: event?._id,
      participantIds: checkedParticipants,
      isCoreTarget: true,
      batchAll: isBtnAllParticipantsActive,
    });
  };

  /**
   * Handle change
   *
   * @param {object} values
   * @param {object} nextProfile
   */
  handleOnRefuse = (values, nextProfile) => {
    const {
      props: { event, authUser, refuseParticipant, exponent, refuseParticipants, location, intl },
      state: { participantActionBtnDisabled, selectedParticipant, isMultipleSelectAction, checkedParticipants, isBtnAllParticipantsActive, callback },
      getSearchParticipantsParams, getCountParticipantChecked } = this;

    const context = this.getDynamicContext();

    const { page, offset, search } = getSearchParticipantsParams();
    const locationQuery = queryStringToObject(location.search);
    const count = getCountParticipantChecked();


    participantActionBtnDisabled[selectedParticipant._id] = true;

    if (isMultipleSelectAction) {
      refuseParticipants({
        eventId: event._id,
        context,
        locationQuery,
        page,
        offset,
        search,
        recruiterId: values.user._user._id,
        participantIds: checkedParticipants,
        offerId: values.offer,
        message: values.message,
        batchAll: isBtnAllParticipantsActive,
        notificationParams: {
          success: {
            message: `🙌 ${intl.formatMessage(messages.profileHide)}`,
            kind: 'info',
            style: {
              bottom: '5%',
              top: 'inherit',
            },
          },
          error: true,
        },
        callback: () => analyticsRefuseCandidate({ authUser, exponent, event, count, severalActions: true }),
      });
    } else {
      refuseParticipant({
        eventId: event._id,
        participantId: selectedParticipant._id,
        context,
        locationQuery,
        page,
        offset,
        search,
        body: values,
        notificationParams: {
          success: {
            message: `🙌 ${intl.formatMessage(messages.profileHide)}`,
            kind: 'info',
            style: {
              bottom: '5%',
              top: 'inherit',
            },
          },
          error: true,
        },
        callback: () => analyticsRefuseCandidate({ authUser, exponent, event, count, severalActions: false }),
        nextProfile,
      });
    }

    this.setState({ modalAppointmentIsVisible: false, selectedParticipant: null, isMultipleSelectAction: false, participantActionBtnDisabled });

    if (typeof callback === 'function') {
      callback();
    }
  };

  handleOnShowParticipant = (participant, saveVisit) => {
    const { props: { visitParticipant, event, context, setOpenParticipant } } = this;

    if (participant) {
      setOpenParticipant(participant._id);

      this.processQueryChange({ participant: participant._id });
      this.setState({ profileNavigation: null, openParticipant: participant });

      if (saveVisit) {
        visitParticipant({
          eventId: event._id,
          participantId: participant._id,
          context,
        });
      }
    }
  };

  handleOnCloseParticipant = () => {
    const { props: { setOpenParticipant, history } } = this;

    const location = { ...history.location };

    location.query = { ...queryStringToObject(location.search), participant: null, proposeModal: null };
    location.search = objectToParams(location.query);

    this.setState({ queryParams: location.query, search: location.query });

    setOpenParticipant(null);
    history.push(location);
  };

  /**
   * Handle change
   *
   * @param {object} newParams
   */
  processQueryChange = (newParams) => {
    const { props: { getParticipants, event, context, pagination, history } } = this;
    const location = { ...history.location };

    location.query = queryStringToObject(location.search);

    const queryMap = isEmpty(location.query) ? Map(newParams) : Map(location.query);
    const newMapParams = queryMap.merge(newParams);

    const query = newMapParams.toJS();

    // We have to filter out experiences -1 if there is more than one experience because
    // experiences -1 means everyone
    // eslint-disable-next-line eqeqeq
    if (query.experiences && query.experiences !== '-1') {
      if (query.experiences.length > 0) {
        query.experiences = typeof query.experiences === 'string' ? query.experiences.split(',') : query.experiences.filter(experience => experience !== '-1');
      } else if (query.experiences.length === 0) {
        query.experiences.push('-1')
      }
    }

    location.query = query;
    location.search = objectToParams(location.query);

    this.setState({ queryParams: location.query, search: location.query });

    if (!newParams.participant || newParams.participant === 'null') {
      const offset = location.query.page === 1 || location.query.page === undefined ? 0 : (location.query.page * pagination.pageSize) - pagination.pageSize;
      const search = omit(location.query, ['page', 'context']);

      getParticipants({
        eventId: event._id,
        context,
        offset,
        page: location.query.page,
        search,
      });
    }

    history.push(location);
  };

  /**
   * HandleKeywordsChange
   *
   * @param {array} keywords
   */
  handleKeywordsChange = (keywords) => {
    this.processQueryChange({ keywords: keywords.join(','), facetKey: null, page: 1 });
  };

  /**
   * HandleDownload
   *
   * @param {object} participant
   */
  handleDownload = (participant) => {
    const { authUser, event, postAction } = this.props;
    const resumeUrl = getParticipantResumeUrl(participant, event.slug, true);

    if (resumeUrl) {
      postAction({
        actionParams: {
          name: 'RECRUITER_DOWNLOAD_CV',
          _user: authUser._id,
          _organization: authUser._currentOrganization._id,
          _event: event._id,
          participantId: participant._id,
          data: { candidate: participant._user._id, resumeUrl },
        },
      });
      track({
        name: ANALYTICS_RECRUITER.DOWNLOADED_CV,
        user: authUser,
        event,
        properties: {
          roles: getCurrentRole(authUser.roles, authUser._currentOrganization._id)?._role?.key,
        },
      });
      window.open(resumeUrl, '_blank');
    }
  };

  /**
   * handleDisplayContact
   *
   * @param {object} participant
   */
  handleDisplayContact = (participant) => {
    const { authUser, event, postAction } = this.props;

    postAction({
      actionParams: {
        name: 'RECRUITER_DISPLAY_CONTACT',
        _user: authUser._id,
        _organization: authUser._currentOrganization._id,
        _event: event._id,
        participantId: participant._id,
        data: { candidate: participant._user._id },
      },
    });
  };

  trackModifyAppointment = (type, status, message) => {
    const { props: { authUser, event } } = this;
    track({
      name: ANALYTICS_RECRUITER.CLICKED_CHANGE_APPOINTMENT,
      user: authUser,
      event,
      properties: {
        type,
        status,
        message,
      },
    });
  };

  handleOnSyncIntercom = () => {
    const { props: { context, event, authUser } } = this;

    this.setState({ isSyncing: true });

    request(`${process.env.FRONT_API_URL}/events/${event._id}/participants/tagParticipantsInIntercom`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ context }),
    }).then((result) => {
      this.setState({
        isSyncing: false,
        confirmModalSyncIntercomIsVisible: true,
        syncIntercomUsers: result.indexUsers,
        notSyncIntercomUsers: result.notIndexUsers,
      });
    });
  };

  handleOnModifyAppointment = (e, appointment) => {
    const { props: { modifyAppointment, event, location, match, context, authUser }, getSearchParticipantsParams } = this;
    const { page, offset, search } = getSearchParticipantsParams();
    modifyAppointment({
      appointmentId: appointment._id,
      timeslotId: appointment._timeslot ? (appointment._timeslot._id || appointment._timeslot) : null,
      eventSlug: event.slug,
      redirect: `${location.pathname}`,
      currentUser: authUser,
      participantsParams: {
        eventId: match.params.eventSlug,
        context,
        offset,
        page,
        search,
      },
      callback: () => {
        track({
          name: ANALYTICS_RECRUITER.CHANGED_APPOINTMENT,
          user: authUser,
          event: event._id,
        });
      },
    });
  };

  handleOnCancelAppointment = ({ message }, appointment, callback) => {
    const { props: { cancelAppointment, event, location, match, authUser, context, intl }, getSearchParticipantsParams } = this;
    const { page, offset, search } = getSearchParticipantsParams();
    cancelAppointment({
      appointmentId: appointment._id,
      timeslotId: appointment._timeslot ? (appointment._timeslot._id || appointment._timeslot) : null,
      eventId: match.params.eventSlug,
      userId: authUser,
      redirect: `${location.pathname}`,
      eventSlug: event.slug,
      currentUser: authUser,
      message,
      participantsParams: {
        eventId: match.params.eventSlug,
        context,
        offset,
        page,
        search,
      },
      notificationParams: {
        success: {
          message: `🙌 ${intl.formatMessage(messages.modificationSuccess)}`,
          kind: 'info',
          style: {
            bottom: '5%',
            top: 'inherit',
          },
        },
        error: true,
      },
      callback: () => {
        track({
          name: ANALYTICS_RECRUITER.CANCELED_APPOINTMENT,
          user: authUser,
          event: event._id,
          properties: {
            message,
          },
        });
        callback();
      },
    });
  };

  handleOnChangeAppointmentDate = ({ message }, appointment, callback) => {
    const { props: { patchAppointmentDate, event, location, match, authUser, context, intl }, getSearchParticipantsParams } = this;
    const { page, offset, search } = getSearchParticipantsParams();

    patchAppointmentDate({
      appointmentId: appointment._id,
      timeslotId: appointment._timeslot ? (appointment._timeslot._id || appointment._timeslot) : null,
      eventId: match.params.eventSlug,
      userId: authUser,
      redirect: `${location.pathname}`,
      eventSlug: event.slug,
      currentUser: authUser,
      message,
      participantsParams: {
        eventId: match.params.eventSlug,
        context,
        offset,
        page,
        search,
      },
      notificationParams: {
        success: {
          message: `🙌 ${intl.formatMessage(messages.modificationSuccess)}`,
          kind: 'info',
          style: {
            bottom: '5%',
            top: 'inherit',
          },
        },
        error: true,
      },
      callback: () => { this.trackModifyAppointment('date', appointment.status, message); callback(); },
    });
  };

  handleOnChangeAppointmentOwner = ({ user, message }, appointment, callback) => {
    const { props: { patchAppointmentOwner, event, location, match, authUser, context, intl }, getSearchParticipantsParams } = this;
    const { page, offset, search } = getSearchParticipantsParams();
    const ownerId = get(user, '_user._id');

    patchAppointmentOwner({
      appointmentId: appointment._id,
      timeslotId: appointment._timeslot ? (appointment._timeslot._id || appointment._timeslot) : null,
      eventId: match.params.eventSlug,
      userId: authUser._id,
      redirect: `${location.pathname}`,
      eventSlug: event.slug,
      currentUser: authUser,
      ownerId,
      message,
      participantsParams: {
        eventId: match.params.eventSlug,
        context,
        offset,
        page,
        search,
      },
      notificationParams: {
        success: {
          message: `🙌 ${intl.formatMessage(messages.modificationSuccess)}`,
          kind: 'info',
          style: {
            bottom: '5%',
            top: 'inherit',
          },
        },
        error: true,
      },
      callback: () => { this.trackModifyAppointment('recruiter', appointment.status, message); callback(); },
    });
  };

  handleOnChangeInteractionOwner = ({ user, message }, interaction, callback) => {
    const { props: { patchInteractionOwner, event, location, match, authUser, context, intl }, getSearchParticipantsParams } = this;
    const { page, offset, search } = getSearchParticipantsParams();
    const ownerId = get(user, '_user._id');

    patchInteractionOwner({
      interactionId: interaction._id,
      eventId: match.params.eventSlug,
      userId: authUser._id,
      redirect: `${location.pathname}`,
      eventSlug: event.slug,
      currentUser: authUser,
      ownerId,
      message,
      participantsParams: {
        eventId: match.params.eventSlug,
        context,
        offset,
        page,
        search,
      },
      notificationParams: {
        success: {
          message: `🙌 ${intl.formatMessage(messages.modificationSuccess)}`,
          kind: 'info',
          style: {
            bottom: '5%',
            top: 'inherit',
          },
        },
        error: true,
      },
      callback,
    });
  };

  handleOnBanParticipant = ({ _id }, callback) => {
    const { props: { banParticipant, event, match, authUser, context, intl, getParticipants }, getSearchParticipantsParams } = this;
    const { page, offset, search } = getSearchParticipantsParams();

    banParticipant({
      eventSlug: event.slug,
      currentUser: authUser,
      participantId: _id,
      userId: authUser,
      eventId: match.params.eventSlug,
      participantParams: {
        eventId: event._id,
        context,
        offset,
        page,
        search,
      },
      notificationParams: {
        success: {
          message: `🙌 ${intl.formatMessage(messages.modificationSuccess)}`,
          kind: 'info',
          style: {
            bottom: '5%',
            top: 'inherit',
          },
        },
        error: {
          message: `🙌 ${intl.formatMessage(messages.errorCannotBanParticipant)}`,
          kind: 'error',
          style: {
            bottom: '5%',
            top: 'inherit',
          },
        },
      },
      callback: () => {
        callback();
        getParticipants({
          eventId: event._id,
          context,
          offset,
          page: location.query.page,
          search,
        });
      },
    });
  };

  handleOnCardToggle = (isChecked, id, checkedParticipants) => {
    this.setState({ checkedParticipants, isBtnAllParticipantsActive: false, isBtnPageParticipantsActive: checkedParticipants.length === this.props.participants.length });
  }

  handleCloseModal = () => {
    this.setState({ modalAppointmentIsVisible: false });
  }

  handleChangePageAnOpenProfile = (page, type) => {
    const { handleOnChangePagination } = this;

    handleOnChangePagination(page);

    this.setState({ profileNavigation: { page, type } });
  };

  handleOnTogglePageParticipants = () => {
    const { props: { participants }, state: { isBtnPageParticipantsActive } } = this;
    // On active click
    const checkedParticipants = isBtnPageParticipantsActive ? [] : participants.map((participant) => participant._id);
    this.setState({ isBtnPageParticipantsActive: !isBtnPageParticipantsActive, checkedParticipants, isBtnAllParticipantsActive: false });
  };

  handleOnToggleAllParticipants = () => {
    this.setState({ isBtnAllParticipantsActive: !this.state.isBtnAllParticipantsActive });
  };

  handleGroupedAction = (type, moveType, participant, grouped = true) => {
    const { props: { participants, authUser, event, exponent, context }, state: { checkedParticipants }, handleOnMove, getCountParticipantChecked } = this;
    const firstParticipantChecked = participants.find(((participant) => participant._id === checkedParticipants[0]));
    const count = getCountParticipantChecked();

    if (type === 'move') {
      this.modals.confirmGroupedActions.open({
        states: {
          isMultipleSelectAction: grouped,
          confirmedModalType: { type, moveType },
          selectedParticipant: participant,
          actionAfterConfirmed: () => {
            handleOnMove(grouped ? null : participant, moveType);
            if (grouped) {
              track({
                name: ANALYTICS_RECRUITER.MOVE_PROFILES,
                user: authUser,
                event,
                properties: {
                  'User type': context.indexOf('admin') === -1 && exponent && exponent.users ? exponent.users.find((user) => user._user._id === authUser._id).role : 'admin',
                  'Initial tab': CONTEXTS[context],
                  'destination tab': CONTEXTS[moveType],
                  'several actions': true,
                  count,
                },
              });
            }
          },
        },
      });
    } else if (['propose', 'accept', 'refuse'].includes(type)) {
      this.setState({ isMultipleSelectAction: true, isRefuseContext: type === 'refuse', selectedParticipant: firstParticipantChecked, modalAppointmentIsVisible: true });
    } else if (type === 'hide') {
      this.modals.confirmGroupedActions.open({ states: { isMultipleSelectAction: true, confirmedModalType: { type }, actionAfterConfirmed: () => { handleOnMove(null, 'refuse'); this.analyticsHidCandidate(); } } });
    }
  };

  analyticsGroupedHidCandidate = () => {
    const { props: { authUser, exponent }, getCountParticipantChecked } = this;
    const exponentUser = exponent ? find(exponent.users, (user) => user._user._id === authUser._id) : null;
    const role = exponentUser ? exponentUser.role : null;
    const count = getCountParticipantChecked();

    track({
      name: ANALYTICS_RECRUITER.HID_CANDIDATES,
      user: authUser,
      event: exponent._event,
      properties: {
        'User Type': role,
        'several actions': true,
        count,
      },
    });
  };

  handleCloseCardInfo = () => {
    window.localStorage.setItem(
      `${this.props.authUser.id}//${this.props.event.slug}`,
      JSON.stringify({ isCardInfoVisible: false }),
    );
    this.setState({ isCardInfoVisible: false });
  };

  modals = {
    confirmGroupedActions: {
      open: ({ states }) => { this.setState({ ...states, isConfirmGroupedActionsModalVisible: true }); },
      close: () => { this.setState({ isConfirmGroupedActionsModalVisible: false }); },
      onConfirm: () => this.state.actionAfterConfirmed(),
      getContents: () => {
        const count = this.getCountParticipantChecked();

        return {
          move: {
            title: this.state.isMultipleSelectAction ?
              <FormattedMessage {...messages.moveConfirmTitle} values={{ count, context: this.state.confirmedModalType.moveType && <FormattedMessage {...messages[`move_${this.state.confirmedModalType.moveType}`.replace('admin', '')]} /> }} />
              : <FormattedMessage {...messages.moveConfirmTitle2} values={{ candidateFirstName: get(this.state.selectedParticipant, '_user.firstName'), context: this.state.confirmedModalType.moveType && <FormattedMessage {...messages[`move_${this.state.confirmedModalType.moveType}`.replace('admin', '')]} /> }} />,
            text: <FormattedMessage {...messages.moveConfirm} values={{ count, context: this.state.confirmedModalType.moveType && <FormattedMessage {...messages[`move_${this.state.confirmedModalType.moveType}`.replace('admin', '')]} /> }} />,
            component: this.state.isMultipleSelectAction ? null : <div className={styles.groupedActionsModal} style={{ marginBottom: '20px' }}>
              <Checkbox
                onChange={(e) => setLocalStorage(LOCAL_STORAGE.recruiter.hideConfirmOnParticipantMove, e.target.checked)}
              >
                <FormattedMessage {...messages.notDisplayMessage} />
              </Checkbox>
            </div>,
            action: () => {
              this.handleOnMove(this.state.isMultipleSelectAction ? null : this.state.selectedParticipant, this.state.confirmedModalType.moveType, null, this.modals.confirmGroupedActions.close);

              if (this.state.isMultipleSelectAction) {
                const { exponent, authUser } = this.props;
                const expUser = exponent && exponent.users ? exponent.users.find((user) => user._user._id === authUser._id) : null;

                track({
                  name: ANALYTICS_RECRUITER.MOVE_PROFILES,
                  user: this.props.authUser,
                  event: this.props.event,
                  properties: {
                    'User type': this.props.context && this.props.context.indexOf('admin') === -1 && expUser ? expUser.role : 'admin',
                    'Initial tab': CONTEXTS[this.props.context],
                    'destination tab': CONTEXTS[this.state.confirmedModalType.type],
                    'several actions': true,
                    count,
                  },
                });
              }
            },
          },
          hide: {
            title: <FormattedMessage {...messages.hideConfirmTitle} values={{ count }} />,
            text: <FormattedMessage {...messages.hideConfirm} values={{ count }} />,
            action: () => { this.handleOnMove(null, 'refuse'); this.analyticsGroupedHidCandidate(); },
          },
        }[this.state.confirmedModalType.type];
      },
    },
  };

  handleOnConfirmAppointment = (participant, appointment, nextProfile) => {
    const { props: { confirmAppointment, getParticipants, event, context }, getSearchParticipantsParams } = this;
    const { page, offset, search } = getSearchParticipantsParams();

    confirmAppointment({
      appointmentId: appointment._id,
      eventSlug: event.slug,
      nextProfile,
      search,
      context,
      offset,
      page,
      callback: () => {
        getParticipants({
          eventId: this.props.match.params.eventSlug,
          hideIds: [participant._id],
          context,
          offset,
          page,
          search,
        });
      },
    });
  };

  getPartnerLabel = () => {
    const { authUser, event } = this.props;
    const locale = authUser?.locale;

    return event?.modules?.partner?.[locale === 'fr' ? 'label_fr' : 'label_en'];
  }

  render() {
    const {
      props: { openedParticipant, pagination, participants, authUser, context, event, offers, exponent, goToEditPage, stats, location, isLoading, facets, schools, intl, actionIsPending, countNew, match },
      state: {
        queryParams, selectedParticipants, search, modalAppointmentIsVisible, openParticipant, isSyncing, isMoving,
        confirmModalSyncIntercomIsVisible, selectedParticipant, currentPage, checkedParticipants, isBtnAllParticipantsActive,
        isMultipleSelectAction, participantActionBtnDisabled, syncIntercomUsers, notSyncIntercomUsers, isRefuseContext, isCardInfoVisible,
      },
      handleOnChangePagination, handleChangePageAnOpenProfile, handleOnSelectParticipant, handleOnShowAppointmentModal, handleOnCardToggle,
      handleKeywordsChange, handleOnShowParticipant, handleOnCloseParticipant, handleOnCancelAppointment, onCloseModalSyncIntercomIsVisible,
      handleOnSubmitAppointment, processQueryChange, handleOnGroupedSelectionChange, handleOnSyncIntercom,
      handleOnModifyAppointment, handleOnChangeAppointmentDate, handleOnBanParticipant, handleOnChangeAppointmentOwner, handleOnChangeInteractionOwner,
      handleDownload, handleDisplayContact, handleOnMove, handleCloseModal, onReset, handleOnSort, handleOnConfirmAppointment,
      handleToogleCoreTarget,
      handleCoreTargets,
      handleCloseCardInfo,
      getPartnerLabel,
    } = this;

    if ((isEmpty(exponent) && context.indexOf('admin') === -1) || isEmpty(event)) {
      return null;
    }

    let keywords = [];

    if (typeof search.keywords === 'object') {
      keywords = search.keywords;
    } else if (search.keywords) {
      keywords = search.keywords.split(',');
    }

    if (isLoading) {
      return (<LoadingIndicator />);
    } if (isSyncing) {
      return (
        <div className={styles.loaderSyncContainer}>
          <LoadingIndicator className={styles.loaderSync} />
          <div>{intl.formatMessage(messages.syncLoader)}</div>
        </div>
      );
    }

    const dynamicContext = this.getDynamicContext();
    let blankState;
    let showSearchBar = true;

    const cleanSearch = omit(search, ['page', 'offer', 'participant']);

    if (!isMoving && participants.length === 0) {
      showSearchBar = false;

      switch (context) {
        case 'applications':
          if (isEmpty(location.query)) {
            if (stats.allApplications > 0) {
              blankState = (
                <BlankState
                  style={{ marginTop: '80px' }}
                  title={intl.formatMessage(messages.blankStateApplicationsManageTitle)}
                  content={intl.formatMessage(messages.blankStateApplicationsManageContent)}
                  icon="blank-state-rocket"
                  handleAction={() => goToEditPage(`/${event.slug}/recruiter/jobdating/sourcing`)}
                  buttonLabel={intl.formatMessage(messages.blankStateApplicationsManageBtn)}
                >
                </BlankState>
              );
            } else {
              blankState = (
                <BlankState
                  style={{ marginTop: '80px' }}
                  className={styles.blankState}
                  title={intl.formatMessage(messages.blankStateApplicationsEmptyTitle)}
                  content={<a role="button" tabIndex={0} onClick={() => goToEditPage(`/${event.slug}/recruiter/jobdating/sourcing`)}>{intl.formatMessage(messages[`blankState${capitalize(context)}ManageContent`])}</a>}
                  icon="blank-state-cactus"
                />
              );
            }
          } else {
            showSearchBar = true;

            blankState = (
              <BlankState
                style={{ marginTop: '80px' }}
                title={intl.formatMessage(messages.blankStateSearchTitle)}
                content={isEmpty(location.query) ? intl.formatMessage(messages.blankStateSearchContentEmptySearch) : intl.formatMessage(messages.blankStateSearchContentBadSearch)}
                icon="blank-state-nothing-found"
              />
            );
          }

          break;
        case 'sourcing':
          if (isEmpty(cleanSearch)) {
            blankState = (
              <BlankState
                style={{ marginTop: '80px' }}
                title={intl.formatMessage(messages.blankStateSourcingManageTitle)}
                content={intl.formatMessage(messages.blankStateSourcingManageContent)}
                icon="blank-state-rocket"
                handleAction={() => goToEditPage(`/${event.slug}/recruiter/jobdating/interview`)}
                buttonLabel={intl.formatMessage(messages.blankStateSourcingManageBtn)}
              />
            );
          } else {
            showSearchBar = true;

            blankState = (
              <BlankState
                style={{ marginTop: '80px' }}
                title={intl.formatMessage(messages.blankStateSearchTitle)}
                content={isEmpty(location.query) ? intl.formatMessage(messages.blankStateSearchContentEmptySearch) : intl.formatMessage(messages.blankStateSearchContentBadSearch)}
                icon="blank-state-nothing-found"
              />
            );
          }
          break;
        case 'all':
          if (isEmpty(cleanSearch)) {
            blankState = (
              <BlankState
                style={{ marginTop: '80px' }}
                className={styles.blankState}
                content={(
                  <>
                    <p>
                      {intl.formatMessage({ id: 'jobdating.recruiter.participants.blankState.title' })}
                      <br />
                      {['preregistration', 'discovering', 'open'].includes(event.dateStatus) ?
                        intl.formatMessage({ id: 'jobdating.recruiter.participants.blankState.content' }, { date: moment(event.keyDates.jobfair.beginAt).format('D MMM. YYYY') }) : ''}
                    </p>

                    {['preregistration', 'discovering', 'open'].includes(event.dateStatus) ? (
                      <Countdown
                        type="inline"
                        targetDate={event.dateStatus === 'in_progress' ? event.keyDates.jobfair.endAt : event.keyDates.jobfair.beginAt}
                        icon="clockColor"
                      />
                    ) : ''}
                  </>
                )}
                icon="blank-state-cactus"
              />
            );
          } else {
            showSearchBar = true;

            blankState = (
              <BlankState
                style={{ marginTop: '80px' }}
                title={intl.formatMessage(messages.blankStateSearchTitle)}
                content={isEmpty(location.query) ? intl.formatMessage(messages.blankStateSearchContentEmptySearch) : intl.formatMessage(messages.blankStateSearchContentBadSearch)}
                icon="blank-state-nothing-found"
              />
            );
          }
          break;

        default:
          if (isEmpty(cleanSearch)) {
            blankState = (
              <BlankState
                className={styles.blankState}
                title={intl.formatMessage(messages.blankStateTitle)}
                style={{ marginTop: '80px' }}
                icon="blank-state-cactus"
              />
            );
          } else {
            showSearchBar = true;

            blankState = (
              <BlankState
                style={{ marginTop: '80px' }}
                title={intl.formatMessage(messages.blankStateSearchTitle)}
                content={isEmpty(location.query) ? intl.formatMessage(messages.blankStateSearchContentEmptySearch) : intl.formatMessage(messages.blankStateSearchContentBadSearch)}
                icon="blank-state-nothing-found"
              />
            );
          }
      }
    }

    const highlightWords = typeof search.keywords === 'string' ? [search.keywords] : search.keywords;

    const totalParticipants = context && stats[context] ? stats[context] : 0;
    const publishedOffers = !isEmpty(offers) ? offers.filter((offer) => offer.status === 'published') : [];

    const hasFilter = event.format === 'hybrid' || !isEmpty(schools) ||
      (!isEmpty(publishedOffers)) || !isEmpty(event._criteria.filter((criterion) => criterion.enable));

    const exponentUser = exponent ? exponent.users.find((u) => u._user && u._user._id === authUser._id) : null;
    const newDate = context === 'sourcing' || context === 'applications' ? moment(authUser.lastView).subtract(6, 'hours') : null;
    const countCheckedParticipants = size(checkedParticipants);
    const showMoving = (countCheckedParticipants >= pagination.pageSize && isMoving);

    return (
      <Wrapper size="large">
        {modalAppointmentIsVisible ? <AppointmentModal
          visible={modalAppointmentIsVisible}
          participant={selectedParticipant}
          isMultiple={isMultipleSelectAction}
          countParticipants={this.getCountParticipantChecked()}
          exponent={exponent}
          authUser={authUser}
          offers={offers}
          onOk={handleOnSubmitAppointment}
          onClose={handleCloseModal}
          context={context}
          onCloseParticipant={handleOnCloseParticipant}
          isRefuseContext={isRefuseContext}
        /> : null
        }

        <ModalConfirm
          visible={this.state.isConfirmGroupedActionsModalVisible}
          onCancel={this.modals.confirmGroupedActions.close}
          onConfirm={this.modals.confirmGroupedActions.getContents(this.state.confirmedModalType).action}
          wrapClassName={styles.groupedActionsModal}
        >
          <h2>{this.modals.confirmGroupedActions.getContents(this.state.confirmedModalType).title}</h2>
          <p>{this.modals.confirmGroupedActions.getContents(this.state.confirmedModalType).text}</p>
          {this.modals.confirmGroupedActions.getContents(this.state.confirmedModalType).component}
        </ModalConfirm>

        {exponent?.isPartner && (
          <div className={styles.cardInfo}>
            <CardInfo
              icon={
                <Avatar
                  variant="business"
                  src={authUser?._currentOrganization?.profile?.pictureUrl}
                  color={event?.modules?.partner?.color}
                  badge={event?.modules?.partner?.icon}
                  tooltip={event?.modules?.partner?.[authUser?.locale === 'fr' ? 'label_fr' : 'label_en']}
                />
              }
              title={intl.formatMessage({ id: 'event.recruiter.jobdating.partner.title' }, { label: getPartnerLabel() })}
              description={intl.formatMessage({ id: 'event.recruiter.jobdating.partner.description' })}
              handleClose={handleCloseCardInfo}
              isClosable
              visible={isCardInfoVisible}
            />
          </div>
        )}

        <Row type="flex" gutter={16}>
          {hasFilter ? (
            <Col span={3}>
              <SearchEngine
                context={context}
                authUser={authUser}
                event={event}
                exponent={exponent}
                criterion={event._criteria}
                offers={publishedOffers}
                search={search}
                facetsSyncing={isMoving}
                facets={facets}
                schools={schools}
                onChange={(search) => processQueryChange(search)}
                onReset={() => onReset()}
                location={location}
              />
            </Col>
          ) : null}

          <Col span={hasFilter ? 9 : 12}>
            <PageDescription
              context={dynamicContext}
              keyDates={event.keyDates}
              skipAppointmentValidation={event.skipAppointmentValidation}
              hideSyncHelp={authUser.hideSyncHelp}
            />

            {
              ['confirmed', 'unconfirmed', 'refused', 'interview', 'transferred'].includes(context) ? <InterviewAutoNav
                push={this.props.push}
                slug={event.slug}
                event={event}
                exponent={exponent}
                skipAppointmentValidation={event.skipAppointmentValidation}
                context={dynamicContext}
                stats={this.props.stats}
                onMenuItemChange={(context) => {
                  this.setState({ context });
                  this.props.getParticipants({
                    eventId: this.props.match.params.eventSlug,
                    context,
                    offset: 0,
                    page: 1,
                    search,
                  });
                }}
              /> : null
            }
            <ResultsContainer style={{ minHeight: 400 }}>
              {showSearchBar ? (
                <div className={styles.marginItem}>
                  <Select
                    mode="tags"
                    style={{ width: '100%' }}
                    placeholder={intl.formatMessage(messages.searchByKeyword)}
                    dropdownStyle={{ display: 'none' }}
                    onChange={handleKeywordsChange}
                    onSearch={() => { }}
                    noFloatingLabel
                    placeholderIcon="search"
                    defaultValue={keywords}
                    value={keywords}
                    className="searchSelect"
                  />
                </div>
              ) : ''}
              {participants.length === 0 ? blankState : (
                <>
                  <GroupedActions
                    handleGroupedSelectionChange={handleOnGroupedSelectionChange}
                    handleSyncIntercom={handleOnSyncIntercom}
                    onToggleAllParticipants={this.handleOnToggleAllParticipants}
                    onTogglePageParticipants={this.handleOnTogglePageParticipants}
                    isBtnAllParticipantsChecked={this.state.isBtnAllParticipantsActive}
                    isBtnPageParticipantsActive={this.state.isBtnPageParticipantsActive}
                    handleGroupedAction={this.handleGroupedAction}
                    selectedParticipants={selectedParticipants}
                    handleCloseModal={onCloseModalSyncIntercomIsVisible}
                    currentPage={currentPage}
                    checkedParticipants={this.state.checkedParticipants}
                    context={dynamicContext}
                    pagination={pagination}
                    countParticipants={participants.length}
                    totalParticipants={totalParticipants}
                    handleSort={handleOnSort}
                    sort={queryParams.sort}
                    modalVisible={confirmModalSyncIntercomIsVisible}
                    syncIntercomUsers={syncIntercomUsers}
                    notSyncIntercomUsers={notSyncIntercomUsers}
                    event={event}
                    exponent={exponent}
                    handleCoreTargets={handleCoreTargets}
                  />
                  {exponentUser && exponentUser[`visit${context[0].toUpperCase()}${context.substring(1)}`] === true && countNew > 0 && pagination && pagination.offset === 0 && (queryParams.sort === 'new' || context === 'applications') ? (
                    <TextSeparator text={intl.formatMessage(context === 'applications' ? messages.countNewApplications : messages.countNewSourcing, { count: countNew })} textstyle={{ backgroundColor: '#F5F6FA', textColor: '#33A8EB' }} style={{ marginTop: 34, height: 25 }} />

                  ) : ''}

                  <ParticipantList
                    participants={participants}
                    showMoving={showMoving}
                    defaultParticipant={search.participant}
                    openedParticipant={openedParticipant}
                    openParticipant={openParticipant}
                    isAllParticipantChecked={this.state.isBtnPageParticipantsActive}
                    checkedParticipants={this.state.checkedParticipants}
                    isModifyAppointmentModalVisible={this.state.isModifyAppointmentModalVisible}
                    modalStatus={this.state.modifyAppointmentModalType}
                    authUser={authUser}
                    event={event}
                    handleGroupedAction={this.handleGroupedAction}
                    context={dynamicContext}
                    offers={offers}
                    selectedParticipants={selectedParticipants}
                    onSelectParticipant={handleOnSelectParticipant}
                    handleCardToggle={handleOnCardToggle}
                    handleOnShowAppointmentModal={handleOnShowAppointmentModal}
                    handleMove={handleOnMove}
                    handleToogleCoreTarget={handleToogleCoreTarget}
                    handleConfirmAppointment={handleOnConfirmAppointment}
                    handleCancelAppointment={handleOnCancelAppointment}
                    handleModifyAppointment={handleOnModifyAppointment}
                    handleChangeAppointmentOwner={handleOnChangeAppointmentOwner}
                    handleChangeInteractionOwner={handleOnChangeInteractionOwner}
                    handleChangeAppointmentDate={handleOnChangeAppointmentDate}
                    handleBanParticipant={handleOnBanParticipant}
                    handleDownload={handleDownload}
                    handleDisplayContact={handleDisplayContact}
                    onShowParticipant={handleOnShowParticipant}
                    onCloseParticipant={handleOnCloseParticipant}
                    pagination={pagination}
                    handleProfileNavigation={handleChangePageAnOpenProfile}
                    exponent={exponent}
                    highlightWords={highlightWords !== undefined ? highlightWords : []}
                    location={location}
                    exponentUser={exponentUser}
                    newDate={newDate}
                    participantActionBtnDisabled={participantActionBtnDisabled || actionIsPending}
                    interactions={this.props.interactions}
                    match={match}
                  />

                  <Separator height={30} />

                  <div className="pagination-container">
                    <Pagination
                      className="flex justify-center"
                      current={queryParams.page ? parseInt(queryParams.page, 10) : 1}
                      pageSize={pagination.pageSize}
                      total={pagination.total}
                      onChange={handleOnChangePagination}
                    />
                  </div>
                </>
              )}
              {showMoving ? (
                <div className={styles.loaderSyncContainer}>
                  <LoadingIndicator className={styles.loaderSync} />
                  <div>{intl.formatMessage(messages.multipleLoader, { count: isBtnAllParticipantsActive ? pagination.total : countCheckedParticipants })}</div>
                </div>
              ) : null}
            </ResultsContainer>
          </Col>
        </Row>
      </Wrapper>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser,
  exponent: exponentSelectors.getCurrentExponent,
  pagination: participantSelectors.getParticipantPagination,
  openedParticipant: participantSelectors.getOpenParticipant,
  event: eventSelectors.getCurrentEvent,
  participants: participantSelectors.getParticipants,
  interactions: interactionSelectors.getInteractions,
  facets: participantSelectors.getParticipantsFacets,
  schools: participantSelectors.getParticipantsSchools,
  stats: participantSelectors.getParticipantsStats,
  offers: offerSelectors.getOffers,
  isLoading: participantSelectors.getParticipantsFetching,
  actionIsPending: participantSelectors.getActionIsPending,
  countNew: participantSelectors.getCountNew,
});

const mapDispatchToProps = {
  getOrganizationOffers: offerActions.getOrganizationOffers,
  setOpenParticipant: participantActions.setOpenParticipant,
  getParticipant: participantActions.getParticipant,
  getParticipants: participantActions.getParticipants,
  getParticipantsStats: participantActions.getParticipantsStats,
  refuseParticipant: participantActions.refuseParticipant,
  refuseParticipants: participantActions.refuseParticipants,
  selectParticipant: participantActions.selectParticipant,
  selectParticipants: participantActions.selectParticipants,
  visitParticipant: participantActions.visitParticipant,
  moveParticipant: participantActions.moveParticipant,
  moveParticipants: participantActions.moveParticipants,
  resetParticipantsList: participantActions.resetParticipantsList,
  goToEditPage: push,
  banParticipant: participantActions.banParticipant,
  batchParticipants: participantActions.batchParticipants,
  confirmAppointment: appointmentActions.confirmAppointment,
  modifyAppointment: appointmentActions.modifyAppointment,
  cancelAppointment: appointmentActions.cancelAppointment,
  patchAppointmentDate: appointmentActions.patchAppointmentDate,
  patchAppointmentOwner: appointmentActions.changeAppointmentOwner,
  patchInteractionOwner: interactionActions.changeInteractionOwner,
  refuseAppointment: appointmentActions.refuseAppointment,
  postAction: actionActions.postAction,
  toogleCoreTarget: participantActions.toogleCoreTarget,
  toogleCoreTargets: participantActions.toogleCoreTargets,
  push,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withRouter,
  withConnect,
  injectIntl,
)(toJS(ParticipantsView));
