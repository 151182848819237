import React, { useState } from 'react'
import moment from 'moment';
import 'moment-timezone';
import { func, object, any, bool, array } from 'prop-types';

// components
import Slots from './components/Slots';
import AppointmentConfirm from './components/AppointmentConfirm';

const MainContent = ({
  handleShowOffer,
  toggleSettings,
  appointment,
  authUser,
  handleConfirmTimeslot,
  selectedTimeslot,
  handleCancelAppointment,
  onSelect,
  tracker,
  event,
  skipValidation,
  timeslots,
  participant,
  isEdit,
}) => {
  moment.tz.setDefault(authUser.timezone.utc[0]);

  const [slotSelected, setSlotSelected] = useState(null);
  const [appointmentConfirmed, setAppointmentConfirmed] = useState(!isEdit);


  const handleOnSelectSlot = (slot) => {
    setSlotSelected(slot)

    if (typeof onSelect === 'function') {
      onSelect(slot);
    }
  };

  const handleOnConfirmSelection = (messageInformal1to1Candidate) => {
    handleConfirmTimeslot({ slotSelected, appointment, messageInformal1to1Candidate, callback:  () => {
        setAppointmentConfirmed(true);
      }});
  };

  // Ask confirmation to refuse appointment
  const handleOnCancelSelection = (candidateMessage) => {
    setSlotSelected(null);
    handleCancelAppointment(candidateMessage)
  };

  return (
    <>
      {appointmentConfirmed ? (
        <AppointmentConfirm
          appointment={appointment}
          selectedTimeslot={selectedTimeslot}
          authUser={authUser}
          skipValidation={skipValidation}
          hideHelp={event?.type === 'jpo'}
        />
      ) : (
        <Slots
          handleSelectSlot={handleOnSelectSlot}
          authUser={authUser}
          toggleSettings={toggleSettings}
          handleShowOffer={handleShowOffer}
          slotSelected={slotSelected}
          timeslots={timeslots}
          appointment={appointment}
          skipValidation={skipValidation}
          tracker={tracker}
          handleConfirmSelection={handleOnConfirmSelection}
          handleCancelSelection={handleOnCancelSelection}
          participant={participant}
        />
      )}
    </>
  );
}

MainContent.propTypes = {
  handleShowOffer: func,
  toggleSettings: func,
  appointment: object,
  authUser: object,
  handleConfirmTimeslot: func,
  selectedTimeslot: any,
  handleCancelAppointment: func,
  onSelect: func,
  tracker: func,
  skipValidation: bool,
  timeslots: array,
  participant: object,
  event: object,
}


export default MainContent;
