import React, { useState } from 'react';
import { object, func, bool } from 'prop-types';
import { compose } from 'redux';
import { NavLink, withRouter, Link } from 'react-router-dom';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { useFormatMessage } from 'react-intl-hooks';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

// Components
import { Button } from '@seekube-tech/ui-kit';

import { Menu, Modal } from 'antd';
import { createStructuredSelector } from 'reselect';
import Icon from '@/components/Icon';
import Separator from '@/components/Separator';

// Store
import { eventActions } from '@/store/event';
import { getAvailableActions } from '@/store/availableActions/selectors';
import { hasValidKeyMoments } from '@/utils/keyMoments';
import { story } from '@/utils/manageVisibilityStory';
import CreateEventModal from '../../../../../../../Admin/scenes/Dashboard/containers/CreateEventModal';

// Styles & Translations
import messages from './messages';
import styles from './styles.less';

const { SubMenu, Item } = Menu;


const SettingsMenu = ({
  event,
  match,
  patchEvent,
  push,
  availableActions,
  isAdmin,
}) => {
  const t = useFormatMessage();

  const [showConfirmDeletionModal, setShowConfirmDeletionModal] = useState(false);

  const handleClickDelete = () => {
    patchEvent({
      event: { deleted: true },
      callback: () => push('/events'),
      notificationParams: {
        success: {
          message: `🙌 ${t({ id: 'event.deletion.notification.success' })}`,
          kind: 'info',
          style: {
            bottom: '5%',
            top: 'inherit',
          },
        },
        error: true,
      },
    });
  };


  return (
    <Menu
      className="eventOwnerSettings"
      style={{ width: 270, marginTop: '-20px', borderRight: 'none' }}
      mode="inline"
    >
      {/*  Basic Information */}
      <Item key="basic">
        <NavLink exact to={`${match.url}`} activeClassName="activeNavLink">
          {t({ id: 'event.menu.basic' })}
        </NavLink>
      </Item>

      <SubMenu
        key="limits"
        title={
          <span>
            {t({ id: 'event.menu.limits' })}
          </span>}
      >
        {/* Limits : default */}
        <Item key="limitsDefault">
          <NavLink to={`${match.url}/limits/candidates`} activeClassName="activeNavLink">
            {t({ id: 'event.menu.limits.byCandidates' })}
          </NavLink>
        </Item>

        {/* limits : organizations */}
        <Item key="limitsOrganizations">
          <NavLink to={`${match.url}/limits/organizations`} activeClassName="activeNavLink">
            {t({ id: 'event.menu.limits.byOrga' })}
          </NavLink>
        </Item>


        {/* limits : owners */}
        {story.orgaAccess.isVisible && isAdmin &&
          <Item key="limitsOwners">
            <NavLink to={`${match.url}/limits/owners`} activeClassName="activeNavLink">
              {t({ id: 'event.menu.limits.byOwners' })}
            </NavLink>
          </Item>
        }

      </SubMenu>

      {/* Participants */}
      <Item key="goals">
        <NavLink to={`${match.url}/goals`} activeClassName="activeNavLink">
          {t({ id: 'event.menu.goals' })}
        </NavLink>
      </Item>

      {/* Participants onboarding */}
      <Item key="participantsOnboarding">
        <NavLink to={`${match.url}/onboarding/participants`} activeClassName="activeNavLink">
          {t({ id: 'event.menu.participants.onboarding' })}
        </NavLink>
      </Item>

      {/* Matching */}
      <SubMenu
        key="matching"
        title={<span><FormattedMessage {...messages.menuMatching} /></span>}
      >
        {/* Matching : Dashboard */}
        <Item key="dashboard">
          <NavLink to={`${match.url}/matching/dashboard`} activeClassName="activeNavLink">
            {t({ id: 'event.matching.dashboard' })}
          </NavLink>
        </Item>

        {/* Matching : Onboarding */}
        <Item key="onboarding">
          <NavLink to={`${match.url}/matching/onboarding`} activeClassName="activeNavLink">
            {t({ id: 'event.matching.onboarding' })}
          </NavLink>
        </Item>

        {/* Matching : Candidate Profile */}
        <Item key="candidate-profile">
          <NavLink to={`${match.url}/matching/candidate-profile`} activeClassName="activeNavLink">
            {t({ id: 'event.matching.candidateProfile' })}
          </NavLink>
        </Item>

        {/* Matching : Search engine offer */}
        <Item key="search-participant">
          <NavLink to={`${match.url}/matching/search-participant`} activeClassName="activeNavLink">
            {t({ id: 'event.matching.searchEngineOffer' })}
          </NavLink>
        </Item>

        {/* Matching : Search engine candidate */}
        <Item key="search-exponent">
          <NavLink to={`${match.url}/matching/search-exponent`} activeClassName="activeNavLink">
            {t({ id: 'event.matching.searchEngineCandidate' })}
          </NavLink>
        </Item>

        {/* Matching : Offer */}
        <Item key="middle-offer">
          <NavLink to={`${match.url}/matching/offer`} activeClassName="activeNavLink">
            {t({ id: 'event.matching.offer' })}
          </NavLink>
        </Item>
      </SubMenu>

      {/* Modules */}
      <SubMenu
        key="modules"
        title={<span><FormattedMessage {...messages.menuModules} /></span>}
      >
        {/* Modules : Activations et labels des onglets */}
        <Item key="tabs">
          <NavLink to={`${match.url}/modules/tabs`} activeClassName="activeNavLink">
            {t({ id: 'event.modules.activation.and.tabs' })}
          </NavLink>
        </Item>
        {/* Modules : Corners */}
        <Item key="corner">
          <NavLink to={`${match.url}/modules/corner`} activeClassName="activeNavLink">
            {t({ id: 'corner.pl' }, { count: 2 })}
          </NavLink>
        </Item>
        {/* Modules : Onglet d'atterrissage */}
        <Item key="landing">
          <NavLink to={`${match.url}/modules/landing`} activeClassName="activeNavLink">
            {t({ id: 'event.modules.landing' })}
          </NavLink>
        </Item>

        {/* Modules : Feedback */}
        <Item key="feedback">
          <NavLink to={`${match.url}/modules/feedback`} activeClassName="activeNavLink">
            {t({ id: 'event.modules.feedback' })}
          </NavLink>
        </Item>

        {/* Modules : Partners */}
        <Item key="partners" className={styles.partnerLink} >
          <NavLink to={`${match.url}/modules/partner`} activeClassName="activeNavLink">
            {t({ id: 'event.modules.partners' })}
          </NavLink>
        </Item>

        {/* Modules : Dashboard Candidate */}
        <Item key="dashboardCandidate">
          <NavLink to={`${match.url}/modules/dashboardCandidate`} activeClassName="activeNavLink">
            {t({ id: 'settings.module.dashboardCandidate' })}
          </NavLink>
        </Item>
      </SubMenu>

      {/* Diffusion */}
      <Item key="diffusion">
        <NavLink to={`${match.url}/diffusion`} activeClassName="activeNavLink">
          {t({ id: 'event.menu.diffusion' })}
        </NavLink>
      </Item>

      {/* Reporting */}
      {isAdmin &&
        <Item key="reportings">
          <NavLink to={`${match.url}/reportings`} activeClassName="activeNavLink">
            {t({ id: 'reporting.pl' }, { count: 2 })}
          </NavLink>
        </Item>}

      <Separator height={10} />

      <Item key="edit" style={{ lineHeight: 'initial' }}>
        <Button
          className="w-full"
          onClick={() => window.open(`/${match.params.eventSlug}/edit`, '_blank')}
          disabled={!hasValidKeyMoments(event)}
        >
          {t({ id: 'event.menu.landing' })}
        </Button>
      </Item>

      <Item key="event" style={{ lineHeight: 'initial', height: 'auto' }}>
        <CreateEventModal match={match} defaultValues={event}>
          <Button className="w-full" variant="outline" color="neutral">
            {t({ id: 'event.menu.duplicate' })}
          </Button>
        </CreateEventModal>
      </Item>

      {availableActions.deleteEvent && (
        <Item key="remove" style={{ lineHeight: 'initial', height: 'auto'  }}>
          <Button className="w-full"  color="error" onClick={() => setShowConfirmDeletionModal(true)}>
            <span role="img" aria-label="warning">⚠️ </span>{availableActions.deleteEvent.getText()}
          </Button>
        </Item>
      )}

      <Modal
        visible={showConfirmDeletionModal}
        footer={false}
        maskClosable
        width={470}
        className="customConfirm"
        onCancel={() => setShowConfirmDeletionModal(false)}
      >
        <a role="button" tabIndex={0} className="modal-close" onClick={() => setShowConfirmDeletionModal(false)}>
          <Icon name="close" className="modal-close-icon" />
        </a>

        <h4 className="ant-confirm-title">
          <span role="img" aria-label="warning">⚠️ </span><FormattedHTMLMessage {...messages.eventDeletionModalConfirm} />
        </h4>

        <Separator height={15} />

        <div className="confirm-actions">
          <a className="ant-btn" role="button" tabIndex={0} onClick={() => setShowConfirmDeletionModal(false)}>
            {t({ id: 'cancel' })}
          </a>
          <Button
            onClick={() => {
              handleClickDelete(event);
              setShowConfirmDeletionModal(false);
            }}
          >
            {t({ id: 'btn.confirm' })}
          </Button>
        </div>
      </Modal>
      <Separator height={30} />
    </Menu >
  );
};

SettingsMenu.propTypes = {
  match: object,
  event: object,
  intl: object,
  patchEvent: func,
  push: func,
  availableActions: object,
  isAdmin: bool
};

const mapStateToProps = createStructuredSelector({
  availableActions: getAvailableActions
})

const mapDispatchToProps = {
  patchEvent: eventActions.patchEvent,
  push,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withRouter,
  withConnect,
)(SettingsMenu);
