import { defineMessages } from 'react-intl';

export default defineMessages({
  applicationRefused: {
    id: 'event.candidate.jobdating.offer.refused',
  },
  applicationScheduled: {
    id: 'event.candidate.jobdating.offers.tag.application.scheduled',
  },
  applicationAccepted: {
    id: 'event.candidate.jobdating.offer.refused',
  },
  appointmentPending: {
    id: 'event.candidate.jobdating.offers.tag.appointment.pending',
  },
  applicationPending: {
    id: 'event.candidate.jobdating.offers.tag.application.pending',
  },
});
