import React from 'react';
import { queryStringToObject } from '@/utils/url';
import { object, func, array, oneOf } from 'prop-types';
import { injectIntl } from 'react-intl';
import { isEmpty, isArray, debounce, compact, map, find } from 'lodash';

// Components
import { Form, Input, Icon as IconAntd } from 'antd';
import { If } from '@/components/If';
import Selector from '@/components/Form/Selector';
import Icon from '@/components/Icon';
import SearchEngineWrapper from '@/components/SearchEngineWrapper';

// Components
import { history } from '@/configureStore';
import styles from './styles.less';
import messages from './messages';

const FormItem = Form.Item;

class SearchEngineOffers extends React.PureComponent {
  static propTypes = {
    search: object,
    onSearch: func,
    intl: object,
    values: object,
    defaultCriteria: array,
    recruiters: array,
    events: array,
    status: oneOf(['published', 'draft']),
  };

  static defaultProps = {
    search: {},
    keywords: null,
    defaultSectors: [],
  };

  constructor(props) {
    super(props);
    const { search } = props;

    const paramObject = queryStringToObject(history.location.search);

    this.state = {
      isModalFilter: false,
      keywords: paramObject.keywords,
      search,
      sizeOptions: [],
    };

    this.remoteSearch = debounce((search, matching) => props.onSearch(search, matching), 400);
  }

  updateSearch = (key, value) => {
    const {
      props: { onSearch },
      state: { search, matching },
      remoteSearch,
    } = this;

    const newState = {};

    search[key] = value;
    search.page = 1;

    newState.search = search;

    if (key === 'keywords') {
      newState.keywords = value;

      this.setState(newState, remoteSearch(search, matching));
    } else {
      this.setState(newState, onSearch(search, matching));
    }
  };

  render() {
    const {
      props: {
        intl, defaultCriteria, recruiters, events, status,
        values: { recruiterSelectedIds, eventSelectedIds, contractSelectedIds },
      },
      state: { keywords },
      updateSearch,
    } = this;

    if (isEmpty(defaultCriteria)) return null;

    const contractCriteria = find(defaultCriteria, (c) => c.key === 'CONTRACT') || {};

    return (
      <SearchEngineWrapper
        topComponent={<>
          <FormItem className="formInputSearchItem">
            <Input
              className={styles.searchInput}
              placeholder={intl.formatMessage(messages.search)}
              value={keywords}
              prefix={<IconAntd type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
              onChange={(e) => updateSearch('keywords', e.target.value)}
              onPressEnter={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            />
            <button onClick={() => this.setState({ isModalFilter: true })} className="openFiltersIcon show-mobile">
              <Icon name="filters" />
            </button>
          </FormItem>

          <FormItem className="formSearchItem" style={{ width: '230px', flexGrow: 0, zIndex: 7 }}>
            <Selector
              position="right"
              isMobile={false}
              noBadge
              withAvatar
              searchable={recruiters && recruiters.length > 8}
              label={intl.formatMessage({ id: 'recruiters' }, { count: 2 })}
              noLabel={intl.formatMessage({ id: 'searchEngine.noLabel' })}
              values={isArray(recruiterSelectedIds) ? recruiterSelectedIds : compact([recruiterSelectedIds])}
              options={map(recruiters, (item) => ({ value: item._id, label: item.fullName, badgeCount: 10, avatarUser: item })).sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()))}
              onChange={(val) => updateSearch('recruiterSelectedIds', val)}
            />
          </FormItem>
        </>}
        bottomComponent={<>
          <If condition={status === 'published'}>
            <FormItem className="formSearchItem" style={{ width: '230px', flexGrow: 0, zIndex: 7 }}>
              <Selector
                position="left"
                isMobile={false}
                noBadge
                searchable={events && events.length > 8}
                label={intl.formatMessage({ id: 'jobdating' })}
                noLabel={intl.formatMessage({ id: 'searchEngine.noLabel' })}
                values={isArray(eventSelectedIds) ? eventSelectedIds : compact([eventSelectedIds])}
                options={map(events, (item) => ({ value: item._id, label: item.name, subLabel: item?.planners?._organizations?.map((o) => o.name).join(', '), badgeCount: 10 })).sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()))}
                onChange={(val) => updateSearch('eventSelectedIds', val)}
              />
            </FormItem>
          </If>


          <FormItem className="formSearchItem" style={{ width: '230px', flexGrow: 0, zIndex: 7 }}>
            <Selector
              position="left"
              isMobile={false}
              noBadge
              label={intl.formatMessage({ id: 'contracts' })}
              noLabel={intl.formatMessage({ id: 'searchEngine.noLabel' })}
              values={isArray(contractSelectedIds) ? contractSelectedIds : compact([contractSelectedIds])}
              options={map(contractCriteria._choices.filter((c) => c.enable), (item) => ({ value: item._id, label: item.label, badgeCount: 10 })).sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()))}
              onChange={(val) => updateSearch('contractSelectedIds', val)}
            />
          </FormItem>
        </>}
      />
    );
  }
}

export default injectIntl(SearchEngineOffers);
