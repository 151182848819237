import { diff } from 'deep-object-diff';

/**
 * Compare form current values and initial value
 * to determine wich value have changed
 * 
 * @param {object} initialValues
 * @param {object} values diffs will be returned with this object values
 * @returns {object}
 */
export const getModifiedValues = (initialValues, values) => diff(initialValues, values);

/**
 * Modify a form based on an object
 * The object must have same properties as form
 * 
 * @param {object} form
 * @param {object} values values to be changed in form
 */
export const setFormValuesFromObject = (form, values) => {
  const arrayData = Object.entries(values);
      
  form.batch(() => {
    arrayData.forEach(([key, value]) => {
      form.change(key, value);
    })
  })
}

/**
 * get error message on a set or errors using field's name
 * 
 * @param {object} errors 
 * @param {string} name
 * @returns {string|undefined}
 */
export const getFieldError = (errors, name) => errors?.[name];
