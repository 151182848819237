import React from 'react';
import { func } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';

import { Button } from '@seekube-tech/ui-kit';

import pageStyles from '../Request/styles.less';
import styles from './styles.less';


const Success = ({ push }) => (
  <div className={pageStyles.pageContainer}>
    <div className={styles.successContainer}>
      <span role="img" aria-label="">👍</span>
      <FormattedMessage id="recruiter.planInfo.request.success.title" tagName="h2" />
      <FormattedMessage id="recruiter.planInfo.request.success.description" tagName="p" />

      <div className={styles.actions}>
        <Button onClick={() => push('/events')}>
          <FormattedMessage id="close" />
        </Button>
      </div>
    </div>
  </div>
);

Success.propTypes = {
  push: func,
};

const mapDispatchToProps = {
  push,
};

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect)(Success);
