import React, { useState } from 'react';
import { object, func, string } from 'prop-types';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { Form } from 'antd';
import { Button, H4 } from '@seekube-tech/ui-kit';
import { trackRecruiterClickedSendRequestPlan } from '@/utils/analytics';
import { toJS } from '@/utils';

import { authSelectors } from '@/store/auth';
import Textarea from '@/components/Textarea';

import InputMaterial from '@/components/Input/InputMaterial';
import styles from './styles.less';

import { postContact } from './requests';

const FormItem = Form.Item;

const Request = ({
  plan,
  authUser,
  push,
  form: { getFieldDecorator, validateFields },
  intl: { formatMessage },
}) => {
  const [loading, setLoading] = useState(false);

  const handleOnSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    validateFields((err, { phone, message }) => {
      if (err) return setLoading(false);

      trackRecruiterClickedSendRequestPlan({ authUser, plan, phone, message });

      return postContact({
        authUser,
        body: { plan: plansText[plan], phone, message },
        callback: () => {
          setLoading(false);
          push('?step=success');
        },
      });
    });
  };

  return (
    <div className={styles.pageContainer}>
      <H4 className="mb-35"><FormattedMessage id="recruiter.planInfo.request.title" /></H4>

      <Form onSubmit={handleOnSubmit}>
        <FormItem>
          {getFieldDecorator('phone', {
            initialValue: '',
          })(
            <InputMaterial
              addOnIcon="phone"
              label={formatMessage({ id: 'recruiter.planInfo.request.phone.label' })}
              placeholder=""
              type="text"
            />
          )}
        </FormItem>
        <FormItem
          className="textareaWrapper"
        >
          {getFieldDecorator('message', {
            initialValue: '',
          })(
            <Textarea
              label={formatMessage({ id: 'recruiter.planInfo.request.goal.label' })}
              placeholder=""
              maxLength="1000"
              style={{ height: '8em' }}
            />
          )}
        </FormItem>
        <div className={styles.actions}>
          <Button
            type="submit"
            loading={loading}
          >
            {formatMessage({ id: 'recruiter.planInfo.request.action.send' })}
          </Button>
        </div>
      </Form>
    </div>
  );
};

const plansText = {
  seekube: 'Forums Thématiques',
  school: 'SchoolPack',
  company: 'Forum dédié',
};


Request.propTypes = {
  plan: string,
  intl: object,
  form: object,
  authUser: object,
  push: func,
};

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser,
});

const mapDispatchToProps = {
  push,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withForm = Form.create();

export default compose(
  injectIntl,
  withForm,
  withConnect,
  toJS,
)(Request);
