import React from 'react';
import { object, string, array, any } from 'prop-types';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';

import languageList from '@/utils/languages.json';

// Components

// Styles
import styles from './styles.less';
import messages from './messages';

/**
 * OfferCardSmall
 *
 * @param {object} props
 */
const MatchingHelp = ({ criteria, matching, context, className, intl }) => {
  const containerClasses = classNames(styles.matchingHelp, className);

  if (!matching) {
    return '';
  }

  const tagsItems = [];

  criteria
    .filter((criterion) => criterion.enable && criterion.modules[context].choiceMin === 0)
    .forEach((criterion) => {
      // Custom filters
      if (criterion.key === undefined || criterion.key === 'MOBILITY' || criterion.key === 'LEVEL') {
        const options = matching.filters;

        if (options) {
          options.forEach((option) => {
            const optionSelected = criterion._choices.find((choice) => option === choice._id.toString());

            if (optionSelected) {
              tagsItems.push({
                icon: criterion.icon,
                text: `${criterion.name}: ${optionSelected.label}`,
              });
            }
          });
        }
      } else if (criterion.key === 'LANGUAGES') {
        const {languages} = matching;

        if (languages) {
          matching.languages.forEach((item) => {
            if (!isEmpty(item.lang) && !isEmpty(item.level)) {
              const level = intl.formatMessage(messages[`level${item.level}`]);
              const lang = languageList[item.lang].name;

              tagsItems.push({
                icon: criterion.icon,
                text: `${criterion.name}: ${lang} ${level.toLowerCase()}`,
              });
            }
          });
        }
      } else if (criterion.key === 'EXPERIENCE') {
        if (matching.experiences) {
          if (typeof matching.experiences !== 'object') {
            tagsItems.push({
              icon: criterion.icon,
              text: `${criterion.name}: ${criterion._choices[matching.experiences] ? criterion._choices[matching.experiences].label : ''}`,
            });
          } else {
            const options = matching.filters;
            options.forEach((option) => {
              const optionSelected = criterion._choices.find((choice) => option === choice._id.toString());
              if (optionSelected) {
                tagsItems.push({
                  icon: criterion.icon,
                  text: `${criterion.name}: ${optionSelected.label}`,
                });
              }
            });
          }
        }
      }
    });

  return (
    <div className={containerClasses}>

    </div>
  );
};

MatchingHelp.propTypes = {
  intl: object,
  context: string,
  criteria: array,
  matching: object,
  className: any,
};

export default injectIntl(MatchingHelp);
