import React from 'react';
import { func, string, any, object, array } from 'prop-types';
import { upperCase, upperFirst } from 'lodash';
import moment from 'moment';
import classNames from 'classnames';

// components
import Carousel from '@/components/Carousel';
import Icon from '@/components/Icon';

import styles from './styles.less';

/**
 * <SliderNavigation />
 *
 * @description : Render prev & next carousel buttons
 */
const SliderNavigation = ({ onClick, name, className }) => (
  <button onClick={onClick} className={className}><Icon name={name} /></button>
);

SliderNavigation.propTypes = {
  onClick: func,
  name: string,
  className: string,
};

/**
 * <DaySelection />
 *
 * @description : List of selectable days
 */
const DaySelection = ({ startDate, endDate, handleSelectDay, selectedDay, timeslots }) => {
  /**
   * _renderDays
   *
   * @description : Render day between start and end date
   */
  const _renderDays = () => {
    const range = moment.range(startDate.isoWeekday(1), endDate);
    const days = Array.from(range.by('day'));

    return days.map((day) => {
      const startDay = moment(day).startOf('day');
      const endDay = moment(day).endOf('day');
      const daySlots = timeslots.filter((slot) => moment(slot.beginAt).isAfter(startDay) && moment(slot.beginAt).isBefore(endDay));

      const classname = classNames('day', {
        active: moment(selectedDay).diff(day) === 0 && daySlots.length > 0,
        disabled: daySlots.length === 0,
      });

      return (
        <div className={classname} key={moment(day)}>
          { upperFirst(moment(day).format('ddd')).slice(0, 3) }
          <a role="button" tabIndex="0" onClick={(e) => (daySlots.length > 0) ? handleSelectDay(e, day) : e.preventDefault()}>{ moment(day).format('D') }</a>
          <span className="month">{ upperCase(moment(day).format('MMM')).slice(0, 3) }</span>
        </div>
      );
    });
  };

  return (
    <div className={styles.weekCarousel}>
      <Carousel
        infinite={false}
        variableWidth
        slidesToShow={7}
        slidesToScroll={7}
        nextArrow={<SliderNavigation name="arrow-right" />}
        prevArrow={<SliderNavigation name="arrow-left" />}
        responsive={[
          {
            breakpoint: 600,
            settings: {
              variableWidth: true,
              slidesToScroll: 5,
            },
          },
          {
            breakpoint: 500,
            settings: {
              variableWidth: true,
              slidesToScroll: 4,
            },
          },
        ]}
      >
        { _renderDays() }
      </Carousel>
    </div>
  );
};

DaySelection.propTypes = {
  startDate: any,
  endDate: any,
  handleSelectDay: func,
  selectedDay: object,
  timeslots: array,
};

export default DaySelection;
