import React from 'react';
import { injectIntl } from 'react-intl';
import { func, object } from 'prop-types';
import isEmpty from 'lodash/isEmpty';

// Components
import { If, Then, Else } from 'react-if';
import { Modal, Upload } from 'antd';
import notification from '@/components/Notification';
import Icon from '@/components/Icon';
import { Input } from '@/components/Input';
import Image from './Image';

// Styles & Translations
import styles from './styles.less'; // eslint-disable-line
import messages from '../messages';

// Antd
const { Dragger } = Upload;

/**
 * ImageAdd
 */
class ImageAdd extends React.PureComponent {
  static propTypes = {
    onOk: func,
    intl: object,
  };

  /**
   * Constructor
   *
   * @param {object} props
   */
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      imageElement: {
        type: 'image',
        url: {},
        name: '',
      },
    };
  }

  /**
   * Send value to the parent and close the modal
   */
  handleOnOk = () => {
    const { state: { imageElement }, props: { onOk, intl }, toggleModal } = this;

    // Send the imageElement only if we have a image and a name
    if (!isEmpty(imageElement.url) && imageElement.name !== '') {
      onOk(imageElement);
      toggleModal();
    } else {
      notification.error(intl.formatMessage(messages.imageAddNotificationError));
    }
  };

  /**
   * Toggle the modal
   */
  toggleModal = () => {
    const { state: { modalIsOpen, imageElement } } = this;

    const resetImageElement = {
      ...imageElement,
      url: {},
      name: '',
    };

    this.setState({ modalIsOpen: !modalIsOpen, imageElement: resetImageElement });
  };

  /**
   * Handle change from inputs
   *
   * @param {string} key
   */
  handleOnChange = (key) => (e) => {
    const { state: { imageElement } } = this;

    const newImageElement = {
      ...imageElement,
      [key]: (key === 'url') ? e : e.target.value,
    };

    this.setState({ imageElement: newImageElement });
    return false;
  };

  /**
   * Render the modal with inputs for select an address
   */
  renderModal = () => {
    const {
      props: { intl },
      state: { modalIsOpen, imageElement },
      handleOnOk,
      toggleModal,
      handleOnChange,
    } = this;

    return (
      <Modal
        title={intl.formatMessage(messages.imageAddModalTitle)}
        visible={modalIsOpen}
        onOk={handleOnOk}
        cancelText={intl.formatMessage({ id: 'cancel' })}
        onCancel={toggleModal}
      >
        <div>
          {/* Input */}
          <Input placeholder={intl.formatMessage(messages.imageAddInputPlaceholder)} value={imageElement.name} onChange={handleOnChange('name')} />

          {/* Preview */}
          <If condition={isEmpty(imageElement.url)}>
            <Then>
              <Dragger className={styles.dragZone} beforeUpload={handleOnChange('url')} >
                <p className="ant-upload-text">{intl.formatMessage(messages.imageAddDrag)}</p>
              </Dragger>
            </Then>
            <Else>
              <Image className={styles.marginTop} element={imageElement} />
            </Else>
          </If>
        </div>
      </Modal>
    );
  };

  /**
   * Render
   */
  render() {
    const {
      toggleModal,
      renderModal,
    } = this;

    return (
      <div>
        { renderModal() }
        <a role="button" tabIndex={0} onClick={toggleModal}>
          <Icon name="image" />
        </a>
      </div>
    );
  }
}

export default injectIntl(ImageAdd);
