import React, { useRef, useState, useEffect } from 'react';
import { string, node, number, object } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { If } from '@/components/If';
import styles from './styles.less';
import messages from './messages.js';

const ShowMore = ({
  children,
  expandText = <FormattedMessage {...messages.showMore} />,
  reduceText = <FormattedMessage {...messages.showLess} />,
  maxHeight = 60,
  toggleTextStyle = {},
}) => {
  const [mode, setMode] = useState('small');
  const ref = useRef(children);
  const [childrenHeight, setHeight] = useState(ref.current.scrollHeight);
  const btnText = mode === 'small' ? expandText : reduceText;

  useEffect(() => {
    setHeight(ref.current.scrollHeight);
  });

  return (
    <div
      className={styles.showMore}
    >
      <div ref={ref} style={{ maxHeight: mode === 'large' ? '600px' : `${maxHeight}px` }} className={styles.componentContent}>
        {children}
      </div>
      <If condition={childrenHeight > maxHeight}>
        <a tabIndex={0} role="button" onClick={(e) => { e.stopPropagation(); setMode(mode === 'large' ? 'small' : 'large'); }}>
          <span style={{ ...toggleTextStyle }}>{btnText}</span>
        </a>
      </If>

    </div>
  );
};

ShowMore.propTypes = {
  children: node,
  expandText: string,
  reduceText: string,
  maxHeight: number,
  toggleTextStyle: object,
};

export { ShowMore };
