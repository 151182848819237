import React, { useState, useEffect } from 'react';
import { func, object, array, bool, oneOf } from 'prop-types';
import { valuesIn, startsWith, upperCase, isEmpty } from 'lodash';
import { TEMPLATES } from '@/utils/constants';

import { Select as SelectAntd } from 'antd';
import Select from '@/components/Form/Select';
import Icon from '@/components/Icon';

// Styles & Translations
import { useGetTemplates } from '@/queries/templates/useGetTemplates';
import { useFormatMessage } from 'react-intl-hooks';
import { useSelector } from 'react-redux';
import { getAuthUser } from '@/store/auth/selectors';

const {Option} = SelectAntd;

const SelectTemplate = ({ isDisabled, type, onTemplateSelected }) => {
  const t = useFormatMessage();
  const authUser = useSelector(getAuthUser)?.toJS();

  const templatesQuery = useGetTemplates({ userId: authUser?._id });

  const templates = templatesQuery?.data?.filter((t) => !isEmpty(t.type))

  const defaultTemplate = templatesQuery?.data?.find((template) => template.type === type && startsWith(template.name, upperCase(authUser.locale)));

  const [templateSelected, setTemplateSelected] = useState(null);

  useEffect(() => {
    const template = templateSelected || defaultTemplate;

    if (template) {
      onTemplateSelected(template);
      setTemplateSelected(template);
    }
  }, [defaultTemplate, templateSelected]);

  const handleSelectTemplate = (value) => {
    const templateSelected = templates.find((template) => template._id === value);

    setTemplateSelected(templateSelected);
  };

  const templatesOptions = () => (
    templates?.map((template) => (
      <Option key={template._id} value={template._id}>
        {template.name}
      </Option>
    ))
  );


  return (
    <div className="formItem">
      <Select
        disabled={isDisabled}
        required
        placeholder={t({ id: 'input.template.placeholder' })}
        showSearch
        addOnIcon="blog"
        value={templateSelected ? templateSelected._id : null}
        onSelect={handleSelectTemplate}
        suffixIcon={<Icon name="chevron"/>}
        optionFilterProp="children"
        filterOption={(input, option) => option.props.name && option.props.name.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        {templatesOptions()}
      </Select>
    </div>
  );
};

SelectTemplate.propTypes = {
  isDisabled: bool,
  onTemplateSelected: func,
  templates: array,
  type: oneOf(valuesIn(TEMPLATES)),
};

export { SelectTemplate };
