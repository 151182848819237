import { Route, useHistory, withRouter } from 'react-router-dom';
import React from 'react';
import { any, array, bool, object, string } from 'prop-types';
import { difference, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { USERS_EVENT_ROLES } from '@/utils/constants';
import { getCurrentEvent } from '@/store/event/selectors';
import { getAuthUser } from '@/store/auth/selectors';

const EventRoute = ({ // FIXME: change to EventRoute
  scope,
  path,
  isOnboarding = false,
  component: Component,
  noAuthRedirect,
  ...props
}) => {
  const history = useHistory();
  const event = useSelector(getCurrentEvent)?.toJS() ?? props.event;
  const authUser = useSelector(getAuthUser)?.toJS() ?? props.user;
  const hasOrganization = !isEmpty(authUser?._organizations);
  const { userContext } = event;
  const byPassCandidate = scope.includes(USERS_EVENT_ROLES.participant) && [USERS_EVENT_ROLES.guest, USERS_EVENT_ROLES.public].includes(userContext.roles[0]) && !hasOrganization;

  if (!authUser && !byPassCandidate && noAuthRedirect) {
    return history.push(noAuthRedirect);
  }

  if (!userContext) {
    return null;
  }
  const redirections = {
    [USERS_EVENT_ROLES.participant]: `/${event.slug}`,
    [USERS_EVENT_ROLES.guest]: hasOrganization ? '/404' : `/${event.slug}`,
    [USERS_EVENT_ROLES.public]: `/${event.slug}`,
    [USERS_EVENT_ROLES.owner]: `/${event.slug}/owner/dashboard`,
    [USERS_EVENT_ROLES.admin]: `/${event.slug}/owner/dashboard`,
    [USERS_EVENT_ROLES.recruiter]: `/${event.slug}/recruiter/jobdating`,
  }
  const hasRole = difference(scope, userContext.roles).length < scope.length;

  return (<Route
    path={path}
    render={(props) =>
      !byPassCandidate && !isOnboarding && !hasRole ? history.push(redirections[userContext.roles[0]]) : (
        <Component {...props} />
      )
    }
  />);
}

EventRoute.propTypes = {
  scope: array,
  user: object,
  event: object,
  path: string,
  component: any,
  isOnboarding: bool,
  noAuthRedirect: string
}

export default withRouter(EventRoute);

