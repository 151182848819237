import React from 'react';
import { Link } from 'react-router-dom';
import { ReactTitle } from 'react-meta-tags';
import { ThemeProvider, Typography, Button } from '@seekube-tech/ui';

// Styles
import thankYouGif from '@/assets/images/thankyou.gif';
import styles from './styles.less';

/**
 * ThankYouScene
 */
function ThankYouScene() {
  return (
    <ThemeProvider>
      <ReactTitle title="Seekube - Merci" />
      <div className={styles.container}>
        <div className={styles.thankContent}>
          <Typography variant="h1">
            Merci<br />infiniment...
          </Typography>
          <Button
            color="primary"
            variant="contained"
            href="/events"
          >
            Retour à la page d’accueil du jobdating
          </Button>

        </div>
        <img src={thankYouGif} alt="thank you" />
      </div>
    </ThemeProvider>
  );
}

export default ThankYouScene
