import React from 'react';
import { object } from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useFormatMessage } from 'react-intl-hooks'
import { Avatar, Typography } from '@seekube-tech/ui'
import { useSelector } from 'react-redux';
import { Button } from '@seekube-tech/ui-kit';
import { Tooltip } from '@/components/Tooltip';
import styles from '@/components/InteractionCard/styles.less';
import { getLocale } from '@/utils/localStorage'
import { trackCandidateClickedViewKeyDates } from '@/utils/analytics'
import { getAvailableActions } from '@/store/availableActions/selectors';

const ActionBtn = ({ interaction, participant, event, match }) => {
  const t = useFormatMessage();
  const availableActions = useSelector(getAvailableActions);

  const { _appointment, _organization } = interaction;
  const canChooseTimeslot = !availableActions.choosingTimeslotAfterRecruiterAction.isDisabled({event, role: 'candidate', participant});

  return (
    <div className={styles.action}>
      <div className={styles.btnWrapper}>
        {(interaction.type === 'informal1to1' && interaction._appointment?.acceptedAt && _appointment.status === 'pending') || ( interaction.type !== 'informal1to1' && _appointment && !['canceled', 'refused', 'accepted', 'unconfirmed'].includes(_appointment?.status)) ? (
          <Tooltip
            title={!canChooseTimeslot &&
              <div>
                <Typography variant="body2" color="basic.white">
                  {t({ id: 'event.candidate.appointments.card.confirm.disabled'})}
                  <Typography
                    variant="link2"
                    target="_blank"
                    component="a"
                    href={`${window.location.origin}/${match.params.eventSlug}/candidate/keydates`}
                    onClick={() => trackCandidateClickedViewKeyDates({ authUser: participant._user })}
                  >
                    {t({ id: 'see.keyDates' })}
                  </Typography>
                </Typography>
              </div>}
            placement="bottom"
            color="dark"
          >
            <span>
          <Link to={`/${participant._event.slug}/candidate/jobdating/appointment/${interaction._appointment._id}`}>
            <Button disabled={!canChooseTimeslot}>
              <FormattedMessage id="event.candidate.appointments.card.chooseSlot" />
            </Button>
          </Link>
          </span>
          </Tooltip>
        ) : ['accepted', 'unconfirmed'].includes(_appointment?.status) && interaction._appointment?.date && (
          <div className={styles.organizationPicture}>
            <Avatar
              variant="business"
              src={_organization?.profile?.pictureUrl}
              color={_organization?.isPartner ? event?.modules?.partner?.color : 'default'}
              badge={_organization?.isPartner ? event?.modules?.partner?.icon : null}
              tooltip={event?.modules?.partner?.[`label_${getLocale()}`]}
            />
          </div>
        )}
      </div>
    </div>
  )
}

ActionBtn.propTypes = {
  interaction: object,
  participant: object,
  event: object,
  match: object,
}

export default withRouter(ActionBtn)