import React from 'react';
import { func, string } from 'prop-types';
import { connect } from 'react-redux';
import { useFormatMessage } from 'react-intl-hooks';
import { Button, IconDownload } from '@seekube-tech/ui-kit';
import { FormattedMessage } from 'react-intl';
import { trackOwnerExportedLiveData } from '@/utils/analytics';

import { useDownloadFile } from '@/utils/hooks';

import { notificationActions } from '@/store/notification';

const DownloadAttendees = ({ slug, conferenceId, conferenceTitle, sendNotification, className = '' }) => {
  const url = `${process.env.FRONT_API_URL}/events/${slug}/export/conferences/${conferenceId}/attendees?apiKey=41a1289e-fee1-430e-a849-5b2b7ed23f1a`;
  const fileName = `Participants-${conferenceTitle}-${conferenceId}.xlsx`;

  const t = useFormatMessage();
  const [onDownload, isDownloading] = useDownloadFile(
    url,
    fileName,
    () => {sendNotification({ message: t({ id: 'download.error' }), kind: 'success',})},
    () => {
      trackOwnerExportedLiveData({ dataType: 'per live' })
      sendNotification({ message: t({ id: 'download.upcoming' })
      })},
  )

  return (
    <Button
      onClick={onDownload}
      loading={isDownloading}
      variant="outline"
      color="neutral"
      imageComponentLeft={<IconDownload size={16} />}
      className="mr-20"
    >
      <FormattedMessage id="event.owner.statistics.export.btn" />
    </Button>
  )
}

DownloadAttendees.propTypes = {
  slug: string,
  conferenceId: string,
  conferenceTitle: string,
  sendNotification: func,
}

const mapDispatchToProps = {
  sendNotification: notificationActions.sendNotification,
};
const withConnect = connect(null, mapDispatchToProps);

export default withConnect(DownloadAttendees)
