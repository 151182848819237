import moment from 'moment';
import { PHASE_STATE, PHASES } from '@/store/availableActions/utils/const';

export function getKeyDatePhases(event) {

  const phases = {};
  /**
   * Phases definition and their value will be added in the forEach below.
   */
  Object.keys(PHASES).forEach((phaseKey) => {
    const phaseName = PHASES[phaseKey];
    const eventHasPhase = event.keyDates && !!event?.keyDates[phaseName];
    const now = moment.utc();

    switch (phaseName) {
      case PHASES.ALL:
        /**
         * By default, giving a phase named "*" with a state "*" will equal to true.
         */
        phases[PHASES.ALL] = {
          [PHASE_STATE.ALL]: true
        }
        break;
      default:
        phases[phaseName] = {
          isDefined: eventHasPhase,
          isPast: eventHasPhase && moment(event.keyDates[phaseName].endAt).isBefore(now),
          isUpcoming: eventHasPhase && moment(event.keyDates[phaseName].beginAt).isAfter(now),
          isInProgress: eventHasPhase && moment(event.keyDates[phaseName].endAt).isAfter(now) && moment(event.keyDates[phaseName].beginAt).isBefore(now)
        }
    }
  })

  return phases;
}
