import { isParticipatingToAllHybridFormats } from '@/helpers/participant/isParticipatingToAllHybridFormats';
import { EVENT_FORMAT } from '@/utils/constants';
import { PHASES, PHASE_STATE } from '../../utils/const';
import {
  getEventPhases,
  isAdmin,
  isJobfairPast,
  isRoleCandidate,
  isRoleOwner,
  isRoleRecruiter
} from '../../utils/event';


import {
  isParticipantFormatPhysical,
  isParticipantFormatVirtual,
} from '../../utils/user';

function isDisabled({
  event,
  user,
  participant,
  role,
}) {
  const eventPhases = getEventPhases(event);

  if (event.isAuthUserBanned) {
    return true;
  }

  if (isRoleOwner(role) || isRoleRecruiter(role) || isAdmin(user)) {
    return true;
  }

  if (isRoleCandidate(role)) {
    if (event.format === EVENT_FORMAT.HYBRID) {
      if (isParticipatingToAllHybridFormats(participant?.keyMomentFormats)) {
        return isJobfairPast(eventPhases, EVENT_FORMAT.PHYSICAL) && isJobfairPast(eventPhases, EVENT_FORMAT.VIRTUAL);
      }

      if (isParticipantFormatPhysical(participant)) {
        return isJobfairPast(eventPhases, EVENT_FORMAT.PHYSICAL);
      }

      if (isParticipantFormatVirtual(participant)) {
        return isJobfairPast(eventPhases, EVENT_FORMAT.VIRTUAL);
      }
    }

    if (event.format === EVENT_FORMAT.VIRTUAL) {
      return isJobfairPast(eventPhases, EVENT_FORMAT.VIRTUAL);
    }

    if (event.format === EVENT_FORMAT.PHYSICAL) {
      return isJobfairPast(eventPhases, EVENT_FORMAT.PHYSICAL);
    }
  }

  return false;
}

/**
 * Do not forget to specify strict function params (instead of destructuring) to avoid issues when calling
 */
export default {
  isAvailable: [
    [
      {
        phase: PHASES.ALL,
        state: PHASE_STATE.ALL
      },
    ],
  ],
  generateActions: ({ authUser }) => {
    const user = authUser?.toJS();

    return {
      isDisabled: ({ event, role, participant }) => isDisabled({ event, user, role, participant }),
    }
  }
}
