import React, { useState } from 'react';
import { func, array, object, bool } from 'prop-types';
import { keyBy, toLower } from 'lodash';
import { useFormatMessage } from 'react-intl-hooks';
import { SearchOffer, OfferList } from './components';
import styles from './styles.less';

const ImportCrossForumOffers = ({
  authUserEvents,
  duplicateCrossOffers,
  visible,
  setVisible,
  crossOffers,
  event,
  getOffers
}) => {
  const t = useFormatMessage();
  const [filters, setFilters] = useState({ search: '', event: 'all' });
  const [defaultChecked, setDefaultChecked] = useState([]);
  const [isImporting, setIsImporting] = useState(false);


  const getVisibleOffer = (crossOffers) => {
    const filteredByEvent = filterOffersByEventId(crossOffers, filters.event);

    return filterOffersBySearch(filteredByEvent, filters.search);
  };

  const filterOffersByEventId = (offers, eventId) => eventId !== 'all' ? offers.filter((offer) => (offer._event === eventId)) : offers;

  const filterOffersBySearch = (offers, search) => offers.filter((offer) => (toLower(offer.title).includes(toLower(search))));

  const goToDuplicatedOffer = () => {
    setVisible(false);
    const banner = document.getElementsByClassName('banner-draft');

    if (banner[0]) {
      window.scrollTo(0, banner[0].offsetTop - 150, { behavior: 'smooth' });
    }
  };

  const handleOnSearchChange = (search) => {
    setFilters({ ...filters, search });
  };

  const handleOffersChecked = (offersChecked) => {
    setDefaultChecked(offersChecked);
  };

  const handleImportOffers = () => {
    setIsImporting(true);

    duplicateCrossOffers({
      offers: defaultChecked,
      eventId: event.slug,
      notificationParams: {
        success: {
          message: t({ id: 'event.recruiter.preparation.offer.notification.success.duplicate'}),
          kind: 'info',
          style: {
            bottom: '5%',
            top: 'inherit',
          },
        },
        error: true,
      },
      callback: () => { setIsImporting(false); getOffers(); setTimeout(goToDuplicatedOffer, 200); },
    });
  };


  const visibleOffers = getVisibleOffer(crossOffers);

  return (
    visible ? <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.contentHeader}>
          <SearchOffer onSearchChange={handleOnSearchChange} />
        </div>
        <div className={styles.contentBody}>
          <OfferList
            event={event}
            isImporting={isImporting}
            events={authUserEvents}
            onImportOffers={handleImportOffers}
            offersChecked={handleOffersChecked}
            visibleOffers={visibleOffers}
            offers={keyBy(visibleOffers, '_id')}
            search={filters.search}
            defaultChecked={defaultChecked}
          />
        </div>
      </div>
    </div> : null
  );
}

ImportCrossForumOffers.propTypes = {
  authUserEvents: array,
  duplicateCrossOffers: func,
  visible: bool,
  setVisible: func,
  crossOffers: array,
  event: object,
  getOffers: func,
};

export default ImportCrossForumOffers;
