import React from 'react';
import { any, func, object, array, bool } from 'prop-types';
import { isEmpty, size } from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import ApiToForm from '@/services/Form/ApiToForm';
import LoadingIndicator from '@/components/LoadingIndicator';
import { getStoredUtmParams } from '@/utils/url';
import { participantSelectors } from '@/store/participant';
import { toJS } from '@/utils';
import UserInfos from './components/UserInfos';
import UserExperience from './components/UserExperience';
import Checking from './components/Checking';

import styles from './styles.less';

class SignupModal extends React.PureComponent {
  static propTypes = {
    currentStep: any,
    handleDataUpdate: func,
    user: object,
    event: object,
    participant: object,
    defaultCriteria: array,
    isUserFetching: bool,
    postParticipant: func,
    isLoadingParticipant: bool,
  };

  state = {
    initialMatchingValues: {
      filters: [],
    },
  };

  static getDerivedStateFromProps({ participant, event, user }, state) {
    if (!isEmpty(event) && !isEmpty(participant) && event._criteria && isEmpty(state.initialMatchingValues.filters)) {
      let selectedValues = state.initialMatchingValues;

      if (participant && participant.matching && !isEmpty(participant.matching.filters)) {
        selectedValues = participant.matching;
      } else if (user && user.matching && !isEmpty(user.matching.filters)) {
        selectedValues = user.matching;
      }

      const criteria = event._criteria;
      const sortCriteria = criteria
        .filter((criterion) => criterion.enable && criterion.modules.onboarding.isVisible)
        .sort((a, b) => a.modules.onboarding.position - b.modules.onboarding.position);

      const initialMatchingValues = ApiToForm.matchingFiltersParticipant(sortCriteria, selectedValues);

      return { initialMatchingValues };
    }

    return null;
  }

  computeParticipant = (values) => {
    const matchingFilters = Object
      .keys(values)
      .filter((value) => value.includes('matching-filters') === true)
      .map((filter) => values[filter]);
    const filters = [];

    Object.keys(matchingFilters).forEach((key) => {
      const filterValue = matchingFilters[parseInt(key, 10)];

      if (typeof filterValue === 'object') {
        filterValue.forEach((item) => {
          filters.push(item);
        });
      } else {
        filters.push(filterValue);
      }
    });

    const participant = {
      matching: this.state.initialMatchingValues,
    };

    const languages = [];

    Object
      .keys(values)
      .filter((value) => value.includes('matching-languages-lang') === true)
      .forEach((filter, index) => {
        if (values[`matching-languages-lang-${index}`]) {
          languages.push({
            lang: values[`matching-languages-lang-${index}`],
            level: values[`matching-languages-level-${index}`],
          });
        }
      });

    if (values.license) {
      participant.matching.license = values.license;
    }

    participant.matching.languages = languages;
    participant.matching.filters = filters;

    if (values['matching-date']) {
      participant.matching.date = values['matching-date'];
    } else {
      participant.matching.date = null;
    }

    if (values['matching-experiences']) {
      participant.matching.experiences = values['matching-experiences'];
    } else {
      participant.matching.experiences = null;
    }

    return participant;
  };

  render() {
    const {
      props: {
        currentStep,
        handleDataUpdate,
        user,
        event,
        participant,
        defaultCriteria,
        isUserFetching,
        postParticipant,
        isLoadingParticipant,
      },
    } = this;

    if (event === undefined) return null;
    if (!defaultCriteria) return null;

    if (((participant === undefined && currentStep !== 'signup' && !isLoadingParticipant) ||
      isUserFetching) &&
      !window.location.pathname.includes('/owner/settings/matching/preview')) {
      if (user) {
        postParticipant({
          eventId: event.id,
          slug: event.slug,
          toPost: {
            _event: event.id,
            userProvider: getStoredUtmParams(),
          }
        });
      }
      return <LoadingIndicator className={styles.loading} />;
    }

    const eventSteps = event.participants.onboarding;
    const countEnabledSteps = size(event.participants.onboarding.filter((step) => step && step.enabled));


    switch (currentStep) {
      case -1:
        return (<Checking
          handleDataUpdate={handleDataUpdate}
          currentStep={currentStep}
          user={user}
          event={event}
        />);
      case 0:
        return null;

      case 1:
        return (<UserInfos
          handleDataUpdate={handleDataUpdate}
          currentStep={currentStep}
          user={user}
          event={event}
          eventSteps={eventSteps}
          countEnabledSteps={countEnabledSteps}
          stepPosition={eventSteps.findIndex((step) => step.name === 'cv')}
        />);

      case 2:
        return (<UserExperience
          handleDataUpdate={handleDataUpdate}
          currentStep={currentStep}
          user={user}
          event={event}
          eventSteps={eventSteps}
          countEnabledSteps={countEnabledSteps}
          stepPosition={eventSteps.findIndex((step) => step.name === 'experience')}
          defaultCriteria={defaultCriteria}
        />);

      default:
        return null;
    }
  }
}

const mapStateToProps = createStructuredSelector({
  participant: participantSelectors.getCurrentParticipant,
});

const mapDispatchToProps = {};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect
)(toJS(SignupModal));
