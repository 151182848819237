import * as React from 'react';
import { connect } from 'react-redux';
import { func, object } from 'prop-types';

// Components
import { FormattedMessage } from 'react-intl';
import { Form, Input, Radio } from 'antd';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { useFormatMessage } from 'react-intl-hooks';
import { Button, Tooltip, TooltipContent, TooltipTrigger, IconSave } from '@seekube-tech/ui-kit';
import { Bloc, BlocHeader } from '@/components/Bloc';

// Services

// Store
import { getAvailableActions } from '@/store/availableActions/selectors';
import withErrorBoundary from '@/services/ErrorBoundary';
import ApiToForm from '../../services/formMapping/ApiToForm';

// Antd
const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;

const DashboardForm = ({
  form: { validateFields, getFieldDecorator },
  initialValues: {
    slug,
    modules,
  },
  onSubmit,
  availableActions
}) => {
  const t = useFormatMessage();

  const handleSubmit = (e) => {
    e.preventDefault();

    return validateFields(onSubmit);
  };

  return (
    <Bloc>
      <Form onSubmit={handleSubmit} style={{ maxWidth: '500px', textAlign: 'left' }} layout="vertical">
        <BlocHeader>
          <FormattedMessage id="event.owner.dashboard" tagName="h2" />
        </BlocHeader>

        <FormItem label={<FormattedMessage id="event.modules.lives.enable.candidate.label" />}>
          {getFieldDecorator('enable', {
            initialValue: ApiToForm.module(modules, 'dashboard', 'enable'),
            rules: [{ required: true, message: 'Merci de renseigner le label' }],
          })(
            <RadioGroup>
              <RadioButton value ><FormattedMessage id="yes" /></RadioButton>
              <Tooltip>
                <TooltipTrigger>
                  <RadioButton
                    disabled={availableActions.deactivateEventTab?.isEnabled('dashboard')}
                    value={false}
                  >
                    <FormattedMessage id="no" />
                  </RadioButton>
                </TooltipTrigger>
                <TooltipContent>
                  {availableActions.deactivateEventTab?.getTooltipMessage('dashboard')}
                </TooltipContent>
              </Tooltip>
            </RadioGroup>
          )}
        </FormItem>

        <FormItem label={<FormattedMessage id="event.modules.activation.and.tabs.label.fr" />}>
          {getFieldDecorator('label_fr', {
            initialValue: ApiToForm.module(modules, 'dashboard', 'label_fr'),
            rules: [{ required: true, message: 'Merci de renseigner le label' }],
          })(
            <Input />
          )}
        </FormItem>

        {/* labelMultiple */}
        <FormItem label={<FormattedMessage id="event.modules.activation.and.tabs.label.en" />}>
          {getFieldDecorator('label_en', {
            initialValue: ApiToForm.module(modules, 'dashboard', 'label_en'),
            rules: [{ required: true, message: 'Merci de renseigner le label' }],
          })(
            <Input />
          )}
        </FormItem>

        {/* Submit */}
        <div>
          <Button type="submit">
            {t({ id: 'save' })}
          </Button>
        </div>
      </Form>
    </Bloc>
  );
};

DashboardForm.propTypes = {
  onSubmit: func.isRequired,
  form: object,
  initialValues: object,
  availableActions: object
};

const mapStateToProps = createStructuredSelector({
  availableActions: getAvailableActions
})

const withConnect = connect(mapStateToProps, {})
const withForm = Form.create()

export default compose(
  withConnect,
  withForm,
  withErrorBoundary
)(DashboardForm);
