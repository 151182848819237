import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import * as types from './types';

const initialState = fromJS({
  list: [],
});

const actionReducer = handleActions({
  [types.GET_ACTIONS_SUCCESS]: (state, { payload: { docs } }) => state.set('list', docs),
}, initialState);

export default actionReducer;
