import React from 'react';
import { func, object } from 'prop-types';

// Components
import { FormattedMessage } from 'react-intl';
import { Form, Radio } from 'antd';
import { Button, IconHome, IconStar, IconBuilding, IconVideo, IconMessageCircle2 } from '@seekube-tech/ui-kit';
import { Tooltip } from '@/components/Tooltip';
import { Bloc, BlocHeader } from '@/components/Bloc';

import { useFormatMessage } from 'react-intl-hooks';
import { story } from '@/utils/manageVisibilityStory';
import styles from '../../../../../../styles.less';

// Antd
const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;

const DefaultRedirectionForm = ({
  form: { validateFields, getFieldDecorator },
  event: { participantRedirection, modules },
  onSubmit,
}) => {
  const t = useFormatMessage();

  const handleSubmit = (e) => {
    e.preventDefault();

    return validateFields(onSubmit);
  };

  return (
    <Bloc>
      <Form onSubmit={handleSubmit} style={{ maxWidth: '720px',textAlign: 'left' }} layout="vertical">
        <BlocHeader>
          <FormattedMessage id="event.modules.landing.title" values={{ count: 2 }} tagName="h2" />
        </BlocHeader>

        <div className={styles.textGrey} style={{ marginBottom: '40px' }}>
          <FormattedMessage id="event.modules.landing.description" />
        </div>

        <FormItem>
          {getFieldDecorator('participantRedirection', {
            initialValue: participantRedirection,
          })(
            <RadioGroup>
              {story.dashboardCandidate.isVisible &&
                <Tooltip
                  title={!modules?.dashboard?.enable &&
                    <FormattedMessage id="event.modules.landing.input.disabled.tooltip"
                                      values={{ text: <FormattedMessage id="event.owner.dashboard"/> }}/>}
                  color="dark"
                  placement="bottom"
                >
                  <RadioButton disabled={!modules?.dashboard?.enable} value="dashboard">
                    <div className="flex items-center">
                      <IconHome size={16} className="mr-6"/>
                      <FormattedMessage id="event.owner.dashboard"/>
                    </div>
                  </RadioButton>
                </Tooltip>}
              <Tooltip
                title={!modules.offer.enable && <FormattedMessage id="event.modules.landing.input.disabled.tooltip" values={{ text: <FormattedMessage id="offers.plural" values={{ count: 2 }} /> }} />}
                color="dark"
                placement="bottom"
              >
                <RadioButton disabled={!modules.offer.enable} value="offer" >
                  <div className="flex items-center">
                    <IconStar size={16} className="mr-6" />
                    <FormattedMessage id="offers.plural" values={{ count: 2 }} />
                  </div>
                </RadioButton>
              </Tooltip>

              <RadioButton value="company">
                <div className="flex items-center">
                  <IconBuilding size={16} className="mr-6" />
                  <FormattedMessage id="company" values={{ count: 2 }} />
                </div>
              </RadioButton>

              <Tooltip
                title={!modules.live.participantEnable && <FormattedMessage id="event.modules.landing.input.disabled.tooltip" values={{ text: <FormattedMessage id="livetalk" /> }} />}
                color="dark"
                placement="bottom"
              >
                <RadioButton value="live" disabled={!modules.live.participantEnable}>
                  <div className="flex items-center">
                    <IconVideo size={16} className="mr-6" />
                    <FormattedMessage id="livetalk" />
                  </div>
                </RadioButton>
              </Tooltip>

              <Tooltip
                title={!modules.informal1to1?.enable && <FormattedMessage id="event.modules.landing.input.disabled.tooltip" values={{ text: <FormattedMessage id="event.owner.settings.modules.landing.informal1to1" /> }} />}
                color="dark"
                placement="bottom"
              >
                <RadioButton value="informal1to1" disabled={!modules.informal1to1?.enable}>
                  <div className="flex items-center">
                    <IconMessageCircle2 size={16} className="mr-6" />
                    <FormattedMessage id="event.owner.settings.modules.landing.informal1to1" />
                  </div>
                </RadioButton>
              </Tooltip>
            </RadioGroup>
          )}
        </FormItem>

        <FormItem>
          <Button type="submit">
            {t({ id: 'save' })}
          </Button>
        </FormItem>
      </Form>
    </Bloc>
  );
};

DefaultRedirectionForm.propTypes = {
  onSubmit: func.isRequired,
  form: object,
  event: object,
};

export default Form.create()(DefaultRedirectionForm);
