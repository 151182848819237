import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Upload, Col, Row } from 'antd';

// Components
import { Button, IconUpload, IconXCircle } from '@seekube-tech/ui-kit';
import { H1 } from '@/components/Title';

// Styles & Translations
import styles from "../../../styles.less";

// antd
const FormItem = Form.Item;

/**
 * PartnersForm
 */
class PartnersForm extends React.PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func,
    form: PropTypes.object,
    initialValues: PropTypes.array,
  };

  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);

    this.state = {
      fileLogo: {},
      uploading: false,
      partnersJSX: [],
      partners: [],
    };

    this.uuid = 0;
    this.skey = -1;
  }

  /**
   * Invoked before a mounted component receives new props.
   *
   * @param nextProps
   */
  componentWillReceiveProps(nextProps) {
    this.setState({
      partners: nextProps.initialValues,
    });
  }

  /**
   * Disable auto-upload of antdesign "<Upload />" and append File into the list
   *
   * @param {File} file
   */
  handleBeforeUpload = (fileLogo) => {
    this.setState({ fileLogo });
    return false;
  };

  /**
   * Handle submit of the Form.
   * Validate values and send data to 'handleUpload'
   *
   * @param {object} e
   */
  handleSubmit = (e) => {
    const { state: { fileLogo, partners }, props: { onSubmit, form } } = this;

    e.preventDefault();

    form.validateFields((err, values) => {
      if (!err) {
        /**
         * onSubmit, call the parent function for redux action
         *
         * @param {object} err -> Error object or NULL if no error
         * @param {object} values -> object of input text in the partner form
         * @param {File} fileLogo -> file for the new partner's logo
         * @param {array} partners -> array of partners ( ! without the new one ! )
         */
        onSubmit(null, values, fileLogo, partners);
        form.resetFields();
        this.setState({ fileLogo: {} });
      }
    });
  };

  /**
   * Remove a partner in the state
   *
   * @param {int} index
   *    index= index of the partner in the partners array
   */
  removePartner = (index) => {
    const { state: { partners }, props: { onSubmit } } = this;

    partners.splice(index, 1);

    this.setState({ partners });
    onSubmit(null, null, null, partners);
  };

  /**
   * Render
   */
  render() {
    const {
      state: { fileLogo, partners },
      props: { form: { getFieldDecorator } },
      handleBeforeUpload,
    } = this;

    /**
     * Render : Append the Form by each partners (Input Name + Input File Logo)
     */
    const formItems = (
      <div>
        <FormItem
          label="Partners"
          required={false}
        >
          {getFieldDecorator('partnerName', {
            initialValue: '',
          })(
            <Input placeholder="Nom du partenaire" style={{ width: '80%', marginRight: 8 }} />
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('partnerLogo', {
            initialValue: null,
          })(
            <Upload beforeUpload={handleBeforeUpload} style={{ width: '80%', marginRight: 8 }}>
              { !(Object.keys(fileLogo).length === 0) ?
                <Button variant="tonal" imageComponentLeft={<IconUpload size={16} />} style={{ color: '#38D996' }} size="small">
                  Logo sélectionné
                </Button>
                :
                <Button variant="tonal" imageComponentLeft={<IconUpload size={16} />} size="small" type="button" >
                  Sélectionnez un logo
                </Button>
              }
            </Upload>
          )}
        </FormItem>
      </div>
    );

    /**
     * Draw actual partners
     */
    const partnersRender = partners.map((prt, index) => (
        <Col span={4} key={index + this.skey}>
          <span>{`Name: ${prt.name}`}</span>
          <div><img className={styles.imgResponsive} src={prt.url} alt={prt.name} /></div>
          <Button imageComponentLeft={<IconXCircle size={16} />} onClick={() => this.removePartner(index)}>
            Supprimer ce partenaire
          </Button>
        </Col>
      )
    );

    return (
      <Form onSubmit={this.handleSubmit}>
        <H1>Les partneraires</H1>
        <Row type="flex" justify="start" align="middle">
          {partnersRender}
        </Row>
        <H1>Ajouter un partenaire</H1>
        <Row type="flex" justify="start" align="middle">
          {formItems}
          <Button type="submit">Enregistrer</Button>
        </Row>
      </Form>
    );
  }
}

export default Form.create()(PartnersForm);

