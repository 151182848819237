import { defineMessages } from 'react-intl';

export default defineMessages({
  save: {
    id: 'save',
  },
  delete: {
    id: 'delete',
  },
  edit: {
    id: 'edit',
  },
  templateDefaultLabel: {
    id: 'settings.templatesMail.label.default',
  },
  templateNewTemplate: {
    id: 'settings.templatesMail.cta.newTemplate',
  },
  templateTitle: {
    id: 'settings.templatesMail.title',
  },
  addTemplateTitle: {
    id: 'settings.templatesMail.add.title',
  },
  deleteTemplateAlertText: {
    id: 'settings.templatesMail.delete.alert.text',
  },
  addTemplateHint: {
    id: 'settings.templatesMail.add.hint',
  },
  editTemplateTitle: {
    id: 'settings.templatesMail.edit.title',
  },
  editTemplateHint: {
    id: 'settings.templatesMail.edit.hint',
  },
  editSuccess: {
    id: 'toaster.edit.success',
  },
});
