import moment from 'moment';
import { PHASE_STATE, PHASES } from '@/store/availableActions/utils/const';

export function getKeyMomentPhases(event) {
  const phases = {
    hybrid: event.format === 'hybrid'
  }

  Object.keys(PHASES).forEach(phaseKey => {
    const phaseName = PHASES[phaseKey];
    const eventHasPhase = event.keyMoments && !!event?.keyMoments[phaseName];
    const phaseKeyMoments = event.keyMoments[phaseName];
    const now = moment.utc();

    switch (phaseName) {
      case PHASES.ALL:
        /**
         * By default, giving a phase named "*" with a state "*" will equal to true.
         */
        phases[PHASES.ALL] = {
          [PHASE_STATE.ALL]: true
        }
        break;
      case PHASES.NONE:
        break;
      default:
        if (phaseKeyMoments instanceof Array) {
          phases[phaseName] = phaseKeyMoments.map(keyMoment => ({
            format: keyMoment.format,
            isDefined: eventHasPhase,
            isPast: eventHasPhase && moment(keyMoment.endAt).isBefore(now),
            isUpcoming: eventHasPhase && moment(keyMoment.beginAt).isAfter(now),
            isInProgress: eventHasPhase && moment(keyMoment.endAt).isAfter(now) && moment(keyMoment.beginAt).isBefore(now)
          }))
        } else {
          phases[phaseName] = [{
            error: true
          }]
        }
    }
  })

  return phases;
}
