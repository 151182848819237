import React from 'react';
import { Select as SelectAnt } from 'antd';
import { array, func, object, string } from 'prop-types';
import { useSelector } from 'react-redux';
import { getAuthUser } from '@/store/auth/selectors';
import { Select } from '@/components/Form';

const { Option } = SelectAnt;

const SearchMatchingSelector = ({
  options,
  name,
  icon,
  placeholder,
  valueSelected,
  onChange,
  hasError = false
}) => {
  const authUser = useSelector(getAuthUser)?.toJS();

  const customOptions = options?._choices
    ?.filter(choice => choice.enable)
    .map((choice) => (
        <Option disabled={valueSelected.length === 2 && !valueSelected.includes(choice._id)} key={`${name}FilterChoice${choice._id.toString()}`} value={choice._id.toString()}>
          {choice[`label_${authUser.locale}`] ?? choice.label}
        </Option>
      ))

  return (
      <Select
        required
        id={name}
        showSearch
        mode="multiple"
        addOnIcon={icon}
        optionFilterProp="children"
        showDropdown
        onChange={onChange}
        placeholder={placeholder}
        value={valueSelected}
        maxItems={2}
        withInputErrorStyle
        hasError={hasError}
      >
        {customOptions}
      </Select>
  )
}

SearchMatchingSelector.propTypes = {
  options: array,
  name: string,
  icon: string,
  placeholder: string,
  valueSelected: object,
  onChange: func
}

export default SearchMatchingSelector;
