import React from 'react';
import { Avatar } from '@seekube-tech/ui';
import { Body1 } from '@seekube-tech/ui-kit';
import colors from '@seekube-tech/ui-kit/dist/colors'
import Html from '@/components/Html';
import useStyles from './styles';

const Exponents = ({ exponents, onClick }) => {
  const styles = useStyles();

  return (
    exponents.map((exponent, index) => (
      <li
        role="button"
        tabIndex={0}
        key={index}
        onClick={() => onClick('exponent', exponent)}
      >
        <div className={styles.resultItem}>
          <div className={styles.resultIcon}>
            <Avatar
              variant="business"
              size="small"
              className={styles.borderWidth}
              src={exponent?.profile?.pictureUrl ? exponent.profile?.pictureUrl : ''}
            />
          </div>
          <div className={styles.resultInfo} color={colors.neutral['500']}>
            <Body1>
              <Html value={exponent?._highlightResult?.name?.value} />
            </Body1>
          </div>
        </div>
      </li>
    )))
}

export { Exponents }