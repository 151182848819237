import { func, object, number } from 'prop-types';
import React from 'react'
import { useFormatMessage } from 'react-intl-hooks';
import { EditorState, Modifier } from 'draft-js';
import { IconLink2 } from '@seekube-tech/ui-kit';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { getCurrentEvent } from '@/store/event/selectors';
import styles from './styles.less'

const CustomLink = ({ editorState, onChange, page, index }) => {
  const t = useFormatMessage()
  const event = useSelector(getCurrentEvent)?.toJS();

  const getUrl = () => {
    switch (page) {
      case 'stands':
        return event ? `${location.origin}/${event.slug}/candidate/jobdating/exponents` : 'exponents'
      case 'offers':
        return event ? `${location.origin}/${event.slug}/candidate/jobdating/jobs` : 'jobs'
      case 'informal':
        return event ? `${location.origin}/${event.slug}/candidate/jobdating/informal1to1` : 'informal1to1'
      case 'lives':
        return event ? `${location.origin}/${event.slug}/candidate/jobdating/conferences/discovery?page=1&archive=false&limit=11` : 'conferences/discovery?page=1&archive=false&limit=11'
      default:
        return ''
    }
  }

  const addLink = () => {
    const entityKeyLink = editorState
      .getCurrentContent()
      .createEntity("LINK", "MUTABLE", {
        url: getUrl(),
        targetOption: null
      })
      .getLastCreatedEntityKey();

    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      t({ id: `go.to.${page}` }),
      editorState.getCurrentInlineStyle(),
      entityKeyLink
    );
    onChange(EditorState.push(editorState, contentState, 'insert-characters'));
  }

  return (
    <div role="link" onClick={addLink} className={classNames(styles.customLink, index === 0 && styles.customLinkFirst)}>
      <IconLink2 size={12} />
      {t({ id: `link.to.${page}` })}
    </div>
  )
}

CustomLink.propTypes = {
  editorState: object,
  onChange: func,
  page: object,
  index: number
};

export default CustomLink;