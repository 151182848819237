import entitiesReducer from './reducer';
import * as entitiesActions from './actions';
import * as entitiesTypes from './types';

export {
  entitiesActions,
  entitiesTypes,
};

export default entitiesReducer;
