import React from 'react';
import { func, object, bool } from 'prop-types';
import { Field } from 'react-final-form';
import { Autocomplete } from '@seekube-tech/ui';
import { useFormatMessage } from 'react-intl-hooks';

import useValidations from '@/utils/validations/useValidations';

import Icon from '@/components/Icon';


import { createStructuredSelector } from 'reselect';
import { authSelectors } from '@/store/auth';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { LOCALES } from '@/utils/constants';
import { toJS } from '@/utils';
import styles from '../../../styles.less';
import { getCriterionLabel } from '../utils';

function LicenseField({ criterion, showHelper, authUser, disabled = false, required = true }) {
  /* Vars */

  const t = useFormatMessage();
  const { isFilled } = useValidations();
  const LICENSE_OPTIONS = [
    {
      label: t({ id: 'yes' }),
      value: 'true',
    },
    {
      label: t({ id: 'no' }),
      value: 'false',
    },
  ];

  /* Derived vars */

  const label = getCriterionLabel(criterion);
  const getOptionLabel = (option) => authUser.locale === LOCALES.en && option.label_en ? option.label_en : option.label

  return (
    <div className={styles.marginTop}>
      <Field
        disabled={disabled}
        name="license"
        id="license"
        label={label}
        icon={<Icon name="legal" />}
        component={Autocomplete}
        required={Boolean(criterion.modules.offer.choiceMin) && required}
        options={LICENSE_OPTIONS}
        fullWidth
        getOptionLabel={getOptionLabel}
        getOptionValue={(option) => option.value}
        validate={isFilled}
        showHelper={(id) => showHelper(id, label)}
      />
    </div>
  );
}

LicenseField.propTypes = {
  criterion: object,
  showHelper: func,
  authUser: object,
  disabled: bool,
};

LicenseField.defaultProps = {
  criterion: {},
};

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser
})

const mapDispatchToProps = {};

const withConnect = connect(mapStateToProps, mapDispatchToProps);


export default compose(
  withConnect
)(toJS(LicenseField));
