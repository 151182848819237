import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import * as types from './types';

// Initial State
const initialReducer = fromJS({
  allIds: [],
  crossEventsIds: [],
  pagination: {
    current: 1,
    pageSize: 20,
    total: 0,
    offset: 0,
  },
  stats: {
    totalPublished: 0,
    totalDraft: 0,
  },
  facets: {},
  countSlots: {},
  totalOffers: 0,
  isFetching: false,
  participantSelectedOffer: null,
  lastParticipantOffersSearch: {},
});

// Offer reducer
const offerReducer = handleActions({
  [types.POST_GLOBAL_OFFER]: (state) => state.set('isFetching', true),
  [types.POST_OFFER_SUCCESS]: (state, { payload }) => state
    .set('allIds', state.get('allIds').unshift(payload))
    .set('isFetching', false),
  [types.POST_OFFERS_SUCCESS]: (state, { payload }) =>
    state.set('allIds', state.get('allIds').merge(payload)),
  [types.DELETE_OFFER_SUCCESS]: (state, { payload }) =>
    state.set('allIds', state.get('allIds').filter((id) => id !== payload)),
  [types.DELETE_GLOBAL_OFFER_SUCCESS]: (state, { payload: { offerId } }) => state.set('allIds', state.get('allIds').filter((id) => id !== offerId)),
  [types.GET_PARTICIPANT_OFFERS]: (state, { payload }) => {
    state.set('allIds', []);

    if (payload.loader === true) {
      return state.set('isFetching', true);
    }

    return state;
  },
  [types.GET_CROSSFORUM_OFFER_SUCCESS]: (state, { payload: { offers } }) => state.set('crossEventsIds', offers),
  [types.GET_ORGANIZATION_USER_OFFERS]: (state) => state.set('isFetching', true),
  [types.GET_ORGANIZATION_USER_OFFERS_SUCCESS]: (state, { payload: { result, limit, offset, total, stats } }) => {
    const newAllIds = state.get('allIds').clear().merge(result);
    return state
      .set('allIds', newAllIds)
      .set('isFetching', false)
      .setIn(['pagination', 'pageSize'], limit)
      .setIn(['pagination', 'offset'], offset)
      .setIn(['pagination', 'total'], total)
      .setIn(['stats', 'totalDraft'], stats.totalDraft)
      .setIn(['stats', 'totalPublished'], stats.totalPublished)
      .setIn(['stats', 'totalExpired'], stats.totalExpired);
  },
  [types.GET_GLOBAL_OFFER]: (state) => state.set('isFetching', true),
  [types.DUPLICATE_GLOBAL_OFFER]: (state) => state.set('isFetching', true),
  [types.PATCH_GLOBAL_OFFER_BY_EVENTS]: (state) => state.set('isFetching', true),
  [types.PATCH_GLOBAL_OFFER_BY_EVENTS_SUCCESS]: (state) => state
      .set('isFetching', false),
  [types.GET_GLOBAL_OFFER_SUCCESS]: (state, { payload: { result } }) => {
    const newAllIds = state.get('allIds').clear().merge([result]);
    return state
      .set('allIds', newAllIds)
      .set('isFetching', false)
      ;
  },
  [types.GET_PARTICIPANT_OFFERS_SUCCESS]: (state, { payload, payload: { facets, lastSearch } }) => {
    const newAllIds = state.get('allIds').clear().merge(payload.result);

    if (!payload.withOrganization) {
      return state
        .setIn(['pagination', 'current'], payload.currentPage || 1)
        .set('totalOffers', payload.totalOffers)
        .set('countSlots', payload.countSlots)
        .setIn(['pagination', 'pageSize'], payload.limit)
        .setIn(['pagination', 'offset'], payload.offset)
        .setIn(['pagination', 'total'], payload.total)
        .set('facets', facets)
        .set('isFetching', false)
        .set('allIds', newAllIds)
        .set('lastParticipantOffersSearch', lastSearch);
    }

    return state
      .setIn(['pagination', 'pageSize'], payload.limit)
      .setIn(['pagination', 'offset'], payload.offset)
      .setIn(['pagination', 'total'], payload.total)
      .set('facets', payload.facets)
      .set('isFetching', false)
      .set('allIds', newAllIds);
  },
  [types.GET_OFFER]: (state) => state.set('participantSelectedOffer', null),
  [types.SET_PARTICIPANT_SELECTED_OFFER]: (state, { payload }) => state.set('participantSelectedOffer', payload),
  [types.DELETE_PARTICIPANT_SELECTED_OFFER]: (state) => state.set('participantSelectedOffer', null),
  [types.GET_EVENT_OFFERS]: (state, { payload }) => {
    if (payload.loader === true) {
      return state.set('isFetching', true);
    }

    return state;
  },
  [types.GET_EVENT_OFFERS_SUCCESS]: (state, { payload }) => {
    const newAllIds = state.get('allIds').clear().merge(payload.result);

    return state
      .set('allIds', newAllIds)
      .set('isFetching', false)
      .set('totalOffers', payload.total)
      .setIn(['pagination', 'current'], payload.currentPage || 1)
      .setIn(['pagination', 'pageSize'], payload.limit)
      .setIn(['pagination', 'offset'], payload.offset)
      .setIn(['pagination', 'total'], payload.total);
  },
  [types.GET_EVENT_OFFERS_BY_MATCHING_SUCCESS]: (state, { payload, payload: { facets } }) => {
    const newAllIds = state.get('allIds').clear().merge(payload.result);

    return state
      .set('allIds', newAllIds)
      .set('isFetching', false)
      .set('facets', facets)
      .set('totalOffers', payload.totalOffers)
      .setIn(['pagination', 'current'], payload.currentPage || 1)
      .setIn(['pagination', 'pageSize'], payload.limit)
      .setIn(['pagination', 'offset'], payload.offset)
      .setIn(['pagination', 'total'], payload.total);
  },
  [types.GET_ORGANIZATION_OFFERS]: (state, { payload }) => {
    if (payload.loader === true) {
      return state.set('isFetching', true);
    }

    return state;
  },
  [types.GET_ORGANIZATION_OFFERS_SUCCESS]: (state, { payload }) => {
    const newAllIds = state.get('allIds').clear().merge(payload.result);

    return state
      .set('allIds', newAllIds)
      .set('isFetching', false)
      .setIn(['pagination', 'current'], payload.currentPage)
      .setIn(['pagination', 'pageSize'], payload.limit)
      .setIn(['pagination', 'offset'], payload.offset)
      .setIn(['pagination', 'total'], payload.total);
  },
  [types.SET_LAST_PARTICIPANT_OFFER_SEARCH]: (state, { payload }) =>
    state.set('lastParticipantOffersSearch', payload),
}, initialReducer);

export default offerReducer;
