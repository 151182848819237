import React from 'react';
import { node, string, oneOfType, bool } from 'prop-types';
import omit from 'lodash/omit';
import classnames from 'classnames';

// Styles & Translations
import styles from './index.less';

/**
 * HP
 * @param {object} props
 */
const P = (props) => (
  <p
    className={classnames(styles.p, props.bold ? styles.bold : null, props.className)}
    {...omit(props, ['className', 'children', 'bold'])}
  >
    {props.children}
  </p>
);

P.propTypes = {
  children: oneOfType([string, node]).isRequired,
  className: string,
  bold: bool,
};

export default P;
