import { useQuery } from 'react-query';
import request from '@/utils/request';
import { objectToParams } from '@/utils/url';

export function useGetCheckInvitations({ token, organization, onSuccess = () => {}, ...props }) {
  const [id, username] = Buffer.from(token, 'base64').toString().split(':');
  const params = objectToParams({ id, username, organization });
  const requestUrl = `${process.env.FRONT_API_URL}/users/checkInvitation?${params}`;

  const queryFn = () => request(requestUrl, {
    method: 'GET',
  });

  const query = useQuery({
    queryKey: ['userInvitation'],
    queryFn,
    refetchOnWindowFocus: true,
    onSuccess,
    ...props,
  });

  return query;
}
