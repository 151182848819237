import { Col } from 'antd';
import styles
  from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Conferences/containers/ConferencesList/styles.less';
import BlankState from '@/components/BlankState';
import { FormattedMessage } from 'react-intl';
import { bool, func, number } from 'prop-types';
import React from 'react';

export const BlankCactus = ({ isConfirmedView, goToDiscovery, total }) => (
  <>
    {!isConfirmedView ? ( total > 0 ? <Col xs={12} sm={6} md={6} lg={6}>
        <div className={styles.blankState}>
          <BlankState
            className={styles.cactus}
            icon="blank-state-cactus"
            content={
              <div>
                <FormattedMessage id="search.live.no.result"/> <br/>
                <FormattedMessage id="event.candidate.noOffer.content"/>
              </div>
            }
          />
        </div>
      </Col> : <div className={styles.blankState}>
        <BlankState
          className={styles.cactus}
          icon="blank-state-cactus"
          content={
            <div className={styles.title}>
              <FormattedMessage id="live.none.publish"/>
            </div>
          }
        />
      </div>) :
      <div className={styles.blankState}>
        <BlankState
          className={styles.cactus}
          icon="blank-state-cactus"
          title={<span className={styles.title}><FormattedMessage id="blankstate.no.confirmed.live"/></span>}
          handleAction={goToDiscovery}
          buttonLabel={<FormattedMessage id="discover.lives" />}
        />
      </div>
    }
  </>
)

BlankCactus.propTypes = {
  isConfirmedView: bool,
  goToDiscovery: func,
  total: number,
}
