import React from 'react';
import { node, object } from 'prop-types';
import { isEmpty } from 'lodash';
import styles from '@/components/InteractionCard/styles.less';
import Popover from '@/components/Popover';
import Icon from '@/components/Icon';

const MoreActions = ({ popoverContent, _appointment }) => (
  !isEmpty(popoverContent) && (!_appointment || _appointment.status !== 'canceled') && (
    <div className={styles.more}>
      <Popover
        popoverContent={<div className={styles.popoverContent}>{popoverContent}</div>}
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
        placement="bottomRight"
      >
        <button><Icon name="more" /></button>
      </Popover>
    </div>
  )
)

MoreActions.propTypes = {
  popoverContent: node,
  _appointment: object,
}

export { MoreActions }