import React from 'react';
import { object, oneOf } from 'prop-types';
import { keys } from 'lodash';
import { IconMapPin, IconWifi, Tab } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { useHistory } from 'react-router';
import { EVENT_FORMAT } from '@/utils/constants';
import { objectToParams } from '@/utils/url';

const VIEWS = {
  physical: EVENT_FORMAT.PHYSICAL,
  virtual: EVENT_FORMAT.VIRTUAL,
  all: 'all',
}

export function HybridTabs({ event, view }) {
  const t = useFormatMessage();
  const history = useHistory();

  const tabs = [
    {
      name: t({ id: 'exponent.changeView.physical' }),
      params: `?view=${VIEWS.physical}`,
      paramValue: VIEWS.physical,
      icon: <IconMapPin size={16} />,
      index: 0,
    },
    {
      name: t({ id: 'exponent.changeView.virtual' }),
      params: `?view=${VIEWS.virtual}`,
      paramValue: VIEWS.virtual,
      icon: <IconWifi size={16} />,
      index: 1,
    },
    {
      name: t({ id: 'exponent.changeView.all' }),
      params: `?view=${VIEWS.all}`,
      paramValue: VIEWS.all,
      icon: <IconWifi size={16} />,
      index: 2,
    },
  ];

  const defaultIndex = tabs.find((tab) => tab.paramValue === view)?.index;

  const onIndexChange = (index) => {
    const params = objectToParams({ view: tabs[index].paramValue });

    history.push(`?${params}`);
  }

  return (event?.format === EVENT_FORMAT.HYBRID && (
      <Tab.Group defaultIndex={defaultIndex} onChange={onIndexChange} className="mb-40">
        <Tab.List>
          {tabs.map(({ name, icon, params }) => (
            <Tab icon={icon} key={params} as="span">
              {name}
            </Tab>
          ))}
        </Tab.List>
      </Tab.Group>
    )
  )
}

HybridTabs.propTypes = {
  view: oneOf(keys(VIEWS)),
  event: object
}
