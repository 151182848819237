import moment from 'moment';

export default (exponents, { actions }) => exponents
  .sort((a, b) => moment(b.createdAt).unix() - moment(a.createdAt).unix())
  .map((exponent) => (
    {
      key: exponent._id,
      exponent,
      _organization: exponent._organization,
      actions,
      organizationProfile: exponent.profile || exponent._organization?.profile,
    })
  );
