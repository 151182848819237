/**
 * Class for transform data from the form to the API
 */
export default class FormToApi {
  /**
   * Return modules with new value for a specific module
   *
   * @param {Array} modules
   * @param {string} moduleName
   * @param {object} newValues
   *
   * @returns {Array}
   */
  static modules = (modules = {}, moduleName, newValues = {}) => {
    const moduleUpdate = {
      ...modules[moduleName],
      ...newValues,
    };

    if (moduleUpdate.enable === undefined) {
      moduleUpdate.enable = true;
    }

    modules[moduleName] = moduleUpdate; // eslint-disable-line
    return modules;
  };
}
