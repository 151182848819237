import React from 'react';
import { number, string } from 'prop-types';

/**
 * HP
 * @param {object} props
 */
const Separator = (props) => (
  <div className={props.className} style={{ height: props.height }} />
);

Separator.propTypes = {
  className: string,
  height: number,
};

export default Separator;
