import React from 'react';
import { object, string, any, oneOfType } from 'prop-types';

import { Typography } from '@seekube-tech/ui';

import styles from './styles.less';

const ModalTitle = ({ imgSrc, title }) => (
  <div className={styles.header}>
    <div>
      <img src={imgSrc} alt="alt" />
    </div>
    <Typography variant="h3">{title}</Typography>
  </div>
);

ModalTitle.propTypes = {
  imgSrc: oneOfType([object, string]),
  title: any,
};

export default ModalTitle;
