import React from 'react';
import { array } from 'prop-types'

import Avatar from '@/components/Avatar';

import styles from './styles.less';

const Presenters = ({ presenters }) => (
  presenters.map((presenter, key) => (
      <div className={styles.presenterContainer} key={key}>
        <div className={styles.presenterCard}>
          <div className={styles.leftSide}>
            <Avatar size={49} src={null} user={{ ...presenter }}/>
          </div>
          <div className={styles.rightSide}>
            <div>
              <p className={styles.title}>{`${presenter.firstName} ${presenter.lastName}`}</p>
              <p className={styles.content}>{presenter.title} </p>
            </div>
          </div>
        </div>
      </div>
    )
  )
)

Presenters.propTypes = {
  presenters: array,
}
export { Presenters }
