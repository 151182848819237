import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';

import { useFormatMessage } from 'react-intl-hooks';
import GalleryComponent from '@/components/Gallery';
import Title from '@/scenes/Event/components/Title';
import styles from '@/scenes/Event/containers/Landing/components/Contents/components/GeneralInfos/components/LandingContent/styles.less';


const Gallery = ({ event, contentIndex, editMode, onChange, onInit, locale }) => {
  const t = useFormatMessage();

  useEffect(
    () => {
      if (onInit) {
        onInit(contentIndex)({
          inNav: false,
          label: t({ id: 'event.content.gallery.label' }),
          h2: t({ id: 'event.content.gallery.h2' }),
          content: {
            gutter: 8,
            elements: [],
          },
        });
      }
    }, []
  )


  const renderGallery = (content) => {
    const gallery = event._contents[contentIndex];

    if (!content || !content.content || !gallery) {
      return null;
    }

    const countElement = gallery.content?.elements?.length;
    const columnsCountBreakPoints = {
      200: 1,
      450: countElement === 1 ? 1 : 2,
      900: countElement < 3 ? countElement : 3,
    };
    return (
      <GalleryComponent
        columnsCountBreakPoints={columnsCountBreakPoints}
        editMode={false}
        gutter="10px"
        onChange={onChange(['_contents', contentIndex, 'content', 'elements'])}
        elements={gallery.content.elements}
      />
    );
  };

  return (
    <div className={styles.mediaContainer}>
      <Title
        event={event}
        contentIndex={contentIndex}
        editMode={editMode}
        onChange={onChange}
        locale={locale}
      />
      <Row>
        <Col span={24} className={styles.organizationsLogoWrapper}>
          {renderGallery(event._contents[contentIndex])}
        </Col>
      </Row>
    </div>
  );
}

Gallery.propTypes = {
  event: PropTypes.object,
  contentIndex: PropTypes.number,
  editMode: PropTypes.bool,
  onChange: PropTypes.func,
  onInit: PropTypes.func,
  locale: PropTypes.string,
};

export default Gallery;
