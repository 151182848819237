import React from 'react';
import { object, func } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Form, Radio } from 'antd';

// Components
import { useFormatMessage } from 'react-intl-hooks';
import { Button } from '@seekube-tech/ui-kit';
import Separator from '@/components/Separator';
import { Bloc, BlocHeader } from '@/components/Bloc';

// Styles & Translations
import styles from '../../../../styles.less';

// Antd
const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;

const EnableExportApi = ({
  form: { getFieldDecorator, validateFields },
  initialValues,
  onSubmit,
}) => {
  const t = useFormatMessage();

  const handleSubmit = (e) => {
    e.preventDefault();

    return validateFields(onSubmit);
  };

  return (
    <Bloc>
      <Form onSubmit={handleSubmit} style={{ width: '100%', textAlign: 'left' }} layout="vertical">
        <BlocHeader>
          <FormattedMessage id="event.diffusion.export.title" tagName="h2" />
        </BlocHeader>
        <span className={styles.textGrey}>
          <FormattedMessage id="event.diffusion.export.description" />
        </span>
        <Separator height={10} />

        <FormItem>
          {getFieldDecorator('enableExportOffersApi', {
            initialValue: initialValues ? initialValues.enableExportOffersApi : false,
          })(
            <RadioGroup>
              <RadioButton value ><FormattedMessage id="yes" /></RadioButton>
              <RadioButton value={false}><FormattedMessage id="no" /></RadioButton>
            </RadioGroup>
          )}
        </FormItem>

        {/* Submit */}
        <div>
          <Button type="submit">
            {t({ id: 'save' })}
          </Button>
        </div>
      </Form>
    </Bloc>
  );
};

EnableExportApi.propTypes = {
  form: object,
  initialValues: object,
  onSubmit: func.isRequired,
};

export default Form.create()(EnableExportApi);
