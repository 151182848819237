import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Body1, Caption1 } from '@seekube-tech/ui-kit';
import { REPORT_PHASES } from '@/scenes/Event/scenes/Owner/scenes/Setting/scenes/Reporting/const';

export default (report, headerTitle) => {
  if ([REPORT_PHASES.registrationAndPreparationOfCompanies,
    REPORT_PHASES.registrationAndActivitiesOfCandidates,
    REPORT_PHASES.appointmentProcessing].includes(report.phase)) {


    return ([
      {
        Header: <FormattedMessage id="owner.reporting.overview.table.datatype"/>,
        id: 'name',
        disableSortBy: true,
        accessor: (record) => {
          const goal = record.goal > 0 ?
            <Caption1 style={{ marginLeft: '24px', fontStyle: 'italic' }}
                        variant="caption" color="neutral.400">
              <FormattedMessage id="goal.pl" values={{ count: 1 }}/>
              : {record.goal}
            </Caption1> : null;

          return <Body1>{record.name} {goal}</Body1>;
        },
      },
      {
        Header: <FormattedMessage id="total" />,
        id: 'total',
        disableSortBy: true,
        accessor: (record) => <span>{record.total}</span>,
      },
    ]);
  }

  return ([
    {
      Header: '',
      id: 'icon',
      disableSortBy: true,
      style: { width: ' 15px' },
      accessor: (record) => record.icon,
    },
    {
      Header: <span style={{ textAlign: 'left' }}> {headerTitle}</span>,
      id: 'name',
      disableSortBy: true,
      accessor: (record) => <Body1>{record.name} </Body1>,
    }
  ]);

};