import React from 'react';
import { bool, object, string } from 'prop-types';
import classnames from 'classnames';
import { isEmpty } from 'lodash';
import styles from '@/scenes/Event/containers/Landing/styles.less';

const CoverPicture = ({ event, cover, editMode }) => {
  if (!event.theme.coverPictureUrl) return null;

  return (
    <div
      className={
        classnames(styles.coverContainer, {
            [styles.emptyCover]: editMode && isEmpty(event.theme.coverPictureUrl)
          }
        )}
    >
      <img className={styles.coverImg} src={cover || event.theme.coverPictureUrl} alt='cover' />
    </div>
  )
}

CoverPicture.propTypes = {
  event: object,
  cover: string,
  editMode: bool,
}

export { CoverPicture }
