import React from 'react';

// components
import { Icon as IconAntd } from 'antd';
import { Button, H5 } from '@seekube-tech/ui-kit';
import Avatar from '@/components/Avatar';

import styles from './styles.less';

const OfferAd = () => (
  <>
    <div className={styles.mainContent}>
      <div className={styles.adContent}>
        <H5 className="mb-20">Inscrivez-vous pour recevoir des propositions d’entretien !</H5>
        <div className={styles.baseline}>
          {`Sur Seekube les recruteurs peuvent aussi vous proposer un RDV. Pour cela, vous n'avez qu'à
          vous inscrire en 5mn pour qu'ils puissent avoir accès à votre profil !`}
        </div>
      </div>
    </div>

    <div className={styles.contact}>
      <div className={styles.avatar}>
        <Avatar size={70} />
      </div>
      <div className={styles.contactDetails}>
        <div className={styles.showOffer}>
          <Button>
            S inscrire
          </Button>
        </div>
      </div>
    </div>

    <div className={styles.more}>
      <IconAntd type="close-circle-o" />
    </div>
  </>
);

export default OfferAd;
