import React from 'react';
import { bool } from 'prop-types';
import classnames from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';

// Components
import { If } from 'react-if';

// Styles & Translations
import styles from './styles.less';

/**
 * UploadStatus
 */
class UploadStatus extends React.PureComponent {
  static propTypes = {
    isUpload: bool,
  }

  /**
   * Render
   */
  render() {
    const { props: { isUpload } } = this;

    return (
      <If condition={isUpload}>
        <div className={classnames(styles.indeterminateLoader, styles.tres)}>
          <div className={styles.progressLine} />
        </div>
      </If>
    );
  }
}

const mapStateToProps = (state) => ({
  isUpload: state.get('upload'),
});

const withConnect = connect(mapStateToProps);

export default compose(
  withConnect,
)(UploadStatus);
