import { defineMessages } from 'react-intl';

export default defineMessages({
  phone: {
    id: 'appointment.medium.phone',
  },
  physical: {
    id: 'appointment.medium.physical',
  },
  visio: {
    id: 'appointment.medium.visio',
  },
  pending: {
    id: 'appointment.status.pending',
  },
  none: {
    id: 'appointment.medium.none',
  },
  appointmentWith: {
    id: 'event.recruiter.appointment.with',
  },
  resumeLink: {
    id: 'event.recruiter.appointment.resume.link',
  },
  appointmentConfirmed: {
    id: 'event.recruiter.appointment.confirmed',
  },
  appointmentReminder: {
    id: 'event.recruiter.appointment.reminder',
  },
  addToCalendar: {
    id: 'event.candidate.appointment.mainContent.addToCalendar',
  },
  appointmentAbout: {
    id: 'event.candidate.appointment.details.about',
  },
  medium: {
    id: 'appointment.medium',
  },
  duration: {
    id: 'appointment.duration',
  },
  aboutCandidate: {
    id: 'event.recruiter.appointment.details.about.candidate',
  },
  resumeConsultation: {
    id: 'event.recruiter.appointment.resume.consultation',
  },
  on: {
    id: 'on',
  },
  link: {
    id: 'link',
  },
});
