import React from 'react';
import { object } from 'prop-types';
import { size } from 'lodash';

const ParticipationColumn = ({ record }) => (
  size(record._events)
);

ParticipationColumn.propTypes = {
  record: object,
};

export { ParticipationColumn };
