import * as React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { number } from 'prop-types';
import { IconPhone } from '@seekube-tech/ui-kit';

function AppointmentValidated({ countAppointments }) {
  return (
    <span key="appointmentValidated">
      <IconPhone size={16} /><FormattedHTMLMessage values={{ count: countAppointments }} id="events.stats.candidate.appointments.accepted" />
    </span>
  )
}

AppointmentValidated.propTypes = {
  countAppointments: number
}

export default AppointmentValidated;
