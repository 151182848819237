import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Row, Col } from 'antd';
import Slider from 'react-slick';
import Title from '@/scenes/Event/components/Title';
import styles from '@/scenes/Event/containers/Landing/components/Contents/components/GeneralInfos/components/LandingContent/styles.less';

const isMobile = window.innerWidth <= 650;

const itemsPerSlide = isMobile ? 3 : 12;

class Partners extends PureComponent {
  static propTypes = {
    event: PropTypes.object,
    intl: PropTypes.object,
    contentIndex: PropTypes.number,
    editMode: PropTypes.bool,
    onChange: PropTypes.func,
    onInit: PropTypes.func,
    locale: PropTypes.string,
  };

  state = {
    currentSlide: 0,
    totalSlide: Math.ceil(this.props.event.partners.length / itemsPerSlide) - 1,
  };

  componentDidMount() {
    const { props: { intl, contentIndex, onInit } } = this;

    if (onInit) {
      onInit(contentIndex)({
        inNav: true,
        label: intl.formatMessage({ id: 'event.content.partners.label' }),
        h2: intl.formatMessage({ id: 'event.content.partners.h2' }),
      });
    }
  }

  _renderSlider = (content) => {
    const { props: { event, contentIndex }, _renderSliderPages } = this;

    if (!content || !content.content) {
      return null;
    }
    const {elements} = event._contents[contentIndex].content;
    if (elements?.length < itemsPerSlide) {
      return _renderSliderPages(elements);
    }

    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      dots: true,
      afterChange: (currentSlide) => this.setState({ currentSlide }),
    };

    return (
      <div style={{ width: '100%' }}>
        <Slider ref={(slider) => (this.slider = slider)} {...settings} customPaging={() => <a />}>
          { _renderSliderPages(elements) }
        </Slider>
      </div>
    );
  };

  _renderSliderPages = (elements) => {
    const { renderPartners } = this;

    return elements?.map((logo, i) => { // eslint-disable-line
      if (i === 0 || i % itemsPerSlide === itemsPerSlide - 1) {
        return (
          <Row type="flex" justify="space-around" align="middle" key={`page-${i}`} className={styles.organizationsLogoWrapper} style={{ display: 'flex' }}>
            {renderPartners(elements, i, (i + itemsPerSlide) - 1)}
          </Row>
        );
      }
    });
  };


  renderPartners = (elements, startIndex, endIndex) => (
    elements
      .filter((partner, i) => i >= startIndex && i <= endIndex && partner.pictureUrl)
      .map(
        (partner, index) => (
          <Col key={index} xs={6} sm={6} md={4} lg={4} className={styles.partnersWrapper}>
            <div className={styles.partnersOuter}>
              <div className={styles.partnersInner}>
                <a href={partner.url} target="_blank"><img className={styles.imgResponsive} src={partner.pictureUrl} alt={partner.name} /></a>
              </div>
            </div>
          </Col>
        )
      )
  );


  render() {
    const {
      _renderSlider,
      props: { event, contentIndex, editMode, onChange, locale },
    } = this;

    const containerClasses = styles.partnersContainer;

    return (
      <div className={containerClasses}>
        <Title
          event={event}
          contentIndex={contentIndex}
          editMode={editMode}
          onChange={onChange}
          locale={locale}
        />
        <Row type="flex" justify="space-around" align="middle" className={styles.organizationsLogoWrapper}>
          { _renderSlider(event._contents[contentIndex]) }
        </Row>
      </div>
    );
  }
}

export default injectIntl(Partners);
