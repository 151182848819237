import React, { useEffect, useState } from 'react';
import { bool, func, object, oneOf } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import get from 'lodash/get';
import map from 'lodash/map';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';
import { useFormatMessage } from 'react-intl-hooks';
import { Autocomplete } from '@seekube-tech/ui';
import { Modal } from 'antd';
import { IconMapPin, IconWifi, IconInfo, IconMapPinWifi } from '@seekube-tech/ui-kit';
import { toJS } from '@/utils';
import { getIds, getId } from '@/utils/global';
import { EVENT_FORMAT } from '@/utils/constants';

// Ducks
import { eventActions, eventSelectors } from '@/store/event';
import { exponentSelectors, exponentActions } from '@/store/exponent';
import { organizationActions, organizationSelectors } from '@/store/organization';
import { authSelectors } from '@/store/auth';

// Components
import { If } from '@/components/If';
import Icon from '@/components/Icon';
import { Button } from '@seekube-tech/ui-kit';
import notification from '@/components/Notification';
import Checkbox from '@/components/Checkbox';
import PickOrganization from './components/PickOrganization';
import Exponent from './components/Exponent';

import styles from './styles.less';



function ExponentModal({
  event,
  isOpen = false,
  context = 'default',
  organization = null,
  postOrganization,
  authUser,
  onCancel,
  setCurrentOrganization,
  pushOrganization,
  postExponent,
  patchEvent,
  exponents,
  onOk,
}) {
  const t = useFormatMessage();

  const VIEWS = {
    pickOrganization: {
      key: 'pickOrganization',
      name: 'pickOrganization',
      title: t({ id: 'exponentModal.addCompany.title' }),
      toggleText: () => <FormattedMessage id="admin.organizations.create.organization" />,
      render: () => renderPickOrganization(),
    },
    createOrganization: {
      key: 'createOrganization',
      name: 'createOrganization',
      title: <FormattedMessage id="admin.organizations.create.organization" />,
      toggleText: () => <If condition={previousView !== VIEWS.addRecruiters.name}><FormattedMessage id="event.owner.company.add">{(mess) => (`+ ${mess}`)}</FormattedMessage></If>,
      render: () => renderCreateOrganization,
    },
    addRecruiters: {
      key: 'addRecruiters',
      name: 'addRecruiters',
      title: <FormattedMessage id="event.owner.planner" />,
      toggleText: () => <FormattedMessage id="admin.organizations.create.organization" />,
      render: () => renderCreateOrganization,
    },
  };

  const getCurrentView = (context) => {
    if (context === 'default') {
      return VIEWS.pickOrganization.name;
    }
    if (context === 'postOrganization') {
      return VIEWS.createOrganization.name;
    }
    return VIEWS.addRecruiters.name;
  };

  const [errCompanyExist, setErrCompanyExist] = useState(null);
  const [errParticipationMode, setErrParticipationMode] = useState(false);
  const [modules, setModules] = useState([]);
  const [corners, setCorners] = useState([]);
  const [currentView, setCurrentView] = useState(getCurrentView(context));
  const [previousView, setPreviousView] = useState(context === 'default' ? 'pickOrganization' : 'addRecruiters');
  const [isPartner, setIsPartner] = useState(false);
  const [participationMode, setParticipationMode] = useState('');
  const [keyMomentLinks, setKeyMomentLinks] = useState([]);
  const [keywords, setKeywords] = useState('');
  /**
   * We have to know if owner (not admin) knows exponents he's adding
   */
  const [ownerKnowsAddedExponents, setOwnerKnowsAddedExponents] = useState(!!authUser.ownerKnowsAddedExponents);
  const hasToCheckKnowsAddedExponents = !errCompanyExist && !authUser.isAdmin && !authUser?.ownerKnowsAddedExponents;

  useEffect(() => {
    setOwnerKnowsAddedExponents(authUser.ownerKnowsAddedExponents || false)
  }, [authUser])

  useEffect(() => {
    if (isOpen) {
      setModules([]);
      setCorners([]);
      setCurrentOrganization('');
      setIsPartner(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if(getOrganization()) {
      setErrCompanyExist(exponents.find((exponent) => exponent._organization._id === getOrganization()._id));
    }
  }, [organization]);

  useEffect(() => {
    if (participationMode !== '') {
      setErrParticipationMode(false)
    }
    if (participationMode === EVENT_FORMAT.PHYSICAL) {
      setKeyMomentLinks([{
        _keyMoment: {
          _id: event.keyMoments.jobfair.find(e => e.format === EVENT_FORMAT.PHYSICAL)._id
        },
        type: 'jobfair',
      }])
    }
    else if (participationMode === EVENT_FORMAT.VIRTUAL) {
      setKeyMomentLinks([{
        _keyMoment: {
          _id: event.keyMoments.jobfair.find(e => e.format === EVENT_FORMAT.VIRTUAL)._id
        },
        type: 'jobfair',
      }])
    }
    else if (participationMode === EVENT_FORMAT.HYBRID) {
      setKeyMomentLinks([{
        _keyMoment: {
          _id: event.keyMoments.jobfair.find(e => e.format === EVENT_FORMAT.PHYSICAL)._id
        },
        type: 'jobfair',
      },
      {
        _keyMoment: {
          _id: event.keyMoments.jobfair.find(e => e.format === EVENT_FORMAT.VIRTUAL)._id
        },
        type: 'jobfair',
      }
    ])
    }
  }, [participationMode]);

  const handleOnSelect = (organization) => {
    pushOrganization(organization);
    setCurrentOrganization(organization._id);
  };

  const handleOnOk = () => {
    if (participationMode === '' && event.format === EVENT_FORMAT.HYBRID && currentView !== 'addRecruiters' && context === 'default') {
      setErrParticipationMode(true)
      return notification.error('Veuillez définir le mode participation de l’exposant.');
    }

    // Error, must be select a organization
    if (isEmpty(organization)) return notification.error('Vous devez séléctioner une entreprise.');
    // TODO: Faire un truc plus propre en fonction du context
    if (context === 'withRecruiters') {
      const planners = { ...event.planners, _organizations: uniq([...getIds(event.planners._organizations), getId(organization)]) };
      patchEvent({
        event: { planners },
        notificationParams: {
          success: {
            message: t({ id: 'notifications.update.success' }),
            kind: 'info',
            style: {
              bottom: '5%',
              top: 'inherit',
            },
          },
        }
      });

      onOk({ ...organization, profile: {} });
    } else if (context !== 'postOrganization') {
      let params = {
        _event: event._id,
        _organization: organization._id,
        users: [],
        modules,
        corners,
        isPartner,
        _keyMomentLinks: keyMomentLinks,
        callback: () => {
          onOk({ ...organization, profile: {} })
        },
        callbackError: () => {setErrCompanyExist(organization.name)},
        notificationParams: {
          success: {
            message: t({ id: 'toasters.addExponent.success' })
          }
        }
      }

      if (!authUser.isAdmin && !authUser.ownerKnowsAddedExponents) {
        // We need to make sure owner knows the exponents he's adding
        params = {
          ...params,
          ownerKnowsAddedExponents,
          // to refetch auth user when he agreed knowing exponents he's adding
          reloadAuthUser: true,
        }
      }

      postExponent(params);
    } else {
      onOk({ ...organization, profile: {} });
    }

    return true;
  };


  const handleCreateOrganization = (err, organization) => {
    // Create the organization
    if (!err) {
      setErrCompanyExist(null);

      postOrganization({
        organizationParams: organization,
        callbackError: () => {
          setErrCompanyExist(<p className="textError"><FormattedMessage id="event.recruiter.preparation.stand.edit.existError" /></p>);
        },
      });
    }
  };

  const changeView = (view) => {
    let newView = view;

    switch (currentView) {
      case VIEWS.pickOrganization.name: newView = VIEWS.createOrganization.name; break;
      case VIEWS.createOrganization.name: newView = VIEWS.pickOrganization.name; break;
      case VIEWS.addRecruiters.name: newView = VIEWS.createOrganization.name; break;

      default: return VIEWS.pickOrganization.name;
    }

    setCurrentOrganization('');
    setPreviousView(currentView);
    setCurrentView(newView);

    return newView;
  };

  const renderPickOrganization = () => (
    <PickOrganization
      errCompanyExist={errCompanyExist}
      onSelect={handleOnSelect}
      view={VIEWS[currentView].name}
      onCreateOrganization={handleCreateOrganization}
      authUser={authUser}
      disabled={context === 'updateParticipationMode'}
      keywords={keywords}
      setKeywords={setKeywords}
    />
  );

  const renderCreateOrganization = () => (
    <Exponent
      organization={getOrganization()}
    />
  );

  const renderCorners = () => (
    !isEmpty(get(event, 'modules.corner.items')) && <div style={{ marginTop: '20px' }}>
      <Autocomplete
        isMultiple
        icon={<Icon name="skill" style={{ width: '24px', height: '24px' }} />}
        label={t({ id: 'event.owner.exponents.input.corner.label' })}
        getOptionLabel={(corner) => authUser.locale === 'fr' ? corner.label_fr : corner.label_en}
        meta={{
          error: '',
        }}
        onChange={(evt, optionsSelected) => setCorners(map(optionsSelected, (o) => o._id))}
        options={get(event, 'modules.corner.items')}
        fullWidth
      />
    </div>
  );

  const renderParticipationMode = () => (
    event?.format === EVENT_FORMAT.HYBRID &&
    <div className={`${styles.participationMode} ${errParticipationMode && styles.participationModeError}`} style={{ marginTop: '20px' }}>
      <p className={styles.formLabel}>{t({ id: 'event.owner.exponents.input.participationMode.label' })} <span>*</span></p>
      <div className={styles.btnGroup}>
        <button onClick={() => setParticipationMode(EVENT_FORMAT.PHYSICAL)} className={participationMode === EVENT_FORMAT.PHYSICAL && styles.btnActive}><IconMapPin size={16}/> {t({ id: 'event.owner.exponents.input.participationMode.optionPhysical' })}</button>
        <button onClick={() => setParticipationMode(EVENT_FORMAT.VIRTUAL)} className={participationMode === EVENT_FORMAT.VIRTUAL && styles.btnActive}><IconWifi size={16} /> {t({ id: 'event.owner.exponents.input.participationMode.optionVirtual' })}</button>
        <button onClick={() => setParticipationMode(EVENT_FORMAT.HYBRID)} className={participationMode === EVENT_FORMAT.HYBRID && styles.btnActive}><IconMapPinWifi size={14} /> {t({ id: 'event.owner.exponents.input.participationMode.optionHybrid' })}</button>
      </div>
      {errParticipationMode && <p className={styles.errorMessage}>{t({ id: 'event.owner.exponents.participationMode.errorMessage' })}</p>}
    </div>
  );

  const renderPartner = () => (
    get(event, 'modules.partner.enable') &&
    <div className={styles.participationMode} style={{ marginTop: '20px' }}>
      <p className={styles.formLabel}>{t({ id: 'event.owner.exponents.partner.label' })} {authUser.locale === 'fr' ? event?.modules.partner.label_fr : event?.modules.partner.label_en } <span>*</span></p>
      <div className={styles.btnGroup}>
        <button onClick={() => setIsPartner(true)} className={isPartner && styles.btnActive}>{t({ id: 'yes' })}</button>
        <button onClick={() => setIsPartner(false)} className={!isPartner && styles.btnActive}>{t({ id: 'no' })}</button>
      </div>
    </div>
  );

  const renderErrorCompanyExist = () => (
    <div className={styles.errorCompanyExistContainer} style={{ marginTop: '20px' }}>
      <IconInfo size={16} />
      <p>{errCompanyExist?._organization?.name || errCompanyExist} {t({ id: 'event.owner.exponents.company.errorMessage' })}</p>
    </div>
  );

  const getOrganization = () => (
    context === 'postOrganization' && getId(authUser._currentOrganization) === getId(organization) ?
      null : organization
  );

  const toggleCancel = () => {
    if (context !== 'postOrganization') {
      setCurrentView(VIEWS.pickOrganization.name);
    }
    onCancel();
  }

  const properties = VIEWS[currentView];

  return (
    <Modal
      onOk={handleOnOk}
      onCancel={toggleCancel}
      visible={isOpen}
      width={660}
      footer={false}
      className={`customConfirm ${styles.exponentModalContainer}`}
      okText={t({ id: 'add' })}
      cancelText={t({ id: 'cancel' })}
    >
      <a role="button" tabIndex={0} className="modal-close" onClick={toggleCancel}>
        <Icon name="close" className="modal-close-icon" />
      </a>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '30px' }}>
        <h4 className={styles.modalTitle}>{properties.title}</h4>
        <a
          role="button"
          tabIndex={0}
          className={styles.createOrganizationLink}
          onClick={changeView}
        >
          {properties.toggleText()}
        </a>
      </div>
      {renderPickOrganization()}

      {renderCreateOrganization()}

      {errCompanyExist && renderErrorCompanyExist()}

      {!isEmpty(getOrganization()) && !errCompanyExist && currentView !== 'addRecruiters' && context === 'default' && renderCorners()}

      {(!isEmpty(getOrganization()) && !errCompanyExist) && currentView !== 'addRecruiters' && context === 'default' && renderParticipationMode()}

      {!isEmpty(getOrganization()) && !errCompanyExist && currentView !== 'addRecruiters' && context === 'default' && renderPartner()}

      {!isEmpty(getOrganization()) && context === 'default' && hasToCheckKnowsAddedExponents && (
        <Checkbox className={styles.ownerKnowsAddedExponents} checked={!!ownerKnowsAddedExponents} onChange={(e) => {
          if (e.target.checked) {
            setOwnerKnowsAddedExponents(new Date())
          } else {
            setOwnerKnowsAddedExponents(false)
          }
        }}>{t({ id: "event.owner.exponents.company.knowsCompanies" })}</Checkbox>
      )}

      {!isEmpty(getOrganization()) && !errCompanyExist &&
        <Button
          id="addOrganizationSubmit"
          className="w-full mt-20"
          onClick={handleOnOk}
        >
          {t({ id: 'add.company.pl' }, { count: 1 })}
        </Button>}
    </Modal>
  );
}

ExponentModal.propTypes = {
  isOpen: bool,
  onOk: func,
  onCancel: func,
  patchEvent: func,
  postExponent: func,
  exponents: object,
  postOrganization: func,
  pushOrganization: func,
  setCurrentOrganization: func,
  event: object,
  organization: object,
  authUser: object,
  context: oneOf(['default', 'withRecruiters', 'postOrganization']),
};

const mapStateToProps = createStructuredSelector({
  organization: organizationSelectors.getCurrentOrganization,
  authUser: authSelectors.getAuthUser,
  event: eventSelectors.getCurrentEvent,
  exponents: exponentSelectors.getExponents,
});

const mapDispatchToProps = {
  setCurrentOrganization: organizationActions.setCurrentOrganization,
  pushOrganization: organizationActions.pushOrganization,
  postOrganization: organizationActions.postOrganization,
  postExponent: exponentActions.postExponent,
  patchEvent: eventActions.patchEvent,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(toJS(ExponentModal));
