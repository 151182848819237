import { FormattedHTMLMessage } from 'react-intl';
import { Form, Radio } from 'antd';
import React from 'react';
import { func, object } from 'prop-types';
import { Body1 } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';

const FormItem = Form.Item;
const RadioGroup = Radio.Group;

export function NotifyJDOpening({ getFieldDecorator, notifs = {} }) {
  const t = useFormatMessage();

  return (
    <>
      <Body1 className="mb-10 text-neutral-400"><FormattedHTMLMessage id="profile.settings.notifications.jd.opening.label" /></Body1>
      <FormItem>
        {getFieldDecorator('recruiter_jd_opening', {
          initialValue: notifs.recruiter_jd_opening,
        })(
          <RadioGroup>
            <Radio value>{t({ id: 'yes' })}</Radio>
            <Radio value={false}>{t({ id: 'no' })}</Radio>
          </RadioGroup>
        )}
      </FormItem>
    </>
  );
}

NotifyJDOpening.propTypes = {
  getFieldDecorator: func,
  notifs: object,
  intl: object,
};
