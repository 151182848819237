import React from 'react';
import { number, bool } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Funnel } from '@/components/Stats/Funnels';
import messages from '../../messages';

const StatsFunnelConversionParticipants = ({ countParticipants = 0, countVisits = 0, countConverted = 0, loading }) => (
  <Funnel
    dataSources={[
      { title: <FormattedMessage {...messages.conversionParticipantTitle} />, count: countParticipants, description: <FormattedMessage {...messages.conversionParticipantText} />, loading },
      { title: <FormattedMessage {...messages.conversionParticipantVisitTitle} />, count: countVisits, description: <FormattedMessage {...messages.conversionParticipantVisitText} />, loading },
      { title: <FormattedMessage {...messages.conversionParticipantParticipationTitle} />, count: countConverted, description: <FormattedMessage {...messages.conversionParticipantParticipationText} />, loading },

    ]}
  />
);

StatsFunnelConversionParticipants.propTypes = {
  loading: bool,
  countParticipants: number,
  countVisits: number,
  countConverted: number,
};

export { StatsFunnelConversionParticipants };
