import React from 'react';
import ParticipantsView from '@/scenes/Event/scenes/Recruiter/scenes/Jobdating/container/ParticipantsView';

class RecruiterJobdatingSaveScene extends React.PureComponent { // eslint-disable-line
  render() {
    return (
      <ParticipantsView context="save" />
    );
  }
}

export default RecruiterJobdatingSaveScene;
