import { useQuery } from 'react-query';
import request from '@/utils/request';

export function useGetOrganizationsProfilesPreviewsSidebarConferences({ organizationId, eventId, ...props }) {
  const requestUrl = `${process.env.FRONT_API_URL}/organizations/${organizationId}/profiles/previews/sidebar/conferences?eventId=${eventId}`;
  const queryFn = () => request(requestUrl, { method: 'GET' });

  const query = useQuery({
    queryKey: ['standSidebarConferences'],
    queryFn,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    enabled: !!organizationId,
    ...props
  });
  return query;
}
