import React from 'react';
import { object } from 'prop-types';
import { IconCheck, Body1 } from '@seekube-tech/ui-kit';
import colors from '@seekube-tech/ui-kit/dist/colors';
import { useFormatMessage } from 'react-intl-hooks';
import { take, size } from 'lodash';
import classnames from 'classnames';
import styles from './styles.less';

export const BenefitsList= ({ profile, maxBenefits = 0 }) => {
  const t = useFormatMessage();
  const fullList = size(profile?.benefitsJoin) === maxBenefits;
  const benefits = profile?.benefitsJoin?.filter(n => n);

  return (
    <div className={classnames(styles.listContent, { [styles.fullList] : fullList})}>
      {take(benefits, maxBenefits)?.map((benefit, key) => (
        <div key={key} className="flex mb-16">
          <Body1 color={colors.info['500']}  className="mr-16">
            <IconCheck color={colors.info['500']} size={24} />
          </Body1>
          <Body1 color={colors.neutral['500']}>{benefit}</Body1>
        </div>
      ))}
    </div>
  )
}

BenefitsList.propTypes = {
  profile: object,
}
