import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { useSelector } from 'react-redux';
import { getAuthUser } from '@/store/auth/selectors';
import {
  AvatarBusiness,
  IconCandidates,
  IconCheckCircle,
  IconClock,
  IconVideo,
  Tag
} from '@seekube-tech/ui-kit';
import { Link } from 'react-router-dom';
import { Calendar } from 'react-feather';
import { boolean, object, string } from 'prop-types';

// Styles

import styles from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Exponents/scenes/Exponent/components/Home/Sidebar/Cards/styles.less';

const CardConference = ({
  event,
  conference,
  isPartner,
  organizationProfile,
  partnerColor,
  partnerIcon
}) => {
  const t = useFormatMessage();
  const nameReference = useRef(null);
  const [nameCssClass, setNameCssClass] = useState(styles.title);
  const authUser = useSelector(getAuthUser)?.toJS();

  useEffect(() => {
    if (nameReference.current) {
      const linesNumber = nameReference.current.offsetHeight / parseInt(getComputedStyle(nameReference.current).lineHeight.replace('px', ''));

      if (linesNumber > 3) {
        setNameCssClass(`${styles.title} ${styles.truncated}`);
      } else {
        setNameCssClass(styles.title);
      }
    }
  }, [nameReference]);

  const getLiveStatus = ({ beginAt, isParticipate, isFull }) => {
    const now = new Date()
    if (isParticipate) {
      return 'participate'
    }
    if (isFull) {
      return 'full';
    }
    if (moment(beginAt).isBefore(now)) {
      return "ongoing";
    }
  }

  const labelDisplayed = (live) => {
    const status = getLiveStatus(live);

    switch (status) {
      case 'ongoing':
        return (
          <div className={styles.label}>
            <IconVideo size={12}/>
            <p>{t({ id: `dashboard.realtime.liveCard.label.ongoing` })}</p>
          </div>
        )
      case 'participate':
        return (
          <div>
            <Tag size="small" variant="tonal" color="info"
                 icon={<IconCheckCircle size={12}/>}>{t({ id: `conference.registred` })}</Tag>
          </div>
        )
      case 'full':
        return (
          <div>
            <Tag size="small" variant="tonal" color="error"
                 icon={<IconCandidates size={12}/>}>{t({ id: `conference.full` })}</Tag>
          </div>
        )
      default:
        return (
          <div>
            <Tag size="small" variant="tonal" color="info"
                 icon={<IconClock size={12}/>}>{t({ id: `dashboard.realtime.liveCard.label.upcoming` })}</Tag>
          </div>
        )
    }
  }

  return <Link to={`/${event.slug}/candidate/jobdating/conferences/conference/${conference._id}`} target='_blank'>
    <div className={styles.cardConference}>
      {labelDisplayed(conference)}
      <div className={styles.cardLiveContent}>
        <div className={styles.leftContent}>
          <AvatarBusiness
            pictureUrl={conference.cover ?? conference.organizationProfile.cover}
            size="small"
          />
          <AvatarBusiness
            color={isPartner ? partnerColor : 'default'}
            badge={isPartner ? partnerIcon : null}
            pictureUrl={(organizationProfile?.logo || organizationProfile?.pictureUrl)}
            size="small"
          />
        </div>
        <div className={styles.rightContent}>
          <div className={styles.conferenceInfo}>
            <div ref={nameReference} className={nameCssClass}>{conference.title}</div>
            <div className={styles.detail}>
              <Calendar size={12}/>
              <p>{moment.tz(conference.beginAt, authUser?.timezone.utc[0]).format('DD/MM - HH:mm')} ({ conference.duration } min)</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Link>
}

CardConference.propTypes = {
  event: object,
  conference: object,
  isPartner: boolean,
  organizationProfile: object,
  partnerColor: string,
  partnerIcon: string
}

export default CardConference;
