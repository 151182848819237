import React from 'react';
import { Button } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { bool, object } from 'prop-types';

import Progress from '@/components/Progress';

function ModalFooter({ displayProgress, submitLoading, offer, confirmationIsVisible }) {
  /* Vars */

  const t = useFormatMessage();

  /* Render */

  return confirmationIsVisible === false && (
    <div className="progressWrapper">
      {displayProgress && (
        <Progress
          items={[
            { isActive: true, label: t({ id: 'appointment.footer.step.criterion' }) },
            { isActive: false, label: t({ id: 'appointment.footer.step.slot' }) },
          ]}
        />
      )}

      <Button
        color="primary"
        type="submit"
        loading={submitLoading}
      >
        {offer?._event?.skipAppointmentValidation ?
          t({ id: 'submit' }) :
          t({ id: 'event.candidate.jobdating.offer.applicationModal.apply' })
        }
      </Button>
    </div>
  );
}

ModalFooter.propTypes = {
  displayProgress: bool,
  submitLoading: bool,
  offer: object,
  confirmationIsVisible: bool,
};

ModalFooter.defaultProps = {
  displayProgress: false,
  submitLoading: false,
  offer: {},
  confirmationIsVisible: false,
};

export default ModalFooter;
