import React from 'react';
import { string, any, bool, oneOfType, object } from 'prop-types';
import classnames from 'classnames';

// Components
import { Tooltip as TooltipAntd } from 'antd';

// Styles & Translations
import './styles.less';

const Tooltip = (props) => props.isVisible &&
  <TooltipAntd {...props} overlayClassName={classnames(`sk-tooltip-${props.color}`, props.overlayClassName, props.align)} />

Tooltip.propTypes = {
  color: string,
  title: oneOfType([string, object]),
  overlayClassName: any,
  align: any,
  isVisible: bool,
};

Tooltip.defaultProps = {
  color: 'default',
  align: 'middle',
  isVisible: true,
};

export { Tooltip };
