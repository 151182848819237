import React, { useState } from 'react';
import { object, func, bool  } from 'prop-types';
import { Button, IconUpload, IconX, IconLink, IlluCvSuccess, IlluCvError } from '@seekube-tech/ui-kit';
import classnames from 'classnames';
import { useFormatMessage } from 'react-intl-hooks';
import { DragAndDropFile } from '@/components/DragAndDropFile';
import { Mandatory } from '@/components/Form';
import { getUserResumeUrl } from "@/helpers/resume";
import { useIsMobile } from '@/utils/MediaQueries';
import styles from './styles.less';

const emptyCvValues = { resumeS3Key: undefined, resumeUrl: null, resumeTitle: null };

export const UserCv = ({ form, user, cvIsRequired, onUploadOk, onClick = () => {} }) => {
  const t = useFormatMessage();
  const isWiderThanMobile = !useIsMobile();
  const cvIlluSize = isWiderThanMobile ? 100 : 60;
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState("");
  const [cv, setCv] = useState({
    s3Key: user.resumeS3Key,
    url: user.resumeUrl,
    title: user.resumeTitle,
  })

  const onFileUploaded = ({ resumeS3Key, resumeTitle }) => {
    const values = { resumeS3Key, resumeTitle };

    form.setFieldsValue({ resumeS3Key, resumeTitle });
    setHasError(false);
    setCv({
      s3Key: resumeS3Key,
      url: user.resumeUrl,
      title: resumeTitle,
    });

    if (typeof onUploadOk === 'function') {
      onUploadOk(values);
    }
  };

  const onFileUploadedError = (error) => {
    form.setFieldsValue(emptyCvValues);
    setHasError(true);
    
    if (error?.statusCode === 413) {
      setError(t({ id: 'upload.file.tooLarge' }));
    }
  };

  const onFileRemove = (e) => {
    e.preventDefault();
    form.setFieldsValue(emptyCvValues)
    setHasError(false);
    setCv({
      s3Key: emptyCvValues.resumeS3Key,
      url: emptyCvValues.resumeUrl,
      title: emptyCvValues.resumeTitle,
    });
  };

  return (
    <div>
      <DragAndDropFile onClick={onClick} user={user} formatAccepted=".pdf" onFileUploaded={onFileUploaded} onError={onFileUploadedError} type="cv">
        <div className="flex items-center">
          <div className={classnames(styles.dragContainer, cv.s3Key ? styles.uploaded : '')}>
            {!cv.s3Key && !hasError && <IconUpload className={styles.iconUpload} />}
            {cv.s3Key && <IlluCvSuccess size={cvIlluSize}  className="uploaded-icon" />}
            {hasError && <IlluCvError size={cvIlluSize}  className="uploaded-icon" />}
          </div>
          <div>
            <Button size="small" className="cvBtn" variant="outline">
              <Mandatory visible={cvIsRequired}>{!cv.s3Key ?t({ id: 'form.resume.import' }) : t({ id: 'form.resume.update' })}</Mandatory>
            </Button>
            <p className="upload-infos">
              {t({ id: 'resume.format' })}
            </p>
          </div>
        </div>
      </DragAndDropFile>
      {error && <div className={styles.fileError}>{error}</div>}
      {cv.title &&
        <div className={styles.cvLink}>
          <a href={cv.s3Key ? getUserResumeUrl() : cv.url} className="flex items-center mr-5" target="_blank">
            <IconLink size={12} className="mr-5" /><span className={styles.fileName}>{cv.title}</span>
            <Button variant="tonal" color="error" size="small" onClick={onFileRemove} role="button" tabIndex={0}> <IconX size={12} /></Button>
          </a>
        </div>
      }
    </div>
  )
}
UserCv.propTypes = {
  user: object,
  form: object,
  cvIsRequired: bool,
  onUploadOk: func,
  onClick: func
}
