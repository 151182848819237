import classnames from 'classnames';
import React, { useEffect } from 'react';
import styles from './styles.less';
import { usePostSwitchLogout } from '@/queries/user/usePostSwitchLogout';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { authActions } from '@/store/auth';
import { useFormatMessage } from 'react-intl-hooks';
import { Button } from '@seekube-tech/ui-kit';

const NavConnectedAs = ({
  authUserConnectedAs,
  authUserNotConnectedAs,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const t = useFormatMessage();

  const leaveSwitch = () => {
    history.push(localStorage.getItem('switch_quit_url') || '/events');
    localStorage.setItem('switch_quit_url', '');
    dispatch(authActions.switchExitSuccess());
    window.location.reload();
  } 

  useEffect(() => {
    if (authUserNotConnectedAs?._id === authUserConnectedAs?._id && authUserNotConnectedAs !== null) {
      leaveSwitch();
    }
  }, [authUserConnectedAs?._id, authUserNotConnectedAs?._id])

  const switchExitMutate = usePostSwitchLogout({
    onSuccess: () => {
      leaveSwitch()
    },
    onError: () => {
      history.push('/events');
      window.location.reload();
    }
  });

  if (
    isEmpty(authUserConnectedAs)
    || isEmpty(authUserNotConnectedAs)
    || !authUserNotConnectedAs.isAdmin
  ) {
    return null;
  }
  
  return (
    <div className={styles.navContainer}>
      <div className={classnames(styles.nav, styles.navConnectedAs)}>
        <span className={styles.navConnectedAsSpan}>{t({ id: 'connectedAs' })} {authUserConnectedAs.username} ({authUserConnectedAs._id})</span>
        <Button
          color="primary"
          variant="text"
          onClick={switchExitMutate.mutate}
        >
          {t({ id: 'leaveSwitch' })}
        </Button>
      </div>
    </div>
  );
}

export { NavConnectedAs };