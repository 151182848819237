import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Form, Input, Radio } from 'antd';
import { compose } from 'redux';
import { object, func } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { Video } from 'react-feather';
import { H4, Button } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { toJS } from '@/utils';

import { InputGoogleMapsSuggest } from '@/components/Input';
import Checkbox, { CheckboxGroup } from '@/components/Checkbox';
import { Bloc } from '@/components/Bloc';


import { getAvailableActions } from '@/store/availableActions/selectors';
import Icon from '@/components/Icon';
import { authSelectors } from '@/store/auth';
import { getAuthUser } from '@/store/auth/selectors';
import styles from './styles.less';
import ApiToForm from '../../services/formMapping/ApiToForm';

const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;

const GeneralInfos = ({
  event: { name, type, location, format, locale, targets, opsManagers },
  form: { getFieldDecorator, validateFields, getFieldValue, setFieldsValue },
  handleSubmit,
  availableActions
}) => {
  const t = useFormatMessage();
  const [stateLocation, setLocation] = useState(location);
  const authUser = useSelector(getAuthUser).toJS()

  return (
    <Form onSubmit={(e) => { e.preventDefault(); validateFields(handleSubmit); }} layout="vertical" className={styles.formContainer}>
      <Bloc>
        <H4 className="mb-20">{t({ id: 'event.basic.generalInfos.title' })}</H4>

        <FormItem label={t({ id: 'lastName' })}>
          {getFieldDecorator('name', {
            initialValue: name,
            rules: [{ required: true, message: t({ id: 'event.basic.input.name.required' }) }],
          })(
            <Input placeholder={t({ id: 'event.basic.input.name.placeholer' })} />
          )}
        </FormItem>
        <FormItem label={t({ id: 'type' })}>
          {getFieldDecorator('type', {
            initialValue: type,
            rules: [
              { required: true, message: t({ id: 'form.error.required' }) },
            ],
          })(
            <RadioGroup>
              <RadioButton value="school">
                <Icon name="formation" />
                {t({ id: 'event.basic.type.school' })}
              </RadioButton>
              <RadioButton value="company">
                <Icon name="building" />
                {t({ id: 'event.basic.type.company' })}
              </RadioButton>
              <RadioButton value="seekube" >
                <Icon name="logo" />
                Seekube
              </RadioButton>
              <RadioButton value="jpo" >
                jpo
              </RadioButton>
            </RadioGroup>
          )}
        </FormItem>
        <FormItem label="Format">
          {getFieldDecorator('format', {
            initialValue: format || ApiToForm.localizationType(location),
            rules: [
              { required: true, message: t({ id: 'form.error.required' }) },
            ],
          })(
            <RadioGroup>
              <RadioButton value="virtual">
                <Video style={{ fill: 'transparent' }} />
                <FormattedMessage id="event.localization.virtual" />
              </RadioButton>
              <RadioButton value="physical">
                <Icon name="building" />
                <FormattedMessage id="event.localization.physic" /></RadioButton>
              {availableActions.updateEventType?.isTypeEnabled('hybrid') && (
                <RadioButton value="hybrid">
                  <Icon name="logo" />
                  <FormattedMessage id="event.localization.hybrid" />
                </RadioButton>
              )}
            </RadioGroup>
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('location', {
            initialValue: ApiToForm.localizationObject(stateLocation),
            rules: [
              { required: true, message: t({ id: 'form.error.required' }) },
            ],
          })(
            <InputGoogleMapsSuggest
              placeholder={t({ id: 'event.basic.input.address' })}
              initialValue={ApiToForm.localizationName(stateLocation)}
              disabled={getFieldValue('format') === 'virtual'}
              onChange={(locations) => { setLocation(locations); setFieldsValue({ location: locations }); }}
            />
          )}
        </FormItem>
        <FormItem label={t({ id: 'event.basic.targets.label' })}>
          {getFieldDecorator('targets', {
            initialValue: targets,
            rules: [
              { required: true, message: t({ id: 'form.error.required' }) },
            ],
          })(
            <CheckboxGroup>
              <Checkbox value="engineer" >{t({ id: 'engineer.short' })}</Checkbox>
              <Checkbox value="tech">{t({ id: 'technical.short' })}</Checkbox>
              <Checkbox value="business">{t({ id: 'business' })}</Checkbox>
              <Checkbox value="other">{t({ id: 'other' })}</Checkbox>
            </CheckboxGroup>
          )}
        </FormItem>
        <FormItem label={t({ id: 'event.basic.input.language.label' })}>
          {getFieldDecorator('locale', {
            initialValue: locale || 'fr',
            rules: [
              { required: true, message: t({ id: 'form.error.required' }) },
            ],
          })(
            <RadioGroup>
              <RadioButton value="fr" ><FormattedMessage id="french" /></RadioButton>
              <RadioButton value="en"><FormattedMessage id="english" /></RadioButton>
            </RadioGroup>
          )}
        </FormItem>
        {authUser.isAdmin && 
          <FormItem label={t({ id: 'event.ops.name' })}>
            {getFieldDecorator('opsManagers', {
              initialValue: opsManagers,
            })(
              <Input placeholder={t({ id: 'event.ops.name.placeholder' })} />
            )}
          </FormItem>
        }
        <FormItem>
          <Button type="submit">
            {t({ id: 'save' })}
          </Button>
        </FormItem>
      </Bloc>
    </Form>
  );
};

GeneralInfos.propTypes = {
  event: object,
  form: object,
  handleSubmit: func,
  availableActions: object
};

const withForm = Form.create();

const mapStateToProps = createStructuredSelector({
  availableActions: getAvailableActions,
})

const mapDispatchToProps = {}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(
  withForm,
  toJS,
  injectIntl,
  withConnect
)(GeneralInfos);
