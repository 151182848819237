import { ANALYTICS_RECRUITER } from '@/utils/constants';
import { track } from '@/utils/analytics';

const analyticsRefuseCandidate = ({ authUser, event, exponent, count, severalActions }) => (
  track({
    name: ANALYTICS_RECRUITER.REFUSED_CANDIDATES,
    user: authUser,
    event,
    properties: {
      'user type': exponent.users.find((user) => user._user._id === authUser._id).role,
      'several actions': severalActions,
      count,
    },
  })
);

export {
  analyticsRefuseCandidate,
};
