import React from 'react';
import { string } from 'prop-types';
import classnames from 'classnames';
// Components
import { Checkbox as CheckboxAntd } from 'antd';

// Styles & Translations
import styles from './styles.less';

/**
 * Checkbox
 *
 * @param {object} props
 */
const Checkbox = (props) => (
  <span className={styles.checkboxContainer}><CheckboxAntd {...props} className={classnames(props.className)} /></span>
);
Checkbox.propTypes = {
  className: string,
};

export const CheckboxGroup = CheckboxAntd.Group;
export default Checkbox;
