import React, { Fragment } from 'react';
import { array, object } from 'prop-types';
import { size } from 'lodash';
import { AvatarBusiness, Body1 } from '@seekube-tech/ui-kit';

import { useFormatMessage } from 'react-intl-hooks';
import { mapAndJoin } from '@/utils/text';
import { getLocale } from '@/utils/localStorage';
import styles from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Dashboard/components/Cover/styles.less';

const Planners = ({ organizations = [], event }) => {
  const t = useFormatMessage();

  return (
    organizations.length ?
      <div className={styles.planners}>
        <Body1 className="mr-10" fontWeight={900}>
          {t({ id: 'event.landing.organizedBy.test'}, { count: size(organizations) })} <br/>
          {mapAndJoin(organizations, 'name', ', ')}
        </Body1>
        {
          organizations.map((organization) =>
            <Fragment key={organization.name}>
              <AvatarBusiness
                size="small"
                pictureUrl={(organization.profile?.logo || organization.profile?.pictureUrl)}
                variant="business"
                color={organization?.modules?.partner?.color || 'default'}
                badge={organization?.modules?.partner?.icon || null}
                tooltip={event?.modules?.partner?.[`label_${getLocale()}`]}
              />
            </Fragment>
          )
        }
      </div> : null
  );
};

Planners.propTypes = {
  organizations: array,
  event: object,
};

export { Planners };
