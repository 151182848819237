import * as React from 'react';
import { array } from 'prop-types';
import { groupBy } from 'lodash';
import styles from './styles.less';
import ReportPhase from './components/ReportPhase';

export const DataOverview = ({ nextReportsToSend }) => (<div className={styles.dataOverview}>
    {nextReportsToSend.map(report => <ReportPhase reports={[report]} name={report.phase} />)}
  </div>)

DataOverview.propTypes = {
  nextReportsToSend: array,
}
