import * as React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DatePicker, Form } from 'antd';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useFormatMessage } from 'react-intl-hooks';
import { eventSelectors } from '@/store/event';
import { toJS } from '@/utils';
import { IconInfo } from '@seekube-tech/ui-kit';
import { Tooltip } from '@/components/Tooltip';
import Icon from '@/components/Icon';
import ApiToForm from '../../../services/formMapping/ApiToForm';

const FormItem = Form.Item;
const { MonthPicker } = DatePicker;

const datePickerParams = {
  format: 'DD-MM-YYYY HH:mm:ss',
  showTime: {
    defaultValue: moment('07:00:00', 'HH:mm:ss'),
  },
};

function DefaultMonthSelector({ form, event }) {
  const { setFieldsValue, getFieldValue } = form;
  const t = useFormatMessage();

  const keyDatesOk = getFieldValue('keyDatesOk');

  return (
    <div style={{ display: keyDatesOk ? 'none' : 'block', marginTop: '10px', marginBottom: '20px' }}>
      <FormItem
        label={
          <Tooltip color="dark" title={t({ id: 'event.basic.input.keydates.jobdating.help' })} placement="right">
            {t({ id: 'event.basic.input.keydates.jobdating.label' })}<IconInfo size={16} className="ml-4" />
          </Tooltip>}
      >
        <MonthPicker
          {...datePickerParams}
          showTime={false}
          format="MMMM YYYY"
          defaultValue={ApiToForm.keyDates(event.keyDates, 'jobfair')[0]?.startOf('month')}
          onChange={(e) => {
            const t = e.toDate();
            setFieldsValue({ 'keyDates.jobfair': [moment(t).hours(8), moment(t).hours(8)] });
          }}
        />
      </FormItem>
    </div>
  )
}

DefaultMonthSelector.propTypes = {
  form: PropTypes.func,
  event: PropTypes.object
}

const mapDispatchToProps = {};

const mapStateToProps = createStructuredSelector({
  event: eventSelectors.getCurrentEvent
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(toJS(DefaultMonthSelector));
