import moment from 'moment/moment';

const mergeEventsEntities = (entities) => {
  const objects = entities;

  if (objects && objects.events) {
    const { events } = objects;

    Object.keys(events).forEach((eventId) => {
      const event = events[eventId];

      ['jobfair', 'discovering', 'hunt'].forEach((key) => {
        if (event.keyDates && event.keyDates[key]) {
          events[eventId].keyDates[key].beginAt = moment(new Date(event.keyDates[key].beginAt).getTime()).utcOffset(moment(event.keyDates[key].beginAt).tz('Europe/Paris').utcOffset()).toDate();
          events[eventId].keyDates[key].endAt = moment(new Date(event.keyDates[key].endAt).getTime()).utcOffset(moment(event.keyDates[key].endAt).tz('Europe/Paris').utcOffset()).toDate();
        }
      });
    });

    objects.events = events;
  }

  return Object.values(objects?.events ?? {});
}

export default mergeEventsEntities;
