import React from 'react';
import { object } from 'prop-types';

import styles from './styles.less';

function Jobfair({ event, jobfairsColor }) {
  const jobfairColor = jobfairsColor[event._event];

  return (
    <div className={styles.root} style={{ backgroundColor: jobfairColor?.light, color: jobfairColor?.main }}>
      {event.label}
    </div>
  );
}

Jobfair.propTypes = {
  event: object,
  jobfairsColor: object,
};

export default Jobfair;
