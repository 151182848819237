import { defineMessages } from 'react-intl';

export default defineMessages({
  languageLevel0: {
    id: 'language.level.0',
  },
  languageLevel1: {
    id: 'language.level.1',
  },
  languageLevel2: {
    id: 'language.level.2',
  },
  languageLevel3: {
    id: 'language.level.3',
  },
  languagePlaceholder: {
    id: 'event.basic.input.language.label',
  },
  languageLevelLabel: {
    id: 'language.level.label',
  },
  languageAdd: {
    id: 'language.add',
  },
  experienceLevel0: {
    id: 'experience.level[0]',
  },
  experienceLevel1: {
    id: 'experience.level[1]',
  },
  experienceLevel2: {
    id: 'experience.level[2]',
  },
  experienceLevel3: {
    id: 'experience.level[3]',
  },
  experienceLevel4: {
    id: 'experience.level[4]',
  },
  availableToday: {
    id: 'available.today',
  },
});
