import { useQuery } from 'react-query';
import request from '@/utils/request';

export function useGetOrganizationsProfileSearch({ keywords = '', limit = 40, ...props  }) {
  const requestUrl = `${process.env.FRONT_API_URL}/organizations/profile-search?keywords=${keywords}&limit=${limit}`;
  const queryFn = () => request(requestUrl, { method: 'GET' });

  const query = useQuery({
    queryKey: ['organizationProfileSearch', keywords],
    queryFn,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    ...props,
  });
  return query;
}
