import React from 'react';
import { injectIntl } from 'react-intl';
import { Form } from 'antd';
import { find, isEmpty } from 'lodash';
import { compose } from 'redux';
import { object, bool, func } from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useFormatMessage } from 'react-intl-hooks';
import { Button, Alert } from '@seekube-tech/ui-kit';
import { toJS } from '@/utils';

import timezones from '@/scenes/Settings/components/General/components/Timezone/timezone.json';
import 'moment-timezone';

import Radio, { RadioGroup } from '@/components/Radio';

import { eventSelectors } from '@/store/event';

import { H4 } from '@seekube-tech/ui-kit';
import { Bloc } from '@/components/Bloc';
import { getAuthUser } from '@/store/auth/selectors';
import { ANALYTICS_ORGA } from '@/utils/constants';
import { track } from '@/utils/analytics';
import FormToApi, { keyMomentsFormToApi } from '../../services/formMapping/FormToApi';

import TimelineBlock from './components/TimelineBlock';
import TimezoneSelector from './components/TimezoneSelector';
import KeyDatesForm from './components/KeyDatesForm';
import DefaultMonthSelector from './components/DefaultMonthSelector';
import KeyMomentsForm from './components/KeyMomentsForm';

import styles from './styles.less';

const FormItem = Form.Item;

function reassignKeyMomentsIdsToFormValues(event, newKeyMoments) {
  const reconciledKeyMoments = {
    ...newKeyMoments
  }
  Object.entries(reconciledKeyMoments).forEach(([key, value]) => {
    value.forEach((e, index) => {
      e._id = event.keyMoments[key]?.[index]?._id;
    })
  })

  return reconciledKeyMoments;
}

const KeyDatesBloc = ({
  event,
  form,
  handleSubmit,
  isLoading,
  hasSavedDates
}) => {
  const t = useFormatMessage();
  const authUser = useSelector(getAuthUser);
  const { getFieldDecorator, validateFields, getFieldValue } = form;
  /**
   * If reportings are enabled and keyDates have just been saved, show the user an alert stating that they should update
   * reportings dates
   */
  const showReportingsBanner = event?.modules?.reports?.enable && hasSavedDates && event?.keyDatesOk;

  const isLinksDisplayed =
    !isEmpty(getFieldValue('keyDates.jobfair')) &&
    !isEmpty(getFieldValue('keyDates.discovering')) &&
    !isEmpty(getFieldValue('keyDates.hunt')) &&
    event.keyDatesOk &&
    event.keyDatesTimelineIsHidden !== true;

  const onSubmit = (e) => {
    e.preventDefault();
    validateFields((formError, values) => {
      const timezone = find(timezones, { text: values.timezone });
      /**
       * If an event has keyMoments it's a Hybrid event, else it's a 100% virtual or 100% on-site
       */
      if (values.keyMoments) {
        try {
          let keyMoments = keyMomentsFormToApi(values.keyMoments, timezone);

          if (event.keyMoments) {
            keyMoments = reassignKeyMomentsIdsToFormValues(event, keyMoments);
          }
          track({
            name: ANALYTICS_ORGA.MODIFIED_KEY_DATES,
            event,
            authUser,
          })
          return handleSubmit(formError, { ...values, timezone, keyMoments });
        } catch (err) {
          console.error(err)
          return handleSubmit(true, { ...values, timezone, keyDates })
        }
      }
      const keyDates = FormToApi.keyDates(values.keyDates, event.timezone.utc[0]);
      track({
        name: ANALYTICS_ORGA.MODIFIED_KEY_DATES,
        event,
        authUser,
      })
      return handleSubmit(formError, { ...values, timezone, keyDates })
    });
  };

  const isEventHybrid = event.format === 'hybrid'
  const keyDatesOk = getFieldValue('keyDatesOk')

  return (
    <Form onSubmit={onSubmit} layout="vertical" className={styles.test}>
      <Bloc>
        <div className="mb-20">
          <H4 className="mb-20">{t({ id: 'event.basic.keydates.title' })}</H4>

          {getFieldDecorator('keyDatesOk', {
            initialValue: event.keyDatesOk,
          })(
            <RadioGroup
              onChange={handleSubmit}
              name="1"
            >
              <Radio value={false}>
                {t({ id: 'event.basic.radio.keydatesOk.no' })}
              </Radio>
              <Radio value data-testid="radio-keyDatesOk">
                {t({ id: 'event.basic.radio.keydatesOk.yes' })}
              </Radio>
            </RadioGroup>
          )}
        </div>

        {!keyDatesOk && <DefaultMonthSelector form={form} />}

        {keyDatesOk && <TimezoneSelector form={form} />}

        {keyDatesOk && !isEventHybrid && <KeyDatesForm form={form} />}

        {keyDatesOk && isEventHybrid && <KeyMomentsForm form={form} />}

        { showReportingsBanner && (
          <div className={styles.alertReportings}>
            <Alert color="warning" isClosable>
              {t({ id: 'event.basic.keyDates.reportingsEnabled.warning' })}
            </Alert>
          </div>
        )}

        <FormItem>
          <Button id="btnSubmitKeyDates" className={styles.button} loading={isLoading} type="submit">
            {t({ id: 'save' })}
          </Button>
        </FormItem>

        { isLinksDisplayed && (
            <TimelineBlock />
          )
        }
      </Bloc>
    </Form>
  );
};

KeyDatesBloc.propTypes = {
  event: object,
  form: object,
  handleSubmit: func,
  isLoading: bool,
  hasSavedDates: bool
};


const mapStateToProps = createStructuredSelector({
  event: eventSelectors.getCurrentEvent
});

const withForm = Form.create();
const withConnect = connect(mapStateToProps, null);

export default compose(
  withForm,
  injectIntl,
  withConnect,
)(toJS(KeyDatesBloc));
