import React from 'react';
import { FormattedMessage } from 'react-intl';
import { func } from 'prop-types';
import { Button } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import Html from '@/components/Html';
import styles from './styles.less';

const Failure = ({ onValueSelected }) => {
  const t = useFormatMessage();

  return (
    <div className={styles.failure}>
      <FormattedMessage tagName="h2" id="teams.recommendation.failure.title" />
      <hr/>

      <Html value={t({ id: 'teams.recommendation.failure.text' })} />
      <div className="mt-30">
        <Button onClick={() => { onValueSelected('phone'); }} className="w-full">
          {t({ id: 'seeklive.recommendation.failure.btn' })}
        </Button>
      </div>
    </div>
  )}

Failure.propTypes = {
  onValueSelected: func,
};

export default Failure;
