import { Bloc, BlocHeader } from '@/components/Bloc';
import { H4, Toggle, Link2, Subtitle2, Button } from '@seekube-tech/ui-kit';
import colors from '@seekube-tech/ui-kit/dist/colors';
import React, { useState } from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { eventActions } from '@/store/event';

import { useDispatch } from 'react-redux';
import styles from '@/scenes/Event/scenes/Owner/scenes/Setting/styles.less';

const Applications = ({ event }) => {
  const t = useFormatMessage();
  const dispatch = useDispatch();
  const [limitNumber, setLimitNumber] = useState(event?.limits?.applicationsMax || 0);

  const handleSave = () => {
    dispatch(eventActions.patchEvent({
      event: { limits: { ...event.limits, applicationsMax: limitNumber} },
      notificationParams: {
        success: {
          message: t({ id: 'notifications.update.success' }),
          kind: 'success',
        },
      }
    }));
  }

  const disableSave = !event?.limits?.enableApplications || limitNumber === 0 || limitNumber === event?.limits?.applicationsMax;

  return <div className={styles.formWrapper}>
    <Bloc>
      <BlocHeader className="mb-24">
        <H4>
          {t({ id: 'event.owner.setting.limits.candidates.applications.title' })}
        </H4>
        <Toggle checked={event?.limits?.enableApplications} onChange={(enableApplications) => {
          dispatch(eventActions.patchEvent({
            event: { limits: { ...event.limits, enableApplications} },
            notificationParams: {
              success: {
                message: t({ id: 'notifications.update.success' }),
                kind: 'success',
              },
            }
          })); }} />
      </BlocHeader>

      <div className="flex items-center">
        <Subtitle2 color={colors.grey['500']}>
          {t({ id: 'event.owner.setting.limits.candidates.applications.description' }, {
            clickHere:
              <Link2 className="lowercase" target="_blank" href="https://www.notion.so/seekube/Gestion-des-limitations-d-un-event-d0803947f2a94e7dba4f5f79248edead">
                {t({ id: 'click.here' })}
              </Link2>
          })}
        </Subtitle2>
      </div>

      {event?.limits?.enableApplications &&
        <div className={styles.setLimitationContainer}>
          <p className={styles.labelLimitation}>{t({id: 'max.applications'})}</p>
          <input type='number' className={styles.inputLimitation} value={limitNumber} onChange={(e) => setLimitNumber(e.target.value)} />
          <Button disabled={disableSave} size='small' onClick={() => handleSave()}>
            {t({id: 'save'})}
          </Button>
        </div>
      }
    </Bloc>
  </div>
}

export { Applications }