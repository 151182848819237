import React from 'react';
import { func, array, object } from 'prop-types';

// services
import ApiToForm from '@/services/Form/ApiToForm';

// components
import { Form } from 'antd';
import InputGoogleMapsSuggest from '@/components/Input/InputGoogleMapsSuggest';

const FormItem = Form.Item;

const OfferLocation = ({ fieldKey = 'locations', getFieldDecorator, locations, handleChangeLocations, handleBlurLocations, showHelper, criterion, authUser }) => {// eslint-disable-line
  const onLocationChange = (values) => handleChangeLocations(values);
  const onBlurLocations = () => handleBlurLocations();
  return (
    <FormItem style={{ zIndex: '15' }}>
      {getFieldDecorator(fieldKey, {
        initialValue: locations ? ApiToForm.localizationsObject(locations) : [],
        rules: [{
          whitespace: true,
          message: ' ',
          transform: (value) => value.name,
        }],
      })(
        <InputGoogleMapsSuggest
          placeholder={authUser.locale === 'fr' ? criterion.modules.offer.label : (authUser.locale === 'en' && criterion.modules.offer.label_en ? criterion.modules.offer.label_en : criterion.modules.offer.label)}
          initialValue={locations ? ApiToForm.localizationsName(locations) : []}
          addOnIcon="location"
          multiple
          required={criterion.modules.offer.choiceMin > 0}
          locations={locations}
          onChange={onLocationChange}
          onBlur={onBlurLocations}
          placeTypes={['locality', 'sublocality', 'postal_code', 'administrative_area_level_1','administrative_area_level_2' ]}
          withInputErrorStyle={false}
          showHelper={showHelper}
          id="locations"
        />
      )}
    </FormItem>
  );
};
OfferLocation.propTypes = {
  locations: array,
  getFieldDecorator: func,
  handleChangeLocations: func,
  handleBlurLocations: func,
  showHelper: func,
  criterion: object,
  authUser: object,
};

export default OfferLocation;
