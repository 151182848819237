import * as React from 'react';
import { string, func, node } from 'prop-types';
import { IconX } from '@seekube-tech/ui-kit'
import { useFormatMessage } from 'react-intl-hooks';
import styles from './styles.less';

function AppointmentDetails({
  children,
  type,
  closePopover,
  title = null,
}) {

  const t = useFormatMessage();

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        {title && <span className={styles.titleConferenceDetail}>{title}</span>}
        { type === "interview" && <h4>{t({ id: 'event.recruiter.preparation.timeslots.event.appointment' })}</h4> }
        { type === "informal1to1" && <h4>{t({ id: 'event.recruiter.preparation.timeslots.event.informal1to1' })}</h4>}
        <i role="button" tabIndex={0} className="flex cursor-pointer duration-300 rounded-md" onClick={closePopover}>
          <IconX />
        </i>
      </div>
      {children}
    </div>
  );
}

AppointmentDetails.propTypes = {
  type: string.isRequired,
  children: node.isRequired,
  closePopover: func.isRequired
};

export default AppointmentDetails;
