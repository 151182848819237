import React, { useEffect, useState } from 'react';
import { object, bool } from 'prop-types';

import moment from 'moment';
import { useFormatMessage } from 'react-intl-hooks';
import { NavLink } from 'react-router-dom';

import { Check, Video, Clock, Calendar, User, Mic } from 'react-feather';
import { Avatar } from '@seekube-tech/ui';
import styles from './styles.less'

const LiveCardStat = ({ live, event, focusOn }) => {

  const t = useFormatMessage();
  const cover = live?.cover ?? live?._organization?.profile?.cover ?? undefined;

  const [liveStatus, setLiveStatus] = useState("incoming");

  useEffect(() => {
    const now = new Date()
    if (moment(live.beginAt).add(live.duration, 'm').isBefore(now)) {
      setLiveStatus("over");
    }
    else if (moment(live.beginAt).isBefore(now)) {
      setLiveStatus("ongoing");
    }

    if (focusOn) {
      const list = document.getElementById('listLivesCardsContainer');
      const card = document.getElementById('cardFocusOn');
      list.scroll(0, card.getBoundingClientRect().top - list.getBoundingClientRect().top);
    }
  }, [])

  const labelDisplayed = () => {
    if (liveStatus === 'ongoing') {
      return (
        <div className={styles.label}>
          <Video size={12} />
          <p>{t({ id: `dashboard.realtime.liveCard.label.ongoing` })}</p>
        </div>
      )
    }
    if (liveStatus === 'over') {
      return (
        <div className={styles.labelLiveOver}>
          <Check size={12} />
          <p>{t({ id: `dashboard.realtime.liveCard.label.over` })}</p>
        </div>
      )
    }

    return (
      <div className={styles.labelLiveIncoming}>
        <Clock size={12} />
        <p>{t({ id: `dashboard.realtime.liveCard.label.upcoming` })}</p>
      </div>
    )

  }

  return (
    <div className={styles.liveCardStatContainer} id={focusOn ? 'cardFocusOn' : null}>
      <div className={styles.leftContent}>
        {labelDisplayed()}
        <div className={styles.picturesContainer}>
          {
            cover !== undefined && <div className={styles.pictureElement}>
              <img src={cover} alt='livePicture' />
            </div>
          }
          <Avatar
            variant="business"
            type="rounded"
            size="small"
            src={live.pictureUrl ?? 'https://cdn-app-assets.seekube.com/user/picture.png'}
            color={live._organization.isPartner ? event.modules.partner?.color : 'default'}
            badge={live._organization.isPartner ? event.modules.partner?.icon : null}
            tooltip={event.modules.partner?.label_fr}
          />
        </div>
      </div>
      <div className={styles.rightContent}>
        <div className={styles.organization}>{t({ id: `dashboard.realtime.liveCard.organization` })} {live._organization.name}</div>
        <div className={styles.liveInfo}>
          <div className={styles.title}>{live.title}</div>
          <div className={styles.detail}>
            <Calendar size={12} />
            <p>{moment(live.beginAt).format('DD/MM - HH:mm')} ({live.duration} min)</p>
          </div>
          <div className={styles.detail}>
            <User size={12} />
            <p>{live.countAttendees} {t({ id: `dashboard.realtime.liveCard.attendees` }, { maxAttendees: live.maxAttendees })}</p>
          </div>
          <div className={styles.detail}>
            <Mic size={12} />
            <p>{t({ id: `dashboard.realtime.liveCard.presenters` }, { countPresenters: live.presenters.length })}</p>
          </div>
        </div>
        <NavLink to={`/${event._id}/owner/conferences/${live._id}/all`}>
          <div className={styles.button}>{t({ id: `dashboard.realtime.liveCard.button` })}</div>
        </NavLink>
      </div>
    </div>
  )
}


LiveCardStat.propTypes = {
  live: object,
  event: object,
  focusOn: bool,
};

export default LiveCardStat;
