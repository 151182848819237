import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useFormatMessage } from 'react-intl-hooks';

import { Button } from '@seekube-tech/ui-kit';

import { CustomizeCookie } from '@seekube-tech/cookie-consent';
import pkg from '../../../package.json';

import styles from './styles.less';

function Footer() {
  /* Vars */

  const [isVisible, setIsVisible] = useState(false);
  const t = useFormatMessage();

  /* Functions */

  /**
   * When call, close a customize cookie modal
   */
  const handleCloseCookie = () => {
    setIsVisible(false);
  };

  /**
   * When call, open a customize cookie modal
   */
  const handleOpenCookie = () => {
    setIsVisible(true);
  };

  /* Render */

  return (
    <div className={styles.root}>
      <Row className={styles.footer} type="flex" justify="center" align="middle" gutter={8}>
        <Col>
          <a href={t({ id: 'url.we.recruit' })} target="_blank">
            <FormattedMessage id="footer.weRecruit" />
          </a>
        </Col>

        <Col><span>.</span></Col>

        <Col><a href="https://intercom.help/seekube" target="_blank"><FormattedMessage id="footer.help" /></a></Col>

        <Col><span>.</span></Col>

        <Col><a href="https://www.seekube.com/legals" target="_blank"><FormattedMessage id="footer.legalMention" /></a></Col>

        <Col><span>.</span></Col>

        <Col><a href="https://www.seekube.com/terms" target="_blank"><FormattedMessage id="footer.termOfUse" /></a></Col>

        <Col><span>.</span></Col>

        <Col><a href="https://www.seekube.com/privacy-policy" target="_blank"><FormattedMessage id="footer.privacy" /></a></Col>

        <Col><span>.</span></Col>

        <Col>
          <Button onClick={handleOpenCookie} className={styles.buttonLink}>
            <FormattedMessage id="footer.cookies" />
          </Button>
        </Col>

        <Col><span>.</span></Col>

        <Col><span>{`© ${new Date().getFullYear()} Seekube`}</span></Col>

        <Col><span>.</span></Col>

        <Col><span>v{pkg.version}</span></Col>
      </Row>

      <CustomizeCookie
        isVisible={isVisible}
        onClose={handleCloseCookie}
        labels={{
          title: t({ id: 'customizeModal.title' }),
          description: t({ id: 'customizeModal.description' }),
          fonctional: {
            label: t({ id: 'customizeModal.fonctional.label' }),
            description: t({ id: 'customizeModal.fonctional.description' }),
          },
          statistics: {
            label: t({ id: 'customizeModal.statistics.label' }),
            description: t({ id: 'customizeModal.statistics.description' }),
          },
          marketing: {
            label: t({ id: 'customizeModal.marketing.label' }),
            description: t({ id: 'customizeModal.marketing.description' }),
          },
          actions: {
            findMore: t({ id: 'customizeModal.actions.findMore' }),
            refuse: t({ id: 'customizeModal.actions.refuse' }),
            accept: t({ id: 'customizeModal.actions.accept' }),
          },
        }}
      />
    </div>
  );
}

export default Footer;
