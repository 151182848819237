import React from 'react';
import { any, object } from 'prop-types';
import { Col, Row } from 'antd';
import { useIsDesktop } from '@/utils/MediaQueries';
import {
  useInfiniteGetOrganizationProfilesPreviewsOffers
} from '@/queries/offers/useInfiniteGetOrganizationProfilesPreviewsOffers';
import {
  CardOffers,
} from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Exponents/scenes/Exponent/components/Offers/components';
import CardInfo
  from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Exponents/scenes/Exponent/components/CardInfo';
import interviewHelpPicture from '@/assets/images/interviewHelpPicture.svg';
import { useFormatMessage } from 'react-intl-hooks';
import {
  ShowMoreButton
} from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Exponents/scenes/Exponent/components/ShowMoreButton';
import { getLocale } from '@/utils/localStorage';
import styles from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Exponents/scenes/Exponent/stylesV2.less';
import { getId } from '@/utils/global';

const StandOffers= ({
  exponent,
  event,
  children
}) => {
  const t = useFormatMessage();
  const isDesktop = useIsDesktop();

  const offersInfiniteQuery = useInfiniteGetOrganizationProfilesPreviewsOffers({
    eventId: getId(exponent._event),
    organizationId: getId(exponent._organization)
  });

  return (
    <Row gutter={[32, 32]}>
      <Col xs={12} xl={4} className={isDesktop ? 'float-right' : ''}>
        <CardInfo title={t({ id: 'the.interviews' }, { label: event.modules?.offer[`labelMultiple_${getLocale()}`] })}
                  description={t({ id: 'candidate.dashboard.stats.interview.popover.description' })}
                  img={interviewHelpPicture}
        />
      </Col>

      <Col xs={12} xl={8}>
        <div className={styles.tabLeftContent}>
          {children}
          <CardOffers offersInfiniteQuery={offersInfiniteQuery} exponent={exponent} event={event} />
          <ShowMoreButton entityInfiniteQuery={offersInfiniteQuery} />
        </div>
      </Col>
    </Row>
  )
}

StandOffers.propTypes = {
  exponent: object,
  event: object,
  children: any
}

export default StandOffers;
