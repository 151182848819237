import React from 'react';
import { injectIntl } from 'react-intl';
import { string, func, node, oneOf, number, object } from 'prop-types';
import { isEmpty } from 'lodash';
import 'cropperjs/dist/cropper.css';

// Helper
import { If, Then, Else } from 'react-if';
import Cropper from 'react-cropper';
import { ButtonRound } from '@seekube-tech/ui-kit';
import { dataURItoFile } from '@/utils/file';
import S3 from '@/services/S3';

// Components
import Modal from '@/components/Modal';
import { InputFile } from '@/components/Input';
import AvatarPreview from '@/components/Avatar';
import Icon from '@/components/Icon';
import DropZone from './components/DropZone';
import styles from './styles.less';

// Styles & Translations
import messages from '../AvatarEditor/messages';

/**
 * AvatarEditorDnd
 */
class AvatarEditorDnd extends React.PureComponent {
  static propTypes = {
    src: string,
    children: node,
    onSave: func,
    onRemove: func,
    display: oneOf(['modal', 'inline']),
    objectId: string,
    size: number,
    user: object,
    intl: object,
  };

  /**
   * State
   */
  state = {
    src: this.props.src,
    displayCropper: false,
    error: false,
    modalIsVisible: false,
    dragging: false,
  };

  /**
   * When we receive new props
   *
   * @param {object} params
   * @param {string|object} params.src
   */
  componentWillReceiveProps({ src }) {
    this.setState({ src });
  }

  /**
   * Open the modal
   */
  openModal = () => this.setState({ modalIsVisible: true });

  /**
   * Toggle the modal
   *
   * @param {Function} cb
   */
  toggleModal = (cb) => this.setState({
    modalIsVisible: !this.state.modalIsVisible,
  }, cb);

  /**
   * Handle Change when user choose new picture
   *
   * @param {File} file
   */
  handleOnChangeInputFile = (file) => {
    const reader = new FileReader();

    const authorizedTypes = [
      'image/jpeg',
      'image/png',
      'image/gif',
    ];

    if (!authorizedTypes.includes(file.type)) {
      this.setState({ error: true });
    } else {
      reader.onload = () => this.setState({ src: reader.result, displayCropper: true, error: false });

      reader.readAsDataURL(file);
    }
  };

  /**
   * Get image cropped and send it to the parent
   */
  handleClickSave = () => {
    const { props: { onSave, objectId }, cropperElement, toggleModal } = this;

    if (typeof cropperElement.getCroppedCanvas() === 'undefined') {
      return false;
    }

    // Save the image cropped
    const imageCropped = cropperElement.getCroppedCanvas().toDataURL();

    this.setState({ src: imageCropped });

    // Close the modal and send to the parent the new image cropped
    return toggleModal(() => {
      this.setState({ displayCropper: false }, () => {
        const file = dataURItoFile(imageCropped);
        const s3Url = S3.getS3Url(file, 'user', objectId);

        s3Url.then((url) => {
          onSave(url);
        });
      });
    });
  };

  /**
   * Handle close modal
   */
  handleClose = () => {
    const { props: { src }, toggleModal } = this;

    toggleModal(() => {
      this.setState({ displayCropper: false, src });
    });
  };

  /**
   * Handle close modal
   */
  handleInputFileClick = () => {
    this.inputElement.handleOnClick();
  }

  handleOnDragEnter = () => {
    this.setState({
      dragging: true,
    });
  }

  handleOnDragLeave = () => {
    this.setState({
      dragging: false,
    });
  }

  handleOnDrop = (file) => {
    this.handleOnChangeInputFile(file);
  }

  /**
   * Render the modal footer with buttons for upload or save
   */
  renderActions = () => {
    const {
      state: { displayCropper },
      props: { children },
      handleOnChangeInputFile, handleClickSave, handleClose,
    } = this;

    return (
      <div className={styles.btnContainer}>
        {/* BTN for upload an image */}
        <If condition={!displayCropper}>
          <InputFile
            onChange={handleOnChangeInputFile}
            ref={(input) => { this.inputElement = input; }}
            accept=".png,.jpeg,.gif,.jpg"
          >
            <div className={styles.children}>{ children }</div>
          </InputFile>
        </If>

        {/* BTN(s) for save or cancel the crop */}
        <If condition={displayCropper}>
          <div className={styles.cropperActions}>
            <ButtonRound variant="border" onClick={handleClose}>
              <Icon name="close" style={{ width: '12px' }} />
            </ButtonRound>
            <ButtonRound variant="fill" onClick={handleClickSave}>
              <Icon name="success" />
            </ButtonRound>
          </div>
        </If>
      </div>
    );
  };

  /**
   * Render the children and listen onClick props for open the modal
   */
  renderChildrenForOpenModal = () => {
    const { props: { children }, openModal } = this;

    return React.cloneElement(children, { onClick: openModal });
  };

  /**
   * Render
   */
  render() {
    const {
      props: { display, onRemove, size, user, intl },
      state: { src, modalIsVisible, displayCropper, dragging, error },
      handleClose, renderActions, renderChildrenForOpenModal, handleInputFileClick,
      handleOnDragEnter, handleOnDrop, handleOnDragLeave,
    } = this;

    const avatarInlineContainerCSS = (dragging) ? styles.activeAvatarInlineContainer : styles.avatarInlineContainer;

    return (
      <If condition={display === 'modal'}>
        <Then>
          <>
            {/* Button for open the modal and edit his avatar */}
            { renderChildrenForOpenModal() }

            {/* Modal */}
            <Modal
              title={intl.formatMessage(messages.profilePicture)}
              visible={modalIsVisible}
              onCancel={handleClose}
              footer={renderActions()}
              wrapClassName={styles.modalContainer}
            >
              {/* Avatar */}
              <If condition={!displayCropper}>
                <AvatarPreview
                  src={src}
                  className={styles.avatarPreview}
                  context="edit"
                  size={size || 130}
                />

                <a
                  role="button"
                  tabIndex="0"
                  onClick={(e) => onRemove ? onRemove(e) : null}
                  className="remove-picture"
                >
                  <Icon name="refuse" className="ico-remove-picture" />
                </a>
              </If>

              {/* Cropper */}
              <If condition={displayCropper}>
                <div className={styles.cropperContainer}>
                  <Cropper
                    ref={(cropper) => { this.cropperElement = cropper; }}
                    src={src}
                    aspectRatio={1}
                    autoCropArea={1}
                    viewMode={1}
                    dragMode="move"
                    zoomable={false}
                  />
                </div>
              </If>
            </Modal>
          </>
        </Then>
        <Else>
          <div className={styles.inlineContainer}>
            {/* Cropper */}
            <If condition={displayCropper}>
              <div className={styles.cropperContainer}>
                <Cropper
                  ref={(cropper) => { this.cropperElement = cropper; }}
                  src={src}
                  aspectRatio={1}
                  autoCropArea={1}
                  viewMode={1}
                  dragMode="move"
                  zoomable={false}
                />
              </div>
            </If>

            {/* Avatar */}
            <div className={avatarInlineContainerCSS}>
              <If condition={!displayCropper}>
                <DropZone
                  handleDragEnter={handleOnDragEnter}
                  handleDragLeave={handleOnDragLeave}
                  handleDrop={handleOnDrop}
                >
                  <InputFile
                    onChange={this.handleOnChangeInputFile}
                    ref={(input) => { this.inputElement = input; }}
                    accept=".png,.jpeg,.gif,.jpg"
                  >
                    <AvatarPreview
                      src={src}
                      className={styles.avatarPreview}
                      defaultIcon="photo"
                      context="edit"
                      onClick={handleInputFileClick}
                      user={user}
                      size={size || 130}
                    />
                  </InputFile>

                  {!isEmpty(src) ? (
                    <a
                      role="button"
                      tabIndex="0"
                      onClick={(e) => onRemove(e)}
                      className="remove-picture"
                    >
                      <Icon name="refuse" className="ico-remove-picture" />
                    </a>
                  ) : ''}
                </DropZone>
              </If>

              { renderActions() }
            </div>
            {error ? (<div className={styles.error}>{intl.formatMessage(messages.errorImgFormat)}</div>) : ''}
          </div>
        </Else>
      </If>
    );
  }
}

export default injectIntl(AvatarEditorDnd);
