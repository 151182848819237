import * as React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { getEventPhases, isPhaseInState } from "@/store/availableActions/utils/event";
import { PHASES, PHASE_STATE } from "@/store/availableActions/utils/const";
import { trackRecruiterClickedViewKeyDates } from '@/utils/analytics';
import { isEventInHunting } from '@/store/availableActions/utils/event/isEventInHunting';

export default {
  isAvailable: [
    [
      {
        phase: PHASES.HUNT,
        state: PHASE_STATE.IS_IN_PROGRESS
      },
      {
        phase: PHASES.JOBFAIR,
        state: PHASE_STATE.IS_UPCOMING,
      },
    ],
    [
      {
        phase: PHASES.HUNT,
        state: PHASE_STATE.IS_IN_PROGRESS
      },
      {
        phase: PHASES.JOBFAIR,
        state: PHASE_STATE.IS_IN_PROGRESS,
      },
    ],
    [
      {
        phase: PHASES.JOBFAIR,
        state: PHASE_STATE.IS_IN_PROGRESS
      },
      {
        phase: PHASES.INTERACTIONS_CLOSING,
        state: PHASE_STATE.IS_UPCOMING
      }
    ],
    [
      {
        phase: PHASES.JOBFAIR,
        state: PHASE_STATE.IS_UPCOMING
      },
      {
        phase: PHASES.INTERACTIONS_CLOSING,
        state: PHASE_STATE.IS_UPCOMING
      }
    ],
  ],
  generateActions: ({ currentEvent, authUser }) => {
    const event = currentEvent?.toJS();
    const user = authUser?.toJS();
    const eventPhases = getEventPhases(event);

    return {
      isEnabled: () => isPhaseInState(eventPhases, PHASES.INTERACTIONS_CLOSING, PHASE_STATE.IS_UPCOMING),
      isHidden: () => !isEventInHunting(eventPhases),
      getTitle: (intl, messages, eventSlug) => {
        const isEnabled = isPhaseInState(eventPhases, PHASES.INTERACTIONS_CLOSING, PHASE_STATE.IS_UPCOMING);

        if (isEnabled) {
          return intl.formatMessage(messages.appointmentPropose)
        }

        return (
          <>
            <FormattedHTMLMessage
              id="recruiter.participantsList.appointment.propose.disabled"
            />
            <a
              href={`${document.location.origin}/${eventSlug}/recruiter/keydates`}
              target="_blank"
              onClick={() => trackRecruiterClickedViewKeyDates({ authUser: user })}
            >
              <FormattedMessage id="see.keyDates" />
            </a>
          </>
        )
      }
    }
  }
}
