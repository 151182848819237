import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Modal, Select } from 'antd';
import { injectIntl } from 'react-intl';

// Style
import { getLabel } from '@/components/Corners/utils/label';
import styles from '../../../../styles.less';

// Antd
const { Option } = Select;

/**
 * DefaultCriteriaModal
 */
class DefaultCriteriaModal extends React.PureComponent {
  static propTypes = {
    isVisible: PropTypes.bool,
    defaultCriteria: PropTypes.object,
    onOk: PropTypes.func,
    onCancel: PropTypes.func,
    eventCriteria: PropTypes.array,
    intl: PropTypes.func,
  };

  state = {
    criteriaSelected: [],
  };

  /**
   * Handle onOk modal
   */
  handleOnOk = () => {
    const {
      props: { onOk },
      state: { criteriaSelected },
    } = this;

    onOk(criteriaSelected);
  };

  /**
   * Handle select change
   *
   * @param {Array} criteriaSelected
   */
  handleOnChange = (criteriaSelected) => {
    this.setState({ criteriaSelected });
  };

  getCriterionLabel = (criterion) => {
    const { props: { intl } } = this;

    return intl.formatMessage({ id: `owner.settings.CriteriaTable.addChoices.modal.options[${criterion.get('key')}]` })
  }

  /**
   * Render
   */
  render() {
    const {
      handleOnOk, handleOnChange, getCriterionLabel,
      props: { isVisible, onCancel, defaultCriteria, eventCriteria, intl },
    } = this;


    return (
      <Modal
        title={intl.formatMessage({ id: 'owner.settings.criteriaTable.addChoices.modal.title' })}
        visible={isVisible}
        onOk={handleOnOk}
        onCancel={onCancel}
        cancelText={intl.formatMessage({ id: 'owner.settings.criteriaTable.addChoices.cancel' })}
        destroyOnClose
      >
        <p>{intl.formatMessage({ id: 'owner.settings.criteriaTable.addChoices.modal.paragraph' })}</p>

        <Select
          style={{ width: '100%', marginTop: 20 }}
          placeholder={intl.formatMessage({ id: 'owner.settings.criteriaTable.addChoices.modal.placeholder' })}
          onChange={handleOnChange}
        >
          {
            defaultCriteria
              .filter((criterion) => !criterion.get('deleted'))
              .map((criterion) => {
                const isInEvent = !isEmpty(eventCriteria.find((eventCriterion) => eventCriterion.get('key') === criterion.get('key')));
                return (
                  <Option
                    disabled={isInEvent}
                    key={criterion.get('_id')}
                    value={criterion}
                    className={isInEvent ? styles.disableOption : ''}
                  >{getCriterionLabel(criterion)}</Option>
                );
              })
          }
        </Select>
      </Modal>
    );
  }
}

export default injectIntl(DefaultCriteriaModal);
