import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { Form, Select as SelectAntd } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { number, object } from 'prop-types';
import Select from '@/components/Form/Select';
import { Mandatory } from '@/components/Form';

const { Option } = SelectAntd;
const FormItem = Form.Item;

export const EndYearFormation = ({ form, education, order }) => {
  const t = useFormatMessage();
  const selectedStartYear = form.getFieldValue(`educations.${order}.startYear`);
  const selectedEndYear = form.getFieldValue(`educations.${order}.endYear`);

  const endYears = changeEndYears();
  if (!isEmpty(selectedEndYear) && parseInt(selectedEndYear, 10) < parseInt(selectedStartYear, 10)) {
    form.setFieldsValue({ [`educations.${order}.endYear`]: '' });
  }
  function changeEndYears () {
    const currentYear = new Date().getFullYear() + 15;
    const yearList = [];

    for (let i = currentYear; i >= currentYear - 60; i -= 1) {
      yearList.push(i);
    }

    return yearList;
  }

  return (
    <>
      <FormItem>
        {form.getFieldDecorator(`educations.${order}.endYear`, {
          initialValue: education?.endAt ? moment(education.endAt).format('YYYY') : '',
          rules: [
            { required: !selectedStartYear, message: t({ id: 'form.error.required' }) },
          ],
        })(
          <Select
            disabled={!selectedStartYear}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            placeholder={<Mandatory>{t({ id: 'endDate' })}</Mandatory>}
          >
            {
              endYears
              .filter((year) => isEmpty(selectedStartYear) || parseInt(selectedStartYear, 10) <= year)
              .map((year) => (<Option key={year}>{year}</Option>))
            }
          </Select>
        )}
      </FormItem>
    </>
  )
}

EndYearFormation.propTypes = {
  form: object,
  education: object,
  order: number
}
