import React from 'react';
import { Button } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { object, string } from 'prop-types';

export const ShowMoreButton = ({ entityInfiniteQuery, label, variant }) => {
  const t = useFormatMessage();

  // If there is no more data to fetch, we hide the button, do not return null otherwise the layout will display a scroll
  const isHidden = !entityInfiniteQuery?.hasNextPage ? 'hidden' : '';

  return (
    <div className={`flex justify-center w-full ${isHidden}`}>
      <Button
        loading={entityInfiniteQuery.isFetching}
        onClick={() => entityInfiniteQuery.fetchNextPage()}
        variant={variant || 'fill'}
      >
        {label || t({ id: 'seeMore' })}
      </Button>
    </div>
  )
}

ShowMoreButton.propTypes = {
  entityInfiniteQuery: object,
  label: string,
  variant: string
}
