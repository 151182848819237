import { number } from 'prop-types';
import * as React from 'react';
import { IconBriefcase } from '@seekube-tech/ui-kit';
import { FormattedHTMLMessage } from 'react-intl';
import styles from '../styles.less';

function ApplicationsNumber({ countApplications }) {
  return countApplications ?
    <span key="applicationsNumber" className={countApplications > 0 ? styles.highlight : ''}>
      <IconBriefcase size={16} />
      <FormattedHTMLMessage values={{ count: countApplications }} id="events.stats.applications2" />
    </span> : null
}

ApplicationsNumber.propTypes = {
  countApplications: number
}

export default ApplicationsNumber;
