import {useQuery} from 'react-query';
import request from '@/utils/request';

export function useGetShouldRefreshFeeds({ date, ...props }) {
  const requestUrl = `${process.env.FRONT_API_URL}/statistics/kpi/feeds-refresh?date=${date}`;
  const queryFn = () => request(requestUrl, {
    method: 'GET',
  });

  return useQuery({
    queryKey: ['refreshFeed'],
    queryFn,
    refetchOnWindowFocus: false,
    ...props,
  });
}
