import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { MemberColumn } from '../../../../components/memberColumn';
import { ActionColumn } from '../../../../components/actionColumn';
import { ParticipationColumn } from '../../../../components/participationColumn';
import styles from './styles.less';

export default () => (
   [
    {
      title: <FormattedMessage id="recruiters" values={{ count: 2 }} />,
      dataIndex: 'user',
      key: 'user',
      className: 'membersTable',
      width: 350,
      render: (text, { user, authUser }) => <MemberColumn record={user} authUser={authUser} />,
    },
    {
      title: <FormattedMessage id="last.connection" />,
      dataIndex: 'lastLogin',
      key: 'lastLogin',
      width: 90,
      render: (lastLogin) => lastLogin && <span role="button" tabIndex={0}>{(moment(lastLogin).format('DD/MM/YY') || 0)}</span>,
    },
    {
      title: <FormattedMessage id="email" />,
      dataIndex: 'physical',
      key: 'username',
      className: 'emailTable',
      render: (username) => <span className={styles.highlight} role="button" tabIndex={0}><span>{username}</span></span>,
    },
    {
      title: <FormattedMessage id="event.status.title" />,
      dataIndex: 'role',
      key: 'role',
      width: 120,
      render: (role) => <FormattedMessage id={`event.recruiter.preparation.team.${role}`} />,
    },
    {
      title: <span>JD</span>,
      dataIndex: 'participation',
      key: 'participation',
      className: 'participationTable',
      width: 50,
      render: (text, { user }) => <ParticipationColumn record={user} />,
    },
    {
      title: <FormattedMessage id="action.pl" values={{ count: 2 }} />,
      dataIndex: 'tool',
      key: 'tool',
      className: 'actionTable',
      // width: 20,
      render: (text, { actions, authUser, context, user }) => {
        const {
          handleOpenEdit,
          handleOpenAddJobdating,
          handleOpenEditPassword,
          onToolsClick,
          handleOpenDeleteUser,
          handleSwitchRole,
          handleSendInvitation,
        } = actions || {};
        return (
          <span role="button" tabIndex={0}>
            <ActionColumn
              context={context}
              record={user}
              authUser={authUser}
              onEdit={handleOpenEdit}
              onAddJobdating={handleOpenAddJobdating}
              onEditPassword={handleOpenEditPassword}
              onToolsClick={onToolsClick}
              onDeleteUser={handleOpenDeleteUser}
              onSwitchRole={handleSwitchRole}
              onSendInvitation={handleSendInvitation}
            />
          </span>
        );
      },
    },
  ]
)
