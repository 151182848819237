import React, { useEffect } from 'react';
import { object, func } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { Switch, Route, withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { replace } from 'connected-react-router';
import { authSelectors } from '@/store/auth';
import { eventSelectors } from '@/store/event';
import { toJS } from '@/utils';
import { getId } from '@/utils/global';
import { withLayout } from '@/containers/Layout';
import { withAuth } from '@/containers/RequiredAuth';
import ConferencePreview from '@/scenes/Client/scenes/Conferences/components/EditLivetalk/components/ConferencePreview/ConferencePreview';
import { CONTEXT_ROLES } from '../../utils/constants';
import NavOwner from './components/NavOwner';
import OwnerDashboardPage from './scenes/Dashboard';
import OwnerOnBoardingPage from './scenes/OnBoarding';
import OwnerSettingsPage from './scenes/Setting';
import OwnerCandidatesPage from './scenes/Candidate';
import OwnerStatisticsPage from './scenes/Statistic';
import OwnerOfferPage from './scenes/Offer';
import ConferencesPage from './scenes/Conferences';
import OwnerExponentsPage from './scenes/Exponents';
import OwnerExponentOffersPage from './scenes/Exponents/Offers';
import OwnerStandPage from './scenes/Stand/Edit';
import OwnerStandPreview from './scenes/Stand';
import Informal1to1Page from './scenes/Informal1to1';
import KeyDates from '../../components/KeyDates';

import OfferPreview from '../Candidate/scenes/JobDating/scenes/Jobs/scenes/Job/components/OfferPreview';
import ConferenceParticipants from '../../../../containers/ConferenceParticipants';
import styles from './styles.less';

const LivetalkAll = () => (<ConferenceParticipants context="all" userType="owner" />);
const LivetalkPresent = () => (<ConferenceParticipants context="present" userType="owner" />);

const OwnerScene = (props) => { // eslint-disable-line
  const { match, location, authUser, event, replace } = props;

  useEffect(() => {
    if (!authUser.isAdmin && (
      isEmpty(event.planners._users.find((user) => getId(user) === getId(authUser))) &&
      isEmpty(event.planners._organizations.find((organization) => getId(organization) === getId(authUser._currentOrganization)))
    )) {
      replace('/');
    } else if (match.isExact) {
      replace(`${match.url}/dashboard`);
    }

    const requestUrl = `${process.env.FRONT_API_URL}/events/${match.params.eventSlug}/statistics/refresh?apiKey=41a1289e-fee1-430e-a849-5b2b7ed23f1a`;

    if (process.env.NODE_ENV === 'production') { fetch(requestUrl).then(); }
  }, []);


  return (
    <>
      {!window.location.pathname.includes('/matching/preview') ? (
        <NavOwner authUser={authUser} match={match} {...event} />
      ) : ''}
      <div className={location.pathname.search('/reports/candidates') === -1 ? styles.defaultSize : ''}>
        <Switch>
          <Route path={`${match.path}/dashboard`} component={OwnerDashboardPage} />
          <Route path={`${match.path}/exponents/:exponentId/offers`} render={(routeProps) => <OwnerExponentOffersPage  {...props} {...routeProps} event={event} />} />
          <Route path={`${match.path}/exponents`} component={OwnerOnBoardingPage} />
          <Route path={`${match.path}/candidates`} component={OwnerCandidatesPage} />
          <Route path={`${match.path}/statistics`} component={OwnerStatisticsPage} />
          <Route path={`${match.path}/conferences/:conferenceID/all`} component={LivetalkAll} />
          <Route path={`${match.path}/conferences/:conferenceID/present`} component={LivetalkPresent} />
          <Route path={`${match.path}/conferences/:conferenceID/preview`} render={() => <ConferencePreview authUser={authUser} />} />
          <Route path={`${match.path}/conferences`} render={(routeProps) => <ConferencesPage {...props} {...routeProps} />} />
          <Route path={`${match.path}/offers/:jobID/preview`} render={(routeProps) => <OfferPreview {...routeProps} context="OfferAdmin" isPreview />} />
          <Route path={`${match.path}/offers`} component={OwnerOfferPage} />
          <Route path={`${match.path}/stands/:exponentID/edit`} component={OwnerStandPage} />,
          <Route path={`${match.path}/stands/:exponentID/preview`} component={OwnerStandPreview} />
          <Route path={`${match.path}/stands`} component={OwnerExponentsPage} />
          <Route path={`${match.path}/informal1to1`} component={Informal1to1Page} />
          <Route path={`${match.path}/settings`} render={(routeProps) => <OwnerSettingsPage {...props} {...routeProps} event={event} />} />
          <Route path={`${match.path}/keydates`} render={() => <KeyDates context={CONTEXT_ROLES.OWNER} />} />
        </Switch>
      </div>
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser,
  event: eventSelectors.getCurrentEvent,
});

const mapDispatchToProps = {
  replace,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

OwnerScene.propTypes = {
  match: object,
  event: object,
  replace: func,
  push: func,
  authUser: object,
  location: object,
};

export default compose(
  withConnect,
  withRouter,
  withLayout({
    size: 'full',
    navType: 'owner',
    withHeader: !(window.location.pathname.includes('/owner') &&
      (window.location.pathname.includes('/export') || window.location.pathname.includes('/matching/preview'))
    ),
  }),
  withAuth(),
)(toJS(OwnerScene));
