import React from 'react';
import { bool, func, oneOf, object } from 'prop-types';
import { injectIntl } from 'react-intl';
import Modal from '@/components/Modal';
import { RegexIdFormat, CriteriaLabel } from './components';
// Styles & Translations
import styles from '../../styles.less';

class SpecificModal extends React.PureComponent {
  static propTypes = {
    isVisible: bool,
    criterion: object,
    type: oneOf(['regexFormat', 'criteriaLabel']),
    onOk: func,
    onCancel: func,
    intl: object
  };

  state = {
    inputValue: '',
  };

  handleInputChange = (inputValue) => {
    this.setState({ inputValue });
  };


  handleOnOk = () => {
    const { state: { inputValue }, props: { onOk, onCancel } } = this;

    onOk({ keysValues: inputValue, callback: onCancel });
  };

  renderSpecificCriteria = () => {
    const { props: { criterion, type }, handleInputChange } = this;

    if (criterion) {
      switch (type) {
        case 'regexFormat': return <RegexIdFormat defaultValue={criterion.value} onChange={handleInputChange} />;
        case 'criteriaLabel': return <CriteriaLabel criterion={criterion} onChange={handleInputChange} />;
        default: return null;
      }
    }
    return null;
  };

  render() {
    const {
      handleOnOk,
      props: { isVisible, onCancel },
    } = this;

    return (
      <Modal
        title={this.props.intl.formatMessage({ id: 'owner.settings.criteriaTable.specificModal.title' })}
        visible={isVisible}
        onOk={handleOnOk}
        onCancel={onCancel}
        cancelText={this.props.intl.formatMessage({ id: 'cancel' })}
      >
        <div className={styles.textAreaContainer}>
          {this.renderSpecificCriteria()}
        </div>
      </Modal>
    );
  }
}

export default injectIntl(SpecificModal);
