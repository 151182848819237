import { defineMessages } from 'react-intl';

export default defineMessages({
  menuGeneral: {
    id: 'general',
  },
  menuPassword: {
    id: 'form.password',
  },
  recruiters: {
    id: 'recruiters',
  },
  opportunities: {
    id: 'opportunities',
  },
  baseline: {
    id: 'event.candidate.onboarding.baseline',
  },
  oops: {
    id: 'oups',
  },
  noResult: {
    id: 'event.candidate.onboarding.noResult',
  },
});
