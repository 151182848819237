import React from 'react';
import { func, number, shape, array, object, string } from 'prop-types';
import { If, Then, Else } from 'react-if';
import { isEmpty, isEqual, isUndefined } from 'lodash';
import { injectIntl } from 'react-intl';

// components
import { Form, Divider, Select as SelectAntd } from 'antd/lib/index';
import { Button, IconTrash2, IconPlusCircle } from '@seekube-tech/ui-kit';
import Select from '@/components/Form/Select/index';
import InputMaterial from '@/components/Input/InputMaterial/index';
import { InputOrganizationsSuggest } from '@/components/Input';
import Checkbox from '@/components/Checkbox';
import Separator from '@/components/Separator';
import messages from '../messages';
import styles from './styles.less';

const { Option } = SelectAntd;
const FormItem = Form.Item;

const hasErrors = (fieldsError) => Object.keys(fieldsError).some((field) => fieldsError[field]);

class PositionForm extends React.PureComponent {
  static propTypes = {
    handleAddExperience: func,
    handleDeleteExperience: func,
    order: number,
    experiencesCount: number,
    experience: object,
    form: shape({
      getFieldDecorator: func,
      getFieldError: func,
      isFieldTouched: func,
    }),
    beginYears: array,
    endYears: array,
    intl: object,
    months: array,
    setFormIsModify: func,
    context: string,
    user: object,
  };

  constructor(props) {
    super(props);

    this.state = {
      disableEndInput: props.experience ? props.experience.currentExperience : false,
      endDateError: false,
      initialValues: {},
    };

    const { setFormIsModify, experience, order, form } = props;
    const { getFieldValue } = form;

    let formIsModify = false;

    if (!isEmpty(experience)) {
      formIsModify = !isEqual(experience.company, getFieldValue(`company${order}`)) || !isEqual(experience.title, getFieldValue(`title${order}`)) ||
        !isEqual(experience.startMonth, getFieldValue(`startMonthExperience${order}`)) || !isEqual(experience.startYear, getFieldValue(`startYearExperience${order}`)) ||
        (!isEmpty(experience.endAt) &&
          (!isEqual(experience.endMonth, getFieldValue(`endMonthExperience${order}`)) ||
            !isEqual(experience.endYear, getFieldValue(`endYearExperience${order}`))
          )
        );
    } else {
      formIsModify = !hasErrors(form.getFieldsError());
    }


    setFormIsModify(order, formIsModify);
  }

  componentDidMount() {
    const { form: { validateFields }, experience } = this.props;

    this.setExperienceField(experience || {});

    validateFields();
  }

  componentWillReceiveProps({ experience, months, order, form, setFormIsModify, user, experiencesCount }) {
    let formIsModify = false;
    const { getFieldValue } = form;

    if (!isEmpty(experience) && !isEmpty(user)) {
      formIsModify = !isEqual(experience.company, getFieldValue(`company${order}`)) || !isEqual(experience.title, getFieldValue(`title${order}`)) ||
        !isEqual(experience.startMonth || experience.startMonthExperience, getFieldValue(`startMonthExperience${order}`)) ||
        !isEqual(experience.startYear || experience.startYearExperience, getFieldValue(`startYearExperience${order}`)) ||
        (!isEmpty(experience.endAt) &&
          (!isEqual((experience.endMonth || experience.endMonthExperience), getFieldValue(`endMonthExperience${order}`)) ||
            !isEqual((experience.endYear || experience.endYearExperience), getFieldValue(`endYearExperience${order}`))
          )
        ) ||
        (isUndefined(experience.endAt) &&
          (!isEmpty(getFieldValue(`endMonthExperience${order}`) || !isEmpty(getFieldValue(`endYearExperience${order}`))))
        ) || (!isUndefined(this.state.disableEndInput) && !isEqual(this.state.disableEndInput, experience.currentExperience || experience.isCurrent)) ||
        user.positions.length !== experiencesCount;
    } else {
      formIsModify = !hasErrors(form.getFieldsError());
    }

    setFormIsModify(order, formIsModify);

    if (!isEmpty(experience) && !isEmpty(months) && !isEqual(experience, this.props.experience)) {
      this.setExperienceField(experience);
    }

    this.forceUpdate();
  }

  componentWillUnmount() {
    this.props.form.validateFields();
  }

  setExperienceField = (experience) => {
    const { props: { order, months, form: { setFieldsValue } } } = this;

    const values = {};
    values[`company${order}`] = experience ? experience.company : '';
    values[`title${order}`] = experience ? experience.title : '';
    values[`startMonthExperience${order}`] = experience && experience.beginAt ? months[new Date(experience.beginAt).getMonth()] : '';
    values[`startYearExperience${order}`] = experience && experience.beginAt ? new Date(experience.beginAt).getFullYear() : '';
    values[`endMonthExperience${order}`] = experience && experience.endAt ? months[new Date(experience.endAt).getMonth()] : '';
    values[`endYearExperience${order}`] = experience && experience.endAt ? new Date(experience.endAt).getFullYear() : '';
    setFieldsValue(values);

    this.setState({ disableEndInput: experience ? (experience.currentExperience || experience.isCurrent) : false });
  };

  handleCurrentExperienceChange = (e) => {
    const { form: { setFieldsValue, validateFields }, order } = this.props;

    const resetDates = {};
    resetDates[`endMonthExperience${order}`] = '';
    resetDates[`endYearExperience${order}`] = '';
    resetDates[`isCurrentExperience${order}`] = e.target.checked;

    setFieldsValue(resetDates);

    this.setState({
      disableEndInput: e.target.checked,
    }, () => validateFields());
  };

  handleOnDeleteExperience = () => {
    const {
      props: { handleDeleteExperience, experiencesCount, order },
      setExperienceField,
    } = this;

    if (experiencesCount === 1) {
      setExperienceField();
    } else {
      handleDeleteExperience(order);
    }
  };

  render() {
    const {
      handleCurrentExperienceChange, handleOnDeleteExperience,
      props: {
        handleAddExperience,
        handleDeleteExperience,
        experiencesCount,
        order,
        beginYears,
        endYears,
        intl,
        months,
        experience,
        context,
        user,
        form: { getFieldDecorator, getFieldValue, setFieldsValue },
      },
      state: { disableEndInput, endDateError },
    } = this;

    const selectedStartMonth = getFieldValue(`startMonthExperience${order}`);
    const selectedEndMonth = getFieldValue(`endMonthExperience${order}`);
    const selectedStartYear = getFieldValue(`startYearExperience${order}`);
    const selectedEndYear = getFieldValue(`endYearExperience${order}`);
    const selectedStartMonthIndex = selectedStartMonth ? (
      months.findIndex((month) => month === selectedStartMonth)
    ) : null;
    const selectedEndMonthIndex = selectedEndMonth ? (
      months.findIndex((month) => month === selectedEndMonth)
    ) : null;

    if (!isEmpty(selectedEndYear) && parseInt(selectedEndYear, 10) < parseInt(selectedStartYear, 10)) {
      if (order === 0) {
        setFieldsValue({ endYearExperience0: '' });
      } else {
        setFieldsValue({ endYearExperience1: '' });
      }
    }

    if (!isEmpty(selectedStartYear) && !isEmpty(selectedEndYear) && selectedStartYear >= selectedEndYear && !isEmpty(selectedEndMonth) && selectedStartMonthIndex > selectedEndMonthIndex) {
      if (order === 0) {
        setFieldsValue({ endMonthExperience0: '' });
      } else {
        setFieldsValue({ endMonthExperience1: '' });
      }
    }
    const formIsModify = !isEmpty(getFieldValue(`company${order}`)) || !isEmpty(getFieldValue(`title${order}`) ||
      !isEmpty(getFieldValue(`startMonthExperience${order}`)) || !isEmpty(getFieldValue(`startYearExperience${order}`)) ||
      !isEmpty(getFieldValue(`endMonthExperience${order}`)) || !isEmpty(getFieldValue(`endYearExperience${order}`)));
    return (
      <>
        <FormItem>
          {getFieldDecorator(`company${order}`, {
            initialValue: experience ? experience.company : '',
            rules: [{ required: formIsModify, message: intl.formatMessage(messages.requiredNamePosition) }],
          })(
            <InputOrganizationsSuggest
              initialValue={experience?.company || ''}
              id={`company${order}`}
              label={intl.formatMessage(messages.experiencePositionOrganization)}
              placeholder={<span>{intl.formatMessage(messages.experiencePositionOrganization)}</span>}
              addOnIcon="legal"
              params={{ type: 'company' }}
              onChange={() => {
                this.props.form.validateFields([`company${order}`, `title${order}`, `startMonthExperience${order}`, `startYearExperience${order}`, `endMonthExperience${order}`, `endYearExperience${order}`], { force: true });
              }}
            />
          )}
        </FormItem>

        <FormItem>
          {getFieldDecorator(`title${order}`, {
            initialValue: experience ? experience.title : '',
            rules: [{ required: formIsModify, message: intl.formatMessage(messages.requiredTitlePosition) }],
          })(
            <InputMaterial
              addOnIcon="offers"
              label={intl.formatMessage(messages.experiencePositionTitle)}
              placeholder={intl.formatMessage(messages.experiencePositionTitle)}
              type="text"
              onChange={() => {
                this.props.form.validateFields([`company${order}`, `title${order}`], { force: true });
              }}
            />
          )}
        </FormItem>

        <div className="flex-row-wrapper positionFormDates with-space">
          <FormItem>
            <div className="date-label">{intl.formatMessage(messages.startDate)}</div>
            {getFieldDecorator(`startMonthExperience${order}`, {
              initialValue: experience && experience.beginAt ? (`${months[new Date(experience.beginAt).getMonth()]}` || '') : '',
              rules: [{ required: formIsModify, message: intl.formatMessage(messages.requiredStartMonth) }],
            })(
              <Select
                placeholder={intl.formatMessage(messages.month)}
                label={intl.formatMessage(messages.month)}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                dropdownClassName={styles.dropdownWithoutEllipsis}
                disabled={isEmpty(getFieldValue(`company${order}`))}
              >
                {
                  months.map((month) => (<Option key={month}>{month}</Option>))
                }
              </Select>
            )}
          </FormItem>
          <FormItem>
            <Separator height={30} />
            {getFieldDecorator(`startYearExperience${order}`, {
              initialValue: experience ? (`${experience.startYear}` || '') : '',
              rules: [{ required: formIsModify, message: intl.formatMessage(messages.requiredStartYear) }],
            })(
              <Select
                placeholder={intl.formatMessage(messages.year)}
                label={intl.formatMessage(messages.year)}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                disabled={isEmpty(getFieldValue(`company${order}`))}
              >
                {
                  beginYears.map((year) => (<Option key={year}>{year}</Option>))
                }
              </Select>
            )}
          </FormItem>

          <FormItem>
            <div className="date-label">{intl.formatMessage(messages.endDate)}</div>
            {getFieldDecorator(`endMonthExperience${order}`, {
              initialValue: experience && experience.endAt ? (`${months[new Date(experience.endAt).getMonth()]}` || '') : '',
              rules: [{ required: !disableEndInput && formIsModify, message: intl.formatMessage(messages.requiredEndMonth) }],
            })(
              <Select
                disabled={disableEndInput || isEmpty(getFieldValue(`company${order}`))}
                placeholder={intl.formatMessage(messages.month)}
                label={intl.formatMessage(messages.month)}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                dropdownClassName={styles.dropdownWithoutEllipsis}
              >
                {isEmpty(selectedStartYear) || selectedStartYear !== selectedEndYear ? (
                  months.map((month) => (<Option key={month}>{month}</Option>))
                ) : (
                  months
                  .filter((month, index) => (
                    !selectedStartMonthIndex || index >= selectedStartMonthIndex
                  ))
                  .map((month) => (<Option key={month} >{month}</Option>))
                )}
              </Select>
            )}
          </FormItem>

          <FormItem>
            <Separator height={30} />
            {getFieldDecorator(`endYearExperience${order}`, {
              initialValue: experience && experience.endAt ? (`${experience.endYear}` || '') : '',
              rules: [{ required: !disableEndInput && formIsModify, message: intl.formatMessage(messages.requiredEndYear) }],
            })(
              <Select
                disabled={disableEndInput || isEmpty(getFieldValue(`company${order}`))}
                placeholder={intl.formatMessage(messages.year)}
                label={intl.formatMessage(messages.year)}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                {
                  endYears
                  .filter((year) => isEmpty(selectedStartYear) || parseInt(selectedStartYear, 10) <= year)
                  .map((year) => (<Option key={year}>{year}</Option>))
                }
              </Select>
            )}
          </FormItem>
        </div>

        <div className="add-container">
          <FormItem className="add-zone add-zone--left">
            <Checkbox
              onChange={handleCurrentExperienceChange}
              checked={disableEndInput}
            >
              {intl.formatMessage(messages.experiencePositionCurrent)}
            </Checkbox>
          </FormItem>

          <If condition={endDateError}>
            <div className="signup-dates-error-container">{intl.formatMessage(messages.endDateError0)}</div>
          </If>

          {context !== 'settings' ? (
            <FormItem className="add-zone">
              <If condition={order === 0}>
                <Then>
                  <If condition={experiencesCount === 1}>
                    <Then>
                      <Button variant="tonal" imageComponentLeft={<IconPlusCircle size={16} />} role="presentation" onClick={handleAddExperience}>
                        {intl.formatMessage(messages.experienceAdd)}
                      </Button>
                    </Then>
                  </If>
                </Then>
                <Else>
                  <Button variant="tonal" color="error" imageComponentLeft={<IconTrash2 size={16} />} role="presentation" onClick={() => handleDeleteExperience(order)}>
                    {intl.formatMessage(messages.experienceDelete)}
                  </Button>
                </Else>
              </If>
            </FormItem>
          ) : (
            <FormItem className="add-zone">
              {user.positions.length > 0 || (experiencesCount > 1 && order !== 0) ? (
                <Button variant="tonal" color="error" imageComponentLeft={<IconTrash2 size={16} />} role="presentation" onClick={handleOnDeleteExperience}>
                  {intl.formatMessage(messages.experienceDelete)}
                </Button>
              ) : '' }
              {(order === 0 && experiencesCount <= 1) && (
                <div>
                  <Button className="add-experience" variant="tonal" imageComponentLeft={<IconPlusCircle size={16} />} role="presentation" onClick={handleAddExperience}>
                    {intl.formatMessage(messages.experienceAdd)}
                  </Button>
                </div>
              )}
            </FormItem>
          )}
        </div>
        {experiencesCount > 1 && order === 0 ? <Divider /> : ''}
      </>
    );
  }
}

export default injectIntl(PositionForm);
