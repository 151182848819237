import React, { Component } from 'react';
import { map, difference } from 'lodash';
import { Tree } from 'antd';
import { func, object, array } from 'prop-types';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { authSelectors } from '@/store/auth';
import { toJS } from '@/utils';
import styles from './styles.less';

const { TreeNode } = Tree;

class TreeFilter extends Component {
  static propTypes = {
    onCheckChange: func,
    treeParents: array,
    treeChildren: object,
    checkedKeys: array,
    authUser: object
  };

  state = {
    expandedKeys: [],
    autoExpandParent: true,
    checkedKeys: this.props.checkedKeys,
    selectedKeys: this.props.checkedKeys,
  };

  onExpand = (expandedKeys) => {
    this.setState({
      expandedKeys,
      autoExpandParent: false,
    });
  };

  onCheck = (checkedKeys) => {
    const { props: { treeParents, onCheckChange } } = this;

    onCheckChange(difference(checkedKeys, map(treeParents, '_id')));
    this.setState({ checkedKeys });
  };

  renderTreeNodes = (data) => {
    const { props: { authUser } } = this;

    return data.map((item) => {
      if (item.children) {
        return (
          <TreeNode className={styles.parent} title={authUser.locale === 'fr' ? item.label : item.label_en} disabled={item.disabled} key={item.key} dataRef={item}>
            {this.renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode key={item.key} title={item.html} {...item} />;
    });
  }

  render() {
    const { props: { treeChildren, treeParents } } = this;
    const tree = treeParents.map((parent) => ({ ...parent, children: treeChildren[parent.key] }));

    return (
      <Tree
        checkable
        onExpand={this.onExpand}
        expandedKeys={this.state.expandedKeys}
        autoExpandParent={this.state.autoExpandParent}
        onCheck={this.onCheck}
        checkedKeys={this.state.checkedKeys}
        selectable={false}
        selectedKeys={this.state.selectedKeys}
        className={styles.tree}
      >
        {this.renderTreeNodes(tree)}
      </Tree>
    );
  }
}


const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser,
});

const mapDispatchToProps = {};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(toJS(TreeFilter));
