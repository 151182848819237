import React, { useEffect, useState } from 'react';
import { object, func, array, bool, string, oneOf } from 'prop-types';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';
import { FormattedMessage } from 'react-intl';
import { CSSTransition } from 'react-transition-group';
import { Map } from 'immutable';
import { createStructuredSelector } from 'reselect';
import { flatten, get, isEmpty, isUndefined, map, omit } from 'lodash';
import { Col, Row } from 'antd';
import moment from 'moment';
import { Menu, Pagination } from 'antd';
import { Button as ButtonUi, ButtonRound, IconArrowLeft, IconDownload } from '@seekube-tech/ui-kit';
import { toJS } from '@/utils';



import { track } from '@/utils/analytics';

// Store
import { actionActions } from '@/store/action';
import { participantSelectors } from '@/store/participant';
import { interactionSelectors } from '@/store/interaction';
import { eventSelectors } from '@/store/event/index';
import { authSelectors } from '@/store/auth';
import { participantActions } from '@/store/participant';

import { ANALYTICS_RECRUITER } from '@/utils/constants';
import { objectToParams, queryStringToObject } from '@/utils/url';

// Components

import LoadingIndicator from '@/components/LoadingIndicator';
import Wrapper from '@/components/Wrapper';


import { Tooltip } from '@/components/Tooltip';
import Icon from '@/components/Icon';
import Separator from '@/components/Separator';
import ModalFull from '@/components/ModalFull';
import { Select } from '@/components/Form';
import ParticipantCards from '@/components/ParticipantCards';
import { CardLive } from '@/components/CardLive';

import { getCurrentRole } from '@/store/role/helpers';
import { useGetConference } from '@/queries/conferences/useGetConference';
import { useFormatMessage } from 'react-intl-hooks';
import { getParticipantResumeUrl } from '@/helpers/resume';
import DownloadAttendees from '../../scenes/Event/scenes/Owner/scenes/Livetalks/components/DownloadAttendees';
import styles from '../../scenes/Event/scenes/Recruiter/scenes/Jobdating/components/ParticipantsList/styles.less';


/**
 * Conference
 */
const ConferenceParticipants = ({
  authUser,
  event,
  context,
  history,
  match,
  pagination,
  location,
  getConferenceParticipants,
  resetParticipantsList,
  postAction,
  participants,
  highlightWords,
  interactions,
  isFetching,
  push,
  userType,
}) => {

  const t = useFormatMessage();
  const [queryParams, setQueryParams] = useState({
    page: 1,
    ...location.query,
  })
  const [modalProfileIsOpen, setModalProfileIsOpen] = useState(false);
  const [userProfile, setUserProfile] = useState()

  const conference = useGetConference({conferenceId: match.params.conferenceID}).data

  useEffect(() => {
    window.scrollTo(0, 0);

    const { page, offset, search } = getSearchParticipantsParams();

    getConferenceParticipants({
      conferenceId: match.params.conferenceID,
      context,
      offset,
      page,
      search,
      loader: true,
    });

    return resetParticipantsList();
  }, [])

  const getSearchParticipantsParams = () => {

    location.query = queryStringToObject(location.search);

    const page = location.query.page ? parseInt(location.query.page, 10) : 1;
    const offset = page === 1 ? 0 : (page * pagination.pageSize) - pagination.pageSize;
    const search = omit(location.query, ['page', 'context']);

    return {
      page,
      offset,
      search,
    };
  };

  const processQueryChange = (newParams) => {
    const location = { ...history.location };

    location.query = queryStringToObject(location.search);

    const queryMap = isEmpty(location.query) ? Map(newParams) : Map(location.query);
    const newMapParams = queryMap.merge(newParams);

    location.query = newMapParams.toJS();
    location.search = objectToParams(location.query);

    setQueryParams(location.query)

    if (!newParams.participant || newParams.participant === 'null') {
      const offset = location.query.page === 1 || location.query.page === undefined ? 0 : (location.query.page * pagination.pageSize) - pagination.pageSize;
      const search = omit(location.query, ['page', 'context']);

      getConferenceParticipants({
        conferenceId: match.params.conferenceID,
        context,
        offset,
        page: location.query.page,
        search,
        loader: true,
      });
    }

    history.push(location);
  };

  const showProfile = (participant) => {
    if (!participant) {
      return false;
    }

    const modalNodes = document.getElementsByClassName('ant-modal-wrap');

    if (modalNodes) {
      modalNodes.scrollTop = 0;
    }

    setModalProfileIsOpen(true)
-   setUserProfile({
      participant,
    })

    return true;
  };

  const handleDownload = (participant) => {
    const resumeUrl = getParticipantResumeUrl(participant, event.slug, true);

    if (resumeUrl) {
      postAction({
        actionParams: {
          name: 'RECRUITER_DOWNLOAD_CV',
          _user: authUser._id,
          _organization: authUser._currentOrganization._id,
          _event: event._id,
          participantId: participant._id,
          data: { candidate: participant._user._id, resumeUrl },
        },
      });

      track({
        name: ANALYTICS_RECRUITER.DOWNLOADED_CV,
        user: authUser,
        event,
        properties: {
          roles: getCurrentRole(authUser.roles, authUser._currentOrganization._id)?._role?.key,
        },
      });

      window.open(resumeUrl, '_blank');
    }
  };

  const renderParticipants = (participants, displayTag) => {

    if (isFetching === true) {
      return (<LoadingIndicator />);
    }

    return participants
      .filter((participant) => participant)
      .map((participant, key) => {
        const applicationHmlId = `appConferenceActions${participant._id}`;
        const interactionsClean = interactions ? interactions.filter((interaction) => get(interaction, '_candidate._id') === get(participant, '_user._id')) : [];
        const interaction = interactionsClean.find((interaction) => interaction.enable);
        const appointment = interaction ? interaction._appointment : null;
        const offers = flatten(map(interactionsClean.filter((i) => i.type === 'application'), '_offers'));

        const actions = (
          <div id={applicationHmlId}>
            <Tooltip
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              color="dark"
              placement="bottom"
              title={t({ id: 'recruiter.participantsList.download.cv' })}
            >
              <ButtonRound color="success" onClick={() => handleDownload(participant)} variant="fill">
                <IconDownload />
              </ButtonRound>
            </Tooltip>
          </div>
        );

        if (participant && !isUndefined(participant._user)) {
          const isVisited = participant.visitBy.includes(authUser._id);

          let livetag = null;

          if (displayTag) {
            livetag = participant.isPresent ? 'present' : 'noshow';
          }

          return (
            <CSSTransition
              key={`participant_${participant._id}`}
              classNames={styles.participantItem}
              timeout={{ enter: 200, exit: 300 }}
            >
              <div className={styles.participantItem}>
                <div className="cardListItem">
                  <ParticipantCards
                    key={key}
                    areInteractionsClosed={event.areInteractionsClosed}
                    isChecked={false}
                    component="medium"
                    participant={participant}
                    viewBy={authUser}
                    status="none"
                    hideCheck
                    liveTag={livetag}
                    actions={actions}
                    appointment={appointment}
                    onOpen={() => { showProfile(participant, true); }}
                    highlightWords={highlightWords || []}
                    currentUser={authUser}
                    isVisited={isVisited}
                    interactions={interactionsClean}
                    offers={offers}
                  />
                </div>
              </div>
            </CSSTransition>
          );
        }

        return null;
      });
  };

  const onChangeMenu = ({ key }) => {

    if (context !== key) {
      resetParticipantsList();

      const route = location.pathname;

      push(route.replace(`/${context}`, `/${key}`));
    }
  }

  const handleOnChangePagination = (pagination) => {
    const location = { ...history.location };

    location.query = queryStringToObject(location.search);

    processQueryChange({ page: pagination });

    window.scrollTo(0, 0);
  };

  const onCloseModal = () => {
    setModalProfileIsOpen(false)
  };

  const handleKeywordsChange = (keywords) => {
    processQueryChange({ keywords: keywords.join(','), facetKey: null, page: 1 });
  };

  if (!conference || !participants || !event) {
    return (<LoadingIndicator />);
  }

  if (conference.deleted) {
    return push('/');
  }

  let keywords = [];

  if (typeof queryParams.keywords === 'object') {
    keywords = queryParams.keywords;
  } else if (queryParams.keywords) {
    keywords = queryParams.keywords.split(',');
  }

  let openProfile = null;
  const displayTag = moment(conference.beginAt) < moment();

  if (!isEmpty(userProfile)) {
    const { participant } = userProfile;

    const participantInteractions = interactions.filter((interaction) => participant._user && interaction._candidate && interaction._candidate._id === participant._user._id);
    const interaction = interactions.find((interaction) => participant._user._id === interaction._candidate._id && !isEmpty(interaction._appointment) && (get(interaction, '_recruiter._id') === authUser._id || get(interaction, '_owner._id') === authUser._id)) || null;
    const appointment = interaction ? interaction._appointment : null;
    const applicationHmlId = `appConferenceActionsOpen${participant._id}`;
    const actions = (
      <div id={applicationHmlId}>
        <Tooltip getPopupContainer={(triggerNode) => triggerNode.parentNode} color="dark" placement="bottom" title={t({id: 'recruiter.participantsList.download.cv'})}>
          <ButtonRound variant='fill' color="success" size="large" onClick={() => handleDownload(participant)}>
            <IconDownload />
          </ButtonRound>
        </Tooltip>
      </div>
    );
    const participantOffers = flatten(map(participantInteractions.filter((i) => i.type === 'application'), '_offers'));
    let livetag = null;

    if (displayTag) {
      livetag = participant.isPresent ? 'present' : 'noshow';
    }

    openProfile = (
      <ParticipantCards
        component="big"
        participant={participant}
        // getAtionsFromContext={getActionsFromContext}
        viewBy={authUser}
        status="none"
        actions={actions}
        liveTag={livetag}
        currentUser={authUser}
        displayRating={context === 'selection'}
        onOpen={() => { }}
        onDownload={handleDownload}
        onDisplay={() => { }}
        appointment={appointment}
        highlightWords={highlightWords}
        interactions={participantInteractions}
        offers={participantOffers}
      />
    );
  }

  return (
    <Wrapper type="scene">
      <div style={{ display: 'flex', justifyContent: 'space-between', verticalAlign: 'center', marginBottom: '20px' }}>
        <h2 className="pageTitle"><span>{t({ id: 'recruiter.live.details.meet.your.x.attendees' }, { count: conference.countAttendees })}</span></h2>
        <div className="flex">
          {userType === 'owner' && (
            <DownloadAttendees className="mr-24" slug={event.slug} conferenceId={conference._id} conferenceTitle={conference.title} />
          )}
          <ButtonUi
            className="back"
            imageComponentLeft={<IconArrowLeft size={16} />}
            color="primary"
            onClick={history.goBack}
          >
            <FormattedMessage id="back" />
          </ButtonUi>
        </div>
      </div>

      <Row gutter={20}>
        <Col span={4}>
          <CardLive
            variant="live"
            authUser={authUser}
            conference={conference}
            context="client"
            hideParticipants
            event={event}
          />
        </Col>
        <Col span={8}>
          <Menu
            onClick={onChangeMenu}
            selectedKeys={[context]}
            mode="horizontal"
            style={{ background: 'none', marginLeft: 0 }}
            className="horizontal-menu-tab"
          >
            <Menu.Item key="all">
              <div>{t({ id: 'candidate.registered.pl' }, { count: 2 })} <div className="horizontal-menu-tab-count">{conference.countAttendees}</div></div>
            </Menu.Item>
            <Menu.Item key="present">
              <div>{t({ id: 'candidate.present.pl' }, { count: 2 })} <div className="horizontal-menu-tab-count">{conference.countPresentAttendees}</div></div>
            </Menu.Item>
          </Menu>

          <div style={{ margin: '20px 0' }}>
            <Select
              mode="tags"
              style={{ width: '100%' }}
              placeholder="Rechercher"
              dropdownStyle={{ display: 'none' }}
              onChange={handleKeywordsChange}
              onSearch={() => { }}
              noFloatingLabel
              placeholderIcon="search"
              defaultValue={keywords}
              value={keywords}
              className="searchSelect"
            />
          </div>

          {renderParticipants(participants, displayTag)}

          <Separator height={30} />

          <div className="pagination-container">
            {pagination.total > 10 && (
              <Pagination
                current={queryParams.page ? parseInt(queryParams.page, 10) : 1}
                pageSize={pagination.pageSize}
                total={pagination.total}
                onChange={handleOnChangePagination}
              />
            )}
          </div>
        </Col>
      </Row>

      <ModalFull
        id="participantModal"
        visible={modalProfileIsOpen}
        footer={null}
        onCancel={onCloseModal}
        maskClosable
        keyboard
      >
        <a role="button" tabIndex={0} className="modal-close" onClick={onCloseModal}>
          <Icon name="close" className="modal-close-icon" />
        </a>
        <div className="participantModal">
          {modalProfileIsOpen && openProfile ? openProfile : ''}
        </div>
      </ModalFull>
    </Wrapper>
  );
}

ConferenceParticipants.propTypes = {
  authUser: object,
  event: object,
  context: string,
  history: object,
  match: object,
  pagination: object,
  location: object,
  getConferenceParticipants: func,
  resetParticipantsList: func,
  postAction: func,
  participants: array,
  highlightWords: array,
  interactions: array,
  isFetching: bool,
  push: func,
  userType: oneOf(['owner', 'recruiter']),
}

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser,
  participants: participantSelectors.getParticipants,
  isFetching: participantSelectors.getParticipantsFetching,
  event: eventSelectors.getCurrentEvent,
  interactions: interactionSelectors.getInteractions,
  pagination: participantSelectors.getParticipantPagination,
});

const mapDispatchToProps = {
  push,
  postAction: actionActions.postAction,
  getConferenceParticipants: participantActions.getConferenceParticipants,
  resetParticipantsList: participantActions.resetParticipantsList,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withRouter,
  withConnect,
)(toJS(ConferenceParticipants));
