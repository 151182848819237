import React, { useEffect, memo } from 'react';
import { object, func, bool } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { replace } from 'connected-react-router';
import { createStructuredSelector } from 'reselect';

import { organizationActions, organizationSelectors } from '@/store/organization';

// Containers
import { withAuth } from '@/containers/RequiredAuth';
import { withLayout } from '@/containers/Layout';

// Pages
import OrganizationDetails from './scenes/Details';
import OrganizationMembers from './scenes/Users';
import OrganizationJobdating from './scenes/Jobdating';
import NavOrganization from './components/NavOrganization';
import ClientStatsByPeriodScene from '../../../../../Client/scenes/Stats/containers/ByEvent';
import ClientStatsByEventScene from '../../../../../Client/scenes/Stats/containers/ByPeriod';

const AdminOrganizationScene = ({
  match,
  organization,
  currentOrganization,
  replace,
  getSpecificOrganization,
  isFetching,
}) => {
  useEffect(
    () => {
      getSpecificOrganization(match.params.organizationId);
    }, []
  );

  if (match.isExact) {
    replace(`${match.url}/info`);
  }
  const cc = match?.params?.organizationId ? organization : currentOrganization;

  if (!cc) return null;

  return (
    <div>
      <NavOrganization match={match} />

      <Switch>
        <Route path={`${match.path}/info`} component={OrganizationDetails} />
        <Route path={`${match.path}/users`} render={(props) => <OrganizationMembers {...props} organization={cc} />} />
        <Route path={`${match.path}/exponents`} render={(props) => <OrganizationJobdating {...props} context="admin" />} />
        <Route path={`${match.path}/stats/by-event`} component={ClientStatsByPeriodScene} />
        <Route path={`${match.path}/stats/by-period`} component={ClientStatsByEventScene} />
      </Switch>
    </div>
  );
};

AdminOrganizationScene.propTypes = {
  match: object,
  organization: object,
  replace: func,
  getSpecificOrganization: func,
  isFetching: bool,
};

const mapStateToProps = createStructuredSelector({
  isFetching: organizationSelectors.getOrganizationFetching,
  organization: organizationSelectors.getSpecificOrganization,
  currentOrganization: organizationSelectors.getCurrentOrganization,
});

const mapDispatchToProps = {
  replace,
  getOrganization: organizationActions.getOrganization,
  getSpecificOrganization: organizationActions.getSpecificOrganization,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  withLayout({ size: 'large', withHeader: true, withNav: true }),
  withAuth({ isAdmin: true }),
  memo,
)(AdminOrganizationScene);
