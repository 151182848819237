import { schema } from 'normalizr';
import { sectorListSchema } from '../sector/schema';

export const organizationProfileSchema = new schema.Entity('organizationsProfile', { sectors: sectorListSchema }, {
  idAttribute: '_id',
});
export const organizationSchema = new schema.Entity('organizations', {}, {
  idAttribute: '_id',
});
export const organizationListSchema = [organizationSchema];
