import React from 'react';
import { any, number, object, string, bool } from 'prop-types';
import classnames from 'classnames';

// Components
import { Tooltip } from '@/components/Tooltip';

// Styles & Translations
import styles from './styles.less';

const TooltipCount = (props) => {
  const { title, count, max, style, className } = props;
  const renderContent = count > max ? '9+' : `+${count}`;

  if (displayCondition(props)) return '';

  return (
    <Tooltip color="dark" placement="bottom" title={title} getPopupContainer={(triggerNode) => triggerNode.parentNode} >
      <div className={classnames(styles.tooltipContainer, className)} style={style}>{renderContent}</div>
    </Tooltip>
  );
};

const displayCondition = ({ withNull, count }) => !withNull && count === 0;

TooltipCount.propTypes = {
  title: any,
  max: number,
  count: number,
  style: object,
  className: string,
};

displayCondition.propTypes = {
  withNull: bool,
};

TooltipCount.defaultProps = {
  color: 'default',
  className: '',
  max: 9,
  withBadge: true,
};

export { TooltipCount };
