import { useMutation } from 'react-query';
import request from '@/utils/request';


export function usePostChangePassword() {
  const mutationFn = ({ password, resetToken }) => {
    const requestUrl = `${process.env.FRONT_API_URL}/users/changepassword`;

    return (
      request(requestUrl, {
        method: 'POST',
        body: JSON.stringify({ password, resetToken }),
      }, [], true)
    )
  }

  return useMutation({
    mutationFn
  });
}
