import React from 'react';
import { number } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { StatBloc } from '@/components/Stats/StatBloc';
import messages from '../../messages';

const StatsSourcing = ({ countPropositions = 0 }) => (
  <StatBloc
    title={<FormattedMessage {...messages.sourcingTitle} />}
    count={countPropositions}
    description={<FormattedMessage {...messages.sourcingText} />}
  />
);

StatsSourcing.propTypes = {
  countPropositions: number,
};

export { StatsSourcing };
