import React from 'react';
import { node, string, oneOfType, bool } from 'prop-types';
import omit from 'lodash/omit';
import classnames from 'classnames';

// Styles & Translations
import styles from './index.less';

/**
 * H3
 * @param {object} props
 */
const H4 = (props) => (
  <h4
    className={classnames(styles.h4, props.bold ? styles.bold : null, props.className)}
    {...omit(props, ['className', 'children', 'bold'])}
  >
    {props.children}
  </h4>
);

H4.propTypes = {
  children: oneOfType([string, node]),
  className: string,
  bold: bool,
};

export default H4;
