import { defineMessages } from 'react-intl';

export default defineMessages({
  welcome: {
    id: 'welcome',
  },
  inProgress: {
    id: 'inProgress',
  },
});
