import React from 'react';
import { injectIntl } from 'react-intl';
import { object } from 'prop-types';

// Containers
import NavEvent from '@/components/Nav/NavEvent';
import { NavScenePortal } from '@/containers/Layout'; // eslint-disable-line

/**
 * NavOrganization
 *
 * @param {object} match
 */
const NavOrganization = ({ match }) => {
  const elems = [];

  elems.push({ url: `${match.url}/info`, label: 'Infos', icon: 'building', type: 'blue' });
  elems.push({ url: `${match.url}/users`, label: 'Membres', icon: 'user', type: 'blue' });
  elems.push({ url: `${match.url}/exponents`, label: 'Jobdating', icon: 'house', type: 'blue' });
  elems.push({ url: `${match.url}/stats/by-period`, label: 'Stats', icon: 'candidats', type: 'blue' });

  return (
    <NavScenePortal>
      <NavEvent items={elems} />
    </NavScenePortal>
  );
};

NavOrganization.propTypes = {
  match: object,
};

export default injectIntl(NavOrganization);
