import { defineMessages } from 'react-intl';

export default defineMessages({
  showMore: {
    id: 'showMore.default.showMore',
  },
  showLess: {
    id: 'showMore.default.showLess',
  },
});
