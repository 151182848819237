import React, { useState } from 'react';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isEmpty, join } from 'lodash';
import { useFormatMessage } from 'react-intl-hooks';
import { toJS } from '@/utils';
import { useGetEventsSelect } from '@/features/agenda/recruiter/api/useGetEventsSelect';
import LoadingIndicator from '@/components/LoadingIndicator';
import { trackRecruiterDownloadedAppointmentPlanning } from '@/utils/analytics';
import { getParticipant } from '@/store/participant/actions';
import { getCurrentEvent } from '@/store/event/selectors';
import { getAuthUser } from '@/store/auth/selectors';
import { downloadAppointments, getAppointments } from '@/store/appointment/actions';
import { ModalQuitAgendaWithoutSaving } from '@/features/agenda/recruiter/ui/Modals/ModalQuitAgendaWithoutSaving';
import Calendar from '@/features/agenda/recruiter/components/Calendar';
import { BlankNoParticipation } from '@/features/agenda/recruiter/ui/Blankstates/BlankNoParticipation';

const Agenda = () => {
  const t = useFormatMessage();
  const dispatch = useDispatch();

  const authUser = useSelector(getAuthUser)?.toJS();
  const event = useSelector(getCurrentEvent)?.toJS();
  const appointments = useSelector(getAppointments);

  const [isQuitWithoutSavingModalVisible, setIsQuitWithoutSavingModalVisible] = useState(false);

  const isClient = authUser?._currentOrganization?.isClient;
  const isAgendaIconVisible = isClient || (!isEmpty(event) && !isClient);

  const { isLoading, isSuccess, ...authEventsQuery } = useGetEventsSelect({
    userId: authUser._id,
    eventId: event?._id,
    enabled: !!authUser && isAgendaIconVisible,
    cacheTime: 0,
  });

  if (isLoading) return <LoadingIndicator />

  if (!isSuccess || isEmpty(authUser)) return null;

  const { selected: eventSelected, docs: events } = authEventsQuery?.data;

  if (isEmpty(events)) return <BlankNoParticipation selectedUser={authUser} />;

  const getNotification = (messageIntl, kind, options = { emoji: '', withHtml: false }) => {
    const { emoji, withHtml } = options;
    const messageFormated = withHtml ? messageIntl : t(messageIntl);
    const space = ' ';
    const message = emoji ? [emoji, messageFormated] : [messageFormated];
    const messageWithHtml = <>{emoji} {messageFormated}</>;

    return {
      message: withHtml ? messageWithHtml : join(message, space),
      kind,
      style: {
        bottom: '5%',
        top: 'inherit',
      },
    };
  };


  const handleGetParticipant = (userId, slug) => {
    dispatch(
      getParticipant({
        eventId: slug || eventSelected.slug,
        userId,
        noLoader: true,
        openProfile: true,
        redirectUrl: `/${slug}/recruiter/jobdating/`,
      }));
  };

  const downloadAllCv = (userSelected, slug) => {
    const recruiterId = userSelected ? [userSelected._id] : null;

    const callback = () => {
      trackRecruiterDownloadedAppointmentPlanning({
        authUser,
        event: eventSelected,
        owner: userSelected._id === authUser._id,
      })
    };

    const notificationParams = {
      success: getNotification(
        <p><span role="img" aria-labelledby="">⏱️</span>
          {t({ id: 'download.running' })} <br />
          {t({ id: 'recruiter.export.appointments' })}</p>, 'info',
        { emoji: ' ', withHtml: true }),
      error: true,
    };

    dispatch(downloadAppointments({
      appointment: appointments[0],
      recruiterId,
      eventSlug: slug,
      notificationParams,
      callback
    }));

    return true;
  };

  return (
    <div id="jobdatingAgendaContainer">
      <Calendar
        currentEvent={eventSelected}
        events={events}
        authUser={authUser}
        downloadAllCv={downloadAllCv}
        getParticipant={handleGetParticipant}
      />
      <ModalQuitAgendaWithoutSaving
        visible={isQuitWithoutSavingModalVisible}
        onCancel={() => setIsQuitWithoutSavingModalVisible(false)}
        onConfirm={() => setIsQuitWithoutSavingModalVisible(false)}
      />
    </div>
  );
}

export default compose(
  withRouter,
)(toJS(Agenda));
