import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import request from '@/utils/request';
import { getCurrentParticipant } from '@/store/participant/selectors';
import { getCurrentEvent } from '@/store/event/selectors';
import { isEmpty } from 'lodash';

export function useGetDashboardIntroduction(props = {}) {
  const participant = useSelector(getCurrentParticipant)?.toJS();
  const event = useSelector(getCurrentEvent)?.toJS();

  const requestUrl = `${process.env.FRONT_API_URL}/events/${event._id}/participants/${participant._id}/dashboards/introduction`;
  const queryFn = () => request(requestUrl, { method: 'GET' });

  const query = useQuery({
    queryKey: ['dashboard', 'introduction'],
    queryFn,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    enabled: !isEmpty(event) && !isEmpty(participant),
    ...props,
  });
  return query;
}
