import { denormalize } from 'normalizr';
import { createSelector } from 'reselect';
import { userSchema, userListSchema } from './schema';

const getOrganizationReducer = (state) => state.get('organization');
const getUserReducer = (state) => state.get('user');
const getEntities = (state) => state.get('entities');

/**
 * Return the current user
 */
export const getCurrentUser = createSelector(
  getUserReducer,
  getEntities,
  (userState, entities) => {
    const userId = userState.get('currentId');
    const user = entities.getIn(['users', userId]);

    return denormalize(user, userSchema, entities);
  }
);

/**
 * Return users in the current organization
 */
export const getUsersOrganization = createSelector(
  getUserReducer,
  getOrganizationReducer,
  getEntities,
  (userState, organizationState, entities) => {
    const organizationId = organizationState.get('currentId');
    const users = userState.getIn(['byOrganization', organizationId]);

    return denormalize(users, userListSchema, entities);
  }
);

/**
 * Get pagination
 */
 export const getUsersPagination = createSelector(
  getUserReducer,
  (userState) => userState.get('pagination'),
);


/**
 * Get loader
 */
export const getUsersIsFetching = createSelector(
  getUserReducer,
  (userState) => userState.get('isFetching'),
);

/**
 * Get loader
 */
export const getIsCalendarsFetching = createSelector(
  getUserReducer,
  (userState) => userState.get('isCalendarsFetching'),
);

/**
 * Return the user last participation
 */
export const getUserLastParticipation = createSelector(
  getUserReducer,
  getEntities,
  (userState, entities) => {
    const participation = userState.get('lastParticipation');

    return denormalize(participation, userSchema, entities);
  }
);

export const getUsers = createSelector(
  getUserReducer,
  getEntities,
  (userState, entities) => {
    const allIds = userState.get('allIds');
    const usersList = allIds.map((id) => entities.getIn(['users', id]).toJS());

    return usersList;
  }
);

export const getCalendars = createSelector(
  getUserReducer,
  getEntities,
  (userState) => userState.get('calendars'),
);

export const getUserPagination = createSelector(
  getUserReducer,
  (userState) => userState.get('pagination'),
);
