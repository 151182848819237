import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import * as types from './types';

const initialState = fromJS({
  isFetching: false,
  isCalendarFetching: false,
  currentId: null,
  calendarId: null,
  calendars: [],
  byOrganization: {},
  lastParticipation: {},
  allIds: [],
  users: [],
  pagination: {
    current: 1,
    pageSize: 10,
    total: 0,
    offset: 0,
  },
});

const byOrganization = handleActions({
  [types.GET_USERS_ORGANIZATION]: (state, { payload }) => {
    if (payload.loader === true) {
      return state.set('isFetching', true);
    }

    return state;
  },
  [types.GET_USERS_ORGANIZATION_SUCCESS]: (state, { payload, payload: { organizationId, docs, limit, offset, total } }) => state
      .setIn(['byOrganization', organizationId], fromJS(docs))
      .setIn(['pagination', 'current'], (Math.floor(payload.offset || 0) / payload.limit) + 1)
      .setIn(['pagination', 'pageSize'], limit)
      .setIn(['pagination', 'offset'], offset)
      .setIn(['pagination', 'total'], total),
  [types.SET_CURRENT_USER]: (state, { payload: userId }) =>
    state.set('currentId', userId),
  [types.POST_USER_ORGANIZATION_SUCCESS]: (state, { payload: { organizationId, result } }) => {
    const newList = state.getIn(['byOrganization', organizationId]).merge(result);

    return state
      .setIn(['byOrganization', organizationId], newList);
  },
  [types.GET_USER_LAST_PARTICIPATION_SUCCESS]: (state, { payload: { result } }) =>
    state.set('lastParticipation', result),
  [types.PATCH_USER]: (state, { payload }) => {
    if (payload.loader !== false) {
      return state.set('isFetching', true);
    }

    return state;
  },
  [types.PATCH_USER_BEFORE_SIGNUP]: (state) =>
    state.set('isFetching', true),
  [types.PUT_USER_AFTER_SIGNUP]: (state) =>
    state.set('isFetching', true),
  [types.PATCH_USER_SUCCESS]: (state) =>
    state.set('isFetching', false),
  [types.DELETE_USER]: (state) =>
    state.set('isFetching', true),
  [types.DELETE_USER_SUCCESS]: (state) =>
    state.set('isFetching', false),
  [types.GET_CALENDARS]: (state) =>
    state.set('isCalendarsFetching', true),
  [types.SET_CALENDARS]: (state, { payload: { calendars } }) =>
    state
      .set('calendars', calendars)
      .set('isCalendarsFetching', false),
}, initialState);

export default byOrganization;
