import { createAction } from 'redux-actions';
import * as types from './types';

// GET events in progress
export const getEvents = createAction(types.GET_EVENTS);
export const getEventsSuccess = createAction(types.GET_EVENTS_SUCCESS);
export const getEventsError = createAction(types.GET_EVENTS_ERROR);

// GET contacts on Seekube
export const getContacts = createAction(types.GET_CONTACTS);
export const getContactsSuccess = createAction(types.GET_CONTACTS_SUCCESS);
export const getContactsError = createAction(types.GET_CONTACTS_ERROR);
