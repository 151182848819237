import React, { useState, useEffect } from 'react';
import { object, func, array } from 'prop-types';
import { size } from 'lodash';
import classnames from 'classnames';

// Styles
import styles from '../../../../styles.less';

const RowToggle = ({ school, onToggle, checkedItems }) => {
  const [isChecked, setIsChecked] = useState(false);
  useEffect(() => {
    if (size(checkedItems) === 0) {
      setIsChecked(false);
    }
  });


  const onCardToggle = (e) => {
    e.stopPropagation();

    setIsChecked(!isChecked);

    onToggle(school, !isChecked);
  };


  return (
    <div className={classnames(styles.cardOverlay, isChecked ? styles.selected : '', school.isMerged ? styles.warning : '')}>
      <div><span tabIndex={0} role="button" onClick={onCardToggle} className={styles.participantSelection} /></div>
    </div>
  );
};

RowToggle.propTypes = {
  school: object,
  onToggle: func,
  checkedItems: array,
};

RowToggle.defaultPros = {
  school: {},
};

export default RowToggle;
