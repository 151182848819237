import React from 'react';
import classnames from 'classnames';
import { oneOf, string } from 'prop-types';

import Icon from '@/components/Icon';

import styles from './styles.less';


const StatusSeparator = (props) => (
  <div className={classnames(styles.separator, styles[props.type])}>
    <div className={styles.label}>
      <span className={styles.ellipse}>
        {typeof props.icon === 'string' ? <Icon name={props.icon}/> : props.icon}
      </span>
      {props.label}
    </div>
    <div className={styles.border} />
  </div>
);

StatusSeparator.propTypes = {
  type: oneOf(['propositionsModified', 'accepted', 'pending', 'refused', 'becoming', 'done']),
  label: string,
  icon: string,
};

export default StatusSeparator;
