import { get, values, isUndefined, map, isDate, compact } from 'lodash';
import moment from 'moment';
import request from '@/utils/request';

const status = {
  prospect: 'Prospect',
  client: 'Client',
  user: 'Ancien client',
  agency: 'Agence comm\' & clients finaux',
};

export const PIPE_DRIVE = {
  status: {
    enum: values(status),
    ...status,
  },
};

export const isOfferLimitExceeded = (exponent, offersCount) => {
  // offersSelectedSize is used for grouped action when we have multiple offers to add
  const { limits, offersSelectedSize = 0 } = exponent;

  const offerMax = get(limits, 'offerMax');

  return offersCount + offersSelectedSize >= offerMax;
};

export const isRecruiterLimitExceeded = (exponent, recruitersCount) => {
  if (isUndefined(exponent) || isUndefined(recruitersCount)) return false;

  const { limits } = exponent;

  const recruiterMax = get(limits, 'recruiterMax');

  return recruitersCount > recruiterMax;
};

export const doesRecruiterSelectedExceedLimit = (index, exponent) => index >= get(exponent, 'limits.recruiterMax') - exponent.users.length;
