import { defineMessages } from 'react-intl';

export default defineMessages({
  viewByEvent: {
    id: 'view.by.jobdating',
  },
  viewByCollaborator: {
    id: 'view.by.collaborator',
  },
});
