import React from 'react';
import { object } from 'prop-types';
import { Col, Row } from 'antd';
import { isEmpty } from 'lodash';

import SearchDisplay from '@/components/Search';

const SearchRow = ({ participant, user, authUser, defaultCriteria }) => {

  if (!isEmpty(user)) {
    return (
      <Row gutter={16}>
        <Col span={12}>
          <div className="searchDisplayContainer">
            <SearchDisplay user={user} authUser={authUser} defaultCriteria={defaultCriteria} />
          </div>
        </Col>
      </Row>
    );
  }

  if (!participant.matching || isEmpty(participant.matching.filters) || (isEmpty(participant.matching.CONTRACT) && isEmpty(participant.matching.date) && isEmpty(participant.matching.MOBILITY))) {
    return null;
  }

  return (
    <Row gutter={16}>
      <Col span={12}>
        <div className="searchDisplayContainer">
          <SearchDisplay participant={participant} authUser={authUser} />
        </div>
      </Col>
    </Row>
  );
};

SearchRow.propTypes = {
  participant: object,
  user: object,
  authUser: object,
  defaultCriteria: object,
};

export default SearchRow;
