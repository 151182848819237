import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import { isEmpty } from 'lodash';
import * as types from './types';

// Initial State
const initialReducer = fromJS({
  authUserIsFetching: false,
  isFetching: false,
  error: {},
  eventId: null,
  calendarId: null,
  planningId: 'all',
  currentId: null,
  allIds: [],
  exponentIds: [],
  userEventIds: [],
  byUserId: {},
  pagination: {
    current: 1,
    pageSize: 10,
    total: 0,
    offset: 0,
  },
  byUserPagination: {
    current: 1,
    pageSize: 2,
    total: 1,
    offset: 0,
  },
  countAuthUserEvents: 0,
  isAuthUserPreregistered: false,
});

// Events reducer
const eventsReducer = handleActions({
  [types.SET_CURRENT_EVENT]: (state, { payload }) => state.set('currentId', payload),
  [types.SET_CALENDAR_EVENT]: (state, { payload }) => state.set('calendarId', payload),
  [types.GET_AUTH_USER_EVENTS]: (state, { payload }) => {
    if (payload.loader === true) {
      return state.set('authUserIsFetching', true);
    }

    return state;
  },
  [types.SET_PLANNING_EVENT]: (state, { payload }) => state.set('planningId', payload),
  [types.GET_EVENTS]: (state) => state.set('isFetching', true),
  [types.SET_FETCHING]: (state) => state.set('isFetching', true),
  [types.PATCH_EVENT]: (state) => state.set('isFetching', true),
  [types.PATCH_EVENT_SUCCESS]: (state) => state.set('isFetching', false),
  [types.PATCH_EVENT_ERROR]: (state) => state.set('isFetching', false),
  [types.POST_EVENT_SUCCESS]: (state, { payload }) =>
    state.set('allIds', state.get('allIds').push(payload)),
  [types.GET_AUTH_USER_EVENTS_SUCCESS]: (state, { payload: { userId, result, currentPage, limit, offset, context, total, eventIds } }) => {
    if (context === 'exponent') {
      return state
        .set('userEventIds', !isEmpty(eventIds) ? eventIds : state.get('eventIds'))
        .set('exponentIds', fromJS(result));
    }

    return state
      .setIn(['byUserId', userId], fromJS(result))
      .set('authUserIsFetching', false)
      .setIn(['byUserPagination', 'current'], currentPage)
      .setIn(['byUserPagination', 'pageSize'], limit)
      .setIn(['byUserPagination', 'offset'], offset)
      .setIn(['byUserPagination', 'total'], total);
  },
  [types.GET_EVENTS_SUCCESS]: (state, { payload }) => {
    const newAllIds = payload.currentPage === 1 ? state.get('allIds').clear().merge(payload.result) : state.get('allIds').push(...payload.result);

    return state
      .set('allIds', newAllIds)
      .set('isFetching', false)
      .setIn(['pagination', 'current'], payload.currentPage)
      .setIn(['pagination', 'pageSize'], payload.limit)
      .setIn(['pagination', 'offset'], payload.offset)
      .setIn(['pagination', 'total'], payload.total);
  },
  [types.COUNT_AUTH_USER_EVENTS_SUCCESS]: (state, { payload }) => state.set('countAuthUserEvents', payload),
  [types.IS_AUTH_USER_PREREGISTERED_SUCCESS]: (state, { payload }) => state.set('isAuthUserPreregistered', payload),
}, initialReducer);

export default eventsReducer;
