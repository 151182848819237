import React, { useState } from 'react';
import { object, func } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect/lib/index';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useFormatMessage } from 'react-intl-hooks';
import { authActions, authSelectors } from '@/store/auth';
import { userActions } from '@/store/user';
import { toJS } from '@/utils';
import { queryStringToObject } from '@/utils/url';
import { track } from '@/utils/analytics';
import { ANALYTICS_RECRUITER } from '@/utils/constants';
import { withLayout } from '@/containers/Layout';
import Wrapper from '@/components/Wrapper';
import LoadingIndicator from '@/components/LoadingIndicator';
import { useGetCheckInvitations } from '@/queries/users/useGetCheckInvitations';
import SignupModal from './components/SignupModal';

const AuthInvitScene = (props) => {
  const { authUser, location, patchMe } = props;
  const [currentStep, setCurrentStep] = useState(0);
  const history = useHistory();
  const t = useFormatMessage();

  const { data: authInviteUser, isLoading } =  useGetCheckInvitations({
    token: props.match.params.token,
    organization: props.match.params.organization,
    onSuccess: (user) => {
      if (user.isActive) {
        history.push('/auth/login/recruiter')
      }
    }});

  const handleOnGoToNextStep = (e, nextStep) => {
    setCurrentStep(nextStep);
  }

  const handleOnSetPassword = (password) => {
    const { match: { params }, authChangePassword } = props;

    authChangePassword({
      password,
      resetToken: params.token,
    });
  }

  const handleOnSubmitIdentity = (fields) => {
    patchMe({
      ...fields,
      callback: () =>
        track({
          authUser,
          name: ANALYTICS_RECRUITER.EDIT_PROFILE,
          properties: {
            step: 'Sign up',
            photo: !isEmpty(fields.userParams.pictureUrl),
            summary: !isEmpty(fields.userParams.description),
            myProfile: true,
            full: !isEmpty(fields.userParams.pictureUrl) && !isEmpty(fields.userParams.title) && !isEmpty(fields.userParams.firstName) && !isEmpty(fields.userParams.lastName) && !isEmpty(fields.userParams.description),
          },
        }),
      notificationParams: {
        success: {
          message: t({ id: 'notifications.update.success' }),
          kind: 'info',
          style: {
            bottom: '5%',
            top: 'inherit',
          },
        },
      }
    });
  };

  if (isLoading) {
    return (<LoadingIndicator />);
  }

  const urlParams = queryStringToObject(location.search);

  const { from, pictureUrl } = urlParams;

  return (
    <Wrapper>
      <SignupModal
        user={authUser}
        inviteUser={authInviteUser}
        currentStep={currentStep}
        handleGoToNextStep={handleOnGoToNextStep}
        handleSetPassword={handleOnSetPassword}
        invitationFrom={from}
        picture={pictureUrl}
        handleSubmitIdentity={handleOnSubmitIdentity}
      />
    </Wrapper>
  );
}

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser,
});

const mapDispatchToProps = {
  authChangePassword: authActions.authChangePassword,
  patchMe: userActions.patchMe,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

AuthInvitScene.propTypes = {
  authUser: object,
  match: object,
  authChangePassword: func,
  location: object,
  patchMe: func,
}

export default compose(
  withLayout({ size: 'default', withHeader: false, withBlur: true }),
  withConnect,
)(toJS(AuthInvitScene));
