import React from 'react';
import classnames from 'classnames';
import { object } from 'prop-types';

import { getLabel, isLabelDark } from '../../utils/label';

import styles from './styles.less';

function CornerTag({ corner }) {
  /* Render */

  return (
    <div
      className={classnames(styles.root, { [styles.dark]: isLabelDark(corner.color) })}
      style={{ backgroundColor: corner.color }}
    >
      {getLabel(corner, true)}
    </div>
  );
}

CornerTag.propTypes = {
  corner: object.isRequired,
};

export default CornerTag;
