import { useMutation } from 'react-query';
import request from '@/utils/request';

// It's not cached, it s juste a simple patch with request status
export function useUpdatePassword() {
  const mutationFn = ({ userId, password }) => {
    const requestUrl = `${process.env.FRONT_API_URL}/users/${userId}`;

    return (
      request(requestUrl, {
        method: 'PATCH',
        body: JSON.stringify({ password }),
      })
    )
  }

  return useMutation({
    mutationFn,
  });
}
