import React from 'react';
import { percentage } from '@/utils/math';
import { COLORS } from '@/utils/global';
import { object } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { pick, keys, map } from 'lodash';
import BlankState from '@/components/BlankState';
import { Stats, WithProgress } from '@/components/Stats';

import styles from './styles.less';

const StatsParticipants = ({ stats = {}, goals = {}, phases = {} }) => {
  const t = useFormatMessage();

  const pageStats = {
    total: {
      title: t({ id: 'stats.participants' }),
      icon: 'candidats',
      noSeparator: true,
      additionalText: <WithProgress value={percentage(stats.total, goals.totalParticipants)} visible={goals.totalParticipants > 0} />,
      count: 0,
    },
    top: {
      title: t({ id: 'stats.participants.top' }),
      icon: 'like',
      noSeparator: true,
      count: 0,
      additionalText: <WithProgress value={percentage(stats.top, goals.topParticipants)} visible={goals.topParticipants > 0} />,
    },
    save: {
      title: t({ id: 'stats.participants.save' }),
      icon: 'star',
      noSeparator: true,
      count: 0,
      additionalText: <WithProgress value={percentage(stats.save, stats.saveParticipants)} visible={goals.saveParticipants > 0} />,
    },
    apply: {
      title: t({ id: 'stats.participants.apply' }),
      icon: 'user-invite',
      noSeparator: true,
      count: 0,
      additionalText: <WithProgress value={percentage(stats.apply, stats.total)} visible={stats.total > 0} colors={[[0, 20, COLORS.error], [20, 39, COLORS.warning], [40, 100, COLORS.success]]} />,
    },
  }

  const statsList = keys(pageStats);
  const statsFound = keys(pick(stats, statsList));

  const result = map(statsFound, (key) => ({ ...pageStats[key], count: stats[key] }));

  if (!phases.subscriptionCandidate || !phases.eventVisibleForCandidate) {
    return (
      <BlankState
        className={styles.blankState}
        title={phases.eventVisibleForCandidate ? t({ id: 'stats.participants.blankState.visible' }) : t({ id: 'stats.participants.blankState.notVisible' })}
        icon="blank-state-cactus-disabled"
      />
    );
  }

  return <Stats list={result} containerClass={styles.statsContainer} />;
};

StatsParticipants.propTypes = {
  stats: object,
  goals: object,
  phases: object,
};

export { StatsParticipants };
