import { useMutation, useQueryClient } from 'react-query';
import request from '@/utils/request';

export function usePatchConference(
  {
    onSuccess = () => {},
    conferenceId,
  }) {
  const queryClient = useQueryClient();

  const mutationFn = (body) => {
    const requestUrl = `${process.env.FRONT_API_URL}/conferences/${conferenceId}`;

    return (
      request(requestUrl, {
        method: 'PATCH',
        body: JSON.stringify(body.conference),
      })
    )
  }

  return useMutation({
    mutationFn,
    onSuccess: async (newConference) => {

      queryClient.setQueryData(['conferences', conferenceId], () => newConference);

      onSuccess();
    },
  });
}
