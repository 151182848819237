import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { object, func } from 'prop-types';
import frFR from 'antd/lib/locale-provider/fr_FR';
import enGB from 'antd/lib/locale-provider/en_GB';
import moment from 'moment';
import { useFormatMessage } from 'react-intl-hooks';
import { Calendar } from 'antd';
import classnames from 'classnames';
import { createStructuredSelector } from 'reselect';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';
import { queryStringToObject } from '@/utils/url';
import Icon from '@/components/Icon';
import { authSelectors } from '@/store/auth';
import { getAuthUser } from '@/store/auth/selectors';
import styles from './styles.less';

function CalendarLive({ event, onSelect, liveDates }) {
  const authUser = useSelector(getAuthUser)?.toJS();
  const t = useFormatMessage();
  const { locale, timezone } = authUser;
  const momentLocale = locale === 'en' ? 'en-gb' : 'fr';
  const isNowDuringLivePeriod = moment().isAfter(event.keyDates?.live?.beginAt || event.keyDates.jobfair.beginAt) && moment().isBefore(event.keyDates?.live?.endAt || event.keyDates.jobfair.beginAt)
  const { dates } = queryStringToObject(window.location.search)
  const isLivePeriodDuringSameMonth = moment(event.keyDates.hunt.beginAt).isSame(event.keyDates.hunt.endAt, 'month');
  const [dateSelected, setDateSelected] = useState(null);

  const getDaysWithLives = () => {
    const daysWithLives = {};
    const tz = timezone?.utc?.[0];

    if (!liveDates) {
      return {};
    }

    liveDates.forEach((date) => {
      const baseDate = moment(date).utc().format('YYYY-MM-DD');
      const convertedDate = moment.tz(date, tz).format('YYYY-MM-DD');
      if (!daysWithLives[convertedDate]) {
        daysWithLives[convertedDate] = [];
      }
      if (!daysWithLives[convertedDate].includes(baseDate) && baseDate !== convertedDate) {
        daysWithLives[convertedDate].push(baseDate);
      }
    });

    return daysWithLives;
  }

  const daysWithLives = getDaysWithLives();

  useEffect(() => {
    let date = null;
    
    if (dates) {
      date = moment(dates, "YYYY-MM-DD").format();
    } else if (isNowDuringLivePeriod) {
      date = Object.keys(daysWithLives)[0] || moment().format();
    } else {
      date = moment(event.keyDates?.live?.beginAt || event.keyDates.jobfair.beginAt).format();
    }

    setDateSelected(date);
  }, [dates])

  const headerRender = ({ value }) => {
    const previousMonth = moment(value).locale(momentLocale).subtract(1, 'month').endOf('month');
    const nextMonth = moment(value).locale(momentLocale).add(1, 'month').startOf('month');
    const isPreviousMonthDisable = previousMonth.isBefore(moment(get(event, 'keyDates.live.beginAt')))
    const isNextMonthDisable = nextMonth.isAfter(moment(get(event, 'keyDates.live.endAt')))

    return (
      <div className={classnames(styles.calendarHeader, isLivePeriodDuringSameMonth && styles.sameMonth)}>
        <a className={classnames(isPreviousMonthDisable && styles.disable)} role="button" tabIndex={0} onClick={() => !isPreviousMonthDisable && setDateSelected(moment(value).subtract(1, 'month'))}>
          <Icon name="arrow-left" />{`${previousMonth.format('MMMM').slice(0, 3)}`}
        </a>
        <span>{moment(value).locale(momentLocale).format('MMMM YYYY')}</span>
        <a className={isNextMonthDisable && styles.disable} role="button" tabIndex={0} onClick={() => !isNextMonthDisable && setDateSelected(moment(value).add(1, 'month'))}>
          {`${nextMonth.format('MMMM').slice(0, 3)}`}<Icon name="arrow-right" />
        </a>
      </div>
    )
  }

  const isDateDisabled = (date) => {
    const now = moment().format('YYYY-MM-DD');

    return moment(date).isBefore(now) || !Object.keys(daysWithLives).includes(date.format('YYYY-MM-DD'));
  }

  const dateCellRender = (date) => {
    if (!Object.keys(daysWithLives).includes(date.format('YYYY-MM-DD'))) {
      return null;
    }
    return (
      <div className={styles.dateCell}>
        <span className={styles.point} />
      </div>
    );
  }

  const onSelectDate = (params) => {
    const daysWLives = daysWithLives[params.dates] ?? [];
    const search = [params.dates, ...daysWLives];

    onSelect({ dates: search.join(',') });
  }

  return (
    <div className={styles.calendarLiveContainer}>
      <div className={styles.calendarLiveHeader}>
        <span className={styles.calendarTitle}>{t({ id: 'live.calendar.filter.title' })}</span>
        <a role="button" tabIndex={0} onClick={() => onSelect({ dates: null })}>{t({ id: 'reset' })}</a>
      </div>
      <div className={classnames(styles.calendar)}>
        <Calendar
          mode="month"
          value={moment(dateSelected)}
          onSelect={(date) => onSelectDate({ dates: dates === date.format('YYYY-MM-DD') ? null : date.format('YYYY-MM-DD') })}
          fullscreen={false}
          locale={locale === 'en' ? enGB : frFR}
          headerRender={headerRender}
          disabledDate={(date)=> isDateDisabled(date)}
          dateCellRender={(date) => dateCellRender(date)}
        />
      </div>
    </div>)
}

CalendarLive.propTypes = {
  event: object,
  onSelect: func,
  liveDates: object
}

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser
})

const mapDispatchToProps = {};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const ConnectedCardLive = compose(withConnect)(CalendarLive);

export { ConnectedCardLive as CalendarLive }
