import React from 'react';
import { string, func } from 'prop-types';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { useFormatMessage } from 'react-intl-hooks';
import { BarChart2 as BarChart2Icon,
  PlayCircle as PlayCircleIcon,
  Calendar as CalendarIcon,
  Sliders as SlidersIcon,
} from 'react-feather';
import { Typography } from '@seekube-tech/ui';
import iconSeekube from '@/assets/images/mainLogo.svg';
import Icon from '@/components/Icon';

import useStyles from './styles';

const Menu = ({
  handleSelectedKey,
  selectedKey,
}) => {
  const classes = useStyles();
  const t = useFormatMessage();

  return (
    <div className={classes.root}>
      <img src={iconSeekube} className={classes.logo} color="red" alt="Seekube" />
      <ul>
        <li className={classNames({[classes.selected]: selectedKey === 'kpi'}, classes.li) }>
          <Typography
            variant="body2"
            component="a"
            onClick={() => handleSelectedKey('kpi')}
          >
            <BarChart2Icon className={classes.transparent} />
            <span>{t({ id: 'kpi.pl' }, { count: 2 })}</span>
          </Typography>
        </li>
        <li>
          <NavLink to="/admin">
            <Typography variant="body2">
              <Icon name="house" />
              {t({ id: 'header.userDropdown.events' })}
            </Typography>
          </NavLink>
        </li>
        <li>
          <NavLink to="/admin/demo">
            <Typography variant="body2">
              <PlayCircleIcon className={classes.transparent}/>
              {t({ id: 'demo' })}
            </Typography>
          </NavLink>
        </li>
        <li>
          <NavLink to="/admin/organizations">
            <Typography variant="body2">
              <Icon name="building" />
              {t({ id: 'company' }, { count: 2 })}
            </Typography>
          </NavLink>
        </li>
        <li>
          <NavLink to="/calendar">
            <Typography variant="body2">
              <CalendarIcon className={classes.transparent} />
              {t({ id: 'calendar' })}
            </Typography>
          </NavLink>
        </li>
        <li>
          <NavLink to="/admin/cleaner/merge-schools">
            <Typography variant="body2">
              <SlidersIcon className={classes.transparent} />
              {t({ id: 'dbCleaner' })}
            </Typography>
          </NavLink>
        </li>
        <li>
          <NavLink to="/seekalendar">
            <Typography variant="body2">
              <Icon name="logoFlat" />
              {t({ id: 'seekalendar' })}
            </Typography>
          </NavLink>
        </li>
      </ul>
    </div>
  )
}

Menu.propTypes = {
  handleSelectedKey: func,
  selectedKey: string,
}

export { Menu }
