import { isEmpty } from 'lodash';
import Selector from '@/components/Form/Selector';
import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { Form } from 'antd';
import { sortByOccurence } from '@/scenes/Event/scenes/Candidate/scenes/JobDating/components/SearchEngine/helpers';
import {
  getLabel
} from '@/scenes/Event/scenes/Recruiter/scenes/Preparation/scenes/Offer/containers/CreateOfferModal/components/CreateOfferForm/components/CriteriaFields/utils';
import styles from '@/scenes/Event/components/StaticOffers/components/Filters/styles.less';

const FormItem = Form.Item;
const CUSTOM_FILTER_DISPLAY_LENGTH = 4;

export const CustomSelector = ({
  isMobile,
  matching,
  updateMatching,
  customCriterias,
  event,
  facets
}) => {
  const t = useFormatMessage();

  const customCriteriaIndexes = event._criteria
    .filter((c) => isEmpty(c.key))
    .map((c, index) => ({ id: c._id.toString(), index, name: c.name }));

  const otherCriterias = [...customCriterias];

  otherCriterias.splice(0, CUSTOM_FILTER_DISPLAY_LENGTH);

  const otherChoices = [];

  otherCriterias.forEach((cr, i) => {
    const parentId = `${i}-${cr.label}`;
    const tmpIdx = customCriteriaIndexes.find((idx) => idx.id.toString() === cr._id.toString());
    const customCriteriaKey = isEmpty(cr.key) && tmpIdx ? `custom${tmpIdx.index}` : cr.key;

    otherChoices.push({ value: parentId, label: cr.modules.searchEngineParticipant.label, uncheckable: true });
    cr._choices.forEach((ch) => otherChoices.push({
      value: ch._id,
      parent: parentId,
      label: ch.label,
      badgeCount: getFacetFromKey(customCriteriaKey, ch._id)
    }));
  });

  return (
    <>
      {customCriterias
        .filter((criteria) => criteria.modules.searchEngineParticipant.isVisible).map((criteria, index) => {
          const tmpIdx = customCriteriaIndexes.find((idx) => idx.id.toString() === criteria._id.toString());
          const customCriteriaKey = isEmpty(criteria.key) && tmpIdx ? `custom${tmpIdx.index}` : criteria.key;

          const getOptions = () => criteria._choices.filter((o) => o.enable).map((c) => ({
                value: c._id,
                label: getLabel(c),
                badgeCount: getFacetFromKey(customCriteriaKey, c._id)
              }))

          if (index >= CUSTOM_FILTER_DISPLAY_LENGTH) return undefined;

          const options = getOptions();
          const filtredOptions = options.filter((o) => o !== undefined);
          const label = getLabel(criteria.modules.searchEngineParticipant);

          return (
            <FormItem className={styles.formItem} style={{ width: '140px' }} key={criteria._id}>
              <Selector
                position="center"
                isMobile={!!isMobile}
                label={label}
                noLabel={t({ id: 'searchEngine.noLabel' })}
                options={sortByOccurence(filtredOptions)}
                values={matching.filters.flat().filter((v) => filtredOptions.find((c) => c?.value === v))}
                onChange={(e) => updateMatching(matching.filters.flat().filter((v) => !filtredOptions.find((c) => c.value === v)), e)}
              />
            </FormItem>);
        })
      }

      {otherCriterias.length ? (
        <FormItem className={styles.formItem} style={{ width: '140px' }}>
          <Selector
            position="right"
            isMobile={!!isMobile}
            label={t({ id: 'other' })}
            noLabel={t({ id: 'searchEngine.noLabel' })}
            isTree
            options={sortByOccurence(otherChoices)}
            values={matching.filters.flat().filter((v) => otherChoices.find((oc) => oc.value === v))}
            onChange={(e) => updateMatching(matching.filters.flat().filter((v) => !otherChoices.find((oc) => oc.value === v)), e)}
          />
        </FormItem>
      ) : null}
    </>
  );

  function getFacetFromKey (key, value) {
    return (facets && typeof facets[`matching.${key}`] === 'object') ? facets[`matching.${key}`][value] || 0 : 0;
  }
}
