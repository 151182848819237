import React from 'react';
import { compose } from 'redux';
import { toJS } from '@/utils/index';
import { injectIntl } from 'react-intl';
import { func, shape, object } from 'prop-types';
import { isEmpty } from 'lodash';

// Components
import { Form, Select as SelectAntd } from 'antd/lib/index';
import Select from '@/components/Form/Select/index';

import styles from '@/scenes/Settings/styles.less'
import messages from '../messages';


const {Option} = SelectAntd;
const FormItem = Form.Item;

class EducationLevelForm extends React.PureComponent {
  static propTypes = {
    intl: object,
    form: shape({
      getFieldDecorator: func,
      getFieldError: func,
      isFieldTouched: func,
    }),
    onChange: func,
  };

  state = {
    educationLevels: [],
    initialValue: '',
  };

  static getDerivedStateFromProps({ defaultCriteria, matching }, state) {
    const newState = {};

    if (!isEmpty(defaultCriteria) && isEmpty(state.educationLevels)) {
      const levels = defaultCriteria.find((c) => c.key === 'LEVEL');

      if (!isEmpty(levels) && !isEmpty(levels._choices)) {
        newState.educationLevels = levels._choices;

        if (!isEmpty(matching)) {
          newState.initialValue = matching.level || '';
        }
      }
    }

    return newState;
  }

  handleOnChange = (e) => {
    const {
      props: { form, onChange },
      state: { initialValue },
    } = this;

    if (typeof onChange === 'function') onChange(e, initialValue);
    form.setFieldsValue({ educationLevel: e });
    this.setState({ value: e });
  };

  render() {
    const {
      props: { intl, form },
      state: { educationLevels, initialValue },
      handleOnChange,
    } = this;

    const educationLevelName = 'educationLevel';
    const educLevel = form.isFieldTouched(educationLevelName) && form.getFieldError(educationLevelName);

    return (
      <div id="educationLevelFormItemWrapper">
        <p className={`${styles.text} help`} style={{ fontSize: '14px' }}>{intl.formatMessage(messages.educationLevelHelp)}</p>
        <FormItem
          validateStatus={educLevel ? 'error' : ''}
          help={educLevel || ''}
        >
          {form.getFieldDecorator(educationLevelName, {
            initialValue,
            rules: [{ required: true, message: intl.formatMessage(messages.educationLevelMandatory) }],
          })(
            <Select
              addOnIcon="skill"
              getPopupContainer={() => document.getElementById('educationLevelFormItemWrapper')}
              placeholder={<span>{intl.formatMessage(messages.educationLevel)} <span className="mandatory">*</span></span>}
              label={intl.formatMessage(messages.educationLevel)}
              onChange={handleOnChange}
            >
              {educationLevels.filter((l) => (intl.locale === 'en' && !isEmpty(l.label_en)) || (intl.locale === 'fr')).map((level) => {
                if (intl.locale === 'fr') {
                  return (<Option key={level._id}>{level.label}</Option>);
                }

                return (<Option key={level._id}>{level.label_en}</Option>);
              })}
            </Select>
          )}
        </FormItem>
      </div>
    );
  }
}

export default  compose(
  injectIntl,
)(toJS(EducationLevelForm));
