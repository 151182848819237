import React from 'react';
import { injectIntl } from 'react-intl';
import { Form } from 'antd';
import { compose } from 'redux';
import { object, func } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { toJS } from '@/utils';
import { H1 } from '@/components/Title';

import Checkbox, { CheckboxGroup } from '@/components/Checkbox';

import ApiToForm from '../../services/formMapping/ApiToForm';

const FormItem = Form.Item;

const Modules = ({
  modules,
  form: { getFieldDecorator },
  handleSubmit,
}) => {
  const t = useFormatMessage();
  
  return(
    <Form onSubmit={handleSubmit} layout="vertical" style={{ display: 'none' }}>
      <H1 bold>{t({ id: 'event.basic.modules.title' })}</H1>
      <FormItem label={t({ id: 'event.basic.input.modules.label' })}>
        {getFieldDecorator('modules', {
          initialValue: ApiToForm.modules(modules),
        })(
          <CheckboxGroup>
            <Checkbox value="jobdating">{t({ id: 'modules.jobdating' })}</Checkbox>
            <Checkbox value="live">{t({ id: 'modules.live' })}</Checkbox>
            <Checkbox value="hunt">{t({ id: 'modules.hunt' })}</Checkbox>
            <Checkbox value="feedback">{t({ id: 'modules.feedback' })}</Checkbox>
            <Checkbox value="offer">{t({ id: 'event.modules.offer' })}</Checkbox>
          </CheckboxGroup>,
        )}
      </FormItem>
    </Form>
  );
};

Modules.propTypes = {
  form: object,
  modules: object,
  handleSubmit: func,
};

const withForm = Form.create();

export default compose(
  withForm,
  injectIntl,
  toJS,
)(Modules);
