import React, { PureComponent } from 'react';
import { any, string, bool, func } from 'prop-types';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import uuidv1 from 'uuid/v1';

import Icon from '@/components/Icon';
import styles from '@/components/Form/styles.less';

class InputCheckbox extends PureComponent {
  static propTypes = {
    value: string,
    label: string,
    type: string.isRequired,
    id: string.isRequired,
    disabled: bool,
    validations: any,
    addOnIcon: string,
    required: bool,
    onChange: func,
    children: any,
  };

  static defaultProps = {
    disabled: false,
    required: false,
    createAlert: false,
    id: 'text-box',
    placeholder: 'name',
    addOnIcon: null,
    validAutocompleteCreateItem: true,
  };

  static getDerivedStateFromProps(props) {
    if (props.value) {
      return { hasValue: !isEmpty(props.value) };
    }

    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      currentValue: props.value,
      hasValue: !isEmpty(props.value),
      hasFocus: false,
    };

    this.idContainer = uuidv1();
  }

  onFocus = () => {
    this.setState({ hasFocus: true });
  };

  onBlur = (event) => {
    const value = typeof event === 'object' ? event.currentTarget.value : event;

    this.setState({ hasValue: Boolean(value), hasFocus: false });
  };

  onChange = (event) => {
    const {
      props: { onChange, type },
    } = this;

    const value = typeof event === 'string' ? event : event.currentTarget.value;
    const newState = {
      hasValue: !isEmpty(value),
      currentValue: value,
    };

    if (type === 'autocomplete') { // Todo: remove because hack due to onFocus autocomplete bug on antdesign
      newState.hasFocus = true;
    }

    this.setState(newState);

    if (onChange) {
      onChange(event);
    }
  };

  render() {
    const {
      props: {
        addOnIcon, id, disabled, required, type, label, validations,
      },
      state: { hasValue, validationStatus, hasFocus },
    } = this;

    const inputContainerClasses = classNames(
      styles[validationStatus],
      styles.checkboxInput,
      styles['fl-input-container'], disabled ? styles['fl-disabled'] : null,
      hasFocus ? styles.hasFocus : null,
      validations !== undefined ? styles.withValidation : null,
      'input-material-wrapper',
      'input-checkbox-wrapper',
    );

    const addOnContainerClasses = classNames(
      styles.addOn,
      hasFocus ? styles.hasFocus : null,
      hasValue ? styles.hasValue : null,
      'input-material-wrapper',
    );

    const input = this.props.children;

    return (
      <div className={addOnContainerClasses} id={`dropdownAutocomplete${this.idContainer}`}>
        <span className="ant-input-group-wrapper">
          <span
            className={
              classNames(
                'ant-input-wrapper',
                'ant-input-group',
              )
            }
          >
            <span className="ant-input-group-addon">
              <Icon name={addOnIcon} className={styles.inputIcon} />
            </span>
            <span className="ant-input-affix-wrapper">
              <div className={inputContainerClasses}>
                {type !== 'autocomplete' ? (
                  <label htmlFor={id}>
                    {label}
                    {(validations && validations.includes('required')) || required ? (<span className="mandatory-symbol"> * </span>) : null}
                  </label>
                ) : ''}
                {input}
              </div>
            </span>
          </span>
        </span>
      </div>
    );
  }
}

export default InputCheckbox;
