import { Typography } from 'antd';
import { func } from 'prop-types';
import * as React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { useFormatMessage } from 'react-intl-hooks';
import Outlook from "@/scenes/Settings/components/SyncCalendar/components/outlook";
import styles from './styles.less';


function Sync({ onUpdate }) {
  const t = useFormatMessage();

  return <Outlook
    presetValue
    title={t({ id: 'profile.settings.syncAgenda.enableModal.title' })}
    subtitle={(
      <>
        <Typography variant="body2" className={styles.greyText}>{t({ id: 'profile.settings.syncAgenda.enableModal.subtitle' })}</Typography>
        <Typography variant="body2" className={styles.greyText}><FormattedHTMLMessage id="profile.settings.syncAgenda.enableModal.knowMore" /></Typography>
      </>
    )}
    onUpdate={onUpdate}
  />
}

Sync.propTypes = {
  onUpdate: func.isRequired
}

export default Sync;
