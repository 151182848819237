import { defineMessages } from 'react-intl';

export default defineMessages({
  editTitle_meeting: {
    id: 'recruiter.livetalks.edit.title.meeting',
  },
  editTitle_webinar: {
    id: 'recruiter.livetalks.edit.title.webinar',
  },
  editTitle_chat: {
    id: 'recruiter.livetalks.edit.title.chat',
  },
  confirmDelete: {
    id: 'recruiter.live.confirm.delete',
  },
  confirmEdit: {
    id: 'event.recruiter.preparation.offer.published.form.update',
  },
  chatText: {
    id: 'event.recruiter.lives.popover.chat.text',
  },
  webinarText: {
    id: 'event.recruiter.lives.popover.webinar.text',
  },
  meetingText: {
    id: 'event.recruiter.lives.popover.meeting.text',
  },
  popoverPlaces: {
    id: 'event.recruiter.lives.popover.tag.places',
  },
  livesTitle: {
    id: 'event.recruiter.lives.title',
  },
  alertLiveMax: {
    id: 'event.recruiter.lives.alert.liveMax',
  },
  infoLiveMax: {
    id: 'event.recruiter.lives.description.liveMax',
  },
  btnPost: {
    id: 'recruiter.live.btn.post',
  },
  livesUpcoming: {
    id: 'event.recruiter.lives.upcoming',
  },
  livesPast: {
    id: 'event.recruiter.lives.past',
  },
  popoverUnlimited: {
    id: 'unlimited',
  },
});
