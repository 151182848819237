import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';
import { replace } from 'connected-react-router';
import { createStructuredSelector } from 'reselect';
import { Switch, Route } from 'react-router-dom';
import { ReactTitle } from 'react-meta-tags';
import { eventActions } from '@/store/event';
import { getHelloWorkPixel } from '@/utils/pixels/helloWork';
import { getCurrentEvent } from '@/store/event/selectors';
import EventRouter from '@/components/EventRouter';
import { USERS_EVENT_ROLES } from '@/utils/constants';
import { appActions, appSelectors } from '@/store/app';
import { currentLocale } from '@/containers/LanguageProvider/selectors';
import EventLandingCMSPage from './scenes/Edit';
import EventRecruiterPage from './scenes/Recruiter';
import EventOwnerPage from './scenes/Owner';
import EventCandidatePage from './scenes/Candidate';
import JobDatingPage from './scenes/Candidate/scenes/JobDating';
import { Landing } from './containers/Landing';


const EventScene = ({ getEvent, match, replace, fetchError, cleanFetchError, locale }) => {
  const event = useSelector(getCurrentEvent)?.toJS();

  useEffect(() => {
    if (!match?.params) {
      return;
    }
    getEvent(match.params.eventSlug);

    getHelloWorkPixel(match.params.eventSlug)?.pageView();
  }, []);

  if (event?.deleted || fetchError?.code === 404) {
    cleanFetchError();
    replace('/404');
  }

  if (!event) {
    return null;
  }

  if (match.isExact) {
    return (
      <Landing
        event={event}
        editMode={false}
        match={match}
        currentLocale={locale}
      />
    );
  }

  return (
    <>
      {event ? (<ReactTitle title={`${event.name} - Seekube`} />) : ''}
      <Switch>
        <EventRouter
          path={`${match.path}/recruiter`}
          component={EventRecruiterPage}
          scope={[USERS_EVENT_ROLES.recruiter]}
          noAuthRedirect="/auth/login/recruiter"
        />
        <EventRouter
          path={`${match.path}/candidate`}
          component={EventCandidatePage}
          scope={[USERS_EVENT_ROLES.participant]}
          noAuthRedirect={`/${event.slug}`}
        />
        <Route path={`${match.path}/jobdating`} component={JobDatingPage} />
        <EventRouter
          path={`${match.path}/owner`}
          component={EventOwnerPage}
          scope={[USERS_EVENT_ROLES.owner, USERS_EVENT_ROLES.admin]}
          noAuthRedirect="/auth/login/owner"
        />
        <Route path={`${match.path}/edit`} component={EventLandingCMSPage} />
      </Switch>
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  fetchError: appSelectors.getFetchError,
  locale: currentLocale,
});

const mapDispatchToProps = {
  getEvent: eventActions.getEvent,
  cleanFetchError: appActions.cleanFetchError,
  replace,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

EventScene.propTypes = {
  getEvent: PropTypes.func,
  match: PropTypes.object,
  replace: PropTypes.func,
  fetchError: PropTypes.object,
  cleanFetchError: PropTypes.func
}

export default compose(
  withConnect,
)(EventScene);
