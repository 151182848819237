import React from 'react';
import { array, object, func, bool, string } from 'prop-types';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { toJS } from '@/utils';
import { participantSelectors } from '@/store/participant';
import { authSelectors } from '@/store/auth';
import AppointmentNumber from './components/AppointmentNumber';
import AppointmentForCandidate from './components/AppointmentForCandidate';
import AppointmentValidated from './components/AppointmentValidated';
import CvThequeDate from './components/CvThequeDate';
import ParticipantsNumber from './components/ParticipantsNumber';
import ParticipantsClose from './components/ParticipantsClose';
import ApplicationsNumber from './components/ApplicationsNumber';
import WaitingListNumber from './components/WaintingListNumber';
import CandidateApplicationsClosing from './components/CandidateApplicationsClosing';
import Propositions from './components/Propositions';
import CandidatePropositionClose from './components/CandidatePropositionClose';
import JobdatingDate from './components/JobdatingDate';
import OpeningForCandidate from './components/OpeningForCandidate';
import HuntDate from './components/HuntDate';
import CountOffersAndFreeSlots from './components/CountOffersAndFreeSlots';
import Opportunities from './components/Opportunities';
import CountOffers from './components/CountOffers';
import DownloadCVTheque from './components/DownloadCvTheque';
import SeeCalendarLink from './components/SeeCalendarLink';

function Tags({ tagsList, ...props }) {
  return tagsList.map((tagName) => <Tag key={tagName} name={tagName} {...props} />);
}

const Tag = ({ name, event, downloadAllCV2, loadingDownload, locale }) => {
  const stats = isEmpty(event.stats) ? {} : event.stats;

  switch (name) {
    case 'cvtheque': return <CvThequeDate daysBeforeCvthequeIsClosed={stats.daysBeforeCvthequeIsClosed || -1} />
    case 'appointment': return <AppointmentNumber stats={stats || {}} event={event} />;
    case 'appointmentForCandidate': return <AppointmentForCandidate countAppointments={stats.countAppointments || 0} />;
    case 'appointmentValidated': return <AppointmentValidated countAppointments={stats.countAppointments || 0} />;
    case 'waitingList': return <WaitingListNumber countApplications={stats.countApplications || 0} skipAppointmentValidation={event.skipAppointmentValidation} />;
    case 'applications': return <ApplicationsNumber countApplications={stats.countApplications || 0} />
    case 'applicationsClosing': return <CandidateApplicationsClosing event={event} />
    case 'participants': return <ParticipantsNumber countParticipants={stats.countParticipants || 0} />;
    case 'participantsClose': return <ParticipantsClose countParticipants={stats.countParticipants || 0} />;
    case 'propositions': return <Propositions countPropositions={stats.countPropositions} />
    case 'candidatePropositionsClose': return <CandidatePropositionClose countPropositions={stats.countPropositions} />;
    case 'jobdatingDate': return <JobdatingDate event={event} locale={locale} />;
    case 'openingForCandidate': return <OpeningForCandidate event={event} />;
    case 'huntDate': return <HuntDate event={event} />
    case 'countOffersAndFreeSlots': return <CountOffersAndFreeSlots countFreeSlots={stats.countFreeSlots || 0} countOffers={stats.countOffers || 0} />;
    case 'opportunities': return <Opportunities countOpportunities={stats.countOpportunities || 0} />;
    case 'countOffers': return <CountOffers countOffers={event.countOffers} dateStatus={event.dateStatus} />;
    case 'downloadCVTheque': return <DownloadCVTheque countTotalAppointments={stats.countTotalAppointments || 0} downloadAllCV2={downloadAllCV2} loadingDownload={loadingDownload} />;
    case 'seeCalendar': return event.keyDatesTimelineIsHidden ? null : <SeeCalendarLink event={event} />;
    default: return '';
  }
};

Tags.propTypes = {
  tagsList: array,
  event: object,
  downloadAllCV2: func,
  loadingDownload: bool,
};

Tag.propTypes = {
  name: string,
  locale: string,
  event: object,
  downloadAllCV2: func,
  loadingDownload: bool,
};



const mapStateToProps = createStructuredSelector({
  stats: participantSelectors.getParticipantsStats,
  authUser: authSelectors.getAuthUser,
});

const withConnect = connect(mapStateToProps, null);

export default compose(withConnect, toJS)(Tags);
