import { defineMessages } from 'react-intl';

export default defineMessages({
  modifyProfile: {
    id: 'recruiter.actions.modifyProfile',
  },
  handlePassword: {
    id: 'recruiter.actions.handlePassword',
  },
  addJobdating: {
    id: 'recruiter.actions.addJobdating',
  },
  editRole: {
    id: 'recruiter.actions.editRole',
  },
  sendInvitation: {
    id: 'recruiter.actions.sendInvitation',
  },
  switchRole: {
    id: 'recruiter.actions.switchRole',
  },
  removeUser: {
    id: 'delete.account',
  },
});
