import { makeStyles } from '@seekube-tech/ui';

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 5),
    borderRadius: 10,
    border: `1px solid ${theme.palette.neutral[200]}`,
    boxSizing: 'border-box',
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.basic.white,
    color: theme.palette.neutral[500],

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: theme.spacing(2, 2, 3, 2),
    },
  },
  active: {
    borderColor: theme.palette.primary[500],
    backgroundColor: theme.palette.primary[100],
    padding: theme.spacing(3, 5),
    boxShadow: '0px 10px 32px rgba(0, 0, 0, 0.05)',
    marginLeft: -19,
    marginRight: -19,

    [theme.breakpoints.down('sm')]: {
      marginLeft: -16,
      marginRight: -16,
      padding: theme.spacing(2, 2, 3, 2),
    },
  },
  disabled: {
    opacity: 0.45,
  },
  info: {
    marginLeft: theme.spacing(5),
    flex: 1,

    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginTop: theme.spacing(3),
    },
  },
  infoActive: {
    fontSize: '17.5px',
    '& p': {
      fontSize: '18px',
    }
  },
  polygonIcon: {
    marginTop: 10,
  },
  title: {
    flex: 1,
  },
  text: {
    marginTop: theme.spacing(1),

    '& ul': {
      paddingLeft: theme.spacing(3),

      '& li': {
        listStyle: 'disc',
      },
    },
  },
  liveHelper: {
    color: theme.palette.neutral[300],
  },
}));
