import { times, uniq, compact } from 'lodash';
import { mergeArray } from '@/utils/math';
import moment from 'moment';

const FORMAT_RESTRICTIONS = {
  meeting: {
    maxAttendees: {
      min: 0,
      max: 15,
      override: 400,
    },
  },
  webinar: {
    maxAttendees: {
      min: 15,
      max: 100,
      override: 400,
    },
  },
  chat: {
    maxAttendees: {
      min: 0,
      max: 200,
      override: 400,
    },
  },
};

const getRangeDateForLive = (dates) => {
  const now = moment();

  const res = compact(dates).map(({ beginAt, endAt }) => {
    const firstDate = moment(beginAt).isBefore(now) ? now : moment(beginAt);
    const isEndDateFinished = moment(endAt).isBefore(now);
    const nbDaysToInc = moment(endAt).diff(firstDate, 'days') > 0 ? moment(endAt).diff(firstDate, 'days') + 1 : (isEndDateFinished ? 0 : 1);
    const dateSelectable = times(nbDaysToInc, (x) => moment(firstDate).add(x, 'day').format('DD MMMM YYYY'));
    return dateSelectable;
  });

  return uniq(mergeArray(...res));
};

export {
  FORMAT_RESTRICTIONS,
  getRangeDateForLive,
};
