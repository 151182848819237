import React from 'react';
import { func, object, array } from 'prop-types';
import { isEmpty } from 'lodash';

// Components
import { Form } from 'antd';
import { Button } from '@seekube-tech/ui-kit';

// Styles & Translations
import styles from '../../styles.less';
import InputMaterial from '../../../../../../components/Input/InputMaterial';

const FormItem = Form.Item;

class MergeNewItem extends React.PureComponent { // eslint-disable-line
  static propTypes = {
    onMerge: func,
    onSkip: func,
    item: object,
    defaultItems: array,
    suggestions: array,
  };

  state = {
    selectedItem: null,
    search: '',
  }

  handleSelectItem = (item) => {
    this.setState({ selectedItem: item });
  }

  handleMerge = () => {
    const {
      props: { item, onMerge },
      state: { selectedItem },
    } = this;

    this.setState({ selectedItem: null });

    onMerge(item, selectedItem);
  }

  handleSkip = () => {
    const { props: { item, onMerge } } = this;

    this.setState({ selectedItem: null });

    onMerge(item, null);
  }

  handleSkipEvent = (item) => {
    const { props: { onSkip } } = this;

    onSkip(item);
  }

  handleSearch = (e) => {
    this.setState({ search: e.currentTarget.value });
  }

  render() {
    const {
      props: { item, defaultItems, suggestions },
      state: { selectedItem, search },
      handleSelectItem, handleMerge, handleSkip, handleSearch, handleSkipEvent,
    } = this;

    const filterItems = !isEmpty(search) ? defaultItems.filter((item) => item && item.label.toLowerCase().indexOf(search.toLowerCase()) > -1) : defaultItems;

    return (
      <div className={styles.mergeNewItemComponent}>
        <h1 className="mainTitle">NOUVEAU CRITERE À TRAITER</h1>
        <div className={styles.itemTitle}>
          <h2>{item.label}</h2>
          {item.event} - {item.parent}
        </div>
        <div className={styles.blocActionContainer}>
          <div className={styles.blocAction}>
            <p>Cette entrée ne doit pas être fusionner avec une existante</p>
            <Button
              style={{ marginBottom: '10px' }}
              onClick={() => handleSkip()}
            >Ne pas fusionner</Button>

            <Button
              color="error"
              onClick={() => handleSkipEvent(item)}
            >Passer au critère suivant</Button>
          </div>
          <div className={styles.blocAction}>
            {selectedItem ? (
              <>
                <p>Fusionner <b>{item.label}</b> avec<br /><b>{selectedItem.label}</b> ?</p>
                <Button onClick={() => handleMerge()}>Fusionner</Button>
              </>
            ) : ''}
          </div>
        </div>

        <div className={styles.suggestions}>
          <h2 className="mainTitle">Suggestion de rapprochement</h2>

          {suggestions.map((s) => (
            <Button
              onClick={() => handleSelectItem(s)}
              variant={selectedItem && selectedItem.label === s.label && s.parent === selectedItem.parent ? 'fill' : 'tonal' }
            >{s.label} <small>{`(${s.parent}${s.parentChoice ? s.parentChoice.label : ''})`}</small></Button>
          ))}
        </div>

        <Form className={styles.inputSuggestion}>
          <FormItem>
            <InputMaterial
              addOnIcon="user"
              label="Chercher un critère"
              type="string"
              onChange={handleSearch}
            />
          </FormItem>
        </Form>

        <ul className={styles.criterionList}>
          {filterItems.map((s) => (
            <li key={`suggestionLi${s._id}`}>
              <Button
                onClick={() => handleSelectItem(s)}
                variant={selectedItem && selectedItem._id === s._id ? 'fill' : 'tonal'}
              >{s.label} <small>{`(${s.parent}${s.parentChoice ? ` ${s.parentChoice.label}` : ''})`}</small></Button>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default MergeNewItem;
