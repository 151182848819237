import React from 'react';
import { Tab } from '@/components/Tab';
import { useFormatMessage } from 'react-intl-hooks';

export const Nav = ({ conferencesQuery, handleClick }) => {
  const t = useFormatMessage();
  const indexSelected = location.pathname.includes('confirmed')  ? 1 : 0;

  return (
    <Tab
      items={[
        {
          label: t({ id: 'event.recruiter.lives.upcoming' }),
          count: conferencesQuery?.data?.countUpcoming,
          onClick: () => handleClick('discovery')
        },
        {
          label: t({ id: 'event.recruiter.lives.past' }),
          count: conferencesQuery?.data?.countPast,
          onClick: () =>  handleClick('confirmed')
        },
      ]}
      defaultIndex={indexSelected}
      index={indexSelected}
    />
  )
}
