import { defineMessages } from 'react-intl';

export default defineMessages({
  organizationsTitle: {
    id: 'admin.organizations.title',
  },
  organizationMembersTitle: {
    id: 'admin.organizations.members.title',
  },
  createOrganization: {
    id: 'admin.organizations.create.organization',
  },
  searchByKeyword: {
    id: 'admin.organizations.search',
  },
  registerOrganization: {
    id: 'admin.organizations.modal.register.organization',
  },
  deleteOrganization: {
    id: 'admin.organizations.modal.delete.organization',
  },
  organizationName: {
    id: 'admin.organizations.modal.input.organization.name',
  },
  addOrganizationSuccessText: {
    id: 'admin.organizations.modal.register.success',
  },
  unPublishedStand: {
    id: 'admin.organizations.detail.unpublished.stand',
  },
  publishedStand: {
    id: 'admin.organizations.detail.published.stand',
  },
  standStatus: {
    id: 'admin.organizations.detail.stand',
  },
  showStand: {
    id: 'admin.organizations.detail.stand.show',
  },
  editStand: {
    id: 'admin.organizations.detail.stand.edit',
  },
  groupedActions: {
    id: 'admin.organizations.members.grouped.actions',
  },
  addOnJobdating: {
    id: 'admin.organizations.members.actions.add.on.jobdating',
  },
  addJobdatingNumber: {
    id: 'admin.organizations.members.actions.add.on.jobdating.number',
  },
  deleteMember: {
    id: 'admin.organizations.members.actions.delete.modal.title',
  },
  detachFromComany: {
    id: 'admin.organizations.members.actions.delete.modal.button.detach.from.company',
  },
  finalDelete: {
    id: 'admin.organizations.members.actions.delete.modal.button.final.delete',
  },
  descriptionDelete: {
    id: 'admin.organizations.members.actions.delete.modal.delete.description',
  },
  managePassword: {
    id: 'admin.organizations.members.actions.edit.password.modal.title',
  },
  sendResetOfPassword: {
    id: 'admin.organizations.members.actions.edit.password.modal.send.reset.password',
  },
  orText: {
    id: 'admin.organizations.members.actions.edit.password.modal.orText',
  },
  editPassword: {
    id: 'admin.organizations.members.actions.edit.password.modal.input.edit.password',
  },
  registerEditedPassword: {
    id: 'admin.organizations.members.actions.edit.password.modal.submit.register.edited.password',
  },
  teamInfosUpdate: {
    id: 'event.recruiter.preparation.team.infos.update',
  },
  cancel: {
    id: 'admin.organizations.cancel',
  },
  confirm: {
    id: 'admin.organizations.confirm',
  },
  delete: {
    id: 'delete'
  },
  deleteOrganizationConfirm: {
    id: 'admin.organizations.detail.confirm.delete',
  },
  searchByRecruiter: {
    id: 'admin.organizations.members.search.input.placeholder',
  },
  deleteCollaboratorConfirm: {
    id: 'admin.organizations.members.actions.delete.collaborator',
  },
  deleteRecruiterFromJd: {
    id: 'recruiter.delete.recruiter.from.jd',
  },
  existError: {
    id: 'event.recruiter.preparation.stand.edit.existError',
  },
  maxRecruiterHelp: {
    id: 'event.recruiter.preparation.team.maxRecruiterHelp',
  },
  warningJobdatingMax: {
    id: 'admin.organizations.members.add.jobdating.warning.max',
  },
  seePipedrive: {
    id: 'admin.organizations.organization.details.seePipedrive',
  },
  add: {
    id: 'add',
  },
  createMember: {
    id: 'admin.organizations.members.actions.create',
  },
  invalid: {
    id: 'admin.organizations.jobdating.add.invalid',
  },
  noJobdating: {
    id: 'admin.organizations.jobdating.add.noResult',
  },
  upcomingEvents: {
    id: 'upcoming.events',
  },
  resultNoJobdating: {
    id: 'no.jobdating',
  },
  remove: {
    id: 'delete',
  },
  timeslotsPl: {
    id: 'timeslots.pl',
  },
  offersPl: {
    id: 'offers',
  },
  livePl: {
    id: 'live.pl',
  },
  tooltipLiveNotAllowed: {
    id: 'client.member.tooltip.not.allow.to.publish.live',
  },
  resendInvitationEmail: {
    id: "recruiter.resendInvitationEmail"
  },
  addToJobdatings: {
    id: "recruiter.addToJobdatings"
  },
  modifyPasswordSuccess: {
    id: 'recruiter.notification.modifyPassword.success'
  },
  resendInvitationEmailSuccess: {
    id: 'recruiter.notification.resendInvitationEmail.success'
  },
  deleteUserSuccess: {
    id: 'recruiter.notification.deleteUser.success'
  },
  changeUserRoleSuccess: {
    id: 'recruiter.notification.changeUserRole.success'
  },
  addEventSuccess: {
    id: 'recruiter.notification.addEvent.success'
  },
  removeFromJobdatingSuccess: {
    id: 'recruiter.notification.removeFromJobdating.success'
  },
  updateCreditsSuccess: {
    id: 'recruiter.notification.updateCredits.success'
  }
});
