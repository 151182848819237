import React from 'react';
import { node, func, string, oneOf, bool } from 'prop-types';
import classnames from 'classnames';

import logoColor from '@/assets/images/logo-color.svg';

import { CloseModal } from '../Buttons/components';

import styles from './styles.less';

class SplitView extends React.PureComponent {
  static propTypes = {
    leftContent: node,
    rightContent: node,
    handleCloseAppointment: func,
    classContainer: string,
    classModalContainer: string,
    logo: bool,
    closePosition: oneOf(['left', 'right', 'none']),
  };

  static defaultProps = {
    closePosition: 'right',
    logo: false,
  }

  render() {
    const { props: { logo, handleCloseAppointment, leftContent, rightContent, classContainer, classModalContainer, closePosition } } = this;

    return (
      <div className={classnames(styles.customModal, classModalContainer)}>
        <div className={classnames(styles.container, classContainer)}>
          <div className={classnames(styles.leftSide, 'leftSide')}>
            {logo ? (<img src={logoColor} className={styles.logo} alt="Seekube" />) : null}
            <div className={classnames(styles.leftContent, 'leftContent')} >
              {leftContent}
            </div>
            {closePosition === 'left' && <CloseModal onClick={handleCloseAppointment} />}
          </div>
          <div className={classnames(styles.rightSide, 'rightSide')}>
            <div className={classnames(styles.rightContent, 'rightContent')}>
              {rightContent}
            </div>
          </div>
        </div>
        {closePosition === 'right' && <CloseModal onClick={handleCloseAppointment} />}
      </div>
    );
  }
}
export default SplitView;
