import React from 'react';
import { string, func, node, any, bool } from 'prop-types';
import classnames from 'classnames';

// Components
import { If } from 'react-if';
import { Tooltip, Icon } from 'antd';

// Styles & Translations
import styles from './styles.less';

/**
 * Tag
 */
const Tag = ({
  children,
  tooltipTitle,
  tooltipPlacement,
  type,
  onClick,
  className,
  icon,
  closable = true,
}) => (
  <div className={classnames(styles.tagContainer, styles[type], className || '')} onClick={onClick || null} style={{ paddingRight: closable ? '1px' : '6px' }} role="button" tabIndex={0}>
    <div className={styles.tagContent}>
      {/* Tooltip */}
      <If condition={typeof tooltipTitle !== 'undefined' && tooltipTitle !== ''}>
        <Tooltip title={tooltipTitle} placement={tooltipPlacement}>
          <Icon type="exclamation-circle-o" className={styles.tooltipChildren} />
        </Tooltip>
      </If>
      <If condition={typeof icon !== 'undefined' && icon !== ''}>
        {icon}
      </If>
      <span className={classnames(styles.children)}>{children}</span>
    </div>
  </div>
);

Tag.propTypes = {
  children: any.isRequired,
  tooltipTitle: string,
  onClick: func,
  tooltipPlacement: string,
  type: string,
  className: string,
  icon: node,
  closable: bool,
};

Tag.defaultProps = {
  tooltipPlacement: 'top',
  type: 'default',
};

export default Tag;
