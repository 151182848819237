import { PHASE_STATE, PHASES } from '@/store/availableActions/utils/const';

export function isPhaseInState(eventPhases = {}, phase = '', state = '', format = undefined) {
  // If event phases is an array, it means we're in a hybrid format event
  // Hence the special treatment
  if (typeof eventPhases === 'object' && eventPhases[phase] instanceof Array) {
    if (state === PHASE_STATE.IS_PAST && format === undefined) {
      return (phase === PHASES.ALL && state === PHASE_STATE.ALL) || eventPhases[phase].reduce((prev, phaseStates) => (
        prev && (phaseStates[state] && (format ? phaseStates.format === format : true))
      ), true) || false;
    }

    return (phase === PHASES.ALL && state === PHASE_STATE.ALL) || eventPhases[phase].reduce((prev, phaseStates) => (
      prev || (phaseStates[state] && (format ? phaseStates.format === format : true))
    ), false) || false;

  }

  return typeof eventPhases === 'object' && (
    (phase === PHASES.ALL && state === PHASE_STATE.ALL) || eventPhases?.[phase]?.[state] || false
  );
}
