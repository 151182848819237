import { isArray, isEmpty, size } from 'lodash';
import classnames from 'classnames';
import styles from '@/scenes/Event/components/StaticOffers/components/Filters/styles.less';
import Selector from '@/components/Form/Selector';
import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { Form } from 'antd';

const FormItem = Form.Item;

export const InformalOrganizationSelector = ({
  isMobile,
  facetKey,
  updateSearch,
  search,
  facetsInformal,
  context,
}) => {
  const t = useFormatMessage();
  let items = [];
  const facetOrganizationName = facetsInformal?.['_organization.name'];
  const options = facetOrganizationName ?
    Object.keys(facetOrganizationName)
      .map((o) => ({
        value: o,
        label: o,
        badgeCount: facetOrganizationName?.[o] || 0
      }))
    : [];

  if (!isEmpty(search) && !isEmpty(search.organizations)) {
    if (isArray(search.organizations)) {
      items = search.organizations;
    } else {
      items = search.organizations.split(',');
    }
  }

  return (
    <FormItem className={classnames(styles.formItem, context === 'exponent' ? styles.formItemBordered : '')} style={{ minWidth: '140px', width: '140px' }}>
      <Selector
        position="left"
        isMobile={!!isMobile}
        searchable={size(options) >= 10}
        label={t({ id: 'conference.owner' })}
        noLabel={t({ id: 'searchEngine.noLabel' })}
        options={options}
        values={items}
        onChange={(e) => {
          updateSearch('organizations', e, facetKey);
        }}
      />
    </FormItem>
  );
}
