import { Body1, Caption2, Tag } from '@seekube-tech/ui-kit';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Col, Row } from 'antd';
import { useFormatMessage } from 'react-intl-hooks';
import { object, string, array } from 'prop-types';
import { EVENT_FORMAT } from '@/utils/constants';
import { EVENT_TYPE } from '@/features/agenda/recruiter/components/BigCalendar/const';
import styles from '@/features/agenda/recruiter/components/BigCalendar/BigCalendarToolbar/styles.less';
import {
  HybridPhysicalSlotsNumber
} from '@/features/agenda/recruiter/components/CalendarFooter/HybridPhysicalSlotsNumber';
import {
  HybridVirtualSlotsNumber
} from '@/features/agenda/recruiter/components/CalendarFooter/HybridVirtualSlotsNumber';
import { NonHybridSlotsNumber } from '@/features/agenda/recruiter/components/CalendarFooter/NonHybridSlotsNumber';


export const FreeSlotsNumber = ({
  exponentFormat,
  calendarSlots,
  event: { keyDates, id, format, modules },
}) => {
  const t = useFormatMessage();
  const [interviewTimeslotAvailable, setInterviewTimeslotAvailable] = useState();
  const [informal1to1TimeslotAvailable, setInformal1to1TimeslotAvailable] = useState();
  const isExponentHybrid = exponentFormat === EVENT_FORMAT.HYBRID;
  const isModuleInformal1to1Enable = modules?.informal1to1?.enable || false;
  const isModuleOfferEnable = modules?.offer?.enable || false;


  useEffect(() => {
    if (calendarSlots) {
      const timeslotAvailable = calendarSlots
        .filter((timeslot) => (
          (format === 'physical' ? timeslot.medium === 'physical' : true) &&
          timeslot.type !== EVENT_TYPE.JOBFAIR &&
          Boolean(timeslot._appointment) === false &&
          (Boolean(timeslot._event) === false || timeslot._event === id) &&
          moment().isBefore(moment(timeslot.start)) &&
          moment(timeslot.start).isBefore(keyDates.jobfair.endAt)
        ))
      setInterviewTimeslotAvailable(timeslotAvailable.filter((timeslot) => timeslot.type === "interview"))
      setInformal1to1TimeslotAvailable(timeslotAvailable.filter((timeslot) => timeslot.type === "informal1to1"))
    }
  }, [calendarSlots]);

  const interviewNumberOfTimeslotAvailable = React.useMemo(() => {
    if (interviewTimeslotAvailable) {
      const numberOfSlotPhysical = interviewTimeslotAvailable.filter((timeslot) => timeslot.medium === "physical").length;
      const numberOfSlotVirtual = interviewTimeslotAvailable.filter((timeslot) => timeslot.medium !== "physical").length;
      return {
        physical: numberOfSlotPhysical,
        virtual: numberOfSlotVirtual
      }
    }

    return {
      physical: 0,
      virtual: 0
    }
  }, [interviewTimeslotAvailable]);


  const informal1to1NumberOfTimeslotAvailable = React.useMemo(() => {
    if (informal1to1TimeslotAvailable) {
      const numberOfSlotPhysical = informal1to1TimeslotAvailable.filter((timeslot) => timeslot.medium === "physical").length;
      const numberOfSlotVirtual = informal1to1TimeslotAvailable.filter((timeslot) => timeslot.medium !== "physical").length;
      return {
        physical: numberOfSlotPhysical,
        virtual: numberOfSlotVirtual
      }
    }

    return {
      physical: 0,
      virtual: 0
    }
  }, [informal1to1TimeslotAvailable]);

  return (
    <>
      <Body1>{t({ id: 'calendar.footer.all.available.slots' })}</Body1>
      <Row type="flex" justify="space-between" align="middle" style={{padding: '0 32px'}} >
        <Row type="flex" align='middle'>
          <Col className={styles.timeslotsInfoContainer}>
            <Row type="flex" align="middle" gutter={8}>
              <Col>
                {((isModuleInformal1to1Enable && isModuleOfferEnable) || isExponentHybrid) ?
                  <Row type='flex' align='middle'>
                    {/* Timeslots virtual */}
                    <HybridVirtualSlotsNumber
                      isModuleOfferEnable={isModuleOfferEnable}
                      isModuleInformal1to1Enable={isModuleInformal1to1Enable}
                      exponentFormat={exponentFormat}
                      interviewNumberOfTimeslotAvailable={interviewNumberOfTimeslotAvailable}
                      informal1to1NumberOfTimeslotAvailable={informal1to1NumberOfTimeslotAvailable}
                    />
                    <HybridPhysicalSlotsNumber
                      isModuleOfferEnable={isModuleOfferEnable}
                      isModuleInformal1to1Enable={isModuleInformal1to1Enable}
                      exponentFormat={exponentFormat}
                      interviewNumberOfTimeslotAvailable={interviewNumberOfTimeslotAvailable}
                      informal1to1NumberOfTimeslotAvailable={informal1to1NumberOfTimeslotAvailable}
                    />
                  </Row>
                  :
                  <Row type='flex' align='middle'>
                    <NonHybridSlotsNumber
                      exponentFormat={exponentFormat}
                      interviewNumberOfTimeslotAvailable={interviewNumberOfTimeslotAvailable}
                      informal1to1NumberOfTimeslotAvailable={informal1to1NumberOfTimeslotAvailable}
                      isModuleOfferEnable={isModuleOfferEnable}
                    />
                  </Row>
                }
              </Col>
            </Row>
          </Col>
        </Row>
      </Row>
    </>
  )
}

FreeSlotsNumber.propTypes = {
  exponentFormat: string,
  calendarSlots: array,
  event: object
}

