import React from 'react';
import { func, bool, object, string, number, array } from 'prop-types';
import { FooterDeleteMode } from '@/features/agenda/recruiter/components/CalendarFooter/FooterDeleteMode';
import { Footer } from '@/features/agenda/recruiter/components/CalendarFooter/Footer';

export const CalendarFooter = ({
  event,
  setIsDeleteModeActive,
  slotSelectedForDelete,
  exponentFormat,
  calendarSlots,
  indexWeek,
  exponentUser,
  isDeleteModeActive,
  settings,
  setModalContext,
  handleBackToJobdating
}) => {
  if (isDeleteModeActive)
    return (
      <FooterDeleteMode
        calendarSlots={calendarSlots}
        setIsDeleteModeActive={setIsDeleteModeActive}
        slotSelectedForDelete={slotSelectedForDelete}
        settings={settings}
        setModalContext={setModalContext}
      />
    )

  return (
    <Footer
      calendarSlots={calendarSlots}
      exponentUser={exponentUser}
      indexWeek={indexWeek}
      event={event}
      exponentFormat={exponentFormat}
      setIsDeleteModeActive={setIsDeleteModeActive}
      isDeleteModeActive={isDeleteModeActive}
      settings={settings}
      handleBackToJobdating={handleBackToJobdating}
      setModalContext={setModalContext}
    />
  )
}

CalendarFooter.propTypes = {
  handleBackToJobdating: func,
  event: object,
  setIsDeleteModeActive: func,
  slotSelectedForDelete: func,
  setModalContext: func,
  exponentFormat: string,
  calendarSlots: array,
  indexWeek: number,
  exponentUser: object,
  settings: object,
  isDeleteModeActive: bool,
}
