import { useMutation, useQueryClient } from 'react-query';
import { map } from 'lodash';
import request from '@/utils/request';

export function useUpdateReport(
  {
    eventId,
  }) {
  const queryClient = useQueryClient();

  const mutationFn = ({ _id, scheduledAt = null, phase }) => {
    const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/reports/${_id}`;

    return (
      request(requestUrl, {
        method: 'PATCH',
        body: JSON.stringify({ scheduledAt, phase }),
      })
    )
  }

  return useMutation({
    mutationFn,
    onSuccess: async (report) => {
      const reports = map(queryClient.getQueryData('reports'),
        (prevReport) => prevReport._id === report._id ? report : prevReport);
      queryClient.setQueryData('reports', () => reports);
    },
  });
}
