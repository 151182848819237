import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { Field } from 'react-final-form';
import { array, object, string } from 'prop-types';
import { Form } from 'antd';
import SearchMatchingSelector from '@/components/SearchMatchingSelector';
import useValidations from '@/utils/validations/useValidations';

const FormItem = Form.Item;

const MatchingContracts = ({
  options,
  form,
  formType = 'final-form',
  initialValue
}) => {
  const t = useFormatMessage();
  const { isFilled } = useValidations();

  if (formType === 'final-form') {
    return (
      <Field name="filters" validate={(value) => isFilled(value)}>
        {props => (
          <SearchMatchingSelector
            options={options}
            placeholder={t({ id: 'contract' })}
            icon="star"
            name="filters"
            onChange={props.input.onChange}
            valueSelected={props.input.value}
            hasError={props.meta.error}
          />
        )}
      </Field>
    )
  }

  return (
    <FormItem>
      {form.getFieldDecorator('matching.filters', {
          initialValue,
          rules: [{
            required: true, message: t({ id: 'form.mandatory' }),
          }]
        })(
        <SearchMatchingSelector
          options={options}
          placeholder={t({ id: 'contract' })}
          icon="star"
          name="filters"
          onChange={(value) => form.setFieldsValue({ 'matching.filters': value })}
          valueSelected={form.getFieldValue('matching.filters') ?? []}
          hasError={form.getFieldError('matching.filters')}
        />
      )}
    </FormItem>
  )
}

MatchingContracts.propTypes = {
  form: object,
  options: array,
  formType: string,
  initialValue: object
}

export default MatchingContracts;
