import React from 'react';
import ParticipantsView from '@/scenes/Event/scenes/Recruiter/scenes/Jobdating/container/ParticipantsView';

/**
 * AdminCandidatesTopScene
 */
class AdminCandidatesSaveScene extends React.PureComponent { // eslint-disable-line
  /**
   * Render
   */
  render() {
    return (
      <ParticipantsView context="adminsave" />
    );
  }
}

export default AdminCandidatesSaveScene;
