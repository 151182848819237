
import { get, isEmpty } from 'lodash';
import moment from 'moment';
import { getTz } from '@/utils/global';
import {
  SEPARATORS_NAMES
} from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Appointments/components/AppointmentsList/const';

/*
 Use to know where to dispatch interactions.
 For instance all completed appointments will be grouped under the section called 'done
 */
  export const getSeparatorName = (interaction, authUser) => {
  const { propositionsModified, accepted, done, proposition, pending, refused} = SEPARATORS_NAMES;

  if (get(interaction, '_appointment.deleted') === true) return '';

  const now = moment().tz(getTz(authUser)).subtract(2, 'hour');
  const appointmentStatus = interaction?._appointment?.status;
  const isPropositionModified = appointmentStatus === 'pending' && interaction?._appointment?.wasAcceptedAndUpdatedAt;
  const isAppointmentRefused = appointmentStatus === 'refused' || appointmentStatus === 'canceled' || (interaction.messageRefused && isEmpty(interaction._appointment));
  const isAppointmentAcceptedOrUnconfirmed = ['accepted', 'unconfirmed'].includes(appointmentStatus);
  const isAppointmentDone = isAppointmentAcceptedOrUnconfirmed && moment(interaction._appointment.date) < now;
  const isPropositions = (['proposition', 'application'].includes(interaction.type) || (interaction.type === 'informal1to1' && interaction._appointment?.acceptedAt)) && appointmentStatus === 'pending';
  const isPending = (interaction.type === 'application' && isEmpty(interaction._appointment)) || interaction.type === 'informal1to1';

  if (isPropositionModified) return propositionsModified;

  if (isAppointmentRefused) return refused;

  if (isAppointmentDone) return done;

  if (isAppointmentAcceptedOrUnconfirmed) return accepted;

  if (isPropositions) return proposition;

  if (isPending) return pending;

  return '';
}

