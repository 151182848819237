import React from 'react';
import { func } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedHTMLMessage } from 'react-intl';
import { Input, Modal } from 'antd';
import { Button, Pagination, Table } from '@seekube-tech/ui-kit';
import { useHistory } from 'react-router-dom';
import { useFormatMessage } from 'react-intl-hooks';
import { toJS } from '@/utils';

import { eventActions } from '@/store/event';

// Components
import Icon from '@/components/Icon';
import Separator from '@/components/Separator';

import { useGetEvents } from '@/queries/events/useGetEvents';
import { Columns } from '@/scenes/Admin/scenes/Dashboard/containers/EventList/Columns';
import styles from '../../styles.less';

const defaultPagination = {
  limit: 20,
  offset: 0,
  keywords: '',
}

const EventList = (props) => {
  const { patchEvent } = props;
  const t = useFormatMessage();
  const [showConfirmDeletionModal, setShowConfirmDeletionModal] = React.useState(false);
  const [search, setSearch] = React.useState(defaultPagination);
  const [currentEvent, setCurrentEvent] = React.useState({});
  const eventsQuery = useGetEvents({ search });

  const history = useHistory();

  function convertOffsetInPage () {
    return Math.floor(search.offset / search.limit) + 1;
  }

  const handleClickEdit = (record) => {
    history.push(`${record.slug}/owner/settings`); // Go to the edit page
  };


  const handleClickDelete = (record) => {
    patchEvent({
      eventId: record.slug,
      event: { deleted: true },
      callback: () => eventsQuery.refetch(),
      notificationParams: {
        success: {
          message: `🙌 ${t({ id: 'event.deletion.notification.success' })}`,
          kind: 'info',
          style: {
            bottom: '5%',
            top: 'inherit',
          },
        },
        error: true,
      },
    });
  };


  const handleOnChange = (page) => {
    const offset = ((page - 1) * search.limit);

    setSearch({ ...search, offset });

    window.scrollTo(0, 0)
  }

  const handleOnChangeSearchText = (e) => {
    const keywords = e.target.value;

    setSearch({ ...search, offset: 0, keywords })
  };

  const openDeleteModal = (record) => {
    setShowConfirmDeletionModal(true);
    setCurrentEvent(record);
  }

  return (
    <div className={styles.eventListsContainer}>
      <div className={styles.searchableWrapper}>
        <Input
          type="text"
          value={search.keywords}
          onChange={handleOnChangeSearchText}
          placeholder="Rechercher"
          prefix={<Icon name="search" className={styles.icon} />}
        />
      </div>

      <Table
        getCustomTableProps={() => ({ style: { width: '100%' } })}
        columns={Columns({ actions: { handleClickEdit, openDeleteModal } })}
        data={eventsQuery?.data?.docs}
        isLoadingData={eventsQuery?.isFetching}
      />

      <Pagination
        className="flex justify-center mt-20"
        pageSize={eventsQuery?.data?.limit}
        total={eventsQuery?.data?.total || 0}
        current={convertOffsetInPage()}
        onChange={handleOnChange}
      />

      <Modal
        visible={showConfirmDeletionModal}
        footer={false}
        maskClosable
        width={470}
        className="customConfirm"
        onCancel={() => setShowConfirmDeletionModal(false)}
      >
        <a role="button" tabIndex={0} className="modal-close" onClick={() => setShowConfirmDeletionModal(false)}>
          <Icon name="close" className="modal-close-icon" />
        </a>

        <h4 className="ant-confirm-title">
          <span role="img" aria-label="warning">⚠️ </span><FormattedHTMLMessage id="event.deletion.modal.confirm" />
        </h4>

        <Separator height={15} />

        <div className="confirm-actions">
          <a className="ant-btn" role="button" tabIndex={0} onClick={() => setShowConfirmDeletionModal(false)}>
            {t({ id: 'cancel' })}
          </a>
          <Button
            onClick={() => {
              handleClickDelete(currentEvent);
              setShowConfirmDeletionModal(false);
            }}
          >
            {t({ id: 'btn.confirm' })}
          </Button>
        </div>
      </Modal>
    </div>
  );
}

EventList.propTypes = {
  patchEvent: func,
};


const mapDispatchToProps = {
  setCurrentEvent: eventActions.setCurrentEvent,
  patchEvent: eventActions.patchEvent,
};

const withConnect = connect(null, mapDispatchToProps);

export default compose(
  withConnect,
)(toJS(EventList));
