import { isEmpty } from 'lodash';
import moment from 'moment/moment';

/**
* Return the earliest key date or key moment regarding jobfair (by default)
* in the event
*/
export function getEarliestKeyMoment(event, momentType = "jobfair", format) {
  /**
   * Do not filter if format is undefined
   */
  const keyMoments = format ?
  event?.keyMoments?.[momentType].filter(keyMoment => keyMoment.format === format) :
  event?.keyMoments?.[momentType];

  if (!isEmpty(keyMoments)) {
    return keyMoments.reduce((prev, keyMoment) => {
      if (moment(prev).isBefore(moment(keyMoment.beginAt))) {
        return prev
      }

      return moment(keyMoment.beginAt)
    }, moment(keyMoments[0].beginAt))
  }

  if (event?.keyDates) {
    return event.keyDates?.[momentType]?.beginAt ? moment(event.keyDates?.[momentType]?.beginAt) : undefined;
  }

  return undefined
}
