import { useMutation } from 'react-query';
import request from '@/utils/request';

export function usePostOauthInfos({ onSuccess, provider }) {
  
  const mutationFn = (user) => {
    const requestUrl = `${process.env.FRONT_API_URL}/users/oauth/infos`;

    return (
      request(requestUrl, {
        method: 'POST',
        body: JSON.stringify({
          token: user._token.accessToken,
          provider,
        }),
      })
    )
  }

  return useMutation({
    mutationFn,
    onSuccess
  });
}