import React, { useState } from 'react';
import { object } from 'prop-types';
import { Body1 } from '@seekube-tech/ui-kit';
import classNames from 'classnames';
import styles from './styles.less'
import ModalPictures from './ModalPicture';

const Gallery = ({
  profile,
}) => {

  if (!profile?.pictures) {
    return null;
  }

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [pictureIndexInitial, setPictureIndexInitial] = useState(0)

  return (
    <div className={styles.picturesContainer}>
    {isModalVisible &&
      <ModalPictures setIsModalVisible={setIsModalVisible} profile={profile} pictureIndexInitial={pictureIndexInitial} />
    }
      {profile.pictures.map((picture, key) => {
        if (key > 4) {
          return null
        }
        if ( key === 4 && profile.pictures.length > 5) {
          return (
            <div
              onClick={() => {setPictureIndexInitial(key); setIsModalVisible(true)}}
              className={classNames(styles.picture, styles.pictureDisabled)}
              key={key}>
              <img src={picture} />
              <div className={styles.textContainer}>
                {profile.pictures.length > 5 && <Body1 fontWeight={800}>{profile.pictures.length - 5} de plus</Body1>}
              </div>
            </div>
          )
        }
        return (
          <div
            onClick={() => {setPictureIndexInitial(key); setIsModalVisible(true)}}
            className={classNames(styles.picture, key < 2 && styles.pictureFirstLine)} key={key}
          >
            <img src={picture} />
          </div>
        )
      })}
    </div>
  )
}

Gallery.propTypes = {
  profile: object
}

export default Gallery;
