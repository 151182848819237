import React from 'react';
import NewPasswordForm
  from '@/scenes/ResetPassword/components/ResetPasswordModal/components/NewPassword/NewPasswordForm';
import { NewPasswordUpdatedSuccessfully }
  from '@/scenes/ResetPassword/components/ResetPasswordModal/components/NewPassword/NewPasswordUpdatedSuccessfully';
import { usePostChangePassword } from '@/queries/users/usePostChangePassword';


const NewPassword = () => {
  const changePasswordQuery = usePostChangePassword();

  return (
    changePasswordQuery.isSuccess ?
      <NewPasswordUpdatedSuccessfully />
      : <NewPasswordForm changePasswordQuery={changePasswordQuery} />
  );
}

export { NewPassword };
