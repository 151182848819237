import React, { useState } from 'react';
import { func, object, string  } from 'prop-types';
import InputMaterial from '@/components/Input/InputMaterial';
import messages from '../../messages';

const OfferTitleForm = ({intl, handleOnFormedChange, initialValue}) => {
  
  const [value, setValue] = useState(initialValue);

  const onFormChange = ({ target: { value } }) => {
    setValue(value);
    handleOnFormedChange(value);
  };

  return (
    <InputMaterial
      addOnIcon="attachment"
      label={intl.formatMessage(messages.titleLabel)}
      placeholder={intl.formatMessage(messages.titlePlaceholder)}
      type="string"
      required
      value={value}
      withInputErrorStyle
      showHelper={() => {}}
      id="title"
      onChange={onFormChange}
    />
  );
}

OfferTitleForm.propTypes = {
  initialValue: string,
  handleOnFormedChange: func,
  intl: object,
}

export default OfferTitleForm;
