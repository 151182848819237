export function convertToPrivacyEnhancedUrl(videoUrl) {
  if (isYouTubeUrl(videoUrl)) {
    let url = videoUrl
      .replace("youtu.be/","youtube.com/embed/")
      .replace("watch?v=","embed/")
      .replace("/watch/", "/embed/");

    url = url.split('&')[0];

    // For YouTube URLs, replace www.youtube.com with www.youtube-nocookie.com
    return url
      .replace('www.youtube.com', 'www.youtube-nocookie.com')
      .replace('/youtube.com', '/www.youtube-nocookie.com');
  }

  if (isVimeoUrl(videoUrl)) {
    // For Vimeo URLs, replace vimeo.com with player.vimeo.com
    return videoUrl.replace('vimeo.com/', 'player.vimeo.com/video/');
  }

  console.error(videoUrl, 'Unsupported video provider or invalid URL');
  return videoUrl;
}

export function isYouTubeUrl(url) {
  const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com\/(watch\?v=|embed\/|v\/)|youtu\.be\/)/;
  return youtubeRegex.test(url);
}

export function isVimeoUrl(url) {
  // Vimeo URLs can have various formats, so we'll use a more flexible regex
  const vimeoRegex = /^(https?:\/\/)?(www\.)?(vimeo\.com\/(video\/)?(\d+|[a-zA-Z0-9]+))/;
  return vimeoRegex.test(url);
}

// Example usage:
const youtubeUrl = 'https://www.youtube.com/watch?v=your_video_id';
const vimeoUrl = 'https://vimeo.com/your_video_id';

const privacyEnhancedYouTubeUrl = convertToPrivacyEnhancedUrl(youtubeUrl);
const privacyEnhancedVimeoUrl = convertToPrivacyEnhancedUrl(vimeoUrl);

if (privacyEnhancedYouTubeUrl) {
  console.log('YouTube nocookie URL:', privacyEnhancedYouTubeUrl);
}

if (privacyEnhancedVimeoUrl) {
  console.log('Vimeo player URL:', privacyEnhancedVimeoUrl);
}
