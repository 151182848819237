import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'event.candidate.jobdating.offer.applicationModal.title',
  },
  subtitle: {
    id: 'event.candidate.jobdating.offer.applicationModal.subtitle',
  },
  apply: {
    id: 'event.candidate.jobdating.offer.applicationModal.apply',
  },
  submit: {
    id: 'submit',
  },
  matchingWarning: {
    id: 'event.candidate.jobdating.offer.applicationModal.matchingWarning',
  },
  applyConfirmation: {
    id: 'event.candidate.jobdating.jobs.applyConfirmation',
  },
  applyConfirmationTitle: {
    id: 'event.candidate.jobdating.jobs.applyConfirmation.title',
  },
  applyConfirmationTitleAlt: {
    id: 'event.candidate.jobdating.jobs.applyConfirmation.titleAlt',
  },
  applyConfirmationText: {
    id: 'event.candidate.jobdating.jobs.applyConfirmation.text',
  },
  forceApplyConfirmationTitle: {
    id: 'event.candidate.jobdating.jobs.applyConfirmation.title',
  },
  offer: {
    id: 'event.candidate.jobdating.offer.applicationModal.offer',
  },
  step1: {
    id: 'appointment.footer.step.criterion',
  },
  step2: {
    id: 'appointment.footer.step.slot',
  },
  aboutOf: {
    id: 'aboutOf',
  },
  confirmationLightTitle: {
    id: 'event.candidate.jobdating.jobs.confirmationLight.title',
  },
  confirmationLightText: {
    id: 'event.candidate.jobdating.jobs.confirmationLight.text',
  },
  confirmationLightCta: {
    id: 'event.candidate.jobdating.jobs.confirmationLight.cta',
  },
});
