import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';

// Styles & Translations
import styles from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Exponents/scenes/Exponent/components/Home/Sidebar/styles.less';
import { boolean, object, string } from 'prop-types';
import SidebarSectionTitle from './SidebarSectionTitle';

const SidebarSection = ({
  title,
  children,
  hidden,
  popover
}) => {
  const t = useFormatMessage();

  if (!hidden) {
    return <div className={styles.section}>
      <SidebarSectionTitle title={title} popover={popover}>
      </SidebarSectionTitle>
      {children}
    </div>
  }
  return null;
}

SidebarSection.propTypes = {
  title: string,
  children: object,
  hidden: boolean,
  popover: object
}

export default SidebarSection
