import { useMutation, useQueryClient } from 'react-query';
import { filter } from 'lodash';
import request from '@/utils/request';

export function useDeleteConference(
  {
    onSuccess = () => {},
  }) {
  const queryClient = useQueryClient();

  const mutationFn = ({ _id }) => {
    const requestUrl = `${process.env.FRONT_API_URL}/conferences/${_id}`;

    return (
      request(requestUrl, {
        method: 'DELETE',
      })
    )
  }

  return useMutation({
    mutationFn,
    onMutate: async (conference) => {
      const conferences = queryClient.getQueryData(['conferences']);
      const newDocs = filter(conferences?.docs, (prevConferences) => prevConferences._id !== conference._id);
      const newConferences = { ...conference, docs: newDocs };

      queryClient.setQueryData(['conferences'], newConferences);

      return newConferences;
    },
    onSuccess
  });
}
