import { makeStyles } from '@seekube-tech/ui';

export default makeStyles(theme => ({
  card: {
    marginTop: theme.spacing(6),
  },
  required: {
    marginTop: theme.spacing(2),
  },
  asterisk: {
    color: theme.palette.error[500],
  },
  field: {
    marginTop: theme.spacing(1.5),
  },
  samplesContainer: {
    marginTop: theme.spacing(4),
  },
  samples: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(1.5),
    backgroundColor: theme.palette.neutral[100],
    borderRadius: 4,
  },
  logoContainer: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(2.5, 2),
    borderRadius: 5,
    backgroundColor: theme.palette.basic.white,

    '& svg': {
      fill: theme.palette.basic.white,
    },
  },
  seekube: {
    fontWeight: 600,
  },
  seekubeInfo: {
    textTransform: 'uppercase',
    fontWeight: 700,
    marginTop: theme.spacing(0.5),
  },
  sendBtn: {
    marginTop: theme.spacing(4.5),
  },
}));