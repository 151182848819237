import styles from '@/scenes/Event/components/StaticOffers/components/Filters/styles.less';
import Selector from '@/components/Form/Selector';
import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { Form } from 'antd';
import { sortByOccurence } from '@/scenes/Event/scenes/Candidate/scenes/JobDating/components/SearchEngine/helpers';
import {
  getLabel
} from '@/scenes/Event/scenes/Recruiter/scenes/Preparation/scenes/Offer/containers/CreateOfferModal/components/CreateOfferForm/components/CriteriaFields/utils';

const FormItem = Form.Item;

export const ContractSelector = ({
  isMobile,
  matching,
  updateMatching,
  criterias,
  facets,
}) => {
  const t = useFormatMessage();

  const contractOptions = criterias.find((c) => c.key === 'CONTRACT');

  if (!contractOptions?.modules?.searchEngineParticipant) return '';

  const options = contractOptions._choices
    .filter((o) => o.enable)
    .map((o) => ({
      value: o._id,
      label: getLabel(o),
      badgeCount: getFacetFromKey('CONTRACT', o._id) }))

  const filtredOptions = options.filter((o) => o !== undefined);

  return (
    <FormItem className={[styles.formItem, styles.formItemBordered].join(' ')} style={{ width: '160px' }}>
      <Selector
        position="center"
        isMobile={!!isMobile}
        label={getLabel(contractOptions.modules.searchEngineParticipant)}
        noLabel={t({ id: 'searchEngine.noLabel' })}
        options={sortByOccurence(filtredOptions)}
        values={matching.filters.flat().filter((v) => filtredOptions.find((c) => c?.value === v))}
        onChange={(e) => updateMatching(matching.filters.flat().filter((v) => !filtredOptions.find((c) => c.value === v)), e, 'CONTRACT')}
      />
    </FormItem>
  );

  function getFacetFromKey (key, value) {
    return (facets && typeof facets[`matching.${key}`] === 'object') ? facets[`matching.${key}`][value] || 0 : 0;
  }
}
