import React, { useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import { Element } from 'react-scroll';
import { isEmpty, set } from 'lodash';
import { useSelector } from 'react-redux';
import * as Components from '@/scenes/Event/containers/Landing/components/Contents/components/GeneralInfos/components/LandingContent/components';
import { getExponentsStats } from '@/store/exponent/selectors';
import { getAuthUser } from '@/store/auth/selectors';
import { useGetExponentsLanding } from '@/queries/exponent/useGetExponentsLanding';
import styles from '@/scenes/Event/containers/Landing/components/Contents/components/GeneralInfos/components/LandingContent/styles.less';

const LandingContent = ({
  event,
  editMode,
  onChange,
  currentLocale,
}) => {

  if (event.size <= 0) {
    return null;
  }
  const [currentEvent, setCurrentEvent] = useState(event)

  const authUser = useSelector(getAuthUser)?.toJS()
  const exponents = useGetExponentsLanding({ eventId: event._id })?.data?.docs || []
  const exponentsStats = useSelector(getExponentsStats)?.toJS()

  const locale = currentLocale || authUser?.locale || 'fr';

  const handleOnInit = (contentIndex) => (defaultValue) => {
    if (!editMode) {
      return false;
    }

    const baseContent = event?._contents[contentIndex];
    const contentFr = event?._contents[contentIndex]?.fr;
    const contentEn = event?._contents[contentIndex]?.en;

    if (baseContent === null && contentFr === null && contentEn === null) return false;

    const newEvent = currentEvent;

    newEvent._contents[contentIndex] = {
      ...defaultValue,
      ...baseContent,
      content: {
        ...defaultValue?.content,
        ...baseContent.content,
      },
      fr: {
        ...defaultValue,
        ...contentFr,
      },
      en: {
        ...defaultValue,
        ...contentEn,
      },
    };

    setCurrentEvent(newEvent);

    return onChange(newEvent);
  };

  const handleOnChange = (key) => (value) => {
    if (!editMode) {
      return false;
    }

    const newEvent = currentEvent;
    setCurrentEvent(set(newEvent, key, value));

    return onChange(set(newEvent, key, value));
  };

  const componentMapping = {
    gallery: Components.Gallery,
    numbers: Components.Numbers,
    organizations: Components.Organizations,
    roadmap: Components.Roadmap,
    partners: Components.Partners,
    separator: Components.Separator,
    text: Components.Text,
  };

  return event._contents
    .map((content, index) => {
      const Component = componentMapping[content.name];
      const name = `content_${index}`;

      if (!Component) {
        return null
      }

      return (
        <Element name={name} key={index} className={styles.contentLanding}>
          <Component
            key={index}
            locale={locale}
            event={currentEvent}
            contentIndex={index}
            content={currentEvent._contents[index]}
            editMode={editMode}
            authUser={authUser}
            exponentsStats={exponentsStats}
            exponents={exponents?.filter((exponent) => exponent?._organization?.profile && !isEmpty(exponent?._organization?.profile?.pictureUrl))}
            onInit={handleOnInit}
            onChange={handleOnChange}
          />
        </Element>);
    });
}

LandingContent.propTypes = {
  event: object,
  editMode: bool,
  onChange: func,
  currentLocale: string,
}

export { LandingContent };
