import { defineMessages } from 'react-intl';

export default defineMessages({
  jobdatings: {
    id: 'header.client.jobdatings',
  },
  offers: {
    id: 'header.client.offers',
  },
  stats: {
    id: 'stat.pl',
  },
  live: {
    id: 'live.pl',
  },
  stand: {
    id: 'header.client.stand',
  },
  members: {
    id: 'header.client.members',
  },
});
