import React from 'react';
import { FormattedMessage } from 'react-intl';
import { func } from 'prop-types';
import { Button } from '@seekube-tech/ui-kit';
import styles from './styles.less';

const Result = ({ nextStep, setContext }) => (
  <div className={styles.result}>
    <FormattedMessage tagName="h2" id="seeklive.test.result.title" />
    <hr />

    <FormattedMessage tagName="div" id="seeklive.test.result.text" />

    <div className={styles.actions}>
      <Button onClick={() => { nextStep(); setContext('yes'); }}>
        <FormattedMessage id="seeklive.test.result.btn.yes" />
      </Button>
      <Button onClick={() => { nextStep(); setContext('no'); }}>
        <FormattedMessage id="seeklive.test.result.btn.no" />
      </Button>
    </div>
  </div>
);

Result.propTypes = {
  nextStep: func,
  setContext: func,
};

export default Result;
