import React, { Fragment } from 'react';
import { object, func } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { useSelector } from 'react-redux';
import { interactionSelectors } from '@/store/interaction';
import CardOffer from '@/components/CardOffer';

const CardOffers = ({
  exponent,
  event,
  offersInfiniteQuery
}) => {
  const interactions = useSelector(interactionSelectors.getInteractions)?.toJS();
  const t  = useFormatMessage();

  const getInteraction = (offer) => interactions?.find(int => int._offer?._id === offer._id);

  return (
    offersInfiniteQuery?.data?.pages?.map(page => page?.docs?.map((offer) => (
      <Fragment key={offer._id}>
        <CardOffer
          offer={offer}
          event={event}
          interaction={getInteraction(offer)}
          avatar={exponent._organization?.profile?.pictureUrl}
          action={t({ id: 'show.offer' })}
          onClickAction={() => window.open(`/${exponent?._event?._id}/candidate/jobdating/jobs/${offer._id}`, '_blank')}
        />
      </Fragment>
    ))) || null
  )
}

CardOffers.propTypes = {
  exponent: object,
  event: object,
  offersInfiniteQuery: func
}

export { CardOffers };
