import * as React from 'react';
import { object, string } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { useSelector } from 'react-redux';
import { H5, IconMapPin, IconWifi, Table } from '@seekube-tech/ui-kit';
import classNames from 'classnames';
import { EVENT_FORMAT } from '@/utils/constants';
import { isEventHybrid } from '@/utils/event';
import { getCurrentEvent } from '@/store/event/selectors';
import { REPORT_PHASES } from '../../../const';
import Columns from './Columns';
import { getDataSource } from './getDataSource';
import styles from './ReportData.less';

function ReportDataFormat({ keyMomentFormat }) {
  const t = useFormatMessage();

  if (keyMomentFormat && keyMomentFormat !== EVENT_FORMAT.HYBRID) {
    return (
      <div className={styles.format}>
        <div className={styles.icon}>{ keyMomentFormat === EVENT_FORMAT.VIRTUAL ? <IconWifi size="16px" /> : <IconMapPin size="16px" /> }</div>
        <H5>{t({id: keyMomentFormat })}</H5>
      </div>
    )
  }

  return null;
}

ReportDataFormat.propTypes =  {
  keyMomentFormat: string
}

function ReportData({ report }) {
  const t = useFormatMessage();
  const event = useSelector(state => getCurrentEvent(state)?.toJS())

  if ([
    REPORT_PHASES.registrationAndPreparationOfCompanies,
    REPORT_PHASES.registrationAndActivitiesOfCandidates,
    REPORT_PHASES.appointmentProcessing,
  ].includes(report?.phase)) {
    return report?.snapshots?.map((snapshot, i) => (
      <div className={classNames({
          [styles.formatContainer]: isEventHybrid(event),
          [styles.lastTable]: report?.snapshots.length > 0 && i === report?.snapshots.length - 1
        })}>
        <ReportDataFormat keyMomentFormat={snapshot?.keyMomentFormat} />
        <Table
          getCustomTableProps={() => ({ style: { width: '100%', textAlign: 'left' } })}
          getCellProps={() => ({ style: { width: '200%', fontSize: '14px !important', textAlign: 'left' } })}
          getHeaderProps={() => ({ style: { textAlign: 'left' } })}
          getRowProps={() => ({ style: { textAlign: 'left' } })}
          size="default"
          columns={Columns(report)}
          data={getDataSource(report.phase, snapshot, event)}
        />
      </div>))
    }

  if ([REPORT_PHASES.closingOfAppointmentSchedules, REPORT_PHASES.review].includes(report?.phase)) {
    const snapshot = report?.snapshots[0];
    return (
    <div className={classNames({ [styles.formatContainer]: isEventHybrid(event) })}>
      <ReportDataFormat keyMomentFormat={report?.keyMomentFormat} />
      <div className="flex" style={{ marginBottom: '24px'}}>
        <Table
          getCustomTableProps={() => ({ style: { width: '50%', marginRight: '20px' } })}
          getCellProps={() => ({ style: { width: '200%', fontSize: '14px !important' } })}
          size="default"
          columns={Columns(report, t({ id: 'preparation.of.companies' }))}
          data={getDataSource(report.phase, snapshot, event)[0]}
        />
        <Table
          getCustomTableProps={() => ({ style: { width: '50%' } })}
          getCellProps={() => ({ style: { width: '200%', fontSize: '14px !important' } })}
          size="default"
          columns={Columns(report, t({ id: 'registration.candidates' }))}
          data={getDataSource(report.phase, snapshot, event)[1]}
        />
      </div>
      <Table
        getCustomTableProps={() => ({ style: { width: '100%' } })}
        getCellProps={() => ({ style: { width: '200%', fontSize: '14px !important' } })}
        size="default"
        columns={Columns(report, t({ id: 'appointment' }))}
        data={getDataSource(report.phase, snapshot, event)[2]}
      />
    </div>)
  }

  /**
   * This is an unsupported report type
   */
  return <span>Error</span>
}

ReportData.propTypes = {
  report: object
}

export default ReportData;
