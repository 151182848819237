import React from 'react';
import { object, string, array, func } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { Dropdown, Menu } from 'antd';
import { Button, IconChevronDown } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { patchMe } from '@/store/user/actions';
import { authSelectors } from '@/store/auth';
import { userActions } from '@/store/user';
import { toJS } from '@/utils';
import { getLanguages, currentLocale } from '@/containers/LanguageProvider/selectors';
import { changeLocale } from '@/containers/LanguageProvider/actions';
import { LOCAL_STORAGE, setLocalStorage } from '@/utils/localStorage';

const { Item: MenuItem } = Menu;

const LanguageSelector = ({
  authUser, changeLocale, languages, locale,
}) => {
  const t = useFormatMessage();
  const dispatch = useDispatch();
  const handleChangeLocale = (local) => {

    if (window.moment) {
      window.moment.locale(locale);
    }

    if (locale !== local) {
      setLocalStorage(LOCAL_STORAGE.global.locale, local);

      if (authUser) {
        dispatch(patchMe({
          userParams: { locale: local },
          notificationParams: {
            success: {
              message: t({ id: 'notifications.update.success' }),
              kind: 'info',
              style: {
                bottom: '5%',
                top: 'inherit',
              },
            },
          }
        }));
      } else {
        changeLocale(local);
      }
    }
  };

  if (!languages) {
    return '';
  }

  const currentLocale = languages.filter((lang) => locale === lang.id);

  return (
    <Dropdown
      overlay={
        <Menu>
          {languages.map((item) => (
            <MenuItem key={item.id}><a key={item.id} onClick={() => handleChangeLocale(item.id)} role="button" tabIndex={0}>{item.label}</a></MenuItem>
          ))}
        </Menu>
      }
      trigger={['click']}
      placement="bottomRight"
    >
      <Button
        variant="outline"
        color="neutral"
        imageComponentRight={<IconChevronDown size={16} />}
      >
        {currentLocale ? currentLocale[0].label : ''}
      </Button>
    </Dropdown>
  );
}

LanguageSelector.propTypes = {
  languages: array,
  locale: string,
  authUser: object,
  changeLocale: func,
};

const mapStateToProps = createStructuredSelector({
  languages: getLanguages,
  locale: currentLocale,
  authUser: authSelectors.getAuthUser,
});

const mapDispatchToProps = {
  patchUser: userActions.patchUser,
  changeLocale,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(toJS(LanguageSelector));
