import { isParticipantFormatPhysical } from '@/store/availableActions/utils/user';
import { getEventParticipation } from '@/store/availableActions/utils/user/getEventParticipation';
import { getEventDate } from '@/helpers/event/getEventDate';

export function getJobfairData (event, participant) {
  const location = isParticipantFormatPhysical(participant) || event.format === 'physical'  ? event.location.name : null;
  const { beginAt, endAt } = getEventParticipation(event, participant);

  return {
    date: getEventDate({ beginAt, endAt }),
    location,
  };
}

export function isMoreThanXPercentage(total, number, percentage = 10) {
  return (total > (percentage/100) * number);
}