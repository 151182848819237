import React from 'react';
import { object, func, number } from 'prop-types';
import { Body1, Button, H3, Link1 } from '@seekube-tech/ui-kit';
import { DatePicker, Form, Select as SelectAntd } from 'antd';
import { useFormatMessage } from 'react-intl-hooks';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import thisStyles from '@/scenes/Admin/scenes/Organizations/scenes/Organization/scenes/Details/styles.less';
import Select from '@/components/Form/Select';
import { DEAL_NAME } from '@/utils/deals';
import { InputNumber } from '@/components/Input';
import { RemoveDealBtn } from '@/scenes/Admin/scenes/Organizations/scenes/Organization/scenes/Details/components/DealsHandler/RemoveDealBtn';
import { useCreateDeal } from '@/queries/deals/useCreateDeal';
import { useUpdateDeal } from '@/queries/deals/useUpdateDeal';
import { useGetDeals } from '@/queries/deals/useGetDeals';
import { useDeleteDeal } from '@/queries/deals/useDeleteDeal';
import ModalV2 from '@/components/ModalV2';
const { RangePicker } = DatePicker;
const { Option } = SelectAntd;

const FormItem = Form.Item;

const rangePickerParams = {
  format: 'DD-MM-YYYY',
  placeholder: ['jj-mm-aaaa', 'jj-mm-aaaa'],
  showTime: {
    defaultValue: [moment('07:00:00', 'HH:mm:ss'), moment('23:00:00', 'HH:mm:ss').add(5, 'day')],
  },
};
const datePickerParams = {
  format: 'DD-MM-YYYY',
  showTime: {
    defaultValue: moment('07:00:00', 'HH:mm:ss'),
  },
};
const NOTION_DEAL_URL = "https://www.notion.so/seekube/57bb4f915a32428c882fd4c481aaba4b?v=dd806b3aed3b47b88a1c0f5b13114c18";

const CreateOrUpdateDealModal = ({
  organization,
  setHandleDealModal,
  handleDealModal,
  currentPage,
  form: { getFieldValue, getFieldDecorator, validateFields, resetFields },
}) => {
  const t = useFormatMessage();

  const handleRemove = () => {
    mutateDeleteDeal.mutate({ _id: handleDealModal.id })
  }

  const dealsQuery = useGetDeals({ organizationId: organization._id, page: currentPage });

  const mutateCreateDeals = useCreateDeal({
    organizationId: organization._id,
    onSuccess: () => {
      setHandleDealModal({ visible: false, id: null, deal: null });
      dealsQuery.refetch();
    }
  });
  const mutateUpdateDeal = useUpdateDeal({
    page: currentPage,
    organizationId: organization._id,
    onSuccess: () => {
      setHandleDealModal({ visible: false, id: null, deal: null });
    }
  });

  const mutateDeleteDeal = useDeleteDeal({
    page: currentPage,
    onSuccess: () => {
      setHandleDealModal({ visible: false, id: null, deal: null });
    }
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    validateFields((err, values) => {
      const isPeriod = values.deal.date?.length === 2;

      if (values.deal.oneShotAt)  {
        values.deal.oneShotAt = values.deal.oneShotAt.format('YYYY-MM-DD')
      }

      if (isPeriod) {
        values.deal = {
          ...values.deal,
          beginAt: values.deal.date[0].format('YYYY-MM-DD'),
          endAt: values.deal.date[1].format('YYYY-MM-DD')
        }
      }

      if (handleDealModal.id) {
        mutateUpdateDeal.mutate({
          body: { ...values.deal, _organization: organization._id },
          _id: handleDealModal.id,
        });
      } else {
        mutateCreateDeals.mutate({ ...values.deal })
      }
    })
  }

  return (
    <ModalV2
      template="defaultSophie"
      visible={handleDealModal.visible}
      isClosable
      onClose={() => {
        setHandleDealModal({ visible: false, id: handleDealModal.id, deal: handleDealModal.deal });
        resetFields();
      }}
    >
      <H3>{ handleDealModal.id === null ? t({ id: 'form.deal.title.add' }) : t({ id: 'form.deal.title.update' }) }</H3>
      <Form onSubmit={handleSubmit} className="mt-20 pb-20">
        <div className="mb-20">
          <FormItem>
            {getFieldDecorator('deal.name', {
              initialValue: handleDealModal.deal?.name,
              rules: [{ required: true, message: <FormattedMessage id="admin.organizations.create.organization" /> }],
            })(
              <Select
                label={t({ id: 'form.deal.name.label' })}
                placeholder={t({ id: 'form.deal.name.label' })}
                value={ handleDealModal.deal?.name}
              >
                <Option key={DEAL_NAME.thematic_one_shot} value={DEAL_NAME.thematic_one_shot}>
                  {t({ id: 'form.deal.select.thematic_one_shot' })}
                </Option>
                <Option key={DEAL_NAME.thematic_subscription} value={DEAL_NAME.thematic_subscription}>
                  {t({ id: 'form.deal.select.thematic_subscription' })}
                </Option>
                <Option key={DEAL_NAME.credit_subscription} value={DEAL_NAME.credit_subscription}>
                  {t({ id: 'form.deal.select.credit_subscription' })}
                </Option>
                <Option key={DEAL_NAME.thematic_party_one_shot} value={DEAL_NAME.thematic_party_one_shot}>
                  {t({ id: 'form.deal.select.thematic_party_one_shot' })}
                </Option>
                <Option key={DEAL_NAME.seekalendar_subscription} value={DEAL_NAME.seekalendar_subscription}>
                  {t({ id: 'form.deal.select.seekalendar_subscription' })}
                </Option>
              </Select>
            )}
          </FormItem>

          {getFieldValue('deal.name') === DEAL_NAME.credit_subscription
            && <FormItem>
              <label>
                <Body1 className="text-neutral-500">
                  {t({ id: 'form.deal.credit_subscription.label' })}
                  <span className="mandatory-symbol">*</span>
                </Body1>
              </label>
              {getFieldDecorator('deal.creditQty', {
                initialValue: handleDealModal.deal?.creditQty,
                rules: [{ required: true, message: ' ' }],
              })(
                <InputNumber
                  style={{ width: '100%' }}
                  min={1}
                  customSize="small"
                  label={t({ id: 'form.deal.credit_subscription.label' })}
                  placeholder={t({ id: 'form.deal.credit_subscription.label' })} type="text"
                  validations={['required', 'validator']}/>,
              )}
            </FormItem>}

          <FormItem>
            <label>
              <Body1 className="text-neutral-500">
                {t({ id: 'form.deal.rangepicker.date' })}
                <span className="mandatory-symbol">*</span>
              </Body1>
            </label>
            {
              [DEAL_NAME.thematic_subscription, DEAL_NAME.credit_subscription, DEAL_NAME.seekalendar_subscription].includes(getFieldValue('deal.name')) ?
                getFieldDecorator('deal.date', {
                  rules: [
                    { required: true, message: t({ id: 'event.basic.input.keydates.required' }) },
                  ],
                  initialValue: [
                    moment(handleDealModal?.deal?.beginAt),
                    handleDealModal?.deal?.endAt ?
                      moment(handleDealModal?.deal?.endAt)
                      : moment(handleDealModal?.deal?.endAt).add(1, 'year')],
                })(
                  <RangePicker
                    {...rangePickerParams}
                  />
                )
                : getFieldDecorator('deal.oneShotAt', {
                  rules: [
                    { required: true, message: t({ id: 'event.basic.input.keydates.required' }) },
                  ],
                  initialValue: moment(handleDealModal?.deal?.oneShotAt) || moment(),
                })(
                  <DatePicker
                    className={thisStyles.datePicker}
                    {...datePickerParams}
                  />
                )
            }
          </FormItem>
          <div className={thisStyles.hintContainer}>
            {
              [DEAL_NAME.thematic_one_shot, DEAL_NAME.company_one_shot,
                DEAL_NAME.movie_party_one_shot, DEAL_NAME.thematic_party_one_shot].includes(getFieldValue('deal.name')) && (
                <Body1 className="text-neutral-300">
                  {t({ id: 'admin.organizations.modal.deal.oneshot.hint' })}
                </Body1>
              )
            }
            <Link1
              target="_blank"
              href={NOTION_DEAL_URL}
              className={thisStyles.aboutDeal}
            >
              {t({ id: 'admin.organizations.modal.about.deals' })}
            </Link1>
          </div>
          { handleDealModal.id === null &&
            <Button
              variant="fill"
              type="submit"
              loading={mutateCreateDeals.isLoading}
              disabled={mutateCreateDeals.isLoading}
            >
              {t({ id: 'save' })}
            </Button>
          }
          { handleDealModal.id !== null &&
            <div className="flex">
              <RemoveDealBtn onRemove={handleRemove} isLoading={mutateDeleteDeal.isLoading} />
              <Button
                variant="fill"
                type="submit"
                className="w-full whitespace-nowrap"
              >
                {t({ id: 'form.deal.btn.update' })}
              </Button>
            </div>
          }
        </div>
      </Form>
    </ModalV2>
  )
}

CreateOrUpdateDealModal.propTypes = {
  currentPage: number,
  handleDealModal: object,
  organization: object,
  setHandleDealModal: func,
  form: object,
}

export default Form.create()(CreateOrUpdateDealModal)
