import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Timeline as TimelineAntD } from 'antd';
import styles from './styles.less';
const { Item } = TimelineAntD;

const Dot = ({ color }) => <div className={classnames(styles.dot, styles[color])}></div>;

const Timeline = ({
  dates,
}) => (
  <TimelineAntD>
    {
      dates.map((date, index) => (
        <Item key={index} className="timelineItem selected" dot={<Dot color={date.color} />}>
          <div className={styles.itemRoadmap}>
            <h3>{date.date}</h3>
            <h4>{date.label}</h4>
            {date.content}
          </div>
        </Item>
      ))
    }
  </TimelineAntD>
);

Timeline.propTypes = {
  dates: PropTypes.array,
};

Dot.propTypes = {
  color: PropTypes.string.isRequired,
};

export default Timeline;
