import React from 'react';
import { object } from 'prop-types';
import { ReactTitle } from 'react-meta-tags';
import Sticky from 'react-sticky-el';
import EventsList from '../../container/EventsList';
import { Explore } from '@/scenes/Events/scenes/Explore';
import styles from '@/scenes/Events/container/EventsList/styles.less';
import Header from '@/containers/Layout/containers/Header';
import MobileHeader from '@/containers/Layout/containers/MobileHeader';

function NewEventsScene({ authUser, ...props }) {
  if (!authUser) return null;
  
  return (
    <div className={styles.sliderContainer}>
      <ReactTitle title="Seekube" />
      <div className={styles.headerContainer}>
        <Sticky>
          <Header />
        </Sticky>
      </div>
      <Explore authUser={authUser}>
        <EventsList authUser={authUser} {...props} />
      </Explore>
      <MobileHeader />
    </div>
  );
}

NewEventsScene.propTypes = {
  authUser: object,
};

export { NewEventsScene };
