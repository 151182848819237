import { isEmpty } from 'lodash';
import moment from 'moment/moment';
import { getEarliestKeyMoment } from '@/utils/keyMoments';

export function getEarliestKeyMomentFromFormats(event, momentType = 'jobfair', formats = []) {
  if (isEmpty(formats)) {
    return getEarliestKeyMoment(event, momentType);
  }

  return moment.min(formats.map(format => getEarliestKeyMoment(event, momentType, format)))
}