import { useMutation, useQueryClient } from 'react-query';
import request from '@/utils/request';

export function usePostParticipateConference(
  {
    onSuccess = () => {},
    conferenceId,
  }) {
  const queryClient = useQueryClient();

  const mutationFn = (body) => {
    const requestUrl = `${process.env.FRONT_API_URL}/conferences/${conferenceId}/participate`;

    return (
      request(requestUrl, {
        method: 'POST',
        body: JSON.stringify(body),
      })
    )
  }

  return useMutation({
    mutationFn,
    onSuccess: async ({ attendee }) => {
      const prevConf = queryClient.getQueryData(['conferences', conferenceId]);

      const conference = { ...prevConf, attendee: { ...attendee, canceled: false } };

      queryClient.setQueryData(['conferences', conferenceId], () => conference);

      onSuccess();
    },
  });
}
