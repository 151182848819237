import { intersection, isEmpty } from 'lodash'


const getInitialType = (activePlans, isAdminContext) => {
  const res = [];

  if (isAdminContext ||
    !isEmpty(intersection(activePlans, ['welcomePack', 'oneShot', 'subscription']))) {
    res.push('school');
  }
  if (isAdminContext ||
    !isEmpty(intersection(activePlans, ['oneShot', 'subscription']))) {
    res.push('seekube');
  }

  return res;
};

export { getInitialType };
