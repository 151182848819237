import React from 'react';
import { object, number, func } from 'prop-types';

// Components
import { Button, Toggle } from '@seekube-tech/ui-kit';

/**
 * Default
 *
 * @description
 * Default panel display in the MenuItem
 *
 * @param {object} params
 * @param {object} params.event
 * @param {number} params.contentIndex
 * @param {Function} params.onChange
 * @param {Function} params.onDelete
 */
const Default = ({
  event, // eslint-disable-line
  contentIndex, // eslint-disable-line
  onChange, // eslint-disable-line
  onDelete,
}) => (
  <div>
    {/* Display Label */}
    <div>
      <label htmlFor="mainColor">Afficher label dans le menu :</label>
      <Toggle
        size="small"
        checked={event._contents[contentIndex].inNav}
        onChange={(checked) => onChange(['_contents', contentIndex, 'inNav'], checked)}
      />
    </div>

    {/* Button for delete the bloc */}
    <div>
      <label htmlFor="actions">Actions :</label>
      <Button color="error" onClick={() => onDelete(contentIndex)}>Supprimer le bloc</Button>
    </div>
  </div>
);

Default.propTypes = {
  event: object,
  contentIndex: number,
  onChange: func,
  onDelete: func,
};

export default Default;
