import { object, string } from 'prop-types';
import React, { useEffect } from 'react'
import AppointmentsOfTheDay from './components/AppointmentsOfTheDay';
import LivesOfTheDay from './components/LiveOfTheDay';
import SmallStatCard from './components/smallStatCard';
import SmallStatMeetingsCard from './components/smallStatMeetingsCard';
import styles from './styles.less'

const RealTimeActivity = ({ event, view='all' }) => {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <div className={styles.realTimeActivityContainer}>
      <div className={styles.smallStat}>
        <SmallStatCard type='candidate' eventId={event._id} view={view} />
      </div>
      <div className={styles.smallStat}>
        <SmallStatCard type='recruiter' eventId={event._id} view={view} />
      </div>
      <div className={styles.smallStat}>
        <SmallStatMeetingsCard type='recruiter' event={event} view={view} />
      </div>
      <div className={`${!event.modules.live.participantEnable && styles.listAppointmentContainerFullWidth} ${styles.listAppointmentContainer}`}>
        <AppointmentsOfTheDay event={event} view={view} />
      </div>
      {event.modules.live.participantEnable &&
        <div className={styles.livesContainer}>
          <LivesOfTheDay event={event} view={view} />
        </div>
      }
    </div>
  )
}

SmallStatCard.propTypes = {
  event: object,
  view: string,
};

export default RealTimeActivity;
