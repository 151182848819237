import {
  SEPARATORS_NAMES
} from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Appointments/components/AppointmentsList/const';

export function getSeparatorNameOrder(event) {
  const { propositionsModified, accepted, done, proposition, pending, refused} = SEPARATORS_NAMES;

  return event.phases.canCandidateInteractWithRecruiter ?
    [propositionsModified, proposition, accepted, pending, refused, done]
    : [propositionsModified, accepted, done, pending, proposition, refused];
}