import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { Form } from 'antd';
import InputMaterial from '@/components/Input/InputMaterial';
import { formatName } from '@/utils/format';
import { IconUser } from '@seekube-tech/ui-kit';

const FormItem = Form.Item;

export const FirstName = ({ form, user }) => {
  const t = useFormatMessage();
  const initialValue = user.firstName || window.localStorage.getItem('signup-fn');

  return (
    <>
      <FormItem>
        {form.getFieldDecorator('firstName', {
          initialValue,
          rules: [{ required: true, message: t({ id: 'event.recruiter.preparation.signup.identity.form.firstName.error' }) }],
        })(
          <InputMaterial
            addOnIcon={<IconUser size={24} />}
            label={t({ id: 'firstName' })}
            placeholder={t({ id: 'firstName' })}
            type="text"
            validations={['required']}
            applyFormatValue={formatName}
          />
        )}
      </FormItem>
    </>
  )
}
