
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { entitiesActions } from '../entities';
import { appSchema } from './schema';
import * as actions from './actions';
import * as types from './types';
import request from '../../utils/request';
import { notificationActions } from '../notification';
import { getCurrentApp } from './selectors';
import { trackError } from '../../utils/analytics/helpers';

/**
 * GET app
 */
function* getApp() {
  const requestUrl = `${process.env.FRONT_API_URL}/apps/`;

  try {
    const result = yield call(request, requestUrl);

    // Normaliz data
    const dataNormalized = normalize(result[0], appSchema);

    // Save entities
    yield put(entitiesActions.replaceEntities({ id: result[0]._id, type: 'app', entities: dataNormalized.entities }));
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    // Set the current app version
    yield put(actions.setCurrentApp(result[0]._id));
  } catch (err) {
    trackError(err);
  }
}

/**
 * Patch app
 */
function* patchApp({ payload: { body, notificationParams } }) {
  const currentApp = yield select(getCurrentApp);
  const requestUrl = `${process.env.FRONT_API_URL}/apps/${currentApp.get('_id')}`;

  // Patch the event
  try {
    const result = yield call(request, requestUrl, {
      method: 'PATCH',
      body: JSON.stringify({ ...body }),
    });

    // Normaliz data
    const dataNormalized = normalize(result, appSchema);

    // Save entities
    yield put(entitiesActions.replaceEntities({ id: result._id, type: 'app', entities: dataNormalized.entities }));
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    // Push success notification
    if (notificationParams && notificationParams.success) {
      yield put(notificationActions.sendNotification({
        message: notificationParams.success.message,
        kind: notificationParams.success.kind,
        style: notificationParams.success.style,
      }));
    }
  } catch (err) {
    trackError(err);

    if (notificationParams && notificationParams.error) {
      yield put(notificationActions.sendNotification({
        message: err.message,
        kind: 'error',
        style: {},
      }));
    }
  }
}

/**
 * Listen Actions
 */
export default [
  takeLatest(types.GET_APP, getApp),
  takeLatest(types.PATCH_APP, patchApp),
];
