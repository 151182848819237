import React from 'react';
import { string, func, object } from 'prop-types';

// components
import Icon from '@/components/Icon';

// styles
import styles from './styles.less';

export const CloseModal = ({ onClick, className, style }) => (
  <a role="button" tabIndex={0} className={`${styles.closeBtn} ${className}`} style={style} onClick={onClick}>
    <Icon name="close" className={styles.icon} />
  </a>
  );

CloseModal.propTypes = {
  onClick: func,
  className: string,
  style: object,
};
