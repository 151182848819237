import moment from 'moment';
import { EVENT_FORMAT } from '@/utils/constants';

export const getCurrentDate = (event, indexWeek, exponentFormat) => {
  if (!event) return null;

  if (!(indexWeek !== null)) return null;

  const isExponentFormatHybrid = exponentFormat === EVENT_FORMAT.HYBRID;
  const defaultIndexWeek = event?.weeks?.[isExponentFormatHybrid ? 'all' : exponentFormat]?.[indexWeek] ? indexWeek : 0;


  return (
    moment(
      event
        ?.weeks
        ?.[isExponentFormatHybrid ? 'all' : exponentFormat]
        ?.[defaultIndexWeek]
        ?.beginAt)
      .add(10, 'hours').toDate()
  )
}
