import request, { parseJSON, checkStatus } from '@/utils/request';

const getExponentsStats = async ({ eventId }) => {
  const requestUrl = `${process.env.FRONT_API_URL}/statistics/kpi/event-light?eventId=${eventId}`;

  return request(requestUrl);
};

const getExponentsUsers = async ({ eventId, notReady }) => {
  const requestUrl = `${process.env.FRONT_API_URL}/statistics/exponents-users-list?eventId=${eventId}&notReady=${notReady}`;

  return request(requestUrl);
};

const getExponents = async ({ eventId }) => {
  const requestUrl = `${process.env.FRONT_API_URL}/statistics/exponents-list?eventId=${eventId}`;

  return request(requestUrl);
};

const getParticipants = async ({ eventId }) => {
  const requestUrl = `${process.env.FRONT_API_URL}/statistics/kpi/event-participants?eventId=${eventId}`;

  return request(requestUrl);
};

const refresh = async ({ eventId }) => {
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/statistics/refresh?apiKey=41a1289e-fee1-430e-a849-5b2b7ed23f1a`;

  request(requestUrl);
};

const getExponentsActivities = async ({ slug }) => {
  const requestUrl = `${process.env.FRONT_API_URL}/statistics/exponents-activities?eventSlug=${slug}`;

  return request(requestUrl);
};

const getAppointmentsStats = async ({ slug }) => {
  const requestUrl = `${process.env.FRONT_API_URL}/statistics/appointments-report?eventSlug=${slug}`;

  return request(requestUrl);
};

const getOffersStats = async ({ slug }) => {
  const requestUrl = `${process.env.FRONT_API_URL}/statistics/offers-list?eventId=${slug}`;

  return request(requestUrl);
};

export { getExponentsStats, getExponentsUsers, getExponents, getParticipants, getExponentsActivities, getAppointmentsStats, getOffersStats, refresh };
