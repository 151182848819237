import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import request from '@/utils/request';
import { getCurrentEvent } from '@/store/event/selectors';

export function useGetDashboardPosts({ status, props }) {
  const event = useSelector(getCurrentEvent)?.toJS();

  const requestUrl = `${process.env.FRONT_API_URL}/posts/event/${event?.id}?status=${status}`;
  const queryFn = () => request(requestUrl, { method: 'GET' });

  const query = useQuery({
    queryKey: ['dashboard', 'posts'],
    queryFn,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    ...props,
  });
  return query;
}
