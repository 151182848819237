import { track } from '@/utils/analytics';
import { ANALYTICS_RECRUITER } from '@/utils/constants';

export const trackLinkedinImport = (data, authUser) => {
  const tempUser = {
    firstName: data.infos.firstName,
    lastName: data.infos.lastName,
    title: data.infos.headline,
    description: data.infos.summary,
    linkedinProfileUrl: data.infos.publicProfileUrl,
  };
  
  track({
    user: Object.assign(authUser, tempUser),
    name: ANALYTICS_RECRUITER.IMPORT_LINKEDIN,
    properties: {
      step: 'Setting',
    },
  });
}