import React from 'react';
import { object } from 'prop-types';
import { Tag, Body2 } from '@seekube-tech/ui-kit';
import { isEmpty } from 'lodash';
import { useFormatMessage } from 'react-intl-hooks';

export const ActivityStatus = ({ activityToUpdate }) => {
  const t = useFormatMessage();

  if (isEmpty(activityToUpdate?.status)) return null;

  const color = activityToUpdate?.status === 'published' ? 'apple' : 'neutral';

  return (
    <div className="flex items-center absolute top-40">
      <Body2 className="mr-12 text-neutral-500">{t({ id: 'status.b' })} :</Body2>
      <Tag variant='tonal' color={color} size="large">
        {activityToUpdate.status === 'published' ?
          t({ id: 'organization.status.published' })
          : t({ id: 'organization.status.draft' })}
      </Tag>
    </div>
  )
}

ActivityStatus.propTypes = {
  activityToUpdate: object,
}
