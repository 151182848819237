import React from 'react';
import { Link } from 'react-router-dom';
import { Body2, Button, H5 } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { number, string, object } from 'prop-types';

// styles
import styles from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Exponents/scenes/Exponent/components/Home/Sidebar/Cards/styles.less';

const CardConferencesFooter = ({
  link,
  conferences_count,
  organizationName,
  labels
}) => {
  const t = useFormatMessage();

  return <div className={styles.cardFooter}>
      <div>
        <H5 fontWeight={800} className={styles.description}>
          {conferences_count}&nbsp;
        </H5>
        <Body2 className={styles.description}>
          {conferences_count > 1 ? labels.plural : labels.singular}&nbsp;
          {t({ id: 'stand.sidebar.cardDescription.lives.footer.offered' }, { count: conferences_count })}
          <span className={styles.organization}> {organizationName}</span>
        </Body2>
      </div>
      <Link to={link}>
        <Button>
          {t({ id: 'discover.lives.stand' }, { label: labels.plural })}
        </Button>
      </Link>
  </div>
}

CardConferencesFooter.propTypes = {
  link: string,
  conferences_count: number,
  organizationName: string,
  labels: object
}

export default CardConferencesFooter;
