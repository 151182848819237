import { defineMessages } from 'react-intl';

export default defineMessages({
  modalTitle: {
    id: 'failed.to.fetch.modal.title',
  },
  modalDescriptionText: {
    id: 'failed.to.fetch.modal.description.text',
  },
  modalSolutionQuestion: {
    id: 'failed.to.fetch.modal.solution.question',
  },
  modalSolutionText: {
    id: 'failed.to.fetch.modal.solution.text',
  },
});
