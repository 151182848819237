import { defineMessages } from 'react-intl';

export default defineMessages({
  exportBtn: {
    id: 'event.owner.statistics.export.btn',
  },
  seeDetail: {
    id: 'see.detail',
  },
  noResult: {
    id: 'noResult',
  },
});
