import { useQuery } from 'react-query';
import request from '@/utils/request';
import { objectToParams } from '@/utils/url';

export function useGetUsersSelect({ eventId, search = {} }) {
  const searchParams = objectToParams({ ...search, eventId });

  const requestUrl = `${process.env.FRONT_API_URL}/organizations/agenda/calendar/users-select?${searchParams}`

  const queryFn = () => request(requestUrl, { method: 'GET' });

  const query = useQuery({
    queryKey: ['agenda', 'calendar', 'usersSelect', eventId],
    queryFn,
    refetchOnWindowFocus: false,
  });

  return query;
}
