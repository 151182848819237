import React from 'react';
import { object, func, bool, array } from 'prop-types';
import { map, size } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { StatBloc } from '@/components/Stats/StatBloc';
import { Tooltip } from '@/components/Tooltip';

import styles from './styles.less';
import messages from '../../messages';

const StatsLive = ({ data, loading, statBlocProperties = {}, onActionClick }) => {
  const stats = map(data, (live) => ({ item: live.title }));

  const statsModal = map(data, (live) => ({
    item: (
      <div className={styles.row}>
        <Tooltip
          title={live.title}
          color="dark"
          placement="bottom"
        ><span>{live.title}</span></Tooltip>
        <div className={styles.columnStats}>
          <span>{live.countAttendees}</span><span>{live.countParticipants}</span></div>
      </div>
    ),
  }));

  return (
    <StatBloc
      title={<FormattedMessage {...messages.lives} values={{ count: 2 }} />}
      withList
      withAction
      onActionClick={onActionClick}
      loading={loading}
      count={size(data)}
      classContainer={styles.statsLive}
      dataSource={stats}
      modalProperties={{
        header: (
          <div className={styles.extraContent}>
            <span style={{ textAlign: 'left' }}><FormattedMessage {...messages.livePublish} values={{ count: 2 }} /></span>
            <span style={{ display: 'flex', justifyContent: 'flex-end' }}><span style={{ width: '84px' }}><FormattedMessage {...messages.signup} /></span><span style={{ width: '84px' }}><FormattedMessage {...messages.camePl} values={{ count: 2, gender: 'male' }} /></span>
            </span>
          </div>
        ),
        dataSource: statsModal,
        title: <FormattedMessage {...messages.lives} values={{ count: 2 }} />,
      }}
      {...statBlocProperties}
    />
  );
};

StatsLive.propTypes = {
  data: array,
  loading: bool,
  onActionClick: func,
  statBlocProperties: object,
};

export { StatsLive };
