const objectToParams = (object) => {
  let str = '';

  if (!object) {
    return str;
  }

  const keys = Object.keys(object);

  keys.forEach((key) => {
    if (object[key]) {
      if (str !== '') {
        str += '&';
      }

      str += `${key}=${encodeURIComponent(object[key])}`;
    }
  });

  return str;
};

const getUrlParameterByName = (name) => {
  const param = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${param}=([^&#]*)`);
  const results = regex.exec(location.search);

  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

const getStoredUtmParams = () => JSON.parse(window.localStorage.getItem('user_provider')) || {}

const storeUtmParams = () => {
  const userProvider = getStoredUtmParams()
  const utmSource = getUrlParameterByName('utm_source') || userProvider.utmSource || '';
  const utmMedium = getUrlParameterByName('utm_medium') || userProvider.utmMedium || '';
  const utmCampaign = getUrlParameterByName('utm_campaign') || userProvider.utmCampaign || '';
  const utmContent = getUrlParameterByName('utm_content') || userProvider.utmContent || '';
  const utmTerm = getUrlParameterByName('utm_term') || userProvider.utmTerm || '';

  if (utmSource ||
    utmMedium ||
    utmCampaign ||
    utmContent ||
    utmTerm) {
    window.localStorage.setItem('user_provider', JSON.stringify({
      utmSource,
      utmMedium,
      utmCampaign,
      utmContent,
      utmTerm,
    }));
  }

  return {
    utmSource,
    utmMedium,
    utmCampaign,
    utmContent,
    utmTerm,
  }
}

const deleteStoredUtmParams = () => window.localStorage.removeItem('user_provider')

const utmParamsToQueryString = () => {
  const params = getStoredUtmParams();
  const hasParams = Object.keys(params).filter(param => params[param] !== '');

  return hasParams ? `?${Object.keys(params).map(param => {
    if (!params[param]) {
      return ''
    }
    const utmTerm = `utm_${param.replace('utm', '').toLocaleLowerCase()}`;
    const utmValue = encodeURIComponent(params[param]);

    return `${utmTerm}=${utmValue}`
  }).filter(v => !!v).join('&')}` : ''
}

const queryStringToObject = (queryString) => {
  const params = queryString.slice(1).split('&');
  const result = {};

  params.forEach((param) => {
    const pair = param.split('=');

    if (pair[0] !== '' && pair[1] !== '') {
      const paramValue = decodeURIComponent(pair[1]);

      if (result[pair[0]] !== undefined) {
        if (typeof result[pair[0]] === 'object') {
          result[pair[0]].concat(paramValue.split(','));
        } else {
          result[pair[0]] = [paramValue];
        }
      } else if (paramValue.split(',').length > 1) {
        result[pair[0]] = paramValue.replace(/, /gi, '|').split(',');
        result[pair[0]] = result[pair[0]].map((item) => item.replace(/\|/gi, ', '));
      } else {
        result[pair[0]] = paramValue;
      }
    }
  });

  return result;
};

const isRegexUrlvalid = (str) => {
  const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(str);
};

const isValidHttpUrl = (string) => {
  let url;

  try {
    url = new URL(string);
  } catch (e) {
    return false;
  }

  return (url.protocol === 'http:' || url.protocol === 'https:') && isRegexUrlvalid;
};

const isFromHelloWorkUtm = () => getUrlParameterByName('utm_source') === 'hellowork' || getStoredUtmParams().utmSource === 'hellowork';

const getApiUrl = (url) => `${process.env.FRONT_API_URL}${url}`;

export {
  objectToParams,
  queryStringToObject,
  isValidHttpUrl,
  isFromHelloWorkUtm,
  getUrlParameterByName,
  storeUtmParams,
  getStoredUtmParams,
  utmParamsToQueryString,
  deleteStoredUtmParams,
  getApiUrl
};
