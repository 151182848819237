import * as React from 'react';
import { object } from "prop-types";
import { isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import {
  trackCandidateClickedViewKeyDates,
  trackRecruiterClickedViewKeyDates
} from '@/utils/analytics';
import { authSelectors } from '@/store/auth';
import { toJS } from '@/utils';

function SeeCalendarLink({ event, authUser }) {
  const { keyDates, slug } = event;
  const isClosingKeyDateDefined = !isEmpty(keyDates?.applicationsClosing?.beginAt || keyDates?.interactionsClosing?.beginAt);
  const authUserRole = isEmpty(authUser?._currentOrganization) ? 'candidate' : 'recruiter';
  const trackOnclick = authUserRole === 'candidate' ? trackCandidateClickedViewKeyDates : trackRecruiterClickedViewKeyDates
  const conditionToRender = authUserRole === 'recruiter' || (
    authUserRole === 'candidate' && isClosingKeyDateDefined
  )

  return (
    conditionToRender ? <span key="seeCalendarLink">
      <a
        href={`${document.location.origin}/${slug}/${authUserRole}/keydates`}
        target="_blank"
        onClick={() => trackOnclick({ authUser })}
      >
        <FormattedMessage id="see.calendar" />
      </a>
    </span> : null
  )
};

SeeCalendarLink.propTypes = {
  event: object,
  authUser: object
}

const mapDispatchToProps = {};

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser,
});

export default connect(mapStateToProps, mapDispatchToProps)(toJS(SeeCalendarLink));
