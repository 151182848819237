import appointmentReducer from './reducer';
import * as appointmentTypes from './types';
import * as appointmentActions from './actions';
import * as appointmentSelectors from './selectors';
import appointmentSagas from './sagas';

export {
  appointmentTypes,
  appointmentActions,
  appointmentSelectors,
  appointmentSagas,
};

export default appointmentReducer;
