import { number } from 'prop-types';
import * as React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { IconBriefcase } from '@seekube-tech/ui-kit';

function CandidatePropositionClose({ countPropositions }) {
  return (
    <span key="candidatPropositionClose">
      <IconBriefcase size={16} />
      <FormattedHTMLMessage values={{ count: countPropositions || 0 }} id="events.stats.candidate.propositions" />
    </span>
  )
}

CandidatePropositionClose.propTypes = {
  countPropositions: number
}

export default CandidatePropositionClose;
