import React from 'react';
import { H5, Body2, Button } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { useHistory } from 'react-router-dom';

const EmailSentSuccessFully = () => {
  const t = useFormatMessage();
  const history = useHistory();

  return (
    <div className="confirmation-step">
      <H5 className="mb-20">{t({ id: 'forgotPassword.confirmationTitle' })}</H5>
      <Body2 className="mb-20 text-neutral-400">{t({ id: 'forgotPassword.confirmationP1' })}</Body2>
      <Body2 className="text-neutral-400">{t({ id: 'forgotPassword.confirmationP2' })}</Body2>
    </div>
  );
}

export { EmailSentSuccessFully };
