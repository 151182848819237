import React from 'react';
import { injectIntl } from 'react-intl';
import { object, string, array } from 'prop-types';
import { isUndefined } from 'lodash';
import moment from 'moment';

// Components
import Highlighter from 'react-highlight-words';

// Styles & Translations
import styles from './styles.less';
import messages from '../../messages';

/**
 * Return date formatted
 *
 * @param {string|date|moment} beginAt
 * @param {string|date|moment|null} endAt
 *
 * @returns {string}
 */
const returnDate = (beginAt, endAt, intl, format = 'MMM YYYY') => {
  const beginAtMoment = moment(beginAt).format(format);
  const beginAtMomentMonth = format === 'MMM YYYY' ? moment(beginAt).format('MMM').slice(0, 4) : '';
  const beginAtMomentYear = moment(beginAt).format('YYYY');
  if (beginAtMoment && !isUndefined(endAt)) {
    const endAtMomentMonth = format === 'MMM YYYY' ? moment(endAt).format('MMM').slice(0, 4) : '';
    const endAtMomentYear = moment(endAt).format('YYYY');
    return (
      <>
        {beginAtMomentMonth} {beginAtMomentYear} - {endAtMomentMonth} {endAtMomentYear}
      </>
    );
  }

  return (
    <>{intl.formatMessage(messages.since)} <span>{beginAtMoment} ({intl.formatMessage(messages.inProgress)})</span></>
  );
};

const ResumeItem = ({ resumeItem, resumeType, highlightWords, size, intl }) => {
  const returnOrganisation = ({ _organization }) => {
    if (_organization) {
      if (resumeType === 'positions' || size === 'big') return `@${resumeItem._organization.name}`;
      return resumeItem._organization.name;
    }
    return '';
  };
  const title = (<Highlighter
    highlightClassName="highlightText"
    searchWords={highlightWords}
    autoEscape
    textToHighlight={(resumeItem.fieldOfStudy ? resumeItem.fieldOfStudy : resumeItem.title) || ''}
  />);

  const organization = (<Highlighter
    highlightClassName="highlightText"
    searchWords={highlightWords}
    autoEscape
    textToHighlight={returnOrganisation(resumeItem)}
  />);

  return (
    <p>
      <span className={styles.title}>{title}</span>
      <span className={styles.organization}> {organization}</span>
      <span> - {returnDate(resumeItem.beginAt, resumeItem.endAt, intl, resumeType === 'positions' ? 'MMM YYYY' : 'YYYY')}</span>
    </p>
  );
};

ResumeItem.propTypes = {
  resumeItem: object,
  resumeType: string,
  size: string,
  highlightWords: array,
  intl: object,
};

ResumeItem.defaultProps = {
  highlightWords: [],
};

export default injectIntl(ResumeItem);
