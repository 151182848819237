import { normalize } from 'normalizr';
import moment from 'moment';
import { fromJS } from 'immutable';

import { call, put, takeLatest, select } from 'redux-saga/effects';
import { get } from 'lodash';
import request from '@/utils/request';
import { getCurrentExponent } from '@/store/exponent/selectors';
import * as actions from './actions';
import * as types from './types';
import { timeslotListSchema } from './schema';
import { userActions } from '../user';
import { entitiesActions } from '../entities';
import { authSelectors } from '../auth';
import { exponentSelectors } from '../exponent';
import { patchExponent } from '../exponent/actions';
import { notificationActions } from '../notification';
import { trackError } from '../../utils/analytics/helpers';

/**
 * Get user timeslots
 *
 * @param {object} currentUser
 */
function* getUserTimeslots({ payload: { currentUser, currentEvent, context, callback } }) {
  const authUser = yield select(authSelectors.getAuthUser);
  const userIdSelected = !currentUser ? authUser.get('_id') : currentUser._id;
  const eventDate = currentEvent
    ? `?beginAt=${moment(get(currentEvent, 'keyDates.jobfair.beginAt')).toISOString()}&endAt=${moment(get(currentEvent, 'keyDates.jobfair.endAt')).toISOString()}`
    : '';
  const requestUrl = `${process.env.FRONT_API_URL}/users/${userIdSelected}/timeslots${eventDate}`;

  try {
    const result = yield call(request, requestUrl);

    // Normaliz data
    const dataNormalized = normalize(result, timeslotListSchema);

    // Save entities
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    // Save timeslotsIds by userId
    yield put(actions.getUserTimeslotsSuccess({
      ids: dataNormalized.result,
      userId: userIdSelected,
      context,
    }));

    if (typeof callback === 'function') {
      return callback();
    }

    // Set Current user
    yield put(userActions.setCurrentUser(userIdSelected));
  } catch (err) {
    trackError(err);
  }
}


/**
 * Listen Actions
 */
export default [
  takeLatest(types.GET_USER_TIMESLOTS, getUserTimeslots),
];

