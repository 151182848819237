import React, { useState } from 'react';
import { object } from 'prop-types';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory, withRouter } from 'react-router-dom';
import { H4, Body1 } from '@seekube-tech/ui-kit';

import { useFormatMessage } from 'react-intl-hooks';
import { getAuthUser } from '@/store/auth/selectors';
import { getCurrentEvent } from '@/store/event/selectors';
import { postAction } from '@/store/action/actions';

import { withLayout } from '@/containers/Layout';
import MobileHeader from '@/containers/Layout/containers/MobileHeader';

import { getId } from '@/utils/global';
import { objectToParams, queryStringToObject } from '@/utils/url';
import { toJS } from '@/utils/index';
import MobileMenu from '@/containers/Layout/containers/MobileMenu';
import { ModalConfirm } from '@/components/ModalConfirm';
import { PostConferenceBtn } from '@/scenes/Client/scenes/Conferences/components';
import ModalEditLiveTalk from '@/scenes/Client/scenes/Conferences/components/EditLivetalk';
import { LiveStats, DownloadLives } from '@/scenes/Event/scenes/Owner/scenes/Livetalks/components';

import { useGetConferences } from '@/queries/conferences/useGetConferences';
import ConferencesList from '@/scenes/Event/scenes/Owner/scenes/Conferences/ConferencesList';
import { useDeleteConference } from '@/queries/conferences/useDeleteConference';

import { Nav } from '@/scenes/Event/scenes/Owner/scenes/Conferences/Nav';

const DEFAULT_SEARCH = {
  page: 1,
  offset: 0,
  limit: 10,
  archive: location.pathname.includes('confirmed'),
  keywords: '',
}

const Conferences = ({ match, context }) => {
  const t = useFormatMessage();

  const authUser = useSelector(getAuthUser)?.toJS();
  const event = useSelector(getCurrentEvent)?.toJS();
  const history = useHistory();
  const dispatch = useDispatch();
  const [formatSelected, setFormatSelected] = useState('webinar');
  const [conferenceSelected, setConferenceSelected] = useState({});
  const [editFlowVisible, setEditFlowVisible] = useState(false);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [action, setAction] = useState(null);
  const [search, setSearch] = useState({ ...DEFAULT_SEARCH, ...queryStringToObject(history.location.search) });
  const listContext = history.location.pathname.includes('discovery') ? 'discovery' : 'confirmed';

  const participate = { participate: listContext === 'confirmed' }

  const conferencesQuery = useGetConferences({
    eventId: event._id,
    search,
    loader: true,
    ...participate,
  });

  const mutateDeleteConference = useDeleteConference({
    onSuccess: () => {
      setConferenceSelected({})
      setEditFlowVisible(false)
      setIsConfirmModalVisible(false)
      conferencesQuery.refetch();
    },
  });

  const startEditFlow = (formatSelected) => {
    setFormatSelected(formatSelected)
    setEditFlowVisible(true)
    setConferenceSelected({})
  };

  const goToDiscovery = () => {
    history.push(`${match.url}/discovery?page=1&archive=false`);
    setSearch({ ...search, archive: false })
  };

  const goToConfirmed = () => {
    history.push(`${match.url}/confirmed?page=1&archive=true`);
    setSearch({ ...search, archive: true })
  };

  const handleNavClick = (name) => {
    if (name === 'discovery') { goToDiscovery() }

    if (name === 'confirmed') { goToConfirmed() }
  }


  const onConfirmModal = () => {
    if (!editFlowVisible && action === 'removeLive') {
      mutateDeleteConference.mutate({ _id: conferenceSelected._id });
    }

    if (editFlowVisible) {
      setConferenceSelected({});
      setEditFlowVisible(false);
    }

    setIsConfirmModalVisible(false);
  }

  const handleActions = (actionType, conference) => {
    switch (actionType) {
      case 'editLive':
        setEditFlowVisible(true);
        setConferenceSelected(conference);
        setFormatSelected(conference.format)
        break;

      case 'duplicateLive':
        setEditFlowVisible(true)
        setConferenceSelected({ ...conference, isDuplicating: true })
        setFormatSelected(conference.format)
        break;

      case 'removeLive':
        setAction('removeLive')
        setConferenceSelected(conference);
        setIsConfirmModalVisible(true);
        break;

      case 'seeAttendees':
        dispatch(postAction({
          actionParams: {
            name: 'RECRUITER_VISIT_CONFERENCE',
            _user: getId(authUser),
            _organization: getId(conference._organization),
            _event: getId(conference._event),
            data: { conference: getId(conference._id) },
          },
          callback: () => history.push(`conferences/${conference._id}/all`),
        }))
        break;

      default:
        break;
    }
  };

  const updateSearchAndURL = (searchChanges) => {
    const newSearch = { ...search, ...searchChanges };

    setSearch(newSearch);

    updateURLWithSearchChanges(newSearch)
  }

  const updateURLWithSearchChanges = (searchToUpdate) => {
    history.push(`${history.location.pathname}?${objectToParams(searchToUpdate)}`)
  }

  if (match.isExact) {
    history.replace(`${match.url}/discovery`);
  }

  return (
    <>
      {(history.location.pathname.includes('discovery') || history.location.pathname.includes('confirmed')) && (
        <div className="layout-large">
          <div className="flex items-center justify-between flex-wrap">
            <Nav conferencesQuery={conferencesQuery} handleClick={handleNavClick} />
            <div className="flex justify-end items-center">
              <div className="mr-20">
                <DownloadLives slug={event.slug} />
              </div>

              <PostConferenceBtn
                startEditFlow={startEditFlow}
                disabled={false}
              />
            </div>
          </div>
          <LiveStats eventId={getId(event)} />
        </div>
      )}

      <ModalConfirm
        visible={isConfirmModalVisible}
        onConfirm={onConfirmModal}
        onCancel={() => setIsConfirmModalVisible(false)}
      >
        {
          editFlowVisible ?
            <H4>{t({ id: 'event.recruiter.preparation.offer.published.form.update' })}</H4>
            : <>
              <H4>{t({ id: 'recruiter.live.confirm.delete.title' })}</H4>
              <Body1 className="mt-20">{t({ id: 'recruiter.live.confirm.delete.subtitle' })}</Body1>
            </>
        }
      </ModalConfirm>

      <ModalEditLiveTalk
        conference={conferenceSelected}
        visible={editFlowVisible}
        format={formatSelected}
        context="owner"
        onClose={(callback, hasFormBeenSubmitted) => {
          if (hasFormBeenSubmitted) {
            callback();
            conferencesQuery.refetch();
            setConferenceSelected({});
            setEditFlowVisible(false)
          } else {
            setIsConfirmModalVisible(true)
          }
        }}
      />

      <Switch>
        <Route
          path={`${match.path}/confirmed`}
          render={() => (<ConferencesList
            onSearch={updateSearchAndURL}
            handleActions={handleActions}
            goToDiscovery={goToDiscovery}
            listContext={listContext}
            conferencesQuery={conferencesQuery}
            event={event}
          />)}
        />
        <Route
          path={`${match.path}/discovery`}
          render={() => (<ConferencesList
            handleActions={handleActions}
            onSearch={updateSearchAndURL}
            listContext={listContext}
            conferencesQuery={conferencesQuery}
            event={event}
          />)}
        />
      </Switch>

      <MobileHeader event={event} />

      <MobileMenu
        displayInformal={event.modules?.informal1to1?.enable}
        displayLive={event?.modules?.live?.recruiterEnable}
        liveLabel={event?.modules.live?.labelMultiple}
      />
    </>
  );
}

Conferences.propTypes = {
  match: object,
  context: object,
};


export default compose(
  withLayout({ size: 'full', withHeader: false, navType: 'candidate' }),
  withRouter,
)(toJS(Conferences));
