import React from 'react';
import { number, object } from 'prop-types';

// Components
import { Rate as RateAntd } from 'antd';
import Icon from '@/components/Icon';

// Styles & Translations
import styles from './styles.less';

const starsColor = (val, starClass) => {
  switch (val) {
    case 1:
      return starClass || styles.danger;
    default: return '';
  }
};

/**
 * Stars
 */
const Stars = (props) => (
  <div className={props.componentClass || styles.rateContainer}>
    <RateAntd
      {...props}
      character={<Icon name="star-filled" style={props.style} className={`${starsColor(props.star || props.value, props.characterClass)}`} />}
      count={props.count || 5}
    />
  </div>
);

Stars.propTypes = {
  value: number,
  star: number,
  componentClass: object,
  characterClass: object,
  style: object,
  count: number,
};

export default Stars;
