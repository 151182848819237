import { Body1, Tag } from '@seekube-tech/ui-kit';
import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import moment from 'moment';
import Avatar from '@/components/Avatar';
import MoreActions from '@/scenes/Event/scenes/Owner/scenes/Dashboard/components/MoreActions';
import styles from '@/scenes/Event/scenes/Owner/scenes/Dashboard/components/ModalActivities/styles.less';

export const Columns = ({ onPreview, onDelete, onUpdate }) => {
  const t = useFormatMessage();

 return [
    {
      id: 'title',
      Header: t({ id: 'title'}),
      accessor: (record) => <Body1 fontWeight={600}>{record.title}</Body1>,
    },
    {
      id: '_author',
      Header: t({ id: 'author'}),
      accessor: (record) => (
          <div className={styles.userContainer}>
            <Avatar src={record._author.pictureUrl} user={record._author} size={20} />
            <Body1 fontWeight={600} className={styles.authorName}>{record._author.fullName}</Body1>
          </div>
        ),
    },
    {
      id: 'createdAt',
      Header: t({ id: 'publication.date'}),
      accessor: (record) => {
        if (record.status === 'draft') {
          return <Body1 fontWeight={600}>-</Body1>
        }
        return <Body1 fontWeight={600}>{moment(record.createdAt).format('DD/MM/YYYY')}</Body1>;
      },
    },
    {
      id: 'status',
      Header: t({ id: 'status.b' }),
      accessor: (record) => {
        const color = record.status === 'published' ? 'apple' : 'neutral';
        return (
          <Tag className={styles.tag} variant='tonal' color={color} size='small'>
          {record.status === 'published' ?
            t({id: 'organization.status.published'})
            : t({id: 'organization.status.draft'})}
        </Tag>);
      },
    },
    {
      id: 'actions',
      Header: '',
      disableSortBy: true,
      accessor: (record) => (
        <MoreActions
          onUpdate={() => onUpdate(record)}
          onDelete={onDelete}
          onPreview={() => onPreview(record)}
          activityId={record._id}
        />
      )
    },
  ]
}
