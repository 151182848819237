import React from 'react';
import { object, func, bool, any } from 'prop-types';
import { IconMapPin, IconWifi, IconCalendar } from '@seekube-tech/ui-kit';
import styles from '@/scenes/Event/scenes/Owner/scenes/Dashboard/styles.less';
import { EVENT_FORMAT } from '@/utils/constants';
import {
  RealTimeActivityViewSwitcher
} from '@/scenes/Event/scenes/Owner/scenes/Dashboard/components/Header/RealTimeActivityViewSwitcher';
import { getEventKeyMoments } from '@/helpers/event/getEventKeyMoments';
import { dateFormat } from '@/utils/format';

export function Subtitle({ isLinkVisible, changeDashboardMode, event, subtitle }) {
  return (
    <div className={styles.subtitle}>
      {event.format === EVENT_FORMAT.HYBRID &&
        <HybridDates event={event}/>}

      {event.format !== EVENT_FORMAT.HYBRID &&
        <>
          <NonHybridDate subtitle={subtitle} />
          <RealTimeActivityViewSwitcher isLinkVisible={isLinkVisible} handleDashboardMode={changeDashboardMode} />
        </>}
    </div>
  )
}

function NonHybridDate({ subtitle }) {
  return (
    <div className={styles.date}>
      <div className="mr-5"><IconCalendar size={20} /></div>
      <span>{subtitle}</span>
    </div>
  )
}

function HybridDates({ event }) {
  const physicalDates = getEventKeyMoments(event, { type: 'jobfair', format: EVENT_FORMAT.PHYSICAL });
  const virtualDates = getEventKeyMoments(event, { type: 'jobfair', format: EVENT_FORMAT.VIRTUAL });

  return (
    <div className={styles.date}>
      <div className={styles.dateFormat}>
        <IconMapPin size={20} />
        {dateFormat(physicalDates.beginAt, physicalDates.endAt, true, 4)}
      </div>
      <div className={styles.dateFormat}>
        <IconWifi size={20} />
        {dateFormat(virtualDates.beginAt, virtualDates.endAt, true, 4)}
      </div>
    </div>
  )
}

Subtitle.propTypes = {
  event: object,
  subtitle: any,
  isLinkVisible: bool,
  changeDashboardMode: func,
}

HybridDates.propTypes = {
  event: object,
}

NonHybridDate.propTypes = {
  subtitle: any,
}
