import { createSelector } from 'reselect';


const getRoleReducer = (state) => state.get('role');


export const getRolesById = createSelector(
  getRoleReducer,
  (rolesState) => rolesState.get('byId')
);

export const getRolesByKey = createSelector(
  getRoleReducer,
  (rolesState) => rolesState.get('byKey')
);

