import React from 'react';
import { node, func, string } from 'prop-types';
import { omit } from 'lodash';
import { LinkedIn } from 'react-linkedin-login-oauth2';
import request from '../../utils/request';

/**
 * <SocialLoginButton />
 *
 * @description
 * Handle connection with provider with HOC
 */
const SocialLoginButton = ({ onLoading, children, type, className = '', ...props }) => {
  if (props.provider !== 'linkedin') {
    return <span>Unsupported OAuth</span>
  }

  const handleSuccess = (data) => {
    const url = `${process.env.FRONT_API_URL}/users/oauth/linkedin/oauthToken`;

    request(url, {
      method: 'POST',
      body: JSON.stringify({
        code: data.code,
        grant_type: 'authorization_code',
        redirect_uri: `${window.location.origin}/linkedin`,
        client_id: props.appId,
        client_secret: 'tpM4UcDjPAKLdc52',
      }),
    }).then((results) => {
      props.onLoginSuccess(results.user);
    });
  };

  if (type === 'link' && props.provider === 'linkedin') {
    return (<a {...omit(props, ['appId'])} role="button">
      <LinkedIn
        style={{}}
        clientId={props.appId}
        scope="r_liteprofile r_emailaddress"
        onSuccess={handleSuccess}
        onFailure={props.onLoginFailure}
        redirectUri={`${window.location.origin}/linkedin`}
      >
        {children}
      </LinkedIn>
    </a>);
  }

  return (
    <LinkedIn
      className={className}
      clientId={props.appId}
      scope="r_liteprofile r_emailaddress"
      onSuccess={handleSuccess}
      onFailure={props.onLoginFailure}
      redirectUri={`${window.location.origin}/linkedin`}
    >
      {children}
    </LinkedIn>
  );
};

SocialLoginButton.propTypes = {
  appId: string,
  className: string,
  type: string,
  provider: string,
  children: node,
  onLoading: func,
  onLoginFailure: func,
  onLoginSuccess: func,
};

SocialLoginButton.defaultProps = {
  type: 'button',
};

export default SocialLoginButton;
