import React from 'react';
import { injectIntl } from 'react-intl';
import { Steps } from 'antd';
import { oneOf, func, number, bool } from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { toJS } from '@/utils';
import { useFormatMessage } from 'react-intl-hooks';

import styles from './styles.less';

const { Step } = Steps;

const EditSteps = ({
  context,
  step = 0,
  isEditing,
  onStepChange,
}) => {
  const onChange = (currentStep) => {
    onStepChange(currentStep);
  };
  const isEventStepVisible = (context !== 'client') || (context === 'client' && isEditing);
  const getStepClassName = (currentStep) => (currentStep > step) ? styles.disable : styles.default;
  const t = useFormatMessage();

  return (
    <div className={styles.steps}>
      <Steps current={step} direction="vertical">
        {
          context === 'client' && !isEditing ?
            <Step className={getStepClassName(0)} title={t({ id: 'conference.create.step' }, { count: 1 })} onClick={() => onChange(0)} description={t({ id: 'conference.create.selectJobdating' })} /> : null
        }
        <Step className={getStepClassName(1)} title={t({ id: 'conference.create.step' }, { count: isEventStepVisible ? 1 : 2})} onClick={() => onChange(isEventStepVisible ? 0 : 1)} description={t({ id: 'conference.create.introduce' })} />
        <Step className={getStepClassName(2)} title={t({ id: 'conference.create.step' }, { count: isEventStepVisible ? 2 : 3})} onClick={() => onChange(isEventStepVisible ? 1 : 2)} description={t({ id: 'conference.create.selectTool' })} />
        <Step className={getStepClassName(3)} title={t({ id: 'conference.create.step' }, { count: isEventStepVisible ? 3 : 4})} onClick={() => onChange(isEventStepVisible ? 2 : 3)} description={t({ id: 'conference.create.configureTool' })} />
        <Step className={getStepClassName(3)} title={t({ id: 'conference.create.step' }, { count: isEventStepVisible ? 4 : 5})} onClick={() => onChange(isEventStepVisible ? 3 : 4)} description={t({ id: 'conference.create.confirmAndPublish' })} />
      </Steps>
    </div>
  );
};

EditSteps.propTypes = {
  context: oneOf(['nonClient', 'client', 'owner']),
  step: number,
  isEditing: bool,
  onStepChange: func,
};

export default compose(
  injectIntl,
  withRouter
)(toJS(EditSteps));
