import request from '@/utils/request';

const changeReferent = ({ exponentId, body, eventId }) => {
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/exponents/${exponentId}/requestAuthorizePreparationByOwner`;
  return request(requestUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body),
  })
};

export { changeReferent };
