import React from 'react'
import { func, object, bool } from 'prop-types'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { useFormatMessage } from 'react-intl-hooks';
import { Grid } from '@seekube-tech/ui'
import { authActions, authSelectors } from '@/store/auth'
import { userActions } from '@/store/user'
import { toJS } from '@/utils'
import { Kpis, Feeds, InputSearchAdmin } from './components'
import useStyles from './styles'

function Kpi({
  switchAuth,
  patchMe,
  authUser,
  isSpotlightVisible,
}) {
  const classes = useStyles();
  const t = useFormatMessage();

  const handleOnClickSearchAdmin = (type, value) => {
    if (type === 'user') {
      switchAuth({
        email: value.username,
        userId: value._id,
        notificationParams: {
          success: {
            message: t({ id: 'toasters.switch.success' }),
            kind: 'success',
            style: {
              top: '7%',
            },
          },
          error: {
            message: t({ id: 'toasters.switch.error' })
          },
        },
      });
    } else if (type === 'exponent') {
      patchMe({
        userParams: {
          _currentOrganization: value._id,
        },
        notificationParams: {
          success: {
            message: t({ id: 'toasters.switch.success' }),
            kind: 'info',
            style: {
              bottom: '5%',
              top: 'inherit',
            },
          },
          error: true,
        },
        callback: () => {
          window.location.replace(`/admin/organizations/${value._id}`, { organizationId: value._id });
        },
      });
    }
  };

  return (
    <div className={classes.root}>
      <Grid container justifyContent="center" xs="12" direction="row">
        <Grid item xs="10">
          <InputSearchAdmin onEnter={handleOnClickSearchAdmin} onClick={handleOnClickSearchAdmin} />
        </Grid>
        <Grid item xs="8">
          <Kpis isSpotlightVisible={isSpotlightVisible} />
          <hr />
          <Feeds isSpotlightVisible={isSpotlightVisible} />
        </Grid>
      </Grid>
    </div>
  )
}

Kpi.propTypes = {
  authUser: object,
  patchMe: func,
  switchAuth: func,
  isSpotlightVisible: bool,
};

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUserNotConnectedAs,
});

const mapDispatchToProps = {
  switchAuth: authActions.switchAuth,
  patchMe: userActions.patchMe,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  toJS,
)(Kpi);
