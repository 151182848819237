import { defineMessages } from 'react-intl';

export default defineMessages({
  step1: {
    id: 'event.participants.onboarding.step.1',
  },
  step2: {
    id: 'event.participants.onboarding.step.2',
  },
  step3: {
    id: 'event.participants.onboarding.step.3',
  },
  infos: {
    id: 'event.participants.onboarding.infos',
  },
  yes: {
    id: 'yes',
  },
  no: {
    id: 'no',
  },
  title: {
    id: 'event.menu.participants.onboarding',
  },
});
