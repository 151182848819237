import React from 'react';
import { array, func, bool } from 'prop-types';

// Components

// Styles & Translations
import styles from '../../scenes/MergeSchools/styles.less';

class Log extends React.PureComponent { // eslint-disable-line
  static propTypes = {
    items: array,
    onCancel: func,
    displayCancel: bool,
  };

  state = {
    history: [],
  };

  render() {
    const {
      props: { onCancel, items, displayCancel },
    } = this;

    return (
      <div className={styles.logComponent}>
        <div className={styles.logHeader}>
          <div>Journal des opérations</div>
        </div>
        <div className={styles.container}>
          <div className={styles.historyContainer}>{items.map((item, index) =>
            (<div key={item._id}>
              <span style={{ marginRight: 10 }}>{item.data.oldName} {item.data.newId ? (<strong><span role="img" aria-label="arrow">➡️</span>{item.data.newName}</strong>) : ''} </span>
              {index === 0 && displayCancel ? (<a role="button" tabIndex={0} onClick={() => onCancel(item._id)}>Annuler</a>) : ''}
            </div>)
          )}</div>
        </div>
      </div>
    );
  }
}

export default Log;
