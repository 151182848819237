import { makeStyles } from '@seekube-tech/ui';

export default makeStyles((theme) => ({
  root: {
    border: `1px solid ${theme.palette.neutral[200]}`,
    boxSizing: 'border-box',
    borderRadius: 5,
    backgroundColor: theme.palette.basic.white,
    padding: theme.spacing(2.5),
    width: '100%',
  },
  icon: {
    height: '100px !important',
    width: '100% !important',
  },
  title: {
    marginTop: theme.spacing(4),
  },
  subtitle: {
    marginTop: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(6),
  },
  fullWidth: {
    width: '100%'
  }
}));
