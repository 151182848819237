import React from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';

// Components
import { Form } from 'antd';
import { Button, H4, IconCheck, Toggle } from '@seekube-tech/ui-kit';
import { upperFirst } from 'lodash';
import { JOTFORM_URL } from '@/utils/constants';
import { H3 } from '@/components/Title';
import { Bloc, BlocHeader } from '@/components/Bloc';

// Styles & Translations
import messages from '../../messages';
import styles from '../../styles.less';

// Antd
const FormItem = Form.Item;


class FeedbackForm extends React.PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    form: PropTypes.object,
    intl: PropTypes.object,
    initialValues: PropTypes.object,
    survey: PropTypes.object,
  };

  state = {
    shareRecruiterSurveyIsActive: false,
    shareCandidateSurveyIsActive: false,
  };


  handleSubmit = (e) => {
    e.preventDefault();
    const { form: { validateFields }, onSubmit } = this.props;

    return validateFields(onSubmit);
  };


  render() {
    const {
      props: { initialValues, intl, survey, form: { getFieldDecorator, getFieldValue } },
      state: { shareCandidateSurveyIsActive, shareRecruiterSurveyIsActive },
    } = this;

    const enableCandidateSurveyField = getFieldValue('enableCandidateSurvey') === undefined ? initialValues.enableCandidateSurvey : getFieldValue('enableCandidateSurvey');
    const enableRecruiterSurveyField = getFieldValue('enableRecruiterSurvey') === undefined ? initialValues.enableRecruiterSurvey : getFieldValue('enableRecruiterSurvey');

    return (
      <Bloc>
        <Form className={styles.feedbackContainer} onSubmit={this.handleSubmit} style={{ textAlign: 'left' }} layout="vertical">
          <BlocHeader>
            <H4 className="mb-20">{intl.formatMessage(messages.feedback)}</H4>
          </BlocHeader>

          { /* Candidate block */ }
          <div className={styles.userContainer}>
            <div>
              <H3 className="mainTitle">{upperFirst(intl.formatMessage(messages.candidates, { count: 0 }))}</H3>

              <FormItem label={intl.formatMessage(messages.enableSurvey)}>
                {getFieldDecorator('enableCandidateSurvey', {
                  initialValue: initialValues.enableCandidateSurvey,
                })(
                  <Toggle checked={initialValues.enableCandidateSurvey} />
                )}
              </FormItem>
              <FormItem label={intl.formatMessage(messages.sendSurvey)}>
                {getFieldDecorator('sendCandidateSurvey', {
                  initialValue: initialValues.sendCandidateSurvey,
                })(
                  <Toggle disabled={!enableCandidateSurveyField} checked={initialValues.sendCandidateSurvey} />
                )}
              </FormItem>
            </div>
            <div className={styles.userActions}>
              <div>
                {enableCandidateSurveyField && survey && survey.candidateSurveyId ? (
                  <a href={`${JOTFORM_URL.admin}/${survey.candidateSurveyId}${JOTFORM_URL.edit}`} target="_blank" className="ant-btn">
                    {intl.formatMessage(messages.editSurvey)}
                  </a>
                ) : (
                  <Button disabled className="w-full">{intl.formatMessage(messages.editSurvey)}</Button>
                )}
              </div>
              <div>
                {enableCandidateSurveyField && survey && survey.candidateSurveyId ? (
                  <CopyToClipboard
                    text={survey.candidateUrl}
                    onCopy={() => {
                      this.setState({ shareCandidateSurveyIsActive: true, shareRecruiterSurveyIsActive: false });
                    }}
                  >
                    <Button
                      imageComponentLeft={shareRecruiterSurveyIsActive && <IconCheck size={16} />}
                      className="mr-6"
                    >
                      {!shareCandidateSurveyIsActive ? intl.formatMessage({ id: 'share' }) : (
                        intl.formatMessage({ id: 'copy.success2' })
                      )}
                    </Button>
                  </CopyToClipboard>
                ) : (
                  <Button disabled className="mr-6">{intl.formatMessage(messages.share)}</Button>
                )}

                {enableCandidateSurveyField && survey && survey.candidateSurveyId ? (
                  <a href={`${JOTFORM_URL.admin}/${survey.candidateSurveyId}${JOTFORM_URL.submissions}`} target="_blank" className="ant-btn">
                    {intl.formatMessage(messages.showResults)}
                  </a>
                ) : (
                  <Button disabled>{intl.formatMessage(messages.showResults)}</Button>
                )}
              </div>
            </div>
          </div>

          { /* Recruiter block */ }
          <div className={styles.userContainer}>
            <div>
              <H3 className="mainTitle">{upperFirst(intl.formatMessage(messages.recruiters, { count: 0 }))}</H3>

              <FormItem label={intl.formatMessage(messages.enableSurvey)}>
                {getFieldDecorator('enableRecruiterSurvey', {
                  initialValue: initialValues.enableRecruiterSurvey,
                })(
                  <Toggle checked={initialValues.enableRecruiterSurvey} />
                )}
              </FormItem>
              <FormItem label={intl.formatMessage(messages.sendSurvey)}>
                {getFieldDecorator('sendRecruiterSurvey', {
                  initialValue: initialValues.sendRecruiterSurvey,
                })(
                  <Toggle disabled={!enableRecruiterSurveyField} checked={initialValues.sendRecruiterSurvey} />
                )}
              </FormItem>
            </div>
            <div className={styles.userActions}>
              <div>
                {enableRecruiterSurveyField && survey && survey.recruiterSurveyId ? (
                  <a href={`${JOTFORM_URL.admin}/${survey.recruiterSurveyId}${JOTFORM_URL.edit}`} target="_blank" className="ant-btn">
                    {intl.formatMessage(messages.editSurvey)}
                  </a>
                ) : (
                  <Button disabled className="w-full">{intl.formatMessage(messages.editSurvey)}</Button>
                )}
              </div>
              <div>
                {enableRecruiterSurveyField && survey?.recruiterSurveyId ? (
                  <CopyToClipboard
                    text={survey.recruiterUrl}
                    onCopy={() => {
                      this.setState({ shareRecruiterSurveyIsActive: true, shareCandidateSurveyIsActive: false });
                    }}
                  >
                    <Button
                      imageComponentLeft={shareRecruiterSurveyIsActive && <IconCheck size={16} />}
                      className="mr-6"
                    >
                      {!shareRecruiterSurveyIsActive ? intl.formatMessage({ id: 'share' }) : (
                       intl.formatMessage({ id: 'copy.success2' })
                    )}</Button>
                  </CopyToClipboard>
                ) : (
                  <Button disabled className="mr-6">{intl.formatMessage({ id: 'share' })}</Button>
                )}
                {enableRecruiterSurveyField && survey && survey.recruiterSurveyId ? (
                  <a href={`${JOTFORM_URL.admin}/${survey.recruiterSurveyId}${JOTFORM_URL.submissions}`} target="_blank" className="ant-btn">
                    {intl.formatMessage({ id: 'cta.showResults' })}
                  </a>
                ) : (
                  <Button disabled>{intl.formatMessage(messages.showResults)}</Button>
                )}
              </div>
            </div>
          </div>
          {/* Submit */}
          <div>
            <Button type="submit">
              {intl.formatMessage({ id: 'save' })}
            </Button>
          </div>
        </Form>
      </Bloc>
    );
  }
}

export default Form.create()(FeedbackForm);
