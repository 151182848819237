import React, { useEffect, useState } from 'react';
import { useFormatMessage } from "react-intl-hooks";

import { Form, Select as SelectAntd } from 'antd';
import { Typography } from '@seekube-tech/ui';
import { Button, Alert } from '@seekube-tech/ui-kit';
import Modal from '@/components/ModalV2';
import request from "@/utils/request";
import Separator from '@/components/Separator';
import { Select } from '@/components/Form';

const { Option } = SelectAntd;
const FormItem = Form.Item;

const ModalAtsSource = ({ visible, syncAts, onClose, onSourceSelected, defaultSource = null }) => {
  const t = useFormatMessage();
  const [sourceSelected, setSourceSelected] = useState(null);
  const [sources, setSources] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getSources = (q) => {
    request(`${process.env.FRONT_API_URL}/ats/sources?q=${q}`, {
      method: 'GET',
    }).then((res) => {
      setIsLoading(false);
      setSources(res.docs);
    }).catch(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (syncAts && sources.length === 0) {
      getSources('');
    }

  }, [visible]);

  useEffect(() => {
    setSourceSelected(defaultSource);
  }, [defaultSource]);

  const handleSelectSource = (id) => {
    setSourceSelected(id);
  };

  const handleFinished = () => {
    if (typeof onSourceSelected === 'function') {
      onSourceSelected(sourceSelected);
    }

    setSourceSelected(null)
  }

  return (
    <Modal
      template="confirm"
      visible={visible}
      width="820px"
      onClose={onClose}
    >
      <div className="">
        <Typography variant="h4">{t({ id: 'ats.sourceModal.title' })}</Typography>
        <Separator height={32} />

        <FormItem
          required
          label={t({ id: 'ats.sourceModal.searchLabel' })}
        >
          <Select
            showSearch
            addOnIcon="search"
            mode="default"
            style={{ width: '100%' }}
            placeholder={t({ id: 'ats.sourceModal.search' })}
            onChange={handleSelectSource}
            loading={isLoading}
            className="searchSelect"
            optionFilterProp="children"
            filterOption={(input, option) => typeof option.props?.children?.toLowerCase === 'function' && option.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
            value={sourceSelected}
            notFoundContent={isLoading ? 'Chargement...' : null}
          >
            {sources?.map((o, i) => (<Option value={o.id} key={`${i}${o.id}`}>{o.label + 33}</Option>))}
          </Select>
          <Separator height={24} />
          <Alert isClosable={false}>{t({ id: 'ats.sourceModal.searchHelp' })}</Alert>
        </FormItem>

        <Separator height={32} />
        <div style={{ textAlign: 'center' }}>
          <Button disabled={!sourceSelected} onClick={handleFinished}>{t({ id: 'save' })}</Button>
        </div>
      </div>
    </Modal>
  );
}

export default ModalAtsSource;
