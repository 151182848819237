import React from 'react';
import { any, number } from 'prop-types';

import './styles.less';

/**
 * <StepIndicator />
 *
 * @description
 * Show all steps of signup process
 */
const StepsIndicatorMobile = ({ currentStep, maxStep }) => (
  <div className="steps-mobile">
    <div style={{ width: `${(currentStep * 100) / maxStep}%` }} />
  </div>
);

StepsIndicatorMobile.propTypes = {
  currentStep: any,
  maxStep: number,
};

export default StepsIndicatorMobile;
