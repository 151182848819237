import { defineMessages } from 'react-intl';

export default defineMessages({
  step1Label: {
    id: 'client.create.offer.step1.label',
  },
  step2Label: {
    id: 'client.create.offer.step2.label',
  },
  step3Label: {
    id: 'client.create.offer.step3.label',
  },
  step: {
    id: 'client.create.offer.step',
  },
});
