import React from 'react';

// Components
import { Input as InputAntd } from 'antd';

// Styles
import './styles.less';

/**
 * Input
 *
 * @param {object} props
 */
const Input = (props) => (
  <InputAntd {...props} />
);

export default Input;
