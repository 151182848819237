import React from 'react';
import { number, bool } from 'prop-types';
import { cssWarning } from '@/utils/text';

function ValueWithWarning ({ value, breakPoint = 0, enableValue = true }) {
  return (
    enableValue ? <span className={cssWarning(value <= breakPoint)}>{value}</span> : <span>-</span>

  )
}

ValueWithWarning.propTypes = {
  value: number,
  breakPoint: number,
  enableValue: bool,
}

export { ValueWithWarning }