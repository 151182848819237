import React from 'react';
import { object, any, bool } from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import moment from 'moment';
import { isEmpty, get, head } from 'lodash';
import { Link } from 'react-router-dom';
import { Alert, Body1 } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { ANALYTICS_CANDIDATE, EVENT_TYPE } from '@/utils/constants';
import { getUtcFormat } from '@/utils/format';
import { H1 } from '@/components/Title';
import Html from '@/components/Html';
import AvatarWithDate from '@/components/AvatarWithDate';
import Separator from '@/components/Separator';
import calendarCheck from '@/assets/images/calendar-check.png';
import AppointmentUnconfirmedHelp
  from '@/scenes/Event/scenes/Candidate/scenes/JobDating/components/AppointmentUnconfirmedHelp';
import messages
  from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Appointment/components/MainContent/messages';
import AddToCalendar from '@/components/AddToCalendar';
import addToCalendarTracking from '@/utils/tracking';
import styles from './styles.less';

const AppointmentConfirm = ({ appointment, selectedTimeslot, authUser, intl, skipValidation, hideHelp = false }) => {
  const timeslot = selectedTimeslot || appointment._timeslot;
  const startDate = timeslot ? moment(timeslot.beginAt) : null;
  const endDate = timeslot ? moment(timeslot.beginAt).add(timeslot.duration, 'minutes') : null;
  const t = useFormatMessage();
  const offerLink = `/${appointment._event.slug}/candidate/jobdating/jobs/${appointment._offers?.[0]?._id}`;

  const getEventParams = () => {
    let description = '';
    const location = appointment.settings.medium === 'physical' ? appointment.settings.location : '';

    if (appointment._timeslot?.medium === 'phone') {
      description = t({ id: 'add.to.calendar.description.phone' }, { recruiter_name: appointment._organizationUser.fullName });
    } else if (appointment._timeslot?.medium === 'visio') {
      description = t({ id: 'rdv.auto.calendar.description.visio' }, { visio_url: `${appointment.settings.candidateUrl}` });
    }

    description += t({ id: 'rdv.auto.calendar.description.more' }, {
      offer_url: `${window.location.origin}${offerLink}`,
      stand_url: `${window.location.origin}/${appointment._event.slug}/candidate/jobdating/exponents/${appointment._organization._id}`,
    });

    return {
      title: t({ id: 'rdv.auto.calendar.title' }, { recruiter_name: appointment._organizationUser.fullName }),
      description: description.replace(/[\r\n]/gm, ''),
      location,
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
      startTime: startDate.format('LT'),
      endTime: endDate.format('LT')
    }
  }

  moment.locale(authUser.locale || 'fr');

  const renderOfferInfos = () => {
    if (isEmpty(appointment._offers)) {
      return null;
    }

    if (appointment._offers.length > 1) {
      return null;
    }

    return (
      <><FormattedMessage {...messages.subject} /> <Link to={offerLink}>{appointment._offers[0].title}</Link></>
    );
  };

  const Title = () => {
    switch (appointment.origin) {
      case 'informal1to1' : {
        if (skipValidation) {
          if (appointment.status === 'accepted' && appointment.wasAcceptedAndUpdatedAt) {
            return (
              <H1 bold>
                {t({ id: 'event.candidate.appointment.informal1to1.validated.title' }, { firstName: appointment._organizationUser.firstName })}
              </H1>
            )
          }
          return (
            <H1 bold>
              {t({ id: 'event.candidate.appointment.informal1to1.confirm.title' }, { firstName: appointment._organizationUser.firstName })}
            </H1>
          )
        }
        if (!skipValidation && !appointment.acceptedAt) {
          return (
            <H1 bold>
              {t({ id: 'event.candidate.appointment-classic.informal1to1.confirm.title' }, { firstName: appointment._organizationUser.firstName })}
            </H1>
          )
        }

        if (!skipValidation && appointment.acceptedAt) {
          return (
            <H1 bold>
              {t({ id: 'event.candidate.appointment.informal1to1.validated.title' }, { firstName: appointment._organizationUser.firstName })}
            </H1>
          )
        }
        return null;
      }
      default:
        return (
          <H1 bold>
            <span role="img" aria-label="congratz">🎉</span>
            <DefaultTitleMessage appointment={appointment} skipValidation={skipValidation}/>
          </H1>
        )
    }
  }

  return (
    <div className={styles.appointmentConfirmation}>
      {appointment.origin === 'informal1to1' && (
        <img src={calendarCheck} alt="" className={styles.calendarCheckIcon}/>
      )}

      <Title/>

      {timeslot && (
        <AvatarWithDate
          className="avatarWithDate--appointmentConfirm"
          src={appointment._organization.profile.pictureUrl}
          alt={appointment._organization.name}
          date={timeslot.beginAt}
        />
      )}

      {!hideHelp && skipValidation && appointment.status === 'unconfirmed' && appointment._event.type !== EVENT_TYPE.jpo && (
        <AppointmentUnconfirmedHelp
          date={appointment._event.keyDates.hunt ? appointment._event.keyDates.hunt.beginAt : appointment._event.keyDates.jobfair.endAt}
        />)}

      {timeslot && (
        <Body1 className={styles.text}>
          {appointment.settings.medium === 'physical' ?
            <>
              <h3>{appointment._organizationUser.firstName} @{appointment._organization.name}</h3><br/>
              <Html
                value={intl.formatMessage(messages.acceptedTextLocation2, {
                  date: moment(timeslot.beginAt).format('D MMMM'),
                  time: moment(timeslot.beginAt).format('LT'),
                  timezone: getUtcFormat(head(get(authUser, 'timezone.utc'))),
                  medium: intl.formatMessage(messages[appointment.settings.medium]),
                })}
              />
            </>
            : <Html
              value={intl.formatMessage(messages.confirmText2, {
                recruiter: appointment._organizationUser.firstName,
                organization: appointment._organization.name,
                timezone: getUtcFormat(head(get(authUser, 'timezone.utc'))),
                medium: intl.formatMessage(messages[appointment.settings.medium]),
                date: moment(timeslot.beginAt).format('LT'),
              })}
            />
          }

          {renderOfferInfos()}
        </Body1>
      )}

      {timeslot && (
        <div className={styles.calendarSection}>
          <Alert color="info">
            <div className={styles.bold}>{t({ id: 'add.to.calendar.info' })}</div>
            {t({ id: 'add.to.calendar.info.attention' })}
          </Alert>
          <AddToCalendar
            params={getEventParams()}
            variant="fill"
            className="flex justify-center"
            onSelectCalendarType={(calendar) => addToCalendarTracking(appointment._event, calendar, ANALYTICS_CANDIDATE.EXPORTED_APPOINTMENT_CALENDAR)}
          />
          <Separator height={30}/>
        </div>
      )}
    </div>
  );
};

const DefaultTitleMessage = ({ appointment, skipValidation }) => {
  const t = useFormatMessage();

  if (skipValidation) {
    if ((appointment.wasAcceptedAndUpdatedAt || appointment.origin === 'sourcing') && appointment.status === 'accepted') {
      return t({ id: 'event.candidate.appointment.mainContent.congratulation' });
    }
    return t({ id: 'event.candidate.appointment.mainContent.auto.congratulation' });
  }

  return t({ id: 'event.candidate.appointment.mainContent.congratulation' });
}

DefaultTitleMessage.propTypes = {
  appointment: object,
  skipValidation: bool,
}

AppointmentConfirm.propTypes = {
  intl: object,
  appointment: object,
  authUser: object,
  selectedTimeslot: any,
  skipValidation: bool,
  hideHelp: bool,
};

export default injectIntl(AppointmentConfirm);
