import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { array, func, object, bool } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { get, toString, omit, isUndefined, isEmpty, filter, uniq, flatten, compact, startsWith, keys, reduce, includes, size, last, split, find } from 'lodash';
import moment from 'moment';
import { Form } from 'antd';
import draftToHtml from 'draftjs-to-html';
import { Button } from '@seekube-tech/ui-kit';
import { eventSelectors } from '@/store/event';
import { offerActions, offerSelectors } from '@/store/offer';
import { exponentSelectors } from '@/store/exponent';
import { criterionSelectors } from '@/store/criterion';
import { toJS } from '@/utils';
import { strictIncludes } from '@/utils/math';
import { queryStringToObject } from '@/utils/url';
import { getId } from '@/utils/global';
import ApiToForm from '@/services/Form/ApiToForm';
import MatchingFilterFormItem from '@/components/MatchingFilterFormItem';
import LoadingIndicator from '@/components/LoadingIndicator';
import { withAuth } from '@/containers/RequiredAuth';
import { withExponents } from '@/scenes/Client/Hoc/RequiredExponents';
import { withCriterion } from '@/scenes/Client/Hoc/RequiredCriterion';
import OfferLocation from '@/scenes/Event/scenes/Recruiter/scenes/Preparation/scenes/Offer/containers/CreateOfferModal/components/CreateOfferForm/components/OfferLocation';
import messagess from '@/scenes/Event/scenes/Recruiter/scenes/Preparation/scenes/Offer/containers/CreateOfferModal/components/InputHelper/messages';
import styles from '@/scenes/Client/scenes/EditOffer/styles.less';
import hasDurationContract from '@/utils/forms/contractDuration';
import messages from './messages';

class EditOfferStep3 extends PureComponent {
  static propTypes = {
    offers: array,
    exponents: array,
    defaultCriteria: array,
    locations: array,
    offer: object,
    location: object,
    authUser: object,
    form: object,
    unSavedOffer: object,
    saveOffer: func,
    getGlobalOffer: func,
    setOffer: func,
    showHelper: func,
    isFetchingOffers: bool,
    isExponentsFetching: bool,
  };

  static DEFAULT_CRITERION_KEYS = ['CONTRACT', 'AVAILABILITY', 'DURATION', 'MOBILITY', 'POSITION'];

  static DEFAULT_CRITERION_KEYS_USED_IN_CUSTOM = ['EXPERIENCE', 'TECHNOS'];

  constructor(props) {
    super(props);
    const { offerId } = queryStringToObject(props.location.search);
    const getOffer = props.offers.find((off) => getId(off) === getId(this.props.offer));
    if (getOffer.childrenEvents === 'undefined') {
      props.getGlobalOffer({ offerId });
    }
  }

  state = {
    isSubmitBtnDisabled: true,
    locations: this.props.offer.locations || [],
  };

  getSeparateCriteria = () => {
    const { props: { defaultCriteria, offer } } = this;
    const { DEFAULT_CRITERION_KEYS } = EditOfferStep3;
    const sortCriteria = DEFAULT_CRITERION_KEYS.map((key) => defaultCriteria.find((criterion) => criterion.key === key));
    const defaultFilters = ApiToForm.matchingDefaultCriteria(sortCriteria, offer.matching).filters.reduce((prevVal, val) => ([...prevVal, ...val]), []);
    const customerFilters = offer.matching.filters.slice(defaultFilters.length, offer.matching.filters.length);
    const childrenCustomFilter = offer.childrenOffers
      .map((childrenOffer) => (childrenOffer.matching && childrenOffer.matching.filters.slice(defaultFilters.length, childrenOffer.matching.filters.length)) || [])
      .reduce((prevVal, val) => ([...prevVal, ...val]), []);

    return {
      defaultFilters,
      customerFilters: [...customerFilters, ...childrenCustomFilter],
    };
  };

  getEvent = (eventId) => {
    const { props: { exponents } } = this;
    return exponents.find((exponent) => getId(get(exponent, '_event')) === eventId)._event;
  };

  getEvents = () => {
    const { props: { exponents } } = this;
    const events = exponents.map((exponent) => exponent._event);
    const getOffer = this.props.offers.find((off) => off._id === this.props.offer._id);

    if (isUndefined(this.props.offer.childrenEvents) || this.props.offer.childrenEvents.length !== this.props.offer._events.length) {
      getOffer.childrenEvents = filter(events, (event) => strictIncludes(this.props.offer._events, getId(event)));
    }

    const childrenEvents = this.props.offer._events.length ? getOffer.childrenEvents : [this.props.offer._event, ...this.props.offer.childrenEvents];

    return compact(childrenEvents);
  };

  getDefaultCriteria = (events) => {
    const { DEFAULT_CRITERION_KEYS } = EditOfferStep3;
    const defaultKeys = DEFAULT_CRITERION_KEYS;

    return compact(uniq(flatten(events.map((event) => event._criteria.map((criteria) => strictIncludes(defaultKeys, criteria.key))))));
  };

  saveDraft = () => {
    const { dispatchValuesInOffers } = this;
    const { props: { form: { getFieldsValue }, saveOffer } } = this;
    saveOffer(dispatchValuesInOffers(getFieldsValue()).map((off) => ({ ...off, _event: getId(off._event), status: 'draft' })));
  };

  saveForm = () => {
    this.props.saveOffer({ ...this.props.offer, status: 'published' });
  };

  handleBlurLocations = () => {
    // this.props.form.validateFields();
  };

  handleChangeLocations = (values) => {
    this.setState({ locations: values, locationsCount: values.length });
    this.checkForm('locations', values);
  };

  result = { merge: [] };

  computeValues = (values) => {
    const {
      state: { locations },
      props: { authUser },
    } = this;
    const newValues = values;

    newValues._events = this.getEvents().map((event) => getId(event));
    newValues._organization = authUser._currentOrganization._id;
    newValues.tags = [];
    newValues.locations = locations;
    newValues.matching = {};

    const matchingFilters = Object
      .keys(values)
      .filter((value) => value.includes('matching-filters') === true && values[value] !== undefined)
      .map((filter) => values[filter]);

    Object.keys(newValues)
      .filter((value) => value.includes('matching-filters') === true && values[value] === undefined)
      .forEach((filter) => { newValues[filter] = []; });

    const filters = [];

    Object.keys(matchingFilters).forEach((index) => {
      const filterValue = matchingFilters[index];

      if (typeof filterValue === 'object') {
        filterValue.forEach((item) => {
          filters.push(item);
        });
      } else {
        filters.push(filterValue);
      }
    });

    const languages = [];

    Object
      .keys(values)
      .filter((value) => value.includes('matching-languages-lang') === true)
      .forEach((filter, index) => {
        if (values[`matching-languages-lang-${index}`]) {
          languages.push({
            lang: values[`matching-languages-lang-${index}`],
            level: values[`matching-languages-level-${index}`],
          });
        }
      });

    if (newValues.license) {
      newValues.matching.license = newValues.license;
    }

    newValues.matching.languages = languages;
    newValues.matching.filters = filters;

    if (values['matching-date-min1']) {
      newValues.matching.date = values['matching-date-min1'];
    } else {
      newValues.matching.date = null;
    }

    // Wysiwyg fields
    if (typeof newValues.description === 'object') {
      newValues.description = draftToHtml(newValues.description);
    }

    if (typeof newValues.profileDescription === 'object') {
      newValues.profileDescription = draftToHtml(newValues.profileDescription);
    }

    if (values['matching-experiences']) {
      newValues.matching.experiences = values['matching-experiences'];
    } else {
      newValues.matching.experiences = null;
    }

    return newValues;
  };

  dispatchValuesInOffers = (values) => {
    const {
      props: {
        unSavedOffer,
        offer,
      },
      computeValues,
    } = this;
    const { DEFAULT_CRITERION_KEYS } = EditOfferStep3;
    const newValues = computeValues(values);
    const valuesKeys = keys(newValues);
    const defaultFiltersKeys = compact(DEFAULT_CRITERION_KEYS.map((criterionKey) => valuesKeys.filter((valueKey) => startsWith(valueKey, `matching-filters-${criterionKey}`))[0]));
    const defaultFiltersKeysValues = defaultFiltersKeys.reduce((prevVal, val) => [...prevVal, ...newValues[val]], []);

    return offer._events.map((eventId) => {
      const customFiltersKeys = valuesKeys.filter((valueKey) => strictIncludes(valueKey, eventId));
      const customerFiltersValues = customFiltersKeys.reduce((prevVal, val) => {
        const currentVal = typeof newValues[val] === 'string' ? [newValues[val]] : newValues[val];
        return [...prevVal, ...currentVal];
      }, []);

      return (
        { publishedAt: new Date(), ...unSavedOffer, _event: this.getEvent(eventId), deleted: offer._events.indexOf(eventId) === -1, status: 'published', matching: { filters: [...defaultFiltersKeysValues, ...customerFiltersValues], date: values.matching.date, experiences: newValues.matching.experiences }, locations: newValues.locations }
      );
    });
  };

  tmpSave = () => {
    const {
      props: { setOffer }, dispatchValuesInOffers } = this;
    const { props: { form: { getFieldsValue } } } = this;
    const values = getFieldsValue();

    setOffer({ ...dispatchValuesInOffers(values)[0] }, true);
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const {
      props: {
        form: { validateFields, resetFields },
        saveOffer,
      },
      dispatchValuesInOffers,
    } = this;

    return validateFields((err, values) => {
      if (!err) {
        resetFields();
      }
      return saveOffer(dispatchValuesInOffers(values));
    });
  };

  isFieldMinValid = (filterName, value) => {
    const min = Number(last(split(filterName, '-min')));

    return size(value) >= min;
  };

  isFormValid = (filterName, value) => {
    const {
      props: { form: { getFieldsValue } },
      isDurationDisabled, isFieldMinValid,
    } = this;

    let values = { ...getFieldsValue(), [filterName]: value };

    if (isDurationDisabled) {
      const durationKey = keys(getFieldsValue()).find((val) => startsWith(val, 'matching-filters-DURATION'));
      values = omit(values, [durationKey]);
    }

    const optionalKeys = keys(getFieldsValue()).filter((val) => startsWith(val, 'matching-filters-CONTRACT') || startsWith(val, 'matching-filters-Technos') || startsWith(val, 'matching-experiences'));
    values = omit(values, optionalKeys);

    return reduce(values, (result, value, key) => result && isFieldMinValid(key, value), true);
  };

  isContractField = (filterName) => strictIncludes(filterName, 'matching-filters-CONTRACT');

  checkForm = (filterName, value) => {
    const { isFormValid, isContractField } = this;

    if (isContractField(filterName)) {
      this.isDurationDisabled = !hasDurationContract(value);
    }
    this.setState({ isSubmitBtnDisabled: !isFormValid(filterName, value) });
  };

  renderDefaultFilters = () => {
    const {
      props: { form: { getFieldDecorator, getFieldValue, getFieldsValue }, showHelper, offer, form, authUser, defaultCriteria },
      state: { locations },
      handleBlurLocations, handleChangeLocations, checkForm,
    } = this;
    const { DEFAULT_CRITERION_KEYS } = EditOfferStep3;
    const sortCriteria = DEFAULT_CRITERION_KEYS.map((key) => defaultCriteria.find((criterion) => criterion.key === key));
    const initialValues = ApiToForm.matchingDefaultCriteria(sortCriteria, offer.matching);

    return DEFAULT_CRITERION_KEYS.map(
      (criteriaKeys, index) => {
        const required = true;
        // Contract filter is based on event criteria and not on default filters in step 3
        const criterion = defaultCriteria.find((criterion) => criterion.key === criteriaKeys);
        const context = 'offer';
        const choiceMin = get(criterion, 'modules.offer.choiceMin');
        let isRequired = choiceMin > 0;
        let isDisabled;
        let availabilityKey = null;
        if (criterion.modules.offer.isVisible === false) {
          return null;
        }
        const fieldKey = `matching-filters-${criteriaKeys}-${index}-min${choiceMin}`;

        if (criteriaKeys === 'MOBILITY' && required === isRequired) {
          return (<OfferLocation
            authUser={authUser}
            locations={locations}
            handleBlurLocations={handleBlurLocations}
            handleChangeLocations={handleChangeLocations}
            {...form}
            fieldKey={`${criteriaKeys}-${index}-min${choiceMin}`}
            criterion={criterion}
          />);
        }

        if (isRequired !== required) {
          return null;
        }

        if (criteriaKeys === 'AVAILABILITY' && isEmpty(initialValues.availability) && isEmpty(offer.matching.date)) {
          availabilityKey = `matching-date-min${choiceMin}`;
          if (Number(moment().format('YMM')) > Number(moment.unix(getFieldsValue()[availabilityKey]).format('YMM'))) {
            offer.matching.date = null;
            this.checkForm(availabilityKey, offer.matching.date);
          } else if (!isUndefined(getFieldsValue()[availabilityKey])) {
            this.checkForm(availabilityKey, getFieldsValue()[availabilityKey]);
          }
        }

        if (criteriaKeys === 'CONTRACT') {
          const durationKey = keys(getFieldsValue()).find((val) => startsWith(val, 'matching-filters-DURATION'));
          if (isUndefined(durationKey)) {
            this.checkForm(fieldKey, initialValues.filters[index]);
          }
          isDisabled = true;
          isRequired = false;
        }

        const endDate = moment().toDate();

        return (
          <Fragment key={`formItem${criterion._id}${index}`}>
            <MatchingFilterFormItem
              getFieldDecorator={getFieldDecorator}
              availabilityKey={availabilityKey}
              key={`formItem${criterion._id}${index}`}
              criterion={criterion}
              fieldDecoratorKey={fieldKey} // Warning: Required fieldDecoration doesn't work with array in fieldDecoratorKey
              initialValue={(initialValues.filters[index]) || []}
              matchingData={offer ? offer.matching : null}
              context={context}
              offer={offer}
              endDate={endDate}
              getFieldValue={getFieldValue}
              disableDuration={this.isDurationDisabled}
              form={form}
              required={isRequired}
              withInputErrorStyle={false}
              showHelper={showHelper}
              authUser={authUser}
              onChange={checkForm}
              isDisabled={isDisabled}
            />
          </Fragment>
        );
      }
    );
  };

  renderCustomerFilters = () => {
    const {
      props: { form: { getFieldDecorator, getFieldValue }, showHelper, form, authUser, offer },
      state: { disableDuration },
      isDurationDisabled, checkForm, getEvents,
    } = this;

    const { DEFAULT_CRITERION_KEYS_USED_IN_CUSTOM } = EditOfferStep3;
    const parentOffer = offer;
    const parentEvent = this.props.exponents.find((exponent) => toString(get(exponent, '_event._id')) === ((parentOffer._event && get(parentOffer, '_event._id') && getId(parentOffer._event)) || parentOffer._event || toString(get(parentOffer.childrenOffers[0], '_event._id'))))._event;
    const childrenOffers = offer._events
      .filter((eventId) => getId(parentEvent) !== eventId && !isEmpty(this.props.exponents.find((exponent) => getId(exponent._event) === eventId)))
      .map((eventId) => parentOffer.childrenOffers.find((childOffer) => get(childOffer, '_event._id') === eventId) || { _event: this.getEvent(eventId) });

    const offersWithChildren = [{ ...omit(parentOffer, 'childrenOffers'), _event: parentEvent }, ...childrenOffers];
    const { customerFilters } = this.getSeparateCriteria();

    return offersWithChildren.map(
      (off) => {
        const eventCriteria = filter(get(find(getEvents(), (ev) => getId(ev) === getId(off._event)), '_criteria'), (criterion) => ((isEmpty(criterion.key) || includes(DEFAULT_CRITERION_KEYS_USED_IN_CUSTOM, criterion.key)) && criterion.enable && criterion.modules.offer.isVisible));
        const label = <FormattedMessage {...messages.criterionSpecific} values={{ name: off._event.name }} />;
        const initialValues = ApiToForm.matchingFilters(eventCriteria, { filters: customerFilters });
        const context = 'offer';
        const endDate = off._event.keyDates ? moment(off._event.keyDates.jobfair.endAt).toDate() : new Date();

        if (eventCriteria.length === 0) return null;

        return (
          <>
            <h3 style={{ marginBottom: '13px' }}>{label}</h3>
            {eventCriteria.map((criterion, i) => {
              const choiceMin = get(criterion, 'modules.offer.choiceMin');
              const fieldKey = `matching-filters-${criterion.name}-${off._event._id}-min${choiceMin}`;

              return (
                <MatchingFilterFormItem
                  getFieldDecorator={getFieldDecorator}
                  key={`formItem${criterion._id}${i}`}
                  criterion={criterion}
                  fieldDecoratorKey={fieldKey} // Warning: Required fieldDecoration doesn't work with array in fieldDecoratorKey
                  initialValue={initialValues.filters[i] || []}
                  matchingData={off ? off.matching : null}
                  context={context}
                  offer={off}
                  endDate={endDate}
                  getFieldValue={getFieldValue}
                  disableDuration={disableDuration}
                  validatorCallback={isDurationDisabled}
                  form={form}
                  required={choiceMin > 0}
                  withInputErrorStyle={false}
                  showHelper={showHelper}
                  authUser={authUser}
                  onChange={checkForm}
                />);
            })}
          </>);
      }
    );
  };

  render() {
    const { state: { isSubmitBtnDisabled }, props: { isFetchingOffers, isExponentsFetching, defaultCriteria }, getEvents } = this;
    const events = getEvents();

    if (isFetchingOffers || !defaultCriteria.length || isExponentsFetching || isEmpty(events)) return <LoadingIndicator />;

    return (
      <div>
        <FormattedMessage {...messages.title} tagName="h1" />
        <h3 style={{ marginBottom: '30px' }}><FormattedMessage {...messages.subtitle} /></h3>
        <Form onSubmit={(e) => this.handleSubmit(e)} id="offerForm" className={styles.form}>
          {this.renderDefaultFilters()}
          {this.renderCustomerFilters()}
          <div className={styles.submitContainer}>
            <Button className="mr-6" onClick={this.saveDraft} variant="tonal">
              <FormattedMessage {...messagess.saveDraft} />
            </Button>
            <Button disabled={isSubmitBtnDisabled} type="submit">
              <FormattedMessage {...messagess.publishOffer} />
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}

const withForm = Form.create();

const mapStateToProps = createStructuredSelector({
  isFetching: eventSelectors.getEventsFetching,
  isFetchingOffers: offerSelectors.getOffersFetching,
  isExponentsFetching: exponentSelectors.getExponentsFetching,
  offers: offerSelectors.getOffers,
  exponents: exponentSelectors.getExponents,
  defaultCriteria: criterionSelectors.getDefaultCriteria,
});

const mapDispatchToProps = {
  getGlobalOffer: offerActions.getGlobalOffer,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(
  withForm,
  withRouter,
  withConnect,
  withCriterion(),
  withExponents(),
  withAuth(),
)(toJS(EditOfferStep3));
