import React from 'react';
import { bool, object, string } from 'prop-types';
import classnames from 'classnames';
import { getCdnUrl } from '../../../ImgCdn';

class Image extends React.PureComponent {
  static propTypes = {
    element: object,
    className: string,
    modalIsOpen: bool,
  };

  /**
   * Display Image based on the element
   *
   * @description
   * If we have a url use it, else used file key and display a preview
   */
  componentDidMount() {
    const { props: { element, modalIsOpen } } = this;
    const { url, originalUrl } = element;

    // Display a preview if url is a File
    if (url instanceof File) {
      const reader = new FileReader();

      // Display a preview
      reader.onload = (e) => {
        this.imgDomElement.src = e.target.result;
      };

      reader.readAsDataURL(url);
    } else {
      // If modalIsOpen, modalFull is opened with image in big size
      // We don't want to resize the image in that case to prevent low quality
      // In that case, we use originalUrl if it's defined. It is url without any resizing
      const urlToUse = !modalIsOpen ? url : originalUrl || url;
      this.imgDomElement.src = getCdnUrl(urlToUse, modalIsOpen ? null : 500);
    }
  }


  render() {
    const { props: { element, className } } = this;

    return (
      <div className={classnames(className)} {...this.props}>
        <a href={element.href} target="_blank">
          <img ref={(e) => (this.imgDomElement = e)} alt={element.name} />
        </a>
      </div>
    );
  }
}

export default Image;
