import React from 'react';
import { any, string } from 'prop-types';

import styles from './styles.less';

const OauthCover = ({ coverUrl, children, style = {} }) => (
  <div
    className={styles.cover}
    style={{ backgroundImage: coverUrl ? `url(${coverUrl})` : null, backgroundSize: 'cover', ...style }}
  >
    <div className={styles.mask}>{children}</div>
  </div>
);


OauthCover.propTypes = {
  children: any,
  style: any,
  coverUrl: string,
}

export default OauthCover;
