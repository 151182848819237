import React from 'react';
import { object, number, string, any } from 'prop-types';

// Components
import Avatar from '@/components/Avatar';

// Styles
import styles from './styles.less';

/**
 * AvatarStatus
 *
 * @param {object} props
 */
const AvatarStatus = ({ appointment, offer, size, status }) => {
  const user = (appointment && appointment._organizationUser) ? appointment._organizationUser : offer._user ? offer._user : null; // eslint-disable-line
  const avatarSrc = user ? user.pictureUrl : '';
  return (
    <div className={styles.avatar}>
      <Avatar size={size} src={avatarSrc} type="circle" status={status} user={user} />
    </div>
  );
};

AvatarStatus.propTypes = {
  offer: object,
  size: number,
  status: string,
  appointment: any,
};

export default AvatarStatus;
