import React, { useEffect, useState } from 'react';
import { object, func } from 'prop-types';
import { compose } from 'redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { size, pick, isEmpty, get, isEqual, intersection } from 'lodash';
import { Form } from 'antd';
import { withRouter } from 'react-router-dom';
import { Button } from '@seekube-tech/ui-kit';
import { toJS } from '@/utils';
import { organizationActions } from '@/store/organization';
import { offerActions } from '@/store/offer';
import { areWysiwygFieldCompleted } from '@/components/WysiwygEditor';
import messagess from '@/scenes/Event/scenes/Recruiter/scenes/Preparation/scenes/Offer/containers/CreateOfferModal/components/InputHelper/messages';
import styles from '@/scenes/Client/scenes/EditOffer/styles.less';
import { getId } from '@/utils/global';
import {
  OfferContractsForm
} from '@/scenes/Client/scenes/EditOffer/components/EditOfferStep1/components/OfferContractsForm';
import { useGetOrganizationUsersSelect } from '@/queries/organizations/useGetOrganizationUsersSelect';
import { useGetOrganizationUserContracts } from '@/queries/contracts/useGetOrganizationUserContracts';
import messages from './messages.js';
import { OfferTitleForm, OfferOwnerForm, OfferDescriptionForm, OfferProfileDescriptionForm } from './components';

const EditOfferStep1 = ({
  saveOffer,
  setOffer,
  offer,
  form,
  offerDB,
  intl,
}) => {
  const FIELDS = ['title', 'profileDescription', '_user', 'description', 'matching.filters'];
  const [step1Values, setStep1Values] = useState({ ...offer });
  const [disableNextbtn, setDisableNextbtn] = useState(true);
  const [title] = useState(step1Values.title || offer.title);
  const userId = form.getFieldValue('_user');
  const contracts = useGetOrganizationUserContracts({
    organizationId: step1Values._organization._id ?? step1Values._organization,
    userId ,
    enabled: userId !== undefined && (step1Values._organization._id ?? step1Values._organization) !== undefined
  });
  const shouldDisableContracts = !userId || userId && contracts.data && isEmpty(contracts.data.contracts) && contracts.data.countEvents > 0;

  const { data: users } = useGetOrganizationUsersSelect({
    organization: step1Values._organization
  });

  useEffect(() => {
    const fieldsCount = shouldDisableContracts ? 4 : 5;

    setDisableNextbtn(!(size(step1Values) >= fieldsCount && areFieldsCompleted()));
  }, [])

  const saveForm = () => {
    const values = { ...pick(step1Values, FIELDS) };
    tmpSave();
    saveOffer(values);
  };

  const checkValues = (values) => {
    const hasChanged = !(offerDB && FIELDS
      .map((fieldName) => offerDB[fieldName] && (isEqual(getId(values[fieldName]), getId(offerDB[fieldName]))))
      .reduce((prev, val) => prev && val, true));

    return hasChanged;
  };

  const tmpSave = () => {
    const values = { ...pick(step1Values, FIELDS) };
    const hasChanged = checkValues(values);
    setOffer(values, hasChanged);
  };

  const formValues = (fieldName, val) => {
    const updatedStepValues = step1Values;

    if(val.target && val.target.value === "" || val === '' || val === '<p></p>\n') {
      delete updatedStepValues[fieldName];
    }
    else if (fieldName === 'filters') {
      updatedStepValues.matching = { ...updatedStepValues.matching, filters: val };
    } else {
      updatedStepValues[fieldName] = val.target ? val.target.value : val;
    }
    const countFields = shouldDisableContracts ? 4 : 5;
    setStep1Values(updatedStepValues);
    setDisableNextbtn(!(size(updatedStepValues) >= countFields && areFieldsCompleted(updatedStepValues)));
  };

  const save = () => {
    saveOffer({ ...step1Values }, 'draft');
  };

  const areFieldsCompleted = (values = step1Values) => {
    const fields = [values.title, values._user];

    if (!shouldDisableContracts) {
      fields.push(values.matching?.filters);
    }

    return fields
        .reduce((previousValue, currentValue) => previousValue && !isEmpty(currentValue), true)
      && areWysiwygFieldCompleted([step1Values.description, step1Values.profileDescription]);
  };

  const handleChangeWysiwyg = (fieldName, value, key) => {
    if (typeof value === 'string') {
      const tmp = document.createElement('div');
      tmp.innerHTML = value;

      const cleanValue = tmp.innerText;
      const newState = {};

      newState[key] = cleanValue.length > 1;
      formValues(fieldName, value);
    }
  };

  const editOwner = (val) => {
    formValues('_user', users?.find((user) => getId(user) === val) ?? []);
    formValues('filters', []);
    form.setFieldsValue({ 'matching.filters': [] });
  }

  return (
    <>
      <h1><FormattedMessage {...messages.title} /></h1>
      <p><FormattedMessage {...messages.subtitle} /></p>
      <OfferTitleForm intl={intl} initialValue={title} handleOnFormedChange={(val) => { formValues('title', val); }} />
      <OfferOwnerForm
        handleOnOfferOwnerChange={editOwner}
        initialValue={get(step1Values, '_user._id') || get(step1Values, '_user')}
        placeholder={intl.formatMessage(messages.placeholderOfferOwner)}
        intl={intl} users={users ?? []} {...form}
      />
      <OfferContractsForm
        disabled={shouldDisableContracts}
        contracts={contracts.data?.contracts}
        users={users ?? []}
        organizationId={step1Values._organization._id ?? step1Values._organization}
        handleChange={(val) => { formValues('filters', val)}}
        initialValue={intersection(get(step1Values, 'matching.filters'), contracts.data?.contracts.map(contract => contract._id))}
        showEmptyError={contracts.data?.countEvents === 0}
        { ...form }
      />
      <OfferDescriptionForm intl={intl} {...form} showHelper={() => {}} initialValue={step1Values.description} handleOnDescriptionChange={(value) => handleChangeWysiwyg('description', value, 'hasDescription')}/>
      <OfferProfileDescriptionForm intl={intl} {...form} showHelper={() => {}} initialValue={step1Values.profileDescription} handleOnProfileDescriptionChange={(value) => handleChangeWysiwyg('profileDescription', value, 'hasDescription')}/>

      <div className={styles.submitContainer}>
        <Button className="mr-6" c variant="tonal" onClick={save}>
          <FormattedMessage {...messagess.saveDraft} />
        </Button>
        <Button disabled={disableNextbtn} onClick={saveForm}>
          <FormattedMessage {...messages.next} />
        </Button>
      </div>
    </>
  );
}

const withConnect = connect(null, {
  postGlobalOffer: offerActions.postGlobalOffer,
  getOrganization: organizationActions.getOrganization,
});
const withForm = Form.create();

EditOfferStep1.propTypes = {
  saveOffer: func,
  setOffer: func,
  offer: object,
  form: object,
  offerDB: object,
  intl: object,
}


export default compose(
  withForm,
  withConnect,
  withRouter,
  injectIntl,
)(toJS(EditOfferStep1));
