import { isArray, isEmpty } from 'lodash';
import classnames from 'classnames';
import styles from '@/scenes/Event/components/StaticOffers/components/Filters/styles.less';
import Selector from '@/components/Form/Selector';
import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { Form } from 'antd';
import { CONFERENCE_TOPICS } from '@/utils/constants';

const FormItem = Form.Item;

export const TopicSelector = ({
  isMobile,
  facetKey,
  updateSearch,
  search,
  facetsConferences,
  context,
}) => {
  const t = useFormatMessage();
  if (!event?.modules?.live?.filterByTopicEnable) return null;

  let topics = [];

  const options = CONFERENCE_TOPICS.map((o) => ({
    value: o,
    label: t({ id: `conference.topics.${o}` }),
    badgeCount: facetsConferences?.topics?.[o] || 0
  }));

  if (!isEmpty(search) && !isEmpty(search.topics)) {
    if (isArray(search.topics)) {
      topics = search.topics;
    } else {
      topics = search.topics.split(',');
    }
  }

  return (
    <FormItem className={classnames(styles.formItem, context === 'exponent' ? styles.formItemBordered : '')} style={{ minWidth: '140px', width: '140px' }}>
      <Selector
        position="left"
        isMobile={!!isMobile}
        label={t({ id: 'conference.topics.label' })}
        noLabel={t({ id: 'searchEngine.noLabel' })}
        options={options}
        values={topics}
        onChange={(e) => {
          updateSearch('topics', e, facetKey, context);
        }}
      />
    </FormItem>
  );
}
