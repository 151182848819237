import React from 'react';
import { keys, map, pick } from 'lodash';
import { percentage } from '@/utils/math';

import { FormattedMessage } from 'react-intl';
import { WithPercentage } from '@/components/Stats';

const getStats = (stats) => ({
  // lives
  countLives: {
    title: <FormattedMessage id="total" />,
    tooltip: <FormattedMessage id="owner.lives.stats.tooltip.countLives" />,
    icon: 'playVideo2',
    count: 0,
    noSeparator: true
  },
  // organisateurs
  countOrganizations: {
    title: <FormattedMessage id="inputMaterial.select.company.multiple" />,
    tooltip: <FormattedMessage id="owner.lives.stats.tooltip.countOrganizations" />,
    icon: 'building',
    count: 0,
    noSeparator: true,
  },
  // remplisage
  fill: {
    title: <FormattedMessage id="filling" />,
    tooltip: <FormattedMessage id="owner.lives.stats.tooltip.fill" />,
    icon: 'timer',
    formatCount: (count) =>  `${Math.round(count * 100)} %`,
    noSeparator: false,
    separatorEndChild: true,
    count: 0,
  },
  // Participations
  countAttendees: {
    title: <FormattedMessage id="total" />,
    tooltip: <FormattedMessage id="owner.lives.stats.tooltip.countAttendees" />,
    icon: 'user',
    count: 0,
    noSeparator: true,
  },
  countUniqAttendees: {
    title: <FormattedMessage id="uniq.pl" values={{count: 2 }} />,
    tooltip: <FormattedMessage id="owner.lives.stats.tooltip.countUniqAttendees" />,
    additionalText: <WithPercentage tooltip={<FormattedMessage id="owner.lives.stats.tooltip.countUniqAttendees.percentage" />} value={percentage(stats.countUniqAttendees, stats.countEventParticipants, 0, true)} />,
    count: 0,
    noSeparator: true,
  },
  countPresent: {
    title: <FormattedMessage id="present.pl" values={{count: 2 }} />,
    tooltip: <FormattedMessage id="owner.lives.stats.tooltip.countPresent" />,
    additionalText: <WithPercentage tooltip={<FormattedMessage id="owner.lives.stats.tooltip.countPresent.percentage" />} value={percentage(stats.countPresent, stats.countAttendees, 0, true)} />,
    icon: 'refuse',
    count: 0,
    noSeparator: true,
  },
});

const getLiveStats = (stats) => {
  const pageStats = getStats(stats);
  const statsList = keys(pageStats);
  const statsFound = keys(pick(stats, statsList));

  return map(statsFound, (key) => ({ ...pageStats[key], count: stats[key] }));
}

export { getLiveStats }
