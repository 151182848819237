import React from 'react';

// Components
import { Radio as RadioAntd } from 'antd';

// Styles & Translations
import './styles.less';

/**
 * Radio
 *
 * @param {object} props
 */
const Radio = (props) => (
  <RadioAntd {...props} />
);

export const RadioGroup = RadioAntd.Group;
export const RadioButton = RadioAntd.Button;
export default Radio;
