import React from 'react';
import { object, array, func } from 'prop-types';
import { Tab } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { useHistory } from 'react-router-dom';
import { HASH } from '@/scenes/Event/containers/Landing/const';

const LandingNavBar = ({
  match,
  availableTabs,
  onChangeTab = () => {},
  counters,
}) => {
  const history = useHistory();
  const t = useFormatMessage();

  if (availableTabs?.length === 0) {
    return null
  }

  if (!counters) {
    return null;
  }

  function getCurrentTabName() {
    switch (history.location.hash) {
      case `#${HASH.offers}`:
        return HASH.offers
      case `#${HASH.informal1to1s}`:
        return HASH.informal1to1s
      case `#${HASH.conferences}`:
        return HASH.conferences
      default:
        return 'home'
    }
  }

  function getCurrentIndex() {
    return tabs.find((tab) => tab.paramValue === getCurrentTabName())?.index || 0;
  }

  const tabs = [
    {
      name: t({ id: `global.information` }),
      param: '',
      paramValue: 'home',
    },
    {
      name: `${t({ id: `stand.navbar.offers` })}`,
      count: counters.offers,
      param: `#${HASH.offers}`,
      paramValue: HASH.offers,
    },
    {
      name: `${t({ id: `stand.navbar.informal1to1s` })}`,
      count: counters.informal1to1s,
      param: `#${HASH.informal1to1s}`,
      paramValue: HASH.informal1to1s,
    },
    {
      name: `${t({ id: `live.pl` }, { count: 2 })}`,
      count: counters.conferences,
      param: `#${HASH.conferences}`,
      paramValue: HASH.conferences,
    },
  ]
    .filter(tab => ['home', ...availableTabs].includes(tab.paramValue))
    .map((tab, index )=> ({ ...tab, index }));

  const defaultIndex = getCurrentIndex();

  const onIndexChange = (index) => {
    history.push(`${match.url}${history.location.search}${tabs[index].param}`);
    onChangeTab(tabs[index].param);
  }

  return (
    <div className="mb-40">
      <Tab.Group defaultIndex={defaultIndex} onChange={onIndexChange}>
        <Tab.List>
          {tabs.map(({ name, count }) => (
            <Tab key={name} as="span">
              {count ? `${name} (${count})` : name }
            </Tab>
          ))}
        </Tab.List>
      </Tab.Group>
    </div>
  );
}

LandingNavBar.propTypes = {
  match: object,
  availableTabs: array,
  onChangeTab: func,
  counters: object
}

export { LandingNavBar };
