import { useQuery } from 'react-query';
import request from '@/utils/request';
import { isEmpty } from 'lodash';

export function useGetCheckCandidateOnboardings({ user, ...props }) {

  const requestUrl = `${process.env.FRONT_API_URL}/users/${user?._id}/checkOnboardings`;
  const queryFn = () => request(requestUrl, { method: 'GET' });
  const isCandidate = isEmpty(user?._currentOrganization);

  const query = useQuery({
    queryKey: ['checkOnboardings', user?._id],
    queryFn,
    refetchOnWindowFocus: false,
    enabled: !!user?._id && isCandidate && !user.isActive,
    ...props,
  });

  return query;
}
