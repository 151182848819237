import * as React from 'react';
import { bool, number } from 'prop-types';
import { FormattedHTMLMessage } from 'react-intl';
import { IconPhone } from '@seekube-tech/ui-kit';
import classNames from 'classnames';
import styles from '../styles.less';

function AppointmentNumber({ countTotalAppointments, countAppointments, countUnconfirmed, skipAppointmentValidation, areInteractionsClosed }) {
  if (skipAppointmentValidation) {
    return (
      <span key="appointmentsNumber" className={classNames(countUnconfirmed > 0 ? styles.highlight : '')}>
        <IconPhone size={16} />{
          areInteractionsClosed ?
            <FormattedHTMLMessage values={{ count: countTotalAppointments }} id="events.stats.candidate.appointments.confirmed" />
            : <FormattedHTMLMessage values={{ count: countUnconfirmed }} id="events.stats.candidate.appointments.unconfirmed" />
        }
      </span>
    );
  }

  return (
    countAppointments ?
      <span key="appointmentsNumber">
        <IconPhone size={16} /><FormattedHTMLMessage values={{ count: countAppointments }} id="events.stats.candidate.appointments.upcoming" />
      </span> : null
  );
};

AppointmentNumber.propTypes = {
  countTotalAppointments: number,
  countAppointments: number,
  countUnconfirmed: number,
  skipAppointmentValidation: bool,
  areInteractionsClosed: bool
}

export default AppointmentNumber;
