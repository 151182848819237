import React from 'react';
import ReactDOM from 'react-dom';
import { node, string, oneOfType, bool, func } from 'prop-types';
import { ButtonRound } from '@seekube-tech/ui-kit';
import Slider from 'react-slick';
import Wrapper from '@/components/Wrapper';
import Icon from '@/components/Icon';
import Gallery from '@/components/Gallery';

// Styles & Translations
import './styles.less';

/**
 * Carousel
 * @param {object} props
 */
class Carousel extends React.PureComponent {
  static propTypes = {
    withEnlargeBtn: bool,
    children: oneOfType([string, node]).isRequired,
    onEnlargeCarousel: func,
  };

  static defaultProps = {
    withEnlargeBtn: false,
  };

  state = {
    adaptiveHeight: false,
    carouselIsEnlarge: false,
  };

  componentDidMount() {
    const { reRender } = this;
    const imgs = ReactDOM.findDOMNode(this.slider).querySelectorAll('img'); // eslint-disable-line

    let loadedImgs = 0;

    imgs.forEach((img) => {
      const imgItem = img;

      imgItem.onload = () => {
        loadedImgs += 1;

        if (loadedImgs === imgs.length && this.slider !== null) {
          this.slider.innerSlider.adaptHeight();
        }

        reRender();
      };
    });
  }

  onEnlargeCarousel = () => {
    this.setState({ carouselIsEnlarge: true }, this.props.onEnlargeCarousel);
  };

  onCloseEnlargeCarousel = () => {
    this.setState({ carouselIsEnlarge: false });
  };

  enLargeBtn = () => {
    const { props: { children }, state: { carouselIsEnlarge }, onEnlargeCarousel, onCloseEnlargeCarousel } = this;

    const pictureItems = children
      .map((item) => item && item.props && item.props.children && item.props.children.props ? item.props.children.props.src : [])
      .map((item) => ({
        type: 'image',
        url: item,
        name: '',
      }));
    return (
      this.props.withEnlargeBtn ? [
        <ButtonRound variant="border" onClick={onEnlargeCarousel}>
          <Icon name="largePreview" style={{ width: '24px', height: '24px' }} />
        </ButtonRound>,
        <Gallery
          key="2"
          elements={pictureItems}
          withSlideshow
          onlySlideshow
          slideshowIsOpen={carouselIsEnlarge}
          onCloseSlideshow={onCloseEnlargeCarousel}
        />] : null);
  };

  reRender = () => {
    const that = this;

    setTimeout(() => {
      that.setState({
        adaptiveHeight: false,
      });
    }, 1);
  };

  render() {
    const {
      state: { adaptiveHeight },
      props: { children },
      props } = this;

    return (
      <Wrapper className="carousel">
        {this.enLargeBtn()}
        <Slider ref={(el) => { this.slider = el; }} adaptiveHeight={adaptiveHeight} {...props}>
          {children}
        </Slider>
      </Wrapper>
    );
  }
}

export default Carousel;
