import { defineMessages } from 'react-intl';

export default defineMessages({
  about: {
    id: 'event.candidate.appointment.details.about',
  },
  location: {
    id: 'event.recruiter.preparation.timeslots.location.label',
  },
  adviceText: {
    id: 'event.candidate.appointment.details.adviceText',
  },
  contactRecruiterTitle: {
    id: 'event.candidate.appointment.contactRecruiter.title',
  },
  contactRecruiterContent: {
    id: 'event.candidate.appointment.contactRecruiter.content',
  },
  medium: {
    id: 'appointment.medium',
  },
  minutes: {
    id: 'date.minute',
  },
  duration: {
    id: 'appointment.duration',
  },
  phone: {
    id: 'appointment.medium.phone',
  },
  physical: {
    id: 'appointment.medium.physical',
  },
  visio: {
    id: 'appointment.medium.visio',
  },
});
