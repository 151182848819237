import { includes } from 'lodash';
import { getIds, getId } from '@/utils/global';


export default (plannersOrganizations, plannersUsers, searchText, highlightText, { actions }) => plannersOrganizations
  .map((organization) => {
    const plannerUsers = plannersUsers.filter((user) => includes(getIds(user._organizations), getId(organization)));

    return ({
      key: organization._id,
      collaboratorsLength: plannerUsers.length,
      exponent: highlightText(organization.name, searchText),
      users: plannerUsers,
      organization,
      tool: organization,
      children: plannerUsers.map((user, index) => ({
        key: `${user._id}-${user._user ? user._user._id : index}`,
        exponent: user._user ? user.fullName : '',
        slot: '10',
        job: '1',
        users: plannerUsers,
        tool: organization,
        organization,
        actions,
      })),
      actions,
    });
  });

