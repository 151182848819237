import React from 'react';
import { object, func, string, array, number } from 'prop-types';
import { concat, findIndex, intersection, isEmpty, isEqual, replace as _replace } from 'lodash';
import { replace } from 'connected-react-router';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Form } from 'antd';
import { Button } from '@seekube-tech/ui-kit';
import { withRouter } from 'react-router-dom';
import { participantActions } from '@/store/participant';
import { userActions } from '@/store/user';
import { offerActions } from '@/store/offer';
import { track } from '@/utils/analytics';
import {
  ANALYTICS_CANDIDATE,
  CONTRACTS_COMPATIBLE_WITH_NO_DURATION,
  CONTRACTS_OTHERS,
  CONTRACTS_WITHOUT_DURATION
} from '@/utils/constants';
import { TEMPLATE_CRITERIA_ID } from '@/utils/global';
import MatchingFilterFormItem from '@/components/MatchingFilterFormItem';
import ApiToForm from '@/services/Form/ApiToForm';
import { getHelloWorkPixel } from '@/utils/pixels/helloWork'
import { queryStringToObject } from '@/utils/url';
import hasDurationContract from '@/utils/forms/contractDuration';
import { patchMe } from '@/store/user/actions';
import messages from './messages';


const hasErrors = (fieldsError) => (
  Object.keys(fieldsError).some((field) => fieldsError[field])
);

class Matching extends React.PureComponent {
  static propTypes = {
    defaultCriteria: array,
    form: object,
    event: object,
    authUser: object,
    participant: object,
    totalOffers: number,
    onUpdate: func,
    setLastParticipantOfferSearch: func,
    context: string,
    patchParticipant: func,
    postParticipant: func,
    onCancel: func,
    replace: func,
    intl: object
  };

  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        filters: [],
      },
      disableDuration: false,
      contractsActiveOptions: [],
      sortCriteria: [],
    };
  }

  static getDerivedStateFromProps({ event, participant }, state) {
    let { sortCriteria } = state;

    if (!isEmpty(event)) {
      const criteria = event._criteria;

      sortCriteria = criteria
        .filter((criterion) => !['LEVEL', 'SECTORS', 'SIZE'].includes(criterion.key) && criterion.enable && criterion.modules.onboarding.isVisible)
        .sort((a, b) => a.modules.onboarding.position - b.modules.onboarding.position);

      const selectedValues = participant && participant.matching ? participant.matching : state.initialValues;
      const initialValues = ApiToForm.matchingFiltersParticipant(sortCriteria, selectedValues);

      if (isEmpty(state.sortCriteria)) {
        return { initialValues, sortCriteria };
      }
      return { initialValues };
    }
    return null;
  }

  componentDidMount() {
    const {
      props: { form, onUpdate, event },
      state,
      handleSubmit,
    } = this;

    if (!isEmpty(event) && event._criteria) {
      const criteria = event._criteria;
      const sortCriteria = criteria
        .filter((criterion) => !['LEVEL', 'SECTORS', 'SIZE'].includes(criterion.key) && criterion.enable && criterion.modules.onboarding.isVisible)
        .sort((a, b) => a.modules.onboarding.position - b.modules.onboarding.position);

      const initialValues = ApiToForm.matchingFiltersParticipant(sortCriteria, state.initialValues);

      this.checkContractActiveOptions(state.initialValues.filters.flat());
      this.initializeDurationFilter(event, initialValues); // We don't pass here on the first page load
      this.initializeContractFilter(initialValues);
    }

    form.validateFields();

    if (typeof onUpdate === 'function') {
      onUpdate(form, handleSubmit);
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.handleSubmit();
  }

  getCriterion = (key) => {
    const {
      state: { sortCriteria },
    } = this;

    return sortCriteria.find((criterion) => criterion.key === key);
  };

  initializeDurationFilter = (event, initialValues) => {
    const contractCriterion = event._criteria.filter((criterion) => criterion.key === 'CONTRACT');

    if (!isEmpty(contractCriterion)) {
      const cdiFilter = contractCriterion[0]._choices
        .filter((choice) => choice.enable && (TEMPLATE_CRITERIA_ID.cdi === choice._id || choice.label === 'CDI'))
        .map((choice) => choice._id);

      if (!isEmpty(cdiFilter)) {
        initialValues.filters.map((filter) => {
          if (isEqual(filter, cdiFilter)) {
            this.setState({ disableDuration: true });

            return true;
          }

          return false;
        });
      }
    }
  };

  isDurationDisabled = (rule, value, callback) => {
    const {
      props: { form: { setFields } },
      state: { disableDuration },
    } = this;

    const selectedValue = typeof value === 'object' ? value : [value];

    const hasDurationValues = hasDurationContract(selectedValue);
    const errors = !hasDurationValues ? undefined : [' '];

    if (disableDuration !== !hasDurationValues) {
      setFields({ 'matching-filters-DURATION': { value: [], errors } });
      this.setState({ disableDuration: !hasDurationValues });
    }

    callback();
  };

  checkContractActiveOptions = (selectedIdContracts) => {
    const {
      props: { event },
    } = this;

    const contractCriterion = event._criteria.filter((criterion) => criterion.key === 'CONTRACT')[0];

    if (!isEmpty(contractCriterion)) {
      const contractOptions = contractCriterion._choices
        .filter((choice) => choice.enable && selectedIdContracts.includes(choice._id))
        .map((choice) => choice.label);

      this.setState({ contractsActiveOptions: !isEmpty(contractOptions) ? contractOptions : [] });
    }
  };

  initializeContractFilter = (initialValues) => {
    const {
      props: { event },
    } = this;
    const initializeContracts = [];

    if (!isEmpty(initialValues) && !isEmpty(initialValues.filters)) {
      const contractIndex = findIndex(event._criteria, (criterion) => criterion.key === 'CONTRACT');
      const contractCriterion = event._criteria[contractIndex];
      if (!isEmpty(initialValues.filters[contractIndex])) {
        const contractsArray = initialValues.filters[contractIndex];

        if (contractCriterion) {
          contractCriterion._choices.forEach((choice) => {
            if (choice.enable && contractsArray.includes(choice._id)) {
              initializeContracts.push(choice.label);
            }
          });
        }
      }

      this.setState({
        contractsActiveOptions: initializeContracts,
      });
    }
  };

  handleOnChange = () => {
    const { props: { onUpdate, form }, handleSubmit } = this;

    setTimeout(() => {
      form.validateFields();

      if (typeof onUpdate === 'function') {
        onUpdate(form, handleSubmit);
      }
    }, 5);
  }

  handleSubmit = () => {
    const {
      props: { form: { validateFields }, event, totalOffers },
      handleOnSaveParticipant,
    } = this;

    track({
      name: ANALYTICS_CANDIDATE.EDIT_SEARCH,
      event,
      properties: {
        isUpdate: true,
        countOffers: totalOffers,
      },
    });

    return validateFields((err, values) => {
      const matchingFilters = Object
        .keys(values)
        .filter((value) => value.includes('matching-filters') === true)
        .map((filter) => values[filter]);

      const filters = [];

      Object.keys(matchingFilters).forEach((key) => {
        const filterValue = matchingFilters[parseInt(key, 10)];

        if (typeof filterValue === 'object') {
          filterValue.forEach((item) => {
            filters.push(item);
          });
        } else {
          filters.push(filterValue);
        }
      });

      const participant = {
        matching: this.state.initialValues,
      };

      const languages = [];

      Object
        .keys(values)
        .filter((value) => value.includes('matching-languages-lang') === true)
        .forEach((filter, index) => {
          if (values[`matching-languages-lang-${index}`]) {
            languages.push({
              lang: values[`matching-languages-lang-${index}`],
              level: values[`matching-languages-level-${index}`],
            });
          }
        });

      if (values.license) {
        participant.matching.license = values.license;
      }

      participant.matching.languages = languages;
      participant.matching.filters = filters;

      if (values['matching-date']) {
        participant.matching.date = values['matching-date'];
      } else {
        participant.matching.date = null;
      }

      if (values['matching-experiences']) {
        participant.matching.experiences = values['matching-experiences'];
      } else {
        participant.matching.experiences = null;
      }

      return handleOnSaveParticipant(err, participant);
    });
  };

  handleOnSaveParticipant = (err, values) => {
    const {
      props: { context, participant, event, patchParticipant, postParticipant, defaultCriteria, authUser, setLastParticipantOfferSearch, intl }
    } = this;

    const toPost = {
      matching: values.matching,
      _event: event._id,
    };

    /**
     * We have to set the step number.
     * Hybrid events require a step more (choose phase participation).
     */
    if (context === 'onboarding') {
      toPost.isActive = true;
      toPost.tourStep = 5;
    }

    if (participant && !isEmpty(participant.matching)) {
      const { redirect } = queryStringToObject(window.location.search);

      patchParticipant({
        participantId: participant._id,
        eventId: event._id,
        participantParams: toPost,
        slug: event.slug,
        redirect: redirect ? '' : 'event',
        notificationParams: {
          success: {
            message: intl.formatMessage({ id: 'toasters.updateSearch.success' }),
            kind: 'info',
            style: {
              bottom: '5%',
              top: 'inherit',
            },
          },
          error: true,
        },
        callback: () => {
          getHelloWorkPixel(event)?.track();
          getHelloWorkPixel(event)?.trackRegistration();

          setLastParticipantOfferSearch({});
        },
      });

      const matchingDefaultFilters = ApiToForm.matchingDefaultFiltersUser(
        defaultCriteria,
        values.matching
      );

      if (!isEmpty(matchingDefaultFilters) && (!isEmpty(matchingDefaultFilters.filters) || !isEmpty(matchingDefaultFilters.date))) {
        patchMe({
          userId: authUser._id,
          userParams: {
            matching: {
              ...authUser.matching,
              filters: matchingDefaultFilters.filters,
              date: matchingDefaultFilters.date,
            },
          },
          notificationParams: {
            success: {
              message: this.props.intl.formatMessage({ id: 'notifications.update.success' }),
              kind: 'info',
              style: {
                bottom: '5%',
                top: 'inherit',
              },
            },
          }
        });
      }
    } else {
      const userProvider = JSON.parse(window.localStorage.getItem('user_provider'));

      postParticipant({
        eventId: event._id,
        slug: event.slug,
        toPost: { ...toPost, userProvider },
        redirect: 'event',
        notificationParams: {
          success: {
            message: intl.formatMessage({ id: 'toasters.createSearch.success' }),
            kind: 'info',
            style: {
              bottom: '5%',
              top: 'inherit',
            },
          },
          error: true,
        },
      });
    }
  };

  returnFormItems = () => {
    const {
      isDurationDisabled, checkContractActiveOptions, handleOnChange,
      state: { initialValues, disableDuration, sortCriteria },
      props: {
        event, participant, form: { getFieldDecorator }, form, authUser,
      },
    } = this;

    if (!event) {
      return '';
    }

    const context = 'onboarding';

    return (
      sortCriteria
        .map((criterion, index) => {
          if (criterion.modules.onboarding.isVisible === false) {
            return null;
          }

          const fieldKey = criterion.key !== undefined ?
            `matching-filters-${criterion.key}` :
            _replace(`matching-filters-${criterion.name}-${index}`, '.', '');

          return (
            <MatchingFilterFormItem
              getFieldDecorator={getFieldDecorator}
              key={`formItem${criterion._id}`}
              criterion={criterion}
              fieldDecoratorKey={fieldKey} // Warning: Required fieldDecoration doesn't work with array in fieldDecoratorKey
              initialValue={initialValues.filters[index]}
              matchingData={participant && participant.matching ? participant.matching : null}
              context={context}
              onChange={handleOnChange}
              disableDuration={disableDuration}
              validatorCallback={criterion.key === 'CONTRACT' ? isDurationDisabled : null}
              form={form}
              checkContractActiveOptions={checkContractActiveOptions}
              withInputErrorStyle={false}
              authUser={authUser}
            />
          );
        })
    );
  };

  render() {
    const {
      onSubmit, returnFormItems,
      props: {
        event, form, context, onCancel,
      },
      state: { sortCriteria },
    } = this;

    if (event && sortCriteria) {
      return (
        <Form onSubmit={onSubmit}>
          {returnFormItems()}
          {/* Submit */}

          {context !== 'onboarding' && (
            <div className="confirm-actions">
              <Button
                variant="tonal"
                disabled={hasErrors(form.getFieldsError())}
                onClick={onCancel}
              >
                <FormattedMessage {...messages.cancel} />
              </Button>
              <Button
                disabled={hasErrors(form.getFieldsError())}
                type="submit"
              >
                <FormattedMessage {...messages.submit} />
              </Button>
            </div>
          )}
        </Form>
      );
    }

    return '';
  }
}


const mapStateToProps = createStructuredSelector({});
const mapDispatchToProps = {
  patchParticipant: participantActions.patchParticipant,
  postParticipant: participantActions.postParticipant,
  setLastParticipantOfferSearch: offerActions.setLastParticipantOfferSearch,
  patchMe: userActions.patchMe,
  replace
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withForm = Form.create();

export default compose(
  withConnect,
  withForm,
  withRouter,
  injectIntl,
)(Matching);
