import React from 'react';
import { Body2, Button, H5 } from "@seekube-tech/ui-kit";
import { Link } from "react-router-dom";
import { number, string, object } from 'prop-types';
import Card from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Exponents/scenes/Exponent/components/Card';

// Styles & Translations
import styles from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Exponents/scenes/Exponent/components/Home/Sidebar/Cards/styles.less'
import { useFormatMessage } from 'react-intl-hooks';

const CardOffers = ({
  countOffers,
  link,
  labels,
}) => {
  const t = useFormatMessage();

  return <Card>
    <div className={styles.cardOffer}>
      <div>
        <H5 className="text-neutral-500">
          {countOffers} {countOffers > 1 ? labels.plural : labels.singular}
        </H5>
        <br />
        <Body2 className="text-neutral-500">
          {t({ id: 'stand.sidebar.cardDescription.offers' }, { label: labels.plural })}
        </Body2>
      </div>
      <Link to={link}>
        <Button>
          {t({ id: 'go.to.offers.stands' }, { label: labels.plural })}
        </Button>
      </Link>
    </div>
  </Card>
}

CardOffers.propTypes = {
  countOffers: number,
  link: string,
  labels: object,
}

export default CardOffers;
