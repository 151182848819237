import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import request from '@/utils/request';
import { getAuthUser } from '@/store/auth/selectors';
import Separator from '@/components/Separator';
import OauthCover from '@/components/OauthCover';
import { IconSeekube } from '@seekube-tech/ui-kit';
import { getApiUrl, queryStringToObject } from "@/utils/url";
import { replace } from "connected-react-router";
import { useFormatMessage } from 'react-intl-hooks';
import { useLocation } from 'react-router-dom'

const RedirectEvent = () => {
  const t = useFormatMessage();
  const dispatch = useDispatch();
  const authUser = useSelector(getAuthUser);
  const location = useLocation();

  useEffect(async () => {
    if (isEmpty(authUser?.toObject())) {
      // Redirect non connected users
      window.location.href = '/events';
    }

    const q = queryStringToObject(location.search);

    const nextEvent = await request(getApiUrl('/auth/me/next-event'));

    if (nextEvent?.slug) {
      if (q?.context === 'stand') {
        dispatch(replace(`/${nextEvent?.slug}/${nextEvent?.role}/preparation/stand`))
      } else {
        dispatch(replace(`/${nextEvent?.slug}/${nextEvent?.role}`))
      }
    }
  }, []);

  return (
    <OauthCover>
      <div style={{ textAlign: 'center', paddingTop: '35vh', color: '#FFF' }}>
        <IconSeekube size={52} color="#FFF" />
        <Separator height={32} />
        <h4 style={{ color: '#FFF' }}>{t({ id: 'redirectEvent.loading' })}</h4>
      </div>
    </OauthCover>
  )
}

export default RedirectEvent;
