import React from 'react';
import classNames from 'classnames';
import { useFormatMessage } from 'react-intl-hooks';
import { Form } from 'antd';

import {
  AvatarBusinessList
} from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/ParticipationMode/AvatarBusinessList';
import {
  KeyMomentInformation
} from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/ParticipationMode/KeyMomentInformation';
import { EVENT_FORMAT } from '@/utils/constants';
import styles
  from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/ParticipationMode/styles.less';
const FormItem = Form.Item;

export const ParticipationVirtual = ({
  event,
  form,
  participant,
}) => {
  const t = useFormatMessage();

  return (
    <div className={styles.formatContainer}>
      <div className={styles.formatHeader}>
        <KeyMomentInformation format={EVENT_FORMAT.VIRTUAL} event={event} />
        <AvatarBusinessList event={event} eventFormat="virtual" />
      </div>

      <FormItem label="">
        {form.getFieldDecorator('virtualKeyMoment', {
          initialValue: participant.keyMomentFormats.includes('virtual') || true,
          rules: [{ required: true, message: ' ' }],
        })(
          <div className={styles.buttonsChoice}>
            <div
              className={classNames(styles.buttonChoice, form.getFieldValue('virtualKeyMoment') && styles.buttonChoiceActive)}
              onClick={() => form.setFieldsValue({ virtualKeyMoment: true })}
            >
              {t({ id: 'hybrid.format.participate' })}
            </div>
            <div
              className={classNames(styles.buttonChoice, !form.getFieldValue('virtualKeyMoment') && styles.buttonChoiceActive)}
              onClick={() => form.setFieldsValue({ virtualKeyMoment: false })}
            >
              {t({ id: 'hybrid.format.participate.not' })}
            </div>
          </div>
        )}
      </FormItem>
    </div>
  )
}