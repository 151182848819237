import { useQuery } from 'react-query';
import request from '@/utils/request';

export function useGetUserEvents({ authUser, getPastEvent, limit, userId = null, context, offset = 0, page = 1, eventId = null, ...props }) {
  const isPastEvent = typeof getPastEvent === 'boolean' ? '?past=true' : '?past=false';
  const requestUrl = `${process.env.FRONT_API_URL}/users/${userId || authUser._id}/events${isPastEvent}&offset=${offset}&limit=${limit}&page=${page}&context=${context}${eventId ? `&event=${eventId}` : ''}`;

  const queryFn = () => request(requestUrl, { method: 'GET', });

  const query = useQuery({
    queryKey: ['events', isPastEvent, context, page, offset],
    queryFn,
    retry: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 30000,
    ...props
  });
  return query;
}
