import React, { useState } from 'react';
import { string, array } from 'prop-types';
import { lowerCase, size } from 'lodash';
import classnames from 'classnames';

import Highlighter from 'react-highlight-words';
import { Input } from '@/components/Input';
import Table from '@/components/Table';
import Icon from '@/components/Icon';

import styles from './styles.less';

const getColumnSearchProps = (dataIndex) => {
  const [visible, setVisible] = useState(false);
  const [searchText, setSearchText] = useState(null);
  const [searchedColumn, setSearchedColumn] = useState('');

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  return ({
    // eslint-disable-next-line react/prop-types
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          label={`Search ${dataIndex}`}
          placeholder={`Search ${dataIndex}`}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [''])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          className={styles.filterInput}
        />
      </div>
    ),

    filterIcon: () => (
      <span role="button" tabIndex={0} onClick={() => setVisible(!visible)}>
        <Icon name="search" className={classnames(styles.filterIcon, size(searchText) ? styles.filtered : '')} />
      </span>
    ),

    filteredValue: searchText ? [searchText] : null,

    onFilter: (value, record) => (
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(lowerCase(searchText))
    ),

    render: (text) => (
      searchedColumn === dataIndex ?
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        /> : text
    ),
  });
};

const BasicRanking = ({ dataSource, messageTitle = '' }) => {
  const nameSearchProps = getColumnSearchProps('name');

  return (
    <div>
      <h2>{messageTitle}</h2>
      <Table
        dataSource={dataSource}
        columns={[{
          title: 'N.',
          dataIndex: 'index',
          width: '15%',
          key: 'index',
        }, {
          title: 'Entreprise',
          dataIndex: 'name',
          width: '50%',
          key: 'name',
          ...nameSearchProps,
        }, {
          title: 'Nombre de visite',
          dataIndex: 'count',
          align: 'center',
          width: '35%',
          key: 'count',
        }]}
        size="middle"
        pagination={false}
        scroll={{ y: 400 }}
      />
    </div>
  );
};

BasicRanking.propTypes = {
  dataSource: array,
  messageTitle: string,
};

export { BasicRanking, getColumnSearchProps };
