import React from 'react';
import { compose } from 'redux';
import { useSelector } from 'react-redux';

import { toJS } from '@/utils';
import { Informal1to1 } from '@/scenes/Event/scenes/Owner/scenes/Setting/scenes/Limits/scenes/Candidates/Informal1to1';
import { getCurrentEvent } from '@/store/event/selectors';
import { Applications } from './Applications';

const CandidatesScene = (props) => {
  const event = useSelector(getCurrentEvent)?.toJS();

  return (
    <div style={{ width: '100%', flex: 1, paddingTop: '20px' }}>
      {event.modules?.offer?.enable &&
        <Applications {...props} event={event}  />
      }
      <Informal1to1 {...props} event={event}  />
    </div>
  );
};

export default compose(
  toJS,
)(CandidatesScene);
