import { useMutation } from 'react-query';
import request from '@/utils/request';
import { objectToParams } from '@/utils/url';
import { fileBytesToMB } from '@/utils/file';

export function useAnalyzeCv({
  onSuccess = () => {},
  onError = () => {}
}) {
  const mutationFn = ({ saveFile = false, ...props }) => {
    const params = objectToParams({ saveFile });
    const requestUrl = `${process.env.FRONT_API_URL}/auth/me/analyze-cv?${params}`;
    const formData = new FormData();

    if (fileBytesToMB(props.file.size) > 2) {
      throw new Error('toolarge');
    }

    formData.append('cvFile', props.file, props.file.name);

    return (
      request(requestUrl, {
        method: 'POST',
        body: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }, [504, 413, 500])
    )
  }

  return useMutation({
    mutationFn,
    onSuccess: async () => {
      onSuccess();
    },
    onError: async (error) => {
      onError(error);
    }
  });
}
