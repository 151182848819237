import request from '@/utils/request';

const getLivesStats = ({ eventId }) => {
  const requestUrl = `${process.env.FRONT_API_URL}/statistics/event-lives?eventId=${eventId}`;

  return request(requestUrl, {
    method: 'GET',
  });
};

export { getLivesStats };
