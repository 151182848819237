import React, { useState } from 'react';
import { array, object } from 'prop-types';
import { Button, H5 } from '@seekube-tech/ui-kit';
import { trackRecruiterCreatedNewTemplate, trackRecruiterUpdatedTemplate } from '@/utils/analytics';


// component
import { useFormatMessage } from 'react-intl-hooks';
import { useGetTemplates } from '@/queries/templates/useGetTemplates';
import { TemplatesItem, NewTemplate } from './components';

import styles from '../../styles.less';
import templatesMailStyle from './styles.less';

const TemplatesMail = ({
  authUser,
}) => {
  const t = useFormatMessage();
  const [isAddTemplateModalVisible, setIsAddTemplateModalVisible] = useState(false);
  const [templateSelected, setTemplateSelected] = useState(null);
  const templatesQuery = useGetTemplates({ userId: authUser?._id });

  function onNewTemplateClick () {
    setIsAddTemplateModalVisible(true);
    setTemplateSelected({});
    trackRecruiterCreatedNewTemplate({ authUser });
  }

  const renderTemplates = () => templatesQuery.data?.map((template) => {
      const templateType = template.type ? 'defaultTemplate' : 'customTemplate';

      return (
        <TemplatesItem
          key={template._id}
          template={template}
          type={templateType}
          callback={() => { setIsAddTemplateModalVisible(true); setTemplateSelected(template); }}
        />
      );
    });

  return (
    <div className={styles.emailFormWrapper}>
      <div className={templatesMailStyle.header}>
        <H5>{t({ id: 'settings.templatesMail.title' })}</H5>
        <Button variant="tonal" size="small" onClick={onNewTemplateClick}>
          {t({ id: 'settings.templatesMail.cta.newTemplate' })}
        </Button>
      </div>

      {isAddTemplateModalVisible &&
        <NewTemplate
          template={templateSelected}
          defaultVisible={isAddTemplateModalVisible}
          onClose={() => setIsAddTemplateModalVisible(false)}
          authUser={authUser}
          submitCallback={() => { trackRecruiterUpdatedTemplate({ authUser }); }}
        />
      }
      {renderTemplates()}
    </div>
  );
}

TemplatesMail.propTypes = {
  authUser: object,
};

export default TemplatesMail;
