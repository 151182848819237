import { defineMessages } from 'react-intl';

export default defineMessages({
  recruitersPl: {
    id: 'recruiters',
  },
  offersPublishPl: {
    id: 'offer.publish.pl',
  },
  applicationPl: {
    id: 'application.pl',
  },
  receivedPlgr: {
    id: 'received.plgr',
  },
  acceptedPlgr: {
    id: 'accepted.plgr',
  },
  refusedPlgr: {
    id: 'refused.plgr',
  },
  untreatedPlgr: {
    id: 'untreated.plgr',
  },
  cvDownloaded: {
    id: 'event.owner.statistics.exponents.timeslots.activities.cvDownloaded.title',
  },
  livePublishPl: {
    id: 'live.publish.pl',
  },
  lastConnection: {
    id: 'last.connection',
  },
  rdv: {
    id: 'appointment.admin.status',
  },
});
