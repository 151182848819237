import React from 'react';
import { func, string } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { Dropdown, Menu } from 'antd';
import { Body2, IconEdit2, IconEye, IconMoreVertical, IconTrash2 } from '@seekube-tech/ui-kit';
import styles
  from '@/scenes/Event/scenes/Owner/scenes/OnBoarding/containers/Dashboard/components/Recruiters/styles.less';
import classNames from 'classnames';
const { Item: MenuItem } = Menu;

const MoreActions = ({ onDelete, onUpdate, activityId, onPreview }) => {
  const t = useFormatMessage();

  return (
    <Dropdown
      overlay={
        <Menu>
          <MenuItem key="update">
            <a className={styles.itemContainer} role="button" onClick={() => onUpdate(activityId)} tabIndex={0}>
              <IconEdit2 size={12} />
              <Body2 className={styles.moreText} fontWeight={400}>{t({ id: 'update.actuality' })}</Body2>
            </a>
          </MenuItem>
          <MenuItem key="preview">
            <a className={styles.itemContainer} role="button" onClick={onPreview} tabIndex={0}>
              <IconEye size={12} />
              <Body2 className={styles.moreText} fontWeight={400}>{t({ id: 'preview' })}</Body2>
            </a>
          </MenuItem>
          <MenuItem key="delete">
            <a className={classNames(styles.itemContainer, styles.itemContainerDelete) } role="button" tabIndex={0} onClick={() => onDelete(activityId)}>
              <IconTrash2 size={12} />
              <Body2 className={styles.moreText} fontWeight={400}>{t({ id: 'delete.activity' })}</Body2>
            </a>
          </MenuItem>
        </Menu>
      }
      placement="bottomRight"
    >
      <a role="button" tabIndex={0} className={styles.moreActions}>
        <IconMoreVertical className="fill-neutral-300" />
      </a>
    </Dropdown>
  )
}

MoreActions.propTypes = {
  onDelete: func,
  onUpdate: func,
  activityId: string,
  onPreview: func
}

export default MoreActions
