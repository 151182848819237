import React, { useEffect } from 'react';
import { object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { head, isEmpty } from 'lodash';
import { Col, Row } from 'antd';
import { Button } from '@seekube-tech/ui-kit';
import { Alert } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { useHistory } from 'react-router';
import { toJS } from '@/utils';

import { ANALYTICS_CANDIDATE } from '@/utils/constants';
import { track } from '@/utils/analytics';

// Store
import { eventSelectors } from '@/store/event';

// Components
import LoadingIndicator from '@/components/LoadingIndicator';
import Wrapper from '@/components/Wrapper';

import OrganizationLayout from '@/components/OrganizationLayout';

import { If } from '@/components/If';
import Icon from '@/components/Icon';
import { H3 } from '@/components/Title';
import P from '@/components/P';
import Html from '@/components/Html';
import Carousel from '@/components/Carousel';
import { getCdnUrl } from '@/components/ImgCdn';
import { TopicTag } from '@/components/CardLive/components/TopicTag';

import { useGetConference } from '@/queries/conferences/useGetConference';
import {
  AffixConferenceInfo
} from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Conferences/scenes/Conference/components/AffixConferenceInfo';
import { Presenters } from './Presenters';
import styles from './styles.less';


const Conference = ({
  authUser,
  event,
  match,
}) => {
  const t = useFormatMessage();
  const history = useHistory();
  const conferenceQuery = useGetConference({ conferenceId: match.params.conferenceID });

  const conference = conferenceQuery.data;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const handleShowExponent = () => {
    track({
      name: ANALYTICS_CANDIDATE.VIEW_COMPANY_CLICK,
      event,
      user: authUser,
      properties: {
        origin: 'Livetalk',
        company: conference._organization?.name,
        'Current url TRUE': window.location.href,
      },
    });

    history.push(`/${event.slug}/candidate/jobdating/exponents/${conference._organization._id}`);
  };

  const renderPictures = (pictures) => {
    if (pictures.length > 0) {
      return (
        <div className="carousel">
          <Carousel
            arrows={false}
            pictures={pictures}
            withEnlargeBtn
            dots
            customPaging={() => <a />}
          >
            {pictures.map((src, index) => (
              <div key={`pictureImg${index}`}>
                <img src={getCdnUrl(src, 999)} alt={conference._organization.name} />
              </div>))}
          </Carousel>
        </div>
      );
    }

    return null;
  };

  if (conferenceQuery?.isLoading) {
    return (<LoadingIndicator />);
  }

  if (conference.deleted) {
    history.push(`/${event}/candidate/jobdating/conferences`);
  }

  const pictures = conference._organization?.profile?.pictures?.filter((item) => !isEmpty(item)) || [];

  return (
    <Wrapper size="full" style={{ marginTop: '-20px' }}>
      <OrganizationLayout
        organization={conference._organization}
        cover={conference.cover}
        pictureUrl={conference.pictureUrl}
        event={event}
      >
        <Row gutter={0}>
          <Col xs={12} sm={12} md={7} lg={7}>
            <div className="offerInfos">
              <TopicTag topic={head(conference.topics)} classname={styles.topics} />

              <h1 className="mainTitle">{conference.title}</h1>

              <div className="appointmentData">
                <ul>
                  <li><Icon name="visio" /> {conference.format}</li>
                  <li><Icon name="user" /> {t({ id: 'conference.maxAttendees' }, { count: conference.maxAttendees })} </li>
                </ul>
              </div>

              <Alert>{t({ id: 'conference.enableDevices' })}</Alert>

              <div className="organizationLayoutContent">
                <H3 bold>{t({ id: 'conference.description' })}</H3>
                <P><Html value={conference.description} /></P>

                <If condition={!isEmpty(conference.presenters)}>
                  <H3 bold>{t({ id: 'the.presenters' })}</H3>
                  <Presenters presenters={conference.presenters} />
                </If>

                <H3 bold>{t({ id: 'about' })}</H3>
                {renderPictures(pictures)}
                <Button type="primary" onClick={handleShowExponent}>{t({ id: 'know.more' })}</Button>
              </div>
            </div>
          </Col>
          <Col xs={12} md={5} lg={5}>
            <AffixConferenceInfo conference={conference} match={match} event={event} />
          </Col>
        </Row>
      </OrganizationLayout>
    </Wrapper>
  );
}

Conference.propTypes = {
  authUser: object,
  event: object,
  match: object,
};


const mapStateToProps = createStructuredSelector({
  event: eventSelectors.getCurrentEvent,
});

const withConnect = connect(mapStateToProps, null);

export default compose(
  withRouter,
  withConnect,
)(toJS(Conference));
