import React from 'react';
import { any, func } from 'prop-types';
import { Button } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import ModalV2 from '@/components/ModalV2';

const ModalConfirm = React.memo((props) => {
  const t = useFormatMessage();

  return (
    <ModalV2
      template="default"
      {...props}
      onClose={props.onCancel}
    >
      <div>
        {props.children}

        <div className="confirm-actions mt-24">

          <Button className="mr-6" color="neutral" variant="outline" onClick={props.onCancel}>
            {t({ id: 'cancel' })}
          </Button>
          <Button onClick={props.onConfirm} >
            {t({ id: 'btn.confirm' })}
          </Button>
        </div>
      </div>
    </ModalV2>
  );
});

ModalConfirm.propTypes = {
  onConfirm: func,
  onCancel: func,
  children: any,
};

export { ModalConfirm };
