import React from 'react';
import { injectIntl } from 'react-intl';
import { object, func, array } from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { isEmpty, find, omit } from 'lodash';
import { exponentSelectors } from '@/store/exponent';
import { push } from 'connected-react-router/immutable';
import { organizationSelectors } from '@/store/organization';
import { offerActions, offerSelectors } from '@/store/offer';
import { toJS } from '@/utils';
import { queryStringToObject } from '@/utils/url';
import { getId } from '@/utils/global';
import { withAuth } from '@/containers/RequiredAuth';
import { areWysiwygFieldCompleted } from '@/components/WysiwygEditor';

import SplitViewsComponent from '@/components/SplitView';
import { EditOfferStep1, EditOfferStep2, EditOfferStep3, CancelModal } from './components';

import OfferPreview from '../../../Event/scenes/Candidate/scenes/JobDating/scenes/Jobs/scenes/Job/components/OfferPreview';
import Steps from './components/steps';
import styles from './styles.less';

let offer = {};
let unSavedOffer = {};

class ClientEditOfferScene extends React.PureComponent { // eslint-disable-line
  static propTypes = {
    match: object,
    authUser: object,
    location: object,
    intl: object,
    offers: array,
    deleteGlobalOffer: func,
    getGlobalOffer: func,
    getUsersOrganization: func,
    patchGlobalOffer: func,
    patchGlobalOfferByEvents: func,
    postGlobalOffer: func,
    push: func
  };


  constructor(props) {
    super(props);
    const { offerId } = queryStringToObject(props.location.search);

    if (offerId) {
      props.getGlobalOffer({ offerId });
    }
  }

  state = {
    currentStep: queryStringToObject(this.props.location.search).step,
    offer,
    unSavedOffer,
    confirmModalIsVisible: false,
  };

  componentDidUpdate(prevProps) {
    const { step } = queryStringToObject(this.props.location.search);
    const prevStep = queryStringToObject(prevProps.location.search).step;
    if (step !== prevStep) {
      const { step } = queryStringToObject(this.props.location.search);
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ currentStep: step });
    }
  }

  componentWillUnmount() {
    offer = {};
    unSavedOffer = {};
  }

  onClosePageWithoutSaving = () => {
    const { props: { offers, deleteGlobalOffer, authUser: { _currentOrganization } } } = this;
    const { offerId } = queryStringToObject(this.props.location.search);
    const pendingOffer = find(offers, (offer) => offer._id.toString() === offerId);
    const isPendingOffer = pendingOffer && pendingOffer.status === 'pending';
    if (isPendingOffer) {
      deleteGlobalOffer({
        offerId,
        organizationId: _currentOrganization._id,
        notificationParams: null,
        query: { soft: 'false' },
        callback: () => {
          this.props.push('/client/offers', { refresh: true })
        }
      });
    } else {
      this.props.push('/client/offers', { refresh: true });
    }
  };

  onClosePreview = () => {
    this.props.push('/client/offers');
  };

  setCurrentStep = (current) => {
    const { offerId } = queryStringToObject(this.props.location.search);
    this.props.push(`${this.props.match.path}?step=${current}&offerId=${offerId}`);
    this.setState({ current });
  };

  setOffer = (keysValues, hasChanged) => {
    offer = { ...offer, ...keysValues };
    if (hasChanged) {
      unSavedOffer = { ...unSavedOffer, ...keysValues };
    }
  };

  getDBOffer = () => {
    const { props: { offers, location } } = this;
    const { offerId } = queryStringToObject(location.search);

    return offerId ? offers.find((offer) => offer._id.toString() === offerId) : null;
  };

  isFirstStepDone = () => {
    const fields = [offer.title, offer._user];
    return fields
      .reduce((previousValue, currentValue) => previousValue && !isEmpty(currentValue), true)
      && areWysiwygFieldCompleted([offer.description, offer.profileDescription]);
  };

  closeModalWithConfirm = () => {
    this.setState({ confirmModalIsVisible: true });
  };

  closeConfirmModal = () => {
    this.setState({ confirmModalIsVisible: false });
  };

  handleNext = () => {
    this.setCurrentStep(parseInt(this.state.currentStep, 10) + 1);
  };

  handlePrevious = () => {
    this.setCurrentStep(parseInt(this.state.currentStep, 10) - 1);
  };

  showPreview = () => {
    window.open(`${this.props.match.path}${this.props.location.search}&preview=true`, '_blank');
  };

  saveOffer = (keysValues, saveType) => {
    const { props: { patchGlobalOffer, patchGlobalOfferByEvents, postGlobalOffer, match }, state: { currentStep }, handleNext } = this;
    const { offerId } = queryStringToObject(this.props.location.search);

    if (offerId) {
      if (saveType === 'draft') {
        patchGlobalOffer({
          offerId,
          params: { ...keysValues, status: 'draft' },
          callback: this.props.push({
            pathname: '/client/offers',
            state: { refresh: true }
          }),
          success: {
            message: this.props.intl.formatMessage({ id: 'notifications.update.success' }),
            kind: 'info',
            style: {
              bottom: '5%',
              top: 'inherit',
            },
          }
        });
      } else if (currentStep === '2') {
        patchGlobalOfferByEvents({
          offerId,
          params: keysValues.map((off) => omit(off, ['childrenOffers'])),
          callback: this.props.push('/client/offers'),
          notificationParams: {
            success: {
              message: this.props.intl.formatMessage({ id: 'notifications.update.success' }),
              kind: 'info',
              style: {
                bottom: '5%',
                top: 'inherit',
              },
            }
          }
        });
      } else if (currentStep === '1') {
        if (keysValues._events.length) {
          handleNext();
        } else {
          patchGlobalOffer({
            offerId,
            params: { status: 'draft' },
            callback: this.props.push({
              pathname: '/client/offers',
              state: { refresh: true }
            }),
            success: {
              message: this.props.intl.formatMessage({ id: 'notifications.update.success' }),
              kind: 'info',
              style: {
                bottom: '5%',
                top: 'inherit',
              },
            }
          });
        }
      } else {
        handleNext();
      }
    } else if (saveType === 'draft') {
      postGlobalOffer({
        params: { ...keysValues, status: 'draft' },
        callback: () => { unSavedOffer = {}; this.props.push('/client/offers', { refresh: true }); },
      });
    } else {
      postGlobalOffer({
        params: { ...keysValues, status: 'pending' },
        callback: ({ _id }) => { unSavedOffer = {}; this.props.push(`${match.path}?step=${parseInt(currentStep, 10) + 1}&offerId=${_id}`); },
      });
    }
  };

  arePreviousStepsDone = (step) => {
    switch (step) {
      case '0': return true;
      case '1': return this.isFirstStepDone();
      case '2': return offer._events.length > 0;
      default: return false;
    }
  };

  renderStep = () => {
    const { state: { currentStep }, handleNext, saveOffer, setOffer, getDBOffer, setCurrentStep, handlePrevious } = this;
    const offerDB = getDBOffer();

    if (currentStep === '2' && isEmpty(offer._event)) {
      setCurrentStep(1);
    }

    switch (currentStep) {
      case '0': return <EditOfferStep1 offerDB={offerDB} handleNext={handleNext} saveOffer={saveOffer} offer={offer} setOffer={setOffer} />;
      case '1': return <EditOfferStep2 goBack={handlePrevious} blank="fdfdd" offerDB={offerDB} handleNext={handleNext} unSavedOffer={unSavedOffer} saveOffer={saveOffer} offer={offer} setOffer={this.setOffer} defaultTargets={['tech', 'business', 'engineer']} />;
      case '2': return <EditOfferStep3 offerDB={offerDB} unSavedOffer={unSavedOffer} saveOffer={saveOffer} setOffer={setOffer} offer={offer} />;
      default: return <EditOfferStep1 offerDB={offerDB} handleNext={handleNext} saveOffer={saveOffer} offer={offer} setOffer={setOffer} />;
    }
  };

  render() {
    const { offerId, preview } = queryStringToObject(this.props.location.search);
    const { state: { confirmModalIsVisible }, props: { intl, offers, authUser }, closeConfirmModal, onClosePageWithoutSaving, renderStep } = this;

    if (offerId && isEmpty(offers)) return null;
    if (offerId && isEmpty(offers.find((offer) => getId(offer) === offerId))) return null;

    const fetchedOffer = !isEmpty(offerId) ? offers.find((offer) => offer._id.toString() === offerId) || {} : {};
    offer = { ...fetchedOffer, ...offer };
    offer._organization = fetchedOffer._organization || authUser._currentOrganization;

    if (preview === 'true' && !isEmpty(offer)) {
      return (
        <OfferPreview
          currOff={offer}
          event={offer._event || {}}
          organization={authUser._currentOrganization}
          context="OfferEdit"
          isPreview
          handleClosePreview={this.onClosePreview}
        />
      );
    }

    return (
      <>
        <div id="editOfferContainer" className={styles.editOfferContainer}>
          <SplitViewsComponent
            classModalContainer={styles.editOfferSplitContainer}
            handleCloseAppointment={this.closeModalWithConfirm}
            leftContent={renderStep({ ...this.props })}
            rightContent={<Steps {...this.props} {...this.state} />}
          />
        </div>
        <CancelModal intl={intl} offer={offer} onOk={onClosePageWithoutSaving} onCancel={closeConfirmModal} visible={confirmModalIsVisible} />
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  organization: organizationSelectors.getCurrentOrganization,
  offers: offerSelectors.getOffers,
  isFetching: offerSelectors.getOffersFetching,
  exponents: exponentSelectors.getExponents,
  isExponentsFetching: exponentSelectors.getExponentsFetching,
});

const withConnect = connect(mapStateToProps, {
  postOffer: offerActions.postOffer,
  getGlobalOffer: offerActions.getGlobalOffer,
  deleteGlobalOffer: offerActions.deleteGlobalOffer,
  postGlobalOffer: offerActions.postGlobalOffer,
  patchGlobalOffer: offerActions.patchGlobalOffer,
  patchGlobalOfferByEvents: offerActions.patchGlobalOfferByEvents,
  push
});

export default compose(
  withRouter,
  withAuth(),
  withConnect,
  injectIntl,
)(toJS(ClientEditOfferScene));
