import React from 'react';
import { object, bool } from 'prop-types';
import { map, get } from 'lodash';
import { Tab } from '@seekube-tech/ui-kit';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { useFormatMessage } from 'react-intl-hooks';
import { StatBloc } from '@/components/Stats/StatBloc';
import styles from '@/components/Stats/Funnels/styles.less';
import messages from './messages';
import thisStyles from './styles.less';


const StatsAppointmentsStatus = ({ data, loading, event, statBlocProperties }) => {
  const t = useFormatMessage();

  const getTabStats = (tabStatName) => map(['accepted', 'refused', 'pending', 'total'], (status) => (
    {
      item: (
        <div className={styles.row}>
          <FormattedMessage {...messages[status]} values={{ gender: 'female', count: 2 }} /><span>{get(data, `${tabStatName}.${status}`)}</span>
        </div>
      ),
    }
  ));
  const getLiveStats = (tabStatName) => map(['attendees', 'participants', 'visits'], (key) => (
    {
      item: (
        (key !== 'visits' || (key === 'visits' && moment('2021-01-01').isBefore(moment(get(event, 'keyDates.jobfair.beginAt'))))) &&
        <div className={styles.row}>
          <FormattedMessage {...messages[key]} values={{ gender: 'male', count: 2 }} /><span>{get(data, `${tabStatName}.${key}`)}</span>
        </div>
      ),
    }
  ));

  const tabs = (
    <Tab.Group defaultIndex={0}>
      <Tab.List className="text-base">
        <Tab as="span" className="px-16">{t({ id: 'application.pl' }, { count: 2 })}</Tab>
        <Tab as="span" className="px-16">{t({ id: 'client.stats.sourcing.title' })}</Tab>
        <Tab as="span" className="px-16">{t({ id: 'live.pl' }, { count: 2 })}</Tab>
      </Tab.List>
      <Tab.Panels>
        <Tab.Panel>
          {getTabStats('applications').map(({ item }) => <div className={styles.item}>{item}</div>)}
        </Tab.Panel>
        <Tab.Panel>
          {getTabStats('sourcing').map(({ item }) => <div className={styles.item}>{item}</div>)}
        </Tab.Panel>
        <Tab.Panel>
          {getLiveStats('conferences').map(({ item }) => <div className={styles.item}>{item}</div>)}
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
  );

  return (
    <StatBloc
      title={t({ id: 'client.stats.distribution.title' })}
      classContainer={thisStyles.StatsAppointmentsStatus}
      description={tabs}
      noCount
      loading={loading}
      {...statBlocProperties}
    />
  );
};

StatsAppointmentsStatus.propTypes = {
  data: object,
  loading: bool,
  event: object,
  statBlocProperties: object,
};
export { StatsAppointmentsStatus };
