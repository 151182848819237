import React from 'react';
import { object, string } from 'prop-types';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';
import logo from '@/assets/images/mainLogo.svg';
import logoColor from '@/assets/images/logo-color.svg';
import Spotlight from '@/scenes/Admin/components/Spotlight';
import { Auth, EventInfos, SeekubeLogo } from '../components';
import styles from '../../styles.less';

const CandidateHeader = (props) => {
  const location = useLocation();
  const comesFromOnboarding = location.pathname.indexOf('candidate/preparation/onboarding') === -1 && props.context !== 'landing';

  return (
    <div className={styles.headerStructure}>
      <div className={styles.leftSide}>
        <SeekubeLogo
          logo={logo}
          logoColor={logoColor}
          context="default"
        />
        {comesFromOnboarding && (
          <div className={classnames(styles.colEvent, styles.candidateHeader)}>
            <EventInfos {...props} />
          </div>
        )}
      </div>
      <div className={styles.rightSide}>
        <div className={styles.colRight}>
          <div className={styles.colAuth}>
            <Auth {...props} />
          </div>
          <Spotlight className={styles.colAuth} />
        </div>
      </div>
    </div>
  );
};


CandidateHeader.propTypes = {
  event: object,
  intl: object,
  context: string,
  targetDate: string,
};

export { CandidateHeader };
