import { defineMessages } from 'react-intl';

export default defineMessages({
  offers: {
    id: 'candidate.jobdating.offers',
  },
  exponents: {
    id: 'candidate.jobdating.exponents',
  },
  appointments: {
    id: 'candidate.jobdating.appointments',
  },
  lives: {
    id: 'live.pl',
  },
});
