import React from 'react';
import { Form } from 'antd';
import { func, object, any } from 'prop-types';
import { isEmpty } from 'lodash';

import { useFormatMessage } from 'react-intl-hooks';

import { FormattedHTMLMessage } from 'react-intl';
import WysiwygEditor, { isWysiwygFieldCompleted } from '@/components/WysiwygEditor';

import styles from './styles.less';

const FormItem = Form.Item;

const SpecificityOffer = (({ form, initialValue, onChange }) => {
  const t = useFormatMessage();

  const handleChangeWysiwyg = (value) => {
    if (typeof value !== 'object') onChange(isWysiwygFieldCompleted(value) ? value : null);
  };

  return (
    <div className={styles.flexInline}>
      <FormItem label={t({ id: 'event.owner.setting.goals.description' })} className={styles.wysig}>
        {form.getFieldDecorator('descriptionCandidatesTarget', {
          initialValue: isEmpty(initialValue) ? '' : initialValue,
        })(
          <WysiwygEditor
            showHelper={() => {}}
            onChange={handleChangeWysiwyg}
            toolbar={{
              options: ['list'],
              list: {
                className: 'groupOptions',
                inDropdown: false,
                options: ['unordered', 'ordered'],
              },
            }}
          />
        )}
      </FormItem>
      <div>
        <p className={styles.exampleTitle}>{t({ id: 'event.owner.setting.goals.example.title' })}</p>
        <p className={styles.exampleMessage}>
          <FormattedHTMLMessage id="event.owner.setting.goals.example.message" />
        </p>
      </div>
    </div>
  );
});

SpecificityOffer.propTypes = {
  form: object,
  initialValue: any,
  onChange: func,
};
export { SpecificityOffer };
