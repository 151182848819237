import React from 'react';
import { array, bool, object, number, oneOf, string } from 'prop-types';
import { isEmpty, isUndefined, isEqual, uniqBy, get } from 'lodash';
import { ANALYTICS_CANDIDATE } from '@/utils/constants';
import { track } from '@/utils/analytics';
import { injectIntl } from 'react-intl';

// Components
import Icon from '@/components/Icon';
import { Row, Col } from 'antd';
import Avatar from '@/components/Avatar';

// Styles
import recruiter1 from '@/assets/images/recruiter1.png';
import recruiter2 from '@/assets/images/recruiter2.png';
import recruiter3 from '@/assets/images/recruiter3.png';
import recruiter4 from '@/assets/images/recruiter4.png';
import recruiter5 from '@/assets/images/recruiter5.png';
import recruiter6 from '@/assets/images/recruiter6.png';
import recruiter7 from '@/assets/images/recruiter7.png';
import recruiter8 from '@/assets/images/recruiter8.png';
import recruiter9 from '@/assets/images/recruiter9.png';
import recruiter10 from '@/assets/images/recruiter10.png';
import styles from './styles.less';
import messages from './messages';


/**
 * SideContent
 */
class SideContent extends React.PureComponent {
  static propTypes = {
    offers: array,
    exponents: array,
    showSearch: bool,
    event: object,
    authUser: object,
    intl: object,
    totalOffers: number,
    title: string,
    description: string,
    context: oneOf(['default', 'plansInfo']),
  };

  static defaultProps = {
    showSearch: false,
    title: '',
    description: '',
    participant: {},
    context: 'default',
  };

  state = {
    countRecruiters: 0,
    offersByRecruiter: [],
    currentOffers: [],
  };

  componentWillReceiveProps({ exponents, offers }) {
    const prevProps = this.props;

    if (!isEmpty(exponents)) {
      const countRecruiters = exponents
        .map((exponent) => exponent.users ? exponent.users.length : 0)
        .reduce((acc, value) => acc + value);

      this.setState({ countRecruiters });
    }

    if (!isEmpty(offers) && !isEqual(offers, prevProps.offers)) {
      const offersByRecruiter = [];

      offers.forEach((offer) => {
        if (!isEmpty(offer._user)) {
          if (isUndefined(offersByRecruiter[offer._user._id])) {
            offersByRecruiter[offer._user._id] = 0;
          }

          offersByRecruiter[offer._user._id] += 1;
        }
      });

      this.setState({ offersByRecruiter });
    }
  }

  onOtherJobdatingsClick = () => {
    const { props: { event, authUser } } = this;
    track({
      event,
      user: authUser,
      name: ANALYTICS_CANDIDATE.JOBDATING_CLICK,
    });
  };

  _renderDefault = () => {
    const {
      props: { intl, event, context, title, description },
      state: { countRecruiters }
    } = this;
    const imgStyle = { border: '2px solid #FFFFFF', background: '#FFFFFF', borderRadius: '50%' };

    let recruiterLabel = intl.formatMessage(messages.recruiters, { count: countRecruiters });

    if (!isEmpty(event?.modules?.organization?.recruiterLabel_fr)) {
      recruiterLabel = countRecruiters > 0 ? event.modules.organization[`recruiterLabelMultiple_${intl.locale}`] : event.modules.organization[`recruiterLabel_${intl.locale}`]
    }

    return (
      <div className={styles.defaultContainer}>
        <div className={styles.topPanel}>
          <Row className={styles.part1}>
            <Col span={5} offset={4}>
              <Icon name="hexagone" style={{ color: '#F7C003', width: 15, height: 17, opacity: 0.3 }} />
              <img alt="recruiter-icon-2" src={recruiter2} style={{ width: 38, height: 38, ...imgStyle }} className={styles.animate} />
            </Col>
          </Row>
          <Row className={styles.part2}>
            <Col span={4}>
              <img alt="recruiter-icon-1" src={recruiter1} style={{ width: 76, height: 76, ...imgStyle }} className={styles.animate2} />
              <Icon name="star-mini" style={{ width: 12.5, height: 12.5, color: '#F95377' }} />
            </Col>
            <Col span={3} offset={5}>
              <Icon name="square" style={{ width: 17.5, height: 17.5, transform: 'rotate(70deg)', opacity: 0.6 }} />
            </Col>
          </Row>
          <Row className={styles.part3}>
            <Col span={4} offset={8}>
              <Icon name="donuts" style={{ width: 7, height: 7, opacity: 0.5, color: '#385077', ...imgStyle }} />
              <img alt="recruiter-icon-3" src={recruiter3} style={{ width: 48, height: 48, ...imgStyle }} className={styles.animate} />
            </Col>
          </Row>
          <Row className={styles.part4}>
            <Col span={4}>
              <Icon name="circle-mini" style={{ width: 7, height: 7, color: '#385077' }} />
              <img alt="recruiter-icon-4" src={recruiter4} style={{ width: 48, height: 48, ...imgStyle }} className={styles.animate2} />
            </Col>
            <Col span={4}>
              <Icon name="cross" style={{ width: 22, height: 22 }} />
            </Col>
            <Col span={4}>
              <img alt="recruiter-icon-5" src={recruiter5} style={{ width: 68, height: 68, ...imgStyle }} className={styles.animate} />
            </Col>
          </Row>
        </div>
        <div className={styles.countRecruiter}>
          {context !== 'plansInfo' && <strong>{`${countRecruiters} ${recruiterLabel}`}</strong>}
          {context === 'plansInfo' && <strong>{title}</strong>}
          {
            context !== 'plansInfo' &&
            <p>
              {intl.formatMessage(messages.baseline)}<br />
              {(!isEmpty(this.props.event) ? this.props.event.name : '')}
            </p>
          }

          {context === 'plansInfo' && <p>{description}</p>}

        </div>
        <div className={styles.bottomPanel}>
          <Row className={styles.part1}>
            <Col span={4}>
              <img alt="recruiter-icon-6" src={recruiter6} style={{ width: 47, height: 47, ...imgStyle }} className={styles.animate2} />
            </Col>
            <Col span={4}>
              <img alt="recruiter-icon-7" src={recruiter7} style={{ width: 60, height: 60, ...imgStyle }} className={styles.animate} />
            </Col>
            <Col span={3}>
              <Icon name="hexagone" style={{ color: '#385077', width: 22, height: 25, opacity: 0.5 }} />
            </Col>
          </Row>
          <Row className={styles.part2}>
            <Col span={2}>
              <Icon name="star-mini" style={{ width: 19, height: 19, color: '#385077' }} />
            </Col>
            <Col span={7} offset={3}>
              <Icon name="circle-mini" style={{ width: 9, height: 9, color: '#DADEE8', opacity: 0.4 }} />
            </Col>
          </Row>
          <Row className={styles.part3}>
            <Col span={5}>
              <img alt="recruiter-icon-8" src={recruiter8} style={{ width: 55, height: 55, ...imgStyle }} className={styles.animate2} />
            </Col>
            <Col span={2}>
              <Icon name="star-mini" style={{ width: 11, height: 11, color: '#33A8EB', opacity: 0.5 }} />
            </Col>
            <Col span={5}>
              <img alt="recruiter-icon-9" src={recruiter9} style={{ width: 55, height: 55, ...imgStyle }} className={styles.animate} />
            </Col>
          </Row>
          <Row className={styles.part4}>
            <Col span={5} offset={7}>
              <img alt="recruiter-icon-10" src={recruiter10} style={{ width: 38, height: 38, ...imgStyle }} className={styles.animate2} />
              <Icon name="square" style={{ width: 13.5, height: 13.5 }} />
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  _renderSearch = () => {
    const { props: { offers, event, intl, totalOffers } } = this;

    return (
      <div className={styles.defaultContainer}>
        <div className={styles.top}>
          <Row className={styles.icons}>
            <Col span={3}>
              <Icon name="star-mini" style={{ color: '#F95377', width: 12.5, height: 12.5 }} />
            </Col>
            <Col span={3}>
              <Icon name="hexagone" style={{ color: '#F7C003', width: 15, height: 17, opacity: 0.3 }} />
            </Col>
            <Col span={3}>
              <Icon name="star-mini" style={{ color: '#385077', width: 19, height: 19 }} />
            </Col>
            <Col span={3}>
              <Icon name="square" style={{ width: 17.5, height: 17.5, transform: 'rotate(70deg)', opacity: 0.6 }} />
            </Col>
          </Row>
        </div>
        <div className={styles.offersContainer}>
          <div className={styles.countRecruiter}>
            <strong>{`${totalOffers} ${intl.formatMessage(messages.opportunities, { count: totalOffers })}`}</strong>
            <p>
              {intl.formatMessage(messages.baseline)}<br />
              {!isEmpty(event) ? event.name : ''}
              <Icon name="circle-mini" style={{ width: 5, height: 5, color: '#33A8EB', opacity: 0.5 }} />
            </p>
          </div>
          <div className={styles.offers}>
            {uniqBy(offers.filter((o) => o && !isEmpty(o._user)), (offer) => get(offer, '_user._id')).map((offer, index) => (
              <div className={styles.offerCard} key={index}>
                <Avatar
                  src={offer._user && offer._user.pictureUrl && offer._user.pictureUrl !== 'undefined' ? offer._user.pictureUrl : ''}
                  size={60}
                  user={offer._user}
                  className={styles.avatar}
                />
                <div className={styles.recruiterInfos}>
                  <div className={styles.recruiterName}>{offer._user.shortName}</div>
                  <div className={styles.recruiterTitle}>{offer._user.title}</div>
                </div>
                <div className={styles.pictureContainer}>
                  {/* <div className={styles.countOffers}>{`${offersByRecruiter[offer._user._id]} opportunités`}</div> */}
                  <div className={styles.picture}>
                    {offer && offer._organization && offer._organization.profile ? (<img src={isUndefined(offer._organization.profile.logo) ? offer._organization.profile.pictureUrl : offer._organization.profile.logo} alt={offer._organization.name} />) : ''}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.bottom}>
          <Col span={4} offset={6}>
            <Icon name="star-mini" style={{ color: '#33A8EB', width: 11, height: 11, opacity: 0.5 }} />
          </Col>
          <Col span={2}>
            <Icon name="hexagone" style={{ color: '#DADEE8', width: 13, height: 13 }} />
          </Col>
        </div>
      </div>
    );
  };


  render() {
    const {
      props: { showSearch, event, context },
      _renderDefault,
      _renderSearch,
    } = this;

    if (!event && context !== 'plansInfo') {
      return null;
    }

    if (showSearch && event.withOfferModule) {
      return _renderSearch();
    }

    return _renderDefault();
  }
}

export default injectIntl(SideContent);
