import React from 'react';
import { func } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { ReactTitle } from 'react-meta-tags';
import { Button } from '@seekube-tech/ui-kit';
import { userActions } from '@/store/user';
import { toJS } from '@/utils';


// Containers
import { withLayout } from '@/containers/Layout';

// Components
import BlankState from '@/components/BlankState';
import Separator from '@/components/Separator';

// Styles & Translations
import styles from './styles.less';

/**
 * DashboardScene
 */
class DemoScene extends React.PureComponent { // eslint-disable-line
  static propTypes = {
    resetDemonstration: func,
  };

  state = {
    isLoading: false,
  }

  onStartDemo = () => {
    this.props.resetDemonstration();
    this.setState({ isLoading: true, isComplete: false });

    setTimeout(() => {
      this.setState({ isLoading: false, isComplete: true });
      // window.location.reload();
    }, 5000);
  }

  render() {
    const { state: { isLoading, isComplete } } = this;

    const accounts = [
      { email: 'recruteur1@seekube.com' },
      { email: 'recruteur2@seekube.com' },
      { email: 'recruteur3@seekube.com' },
      { email: 'recruteur4@seekube.com' },
      { email: 'recruteur5@seekube.com' },
      { email: 'recruteur6@seekube.com' },
    ];

    return (
      <div className={styles.containerDemo}>
        <ReactTitle title="Admin Seekube - Demo" />

        <div style={{ maxWidth: '700px', margin: 'auto' }} className={isLoading ? styles.demoIsLoading : null}>
          <BlankState
            title=""
            content=""
            icon="blank-state-rocket"
          />
        </div>

        {isLoading ? (<Button>Préparation en cours...</Button>
        ) : (<Button onClick={() => this.onStartDemo()}>
          { !isComplete ? 'Préparer une démo' : '🤟 Votre démo est prête !'}
        </Button>)}

        <Separator height={50} />

        <div className={styles.accounts}>
          <h2>Comptes recruteurs</h2>

          <div className="ant-table ant-table-default ant-table-scroll-position-left">
            <div className="ant-table-content">
              <div className="ant-table-body">
                <table className="">
                  <thead className="ant-table-thead">
                    <tr>
                      <th className=""><span></span></th>
                      <th className=""><span>Email</span></th>
                      <th className=""><span>Mot de passe</span></th>
                      <th className=""><span>Lien de partage</span></th>
                    </tr>
                  </thead>
                  <tbody className="ant-table-tbody">
                    {accounts.map((item, index) => (
                      <tr className="ant-table-row ant-table-row-level-0" data-row-key="0">
                        <td>{index + 1}</td>
                        <td>{item.email}</td>
                        <td>seek123</td>
                        <td>{`http://demo.seekube.net?user=${item.email}&pwd=seek123`}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <table>

          </table>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  resetDemonstration: userActions.resetDemonstration,
};

const withConnect = connect(null, mapDispatchToProps);

export default compose(
  withLayout({ size: 'large', withHeader: true, withNav: false }),
  withConnect,
)(toJS(DemoScene));

