import React from 'react';
import { string, bool, any, object } from 'prop-types';
import classnames from 'classnames';

// Components
import AvatarPreview from '@/components/Avatar';

// Styles & Translations
import styles from './styles.less';

const Avatar = ({ pictureUrl, isSkeleton, profileSize, status, user, containerClassName, withMedal }) => {
    const size = profileSize === 'big' ? 130 : 75;

    if (isSkeleton) {
      return (<div style={{ width: `${size}px`, height: `${size}px`, borderRadius: `${parseInt(size / 2, 10)}px`, backgroundColor: 'rgba(56, 80, 119, 0.2)' }} />);
    }

    return (
      <div className={classnames(styles.avatarContainer, containerClassName)}>
        {/* Action for open the modal for edit the avatar */}
        <AvatarPreview src={pictureUrl} size={size} status={status} user={user} imageFit="cover" withMedal={withMedal} />
      </div>
    );
}

Avatar.propTypes = {
  pictureUrl: string,
  containerClassName: string,
  profileSize: string,
  status: any,
  isSkeleton: bool,
  withMedal: bool,
  user: object,
};

export default Avatar;
