import { useQuery } from 'react-query';
import moment from 'moment';
import request from '@/utils/request';
import { objectToParams } from '@/utils/url';

export function useGetTimeSlots({ userId, event, context = 'calendar',  ...props }) {
  const beginAt = moment(event?.keyDates?.jobfair?.beginAt).toISOString();
  const endAt = moment(event?.keyDates?.jobfair?.endAt).toISOString();
  const params = objectToParams({ beginAt, endAt });

  const requestUrl = `${process.env.FRONT_API_URL}/users/${userId}/timeslots?${params}`;

  const queryFn = () => request(requestUrl, { method: 'GET' });

  const query = useQuery({
    queryKey: ['timeslots', userId, event?._id],
    queryFn,
    refetchOnWindowFocus: false,
    ...props,
  });

  return query;
}
