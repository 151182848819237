import { isEmpty, lowerCase, filter, size, get } from 'lodash';
import { getKeyMomentFormats } from '@/helpers/exponent/getKeyMomentFormats';

export default (dataSource, searchText, highlightText, { actions, context, authUser }) =>
  dataSource
    .filter((exponent) => (
      isEmpty(searchText) ||
      exponent._organization.name.toLowerCase().includes(searchText.toLowerCase()) ||
      exponent._event.name.toLowerCase().includes(searchText.toLowerCase()) ||
      (context !== 'owner_exponent' && get(exponent, '_event.planners._organizations')
        .reduce((prev, curr) =>
          prev || (
            curr.name && (
              lowerCase(curr.name).includes(lowerCase(searchText))
            ))
          , false)) ||
      exponent.users.reduce((prev, curr) =>
        prev || (
          curr._user && (
            lowerCase(curr._user.username).includes(lowerCase(searchText))
            || lowerCase(curr._user.fullName).includes(lowerCase(searchText))))
        , false)
    ))
    .map((exponent) => {
      const exponentUsers = filter(exponent.users, (exponentUser) => exponentUser._user);

      return ({
        organizationProfile: exponent.profile || exponent._organization?.profile,
        authUser,
        key: exponent._id,
        collaboratorsLength: size(exponent.users),
        exponent: highlightText(exponent._organization.name, searchText),
        keyMomentFormats: getKeyMomentFormats({ ...exponent }),
        _organization: exponent._organization,
        corners: exponent.corners,
        cornersPopulate: exponent.cornersPopulate,
        eventId: exponent._event._id,
        atsSource: exponent.atsSource,
        eventFormat: exponent._event.format,
        tool: exponent,
        children: exponentUsers.map((user, index) => ({
          key: `${exponent._id}-${user._user ? user._user._id : index}`,
          exponent: user._user ? user._user.fullName : '',
          _organization: exponent._organization,
          slot: '10',
          job: '1',
          tool: exponent,
          actions,
        })),
        actions,
      });
    });
