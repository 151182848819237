import React from 'react';
import styles
  from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/ParticipationMode/AvatarBusinessList/styles.less';
import { AvatarBusiness } from '@seekube-tech/ui-kit';
import classNames from 'classnames';
import { useGetExponentsPictureUrls } from '@/queries/exponent/useGetExponentsPictureUrls';

export const AvatarBusinessList = ({
  event,
  eventFormat= 'all',
}) => {
  const {
    isFetched,
    isSuccess,
    ...getVirtualExponentsQuery
  } = useGetExponentsPictureUrls({ eventId: event._id, limit: 5, eventFormat, sortRandom: true })

  return (
    <div  className={styles.avatarBusinessList}>
      {isFetched && isSuccess && getVirtualExponentsQuery?.data?.docs?.map((exponent, index) =>  (
        <div key={index} className={styles.exponent}>
          <AvatarBusiness
            size='small'
            pictureUrl={exponent?._organization?.profile?.pictureUrl ?? 'https://cdn-app-assets.seekube.com/user/picture.png'}
            color={exponent._organization.isPartner ? event.modules.partner?.color : 'default'}
            tooltip={event.modules.partner?.label_fr}
          />
        </div>
      ))}
      {getVirtualExponentsQuery?.data?.pages > 1 &&
        <div className={classNames(styles.exponent, styles.exponentMore)}>
          <span>+{getVirtualExponentsQuery?.data?.total - getVirtualExponentsQuery?.data?.limit}</span>
        </div>}
    </div>
  )
}