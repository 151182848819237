import { normalize } from 'normalizr';

import { call, put, takeLatest, select } from 'redux-saga/effects';
import request from '@/utils/request';
import { trackError } from '@/utils/analytics';
import * as actions from './actions';
import * as types from './types';
import { roleListSchema } from './schema';
import { entitiesActions } from '../entities';
import { authSelectors } from '../auth';
import { userActions } from '../user';


function* getRoles() {
  const authUser = yield select(authSelectors.getAuthUser);
  const requestUrl = `${process.env.FRONT_API_URL}/apps/roles`;

  try {
    const result = yield call(request, requestUrl, {
      method: 'GET',
    });

    const dataNormalized = normalize(result, roleListSchema);

    // Save entities
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    if (authUser) {
      yield put(userActions.setCurrentUser(authUser.get('_id')));
    }

    yield put(actions.getRolesSuccess({
      result: dataNormalized.result,
      items: result,
    }));
  } catch (err) {
    trackError(err);
  }
}

export default [
  takeLatest(types.GET_ROLES, getRoles),
];
