import { Button } from '@seekube-tech/ui-kit';
import React from 'react';
import { string, object, func } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import AvatarEditorDnd from '@/components/AvatarEditorDnd';

export const AvatarEdition = ({
  authUser,
  pictureUrl,
  onChange
}) => {
  const t = useFormatMessage();
  
  const handleRemoveProfilePicture = () => {
    onChange(null);
  }
  
  return (
    <AvatarEditorDnd
      src={pictureUrl}
      onSave={onChange}
      onRemove={handleRemoveProfilePicture}
      objectId={authUser._id}
      size={130}
      user={authUser}
    >
      <div>
        <Button variant="outline" style={{ display: 'block' }}>
          {`${t({ id: 'pictureUrl.choose' })} (${t({ id: 'event.recruiter.preparation.signup.identity.pictureUrl.recommendation' })})`}
        </Button>

        <p className="upload-infos">
          {t({ id: 'event.recruiter.preparation.signup.identity.pictureUrl.help' })}
        </p>
      </div>
    </AvatarEditorDnd>
  )
}

AvatarEdition.propTypes = {
  pictureUrl: string,
  authUser: object,
  onChange: func
}