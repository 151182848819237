import { createAction } from 'redux-actions';
import * as types from './types';

// Set current event
export const setCurrentEvent = createAction(types.SET_CURRENT_EVENT);
export const setCalendarEvent = createAction(types.SET_CALENDAR_EVENT);
export const setPlanningEvent = createAction(types.SET_PLANNING_EVENT);

// GET an event
export const getEvent = createAction(types.GET_EVENT);
export const getEventSuccess = createAction(types.GET_EVENT_SUCCESS);
export const getEventError = createAction(types.GET_EVENT_ERROR);
// GET an event
export const duplicateEvent = createAction(types.DUPLICATE_EVENT);
export const duplicateEventSuccess = createAction(types.DUPLICATE_EVENT_SUCCESS);
export const duplicateEventError = createAction(types.DUPLICATE_EVENT_ERROR);

// GET all events
export const getEvents = createAction(types.GET_EVENTS);
export const getEventsSuccess = createAction(types.GET_EVENTS_SUCCESS);
export const getEventsError = createAction(types.GET_EVENTS_ERROR);

// PATCH an event
export const patchEvent = createAction(types.PATCH_EVENT);
export const patchEventSuccess = createAction(types.PATCH_EVENT_SUCCESS);
export const patchEventError = createAction(types.PATCH_EVENT_ERROR);

// POST an event
export const postEvent = createAction(types.POST_EVENT);
export const postEventSuccess = createAction(types.POST_EVENT_SUCCESS);
export const postEventError = createAction(types.POST_EVENT_ERROR);

// Get all events of the auth user
export const getAuthUserEvents = createAction(types.GET_AUTH_USER_EVENTS);
export const getAuthUserEventsSuccess = createAction(types.GET_AUTH_USER_EVENTS_SUCCESS);
export const getAuthUserEventsError = createAction(types.GET_AUTH_USER_EVENTS_ERROR);

// Count all events of the auth user
export const getCountAuthUserEvents = createAction(types.COUNT_AUTH_USER_EVENTS);
export const countAuthUserEventsSuccess = createAction(types.COUNT_AUTH_USER_EVENTS_SUCCESS);
export const countAuthUserEventsError = createAction(types.COUNT_AUTH_USER_EVENTS_ERROR);

export const isAuthUserPreregistered = createAction(types.IS_AUTH_USER_PREREGISTERED);
export const isAuthUserPreregisteredSuccess = createAction(types.IS_AUTH_USER_PREREGISTERED_SUCCESS);
export const isAuthUserPreregisteredError = createAction(types.IS_AUTH_USER_PREREGISTERED_ERROR);

export const exportResumes = createAction(types.EXPORT_RESUMES);
export const exportResumesSuccess = createAction(types.EXPORT_RESUMES_SUCCESS);
export const exportResumesError = createAction(types.EXPORT_RESUMES_ERROR);

export const setFetching = createAction(types.SET_FETCHING);
