import React from 'react';
import { object } from 'prop-types';
import { compose } from 'redux';
import { Switch, Route } from 'react-router-dom';

// Containers
import { withAuth } from '@/containers/RequiredAuth';

// Pages
import DashboardPage from './scenes/Dashboard';
import DemoPage from './scenes/Demo';
import OrganizationsPage from './scenes/Organizations';
import CleanerPage from './scenes/Cleaner';
import OrganizationPage from './scenes/Organizations/scenes/Organization';
import ClientStatsByPeriodScene from '../Client/scenes/Stats/containers/ByEvent';
import ClientStatsByEventScene from '../Client/scenes/Stats/containers/ByPeriod';

const AdminScene = ({
  match: { path, isExact },
}) => {
  // List all events on seekube
  if (isExact) return <DashboardPage />;

  return (
    <Switch>
      <Route path={`${path}/demo`} component={DemoPage} />
      <Route path={`${path}/organizations/:organizationId`} component={OrganizationPage} />
      <Route path={`${path}/organizations`} component={OrganizationsPage} />
      <Route path={`${path}/cleaner`} component={CleanerPage} />
      <Route path={`${path}/stats/by-event`} component={ClientStatsByPeriodScene} />
      <Route path={`${path}/stats/by-period`} component={ClientStatsByEventScene} />
    </Switch>
  );
};

AdminScene.propTypes = {
  match: object,
};

export default compose(
  withAuth({ isAdmin: true }),
)(AdminScene);
