import { useQuery } from 'react-query';
import request from '@/utils/request';

export function useGetExponentsStats(
  {
    event,
    view = event.format === 'hybrid' ? undefined : 'all',
  }) {
  const requestUrl = `${process.env.FRONT_API_URL}/statistics/kpi/event-light?eventId=${event._id}&eventFormat=${view}`;

  const queryFn = async () => request(requestUrl);

  const query = useQuery({
    queryKey: ['exponentsStats', view],
    queryFn,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    enabled:  !(event.format === 'hybrid' && view === undefined)
  });

  return query;
}
