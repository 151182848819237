import React from 'react';
import { func, number, shape, array, object, string } from 'prop-types';
import { injectIntl } from 'react-intl';
import { Form, Divider } from 'antd';
import { Button, IconPlusCircle, IconTrash2 } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import {
  StartYearFormation
} from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/Profile/Educations/EducationForm/StartYearFormation';
import {
  EndYearFormation
} from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/Profile/Educations/EducationForm/EndYearFormation';
import {
  SchoolName
} from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/Profile/Educations/EducationForm/SchoolName';
import {
  ProgramName
} from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/Profile/Educations/EducationForm/ProgramName';

const FormItem = Form.Item;


const EducationForm = ({
  education, handleAddEducation, handleDeleteEducation, educationsCount, order, beginYears, endYears, context, form
}) => {
  const t = useFormatMessage();

  return (
    <>
      <SchoolName order={order} education={education} form={form} />

      <ProgramName order={order} education={education} form={form} />

      <div className="flex-row-wrapper with-space">
        <StartYearFormation form={form} education={education} order={order} beginYears={beginYears} />

        <EndYearFormation form={form} education={education} order={order} endYears={endYears} />

      </div>

      <div className="add-container justify-end">
        {context !== 'settings' ? (
          <div className="add-zone">
            {
              order === 0 ? (
                educationsCount === 1 && (
                  <Button variant="outline" size="small" imageComponentLeft={<IconPlusCircle size={16} />} role="presentation" onClick={handleAddEducation}>
                    {t({ id: 'educations.add'  })}
                  </Button>
                )
              ) : (
                <Button variant="tonal" color="error" size="small" imageComponentLeft={<IconTrash2 size={16} />} role="presentation" onClick={() => handleDeleteEducation(order)}>
                  {t({ id: 'event.candidate.preparation.signup.userExperience.formation.delete' })}
                </Button>
              )
            }
          </div>
        ) : (
          <>
            <FormItem className="add-zone">
              {educationsCount > 1 ? (
                <Button variant="tonal" color="error"  size="small" imageComponentLeft={<IconTrash2 size={16} />} role="presentation" onClick={() => handleDeleteEducation(order)}>
                  {t({ id: 'event.candidate.preparation.signup.userExperience.formation.delete'})}
                </Button>
              ) : ''}
            </FormItem>
            {order === 0 && educationsCount <= 1 && (
              <div>
                <Button variant="outline" size="small" imageComponentLeft={<IconPlusCircle size={16} />} role="presentation" onClick={handleAddEducation}>
                  {t({ id: 'educations.add' })}
                </Button>
              </div>
            ) }
          </>
        )}
      </div>

      {(educationsCount > 1 && order === 0) && <Divider />}
    </>
  );
}

EducationForm.propTypes = {
  handleAddEducation: func,
  handleDeleteEducation: func,
  order: number,
  education: object,
  educationsCount: number,
  form: shape({
    getFieldDecorator: func,
    getFieldError: func,
    isFieldTouched: func,
  }),
  beginYears: array,
  endYears: array,
  context: string,
};

export default injectIntl(EducationForm);
