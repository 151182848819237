/**
 * Class for transform data from the form to the API
 */
export default class ApiToForm {
  /**
   * Return a specific key on a specific module
   *
   * @param {Array} modules
   * @param {string} moduleName
   * @param {string} key
   *
   * @returns {any}
   */
  static module = (modules = {}, moduleName, key = 'label') => modules[moduleName] ? modules[moduleName][key] : null;
}
