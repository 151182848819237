import React, { useEffect, useState } from 'react';
import { bool, func, string } from 'prop-types';
import { injectIntl } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import { IconBriefcase, IconBuilding, IconCalendar, IconMessageCircle2, IconVideo, TapBarIcon, IconHome } from '@seekube-tech/ui-kit';
import { push } from 'connected-react-router';
import { useFormatMessage } from 'react-intl-hooks';
import { toJS } from '@/utils';
import { getCurrentEvent } from '@/store/event/selectors';
import styles from './styles.less';

const MobileMenu = ({
  liveLabel,
  displayLive,
  displayInformal,
  push,
}) => {
  const event = useSelector(getCurrentEvent)?.toJS();

  if (!event) {
    return null;
  }

  const t = useFormatMessage();

  const [menuElements, setMenuElements] = useState([
  {
    componentImage: <IconBuilding size="24" />,
    text: t({id: 'event.menu.exponents'}),
    onClick: () => push(`/${event.slug}/candidate/jobdating/exponents`),
    id: 'exponents'
  },
  {
    componentImage: <IconCalendar size="24" />,
    text: t({id: 'candidate.jobdating.appointments'}),
    onClick: () => push(`/${event.slug}/candidate/jobdating/appointments`),
    id: 'appointments'
  }])

  useEffect(() => {
    const elements = [];
    if (event.modules?.dashboard?.enable) {
      elements.push({
        componentImage: <IconHome size="24" />,
        text: t({id: 'event.owner.dashboard'}),
        onClick: () => push(`/${event.slug}/candidate/jobdating/dashboard`),
        id: 'dashboard'
      })
    }

    if (event.modules.offer.enable) {
      elements.push({
        componentImage: <IconBriefcase size="24" />,
        text: t({id: 'candidate.jobdating.offers'}),
        onClick: () => push(`/${event.slug}/candidate/jobdating/jobs`),
        id: 'jobs'
      })
    }

    if (displayInformal) {
      elements.push({
        componentImage: <IconMessageCircle2 size="24" />,
        text: t({id: 'event.owner.settings.modules.landing.informal1to1'}),
        onClick: () => push(`/${event.slug}/candidate/jobdating/informal1to1`),
        id: 'informal1to1'
      })
    }

    if (displayLive) {
      elements.push({
        componentImage: <IconVideo size="24" />,
        text: liveLabel || t({id: 'candidate.jobdating.lives'}),
        onClick: () => push(`/${event.slug}/candidate/jobdating/conferences`),
        id: 'conferences'
      })
    }
    elements.splice(1, 0, menuElements[0])
    elements.push(menuElements[1])
    setMenuElements(elements)
  }, [displayLive, displayInformal])

  return (
    <div className={styles.menu}>
      <TapBarIcon elements={menuElements} activeTab={location.pathname.split('/')[4]} />
    </div>
  );
}

MobileMenu.propTypes = {
  displayLive: bool,
  liveLabel: string,
  displayInformal: bool,
  push: func
};

const mapStateToProps = createStructuredSelector({
});

const mapDispatchToProps = {
  push
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  injectIntl,
)(toJS(MobileMenu));
