import React from 'react';
import { array } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';

const KeyMomentFormatsAccessor =  ({ keyMomentFormats }) => {
  const t = useFormatMessage();

  return (
      <div
        className="border rounded-md border-neutral-600 text-neutral-600 text-center inline"
        style={{ maxWidth: '60px', padding: '4px', display: 'inline-block' }}
      >
        {
          keyMomentFormats.map((format, i) =>
            <span key={i}>{i === 1 && ' + '} {t({ id: `event.owner.settings.keyMoments.input.tags.${format}` })}</span>
          )
        }
      </div>
  );
}
KeyMomentFormatsAccessor.propTypes = {
  keyMomentFormats: array,
}

export { KeyMomentFormatsAccessor }