import { useMutation } from 'react-query';
import request from '@/utils/request';
import notification from '@/components/Notification';
import { useFormatMessage } from 'react-intl-hooks';

export function useGetSignedAwsUrl(
  {
    user,
    onSuccess = () => {},
    sizeLimit = 2000000,
  }) {
  const t = useFormatMessage();

  const mutationFn = ({ ...props }) => {
    const { file } = props;

    if (file?.size > sizeLimit) {
      notification.error(t({ id: 'form.resume.error.size' }));

      return false;
    }

    const requestUrl = `${process.env.FRONT_API_URL}/users/${user._id}/upload?fileName=${file.name}`;

    return request(requestUrl)
  }

  return useMutation({
    mutationFn,
    onSuccess: async (res, file, dd) => onSuccess(res, file),
  });
}
