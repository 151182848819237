import {useQuery} from 'react-query';
import request from '@/utils/request';

export function useGetKpiSessions(props) {
  const requestUrl = `${process.env.FRONT_API_URL}/statistics/kpi/sessions`;
  const queryFn = () => request(requestUrl, { method: 'GET' });

  return useQuery({
    queryKey: ['kpiSessions'],
    queryFn,
    refetchOnWindowFocus: false,
    ...props,
  });
}
