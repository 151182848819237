import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import * as types from './types';

const initialState = fromJS({
  currentId: null,
  createdAt: null,
  alreadyExistError: false,
  allIds: [],
  isFetching: false,
  specificOrganization: null,
  suggestions: [],
  mergeList: [],
  mergeListTotal: 0,
  pagination: {
    current: 1,
    pageSize: 2,
    total: 0,
    offset: 0,
  },
});

export default handleActions({
  [types.SET_CURRENT_ORGANIZATION]: (state, { payload: organizationId }) =>
    state
      .set('currentId', organizationId)
      .set('alreadyExistError', false),
  [types.GET_SPECIFIC_ORGANIZATION]: (state) =>
    state.set('isFetching', true),
  [types.GET_ORGANIZATIONS]: (state, { payload }) => {
    if (payload && payload.loader === true) {
      return state.set('isFetching', true);
    }

    return state;
  },
  [types.GET_ALL_ORGANIZATIONS_LIST]: (state, { payload }) => {
    if (payload && payload.loader === true) {
      return state.set('isFetching', true);
    }

    return state;
  },
  [types.PATCH_ORGANIZATION_ERROR]: (state) => state.set('alreadyExistError', true),
  [types.POST_ORGANIZATION_ERROR]: (state) => state.set('alreadyExistError', true),
  [types.RESET_ALREADY_EXIST]: (state) => state.set('alreadyExistError', false),
  [types.GET_ALL_ORGANIZATIONS_LIST_SUCCESS]: (state, { payload }) => state
      .set('isFetching', false)
      .set('alreadyExistError', false)
      .set('allIds', fromJS(payload.result))
      .setIn(['pagination', 'current'], payload.currentPage)
      .setIn(['pagination', 'pageSize'], payload.limit)
      .setIn(['pagination', 'offset'], payload.offset)
      .setIn(['pagination', 'total'], payload.total),
  [types.GET_MERGE_LIST_SUCCESS]: (state, { payload }) =>
    state
      .set('suggestions', payload.suggestions)
      .set('mergeList', payload.docs)
      .set('mergeListTotal', payload.total),
  [types.GET_SPECIFIC_ORGANIZATION_SUCCESS]: (state, { payload: organizationId }) =>
    state
      .set('specificOrganization', organizationId)
      .set('isFetching', false)
      .set('alreadyExistError', false),
}, initialState);
