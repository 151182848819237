import React from 'react';
import { any } from 'prop-types';
import moment from 'moment';
import { injectIntl } from 'react-intl';

import { Alert } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';

const AppointmentUnconfirmedHelp = ({ date }) => {
  const momentDate = moment(date);
  const t = useFormatMessage();

  return (
    <Alert
      color="info"
    >
      {t({ id: 'appointment.unconfirmedHelp' }, { date: momentDate.format('D MMMM') })}
    </Alert>
  );
};

AppointmentUnconfirmedHelp.propTypes = {
  date: any,
};

export default React.memo(injectIntl(AppointmentUnconfirmedHelp));
