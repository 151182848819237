import React, { useEffect, useState } from 'react';
import { object } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import moment from 'moment'
import { Clock, Info } from 'react-feather';
import styles from './styles.less'

import MeetingElement from './MeetingElement';

const MeetingsList = ({ event, appointments }) => {
  const t = useFormatMessage();
  let appointmentHour = '';
  let focusOn = false;
  const [scrollHasBeenMade, setScrollHasBeenMade] = useState(false)

  useEffect(() => {
    if(appointments && focusOn && document.getElementById('hourFocusOn') && document.getElementById('meetingListBody') && !scrollHasBeenMade) {
      const list = document.getElementById('meetingListBody');
      const card = document.getElementById('hourFocusOn');
      list.scroll(0, card.getBoundingClientRect().top - list.getBoundingClientRect().top);
      setScrollHasBeenMade(true)
    }
  },[document.getElementById('hourFocusOn'), appointments])

  return (
    <div className={styles.meetingsListContainer}>
      <div className={styles.header}>
        <div className={styles.columnHeader}>
          <p>{t({ id: `dashboard.realtime.meetingList.appointments.column.recruiter`})}</p>
        </div>
        <div className={styles.columnHeader}>
          <div className={styles.separator} />
          <p>{t({ id: `dashboard.realtime.meetingList.appointments.column.candidate`})}</p>
        </div>
        <div className={styles.columnHeader}>
          <div className={styles.separator} />
          <p>{t({ id: `dashboard.realtime.meetingList.appointments.column.detail`})}</p>
        </div>
      </div>
      <div className={styles.body} id='meetingListBody'>
        {appointments?.map((appointment, key) => {
          
          if(appointmentHour !== moment(appointment.date).format('HH')) {
            appointmentHour = moment(appointment.date).format('HH')
            
            if(moment(moment(appointment.date)).isSameOrAfter(moment(), 'hours') && !focusOn) {
              focusOn = true;
            }
            return (
              <div key={key}>
                <div className={styles.hourSeparator} id={focusOn ? 'hourFocusOn' : null}><Clock size={16} /> {appointmentHour}h</div>
                <MeetingElement appointment={appointment} key={key} event={event} />
              </div>
            )
          }
          return (
            <MeetingElement appointment={appointment} key={key} event={event} />
          )
        })}
        {appointments?.data?.length === 0 &&
          <div className={styles.meetingBlankState}>
            <Info size={24} />
            <p>{t({ id: `dashboard.realtime.meetingList.appointments.blankState`})}</p>
          </div>
        }
      </div>
    </div>
  )
}

MeetingsList.propTypes = {
  event: object,
  appointments: object,
};

export default MeetingsList;
