import { makeStyles } from '@seekube-tech/ui';

export default makeStyles(theme => ({
  decrease: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.error['500'],

    '& span': {
      color: theme.palette.error['500'],
    },

    '& svg': {
      width: '16px',
      height: '16px',
    }
  },
  increase: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.success['500'],

    '& span': {
      color: theme.palette.success['500'],
    },

    '& svg': {
      width: '16px',
      height: '16px',
    }
  },

}));
