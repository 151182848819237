import { defineMessages } from 'react-intl';

export default defineMessages({
  propositionCardSourcingRecruiter: {
    id: 'proposition.card.sourcing.recruiter',
  },
  propositionCardSourcingOrganization: {
    id: 'proposition.card.sourcing.organization',
  },
  chooseSlot: {
    id: 'event.candidate.appointments.card.chooseSlot',
  },
  pending: {
    id: 'event.candidate.appointments.card.status.pending',
  },
  candidateCanceledInterview: {
    id: 'event.candidate.appointments.card.interview.canceled.by.candidate',
  },
  recruiterCanceledInterview: {
    id: 'event.candidate.appointments.card.status.canceled.by.recruiter',
  },
});
