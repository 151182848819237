import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { oneOf, object, array, func } from 'prop-types';
import { injectIntl } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { isEmpty, map } from 'lodash';
import { Button } from '@seekube-tech/ui-kit';
import { trackRecruiterClickedInfoPlan } from '@/utils/analytics';
import { toJS } from '@/utils';

import { authSelectors } from '@/store/auth';

import Icon from '@/components/Icon';

import styles from './styles.less';

const Info = ({
  plan,
  push,
  authUser,
  intl: { formatMessage },
}) => {
  const pageProperties = getPlans(formatMessage)[plan];

  if (isEmpty(pageProperties)) return null;

  const onSubmit = () => {
    trackRecruiterClickedInfoPlan({ plan, authUser });
    push('?step=request');
  };

  const { title, blocs, advantages, action } = pageProperties;

  return (
    <div className={styles.pageContainer}>
      <h2>{title}</h2>
      <AdvantageBlocs blocs={blocs} />
      <AdvantageList advantages={advantages} />
      <div className={styles.actions}>
        <Button onClick={onSubmit}>
          {action.btn.text}
        </Button>
      </div>
    </div>
  );
};

const AdvantageBlocs = ({ blocs }) => (
  <div className={styles.blocs}>
    {map(blocs,
      (bloc) => (
        <div className={styles.bloc}>
          <span className={styles.heading}><Icon name={bloc.icon} /> <h3>{bloc.title}</h3></span>
          <p>{bloc.description}</p>
        </div>
      )
    )}
  </div>
);

const AdvantageList = ({ advantages }) => (
  <div className={styles.advantages}>
    <div>
      <h3>{advantages.title}</h3>
      <div className={styles.advantage}>
        {!isEmpty(advantages.list.right) ? (
          <>
            <div className={styles.left}>
              {map(advantages.list.left,
                (advantage, index) => <div key={index} className={styles.item}><Icon name={advantage.icon} /> <span>{advantage.text}</span></div>)}
            </div>
            <div className={styles.right}>
              {map(advantages.list.right,
                (advantage, index) => <div key={index} className={styles.item}><Icon name={advantage.icon} /> <span>{advantage.text}</span></div>)}
            </div>
          </>
        ) : (
          <div className={styles.center}>
            {map(advantages.list.left,
              (advantage, index) => <div key={index} className={styles.item}><Icon name={advantage.icon} /> <span>{advantage.text}</span></div>)}
          </div>
        )}
      </div>
    </div>
  </div>
);

const getPlans = (formatMessage) => ({
  school: {
    title: formatMessage({ id: 'recruiter.planInfo.schoolpack.title' }),
    blocs: [
      { icon: 'building', title: formatMessage({ id: 'recruiter.planInfo.schoolpack.bloc.boost.title' }), description: formatMessage({ id: 'recruiter.planInfo.schoolpack.bloc.boost.description' }) },
      { icon: 'hourglass', title: formatMessage({ id: 'recruiter.planInfo.schoolpack.bloc.efficiency.title' }), description: formatMessage({ id: 'recruiter.planInfo.schoolpack.bloc.efficiency.description' }) },
      { icon: 'increase', title: formatMessage({ id: 'recruiter.planInfo.schoolpack.bloc.conversion.title' }), description: formatMessage({ id: 'recruiter.planInfo.schoolpack.bloc.conversion.description' }) },
    ],
    advantages: {
      title: formatMessage({ id: 'recruiter.planInfo.schoolpack.list.title' }),
      list: {
        left: [
          { icon: 'success', text: formatMessage({ id: 'recruiter.planInfo.schoolpack.list.stats' }) },
          { icon: 'success', text: formatMessage({ id: 'recruiter.planInfo.schoolpack.list.sync' }) },
          { icon: 'success', text: formatMessage({ id: 'recruiter.planInfo.schoolpack.list.export.cv' }) },
        ],
        right: [
          { icon: 'success', text: formatMessage({ id: 'recruiter.planInfo.schoolpack.list.seekalendar' }) },
          { icon: 'success', text: formatMessage({ id: 'recruiter.planInfo.schoolpack.list.multidiff.offers' }) },
        ],
      },
    },
    action: {
      btn: {
        text: formatMessage({ id: 'recruiter.planInfo.actions.ask.infos' }),
      },
    },
  },
  seekube: {
    title: formatMessage({ id: 'recruiter.planInfo.seekube.title' }),
    blocs: [
      { icon: 'star', title: formatMessage({ id: 'recruiter.planInfo.seekube.bloc.attractiveness.title' }), description: formatMessage({ id: 'recruiter.planInfo.seekube.bloc.attractiveness.description' }) },
      { icon: 'accept4', title: formatMessage({ id: 'recruiter.planInfo.seekube.bloc.boost.meeting.title' }), description: formatMessage({ id: 'recruiter.planInfo.seekube.bloc.boost.meeting.description' }) },
      { icon: 'sourcing2', title: formatMessage({ id: 'recruiter.planInfo.seekube.bloc.profile.title' }), description: formatMessage({ id: 'recruiter.planInfo.seekube.bloc.profile.description' }) },
    ],
    advantages: {
      title: formatMessage({ id: 'recruiter.planInfo.seekube.list.title' }),
      list: {
        left: [
          { icon: 'success', text: formatMessage({ id: 'recruiter.planInfo.seekube.list.engineers' }) },
          { icon: 'success', text: formatMessage({ id: 'recruiter.planInfo.seekube.list.business' }) },
          { icon: 'success', text: formatMessage({ id: 'recruiter.planInfo.seekube.list.it' }) },
        ],
      },
      center: true,
    },
    action: {
      btn: {
        text: formatMessage({ id: 'recruiter.planInfo.actions.ask.infos' }),
      },
    },
  },
  company: {
    title: formatMessage({ id: 'recruiter.planInfo.company.title' }),
    blocs: [
      { icon: 'logo', title: formatMessage({ id: 'recruiter.planInfo.company.bloc.famous.title' }), description: formatMessage({ id: 'recruiter.planInfo.company.bloc.famous.description' }) },
      { icon: 'accept4', title: formatMessage({ id: 'recruiter.planInfo.company.bloc.expertise.title' }), description: formatMessage({ id: 'recruiter.planInfo.company.bloc.expertise.description' }) },
      { icon: '@/messages', title: formatMessage({ id: 'recruiter.planInfo.company.bloc.support.title' }), description: formatMessage({ id: 'recruiter.planInfo.company.bloc.support.description' }) },
    ],
    advantages: {
      title: formatMessage({ id: 'recruiter.planInfo.company.list.title' }),
      list: {
        left: [
          { icon: 'success', text: formatMessage({ id: 'recruiter.planInfo.company.list.participants' }) },
          { icon: 'success', text: formatMessage({ id: 'recruiter.planInfo.company.list.format' }) },
          { icon: 'success', text: formatMessage({ id: 'recruiter.planInfo.company.list.location' }) },
        ],
        right: [
          { icon: 'success', text: formatMessage({ id: 'recruiter.planInfo.company.list.pme' }) },
          { icon: 'success', text: formatMessage({ id: 'recruiter.planInfo.company.list.mobility' }) },
        ],
      },
    },
    action: {
      btn: {
        text: formatMessage({ id: 'recruiter.planInfo.actions.ask.infos' }),
      },
    },
  },
});

AdvantageBlocs.propTypes = {
  blocs: array,
};

AdvantageList.propTypes = {
  advantages: object,
};

Info.propTypes = {
  plan: oneOf(['seekube', 'school', 'company']),
  push: func,
  authUser: object,
  intl: object,
};

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser,
});

const mapDispatchToProps = {
  push,
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  injectIntl,
  withConnect,
  toJS,
)(Info);
