import React from 'react';
import { bool, func, string } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { Modal } from 'antd';

// Components
import { Button, IconX } from '@seekube-tech/ui-kit';

import styles from './styles.less';



function ConfirmationParticipationMode({
  isOpen = false,
  organizationName,
  oldMode,
  newMode,
  onCancel,
  onOk,
}) {
  const t = useFormatMessage();
  return (
    <Modal
      onOk={onOk}
      onCancel={() => onCancel()}
      visible={isOpen}
      width={906}
      footer={false}
      className={`customConfirm ${styles.confirmationParticipationRoleContainer}`}
      okText={t({ id: 'add' })}
      cancelText={t({ id: 'cancel' })}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
        <h4 style={{ fontSize: '30px' }}>{t({ id: 'event.owner.confirmationParticipationMode.title'},{ organizationName })} {t({ id: `exponent.participationMode.${oldMode}`})} {t({ id: `exponent.participationMode.to`})} {t({ id: `exponent.participationMode.${newMode}`})} ?</h4>
      </div>

      <div className={styles.textContainer}>
        <p className={styles.listIntro}>{t({ id: 'event.owner.confirmationParticipationMode.description.intro' })}</p>
        <ul>
          <li className={styles.listElement}>{t({ id: 'event.owner.confirmationParticipationMode.description.step1' })}</li>
          <li className={styles.listElement}>{t({ id: 'event.owner.confirmationParticipationMode.description.step2'}, { organizationName })} {t({ id: `exponent.participationMode.${newMode}`})}</li>
        </ul>
      </div>

      <div className={styles.buttonContainer}>
        <Button
          variant="text"
          color="primary"
          onClick={() => onCancel()}
        >
          {t({ id: 'cancel' })}
        </Button> 

        <Button
          type='submit'
          variant='fill'
          color='error'
          onClick={() => onOk()}
        >
          {t({ id: 'event.owner.confirmationParticipationMode.button' })}
        </Button>

      </div>
    </Modal>
  );
}

ConfirmationParticipationMode.propTypes = {
  isOpen: bool,
  onOk: func,
  onCancel: func,
  organizationName: string,
  newMode: string,
  oldMode: string,
};

export default ConfirmationParticipationMode;
