/**
 * Combine all reducers in this file and export the combined reducers.
 */
import { combineReducers } from 'redux-immutable';
import { connectRouter } from 'connected-react-router/immutable'

/**
 * App reducers
 */
import notificationReducer from '@/store/notification';
import entitiesReducer from '@/store/entities';
import roleReducer from '@/store/role';
import authReducer from '@/store/auth';
import eventReducer from '@/store/event';
import participantReducer from '@/store/participant';
import uploadReducer from '@/store/upload';
import jobReducer from '@/store/job';
import languageProviderReducer from '@/containers/LanguageProvider/reducer';
import appReducer from '@/store/app';
import userReducer from '@/store/user';
import interactionReducer from '@/store/interaction';
import exponentReducer from '@/store/exponent';
import offerReducer from '@/store/offer';
import appointmentReducer from '@/store/appointment';
import timeslotReducer from '@/store/timeslot';
import organizationReducer from '@/store/organization';
import sectorReducer from '@/store/sector';
import v1KpiReducer from '@/store/v1Kpi';
import criterionReducer from '@/store/criterion';
import actionReducer from '@/store/action';
import surveyReducer from '@/store/survey';

/**
 * Creates the main reducer with the dynamically injected ones
 */
export default function createRootReducers(customHistory) {
  return combineReducers({
    role: roleReducer,
    language: languageProviderReducer,
    entities: entitiesReducer,
    notification: notificationReducer,
    event: eventReducer,
    upload: uploadReducer,
    participant: participantReducer,
    job: jobReducer,
    app: appReducer,
    auth: authReducer,
    user: userReducer,
    interaction: interactionReducer,
    exponent: exponentReducer,
    offer: offerReducer,
    appointment: appointmentReducer,
    timeslot: timeslotReducer,
    organization: organizationReducer,
    sector: sectorReducer,
    v1Kpi: v1KpiReducer,
    criterion: criterionReducer,
    action: actionReducer,
    survey: surveyReducer,
    router: connectRouter(customHistory),
  });
}
