import { getIsEventInPreregistrationPhase } from '@/helpers/event/getIsEventInPreregistrationPhase';
import styles from '@/scenes/Event/containers/Landing/styles.less';
import { Alert } from '@seekube-tech/ui-kit';
import React from 'react';
import { object } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { getFarthestKeyMomentFromFormats } from '@/utils/keyMoments';
import { getLocale } from '@/utils/localStorage';
import { getIsCandidateOrGuest } from '@/utils/event';
import { DATES_FORMAT, EVENT_STATUS, EVENT_TYPE } from '@/utils/constants';

const PreregistrationAlert = ({
  event
}) => {
  const t = useFormatMessage();
  const locale = getLocale();
  const dateFormat = DATES_FORMAT[locale];
  const endOffersDate = moment(getFarthestKeyMomentFromFormats(event, EVENT_STATUS.DISCOVERING)).format(dateFormat);
  
  if (!getIsEventInPreregistrationPhase(event) || !getIsCandidateOrGuest(event) || event.type === EVENT_TYPE.school) {
    return null
  }
  
  return (
    <Alert
      color="info"
      isClosable={false}
    >
      <div className={styles.alert}>
        <span className="font-semibold">{t({ id: 'landing.preregistration.info.title' }, { date: endOffersDate })}</span>
        {t({ id: 'landing.preregistration.info.text' })}
        </div>
    </Alert>
  )
}

PreregistrationAlert.propTypes = {
  event: object
}

export { PreregistrationAlert };