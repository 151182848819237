import React from 'react';
import { any, string, bool, func, object } from 'prop-types';
import classNames from 'classnames';
import { isEmail } from 'validator';
import { isEmpty, omit } from 'lodash';
import styles from '@/components/Form/styles.less';

class Textarea extends React.PureComponent {
  static propTypes = {
    errorMsg: string,
    placeholder: string,
    value: string,
    label: string.isRequired,
    pattern: any,
    id: string.isRequired,
    disabled: bool,
    validations: any,
    maxLength: any,
    onChange: func,
    'data-__meta': object,
    containerClassName: string,
    withFloatingPlaceholder: bool,
  };

  static defaultProps = {
    disabled: false,
    id: 'text-box',
    placeholder: ' ',
    maxLength: null,
    label: '',
    withFloatingPlaceholder: false,
  };

  static getDerivedStateFromProps(props) {
    if ((props['data-__meta'] && props['data-__meta'].initialValue) || (!isEmpty(props.value))) {
      return { hasValue: true };
    }

    return null;
  }

  /**
   * State
   */
  constructor(props) {
    super(props);

    this.state = {
      hasValue: false,
      hasFocus: false,
      hasError: false,
      errorMessage: false,
    };

    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onFocus(event) {
    const { props: { validations } } = this;
    const {value} = event.currentTarget;
    this.setState({ hasFocus: true });

    if (validations !== undefined) {
      this.handleValidation(value);
    }
  }

  onBlur(event) {
    const { props: { validations } } = this;
    const {value} = event.currentTarget;

    this.setState({ hasValue: Boolean(value), hasFocus: false });

    if (validations !== undefined) {
      this.handleValidation(value);
    }
  }

  onChange(event) {
    const { props: { validations, onChange } } = this;
    const {value} = event.currentTarget;

    this.setState({
      hasValue: Boolean(value),
    });

    this.props.onChange(event);

    if (validations !== undefined) {
      this.handleValidation(value);
    }

    onChange(value);
  }

  handleValidation(value) {
    const { props: { validations } } = this;

    let countErrors = 0;

    validations.forEach((item) => {
      switch (item) { // eslint-disable-line
        case 'required':
          if (!value.toString().trim().length) {
            countErrors += 1;
          }

          break;

        case 'email':
          if (!isEmail(value)) {
            countErrors += 1;
          }

          break;
      }
    });

    if (countErrors) {
      this.setState({ validationStatus: 'error' });
    } else {
      this.setState({ validationStatus: 'success' });
    }
  }

  render() {
    const { errorMsg, id, disabled, pattern, placeholder, label, validations, maxLength, containerClassName, withFloatingPlaceholder } = this.props;
    const { hasValue, hasError, validationStatus, hasFocus } = this.state;

    const inputClasses = classNames(
      styles['fl-input'],
      label !== '' ? styles.hasLabel : null,
      hasValue && !hasError ? styles['fl-valid'] : null,
      hasValue && hasError ? styles['fl-invalid'] : null,
      hasValue ? styles.textareaHasValue : null,
    );

    const inputContainerClasses = classNames(
    styles[validationStatus],
      styles['fl-input-container-textarea'],
      styles['fl-input-container'], disabled ? styles['fl-disabled'] : null,
      hasFocus ? styles.hasFocus : null,
      validations !== undefined ? styles.withValidation : null,
      containerClassName,
      withFloatingPlaceholder ? styles.withFloatingPlaceholder: null
    );
    const errMsgClasses = classNames(errorMsg ? styles['fl-error-msg'] : null, (hasError && hasValue) && (errorMsg && pattern) ? styles['fl-error-show'] : null);

    return (
      <div className={inputContainerClasses}>
        <textarea
          className={inputClasses}
          disabled={disabled}
          id={id}
          onBlur={this.onBlur}
          onChange={this.onChange}
          onFocus={this.onFocus}
          placeholder={placeholder}
          maxLength={maxLength}
          {...omit(this.props, ['containerClassName'])}
        />
        {label ? (
          <label className={styles['fl-input-label']} htmlFor={id}>{label}</label>
        ) : ''}
        { errorMsg && <span className={styles[errMsgClasses]}>{errorMsg}</span> }
      </div>
    );
  }
}

export default Textarea;
