import { Button } from '@seekube-tech/ui-kit';
import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { object } from 'prop-types';
import { useForm } from 'react-final-form';
import Icon from '@/components/Icon';
import SocialLoginButton from '@/components/SocialLoginButton';
import styles from './styles.less';
import {
  useOnImportLinkedinInfos
} from '@/scenes/Settings/components/IdentityV2/components/ImportLinkedinInformations/useOnImportLinkedinInfos';
    
export const ImportLinkedinInformations = ({
  authUser
}) => {
  const t = useFormatMessage();
  const form = useForm();
  const { importData, status, isLoading } = useOnImportLinkedinInfos({
    authUser,
    form
  })
  
  const handleSocialLogin = (user) => {
    importData(user);
  }
  
  const getButtonLabel = () => {
    switch (status) {
      case 'success':
        return t({ id: 'event.recruiter.preparation.signup.identity.linkedin.success' });
      case 'error':
        return t({ id: 'event.recruiter.preparation.signup.identity.linkedin.error' });
      default:
        return t({ id: 'event.recruiter.preparation.signup.identity.linkedin.import' });
    }
  }
  
  return (
    <div className={styles.container}>
      <SocialLoginButton
        type="link"
        provider="linkedin"
        className="p-0"
        appId={process.env.FRONT_LINKEDIN_CLIENT_ID}
        onLoginSuccess={handleSocialLogin}
      >
        <Button size="large" color={status === 'error' ? 'error' : 'neutral'} loading={isLoading}>
          <Icon name="linkedin" className="mr-10" />
          {getButtonLabel()}
        </Button>
      </SocialLoginButton>
      <p className={`help-linkedin ${styles.text}`} style={{ fontSize: '12px' }}>
        {t({ id: 'event.recruiter.preparation.signup.identity.linkedin.security' })}
      </p>
    </div>
  )
}

ImportLinkedinInformations.propTypes = {
  authUser: object
}