import { createSelector } from 'reselect';

const getActionReducer = (state) => state.get('action');

/**
 * Return current app version
 */
export const getActions = createSelector(
  getActionReducer,
  (state) => state.get('list')
);
