import React from 'react';
import { bool, object, string } from 'prop-types';
import { Typography, useTheme, useFieldHelperText, Grid } from '@seekube-tech/ui';
import { Popover } from 'antd';

import useStyles from './styles';

function FieldColor({ className, label, required, input, meta }) {
  /* Vars */

  const { helperText } = useFieldHelperText(meta);
  const styles = useStyles();
  const theme = useTheme();
  const colors = Object.values(theme.palette.common)
    .map(color => color[500])
    .filter(color => color !== undefined);

  /* Functions */

  /**
   * Call when popover visibily change
   * @description Set onFucus and onBlur event when is open and closed
   * @param {Boolean} isVisible
   */
  const handleVisibleChange = (isVisible) => {
    if (isVisible) {
      input.onFocus();
    } else {
      input.onBlur();
    }
  };

  /**
   * Send value selected in form
   * @param {String} value
   */
  const handleOnChange = (value) => () => {
    input.onChange(value);
  };

  /* Render */

  return (
    <div className={className}>
      <Typography variant="body2" color="grey[500]">
        {label} {required && (<span className={styles.asterisk}>*</span>)}
      </Typography>

      <Popover
        content={(
          <div className={styles.container}>
            <div className={styles.arrow} />

            <Grid container spacing={1}>
              {colors.map(color => (
                <Grid item key={color}>
                  <button
                    type="button"
                    onClick={handleOnChange(color)}
                    style={{ backgroundColor: color }}
                    className={styles.button}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
        )}
        trigger="click"
        placement="bottom"
        onVisibleChange={handleVisibleChange}
      >
        <div className={styles.preview} style={{ backgroundColor: input.value || '#000' }} />
      </Popover>

      {helperText}
    </div>
  );
}

FieldColor.propTypes = {
  className: string,
  label: string.isRequired,
  required: bool,
  input: object.isRequired,
  meta: object.isRequired,
};

FieldColor.defaultProps = {
  className: '',
  required: false,
};

export default FieldColor;
