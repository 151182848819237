import React from 'react';
import { func, array } from 'prop-types';
import { push } from 'connected-react-router';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { ReactTitle } from 'react-meta-tags';
import { toJS } from '@/utils';
import { isEmpty } from 'lodash';

// Store
import { actionActions, actionSelectors } from '@/store/action';
import { organizationActions, organizationSelectors } from '@/store/organization';
import { criterionActions, criterionSelectors } from '@/store/criterion';

// Components
import MergeNewItem from './components/MergeNewItem';

// Styles & Translations
import styles from './styles.less';

class MergeCriteriaScene extends React.PureComponent { // eslint-disable-line
  static propTypes = {
    defaultCriteria: array,
    suggestions: array,
    mergeList: array,
    getMergeList: func,
    getMergeListSchool: func,
    getActions: func,
    postAction: func,
    getDefaultCriteria: func,
    mergeCriterion: func,
    skipCriterion: func,
  };

  state = {
    isLoading: false,
    currentSchoolsChildren: [],
    cancelTimeoutId: null,
  };

  componentDidMount() {
    this.props.getDefaultCriteria();
    this.props.getMergeList();
    this.props.getMergeListSchool();
    this.props.getActions({ name: 'MERGE_CRITERION' });
  }

  handleMerge = (item, newItem) => {
    const { props: { postAction, getActions, mergeCriterion }, state: { cancelTimeoutId } } = this;

    if (cancelTimeoutId !== null) {
      return false;
    }

    postAction({
      actionParams: {
        name: 'MERGE_CRITERION',
        isAdmin: true,
        data: {
          oldId: item._id,
          oldName: item.label,
          newId: newItem ? newItem._id : null,
          newName: newItem ? newItem.label : null,
        },
      },
      callback: () => { getActions({ name: 'MERGE_CRITERION' }); },
    });

    const id = setTimeout(() => {
      mergeCriterion({
        eventId: item.event,
        oldId: item._id,
        newId: newItem ? newItem._id : null,
      });

      this.setState({ cancelTimeoutId: null });
    }, 1000);

    this.setState({ cancelTimeoutId: id });

    return true;
  };

  handleSkip = (item) => {
    const { props: { postAction, getActions, skipCriterion }, state: { cancelTimeoutId } } = this;

    if (cancelTimeoutId !== null) {
      return false;
    }

    postAction({
      actionParams: {
        name: 'SKIP_CRITERION',
        isAdmin: true,
        data: {
          criterionId: item.parentId,
          event: item.event,
        },
      },
      callback: () => { getActions({ name: 'MERGE_CRITERION' }); },
    });

    const id = setTimeout(() => {
      skipCriterion({
        eventSlug: item.event,
        criterionId: item.parentId,
      });

      this.setState({ cancelTimeoutId: null });
    }, 1000);

    this.setState({ cancelTimeoutId: id });

    return true;
  };

  render() {
    const {
      props: { mergeList, defaultCriteria, suggestions },
      handleMerge, handleSkip,
    } = this;

    const defaultChoices = [];

    defaultCriteria.forEach((defaultCriterion) => {
      defaultCriterion._choices.forEach((defaultChoice) => {
        defaultChoices.push({
          parent: defaultCriterion.name,
          ...defaultChoice,
        });
      });
    });

    return (
      <div>
        <ReactTitle title="Admin Seekube - Gestion des écoles" />

        <div className={styles.sceneContainer}>
          <div className={styles.adminContainer}>
            {!isEmpty(mergeList) ? (
              <MergeNewItem
                defaultItems={defaultChoices}
                item={mergeList[0]}
                suggestions={suggestions}
                onMerge={handleMerge}
                onSkip={handleSkip}
              />
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  actions: actionSelectors.getActions,
  mergeList: criterionSelectors.getMergeList,
  defaultCriteria: criterionSelectors.getDefaultCriteria,
  mergeListTotal: criterionSelectors.getMergeListTotal,
  mergeListTotalSchool: organizationSelectors.getMergeListTotal,
  suggestions: criterionSelectors.getSuggestions,
});

const mapDispatchToProps = {
  getActions: actionActions.getActions,
  deleteAction: actionActions.deleteAction,
  postAction: actionActions.postAction,
  getDefaultCriteria: criterionActions.getDefaultCriteria,
  mergeCriterion: criterionActions.mergeCriterion,
  skipCriterion: criterionActions.skipCriterion,
  getMergeList: criterionActions.getMergeList,
  getMergeListSchool: organizationActions.getMergeList,
  push,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(toJS(MergeCriteriaScene));

