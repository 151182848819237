import React, { useState } from 'react';
import { object } from 'prop-types';
import { H5 } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { isEmpty, size } from 'lodash';
import classnames from 'classnames';
import { Links } from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/Profile/Cv/ExternalMedia/Links';
import { MediaList } from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/Profile/Cv/ExternalMedia/MediaList';
import {
  AddExternalMedia
} from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/Profile/Cv/ExternalMedia/AddExternalMedia';

const MAX_EXTERNAL_LINKS = 3;

export function ExternalMedia ({ form, user }) {
  const t = useFormatMessage();
  const [links, setLinks] = useState(user.links)
  const [medias, setMedias] = useState(user.mediaItems);
  const hasMedias = !isEmpty(medias);

  const handleAddLink = () => {
    if (size(links) < MAX_EXTERNAL_LINKS) {
      setLinks([...links, { url: undefined }]);
    }
  };

  const handleAddMedia = () => {
    if (size(medias) < MAX_EXTERNAL_LINKS) {
      const mediaItems = [...medias, { fileKey: undefined, fileName: undefined, title: undefined }];

      setMedias(mediaItems);
    }
  };

  const removeMedia = (order) => {
    const mediaItems = form.getFieldValue('mediaItems').filter((_, key) => order !== key);

    setMedias(mediaItems);
    form.setFieldsValue({ mediaItems });
  };

  const removeLink = (order) => {
    const newLinks = form.getFieldValue('links').filter((_, key) => order !== key);

    setLinks(newLinks);
    form.setFieldsValue({ links: newLinks });
  }

  return (
    <>
      <H5 className="mb-24 bold">{t({ id: 'external.element.pl' }, { count: 2 })}</H5>

      <div className={classnames({ 'mb-24': [hasMedias] })}>
        <Links form={form} user={user} links={links} handleRemove={removeLink} />
      </div>

      <MediaList
        form={form}
        user={user}
        handleRemove={removeMedia}
        cvIsRequired={false}
        medias={medias}
      />

      <div className="flex justify-end">
        <AddExternalMedia onAddMedia={handleAddMedia} onAddLink={handleAddLink} />
      </div>
    </>
  )
}

ExternalMedia.propTypes = {
  form: object,
  user: object,
}
