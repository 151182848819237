import React from 'react';
import ParticipantsView from '@/scenes/Event/scenes/Recruiter/scenes/Jobdating/container/ParticipantsView';

/**
 * RecruiterJobdatingCandidatesScene
 */
class RecruiterJobdatingApplicationsScene extends React.PureComponent { // eslint-disable-line
  /**
   * Render
   */
  render() {
    return (
      <ParticipantsView context="applications" />
    );
  }
}

export default RecruiterJobdatingApplicationsScene;
