import React from 'react';
import { func, array, string, object, bool, number, any } from 'prop-types';
import { injectIntl, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { isEmpty, map, without, startsWith, replace, size, compact, get } from 'lodash';
import classnames from 'classnames';
import { CONTEXTS_ADMIN_SELECTION, CONTEXTS_INTERVIEW } from '@/utils/global';

// components
import { Dropdown, Menu, Modal, Row } from 'antd';
import { Button, IconDownload, IconChevronDown } from '@seekube-tech/ui-kit';
import Icon from '@/components/Icon';
import DropdownOptions from '@/components/DropdownOptions';
import { Tooltip } from '@/components/Tooltip';
import { useFormatMessage } from 'react-intl-hooks';
import { AlertSelectedParticipant, AlertSelectedOffer, DownloadCvs } from './components';

import messages from './messages';
import styles from './styles.less';

const { Item: MenuItem } = Menu;

const GroupedActions = (props) => { // eslint-disable-line
  const { modalVisible, handleCloseModal, handleSyncIntercom, selectedParticipants, context, handleGroupedAction,
    isBtnPageParticipantsActive, onTogglePageParticipants, checkedParticipants, exponent,
    totalParticipants, pagination, intl, syncIntercomUsers, notSyncIntercomUsers, event, isBtnAllParticipantsChecked, handleCoreTargets } = props;

  const options = [];
  const getMoveActions = () => {
    const moveAvailable = startsWith(context, 'admin') ?
      without(['admintop', 'adminsave', 'adminrefuse'], context) : without(['top', 'save', 'refuse'], context);

    return (
      <div title={intl.formatMessage(messages.move)} icon={<Icon name="move" />}>
        {!['unconfirmed'].includes(context) && map(moveAvailable, (moveType, index) => (
          <a key={index} role="button" onClick={() => handleGroupedAction('move', moveType)} tabIndex={0} style={{ fontSize: '14px' }}>
            <FormattedMessage {...messages[`addTo_${replace(moveType, 'admin', '')}`]} />
          </a>
        ))}
      </div>
    );
  };

  const moveActions = getMoveActions();

  if (context === 'applications') {
    options.push((<a key={0} disabled={event.areInteractionsClosed} role="button" tabIndex={0} onClick={() => handleGroupedAction('accept')}><Icon name="acceptCircle" /> {intl.formatMessage(messages.appointmentGroupedConfirm)}</a>));
    options.push((<a key={1} role="button" tabIndex={0} onClick={() => handleGroupedAction('refuse')}><Icon name="refuse" /> {intl.formatMessage(messages.appointmentGroupedRefuse)}</a>));
  }

  if (['interview', 'top', 'refuse', 'applications', 'save', ...CONTEXTS_INTERVIEW, ...CONTEXTS_ADMIN_SELECTION].includes(context)) {
    if ((
      (['seekube', 'company'].includes(event.type) ||
        (['school'].includes(event.type) && (get(exponent, '_organization.activePlans') || []).includes('welcomePack')))
      || CONTEXTS_ADMIN_SELECTION.includes(context))) {
      options.push(<DownloadCvs
        context={context}
        participantIds={isBtnAllParticipantsChecked ? [] : checkedParticipants}
        event={event}
        totalParticipants={isBtnAllParticipantsChecked ? pagination.total : size(checkedParticipants)}
      />);
    }
  }

  if (['applications', 'sourcing', 'interview', 'top', 'save', 'refuse', 'transferred', 'refused', ...CONTEXTS_ADMIN_SELECTION].includes(context)) {
    options.push(moveActions);
  }
  if (context === 'offers') {
    const { customOptions } = props;

    options.push(<a key={1} role="button" tabIndex={0} onClick={customOptions.publishOnJobdating.onClick}><Icon name={customOptions.publishOnJobdating.icon} /> <FormattedMessage {...customOptions.publishOnJobdating.text} /> </a>);
    if (customOptions.draft.condition) { options.push(<a key={2} role="button" tabIndex={0} onClick={customOptions.draft.onClick}><Icon name={customOptions.draft.icon} /> <FormattedMessage {...customOptions.draft.text} /> </a>); }
    if (customOptions.delete.condition) { options.push(<a key={3} role="button" tabIndex={0} onClick={customOptions.delete.onClick}><Icon name={customOptions.delete.icon} /> <FormattedMessage {...customOptions.delete.text} /> </a>); }
  }

  if (CONTEXTS_ADMIN_SELECTION.includes(context) && event?.enableCoreTarget) {
    options.push((
      <a
        role="button"
        onClick={handleCoreTargets(selectedParticipants)}
        tabIndex={0}
        style={{ fontSize: '14px' }}
      >
        <Row type="flex" align="middle">
          <Icon name="core-target" />

          {intl.formatMessage({ id: 'recruiter.participantsList.coreTarget' })}
        </Row>
      </a>
    ))
  }

// const isInterviewTab = window.location.href.indexOf('interview') !== -1;

  const handleExport = (type) => {
    const baseUrl = window.location.href;

    switch (type) {
      case 'exportAllParticipants':
        window.open(`${process.env.FRONT_EXPORT_API_URL}/events/${event.slug}/export/candidates?apiKey=41a1289e-fee1-430e-a849-5b2b7ed23f1a`, '_blank');
        break;

      case 'exportPageParticipants':
        window.open(`${process.env.FRONT_EXPORT_API_URL}/events/${event.slug}/export/candidates?apiKey=41a1289e-fee1-430e-a849-5b2b7ed23f1a&context=${context}`, '_blank');
        break;
      case 'exportAllCvs':
      case 'exportContextCvs':
        break;
      case 'syncWithIntercom':
        handleSyncIntercom();
        break;
      default:
        window.open(`${baseUrl}/export/exponents`, '_blank');
    }
  };

  return (
    <div className={styles.groupedActionContainer}>
      <Modal
        visible={modalVisible}
        onCancel={() => handleCloseModal()}
        footer={null}
        maskClosable
        closable
        className="customConfirm"
        wrapClassName={styles.groupedActionsContainer}

      >
        <a role="button" tabIndex={0} className="modal-close" onClick={() => handleCloseModal()}>
          <Icon name="close" className="modal-close-icon" />
        </a>

        <h4 className="ant-confirm-title">
          <FormattedHTMLMessage {...messages.syncModalIntercomTitle} />
        </h4>

        <div className="ant-modal-body">
          <div className={styles.subTitle}><FormattedHTMLMessage {...messages.syncModalIntercomSubtitle} /> :</div>
          <div className={styles.successContainer}>
            <span role="img" aria-label="yeah" className="confirmation-emoticon">🎉</span> <FormattedHTMLMessage{...messages.syncModalIntercomSuccess} values={{ count: syncIntercomUsers.length }} />
          </div>
          <div className={styles.failureContainer}>
            <span role="img" aria-label="Sad">😢 </span> <FormattedHTMLMessage{...messages.syncModalIntercomFailure} values={{ count: notSyncIntercomUsers.length }} />
            :
            <ul>
              {map(notSyncIntercomUsers, (user, index) => user && <li key={`${user._id}${index}`}>* {user.fullName}</li>)}
            </ul>
          </div>
        </div>
      </Modal>
      <div className={styles.wrapper}>
        <div className={styles.groupedActionBtn}>
          { !isEmpty(options) && (
            <div className={classnames(styles.checkAllParticipants, isBtnPageParticipantsActive ? styles.selected : '')}>
              <Tooltip placement="bottom" title={<FormattedMessage {...messages.selectAll} />} color="dark" key={0}>
                <span tabIndex={0} role="button" onClick={() => onTogglePageParticipants()} className={styles.participantSelection} />
              </Tooltip>
            </div>
          )}
          { !isEmpty(options) && (
            <div className={styles.filter}>
              <div className={styles.selectBox}>
                <DropdownOptions disabled={checkedParticipants.length === 0} getPopupContainer={(triggerNode) => triggerNode.parentNode} label={intl.formatMessage(messages.groupedActions)} placement="bottomRight">
                  {options}
                </DropdownOptions>
              </div>
            </div>
          )}
        </div>
        {selectedParticipants.length > 0 ? (
          <div>{selectedParticipants.length} { context === 'offers' ? <FormattedMessage {...messages.offers} values={{ count: selectedParticipants.length }} /> : <FormattedMessage {...messages.candidates} values={{ count: selectedParticipants.length }} />}</div>
        ) : (
          <div className={classnames(styles.pagination)}>
            <FormattedHTMLMessage {...messages.groupedPagination} values={{ pagination: pagination.total <= totalParticipants ? pagination.total : totalParticipants, totalParticipants }} />
            { context === 'offers' ? intl.formatMessage(messages.offers, { count: totalParticipants }).toLowerCase() : (!isEmpty(messages[`results.${context}`]) ? intl.formatMessage(messages[`results.${context}`], { count: totalParticipants }) : intl.formatMessage(messages['results.all']))} {pagination.current > 1 ? `(${intl.formatMessage(messages.page)} ${pagination.current})` : ''}
          </div>
        )}


        {CONTEXTS_ADMIN_SELECTION.includes(context) ?
          (<div style={{ flexBasis: '33%' }}><ExportBtn context={context} handleDownload={handleExport} totalParticipants={totalParticipants} /></div>) : ''
        }

        {!CONTEXTS_ADMIN_SELECTION.includes(context) && <div style={{ flexBasis: '33%' }} />}

      </div>
      {
        context === 'offers' ?
          <AlertSelectedOffer {...props} /> :
          <AlertSelectedParticipant {...props} />
      }
    </div>
  );
};

const ExportBtn = ({ handleDownload, context, totalParticipants }) => {
  const t = useFormatMessage();

  const menuItemsData = compact([
    { downloadType: 'exportAllParticipants', text: <FormattedMessage {...messages.participantsExportList} /> },
    { downloadType: 'exportPageParticipants', text: <FormattedMessage {...messages.participantsExportContextList} /> },
    { downloadType: 'exportAllCvs', text: <DownloadCvs context="all"><FormattedMessage {...messages.participantsExportAllCvs} /></DownloadCvs> },
    { downloadType: 'exportContextCvs', text: <DownloadCvs context={context} totalParticipants={totalParticipants}><FormattedMessage {...messages.participantsExportContextCvs} /></DownloadCvs> },
    without(CONTEXTS_ADMIN_SELECTION, 'adminall').includes(context) && { downloadType: 'syncWithIntercom', text: <FormattedMessage {...messages.syncIntercomSubmit} /> },
  ]);
  return (
    <Dropdown
      getPopupContainer={(triggerNode) => triggerNode.parentNode}
      overlay={
        <Menu>
          {menuItemsData.map((menuItemData, i) =>
            <MenuItem key={i}><a role="button" tabIndex={0} onClick={() => handleDownload(menuItemData.downloadType)}>{menuItemData.text}</a></MenuItem>)}
        </Menu>
      }
      trigger={['click']}
      placement="bottomRight"
    >
      <Button
        onClick={() => {}}
        className={styles.exportAppointments}
        variant="outline"
        color="neutral"
        imageComponentLeft={<IconDownload size={16} />}
        imageComponentRight={<IconChevronDown size={16} />}
      >
        {t({ id: 'export' })}
      </Button>
    </Dropdown>
  );
};

ExportBtn.propTypes = {
  handleDownload: func,
  totalParticipants: number,
  context: string,
};

GroupedActions.defaultProps = {
  syncIntercomUsers: [],
  notSyncIntercomUsers: [],
  selectedParticipants: [],
  checkedParticipants: [],
  total: 0,
  pagination: { total: 0 },
};

GroupedActions.propTypes = {
  pagination: object,
  exponent: object,
  handleCloseModal: func,
  modalVisible: bool,
  isBtnAllParticipantsChecked: bool,
  totalParticipants: number,
  isBtnPageParticipantsActive: bool,
  onTogglePageParticipants: func,
  handleGroupedAction: func,
  handleSyncIntercom: func,
  selectedParticipants: array,
  checkedParticipants: array,
  context: string,
  sort: any,
  intl: object,
  syncIntercomUsers: array,
  notSyncIntercomUsers: array,
  handleSort: func,
  event: object,
  customOptions: object,
  handleCoreTargets: func,
};

export default injectIntl(GroupedActions);
