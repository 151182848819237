import React, { useEffect, useState } from 'react';
import { object, func, bool } from 'prop-types';
import { debounce, get } from 'lodash';
import { useFormatMessage } from 'react-intl-hooks';
import { getId } from '@/utils/global';
import { getInfinityFormatter } from '@/utils/text';

import { InputNumber } from '@/components/Input';

const LiveMaxColumn = ({ exponent, patchExponent, disabled = false }) => {
  const t = useFormatMessage();
  const { _event, _id, limits = {} } = exponent;
  const [value, setValue] = useState(get(limits, 'liveMax'))

  useEffect(() => {
    setValue(get(limits, 'liveMax'))
  }, [exponent])

  const onNumberChange = debounce((val) => {
    patchExponent({
      eventId: getId(_event),
      exponentId: _id,
      exponentParams: {
        limits: {
          ...limits,
          liveMax: val < 0 ? 1000 : val,
        },
      },
      notificationParams: {
        success: {
          message: t({ id: 'toaster.edit.success' }),
          kind: 'success',
          style: {
            bottom: '5%',
            top: 'inherit',
          },
        }
      }
    });
  }, 600);

  return (
    <div id={`LiveMax${_id}`}>
      <InputNumber disabled={disabled} onChange={onNumberChange} formatter={getInfinityFormatter} width={50} min={-1} value={value} customSize="small" />
    </div>
  );
};

LiveMaxColumn.propTypes = {
  exponent: object,
  patchExponent: func,
  disabled: bool,
};

export { LiveMaxColumn };
