import { useQuery } from 'react-query';
import request from '@/utils/request';

export function useGetParticipant({ eventId, userId, ...props }) {
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/participants/${userId}`;
  const queryFn = () => request(requestUrl, { method: 'GET' });

  const query = useQuery({
    queryKey: ['participant', eventId, userId],
    queryFn,
    refetchOnWindowFocus: false,
    ...props
  });
  return query;
}
