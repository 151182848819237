import { defineMessages } from 'react-intl';

export default defineMessages({
  notificationSuccessMemberAdd: {
    id: 'event.recruiter.preparation.team.notification.success.member.add',
  },
  notificationErrorMemberCreate: {
    id: 'event.recruiter.preparation.team.notification.error.member.add',
  },
  notificationSuccessMemberCreate: {
    id: 'event.recruiter.preparation.team.notification.success.member.create',
  },
  notificationSuccessMemberDelete: {
    id: 'event.recruiter.preparation.team.notification.success.member.delete',
  },
  notificationSuccessMemberUpdate: {
    id: 'event.recruiter.preparation.team.notification.success.member.update',
  },
  switchToMember: {
    id: 'event.recruiter.preparation.team.switch.to.member',
  },
  switchToManager: {
    id: 'event.recruiter.preparation.team.switch.to.manager',
  },
  teamTitle: {
    id: 'event.recruiter.preparation.team.title',
  },
  notificationSuccessCollaboratorUpdate: {
    id: 'event.recruiter.preparation.team.notification.success.collaborator.update',
  },
  teamInfosUpdate: {
    id: 'event.recruiter.preparation.team.infos.update',
  },
  pictureUrlChoose: {
    id: 'pictureUrl.choose',
  },
  pictureUrlHelp: {
    id: 'pictureUrl.help',
  },
  pictureUrlFormat: {
    id: 'pictureUrl.format',
  },
  formErrorRequired: {
    id: 'form.error.required',
  },
  formJobPositionPlaceholder: {
    id: 'form.jobPosition.placeholder',
  },
  formJobPositionLabel: {
    id: 'form.jobPosition.label',
  },
  firstName: {
    id: 'firstName',
  },
  lastName: {
    id: 'lastName',
  },
  email: {
    id: 'email',
  },
  aboutUser: {
    id: 'about.user',
  },
  optional: {
    id: 'optional',
  },
  save: {
    id: 'save',
  },
  delete: {
    id: 'delete',
  },
  edit: {
    id: 'edit',
  },
  cancel: {
    id: 'cancel',
  },
  btnConfirm: {
    id: 'btn.confirm',
  },
  deleteRecruiterModalConfirm: {
    id: 'event.recruiter.preparation.team.delete.recruiter.modal.confirm',
  },
  numberOfAuthorizedMembers: {
    id: 'event.recruiter.preparation.team.authorized.members',
  },
});
