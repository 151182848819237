import { useMutation, useQueryClient } from 'react-query';
import request from '@/utils/request';
import { map } from 'lodash';

export function useUpdateOffer({ organizationId, eventSlug, onSuccess = () => {}, }) {
  const queryClient = useQueryClient();

  const mutationFn = ({ offerId, ...offerParams }) => {
    const requestUrl = `${process.env.FRONT_API_URL}/events/${eventSlug}/offers/${offerId}`;

    return (
      request(requestUrl, {
        method: 'PATCH',
        body: JSON.stringify(offerParams),
      })
    )
  }

  return useMutation({
    mutationFn,
    onSuccess: async (offer) => {
      const deals = queryClient.getQueryData(['exponentOffers', organizationId]);
      const newDocs = map(deals.docs, (prevOffers) => prevOffers._id === offer._id ? offer : prevOffers);
      const newDeals = { ...deals, docs: newDocs };

      queryClient.setQueryData(['exponentOffers', organizationId], newDeals);onSuccess();
    },
    refetchOnWindowFocus: false,
  });
}
