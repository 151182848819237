import React from 'react';
import { node, object } from 'prop-types';
import Wrapper from '@/components/Wrapper';
import { isEmpty } from 'lodash';
import { JobdatingsDiscovery } from '@/scenes/Events/scenes/components/JobdatingsDiscovery';
import CandidateExplorer from '@/scenes/Events/scenes/Explore/Candidate';

const Explore = ({
  children,
  authUser
}) => {
  const isCandidate = isEmpty(authUser?._currentOrganization);
  
  return (
    <Wrapper type="scene">
      {children}
      {isCandidate ? <CandidateExplorer authUser={authUser} /> : <JobdatingsDiscovery authUser={authUser} /> }
    </Wrapper>
  )
}

Explore.propTypes = {
  children: node,
  authUser: object
}

export { Explore };