import React from 'react';
import { object } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { Bloc } from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/components/Bloc';
import Wrapper from '@/components/Wrapper';
import styles from './styles.less';
import { Button } from '@seekube-tech/ui-kit';

const LoginAdmin = ({
}) => {
  const t = useFormatMessage();

  return (
    <Wrapper size="large">
      <div className={styles.container}>
        <Bloc>
          <div className={styles.blocContent}>
            <h1>Connectez-vous avec votre compte Hellowork</h1>
            <div className={styles.btnContainer}>
              <a href={`${process.env.FRONT_API_URL}/users/oauth/hellowork`}>
                <Button>Connectez vous</Button>
              </a>
            </div>
          </div>
        </Bloc>
      </div>
    </Wrapper>
  )
}

export default LoginAdmin;