import React, { useEffect } from 'react';
import { object, func } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { replace } from 'connected-react-router';
import { createStructuredSelector } from 'reselect';
import { toJS } from '@/utils';
import { authSelectors } from '@/store/auth';
import { eventSelectors } from '@/store/event';
import { criterionSelectors } from '@/store/criterion';
import ParticipationMode from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/ParticipationMode';
import ProfileCheck from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/ProfileCheck';
import Profile from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/Profile';
import Matching from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/Matching';
import {
  EventSummary
} from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/components/EventSummary/EventSummary';
import SignupDev from './scenes/SignupDev';
import SigninDev from './scenes/SigninDev';
import { trackPageDisplayLoginSignup } from '@/utils/analytics/trackers/category/eventOnboarding';

const CandidatePreparationOnboardingScene = (props) => {
  const { match, authUser, replace } = props;

  useEffect(() => {
    trackPageDisplayLoginSignup(props.event);
  }, []);

  const redirectIfNeeded = () => {
    const redirection = false
    if (match.isExact) {
      if (!authUser) {
        return `${match.url}/signin`;
      }
      if (!authUser.isActive) {
        return `${match.url}/profile`;
      }
      if (authUser.isActive) {
        return `${match.url}/profile-check`;
      }

    }
    return redirection
  }

  if (redirectIfNeeded()) replace(redirectIfNeeded())

  return (
    <div>
      <EventSummary 
        eventName={props.event?.name} 
        organizations={props.event?.planners?._organizations} 
      />
      {!authUser &&
        <Switch>
          <Route path={`${match.path}/signin`} render={(routeProp) => <SigninDev {...routeProp} {...props} />} />
          <Route path={`${match.path}/signup`} render={(routeProp) => <SignupDev {...routeProp} {...props} />} />
        </Switch>
      }

      {authUser &&
        <Switch>
          <Route path={`${match.path}/profile`} render={(routeProp) => <Profile {...routeProp} {...props} />} />
          <Route path={`${match.path}/matching`} render={(routeProp) => <Matching {...routeProp} {...props} />} />
          <Route path={`${match.path}/participation-mode`} render={(routeProp) => <ParticipationMode {...routeProp} {...props} />} />
          <Route
            path={`${match.path}/profile-check`}
            render={(routeProp) => <ProfileCheck {...routeProp} {...props} />}
          />
        </Switch>
      }
    </div>
  );
}

CandidatePreparationOnboardingScene.propTypes = {
  authUser: object,
  match: object,
  event: object,
  replace: func,
};

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser,
  event: eventSelectors.getCurrentEvent,
  defaultCriteria: criterionSelectors.getDefaultCriteria,
});

const mapDispatchToProps = {
  replace,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(toJS(CandidatePreparationOnboardingScene));
