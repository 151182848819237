import React from 'react';
import { object, bool } from 'prop-types';
import { Checkbox } from '@seekube-tech/ui-kit';
import { isSlotBooked, isSlotPassed } from '@/features/agenda/recruiter/helpers';

export const TimeslotDeletionCheckbox = ({ event, currentEvent, isChecked, isDeleteModeActive }) => {
  if (!isDeleteModeActive && (isSlotBooked(event) || isSlotPassed(currentEvent, event))) return null;

  return (
    <Checkbox
      className="absolute top-6 right-8"
      type="error"
      checked={isChecked}
    />
  )
}

TimeslotDeletionCheckbox.propTypes = {
  event: object,
  currentEvent: object,
  isChecked: bool,
  isDeleteModeActive: object,
}
