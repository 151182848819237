import { useQuery } from 'react-query';
import request from '@/utils/request';

export function useGetExponentsLanding({ eventId }) {
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/exponents/landing`;

  const queryFn = () => request(requestUrl, { method: 'GET' });

  const query = useQuery({
    queryKey: ['exponents', eventId, 'landing'],
    queryFn,
    initialData: { docs: [] },
    refetchOnWindowFocus: false,
  });

  return query;
}
