import { includes, isArray, isEmpty } from 'lodash';
import classnames from 'classnames';
import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { Form } from 'antd';
import { array, func, object, string, boolean } from 'prop-types';
import { sortByOccurence } from '@/scenes/Event/scenes/Candidate/scenes/JobDating/components/SearchEngine/helpers';
import styles from '@/scenes/Event/components/StaticOffers/components/Filters/styles.less';
import Selector from '@/components/Form/Selector';
const FormItem = Form.Item;

export const SectorSelector = ({
  isMobile,
  facetKey,
  matching,
  updateSearch,
  updateMatching,
  search,
  sectorsOptions,
  context,
  facetsExponents,
  facets,
}) => {
  const t = useFormatMessage();

  if (isEmpty(sectorsOptions)) return '';

  const options = sectorsOptions.map((o) => ({
    value: o._id,
    label: t({ id: `sector[${o.key}]` }),
    badgeCount: getFacetSector(o._id, context)
  }))

  const filtredOptions = options.filter((o) => o !== undefined);

  let sectors = matching.filters.flat().filter((v) => filtredOptions.find((c) => c.value === v));

  if (!isEmpty(search) && !isEmpty(search.sector)) {
    if (isArray(search.sector)) {
      sectors = search.sector;
    } else {
      sectors = search.sector.split(',');
    }
  }

  return (
    <FormItem className={classnames(styles.formItem, context === 'exponent' ? styles.formItemBordered : '')} style={{ minWidth: '140px', width: '140px' }}>
      <Selector
        position={includes(['offer', 'owner'], context) ? 'left' : 'center'}
        isMobile={!!isMobile}
        label={t({ id: 'sectors' })}
        noLabel={t({ id: 'searchEngine.noLabel' })}
        options={sortByOccurence(filtredOptions)}
        values={sectors}
        onChange={(e) => {
          if (context === 'exponent') {
            updateSearch('sector', e, facetKey, context);
          } else {
            updateSearch('sector', e, facetKey, context);
            updateMatching(matching.filters.flat().filter((v) => !filtredOptions.find((c) => c.value === v)), e, facetKey);
          }
        }}
      />
    </FormItem>
  );

  function getFacetSector(value, context) {
    const items = context === 'exponent' ? facetsExponents : facets;
    const facetSectorId = items?.['organizationProfile.sectors._id'];

    return (facetSectorId && typeof facetSectorId === 'object') ?
      facetSectorId[value] : 0;
  };
}

SectorSelector.propTypes = {
  isMobile: boolean,
  facetKey: string,
  sectorsOptions: array,
  matching: object,
  updateSearch: func,
  updateMatching: func,
  search: object,
  facetsExponents: object,
  context: object,
  facets: object
}