import React, { useState, useEffect } from 'react';
import { map, filter, debounce, get } from 'lodash';
import { object, func, number, array } from 'prop-types';

import { Col, Row } from 'antd';
import { Button } from '@seekube-tech/ui-kit';
import S3Upload from '@/containers/S3Upload';
import Avatar from '@/components/Avatar';
import Icon from '@/components/Icon';
import { If } from '@/components/If';
import InputMaterial from '@/components/Input/InputMaterial';
import InlineEditor from '@/components/InlineEditor';

import Draggable from '@/components/Draggable';
import styles from './styles.less';

const Gallery = ({ event, contentIndex, onChange, onDelete }) => {
  const [isAddVideoVisible, setAddVideoVisible] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  const elementsPath = `_contents.${contentIndex}.content.elements`;
  const elements = get(event, elementsPath) || [];

  const handleAction = (actionType, val, partnerIndex) => {
    switch (actionType) {
      case 'remove': onChange(['_contents', contentIndex, 'content', 'elements'], filter(elements, (partner, index) => index !== partnerIndex)); break;
      case 'setName': onChange(['_contents', contentIndex, 'content', 'elements'], map(elements, (element, index) => index === partnerIndex ? { ...element, name: val } : element)); break;
      case 'setUrl': onChange(['_contents', contentIndex, 'content', 'elements'], map(elements, (element, index) => index === partnerIndex ? { ...element, url: val } : element)); break;
      case 'setHref': onChange(['_contents', contentIndex, 'content', 'elements'], map(elements, (element, index) => index === partnerIndex ? { ...element, href: val } : element)); break;
      case 'onSort':
        onChange(['_contents', contentIndex, 'content', 'elements'],
        val
          .map(({ contentObject }) => contentObject.props.element)); break;
      default: break;
    }
  };

  return (
    <div className={styles.partners}>
      <p>Photo(s) & vidéo(s):</p>
      <List elements={[...elements]} handleAction={handleAction} />
      <Row gutter={20}>
        <Col span={6}>
          <S3Upload
            uploadType="event"
            multi
            onChange={
              (files) => {
                onChange(['_contents', contentIndex, 'content', 'elements'],
                  [...elements, ...map(files, (file) => ({ name: file.name, type: 'image', href: null, url: file.url }))]);
              }
            }
          >
            <Button variant="tonal">Photo</Button>
          </S3Upload>
        </Col>
        <Col span={6}>
          <Button variant="tonal" onClick={() => setAddVideoVisible(true)}>Video</Button>
        </Col>
      </Row>
      <If condition={isAddVideoVisible}>
        <div className={styles.inputLink}>
          <InputMaterial
            placeholder="Ajouter un lien"
            addOnIcon="link"
            defaultValue=""
            onChange={(val) => setVideoUrl(val.target.value)}
          />
        </div>
        <Button
          onClick={() => onChange(['_contents', contentIndex, 'content', 'elements'], [...elements, { url: videoUrl, type: 'video', height: '200', name: `video ${elements.length + 1}` }])}
        >
          Ajouter une video
        </Button>
      </If>
      <div>
        <Button type="danger" onClick={() => onDelete(contentIndex)}>Supprimer le bloc</Button>
      </div>
    </div>
  );
};

const List = ({ elements = [], handleAction }) => {
  const items = map(elements, (element, key) => ({
    contentObject: <Item key={key} index={key} element={element} />,
    content: <Item action={(type, val) => handleAction(type, val, key)} element={element} />,
  }));

  return <Draggable
    itemType='gallery'
    localSort
    items={items}
    onDragEnd={(localItems) => handleAction('onSort', localItems)}
  />
}

const debounceFunc = debounce((fct) => fct(), 1000);

const isImage = (type) => type === 'image';

const Item = ({ element, action }) => {
  const [isAddLinkVisible, setLinkVisible] = useState(false);
  const [linkValue, setLinkValue] = useState(isImage(element.type) ? element.href : element.url);
  useEffect(() => {
    debounceFunc(() => {
      if (linkValue !== (isImage(element.type) ? element.href : element.url)) {
        action(isImage(element.type) ? 'setHref' : 'setUrl', linkValue);
      }
    });
  }, [linkValue]);

  return (
    <div className={styles.partner}>
      <div className={styles.main}>
        <Icon name="menu-burger" className={styles.menuIcon} />
        <div className={styles.partnersContent}>
          <div className={styles.partnersLogoAndName}>
            {element.type === 'image' ?
              <Avatar className={styles.logo} size={40} src={element.url} type="rounded" imageFit="cover" />
              : <img alt={element.name} src="https://cdn-app-assets.seekube.com/public/player.png" />
            }
            <span title={element.name}>
              <InlineEditor
                editorEnabled
                value={element.name}
                onChange={(val) => debounceFunc(() => action('setName', val))}
              />
            </span>
          </div>
          <div className={styles.actions}>
            <a tabIndex={0} role="button" onClick={() => setLinkVisible(!isAddLinkVisible)} className={isAddLinkVisible ? styles.iconActive : ''}><Icon name="link" /></a>
            <a tabIndex={0} role="button" onClick={() => action('remove')}><Icon name="trash" /></a>
          </div>
        </div>
      </div>
      <If condition={isAddLinkVisible}>
        <div className={styles.inputLink}>
          <InputMaterial
            placeholder="Ajouter un lien"
            addOnIcon="link"
            value={linkValue}
            onChange={(val) => setLinkValue(val.target.value)}
          />
        </div>
      </If>
    </div>
  );
};

Gallery.propTypes = {
  event: object,
  contentIndex: number,
  onChange: func,
  onDelete: func,
};

List.propTypes = {
  elements: array,
  handleAction: func,
};

Item.propTypes = {
  element: object,
  action: func,
};

export default Gallery;
