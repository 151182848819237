import React from 'react';
import { func, bool } from 'prop-types';
import ModalV2 from '@/components/ModalV2';
import { Email, NewPassword } from '@/scenes/ResetPassword/components/ResetPasswordModal/components';
import { queryStringToObject } from '@/utils/url';
import './styles.less';

const ResetPasswordModal = ({ modalVisible, handleCloseModal }) => {
  const { token, firstname } = queryStringToObject(document.location.search);

  return (
    <ModalV2
      template="default"
      visible={modalVisible}
      onClose={() => handleCloseModal(null)}
      className="resetPasswordModal"
    >
      <div>
        {
          firstname && token ?
          <NewPassword />
        : <Email />
        }
      </div>
    </ModalV2>
  );
}

ResetPasswordModal.propTypes = {
  handleCloseModal: func,
  modalVisible: bool,
}


export default ResetPasswordModal;
