import React from 'react';
import { bool, func } from 'prop-types';
import { Select } from 'antd';
import { useFormatMessage } from 'react-intl-hooks';
import Modal from '@/components/Modal';

const { Option } = Select;

const contents = [
  { value: 'numbers', label: 'Chiffres', fr: 'Chiffres', en: 'Numbers' },
  { value: 'organizations', label: 'Les entreprises', fr: 'Les entreprises', en: 'Companies' },
  { value: 'roadmap', label: 'Dates clés', fr: 'Dates clés', en: 'Roadmap', text: '' },
  { value: 'separator', label: 'Separateur', fr: '', en: '' },
  { value: 'text', label: 'Texte', fr: 'Text', en: 'Text' },
  { value: 'partners', label: 'Partenaires', fr: 'Partenaires', en: 'Partners', content: { elements: [] } },
  { value: 'gallery', label: 'Médias', fr: 'Médias', en: 'Media', content: { elements: [] } },
];

const AddNewContentModal = ({ isOpen, onCancel, onOk }) => {
  const t = useFormatMessage();
  const [value, setValue] = React.useState([]);


  const returnSelectOptions = () => contents.map((content) => (
    <Option key={content.value} value={content.value}>
      { content.label }
    </Option>
  ));

  const handleOnChange = (value) => {
    setValue(value);
  };

  const handleOnOk = () => {
    const item = contents.filter((i) => i.value === value.key);

    if (item) {
      onOk(item[0]);
    }
  };



    return (
      <Modal
        title={t({ id: 'event.edit.addNewContent.addBlock' })}
        visible={isOpen}
        onOk={handleOnOk}
        cancelText={t({ id: 'cancel' })}
        onCancel={onCancel}
      >
        <Select
          showSearch
          labelInValue
          value={value}
          style={{ width: '100%' }}
          placeholder={t({ id: 'event.edit.addNewContent.addBlock.placeholder' })}
          onChange={handleOnChange}
        >
          {returnSelectOptions()}
        </Select>
      </Modal>
    );
}

AddNewContentModal.propTypes = {
  isOpen: bool,
  onCancel: func,
  onOk: func,
};

export default AddNewContentModal;
