import React from 'react';
import { node, func, any } from 'prop-types';
import classnames from 'classnames';
import styles from '@/scenes/Settings/MenuSettings/styles.less';


export const SubMenuItem = ({ children, onClick, className }) => (
  <a
    className={classnames(styles.subMenuItem, className)}
    onClick={onClick}
    role="button"
    tabIndex={0}
  >
    {children}
  </a>
)


SubMenuItem.propTypes = {
  children: node,
  onClick: func,
  className: any,
}
