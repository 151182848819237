import { defineMessages } from 'react-intl';

export default defineMessages({
  exponentTitle: {
    id: 'event.exponent.title',
  },
  exponentType1: {
    id: 'event.type.company',
  },
  exponentType2: {
    id: 'event.type.school',
  },
  exponentType3: {
    id: 'event.type.training',
  },
});
