import { useInfiniteQuery } from 'react-query';
import request from '@/utils/request';

const defaultSearch = {
  limit: 2,
  offset: 0,
  keywords: '',
  page: 1
}

export function useInfiniteGetOrganizationProfilesPreviewsInformal1to1s({ organizationId, eventId, limit = 15 }) {
  const queryFn = (pageParams) => {
    const { pageParam: page } = pageParams;
    const params = `?eventId=${eventId}&limit=${limit}&page=${page}`;
    const requestUrl = `${process.env.FRONT_API_URL}/organizations/${organizationId}/profiles/previews/informal1to1${params}`;

    return request(requestUrl,
      { method: 'GET' });
  };

  const query = useInfiniteQuery({
    queryKey: ['organizationProfilesPreviewsInformal1to1s', eventId, organizationId],
    keepPreviousData: true,
    getNextPageParam: (lastPage) => {
      if ((lastPage.page * lastPage.limit) <= lastPage.total) {
        return (lastPage.page + 1);
      }
    },
    select: (data) => ({
      pages: [...data.pages],
      pageParams: [...data.pageParams],
    }),
    queryFn,
    enabled: !!organizationId,
  });
  return query;
}
