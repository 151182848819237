import { defineMessages } from 'react-intl';

export default defineMessages({
  level0: {
    id: 'language.level.0',
  },
  level1: {
    id: 'language.level.1',
  },
  level2: {
    id: 'language.level.2',
  },
  level3: {
    id: 'language.level.3',
  },
  level4: {
    id: 'language.level.4',
  },
  experience0: {
    id: 'experience.level[0]',
  },
  experience1: {
    id: 'experience.level[1]',
  },
  experience2: {
    id: 'experience.level[2]',
  },
  experience3: {
    id: 'experience.level[3]',
  },
  experience4: {
    id: 'experience.level[4]',
  },
  searchDate: {
    id: 'search.date',
  },
  searchJobs: {
    id: 'search.jobs',
  },
  availableToday: {
    id: 'available.today',
  },
  searchDuration: {
    id: 'search.duration',
  },
  searchAvailability: {
    id: 'search.availability',
  },
  as: {
    id: 'as',
  },
});
