import { useInfiniteQuery } from 'react-query';
import { join } from 'lodash';
import request from '@/utils/request';

export function useInfiniteGetLandingOffers(
  {
    eventId = null,
    limit = 7,
    keywords = '',
    filtersIds = [],
  }) {
  const filtersIdsString = encodeURIComponent(join(filtersIds, ','));

  const queryFn = (pageParams) => {
    const { pageParam: page } = pageParams;
    const params = `?eventId=${eventId}&limit=${limit}&page=${page}&keywords=${keywords}&filtersIds=${filtersIdsString}`;
    const requestUrl = `${process.env.FRONT_API_URL}/landing/offers${params}`;

    return request(requestUrl,
      { method: 'GET' });
  };

  const query = useInfiniteQuery({
    queryKey: ['organizationLandingOffers', eventId, keywords, filtersIdsString],
    keepPreviousData: true,
    getNextPageParam: (lastPage) => {
      if ((lastPage.page * lastPage.limit) <= lastPage.total) {
        return (lastPage.page + 1);
      }
    },
    select: (data) => ({
      pages: [...data.pages],
      pageParams: [...data.pageParams],
    }),
    queryFn,
    refetchOnWindowFocus: false,
  });

  return query;
}
