import { defineMessages } from 'react-intl';

export default defineMessages({
  syncCalendarAlertLink: {
    id: 'event.recruiter.syncCalendar.alert.link',
  },
  syncCalendarAlert: {
    id: 'event.recruiter.syncCalendar.alert',
  },
  syncCalendarExpiredAlertLink: {
    id: 'event.recruiter.syncCalendar.expired.alert.link',
  },
  syncCalendarExpiredAlert: {
    id: 'event.recruiter.syncCalendar.expired.alert',
  },
});
