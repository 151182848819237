import React from 'react';
import { oneOf, array, object, string, bool } from 'prop-types';
import moment from 'moment';
import { head } from 'lodash';
import { Avatar } from '@seekube-tech/ui';

import { useFormatMessage } from 'react-intl-hooks';

import { TopicTag } from './TopicTag';

import styles from './styles.less';


const CardOwner = ({
  topics,
  createdAt,
  _owner: { firstName, lastName },
  _organization: { name, profile, isPartner },
  context,
  filterByTopicEnable,
  partner,
  locale,
  pictureUrl,
}) => {
  const t = useFormatMessage();

  return (
    <div className={styles.ownerLogo}>
      <div className={styles.avatar}>
        <Avatar
          variant="business"
          src={pictureUrl || profile?.pictureUrl}
          badge={isPartner ? partner?.icon : null}
          color={isPartner ? partner?.color : 'default'}
          tooltip={partner?.[locale === 'fr' ? 'label_fr' : 'label_en']}
        />
      </div>
      <div className={styles.ownerName}>
        <TopicTag topic={head(topics)} context={context} filterByTopicEnable={filterByTopicEnable} />

        {['owner', 'candidate'].includes(context) &&
        <p className={styles.organizedBy}>
          {t({ id: 'organizedBy' }, { name })}
        </p>}

        {['recruiter', 'client'].includes(context) &&
          <p>
            {t({ id: 'createdAtAndBy' }, { date: moment(createdAt).format('DD.MM.YYYY'), user: `${firstName} ${lastName}` })}
          </p>
        }
      </div>
    </div>
  );
};

CardOwner.propTypes = {
  topics: array,
  createdAt: string,
  _owner: object,
  _organization: object,
  filterByTopicEnable: bool,
  context: oneOf(['owner', 'client', 'nonClient', 'candidate']),
  partner: object,
  locale: string,
};

export { CardOwner };
