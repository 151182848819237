import { number } from 'prop-types';
import { IconSearch } from '@seekube-tech/ui-kit';
import * as React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

function ParticipantsNumber({ countParticipants }) {
  return countParticipants > 10 ?
    <span key="participantsNumber">
      <IconSearch size={16} />
      <strong>{`${countParticipants} `}</strong>
      <FormattedHTMLMessage values={{ count: countParticipants }} id="candidates" />
    </span> : null
}

ParticipantsNumber.propTypes = {
  countParticipants: number
}

export default ParticipantsNumber;
