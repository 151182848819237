export const logo = 'https://www.seekube.com/uploads/company_profile/207/93157b3c01dd17c283b2f4d45ef3b22247a8ea4c-original.jpeg';
const _organization = {
  name: 'Orange',
  profile: {
    pictureUrl: logo,
  },
};

export const participant = {
  interactions: [],
};

export const offers = [
  {
    title: 'Développeur Symfony passionné (H/F)',
    contractType: 'CDD, Alternant',
    dateBeginContract: 'Jan. 2017',
    lengthContract: 6,
    location: 'Paris, New York, Berlin',
    criteria: ['Bac +4', 'Permis de conduire', 'Promotion 2017'],
    fullName: 'Selena Gromez',
    _user: {
      fullName: 'James Hetfield',
      pictureUrl: 'https://cdn-app-assets.seekube.com/user/5a26a77b6d89e0a2e6d540c7/RDY2i5lwT2cfHyyL.png',
    },
    planned: true,
    position: 'Product Manager',
    _organization,
    highlightCriteria: ['Relation Client', 'Product Design'],
    pdfUrl: 'http://www.lmm.jussieu.fr/~sagaut/epistemologie-v14.pdf',
    isMock: true,
  },
  {
    title: 'Product Manager',
    contractType: 'CDD, Alternant',
    dateBeginContract: 'Jan. 2017',
    lengthContract: 6,
    location: 'Paris, New York, Berlin',
    criteria: ['Bac +4', 'Permis de conduire', 'Promotion 2017'],
    fullName: 'Selena Gromez',
    _user: {
      fullName: 'Bruce Dickinson',
      pictureUrl: 'https://cdn-app-assets.seekube.com/user/5a26a77b6d89e0a2e6d540c7/RDY2i5lwT2cfHyyL.png',
    },
    planned: false,
    position: 'Product Manager',
    _organization,
    highlightCriteria: ['Relation Client', 'Product Design'],
    pdfUrl: 'http://www.lmm.jussieu.fr/~sagaut/epistemologie-v14.pdf',
    isMock: true,
  },
  {
    ad: true,
  },
  {
    title: 'Product Manager',
    contractType: 'CDD, Alternant',
    dateBeginContract: 'Jan. 2017',
    lengthContract: 6,
    location: 'Paris, New York, Berlin',
    criteria: ['Bac +4', 'Permis de conduire', 'Promotion 2017'],
    fullName: 'Selena Gromez',
    _user: {
      fullName: 'John Patrick Mason',
      pictureUrl: '',
    },
    planned: false,
    position: 'Product Manager',
    _organization,
    highlightCriteria: ['Relation Client', 'Product Design'],
    pdfUrl: 'http://www.lmm.jussieu.fr/~sagaut/epistemologie-v14.pdf',
    isMock: true,
  },
  {
    title: 'Product Manager',
    logo,
    contractType: 'CDD, Alternant',
    dateBeginContract: 'Jan. 2017',
    lengthContract: 6,
    location: 'Paris, New York, Berlin',
    criteria: ['Bac +4', 'Permis de conduire', 'Promotion 2017'],
    fullName: 'Selena Gromez',
    _user: {
      fullName: 'Jean Patrick Fitzpatrick',
      pictureUrl: 'https://cdn-app-assets.seekube.com/user/5a26a77b6d89e0a2e6d540c7/RDY2i5lwT2cfHyyL.png',
    },
    planned: false,
    proposal: true,
    position: 'Product Manager',
    _organization,
    highlightCriteria: ['Relation Client', 'Product Design'],
    pdfUrl: 'http://www.lmm.jussieu.fr/~sagaut/epistemologie-v14.pdf',
    isMock: true,
  },
];
