import { FormattedHTMLMessage } from 'react-intl';
import { Form, Radio } from 'antd';
import React from 'react';
import { func, object } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { Body1 } from '@seekube-tech/ui-kit';
const FormItem = Form.Item;
const RadioGroup = Radio.Group;

export const NotifyPreparationOverview = ({ getFieldDecorator, notifs = {} }) => {
  const t = useFormatMessage();

  return (
    <>
      <Body1 className="mb-10 text-neutral-400"><FormattedHTMLMessage id="profile.settings.notifications.preparation.overview.label" /></Body1>
      <FormItem>
        {getFieldDecorator('preparation_overview_frequency', {
          initialValue: notifs.preparation_overview_frequency,
        })(
          <RadioGroup>
            <Radio value="weekly" key={1}>{t({ id: 'weekly' })}</Radio>
            <Radio value="bimonthly" key={2}>{t({ id: 'bimonthly' })}</Radio>
            <Radio value="monthly" key={3}>{t({ id: 'monthly' })}</Radio>
            <Radio value="never" key={4}>{t({ id: 'never' })}</Radio>
          </RadioGroup>

        )}
      </FormItem>
    </>
  );
};

NotifyPreparationOverview.propTypes = {
  notifs: object,
  getFieldDecorator: func,
};
