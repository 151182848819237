import React from 'react';
import { func } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks'
import { Typography, Grid } from '@seekube-tech/ui'
import { Button } from '@seekube-tech/ui-kit'
import { push } from 'connected-react-router';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';

import Icon from '@/components/Icon'

import useStyles from './styles';

function DiscoverOffersCard({ push }) {
  /* Vars */

  const t = useFormatMessage();
  const styles = useStyles();

  /* Functions */

  /**
   * Redirect to offer page
   */
  const handleClickDiscoverOffer = () => {
    push('jobs');
  };

  /* Render */

  return (
    <Grid container direction="column" alignItems="center" className={styles.root}>
      <Grid item>
        <Icon name="blank-state-rocket" className={styles.icon} />
      </Grid>

      <Grid item>
        <Typography variant="h5" className={styles.title} align="center">
          {t({ id: 'oneToOne.discoverOffersCard.title' })}
        </Typography>
      </Grid>

      <Grid item>
        <Typography variant="body2" className={styles.subtitle} align="center">
          {t({ id: 'oneToOne.discoverOffersCard.subtitle' })}
        </Typography>
      </Grid>

      <Grid item className={styles.fullWidth}  style={{ marginTop: '48px' }}>
        <Button onClick={handleClickDiscoverOffer} fullWidth className="w-full">
          {t({ id: 'discover.offers' })}
        </Button>
      </Grid>
    </Grid>
  )
}

DiscoverOffersCard.propTypes = {
  push: func.isRequired,
};

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = {
  push,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(DiscoverOffersCard);
