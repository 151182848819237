import { isEmpty } from 'lodash';

const getUserRole = (authUser, authUserConnectedAs) => {
  const connectedUser = !isEmpty(authUserConnectedAs) ? authUserConnectedAs : authUser;

  if (isEmpty(connectedUser)) {
    return 'candidate';
  }

  return !isEmpty(connectedUser?._currentOrganization) ? 'recruiter' : 'candidate';
};

export { getUserRole };
