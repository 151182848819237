import { defineMessages } from 'react-intl';

export default defineMessages({
  recruiterInvitation: {
    id: 'recruiter.invitation.reminder',
  },
  switchToRecruiter: {
    id: 'switch.to.recruiter',
  },
  switchToManager: {
    id: 'switch.to.manager',
  },
  deleteTeammate: {
    id: 'delete.teammate',
  },
  deleteTeammateFromJd: {
    id: 'event.owner.candidate.tooltip.removeFromJd',
  },
  searchExponent: {
    id: 'event.owner.exponents.search',
  },
  searchByJobdating: {
    id: 'admin.organizations.jobdating.search.input.placeholder',
  },
  addExponent: {
    id: 'event.owner.exponents.add',
  },
  addCompany: {
    id: 'event.owner.company.add',
  },
  addJobdating: {
    id: 'event.owner.jobdating.add',
  },
  exponentTitle: {
    id: 'event.owner.exponents.title',
  },
  companiesTitle: {
    id: 'event.owner.companies.title',
  },
  exponentByOrganizationTitle: {
    id: 'event.owner.exponents.byOrganization.title',
  },
  deleteRecruiterPopover: {
    id: 'event.owner.exponents.popover.recruiter',
  },
  deleteExponentPopover: {
    id: 'event.owner.exponents.popover.exponent',
  },
  infoRecruiter: {
    id: 'event.owner.exponents.dropdown.recruiter.info',
  },
  infoCompany: {
    id: 'event.owner.exponents.dropdown.company.info',
  },
  previewStand: {
    id: 'event.owner.exponents.dropdown.company.preview',
  },
  modifyLogo: {
    id: 'event.owner.exponents.dropdown.modify.logo',
  },
  remove: {
    id: 'delete',
  },
  manager: {
    id: 'event.recruiter.preparation.team.manager',
  },
  recruiter: {
    id: 'event.recruiter.preparation.team.recruiter',
  },
  blankState: {
    id: 'event.owner.exponents.blankState',
  },
  blankStatePreventAddingExponent: {
    id: 'event.owner.exponents.blankState.prevent.from.adding.exponent',
  },
  blankStatePreventAddingExponentBtn: {
    id: 'event.owner.exponents.blankState.prevent.from.adding.exponent.btn',
  },
  cancel: {
    id: 'cancel',
  },
  btnConfirm: {
    id: 'btn.confirm',
  },
  deleteRecruiterModalConfirm: {
    id: 'event.recruiter.preparation.team.delete.recruiter.modal.confirm',
  },
  deleteExponentModalConfirm: {
    id: 'event.owner.delete.exponent.modal.confirm',
  },
  downloadRunning: {
    id: 'download.running',
  },
  exportAppointmentsNotif: {
    id: 'recruiter.export.appointments',
  },
  warningMaxUsers: {
    id: 'event.owner.exponents.table.actions.popover.limit.users',
  },
  modifyProfile: {
    id: 'recruiter.actions.modifyProfile',
  },
  deleteAccount: {
    id: 'delete.account',
  },
  modifyParticipationMode: {
    id: 'exponent.modify.participationMode'
  },
  viewPhysical: {
    id: 'exponent.changeView.physical'
  },
  viewVirtual: {
    id: 'exponent.changeView.virtual'
  },
  viewAll: {
    id: 'exponent.changeView.all'
  },
});
