export const icons = [
  'menu-burger',
  'calendar',
  'pencil',
  'zoom-in',
  'zoom-out',
  'telephone-circle',
  'envelope-circle',
  'message',
  'visio',
  'warning-circle',
  'info-circle',
  '@/messages',
  'download',
  'calendar-check',
  'change-date',
  'image',
  'camera',
  'video',
  'quote-close',
  'quote-open',
  'quote-square-open',
  'quote-left',
  'trash',
  'offers',
  'search',
  'map',
  'location-world',
  'search-people',
  'calendar-info',
  'plus',
  'lock',
  'set-preparation',
  'remove',
  'required',
  'settings',
  'user',
  'minus',
  'success',
  'error',
  'close',
  'arrow-down',
  'arrow-up',
  'arrow-left',
  'arrow-right',
  'more-vertical',
  'star',
  'star-filled',
  'cloud-upload',
  'upload',
  'clock',
  'circle',
  'copy',
  'left',
  'rocket',
  'eye',
  'building',
  'attachment',
  'exit',
  'switchUser',
  'charts',
  'users',
  'candidates',
  'candidats',
  'chat',
  'createUser',
  'jobPosition',
  'hourglass',
  'mail',
  'light',
  'edit-circle',
  'duplicate',
  'more',
  'contract',
  'location',
  'user-invite',
  'change-role',
  'skill',
  'car',
  'langage',
  'language',
  'formation',
  'formation-2',
  'tag',
  'menu-burger-2',
  'accept',
  'accept-circle',
  'refuse',
  'share',
  'slots-calendar',
  'rdv-video',
  'icon-tel',
  'linkedin',
  'cat',
  'phone',
  'flag-france',
  'cat-cv',
  'add-plus-rounded',
  'legal',
  'close-modal',
  'icon-hide',
  'password',
  'password-lock',
  'minus-rounded',
  'photo',
  'social',
  'linkedin-flat',
  'link',
  'social-linkedin',
  'social-facebook',
  'social-instagram',
  'social-twitter',
  'website-icon',
  'fullscreen',
  'timer',
  'social-black-twitter',
  'social-black-instagram',
  'social-black-google',
  'social-black-facebook',
  'blank-state-plus',
  'blank-state-calendar',
  'blank-state-nothing-found',
  'blank-state-nothing-found-oops',
  'blank-state-walter-white',
  'blank-state-404',
  'blank-state-cactus',
  'blank-state-cactus-disabled',
  'umbrella',
  'sourcing',
  'sourcing2',
  'experience',
  'light-refuse',
  'blank-state-rocket',
  '404',
  'house',
  'init',
  'website',
  'icon-seen',
  'cross',
  'hexagone',
  'square',
  'star-mini',
  'circle-mini',
  'donuts',
  'largePreview',
  'cookies',
  'member',
  'seeklive',
  'live-event',
  'information',
  'chevron',
  'list',
  'dislike',
  'like',
  'move',
  'redash',
  'edit-role',
  'add',
  'iconAccept',
  'notification',
  'proposition',
  'blog',
  'iconNoShow',
  'save',
  'add-round-grey',
  'pipedrive',
  'sendMessage',
  'activity',
  'playVideo',
  'playVideo2',
  'gmail',
  'yahoo',
  'outlook',
  'funnelSeparator',
  'bilan',
  'increase',
  'accept4',
  'logo',
  'micro',
  'menu-burger-3',
  'more-horizontal',
  'core-target',
  'core-target-selected',
  'illuCalendar',
  'illuHourglassEnd',
  'illuHourglass',
  'partners',
  'logoFlat',
  'gift',
];
