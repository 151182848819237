import React, { useEffect } from 'react';
import { any, string, func, object } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import { compose } from 'redux';
import { isUndefined, isEmpty } from 'lodash';
import { toJS } from '@/utils';

// Store
import { authSelectors, authActions } from '@/store/auth';

// Containers
import { queryStringToObject, objectToParams } from '@/utils/url';

// Default withAuth params
const defaultParams = {
  isAdmin: false,
};

export const withAuth = (params) => (WrappedComponent) => {
  const mapStateToProps = createStructuredSelector({
    authUser: authSelectors.getAuthUser
  });

  const authParams = {
    ...defaultParams,
    ...params,
  };

  const mapDispatchToProps = {
    goTo: replace,
    autoLogin: authActions.autoLogin,
  };

  const RequiredAuth = (props) => {
    const { authUser, goTo, location, autoLogin } = props;

    useEffect(() => {
      const queryParams = queryStringToObject(window.location.search);
      const queryToken = queryParams.token;
      const queryReset = queryParams.reset;
      //
      // if (!authToken && isUndefined(queryToken)) {
      //   goTo('/auth/login');
      // } else {
      //   let redirection = location.pathname;
      //
      //   // Remove token from url params after the autologin but keep the other params
      //   if (!isEmpty(queryParams)) {
      //     if (queryParams.token) {
      //       delete queryParams.token;
      //     }
      //     if (!isEmpty(queryParams)) {
      //       redirection += `?${objectToParams(queryParams)}`;
      //     }
      //   }
      //
      //   const autoLoginParams = {
      //     authToken: queryToken,
      //     redirection,
      //   };
      //
      //   if (!isUndefined(queryReset)) {
      //     autoLoginParams.resetToken = queryReset;
      //   }
      //
      //   if (!authToken && !isUndefined(queryToken)) {
      //     autoLogin(autoLoginParams);
      //   }
      //
      //   if (authParams.isAdmin && !authUser.isAdmin) {
      //     goTo('/auth/login');
      //   }
      // }
    }, []);

    return (<WrappedComponent {...props} />);
  };

  RequiredAuth.propTypes = {
    authUser: object,
    authParams: object,
    goTo: func,
    location: object,
    autoLogin: func,
    component: any,
  };

  const withConnect = connect(mapStateToProps, mapDispatchToProps);

  return compose(withConnect)(toJS(RequiredAuth));
};
