import React, { useEffect, useState } from 'react';
import { IconAtSign } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { Form } from 'antd';
import { bool, object, string } from 'prop-types';
import InputMaterial from '@/components/Input/InputMaterial';

const FormItem = Form.Item;

export const Username = ({ form, username = null, visible = true }) => {
  const t = useFormatMessage();
  const [defaultEmail, setDefaultEmail] = useState(username)

  useEffect(() => {
    if (window.localStorage.getItem('signup-email')) {
      setDefaultEmail(window.localStorage.getItem('signup-email'));
    }
  }, []);

  return (
    <>
      <FormItem className={!visible && 'hide'}>
        {form.getFieldDecorator('username', {
          initialValue: defaultEmail,
          rules: [{ required: true, type: 'email', message: t({ id: 'form.mandatory' }) },],
          validateTrigger: 'onSubmit',
        })(
          <InputMaterial addOnIcon={<IconAtSign size={24} />} label={t({ id: 'form.email' })} placeholder={t({ id: 'form.email' })} type="mail" validations={['required']} />
        )}
      </FormItem>
    </>
  )
}

Username.propTypes = {
  username: string,
  form: object,
  visible: bool
}
