import React from 'react';
import { object, any } from 'prop-types';
import SplitViewsComponent from '@/components/SplitView';


const SplitView = ({ template, WrappedComponent, props }) => {
  const { rightContent, onClose, redirect, classContainer } = template;

  return (
    <SplitViewsComponent
      classContainer={classContainer}
      handleCloseAppointment={() => { onClose(); props.history.push(redirect()); }}
      leftContent={<WrappedComponent {...props} />}
      rightContent={rightContent}
    />
  );
};

SplitView.propTypes = {
  template: object,
  history: object,
  props: object,
  WrappedComponent: any,
};

export { SplitView };
