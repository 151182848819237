import React from 'react';
import { object, func, bool } from 'prop-types';
import { Button, H5 } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { FormattedHTMLMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import ModalV2 from '@/components/ModalV2';
import Separator from '@/components/Separator';
import { organizationActions } from '@/store/organization';

const ConfirmRemoveOrganizationModal = ({
  organization,
  confirmRemoveOrganizationModal,
  setConfirmRemoveOrganizationModal,
}) => {
  const t = useFormatMessage();
  const dispatch = useDispatch()

  const handleDeleteOrganization = () => {
    setConfirmRemoveOrganizationModal(false);

    dispatch(organizationActions.deleteOrganization({
      organizationId: organization._id,
      callback: () => {
        dispatch(push('/admin/organizations'));
      },
    }));
  };

  return (
    <ModalV2
      template="default"
      visible={confirmRemoveOrganizationModal}
      onCancel={() => setConfirmRemoveOrganizationModal(false)}
      onClose={() => setConfirmRemoveOrganizationModal(false)}
    >
      <H5>
        <span role="img" aria-label="warning">⚠️ </span><FormattedHTMLMessage id="admin.organizations.detail.confirm.delete" />
      </H5>

      <Separator height={20} />

      <div className="confirm-actions">
        <Button
          type="primary"
          variant="tonal"
          onClick={() => setConfirmRemoveOrganizationModal(false)}
        >
          {t({ id: 'admin.organizations.cancel' })}
        </Button>
        <Button
          type="primary"
          variant="fill"
          onClick={() => handleDeleteOrganization()}
        >
          {t({ id: 'admin.organizations.confirm' })}
        </Button>
      </div>
    </ModalV2>
  )
}

ConfirmRemoveOrganizationModal.propTypes = {
  organization: object,
  setConfirmRemoveOrganizationModal: func,
  confirmRemoveOrganizationModal: bool,
}

export { ConfirmRemoveOrganizationModal }