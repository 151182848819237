import { useMutation, useQueryClient } from 'react-query';
import { filter } from 'lodash';
import request from '@/utils/request';

export function useDeletePost(
  {
    onSuccess = () => {},
    eventId,
    page,
  }) {
  const queryClient = useQueryClient();

  const mutationFn = ({ _id }) => {
    const requestUrl = `${process.env.FRONT_API_URL}/posts/${_id}`;

    return (
      request(requestUrl, {
        method: 'DELETE',
      })
    )
  }

  return useMutation({
    mutationFn,
    onMutate: async (post) => {
      const posts = queryClient.getQueryData(['owner', 'dashboard', 'posts', eventId, page]);
      const newDocs = filter(posts?.docs, (prevConferences) => prevConferences._id !== post._id);
      const newPosts = { ...posts, docs: newDocs };

      queryClient.setQueryData(['owner', 'dashboard', 'posts', eventId, page], newPosts);

      return newPosts;
    },
    onSuccess
  });
}
