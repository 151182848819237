import { defineMessages } from 'react-intl';

export default defineMessages({
  presentation: {
    id: 'event.candidate.preparation.signup.step.presentation',
  },
  path: {
    id: 'event.candidate.preparation.signup.step.path',
  },
  resume: {
    id: 'event.candidate.preparation.signup.step.resume',
  },
  search: {
    id: 'event.candidate.preparation.signup.step.search',
  },
  participation: {
    id: 'event.candidate.preparation.signup.step.participation',
  },
});
