import React from 'react';
import { injectIntl } from 'react-intl';
import { bool, func, any, object, number } from 'prop-types';
import classnames from 'classnames';

// Components
import AutosizeInput from 'react-input-autosize';
import AutoSizeTextarea from 'react-textarea-autosize';
import { Tooltip } from 'antd';
import { Button } from '@seekube-tech/ui-kit';

// Styles & Translations
import styles from './styles.less';
import messages from '../../messages';


class InlineEditor extends React.PureComponent {
  static propTypes = {
    value: any,
    canEdit: bool,
    content: any,
    isTextArea: bool,
    editMode: bool,
    contentClassName: any,
    onSave: func,
    intl: object,
    limit: number,
  };

  static defaultProps = {
    isTextArea: false,
  };

  state = {
    value: this.props.value,
    tooltipIsVisible: false,
    loadingMore: false,
    editMode: false,
  };

  /**
   * When we receive new props
   *
   * @param {object} newProps
   * @param {string} newProps.value
   */
  componentWillReceiveProps({ value, editMode }) {
    this.setState({ value, editMode });
  }


  /**
   * Focus the input
   *
   * @param {object} prevProps
   * @param {object} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    if (!prevState.editMode && this.state.editMode) {
      this.inputElement.focus();
    }
  }

  /**
   * Toggle Tooltip
   *
   * @description
   * Works only when editMode is equal true
   */
  toggleTooltip = () => {
    const { props: { canEdit } } = this;

    if (!canEdit) {
      return;
    }

    this.setState({ tooltipIsVisible: !this.state.tooltipIsVisible });
  };


  switchToEditableMode = () => this.setState({ editMode: true, tooltipIsVisible: false });


  handleOnChange = (e) => this.setState({ value: e.target.value });


  handleOnSave = () => {
    const { state: { value }, props: { onSave, canEdit } } = this;

    if (!canEdit) {
      return;
    }

    this.setState({ editMode: false }, () => onSave(value));
  };


  handleOnCancel = () => {
    const { props: { value: oldValue } } = this;

    this.setState({ value: oldValue, editMode: false });
  };


  renderValueNotEditable = () => {
    const {
      state: { value, loadingMore },
      props: { content, canEdit, contentClassName, limit },
      toggleTooltip, switchToEditableMode,
    } = this;

    let strValue = value;
    if (typeof limit === 'number' && value.length > limit) {
      const style = { fontSize: '11px', fontWeight: 600 };
      switch (loadingMore) {
        case true: strValue = <>{value} <a style={style} role="button" tabIndex="0" onClick={() => this.setState({ loadingMore: false })}>MOINS</a></>; break;
        case false: strValue = <>{value.slice(0, limit)} <a style={style} role="button" tabIndex="0" onClick={() => this.setState({ loadingMore: true })}>PLUS</a></>; break;
        default: break;
      }
    }

    return React.cloneElement(content, {
      children: strValue,
      onMouseEnter: toggleTooltip,
      onMouseLeave: toggleTooltip,
      onClick: canEdit ? switchToEditableMode : null,
      href: canEdit ? null : content.props.href,
      className: classnames(
        contentClassName,
        (canEdit) ? styles.editStyle : null
      ),
    });
  };


  renderValueEditable = () => {
    const {
      state: { value },
      props: { contentClassName, isTextArea, intl },
      handleOnChange, handleOnSave, handleOnCancel,
    } = this;

    return (
      <div className={classnames(styles.inlineEditorContainer, contentClassName)}>
        {
          React.cloneElement(isTextArea ? <AutoSizeTextarea /> : <AutosizeInput />, {
            inputRef: (ref) => (this.inputElement = ref),
            value,
            onChange: handleOnChange,
            className: classnames(
              styles.parentStyle,
              styles.editStyle
            ),
          })
        }
        <div className={styles.actionsContainer}>
          <div className={styles.arrow}></div>
          <div className={styles.pop}>
            <Button onClick={handleOnSave}>{intl.formatMessage(messages.save)}</Button>
            <Button className={styles.cancelBtn} onClick={handleOnCancel}>{intl.formatMessage(messages.cancel)}</Button>
          </div>
        </div>
      </div>
    );
  };


  render() {
    const {
      state: { tooltipIsVisible, editMode },
      renderValueNotEditable, renderValueEditable,
    } = this;

    return (
      <Tooltip
        title="Cliquer pour modifier"
        placement="bottom"
        visible={tooltipIsVisible}
      >
        {(editMode) ? renderValueEditable() : renderValueNotEditable()}
      </Tooltip>
    );
  }
}

export default injectIntl(InlineEditor);
