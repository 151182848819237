import { useQuery } from 'react-query';

export const useGetFile = ({ fileUrl }) => {

  const requestUrl = fileUrl;

  const queryFn = () => fetch(requestUrl, { method: 'GET' }).then((file) => file.blob());

  const query = useQuery({
    queryKey: [fileUrl],
    queryFn,
    refetchOnWindowFocus: false,
    enabled: !!fileUrl
  });

  return query;
}