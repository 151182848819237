import {
  getEventPhases, isAdmin,
  isInteractionClosed,
  isRoleCandidate, isRoleOwner, isRoleRecruiter,
} from '@/store/availableActions/utils/event';
import { PHASES, PHASE_STATE } from '@/store/availableActions/utils/const';
import { isParticipantFormatPhysical, isParticipantFormatVirtual } from '@/store/availableActions/utils/user';
import { EVENT_FORMAT } from '@/utils/constants';
import { isParticipatingToAllHybridFormats } from '@/helpers/participant/isParticipatingToAllHybridFormats';


function isDisabled({
  event,
  user,
  role,
  participant,
  interaction,
}) {
  const eventPhases = getEventPhases(event);
  const exponentKeyMomentFormats = interaction?._appointment?.keyMomentFormats || [];

  if (event.isAuthUserBanned || isRoleOwner(role) || isRoleRecruiter(role) || isAdmin(user)) {
    return true;
  }

  if (isRoleCandidate(role)) {
    if (event.format === EVENT_FORMAT.HYBRID) {
      if (isParticipatingToAllHybridFormats(participant?.keyMomentFormats)) {
        // exponent participate au deux
        if (isParticipatingToAllHybridFormats(exponentKeyMomentFormats)) {
          return isInteractionClosed(eventPhases, EVENT_FORMAT.PHYSICAL) && isInteractionClosed(eventPhases, EVENT_FORMAT.VIRTUAL)
        }
        // exponent participate only a physical
        if (isParticipantFormatPhysical(interaction?._appointment)) {
          return isInteractionClosed(eventPhases, EVENT_FORMAT.PHYSICAL)
        }
        // exponent participate only a virtual
        if (isParticipantFormatVirtual(interaction?._appointment)) {
          return isInteractionClosed(eventPhases, EVENT_FORMAT.VIRTUAL)
        }

        return true;
      }

      if (isParticipantFormatPhysical(participant) && isParticipantFormatPhysical(interaction?._appointment)) {
        return isInteractionClosed(eventPhases, EVENT_FORMAT.PHYSICAL);
      }

      if (isParticipantFormatVirtual(participant) && isParticipantFormatVirtual(interaction?._appointment)) {
        return isInteractionClosed(eventPhases, EVENT_FORMAT.VIRTUAL);
      }
    }
  }

  if (event.format === EVENT_FORMAT.VIRTUAL) {
    return isInteractionClosed(eventPhases, EVENT_FORMAT.VIRTUAL);
  }

  if (event.format === EVENT_FORMAT.PHYSICAL) {
    return isInteractionClosed(eventPhases, EVENT_FORMAT.PHYSICAL);
  }

  return true;
}


export default {
  isAvailable:  [
    [
      {
        phase: PHASES.ALL,
        state: PHASE_STATE.ALL
      },
    ],
  ],
  generateActions: ({ authUser }) => {
    const user = authUser?.toJS();


    return {
      isDisabled: ({ event, role, participant, appointment, interaction }) => isDisabled({ event, user, role, participant, appointment, interaction }),
    }
  }
}
