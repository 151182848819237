import React, { useState } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { object, func } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import { createStructuredSelector } from 'reselect';
import { get } from 'lodash';
import { Modal } from 'antd';
import { Pagination, Table } from '@seekube-tech/ui-kit';
import { Button } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { useHistory } from 'react-router-dom';
import { toJS } from '@/utils';
import { track } from '@/utils/analytics';
import { ACL, ANALYTICS_RECRUITER, EVENT_FORMAT } from '@/utils/constants';
import { exponentActions, exponentSelectors } from '@/store/exponent';
import { authSelectors } from '@/store/auth';
import { eventSelectors } from '@/store/event';
import { userActions } from '@/store/user';
import Wrapper from '@/components/Wrapper';
import Separator from '@/components/Separator';
import LoadingIndicator from '@/components/LoadingIndicator';
import Icon from '@/components/Icon';
import ColumnsUi from '@/scenes/Event/scenes/Recruiter/scenes/Preparation/scenes/Team/ColumnsUi';
import DataColumn from '@/scenes/Event/scenes/Recruiter/scenes/Preparation/scenes/DataColumn';
import { isHybrid } from '@/helpers/event/isHybrid';
import { arrayContainAll } from '@/utils/math';
import { useGetUsersList } from '@/queries/exponent/preparation/useGetUsersList';
import { queryStringToObject } from '@/utils/url';
import { useGetUsersSelect } from '@/queries/exponent/preparation/useGetUsersSelect';
import { useDeleteExponentUser } from '@/queries/exponent/useDeleteExponentUser';
import EditUserModal from './containers/EditUserModal';
import OrganizationUsers from './components/OrganizationUsers';
import styles from './styles.less';
import messages from './messages';


const RecruiterPreparationTeamScene = ({
  authUser,
  error,
  event,
  exponent,
  patchExponent,
  postOrganizationUserAndAddToExponent,
}) => {
  const t = useFormatMessage();
  const history = useHistory();
  const { page = 1 } = queryStringToObject(history.location.search);
  const [confirmModalIsVisible, setConfirmModalIsVisible] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [editUserModalIsOpen, setEditUserModalIsOpen] = useState(false);
  const [exponentUserId, setExponentUserId] = useState('');
  const usersListQuery = useGetUsersList({ eventId: event._id, page });
  const usersSelectQuery = useGetUsersSelect({ eventId: event._id, page, keywords: '' });
  const mutateDeleteExponentUser = useDeleteExponentUser({ eventId: event._id, exponentId: exponent._id, onSuccess: usersListQuery.refetch });

  const onDeleteExponentClick = (user) => {
    setConfirmModalIsVisible(true)
    setExponentUserId(user._id)
  };

  const editUser = (user) => {
    setEditUserModalIsOpen(true)
    setCurrentUser(user)
  };

  const handleOnAddUserInExponent = (_, user, callback) => {
    patchExponent({
      exponentId: exponent._id,
      eventId: exponent._event._id,
      lean: true,
      exponentParams: {
        newUser: user,
      },
      notificationParams: {
        success: {
          message: `🙌 ${user.firstName || user.username} ${t(messages.notificationSuccessMemberAdd)}`,
          kind: 'info',
          style: {
            bottom: '5%',
            top: 'inherit',
          },
        },
        error: {
          style: {
            top: '5%',
          },
          message: `${t(messages.notificationErrorMemberCreate)}`,
        },
      },
      callback: () => {
        track({
          authUser,
          event: exponent._event,
          name: ANALYTICS_RECRUITER.INVITED_USER,
        });
        if (typeof callback === 'function') {
          callback();
        }
      },
    });
  };

  const handleOnCreateUser = (userParams, callback) => {
    postOrganizationUserAndAddToExponent({
      organizationId: authUser._currentOrganization._id,
      exponent,
      userParams,
      notificationParams: {
        success: {
          message: `🙌 ${userParams.username} ${t(messages.notificationSuccessMemberCreate)}`,
          kind: 'info',
          style: {
            bottom: '5%',
            top: 'inherit',
          },
        },
        error: {
          style: {
            top: '5%',
          },
          message: `${t(messages.notificationErrorMemberCreate)}`,
        },
      },
      callback: () => {
        track({
          authUser,
          event: exponent._event,
          name: ANALYTICS_RECRUITER.INVITED_USER,
        });

        usersListQuery.refetch();

        if (typeof callback === 'function') {
          callback();
        }
      },
      event: exponent._event,
    });
  };


  const handleCloseModal = () => {
    setEditUserModalIsOpen(false);
    setCurrentUser(null)
  };


  const handleOnDeleteOfferOwner = () => {

    const currentExponentUser = exponent.users.find((item) => item._user && item._user._id === exponentUserId);

    const trackData = {
      user: currentExponentUser ? currentExponentUser._user : null,
      event: exponent._event,
      name: ANALYTICS_RECRUITER.CANCELED_RECRUITER_PARTICIPATION,
    };

    mutateDeleteExponentUser.mutate({ _id: exponentUserId });
    track(trackData);

    setConfirmModalIsVisible(false)
  };

  const inviteCollaborator = (collaboratorId, exponent) => {
    patchExponent({
      eventId: exponent._event._id,
      exponentId: exponent._id,
      exponentParams: {
        invitUser: collaboratorId,
      },
      notificationParams: {
        success: {
          message: t({ id: 'toasters.invite.success' }),
          kind: 'info',
          style: {
            bottom: '5%',
            top: 'inherit',
          },
        },
        error: {
          style: {
            top: '5%',
          },
        },
      },
    });
  };

  const handleRecruiterAction = (type, user, exponent) => {
    switch (type) {
      case 'editUser': editUser(user); break;
      case 'inviteCollaborator': inviteCollaborator(user._id, exponent); break;
      case ACL.REMOVE_USER_FROM_EXPONENT: onDeleteExponentClick(user); break;
      default: break;
    }
  };

  const getColumns = () => {
    let columnsToDisplay = [];

    const formats = event.format === EVENT_FORMAT.HYBRID ? exponent.keyMomentFormats : [exponent._event.format];
    const isPhysical = event.format === EVENT_FORMAT.PHYSICAL;
    const isVirtual = event.format === EVENT_FORMAT.VIRTUAL;
    const fromColumnIdToFormat = {
      physicalHybrid: EVENT_FORMAT.PHYSICAL,
      virtualHybrid: EVENT_FORMAT.VIRTUAL,
    }


    if (event.format === EVENT_FORMAT.HYBRID) {
      columnsToDisplay = ['exponentUser', 'offers', 'physicalHybrid', 'virtualHybrid', 'actions'];

      columnsToDisplay = columnsToDisplay.filter(
        (columnId) => (
          !(columnId === 'physicalHybrid' && !formats.includes(fromColumnIdToFormat[columnId])) &&
          !(columnId === 'virtualHybrid' && !formats.includes(fromColumnIdToFormat[columnId]))
        )
      )
    } else {
      columnsToDisplay = ['exponentUser', 'offers', 'physicalCountAppointment', 'physicalCountInformal', 'virtualCountAppointment', 'virtualCountInformal', 'actions'];

      columnsToDisplay = columnsToDisplay
        .filter((columnId) => (
          (!(columnId === 'virtualCountInformal' && (!isVirtual || !event?.modules?.informal1to1?.enable))) &&
          (!(columnId === 'virtualCountAppointment' && !isVirtual)) &&
          (!(columnId === 'physicalCountAppointment' && !isPhysical)) &&
          (!(columnId === 'physicalCountInformal' && (!isPhysical || !event?.modules?.informal1to1?.enable)))
        ))
    }


    return (
      ColumnsUi(['recruiter'], isHybrid(event), arrayContainAll([EVENT_FORMAT.VIRTUAL, EVENT_FORMAT.PHYSICAL], exponent.keyMomentFormats), exponent, authUser)
        .filter(column => columnsToDisplay.includes(column.id))
    )
  }

  const handleOnPageChange = (page) => {
    history.push(`?page=${page}`);
    window.scrollTo(0, 0);
  }

  if (!event || !exponent) {
    return (<LoadingIndicator />);
  }

  const numberOfAuthorizedMembers = get(exponent, 'limits.recruiterMax') ? (<div className={styles.authorizedMemberNumber}>
    <p className="help"><FormattedMessage {...messages.numberOfAuthorizedMembers} values={{ total: get(exponent, 'limits.recruiterMax'), value: exponent ? exponent.users.length : 0 }} /></p>
  </div>) : null;

  return (
    <Wrapper type="scene">
      <h1 className="mainTitle" style={{ marginBottom: 0 }}>{t({ id: ' event.recruiter.preparation.team.title.collaborator' }, { count: exponent ? exponent.users.length : 0 })}</h1>
      {numberOfAuthorizedMembers}
      <OrganizationUsers
        organization={exponent._organization}
        exponent={exponent}
        onAddUserInExponent={handleOnAddUserInExponent}
        users={usersSelectQuery?.data?.docs || []}
        onCreateUser={handleOnCreateUser}
        error={error}
        isFetching={usersSelectQuery?.isFetching}
      />

      <Table
        getCustomTableProps={() => ({ style: { width: '100%' } })}
        columns={getColumns()}
        data={DataColumn(usersListQuery?.data?.docs || [], exponent, { actions: handleRecruiterAction })}
        isLoadingData={usersListQuery.isFetching}
      />
      <div className="pagination-container">
        <Pagination
          className="flex justify-center mt-10"
          current={parseInt(page, 10)}
          pageSize={usersListQuery.data?.limit}
          total={usersListQuery.data?.total}
          onChange={handleOnPageChange}
        />
      </div>


      {currentUser &&
        <EditUserModal
          onSubmit={() => usersListQuery.refetch()}
          isOpen={editUserModalIsOpen}
          onClose={handleCloseModal}
          user={currentUser}
          exponent={exponent}
          authUser={authUser}
        />}

      <Modal
        visible={confirmModalIsVisible}
        footer={false}
        maskClosable
        width={470}
        className="customConfirm"
        onCancel={() => setConfirmModalIsVisible(false)}
      >
        <a role="button" tabIndex={0} className="modal-close" onClick={() => setConfirmModalIsVisible(false)}>
          <Icon name="close" className="modal-close-icon" />
        </a>

        <h4 className="ant-confirm-title">
          <span role="img" aria-label="warning">⚠️ </span><FormattedHTMLMessage {...messages.deleteRecruiterModalConfirm} />
        </h4>

        <Separator height={15} />

        <div className="confirm-actions">
          <Button className="mr-6" onClick={() => setConfirmModalIsVisible(false)}>
            {t(messages.cancel)}
          </Button>
          <Button onClick={() => handleOnDeleteOfferOwner()}>
            {t(messages.btnConfirm)}
          </Button>
        </div>
      </Modal>
    </Wrapper>
  );
}

RecruiterPreparationTeamScene.propTypes = {
  authUser: object,
  error: object,
  event: object,
  exponent: object,
  patchExponent: func,
  postOrganizationUserAndAddToExponent: func,
  replace: func,
}

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser,
  error: exponentSelectors.getError,
  event: eventSelectors.getCurrentEvent,
  exponent: exponentSelectors.getCurrentExponent,
});

const mapDispatchToProps = {
  patchExponent: exponentActions.patchExponent,
  postOrganizationUserAndAddToExponent: userActions.postOrganizationUserAndAddToExponent,
  replace,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(toJS(RecruiterPreparationTeamScene));
