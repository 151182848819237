import { useQuery } from 'react-query';
import request from '@/utils/request';
import { objectToParams } from '@/utils/url';

const defaultSearch = {
  limit: 20,
  offset: 0,
  keywords: '',
}

export function useGetEventSuggestions({ eventsId = [], search = defaultSearch, context = 'exponent', currentEventId, ...props }) {
  const searchParams = objectToParams(search);

  const requestUrl = `${process.env.FRONT_API_URL}/events/${currentEventId}/suggestions?${searchParams}&context=${context}&eventsId=${encodeURIComponent(eventsId.join(','))}`;

  const queryFn = () => request(requestUrl, { method: 'GET', });

  const query = useQuery({
    queryKey: ['eventSuggestions', search.offset, search.keywords],
    queryFn,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    ...props,
  });
  return query;
}
