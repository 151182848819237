import React from 'react';
import { number, array, bool, func, object } from 'prop-types';
import classnames from 'classnames';
import { isEmpty } from 'lodash';

// Components
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import MasonryList from 'react-masonry-css';
import Slider from 'react-slick';
import { Modal } from 'antd';

import Icon from '@/components/Icon';
import * as Components from './components';

// Styles & Translations
import styles from './styles.less';

class Gallery extends React.PureComponent {
  static propTypes = {
    gutter: number,
    elements: array,
    editMode: bool,
    withSlideshow: bool,
    onChange: func,
    onClick: func,
    onlySlideshow: bool,
    slideshowIsOpen: bool,
    columnsCountBreakPoints: object,
    onCloseSlideshow: func,
    responsiveList: bool, // If true, use react-masonry-css instead of react-responsive-masonry
  };

  static defaultProps = {
    elements: [],
    gutter: 1,
    editMode: false,
    onlySlideshow: false,
    slideshowIsOpen: false,
    onClick: () => {},
    columnsCountBreakPoints: { 350: 1, 900: 2 },
  };

  /**
   * Mapping used for know which element use which component
   *
   * @type {object}
   */
  static masonryElements = {
    image: Components.Image,
    video: Components.Video,
    location: Components.Location,
  };

  /**
   * List Elements used for add new content into the gallery
   *
   * @type {Array}
   */
  static addElements = [
    Components.ImageAdd,
    Components.VideoAdd,
    Components.LocationAdd,
  ];

  static getDerivedStateFromProps({ elements, slideshowIsOpen, onlySlideshow }, state) {
    const { elements: oldElements } = state;

    const newState = {};

    if (oldElements.length !== elements.length) {
      newState.elements = elements;
    }

    if (onlySlideshow) {
      newState.modalIsOpen = slideshowIsOpen;
    }

    if (!isEmpty(newState)) {
      return newState;
    }

    return null;
  }

  /**
   * Constructor
   *
   * @param {object} props
   */
  constructor(props) {
    super(props);

    this.state = {
      elements: props.elements,
      modalIsOpen: false,
      settings: {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: (<span className={styles.arrow}><Icon name="arrow-right" /></span>),
        prevArrow: (<span className={styles.arrow}><Icon name="arrow-left" /></span>),
      },
    };
  }

  /**
   * Handle add
   *
   * @param {object} element
   */
  handleAdd = (element) => {
    const { props: { editMode, onChange }, state: { elements } } = this;

    const newElements = elements.concat([element]);

    if (editMode) { // Send to the parent new values
      onChange(newElements);
    }
  };

  handleShowSlideshow = (index) => {
    const {settings} = this.state;
    this.props.onClick();
    settings.initialSlide = index;

    this.setState({ modalIsOpen: true, settings });
  };

  handleCloseSlideshow = () => {
    this.setState({ modalIsOpen: false });

    if (this.props.onCloseSlideshow) {
      this.props.onCloseSlideshow();
    }
  }

  /**
   * Return all edit elements
   */
  returnEditElements = () => Gallery.addElements
    .map((Element, index) => <Element key={index} onOk={this.handleAdd} />);

  /**
   * Render child elements for Masonry
   */

  renderSlideshowElements = (elements) => {
    const { state: { modalIsOpen } } = this;
    let masonryElements = [];

    // Push all elements
    masonryElements = elements.reduce((acc, element, index) => {
      // Get the good Element based on the elementType and push it
      const Element = Gallery.masonryElements[element.type];
      acc.push(
        <Element
          key={index}
          index={index}
          element={element}
          template="fdf"
          modalIsOpen={modalIsOpen}
        />);

      return acc;
    }, masonryElements);

    return masonryElements;
  };

  /**
   * Render child elements for Masonry
   */
  renderMasonryElements = (elements, isShift = false) => {
    const {
      props: { editMode, withSlideshow },
      state: { modalIsOpen },
      returnEditElements,
      handleShowSlideshow,
    } = this;

    let masonryElements = [];

    // Push elements for edit, only on editMode
    if (editMode) {
      masonryElements.push((
        <div key={-1} className={styles.addElementsContainer}>
          {returnEditElements()}
        </div>
      ));
    }

    // Push all elements
    masonryElements = elements.reduce((acc, element, index) => {
      // Get the good Element based on the elementType and push it
      const Element = Gallery.masonryElements[element.type];

      acc.push(
        <Element
          key={isShift ? index + 1 : index}
          index={isShift ? index + 1 : index}
          element={element}
          onClick={withSlideshow ? () => handleShowSlideshow(isShift ? index + 1 : index) : null}
          style={element.style}
          withSlideshow={withSlideshow}
          className={withSlideshow ? 'withSlideshow' : ''}
          modalIsOpen={modalIsOpen}
        />);

      return acc;
    }, masonryElements);

    return masonryElements;
  };


  render() {
    const {
      props: { gutter, columnsCountBreakPoints, onlySlideshow, responsiveList },
      state: { elements, modalIsOpen, settings },
      renderMasonryElements, renderSlideshowElements, handleCloseSlideshow,
    } = this;
    const masonryElements = elements.slice();

    return (
      <>
        {!onlySlideshow && (
          responsiveList ? (
            // Use to prevent bug in exponent pictures list
            <MasonryList
              breakpointCols={columnsCountBreakPoints}
              className={styles.masonryGrid}
              columnClassName={styles.masonryGridColumn}
            >
              {renderMasonryElements(elements)}
            </MasonryList>
          ) : (
            <ResponsiveMasonry className={classnames(styles.galleryContainer, masonryElements.length === 1 ? styles.centerElement : null)} columnsCountBreakPoints={columnsCountBreakPoints}>
              <Masonry gutter={gutter.toString()}>
                {renderMasonryElements(elements)}
              </Masonry>
            </ResponsiveMasonry>
          )
        )}
        {modalIsOpen ? (
          <Modal
            visible
            onCancel={() => this.setState({ modalIsOpen: false })}
            footer={null}
            maskClosable
            className="slideshowModal"
            width="100%"
          >
            <div className="slideshowContainer">
              <a role="button" tabIndex={0} className="modal-close" onClick={handleCloseSlideshow}>
                <Icon name="close" className="modal-close-icon" />
              </a>
            </div>
            <Slider {...settings} customPaging={() => <a></a>}>
              { renderSlideshowElements(elements) }
            </Slider>
          </Modal>
        ) : ''}
      </>
    );
  }
}

export default Gallery;
