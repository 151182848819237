import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import * as types from './types';

// Initial State
const initialReducer = fromJS({
  allIds: [],
  pagination: {
    current: 1,
    pageSize: 10,
    total: 0,
    offset: 0,
  },
});

// Job reducer
const jobReducer = handleActions({
  [types.POST_JOB_SUCCESS]: (state, { payload }) =>
    state.set('allIds', state.get('allIds').unshift(payload)),
  [types.DELETE_JOB_SUCCESS]: (state, { payload }) =>
    state.set('allIds', state.get('allIds').filter((id) => id !== payload)),
  [types.GET_JOBS_SUCCESS]: (state, { payload }) => {
    const newAllIds = state.get('allIds').merge(payload.result);

    return state
      .set('allIds', newAllIds)
      .setIn(['pagination', 'current'], payload.currentPage)
      .setIn(['pagination', 'pageSize'], payload.limit)
      .setIn(['pagination', 'offset'], payload.offset)
      .setIn(['pagination', 'total'], payload.total);
  },
}, initialReducer);

export default jobReducer;
