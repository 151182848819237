import React from 'react';
import classnames from 'classnames';
import { string, any } from 'prop-types';

import { Loader } from '@seekube-tech/ui-kit';
import styles from './styles.less';

const LoadingIndicator = ({ className, children = null }) => (
  <div className={classnames(styles.loading, className || '')}>
    <Loader />
    {children}
  </div>
);

LoadingIndicator.propTypes = {
  className: string,
  children: any,
};
export default LoadingIndicator;
