import React from 'react';
import { object } from 'prop-types';
import EventsList from '../../container/EventsList';
import { Explore } from '@/scenes/Events/scenes/Explore';
import styles from '@/scenes/Events/container/EventsList/styles.less';
import { ReactTitle } from 'react-meta-tags';
import Sticky from 'react-sticky-el';
import Header from '@/containers/Layout/containers/Header';
import MobileHeader from '@/containers/Layout/containers/MobileHeader';

const ArchiveEvents = ({ authUser, ...props }) => (
  <div className={styles.sliderContainer}>
    <ReactTitle title="Seekube" />
    <div className={styles.headerContainer}>
      <Sticky>
        <Header />
      </Sticky>
    </div>
    <Explore authUser={authUser}>
      <EventsList archive authUser={authUser} {...props} />
    </Explore>
    <MobileHeader />
  </div>
);

ArchiveEvents.propTypes = {
  authUser: object,
};

export { ArchiveEvents };
