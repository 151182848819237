import React from 'react';
import { createStructuredSelector } from 'reselect';
import { bool } from 'prop-types';
import { connect } from 'react-redux';
import { useGetKpiSessions } from '@/queries/kpis/useGetKpiSessions';

const second = 1000;

const UsersConnected = ({ isSpotlightVisible }) => {
  const kpiSessionQuery = useGetKpiSessions({
    refetchInterval: isSpotlightVisible && (5 * second),
  })

  return <>{kpiSessionQuery?.data?.countUsersConnected}</>;
}

UsersConnected.propTypes = {
  isSpotlightVisible: bool,
}

const mapStateToProps = createStructuredSelector({
})

const withConnect = connect(mapStateToProps, null);


export default withConnect(UsersConnected)
