import { useQuery } from 'react-query';

import request from '@/utils/request';
import { objectToParams } from '@/utils/url';

export function useGetExponentsPictureUrls({ eventId, search, page, offset, limit = 10, sortRandom = true, eventFormat = 'all', all = false }) {

  const searchParams = objectToParams(search);
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/exponents/pictureUrls?${searchParams}&all=${all}&sortRandom=${sortRandom}&offset=${offset}&page=${page}&limit=${limit}&eventFormat=${eventFormat}`;

  const queryFn = () => request(requestUrl, { method: 'GET' });

  const query = useQuery({
    queryKey: ['exponentsPictureUrls', eventId, eventFormat, page, searchParams],
    queryFn,
    initialData: { docs: [] },
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });

  return query;
}
