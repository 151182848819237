
import { call, put, takeLatest } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import request from '@/utils/request';
import { entitiesActions } from '../entities';
import { surveySchema } from './schema';
import * as actions from './actions';
import * as types from './types';
import { trackError } from '../../utils/analytics/helpers';

/**
 * GET survey for event
 */
function* getSurveyEvent({ payload: { eventId } }) {
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/surveys`;

  try {
    const result = yield call(request, requestUrl, {
      method: 'GET',
    });

    // Normaliz data
    const dataNormalized = normalize(result, surveySchema);

    // Save entities
    yield put(entitiesActions.replaceEntities({ id: result._id, type: 'survey', entities: dataNormalized.entities }));
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    // Set the current survey
    yield put(actions.setCurrentSurvey(result._id));
  } catch (err) {
    trackError(err);
  }
}

/**
 * Listen Actions
 */
export default [
  takeLatest(types.GET_SURVEY_EVENT, getSurveyEvent),
];
