import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { func, string } from 'prop-types';
import { Button } from '@seekube-tech/ui-kit';

const AddReport = ({ phase, handleClick, format }) => {
  const t = useFormatMessage();

  return (
    <Button
      variant="tonal"
      color="primary"
      style={{ marginLeft: '20px' }}
      onClick={() => handleClick(phase, format)}
    >
      {t({ id: 'owner.reporting.add' })}
    </Button>

  );
}

AddReport.propTypes = {
  phase: string,
  handleClick: func,
  format: string
}

export { AddReport }
