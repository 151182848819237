import { useMutation, useQueryClient } from 'react-query';
import request from '@/utils/request';

export function useCreateTemplate({
  onSuccess = () => {},
  onError = () => {},
  userId,
}) {
  const queryClient = useQueryClient();
  const mutationFn = (template) => {
    const requestUrl = `${process.env.FRONT_API_URL}/users/${userId}/templates`;

    return request(requestUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        KoaUpdateCache: `/users/${userId}/templates`,
      },
      body: JSON.stringify({ ...template }),
    });
  };

  return useMutation({
    mutationFn,
    onSuccess: async (resTemplate, { _id }) => {
      const templates = queryClient.getQueryData(['templates']);

      // if user modify a default template then we replace it otherwise add it to the list
      const newTemplates = resTemplate.type ?
        templates.map((prevTemplate) => prevTemplate.type === resTemplate.type && prevTemplate._id === _id ? resTemplate : prevTemplate)
        : [...templates, resTemplate]

      queryClient.setQueryData(['templates'], newTemplates);

      onSuccess();

      return newTemplates;
    },
    onError,
  });

}
