import React from 'react';
import { object, node, string } from 'prop-types';

import styles from './styles.less';

/**
 * HP
 * @param {object} props
 */
const TextSeparator = (props) => (
  <div className={styles.separator} {...props}>
    <span className={props.className || ''} style={{ ...props.textstyle }}>{props.text}</span>
  </div>
);

TextSeparator.propTypes = {
  text: node,
  textstyle: object,
  className: string,
};

export default TextSeparator;
