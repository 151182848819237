import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { Alert } from '@seekube-tech/ui-kit';

export const WarningNoDescription = ({ count, offersSelectedSize }) => (
  count > 0 &&
  <div style={{ margin: '25px 0' }}>
    <Alert color="warning">
      <div>
        <FormattedHTMLMessage
          id="recruiter.offers.spread.publish.offers.modal.warning.no.description"
          values={{ count, countOffersSelected: offersSelectedSize }}
        />
      </div>
    </Alert>
  </div>
);
