import { EVENT_FORMAT } from '@/utils/constants';

export const getExponentUserParticipation = (event, exponentUser) => {
  if (event?.format === EVENT_FORMAT.HYBRID) {
    if (exponentUser?.keyMomentFormats?.length > 1) {
      return EVENT_FORMAT.HYBRID;
    }
    if (exponentUser?.keyMomentFormats?.[0] === EVENT_FORMAT.PHYSICAL) {
      return EVENT_FORMAT.PHYSICAL;
    }
    return EVENT_FORMAT.VIRTUAL;
  }

  if (event?.format === EVENT_FORMAT.PHYSICAL) {
    return EVENT_FORMAT.PHYSICAL
  }

  return EVENT_FORMAT.VIRTUAL
}
