import React from 'react';
import { func, object, string } from 'prop-types';
import { isEmpty } from 'lodash';
import classNames from 'classnames';

import Icon from '@/components/Icon';
import { Tooltip } from '@/components/Tooltip';

import styles from '../../../styles.less';

const Slot = ({
  slot,
  handleSelectSlot,
  slotSelected,
  tooltipMessage,
}) => {
  const { moment } = window;
  const isDisabled = !isEmpty(slot._appointment) || !slot.available;
  const isSelected = !isEmpty(slotSelected) ? slotSelected._id === slot._id : false;

  const handleOnSelectSlot = (e, slot) => {
    e.preventDefault();

    if (!isDisabled) {
      handleSelectSlot(slot);
    }
  };

  const classname = classNames(
    isDisabled ? styles.disabled : '',
    isSelected ? styles.selected : ''
  );

  let icon;

  if (slot.medium === 'visio') {
    icon = (<Icon name="camera" style={{ width: '18px', height: '18px', position: 'relative', top: '1px' }} />);
  } else if (slot.medium === 'phone') {
    icon = (<Icon name="phone" style={{ width: '18px', height: '18px', position: 'relative', top: '1px' }} />);
  } else if (slot.medium === 'physical') {
    icon = (<Icon name="location" style={{ width: '18px', height: '18px', position: 'relative', top: '1px' }} />);
  }

  if (slot.available === false && window.innerWidth > 650) {
    return (
      <Tooltip
        placement="top"
        title={tooltipMessage}
        color="dark"
        getPopupContainer={() => document.getElementById('slotsContainer')}
      >
        <li key={slot._id} className={classname} onClick={(e) => handleOnSelectSlot(e, slot)} role="button">
          <a role="button" tabIndex={0}>
            {icon}{moment(slot.beginAt).format('LT')}
          </a>
        </li>
      </Tooltip>
    );
  }
  return (
    <li key={slot._id} className={classname} onClick={(e) => handleOnSelectSlot(e, slot)} role="button">
      <a role="button" tabIndex={0}>
        {icon}{moment(slot.beginAt).format('LT')}
      </a>
    </li>
  );
};

Slot.propTypes = {
  slot: object,
  handleSelectSlot: func,
  slotSelected: object,
  tooltipMessage: string,
};

export { Slot }
