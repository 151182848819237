import React from 'react';
import { Avatar as AvatarBusiness } from '@seekube-tech/ui';
import { Body2, Caption2, Link2 } from '@seekube-tech/ui-kit';
import Avatar from '@/components/Avatar';
import useStyles from '@/scenes/Admin/components/Kpi/components/InputSearchAdmin/components/styles';
import Html from '@/components/Html';

const Users = ({ users, onClick }) => {
  const styles = useStyles();

  return (
    users.map((user, index) => (
      <li
        role="button"
        tabIndex={0}
        key={index}
      >
        <div>
          <div className={styles.resultIcon}>
            <Avatar size={26} src={user.pictureUrl} className={styles.avatar} />
            {user._currentOrganization &&
              <AvatarBusiness
                variant="business"
                size="small"
                src={user._currentOrganization.profile?.pictureUrl}
                className={styles.orga}
              />}
          </div>
          <div className={styles.resultInfo}>
            <div>
              <Body2 className={styles.username}>
                <Link2 onClick={() => onClick('user', user)} tabIndex={0}>
                  <Html value={user?._highlightResult?.username?.value} />
                </Link2> <small className="ml-4">({user._id})</small>
              </Body2>
              {user.firstName &&
              <Caption2 className={styles.fullName}>
                <Html value={user?._highlightResult?.firstName?.value} />
                <div className="ml-4"><Html value={user?._highlightResult?.lastName?.value} /></div>
              </Caption2>
              }
            </div>
          </div>
        </div>
      </li>
    )))
}

export { Users }
