import { createAction } from 'redux-actions';
import * as types from './types';

// GET Survey for event
export const getSurveyEvent = createAction(types.GET_SURVEY_EVENT);
export const getSurveyEventSuccess = createAction(types.GET_SURVEY_EVENT_SUCCESS);
export const getSurveyEventError = createAction(types.GET_SURVEY_EVENT_ERROR);

/**
 * Action for set current survey
 */
export const setCurrentSurvey = createAction(types.SET_CURRENT_SURVEY);
