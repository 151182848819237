import { useMutation, useQueryClient } from 'react-query';
import { filter, map } from 'lodash';
import request from '@/utils/request';

export function useUpdateTemplate({
  onSuccess = () => {},
  onError = () => {},
  userId,
  template,
  ...props
}) {
  const queryClient = useQueryClient();

  const mutationFn = (template) => {
    const requestUrl = `${process.env.FRONT_API_URL}/users/${userId}/templates/${template._id}`;

    return request(requestUrl, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(template),
    });
  };

  return useMutation({
    mutationFn,
    onSuccess: async (template) => {
      const templates = queryClient.getQueryData(['templates']);
      const newTemplates = map(templates, (prevTemplate) => prevTemplate._id === template._id ? template : prevTemplate);

      queryClient.setQueryData(['templates'], newTemplates);

      onSuccess();

      return newTemplates;
    },
    onError,
  });

}
