import React from 'react';
import { object, func, oneOf } from 'prop-types';
import { get, keys, isEmpty, size } from 'lodash';
import {  injectIntl } from 'react-intl';
import { Col, Row } from 'antd';
import {  Pagination } from '@seekube-tech/ui-kit';
import { toJS } from '@/utils/index';
import Separator from '@/components/Separator';
import { CardLive } from '@/components/CardLive';
import { CalendarLive } from '@/components/CalendarLive';
import SearchResultsStats from '@/components/SearchResultsStats';
import {
  BlankCactus
} from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Conferences/containers/ConferencesList/BlankCactus';
import SearchEngine from '@/scenes/Event/scenes/Candidate/scenes/JobDating/components/SearchEngine';
import { useSelector } from 'react-redux';
import { getAuthUser } from '@/store/auth/selectors';

const ConferencesList = ({
  event,
  onSearch,
  conferencesQuery,
  handleActions,
  goToDiscovery,
  listContext,
}) => {
  const authUser = useSelector(getAuthUser)?.toJS();

  if (!event) { return '';}

  const totalResult = {
    discovery: {
      total: conferencesQuery.data?.countUpcoming,
    },
    confirmed: {
      total: conferencesQuery.data?.countPast,
    }
  }

  const onActionClick = (actionType, conference) => {
    handleActions(actionType, conference);
  };

  const handleOnChangePagination = (page) => {
    onSearch({ page, offset: (page * conferencesQuery.data?.limit) - conferencesQuery.data?.limit });
    window.scrollTo(0, 0);
  }

  return (
    <div className="layout-large">
      <Separator height={34} />

      <SearchEngine
        event={event}
        onSearch={onSearch}
        context="conference"
        facetsConferences={conferencesQuery.data?.facets}
        calendarLive={<CalendarLive
          event={event}
          onSelect={onSearch}
          liveDates={conferencesQuery.data?.displayConferencesBeginAt}
        />}
      />

      <SearchResultsStats
        intlId="live.pl"
        variant="full"
        count={size(conferencesQuery.data?.docs)}
        total={totalResult[listContext]?.total}
        style={{ marginBottom: '30px', color: '' }}
      />

      <div className="mb-32">
        <Row gutter={25} className="gutter-row">
          {
            conferencesQuery.data?.countAll > 0 && (
              <div className="hidden-mobile">
                <Col xs={12} sm={6} md={6} lg={4}>
                  <CalendarLive
                    event={event}
                    onSelect={onSearch}
                    liveDates={conferencesQuery.data?.displayConferencesBeginAt}
                  />
                </Col>
              </div>
            )
          }
          {
            isEmpty(conferencesQuery.data?.docs) && !conferencesQuery.loading && (
              <BlankCactus
                isConfirmedView={listContext === 'confirmed'}
                goToDiscovery={goToDiscovery}
                total={conferencesQuery.data?.countAll}
              />)
          }
          {conferencesQuery.data?.docs?.map((conf) => (
            <Col xs={12} sm={6} md={6} lg={4} key={conf._id}>
              <CardLive
                authUser={authUser}
                filterByTopicEnable={get(event, 'modules.live.filterByTopicEnable')}
                conference={conf}
                event={event}
                context="owner"
                handleAction={onActionClick}
              />
            </Col>
          ))}
        </Row>
      </div>

      <div className="pagination-container">
        <Pagination
          className="flex justify-center"
          current={conferencesQuery.data?.page + 1}
          pageSize={conferencesQuery.data?.limit}
          total={conferencesQuery.data?.total}
          onChange={handleOnChangePagination}
        />
      </div>
    </div>
  );
}


ConferencesList.propTypes = {
  conferencesQuery: object,
  event: object,
  onSearch: func,
  handleActions: func,
  goToDiscovery: func,
  listContext: oneOf(['confirmed', 'discovery']),
};

export default toJS(injectIntl(ConferencesList));
