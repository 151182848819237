import { defineMessages } from 'react-intl';

export default defineMessages({
  matchingDashboardTitle: {
    id: 'event.matching.dashboard.title',
  },
  matchingDashboardHelp1: {
    id: 'event.matching.dashboard.help.1',
  },
  matchingDashboardHelp2: {
    id: 'event.matching.dashboard.help.2',
  },
  matchingOnboardingTitle: {
    id: 'event.matching.onboarding.title',
  },
  matchingOnboardingHelp: {
    id: 'event.matching.onboarding.help',
  },
  matchingHelp: {
    id: 'event.matching.help',
  },
  matchingCandidateProfileTitle: {
    id: 'event.matching.candidateProfile.title',
  },
  matchingCandidateProfileHelp: {
    id: 'event.matching.candidateProfile.help',
  },
  matchingSearchEngineOfferTitle: {
    id: 'event.matching.searchEngineOffer.title',
  },
  matchingSearchEngineOfferHelp: {
    id: 'event.matching.searchEngineOffer.help',
  },
  matchingSearchEngineCandidateTitle: {
    id: 'event.matching.searchEngineCandidate.title',
  },
  matchingSearchEngineCandidateHelp: {
    id: 'event.matching.searchEngineCandidate.help',
  },
  matchingOfferTitle: {
    id: 'event.matching.offer.title',
  },
  matchingOfferHelp: {
    id: 'event.matching.offer.help',
  },
  matchingDashboard: {
    id: 'event.matching.dashboard',
  },
});
