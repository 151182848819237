import React from 'react';
import { string, array, object, bool } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';
import { EventColumn } from '@/scenes/Event/scenes/Owner/scenes/OnBoarding/containers/Dashboard/components/EventColumn';
import { Tooltip } from '@/components/Tooltip';
import { ValueWithWarning } from '@/components/ValueWithWarning';
import { getKeyMomentFormats } from '@/helpers/exponent/getKeyMomentFormats';

const SubColumnsUi = () => (
  [
    {
      id: 'upcomingJobdating',
      Header: <FormattedMessage id="upcoming.events" />,
      accessor: (record) => <EventColumn key={record.key} {...record} keyMomentFormats={getKeyMomentFormats(record._exponent)} />,
      disableSortBy: true,
    },
    {
      id: 'offers',
      Header: <FormattedMessage id="offers" values={{ count: 2 }} />,
      className: 'text-center',
      accessor: ({ stats }) => <span style={{ display: 'flex', justifyContent: 'center' }}>{get(stats, 'countOffersPublished') || 0}</span>,
      disableSortBy: true,
    },
    {
      id: 'lives',
      Header: <FormattedMessage id="live.pl" values={{ count: 2 }} />,
      accessor: (record) => (
        <span style={{ display: 'flex', justifyContent: 'center' }}>
            {get(record, 'modules.live.recruiterEnable') ? get(record.stats, 'countConferences') || 0
              : <Tooltip color="dark" placement="bottom" title={<FormattedMessage id="client.member.tooltip.not.allow.to.publish.live" />} >-</Tooltip>}
          </span>
      ),
      disableSortBy: true,

    },
    {
      id: 'physicalCountAppointment',
      Header: <FormattedMessage id="interviews.slots" />,
      SubHeaders: [<FormattedMessage id="available.pl" values={{ count: 1 }} />, <FormattedMessage id="booked.plgr"  values={{  count: 1, gender: 'male' }} />],
      accessor: ({ stats }) => [
        <ValueWithWarning value={stats?.countSlotsAvailable} />,
        <ValueWithWarning value={stats?.countAppointmentInterview} />
      ],
      disableSortBy: true,
    },
    {
      id: 'countInformal',
      Header: <FormattedMessage id="slots.informal1to1" />,
      SubHeaders: [<FormattedMessage id="available.pl" values={{ count: 1 }} />, <FormattedMessage id="booked.plgr"  values={{  count: 1, gender: 'male' }} />],
      accessor: (record) => [
        <ValueWithWarning enableValue={record.modules?.informal1to1?.enable} value={record.stats?.countInformalSlotsAvailable} />,
        <ValueWithWarning enableValue={record.modules?.informal1to1?.enable} value={record.stats?.countAppointmentInformal} />
      ],
      disableSortBy: true,
    }
  ]
);

SubColumnsUi.propTypes = {
  tool: object,
  createdAt: string,
  _organization: object,
  users: array,
  locale: string,
  event: object,
  isHybrid: bool,
};

export default SubColumnsUi;
