import { useQuery } from 'react-query';
import request from '@/utils/request';
import { objectToParams } from '@/utils/url';

export function useGetOrganizations({ queryKey, keywords = '', limit = 40, type = 'company', noMongo = false, isMerged, ...props }) {
  const params = objectToParams({ limit, noMongo, type, isMerged });

  const requestUrl = `${process.env.FRONT_API_URL}/organizations/search?name=${keywords}&${params}`;
  const queryFn = () => request(requestUrl, { method: 'GET' });

  const query = useQuery({
    queryKey: ['organizations', queryKey, keywords],
    queryFn,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    ...props,
  });
  return query;
}
