import { trackError } from './analytics/helpers';

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
const parseJSON = (response) => response.text().then((text) => text ? JSON.parse(text) : {});

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
const checkStatus = (response) => {
  if (response.status >= 200 && response.status < 400) {
    return response;
  }

  const error = new Error(response.statusText);
  error.response = response;

  trackError(error);

  throw error;
};

/**
 * Requests a URL, returning a promise
 *
 * @param {string} url       The URL we want to request
 * @param {object} [options] The options we want to pass to "fetch"
 * @param {Array<string>} ignoreCodeError Ignore code error
 *
 * @return {object}           The response data
 */
const requestExternal = async (url, options, ignoreCodeError = []) => {

  const newOptions = {
    ...options,
    headers: {
      ...(options?.headers || {}),
      pragma: options?.headers?.pragma || 'no-cache',
      'cache-control': options?.headers?.['cache-control'] ?? 'no-cache',
    },
  };

  const request = await fetch(url, newOptions);

  if (request.ok) {
    return parseJSON(request);
  }

  const error = new Error(request.statusText);

  if (ignoreCodeError.includes(request.status)) {
    throw error;
  }

  trackError(error);

  throw error;
};

const requestFile = (url, options) => fetch(url, options)
.then(checkStatus)
.then(parseJSON);

export { requestFile, checkStatus, parseJSON };
export default requestExternal;
