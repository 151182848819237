import React, { Component } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { isEmpty } from 'lodash';
import { func, array } from 'prop-types';
import { toJS } from '@/utils';
import { criterionActions, criterionSelectors } from '@/store/criterion';

export const withCriterion = () => (WrappedComponent) => {
  class RequiredCriterion extends Component {
    static propTypes = {
      getDefaultCriteria: func,
      defaultCriteria: array,
    };

    constructor(props) {
      super(props);
      if (isEmpty(props.defaultCriteria)) { props.getDefaultCriteria(); }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }
  const mapStateToProps = createStructuredSelector({
    defaultCriteria: criterionSelectors.getDefaultCriteria,
  });

  const mapDispatchToProps = {
    getDefaultCriteria: criterionActions.getDefaultCriteria,

  };

  const withConnect = connect(mapStateToProps, mapDispatchToProps);
  return compose(withConnect)(toJS(RequiredCriterion));
};
