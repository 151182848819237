import { track } from '@/utils/analytics';
import { ANALYTICS_RECRUITER } from '@/utils/constants';

export const trackRecruiterClickedCreatedSlots = ({ authUser, event, clickedButton }) => (
  track({
    name: ANALYTICS_RECRUITER.CLICKED_CREATED_SLOTS,
    user: authUser,
    event,
    properties: {
      clickedButton,
    },
    intercom: true,
  })
);
