import { makeStyles } from "@seekube-tech/ui";

export default makeStyles((theme) => ({
  title: {
    marginTop: theme.spacing(0),
  },
  filters: {
    marginTop: theme.spacing(2.5),
  },
  countResult: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(3),
  },
  pagination: {
    marginTop: theme.spacing(5),
  },
  container: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  }
}));
