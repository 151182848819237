import React, { useEffect } from 'react';
import { bool, func, number, object, string } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import Title from '@/scenes/Event/components/Title';
import styles from '@/scenes/Event/containers/Landing/components/Contents/components/GeneralInfos/components/LandingContent/styles.less';

const Text = ({
  event,
  contentIndex,
  editMode,
  onChange,
  onInit,
  locale,
}) => {
  const t = useFormatMessage();

  useEffect(() => {
    if (onInit) {
      onInit(contentIndex)({
        inNav: false,
        label: t({ id: 'event.content.text.label' }),
        h2: t({ id: 'event.content.text.h2' }),
        h3: t({ id: 'event.content.text.h3' }),
        small: t({ id: 'event.content.text.small' }),
      });
    }
  }, [])

  return (
    <div>
      <div className={styles.textWrapper}>
        <Title
          event={event}
          contentIndex={contentIndex}
          editMode={editMode}
          onChange={onChange}
          locale={locale}
        />
      </div>
    </div>
  );
}

Text.propTypes = {
  event: object,
  contentIndex: number,
  editMode: bool,
  onChange: func,
  onInit: func,
  locale: string,
}

export default Text;
