import React, { PureComponent } from 'react';
import { object, func, string } from 'prop-types';
import { injectIntl } from 'react-intl';
import { keys, isEmpty, split, includes } from 'lodash';
import { Form } from 'antd';
import { Button } from '@seekube-tech/ui-kit';
import { toJS } from '@/utils';
import InputMaterial from '@/components/Input/InputMaterial';
import Modal from '@/components/Modal';
import styles from './styles.less';

const FormItem = Form.Item;

class PrivateModal extends PureComponent {
  static propTypes = {
    intl: object,
    push: func,
    form: object,
    password: string,
  };

  onPasswordSubmit = (target) => {
    target.preventDefault();
    const { props: { form, push, password } } = this;
    const fieldsValue = form.getFieldsValue();
    const fields = keys(fieldsValue);
    const queries = fields.map((field) => (`${field}=${fieldsValue[field]}`));
    const {location} = window;

    if (form.getFieldValue('pwd') === password) {
      location.replace(`${location.origin}${location.pathname}?${queries.join('&')}`);
    } else {
      push(`?${queries.join('&')}`);
    }
  };

  render() {
    const { props: { intl, password, form: { getFieldDecorator } }, onPasswordSubmit } = this;
    const url = location.search.slice(1, location.search.length);
    const initialValue = split(location.search.slice(0, location.search.length), '=')[1];
    const isPasswordCorrect = includes(split(url, '&'), `pwd=${password}`);
    const help = isEmpty(initialValue) ? '' : `${intl.formatMessage({ id: 'form.password.error' })} ${intl.formatMessage({ id: 'event.private.password.error'})}`;

    return (
      <div className={styles.pageContainer}>
        <Modal
          zIndex={1}
          footer={false}
          header={false}
          visible
          mask={false}
          className={styles.passwordModal}
          closable={false}
        >
          <h3>{intl.formatMessage({ id: 'event.private' })}<br />
            {intl.formatMessage({ id: 'event.private.password.enquire' })}</h3>
          <Form onSubmit={onPasswordSubmit}>
            <FormItem validateStatus={isPasswordCorrect ? '' : 'error'} help={help}>
              {getFieldDecorator('pwd', {
                initialValue: split(location.search.slice(0, location.search.length), '=')[1],
                rules: [
                  { required: false, message: ' ' },
                ],
              })(
                <InputMaterial addOnIcon="password-lock" label={intl.formatMessage({ id: 'form.password' })} placeholder={intl.formatMessage({ id: 'form.password' })} type="text" required validations={[]} />
              )}
            </FormItem>
            <Button className="w-full" type="submit">
              {intl.formatMessage({ id: 'submit'})}
            </Button>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default Form.create()(injectIntl(toJS(PrivateModal)));
