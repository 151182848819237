import React from 'react';
import { object } from 'prop-types';
import {
  Body1,
  H4,
  IconBirthday,
  IconCandidates,
  IconEuro,
  IconFlag,
  IconHome,
  IconPieChart,
  IconUsers
} from '@seekube-tech/ui-kit';

import { useFormatMessage } from 'react-intl-hooks';
import colors from '@seekube-tech/ui-kit/dist/colors';
import styles from './styles.less'

const KeyNumbersCandidate = ({
  profile,
}) => {

  const t = useFormatMessage()

  const getIcons = (name) => {
    switch (name) {
      case 'employees_number':
        return <IconCandidates size={16} />
      case 'remote_working':
        return <IconHome size={16} />
      case 'year_founded':
        return <IconFlag size={16} />
      case 'age_avg':
        return <IconUsers size={16} />
      case 'sales_revenue':
        return <IconEuro size={16} />
      case 'seniority_avg':
        return <IconBirthday size={16} />
      case 'gender_parity':
        return <IconPieChart size={16} />
      default:
        return <IconCandidates size={16} />
    }
  }

  const getValue = (key, value) => {
    switch (key) {
      case 'employees_number':
        return t({ id: `stand.employees.${value}`})
      case 'remote_working':
        return t({ id: `stand.remote.${value}`})
      default:
        return value;
    }
  }

  if (!profile?.keyNumbers) {
    return null
  }

  return (
    <div className={styles.keyNumbersCandidateContainer}>
      <H4>{t({ id: 'stand.navbar.home' })}</H4>
      <div className={styles.keyNumbersCards}>
        {Object.entries(profile.keyNumbers).map((keyNumber, index) => {
          if (keyNumber[1]) {
            return (
              <div key={index} className={styles.keyNumber}>
                <div className={styles.icon}>{getIcons(keyNumber[0])}</div>
                <Body1 color={colors.neutral['500']} className={styles.title}>{t({ id: keyNumber[0] })}</Body1>
                <Body1 color={colors.neutral['500']} fontWeight={700} className={styles.value}>{getValue(keyNumber[0], keyNumber[1])}</Body1>
              </div>
            )
          }
        })}
      </div>
    </div>
  )
}

KeyNumbersCandidate.propTypes = {
  profile: object
}

export default KeyNumbersCandidate;
