import { array, string } from 'prop-types';
import * as React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { H4 } from '@seekube-tech/ui-kit';
import { Bloc, BlocHeader } from '@/components/Bloc';
import classNames from 'classnames';
import { REPORT_PHASES } from '../../../const';
import ReportData from './ReportData';
import { ReportPostNow } from './ReportPostNow';
import styles from '../styles.less';

const Title = ({ phase }) => {
  const t = useFormatMessage();

  const getTitle = () => {
    switch (phase) {
      case REPORT_PHASES.registrationAndActivitiesOfCandidates:
        return t({ id: 'owner.reporting.registrationAndActivitiesOfCandidates.title' });
      case REPORT_PHASES.registrationAndPreparationOfCompanies:
        return t({ id: 'owner.reporting.registrationAndPreparationOfCompanies.title' });
      case REPORT_PHASES.closingOfAppointmentSchedules:
        return t({ id: 'event.keydates.interactionsClosing.title' });
      case REPORT_PHASES.appointmentProcessing:
        return t({ id: 'owner.reporting.appointmentProcessing.title' });
      case REPORT_PHASES.review:
        return t({ id: 'report.pl' }, { count: 1 });

      default: return t({ id: 'owner.reporting.registrationAndActivitiesOfCandidates.title' });
    }
  }


  return (
    <H4 fontWeight={600} className="text-neutral-500">
      {getTitle()}
    </H4>
  )
}

Title.propTypes = {
  phase: string,
}

function ReportPhase({ name, reports }) {
  const t = useFormatMessage();

  return (<>
    <Bloc>
      <BlocHeader className={classNames(styles.spaceBetween, styles.mb16)}>
        <Title phase={name} />
        <ReportPostNow nextReportToSend={reports?.[0]} />
      </BlocHeader>

      <>
        {reports?.map(report => <ReportData report={report} />)}
      </>
    </Bloc>
  </>)
}

ReportPhase.propTypes = {
  name: string,
  reports: array
}

export default ReportPhase;
