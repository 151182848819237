import { defineMessages } from 'react-intl';

export default defineMessages({
  unconfirmed: {
    id: 'recruiter.participantView.interview.unconfirmed.description.find-here-candidate',
  },
  confirmed: {
    id: 'recruiter.participantView.interview.confirmed.description',
  },
  refused: {
    id: 'recruiter.participantView.interview.refused.description',
  },
  pending: {
    id: 'recruiter.participantView.interview.pending.description',
  },
  transferred: {
    id: 'recruiter.participantView.interview.pending.transferred',
  },
  sourcing: {
    id: 'recruiter.participantView.sourcing.description',
  },
  pendingList: {
    id: 'recruiter.participantView.application.description',
  },
  application: {
    id: 'recruiter.participantView.application2.description',
  },
  pendingListTitle: {
    id: 'participants.results.applications',
  },
  applicationTitle: {
    id: 'participants.results.applications2',
  },
  interviewTitle: {
    id: 'recruiter.participantsList.add.to.interview',
  },
});
