import React from 'react';
import { func, object } from 'prop-types';

// Components
import { FormattedMessage } from 'react-intl';
import { Form, Input, Radio } from 'antd';
import { Button } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { Tooltip } from '@/components/Tooltip';
import { Bloc, BlocHeader } from '@/components/Bloc';

// Services
import ApiToForm from '../../../../services/formMapping/ApiToForm';

// Antd
const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;

const Informal1to1Form = ({
  form: { validateFields, getFieldDecorator },
  initialValues: {
    participantRedirection,
    modules,
  },
  onSubmit,
}) => {
  const t = useFormatMessage();

  const handleSubmit = (e) => {
    e.preventDefault();

    return validateFields(onSubmit);
  };

  return (
    <Bloc>
      <Form onSubmit={handleSubmit} style={{ maxWidth: '500px', textAlign: 'left' }} layout="vertical">
        <BlocHeader>
          <FormattedMessage id="chat.oneToOne.pl" values={{ count: 2 }} tagName="h2" />
        </BlocHeader>


        <FormItem label={<FormattedMessage id="event.modules.activation.and.tabs.offers.activate" />}>
          {getFieldDecorator('enable', {
            initialValue: ApiToForm.module(modules, 'informal1to1', 'enable'),
            rules: [{ required: true, message: 'Merci de renseigner le label' }],
          })(
            <RadioGroup>
              <RadioButton value ><FormattedMessage id="yes" /></RadioButton>
              <Tooltip
                title={participantRedirection === 'informal1to1' && <FormattedMessage id="event.modules.prevent.no.tooltip.text" values={{ text: <FormattedMessage id="offers.plural" values={{ count: 2 }} /> }} />}
                color="dark"
                placement="bottom"
              >
                <RadioButton
                  disabled={participantRedirection === 'informal1to1'}
                  value={false}
                >
                  <FormattedMessage id="no" />
                </RadioButton>
              </Tooltip>
            </RadioGroup>
          )}
        </FormItem>

        <FormItem label={<FormattedMessage id="event.modules.activation.and.tabs.label.fr" />}>
          {getFieldDecorator('label_fr', {
            initialValue: ApiToForm.module(modules, 'informal1to1', 'label_fr'),
            rules: [{ required: true, message: 'Merci de renseigner le label' }],
          })(
            <Input />
          )}
        </FormItem>

        <FormItem label={<FormattedMessage id="event.modules.activation.and.tabs.label.fr.pl" />} >
          {getFieldDecorator('labelMultiple_fr', {
            initialValue: ApiToForm.module(modules, 'informal1to1', 'labelMultiple_fr'),
            rules: [{ required: true, message: 'Merci de renseigner le label' }],
          })(
            <Input />
          )}
        </FormItem>

        {/* labelMultiple */}
        <FormItem label={<FormattedMessage id="event.modules.activation.and.tabs.label.en" />}>
          {getFieldDecorator('label_en', {
            initialValue: ApiToForm.module(modules, 'informal1to1', 'label_en'),
            rules: [{ required: true, message: 'Merci de renseigner le label' }],
          })(
            <Input />
          )}
        </FormItem>

        <FormItem label={<FormattedMessage id="event.modules.activation.and.tabs.label.en.pl" />}>
          {getFieldDecorator('labelMultiple_en', {
            initialValue: ApiToForm.module(modules, 'informal1to1', 'labelMultiple_en'),
            rules: [{ required: true, message: 'Merci de renseigner le label' }],
          })(
            <Input />
          )}
        </FormItem>

        {/* Submit */}
        <div>
          <Button type="submit">
            {t({ id: 'save' })}
          </Button>
        </div>
      </Form>
    </Bloc>
  );
};

Informal1to1Form.propTypes = {
  onSubmit: func.isRequired,
  form: object,
  initialValues: object,
};

export default Form.create()(Informal1to1Form);
