import React, { PureComponent } from 'react';
import { any, array, func, string, object, bool } from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import {
  isEmpty,
  isUndefined,
  find,
  get,
  filter,
  map,
  flatten,
  lowerFirst,
  trimStart,
  flow,
  debounce,
  toString, without,
} from 'lodash';
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from 'react-intl';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Dropdown, Menu } from 'antd';
import { IconUserShare, IconMail, IconX, IconChevronsRight, IconDownload, ButtonRound } from '@seekube-tech/ui-kit';

import { queryStringToObject } from '@/utils/url';
import {
  ANALYTICS_RECRUITER,
  APPOINTMENT_ORIGIN,
  EVENT_FORMAT,
  TEMPLATES,
} from '@/utils/constants'
import { CONTEXTS, CONTEXTS_PROCESS_JD } from '@/utils/global';
import { LOCAL_STORAGE, getLocalStorage } from '@/utils/localStorage';
import { toJS } from '@/utils';
import { track, trackRecruiterClickedViewKeyDates, trackRecruiterSentNegativeMail } from '@/utils/analytics';
import { participantSelectors } from '@/store/participant';
import { getAvailableActions } from '@/store/availableActions/selectors';
import { isHybrid } from '@/helpers/event/isHybrid';
import { Tooltip } from '@/components/Tooltip';
import MoreDropdown from '@/components/MoreDropdown';
import ModalFull from '@/components/ModalFull';
import Icon from '@/components/Icon';
import ParticipantCards from '@/components/ParticipantCards';
import ModalContact from '@/components/ModalContact';
import { If } from '@/components/If';
import notification from '@/components/Notification';
import { interactionActions } from '@/store/interaction';
import { SeparatorParticipationExpired } from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Jobs/components';
import { isParticipatingToAllHybridFormats } from '@/helpers/participant/isParticipatingToAllHybridFormats';
import { getEventPhases, isInteractionClosed } from '@/store/availableActions/utils/event';
import ModalAtsImport from '@/components/ModalAtsImport';
import { CardNavigation } from './components/CardNavigation';
import { AcceptButton } from './components';
import styles from './styles.less';
import messages from './messages';


const { SubMenu } = Menu;

const ATS_TABS = ['unconfirmed', 'interview', 'confirmed', 'top', 'save', 'refused', 'refuse'];

class ParticipantList extends PureComponent { // eslint-disable-line
  static propTypes = {
    participants: array,
    offers: array,
    defaultParticipant: any,
    isAllParticipantChecked: bool,
    showMoving: bool,
    exponentUser: object,
    event: object,
    openedParticipant: object,
    pagination: object,
    authUser: any,
    onShowParticipant: func,
    onCloseParticipant: func,
    handleCardToggle: func,
    context: string,
    handleOnShowAppointmentModal: func,
    handleConfirmAppointment: func,
    handleBanParticipant: func,
    handleCancelAppointment: func,
    handleChangeAppointmentOwner: func,
    handleChangeInteractionOwner: func,
    handleChangeAppointmentDate: func,
    handleDisplayContact: func,
    handleDownload: func,
    handleGroupedAction: func,
    handleMove: func,
    handleToogleCoreTarget: func,
    highlightWords: array,
    handleProfileNavigation: func,
    getInteractions: func,
    exponent: object,
    location: object,
    interactions: array,
    newDate: object,
    intl: object,
    participantActionBtnDisabled: object,
    checkedParticipants: array,
    participantsFetching: object,
    participantsCoreTarget: object,
    match: object,
    availableActions: object,
  };

  state = {
    animationModal: false,
    lastPage: 1,
    isLoaded: true,
    modalProfileIsOpen: false,
    userProfile: null,
    currentParticipant: null,
    checkedParticipants: [],
    searchExponentUser: null,
    defaultParticipantLoaded: false,
    isModifyAppointmentModalVisible: false,
    modalAtsIsVisible: false,
    modalAtsParticipantId: null,
    lastConfirmedRefresh: moment().unix(),
  };

  componentDidMount() {
    const { props: { location, participants } } = this;
    location.query = queryStringToObject(location.search);

    if (!isEmpty(location.query) && !isEmpty(location.query.participant) && location.query.participant !== 'null') {
      const participant = participants.find((item) => location.query.participant === item?._id);

      if (participant) {
        this.showProfile(participant, true);

        if (!isEmpty(location.query.proposeModal) && location.query.proposeModal !== 'null') {
          this.handleOnPropose(null, participant);
        }
      }
    }

    window.addEventListener('blur', this.handleRefreshConfirmedInteractions);
    window.addEventListener('focus', this.handleRefreshConfirmedInteractions);
  }

  /**
   * ComponentWillReceiveProps
   */
  componentWillReceiveProps() {
    const {
      props: { defaultParticipant, participants, pagination, openedParticipant },
      state: { defaultParticipantLoaded },
    } = this;

    const newState = {};

    if (defaultParticipantLoaded === false && !isEmpty(openedParticipant)) {
      this.showProfile(openedParticipant, true);

      newState.defaultParticipantLoaded = true;
    } else if (!isEmpty(defaultParticipant) && !isEmpty(participants) && defaultParticipantLoaded === false) {
      const participant = participants.find((item) => defaultParticipant === item._id);

      if (!isEmpty(participant)) {
        this.showProfile(participant, true);
      }

      newState.defaultParticipantLoaded = true;
    }

    if (pagination && pagination.pageSize) {
      const lastPage = parseInt(pagination.total / pagination.pageSize, 10) + 1;

      if (this.state.lastPage !== lastPage) {
        newState.lastPage = parseInt(pagination.total / pagination.pageSize, 10) + 1;
      }
    }

    if (!isEmpty(newState)) {
      this.setState(newState);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('blur', this.handleRefreshConfirmedInteractions);
    window.removeEventListener('focus', this.handleRefreshConfirmedInteractions);
  }

  onCloseModal = () => {
    const { props: { onCloseParticipant } } = this;
    this.setState({ modalProfileIsOpen: false, currentParticipant: null });
    onCloseParticipant();
  };

  handleOpenAtsModal = (participant) => {
    this.onCloseModal();
    this.setState({ modalAtsIsVisible: true, modalAtsParticipant: participant });
  }

  handleOnCloseModalAts = () => this.setState({ modalAtsIsVisible: false, modalAtsParticipant: null });

  trimStartBy = (word, char) => flow(trimStart, lowerFirst)(word, char);

  getActionsFromAdmin = (participant, size, context, appointment) => {
    const {
      props: {
        handleDownload,
        intl,
        participantActionBtnDisabled,
        handleToogleCoreTarget,
        participantsCoreTarget,
        event,
      },
      handleOnMove,
      trimStartBy,
    } = this;

    const applicationHmlId = `appActions${participant._id}${size}`;
    const modalName = (participant.interactions || []).filter((interaction) => interaction._appointment).length ? 'cannotRemoveParticipantFromJd' : 'removeParticipantFromJd';
    const movableContexts = ['admintop', 'adminsave', 'adminrefuse']
      .filter((itemContext) => itemContext !== context)
      .map(
        (movableContext, i) => (
          <Menu.Item key={i}>
            <a role="button" onClick={(e) => handleOnMove(e, participant, movableContext)} tabIndex={0}>{intl.formatMessage(messages[`addTo.${trimStartBy(movableContext, 'admin')}`])}</a>
          </Menu.Item>
        )
      );

    const otherActions = (
      <Menu getPopupContainer={() => document.getElementById(applicationHmlId)}>
        <Menu.Item>
          <a role="button" onClick={() => handleDownload(participant)} tabIndex={0}><Icon name="download" /> {intl.formatMessage(messages.downloadCV)}</a>
        </Menu.Item>
      </Menu>
    );

    const isCoreTarget = participantsCoreTarget?.[participant._id] !== undefined ? participantsCoreTarget[participant._id] : participant.isCoreTarget;

    return (
      <div id={applicationHmlId} className="flex justify-center">
        {event?.enableCoreTarget && (
          <Tooltip color="dark" placement="top" title={intl.formatMessage({ id: 'event.owner.candidates.coreTarget' })} trigger="hover">
            <ButtonRound
              variant={isCoreTarget ? 'fill' : 'border'}
              color={isCoreTarget ? 'primary' : 'neutral'}
              className="mr-10"
              onClick={handleToogleCoreTarget(participant._id, isCoreTarget)}
            >
              <Icon name={isCoreTarget ? 'core-target-selected' : 'core-target'} />
            </ButtonRound>
          </Tooltip>
        )}

        <Tooltip color="dark" placement="top" title={intl.formatMessage({ id: 'recruiter.participantsList.move' })}>
          <Dropdown
            trigger="click"
            overlay={(
              <Menu>
                {movableContexts}
              </Menu>
            )}
            placement="bottomCenter"
          >
            <ButtonRound
              variant="border"
              color="neutral"
              className="mr-10"
            >
              <IconChevronsRight />
            </ButtonRound>
          </Dropdown>
        </Tooltip>

        <Tooltip color="dark" placement="top" title={intl.formatMessage(messages.tooltipRemoveCandidateFromJb)} >

          <ButtonRound
            variant="border"
            color="neutral"
            className="mr-10"
            onClick={(e) => this.openModal(e, appointment, participant, modalName)}
            disabled={(participantActionBtnDisabled && participantActionBtnDisabled[participant._id])}
          >
            <IconX />
          </ButtonRound>

        </Tooltip>
        <MoreDropdown overlay={otherActions} placement="bottomRight" className={styles.moreButton} />
      </div>
    );
  };

  getActionsFromSourcing = (participant, size, subActions) => {
    const {
      props: {
        handleDownload,
        intl,
        participantActionBtnDisabled,
        event,
        authUser,
        availableActions,
        exponent,
      },
      handleOnPropose,
      handleOnHide,
      analyticsHidCandidate,
    } = this;

    const applicationHmlId = `appActions${participant._id}${size}`;
    const moveItem = (<span className={styles.iconHover}><Icon name="move" /> {intl.formatMessage(messages.move)}</span>);

    const otherActions = (
      <Menu getPopupContainer={(triggerNode) => triggerNode.parentNode}>
        <Menu.Item key={subActions.length}>
          <a role="button" onClick={() => handleDownload(participant)} tabIndex={0}><Icon name="download" /> {intl.formatMessage(messages.downloadCV)}</a>
        </Menu.Item>

        <SubMenu title={moveItem}>
          {subActions}
        </SubMenu>
      </Menu>
    );

    return (
      <div id={applicationHmlId} className={styles.actions}>
        <Tooltip getPopupContainer={(triggerNode) => triggerNode.parentNode} color="dark" placement="bottom" title={intl.formatMessage(messages.hide)} >
          <ButtonRound
            variant="border"
            color="neutral"
            className="mr-10"
            onClick={(e) => handleOnHide(e, participant, analyticsHidCandidate)}
            disabled={participantActionBtnDisabled && participantActionBtnDisabled[participant._id]}
          >
            <IconX />
          </ButtonRound>
        </Tooltip>

        {!availableActions.proposeAppointment?.isHidden({ event, authUser }) ? (
          <Tooltip
            overlayStyle={{ minWidth: !availableActions.acceptApplication.isDisabled({ event, participant, exponent }) ? '157px' : '365px' }}
            color="dark" placement="bottom"
            align="left"
            title={!availableActions.acceptApplication.isDisabled({ event, participant, exponent }) ? intl.formatMessage(messages.appointmentPropose) :
              <>
                <FormattedHTMLMessage
                  id="recruiter.participantsList.appointment.propose.disabled"
                />
                <a
                  href={`${document.location.origin}/${this.props.match.params.eventSlug}/recruiter/keydates`}
                  target="_blank"
                  onClick={() => trackRecruiterClickedViewKeyDates({ authUser })}
                >
                  <FormattedMessage id="see.keyDates" />
                </a>
              </>}
          >
            <div>
              <ButtonRound
                variant="fill"
                color="success"
                disabled={availableActions.acceptApplication.isDisabled({ event, participant, exponent })}
                onClick={(e) => handleOnPropose(e, participant)}
              >
                <IconMail />
              </ButtonRound>
            </div>
          </Tooltip>
        ) : null}

        <MoreDropdown overlay={otherActions} placement="bottomRight" />
      </div>
    );
  };

  getMoveActions = (context, participant) => {
    const { props: { intl, handleGroupedAction }, handleOnMove } = this;
    const moveAvailable = without(['top', 'save', 'refuse'], context);

    const onClick = (e, moveType) => (
      getLocalStorage(LOCAL_STORAGE.recruiter.hideConfirmOnParticipantMove) === 'true' || context !== 'applications' ?
        handleOnMove(e, participant, moveType) :
        handleGroupedAction('move', moveType, participant, false)
    );

    return !['unconfirmed', 'confirmed'].includes(context) && map(moveAvailable, (moveType, index) => (
      <Menu.Item key={index}>
        <a role="button" onClick={(e) => onClick(e, moveType)} tabIndex={0}>{intl.formatMessage(messages[`addTo.${moveType}`])}</a>
      </Menu.Item>
    ));
  };

  getActionsFromContext(participant, context, appointment, size, hasAtsInteraction, interaction) {
    const {
      props: {
        authUser,
        handleDownload,
        event,
        intl,
        participantActionBtnDisabled,
        availableActions,
        exponent,
        exponentUser,
      },
      handleOnRefuse,
      handleOnPropose,
      handleOnConfirmAppointment,
      handleOpenAtsModal,
    } = this;
    let otherActions = null;

    if (!participant || !event) {
      return '';
    }

    const applicationHmlId = `appActions${participant._id}${size}`;
    const moveItem = (<span className={styles.iconHover}><Icon name="move" /> {intl.formatMessage(messages.move)}</span>);
    const subActions = this.getMoveActions(context, participant);
    const isAppointmentPast = moment().isAfter(appointment?._timeslot?.beginAt)

    if (['adminall', 'admintop', 'adminrefuse', 'adminsave'].includes(context)) {
      return this.getActionsFromAdmin(participant, size, context, appointment);
    }
    const recruiterInCharge = (
      <Menu.Item key={`app-change-${participant._id}`}>
        <a role="button" onClick={(e) => this.openModal(e, appointment, participant, 'modifyRecruiter')} tabIndex="0"><Icon
          name="edit-role"
        /> {intl.formatMessage(messages.appointmentChangeRecruiterInCharge)}</a>
      </Menu.Item>);

    const recruiterApplicationInCharge = (
      <Menu.Item key={`app-change-${participant._id}`}>
        <a role="button" onClick={(e) => this.openModal(e, null, participant, 'modifyApplicationRecruiter', interaction)} tabIndex="0"><Icon
          name="edit-role"
        /> {intl.formatMessage(messages.appointmentChangeRecruiterInCharge)}</a>
      </Menu.Item>);

    const downloadCv = (
      <Menu.Item key="download-cv">
        <a role="button" onClick={() => handleDownload(participant)} tabIndex={0}>
          <Icon name="download" />{intl.formatMessage(messages.downloadCV)}
        </a>
      </Menu.Item>
    );
    const appointmentChangeDate = (
      <Menu.Item key="unconfirmed-change-date" disabled={!availableActions.acceptApplication.isRecruiterHasAvailableTimeslots({ event, participant, exponent, recruiter: exponentUser })}>
        <a role="button" disabled={!availableActions.acceptApplication.isRecruiterHasAvailableTimeslots({ event, participant, exponent, recruiter: exponentUser })} onClick={(e) => this.openModal(e, appointment, participant, 'modifyDate')} tabIndex={0}>
          <Icon name="change-date" />{intl.formatMessage(messages.appointmentChangeDate)}
        </a>
      </Menu.Item>
    );
    const cancelAppointment = (
      <Menu.Item key="cancelAppointment">
        <a role="button" onClick={(e) => this.openModal(e, appointment, participant, 'canceled')} tabIndex="0"><Icon name="refuse" /> {intl.formatMessage(messages.appointmentCancel)}</a>
      </Menu.Item>
    );

    const appointmentPropose = (
      <Menu.Item key="appointment-propose" disabled={availableActions.acceptApplication.isDisabled({ event, participant, exponent })}>
        <a disabled={availableActions.acceptApplication.isDisabled({ event, participant, exponent })} role="button" onClick={(e) => handleOnPropose(e, participant)} tabIndex="0"><Icon name="message" /> {intl.formatMessage(messages.appointmentPropose)}</a>
      </Menu.Item>
    );

    const proposeNew = (
      <Menu.Item key="appointment-propose-new" disabled={isAppointmentPast}>
        <a role="button" disabled={isAppointmentPast} onClick={(e) => this.handleOnPropose(e, participant)} tabIndex="0">
          <Icon name="add" /> {intl.formatMessage(messages.appointmentProposeNew)}</a>
      </Menu.Item>
    );

    if (context === 'sourcing') {
      return this.getActionsFromSourcing(participant, size, subActions);
    }

    const propositionIsDisable = availableActions.proposeAppointment?.isHidden({ event, authUser });

    const syncAts = authUser._currentOrganization?.syncAts;
    const syncAtsAction = ATS_TABS.includes(context) && syncAts?.enable && (!isEmpty(exponent?.atsSource) || !isEmpty(syncAts?.config.defaultSource)) ? (<Tooltip
      overlayStyle={{ minWidth: '130px' }}
      getPopupContainer={(triggerNode) => triggerNode.parentNode}
      color="dark"
      placement="bottom"
      title={hasAtsInteraction ? intl.formatMessage({ id: 'ats.importCandidate.alreadyImport' }) : intl.formatMessage({ id: 'ats.importCandidate.import' })}
    >
      <span className="inline-block mr-10">
        <ButtonRound variant="fill" disabled={hasAtsInteraction} onClick={() => handleOpenAtsModal(participant)}>
          <IconUserShare size="24" />
        </ButtonRound>
      </span>
    </Tooltip>) : null;

    if (
      ['interview', 'save', 'top', 'refuse', 'refused', 'confirmed', 'transferred', 'adminall', 'admintop', 'adminsave', 'adminrefuse'].includes(context)) {
      const actions = [];

      if (appointment) {
        if (appointment.status === 'accepted') {
          if (get(appointment._timeslot, 'beginAt')) {
            const { beginAt, duration } = appointment._timeslot;
            const isAppointmentPast = moment().isAfter(moment(beginAt).add(duration, 'minute'));


            if (isAppointmentPast) {
              if (!propositionIsDisable) {
                actions.push(proposeNew);
              }
            } else {
              actions.push(appointmentChangeDate);
              actions.push(recruiterInCharge);
            }
          }
        }

        if (appointment.status === 'canceled' && appointment.origin !== APPOINTMENT_ORIGIN.informal1to1) { actions.push(proposeNew); }

        if (appointment.status === 'pending') { actions.push(recruiterInCharge); }

        if (appointment.status === 'pending' && interaction.type === 'application-auto') { actions.push(appointmentPropose); }

        if (appointment.status === 'refused' && appointment.origin !== APPOINTMENT_ORIGIN.informal1to1 && appointment.refusedBy !== 'candidate') { actions.push(proposeNew); }

        if ((appointment.status === 'accepted' && moment(appointment.date) > moment()) || appointment.status === 'pending') {
          actions.push(cancelAppointment);
        }
      } else if (!propositionIsDisable) {
        actions.push(appointmentPropose);
      }

      otherActions = context === 'unconfirmed' ? (
        <Menu getPopupContainer={(triggerNode) => triggerNode.parentNode}>
          {downloadCv}
          {appointmentChangeDate}
          {recruiterInCharge}
        </Menu>
      ) : (
        <Menu getPopupContainer={(triggerNode) => triggerNode.parentNode}>
          {context !== 'transferred' && actions}
          {subActions && <SubMenu title={moveItem}>
            {subActions}
          </SubMenu>}
        </Menu>
      );

      return (
        <div id={applicationHmlId} className="flex">
          <Tooltip overlayStyle={{ minWidth: '130px' }} getPopupContainer={(triggerNode) => triggerNode.parentNode} color="dark" placement="bottom" title={intl.formatMessage(messages.downloadCV)}>
            <ButtonRound className="mr-10" variant="border" color="neutral" onClick={() => handleDownload(participant)}>
              <IconDownload />
            </ButtonRound>
          </Tooltip>

          {syncAtsAction}

          <MoreDropdown overlay={otherActions} placement="bottomRight" />
        </div>
      );
    }


    otherActions = (
      <Menu getPopupContainer={(triggerNode) => triggerNode.parentNode}>
        {downloadCv}
        {context === 'applications' && recruiterApplicationInCharge}
        {subActions && <SubMenu title={moveItem}>
          {subActions}
        </SubMenu>}
      </Menu>
    );

    if (context === 'unconfirmed') {
      otherActions = (
        <Menu getPopupContainer={(triggerNode) => triggerNode.parentNode}>
          {downloadCv}
          {appointmentChangeDate}
          {recruiterInCharge}
        </Menu>
      );
    }

    return (
      <div className="applications-actions flex" id={applicationHmlId}>
        <If condition={context !== 'refused'}>
          <Tooltip color="dark" getPopupContainer={(triggerNode) => triggerNode.parentNode} placement="bottom" title={intl.formatMessage(messages.refuse)} >
            <ButtonRound
              variant="border"
              color="neutral"
              className="mr-10"
              onClick={(e) => handleOnRefuse(e, participant, null, true)}
              disabled={participantActionBtnDisabled && participantActionBtnDisabled[participant._id]}
            >
              <Icon name="times" />
            </ButtonRound>
          </Tooltip>
        </If>

        <AcceptButton
          authUser={this.props.authUser}
          context={context}
          match={this.props.match}
          event={event}
          isParticipantActionBtnDisabled={context === 'unconfirmed' ? participantActionBtnDisabled?.[participant._id] : (availableActions.acceptApplication.isDisabled({ event, participant, exponent }) || participantActionBtnDisabled?.[participant._id])}
          handleOnClick={(e) => context === 'unconfirmed' ? handleOnConfirmAppointment(e, participant, appointment) : handleOnPropose(e, participant)}
        />

        <If condition={size !== 'big' || !['confirmed', 'refused'].includes(context)}>
          <MoreDropdown overlay={otherActions} placement="bottomRight" />
        </If>
      </div>
    );
  }

  /**
   * GetNextProfile
   *
   * @description Return the next profile to show if exists
   *
   */
  getNextProfile = () => {
    const { props: { participants }, state: { currentParticipant } } = this;

    if (currentParticipant) {
      const currentIndex = participants.findIndex((item) => item && item._id === currentParticipant._id);

      return (currentIndex !== participants.length - 1) ? participants[currentIndex + 1] : null;
    }

    return null;
  };

  isSelectedAppointmentPast = () => moment(get(this.state.selectedAppointment, '_timeslot.beginAt')).isBefore(moment());

  modalAppointmentProperties = (type) => {
    switch (type) {
      case 'removeParticipantFromJd':
        return ({
          title: messages.titleBanParticipant,
          components: [{ subtitle: messages.subtitleBanParticipant }],
          disabledComponents: [],
          special: true,
          cta: {
            canBeDisabled: false,
            text: messages.btnConfirm,
            action: () => this.props.handleBanParticipant(this.state.selectedParticipant, () => { this.modals.modifyAppointment.close(); this.onCloseModal(); }),
          },
        });
      case 'cannotRemoveParticipantFromJd':
        return ({
          title: messages.titleCannotBanParticipant,
          components: [{ subtitle: messages.subtitleCannotBanParticipant }],
          disabledComponents: [],
          special: true,
          cta: {
            canBeDisabled: false,
            text: messages.btnCancel,
            action: () => this.modals.modifyAppointment.close(),
          },
        });
      case 'modifyDate':
        return ({
          title: messages.title,
          components: ['recruiter', 'offer', 'template', 'message', 'signature', 'alert'],
          disabledComponents: ['recruiter'],
          defaultMessage: this.props.intl.formatMessage(messages.defaultMessage),
          cta: {
            canBeDisabled: true,
            text: messages.appointmentChange,
            action: (a) => this.props.handleChangeAppointmentDate(a, this.state.selectedAppointment, this.modals.modifyAppointment.close),
          },
          alert: {
            text: messages.modifyAppointmentAlert,
            icon: 'light',
          },
          type: TEMPLATES.EDIT_DATE,
        });
      case 'modifyRecruiter':
        return ({
          title: messages.titleModifyRecruiterInCharge,
          components: ['recruiter', 'offer', 'template', 'message', 'signature'],
          disabledComponents: [],
          defaultMessage: this.props.intl.formatMessage(messages.defaultMessageModifyRecruiter),
          cta: {
            canBeDisabled: true,
            text: messages.appointmentChange,
            action: (a) => this.props.handleChangeAppointmentOwner(a, this.state.selectedAppointment, this.modals.modifyAppointment.close),
          },
          type: TEMPLATES.EDIT_OWNER,
        });
      case 'modifyApplicationRecruiter':
        return ({
          title: { id: 'event.recruiter.modal.modifyApplication.recruiterInCharge.title' },
          components: ['recruiter', 'offer', 'template', 'message', 'signature'],
          disabledComponents: [],
          defaultMessage: this.props.intl.formatMessage(messages.defaultMessageModifyRecruiter),
          cta: {
            canBeDisabled: true,
            text: { id: 'recruiter.participantsList.interaction.change' },
            action: (a) => this.props.handleChangeInteractionOwner(a, this.state.interaction, this.modals.modifyAppointment.close),
          },
          type: TEMPLATES.EDIT_APPLICATION_OWNER,
        });
      case 'canceled':
        return ({
          components: ['template', 'message', 'signature'],
          disabledComponents: [],
          title: messages.titleCancelAppointment,
          defaultMessage: '',
          cta: {
            canBeDisabled: false,
            text: messages.btnCancelAppointment,
            action: (a) => this.props.handleCancelAppointment(a, this.state.selectedAppointment, (e) => { this.modals.modifyAppointment.close(); this.handleOnMove(e, this.state.selectedParticipant, 'refuse'); }),
          },
          type: TEMPLATES.CANCEL_INTERVIEW,
        });
      case 'negativeScoring':
        return ({
          components: ['template', 'message', 'signature'],
          disabledComponents: [],
          title: this.isSelectedAppointmentPast() ? messages.titleNegativeScoring : messages.titleCancelAppointment,
          defaultMessage: '',
          withLoading: true,
          cta: {
            canBeDisabled: false,
            text: messages.btnConfirm,
            action: ({ message }) => {
              this.handleOnMove(
                null,
                this.state.selectedParticipant,
                'score0',
                { score: 0, appointmentId: this.state.selectedAppointment._id, message },
                () => this.modals.modifyAppointment.close()
              );
              trackRecruiterSentNegativeMail({ authUser: this.props.authUser, event: this.props.event });
            },
          },
          type: this.isSelectedAppointmentPast() ? TEMPLATES.NEGATIVE_SCORING : TEMPLATES.CANCEL_INTERVIEW,
        });
      case 'propose':
        return ({
          components: ['recruiter', 'offer', 'template', 'message', 'signature'],
          disabledComponents: [''],
          defaultMessage: '',
          title: messages.titleProposeAppointment,
          emoji: '🤞',
          cta: {
            canBeDisabled: true,
            text: messages.propose,
            action: () => this.handleOnPropose(null, this.state.selectedParticipant),
          },
          type: TEMPLATES.PROPOSITION,
        });
      default: break;
    }
    return null;
  };

  analyticsHidCandidate = () => {
    const { authUser, exponent, event } = this.props;
    const exponentUser = exponent ? find(exponent.users, (user) => user._user._id === authUser._id) : null;
    const role = exponentUser ? exponentUser.role : null;

    track({
      name: ANALYTICS_RECRUITER.HID_CANDIDATES,
      user: authUser,
      event,
      properties: {
        'User Type': role,
        'several actions': false,
      },
    });
  };

  handleOnRefuse = (e, participant, callback, isRefuseContext) => {
    if (e) e.preventDefault();
    const { props: { handleOnShowAppointmentModal }, getNextProfile, animateNext, state: { currentParticipant } } = this;
    const nextProfile = getNextProfile();

    if (typeof callback === 'function') {
      callback();
    }

    handleOnShowAppointmentModal(participant, nextProfile, () => {
      if (currentParticipant) {
        animateNext(false);
      }
    }, isRefuseContext);
  };

  handleOnHide = (e, participant, callback) => {
    if (e) e.preventDefault();

    const { animateNext, state: { currentParticipant }, handleOnMove } = this;
    handleOnMove(e, participant, 'refuse');

    if (typeof callback === 'function') {
      callback();
    }

    if (currentParticipant) {
      animateNext(false);
    }
  };

  handleOnMove = (e, participant, status, scoring, callback) => {
    if (e) e.preventDefault();

    const {
      props: { handleMove, exponent, context, authUser, event },
      state: { modalProfileIsOpen },
      animateNext } = this;

    handleMove(participant, status, scoring, callback);

    if (modalProfileIsOpen && isEmpty(scoring)) {
      animateNext(false);
    }

    const exponentUser = exponent ? find(exponent.users, (user) => user._user._id === authUser._id) : null;
    const role = exponentUser ? exponentUser.role : null;

    track({
      name: ANALYTICS_RECRUITER.MOVE_PROFILES,
      user: authUser,
      event,
      properties: {
        'User type': role,
        'Initial tab': CONTEXTS[context],
        'destination tab': isEmpty(scoring) ? CONTEXTS[status] : status,
        'several actions': false,
        card: modalProfileIsOpen ? 'big card' : 'small card',
      },
    });
  };

  handleOnConfirmAppointment = debounce(
    (e, participant, appointment) => {
      const {
        props: { handleConfirmAppointment },
        state: { modalProfileIsOpen },
        getNextProfile, animateNext,
      } = this;

      const nextProfile = getNextProfile();

      handleConfirmAppointment(participant, appointment, nextProfile);

      if (modalProfileIsOpen) {
        animateNext(false);
      }
    }, 2000, {
    leading: true,
    trailing: false,
  }
  );

  handleOnPropose = (e, participant, callback) => {
    if (e) e.preventDefault();
    const {
      state: { currentParticipant },
      props: { handleOnShowAppointmentModal }, getNextProfile, animateNext,
    } = this;

    const nextProfile = getNextProfile();

    if (typeof callback === 'function') {
      callback();
    }

    handleOnShowAppointmentModal(participant, nextProfile, () => {
      if (currentParticipant) {
        animateNext(false);
      }
    });
  };

  handleNext = (rr, changePageIfNecessary = true) => {
    const {
      props: { participants, handleProfileNavigation, pagination },
      state: { currentParticipant },
      showProfile,
    } = this;

    const currentIndex = participants.findIndex((item) => currentParticipant && item._id === currentParticipant._id);

    if (currentParticipant && currentIndex !== participants.length - 1) {
      showProfile(participants[currentIndex + 1], true);
    } else if (changePageIfNecessary) {
      this.onCloseModal();
      handleProfileNavigation(pagination.current + 1, 'next');
    } else {
      this.onCloseModal();
    }
  };

  animateNext = (changePageIfNecessary = true) => {
    const { handleNext } = this;

    this.setState({ animationModal: true });

    handleNext(changePageIfNecessary);
    setTimeout(() => this.setState({ animationModal: false }), 800);
  };

  handlePrevious = () => {
    const {
      props: { participants, pagination, handleProfileNavigation },
      state: { currentParticipant },
      showProfile,
    } = this;

    const currentIndex = participants.findIndex((item) => currentParticipant && item._id === currentParticipant._id);

    if (currentIndex !== 0) {
      showProfile(participants[currentIndex - 1], true);
    } else if (pagination.current > 1) {
      handleProfileNavigation(pagination.current - 1, 'previous');
    }
  };

  handleRefreshConfirmedInteractions = (withDiff = false) => {
    const { context } = this.props;
    const { lastConfirmedRefresh } = this.state;

    if (context === 'confirmed') {
      const now = moment().unix();
      const diff = now - lastConfirmedRefresh;

      // Refresh confirmed interactions list each minutes to check appointment date & display scoring
      if (diff > 60 && withDiff === true) {
        this.refreshInteractions();
        this.setState({ lastConfirmedRefresh: now });
      }
    }
  }

  showProfile = (participant, saveVisit = false) => {
    const { props: { onShowParticipant } } = this;

    if (!participant) {
      return false;
    }

    const newState = {};

    newState.currentParticipant = participant;

    const modalNodes = document.getElementsByClassName('ant-modal-wrap');

    if (modalNodes) {
      modalNodes.scrollTop = 0;
    }

    newState.modalProfileIsOpen = true;

    newState.userProfile = {
      participant,
    };

    this.setState(newState);

    onShowParticipant(participant, saveVisit);

    return true;
  };

  openModal = (a, appointment, participant, modalContext, interaction) => {
    this.modals.modifyAppointment.open({ selectedParticipant: participant, selectedAppointment: appointment, modalContext, interaction });
  };

  handleCardToggle = (isChecked, a) => {
    const { props: { handleCardToggle } } = this;
    const checkedParticipants = isChecked ? filter(this.props.checkedParticipants, (id) => id !== a) : [...this.props.checkedParticipants, a];

    handleCardToggle(isChecked, a, checkedParticipants);
  };

  modals = {
    modifyAppointment: {
      open: (selectedParticipant, modalContext) => { this.setState({ ...modalContext, isModifyAppointmentModalVisible: true, ...selectedParticipant }); },
      close: () => { this.setState({ isModifyAppointmentModalVisible: false }); },
    },
  };

  trackOnParticipantClick = () => {
    track({
      name: ANALYTICS_RECRUITER.CLICKED_VIEW_PROFILE,
      user: this.props.authUser,
      event: get(this.props.participants[0], '_event._id'),
    });
  };

  isParticipantChecked = (participant) => {
    const { props: { isAllParticipantChecked, checkedParticipants } } = this;
    return isAllParticipantChecked ? true : checkedParticipants.indexOf(participant._id) !== -1;
  };

  refreshInteractions = () => {
    const { props: { authUser, event, getInteractions, participants } } = this;
    const userIds = participants.map((p) => p._user._id);

    getInteractions({ eventSlug: event.slug, context: 'multi', clear: true, body: { users: flatten(userIds), _organization: authUser._currentOrganization._id } })
  }

  // Only for Hybrid event
  isSeparatorParticipationExpiredVisible = (participantIndex) => {
    const { props: { participants, event, exponent, context } } = this;
    const breakpointParticipantWithInteractionExpired = participants?.findIndex(_participant => _participant?.isInteractionExpired);
    const isExponentParticipatingToAllHybridFormats = isParticipatingToAllHybridFormats(exponent?.keyMomentFormats);
    const eventPhases = getEventPhases(event);
    const bothInteractionAreClosed = isInteractionClosed(eventPhases, EVENT_FORMAT.PHYSICAL) && isInteractionClosed(eventPhases, EVENT_FORMAT.VIRTUAL);

    return (
      participantIndex === breakpointParticipantWithInteractionExpired &&
      context === 'sourcing' &&
      isExponentParticipatingToAllHybridFormats &&
      !bothInteractionAreClosed);
  }

  renderParticipants = () => {
    const {
      props: {
        authUser,
        participants,
        exponentUser,
        exponent,
        context,
        newDate,
        highlightWords,
        participantActionBtnDisabled,
        event,
        participantsFetching,
        participantsCoreTarget,
        interactions: allInteractions,
      },
      isParticipantChecked,
      handleCardToggle,
      showProfile,
      trackOnParticipantClick,
      isSeparatorParticipationExpiredVisible,
      handleRefreshConfirmedInteractions,
    } = this;

    return participants
      .filter((participant) => participant)
      .map((participant, key) => {
        const interactions = allInteractions.filter((interaction) => get(interaction, '_candidate._id') === get(participant, '_user._id'));
        const interaction = interactions.find((interaction) => interaction.enable && interaction._recruiter && toString(interaction._recruiter._id) === toString(authUser._id));
        const hasAtsInteraction = interactions.findIndex((interaction) => interaction.type === 'ats-import' && interaction._recruiter && toString(interaction._recruiter._id) === toString(authUser._id)) > -1;
        const appointment = interaction ? interaction._appointment : null;
        const offers = flatten(map(interactions.filter((i) => i.type === 'application'), '_offers'));

        if (participant && !isUndefined(participant._user)) {
          const actions = this.getActionsFromContext(participant, context, appointment, 'list', hasAtsInteraction, interaction);
          const date = context === 'applications' && !isEmpty(participant.lastInteractions) && participant.lastInteractions[authUser._id] ? participant.lastInteractions[authUser._id] : participant.createdAt;
          const isVisited = participant.visitBy.includes(authUser._id);
          const isNew = exponentUser && exponentUser[`visit${context[0].toUpperCase()}${context.substring(1)}`] === true &&
            !CONTEXTS_PROCESS_JD.includes(context) && ((context === 'sourcing' && !isVisited) || context === 'applications') && !isEmpty(newDate) && moment(date) > newDate;

          return (
            <CSSTransition
              key={`participant_${participant._id}`}
              classNames={styles.participantItem}
              timeout={{ enter: 200, exit: 300 }}
            >
              <div className={styles.participantItem}>
                {
                  event.format === EVENT_FORMAT.HYBRID && isSeparatorParticipationExpiredVisible(key) &&
                  <SeparatorParticipationExpired
                    event={event}
                    context="participantExpired"
                  />
                }
                <div className="cardListItem">
                  <ParticipantCards
                    key={key}
                    areInteractionsClosed={event.areInteractionsClosed}
                    isChecked={isParticipantChecked(participant)}
                    onCardToggle={handleCardToggle}
                    component="medium"
                    participant={participant}
                    viewBy={authUser}
                    status="none"
                    isHybrid={isHybrid(event)}
                    actions={actions}
                    appointment={appointment}
                    onOpen={() => { showProfile(participant, true); trackOnParticipantClick(); }}
                    context={context}
                    functions={{ moveParticipant: this.handleOnMove, openModal: this.openModal }}
                    highlightWords={highlightWords || []}
                    handleModifyAppointment={this.openModal}
                    currentUser={authUser}
                    isNew={isNew}
                    isVisited={isVisited}
                    participantActionBtnDisabled={participantActionBtnDisabled}
                    interactions={interactions}
                    offers={offers}
                    skipAppointmentValidation={event.skipAppointmentValidation}
                    participantsFetching={participantsFetching}
                    participantsCoreTarget={participantsCoreTarget}
                    enableCoreTarget={event.enableCoreTarget}
                    exponent={exponent}
                    onClick={() => handleRefreshConfirmedInteractions(true)}
                  />
                </div>
              </div>
            </CSSTransition>
          );
        }

        return null;
      });
  };

  render() {
    const {
      props: { event, openedParticipant, participants, offers, exponent, authUser, context, highlightWords, participantActionBtnDisabled, handleDownload, handleDisplayContact, interactions, pagination, showMoving },
      state: { isLoaded, modalProfileIsOpen, userProfile, isModifyAppointmentModalVisible, selectedParticipant, modalContext, lastPage, currentParticipant, modalAtsIsVisible, modalAtsParticipant },
      onCloseModal, modalAppointmentProperties, modals, handleNext, handlePrevious, handleOnCloseModalAts, refreshInteractions,
    } = this;
    const currentIndex = participants.findIndex((item) => currentParticipant && item._id === currentParticipant._id);
    let openProfile = null;
    let participantOffers = null;

    const syncAts = authUser._currentOrganization?.syncAts;

    if (!isEmpty(userProfile)) {
      const { participant } = userProfile;

      const participantInteractions = interactions.filter((interaction) => participant._user && interaction._candidate && toString(interaction._candidate._id) === toString(participant._user._id));
      const interaction = interactions.find((interaction) => participant._user && interaction._candidate && toString(participant._user._id) === toString(interaction._candidate._id) && !isEmpty(interaction._appointment) && (get(interaction, '_recruiter._id') === authUser._id || get(interaction, '_owner._id') === authUser._id)) || null;
      const hasAtsInteraction = participantInteractions.findIndex((interaction) => interaction.type === 'ats-import' && interaction._recruiter && toString(interaction._recruiter._id) === toString(authUser._id)) > -1;
      const appointment = interaction ? interaction._appointment : null;

      const actions = () => this.getActionsFromContext(participant, context, appointment, 'big', hasAtsInteraction, interaction);

      participantOffers = flatten(map(participantInteractions.filter((i) => i.type === 'application'), '_offers'));

      openProfile = (
        <ParticipantCards
          component="big"
          isHybrid={isHybrid(event)}
          participant={participant}
          viewBy={authUser}
          status="none"
          context={context}
          actions={actions}
          currentUser={authUser}
          displayRating={context === 'selection'}
          onOpen={() => { }}
          onDownload={handleDownload}
          onDisplay={() => handleDisplayContact(participant)}
          appointment={appointment}
          highlightWords={highlightWords}
          functions={{ moveParticipant: this.handleOnMove, openModal: this.openModal, handleNext: this.handleNext }}
          participantActionBtnDisabled={participantActionBtnDisabled}
          interactions={participantInteractions}
          handleModifyAppointment={this.openModal}
          offers={participantOffers}
          exponent={exponent}
        />
      );
    }

    const containerClass = classnames(styles.participantsListContainer, isLoaded ? styles.isLoaded : null);

    let atsOffers = null;

    if (!isEmpty(modalAtsParticipant)) {
      const atsInteractions = interactions.filter((interaction) => modalAtsParticipant._user && interaction._candidate && toString(interaction._candidate._id) === toString(modalAtsParticipant._user._id));

      atsOffers = flatten(map(atsInteractions.filter((i) => i.type === 'application'), '_offers'));
    }

    const appointmentModal = isModifyAppointmentModalVisible ? (
      <ModalContact
        participant={selectedParticipant}
        interactions={interactions}
        visible={isModifyAppointmentModalVisible}
        user={selectedParticipant._user}
        appointment={this.state.selectedAppointment}
        recruiter={this.state.interaction?._recruiter}
        exponent={exponent}
        authUser={authUser}
        offers={offers}
        noAppointmentOffers={this.state.interaction?._offers.map(({ _id }) => _id)}
        properties={modalAppointmentProperties(modalContext)}
        onOk={modalAppointmentProperties(modalContext).cta.action}
        onClose={modals.modifyAppointment.close}
        onCloseUser={onCloseModal}
      />
    ) : null;

    const atsImportModal = ATS_TABS.includes(context) && syncAts ? (
      <ModalAtsImport
        authUser={authUser}
        context={context}
        participant={modalAtsParticipant}
        visible={modalAtsIsVisible}
        syncAts={syncAts}
        source={exponent.atsSource || syncAts?.config.defaultSource}
        participantOffers={atsOffers}
        onClose={handleOnCloseModalAts}
        onImportFinished={() => {
          notification.success('🙌 Candidat exporté avec succès')
          refreshInteractions();
        }}
      />
    ) : null;

    return (
      <div className={containerClass} style={{ opacity: showMoving ? 0.4 : 1 }}>
        <TransitionGroup>
          {this.renderParticipants()}
        </TransitionGroup>
        {appointmentModal}

        <ModalFull
          id="participantModal"
          visible={modalProfileIsOpen && openedParticipant !== null}
          footer={null}
          onCancel={onCloseModal}
          maskClosable
          keyboard
        >
          <a role="button" tabIndex={0} className="modal-close" onClick={onCloseModal}>
            <Icon name="close" className="modal-close-icon" />
          </a>
          {
            modalProfileIsOpen ?
              <CardNavigation
                handleNext={handleNext}
                isNextVisible={participants.length > 1 && !(currentIndex + 1 === participants.length && pagination.current === lastPage)}
                isPreviousVisible={participants.length > 1 && !(currentIndex === 0 && pagination.current === 1)}
                handlePrevious={handlePrevious}
              /> : ''
          }
          <TransitionGroup
            enter={this.state.animationModal}
            exit={this.state.animationModal}
          >
            <CSSTransition
              key={`participant_modal_${this.state.currentParticipant ? this.state.currentParticipant._id : new Date().valueOf()}`}
              classNames="participantModal"
              timeout={{ enter: 600, exit: 600 }}
            >
              <div className="participantModal">
                {modalProfileIsOpen && openedParticipant !== null && openProfile ? openProfile : ''}
              </div>
            </CSSTransition>
          </TransitionGroup>
        </ModalFull>

        {atsImportModal}
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  participantsFetching: participantSelectors.getParticipantFetching,
  participantsCoreTarget: participantSelectors.getParticipantsCoreTarget,
  availableActions: getAvailableActions,
});

const mapDispatchToProps = {
  getInteractions: interactionActions.getInteractions,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, injectIntl)(toJS(ParticipantList));
