import * as React from 'react';
import { FormattedMessage } from "react-intl";
import { PHASES, PHASE_STATE } from "../../utils/const";

function isParticipantRedirectedTo(event, type) {
  if (!event) {
    return false;
  }

  return event.participantRedirection === type
}

export default {
  isAvailable: [
    [
      {
        phase: PHASES.ALL,
        state: PHASE_STATE.ALL
      }
    ],
    [
      {
        phase: PHASES.NONE,
        state: PHASE_STATE.NONE
      }
    ]
  ],
  generateActions: ({ currentEvent }) => {
    const event = currentEvent?.toJS();

    return {
      isEnabled: (type) => isParticipantRedirectedTo(event, type),
      getTooltipMessage: (type) => {
        if (isParticipantRedirectedTo(event, type)) {
          return <FormattedMessage id="event.modules.prevent.no.tooltip.text" values={{ text: <FormattedMessage id="offers.plural" values={{ count: 2 }} /> }} />
        }

        return null
      }
    }
  }
}
