import React from 'react';
import { string, any } from 'prop-types';

// Components
import { Header } from './components';

// Styles
import styles from './styles.less';


const List = ({ title, icon, children }) => (
  <div>
    <Header title={title} icon={icon} />
    <div className={styles.resultsContainer}>
      {children}
    </div>
  </div>
);

List.propTypes = {
  title: string,
  icon: string,
  children: any,
};

Header.propTypes = {
  title: string,
  icon: string,
};

export default List;
