import React, { useEffect } from 'react';
import { func, object } from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { isEmpty } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { Button } from '@seekube-tech/ui-kit';
import { ANALYTICS_CANDIDATE } from '@/utils/constants';
import { track } from '@/utils/analytics';
import { useFormatMessage } from 'react-intl-hooks';

// components
import { H3 } from '@/components/Title/index';
import ParticipantCards from '@/components/ParticipantCards';

// Store
import { getParticipant } from '@/store/participant/actions';
import { getCurrentParticipant } from '@/store/participant/selectors';

// styles
import '../UserExperience/styles.less';
import styles from './styles.less';

const Checking = ({
  user,
  event,
  handleDataUpdate,
}) => {
  const t = useFormatMessage();
  const dispatch = useDispatch();
  const participant = useSelector(getCurrentParticipant);

  useEffect(() => {
    dispatch(getParticipant({
      eventId: event.slug,
      userId: user._id,
    }));
  }, [])

  const trackEditing = (editing) => {
    track({
      name: ANALYTICS_CANDIDATE.CLICKED_UPDATED_PROFILE,
      event,
      user,
      properties: {
        editing: editing ? 'Yes' : 'No',
      },
    });
  };

  const goToEditSteps = () => {
    trackEditing(true);
    handleDataUpdate({}, 0);
  };


  const goToSearchStep = () => {
    trackEditing();
    handleDataUpdate({}, 3);

    return true;
  };

  if (participant.toJS() === undefined) return null;

  return (
    <div className="commonStep">
      <div className={styles.content}>
        <H3 bold className="educationTitle">
          <span className="desktop">
            {isEmpty(user) || !user.educations ? t({id: 'event.candidate.preparation.signup.userExperience.formation.title'}) : t({id: 'profile.up.to.date'})}
          </span>
          <span className="mobile">{t({id: 'profile.up.to.date'})}</span>
        </H3>
        <span className={styles.subtitle}>{t({id: 'onboarding.checking.title'})}</span>
        <div className={styles.pdfWithoutBorder} style={{ marginTop: '20px' }}>
          <ParticipantCards
            component="big"
            status="accept"
            participant={participant && participant.toJS()}
            viewBy={user}
            noActions
            onSave={() => {}}
            handleSaveUser={() => {}}
            eventSlug={event.slug}
          />
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.actions}>
          <Button
            className="mr-6"
            variant="tonal"
            onClick={goToEditSteps}
          >
            {t({id: 'btn.modify.profile'})}
          </Button>
          <Button
            type="submit"
            onClick={goToSearchStep}
          >
            {t({id: 'btn.continue.profile'})}
          </Button>
        </div>
      </div>
    </div>
  );
}

Checking.propTypes = {
  user: object,
  event: object,
  handleDataUpdate: func,
}

export default Checking;

