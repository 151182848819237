import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { Form, Select as SelectAntd } from 'antd';
import moment from 'moment';
import { number, object } from 'prop-types';
import Select from '@/components/Form/Select';
import { isEmpty } from 'lodash';
import { getBeginYearsList } from '@/helpers/form/getBeginYearsList';
import { Mandatory } from '@/components/Form';

const { Option } = SelectAntd;
const FormItem = Form.Item;

export const StartYearFormation = ({ form, education, order }) => {
  const t = useFormatMessage();
  const beginYears = getBeginYearsList();
  const selectedEndYear = form.getFieldValue(`educations.${order}.endYear`);

  return (
    <>
      <FormItem>
        {form.getFieldDecorator(`educations.${order}.startYear`, {
          initialValue: education?.beginAt ? moment(education.beginAt).format('YYYY') : '',
          rules: [
            { required: true, message: t({ id: 'form.error.required' }) },
          ],
        })(
          <Select
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            placeholder={<Mandatory>{t({ id: 'startDate' })}</Mandatory>}
          >
            {
              beginYears
              .filter((year) => isEmpty(selectedEndYear) || parseInt(selectedEndYear, 10) >= year)
              .map((year) => (<Option key={year}>{year}</Option>))
            }
          </Select>
        )}
      </FormItem>
    </>
  )
}

StartYearFormation.propTypes = {
  form: object,
  education: object,
  order: number
}
