import { makeStyles } from '@seekube-tech/ui';

export default makeStyles(theme => ({
  root: {
    padding: theme.spacing(1.5, 4),
    margin: theme.spacing(0, -4),
    marginTop: theme.spacing(3),
    background: theme.palette.neutral[100],
  },
  heading: {
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    marginRight: '20px',
    fontWeight: 700
  },
  sendAt: {
    marginLeft: '10px',
  }
}));
