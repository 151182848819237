import { isEmpty } from 'lodash';
import {
  trackOnboardingCvComplete, trackOnboardingExperienceComplete,
  trackOnboardingPresentationComplete, trackOnboardingMarketingSearchComplete
} from '@/utils/analytics/trackers/category/eventOnboarding';

let isPresentationSend = false;
let isCvSend = false;
let isExperienceSend = false;
let isSearchSend = false;

const getAreFieldsFilled = (form, fields) => fields
  .reduce((prevField, currentField) => {
    const hasErrors = form.getFieldError(currentField);
    const isFieldTouched = form.isFieldTouched(currentField);
    const fieldValue = form.getFieldValue(currentField);

    if (['resumeS3Key', 'resumeTitle'].includes(currentField)) {
      return prevField
        && hasErrors === undefined && !isEmpty(fieldValue);
    }

    return prevField
      && hasErrors === undefined && !isEmpty(fieldValue)
      && isFieldTouched;
  }, true)

export const getTrackers = (form, user, event) => ({
  presentation: () => {
    if (!isPresentationSend) {
      const isFieldsFilled = getAreFieldsFilled(form,['firstName', 'lastName', 'gender', 'phone']);

      if (isFieldsFilled) {
        isPresentationSend = true;

        trackOnboardingPresentationComplete(user, event);
      }
    }
  },
  cv: () => {
    if (!isCvSend) {
      const isFieldsFilled = getAreFieldsFilled(form,['title', 'resumeS3Key', 'resumeTitle']);

      if (isFieldsFilled) {
        isCvSend = true;

        trackOnboardingCvComplete(user, event);
      }
    }
  },

  experience: () => {
    if (!isExperienceSend) {
      const isFieldsFilled = getAreFieldsFilled(form,[
        'educationLevel', 'educations.0.startYear', 'educations.0.endYear', 'educations.0.title',
        'educations.0.school']);

      if (isFieldsFilled) {
        isExperienceSend = true;

        trackOnboardingExperienceComplete(user, event);
      }
    }
  },
  search: () => {
    if (!isSearchSend) {
      const isFieldsFilled = getAreFieldsFilled(form,['matching.targets', 'matching.filters']);

      if (isFieldsFilled) {
        isSearchSend = true;

        trackOnboardingMarketingSearchComplete(user, event);
      }
    }
  },
});
