import React from 'react';
import { node, string, bool } from 'prop-types';

// components
import { Menu, Dropdown, Icon } from 'antd';

import styles from './styles.less';

const {SubMenu} = Menu;

class DropdownOptions extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      icon: 'down',
    };
  }

  handleHover = () => {
    this.setState({
      icon: (this.state.icon === 'up') ? 'down' : 'up',
    });
  };

  renderMenu = (children) => (
    <div className={styles.dropdownWrapper}>
      <Menu getPopupContainer={(triggerNode) => triggerNode.parentNode}>
        {React.Children.map(children, (child, i) => {
          if (child.type === 'div') {
            const submenuTitle = <div className={styles.submenu}>{child.props.icon} {child.props.title}</div>;

            return (
              <SubMenu title={submenuTitle} >
                {React.Children.map(child.props.children, (subchild) => (
                  <Menu.Item key={i}>{subchild}</Menu.Item>
                  )
                )}
              </SubMenu>
            );
          }

          return (
            <Menu.Item disabled={child.props.disabled} key={i}>{child}</Menu.Item>
          );
        })}
      </Menu>
    </div>
  );

  render() {
    const { renderMenu, handleHover, state: { icon }, props: { children, label, placement, disabled } } = this;

    return (
      <Dropdown getPopupContainer={(triggerNode) => triggerNode.parentNode} overlay={renderMenu(children)} placement={placement} disabled={disabled} {...this.props}>
        <a
          className={styles.droplink}
          onMouseEnter={handleHover}
          onMouseLeave={handleHover}
        >
          { label } <Icon type={icon} />
        </a>
      </Dropdown>
    );
  }
}

DropdownOptions.propTypes = {
  label: string,
  children: node,
  placement: string,
  disabled: bool,
};

export default DropdownOptions;
