import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { object, string, func, bool } from 'prop-types';
import { get } from 'lodash';
import classnames from 'classnames';
import { Button, CardEvent } from '@seekube-tech/ui-kit';
import MobileDetect from 'mobile-detect';
import { getIds, getId } from '@/utils/global';
import { dateFormat } from '@/utils/format';
import ImgCdn from '@/components/ImgCdn';
import { If } from '@/components/If';
import EventAccessCta, {
  EVENT_ACCESS_CTA_COMPONENT_TYPE,
  EVENT_ACCESS_CTA_CONTEXT,
} from '@/scenes/Event/components/EventAccessCta';
import {
  Skeleton
} from '@/scenes/Events/scenes/components/JobdatingsYours/components/JobdatingCard/Skeleton';
import { getEarliestKeyMomentFromFormats, getFarthestKeyMomentFromFormats, getKeyMomentsFormatsFromEvent } from '@/utils/keyMoments';
import { JobdatingStatus } from '@/scenes/Events/scenes/components/JobdatingStatus';
import { useFormatMessage } from 'react-intl-hooks';
import { USER_ROLE, USERS_TYPE } from '@/utils/constants';
import Tags from '../Tags';
import styles from './styles.less';
const md = new MobileDetect();


const renderTags = (event, eventStatus, role, downloadAllCV2, loadingDownload) => {
  const tagList = TAGS[role][eventStatus];

  return (
    <div className={styles.infosContainer}>
      <Tags
        tagsList={tagList || []}
        event={event}
        downloadAllCV2={downloadAllCV2}
        loadingDownload={loadingDownload}
      />
    </div>
  );
};

const TAGS = {
  recruiter: {
    preregistration: ['huntDate', 'countOffersAndFreeSlots', 'seeCalendar'],
    open: ['appointment', 'waitingList', 'participants', 'huntDate', 'countOffersAndFreeSlots', 'seeCalendar'],
    discovering: ['participants', 'huntDate', 'countOffersAndFreeSlots', 'seeCalendar'],
    in_progress: ['appointment', 'countOffersAndFreeSlots', 'waitingList', 'participants', 'seeCalendar'],
    closed: ['participantsClose', 'cvtheque', 'downloadCVTheque', 'seeCalendar'],
    hunt_post_event: ['participantsClose', 'cvtheque', 'downloadCVTheque', 'seeCalendar'],
    other: ['appointment', 'waitingList', 'participants', 'cvtheque', 'seeCalendar'],
  },
  owner: {
    preregistration: ['jobdatingDate', 'huntDate', 'countOffersAndFreeSlots'],
    open: ['jobdatingDate', 'appointment', 'waitingList', 'participants', 'huntDate', 'countOffersAndFreeSlots'],
    discovering: ['jobdatingDate', 'participants', 'huntDate', 'countOffersAndFreeSlots'],
    in_progress: ['jobdatingDate', 'appointment', 'participants'],
    closed: ['jobdatingDate', 'participantsClose', 'cvtheque', 'downloadCVTheque', 'participants'],
    hunt_post_event: ['jobdatingDate', 'participantsClose', 'cvtheque', 'participants', 'downloadCVTheque'],
    other: ['jobdatingDate', 'appointment', 'participants', 'cvtheque'],
  },
  candidate: {
    preregistration: ['jobdatingDate', 'applications', 'openingForCandidate', 'seeCalendar'],
    discovering: ['applications', 'seeCalendar'],
    in_progress: ['propositions', 'appointmentForCandidate', 'applications', 'opportunities', 'applicationsClosing', 'seeCalendar'],
    hunt_post_event: ['appointmentValidated', 'candidatePropositionsClose', 'applications', 'seeCalendar'],
    closed: ['appointmentValidated', 'candidatePropositionsClose', 'applications', 'seeCalendar'],
  },
};

export const JobdatingCard = ({
  event,
  role,
  authUser,
  downloadAllCV2,
  loadingDownload,
  isSkeleton = false,
}) => {
  const t = useFormatMessage();
  
  if (isSkeleton) return <Skeleton />;

  const formats = getKeyMomentsFormatsFromEvent(event);
  const [beginAt, setBeginAt] = useState(getEarliestKeyMomentFromFormats(event, 'jobfair', formats));
  const [endAt, setEndAt] = useState(getFarthestKeyMomentFromFormats(event, 'jobfair', formats));
  const dateString = dateFormat(beginAt, endAt);

  const eventDateStatus = authUser.isAdmin && event.dateStatus === 'closed' ? 'hunt_post_event' : event.dateStatus;
  const participate = event.isAuthUserParticipating ? '' : role === 'candidate' && 'preregistered';
  const criteria = [eventDateStatus, event.status, participate, role !== 'candidate' && event.skipAppointmentValidation ? 'auto' : 'default'];

  const isPlanner = getIds(get(event, 'planners._organizations')).includes(getId(authUser._currentOrganization)) && getIds(get(event, 'planners._users')).includes(getId(authUser))
  const isOwner = role === USERS_TYPE.owner;
  
  useEffect(() => {
    setBeginAt(getEarliestKeyMomentFromFormats(event, 'jobfair', formats));
    setEndAt(getFarthestKeyMomentFromFormats(event, 'jobfair', formats));
  }, []);
  
  const getActions = () => (
      <div className={styles.actionsCTA}>
        {(authUser.isAdmin || isPlanner) &&
            <Link
              role="button"
              tabIndex={0}
              to={`/${event.slug}/owner/${authUser.isAdmin ? 'settings' : 'dashboard'}`}
            >
              <Button color="neutral">
                {t({ id: 'event.card.access.owner' })}
              </Button>
            </Link>
        }
        {!isOwner && <EventAccessCta
          authUser={authUser}
          context={EVENT_ACCESS_CTA_CONTEXT.EVENTS_LIST}
          ctaComponentType={EVENT_ACCESS_CTA_COMPONENT_TYPE.BUTTON}
          event={event}
          eventUserStats={event.stats}
          role={role}
          isAuthUserBanned={event.userContext?.isBanned}
        />}
      </div>
    )


  if (md.mobile() || window.innerWidth < 796) {
    return (
      <div className={styles.cardContainerMobile}>
        <CardEvent
          picture={event.theme.coverPictureUrl}
          infos={<JobdatingStatus className="status" criteria={criteria} role={role} dateString={dateString} />}
          title={
            <EventAccessCta
              authUser={authUser}
              context={EVENT_ACCESS_CTA_CONTEXT.EVENTS_LIST}
              ctaComponentType={EVENT_ACCESS_CTA_COMPONENT_TYPE.LINK}
              event={event}
              eventUserStats={event.stats}
              role={role}
              isAuthUserBanned={event.userContext?.isBanned}
            />}
          badges={renderTags(event, eventDateStatus, role, downloadAllCV2, loadingDownload)}
          actions={getActions()}
        />
      </div>
    )
  }

  return (
    <div className={classnames(styles.cardContainer, styles[eventDateStatus])}>
      <div className={styles.cardLeft}>
        <JobdatingStatus className="status" criteria={criteria} />
        <ImgCdn className="image" operation="crop" size={300} src={event.theme.coverPictureUrl} backgroundImageCss="linear-gradient(0deg, rgba(56, 80, 119, 0.3), rgba(56, 80, 119, 0.3))" withshadow />
      </div>
      <div className={styles.cardRight}>
        <div className={styles.cardTop}>
          <JobdatingStatus
            criteria={criteria}
            className="card-status"
            dateString={dateString}
            role={role}
            event={event}
            authUser={authUser}
          />

          <EventAccessCta
            authUser={authUser}
            context={EVENT_ACCESS_CTA_CONTEXT.EVENTS_LIST}
            ctaComponentType={EVENT_ACCESS_CTA_COMPONENT_TYPE.LINK}
            event={event}
            eventUserStats={event.stats}
            role={role}
            isAuthUserBanned={event.userContext?.isBanned}
          />
        </div>
        <div className={styles.cardBottom}>
          {renderTags(event, eventDateStatus, role, downloadAllCV2, loadingDownload)}
        </div>
      </div>
      <div className={styles.cardFooter}>
        <If
          condition={authUser.isAdmin || (
            getIds(get(event, 'planners._organizations')).includes(getId(authUser._currentOrganization))
            && getIds(get(event, 'planners._users')).includes(getId(authUser))
          )}
        >
          <Link
            role="button"
            tabIndex={0}
            to={`/${event.slug}/owner/${authUser.isAdmin ? 'settings' : 'dashboard'}`}
          >
            <Button color="neutral">
              {t({ id: 'event.card.access.owner' })}
            </Button>
          </Link>
        </If>
        {!isOwner && <EventAccessCta
          authUser={authUser}
          context={EVENT_ACCESS_CTA_CONTEXT.EVENTS_LIST}
          ctaComponentType={EVENT_ACCESS_CTA_COMPONENT_TYPE.BUTTON}
          event={event}
          eventUserStats={event.stats}
          role={role}
          isAuthUserBanned={event.userContext?.isBanned}
        />}
      </div>
    </div>
  );
};

JobdatingCard.propTypes = {
  event: object,
  role: string,
  authUser: object,
  downloadAllCV2: func,
  loadingDownload: bool,
  isSkeleton: bool,
};

