
// The differents display of the popoverContent, depending of the interaction between recruiter and candidate
const CARD_STATUS = {
  // when candidate meet recruiter
  done: 'done',
  // when a date is set and no more action are required for the meeting
  accepted: 'accepted',
  // refused by recruiter
  refused: 'refused',
  // when candidate apply in appointment non auto
  pending: 'pending',
  // when candidate get a proposition from recruiter
  proposition: 'proposition',
}

const MEETING_STATUS = {
  // when candidate meet recruiter
  candidateMetRecruiter: 'candidateMetRecruiter',
  // when a date is set and no more action are required for the meeting
  candidateHasAMeeting: 'candidateHasAMeeting',
  // refused by recruiter
  candidateWasRefused: 'candidateWasRefused',
  // when candidate apply in appointment non auto
  pending: 'pending',
  // when candidate get a proposition from recruiter
  candidateReceiveProposition: 'candidateReceiveProposition',
}

export { CARD_STATUS, MEETING_STATUS }