import { useQuery } from 'react-query';
import request from '@/utils/request';

export function useGetRecruiterStats({ userId, eventId, ...props }) {

  const requestUrl = `${process.env.FRONT_API_URL}/users/${userId}/events/recruiter-stats?eventId=${eventId}`;

  const queryFn = () => request(requestUrl, { method: 'GET' });

  const query = useQuery({
    queryKey: ['recruiterStats', eventId, userId],
    queryFn,
    refetchOnWindowFocus: true,
    cacheTime: 0,
    enabled: !!userId,
    ...props,
  });

  return query;
}
