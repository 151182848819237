import React from 'react';
import { func, number } from 'prop-types';

// Components
import { Menu } from 'antd';

// Styles & Translations
import styles from '../../scenes/MergeSchools/styles.less';

// Antd
const { Item: MenuItem } = Menu;

class MergeMenu extends React.PureComponent { // eslint-disable-line
  static propTypes = {
    push: func,
    countNewSchools: number,
    countCriteria: number,
  };

  render() {
    const { props: { countNewSchools, countCriteria, push } } = this;

    return (
      <div className={styles.menuComponent}>
        <Menu>
          <MenuItem key="new" onClick={() => push('/admin/cleaner/merge-schools')}>
            Ecoles <span className={styles.menuItemCount}>{countNewSchools || 0}</span>
          </MenuItem>
          <MenuItem key="handleSchool" onClick={() => push('/admin/cleaner/handle-schools')}>
            Gérer les écoles
          </MenuItem>
          <MenuItem key="matching" onClick={() => push('/admin/cleaner/merge-criteria')}>
            Critère de Matching <span className={styles.menuItemCount}>{countCriteria || 0}</span>
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

export default MergeMenu;
