import React from 'react';
import { number, bool } from 'prop-types';

import { FormattedMessage } from 'react-intl';
import { Funnel } from '@/components/Stats/Funnels';

import messages from '../../messages';

const StatsAppointments = ({ countAppointments = 0, countTop = 0, loading }) => (
  <Funnel
    dataSources={[
      { title: <FormattedMessage {...messages.interviewTitles} />, count: countAppointments, description: <FormattedMessage {...messages.interviewText} />, loading },
      { title: <FormattedMessage {...messages.interviewFurtherTitle} />, count: countTop, description: <FormattedMessage {...messages.interviewFurtherText} />, loading },
    ]}
  />
);

StatsAppointments.propTypes = {
  loading: bool,
  countAppointments: number,
  countTop: number,
};
export { StatsAppointments };
