import React, { useEffect } from 'react';
import { object, array, number, bool } from 'prop-types';
import { isEmpty } from 'lodash';
import { compose } from 'redux';
import { Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { toJS } from '@/utils';
import { getCurrentParticipant } from '@/store/participant/selectors';
import { track } from '@/utils/analytics';
import { ANALYTICS_CANDIDATE } from '@/utils/constants';
import { getStoredUtmParams } from '@/utils/url';
import { getHelloWorkPixel } from '@/utils/pixels/helloWork';
import ApiToForm from '@/services/Form/ApiToForm';
import { participantActions } from '@/store/participant';
import { userActions } from '@/store/user';
import { isHybrid } from '@/helpers/event/isHybrid';
import {
  FooterActions
} from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/Matching/FooterActions';
import Wrapper from '@/components/Wrapper';
import {
  trackOnboardingEnd,
  trackOnboardingMatchingComplete, trackPageDisplayMatching,
} from '@/utils/analytics/trackers/category/eventOnboarding';
import { LOCAL_STORAGE, setLocalStorage } from '@/utils/localStorage';
import MatchingForm from "./MatchingForm";

const Matching = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const participant = useSelector(getCurrentParticipant)?.toJS();

  const { authUser, event, defaultCriteria, form, totalOffers, isPreview = false } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
    if (event) {
      trackPageDisplayMatching(authUser, event);
    }
  }, []);

  const criteria = event._criteria;
  const sortCriteria = criteria
    .filter((criterion) => !['LEVEL', 'SECTORS', 'SIZE'].includes(criterion.key) && criterion.enable && criterion.modules.onboarding.isVisible)
    .sort((a, b) => a.modules.onboarding.position - b.modules.onboarding.position);

  const onSubmit = (e) => {
    e.preventDefault();
    track({
      name: ANALYTICS_CANDIDATE.EDIT_SEARCH,
      event,
      properties: {
        isUpdate: true,
        countOffers: totalOffers,
      },
    });

    return form.validateFields((err, values) => {
      if (err) return null;

      getHelloWorkPixel(event.slug)?.track();
      getHelloWorkPixel(event.slug)?.trackRegistration();

      const matchingFilters = Object
        .keys(values)
        .filter((value) => value.includes('matching-filters') === true)
        .map((filter) => values[filter]);

      const filters = [];

      Object.keys(matchingFilters).forEach((key) => {
        const filterValue = matchingFilters[parseInt(key, 10)];

        if (typeof filterValue === 'object') {
          filterValue.forEach((item) => {
            filters.push(item);
          });
        } else {
          filters.push(filterValue);
        }
      });

      const participant = {
        matching: {},
      };

      const languages = [];

      Object
        .keys(values)
        .filter((value) => value.includes('matching-languages-lang') === true)
        .forEach((filter, index) => {
          if (values[`matching-languages-lang-${index}`]) {
            languages.push({
              lang: values[`matching-languages-lang-${index}`],
              level: values[`matching-languages-level-${index}`],
            });
          }
        });

      if (values.license) {
        participant.matching.license = values.license;
      }
      participant.matching.languages = languages;
      participant.matching.filters = filters;
      participant.matching.date = values['matching-date'] ? values['matching-date'] : null;
      participant.matching.experiences = values['matching-experiences'] ? values['matching-experiences'] : null;

      return handleOnSaveParticipant(participant);
    })
  };

  const handleOnSaveParticipant = (values) => {
    const toPost = {
      matching: values.matching,
      _event: event._id,
      isActive: participant.isActive || !isHybrid(event)
    };

    if (participant && !isEmpty(participant.matching)) {
      dispatch(participantActions.patchParticipant({
        participantId: participant._id,
        eventId: event._id,
        participantParams: toPost,
        slug: event.slug,
        callback: () => {
          getHelloWorkPixel(event)?.track();
          getHelloWorkPixel(event)?.trackRegistration();
        },
      }));

      const matchingDefaultFilters = ApiToForm.matchingDefaultFiltersUser(
        defaultCriteria,
        values.matching
      );

      if (!isEmpty(matchingDefaultFilters) || !isEmpty(matchingDefaultFilters.date)) {
        dispatch(userActions.patchMe({
          userParams: {
            matching: {
              ...authUser.matching,
              filters: matchingDefaultFilters.filters,
              date: matchingDefaultFilters.date,
            },
          },
          callback: () => {
            trackOnboardingMatchingComplete(authUser, event, 'search');

            if (isHybrid(event)) {
              history.push('participation-mode')
            } else {
              trackOnboardingEnd(authUser, event, 'search');
              setLocalStorage(LOCAL_STORAGE.event.signUpSuccess, 'true');
              history.push(`/${event.participantRedirectionUrl}`);
            }
          }
        }));
      }
    } else {
      const userProvider = getStoredUtmParams();

      dispatch(participantActions.postParticipant({
        eventId: event._id,
        slug: event.slug,
        toPost: { ...toPost, userProvider },
        redirect: 'event',
      }));
    }
  };


  if (!event || !sortCriteria) return null;
  return (
    <Wrapper size="large">
      <Form onSubmit={onSubmit}>
        {participant &&
          <MatchingForm
            form={form}
            context="onboarding"
            authUser={authUser}
            event={event}
            defaultCriteria={defaultCriteria}
            participant={participant}
            withInputErrorStyle
          />}
        <FooterActions event={event} authUser={authUser} isPreview={isPreview} />
      </Form>
    </Wrapper>
  );

}

Matching.propTypes = {
  form: object,
  event: object,
  authUser: object,
  defaultCriteria: array,
  totalOffers: number,
  isPreview: bool,
};

const withForm = Form.create();

export default compose(withForm)(toJS(Matching));
