import React from 'react';
import { array, object, func, string } from 'prop-types';
import { If, Then, Else } from 'react-if';
import { injectIntl } from 'react-intl';
import { isNull, isUndefined, isEmpty } from 'lodash';
import moment from 'moment/moment';

// Components
import Highlighter from 'react-highlight-words';
import { TooltipCount } from '@/components/Tooltip/components/TooltipCount';
import Icon from '@/components/Icon';
import Avatar from '@/components/Avatar';
import Search from '../../../components/Search';

// Styles
import styles from './styles.less';
import messages from '../../../messages';

/**
 * OfferContent
 *
 * @param {object} props
 */
const OfferContent = ({ offer, event, highlightWords, handleOpenOffer, interaction, context, type, intl }) => {
  const appointment = interaction && interaction._appointment ? interaction._appointment : null;
  const organization = offer ? offer._organization : appointment._organization;

  let appointmentStr = '';

  if (interaction) {
    if (interaction.pending) {
      appointmentStr = `Entretien en attente avec ${interaction._organization.name}`;
    } else if (appointment) {
      if (appointment.status === 'refused') {
        appointmentStr = `Entretien refusé par ${interaction._organization.name}`;
      } else if (appointment.status === 'pending') {
        appointmentStr = `Entretien en attente avec ${interaction._organization.name}`;
      } else {
        const now = moment().add(1, 'hour');
        const date = appointment._timeslot ? moment(appointment._timeslot.beginAt) : null;

        if (now > date) {
          appointmentStr = `Entretien terminé avec ${interaction._organization.name}`;
        } else {
          appointmentStr = (
            <>Entretien à <strong>{date.format('H:mm')}</strong> avec {interaction._organization.name}</>
          );
        }
      }
    } else {
      appointmentStr = `Entretien refusé par ${interaction._organization.name}`;
    }
  }

  // Mini offer card in Stand
  if (type === 'light' && offer && offer._user && offer.matching) {
    const contracts = [];
    const durations = [];
    const contractCriteria = offer._event._criteria.filter((item) => item.key === 'CONTRACT')[0];
    const durationCriteria = offer._event._criteria.filter((item) => item.key === 'DURATION')[0];
    const date = offer.matching.date === 1 ? 'dès aujourd\'hui' : moment.unix(offer.matching.date).format('MMMM YYYY');

    if (contractCriteria) {
      contractCriteria._choices.forEach((item) => {
        if (item.enable && offer.matching.filters.includes(item._id.toString())) {
          contracts.push(item.label);
        }
      });
    }

    if (durationCriteria) {
      durationCriteria._choices.forEach((item) => {
        if (offer.matching.filters.includes(item._id.toString())) {
          durations.push(item.label);
        }
      });
    }

    let countOthersFilters = 0;

    const otherFilters = offer._event._criteria.filter((item) => item.key !== 'DURATION' && item.key !== 'CONTRACT');

    if (!isEmpty(otherFilters)) {
      otherFilters.forEach((filter) => {
        if (filter.key === 'JOBS') {
          filter._jobs.forEach((item) => {
            if (item.enable && offer.matching.filters.includes(item._id.toString())) {
              countOthersFilters += 1;
            }
          });
        } else {
          filter._choices.forEach((item) => {
            if (item.enable && offer.matching.filters.includes(item._id.toString())) {
              countOthersFilters += 1;
            }
          });
        }
      });
    }

    if (!isEmpty(offer.locations)) {
      countOthersFilters += offer.locations.length - 1;
    }

    return (
      <>
        <div className={styles.recruiter}>
          <div><Avatar type="rounded" src={offer._organization && offer._organization.profile && offer._organization.profile.pictureUrl ? offer._organization.profile.pictureUrl : ''} size={56} defaultColor="#385077" /></div>
          <div className={styles.recruiterInfos}>
            <div className={styles.recruiterName}>{offer._organization.name}</div>
            <div className={styles.recruiterSearch}>{intl.formatMessage(messages.search)}</div>
          </div>
        </div>
        <div className={styles.offerInfos}>
          <div className={styles.offerTitle}>{offer.title}</div>
          <div className={styles.offerCriteria}>
            <span>
              {contracts.length > 0 ? (<span>{contracts.join(', ')}<span className={styles.separator}>•</span></span>) : ''}
              {offer.matching.date && date ? (<span>{date}<span className={styles.separator}>•</span></span>) : ''}
              {durations.length > 0 ? (<><span>{`${durations.join(' - ')}`}</span><span className={styles.separator}>•</span></>) : ''}
              {!isEmpty(offer.locations) ? (<span>{`${offer.locations[0].name}`}</span>) : ''}
              <span className={styles.count}>
                <TooltipCount
                  color="dark"
                  placement="bottom"
                  title=""
                  count={countOthersFilters}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  style={{ marginLeft: '2px' }}
                />
              </span>
            </span>

          </div>
        </div>
      </>
    );
  }

  let picture = '';

  if (type === 'default' && organization.profile.pictureUrl) {
    if ((context === 'appointment' && isNull(appointment)) ||
      (context === 'appointment' && (isUndefined(appointment._timeslot) || isNull(appointment._timeslot))) ||
      context !== 'appointment') {
      picture = (
        <div className={styles.logo}>
          <img src={organization.profile.pictureUrl} alt={organization.name} />
        </div>
      );
    }
  }

  return (
    <>
      {picture}
      <div className={styles.details}>
        <If condition={context === 'appointment'}>
          <Then>
            <>
              <div className={styles.appointmentData}>
                <Icon name="timer" /> {appointmentStr}
              </div>
              <div className={styles.title}>
                {isEmpty(offer) ?
                  null
                  : <a onClick={handleOpenOffer} role="button" tabIndex={0}>{offer ? offer.title : ''}</a>
                }
              </div>
            </>
          </Then>
          <Else>
            <>
              <div className={styles.searchTitle}>{organization.name} {intl.formatMessage(messages.search)}</div>
              <div className={styles.title}>
                <a onClick={handleOpenOffer} role="button" tabIndex={0}>
                  <Highlighter
                    highlightClassName="highlightText"
                    searchWords={highlightWords}
                    autoEscape
                    textToHighlight={offer ? offer.title : ''}
                  />
                </a>
              </div>

              <div className={styles.criterion}>
                <div className={styles.offerContract}>
                  <Search offer={offer} event={event} highlightWords={[]} />
                </div>
              </div>
            </>
          </Else>
        </If>
      </div>
    </>
  );
};

OfferContent.propTypes = {
  offer: object,
  event: object,
  intl: object,
  interaction: object,
  context: string,
  type: string,
  highlightWords: array,
  handleOpenOffer: func,
};

OfferContent.defaults = {
  context: 'offer',
  type: 'default',
};

export default injectIntl(OfferContent);
