import { defineMessages } from 'react-intl';

export default defineMessages({
  dashboard: {
    id: 'event.owner.dashboard',
  },
  exponents: {
    id: 'event.owner.exponents',
  },
  stands: {
    id: 'event.owner.stands',
  },
  companies: {
    id: 'event.owner.companies',
  },
  offers: {
    id: 'event.owner.offers',
  },
  candidates: {
    id: 'event.owner.candidates',
  },
  statistics: {
    id: 'statistics',
  },
  live: {
    id: 'live.pl',
  },
  liveDisabledTooltip: {
    id: 'event.owner.live.disabled.tooltip',
  },
  settings: {
    id: 'event.owner.settings',
  },
});
