import { defineMessages } from 'react-intl';

export default defineMessages({
  jobdatingTitle: {
    id: 'event.owner.statistics.stats.jobdating.title',
  },
  jobdatingInterviewTitle: {
    id: 'event.owner.statistics.stats.jobdating.interview.title',
  },
  jobdatingInterviewPercentageTooltip: {
    id: 'event.owner.statistics.stats.jobdating.interview.percentage.tooltip',
  },
  candidateTitle: {
    id: 'event.owner.statistics.stats.candidate.title',
  },
  candidateInterviewTitle: {
    id: 'event.owner.statistics.stats.candidate.interview.title',
  },
  candidateInterviewTooltip: {
    id: 'event.owner.statistics.stats.candidate.interview.tooltip',
  },
  candidateInterviewPercentageTooltip: {
    id: 'event.owner.statistics.stats.candidate.interview.percentage.tooltip',
  },
  candidateInterviewAverage: {
    id: 'event.owner.statistics.stats.candidate.interview.average',
  },
  interviewAverageTitle: {
    id: 'event.owner.statistics.stats.interview.average.title',
  },
  interviewAverageTooltip: {
    id: 'event.owner.statistics.stats.interview.average.tooltip',
  },
  organizationTitle: {
    id: 'event.owner.statistics.stats.organization.title',
  },
  organizationInterviewTitle: {
    id: 'event.owner.statistics.stats.organization.interview.title',
  },
  organizationInterviewTooltip: {
    id: 'event.owner.statistics.stats.organization.interview.tooltip',
  },
  organizationInterviewPercentageTooltip: {
    id: 'event.owner.statistics.stats.organization.interview.percentage.tooltip',
  },
});
