import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'event.participant.search.title',
  },
  ownerTitle: {
    id: 'event.owner.offers.title',
  },
  seeSpecificity: {
    id: 'event.owner.offers.specificities',
  },
  blankTitle: {
    id: 'event.candidate.noOffer.title',
  },
  blankContent: {
    id: 'event.candidate.noOffer.content',
  },
  match: {
    id: 'event.candidate.jobdating.jobs.match',
  },
  editSearch: {
    id: 'event.candidate.jobdating.jobs.editSearch',
  },
  offer: {
    id: 'offer',
  },
  backOffer: {
    id: 'event.candidate.jobdating.offer.back',
  },
  blankButton: {
    id: 'event.candidate.jobdating.jobs.blankButton',
  },
  exportOffers: {
    id: 'event.owner.statistics.export.offers',
  },
  exportBtn: {
    id: 'event.owner.statistics.export.btn',
  },
});
