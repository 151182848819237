import React, { useEffect, useState } from 'react';
import { func, object } from 'prop-types';
import { debounce, get } from 'lodash';
import { useFormatMessage } from 'react-intl-hooks';
import { getId } from '@/utils/global';

import { InputNumber } from '@/components/Input';

const OfferMaxColumn = ({ exponent, patchExponent }) => {
  const t = useFormatMessage();
  const { _event, _id, limits } = exponent;
  const [value, setValue] = useState(get(limits, 'offerMax'))

  useEffect(() => {
    setValue(get(limits, 'offerMax'))
  }, [exponent])

  const onNumberChange = debounce((val) => {
    setValue(val)
    patchExponent({
      eventId: getId(_event),
      exponentId: _id,
      exponentParams: {
        limits: {
          ...limits,
          offerMax: val < 0 ? 1000 : val,
        },
      },
      notificationParams: {
      success: {
        message: t({ id: 'toaster.edit.success' }),
          kind: 'success',
          style: {
          bottom: '5%',
            top: 'inherit',
        },
      }
    }
    });
  }, 600);

  return (
    <div id={`offerMax${_id}`}>
      <InputNumber onChange={onNumberChange} formatter={(val) => val < 0 || val > 999 ? '∞' : val} width={50} min={-1} value={value} customSize="small" />
    </div>
  );
};

OfferMaxColumn.propTypes = {
  exponent: object,
  patchExponent: func,
};

export { OfferMaxColumn };
