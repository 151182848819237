import { defineMessages } from 'react-intl';

export default defineMessages({
  stepPreparationLabel: {
    id: 'event.progress.preparation',
  },
  stepPreparationHelp: {
    id: 'event.progress.preparation.help',
  },
  stepFairLabel: {
    id: 'event.progress.fair',
  },
  stepFairHelp: {
    id: 'event.progress.fair.help',
  },
  stepReportLabel: {
    id: 'event.progress.report',
  },
  stepReportHelp: {
    id: 'event.progress.report.help',
  },
});
