import React from 'react';

// Containers
import Dashboard from './containers/Dashboard';

const OnBoardingScene = () => (
  <Dashboard />
)

export default OnBoardingScene;
