/**
 * Check if value exist
 * @param {string | Array | Object} value - string value
 * @returns {boolean}
 */
export default function isFilled(value) {
  if (typeof value === 'object' && Boolean(value)) {
    return (
      (Array.isArray(value) && value.length > 0) ||
      (Array.isArray(value) === false && JSON.stringify(value) !== '{}')
    );
  }

  return typeof value === 'number' || (Boolean(value) && value.trim().length > 0);
}
