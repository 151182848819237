import React from 'react';
import { func, number, shape, array, object, string } from 'prop-types';
import { If, Then, Else } from 'react-if';
import { isEmpty, isEqual } from 'lodash';
import { injectIntl } from 'react-intl';

// components
import { Form, Select as SelectAntd, Divider } from 'antd';
import { Button, IconPlusCircle, IconTrash2 } from '@seekube-tech/ui-kit';
import Select from '@/components/Form/Select/index';
import InputMaterial from '@/components/Input/InputMaterial/index';
import { InputOrganizationsSuggest } from '@/components/Input';
import Icon from '@/components/Icon/index';
import messages from '../messages';

const {Option} = SelectAntd;
const FormItem = Form.Item;

const hasErrors = (fieldsError) => Object.keys(fieldsError).some((field) => fieldsError[field]);

class EducationForm extends React.PureComponent {
  static propTypes = {
    handleAddFormation: func,
    handleDeleteFormation: func,
    order: number,
    formation: object,
    formationsCount: number,
    form: shape({
      getFieldDecorator: func,
      getFieldError: func,
      isFieldTouched: func,
    }),
    beginYears: array,
    endYears: array,
    intl: object,
    user: object,
    setFormIsModify: func,
    context: string,
  };

  constructor(props) {
    super(props);

    this.state = {
      endDateError: false,
    };

    const { setFormIsModify, formation, order, form: { getFieldValue, getFieldsError } } = props;
    let formIsModify = false;

    if (!isEmpty(formation)) {
      formIsModify = !isEqual(formation.school, getFieldValue(`school${order}`)) ||
        !isEqual(formation.programName, getFieldValue(`programName${order}`)) ||
        !isEqual(formation.startYear, getFieldValue(`startYear${order}`)) ||
        !isEqual(formation.endYear, getFieldValue(`endYear${order}`));
    } else {
      formIsModify = !hasErrors(getFieldsError());
    }

    setFormIsModify(order, formIsModify);
  }

  componentDidMount() {
    this.setFormationField(this.props.formation || {});
    this.props.form.validateFields();
  }

  componentWillReceiveProps({ formation, user, form, order, formationsCount, setFormIsModify }) {
    let formIsModify = false;
    const { getFieldValue } = form;

    if (!isEmpty(formation) && !isEmpty(user)) {
      formIsModify = !isEqual(formation.school, getFieldValue(`schoolName${order}`)) ||
        !isEqual(formation.programName, getFieldValue(`programName${order}`)) ||
        !isEqual(formation.startYear.toString(), getFieldValue(`startYear${order}`).toString()) ||
        !isEqual(formation.endYear.toString(), getFieldValue(`endYear${order}`).toString()) || user.educations.length !== formationsCount;
    } else {
      formIsModify = !hasErrors(form.getFieldsError());
    }

    setFormIsModify(order, formIsModify);

    if (!isEmpty(formation) && !isEqual(formation, this.props.formation)) {
      this.setFormationField(formation);
    }

    this.forceUpdate();
  }

  componentWillUnmount() {
    this.props.form.validateFields();
  }

  setFormationField = (formation) => {
    const { props: { order, form: { setFieldsValue } } } = this;

    const values = {};
    values[`schoolName${order}`] = formation ? formation.schoolName : '';
    values[`programName${order}`] = formation ? formation.programName : '';
    values[`startYear${order}`] = formation ? formation.startYear : '';
    values[`endYear${order}`] = formation ? formation.endYear : '';
    setFieldsValue(values);
  };

  render() {
    const {
      props: {
        formation, handleAddFormation, handleDeleteFormation, formationsCount, order, beginYears, endYears, intl, context,
        form: { getFieldDecorator, isFieldTouched, getFieldError, getFieldValue, setFieldsValue },
      },
      state: { endDateError },
    } = this;

    const schoolName = isFieldTouched(`schoolName${order}`) && getFieldError(`schoolName${order}`);
    const programName = isFieldTouched(`programName${order}`) && getFieldError(`programName${order}`);
    const startYear = isFieldTouched(`startYear${order}`) && getFieldError(`startYear${order}`);
    const endYear = isFieldTouched(`endYear${order}`) && getFieldError(`endYear${order}`);
    const selectedStartYear = getFieldValue(`startYear${order}`);
    const selectedEndYear = getFieldValue(`endYear${order}`);

    if (!isEmpty(selectedEndYear) && parseInt(selectedEndYear, 10) < parseInt(selectedStartYear, 10)) {
      if (order === 0) {
        setFieldsValue({ endYear0: '' });
      } else {
        setFieldsValue({ endYear1: '' });
      }
    }

    return (
      <>
        <FormItem
          validateStatus={schoolName ? 'error' : ''}
          help={schoolName || ''}
        >
          {getFieldDecorator(`schoolName${order}`, {
            initialValue: formation ? formation.schoolName : '',
            rules: [{ required: true, message: intl.formatMessage(messages.formationSchoolMandatory) }],
          })(
            <InputOrganizationsSuggest
              id={`schoolName${order}`}
              initialValue={formation?.schoolName || ''}
              label={intl.formatMessage(messages.formationSchoolLabel)}
              placeholder={<span>{intl.formatMessage(messages.formationSchoolLabel)} <span className="mandatory">*</span></span>}
              addOnIcon="legal"
              validations={['required']}
              createAlert
              params={{ type: 'school', isMerged: false, noMongo: true }}
            />
          )}
        </FormItem>

        <FormItem
          validateStatus={programName ? 'error' : ''}
          help={programName || ''}
          className="formItemNoMargin"
        >
          {getFieldDecorator(`programName${order}`, {
            initialValue: formation ? formation.programName : '',
            rules: [{ required: true, message: intl.formatMessage(messages.formationProgramMandatory) }],
          })(
            <InputMaterial
              addOnIcon="formation"
              label={intl.formatMessage(messages.formationDiploma)}
              placeholder={intl.formatMessage(messages.formationDiploma)}
              type="text"
              validations={['required']}
            />
          )}
        </FormItem>

        <div className="flex-row-wrapper with-space">
          <FormItem
            validateStatus={startYear ? 'error' : ''}
            help={startYear || ''}
          >
            <div className="date-label" style={{ position: 'relative' }}>{intl.formatMessage(messages.startDate)} <span className="mandatory">*</span></div>
            {getFieldDecorator(`startYear${order}`, {
              initialValue: formation ? `${formation.startYear}` : '',
              rules: [
                { required: true, message: intl.formatMessage(messages.fieldRequired) },
              ],
            })(
              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                placeholder={intl.formatMessage(messages.year)}
                label={intl.formatMessage(messages.year)}
              >
                {
                  beginYears
                    .filter((year) => isEmpty(selectedEndYear) || parseInt(selectedEndYear, 10) >= year)
                    .map((year) => (<Option key={year}>{year}</Option>))
                }
              </Select>
            )}
          </FormItem>

          <FormItem
            validateStatus={endYear ? 'error' : ''}
            help={endYear || ''}
          >
            <div className="date-label">{intl.formatMessage(messages.endDate)} <span className="mandatory">*</span></div>
            {getFieldDecorator(`endYear${order}`, {
              initialValue: formation ? `${formation.endYear}` : '',
              rules: [
                { required: !selectedStartYear, message: intl.formatMessage(messages.fieldRequired) },
              ],
            })(
              <Select
                disabled={!selectedStartYear}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                placeholder={intl.formatMessage(messages.year)}
                label={intl.formatMessage(messages.year)}
              >
                {
                  endYears
                    .filter((year) => isEmpty(selectedStartYear) || parseInt(selectedStartYear, 10) <= year)
                    .map((year) => (<Option key={year}>{year}</Option>))
                }
              </Select>
            )}
          </FormItem>
        </div>

        <div className="add-container" style={{ justifyContent: 'flex-end' }}>
          <If condition={endDateError}>
            <div className="signup-dates-error-container">{intl.formatMessage(messages.endDateError0)}</div>
          </If>

          {context !== 'settings' ? (
            <FormItem className="add-zone">
              <If condition={order === 0}>
                <Then>
                  <If condition={formationsCount === 1}>
                    <Then>
                      <Button variant="tonal" imageComponentLeft={<IconPlusCircle size={16} />} role="presentation" onClick={handleAddFormation}>
                        {intl.formatMessage(messages.formationAdd)}
                      </Button>
                    </Then>
                  </If>
                </Then>
                <Else>
                  <Button variant="tonal" color="error" imageComponentLeft={<IconTrash2 size={16} />} role="presentation" onClick={() => handleDeleteFormation(order)}>
                    {intl.formatMessage(messages.formationDelete)}
                  </Button>
                </Else>
              </If>
            </FormItem>
          ) : (
            <FormItem className="add-zone">
              {formationsCount > 1 ? (
                <Button variant="tonal" color="error" imageComponentLeft={<IconTrash2 size={16} />} role="presentation" onClick={() => handleDeleteFormation(order)}>
                  {intl.formatMessage(messages.formationDelete)}
                </Button>
              ) : ''}
              {order === 0 && formationsCount <= 1 && (
                <div>
                  <Button variant="tonal" imageComponentLeft={<IconPlusCircle size={16} />} role="presentation" onClick={handleAddFormation}>
                    {intl.formatMessage(messages.formationAdd)}
                  </Button>
                </div>
              ) }
            </FormItem>
          )}
        </div>

        <If condition={formationsCount > 1 && order === 0}>
          <Then>
            <Divider />
          </Then>
        </If>
      </>
    );
  }
}

export default injectIntl(EducationForm);
