import React from 'react';
import { func, object, bool } from 'prop-types';
import { Button, H4 } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import styles from '@/scenes/Event/scenes/Owner/scenes/Dashboard/components/AddActivity/styles.less';
import Modal from '@/components/Modal';
import {
  usePostNotify
} from '@/scenes/Event/scenes/Owner/scenes/Dashboard/components/ModalActivities/queries/usePostNotify';

export const ModalSendNotificationActivity = ({ activityToUpdate, isVisible, onConfirm }) => {
  const t = useFormatMessage();

  const mutateNotifyActivity = usePostNotify({ onSuccess: onConfirm });

  return (
    <Modal
      width={672}
      visible={isVisible}
      footer={null}
      closable={false}
    >
      <div className={styles.headerModal}>
        <H4>{t({ id: 'modal.publish.mail.activity.title' })}</H4>
        <div>{t({ id: 'modal.publish.mail.activity.description.one' })}</div>
        <div>{t({ id: 'modal.publish.mail.activity.description.two' })}</div>
      </div>
      <div className={styles.buttonsContainer}>
        <Button
          variant="outline"
          color="neutral"
          onClick={onConfirm}
        >
          {t({ id: 'no' })}
        </Button>
        <Button
          loading={mutateNotifyActivity.isLoading}
          disabled={mutateNotifyActivity.isLoading}
          onClick={() => mutateNotifyActivity.mutate({ _id: activityToUpdate?._id })}
        >
          {t({ id: 'want.to.send.by.mail' })}
        </Button>
      </div>
    </Modal>
  )
}

ModalSendNotificationActivity.propTypes = {
  activityToUpdate: object,
  isVisible: bool,
  onConfirm: func,
}
