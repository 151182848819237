import React from 'react';
import moment from 'moment';
import { object, string } from 'prop-types';
import { getEventKeyMoments } from '@/helpers/event/getEventKeyMoments';
import { IconMapPin, IconWifi } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import styles from '@/scenes/Event/containers/Landing/components/Contents/components/GeneralInfos/components/LandingContent/components/Header/components/JobfairDateHybrid/styles.less';


const JobfairDate = ({ event, locale }) => {
  const t = useFormatMessage();
  moment.locale(locale);

  const momentBeginPhysical = moment(getEventKeyMoments(event, { type: "jobfair", format: "physical" })?.beginAt);
  const momentEndPhysical = moment(getEventKeyMoments(event, { type: "jobfair", format: "physical" })?.endAt);
  const endDatePhysical = momentEndPhysical.format('Do MMMM YYYY');
  const isPhysicalPhaseInOneDay = momentBeginPhysical.format('Do MMMM YYYY') === endDatePhysical;
  const isPhysicalPhaseSameMonth = momentBeginPhysical.format('MMMM') === momentEndPhysical.format('MMMM')
  const isPhysicalPhaseSameYear = momentBeginPhysical.format('YYYY') === momentEndPhysical.format('YYYY')

  const momentBeginVirtual = moment(getEventKeyMoments(event, { type: "jobfair", format: "virtual" })?.beginAt);
  const momentEndVirtual = moment(getEventKeyMoments(event, { type: "jobfair", format: "virtual" })?.endAt);
  const endDateVirtual = momentEndVirtual.format('Do MMMM YYYY');
  const isVirtualPhaseInOneDay = momentBeginVirtual.format('Do MMMM YYYY') === endDateVirtual;
  const isVirtualPhaseSameMonth = momentBeginVirtual.format('MMMM') === momentEndVirtual.format('MMMM')
  const isVirtualPhaseSameYear = momentBeginVirtual.format('YYYY') === momentEndVirtual.format('YYYY')

  const physicalDateLabel = () => {
    if (isPhysicalPhaseInOneDay) {
      return `${t({ id: 'physical' })} : ${momentBeginPhysical.format('Do MMMM YYYY')}
      ${event.location ? `• ${event.location?.formattedAdress}, ${event.location?.countryCode}` : ``}
      `
    }

    if (isPhysicalPhaseSameMonth) {
     return  `${t({ id: 'physical' })} : ${t({ id: 'date.from' })}
      ${momentBeginPhysical.format('Do')} ${t({ id: 'date.to' })}
      ${momentEndPhysical.format('Do MMMM YYYY')}
      ${event.location ? `• ${event.location?.formattedAdress}, ${event.location?.countryCode}` : ``}
      `
    }

    if (isPhysicalPhaseSameYear) {
      return `${t({ id: 'physical' })} : ${t({ id: 'date.from' })}
      ${momentBeginPhysical.format('Do MMMM')} ${t({ id: 'date.to' })}
      ${momentEndPhysical.format('Do MMMM YYYY')}
      ${event.location ? `• ${event.location?.formattedAdress}, ${event.location?.countryCode}` : ``}
      `
    }

    return ` ${t({ id: 'physical' })} : ${t({ id: 'date.from' })}
    ${momentBeginPhysical.format('Do MMMM YYYY')} ${t({ id: 'date.to' })}
    ${momentEndPhysical.format('Do MMMM YYYY')} • ${event.location?.formattedAdress}, ${event.location?.countryCode}
  `
  }

  const virtualDateLabel = () => {
    if (isVirtualPhaseInOneDay) {
      return ` ${t({ id: 'virtual' })} : ${momentBeginVirtual.format('Do MMMM YYYY')}
            `
    }

    if (isVirtualPhaseSameMonth) {
      return  `${t({ id: 'virtual' })} : ${t({ id: 'date.from' })}
       ${momentBeginVirtual.format('Do')} ${t({ id: 'date.to' })}
       ${momentEndVirtual.format('Do MMMM YYYY')}
       `
     }
 
     if (isVirtualPhaseSameYear) {
       return `${t({ id: 'virtual' })} : ${t({ id: 'date.from' })}
       ${momentBeginVirtual.format('Do MMMM')} ${t({ id: 'date.to' })}
       ${momentEndVirtual.format('Do MMMM YYYY')}
       `
     }

    return ` ${t({ id: 'virtual' })} : ${t({ id: 'date.from' })}
            ${momentBeginVirtual.format('Do MMMM YYYY')} ${t({ id: 'date.to' })}
            ${momentEndVirtual.format('Do MMMM YYYY')}
          `
  }

  return (
    <>
      <div className={styles.eventDateContainer}>
        <IconMapPin size={24} className={styles.calendarIcon} />
        {physicalDateLabel()}
      </div>
      <div className={styles.eventDateContainer}>
        <IconWifi className={styles.calendarIcon} />
        {virtualDateLabel()}
      </div>
    </>
  );
};

JobfairDate.propTypes = {
  event: object,
  locale: string
};

export default JobfairDate;
