import React, { useState } from 'react';
import { func, bool, array } from 'prop-types';
import { compose } from 'redux';
import { isNull, join, delay, uniq, debounce } from 'lodash';
import { connect } from 'react-redux';
import { useFormatMessage } from 'react-intl-hooks';
import { push } from 'connected-react-router';
import { createStructuredSelector } from 'reselect';
import { Form } from 'antd';
import { isEmpty } from 'lodash';
import { useMergeState } from '@/utils/hooks';
import { toJS } from '@/utils';
import request from '@/utils/request';

// Store
import { actionActions, actionSelectors } from '@/store/action';
import { organizationActions, organizationSelectors } from '@/store/organization';
import { criterionActions, criterionSelectors } from '@/store/criterion';


// Components

// Styles & Translations
import InputMaterial from '@/components/Input/InputMaterial';
import Checkbox from '@/components/Checkbox';
import Table from '@/components/Table';
import { getDataSource, SchoolsColumns } from './components';
import styles from './styles.less';

const FormItem = Form.Item;


const HandleSchools = ({ mergeOrganizationName, getActions, mergeOrganization, postAction, isFetching, mergeList }) => { // eslint-disable-line
  const t = useFormatMessage();
  const [withMergedSchool, setWithMergedSchool] = useState(false);
  const [searchState, setSearchState] = useMergeState({ search: '', searchItems: [], isSearching: false });
  const [checkedSchools, setCheckedSchools] = useState([]);
  const [currentReferent, setCurrentReferent] = useState(null);


  const onToggleSchool = (school, isChecked) => {
    let newCheckedSchools = checkedSchools;

    if (isChecked) {
      newCheckedSchools.push(school);
    } else {
      newCheckedSchools = checkedSchools.filter((u) => u._id !== school._id);
    }

    setCheckedSchools(newCheckedSchools);
  };

  const handleMergeClick = () => {
    const schools = checkedSchools.filter((school) => school._id !== currentReferent._id);

    const totalSchools = schools.length;
    schools
      .forEach((school, index) => {
        postAction({
          actionParams: {
            name: 'MERGE_SCHOOL',
            isAdmin: true,
            data: {
              oldId: school._id,
              oldName: school.name,
              newId: currentReferent ? currentReferent._id : null,
              newName: currentReferent ? currentReferent.name : null,
            },
          },
          callback: () => {
            if (index + 1 === totalSchools) { getActions({ name: 'MERGE_SCHOOL' }); }
          },
        });
        mergeOrganization({
          oldId: school._id,
          newId: currentReferent ? currentReferent._id : null,
          callback: () => {
            if (index + 1 === totalSchools) { setCheckedSchools([]); handleSearch(searchState.search); }
          },
        });
      });
  };
  const debounceSearch = debounce(
    (value) => {
      const isMerged = withMergedSchool ? '' : '&isMerged=false';

      if (!isEmpty(value)) {
        request(`${process.env.FRONT_API_URL}/organizations/search?name=${value}${isMerged}&type=school&noMongo=true`).then((results) => {
          setSearchState({ isSearching: false, search: value, searchItems: results.docs });
          // setSearchItems(results.docs);
          // setIsSearching(false);
          // setSearch(value);
        });
      } else {
        setSearchState({ isSearching: false, search: value, searchItems: [] });
        // setIsSearching(false);
      }
    }, 1000
  );

  const handleSearch = (e) => {
    const value = typeof e === 'object' ? e.currentTarget.value : e;
    if (!searchState.isSearching) {
      setSearchState({ isSearching: true });
    }
    debounceSearch(value);
  };

  const handleReferentClick = (school) => {
    setCurrentReferent(school);
  };


  const handleEditName = (school, val) => {
    setSearchState({ isSearching: true });
    mergeOrganizationName({
      _id: school._id,
      oldName: school.name,
      newName: val,
      notificationParams: {
        success: {
          message: t({ id: 'toasters.nameUpdated.success' }),
          kind: 'info',
          style: {
            bottom: '5%',
            top: 'inherit',
          },
        },
      },
      callback: () => delay(() => handleSearch(searchState.search), 3000),
    });
  };

  const renderReferent = () => {
    if (isNull(currentReferent)) return null;
    const nbSchoolsToMerge = checkedSchools.length;
    function MergeBtn() {
      return nbSchoolsToMerge ? <a role="button" tabIndex={0} onClick={handleMergeClick}>Fusionner les {checkedSchools.length} écoles</a> : null
    }

    return (
      <div className={styles.referentContainer}>
        <span>{currentReferent.name} </span>
        <span className={styles.alias}>{join(uniq(currentReferent.alias), ', ')} </span>
        <MergeBtn />
      </div>
    );
  };

  if (isFetching) {
    return ('fetching');
  }

  return (
    <div className={styles.handleSchoolsContainer}>
      <div className={styles.sceneContainer}>
        <span>
          <FormItem>
            <InputMaterial
              addOnIcon="user"
              label="Chercher une école"
              type="string"
              onChange={handleSearch}
            />
          </FormItem>
          <span style={{ marginBottom: '20px' }}>
            <a role="button" tabIndex={0} onClick={() => setCheckedSchools([])}>Reset les écoles sélectionnées</a>
            <Checkbox style={{ marginLeft: '15px' }} onChange={(e) => setWithMergedSchool(e.target.checked)}>Afficher les écoles déjà fusionnées</Checkbox>
          </span>
        </span>
        {renderReferent()}

        <Table
          size="default"
          className={styles.tableContainer}
          loading={searchState.isSearching}
          dataSource={getDataSource(searchState.searchItems
            .filter((s) => s._id !== mergeList[0]._id), checkedSchools, { handleEditName, onToggleSchool, handleMergeClick, handleReferentClick })}
          columns={SchoolsColumns()}
        />
      </div>
    </div>
  );
};

HandleSchools.propTypes = {
  mergeOrganizationName: func,
  getActions: func,
  mergeOrganization: func,
  postAction: func,
  isFetching: bool,
  mergeList: array,
};

const mapStateToProps = createStructuredSelector({
  actions: actionSelectors.getActions,
  isFetching: organizationSelectors.getOrganizationFetching,
  mergeList: organizationSelectors.getMergeList,
  mergeListTotal: organizationSelectors.getMergeListTotal,
  mergeListTotalCriterion: criterionSelectors.getMergeListTotal,
  suggestions: organizationSelectors.getSuggestions,
});

const mapDispatchToProps = {
  getActions: actionActions.getActions,
  postAction: actionActions.postAction,
  getOrganizations: organizationActions.getOrganizations,
  mergeOrganization: organizationActions.mergeOrganization,
  mergeOrganizationName: organizationActions.mergeOrganizationName,
  getMergeList: organizationActions.getMergeList,
  getMergeListCriterion: criterionActions.getMergeList,
  push,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(toJS(HandleSchools));

