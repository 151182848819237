import React from 'react';
import { object, string } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { CardInfo, Link1 } from '@seekube-tech/ui-kit';
import Icon from '@/components/Icon';
import { trackCandidateClickedViewKeyDates } from '@/utils/analytics';

const InteractionsDisabled = ({ authUser, href }) => {
  const t = useFormatMessage();

  return (
    <CardInfo
      isClosable={false}
      title={t({ id: 'candidate.infoCard.hybrid.interaction.closed.title' })}
      description={ t({ id: 'candidate.infoCard.interaction.date.is.past.description' })}
      icon={<Icon style={{ width: '100%', height: '100%' }} name="illuCalendar" />}
      visible
      action={href ? (
        <Link1
          onClick={() => trackCandidateClickedViewKeyDates({ authUser })}
          href={href} target="_blank">{t({ id: 'see.keyDates' })}
        </Link1>
      ) : null}
    />
  );
}

InteractionsDisabled.propTypes = {
  authUser: object,
  href: string,
  currentView: string,
}

export { InteractionsDisabled }
