import React from 'react';
import {
  FooterNav
} from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/components/FooterNav/FooterNav';
import { Body2, Button, IconCheckCircle, IconChevronLeft } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { useHistory } from 'react-router-dom';
import colors from '@seekube-tech/ui-kit/dist/colors';

export const FooterActions = ({ form }) => {
  const t = useFormatMessage();
  const history = useHistory();
  const isSubmitBtnDisabled = form.getFieldValue('physicalKeyMoment') === false && form.getFieldValue('virtualKeyMoment') === false;

  return (
    <FooterNav>
      <Button
        onClick={() => history.push('matching')}
        imageComponentLeft={<IconChevronLeft size={16}  />}
        color="neutral"
        variant="outline"
      >
        {t({ id: 'previous.step' })}
      </Button>
      <Body2 color={colors.neutral['500']} className="footerMiddleText">
        {t({ id: 'candidate.signup.footer.last.step' })}
      </Body2>
      <Button
        disabled={isSubmitBtnDisabled}
        type="submit"
        imageComponentLeft={<IconCheckCircle size={16} />}
      >
        {t({ id: 'submit' })}
      </Button>
    </FooterNav>
  )
}