import React from 'react';
import { object, func } from 'prop-types';
import { replace } from 'connected-react-router';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { toJS } from '@/utils';
import { createStructuredSelector } from 'reselect';

// Store
import { eventSelectors } from '@/store/event';
import { participantSelectors } from '@/store/participant';

// Components
import Wrapper from '@/components/Wrapper';
import Separator from '@/components/Separator';
import NavAdminCandidates from './components/NavAdminCandidates';
import AdminCandidatesAllScene from './scenes/All';
import AdminCandidatesTopScene from './scenes/Top';
import AdminCandidatesRefuseScene from './scenes/Refuse';
import AdminCandidatesSaveScene from './scenes/Save';

/**
 * OwnerCandidate
 */
class OwnerCandidatesScene extends React.PureComponent { // eslint-disable-line
  static propTypes = {
    match: object,
    stats: object,
    event: object,
    replace: func,
  };

  /**
   * ComponentDidMount
   *
   * @description
   * "/jobdating" redirects on "/jobdating/dashboard"
   */
  componentDidMount() {
    const {
      props: { match },
    } = this;

    if (match.isExact) {
      this.props.replace(`${match.url}/all`);
    }
  }

  /**
   * Render
   */
  render() {
    const {
      props: {
        match, stats, event,
      },
    } = this;

    if (!event) {
      return '';
    }

    const redashUrl = `https://redash.sawkiube.fr/dashboard/candidats?p_w31_type=custom&p_eventId=${encodeURIComponent(event.slug)}`;

    return (
      <div>
        <NavAdminCandidates match={match} stats={stats} redashUrl={redashUrl} />

        <Wrapper size="large">
          <Separator height={20} />
        </Wrapper>

        <Switch>
          <Route path={`${match.path}/all`} component={AdminCandidatesAllScene} />
          <Route path={`${match.path}/top`} component={AdminCandidatesTopScene} />
          <Route path={`${match.path}/refuse`} component={AdminCandidatesRefuseScene} />
          <Route path={`${match.path}/save`} component={AdminCandidatesSaveScene} />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  event: eventSelectors.getCurrentEvent,
  stats: participantSelectors.getParticipantsStats,
});

const mapDispatchToProps = {
  replace,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  injectIntl,
)(toJS(OwnerCandidatesScene));

