import React, { useState } from 'react';
import { object } from 'prop-types';
import { IconChevronLeft, IconChevronRight, IconX } from '@seekube-tech/ui-kit';
import { useIsMobile } from '@/utils/MediaQueries';
import styles from './styles.less'

const ModalVideos = ({
  profile,
  videos,
  addEmbeddedLink,
  videoIndexInitial = 0,
  setIsModalVisible,
}) => {
  const isMobile = useIsMobile();

  if (!profile) {
    return null;
  }

  const [videoIndex, setVideoIndex] = useState(videoIndexInitial);

  return (
    <div className={styles.modalVideosContainer}>
      <div className={styles.closableContainer}>
        <div onClick={() => setIsModalVisible(false)} className={styles.closableWrapper}>
          <IconX />
        </div>
      </div>
      <div className={styles.modalVideosWrapper}>
        {videoIndex !== 0 && !isMobile &&
          <div onClick={() => setVideoIndex(videoIndex - 1)} className={styles.chevronIcon}>
            <IconChevronLeft size={34} />
          </div>
        }
        <div className={styles.modalVideosWrapper}>
          <iframe
            src={addEmbeddedLink(videos[videoIndex].url)}
            allowFullScreen
          />
        </div>
        <div className={styles.icons}>
          {videoIndex !== 0 && isMobile &&
            <div onClick={() => setVideoIndex(videoIndex - 1)} className={styles.chevronIcon}>
              <IconChevronLeft size={34} />
            </div>
          }
          {videoIndex < videos.length - 1 &&
            <div onClick={() => setVideoIndex(videoIndex + 1)} className={styles.chevronIcon}>
              <IconChevronRight size={34} />
            </div>
          }
        </div>
      </div>
    </div>
  )
}

ModalVideos.propTypes = {
  profile: object
}

export default ModalVideos;
