import React, { useState } from 'react';
import { Col, Form, Row } from 'antd';
import { get, size } from 'lodash';
import { func, object } from 'prop-types';

import { FormattedMessage } from 'react-intl';
import { emailValidator } from '@/utils/text';
import { removeWhitespace } from '@/utils/format'
import InputMaterial from '@/components/Input/InputMaterial';
import Icon from '@/components/Icon';
import { If } from '@/components/If';

import { Alert, Button, IconPlusCircle, IconTrash2 } from '@seekube-tech/ui-kit';
import styles from '../../styles.less';
import messages from '../../../messages';

const FormItem = Form.Item;

const defaultValues = {
  firstName: '',
  lastName: '',
  email: '',
  title: '',
};

const Presenters = ({ getFieldDecorator, conference, intl }) => {
  const [presenters, setPresenters] = useState(size(conference.presenters) === 0 ? [defaultValues] : conference.presenters);

  return (
    <>
      <FormattedMessage {...messages.presentersTitle} tagName="h1" />
      <div>
        {
          presenters.map(
            (presenter, i) => (
              <div key={i}>
                <Row gutter={30} className={i > 0 && styles.presenterWithBorder}>
                  <Col span={6}>
                    <FormItem>
                      {
                        getFieldDecorator(`presenters.${i}.firstName`, {
                          initialValue: get(presenter, 'firstName'),
                          rules: [{ required: true, message: <FormattedMessage {...messages.mandatory} /> }],
                        })(
                          <InputMaterial addOnIcon="createUser" placeholder={intl.formatMessage(messages.formFirstNameLabel)} label={<FormattedMessage {...messages.formFirstNameLabel} />} type="text" validations={['required']} />
                        )
                      }
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem>
                      {
                        getFieldDecorator(`presenters.${i}.lastName`, {
                          initialValue: get(presenter, 'lastName'),
                          rules: [{ required: true, message: <FormattedMessage {...messages.mandatory} /> }],
                        })(
                          <InputMaterial addOnIcon="createUser" placeholder={intl.formatMessage(messages.formLastNameLabel)} label={<FormattedMessage {...messages.formLastNameLabel} />} type="text" validations={['required']} />
                        )
                      }
                    </FormItem>
                  </Col>
                </Row>
                <FormItem>
                  {
                    getFieldDecorator(`presenters.${i}.email`, {
                      initialValue: get(presenter, 'email'),
                      normalize: removeWhitespace,
                      rules: [
                        { validator: emailValidator },
                        {
                          required: true, message: <FormattedMessage {...messages.mandatory} />,
                        }],
                    })(
                      <InputMaterial
                        addOnIcon="mail"
                        placeholder={intl.formatMessage(messages.formFirstEmailLabel)}
                        label={<FormattedMessage {...messages.formFirstEmailLabel} />}
                        type="text"
                        validations={['required']}
                      />
                    )
                  }
                </FormItem>
                <FormItem>
                  {
                    getFieldDecorator(`presenters.${i}.title`, {
                      initialValue: get(presenter, 'title'),
                      rules: [{ required: true, message: <FormattedMessage {...messages.mandatory} /> }],
                    })(
                      <InputMaterial addOnIcon="createUser" placeholder={intl.formatMessage(messages.conferencePresentersTitleLabel)} label={<FormattedMessage {...messages.conferencePresentersTitleLabel} />} type="text" validations={['required']} />
                    )
                  }
                </FormItem>
                <div className={styles.buttonsActions}>
                  <If condition={size(presenters) < 9} otherwise={<div></div>}>
                    <Button
                      size='small'
                      color='primary'
                      variant='tonal'
                      imageComponentLeft={<IconPlusCircle size={16} />}
                      onClick={() => size(presenters) < 9 && setPresenters([...presenters, defaultValues])}
                    >
                      <FormattedMessage {...messages.presentersAdding} />
                    </Button>
                  </If>
                  <If condition={i > 0}>
                    <Button
                      color='error'
                      variant='tonal'
                      imageComponentLeft={<IconTrash2 size={16} />}
                      onClick={() => setPresenters(presenters.filter((a, index) => index !== i))}
                    >
                      <FormattedMessage {...messages.delete} />
                    </Button></If>
                </div>
              </div>
            )
          )
        }
        
        {size(presenters) > 8 &&
          <Alert
            color='warning'
            children={<FormattedMessage {...messages.limitPresenters} />}
            isClosable={false}
          />
        }
      </div>
    </>
  );
};

Presenters.propTypes = {
  getFieldDecorator: func,
  conference: object,
  intl: object,
};

export { Presenters };
