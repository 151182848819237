import React from 'react';
import { object, string } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { CardInfo, Link1 } from '@seekube-tech/ui-kit';
import Icon from '@/components/Icon';
import { trackCandidateClickedViewKeyDates } from '@/utils/analytics';
import { CANDIDATE_JD_VIEWS } from '@/utils/constants';

const InteractionsAvailable = ({ authUser, href, currentView, dateInteractionAvailable }) => {
  const t = useFormatMessage();

  return (
    <CardInfo
      isClosable={false}
      title={t({ id: [CANDIDATE_JD_VIEWS.informal1to1, CANDIDATE_JD_VIEWS.informal1to1s].includes(currentView)  ? 'candidate.jobdating.informal1to1.closingDate' : 'candidate.infoCard.interaction.available.title' }, { date: dateInteractionAvailable.format('DD/MM'), time: dateInteractionAvailable.format('H[h]mm')})}
      icon={<Icon style={{ width: '100%', height: '100%' }} name="illuHourglass" />}
      visible
      action={href ? (
        <Link1
          onClick={() => trackCandidateClickedViewKeyDates({ authUser })}
          href={href} target="_blank">{t({ id: 'see.keyDates' })}
        </Link1>
      ) : null}
    />
  );
}

InteractionsAvailable.propTypes = {
  authUser: object,
  dateInteractionAvailable: object,
  href: string,
  currentView: string,
}

export { InteractionsAvailable }
