import React from 'react';
import { bool, number } from 'prop-types';

// Components
import { Badge as BadgeAntd } from 'antd';

/**
 * Badge
 */
const Badge = (props) => {
  if (props.loading || (props.overflowCount > 99 && props.count > 99)) {
    return (
      <span className="ant-badge ant-badge-not-a-wrapper">
        <sup data-show="true" className="ant-scroll-number ant-badge-count" title="2">
          <span className="ant-scroll-number-only">
            <p className="">{props.loading ? '...' : props.count}</p>
          </span>
        </sup>
      </span>);
  }

  // Render
  return (<BadgeAntd {...props} />);
};

Badge.propTypes = {
  count: number,
  overflowCount: number,
  loading: bool,
};

export default React.memo(Badge);
