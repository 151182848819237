import React from 'react';
import classnames from 'classnames';
import styles
  from '@/scenes/Events/scenes/components/JobdatingsYours/components/JobdatingCard/styles.less';
import ImgCdn from '@/components/ImgCdn';
import appStyles from '@/containers/App/styles.less';

const Skeleton = () => (
  <div className={classnames(styles.cardContainer)}>
    <div className={styles.cardLeft}>
      <ImgCdn className="image" size={500} src={null} backgroundImageCss="linear-gradient(0deg, rgba(56, 80, 119, 0.2), rgba(56, 80, 119, 0.2))" withshadow />
    </div>
    <div className={styles.cardRight}>
      <div className={styles.cardTop} style={{ justifyContent: 'flex-start' }}>
        <div className={appStyles.skeletonTitle} style={{ width: '70%', marginBottom: 10 }} />
        <div className={appStyles.skeletonTitle} style={{ width: '40%' }} />
      </div>
    </div>
    <div className={styles.cardBottom}>
      {}
    </div>
  </div>
);

export { Skeleton }