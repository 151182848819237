import React from 'react';
import { injectIntl } from 'react-intl';
import { func, object } from 'prop-types';
import isEmpty from 'lodash/isEmpty';

// Components
import { If, Then } from 'react-if';
import { Modal, InputNumber } from 'antd';
import notification from '@/components/Notification';
import Icon from '@/components/Icon';
import { InputGoogleMapsSuggest } from '@/components/Input';
import Location from './Location';

// Styles & Translations
import styles from './styles.less'; // eslint-disable-line
import messages from '../messages';

/**
 * LocationAdd
 */
class LocationAdd extends React.PureComponent {
  static propTypes = {
    onOk: func,
    intl: object,
  };

  /**
   * Constructor
   *
   * @param {object} props
   */
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      locationElement: {
        type: 'location',
        address: {},
        height: 200,
      },
    };
  }

  /**
   * Send value to the parent and close the modal
   */
  handleOnOk = () => {
    const { state: { locationElement }, props: { onOk, intl }, toggleModal } = this;

    // Send the locationElement only if we have an address
    if (!isEmpty(locationElement.address)) {
      onOk(locationElement);
      toggleModal();
    } else {
      notification.error(intl.formatMessage(messages.locationAddNotificationError));
    }
  };

  /**
   * Toggle the modal
   */
  toggleModal = () => {
    const { state: { modalIsOpen, locationElement } } = this;

    const resetLocationElement = {
      ...locationElement,
      address: {},
    };

    this.setState({ modalIsOpen: !modalIsOpen, locationElement: resetLocationElement });
  };

  /**
   * Handle the on select
   *
   * @param {string} key
   */
  handleOnChange = (key) => (value) => {
    const { state: { locationElement } } = this;

    const newLocationElement = { ...locationElement, [key]: value };

    this.setState({ locationElement: newLocationElement });
  };

  /**
   * Render the modal with inputs for select an address
   */
  renderModal = () => {
    const {
      props: { intl },
      state: { modalIsOpen, locationElement },
      handleOnOk,
      toggleModal,
      handleOnChange,
    } = this;

    return (
      <Modal
        title={intl.formatMessage(messages.locationAddModalTitle)}
        visible={modalIsOpen}
        onOk={handleOnOk}
        cancelText={intl.formatMessage({ id: 'cancel' })}
        onCancel={toggleModal}
      >
        {/* Inputs */}
        <InputGoogleMapsSuggest
          style={{ width: '100%' }}
          onChange={handleOnChange('address')}
        />
        <InputNumber
          style={{ width: '100%', marginTop: 20 }}
          min={100}
          value={locationElement.height}
          formatter={(value) => `${value}px`}
          parser={(value) => value.replace('px', '')}
          onChange={handleOnChange('height')}
        />
        {/* Preview */}
        <If condition={!isEmpty(locationElement.address)}>
          <Then>
            <Location className={styles.marginTop} element={locationElement} />
          </Then>
        </If>
      </Modal>
    );
  };

  /**
   * Render
   */
  render() {
    const {
      toggleModal,
      renderModal,
    } = this;

    return (
      <div>
        { renderModal() }
        <a role="button" tabIndex={0} onClick={toggleModal}>
          <Icon name="map" />
        </a>
      </div>
    );
  }
}

export default injectIntl(LocationAdd);
