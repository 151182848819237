import React from 'react';
import { H4, Toggle } from '@seekube-tech/ui-kit';
import { bool, func } from 'prop-types';

import { useFormatMessage } from 'react-intl-hooks';
import { Bloc, BlocHeader } from '../../../../../../../../../../components/Bloc';

import styles from './styles.less';


const SkipAppointmentValidation = ({
  skipAppointmentValidation,
  handleSubmit,
}) => {
  const t = useFormatMessage();
  
  return (
    <Bloc>
      <div className={styles.ets}>
        <BlocHeader>
          <H4>{t({ id: 'event.basic.skipAppointmentValidation.title' })}</H4>
          <Toggle
            onChange={(skipAppointmentValidation) => handleSubmit(false, { skipAppointmentValidation })}
            checked={skipAppointmentValidation}
          />
        </BlocHeader>
      </div>

    </Bloc>

  );
};

SkipAppointmentValidation.propTypes = {
  skipAppointmentValidation: bool,
  handleSubmit: func,
};

export { SkipAppointmentValidation };
