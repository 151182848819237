const DEAL_NAME = {
  thematic_one_shot: 'thematic_one_shot', // clients thematiques one shot
  thematic_subscription: 'thematic_subscription', // clients thematiques abonnements
  company_one_shot: 'company_one_shot', // soirées thematiques
  company_subscription: 'company_subscription',
  movie_party_one_shot: 'movie_party_one_shot', // soirée cinéma [deprecated keep only for old deals]
  credit_subscription: 'credit_subscription', // crédits
  schoolpack_subscription: 'schoolpack_subscription', // schoolpack deprecated (keep only for old deals)
  thematic_party_one_shot: 'thematic_party_one_shot', // Soirées thématiques 🆕
  seekalendar_subscription: 'seekalendar_subscription', // Seekalendar
}


export { DEAL_NAME }
