import { makeStyles } from '@seekube-tech/ui';

export default makeStyles(theme => ({
  title: {
    marginBottom: theme.spacing(3),
  },
  actions: {
    marginTop: theme.spacing(1),
  },
  checkbox: {
    display: 'flex !important',
    alignItems: 'center !important',
    marginTop: `${theme.spacing(4)}px !important`,
    marginBottom: `${theme.spacing(2.5)}px !important`,
  },
  additionalText: {
    fontStyle: 'italic',
  }
}))

