import { isSlotPassed } from '@/features/agenda/recruiter/helpers/isSlotPassed';
import { EVENT_FORMAT } from '@/utils/constants';
import { eventContainsSlot } from '@/features/agenda/recruiter/helpers/eventContainsSlot';
import { getEventKeyMoments } from '@/helpers/event/getEventKeyMoments';
import { slotOverlaps } from '@/features/agenda/recruiter/helpers/slotOverlaps';

/**
 * @description
 * Check if the slot can be added to the agenda
 */
export const getMessageSlotProhibited = ({ event, slot, settings, eventKeyMoments, exponentFormat, allSlots, t }) => {
  if (slot.action !== 'click' && slot.action !== 'select') return null;
  
  if (isSlotPassed(event, slot)) {
    return t({ id: 'event.recruiter.preparation.timeslots.slot.notification.error.past' });
  }

  if (event.format === EVENT_FORMAT.HYBRID && exponentFormat === EVENT_FORMAT.HYBRID && !settings.isVisible) {
    if (!eventContainsSlot(
      undefined,
      getEventKeyMoments(event, { type: 'jobfair', format: EVENT_FORMAT.PHYSICAL }),
      getEventKeyMoments(event, { type: 'jobfair', format: EVENT_FORMAT.VIRTUAL }),
      slot,
      settings.duration,
    )) {
      return t({ id: 'event.recruiter.preparation.timeslots.slot.notification.error.notInEvent' })
    }
  } else if (!eventContainsSlot(eventKeyMoments, undefined, undefined, slot, settings.duration)) {
    return t({ id: 'event.recruiter.preparation.timeslots.slot.notification.error.notInEvent' })
  }

  // Check if the slot is free
  if (slotOverlaps(slot, allSlots, settings.duration)) {
    return t({ id: 'event.recruiter.preparation.timeslots.slot.notification.error.slot.conflict' })
  }

  return null;
}
