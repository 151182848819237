import { useMutation, useQueryClient } from 'react-query';
import request from '@/utils/request';

export function useCreateReport(
  {
    eventId,
  }) {
  const queryClient = useQueryClient();

  const mutationFn = ({ scheduledAt = null, phase, format }) => {
    const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/reports`;
    const payload = { scheduledAt, phase };

    /**
     * If event is hybrid, we must add a format to set which phase it is added to
     * In a physical only or virtual only event, there is no need to
     */
    if (format) {
      payload.keyMomentFormat = format;
    }

    return (
      request(requestUrl, {
        method: 'POST',
        body: JSON.stringify(payload),
      })
    )
  }

  return useMutation({
    mutationFn,
    onSuccess: async (report) => {
      queryClient.setQueryData('reports', (old) => [...old, report]);
    },
  });
}
