import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { object, func, bool, array, number, string } from 'prop-types';
import { isEmpty } from 'lodash';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useFormatMessage } from 'react-intl-hooks';
import { Button } from '@seekube-tech/ui-kit';
import { Select as SelectAntd } from 'antd';
import { useHistory } from 'react-router-dom';
import { toJS } from '@/utils';
import { track } from '@/utils/analytics';
import { ANALYTICS_RECRUITER, EVENT_FORMAT, TEMPLATES } from '@/utils/constants';
import { getId } from '@/utils/global';
import { authSelectors } from '@/store/auth';
import { eventActions, eventSelectors } from '@/store/event';
import { exponentActions, exponentSelectors } from '@/store/exponent';
import { timeslotActions, timeslotSelectors } from '@/store/timeslot';
import Textarea from '@/components/Textarea';
import ModalV2 from '@/components/ModalV2';
import Avatar from '@/components/Avatar';
import TagWithAvatar from '@/components/Tag/TagWithAvatar';
import Select from '@/components/Form/Select';
import Icon from '@/components/Icon';
import UserCard from '@/components/UserCard';
import { getLocalStorage, LOCAL_STORAGE } from '@/utils/localStorage';
import { EVENT_TYPE } from '@/utils/constants';
import { getAvailableActions } from '@/store/availableActions/selectors';
import { getFarthestKeyMoment } from '@/utils/keyMoments';
import { SelectTemplate } from '@/components/SelectTemplate';
import { decryptTemplate } from '@/helpers/template/decryptTemplate';
import { useGetRecruiterStats } from '@/queries/recruiters/useGetRecruiterStats';
import { getHasRecruiterAvailableSlots } from '@/store/availableActions/utils/user/getHasRecruiterAvailableSlots';
import { SearchOffer, AlertAppointmentModal, InteractionsAreClosedSoon } from './components';
import styles from './styles.less';
import messages from './messages';

const { Option } = SelectAntd;


export function getDefaultTemplateType(event, isRefuseContext, context) {
  if (isRefuseContext) {
    return TEMPLATES.APPLICATION_REFUSED;
  }
  if (context === 'applications') {
    return TEMPLATES.APPLICATION_VALIDATED;
  }

  if (event.type === EVENT_TYPE.jpo) {
    return TEMPLATES.PROPOSITION_JPO
  }
  return TEMPLATES.PROPOSITION;
}

const AppointmentModal = ({
  exponent,
  event,
  participant,
  authUser,
  onOk,
  visible,
  offers,
  getUserTimeslots,
  isMultiple,
  countParticipants,
  onClose,
  context,
  isRefuseContext
}) => {
  const t = useFormatMessage();
  const history = useHistory();
  const availableActions = useSelector(getAvailableActions);
  const type = getDefaultTemplateType(event, isRefuseContext, context);
  const [currentUserInExponent, setCurrentUserInExponent] = useState(exponent?.users?.find((user) => getId(authUser) === getId(user._user) ));
  const [templateSelected, setTemplateSelected] = useState(null);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [message, setMessage] = useState(decryptTemplate(templateSelected?.fr, participant._user));
  const [showPreventAppointmentModal, setShowPreventAppointmentModal] = useState(true);

  const recruiterStatsQuery = useGetRecruiterStats({ userId: getId(currentUserInExponent._user), eventId: event._id });
  const hasRecruiterAvailableSlots = getHasRecruiterAvailableSlots({ recruiterStatsQuery, availableActions, event, participant, exponent });

  const interactionsClosingAt = event.format === EVENT_FORMAT.HYBRID ?
    getFarthestKeyMoment(event, isEmpty(event.keyMoments.interactionsClosingAt) ? 'jobfair' : 'interactionsClosing')
    : event?.keyDates?.interactionsClosing?.beginAt;

  const getRecruiterOptions = () => !isEmpty(exponent?.users) && (
    exponent.users
      .filter((item) => item?._user?.isActive)
      .sort((a, b) => a._user.fullName.localeCompare(b._user.fullName))
      .map((exponentUser) => (
        <Option key={exponentUser._user._id} value={exponentUser._user._id} title={exponentUser._user.fullName}>
          <TagWithAvatar src={exponentUser?._user?.pictureUrl || ''} user={exponentUser._user} name={exponentUser._user.fullName} />
        </Option>
      ))
  )


  useEffect(() => {
    if (exponent && !isEmpty(exponent.users)) {

      getUserTimeslots({ currentUser: authUser, currentEvent: event, context: 'modal' });
    }
  }, [])


  function analyticsUsedDefaultTemplate () {
    const isTemplateEdited = message !== buildEmail(templateSelected.fr);

    track({
      name: ANALYTICS_RECRUITER.USED_TEMPLATE,
      user: authUser,
      event: exponent._event,
      properties: {
        templateType: templateSelected._user ? 'other' : 'default',
        modified: isTemplateEdited,
      },
    });
  }

  const handleSelectRecruiter = (value) => {
    const currentUserInExponent = exponent.users.filter((item) => item?._user?._id === value)[0];
    setCurrentUserInExponent(currentUserInExponent)

    getUserTimeslots({ currentUser: currentUserInExponent._user, currentEvent: event, context: 'modal' });
  };

  const handleSelectTemplate = (template) => {

    const message = buildEmail(template.fr);

    setTemplateSelected(template)
    setMessage(message)
  };

  const handleSubmitAppointment = () => {

    onOk({
      message: isMultiple ? buildEmail(message, { revert: true }) : message,
      medium: null,
      user: currentUserInExponent,
      offer: selectedOffer,
    });

    analyticsUsedDefaultTemplate();
  };

  const buildEmail = (text, option = { revert: false }) => {
    if (participant?._user) {
      return decryptTemplate(text, participant._user, option);
    }

    return text;
  };

  const formatTemplateMessage = ({ currentTarget }) => {
    setMessage(buildEmail(currentTarget.value));
  };

  const handleOpenCalendar = () => {
    if (authUser._currentOrganization) {
      history.push(`/${event.slug}/recruiter/agenda`);
    }
  }


  const getModalText = () => {
    let submitMessage = '';
    let modalTitle = '';

    if (isRefuseContext) {
      submitMessage = isMultiple ? t({ id: 'appointment.grouped.refuse' }) : t({ id: 'event.recruiter.appointmentModal.application.refuse.submit' });
      modalTitle = t({ id: 'event.recruiter.appointmentModal.application.refuse.title' }, { user: participant?._user?.fullName || '' });
    } else if (context === 'applications') {
      submitMessage = isMultiple ? t({id: 'appointment.grouped.confirm' }) : t({ id: 'event.recruiter.appointmentModal.application.submit', });
      modalTitle = t({ id: 'event.recruiter.appointmentModal.application.title' }, { user: participant?._user?.fullName || '' });
    } else {
      modalTitle = t({ id: 'event.recruiter.appointmentModal.title' }, { name: participant?._user?.firstName || '' });
      submitMessage = t({ id: 'recruiter.participantsList.appointment.propose' });
    }
    if (isMultiple) {
      modalTitle += isMultiple ? t(messages.otherCandidates, { count: countParticipants - 1 }) : '';
    }

    return { modalTitle, submitMessage }
  }

  const { modalTitle, submitMessage } = getModalText()

  const defaultMessage = t({ id: 'event.recruiter.appointmentModal.help' });

  const areInteractionClosedIn4h = interactionsClosingAt && moment().add(4, 'hours').isAfter(interactionsClosingAt)

  const profile = exponent.profile ? exponent.profile : exponent._organization.profile;

  if (isEmpty(event) || isEmpty(exponent)) {
    return null;
  }

  return (
    <ModalV2
      template="default"
      onClose={onClose}
      visible={visible}
      width={720}
      zIndex={1002}
    >
      {
        (context === 'sourcing'
          && areInteractionClosedIn4h
          && showPreventAppointmentModal
          && !JSON.parse(getLocalStorage(LOCAL_STORAGE.recruiter.hideConfirmInteractionSoonClosed))) ?
          <InteractionsAreClosedSoon
            title={modalTitle}
            interactionsClosingBeginAt={interactionsClosingAt}
            handleConfirm={() => setShowPreventAppointmentModal(false)}
            handleCancel={onClose}
          /> :
          <>
            {participant && (
              <div>
                <div className="participantHeader">
                  <div className="participantHeaderContent">
                    <div className={styles.avatar}>
                      <Avatar src={participant._user.pictureUrl} imageFit="cover" user={participant._user} size={64} isDefault={isEmpty(participant._user.pictureUrl)} counter={isMultiple && countParticipants > 1 ? countParticipants - 1 : null} />
                    </div>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                      <h3>{modalTitle}</h3>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="formWrapper">
              {!isRefuseContext && (
                <div className="formItem">
                  <Select
                    required
                    placeholder={t({ id: 'event.recruiter.appointmentModal.inCharge' })}
                    showSearch
                    addOnIcon="user"
                    value={currentUserInExponent ? currentUserInExponent._user._id : null}
                    onSelect={handleSelectRecruiter}
                    suffixIcon={<Icon name="chevron" />}
                    optionFilterProp="children"
                    filterOption={(input, option) => option.props.title && option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {getRecruiterOptions()}
                  </Select>

                  {!hasRecruiterAvailableSlots && ((event.format === EVENT_FORMAT.HYBRID && participant.keyMomentFormats.length > 1) || event.format !== EVENT_FORMAT.HYBRID) && (
                    <span className={styles.error}>{t({ id: 'event.recruiter.appointmentModal.noSlotError' })} <a role="button" tabIndex={0} onClick={() => handleOpenCalendar()}>{t({ id: 'event.recruiter.appointmentModal.addSlot' })}</a></span>
                  )}
                  {!hasRecruiterAvailableSlots && (event.format === EVENT_FORMAT.HYBRID && participant.keyMomentFormats.length === 1) && (
                    <span className={styles.error}>{t({ id: 'event.recruiter.appointmentModal.noSlotError.one.format' }, {format: participant.keyMomentFormats[0]})} <a role="button" tabIndex={0} onClick={() => handleOpenCalendar()}>{t({ id: 'event.recruiter.appointmentModal.addSlot.one.format' }, {format: participant.keyMomentFormats[0]})}</a></span>
                  )}
                </div>
              )}

              {event.withOfferModule && context !== 'applications' && !isRefuseContext && offers.length > 0 && (
                <SearchOffer
                  offers={offers}
                  event={event}
                  currentUserInExponent={currentUserInExponent}
                  onOfferSelected={setSelectedOffer}
                />
              )}

              <div className="formItem">

                <SelectTemplate
                  onTemplateSelected={handleSelectTemplate}
                  isDisabled={false}
                  type={type}
                />
              </div>
              <div className="formItem">
                <Textarea value={message} containerClassName={styles.textareaContainer} placeholder={defaultMessage} onChange={formatTemplateMessage} label="" />
              </div>
              <div className={styles.recruiterSignature}>
                <UserCard
                  user={currentUserInExponent ? currentUserInExponent._user : null}
                  className={styles.recruiterSignature}
                  userSize={56}
                />
              </div>
              <AlertAppointmentModal exponent={exponent} authUser={authUser} isRefuseContext={isRefuseContext} profile={profile} />
              <div className="flex justify-between mt-20">
                <Button
                  variant="tonal"
                  onClick={() => window.open(`${window.location.pathname}?editTemplate=true`, '_blank')}
                >
                  {t({ id: 'event.recruiter.appointmentModal.editTemplate' })}
                </Button>
                <Button
                  onClick={handleSubmitAppointment}
                  disabled={!message || recruiterStatsQuery.isLoading || !currentUserInExponent || (!isRefuseContext && !hasRecruiterAvailableSlots) || (profile?.status === 'draft')}
                >
                  {submitMessage}
                </Button>
              </div>
            </div>
          </>
      }
    </ModalV2>
  );
}

AppointmentModal.propTypes = {
  exponent: object,
  event: object,
  participant: object,
  authUser: object,
  onOk: func,
  visible: bool,
  offers: array,
  getUserTimeslots: func,
  isMultiple: bool,
  countParticipants: number,
  onClose: func,
  context: string,
  isRefuseContext: bool,
};

const mapStateToProps = createStructuredSelector({
  event: eventSelectors.getCurrentEvent,
  timeslots: timeslotSelectors.getModalUserTimeslots,
  authUser: authSelectors.getAuthUser,
  currentExponent: exponentSelectors.getCurrentExponent,
});

const mapDispatchToProps = {
  setCurrentExponent: exponentActions.setCurrentExponent,
  setCalendarEvent: eventActions.setCalendarEvent,
  getUserTimeslots: timeslotActions.getUserTimeslots,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(toJS(AppointmentModal));
