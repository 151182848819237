import React from 'react';
import { object } from 'prop-types';
import { isEmpty } from 'lodash';
import { compose } from 'redux';
import { useSelector } from 'react-redux';
import { toJS } from '@/utils/index';
import { CandidateSettings } from '@/scenes/Settings/CandidateSettings';
import { RecruiterSettings } from '@/scenes/Settings/RecruiterSettings';
import { getAuthUser } from '@/store/auth/selectors';

function SettingsScene(props) {
  const authUser = useSelector(getAuthUser)?.toJS();

  if (!authUser) return null;

  const isCandidate = isEmpty(authUser?._currentOrganization);

  return isCandidate ? <CandidateSettings {...props} /> : <RecruiterSettings {...props} />
}

SettingsScene.propTypes = {
  authUser: object,
}

export default compose(
)(toJS(SettingsScene));
