import { bool, func, node, string } from 'prop-types';
import React from 'react';
import { Toggle, Body1 } from '@seekube-tech/ui-kit';
import colors from '@seekube-tech/ui-kit/dist/colors';
import { Bloc } from '@/components/Bloc';

import styles from './styles.less';

function ToggleInput({
  children,
  value,
  onChange,
  title
}) {
  return (
    <Bloc outline>
      <div className={styles.toggleContainer}>
        <Body1 color={colors.neutral['500']} fontWeight={600}>{title}</Body1>
        <Toggle checked={value} onChange={onChange} />
      </div>
      <div>
        {children}
      </div>
    </Bloc>
  )
}

ToggleInput.propTypes = {
  children: node,
  value: bool.isRequired,
  onChange: func.isRequired,
  title: string.isRequired
}

export default ToggleInput;
