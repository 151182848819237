import { isArray, isEmpty } from 'lodash';
import styles from '@/scenes/Event/components/StaticOffers/components/Filters/styles.less';
import Selector from '@/components/Form/Selector';
import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { Form } from 'antd';
import moment from 'moment';
import { getLocale } from '@/utils/localStorage';
import {
  getLabel
} from '@/scenes/Event/scenes/Recruiter/scenes/Preparation/scenes/Offer/containers/CreateOfferModal/components/CreateOfferForm/components/CriteriaFields/utils';

const FormItem = Form.Item;

export const AvailabilitySelector = ({
  isMobile,
  facetKey,
  matching,
  updateSearch,
  updateMatchingCustom,
  criterias,
  context,
  facets
}) => {
  const t = useFormatMessage();
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();
  const firstDay = new Date(year, month, 1);

  const availabilityCriterion = criterias.find((c) => c.key === 'AVAILABILITY');

  const availabilityOptions = Array.from(Array(12).keys()).map((item) => {
    const now = moment(firstDay).add(item, 'M');

    return {
      index: item,
      value: item === 0 ? moment().format('YYYY-MM') : now.startOf('month').format('YYYY-MM'),
      label: item === 0 ?
        t({ id: 'available.today' }) :
        now.locale(getLocale()).format('MMMM YYYY'),
    };
  });

  if (!availabilityOptions || !availabilityCriterion?.modules?.searchEngineParticipant?.isVisible) return '';

  const options = availabilityOptions.map((o) => ({
    value: o.value,
    label: o.label,
    badgeCount: getFacetAvailability(o.value)
  }));

  if (typeof matching.availability !== 'object') { // Si dès aujourd'hui est initialement selectionné, on coche toutes les dates supérieur
    if (matching.dateStr === moment().format('YYYY-MM')) {
      matching.availability = options.filter((o) => o.value !== '').map((p) => p.value);
    }
  }

  let availabilityValue = !isArray(matching.availability) ?
    [matching.availability]
    : matching.availability.flat().filter((v) => options.find((c) => c.value === v));

  if (!matching.dateStr && (isArray(matching.availability) && isEmpty(matching.availability))) {
    availabilityValue = [];
  }

  return (
    <FormItem className={styles.formItem} style={{ minWidth: '140px', width: '140px' }}>
      <Selector
        position="center"
        isMobile={!!isMobile}
        label={getLabel(availabilityCriterion.modules.searchEngineParticipant)}
        noLabel={t({ id: 'searchEngine.noLabel' })}
        options={options}
        values={availabilityValue.filter((v) => v)}
        onChange={(e) => {
          updateSearch('availability', e, facetKey, context);
          updateMatchingCustom(availabilityCriterion, availabilityValue.flat().filter((v) => !options.find((c) => c.value === v)), e, facetKey);
        }}
      />
    </FormItem>
  );

  function getFacetAvailability(value) {
    return (facets?.['matching.dateStr'] && typeof facets['matching.dateStr'] === 'object') ?
      parseInt(facets['matching.dateStr'][value], 10) : 0;
  }

}
