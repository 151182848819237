import React from 'react';
import { object, oneOf, bool, string } from 'prop-types';
import { TAG_COLORS, CONFERENCE_TOPICS_INDEX } from '@/utils/constants';
import { isEmpty } from 'lodash';
import { Tag } from '@seekube-tech/ui-kit';

import { useFormatMessage } from 'react-intl-hooks';

const TopicTag = ({ topic, context, filterByTopicEnable = true}) => {
  const t = useFormatMessage();

  if (!filterByTopicEnable && ['owner', 'candidate'].includes(context) || isEmpty(topic)) return null;

  return (
    topic &&
    <Tag
      size="small"
      color={TAG_COLORS[CONFERENCE_TOPICS_INDEX[topic]]}
    >
      <span>{t({ id: `conference.topics.${topic}` })}</span>
    </Tag>
  );
};

TopicTag.propTypes = {
  topic: object.isRequired,
  filterByTopicEnable: bool,
  context: oneOf(['owner', 'client', 'nonClient', 'candidate']),
  classname: string,
}

export { TopicTag };
