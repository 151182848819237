import { isArray, isEmpty } from 'lodash';
import classnames from 'classnames';
import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { Form } from 'antd';
import { object, func, boolean, string } from 'prop-types';
import { sortByOccurence } from '@/scenes/Event/scenes/Candidate/scenes/JobDating/components/SearchEngine/helpers';
import styles from '@/scenes/Event/components/StaticOffers/components/Filters/styles.less';
import Selector from '@/components/Form/Selector';

const FormItem = Form.Item;

export const SizeSelector = ({
  isMobile,
  facetKey,
  statusFilter,
  matching,
  updateSearch,
  updateMatching,
  search,
  facetsExponents,
  context,
  facets
}) => {
  const t = useFormatMessage();
  const sizeOptions = [
    t({ id: 'organization.size[0]' }),
    t({ id: 'organization.size[1]' }),
    t({ id: 'organization.size[2]' }),
    t({ id: 'organization.size[3]' }),
    t({ id: 'organization.size[4]' }),
  ];

  const options = sizeOptions.map((o, index) => ({ value: index, label: o, badgeCount: getFacetSize(index, context) }));

  let size = matching.filters.flat().filter((v) => options.find((c) => c.value === v));

  if (!isEmpty(search) && !isEmpty(search.size)) {
    if (isArray(search.size)) {
      size = search.size;
    } else {
      size = search.size.split(',');
    }
  }

  return (
    <FormItem
      className={classnames(styles.formItem, context === 'exponent' || statusFilter ? styles.formItemBordered : '')}
      style={{ minWidth: '140px', width: '140px' }}
    >
      <Selector
        position={context === 'exponent' ? 'right' : 'center'}
        isMobile={!!isMobile}
        label={t({ id: 'size' })}
        noLabel={t({ id: 'searchEngine.noLabel' })}
        options={sortByOccurence(options)}
        values={size}
        onChange={(e) => {
          if (context === 'exponent') {
            updateSearch('size', e, facetKey, context);
          } else {
            updateSearch('size', e, facetKey, context);
            updateMatching(matching.filters.flat().filter((v) => !options.find((c) => c.value === v)), e, facetKey);
          }
        }}
      />
    </FormItem>
  );

  function getFacetSize(value, context) {
    const items = context === 'exponent' ? facetsExponents : facets;

    return (items && items['organizationProfile.size'] &&
      typeof items['organizationProfile.size'] === 'object') ? items['organizationProfile.size'][value] : 0;
  };
}

SizeSelector.propTypes = {
  isMobile: boolean,
  facetKey: string,
  statusFilter: string,
  matching: object,
  updateSearch: func,
  updateMatching: func,
  search: object,
  facetsExponents: object,
  context: object,
  facets: object
}
