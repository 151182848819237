import React from 'react';
import { object, string } from 'prop-types';
import {
  AvatarBusiness,
  Body1,
  Caption1,
  CardLive,
  IconCalendar, IconCheckCircle, IconMic,
  IconUser,
  Tag
} from '@seekube-tech/ui-kit';
import moment from 'moment/moment';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { useFormatMessage } from 'react-intl-hooks';
import { Col } from 'antd';
import { getAuthUser } from '@/store/auth/selectors';
import { stripHtml } from '@/utils/text';
import { CONFERENCE_TOPICS_INDEX, TAG_COLORS } from '@/utils/constants';
import styles from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Exponents/scenes/Exponent/components/Conferences/styles.less'
import { getCurrentEvent } from '@/store/event/selectors';

const ConferencesList = ({
  conferences,
  redirection,
  target
}) => {
  const event = useSelector(getCurrentEvent)?.toJS();
  const authUser = useSelector(getAuthUser)?.toJS();
  const t = useFormatMessage();

  if (!conferences?.data?.pages) {
    return null;
  }

  const getTag = (conference) => {
    if (!isEmpty(conference.attendee) && !conference.attendee.canceled) {
      return (
        <Tag size="small"
             variant="fill"
             color="success"
             icon={<IconCheckCircle size={12}/>}
        >
          {t({ id: `conference.registred` })}
        </Tag>
      )
    }
    if (conference.countAttendees >= conference.maxAttendees){
      return (
        <Tag size="small" variant="fill" color="error">{t({ id: `conference.full` })}</Tag>
      )
    }
  }

  return (
    conferences?.data?.pages?.map(page => page?.docs?.map((conference) => (
      <Col xs={12} sm={6} key={conference._id}>
        <Link to={redirection || `/${event.slug}/candidate/jobdating/conferences/conference/${conference._id}`} target={target || ''}>
          <CardLive
            className="w-full"
            description={stripHtml(conference.description)}
            pictureUrl={conference.cover || conference._organization.profile.cover}
            tag={getTag(conference)}
            title={conference.title}
            infosLive={<div>
              <div className="flex mb-4">
                <IconCalendar className="text-neutral-300" size={16}/>
                <Body1 className="ml-8 text-neutral-500">
                  {moment.tz(conference.beginAt, authUser?.timezone.utc[0]).format('DD/MM - HH:mm')} ({conference.duration} min)
                </Body1>
              </div>
              <div className="flex mb-4">
                <IconUser className="text-neutral-300" size={16}/>
                <Body1
                  className="ml-8 text-neutral-500">{t({ id: `conference.maxAttendees` }, { count: conference.maxAttendees })}</Body1>
              </div>
              <div className="flex justify-between">
                <div className="flex">
                  <IconMic className="text-neutral-300" size={16}/>
                  <Body1
                    className="ml-8 text-neutral-500">{t({ id: `presenter.pl` }, { count: conference.presenters?.length })}</Body1>
                </div>
              </div>
            </div>}
            infosOrga={<div className={styles.infosOrga}>
              <AvatarBusiness className={styles.left} pictureUrl={conference._organization?.profile.pictureUrl}/>
              <div className={styles.right}>
                <div>
                  <Tag className="mb-8"
                       color={TAG_COLORS[CONFERENCE_TOPICS_INDEX[conference.topics[0]]]}
                       variant="tonal">
                    {t({ id: `conference.topics.${conference.topics[0]}` })}
                  </Tag>
                </div>
                <Caption1 className="text-neutral-300">{t({ id: `organizedBy` }, { name: conference._organization.name })}</Caption1>
              </div>
            </div>}
          />
        </Link>
      </Col>
    ))) || null
  )
}

ConferencesList.propTypes = {
  conferences: object,
  redirection: string,
  target: string
}

export default ConferencesList;
