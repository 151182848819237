import React from 'react';
import { injectIntl } from 'react-intl';
import { func, object } from 'prop-types';

// Components
import { Modal, InputNumber } from 'antd';
import { If, Then } from 'react-if';
import notification from '@/components/Notification';
import { Input } from '@/components/Input';
import Icon from '@/components/Icon';
import Video from './Video';

// Styles & Translations
import styles from './styles.less';
import messages from '../messages';

/**
 * VideoAdd
 */
class VideoAdd extends React.PureComponent {
  static propTypes = {
    onOk: func,
    intl: object,
  };

  /**
   * Constructor
   *
   * @param {object} props
   */
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      videoElement: {
        type: 'video',
        url: '',
        height: 200,
      },
    };
  }

  /**
   * Send value to the parent and close the modal
   */
  handleOnOk = () => {
    const { state: { videoElement }, props: { onOk, intl }, toggleModal } = this;

    // Send the videoElement only if we have an address
    if (videoElement.url !== '') {
      onOk(videoElement);
      toggleModal();
    } else {
      notification.error(intl.formatMessage(messages.videoAddNotificationError));
    }
  };

  /**
   * Toggle the modal
   */
  toggleModal = () => {
    const { state: { modalIsOpen, videoElement } } = this;

    const resetVideoElement = {
      ...videoElement,
      url: '',
    };

    this.setState({ modalIsOpen: !modalIsOpen, videoElement: resetVideoElement });
  };

  /**
   * Handle change from inputs
   *
   * @param {string} key
   */
  handleOnChange = (key) => (e) => {
    const { state: { videoElement } } = this;

    const value = typeof e === 'number' ? e : e.target.value;
    const newVideoElement = { ...videoElement, [key]: value };

    this.setState({ videoElement: newVideoElement });
  };

  /**
   * Render the modal with a input for enter a video url
   */
  renderModal = () => {
    const {
      props: { intl },
      state: { modalIsOpen, videoElement },
      handleOnOk,
      toggleModal,
      handleOnChange,
    } = this;

    return (
      <Modal
        title={intl.formatMessage(messages.videoAddModalTitle)}
        visible={modalIsOpen}
        onOk={handleOnOk}
        cancelText={intl.formatMessage({ id: 'cancel' })}
        onCancel={toggleModal}
      >
        <div>
          {/* Inputs */}
          <Input
            style={{ width: '100%' }}
            value={videoElement.url}
            placeholder={intl.formatMessage(messages.videoAddInputPlaceholder)}
            onChange={handleOnChange('url')}
          />
          <InputNumber
            className={styles.marginTop}
            style={{ width: '100%' }}
            min={100}
            value={videoElement.height}
            formatter={(value) => `${value}px`}
            parser={(value) => value.replace('px', '')}
            onChange={handleOnChange('height')}
          />
          {/* Preview */}
          <If condition={videoElement.url !== ''}>
            <Then>
              <Video className={styles.marginTop} element={videoElement} />
            </Then>
          </If>
        </div>
      </Modal>
    );
  };

  /**
   * Render
   */
  render() {
    const {
      toggleModal,
      renderModal,
    } = this;

    return (
      <div>
        { renderModal() }
        <a role="button" tabIndex={0} onClick={toggleModal}>
          <Icon name="video" />
        </a>
      </div>
    );
  }
}

export default injectIntl(VideoAdd);
