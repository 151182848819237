import { useQuery } from 'react-query';

import request from '@/utils/request';

export function useGetConference({ conferenceId }) {
  const requestUrl = `${process.env.FRONT_API_URL}/conferences/${conferenceId}`

  const queryFn = () => request(requestUrl, { method: 'GET' });

  const query = useQuery({
    queryKey: ['conferences', conferenceId],
    queryFn,
    refetchOnWindowFocus: false,
  });

  return query;
}
