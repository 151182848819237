import Icon from '@/components/Icon';
import { Typography } from '@seekube-tech/ui';
import { string } from 'prop-types';
import React from 'react';

import useStyles from './styles';

const Header = ({ title, icon }) => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <Icon name={icon} className={styles.categoryIcon} />
      <div>
        <Typography variant="overline" className={styles.categoryTitle}>
          {title}
        </Typography>
      </div>
    </div>
  )
}

Header.propTypes = {
  title: string,
  icon: string
}

export { Header }
