import { get, isEmpty } from 'lodash';
import { track } from '@/utils/analytics';
import { ANALYTICS_CANDIDATE, ANALYTICS_RECRUITER } from '@/utils/constants';

export const trackUserPatch = (authUser, data) => {
  const analyticName = get(authUser, '_currentOrganization') ? ANALYTICS_RECRUITER.EDIT_PROFILE : ANALYTICS_CANDIDATE.EDIT_PROFILE;
  
  track({
    user: authUser,
    name: analyticName,
    properties: {
      step: 'Settings',
      photo: !isEmpty(data.pictureUrl),
      summary: !isEmpty(data.description),
      myProfile: true,
      full: !isEmpty(data.pictureUrl) && !isEmpty(data.title) && !isEmpty(data.firstName) && !isEmpty(data.lastName),
    },
  })
}
