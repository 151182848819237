import Moment from 'moment';
import { extendMoment } from 'moment-range/dist/moment-range';
import { get } from 'lodash';

const moment = extendMoment(Moment);


/**
 * Slot Overlaps
 *
 * @param {object} slot From react-big-calendar
 * @param {Array} timeslots Timeslots grouped
 * @param {Number} slotDuration
 */
export const slotOverlaps = (
  slot,
  timeslots = [],
  slotDuration = 30
) => {
  // beginAt will be used if it's a slot group
  const slotStart = slot.start || slot.beginAt;

  const rangeToTest = moment.range(
    slotStart,
    moment(slotStart).add(slotDuration, 'minutes')
  );

  return timeslots.findIndex((group) => {
    const groupRange = moment.range(
      group.beginAt,
      moment(group.beginAt)
        .add(slotDuration, 'minutes')
    );

    return rangeToTest.overlaps(groupRange);
  }) !== -1;
};

export function eventContainsTimeslot(event, slot, slotDuration = 30) {
  // beginAt will be used if it's a slot group
  const slotStart = slot.start || slot.beginAt;
  const slotEnd = moment(slotStart).add(slotDuration, 'minutes');

  return moment(event.beginAt).isBefore(slotStart) && moment(slotEnd).isBefore(event.endAt);
}

export function groupTimeSlots(slots, currentEvent) {
  return slots
    .filter((timeslot) => (
      moment(timeslot.beginAt) >= moment(get(currentEvent, 'keyDates.jobfair.beginAt')) &&
      moment(timeslot.beginAt) <= moment(get(currentEvent, 'keyDates.jobfair.endAt')))
    ).map((slot) => [slot]);
}
