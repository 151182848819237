import React from 'react';
import { isEmpty } from 'lodash';
import { H6, Body1 } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';

export const Description = ({ participant }) => {
  if (isEmpty(participant._user.description)) return null;

  const t = useFormatMessage();

  return (
    <div>
      <H6 className="text-neutral-500 mb-8">{t({ id: 'description' })}</H6>
      <Body1 className="text-neutral-400">{participant._user.description}</Body1>
    </div>
  )
}