import styles from '@/scenes/Event/components/StaticOffers/components/Filters/styles.less';
import { includes, isUndefined } from 'lodash';
import { Col, Row } from 'antd';
import { Button, IconPencil, IconRefreshCcw } from '@seekube-tech/ui-kit';
import React from 'react';
import { useIsMobile } from '@/utils/MediaQueries';
import { useFormatMessage } from 'react-intl-hooks';
import OffersBlankState from '@/scenes/Event/scenes/Candidate/scenes/JobDating/components/OffersBlankState';

const ResultsActions = ({
  event,
  context,
  preview,
  searchIsEmpty,
  totalOffers,
  analyticsEditSearchBtn,
  resetSearch
}) => {
  const isMobile = useIsMobile();
  const t = useFormatMessage();

  return (
    <div className={[styles.resetContainer, (isUndefined(event)) ? 'empty' : ''].join(' ')}>
      {context === 'offer' && <OffersBlankState countSearchOffers={totalOffers}/>}
      <Row type="flex" justify="center"   gutter={[32, 16]}>
        {(includes(['offer'], context) && !preview) &&
          <Col span={isMobile && 12} order={totalOffers > 0 ? 2 : 1}>
            <Button
              className={styles.resetButtons}
              imageComponentLeft={<IconPencil size={16} />}
              variant={totalOffers > 0 ? 'fill' : 'outline'}
              color={totalOffers > 0 ? 'primary' : 'neutral'}
              onClick={() => analyticsEditSearchBtn()}>
              {t({ id: 'matching.update' })}
            </Button>
          </Col>
        }
        {(!isUndefined(event) && !preview && !searchIsEmpty()) &&
          <Col span={isMobile && 12} order={totalOffers > 0 ? 1 : 2}>
            <Button
              className={styles.resetButtons}
              variant={totalOffers > 0 ? 'outline' : 'fill'}
              color={totalOffers > 0 ? 'neutral' : 'primary'}
              onClick={resetSearch}
              imageComponentLeft={<IconRefreshCcw size={16} />}
            >
              {t({ id: 'event.resetSearch' })}
            </Button>
          </Col>
        }
      </Row>
    </div>
  )
}

export default ResultsActions;
