import React, { useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { func, bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Button } from '@seekube-tech/ui-kit';
import { Modal, Form } from 'antd';
import Icon from '@/components/Icon';
import InputMaterial from '@/components/Input/InputMaterial';

import { toJS } from '@/utils';

// Store
import { organizationActions, organizationSelectors } from '@/store/organization';

import styles from '../../../styles.less';
import messages from '../../../messages';

const FormItem = Form.Item;

const AddOrganizationModal = ({ visible, handleClose, form, intl, alreadyExistError, resetAlreadyExist, postOrganization }) => {
  const [isLoading, setIsLoading] = useState(false);

  const addOrganization = (e) => {
    if (e) { e.preventDefault(); }
    const newOrganization = form.getFieldValue('newOrganization');
    setIsLoading(true);
    postOrganization({
      organizationParams: { name: newOrganization, type: 'company' },
      callback: () => {
        form.setFieldsValue({ newOrganization: '' });
        setIsLoading(false);
        handleClose();
      },
    });
  };

  const { getFieldDecorator } = form;

  return (
    <Modal
      visible={visible}
      keyboard={false}
      onCancel={() => handleClose(null)}
      footer={null}
      maskClosable
      closable
      className="matchingModal"
      style={{ top: '8%' }}
    >
      <a role="button" tabIndex="0" className="modalCloseButton" onClick={(e) => handleClose(e)}>
        <Icon name="close-modal" />
      </a>
      <div style={{ padding: '30px 75px' }}>
        <div>
          <span className={styles.title}><FormattedMessage {...messages.createOrganization} /></span>
          <Form onSubmit={addOrganization} className="mt-20">
            <FormItem>
              {getFieldDecorator('newOrganization', {
                rules: [{ required: true, message: <FormattedMessage {...messages.createOrganization} /> }],
              })(
                <InputMaterial onChange={() => resetAlreadyExist()} addOnIcon="building" label={intl.formatMessage(messages.organizationName)} placeholder={intl.formatMessage(messages.organizationName)} type="text" />
              )}
              {form.getFieldValue('newOrganization') !== '' && alreadyExistError && <span style={{ color: '#F95377' }}><FormattedMessage {...messages.existError} /></span>}
            </FormItem>

            <div>
              <Button className="mt-20" loading={isLoading} type="submit">
                <FormattedMessage {...messages.registerOrganization} />
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  );
}


AddOrganizationModal.propTypes = {
  form: object,
  visible: bool,
  handleClose: func,
  intl: object,
  postOrganization: func,
  alreadyExistError: bool,
  resetAlreadyExist: func,
};

const mapStateToProps = createStructuredSelector({
  alreadyExistError: organizationSelectors.alreadyExistError,
});
const mapDispatchToProps = {
  postOrganization: organizationActions.postOrganization,
  resetAlreadyExist: organizationActions.resetAlreadyExist,
};
const withForm = Form.create()(AddOrganizationModal);
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  injectIntl,
  withConnect,
)(toJS(withForm));

