import React, { useState } from 'react';
import { object, func, bool } from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { isEmpty, map } from 'lodash';
import moment from 'moment';
import { useFormatMessage } from 'react-intl-hooks';
import { Form } from 'antd';
import { Button, IconEye } from '@seekube-tech/ui-kit';
import { toJS } from '@/utils';

import { authSelectors } from '@/store/auth';
import { exponentSelectors } from '@/store/exponent';

import Html from '@/components/Html';
import { If } from '@/components/If';

import styles from './styles.less';

const RecapConference = ({ conference, authUser }) => {
  const t = useFormatMessage();
  return (
    <div className={styles.recapContainer}>
      <h2>{conference.title}</h2>
      <div>
        <FormattedHTMLMessage
          id="recruiter.live.edit.recap.date"
          values={{ date: moment.tz(conference.beginAt, authUser.timezone.utc[0]).format('DD MMMM YYYY - HH:mm') }}
        />
        <FormattedHTMLMessage
          id="recruiter.live.edit.recap.maxAttendees"
          values={{ count: conference.maxAttendees }}
        />
        <FormattedHTMLMessage
          id="recruiter.live.edit.recap.format"
          values={{ format: conference.format }}
        />
        <FormattedHTMLMessage
          id="recruiter.live.edit.recap.topics"
          values={{
            name: map(conference.topics, (topic) => t({ id: `conference.topics.${topic}` })).join(''),
          }}
        />
        <FormattedHTMLMessage
          id="recruiter.live.edit.recap.duration"
          values={{ count: conference.duration }}
        />
        <If condition={conference.eventName}>
          <FormattedHTMLMessage
            id="recruiter.live.edit.recap.jobdating"
            values={{ name: conference.eventName }}
          />
        </If>
      </div>
      <br />
      <div>
        <span className={styles.presentersContainer}>
          <FormattedHTMLMessage
            id="recruiter.live.edit.recap.presenter"
            values={{ name: conference.eventName }}
          />

          <div className={styles.presenters}>{
            !isEmpty(conference.presenters) && conference.presenters.map(
              (presenter, i) => (<span key={i}>{`${presenter.firstName} ${presenter.lastName} - ${presenter.title}`}
               - {presenter.email}</span>)
            )
          }
          </div>
        </span>
      </div>
      <br />
      <div>
        <span className={styles.presentersContainer}>
          <FormattedHTMLMessage id="recruiter.live.edit.recap.description" />

          <div className={styles.htmlWrapper}>
            <Html
              value={conference.description}
            />
          </div>
        </span>
      </div>
    </div>
  );
};

RecapConference.propTypes = {
  conference: object,
  authUser: object,
};

const EditLivetalkStep5 =
  ({ form, onSubmit, conference, isLoading, authUser, onModify, openPreview, onClose }) => {
    const t = useFormatMessage();
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleSubmit = () => {
      form.validateFields(() => {
        onSubmit();
        setIsSubmitted(true);
      });
    };

    return (
      <div className={styles.editLivetalkStep5}>
        {isSubmitted && !isLoading ?
          <div className={styles.success}>
            <span aria-label="" role="img" className={styles.emoji}>🎉</span>
            <FormattedMessage id="recruiter.live.edit.recap.success.title" tagName="h1" />
            <FormattedHTMLMessage id="recruiter.live.edit.recap.success.ul" />
            <div className={styles.buttonBackContainer}>
              <Button color='primary' variant='fill' onClick={onClose}>{t({id: 'live.creation.goTo.lives'})}</Button>
            </div>
          </div> :
          <div>
            <div className={styles.recapTitle}>
              <FormattedMessage id="recruiter.live.edit.recap.title" tagName="h1" />
              <Button color='primary' variant='text' imageComponentLeft={<IconEye size={12} />} onClick={openPreview}>
                {t({id: "conference.preview"})}
              </Button>
            </div>
            <FormattedMessage id="recruiter.live.edit.recap.text.verify" tagName="p" />
            <RecapConference conference={conference} authUser={authUser} />

            <div className={styles.actions}>
              <Button className={styles.modifyButton} color='primary' variant='tonal' onClick={onModify}>
                <FormattedMessage id="edit" />
              </Button>
              <Button
                color='primary'
                variant='fill'
                onClick={handleSubmit}
                loading={isLoading}
              >
                <FormattedMessage id="publish" />
              </Button>
            </div>
          </div>
        }
      </div>
    );
  };

EditLivetalkStep5.propTypes = {
  form: object,
  conference: object,
  onSubmit: func,
  authUser: object,
  isLoading: bool,
  onModify: func,
  openPreview: func,
  onClose: func,
};

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser,
  exponents: exponentSelectors.getExponents,
});

const withConnect = connect(mapStateToProps, null);
const withForm = Form.create();

export default compose(
  withConnect,
  withForm
)(toJS(EditLivetalkStep5));
