import React from 'react';
import { compose } from 'redux';
import className from 'classnames';

// Containers
import { ReactTitle } from 'react-meta-tags';
import { Button } from '@seekube-tech/ui-kit';
import { FormattedMessage } from 'react-intl';
import { withLayout } from '@/containers/Layout';

// Components

// Containers
import EventList from './containers/EventList';
import CreateEventModal from './containers/CreateEventModal';

// Styles & Translations
import styles from './styles.less';

/**
 * DashboardScene
 */
class DashboardScene extends React.PureComponent { // eslint-disable-line
  render() {
    return (
      <div>
        <ReactTitle title="Admin Seekube" />

        {/* Modal for create an event */}
        <CreateEventModal
          mode="new"
        >
          <Button id="btnCreateEvent" className={className(styles.btnCreateEvent, 'absolute')}>
            <FormattedMessage id="create.event" />
          </Button>
        </CreateEventModal>

        {/* EventList */}
        <EventList />
      </div>
    );
  }
}

export default compose(
  withLayout({ size: 'large', withHeader: true, withNav: false }),
)(DashboardScene);
