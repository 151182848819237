import React from 'react';
import { object } from 'prop-types';
import StandSidebarHome
  from "@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Exponents/scenes/Exponent/components/Home/Sidebar/StandSidebarHome";
import {
  BenefitsJoin
} from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Exponents/scenes/Exponent/components/Home/BenefitsJoin';
import { useIsTablet } from '@/utils/MediaQueries';
import styles from "@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Exponents/scenes/Exponent/stylesV2.less";
import { EVENT_TYPE } from '@/utils/constants';
import KeyNumbersCandidate from './KeyNumbersCandidate';
import StandSocialMedia from './StandSocialMedia';
import StandPresentation from './StandPresentation';
import StandProcessRecruitment from './StandProcessRecruitment';
import AdditionnalText from './AdditionnalText/AdditionnalText';
import Gallery from './Medias/Gallery';
import GalleryVideo from './Medias/GalleryVideo';


const StandHome = ({
  match,
  exponent,
  sidebarConferences,
  sidebarOffers,
  sidebarInformal1to1s,
  showSidebar = true,
  event,
  profile
}) => {
  const isTablet = useIsTablet();
  const isJpo = event?.type === EVENT_TYPE.jpo;

  return (
    <div className={styles.standCandidateContent}>
      <div className={styles.standCandidateContentLeft}>
        {!isJpo && <KeyNumbersCandidate profile={profile} />}
        <StandPresentation profile={profile} />
        {isTablet ? showSidebar && sidebarConferences && sidebarOffers && sidebarInformal1to1s && <StandSidebarHome
          profile={profile}
          conferences={sidebarConferences}
          informal1to1s={sidebarInformal1to1s}
          offers={sidebarOffers}
          isPartner={exponent?.isPartner}
          event={event}
          match={match}
        /> : <></>}
        <StandSocialMedia profile={profile} />
        <AdditionnalText profile={profile} />
        <Gallery profile={profile} />
        <GalleryVideo profile={profile} />
        <BenefitsJoin profile={profile} />
        <StandProcessRecruitment profile={profile} />
      </div>
      {!isTablet ? showSidebar && sidebarConferences && sidebarOffers && sidebarInformal1to1s && <StandSidebarHome
        profile={profile}
        conferences={sidebarConferences}
        informal1to1s={sidebarInformal1to1s}
        offers={sidebarOffers}
        isPartner={exponent?.isPartner}
        event={event}
        match={match}
      /> : <></>}
    </div>
  )
}

StandHome.propTypes = {
  exponent: object,
  profile: object,
  event: object,
  match: object,
  sidebarConferences: object,
  sidebarOffers: object,
  sidebarInformal1to1s: object
}

export default StandHome;
