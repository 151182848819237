import React, { memo } from 'react';
import { object } from 'prop-types';
import { Tab } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { useHistory } from 'react-router';


const StatisticsMenu = memo(({
  match: { url },
}) => {
  const t = useFormatMessage();
  const history = useHistory();
  const tabs = [
    {
      name: t({ id: 'event.owner.statistics.menu.candidates' }),
      url: `${url}/participants`,
      index: 0,
    },
    {
      name: t({ id: 'event.owner.statistics.menu.companies' }),
      url: `${url}/exponents`,
      index: 1,
    },
    {
      name: t({ id: 'event.owner.statistics.menu.attractiveness' }),
      url: `${url}/exponents-rankings`,
      index: 2,
    },
  ];

  const defaultIndex = tabs.find((tab) => tab.url === history.location.pathname)?.index;

  const onIndexChange = (index) => {
    history.push(tabs[index].url)
  }

  return (
    <Tab.Group defaultIndex={defaultIndex} onChange={onIndexChange} className="mb-20">
      <Tab.List>
        {tabs.map(({ name, url }) => (
          <Tab key={url} as="span">
            {name}
          </Tab>
        ))}
      </Tab.List>
    </Tab.Group>
  );
});

StatisticsMenu.propTypes = {
  match: object,
};

export { StatisticsMenu };
