import { defineMessages } from 'react-intl';

export default defineMessages({
  accepted: {
    id: 'accepted.plgr',
  },
  refused: {
    id: 'refused.plgr',
  },
  pending: {
    id: 'untreated.plgr',
  },
  visits: {
    id: 'view.pl',
  },
  participants: {
    id: 'participant.plgr',
  },
  attendees: {
    id: 'registered.plgr',
  },
  application: {
    id: 'application.pl',
  },
  total: {
    id: 'total',
  },
});
