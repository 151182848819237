import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { func, object } from 'prop-types';
import { Field } from 'react-final-form';
import { TextField } from '@seekube-tech/ui';
import { Button } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';

import { COLORS } from '@/utils/constants';

import Icon from '@/components/Icon';
import Modal from '@/components/Modal';
import { CornerTag } from '@/components/Corners';
import DragTable from '@/components/DragTable';
import FieldColor from '../FieldColor';

import styles from './styles.less';

function Table({ fields, handleSortRow, handleSubmit }) {
  /* Vars */

  const t = useFormatMessage();
  const [modal, setModal] = useState({ isVisible: false, params: {} });

  /* Functions */

  /**
   * Open modal for confirm remove corner
   * @param {Number} index
   */
  const handleRemoveCorner = (index) => () => {
    setModal({ isVisible: true, params: { index } });
  };

  /**
   * Close modal
   */
  const handleCancelModal = () => {
    setModal({ isVisible: false, params: {} });
  };

  /**
   * Delete corner and close modal
   */
  const handleConfirmModal = () => {
    fields.remove(modal.params.index);

    handleCancelModal();

    handleSubmit();
  };

  /**
   * Add a new corner
   */
  const handleAddCorner = () => {
    fields.push({
      label_fr: '',
      label_en: '',
      color: COLORS.secondary,
    });
  };

  /* Render */

  return (
    <div className={styles.root}>
      <DragTable
        onSort={handleSortRow}
        dataSource={fields.value}
        pagination={false}
        rowKey="_id"
        columns={[
          {
            title: t({ id: 'event.modules.corner.column.order' }),
            key: 'order',
            align: 'center',
            render: () => (
              <Icon name="menu-burger-3" className={styles.orderedIcon} />
            )
          },
          {
            title: t({ id: 'event.modules.corner.column.labelFr' }),
            key: 'label_fr',
            className: styles.alignTop,
            render: (_, __, index) => (
              <Field
                name={`${fields.name}[${index}].label_fr`}
                component={TextField}
                placeholder={t({ id: 'event.modules.corner.column.labelFr-placeholder' })}
                fullWidth
              />
            )
          },
          {
            title: t({ id: 'event.modules.corner.column.labelEn' }),
            key: 'label_en',
            className: styles.alignTop,
            render: (_, __, index) => (
              <Field
                name={`${fields.name}[${index}].label_en`}
                component={TextField}
                placeholder={t({ id: 'event.modules.corner.column.labelEn-placeholder' })}
                fullWidth
              />
            )
          },
          {
            title: t({ id: 'event.modules.corner.column.color' }),
            key: 'color',
            align: 'center',
            render: (_, __, index) => (
              <Row type="flex" justify="center">
                <Col>
                  <Field
                    name={`${fields.name}[${index}].color`}
                    component={FieldColor}
                  />
                </Col>
              </Row>
            )
          },
          {
            title: t({ id: 'event.modules.corner.column.preview' }),
            key: 'preview',
            align: 'center',
            render: (row) => (
              <CornerTag corner={row} />
            )
          },
          {
            title: t({ id: 'event.modules.corner.column.action' }),
            key: 'action',
            render: (_, __, index) => (
              <a
                role="button"
                onClick={handleRemoveCorner(index)}
                className={styles.remove}
                tabIndex={0}
              >
                <Icon name="trash" className={styles.removeIcon} /> {t({ id: 'event.modules.corner.remove' })}
              </a>
            )
          }
        ]}
      />

      <Row type="flex" align="middle" className={styles.addContainer}>
        <Col>
          <a
            role="button"
            onClick={handleAddCorner}
            className={styles.add}
            tabIndex={0}
          >
            <Icon name="add" className={styles.addIcon} /> {t({ id: 'event.modules.corner.add' })}
          </a>
        </Col>
      </Row>

      <Modal
        visible={modal.isVisible}
        footer={false}
        maskClosable
        width={470}
        className="customConfirm"
        onCancel={() => { }}
      >
        <h4 style={{ fontWeight: 600 }}>
          {t({ id: 'event.modules.corner.modal.title' })}
        </h4>

        <div className={styles.modalDescription}>
          {t({ id: 'event.modules.corner.modal.description' })}
        </div>

        <div className="confirm-actions flex" style={{ marginTop: '24px' }}>
          <Button onClick={handleConfirmModal} className="mr-6">
            {t({ id: 'btn.confirm' })}
          </Button>
          <Button variant="tonal" onClick={handleCancelModal}>
            {t({ id: 'cancel' })}
          </Button>
        </div>
      </Modal>
    </div>
  );
}

Table.propTypes = {
  fields: object.isRequired,
  handleSortRow: func.isRequired,
  handleSubmit: func.isRequired,
};

export default Table;
