import React from 'react';
import { func } from 'prop-types';
import { Button, IconEye } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';

export const PreviewBtn = ({ onClick }) => {
  const t = useFormatMessage();

  return (
    <Button
      variant='outline'
      color='neutral'
      onClick={onClick}
      imageComponentLeft={<IconEye size={16}/>}>
      {t({ id: 'preview' })}
    </Button>
  )
}

PreviewBtn.propTypes = {
  onClick: func,
}
