import React, { useEffect, useRef } from 'react';
import "friendly-challenge/widget";
import { WidgetInstance, localizations } from 'friendly-challenge';
import styles from './styles.less';
import { getLocale } from '@/utils/localStorage';

const Captcha = ({ onSuccess, onError, widget }) => {
  
  if (!widget) {
    return null;
  }
  
  const container = useRef();
  const locale = getLocale();

  useEffect(() => {
    if (!widget.current && container.current) {
      widget.current = new WidgetInstance(container.current, {
        startMode: "auto",
        doneCallback: onSuccess,
        errorCallback: onError,
        language: localizations[locale]
      });
    }

    return () => {
      if (widget.current !== undefined) widget.current.reset();
    }
  }, [container]);

  return (
    <div>
      <div ref={container} className={`frc-captcha ${styles.captcha}`} data-sitekey={process.env.FRONT_FRIENDLY_CAPTCHA_SITEKEY}/>
    </div>
  );
}

export { Captcha }