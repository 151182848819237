import { useMutation } from 'react-query';
import request from '@/utils/request';

export const usePostSwitchLogout = ({ onSuccess = () => {}, onError = () => {} }) => {

  const mutationFn = () => {
    const requestUrl = `${process.env.FRONT_API_URL}/users/switch-logout`;

    return request(requestUrl, { method: 'POST' });
  };

  return useMutation({
    mutationFn,
    onSuccess: async () => {
      onSuccess();
    },
    onError: async () => {
      onError();
    },
  });
}