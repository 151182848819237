import React, { useEffect, useState } from 'react';
import { any, bool, func, array, object } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { isNil, isEmpty, get } from 'lodash';
import { useFormatMessage } from 'react-intl-hooks';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Alert } from '@seekube-tech/ui-kit';
import { Typography, Grid } from '@seekube-tech/ui';
import { createStructuredSelector } from 'reselect';
import MobileDetect from 'mobile-detect';
import moment from 'moment';
import { reorderTimeslotsByDate, timeslotUtils } from '@/utils/timeslots';
import { trackCandidateClickedViewKeyDates } from '@/utils/analytics';
import { appointmentActions } from '@/store/appointment';
import ModalTitle from '@/components/ModalTitle';
import Textarea from '@/components/Textarea';
import Progress from '@/components/Progress';
import SetTimezone from '@/components/SetTimezone';
import CancelAppointmentModal from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Appointments/components/CancelAppointmentModal';
import styles from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Appointment/components/MainContent/styles.less';
import messages from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Appointment/components/MainContent/messages';
import { notificationActions } from '@/store/notification';
import {
  getTitle
} from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Appointment/components/MainContent/components/Slots/getTitle'

import { getAvailableActions } from '@/store/availableActions/selectors';
import { eventSelectors } from '@/store/event';
import { toJS } from '@/utils';
import { interactionSelectors } from '@/store/interaction';
import { Slot } from './components/Slot';
import { RecruiterToContact } from './components/RecruiterToContact';
import DaySelection from './components/DaySelection';

const Slots = ({
  handleShowOffer,
  handleSelectSlot,
  slotSelected,
  appointment,
  timeslots,
  handleConfirmSelection,
  handleCancelSelection,
  skipValidation,
  modifyAppointment,
  match,
  sendNotification,
  event,
  history,
  interactions
}) => {
  const interaction = interactions.find((inter) => inter._appointment?._id === appointment?._id)

  const t = useFormatMessage();
  const md = new MobileDetect(window.navigator.userAgent);

  const hasAppointmentBeenModified = appointment?.status === 'pending' && appointment?.wasAcceptedAndUpdatedAt;

  const [daySlots, setDaySlots] = useState({
    all: [],
    am: [],
    pm: [],
    lastDay: null,
  });

  const [selectedDay, setSelectedDay] = useState(window.moment(timeslots.sort(reorderTimeslotsByDate)[0]?.beginAt).startOf('day'));
  const [refuseModalVisible, setRefuseModalVisible] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [dateNow] = useState(moment());
  const [messageCandidate, setMessageCandidate] = useState(
    appointment._event.areInteractionsClosed && !hasAppointmentBeenModified ?
      t({ id: 'event.candidate.appointment.mainContent.interaction-are-closed.mail' }) :
      t({ id: 'event.candidate.appointment.mainContent.slot-unavailable.mail' })
  );
  const [step, setStep] = useState((skipValidation === false && appointment.origin === 'informal1to1' && isEmpty(appointment.acceptedAt) && isEmpty(appointment.wasAcceptedAndUpdatedAt)) ? 'reason' : 'slots');
  const [
    messageInformal1to1Candidate,
    setMessageInformal1to1Candidate
  ] = useState(appointment?.messageInformal1to1Candidate ?? '');

  useEffect(() => {
    getTimeslotOfTheDay(window.moment(timeslots.sort(reorderTimeslotsByDate)[0]?.beginAt).startOf('day'));
  }, [timeslots]);

  /**
   * getTimeslotOfTheDay
   *
   * @description : get the slots for the selected day and create a day navigation
   */
  const getTimeslotOfTheDay = (day) => {
    const timeslotsComputedData = timeslotUtils.getTimeslotsComputedDataByDay(timeslots, day);

    if (!timeslotsComputedData) {
      return null;
    }

    setDaySlots({
      all: timeslotsComputedData.timeslots,
      am: timeslotsComputedData.timeslotsAM,
      pm: timeslotsComputedData.timeslotsPM,
      lastDay: timeslotsComputedData.lastDay,
    });
    setSelectedDay(day);


    return true;
  };

  const handleOnSelectDay = (e, selectedDay) => {
    e.preventDefault();
    getTimeslotOfTheDay(selectedDay);
  };

  const handleOnConfirmSlot = () => {
    setSubmitLoading(true);
    setTimeout(() => {
      setSubmitLoading(false)
      window.scrollTo(0, 0)
    }, 2000);

    handleConfirmSelection(messageInformal1to1Candidate);
  }

  const handleChangeInformalDescription = (event) => {
    setMessageInformal1to1Candidate(event.target.value);
  };

  const renderHeader = (title, values) => (
    <ModalTitle
      imgSrc={appointment?._organization?.profile?.pictureUrl}
      title={(
        <FormattedMessage
          {...title}
          values={values}
        />
      )}
    />
  );

  const renderOfferResume = () => {
    if (appointment._offers && appointment._offers.length === 1) {
      return (
        <div className={styles.offerResume}>
          <span><FormattedMessage id="event.candidate.appointment.mainContent.offer" /></span>
          <a role="button" tabIndex={0} onClick={() => handleShowOffer(appointment._offers[0])}>{appointment._offers[0].title}</a>
        </div>
      );
    }
    return null;
  }

  const renderDaySelect = () => {
    const startDate = window.moment(get(timeslots[0], 'beginAt')).startOf('day');
    const endDate = daySlots?.lastDay?.beginAt && window.moment(daySlots.lastDay.beginAt).startOf('day');

    if (!isNil(daySlots.lastDay)) {
      return (
        <DaySelection
          startDate={startDate}
          endDate={endDate}
          handleSelectDay={handleOnSelectDay}
          selectedDay={selectedDay}
          timeslots={timeslots}
        />
      );
    }

    return null;
  }

  const renderNoSlotResume = () => (
    <p>
      <FormattedMessage
        id="event.candidate.appointment.mainContent.noSlotsText"
        values={{ recruiter: appointment._organizationUser.shortName }}
      />
    </p>
  )

  const renderSlots = () => {
    const unavailableMessage = t({ id: 'event.candidate.appointment.mainContent.slotUnavailable' });

    return (
      <ul className={styles.timeslots}>
        { daySlots?.am?.map((slot) => (
          <Slot
            key={slot._id}
            tooltipMessage={unavailableMessage}
            slot={slot}
            handleSelectSlot={handleSelectSlot}
            slotSelected={slotSelected}
          />
        ))}
        { daySlots?.pm?.map((slot) => (
          <Slot
            key={slot._id}
            tooltipMessage={unavailableMessage}
            slot={slot}
            handleSelectSlot={handleSelectSlot}
            slotSelected={slotSelected}
          />
        ))}
      </ul>
    );
  }

  const renderFooter = (noSlot) =>  (
    <div className={classNames(styles.slotFooter, md.userAgent() === 'Safari' && styles.slotFooterSafari)}>
      <div className="progressWrapper">
        {refuseModalVisible && (
          <CancelAppointmentModal
            visible={refuseModalVisible}
            onVisibleChange={setRefuseModalVisible}
            appointment={appointment}
            handleCancelMessage={handleCancelSelection}
          />
        )}
        <>
          {skipValidation && appointment.origin !== 'informal1to1' && (
            <Progress
              items={[
                { isActive: false, label: t({ id: 'appointment.footer.step.criterion' }) },
                { isActive: true, label:t({ id: 'appointment.footer.step.slot' }),
                }]}
            />
          )}

          {skipValidation
            && appointment.origin === 'informal1to1'
            && appointment.status !== 'unconfirmed'
            && isEmpty(appointment.wasAcceptedAndUpdatedAt)
            && (
              <Progress
                items={[
                  { isActive: step === 'slots', label: t({ id: 'appointment.footer.step.slot' }) },
                  { isActive: step === 'reason', label: t({ id: 'appointment.footer.step.reason' }),
                  }]}
              />
            )}

          {noSlot ? (
            <div>
              {!skipValidation && (
                <a onClick={() => setRefuseModalVisible(true)} role="button" tabIndex={0}>
                  <FormattedMessage {...messages.cancelAppointment} />
                </a>
              )}
            </div>
          ) : (
            <div className={skipValidation ? 'progressAction' : ''}>
              {!skipValidation && appointment.origin !== 'informal1to1' && (
                <a role="button" tabIndex="0" onClick={() => setRefuseModalVisible(true)} style={{ marginRight: '15px' }}>
                  <FormattedMessage id="event.candidate.appointment.cancel" />
                </a>

              )}

              {appointment.origin === 'informal1to1' && step === 'slots' && isEmpty(appointment.acceptedAt) && isEmpty(appointment.wasAcceptedAndUpdatedAt) ? (
                <Button color="primary" onClick={() => setStep('reason')} disabled={isNil(slotSelected)}>
                  <FormattedMessage id="next" />
                </Button>
              ) : (
                <Button
                  color="primary"
                  loading={submitLoading}
                  onClick={handleOnConfirmSlot}
                  disabled={step === 'reason' ? isEmpty(messageInformal1to1Candidate) : isNil(slotSelected)}
                >
                  {appointment.origin === 'informal1to1' ? (
                    <FormattedMessage
                      id={skipValidation ?
                        'submit' :
                        (step === 'slots' ? 'submit' : 'event.candidate.jobdating.offer.applicationModal.apply')
                      }
                    />
                  ) : (
                    <FormattedMessage id="submit" />
                  )}
                </Button>
              )}
            </div>
          )}
        </>
      </div>
    </div>
  );

  const renderInteractionAreClosed = () => (
      <div className="appointementWrapper" style={{ paddingBottom: 10 }}>
        {renderHeader({
          id: 'event.candidate.appointment.mainContent.interactionClosed.title',
        })}
        <Typography variant="body1">
          {t({ id: 'event.candidate.appointments.card.confirm.disabled'})}
          <br />
          {t({ id: 'event.candidate.appointment.mainContent.interactionClosed.consult-keydates'})}
          <Typography
            className={styles.clickHere}
            variant="link1"
            target="_blank"
            component="a"
            href={`${window.location.origin}/${match.params.eventSlug}/candidate/keydates`}
            onClick={() => trackCandidateClickedViewKeyDates({ authUser: appointment._user })}
          >
            {t({ id: 'click.here' })}
          </Typography>
        </Typography>
        <br />
        <Typography variant="body1">
          {t({ id: 'event.candidate.appointment.mainContent.interactionClosed.recruitername'},
            { recruiter: appointment._organizationUser.shortName })}
        </Typography>
        {
          !isEmpty(appointment.messageCandidate) &&
          <Alert className="mb-15 mt-15">
            <FormattedMessage id="alert.already.send.mail.to.recruiter"/>
          </Alert>
        }
        <div className={classNames('formItem', styles.contact)} style={{ marginBottom: '32px' }}>
          <div>
            <RecruiterToContact
              size={70}
              className={styles.recruiterSignature}
              _organizationUser={appointment._organizationUser}
              title={t({ id: 'talent.modal.contact.title' }, { name: appointment._organizationUser.shortName })}
            />
          </div>
          <Textarea
            value={messageCandidate}
            onChange={(e) => setMessageCandidate(e.currentTarget.value)}
            containerClassName={styles.textareaContainer}
            placeholder=""
            label=""
          />
          <div className={styles.btnSendMail}>
            <Button
              color="primary"
              disabled={!isEmpty(appointment.messageCandidate)}
              onClick={() => modifyAppointment({
                appointmentId: appointment._id,
                messageCandidate,
                status: 'pending',
                eventSlug: appointment._event.slug,
                callback: () => sendNotification({
                  message: t({ id: 'candidate.talent.reply.success.title' }),
                  kind: 'success',
                  style: {
                    bottom: '7%',
                  },
                })
              })}>
              <FormattedMessage id="send" />
            </Button>
          </div>
        </div>
      </div>
    )

  const renderNoRecruiterTimeslotsLeft = () => (
    <div className="appointementWrapper" style={{ paddingBottom: 10 }}>
      {renderHeader(
        { id: 'event.candidate.appointment.mainContent.noSlots.with.recruitername' },
        { recruiter: appointment._organizationUser.firstName },
      )}
      {renderOfferResume()}
      {renderNoSlotResume()}
      {
        !isEmpty(appointment.messageCandidate) &&
        <Alert className="mb-15">
          <FormattedMessage id="alert.already.send.mail.to.recruiter"/>
        </Alert>
      }
      <div className={classNames('formItem', styles.contact)} style={{ marginBottom: '32px' }}>
        <div>
          <RecruiterToContact
            size={70}
            className={styles.recruiterSignature}
            _organizationUser={appointment._organizationUser}
            title={t({ id: 'talent.modal.contact.title' }, { name: appointment._organizationUser.shortName })}
          />
        </div>
        <Textarea
          value={messageCandidate}
          onChange={(e) => setMessageCandidate(e.currentTarget.value)}
          containerClassName={styles.textareaContainer}
          placeholder=""
          label=""
        />
        <div className={styles.btnSendMail}>
          <Button
            color="primary"
            disabled={!isEmpty(appointment.messageCandidate)}
            onClick={() => modifyAppointment({
              appointmentId: appointment._id,
              messageCandidate,
              status: 'pending',
              eventSlug: appointment._event.slug,
              callback: () => sendNotification({
                message: t({ id: 'candidate.talent.reply.success.title' }),
                kind: 'success',
                style: {
                  bottom: '7%',
                },
              })
            })}>
            <FormattedMessage id="send" />
          </Button>
        </div>
      </div>
    </div>
  );

  const renderNoParticipantTimeslotsLeft = () => (
    <>
      <div id="slotsContainer" className={styles.slots}>
        {renderHeader(
          { id: 'event.candidate.appointment.mainContent.noSlots.with.recruitername' },
          { recruiter: appointment._organizationUser.shortName },
        )}
        <SetTimezone />
        {renderOfferResume()}
        <p>{appointment.message}</p>
        {renderDaySelect()}
        {renderSlots()}
        {renderNoSlotResume()}
      </div>
      {renderFooter(true)}
    </>
  );

  const getTooLateCondition = () => {
    const isInteractionClosed = moment.min([moment(event.keyDates.interactionsClosing?.endAt), moment(event.keyDates.jobfair.endAt)]) < dateNow;
    const isApplicationClosed = moment.min([moment(event.keyDates.applicationsClosing?.endAt), moment(event.keyDates.interactionsClosing?.endAt), moment(event.keyDates.jobfair.endAt)]) < dateNow;

    if (interaction?.type === 'proposition') {
      return isInteractionClosed
    }

    if (interaction?.type === 'application' && interaction?._appointment?.status === 'pending') {
      return isApplicationClosed && isInteractionClosed
    }

    return isApplicationClosed
  }

  if (getTooLateCondition() && !hasAppointmentBeenModified) {
    if (interaction?.type !== 'proposition' && interaction?.type !== 'application' && location.search.includes('smartapply')) {
      history.push(`/${appointment._event.slug}/candidate/jobdating/jobs/${interaction._offer._id}`)
    }
    return renderInteractionAreClosed();
  }

  if (timeslots.length === 0) {
    if (interaction?.type !== 'proposition' && interaction?.type !== 'application' && location.search.includes('smartapply')) {
      history.push(`/${appointment._event.slug}/candidate/jobdating/jobs/${interaction._offer._id}`)
    }
    return renderNoRecruiterTimeslotsLeft();
  }

  if (!timeslotUtils.hasAvailableSlots(timeslots)) {
    return renderNoParticipantTimeslotsLeft();
  }

  return (
    <>
      <div id="slotsContainer" className={styles.slots}>
        {step === 'slots' && (
          <>
            {renderHeader(getTitle({ appointment, skipValidation }))}

            <SetTimezone />

            {renderOfferResume()}

            <p>{appointment.message}</p>

            {renderDaySelect()}

            {renderSlots()}
          </>
        )}

        {step === 'reason' && (
          <>
            {renderHeader(messages.reasonTitle, { firstName: appointment._organizationUser.firstName })}

            <div className={styles.descriptionContainer}>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Typography variant="caption1" color="error[500]">
                    <FormattedMessage id="form.helper.required" />
                  </Typography>
                </Grid>
              </Grid>
              <Textarea
                placeholder={t({ id: 'event.recruiter.preparation.timeslots.event.modifyInformalDescription.textArea' })}
                onChange={handleChangeInformalDescription}
                maxLength={300}
                value={messageInformal1to1Candidate}
                containerClassName={styles.description}
              />
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Typography variant="caption1" color="neutral[300]">
                    <FormattedMessage id="remaining.typing.pl" values={{ count: 300 - messageInformal1to1Candidate.length }} />
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </>
        )}
      </div>

      {renderFooter(false)}
    </>
  );
}

Slots.propTypes = {
  handleShowOffer: func,
  handleSelectSlot: func,
  slotSelected: any,
  appointment: any,
  timeslots: array,
  handleConfirmSelection: func,
  handleCancelSelection: func,
  modifyAppointment: func,
  sendNotification: func,
  skipValidation: bool,
  match: object,
  participant: object,
  offer: object,
  event: object,
  availableActions: object,
  history: object,
  interactions: object
}

const mapStateToProps = createStructuredSelector({
  availableActions: getAvailableActions,
  event: eventSelectors.getCurrentEvent,
  interactions: interactionSelectors.getInteractions,
});

const mapDispatchToProps = {
  modifyAppointment: appointmentActions.modifyAppointment,
  sendNotification: notificationActions.sendNotification,
}
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(withConnect(toJS(Slots)));
