import { makeStyles } from '@seekube-tech/ui';

export default makeStyles(theme => ({
  section: {
    marginTop: theme.spacing(3.5),
  },
  itemCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    color: theme.palette.grey[400],
    width: theme.spacing(2),
    height: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  input: {
    '&::placeholder': {
      opacity: '1 !important',
      color: theme.palette.grey['400'],
    },
  },
  hr: {
    margin: theme.spacing(4, 0),
    borderColor: theme.palette.neutral['200'],
  },
  sentAt: {
    background: theme.palette.success['200'],
    color: theme.palette.success['600'],
    marginRight: theme.spacing(3),
    padding: theme.spacing(0, 1),
    borderRadius: '4px',
  },
  spaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  mb16: {
    marginBottom: theme.spacing(2),
  },
  modalAction: {
    display: 'flex',
    marginTop: '20px',
  }
}));
