import React from 'react';
import { object, string } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { useGetAppointmentsMade } from '@/queries/dashboardRealtime/useGetAppointmentsMade';
import { useGetAppointmentsList } from '@/queries/dashboardRealtime/useGetAppointmentsList';
import {
  MeetingsStats
} from '@/scenes/Event/scenes/Owner/scenes/Dashboard/components/RealTimeActivity/components/MeetingStats';
import styles from './styles.less'
import MeetingsList from './MeetingsList';

const AppointmentsOfTheDay = ({ event, view }) => {
  const t = useFormatMessage();
  const { data: appointmentsList } = useGetAppointmentsList({ eventId: event._id, view });
  const statsAppointmentsQuery = useGetAppointmentsMade({ eventId: event._id, view });

  const handleExportAppointments = () => {
    const exportUrl = `${process.env.FRONT_EXPORT_API_URL}/events/${event.slug}/export/appointments?apiKey=41a1289e-fee1-430e-a849-5b2b7ed23f1a&eventFormat=${view}`;

    window.open(exportUrl, '_blank');
  };

  return (
    <div className={styles.appointmentsOfTheDayContainer}>
      <div className={styles.header}>
        <p className={styles.title}>{t({ id: `dashboard.realtime.meetingList.appointments.title`})}</p>
        <button onClick={handleExportAppointments}>
          {t({ id: `dashboard.realtime.meetingList.appointments.button.export`})}
        </button>
      </div>
      {(event.format === 'virtual' || (event.format === 'hybrid' && view === 'virtual')) &&
        <div className={event.modules.informal1to1?.enable ? styles.globalStatsVirtual : styles.globalStats}>
          <MeetingsStats countAppointmentsAcceptedToday={statsAppointmentsQuery?.data?.countAppointmentsAcceptedToday} />
          {event.modules.informal1to1?.enable &&
            <>
              <div className={styles.separator} />
              <MeetingsStats countAppointmentsAcceptedToday={statsAppointmentsQuery?.data?.countAppointmentsAcceptedToday} />
            </>
          }
        </div>
      }
      {(event.format === 'physical' || (event.format === 'hybrid' && view === 'physical'))  &&
        <div className={styles.globalStats}>
          {event.modules.informal1to1?.enable &&
            <MeetingsStats countAppointmentsAcceptedToday={statsAppointmentsQuery?.data} />}
        </div>
      }
      <MeetingsList event={event} appointments={appointmentsList} />
    </div>
  )
}

AppointmentsOfTheDay.propTypes = {
  event: object,
  view: string,
};

export default AppointmentsOfTheDay;
