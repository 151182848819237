import React from 'react';
import { number, string, bool, object, oneOf } from 'prop-types';
import { omit, isEmpty } from 'lodash';
import { getImageSize } from '@/utils/imageHandler';

/**
 * HP
 * @param {object} props
 */
const ImgCdn = (props) => {
  let {src} = props;

  if (!isEmpty(src) && (src.indexOf('cdn.seekube.com') > -1 || src.indexOf('cdn-app-assets.seekube.com') > -1) && props.size) {
    const cdnSize = getImageSize(props.size);

    src = `https://aqfjyrmoen.cloudimg.io/width/${cdnSize}/x/${props.src}`;

    if (props.operation) {
      src = `https://aqfjyrmoen.cloudimg.io/${props.operation}/${props.size}x${props.size}/n/${props.src}`;
    }
  }

  return props.withshadow ?
    <div className={props.className} style={{ height: '100%', ...background(src, props.backgroundImageCss, props.background) }} />
    : (<img className={props.className} src={src} {...omit(props, ['src'])} size={props.size} alt={props.alt} loading="lazy" />);
};

const background = (src, backgroundImage, background) => ({
  backgroundImage: `${backgroundImage || 'linear-gradient(180deg, rgba(37, 45, 71, 0.1) 58.79%, #252D47 100%), linear-gradient(222.63deg, rgba(37, 45, 71, 0.2) 12.46%, rgba(37, 45, 71, 0.04) 44.53%)'}, url(${src})`,
  ...background,
});

const getCdnUrl = (src, size) => {
  if (!isEmpty(src) && (src.indexOf('cdn.seekube.com') > -1 || src.indexOf('cdn-app-assets.seekube.com') > -1) > -1 && size) {
    const cdnSize = getImageSize(size);

    return `https://aqfjyrmoen.cloudimg.io/width/${cdnSize}/x/${src}`;
  }

  return src;
};

ImgCdn.propTypes = {
  className: string,
  size: number,
  src: string,
  alt: string,
  operation: oneOf(['crop', 'width', 'cover', 'fit']),
  withshadow: bool,
  background: object,
  backgroundImageCss: string,
};

export {
  getCdnUrl,
};

export default ImgCdn;
