import { useMutation } from 'react-query';
import request from '@/utils/request';

export const usePatchMe = ({ onSuccess, onError }) => {
  
  const mutationFn = (data) => {
    const requestUrl = `${process.env.FRONT_API_URL}/auth/me`;

    return (
      request(requestUrl, {
        method: 'PATCH',
        body: JSON.stringify(data),
      })
    )
  }

  return useMutation({
    mutationFn,
    onSuccess,
    onError
  });
  
}