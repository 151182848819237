import React from 'react';
import moment from 'moment';
import LinesEllipsis from 'react-lines-ellipsis';

import Avatar from '@/components/Avatar';
import MoreDropdown, { OverlayWithObject } from '@/components/MoreDropdown';


function Columns (t, updatedAt) {

  return (
    [
      {
        Header: t({ id: 'offers.plural' }, { count: 0 }),
        disableSortBy: true,
        id: 'title',
        accessor: ({ title, moreDropDownProperties: { preview } }) => (
            <a
              href={preview.href}
              style={{ zIndex: 3, position: 'relative' }}
              target="_blank"
              title={title}
            >
              <LinesEllipsis
                text={title}
                maxLine="3"
                ellipsis="..."
                trimRight
                basedOn="letters"
              />
            </a>
          ),
      },
      {
        Header: t({ id: 'client.offer.ownerLight' }),
        disableSortBy: true,
        id: 'user',
        accessor: ({ user }) => user ? (
          <div title={user.fullName}><Avatar size={32} user={user} src={user && user.pictureUrl} style={{ zIndex: 3 }}/>
          </div>
        ) : null,
      },
      {
        Header: t({ id: 'location' }),
        id: 'locations',
        disableSortBy: true,
        accessor: ({ locations }) => (
          <div
            style={{ zIndex: 3, position: 'relative' }}
            title={locations}
          >
            <LinesEllipsis
              text={locations}
              maxLine="3"
              ellipsis="..."
              trimRight
              basedOn="letters"
            />
          </div>
        ),
      },
      {
        Header: t({ id: 'contracts' }),
        id: 'contracts',
        disableSortBy: true,
        accessor: ({ contracts }) => (
          <LinesEllipsis
            text={contracts}
            maxLine="3"
            ellipsis="..."
            trimRight
            basedOn="letters"
          />
        ),
      },
      {
        Header: t({ id: 'jobdating.pl' }, { count: 2 }),
        disableSortBy: true,
        id: 'eventsWhereOffIsPublished',
        accessor: ({ eventsWhereOffIsPublished, moreDropDownProperties: { publishOnJobdating } }) => (
          <a title={eventsWhereOffIsPublished.join(', ')} tabIndex={0} style={{ zIndex: 3, position: 'relative' }}
             role="button" onClick={publishOnJobdating.onClick}>
            {eventsWhereOffIsPublished.length}
          </a>
        ),
      },
      {
        Header: t({ id: 'update' }),
        id: 'updatedAt',
        className: 'whitespace-nowrap',
        accessor: ({ offer }) => offer.updatedAt ? (
          <span>{moment(offer.updatedAt).format('DD-MM-YYYY')}</span>
        ) : null,
        initialSort: updatedAt
      },
      {
        Header: t({ id: 'actions' }),
        id: 'actions',
        disableSortBy: true,
        accessor: ({ moreDropDownProperties }) => moreDropDownProperties ? <MoreDropdown
          overlay={OverlayWithObject({ properties: moreDropDownProperties })}
          placement="bottomRight"
          getPopupContainer={() => document.getElementById('offersContainer')}
        /> : null,
      },
    ]
  );
};

export default Columns;
