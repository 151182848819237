import { useMutation, useQueryClient } from 'react-query';
import request from '@/utils/request';

export function usePatchEmailAdmin(
  {
    userId,
    onSuccess
  }) {
  const queryClient = useQueryClient();

  const mutationFn = (email) => {
    const requestUrl = `${process.env.FRONT_API_URL}/users/${userId}/email-admin`;

    return (
      request(requestUrl, {
        method: 'PATCH',
        body: JSON.stringify({ email }),
      })
    )
  }

  return useMutation({
    mutationFn,
    onSuccess
  });
}
