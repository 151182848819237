import React from 'react';
import { string, object, array, bool, oneOfType, node, number, func, any } from 'prop-types';
import classnames from 'classnames';

import Icon from '@/components/Icon';
import { Tooltip } from '@/components/Tooltip';

import styles from './styles.less';


const RenderStat = ({
  title = '',
  tooltip = '',
  icon = '',
  count = 0,
  additionalText = null,
  additionalTooltip = '',
  noSeparator = false,
  separatorEndChild = false,
  formatCount = (count) => (count),
  text = null,
}) => (
  <div className={styles.statItem}>
    <div className={styles.statTitle}>{title}</div>
    <div className={classnames(styles.statContent, separatorEndChild ? styles.separatorEndChild : '',  noSeparator ? styles.noSeparator : '')}>
      <span>
        <IconStat icon={icon} />
        <span className={classnames(styles.statCount, 'statCount')}>
          {text !== null ? (
            <Tooltip title={tooltip} color="dark">{text}</Tooltip>
          ) : (<Tooltip title={tooltip} color="dark">{formatCount(count)}</Tooltip>)}

          <Tooltip title={additionalTooltip} color="dark">{additionalText}</Tooltip>
        </span>
      </span>
    </div>
  </div>
);

const IconStat = ({ icon }) => icon ? <Icon className={styles.statIcon} name={icon} /> : null;
IconStat.propTypes = {
  icon: string,
};

RenderStat.propTypes = {
  title: oneOfType([string, object]),
  tooltip: any,
  icon: string,
  count: oneOfType([string, number]),
  additionalText: node,
  additionalTooltip: any,
  noSeparator: bool,
  separatorEndChild: bool,
  formatCount: func,
};

const Stats = ({ list, containerClass = '' }) => {
  const renderStats = () => list.map((item, key) => <RenderStat key={key} {...item} />);

  return (
    <div className={classnames(styles.statsContainer, containerClass)}>
      {renderStats()}
    </div>
  );
};


const StatsRaw = ({ containerClass = '', children }) => (
  <div className={classnames(styles.statsContainer, containerClass)}>
    {children}
  </div>
);

Stats.propTypes = {
  list: array,
  containerClass: string,
};

export { RenderStat };
export { Stats };
export { StatsRaw };
export { WithPercentage } from './WithPercentage'
export { WithProgress } from './WithProgress'
