import React, { useState } from 'react';
import { func, object, any } from 'prop-types';
import { isEmpty } from 'lodash';

// Components
import { Form } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Button } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { If } from '@/components/If';
import InputMaterial from '@/components/Input/InputMaterial';


const FormItem = Form.Item;


const CreateOrganization = ({
  onSubmit,
  errCompanyExist = '',
  form: { validateFields, getFieldDecorator },
}) => {
  const [hasBeenSubmit, setHasBeenSubmit] = useState(false)
  const t = useFormatMessage();

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setHasBeenSubmit(true);

    return validateFields(onSubmit);
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <FormItem label="">
          {getFieldDecorator('name', {
            initialValue: '',
            rules: [{ required: true, message: "Le nom de l'organisation est obligatoire" }],
          })(
            <InputMaterial
              addOnIcon="building"
              label={t({ id: 'organization.name.label' })}
              placeholder={t({ id: 'organization.name.label' })}
              type="text"
              validations={['required']}
            />
          )}
        </FormItem>
        <If condition={!isEmpty(errCompanyExist) && typeof errCompanyExist === 'string'}>
          {errCompanyExist}
        </If>
        {!hasBeenSubmit &&
          <Button type="submit">
            <FormattedMessage id="save" />
          </Button>
        }
      </Form>
    </div>
  );
};

CreateOrganization.propTypes = {
  onSubmit: func.isRequired,
  errCompanyExist: any,
  form: object,
};

export default Form.create()(CreateOrganization);
