import React, { useState } from 'react';
import { object } from 'prop-types';
import { Table } from 'antd';
import { Grid, TextField, makeStyles, Avatar, Typography } from '@seekube-tech/ui';
import { Button } from '@seekube-tech/ui-kit';
import { Field } from 'react-final-form';
import { useFormatMessage } from 'react-intl-hooks';
import { MinusCircle } from 'react-feather';

import Modal from '@/components/Modal';

const useStyles = makeStyles((theme) => ({
  remove: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.error[500],

    '&:hover': {
      color: theme.palette.error[500],
      textDecoration: 'underline',
    },
  },
  removeIcon: {
    stroke: theme.palette.error[500],
    color: 'transparent',
    height: 16,
    width: 16,
    marginRight: theme.spacing(1),
  },
}));

function ExponentsTable({ fields }) {
  /* Vars */

  const t = useFormatMessage();
  const [modal, setModal] = useState({ isVisible: false, params: {} });
  const styles = useStyles();

  /* Functions */

  /**
   * Open modal for confirm remove corner
   * @param {Number} index
   */
  const handleRemovePartner = (index) => () => {
    setModal({ isVisible: true, params: { index } });
  };

  /**
   * Close modal
   */
  const handleCancelModal = () => {
    setModal({ isVisible: false, params: {} });
  };

  /**
   * Delete corner and close modal
   */
  const handleConfirmModal = () => {
    fields.remove(modal.params.index);

    handleCancelModal();
  };

  /* Render */

  return (
    <>
      <Table
        dataSource={fields.value}
        pagination={false}
        columns={[
          {
            title: t({ id: 'event.modules.partner.score.table.exponent' }),
            key: 't',
            render: (row) => (
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <Avatar
                    variant="business"
                    src={row?._organization?.profile?.pictureUrl}
                  />
                </Grid>

                <Grid item>
                  <Typography variant="body2">{row?._organization?.name}</Typography>
                </Grid>
              </Grid>
            )
          },
          {
            title: t({ id: 'event.modules.partner.score.table.score' }),
            key: 'score',
            render: (_, __, index) => (
              <Field
                name={`${fields.name}[${index}].score`}
                component={TextField}
                fullWidth
                type="number"
                inputProps={{
                  step: 1,
                  min: 0,
                }}
              />
            )
          },
          {
            title: t({ id: 'event.modules.partner.score.table.action' }),
            key: '_',
            render: (_, __, index) => (
              <a
                role="button"
                onClick={handleRemovePartner(index)}
                tabIndex={0}
                className={styles.remove}
              >
                <MinusCircle className={styles.removeIcon} /> {t({ id: 'event.modules.partner.score.table.remove' })}
              </a>
            )
          },
        ]}
      />

      <Modal
        visible={modal.isVisible}
        footer={false}
        maskClosable
        width={470}
        className="customConfirm"
        onCancel={() => { }}
      >
        <h4 style={{ fontWeight: 600 }}>
          {t({ id: 'event.modules.partner.score.modal-remove.title' })}
        </h4>

        <div className="confirm-actions flex" style={{ marginTop: '24px' }}>
          <Button onClick={handleConfirmModal} className="mr-6">
            {t({ id: 'btn.confirm' })}
          </Button>
          <Button variant="tonal" onClick={handleCancelModal}>
            {t({ id: 'cancel' })}
          </Button>
        </div>
      </Modal>
    </>
  );
}

ExponentsTable.propTypes = {
  fields: object.isRequired,
};

export default ExponentsTable;
