import { useQuery } from 'react-query';

import request from '@/utils/request';
import { objectToParams } from '@/utils/url';

export function useGetExponentsUsers({ eventId, search, page, limit }) {
  const searchParams = objectToParams(search);
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/exponents/users?${searchParams}&page=${page}&limit=${limit}&offset=${(page - 1) * limit}`;

  const queryFn = () => request(requestUrl, { method: 'GET' });

  const query = useQuery({
    queryKey: ['exponentsUsers', eventId, page, searchParams],
    queryFn,
    initialData: { docs: [] },
    refetchOnWindowFocus: true,
  });

  return query;
}
