import React from 'react';
import { Button } from '@seekube-tech/ui-kit';
import { isEmpty } from 'lodash';
import { useFormatMessage } from 'react-intl-hooks';
import { func, object } from 'prop-types';
import EventAccessCta, {
  EVENT_ACCESS_CTA_COMPONENT_TYPE,
  EVENT_ACCESS_CTA_CONTEXT,
} from '@/scenes/Event/components/EventAccessCta';
import styles from '@/scenes/Events/scenes/components/JobdatingsExplore/components/JobdatingCard/styles.less';
import { getIds } from '@/utils/global';
import { USERS_TYPE } from '@/utils/constants';

export const Actions = ({ authUser, event, onActionClick }) => {
  const t = useFormatMessage();
  const orgIds = getIds(event.planners._organizations);
  const role = authUser?._currentOrganization ? USERS_TYPE.recruiter : USERS_TYPE.candidate;
  const isAdminOrOwner = authUser?.isAdmin || (role === USERS_TYPE.recruiter && isEmpty(event.stats) && (orgIds.indexOf(authUser._currentOrganization._id) > -1));

  return (
    <>
      <Button
        className={styles.actionButton}
        color='neutral'
        variant='outline'
        size='medium'
        onClick={() => onActionClick(event)}
      >
        {t({ id: 'knowMore' })}
      </Button>
      <EventAccessCta
        authUser={authUser}
        context={EVENT_ACCESS_CTA_CONTEXT.LANDING}
        ctaComponentType={EVENT_ACCESS_CTA_COMPONENT_TYPE.BUTTON}
        editMode={false}
        event={event}
        eventUserStats={event.stats}
        role={isAdminOrOwner ? USERS_TYPE.owner : role}
        linkCustomClass={styles.actionButton}
        customClass={styles.actionButtonLink}
        utmCampaign='seekube_inscription_card'
      />
    </>
  )
}

Actions.propTypes = {
  event: object,
  onActionClick: func,
  authUser: object,
}
