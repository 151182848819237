import React from 'react';
import { object, arrayOf, string, func } from 'prop-types';
import Jobfair from '@/features/agenda/recruiter/components/BigCalendar/BigCalendarEvents/Jobfair';
import Timeslot from '@/features/agenda/recruiter/components/BigCalendar/BigCalendarEvents/Timeslot/Timeslot';
import { EVENT_TYPE } from '@/features/agenda/recruiter/components/BigCalendar/const';

function BigCalendarEvents({
  event,
  currentEvent,
  jobfairsColor,
  settings,
  jobfairs,
  timezone,
  handleDeleteEvent,
  handleOnInformalDescriptionChange,
  isDeleteModeActive,
  slotSelectedForDelete,
  setSlotSelectedForDelete
}) {
  if (event.type === EVENT_TYPE.JOBFAIR) {
    return <Jobfair jobfairsColor={jobfairsColor} event={event} />;
  }

  return (
    <Timeslot
      jobfairsColor={jobfairsColor}
      jobfairs={jobfairs}
      isSettingsVisible={settings.isVisible}
      timezone={timezone}
      event={event}
      currentEvent={currentEvent}
      handleDeleteEvent={handleDeleteEvent}
      handleOnInformalDescriptionChange={handleOnInformalDescriptionChange}
      informalDescription={settings.informalDescription}
      isDeleteModeActive={isDeleteModeActive}
      setSlotSelectedForDelete={setSlotSelectedForDelete}
      slotSelectedForDelete={slotSelectedForDelete}
    />)
}

BigCalendarEvents.propTypes = {
  event: object,
  currentEvent: object,
  jobfairsColor: object,
  settings: object,
  jobfairs: arrayOf(object),
  timezone: string,
  handleDeleteEvent: func,
  isDeleteModeActive: object,
  slotSelectedForDelete: func,
  setSlotSelectedForDelete: func,
  handleOnInformalDescriptionChange: func,
};

export default BigCalendarEvents;
