import React, { useState } from 'react';
import { object, string } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { H4, Subtitle1 } from '@seekube-tech/ui-kit';
import { useHistory } from 'react-router-dom';
import {
  ShowMoreButton
} from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Exponents/scenes/Exponent/components/ShowMoreButton';
import { useInfiniteGetLandingOffers } from '@/queries/landing/useInfiniteGetLandingOffers';
import {
  CardOffers
} from '@/scenes/Event/containers/Landing/components/Contents/components/Offers/CardOffers';
import styles from '@/scenes/Event/containers/Landing/components/Contents/components/Informal1to1s/styles.less';
import SearchEngine from '@/scenes/Event/scenes/Candidate/scenes/JobDating/components/SearchEngine';
import { objectToParams, queryStringToObject } from '@/utils/url';

const DEFAULT_SEARCH = {
  page: 1,
  keywords: '',
}

const Offers = ({ event, redirectionHref }) => {
  const t = useFormatMessage();
  const history = useHistory();
  const locationSearch = queryStringToObject(history.location.search);

  const [search, setSearch] = useState({
    ...DEFAULT_SEARCH,
    ...locationSearch,
    filters: typeof locationSearch?.filters === 'string' ? [locationSearch?.filters] : locationSearch?.filters,
  });

  const offersInfiniteQuery = useInfiniteGetLandingOffers({
    eventId: event._id,
    keywords: search.keywords,
    filtersIds: search.filters,
  });

  const updateSearchAndURL = (searchChanges, matching) => {
    const newSearch = { ...search, ...searchChanges, ...matching };

    setSearch(newSearch);

    updateURLWithSearchChanges(newSearch)
  }

  const updateURLWithSearchChanges = (searchToUpdate) => {
    history.push(`${history.location.pathname}?${objectToParams(searchToUpdate)}#offers`)
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <H4>{t({ id: 'landing.offers.title' })}</H4>
        <Subtitle1 className="text-neutral-400">{t({ id: 'landing.offers.subtitle'})}</Subtitle1>
      </div>
      <SearchEngine
        event={event}
        search={search}
        participantOffersLastSearch={{ search, matchingTmp: search }}
        onSearch={updateSearchAndURL}
        hideReset
        context="landingOffer"
        facets={offersInfiniteQuery.data?.pages[0]?.facets}
      />
      <CardOffers offersInfiniteQuery={offersInfiniteQuery} event={event} redirectionHref={redirectionHref} />
      <ShowMoreButton entityInfiniteQuery={offersInfiniteQuery} />
    </div>
  )
}

Offers.propTypes = {
  event: object,
  redirectionHref: string,
}

export { Offers };
