import React from 'react';
import { object } from 'prop-types';
import { isEmpty } from 'lodash';

// Components
import { Organization2 } from '../Organization';

// Styles
import styles from './styles.less';

const Exponent = ({
  organization,
}) => {
  if (isEmpty(organization)) return null;

  return (
    <div className={styles.exponentContainer}>
      <div className={styles.organization}>
        <Organization2 organization={organization} />
      </div>
    </div>
  );
};

Exponent.propTypes = {
  organization: object,
};

export default Exponent;
