import React, { useState } from 'react';
import { func, object } from 'prop-types';
import { Form, Input } from 'antd';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import styles from '@/scenes/Event/scenes/Owner/scenes/Dashboard/components/AddActivity/styles.less';
import WysiwygEditor from '@/components/WysiwygEditor';
import iconBold from '@/assets/images/IconBold.svg';
import iconItalic from '@/assets/images/IconItalic.svg';
import iconUnderline from '@/assets/images/IconUnderline.svg';
import iconStrikethrough from '@/assets/images/IconStrikethrough.svg';
import iconList from '@/assets/images/IconList.svg';
import iconListNumbers from '@/assets/images/IconListNumber.svg';
import iconAlignLeft from '@/assets/images/iconAlignLeft.svg';
import iconAlignCenter from '@/assets/images/iconAlignCenter.svg';
import iconAlignRight from '@/assets/images/iconAlignRight.svg';
import iconAlignJustify from '@/assets/images/iconAlignJustify.svg';
import iconLink from '@/assets/images/IconLink.svg';
import iconVideo from '@/assets/images/IconVideo.svg';
import iconImage from '@/assets/images/IconImage.svg';
import S3 from '@/services/S3';
const FormItem = Form.Item;

export const Content = ({ form, activityToUpdate, event }) => {
  const [description, setDescription] = useState(activityToUpdate.content);

  const onChangeDescription = (value) => {
    if (typeof value === 'string') {
      setDescription(value);
      form.setFieldsValue({ content: value });
    }
  }

  const getToolbarCustomButtons = () => {
    const toolbarCustomButtons = ['stands'];
    if (event.modules.offer.enable) {
      toolbarCustomButtons.push('offers')
    }
    if (event.modules.informal1to1.enable) {
      toolbarCustomButtons.push('informal')
    }
    if (event.modules.live.participantEnable) {
      toolbarCustomButtons.push('lives')
    }
    return toolbarCustomButtons;
  }

  const addEmbeddedLink = (embeddedLink) => {

    if (embeddedLink.indexOf("youtu") >= 0) {
      embeddedLink = embeddedLink.replace("youtu.be/", "youtube.com/embed/");
      embeddedLink = embeddedLink.replace("watch?v=", "embed/");
      embeddedLink = embeddedLink.replace("/watch/", "/embed/");
      embeddedLink = embeddedLink.split('&')[0];
    }
    return embeddedLink;
  }

  const uploadCallback = async (file) => {
    const url = await S3.getS3Url(file, 'event', event._id);

    return new Promise(
      (resolve, reject) => {
        resolve({ data: { link: url } });
      }
    );
  }

  return (
    <FormItem required>
      <WysiwygEditor
        initialValue={description}
        required
        value={description}
        onChange={onChangeDescription}
        localization={{
          locale: 'fr',
        }}
        toolbar={{
          options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'],
          inline: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['bold', 'italic', 'underline', 'strikethrough'],
            bold: { icon: iconBold, className: styles.toolbarOption },
            italic: { icon: iconItalic, className: styles.toolbarOption },
            underline: { icon: iconUnderline, className: styles.toolbarOption },
            strikethrough: { icon: iconStrikethrough, className: styles.toolbarOption },
          },
          blockType: {
            inDropdown: true,
            options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
            className: styles.toolbarOption,
            component: undefined,
            dropdownClassName: undefined,
          },
          fontSize: {
            className: styles.toolbarOptionHidden,
          },
          fontFamily: {
            className: styles.toolbarOptionHidden,
          },
          list: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['unordered', 'ordered', 'indent', 'outdent'],
            unordered: { icon: iconList, className: styles.toolbarOption },
            ordered: { icon: iconListNumbers, className: styles.toolbarOption },
            indent: { className: styles.toolbarOptionHidden },
            outdent: { className: styles.toolbarOptionHidden },
          },
          textAlign: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['left', 'center', 'right', 'justify'],
            left: { icon: iconAlignLeft, className: styles.toolbarOption },
            center: { icon: iconAlignCenter, className: styles.toolbarOption },
            right: { icon: iconAlignRight, className: styles.toolbarOption },
            justify: { icon: iconAlignJustify, className: styles.toolbarOption },
          },
          colorPicker: {
            className: styles.toolbarOptionHidden,
            component: undefined,
            popupClassName: undefined,
            colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
              'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
              'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
              'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
              'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
              'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
          },
          link: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            popupClassName: undefined,
            dropdownClassName: undefined,
            showOpenOptionOnHover: true,
            defaultTargetOption: '_self',
            options: ['link', 'unlink'],
            link: { icon: iconLink, className: styles.toolbarOption },
            unlink: { className: styles.toolbarOptionHidden },
            linkCallback: undefined
          },
          embedded: {
            icon: iconVideo,
            className: styles.toolbarOption,
            component: undefined,
            popupClassName: styles.popupVideo,
            embedCallback: addEmbeddedLink,
            defaultSize: {
              height: 'auto',
              width: 'auto',
            },
          },
          image: {
            icon: iconImage,
            className: styles.toolbarOption,
            component: undefined,
            popupClassName: styles.popupImage,
            urlEnabled: false,
            uploadEnabled: true,
            alignmentEnabled: true,
            uploadCallback,
            previewImage: true,
            inputAccept: 'image/jpeg,image/jpg,image/png,image/svg',
            alt: { present: false, mandatory: false },
            defaultSize: {
              height: 'auto',
              width: 'auto',
            },
          },
          emoji: {
            className: styles.toolbarOptionHidden,
          },
          remove: {
            className: styles.toolbarOptionHidden,
          },
          history: {
            className: styles.toolbarOptionHidden,
          },
        }}
        toolbarCustomButtons={getToolbarCustomButtons()}
      />
      {form.getFieldDecorator('content', {
        initialValue: isEmpty(description) ? '' : description,
        value: isEmpty(description) ? '' : description,
      })(<Input type='hidden'/>)}
    </FormItem>
  )
}

Content.propTypes = {
  form: object,
  activityToUpdate: func,
  event: object,
}
