import React, { Fragment } from 'react';
import { object, array, func } from 'prop-types';
import { Body2 } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { isEmpty } from 'lodash';
import {
  Media
} from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/Profile/Cv/ExternalMedia/Media';

export function MediaList ({ form, user, onClick, medias, handleRemove }) {
  const t = useFormatMessage();

  if (isEmpty(medias)) return null;

  return (
    <>
      <Body2 className="mb-24 text-neutral-400">
        {t({ id: 'candidate.externalMedia.upload.document.limit' })}
      </Body2>

      {medias?.map((media, order) => (
        <Fragment key={order}>
          <Media
            media={media}
            order={order}
            form={form}
            user={user}
            onClick={onClick}
            handleRemove={handleRemove}
          />
        </Fragment>
      ))}
    </>
  )
}

MediaList.propTypes = {
  form: object,
  user: object,
  onClick: func,
  medias: array,
  handleRemove: func,
}
