import { defineMessages } from 'react-intl';

export default defineMessages({
  menuBasic: {
    id: 'event.menu.basic',
  },
  menuParticipants: {
    id: 'event.menu.participants',
  },
  menuGoals: {
    id: 'event.menu.goals',
  },
  menuParticipantsOnboarding: {
    id: 'event.menu.participants.onboarding',
  },
  menuExponents: {
    id: 'event.menu.exponent',
  },
  menuPartners: {
    id: 'event.menu.partners',
  },
  menuLimits: {
    id: 'event.menu.limits',
  },
  menuLimitsDefault: {
    id: 'event.owner.setting.limits.default.title',
  },
  menuLimitsByOrga: {
    id: 'event.menu.limits.byOrga',
  },
  menuModules: {
    id: 'event.menu.modules',
  },
  menuDiffusion: {
    id: 'event.menu.diffusion',
  },
  menuMatching: {
    id: 'event.menu.matching',
  },
  menuNotifications: {
    id: 'event.menu.notifications',
  },
  menuValidation: {
    id: 'event.menu.validation',
  },
  menuLanding: {
    id: 'event.menu.landing',
  },
  matchingDashboard: {
    id: 'event.matching.dashboard',
  },
  matchingOnboarding: {
    id: 'event.matching.onboarding',
  },
  matchingCandidateProfile: {
    id: 'event.matching.candidateProfile',
  },
  matchingSearchEngineOffer: {
    id: 'event.matching.searchEngineOffer',
  },
  matchingSearchEngineCandidate: {
    id: 'event.matching.searchEngineCandidate',
  },
  matchingOffer: {
    id: 'event.matching.offer',
  },
  tabs: {
    id: 'event.modules.tabs',
  },
  feedback: {
    id: 'event.modules.feedback',
  },
  eventDeletionCta: {
    id: 'event.deletion.cta',
  },
  eventDeletionModalConfirm: {
    id: 'event.deletion.modal.confirm',
  },
  confirm: {
    id: 'btn.confirm',
  },
  livetalk: {
    id: 'livetalk',
  },
  cancel: {
    id: 'cancel',
  },
  eventDeletionNotificationSuccess: {
    id: 'event.deletion.notification.success',
  },
});
