import React from 'react';
import { object, node, func } from 'prop-types';
import { isEmpty, split } from 'lodash';
import moment from 'moment';
import classnames from 'classnames';
import { getLocale } from '@/utils/localStorage';
import { LOCALES } from '@/utils/constants';

import { useFormatMessage } from 'react-intl-hooks';

// Components
import { Tag } from 'antd';
import { If, Then } from 'react-if';
import Card from '@/components/Card';
import Separator from '@/components/Separator';
import Icon from '@/components/Icon';
import Contact from '../OfferCardListParticipant/components/Contact';

// Styles
import styles from './styles.less';
import stylesOffer from '../styles.less';


const OfferCardList = ({ offer, event, handleAction, moreActions }) => {
  const t = useFormatMessage();

  const locale = getLocale();
  const contracts = [];
  const durations = [];
  const otherChoices = [];
  const status = offer.status || 'draft';

  const contractCriteria = event._criteria.filter((item) => item.key === 'CONTRACT')[0];
  const durationCriteria = event._criteria.filter((item) => item.key === 'DURATION')[0];
  const otherFilters = event._criteria.filter((item) => !item.key);

  if (contractCriteria) {
    contractCriteria._choices.forEach((item) => {
      if (item.enable && offer.matching.filters.includes(item._id.toString())) {
        if (locale === LOCALES.en && !isEmpty(item.label_en)) {
          contracts.push(item.label_en);
        } else if (locale === LOCALES.fr) {
          contracts.push(item.label);
        }
      }
    });
  }
  if (durationCriteria) {
    durationCriteria._choices.forEach((item) => {
      if (item.enable && offer.matching.filters.includes(item._id.toString())) {
        if (locale === LOCALES.en && !isEmpty(item.label_en)) {
          durations.push(item.label_en);
        } else if (locale === LOCALES.fr) {
          durations.push(item.label);
        }
      }
    });
  }

  otherFilters.forEach((criterion) => {
    const choices = [];

    criterion._choices.forEach((item) => {
      if (item.enable && offer.matching.filters.includes(item._id.toString())) {
        if (locale === LOCALES.en && !isEmpty(item.label_en)) {
          choices.push(item.label_en);
        } else if (locale === LOCALES.fr) {
          choices.push(item.label);
        }
      }
    });

    if (choices.length > 0) {
      otherChoices.push(
        (<div className={styles.criteria}>
          <Icon name={criterion.icon} className={styles.icon} /> {choices.join(' - ')}
        </div>)
      );
    }
  });

  const date = offer.matching.date === 1 || offer.matching.date === null ? t({ id: 'offer.available.today' }) : moment.unix(offer.matching.date).format('MMMM YYYY');

  return (
    <Card noBorder containerClassName={classnames(styles.card, ['draft', 'expired'].includes(status) ? status : '')}>
      <div id={offer._id} className={styles.content}>
        <div className={stylesOffer.mainContent}>
          <div className={styles.offerData}>
            <div>
              <a onClick={() => handleAction(offer, 'edit')} role="button" tabIndex={0}>{offer.title}</a>
              {
                ['draft', 'expired'].includes(status) ?
                  <Tag className={styles.tag}>
                    {t({ id: 'event.status.draft' })}
                  </Tag>
                  : ''
              }
            </div>
            <Separator height={20} />

            <div className={styles.footer}>
              <div className={styles.criterion}>
                {contracts.length > 0 ? (
                  <div className={styles.criteria}>
                    <Icon name={contractCriteria.icon} className={styles.icon} /> {contracts.join(' - ')}
                  </div>
                ) : ''}

                {offer.matching.date ? (
                  <div className={styles.criteria}>
                    <Icon name="calendar" className={styles.icon} /> {date}
                  </div>
                ) : ''}

                {durations.length > 0 ? (
                  <div className={styles.criteria}>
                    <Icon name="hourglass" className={styles.icon} /> {durations.join(' - ')}
                  </div>
                ) : ''}

                <If condition={!isEmpty(offer.locations)}>
                  <Then>
                    <div className={styles.criteria}>
                      <Icon name="location" className={styles.icon} />
                      {displayCities(offer.locations)}
                    </div>
                  </Then>
                </If>

                {otherChoices.map((el, index) => <React.Fragment key={index}>{el}</React.Fragment>)}
              </div>
            </div>
          </div>
        </div>

        <Contact
          offer={offer}
          event={event}
          participant={null}
          context="recruiter"
          handleOpenOffer={handleAction}
        />

        <div className={styles.more}>{moreActions}</div>
      </div>
    </Card>
  );
};

const displayCities = (locations) =>
  locations ? locations.map((item) => item.city || split(item.name, ',', 1)).join(', ') : '';

OfferCardList.propTypes = {
  event: object,
  offer: object,
  handleAction: func,
  moreActions: node,
};

export default OfferCardList;
