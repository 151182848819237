import React, { useState } from 'react';
import { func, bool } from 'prop-types';
import { Button, H3 } from '@seekube-tech/ui-kit';
import { FormattedMessage } from 'react-intl';
import { useFormatMessage } from 'react-intl-hooks';
import ModalV2 from '@/components/ModalV2';

const RemoveDealBtn = ({ onRemove, isLoading }) => {
  const t = useFormatMessage();
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Button
        variant="outline"
        color="error"
        onClick={() => setVisible(true)}
        className="w-full mr-14"
      >
        <FormattedMessage id="form.deal.btn.remove" />
      </Button>

      <ModalV2
        template="default"
        visible={visible}
        onCancel={() => setVisible(false)}
        onClose={() => setVisible(false)}
        >
        <H3>{t({id: 'form.deal.remove.confirm'})}</H3>
          <div className="confirm-actions mt-20">
            <Button
              variant="outline"
              color="neutral"
              onClick={() => setVisible(false)}
            >
              {t({ id: 'cancel' })}
            </Button>
            <Button
              loading={isLoading}
              disabled={isLoading}
              onClick={onRemove}
            >
              {t({ id: 'btn.confirm' })}
            </Button>
          </div>
      </ModalV2>
    </>
  )
}

RemoveDealBtn.propTypes = {
  onRemove: func,
  isLoading: bool,
}

export { RemoveDealBtn }
