import React, { useState } from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { Body1, Button, Caption2, H4, ItemCard } from '@seekube-tech/ui-kit';
import { Modal } from 'antd';
import styles from './styles.less';

const ModalEditStand = ({eventName, role, organization, handleClose, handleSubmit, values, valid, status}) => {

  const t = useFormatMessage();

  const [saveAsContextual, setSaveAsContextual] = useState()

  const standMasterDisabled = organization.isClient && role!== 'admin';

  return (
    <Modal
      visible
      onCancel={handleClose}
      closable={false}
      footer={false}
      maskClosable
      width={720}
      className={styles.modalEditStand}
    >
      <H4>{t({id: 'event.recruiter.preparation.stand.toaster.btn.save'})}</H4>
      <Body1 className={styles.editStandDescription}>{t({id: 'save.stand.description'})}</Body1>
      <ItemCard
        selectable
        disabled={standMasterDisabled} 
        onSelect={() => !standMasterDisabled && setSaveAsContextual(false)} 
        selected={saveAsContextual === false} 
        description={t({id: 'stand.apply.all.description'})}
      >
        {t({id: 'apply.to.all.event'})}
      </ItemCard>
      {standMasterDisabled && <Caption2 className={styles.msgDisabled}> {t({id: 'stand.save.disabled.message'})}</Caption2> }
      <ItemCard
        selectable
        className={styles.editStandRadioCard} 
        onSelect={() => setSaveAsContextual(true)} 
        selected={saveAsContextual} 
        description={t({id: 'stand.event.only.description'}, {event: eventName})}
      >
        {t({id: 'stand.only.this.event'})}
      </ItemCard>
      <div className={styles.btnContainer}>
        <Button onClick={handleClose} variant='outline' color='neutral'>{t({id: 'cancel'})}</Button>
        <Button onClick={() => handleSubmit({saveAsContextual, isPublish: valid })(values)} disabled={saveAsContextual === undefined} className={styles.submitStand}>{t({id: 'submit'})}</Button>
      </div>
    </Modal>
  );
}

ModalEditStand.propTypes = {
};

export default ModalEditStand;
