import React, { memo } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { bool, object } from 'prop-types';

import Wrapper from '@/components/Wrapper';
import {
  DateClosingCardInfos
} from '@/scenes/Event/scenes/Candidate/scenes/JobDating/containers/SceneAlerts/DateClosingCardInfos';

import styles from './styles.less';

/**
 @description: Display alert in jobdating scene
 */
const GlobalAlert = ({ event, match, authUser }) => (
  <Wrapper size="large" className={styles.wrapperMobile}>
    <DateClosingCardInfos authUser={authUser} event={event} match={match} />
  </Wrapper>
);

GlobalAlert.propTypes = {
  event: object.isRequired,
  match: object.isRequired,
  authUser: object.isRequired,
  signUpSuccess: bool
};


const SceneAlerts = compose(withRouter, memo)(GlobalAlert);

export { SceneAlerts };
