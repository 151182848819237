import { isEmpty } from 'lodash';
import { trackGtmEvent } from '@/utils/pixels/helloWork';
import { getIsNavbarBarTabsVisible, getUserType } from '@/scenes/Event/containers/Landing/helpers';

// https://hellowork.atlassian.net/wiki/spaces/Logiciels/pages/3201826918/Tracking+LP+Funnel+d+inscription


function getFlowModulesList (event) {
  return [...event.onboardingSteps, 'matching']?.join('|')?.toString()
}

function trackPageDisplayLanding(authUser, event, participant, authUserEventsNb) {
  const getPageType = () => {
    const hashNavBar = window.location.hash.replace('#', '');

    return isEmpty(hashNavBar) ? 'generalInfos' : hashNavBar;
  }

  trackGtmEvent('virtual.page.display', {
    'page_category': 'landing_page',
    'page_subject': 'jobdating',
    'flow_name' : 'inscription_jobdating',
    'user_id_back' : authUser?._id,
    'jobdating_name': event.slug,
    'jobdating_type': event.type,
    'user_category': getUserType(authUser, event),
    'user_known': authUserEventsNb > 0,
    'page_type': getPageType(),
    'user_jobdating_signup': !isEmpty(participant),
    'is_navbar_visible': !getIsNavbarBarTabsVisible(event),
  });
}

function trackRegisterFromLanding(authUser, event) {
  const hasSlug = window.location.href.indexOf(event?.slug) > -1;

  trackGtmEvent('registration', {
    'page_category': hasSlug ? 'landing_page' : 'profile',
    'page_subject': hasSlug > -1 ? 'inscription' : 'home_page',
    'event_info': 'CTA',
    'user_id_back' : authUser?._id,
    'jobdating_name': event?.slug,
  });
}

function trackPageDisplayLoginSignup(event) {
  if (window.location.pathname.includes('candidate/preparation/onboarding/signin')
    || window.location.pathname.includes('candidate/preparation/onboarding/signup')) {
    trackGtmEvent('virtual.page.display', {
      'page_category': 'onboarding',
      'page_subject': 'login_signup',
      'jobdating_name': event?.slug,
      'flow_modules_list': getFlowModulesList(event),
    });
  }
}

function trackLogin(authUser, event) {
  trackGtmEvent('login', {
    'jobdating_name': event?.slug,
    'user_id_back' : authUser?._id,
    'flow_modules_list': getFlowModulesList(event),

  });
}

function trackSignup(event) {
  trackGtmEvent('signup', {
    'jobdating_name': event.slug,
    'flow_modules_list': getFlowModulesList(event),
  });
}

function trackOnboardingStart () {
  trackGtmEvent('onboarding_start');
}

function trackPageDisplayProfile(authUser, event) {
  trackGtmEvent('virtual.page.display', {
    'page_category': 'onboarding',
    'page_subject': 'profil',
    'flow_modules_list': getFlowModulesList(event),
    'flow_name' : 'inscription_jobdating',
    'user_id_back' : authUser._id,
    'jobdating_name': event.slug,
  });
}

function trackOnboardingPresentationComplete() {
  trackGtmEvent('onboarding_presentation_complete');
}

function trackOnboardingCvComplete() {
  trackGtmEvent('onboarding_cv_complete',);
}

function trackOnboardingExperienceComplete() {
  trackGtmEvent('onboarding_experience_complete');
}

function trackOnboardingMarketingSearchComplete() {
  trackGtmEvent('onboarding_marketing_search_complete');
}

function trackOnboardingContinue() {
  trackGtmEvent('onboarding_continue');
}

function trackPageDisplayMatching(authUser, event) {
  trackGtmEvent('virtual.page.display', {
    'page_category': 'onboarding',
    'page_subject': 'matching',
    'flow_modules_list': getFlowModulesList(event),
    'user_id_back' : authUser._id,
    'jobdating_name': event.slug,
  });
}

function trackOnboardingMatchingComplete() {
  trackGtmEvent('onboarding_matching_complete');
}

function trackOnboardingEnd() {
  trackGtmEvent('onboarding_end');
}

export {
  trackPageDisplayLanding,
  trackRegisterFromLanding,
  trackPageDisplayLoginSignup,
  trackSignup,
  trackLogin,
  trackPageDisplayProfile,
  trackOnboardingStart,
  trackOnboardingCvComplete,
  trackOnboardingMarketingSearchComplete,
  trackOnboardingContinue,
  trackOnboardingPresentationComplete,
  trackOnboardingExperienceComplete,
  trackPageDisplayMatching,
  trackOnboardingMatchingComplete,
  trackOnboardingEnd,
}
