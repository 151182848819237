import { isEmpty, size } from 'lodash';

export default (dataSource, exponent, { actions }) =>
  dataSource
    .filter((exponentUser) => (exponentUser && !isEmpty(exponentUser._user)) || !isEmpty(exponentUser))
    .map((exponentUser) => ({
      key: exponent._id,
      collaboratorsLength: size(exponent.users),
      exponent,
      exponentUser,
      _organization: exponent._organization,
      eventId: exponent._event._id,
      actions: (type) => actions(type, exponentUser, exponent),
    }));
