import moment from 'moment';
import { isEmpty } from 'lodash';
import { MEETING_STATUS } from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Appointments/const';

export function getMeetingStatus({ interaction, event, timezone }) {
  const { skipAppointmentValidation } = event;
  let type;
  const now = moment().tz(timezone.utc[0]);

  if (
    ((interaction.type === 'proposition' || interaction.type === 'informal1to1') && !interaction._appointment._timeslot)
    || (interaction.type === 'application' && !interaction.pending && !isEmpty(interaction._appointment)
      && !interaction._appointment._timeslot && interaction._appointment.status !== 'canceled')) {

    type = MEETING_STATUS.candidateReceiveProposition;

    if (interaction.type === 'informal1to1' && !skipAppointmentValidation && !interaction._appointment.acceptedAt) {
      type = MEETING_STATUS.pending;
    }

  } else if (
    (interaction.type === 'proposition' && interaction._appointment._timeslot) ||
    (interaction.type === 'informal1to1' && interaction._appointment._timeslot) ||
    (
      interaction.type === 'application' &&
      !isEmpty(interaction._appointment) &&
      interaction._appointment._timeslot &&
      interaction._appointment.status !== 'canceled'
    )
  ) {
    if (interaction._appointment.date && moment(interaction._appointment.date).tz(timezone.utc[0]).isBefore(now)) {
      type = MEETING_STATUS.candidateMetRecruiter;
    } else {
      type = MEETING_STATUS.candidateHasAMeeting;
    }
  } else if (interaction.type === 'application' && !interaction.pending && isEmpty(interaction._appointment)) {
    type = MEETING_STATUS.candidateWasRefused;
  } else if ((interaction.type === 'application') && interaction.pending) {
    type = MEETING_STATUS.pending;
  }
  return type
}
