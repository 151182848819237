import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { appSchema } from './schema';

const getAppReducer = (state) => state.get('app');
const getEntities = (state) => state.get('entities');

/**
 * Return current app version
 */
export const getCurrentApp = createSelector(
  getAppReducer,
  getEntities,
  (appState, entities) => {
    const appId = appState.get('currentId');
    const app = entities.getIn(['app', appId]);

    return denormalize(app, appSchema, entities);
  }
);

/**
 * Get event pagination
 */
export const getCountAppointments = createSelector(
  getAppReducer,
  (appState) => appState.get('countAppointments'),
);

/**
 * Get event pagination
 */
export const getFetchError = createSelector(
  getAppReducer,
  (appState) => appState.get('fetchError'),
);

