import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { useFormatMessage } from 'react-intl-hooks';
import styles from './styles.less';


const Separator = ({
  contentIndex,
  onInit,
}) => {
  const t = useFormatMessage();

  useEffect(() => {
    if (onInit) {
      onInit(contentIndex)({
        inNav: false,
        label: t({ id: 'event.content.separator.label' }),
      });
    }
  }, []);

  
  return (
    <Row>
      <Col span={8} offset={2} className={styles.seperatorContainer} />
    </Row>
  );
}

Separator.propTypes = {
  event: PropTypes.object, // eslint-disable-line
  contentIndex: PropTypes.number,
  onInit: PropTypes.func,
};

export default Separator;

