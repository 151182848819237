import React from 'react';
import { object, string } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { Button, IlluRocket } from '@seekube-tech/ui-kit';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { EVENT_STATUS } from '@/utils/constants';

// Styles
import styles from './styles.less';


const DiscoverInformal1to1 = ({ event, role }) => {
  const t = useFormatMessage();
  const dispatch = useDispatch();

  const passwordParam = event.visibility === 'private' && event.dateStatus === EVENT_STATUS.PREREGISTRATION ?
    `?pwd=${event.participants?.filters?.password}` : '';

  const defaultRedirection = `/${event.slug}${passwordParam}/${role === "participant" ? "candidate/jobdating/" : 'owner/'}informal1to1`;


  return (
    <div className={styles.discoverInformal1to1Container}>
      <div>
        <IlluRocket size={100} />
      </div>
      <div className={styles.text}>
        <h5>{t({ id: 'component.discoverInformal1to1.title'})}</h5>
        <p>{t({ id: 'component.discoverInformal1to1.description'})}</p>
      </div>
      <Button onClick={() => dispatch(push(defaultRedirection))} >
        {t({ id: 'component.discoverInformal1to1.button'})}
      </Button>
    </div>
  )
}

DiscoverInformal1to1.propTypes = {
  event: object,
  role: string,
};

export default DiscoverInformal1to1;