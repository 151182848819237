import React, { useState } from 'react';
import { compose } from 'redux';
import { toJS } from '@/utils';

// Components
import LimitsbyDefault
  from '@/scenes/Event/scenes/Owner/scenes/Setting/scenes/Limits/scenes/Organizations/LimitsbyDefault';
import Form from './Form';

import styles from '../../../../styles.less';

const OrganizationsScene = (props) => {

  const [refetch, setRefetch] = useState(false)
  return (
    <div style={{ width: '100%', flex: 1, paddingTop: '20px' }}>
      <LimitsbyDefault {...props} setRefetch={setRefetch} />
      <div className={styles.formWrapper}>
        <Form
          {...props}
          refetch={refetch}
          setRefetch={setRefetch}
        />
      </div>
    </div>
  )
};

export default compose(
  toJS,
)(OrganizationsScene);
