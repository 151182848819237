import React from 'react';
import { object, func } from 'prop-types';
import moment from 'moment';
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

// Store
import { DatePicker } from 'antd';
import { Button } from '@seekube-tech/ui-kit';
import { appActions, appSelectors } from '@/store/app';

// Containers
import { withLayout } from '@/containers/Layout';
import { withAuth } from '@/containers/RequiredAuth';

// Components
import { toJS } from '@/utils';
import { H1 } from '@/components/Title';

// Styles & Translations
import styles from './styles.less';
import messages from './messages';

/**
 * CalendarScene
 */
class CalendarScene extends React.PureComponent {
  static propTypes = {
    app: object,
    patchApp: func,
    intl: object
  };

  static getDerivedStateFromProps({ app }, state) {
    if (app) {
      const calendarDate = { beginAt: moment(app.calendarBeginAt), endAt: moment(app.calendarEndAt) };

      if (JSON.stringify(state.calendarDate) !== JSON.stringify(calendarDate)) {
        return { calendarDate };
      }
    }

    return null;
  }

  state = {
    calendarDate: { beginAt: null, endAt: null },
  };

  handleOnSubmit = () => {
    const { props: { patchApp }, state: { calendarDate: { beginAt, endAt } } } = this;
    patchApp({
      body: {
        calendarBeginAt: moment(beginAt.toDate()).tz('Europe/Paris').utcOffset(moment(beginAt.toDate())
          .tz('Europe/Paris').utcOffset()).set({ hour: 4, minute: 1, second: 0 }),
        calendarEndAt: moment(endAt.toDate()).tz('Europe/Paris')
          .utcOffset(moment(endAt.toDate()).tz('Europe/Paris')
            .utcOffset()).set({ hour: 23, minute: 59, second: 0 }),
      },
      notificationParams: {
        success: {
          message: this.props.intl.formatMessage({ id: 'notifications.update.success' }),
          kind: 'info',
          style: {
            bottom: '5%',
            top: 'inherit',
          },
        },
        error: true,
      },
    });
  };

  render() {
    const { state: { calendarDate: { beginAt, endAt }, calendarDate }, props: { app } } = this;

    if (!app) {
      return null;
    }

    return (
      <div className={styles.calendarContainer}>
        <H1><FormattedMessage {...messages.calendarB2b} /></H1>
        <div className={styles.calendarPickerContainer}>
          <span><FormattedMessage {...messages.from} /></span>
          <DatePicker
            className={styles.calendarPicker}
            defaultValue={moment(beginAt) || moment()}
            onChange={(value) => {
              calendarDate.beginAt = moment(value);
              this.setState({ calendarDate });
            }}
          />
          <span><FormattedMessage {...messages.to} /></span>
          <DatePicker
            className={styles.calendarPicker}
            defaultValue={moment(endAt) || moment()}
            onChange={(value) => {
              calendarDate.endAt = moment(value);
              this.setState({ calendarDate });
            }}
          />
        </div>
        <div className={styles.submit}>
          <Button className="mr-6" onClick={this.handleOnSubmit} disabled={!beginAt || !endAt}>
            <FormattedMessage {...messages.save} />
          </Button>
          <Button variant="tonal" onClick={() =>  window.open('https://www.seekube.com/recruiters/events', '_blank')}>
            <FormattedMessage {...messages.showResult} />
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  app: appSelectors.getCurrentApp,
});

const mapDispatchToProps = {
  patchApp: appActions.patchApp,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withLayout({ size: 'large', withHeader: true, withNav: false }),
  withAuth({ isAdmin: false }),
  withConnect,
  injectIntl,
)(toJS(CalendarScene));
