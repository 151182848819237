import React from 'react';
import { FormattedMessage } from 'react-intl';
import { func, bool } from 'prop-types';
import { Modal } from 'antd';
import { useFormatMessage } from "react-intl-hooks";
import Icon from '@/components/Icon';
import Button from "@/components/Button";
import styles from './styles.less';
import messages from './messages.js';

const FailedToFetchModal = ({ visible, handleClose }) => {
  const t = useFormatMessage();
  
  return <Modal
    visible={visible}
    keyboard={false}
    onCancel={() => handleClose(null)}
    footer={false}
    maskClosable
    closable={false}
    width={654}
    className="failedTofetch"
  >
    <a role="button" tabIndex="0" className={styles.modalClose} onClick={() => handleClose()}><Icon name="close-modal" /></a>
    <div className={styles.modalTitle}><FormattedMessage {...messages.modalTitle} /></div>
    <p className={styles.modalText}> <FormattedMessage {...messages.modalDescriptionText} /> </p>
    <div className={styles.modalSolutionQuestion}><FormattedMessage {...messages.modalSolutionQuestion} /></div>
    <p className={styles.modalText}> <FormattedMessage {...messages.modalSolutionText} /></p>
    <span className="ant-typo"><code>{process.env.FRONT_API_URL}</code></span>
    <span className="ant-typo"><code>*.seeklive.io</code></span>
    <span className="ant-typo"><code>*.tokbox.com</code></span>
    <span className="ant-typo"><code>*.algolia.com</code></span>
    <Button className={styles.btnRedirect} size="large" type="primary" onClick={() => handleClose(true)}>{t({id: 'modal.fetch.failed.cta'})}</Button>
  </Modal>
};

FailedToFetchModal.propTypes = {
  visible: bool,
  handleClose: func,
};

export default FailedToFetchModal;
