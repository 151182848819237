import React from 'react';
import { bool, func } from 'prop-types';
import { Button, ModalConfirmation } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { useSelector } from 'react-redux';
import { trackRecruiterQuitCreatingSlots } from '@/utils/analytics';
import { getAuthUser } from '@/store/auth/selectors';

export const ModalQuitAgendaWithoutSaving = ({ visible, onCancel, onConfirm }) => {
  const t = useFormatMessage();
  const authUser = useSelector(getAuthUser)?.toJS();

  const handleOnConfirm = () => {
    trackRecruiterQuitCreatingSlots({ authUser });
    onConfirm();
  }

  return (
    <ModalConfirmation
      visible={visible}
      title={t({ id: 'event.recruiter.preparation.offer.published.form.update' })}
      actions={
        <div className="confirm-actions">
          <Button
            className="mr-6"
            variant="tonal"
            color="neutral"
            onClick={onCancel}
          >
            {t({ id: 'cancel' })}
          </Button>
          <Button
            onClick={handleOnConfirm}
            color="error"
          >
            {t({ id: 'btn.confirm' })}
          </Button>
        </div>
      }
    />
  )
}

ModalQuitAgendaWithoutSaving.propTypes = {
  visible: bool,
  onCancel: func,
  onConfirm: func,
}
