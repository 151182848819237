import { defineMessages } from 'react-intl';

export default defineMessages({
  recruiterTitle: {
    id: 'header.userDropdown.recruiterTitle',
  },
  profile: {
    id: 'header.userDropdown.profile',
  },
  changeEvent: {
    id: 'header.userDropdown.changeEvent',
  },
  events: {
    id: 'header.userDropdown.events',
  },
  settings: {
    id: 'header.userDropdown.settings',
  },
  logout: {
    id: 'header.userDropdown.logout',
  },
  help: {
    id: 'header.userDropdown.help',
  },
  team: {
    id: 'recruiter.preparation.team',
  },
  offers: {
    id: 'recruiter.preparation.offers',
  },
  timeslots: {
    id: 'recruiter.preparation.timeslots',
  },
  stand: {
    id: 'recruiter.preparation.stand',
  },
  current: {
    id: 'current',
  },
  switchOrganizationSuccess: {
    id: 'recruiter.switchOrganizationSuccess',
  },
});
