import { FormattedHTMLMessage } from 'react-intl';
import { Form, Radio } from 'antd';
import React from 'react';
import { func, string } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { Body1 } from '@seekube-tech/ui-kit';

const FormItem = Form.Item;
const RadioGroup = Radio.Group;

export const NotifyAgendaSeekube = ({ getFieldDecorator, calendarSync }) => {
  const t = useFormatMessage();

  return (
    <>
      <Body1 className="mb-10 text-neutral-400"><FormattedHTMLMessage id="profile.settings.calendar.subtitle" /></Body1>
      <FormItem>
        {getFieldDecorator('calendarSync', {
          initialValue: calendarSync,
        })(
          <RadioGroup>
            <Radio value>{t({ id: 'yes' })}</Radio>
            <Radio value={false}>{t({ id: 'no' })}</Radio>
          </RadioGroup>
        )}
      </FormItem>
    </>
  );
};

NotifyAgendaSeekube.propTypes = {
  getFieldDecorator: func,
  calendarSync: string,
};
