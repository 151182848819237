import React from 'react';
import { oneOf } from 'prop-types';
import { MediumParticipantCard, BigParticipantCard } from './components';

export default class ParticipantCards extends React.PureComponent {
  static propTypes = {
    component: oneOf(['big', 'medium']).isRequired,
  };

  cardComponents = (size) => {
    switch (size) {
      case 'medium': return <MediumParticipantCard {...this.props} />;
      case 'big': return <BigParticipantCard {...this.props} />;

      default: return null;
    }
  };

  render() {
    return this.cardComponents(this.props.component);
  }
}
