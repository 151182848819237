import React from 'react';
import { array, bool, object, string } from 'prop-types';
import { Typography, Grid } from '@seekube-tech/ui';
import classnames from 'classnames';
import { useFieldHelperText } from '@seekube-tech/ui';

import useStyles from './styles';

function FieldColor({ className, label, required, input, options, meta }) {
  /* Vars */

  const styles = useStyles();
  const { helperText } = useFieldHelperText(meta);

  /* Render */

  return (
    <div className={className}>
      <Typography variant="body2" color="grey[500]">
        {label} {required && (<span className={styles.asterisk}>*</span>)}
      </Typography>

      <Grid container spacing={2} className={styles.buttons}>
        {options.map(option => (
          <Grid item>
            <button
              key={option.value}
              type="button"
              onClick={() => input.onChange(option.value)}
              className={classnames(styles.button, { [styles.selected]: input.value === option.value })}
            >
              {option.icon}
            </button>
          </Grid>
        ))}
      </Grid>

      {helperText}
    </div>
  );
}

FieldColor.propTypes = {
  className: string,
  label: string.isRequired,
  required: bool,
  input: object.isRequired,
  meta: object.isRequired,
  options: array.isRequired,
};

FieldColor.defaultProps = {
  className: '',
  required: false,
};

export default FieldColor;
