import { useMutation } from 'react-query';
import request from '@/utils/request';

export function usePostNotify(
  {
    onSuccess = () => {},
  }) {

  const mutationFn = ({ _id }) => {
    const requestUrl = `${process.env.FRONT_API_URL}/posts/${_id}/notify`;

    return (
      request(requestUrl, {
        method: 'POST',
      })
    )
  }

  return useMutation({
    mutationFn,
    onSuccess: async (post) => {
      onSuccess(post);
    },
  });
}
