import { AvatarCandidate, H3, Body2, IconPhone } from '@seekube-tech/ui-kit';
import colors from '@seekube-tech/ui-kit/dist/colors';
import { getCdnUrl } from '@/components/ImgCdn';
import { IconsHybrid } from '@/components/IconsHybrid';
import React from 'react';
import { isHybrid as getIsHybrid } from '@/helpers/event/isHybrid';
import {  useIsMobile } from '@/utils/MediaQueries';
import styles from './styles.less';

export const AvatarWithMainInfo = ({ participant }) => {
  const isMobile = useIsMobile();
  const formatParticipant = participant?.keyMomentFormats?.length > 1 ? 'all' : participant.keyMomentFormats?.[0];
  const isHybrid = getIsHybrid(participant.event);

  return (
    <div className={styles.avatarWithMainInfo}>
      <AvatarCandidate
        user={participant._user}
        size={isMobile ? 90 : 128}
        pictureUrl={getCdnUrl(participant._user.pictureUrl, 80) ?? ''}
        componentBottom={isHybrid && <IconsHybrid name={participant?._user?.shortName} hybridFormat={formatParticipant} />}
        className={styles.avatar}
      />

      <div className={styles.info}>
        <H3 color={colors.neutral['500']}>{participant._user.fullName}</H3>
        <Body2 color={colors.neutral['400']}>{participant._user.title}</Body2>
        <Body2 color={colors.primary['500']}><IconPhone className="mr-12" size={16}/>{participant._user.phone}</Body2>
      </div>
    </div>
  )
}