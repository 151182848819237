import styles from '@/scenes/Event/scenes/Owner/scenes/Dashboard/components/ModalActivities/styles.less';
import { Button, H4 } from '@seekube-tech/ui-kit';
import Modal from '@/components/Modal';
import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import {
  useDeletePost
} from '@/scenes/Event/scenes/Owner/scenes/Dashboard/components/ModalActivities/queries/useDeletePost';

export const ModalDeleteActivity = ({ itemToDelete, onClose, page, event }) => {
  const t = useFormatMessage();
  const { isLoading, mutate } = useDeletePost({
    eventId: event._id,
    page,
    onSuccess: onClose,
  });

  return (
    <Modal
      width={672}
      visible={!!itemToDelete}
      footer={null}
      className={styles.modalActivities}
      onCancel={onClose}
    >
      <div className={styles.headerModal}>
        <H4>
          {t({ id: 'modal.delete.activity.title' })}
        </H4>
      </div>
      <div className={styles.buttonsContainer}>
        <Button variant='outline' color='neutral' onClick={onClose}>
          {t({ id: 'cancel' })}
        </Button>
        <Button
          color='error'
          loading={isLoading}
          disabled={isLoading}
          onClick={() => mutate({ _id: itemToDelete })}
        >
          {t({ id: 'btn.confirm' })}
        </Button>
      </div>
    </Modal>
  )
}
