import { defineMessages } from 'react-intl';

export default defineMessages({
  placeholderOfferOwner: {
    id: 'client.offer.owner',
  },
  titleLabel: {
    id: 'offer.form.title.label',
  },
  title: {
    id: 'client.create.offer.description.title',
  },
  subtitle: {
    id: 'client.create.offer.description.subtitle',
  },
  titlePlaceholder: {
    id: 'offer.form.title.placeholder',
  },
  profileDescriptionLabel: {
    id: 'offer.form.profileDescription.label',
  },
  descriptionLabel: {
    id: 'client.create.offer.description.label',
  },
  next: {
    id: 'next',
  },
});
