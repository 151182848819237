import React, { useEffect, useState } from 'react';
import { array, number, any } from 'prop-types';
import classnames from 'classnames';

import styles from './styles.less';

const Tab = ({ items, defaultIndex, index, className }) => {
  const [activeIndex, setActiveIndex] = useState(defaultIndex || 0);

  useEffect(() => {
    setActiveIndex(defaultIndex);
  }, [index])

  return (
    <div className={classnames(styles.titles, className)}>
      {
        items.map((item, i) => (
          <div
            tabIndex={0}
            key={i}
            role="button"
            onClick={() => {
              setActiveIndex(i);

              if (typeof item.onClick === 'function') {
                item.onClick();
              }
            }}
            className={activeIndex !== i ? styles.notActive : null}
          >
            <h1>{item.label}</h1> {item.count ? (<span className={styles.stats}>{item.count}</span>) : null}
          </div>
        ))
      }
    </div>
  );
};

Tab.propTypes = {
  items: array,
  defaultIndex: number,
  index: number,
  className: any,
};

export { Tab };
