import { map, join, get, find } from 'lodash';
import React from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import { mapAndJoin } from '@/utils/text';
import { getId } from '@/utils/global';
import { ImgCascade } from '@/components/ImgCascade';
import styles from './styles.less';
import messages from '../../messages';

export default
  (calendarEvents, contractCriteria, intl) => (
    map(calendarEvents,
      (event, key) => ({
        key,
        ...event,
        planners: <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ marginRight: '10px' }}>
            <ImgCascade urls={map(get(event, 'planners._organizations'), (orga) => get(orga, 'profile.pictureUrl'))} />
          </div>{mapAndJoin(event.planners._organizations, 'name', ', ')}</div>,
        name: <a href={`/seekalendar/${event.slug}`} target="_blank">{event.name}</a>,
        targets: join(map(event.targets, (target) => intl.formatMessage(messages[target])), ', '),
        pricing: event.pricing && intl.formatMessage(messages[`pricing_${event.pricing}`]),
        format: intl.formatMessage({ id: `event.localization.${event.format}` }),
        targetContracts: getTargetsContract(event, contractCriteria, intl),
      })
    ))

const getTargetsContract = (event, contractCriteria, intl) => {
  const contracts = join(
    map(event.targetContracts, (criteriaId) =>
      get(
        find(contractCriteria._choices,
          (choice) =>
            getId(criteriaId) === getId(choice)),
        [intl.locale === 'fr' ? 'label' : 'label_en'])
    ), ', ');

  return (
    <span title={contracts}><LinesEllipsis
      text={contracts || <span className={styles.noContract}>{intl.formatMessage(messages.pricing_info)}</span>}
      maxLine="3"
      ellipsis="..."
      trimRight
      basedOn="letters"
    /></span>
  );
};
