import { slotOverlaps } from './slotOverlaps';
import { eventContainsSlot } from './eventContainsSlot';

/**
 * @description
 * remove overlaping & out of event timeslots
 *
 * @param {Array} newSlots From react-big-calendar
 * @param {Array} slots
 * @param {object} period Timeslots grouped
 * @param {Number} slotDuration
 */
export const getSlotsCleaned = (
  newSlots,
  slots = [],
  period = { beginAt: null, endAt: null },
  slotDuration = 0
) => newSlots
  .filter(
      (slot) => (
        !slotOverlaps(slot, slots, slotDuration)
        && eventContainsSlot(period, undefined, undefined, slot, slotDuration)
    )
  )
;
