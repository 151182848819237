import React from 'react';
import { Tag } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';

export const LiveTag = ({ liveTag }) => {
  const t = useFormatMessage();

  return (() => {
    switch (liveTag) {
      case 'present':
        return <Tag color="primary">{t({ id: 'present.pl' }, { count: 0 })}</Tag>;
      case 'accepted':
        return <Tag color="success">{t({ id: 'accepted' })}</Tag>;
      case 'confirmed':
        return <Tag color="success">{t({ id: 'confirmed' })}</Tag>;
      case 'pending':
        return <Tag color="grey">{t({ id: 'pending' })}</Tag>;
      case 'refused':
        return <Tag color="error">{t({ id: 'refused' })}</Tag>;
      case 'canceled':
        return <Tag color="error">{t({ id: 'canceled' })}</Tag>;
      case 'noshow':
        return <Tag color="error">{t({ id: 'noshow' })}</Tag>;

      default: return null;
    }})()
}