import React, { memo } from 'react';

// Components
import { Table as TableAntd } from 'antd';

// Styles & Translations
import './styles.less';

const Table = (props) => (
  <TableAntd {...props} />
);

export default memo(Table);
