import React from 'react';
import { bool, func, object } from 'prop-types';
import { LandingSideBar } from '@/scenes/Event/containers/Landing/components/Contents/components/LandingViewContainer/LandingSideBar';
import styles from './styles.less';

const LandingViewContainer = ({
  event,
  editMode,
  children
}) => (
  <div className={styles.landingV3Container}>
    <div className={styles.landingBody}>
      <div className={styles.landingLeftSide}>
        {children}
      </div>
      {!editMode &&
        <div className={styles.landingRightSide}>
          <LandingSideBar event={event} context='landing' editMode={editMode} />
        </div>
      }
    </div>
  </div>
);

LandingViewContainer.propTypes = {
  event: object,
  editMode: bool,
  children: func
}

export { LandingViewContainer };
