import React, { useMemo } from 'react';
import { Body2, IconChevronLeft, IconChevronRight, Button } from '@seekube-tech/ui-kit';
import { arrayOf, number, object, func } from 'prop-types';
import moment from 'moment';


function DateSelector({ weeks, indexWeek, handleWeekChange }) {
  const currentWeek = weeks[indexWeek];
  const isPrevButtonDisabled = indexWeek === 0;
  const isNextButtonDisabled = Boolean(weeks[indexWeek + 1]) === false;

  const getDateText = useMemo(() => {
    const beginAt = moment(currentWeek?.beginAt);
    const endAt = moment(currentWeek?.endAt);

    if (beginAt?.month() === endAt?.month()) {
      return `${beginAt.format('DD')} - ${endAt.format('DD MMMM YYYY')}`;
    }

    return `${beginAt?.format('DD MMM')} - ${endAt?.format('DD MMM')}`;
  }, [currentWeek]);

  const handlePrevButton = () => {
    handleWeekChange(indexWeek - 1);
  };

  const handleNextButton = () => {
    handleWeekChange(indexWeek + 1);
  };

  return (
    <div className="flex justify-center items-center">
      <Button
        size="small"
        className="mr-16"
        variant="outline"
        color="neutral"
        disabled={isPrevButtonDisabled}
        onClick={handlePrevButton}
      >
        <IconChevronLeft />
      </Button>
      <Body2 fontWeight={800} className="text-neutral-500">{getDateText}</Body2>
      <Button
        size="small"
        className="ml-16"
        variant="outline"
        color="neutral"
        disabled={isNextButtonDisabled}
        onClick={handleNextButton}
      >
        <IconChevronRight />
      </Button>
    </div>
  );
}

DateSelector.propTypes = {
  weeks: arrayOf(object),
  indexWeek: number,
  handleWeekChange: func,
};

export default DateSelector;
