import React, { useEffect } from 'react';
import { Form, Typography } from '@seekube-tech/ui';
import { Button } from '@seekube-tech/ui-kit';
import { bool, func, object } from 'prop-types';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useFormatMessage } from 'react-intl-hooks';
import { withRouter } from 'react-router-dom';

// Store
import { exponentActions, exponentSelectors } from '@/store/exponent';

import Divider from '@/components/newComponents/Divider';
import LoadingIndicator from '@/components/LoadingIndicator';
import ExponentsTable from './components/ExponentsTable/ExponentsTable';

import useStyles from './styles';

/**
 * Send data to server
 * @param {Object} event
 * @param {Func} patchEvent
 * @param {Object} values
 */
 const onSubmit = ({ exponentsPartners, patchExponentsPartners, eventId }) => ({ partners = [] }) => {
  const newPartners = exponentsPartners.map(exponentPartner => {
    const exponent = partners.find((partner) => partner._id === exponentPartner._id);

    return {
      ...(exponent || exponentPartner),
      score: parseInt((exponent?.score || exponentPartner.score), 10),
      isPartner: Boolean(exponent),
    };
  });

  patchExponentsPartners({ eventId, params: { partners: newPartners } });
};

function FormScore({
  isEnable,
  getExponentsPartners,
  patchExponentsPartners,
  exponentsPartners,
  isPatching,
  isFetching,
  match,
}) {
  /* Vars */

  const styles = useStyles();
  const initialValues = { partners: exponentsPartners };
  const t = useFormatMessage();
  const eventId = match?.params?.eventSlug;

  /* Effects */

  useEffect(() => {
    getExponentsPartners({ eventId });
  }, []);

  /* Render */

  return isEnable ? (
    <Form
      subscription={{}}
      onSubmit={onSubmit({ exponentsPartners, patchExponentsPartners, eventId })}
      initialValues={initialValues}
      mutators={{ ...arrayMutators }}
      render={() => (
        <>
          <Divider className={styles.divider} />

          <Typography variant="h3">{t({ id: 'event.modules.partner.score.title' })}</Typography>

          <Typography variant="body2">{t({ id: 'event.modules.partner.score.description' })}</Typography>

          {isFetching ? (
            <LoadingIndicator />
          ) : (
            <div className={styles.table}>
              <FieldArray
                name="partners"
                component={ExponentsTable}
              />
            </div>
          )}

          <Button
            type="submit"
            className={styles.sendBtn}
            loading={isPatching}
          >
            {t({ id: 'save' })}
          </Button>
        </>
      )}
    />
  ) : null;
}

FormScore.propTypes = {
  isEnable: bool,
  getExponentsPartners: func.isRequired,
  patchExponentsPartners: func.isRequired,
  exponentsPartners: object,
  isFetching: bool,
  isPatching: bool,
  match: object,
};

FormScore.defaultProps = {
  isEnable: false,
  exponentsPartners: null,
  isFetching: false,
  isPatching: false,
  match: null,
};

const mapStateToProps = createStructuredSelector({
  exponentsPartners: exponentSelectors.getExponentsPartners,
  isFetching: exponentSelectors.getExponentsFetching,
  isPatching: exponentSelectors.getExponentsPatching,
});

const mapDispatchToProps = {
  getExponentsPartners: exponentActions.getExponentsPartners,
  patchExponentsPartners: exponentActions.patchExponentsPartners,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, withRouter)(FormScore);
