import React from 'react';
import { get, head } from 'lodash';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { compose } from 'redux';
import { func, object, any, string } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { toJS } from '@/utils';
import { Body1 } from '@seekube-tech/ui-kit';
import { getUtcFormat } from '@/utils/format';
import { authActions, authSelectors } from '@/store/auth';

const SetTimezone = ({ text = get(authUser, 'timezone.text'), push, authUser, className }) => (
  <div className={className}>
    <Body1 className="text-neutral-400">
      {getUtcFormat(head(get(authUser, 'timezone.utc')), text)}
      <a className="ml-4" role="button" tabIndex={0} onClick={() => { push('?settings=timezone'); }}>
        <FormattedMessage id="change" />
      </a>
    </Body1>
  </div>
);

SetTimezone.propTypes = {
  authUser: object,
  push: func,
  className: any,
  text: string,
};

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser,
});

const mapDispatchToProps = {
  toggleSettings: authActions.toggleSettings,
  push,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, toJS)(SetTimezone);
