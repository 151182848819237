import * as React from 'react';
import { number } from 'prop-types';
import { IconClock } from '@seekube-tech/ui-kit';
import colors from '@seekube-tech/ui-kit/dist/colors'
import { FormattedHTMLMessage } from 'react-intl';

function Propositions({ countPropositions }) {
  return (
    <span key="propositions" style={{ color: countPropositions > 0 ? colors.warning['500'] : colors.grey['500']}} color={countPropositions > 0 ? 'warning[500]' : 'grey[500]'}>
      <IconClock size={16} />
      <FormattedHTMLMessage values={{ count: countPropositions || 0 }} id="events.stats.candidate.propositions" />
    </span>
  )
}

Propositions.propTypes = {
  countPropositions: number
}

export default Propositions;
