import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isEqual } from 'lodash';
import { injectIntl } from 'react-intl';
import classnames from 'classnames';
import moment from 'moment';
import { Modal, Input } from 'antd';
import Checkbox from '@/components/Checkbox';
import Icon from '@/components/Icon';
import styles from '@/scenes/Event/scenes/Owner/scenes/Setting/scenes/MatchingCriteria/styles.less';
import messages from '@/components/MatchingFilterFormItem/messages';
import Draggable from '@/components/Draggable';

/**
 * EditChoiceModal
 */
class EditChoiceModal extends React.PureComponent {
  static propTypes = {
    isVisible: PropTypes.bool,
    choices: PropTypes.array,
    onOk: PropTypes.func,
    onCancel: PropTypes.func,
    editable: PropTypes.bool,
    criterion: PropTypes.object,
    intl: PropTypes.object,
  };

  state = {
    choices: [],
    canDrag: true,
  };

  /**
   * When receive new props
   *
   * @param {Boolean} isVisible
   * @param {Array} choices
   * @param {Object} criterion
   * @param {Object} intl
   */
  componentWillReceiveProps({ isVisible, choices, criterion, intl }) {
    this.setState({ isVisible });

    if (!isEmpty(choices) && !isEqual(choices, this.props.choices)) {
      this.setState({ choices });
    }

    if (isEmpty(choices) && !isEmpty(criterion) && criterion.key === 'AVAILABILITY') {
      const date = new Date();
      const y = date.getFullYear();
      const m = date.getMonth();
      const firstDay = new Date(y, m, 1);
      const dates = Array.from(Array(12).keys()).map((item) => {
        const now = item > 0 ? moment(firstDay).add(item, 'M') : moment(firstDay);

        return {
          enable: true,
          label: item === 0 ? intl.formatMessage(messages.availableToday) : now.format('MMMM YYYY'),
        };
      });

      this.setState({ choices: dates });
    }
  }

  onDragIconClick = (e) => {
    e.preventDefault();
    this.setState({ canDrag: true });
  };

  /**
   * Handle input change
   *
   * @param {Number} index
   * @param {string} inputValue
   */
  handleInputChange = (index, inputValue) => {
    const { state: { choices } } = this;

    choices[index].label = inputValue;

    this.setState({ choices });
    this.forceUpdate();
  };

  handleInputENChange = (index, inputValue) => {
    const { state: { choices } } = this;

    choices[index].label_en = inputValue;

    this.setState({ choices });
    this.forceUpdate();
  };

  /**
   * Handle checkbox change
   *
   * @param {Number} index
   * @param {boolean} inputValue
   */
  handleCheckboxChange = (index, inputValue) => {
    const { state: { choices } } = this;

    choices[index].enable = inputValue;

    this.setState({ choices });
    this.forceUpdate();
  };

  handleSortChoices = (sortedList) => {
    this.setState({
      choices: sortedList.map((choice) => choice.contentObject),
    });

    this.forceUpdate();
  };

  /**
   * Before send new tag, need to add the choice ID for exist tag
   */
  handleOnOk = () => {
    const { state: { choices }, props: { onOk, onCancel } } = this;

    // Return the new list of choices
    onOk(choices, onCancel);
  };

  getItems = () => {
    const {
      state: { choices, canDrag }, props: { editable },
      handleInputChange, handleInputENChange, handleCheckboxChange, onDragIconClick } = this;

    const items = choices.map((choice, i) => {
      const parent = !isEmpty(choice._parent) ? choices.find((item) => item._id === choice._parent) : null;
      const content = (
        <div className={styles.globalChoicesContainer} key={i}>
          <div className={classnames(!isEmpty(choice._parent) ? styles.childRow : styles.choiceRow)}>
            <Checkbox
              onChange={({ target: { checked: inputValue } }) => handleCheckboxChange(i, inputValue)}
              checked={choice.enable}
              disabled={!editable || (!isEmpty(parent) && parent.enable === false)}
              className="no-drag"
            />
            <Input
              type="text"
              size="small"
              value={choice.label}
              onChange={({ target: { value: inputValue } }) => handleInputChange(i, inputValue)}
              onFocus={(e) => { e.preventDefault(); this.setState({ canDrag: false }); }}
              disabled={!editable || (!isEmpty(parent) && parent.enable === false)}
              className="no-drag"
            />
          </div>
          <div className={classnames(!isEmpty(choice._parent) ? styles.childRow : styles.choiceRow)}>
            <Input
              type="text"
              size="small"
              value={choice.label_en ? choice.label_en : ''}
              onChange={({ target: { value: inputValue } }) => handleInputENChange(i, inputValue)}
              onFocus={(e) => { e.preventDefault(); this.setState({ canDrag: false }); }}
              disabled={!editable || (!isEmpty(parent) && parent.enable === false)}
              className="no-drag"
            />
            {editable || (isEmpty(parent) || parent.enable) ? <span role="button" tabIndex={0} onClick={onDragIconClick}><Icon name="menu-burger" /></span> : ''}
          </div>
        </div>
      );

      return {
        contentObject: choice,
        content,
        index: i,
        disabled: !editable || !canDrag
      };
    });

    return items;
  };

  /**
   * Render
   */
  render() {
    const {
      handleOnOk, handleSortChoices, getItems,
      props: { isVisible, onCancel },
    } = this;

    const handleCancel = () => {
      this.setState({ choices: this.props.choices });
      onCancel();
    }

    return (
      <Modal
        title={this.props.intl.formatMessage({ id: 'owner.settings.criteriaTable.editChoice' })}
        width={620}
        visible={isVisible}
        onOk={handleOnOk}
        onCancel={handleCancel}
        cancelText={this.props.intl.formatMessage({ id: 'cancel' })}
      >
        <div className={styles.labelsTitleContainer}>
          <div className={styles.oneLabelTitleContainer}><span className={styles.labelsTitle}>{this.props.intl.formatMessage({ id: 'french' })}</span></div>
          <div className={styles.oneLabelTitleContainer}><span className={styles.labelsTitle}>{this.props.intl.formatMessage({ id: 'english' })}</span></div>
        </div>
        <div className={styles.choicesContainer}>
          <Draggable
            itemType='criteria'
            items={getItems()}
            localSort
            onDragEnd={handleSortChoices}
          />
        </div>
      </Modal>
    );
  }
}

export default injectIntl(EditChoiceModal);
