import * as React from 'react';
import { node, string } from "prop-types";
import { Typography } from '@seekube-tech/ui';
import HybridTag from '@/components/HybridTag';
import styles from './InputWithTitle.less';

function InputWithTitle({ title, children, tagName }) {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography variant="body2" color="neutral.500">{title}</Typography>
        <div className={styles.tagContainer}>
          <HybridTag name={tagName} />
        </div>
      </div>
      <div className={styles.input}>
        {children}
      </div>
    </div>
  )
}

InputWithTitle.propTypes = {
  title: string,
  children: node,
  tagName: string
}

export default InputWithTitle;
