import { defineMessages } from 'react-intl';

export default defineMessages({
  calendarB2b: {
    id: 'calendar.b2b',
  },
  from: {
    id: 'calendar.b2b.from',
  },
  to: {
    id: 'date.to',
  },
  save: {
    id: 'save',
  },
  showResult: {
    id: 'show.result',
  },
});
