import React, { useEffect, useState } from 'react';
import { func, object } from 'prop-types';
import { connect } from 'react-redux';
import { useFormatMessage } from 'react-intl-hooks';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import Separator from '@/components/Separator';
import { usePatchMe } from '@/queries/user/usePatchMe';
import { IdentityV2 } from '@/scenes/Settings/components/IdentityV2';
import { trackUserPatch } from '@/scenes/Settings/RecruiterSettings/components/General/tracking';
import { toJS } from '@/utils';
import { notificationActions } from '@/store/notification';
import { AsYouType } from 'libphonenumber-js';
import { isEmpty, pick } from 'lodash';
import { authActions } from '@/store/auth';
import { usePatchEmailAdmin } from '@/queries/admin/usePatchEmailAdmin';

const getUserInfos = (data) => pick(data, ['pictureUrl', 'firstName', 'lastName', 'title', 'username', 'phone', 'description']);

const GeneralV2 = ({
  authUser,
  authRefreshUser,
  sendNotification,
  trueAuthUser
}) => {
  const t = useFormatMessage();
  const [userInfos, setUserInfo] = useState(getUserInfos(authUser));
  const patchEmailAsAdmin = usePatchEmailAdmin({ 
    userId: authUser._id,
    onSuccess: (data) => {
      authRefreshUser({ user: data });
      successNotification();
    }
  });

  useEffect(() => {
    setUserInfo(getUserInfos(authUser));
  }, [authUser]);
  
  const successNotification = (label = 'form.information.success') => {
    sendNotification({
      message: t({ id: label }),
      kind: 'info',
      style: {
        bottom: '5%',
        top: 'inherit',
      },
    });
  }
  
  const patchMeQuery = usePatchMe({
    onSuccess: (data) => {
      trackUserPatch(authUser, data);
      authRefreshUser({ user: data });
      successNotification();
    },
    onError: (error) => {
      sendNotification({
        message: error.message || t({ id: 'form.information.error' }),
        kind: 'error',
        style: {
          bottom: '5%',
          top: 'inherit',
        },
      })
    }
  });
  
  const patchUser = (values) => {
    const isOnlyUsernameModified = Object.entries(values).length === 1;
    
    if (isEmpty(values)) {
      return successNotification(' form.information.upToDate');
    }
    
    if (trueAuthUser.isAdmin && values.username) {
      patchEmailAsAdmin.mutate(values.username);
      if (isOnlyUsernameModified) {
        return;
      }
    }
    
    patchMeQuery.mutate({ 
      ...values,
      ...(values.username && !trueAuthUser.isAdmin ? { usernameTemp: values.username, context: 'checkUsername' } : {})
    });
  };
  
  return <div id="profile">
    <div id="identity">
      <IdentityV2 
        authUser={authUser}
        userInfos={{ ...userInfos, phone: new AsYouType().input(userInfos.phone || '') }}
        onSave={patchUser} 
      />
      <Separator height={40} />
    </div>
  </div>
}

GeneralV2.propTypes = {
  authUser: object,
  sendNotification: func
}

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = {
  sendNotification: notificationActions.sendNotification,
  authRefreshUser: authActions.authRefreshUser
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(toJS(GeneralV2));