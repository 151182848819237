import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'recruiter.participantScoring.title',
  },
  dropdownMove: {
    id: 'recruiter.participantScoring.dropdown.move',
  },
  dropdownNegative: {
    id: 'recruiter.participantScoring.dropdown.negative',
  },
  info: {
    id: 'recruiter.participantScoring.info',
  },
  emailNotSendToCandidate: {
    id: 'email.no.send.to.candidate',
  },
});
