import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Modal } from 'antd';
import { injectIntl } from 'react-intl';
import Checkbox from '@/components/Checkbox';

/**
 * ChoiceModal
 */
class JobsModal extends React.PureComponent {
  static propTypes = {
    isVisible: PropTypes.bool,
    jobs: PropTypes.array,
    jobsSelected: PropTypes.array,
    onOk: PropTypes.func,
    onCancel: PropTypes.func,
    intl: PropTypes.object
  };

  /**
   * Constructor
   *
   * @param {Object} props
   */
  constructor(props) {
    super(props);

    this.state = {
      choices: [],
      tags: [],
      inputVisible: false,
      inputValue: '',
      selectedJobIds: [],
    };
  }

  /**
   * When receive new props
   *
   * @param {Boolean} jobsSelected
   * @param {Array} choices
   */
  componentWillReceiveProps({ jobsSelected }) {
    const selectedJobsIds = jobsSelected.map((item) => item._id);

    this.setState({ selectedJobsIds });
  }

  /**
   * Handle input change
   *
   * @param {string} inputValue
   */
  toggleJob = (jobId) => {
    const { state: { selectedJobsIds } } = this;

    if (selectedJobsIds.indexOf(jobId) > -1) {
      selectedJobsIds.splice(selectedJobsIds.indexOf(jobId), 1);
    } else {
      selectedJobsIds.push(jobId);
    }

    this.setState({ selectedJobsIds });
    this.forceUpdate();
  };

  /**
   * Before send new tag, need to add the choice ID for exist tag
   */
  handleOnOk = () => {
    const { state: { selectedJobsIds }, props: { onOk } } = this;

    onOk(selectedJobsIds);
  };

  renderJobsList = (selectedJobsIds) => {
    const {
      toggleJob,
      props: { jobs },
    } = this;

    if (!jobs || !selectedJobsIds) {
      return '';
    }

    return jobs.map((job) => (
      <li>
        <label htmlFor={job._id}>
          <Checkbox
            id={job._id}
            checked={selectedJobsIds.includes(job._id.toString())}
            onChange={() => toggleJob(job._id)}
            value={job._id}
          >{job.name}</Checkbox>
        </label>
      </li>
    ));
  }

  /**
   * Render
   */
  render() {
    const {
      renderJobsList, handleOnOk,
      state: { selectedJobsIds },
      props: { isVisible, onCancel },
    } = this;

    return (
      <Modal
        title={this.props.intl.formatMessage({ id: 'owner.settings.criteriaTable.jobsModal.title' })}
        visible={isVisible}
        onOk={handleOnOk}
        onCancel={onCancel}
        cancelText={this.props.intl.formatMessage({ id: 'cancel' })}
      >
        <ul>{renderJobsList(selectedJobsIds)}</ul>
      </Modal>
    );
  }
}

export default injectIntl(JobsModal);
