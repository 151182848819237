import { defineMessages } from 'react-intl';

export default defineMessages({
  btnConfirm: {
    id: 'btn.confirm',
  },
  cancel: {
    id: 'cancel',
  },
  deleteRecruiterModalConfirm: {
    id: 'event.recruiter.preparation.team.delete.recruiter.modal.confirm',
  },
  deleteRecruiterFromJd: {
    id: 'recruiter.delete.recruiter.from.jd',
  },
  deleteRecruiterFromJds: {
    id: 'recruiter.delete.recruiter.from.jds',
  },
  deleteExponentModalConfirm: {
    id: 'event.owner.delete.exponent.modal.confirm',
  },
  removeAccount: {
    id: 'remove.account',
  },
});
