import React from 'react';
import { object, func, string } from 'prop-types';
import { compose } from 'redux';
import { isEmpty, upperFirst } from 'lodash';
import { Form, Select as SelectAntd } from 'antd';
import { Body1, Button, IconSettings2, IconUser, IconContract, Alert } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { useSelector } from 'react-redux';
import PhoneInput from 'react-phone-number-input';
import { useLocation } from 'react-router';
import { formatName } from '@/utils/format';
import AvatarEditorDnd from '@/components/AvatarEditorDnd';
import InputMaterial from '@/components/Input/InputMaterial';
import './styles.less';
import { Select } from '@/components/Form';
import {
  getCurrentRoleId,
  getLocaleName,
  getRole,
  useUserAclHandler,
  roles,
} from '@/store/role/helpers';
import { getLocale } from '@/utils/localStorage';
import { ACL } from '@/utils/constants';
import { getAuthUser } from '@/store/auth/selectors';
import { Username } from '@/forms/components';
const FormItem = Form.Item;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

const { Option } = SelectAntd;

const CONTEXT_URL = { ownerExponents: 'owner/exponents', adminOrganizations: 'admin/organizations' };

const UserForm = ({ form, user, onSubmit, context, error, organization }) => {
  const authUser = useSelector(getAuthUser)?.toJS();
  const { pathname } = useLocation();

  const t = useFormatMessage();
  const [phoneValue, setPhoneValue] = React.useState(user.phone);
  const { getFieldDecorator, getFieldsError, validateFields } = form;
  const isOwnerExponentsContext = pathname.includes(CONTEXT_URL.ownerExponents);
  const isAdminOrganizationsContext = pathname.includes(CONTEXT_URL.adminOrganizations);

  const locale = getLocale();
  const isContextEditRole = context === ACL.EDIT_ROLE;
  const required = !isContextEditRole;

  const handleSaveProfilePicture = (fileUrl) => {
    form.setFieldsValue({ pictureUrl: fileUrl });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    validateFields((err, values) => {
      if (err) {
        return null;
      }

      return onSubmit(values);
    });
  };

  const currentRoleId = getCurrentRoleId(user.roles, organization._id);

  const { role: authUserRole } = useUserAclHandler(authUser);

  const clientRoles = organization?.isClient &&
    [roles.admin, roles.supervisor, roles.member]
      .map((role) => getRole(role))

  const validatePhoneNumber = (rule, value, callback) => {
    const result = parseInt(value, 10);

    if (Number.isInteger(result) && value && value.length > 0 && value.length >= 6) {
      callback();
    }
    callback();
  };

  return (
    <Form className="form" onSubmit={handleSubmit}>
      <div className="pictureContainer">
        <div className="picture">
          <AvatarEditorDnd
            src={form.getFieldValue('pictureUrl') || undefined}
            onSave={handleSaveProfilePicture}
            onRemove={() => handleSaveProfilePicture(null)}
            objectId={user._id}
            size={130}
            user={user}
          >
            <div className="pictureBtnAction">
              <Button variant="outline" className="pictureBtn">
                {t({ id: 'pictureUrl.choose' })}
              </Button>

              <p className="upload-infos">
                {t({ id: 'pictureUrl.help' })}
                <br />
                {t({ id: 'pictureUrl.format' })}
              </p>
            </div>
          </AvatarEditorDnd>
        </div>
      </div>

      <FormItem className="pictureItem">
        {getFieldDecorator('pictureUrl', {
          initialValue: user?.pictureUrl,
          rules: [{ required: false }],
        })(
          <input type="hidden" />
        )}
      </FormItem>

      <FormItem>
        {getFieldDecorator('firstName', {
          initialValue: user ? user.firstName : '',
          rules: [{ required, message: t({ id: 'form.error.required' }) }],
        })(
          <InputMaterial
            disabled={isContextEditRole}
            addOnIcon={<IconUser />}
            label={t({ id: 'firstName' })}
            placeholder="Paul"
            type="string"
            validations={['required']}
            applyFormatValue={formatName}
          />
        )}
      </FormItem>

      <FormItem>
        {getFieldDecorator('lastName', {
          initialValue: user ? user.lastName : '',
          rules: [{ required, message: t({ id: 'form.error.required' }) }],
        })(
          <InputMaterial
            addOnIcon={<IconUser />}
            disabled={isContextEditRole}
            label={t({ id: 'lastName' })}
            placeholder="Dupond"
            type="string"
            validations={['required']}
            applyFormatValue={formatName}
          />
        )}
      </FormItem>

      <FormItem>
        {getFieldDecorator('title', {
          initialValue: user ? user.title : '',
          rules: [{ required, message: t({ id: 'form.error.required' }) }],
        })(
          <InputMaterial
            disabled={isContextEditRole}
            addOnIcon={<IconContract />}
            label={t({ id: 'form.jobPosition.label' })}
            placeholder={t({ id: 'form.jobPosition.placeholder' })}
            type="string"
            validations={['required']}
            applyFormatValue={upperFirst}
          />
        )}
      </FormItem>

      <Username username={user.username} form={form} />

      <FormItem>
        {getFieldDecorator('phone', {
          initialValue: phoneValue,
          rules: [
            { validator: validatePhoneNumber, required: false },
          ],
        })(
          <PhoneInput
            disabled={isContextEditRole}
            defaultCountry={isEmpty(phoneValue) && locale === 'en' ? 'GB' : isEmpty(phoneValue) ? 'FR' : null}
            className="inputPhone"
            label={t({ id: 'phone' })}
            placeholder={t({ id: 'phone' })}
            onChange={(phoneValue) => { setPhoneValue(phoneValue); }}
          />
        )}
      </FormItem>
      {organization?.isClient && !isOwnerExponentsContext &&
        <FormItem
          className="textareaWrapper"
        >
          {getFieldDecorator('roleId', {
            initialValue: currentRoleId,
            rules: [{ required: false }],
          })(
            <Select
              addOnIcon={<IconSettings2 />}
              placeholder={t({ id: 'role.pl' }, { count: 1 })}
              onChange={() => { }}
            >
              {clientRoles.map((role) => (
                <Option value={role._id} key={role._id} style={{
                  display: !(isAdminOrganizationsContext || authUserRole.priority >= role.priority) ? 'none' : 'block'
                }}>
                  {getLocaleName(role)}
                </Option>))}
            </Select>,
          )}
        </FormItem>}

      {error.visible &&
        <Alert className="mb-24" color="error">
          <Body1 className="mb-6" fontWeight={600}>{error.title}</Body1>
          {error.description}
        </Alert>}

      <div>
        <Button onClick={handleSubmit} type="primary" htmlType="submit" disabled={hasErrors(getFieldsError())}>
          {context === ACL.EDIT_ROLE ? t({ id: 'update.role.of.$name' }, { name: user.firstName }) :
            t({ id: 'update.profile.of.$name' }, { name: user.firstName })
          }
        </Button>
      </div>
    </Form>
  );
}

UserForm.propTypes = {
  form: object,
  user: object,
  error: object,
  organization: object,
  onSubmit: func,
  context: string,
}


const withForm = Form.create();

export default compose(
  withForm,
)(UserForm);
