import React from 'react';
import { node, string } from 'prop-types';
import classnames from 'classnames';

import useStyles from './styles';

function Card({ children, className }) {
  /* Vars */

  const styles = useStyles();

  /* Render */

  return (
    <div className={classnames(styles.root, className)}>
      {children}
    </div>
  );
}

Card.propTypes = {
  children: node.isRequired,
  className: string,
};

Card.defaultProps = {
  className: '',
};

export default Card;
