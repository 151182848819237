import React, { useEffect } from 'react';
import { func, string, array, bool } from 'prop-types';
import { Form, Select as SelectAnt } from 'antd';
import { useFormatMessage } from 'react-intl-hooks';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import Select from '@/components/Form/Select';
import { getAuthUser } from '@/store/auth/selectors';
const FormItem = Form.Item;
const { Option } = SelectAnt;

const OfferContractsForm = ({
  initialValue = [],
  fieldDecoratorName = 'matching.filters',
  getFieldDecorator,
  organizationId,
  handleChange,
  users,
  contracts,
  disabled,
  showEmptyError,
  ...form
}) => {
  const t = useFormatMessage();
  const userId = form.getFieldValue('_user');
  const authUser = useSelector(getAuthUser)?.toJS();
  const fullName = users.filter((user) => user._id === userId)[0]?.fullName;

  const options = contracts
    ?.map((contract) => (
      <Option key={`contractOption${contracts._id}`} value={contract._id}>
        {contract[`label_${authUser.locale}`] || contract.label}
      </Option>
    )) ?? [];

  useEffect(() => {
    if (showEmptyError) {
      form.validateFields([fieldDecoratorName]);
    }
  }, [userId, showEmptyError]);

  return (
    <FormItem>
      <div id="_contractFormItem">
        {getFieldDecorator(fieldDecoratorName, {
          initialValue,
          rules: [
            {
              validator: (rule, value, callback) => showEmptyError && isEmpty(options) && !isEmpty(users) ? callback(new Error()) : callback(),
              message: t({ id: 'offer.create.recruiter.no.contract' }, { fullName })
            },
          ],
        })(
          <Select
            disabled={disabled}
            showSearch
            addOnIcon="contract"
            required
            optionFilterProp="children"
            withInputErrorStyle={showEmptyError && !isEmpty(form.getFieldError(fieldDecoratorName))}
            key="contract"
            mode="multiple"
            onChange={handleChange}
            placeholder={t({ id: 'contracts' })}
            hasError={showEmptyError && !isEmpty(form.getFieldError(fieldDecoratorName))}
          >
            {options}
          </Select>
        )}
      </div>
    </FormItem>
  );
};

OfferContractsForm.propTypes = {
  initialValue: string,
  getFieldDecorator: func,
  fieldDecoratorName: string,
  organizationId: string,
  userId: string,
  handleChange: func,
  users: array,
  contracts: array,
  disabled: bool,
  showEmptyError: bool
};

export { OfferContractsForm };
