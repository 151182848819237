import React, { useEffect, useMemo, useRef, useState } from 'react';
import { bool, object, string } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { Body1, H5 } from '@seekube-tech/ui-kit';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { useLocalStorage } from 'react-use';
import { LOCALES, EVENT_FORMAT } from '@/utils/constants';
import {
  JobfairDate,
  JobfairDateHybrid, 
  Location,
  OrganizationCount,
  PreregistrationDate
} from '@/scenes/Event/containers/Landing/components/Contents/components/GeneralInfos/components/LandingContent/components/Header/components';
import styles from '@/scenes/Event/containers/Landing/components/Contents/components/LandingViewContainer/styles.less';
import Avatars from '@/components/Avatars';
import { useGetExponentsLanding } from '@/queries/exponent/useGetExponentsLanding';
import { getCurrentParticipant } from '@/store/participant/selectors';
import EventAccessCta, { EVENT_ACCESS_CTA_COMPONENT_TYPE, EVENT_ACCESS_CTA_CONTEXT } from '@/scenes/Event/components/EventAccessCta';
import { getAuthUser } from '@/store/auth/selectors';

const LandingSideBar = ({
  event,
  context = '',
  editMode,
}) => {
  const [isIntersecting, setIntersecting] = useState(false)

  const t = useFormatMessage();
  const exponents = useGetExponentsLanding({ eventId: event._id }).data || {}
  const totalExponents = exponents?.total
  const participant = useSelector(getCurrentParticipant)?.toJS()
  const authUser = useSelector(getAuthUser)?.toJS()
  const [totalUsers, setTotalUsers] = useState(null)

  const [exponentUsersPictures, setExponentUsersPictures] = useState(null)

  const [locale] = useLocalStorage('locale', localStorage.getItem('locale') || LOCALES.fr, { raw: true });
  const role = authUser?._currentOrganization ? 'recruiter' : 'candidate';

  const isMobile = window.innerWidth <= 650;
  const ref = useRef(null)

  const orgIds = event.planners._organizations.map((o) => o._id);

  const observer = useMemo(() => new IntersectionObserver(
    ([entry]) => setIntersecting(entry.isIntersecting)
  ), [ref])


  useEffect(() => {
    observer.observe(ref?.current)
    return () => observer.disconnect()
  }, [])

  useEffect(() => {

    const currentExponents = exponents.docs

    if (!isEmpty(currentExponents) && exponentUsersPictures === null) {
      const rand = (min, max) => (Math.random() * (max - min)) + min;
      let totalUsers = 0;
      const usersPictures = [];
      const limit = 12;

      currentExponents.forEach((item) => {
        if (item.users) {
          const pictures = item.users.filter((el) => el._user && el._user.pictureUrl).map((item) => item._user.pictureUrl);
          usersPictures.push(...pictures);
          totalUsers += item.users.length;
        }
      });

      const randSlice = usersPictures.length > limit ? Math.floor(rand(0, usersPictures.length - limit)) : 0;
      setExponentUsersPictures(usersPictures.slice(randSlice, randSlice + limit));
      setTotalUsers(totalUsers);
    }
  }, [exponents]);

  return (
    <div className={styles.landingSideBarContainer} ref={ref}>
      <H5 className={styles.sideBarTitle}>{t({ id: 'detail.event' })}</H5>
      {event.format !== EVENT_FORMAT.HYBRID &&
        <JobfairDate
          type={context === 'seekalendar' && !event.keyDatesOk ? 'short' : 'long'}
          beginAt={event.keyDates.jobfair.beginAt.toString()}
          endAt={event.keyDates.jobfair.endAt.toString()}
          locale={locale}
        />
      }

      {event.format === EVENT_FORMAT.HYBRID &&
        <JobfairDateHybrid 
          event={event}
          locale={locale}
        />
      }

      <PreregistrationDate
        beginAt={event.keyDates.discovering.beginAt}
        locale={locale}
      />
      
      <Location event={event} />

      <OrganizationCount 
        event={event} 
        totalExponents={totalExponents}
        locale={locale}
      />

      {exponentUsersPictures && exponentUsersPictures.length > 0 && (
        <div className={styles.recruiters}>
          <Avatars
            pictures={exponentUsersPictures}
            maxPictures={12}
            others={totalUsers - exponentUsersPictures.length}
          />
          <Body1 className={styles.numberRecruiter}>
            {totalUsers} {t({ id: 'event.landing.recruitersAvatars' }, {
              count: totalUsers,
              label: !isEmpty(event.modules.organization[`recruiterLabel_${locale}`]) ? event.modules.organization[`recruiterLabel_${locale}`] : t({ id: 'recruiters' }, { count: 0 })
            })}
          </Body1>
        </div>
      )}

      {
        context === 'seekalendar' || (participant && participant.isBan) ?
          null : (
            <EventAccessCta
              authUser={authUser}
              context={EVENT_ACCESS_CTA_CONTEXT.LANDING}
              ctaComponentType={EVENT_ACCESS_CTA_COMPONENT_TYPE.BUTTON}
              editMode={editMode}
              event={event}
              eventUserStats={event.stats}
              role={(authUser && authUser.isAdmin) || (role === 'recruiter' && isEmpty(event.stats) && (orgIds.indexOf(authUser._currentOrganization._id) > -1)) ? 'owner' : role}
              customClass={styles.btnSideBar}
              linkCustomClass={styles.btnSideBar}
              isAuthUserBanned={event.userContext.isBanned}
            />
          )
      }
      {isMobile && !isIntersecting &&
        <div className={styles.landingFooterMobile}>
          <EventAccessCta
            authUser={authUser}
            context={EVENT_ACCESS_CTA_CONTEXT.LANDING}
            ctaComponentType={EVENT_ACCESS_CTA_COMPONENT_TYPE.BUTTON}
            editMode={editMode}
            event={event}
            eventUserStats={event.stats}
            role={(authUser && authUser.isAdmin) || (role === 'recruiter' && isEmpty(event.stats) && (orgIds.indexOf(authUser._currentOrganization._id) > -1)) ? 'owner' : role}
            customClass={styles.btnSideBar}
            linkCustomClass={styles.btnSideBar}
            isAuthUserBanned={event.userContext.isBanned}
          />
        </div>
      }
    </div>
  );
}

LandingSideBar.propTypes = {
  event: object,
  editMode: bool,
  context: string,
}

export { LandingSideBar };
