import React from 'react';
import { object, func } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Switch, Route } from 'react-router-dom';
import { replace } from 'connected-react-router';
import { EVENT_STATUS } from '@/utils/constants';
import { toJS } from '@/utils';

// Store
import { eventSelectors } from '@/store/event';
import { participantActions, participantSelectors } from '@/store/participant';
import { authSelectors } from '@/store/auth';

import { storeUtmParams } from '@/utils/url';
import { CONTEXT_ROLES } from '../../utils/constants';

// Pages
import PreparationPage from './scenes/Preparation';
import JobdatingPage from './scenes/JobDating';
import KeyDates from '../../components/KeyDates';

class CandidateScene extends React.PureComponent { // eslint-disable-line
  static propTypes = {
    match: object,
    replace: func,
    authUser: object,
    participant: object,
    event: object,
    getParticipant: func,
    location: object,
  };

  constructor(props) {
    super(props);

    const { match, event, authUser, replace } = props;

    this.state = {
      participantIsFetching: false,
    };

    if (authUser) {
      this.updateParticipantFetching(props);
    }

    storeUtmParams();

    if (match.isExact) {
      const eventStatus = event.dateStatus ? event.dateStatus : null;
      if (eventStatus === EVENT_STATUS.CLOSED) {
        replace(`${match.url}/jobdating`);
      } else if (eventStatus === EVENT_STATUS.IN_PROGRESS) {
        replace(`${match.url}/jobdating`);
      } else {
        replace(`${match.url}/preparation`);
      }
    }
  }

  componentDidMount() {
    const { replace, authUser, match } = this.props;
    if (authUser?.isAdmin || authUser?._currentOrganization?.id) {
      replace(`/${match.params.eventSlug}/`);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.authUser && nextProps.event) {
      if (!this.state.participantIsFetching && nextProps.event._id && nextProps.authUser) {
        this.updateParticipantFetching(nextProps);
      }
    }
  }

  updateParticipantFetching = (nextProps) => {
    const { props: { getParticipant, match, location } } = this;
    this.setState({ participantIsFetching: true });

    if (!location.pathname.includes('signup') && !location.pathname.includes('signin')) {

      getParticipant({
        eventId: match.params.eventSlug,
        userId: nextProps.authUser._id,
        loader: true,
      });
    }
  };

  render() {
    const { props: { match, participant, replace } } = this;

    if (participant && participant.isBan) { replace('/404'); }

    return (
      <Switch>
        <Route path={`${match.path}/preparation`} component={PreparationPage} />
        <Route path={`${match.path}/jobdating`} component={JobdatingPage} />
        <Route path={`${match.path}/keydates`} render={() => <KeyDates userKeyMomentFormats={participant?.keyMomentFormats} context={CONTEXT_ROLES.CANDIDATE} />} />
      </Switch>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser,
  event: eventSelectors.getCurrentEvent,
  participant: participantSelectors.getCurrentParticipant,
});

const mapDispatchToProps = {
  getParticipant: participantActions.getParticipant,
  replace,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(toJS(CandidateScene));
