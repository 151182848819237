import React, { useEffect, useState } from 'react';
import { createStructuredSelector } from 'reselect';
import { array, func, object, string } from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { filter, map, find, isEmpty, keys, get, values, compact, size, forEach, reduce, uniq } from 'lodash';
import { Form } from 'antd';
import { Button } from '@seekube-tech/ui-kit';
import { useGetEvent } from '@/queries/events/useGetEvent';

import { Contracts, Locations } from '@/helpers/criterion';
import { authSelectors } from '@/store/auth';
import { userSelectors } from '@/store/user';
import { exponentSelectors } from '@/store/exponent';
import { notificationActions } from '@/store/notification';

import { mergeArray } from '@/utils/math';
import { getId, getIds, TEMPLATE_CRITERIA_ID } from '@/utils/global';
import { toJS } from '@/utils';
import { withAuth } from '@/containers/RequiredAuth';
import MatchingFilterFormItem from '@/components/MatchingFilterFormItem';
import Icon from '@/components/Icon';
import { Tooltip } from '@/components/Tooltip';


import styles from './styles.less';
import stylesBtn from '../../../EditOffer/styles.less';


const Step3 = ({ offers, form, authUser, sendNotification, handleNext, handleActions, intl, exponents }) => {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const { getFieldDecorator, getFieldValue, validateFields } = form;

  const event = find(exponents, (exponent) => getId(exponent._event) === window.localStorage.getItem('eventSelected'))._event;
  const eventQuery = useGetEvent({ ...event });

  const eventCriteria = get(eventQuery, 'data._criteria') || [];

  const defaultCriterion = filter(eventCriteria || [], (criterion) => !isEmpty(criterion.key) && criterion.enable && criterion.modules.offer.isVisible);
  const specificCriterion = filter(eventCriteria || [], (criterion) => isEmpty(criterion.key) && criterion.enable && criterion.modules.offer.isVisible);

  useEffect(() => {
    shouldDisableNextBtn();
  }, [keys(form.getFieldsValue())]);

  const shouldDisableNextBtn = () => {
    const formValues = form.getFieldsValue();

    let isDisable = false;

    forEach(formValues,
      (offerId) => {
        const filtersMandatory = filter(offerId.filters, (val, criterionKey) => get(find(eventCriteria, (criterion) => criterion._id === criterionKey), 'modules.offer.choiceMin'));
        if (reduce(filtersMandatory, (prev, next) => (prev || isEmpty(next)), false)) {
          isDisable = true;
          return false;
        } return true;
      });

    setIsSubmitDisabled(isDisable);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    validateFields((err, fields) => {
      if (!err) {
        const res = compact(uniq([...getIds(offers), ...keys(fields)]))
          .map(
            (offerId) => {
              const off = offers.find((off) => getId(off) === offerId);

              const test = !isEmpty(fields[offerId]) ? ['CONTRACT', 'DURATION', 'MOBILITY', 'POSITION'].map((key) => fields[offerId][key]) : [];


              const newFilters = !isEmpty(get(fields[offerId], 'filters')) ? mergeArray(...test, ...values(fields[offerId].filters)) : [];
              off.matching = { ...off.matching, ...fields[offerId], filters: compact(uniq([...off.matching.filters, ...newFilters])) };

              return ({
                _id: offerId,
                ...off,
              });
            }
          );
        return handleNext(res);
      }
      return false;
    });
  };


  const renderCustomerFilters = (offer) => (
    compact(
      map(specificCriterion,
        (criterion) => {
          const endDate = get(offer, '_event.keyDates') ? moment(offer._event.keyDates.jobfair.endAt).toDate() : new Date();
          const choiceMin = get(criterion, 'modules.offer.choiceMin');
          const fieldKey = `${getId(offer)}.filters.${criterion._id}`;
          const matching = get(find(offer.childrenOffers, (childOffer) => getId(childOffer._event) === getId(event)), 'matching') || offer.matching;
          const choices = [];

          criterion._choices.forEach((choice) => {
            if (matching.filters.includes(getId(choice))) {
              choices.push(getId(choice));
            }
          });

          return (
            isEmpty(choices) && <MatchingFilterFormItem
              getFieldDecorator={getFieldDecorator}
              key={fieldKey}
              criterion={criterion}
              fieldDecoratorKey={fieldKey} // Warning: Required fieldDecoration doesn't work with array in fieldDecoratorKey
              initialValue={choices}
              matchingData={matching || null}
              context="offer"
              offer={offer}
              endDate={endDate}
              getFieldValue={getFieldValue}
              disableDuration={false}
              validatorCallback
              form={form}
              required={choiceMin > 0}
              withInputErrorStyle={false}
              showHelper={() => {}}
              authUser={authUser}
              onChange={() => {}}
            />);
        })
    )
  );

  const renderDefaultFilters = (offer) => (
    compact(
      map(
        filter(defaultCriterion, (criterion) => {
          let { key } = criterion;
          if (criterion.key === 'AVAILABILITY') {
            key = 'date';
            if (Number(moment().format('YMM')) <= Number(moment.unix(offer.matching[key]).format('YMM'))) {
              return false;
            }
          }
          return isEmpty(offer.matching[key]);
        }),
        (criterion) => {
          let { key } = criterion;
          if (criterion.key === 'AVAILABILITY') {
            key = 'date';
          }

          if (criterion.key === 'DURATION' && (
            offer.matching.CONTRACT.indexOf(TEMPLATE_CRITERIA_ID.cdi) > -1)
          ) return null;

          return (
            <MatchingFilterFormItem
              getFieldDecorator={getFieldDecorator}
              availabilityKey={`${getId(offer)}.${key}`}
              key={`${getId(offer)}.${key}`}
              criterion={criterion}
              fieldDecoratorKey={`${getId(offer)}.${key}`} // Warning: Required fieldDecoration doesn't work with array in fieldDecoratorKey
              initialValue={(offer.matching[key]) || []}
              matchingData={offer ? offer.matching : null}
              context="offer"
              offer={offer}
              endDate={null}
              getFieldValue={getFieldValue}
              form={form}
              required
              withInputErrorStyle={false}
              showHelper={() => {
              }}
              authUser={authUser}
              onChange={() => { }}
            />
          );
        })
    )
  );

  return (
    <div className={styles.step3Container}>
      <FormattedMessage id="recruiter.offers.spread.select.matchings.title" values={{ count: size(offers) }} tagName="h1" />
      <FormattedMessage id="recruiter.offers.spread.select.matchings.subtitle" values={{ count: size(offers) }} tagName="h3" />

      <div className={styles.recruiterContainer}>
        {!eventCriteria.isLoading && <Form layout="vertical" onSubmit={handleSubmit}>
          {
            map(offers,
              (offer, i) => {
                const defaultFilters = renderDefaultFilters(offer);
                const customerFilters = renderCustomerFilters(offer);
                const fieldsAlreadyFilled = isEmpty(customerFilters) && isEmpty(defaultFilters);

                return (
                  <div key={i}>
                    <div className={styles.offerTitle}>
                      <a>{offer.title}</a>
                      <Tooltip
                        title={<FormattedMessage id="recruiter.offers.spread.remove.tooltip" />}
                        color="dark"
                        key={0}
                      >
                        <a
                          role="button"
                          tabIndex={0}
                          onClick={() => {
                            handleActions('setOffersSelected', offer);
                            sendNotification({
                              message: <span><span
                                aria-label=""
                                role="img"
                                style={{ marginRight: '10px' }}
                              >🙌</span>{intl.formatMessage({ id: 'recruiter.offers.spread.remove.toaster.success' })}</span>,
                            });
                          }}
                        >
                          <Icon name="trash" className={styles.trashIcon} />
                        </a>
                      </Tooltip>
                    </div>
                    <Contracts matching={offer.matching} _criteria={get(event, '_criteria')} />
                    <Locations locations={offer.locations} />
                    <div className={styles.criteria}>
                      {defaultFilters}
                      {customerFilters}
                      {fieldsAlreadyFilled && <div className={styles.infoFieldsFilled}><FormattedMessage
                        id="recruiter.offers.spread.select.matchings.already.filled"
                      /></div>}
                    </div>
                  </div>
                );
              }
            )
          }
          <div className={stylesBtn.submitContainer}>
            <Button variant="tonal" className="mr-6" onClick={() => handleActions('openBackModal')}>
              {intl.formatMessage({ id: 'cancel' })}
            </Button>
            <Button disabled={isSubmitDisabled} type="submit">
              <FormattedMessage
                id="recruiter.offers.spread.select.matchings.action.publish"
                values={{ count: size(offers) }}
              />
            </Button>
          </div>
        </Form>}
      </div>
    </div>
  );
};

Step3.propTypes = {
  offers: array,
  authUser: object,
  intl: object,
  form: object,
  handleNext: func,
  sendNotification: func,
  handleActions: func,
  exponents: array,
};

const mapStateToProps = createStructuredSelector({
  users: userSelectors.getUsersOrganization,
  exponents: exponentSelectors.getExponents,
  authUser: authSelectors.getAuthUser,

});

const withForm = Form.create();

const withConnect = connect(mapStateToProps, {
  sendNotification: notificationActions.sendNotification,
});

export default compose(
  withForm,
  withRouter,
  withConnect,
  withAuth(),
  injectIntl,
)(toJS(Step3));
