import React, { useEffect, useState } from 'react';
import { string } from 'prop-types';
import { slice } from 'lodash';
import { FormattedMessage } from 'react-intl';

import { Stats } from '@/components/Stats';
import { getLivesStats } from './requests';

import styles from './styles.less';
import { getLiveStats } from './getLiveStats';

const LiveStats = ({ eventId }) => {
  const [stats, setStats] = useState({});

  useEffect(() => {
    getLivesStats({ eventId }).then((res) => setStats(res));
  }, [])

  const result = getLiveStats(stats);

  return <div className={styles.liveStats}>
    <div>
      <FormattedMessage id="live.pl" values={{ count: 2 }} tagName="h2" />
      <Stats list={slice(result, 0, 3)} />
    </div>
    <div>
      <FormattedMessage id="client.stats.participations.title" tagName="h2" />
      <Stats list={slice(result, 3, 6)} />
    </div>
  </div>;
};

LiveStats.propTypes = {
  eventId: string,
};

export { LiveStats };
