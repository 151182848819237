import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import * as types from './types';

const initialReducer = fromJS({
  ids: [],
  allIds: [],
  list: [],
});

const exponentsReducer = handleActions({
  [types.GET_SECTORS_SUCCESS]: (state, { payload }) => {
    const allIds = state.get('allIds').clear().merge(payload.result);

    return state
      .set('allIds', allIds)
      .set('list', payload.items);
  }
  ,
}, initialReducer);

export default exponentsReducer;
