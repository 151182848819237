import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import moment from 'moment';
import * as types from './types';

const initialState = fromJS({
  currentId: null,
  fetchError: false,
  countAppointments: 183842,
});

const appReducer = handleActions({
  [types.SET_CURRENT_APP]: (state, { payload: appId }) => state.set('currentId', appId),
  [types.SET_FETCH_ERROR]: (state) => state.set('fetchError', true),
  [types.GET_APP]: (state) => state.set('fetchError', false),
  [types.CLEAN_FETCH_ERROR]: (state) => state.set('fetchError', false),
  [types.GET_PUBLIC_APPOINTMENTS]: (state) => {
    let count = initialState.get('countAppointments');

    const now = moment();
    const begin = moment('2018-11-05');

    count += 5 * now.diff(begin, 'hours');

    return state.set('countAppointments', new Intl.NumberFormat('en').format(count));
  },
}, initialState);

export default appReducer;
