import { createAction } from 'redux-actions';
import * as types from './types';

/**
 * Action for push new organization
 */
export const pushOrganization = createAction(types.PUSH_ORGANIZATION);

/**
 * Action for set current organization
 */
export const setCurrentOrganization = createAction(types.SET_CURRENT_ORGANIZATION);

/**
 * Action for post a organization
 */
export const postOrganization = createAction(types.POST_ORGANIZATION);
export const postOrganizationSuccess = createAction(types.POST_ORGANIZATION_SUCCESS);
export const postOrganizationError = createAction(types.POST_ORGANIZATION_ERROR);

/**
 * Action for get an organization
 */
export const getSpecificOrganization = createAction(types.GET_SPECIFIC_ORGANIZATION);
export const getSpecificOrganizationSuccess = createAction(types.GET_SPECIFIC_ORGANIZATION_SUCCESS);
export const getOrganization = createAction(types.GET_ORGANIZATION);
export const getOrganizationSuccess = createAction(types.GET_ORGANIZATION_SUCCESS);
export const getOrganizationError = createAction(types.GET_ORGANIZATION_ERROR);

/**
 * Action for patch an organization
 */
export const patchOrganization = createAction(types.PATCH_ORGANIZATION);
export const resetAlreadyExist = createAction(types.RESET_ALREADY_EXIST);
export const patchOrganizationSuccess = createAction(types.PATCH_ORGANIZATION_SUCCESS);
export const patchOrganizationError = createAction(types.PATCH_ORGANIZATION_ERROR);

/**
 * Action for get organizations list
 */
export const getOrganizations = createAction(types.GET_ORGANIZATIONS);
export const getOrganizationsSuccess = createAction(types.GET_ORGANIZATIONS_SUCCESS);
export const getOrganizationsError = createAction(types.GET_ORGANIZATIONS_ERROR);

/**
 * Action for get all organizations list
 */
export const getAllOrganizationsList = createAction(types.GET_ALL_ORGANIZATIONS_LIST);
export const getAllOrganizationsListSuccess = createAction(types.GET_ALL_ORGANIZATIONS_LIST_SUCCESS);
export const getAllOrganizationsListError = createAction(types.GET_ALL_ORGANIZATIONS_LIST_ERROR);

export const getMergeList = createAction(types.GET_MERGE_LIST);
export const getMergeListSuccess = createAction(types.GET_MERGE_LIST_SUCCESS);

export const mergeOrganization = createAction(types.MERGE_ORGANIZATION);

export const mergeOrganizationName = createAction(types.MERGE_ORGANIZATION_NAME);

export const deleteOrganization = createAction(types.DELETE_ORGANIZATION);

export const postExponents = createAction(types.POST_EXPONENTS);
