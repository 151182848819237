import React, { useMemo } from 'react';
import { Row, Col } from 'antd';
import { arrayOf, bool, func, object, string } from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import { useFormatMessage } from 'react-intl-hooks'
import { IconLives, IconVideo, IconPhone, IconMapPin, Popover, PopoverTrigger, PopoverContent } from '@seekube-tech/ui-kit';
import { filter } from 'lodash';
import { SLOT_TYPE } from '@/utils/constants'
import Icon from '@/components/Icon';
import Avatar from '@/components/Avatar';
import { EVENT_MEDIUM } from '../../const';
import TimeslotDetails from './TimeslotDetails';
import styles from './styles.less';
import { isSlotBooked, isSlotPassed } from '@/features/agenda/recruiter/helpers';
import {
  TimeslotDeletionCheckbox
} from '@/features/agenda/recruiter/components/BigCalendar/BigCalendarEvents/Timeslot/TimeslotDeletionCheckbox';

function Timeslot({
  event,
  currentEvent,
  jobfairsColor,
  jobfairs,
  isSettingsVisible,
  timezone, handleDeleteEvent,
  handleOnInformalDescriptionChange,
  informalDescription,
  isDeleteModeActive,
  slotSelectedForDelete = [],
  setSlotSelectedForDelete
}) {
  const t = useFormatMessage();
  const [visible, setVisible] = React.useState(false);
  const isAppointmentExist = Boolean(event._appointment);
  const isTypeConference = event.type === 'conference';
  const isAvatarVisible = isSettingsVisible === false && isAppointmentExist;
  const newStart = moment.tz(event.start, timezone);
  const newEnd = moment.tz(event.end, timezone);

  const badgesColor = useMemo(() => {
    if (event._event && isAppointmentExist === false) {
      return [jobfairsColor[event._event]];
    }

    return (jobfairs || [])
      .filter((jobfair) =>
        // if _event is null, the timeslot is reserved to all virtual event. We need to omit the physical ones from the frontend side
        (
          jobfair.format && jobfair.format !== "physical" &&
          newStart.isBetween(jobfair.start, jobfair.end)
        )
      )
      .map((jobfair) => jobfairsColor[jobfair._event]);
  }, [jobfairsColor, event, isAppointmentExist, jobfairs]);

  /**
   * Return a color for appointment
   * @return {Object}
   */
  const appointmentColor = useMemo(() => {
    if (isAppointmentExist && event._appointment) {
      return jobfairsColor[event._appointment._event.id];
    }

    if (event._event) {
      return jobfairsColor[event._event];
    }

    return null;
  }, [jobfairsColor, event, isAppointmentExist]);

  /**
   * Return time of slot
   * @return {string}
   */
  const time = useMemo(() => {
    switch(event.type) {
      case SLOT_TYPE.INTERVIEW:
        return `${t({ id: 'interview.pl'}, { count: 0 })}`
      case SLOT_TYPE.INFORMAL1TO1:
        return `${t({ id: 'chat.oneToOne.pl'}, { count: 0 })}`
      case SLOT_TYPE.CONFERENCE:
        return `${t({ id: 'live.pl'}, { count: 0 })}`
      default:
        return null;
    }

  }, [timezone, newStart, newEnd]);

  /**
   * Return true when slot is disabled (slot is passed and/or slot is saved when panel is visible)
   * @returns {Boolean}
   */
  const isDisabled = useMemo(() => moment().isAfter(newEnd) || (isSettingsVisible && event.isSaved),
    [newEnd, isSettingsVisible, event]);

  /* Function */

  /**
   * Return an icon by medium type
   * @returns {JSX}
   */
  const getIcon = () => {
    if (isTypeConference) {
      return <IconLives size={12} />
    }
    switch (event.medium) {
      case EVENT_MEDIUM.PHONE:
        return <IconPhone size={12} />;
      case EVENT_MEDIUM.VISIO:
        return <IconVideo size={12} />;
      case EVENT_MEDIUM.PHYSICAL:
        return <IconMapPin size={12} />;
      default:
        return <IconPhone size={12} />;
    }
  };

  const addSlotToSelection = () => {
    if (isSlotBooked(event) || isSlotPassed(currentEvent, event)) return;

    let newSlotsSelections;
    const shouldAddSelection = !slotSelectedForDelete.includes(event._id);

    if (shouldAddSelection) {
      newSlotsSelections = [...slotSelectedForDelete, event._id];
    } else {
      newSlotsSelections = filter(slotSelectedForDelete, (slot) => slot !== event._id);
    }

    setSlotSelectedForDelete(newSlotsSelections);
  }

  return (
    <Popover onOpenChange={(e) => !isDeleteModeActive && setVisible(e)} open={visible}>
      <PopoverTrigger>
        <div
          role="button"
          tabIndex={0}
          className={classnames(
            styles.root,
            { [styles.appointment]: isAppointmentExist || isTypeConference, [styles.expired]: isDisabled },
          )}
          style={((isAppointmentExist || isTypeConference) && appointmentColor) ? {
            borderColor: visible ? appointmentColor?.main : null,
            backgroundColor: appointmentColor?.light
          } : {}}
          onClick={() => isDeleteModeActive && addSlotToSelection()}
        >
          <Row type="flex" gutter={2} align="middle" style={{ flexFlow: 'noWrap' }}>
            {isAvatarVisible && (
              <Col className="mr-3">
                <Avatar
                  className={styles.avatar}
                  user={event._appointment?._user}
                  size={20}
                  src={event._appointment?._user?.pictureUrl}
                />
              </Col>
            )}

            <Col>
              <Row type="flex" gutter={4} align="start"  style={{ flexFlow: 'noWrap' }}>
                <Col className={!isAppointmentExist ? 'text-neutral-300': 'text-neutral-500'}>
                  {getIcon()}
                </Col>

                <Col>
                <span className={styles.time}>
                  <span>{time}</span>
                </span>
                  {isTypeConference && <span className={styles.time} style={{ color: appointmentColor?.main }}> - {event._conference.title}</span>}
                </Col>
              </Row>

              {isAppointmentExist && (
                <span
                  style={((isAppointmentExist || isTypeConference) && appointmentColor) ? { color: appointmentColor?.main } : {}}
                  className={styles.fullname}
                >
                {event._appointment?._user?.fullName}
              </span>
              )}
            </Col>
          </Row>

          {isAppointmentExist === false && isDisabled === false && !isTypeConference && !isDeleteModeActive && (
            <button className={styles.trashButton} onClick={() => handleDeleteEvent(event)}>
              <Icon name="trash" />
            </button>
          )}

          {isDeleteModeActive &&
            <TimeslotDeletionCheckbox
              event={event}
              currentEvent={currentEvent}
              isChecked={slotSelectedForDelete.includes(event._id)}
            />}

          {isAppointmentExist === false && !isTypeConference && (
            <Row className={styles.badgeContainer} type="flex" gutter={4} align="middle">
              {badgesColor.map((color, i) => (
                <Col key={i}>
                  <div className={styles.badge} style={{ backgroundColor: color ? color.main : '#FF7A00' }} />
                </Col>
              ))}
            </Row>
          )}
        </div>
      </PopoverTrigger>
      <PopoverContent zIndex={11}>
        <TimeslotDetails
          event={event}
          currentEvent={currentEvent}
          jobfairs={jobfairs}
          appointmentColor={appointmentColor && appointmentColor?.main}
          timezone={timezone}
          closePopover={() => setVisible(false)}
          handleOnInformalDescriptionChange={handleOnInformalDescriptionChange}
          informalDescription={informalDescription}
        />
      </PopoverContent>
    </Popover>
  );
}

Timeslot.propTypes = {
  event: object,
  currentEvent: object,
  jobfairsColor: object,
  isSettingsVisible: bool,
  jobfairs: arrayOf(object),
  timezone: string,
  handleDeleteEvent: func,
  handleOnInformalDescriptionChange: func,
  informalDescription: string,
};

export default Timeslot;
