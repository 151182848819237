import { useMutation, useQueryClient } from 'react-query';
import request from '@/utils/request';

export function useCreateTimeslots({ userId, eventId, onSuccess = () => {}, }) {
  const queryClient = useQueryClient();

  const mutationFn = ({ timeslots }) => {
    const requestUrl = `${process.env.FRONT_API_URL}/users/${userId}/timeslots`;

    return (
      request(requestUrl, {
        method: 'POST',
        body: JSON.stringify(timeslots),
      })
    )
  }

  return useMutation({
    mutationFn,
    onSuccess: async (timeslots) => {
      queryClient.setQueryData(['timeslots', userId, eventId], (old) => ([...timeslots, ...old]));
      onSuccess();
    },
    refetchOnWindowFocus: false,
  });
}
