import React from 'react';
import { func, shape, any, object, number } from 'prop-types';
import { H5 } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { find } from 'lodash';
import { Bloc } from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/components/Bloc';
import {
  UserDescription
} from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/Profile/Cv/UserDescriptions';
import {
  UserTitle
} from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/Profile/Cv/UserTitle';
import {
  UserResumeUrl
} from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/Profile/Cv/UserResumeUrl';
import { UserCv } from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/Profile/Cv/UserCv';
import {
  ExternalMedia
} from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/Profile/Cv/ExternalMedia';


const Cv = ({ onClick, ...props }) => {
  const { user, form, event } = props;
  const isBlocEnabled = find(event.participants.onboarding, (e) => e.name === 'cv').enabled;
  const cvIsRequired = !event || event.participants.cvIsRequired !== false;

  const t = useFormatMessage();

  return (
    isBlocEnabled && (
      <Bloc>
        <div className="commonStep">
          <H5 className="mb-24">
            <span>
              {!user?.resumeUrl ?
                t({ id: 'event.candidate.preparation.signup.step.resume' })
                : t({ id: 'event.candidate.preparation.signup.userInfos.title.verify' })}
            </span>
          </H5>

          <UserCv
            form={form}
            user={user}
            cvIsRequired={cvIsRequired}
          />

          <UserResumeUrl form={form} cvIsRequired={cvIsRequired} user={user} />

          <div className="fieldsContainer mb-40">
            <UserTitle form={form} user={user} />
            <UserDescription form={form} user={user} />
          </div>

          <ExternalMedia form={form} user={user} />
        </div>
      </Bloc>
    )
  );
}

Cv.propTypes = {
  form: shape({
    getFieldDecorator: func,
    getFieldsError: func,
    getFieldError: func,
    isFieldTouched: func,
  }),
  onCvOk: func,
  onClick: func,
  handleDataUpdate: func,
  currentStep: any,
  user: object,
  intl: object,
  event: object,
  stepPosition: number,
};

export default Cv;
