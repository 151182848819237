import React, { useState } from 'react';
import { number, string, node, bool, func } from 'prop-types';
import classnames from 'classnames';
import Icon from '@/components/Icon';
import styles from './styles.less';


const MenuItem = ({ index, name, title, content, isOpen: propIsOpen, onClick }) => {
  const [isOpen, setIsOpen] = useState(propIsOpen);



  const toggleMenuItem = () => {
    setIsOpen(!isOpen);
    onClick(name, index, !isOpen);
  };



  const isOpenClass = classnames(styles.menuItem, isOpen ? styles.open : null);

  return (
    <div className={isOpenClass}>
      <a role="button" tabIndex={0} onClick={toggleMenuItem}>
        <Icon name="menu-burger" className={styles.menuIcon} /> {title}
        <Icon name={isOpen ? 'arrow-up' : 'arrow-down'} className={styles.rowIcon} />
      </a>
      <div>{content}</div>
    </div>
  );
}

MenuItem.propTypes = {
  index: number.isRequired,
  name: string.isRequired,
  title: string.isRequired,
  content: node.isRequired,
  isOpen: bool,
  onClick: func,
};

export default MenuItem;
