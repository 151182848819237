import {useQuery} from 'react-query';
import {groupBy} from 'lodash';
import request from '@/utils/request';

export function useGetReport({eventId}) {
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/reports`;

  const queryFn = () => request(requestUrl, {
    method: 'GET',

  });

  return useQuery({
    queryKey: ['reports'],
    queryFn,
    initialData: {},
    select: (data) => groupBy(data, 'phase'),
    refetchOnWindowFocus: false,
  });
}
