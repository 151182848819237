import { isArray, isEmpty } from 'lodash';
import { array, func, object, string, boolean } from 'prop-types';
import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { Form } from 'antd';
import styles from '@/scenes/Event/components/StaticOffers/components/Filters/styles.less';
import Selector from '@/components/Form/Selector';
import { sortByOccurence } from '@/scenes/Event/scenes/Candidate/scenes/JobDating/components/SearchEngine/helpers';

const FormItem = Form.Item;

export const OrganizationStatusSelector = ({
  isMobile,
  facetKey,
  updateSearch,
  search,
  facetsExponents,
  context,
}) => {
  const t = useFormatMessage();
  const options = [
    { value: 'published', label: t({ id: 'organization.status.published' }), badgeCount: getFacetStatus('published') },
    { value: 'draft', label: t({ id: 'organization.status.draft' }), badgeCount: getFacetStatus('draft') },
  ];

  let status = '';

  if (!isEmpty(search?.status)) {
    if (isArray(search.status)) {
      status = search.status;
    } else {
      status = search.status.split(',');
    }
  }

  return (
    <FormItem className={styles.formItem} style={{ minWidth: '140px', width: '140px' }}>
      <Selector
        position="center"
        isMobile={!!isMobile}
        label={t({ id: 'status' })}
        noLabel={t({ id: 'searchEngine.noLabel' })}
        options={sortByOccurence(options)}
        values={status}
        onChange={(e) => {
          updateSearch('status', e, facetKey, context);
        }}
      />
    </FormItem>
  );

  function getFacetStatus(status) {
    const facetsOrganizationStatus = facetsExponents?.['organizationProfile.status'];

    return (facetsOrganizationStatus &&
      typeof facetsOrganizationStatus === 'object') ? facetsOrganizationStatus[status] : 0;
  }
}

OrganizationStatusSelector.propTypes = {
  isMobile: boolean,
  facetKey: string,
  updateSearch: func,
  search: object,
  facetsExponents: object,
  context: object,
}
