import moment from 'moment';

function isKeyDateAPeriod({ beginAt, endAt, formatComparison = 'LLL' }) {
  const beginAtFormatted = moment(beginAt).format(formatComparison);
  const endAtFormatted  = moment(endAt).format(formatComparison);

  return !(beginAtFormatted === endAtFormatted)
}


export { isKeyDateAPeriod }