import React from 'react';
import classnames from 'classnames';
import LinesEllipsis from 'react-lines-ellipsis';
import { get, head, isEmpty, size } from 'lodash';
import { bool, number, object, string } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { DISTINCT_INTERACTION, getInteractionStatus } from '@/utils/global';
import { getUtcFormat } from '@/utils/format';
import styles from '@/components/InteractionCard/styles.less';
import Icon from '@/components/Icon';
import Html from '@/components/Html';
import { getApiUrl } from '@/utils/url';

const Content = ({ interaction, hasAppointmentBeenModified, participant, intl, eventLocation }) => {
  const t = useFormatMessage();
  const { _appointment } = interaction;

  return (
    <div className={classnames(styles.appointmentData,
      _appointment ? styles[_appointment.status] : '')}>
      <LinesEllipsis
        maxLine="2"
        ellipsis="..."
        trimRight
        basedOn="letters"
        text={_appointment?.status === 'pending' && hasAppointmentBeenModified ?
          t({ id: 'event.candidate.appointments.card.interview.modified' })
          :
          <AppointmentContent
            eventLocation={eventLocation}
            intl={intl}
            interaction={interaction}
            timezone={getUtcFormat(
              head(get(participant, '_user.timezone.utc')))}
            offers={size(interaction._offers)}
          />}
      />
    </div>
  )
}

const AppointmentContent = ({ interaction, countOffers, timezone, intl, eventLocation }) => {
  const t = useFormatMessage();
  const { _appointment } = interaction;

  const { moment } = window;
  const now = moment().subtract(2, 'hour');
  const date = !isEmpty(_appointment) && _appointment.date ? moment(_appointment.date) : null;
  const isDone = date !== null && date < now;

  let appointmentStr = '';
  let visioStr = null;

  if (interaction.type === 'informal1to1') {
    if (!_appointment._event.skipAppointmentValidation && _appointment.status === 'pending' && !_appointment.acceptedAt) {
      appointmentStr = t({ id: 'event.candidate.appointments.card.status.pending' }, { count: countOffers });
    }
  }

  if (isEmpty(_appointment)) {
    appointmentStr = t({ id: 'event.candidate.appointments.card.status.pending' }, { count: countOffers });

    if (interaction.messageRefused && interaction.messageRefused.length) {
      appointmentStr = t({ id: 'application.refuse' });
    }
  } else if (_appointment) {
    if (_appointment.status === 'refused') {
      appointmentStr = t({ id: 'event.candidate.appointments.card.status.refused' });
      if (_appointment.refusedBy === 'candidate') {
        appointmentStr = t({ id: 'event.candidate.appointments.card.proposition.refused.by.candidate' });
      }
      if (_appointment.refusedBy === 'recruiter') {
        appointmentStr = t({ id: 'event.candidate.appointments.card.interview.canceled.by.recruiter' });
      }
    } else if (_appointment.status === 'canceled') {
      appointmentStr = _appointment.message || '';
      if (_appointment.canceledBy === 'candidate') {
        appointmentStr = t({ id: 'event.candidate.appointments.card.interview.canceled.by.candidate' });
      }
    } else if (date) {
      appointmentStr = (
        <Medium
          timezone={timezone}
          date={date}
          medium={_appointment.settings.medium}
          location={eventLocation}
          isDone={isDone}
          intl={intl}
        />
      );
    }
  } else {
    appointmentStr = t({ id: 'event.candidate.appointments.card.status.refusedByRecruiter' });
  }

  // TODO: Faire tous les status avec cette fonction
  switch (getInteractionStatus(interaction)) {
    case (DISTINCT_INTERACTION.candidate.interviewCanceled): appointmentStr = t(
      { id: 'event.candidate.appointments.card.interview.canceled.by.candidate' }); break;
    default: break;
  }

  if (_appointment?.status === 'accepted' && _appointment.settings.medium === 'visio') {
    visioStr = (
      <a href={getApiUrl(_appointment.candidateVisioUrl)} target="_blank" role="button" tabIndex={0}>
        <Icon name="camera" className={classnames(styles.iconVisio, isDone ? styles.marginLeft : '')} /> {t({ id: 'appointment.joinVisio' })}
      </a>
    );
  }

  return (<>{appointmentStr}{visioStr}</>);
};

const Medium = ({ medium, location, date, timezone, isDone, intl }) => {
  const t = useFormatMessage();

  if (medium === 'physical') {
    return (
      <div>
        <div><Html
          value={intl.formatMessage({ id: 'event.candidate.appointments.card.status.acceptedText[1]' }, {
            date: date.format('LL'),
            time: date.format('LT'),
            medium: t({ id: `appointment.medium.${medium}` }),
          })}
        /></div>
        <div>{t({ id: 'event.recruiter.preparation.timeslots.location.label' })} <strong>{location}</strong></div>
      </div>
    );
  }
  return (
    <Html
      value={intl.formatMessage({
        id: isDone ?
          'event.candidate.appointments.card.status.doneText.withTimezone'
          : 'event.candidate.appointments.card.status.acceptedText.withTimezone[1]'
      },
        {
          date: date.format('D MMMM'),
          time: date.format('LT'),
          timezone,
          medium: t({ id: `appointment.medium.${medium}` }),

        })}
    />);
};

Medium.propTypes = {
  timezone: string,
  medium: string,
  location: string,
  date: object,
  isDone: bool,
  intl: object,
};

Content.propTypes = {
  interaction: object,
  participant: object,
  hasAppointmentBeenModified: bool,
  intl: object,
  eventLocation: string,
};

AppointmentContent.propTypes = {
  interaction: object,
  intl: object,
  countOffers: number,
  timezone: string,
  eventLocation: string
};

export { Content }
