import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { object } from 'prop-types';
import { FormattedHTMLMessage } from 'react-intl';

import { H3 } from '@/components/Title';
import P from '@/components/P';

function ModalConfirmation({ offer }) {
  /* Vars */

  const t = useFormatMessage();

  /* Functions */

  /**
   * Return message
   * @returns {string}
   */
  const getMessage = () => {
    if (offer?._user?.firstName !== '') {
      return t({ id: 'event.candidate.jobdating.jobs.applyConfirmation.title' }, { name: offer._user.firstName });
    }

    return t({ id: 'event.candidate.jobdating.jobs.applyConfirmation.titleAlt' });
  };

  /* Render */

  return (
    <div className="confirmationModal">
      <div className="confirmation-body">
        <div className="confirmation-content">
          <H3 bold>
            <span role="img" aria-label="apply-success">🎉</span> {getMessage()}
          </H3>

          <P>
            <FormattedHTMLMessage
              id="event.candidate.jobdating.jobs.applyConfirmation.text"
              values={{ name: offer.title }}
            />
          </P>
        </div>
      </div>
    </div>
  );
}

ModalConfirmation.propTypes = {
  offer: object,
};

ModalConfirmation.defaultProps = {
  offer: {},
};

export default ModalConfirmation;
