export const SET_CURRENT_INTERACTION = 'app/store/interaction/SET_CURRENT_INTERACTION';
export const GET_INTERACTION = 'app/store/interactions/GET_INTERACTION';

// GET all interactions
export const GET_INTERACTIONS = 'app/store/interactions/GET_INTERACTIONS';
export const GET_INTERACTIONS_SUCCESS = 'app/store/interactions/GET_INTERACTIONS_SUCCESS';
export const GET_INTERACTIONS_ERROR = 'app/store/interactions/GET_INTERACTIONS_ERROR';


// PATCH a interaction
export const PATCH_INTERACTION = 'app/store/interaction/PATCH_INTERACTION';
export const PATCH_INTERACTION_SUCCESS = 'app/store/interaction/PATCH_INTERACTION_SUCCESS';
export const PATCH_INTERACTION_ERROR = 'app/store/interaction/PATCH_INTERACTION_ERROR';

// POST a interaction
export const POST_INTERACTION = 'app/store/interaction/POST_INTERACTION';
export const POST_INTERACTION_SUCCESS = 'app/store/interaction/POST_INTERACTION_SUCCESS';
export const POST_INTERACTION_ERROR = 'app/store/interaction/POST_INTERACTION_ERROR';

// INTERACT with a interaction
export const SELECT_INTERACTION = 'app/store/interaction/SELECT_INTERACTION';
export const SELECT_INTERACTION_SUCCESS = 'app/store/interaction/SELECT_INTERACTION_SUCCESS';
export const SELECT_INTERACTION_ERROR = 'app/store/interaction/SELECT_INTERACTION_ERROR';

export const SELECT_INTERACTIONS = 'app/store/interaction/SELECT_INTERACTIONS';
export const SELECT_INTERACTIONS_SUCCESS = 'app/store/interaction/SELECT_INTERACTIONS_SUCCESS';
export const SELECT_INTERACTIONS_ERROR = 'app/store/interaction/SELECT_INTERACTIONS_ERROR';

export const CHANGE_INTERACTION_OWNER = 'app/store/CHANGE_INTERACTION_OWNER';

