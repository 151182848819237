/* eslint-disable arrow-body-style */
import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { bool, object } from 'prop-types';
import { percentage } from '@/utils/math';
import { If } from '@/components/If';
import { Stats, WithPercentage } from '@/components/Stats';

import commonMessages from '@/messages';
import messages from './messages';
import styles from './styles.less';


const getPageStats = (stats, goals = {}) => ({
  // jobdating - RDVS VALIDES
  totalAccepted: {
    title: <FormattedMessage {...messages.jobdatingInterviewTitle} />,
    icon: 'iconAccept',
    count: stats.totalAccepted,
    additionalText: <If condition={goals.appointmentsValidated}><WithPercentage tooltip={<FormattedMessage {...messages.jobdatingInterviewPercentageTooltip} />} value={percentage(stats.totalAccepted, goals.appointmentsValidated, 0, true)} /></If>,
  },
  // candidats - RDVs valides
  candidateAccepted: {
    title: <FormattedMessage {...messages.candidateInterviewTitle} />,
    tooltip: <FormattedMessage {...messages.candidateInterviewTooltip} />,
    icon: 'star',
    noSeparator: true,
    count: stats.candidateAccepted,
    additionalText: <WithPercentage tooltip={<FormattedMessage {...messages.candidateInterviewPercentageTooltip} />} value={percentage(stats.candidateAccepted, stats.countParticipants, 0, true)} />,
  },
  candidateCoreTarget: {
    title: <FormattedMessage id="event.owner.statistics.stats.candidate.candidateCoreTarget.title" />,
    tooltip: <FormattedMessage id="event.owner.statistics.stats.candidate.candidateCoreTarget.count-tooltip" />,
    count: stats.candidateCoreTarget,
    additionalText: (
      <If condition={stats.candidateAccepted}>
        <WithPercentage
          tooltip={<FormattedMessage id="event.owner.statistics.stats.candidate.candidateCoreTarget.percentage-tooltip" />}
          value={percentage(stats.candidateCoreTarget, stats.candidateAccepted, 0, true)}
        />
      </If>
    ),
  },
  averageParticipantsAppointments: {
    title: <FormattedMessage {...messages.interviewAverageTitle} />,
    tooltip: <FormattedMessage {...messages.candidateInterviewAverage} />,
    icon: 'star',
    count: percentage(stats.totalAccepted, stats.candidateAccepted * 100, 1),
  },
  // entreprises - RDVs validés
  organizationAccepted: {
    title: <FormattedMessage {...messages.organizationInterviewTitle} />,
    tooltip: <FormattedMessage {...messages.organizationInterviewTooltip} />,
    noSeparator: true,
    count: stats.organizationAccepted,
    additionalText: <WithPercentage tooltip={<FormattedMessage {...messages.organizationInterviewPercentageTooltip} />} value={percentage(stats.countExponentsWithAppointments, stats.countExponents, 0, true)} />,
  },
  averageOrganizationsAppointments: {
    title: <FormattedMessage {...messages.interviewAverageTitle} />,
    tooltip: <FormattedMessage {...messages.interviewAverageTooltip} />,
    noSeparator: true,
    count: percentage(stats.totalAccepted, stats.organizationAccepted * 100, 1),
  },
  countOffers: { // GOOD
    title: <FormattedMessage {...commonMessages.offers} values={{ count: 2 }} />,
    icon: 'star',
    count: stats.countOffers,
  },
});

const StatisticsStats = memo(({ stats = {}, goals = {}, enableCoreTarget = false }) => {
  /* Vars */

  const pageStats = getPageStats(stats, goals);

  /* Functions */

  const getJobdatingStats = () => {
    return [pageStats.totalAccepted];
  }

  const getCandidatesStats = () => {
    const list = [pageStats.candidateAccepted];

    if (enableCoreTarget) {
      list.push(pageStats.candidateCoreTarget);
    }

    list.push(pageStats.averageParticipantsAppointments);

    return list;
  }

  const getBusinessStats = () => {
    return [pageStats.organizationAccepted, pageStats.averageOrganizationsAppointments, pageStats.countOffers];
  }

  /* Render */

  return (
    <div className={styles.statisticsStats}>
      <div>
        <FormattedMessage {...messages.jobdatingTitle} tagName="h2" />
        <Stats list={getJobdatingStats()} />
      </div>

      <div>
        <FormattedMessage {...messages.candidateTitle} tagName="h2" />
        <Stats list={getCandidatesStats()} />
      </div>

      <div>
        <FormattedMessage {...messages.organizationTitle} tagName="h2" />
        <Stats list={getBusinessStats()} />
      </div>
    </div>
  );
});

StatisticsStats.propTypes = {
  stats: object,
  goals: object,
  enableCoreTarget: bool,
};

export { StatisticsStats };
