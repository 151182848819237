import React from 'react';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { object } from 'prop-types';
import { useSelector } from 'react-redux';
import { getEventKeyMoments } from '@/helpers/event/getEventKeyMoments';
import { CANDIDATE_JD_VIEWS, EVENT_FORMAT } from '@/utils/constants';
import { getLastPathInUrl } from '@/utils/math';
import {
  ApplicationsDisabled,
  HybridApplicationsAreClosed,
  HybridClosingDatesInfos,
  HybridInteractionsAreClosed,
  HybridOnlyOneInteractionDateIsClosed,
  InteractionsAvailable,
  InteractionsDisabled,
} from '@/scenes/Event/scenes/Candidate/scenes/JobDating/containers/SceneAlerts/components';
import { getCurrentParticipant } from '@/store/participant/selectors';
import { isParticipatingToAllHybridFormats } from '@/helpers/participant/isParticipatingToAllHybridFormats';


/*
 getDateToDisplay

* Here defaultDate is initialize to null rather than undefined because :
*   moment(undefined).toDate() return {now}
*   moment(null).toDate() return 'invalid Date'
*
* So moment(undefined).isBefore(moment()) return true
*
* And this we don't wanna this when we compare closing date
* By default if there is no closing date there is no restriction
*
* */
const getDateToDisplay = (firstDate, secondDate, defaultDate = null) => {
  const date1 = firstDate || defaultDate;
  const date2 = secondDate || defaultDate;
  const date1IsEmpty = isEmpty(date1);
  const date2IsEmpty = isEmpty(date2);

  if (date1IsEmpty && date2IsEmpty) return null;

  if (!date1IsEmpty && !date2IsEmpty) {
    return moment(date1).isBefore(date2) ? date1 : date2
  }

  return date1IsEmpty ? date2 : date1;
}

/*
 getDateInfo

 * Give a default value if date is not defined and return isDefined
 * this is useful when a candidate has only one participation
 * */
const getDateInfo = (keyMoment, defaultDate) => ({
    endAt: keyMoment?.endAt || defaultDate?.endAt,
    isDefined: !isEmpty(keyMoment?.endAt)
  }
)

const getHybridDateToDisplay = (event) => {
  const now = moment();
  const jobfairPhysical =  getEventKeyMoments(event, { type: 'jobfair',  format: 'physical' }) ;
  const jobfairVirtual =  getEventKeyMoments(event, { type: 'jobfair',  format: 'virtual' });
  const interactionClosingPhysical = getDateInfo(getEventKeyMoments(event, { type: 'interactionsClosing',  format: 'physical' }), jobfairPhysical);
  const interactionClosingVirtual =  getDateInfo(getEventKeyMoments(event, { type: 'interactionsClosing',  format: 'virtual' }), jobfairVirtual);
  const applicationsClosingPhysical = getDateInfo(getEventKeyMoments(event, { type: 'applicationsClosing',  format: 'physical' }), jobfairPhysical);
  const applicationsClosingVirtual = getDateInfo(getEventKeyMoments(event, { type: 'applicationsClosing',  format: 'virtual' }), jobfairVirtual );

  const datePhysicalToDisplay = getDateToDisplay(interactionClosingPhysical?.endAt, applicationsClosingPhysical?.endAt);
  const dateVirtualToDisplay = getDateToDisplay(interactionClosingVirtual?.endAt, applicationsClosingVirtual?.endAt);

  if (isEmpty(datePhysicalToDisplay) && isEmpty(dateVirtualToDisplay)) {
    return undefined;
  }

  return {
    physical: {
      applicationsClosing: {
        isPast: moment(applicationsClosingPhysical?.endAt).isBefore(now),
        isDefined: applicationsClosingPhysical?.isDefined,
      },
      interactionClosing: {
        isPast: moment(interactionClosingPhysical.endAt).isBefore(now),
        isDefined: interactionClosingPhysical.isDefined,
      },
      date: moment(datePhysicalToDisplay),
      isPast: moment(datePhysicalToDisplay).isBefore(now),
    },
    virtual: {
      date: moment(dateVirtualToDisplay),
      isPast: moment(dateVirtualToDisplay).isBefore(now),
      applicationsClosing: {
        isPast: moment(applicationsClosingVirtual?.endAt).isBefore(now),
        isDefined: applicationsClosingVirtual?.isDefined,
      },
      interactionClosing: {
        isPast: moment(interactionClosingVirtual?.endAt).isBefore(now),
        isDefined: interactionClosingVirtual?.isDefined,
      },
    },
    bothPhysicalVirtual: {
      applicationsClosing: {
        isPast: moment(applicationsClosingPhysical?.endAt).isBefore(now) && moment(applicationsClosingVirtual?.endAt).isBefore(now)
      },
      interactionClosing: {
        isPast: moment(interactionClosingVirtual?.endAt).isBefore(now) && moment(interactionClosingVirtual?.endAt).isBefore(now)
      }
    }
  }
}

const DateClosingCardInfos = ({ event, match, authUser, ignoreView = false }) => {
  const participant = useSelector(getCurrentParticipant)?.toJS();
  const { format, phases, keyDates } = event;
  const now = moment();

  const { canCandidateApplyToOffers, canCandidateInteractWithRecruiter } = phases;

  const keydatesUrl = `${window.location.origin}/${match.params.eventSlug}/candidate/keydates`;
  const isInteractionsClosingDefined = !isEmpty(keyDates?.interactionsClosing?.beginAt)
  const isApplicationsClosingDefined = !isEmpty(keyDates?.applicationsClosing?.beginAt)
  const dateInteractionAvailable = moment(getDateToDisplay(keyDates?.applicationsClosing?.beginAt, keyDates?.interactionsClosing?.beginAt))
  const dateInteractionAvailableIsPast = dateInteractionAvailable.isBefore(now)
  const href = event.keyDatesTimelineIsHidden ? null : keydatesUrl;

  const hybridDateToDisplay = getHybridDateToDisplay(event);
  const currentView = getLastPathInUrl(window.location.pathname);

  if ([EVENT_FORMAT.VIRTUAL, EVENT_FORMAT.PHYSICAL].includes(format) ) {
    // SI une date de fermeture des plannings est paramétrée et à partir du moment où elle est dépassée
    if (isInteractionsClosingDefined && !canCandidateInteractWithRecruiter &&
      [CANDIDATE_JD_VIEWS.appointments].includes(currentView)) {
      return <InteractionsDisabled href={href} authUser={authUser} currentView={currentView} />
    }

    // Si une date de fermeture des candidatures ET/ OU une date de fermeture des plannings est paramétrée  et que la date au plus tôt est passée
    if ((isApplicationsClosingDefined  || isInteractionsClosingDefined) && dateInteractionAvailableIsPast &&
      ([CANDIDATE_JD_VIEWS.jobs, CANDIDATE_JD_VIEWS.informal1to1].includes(currentView) || ignoreView)) {
      return <ApplicationsDisabled href={href} authUser={authUser} currentView={currentView} />
    }

    // Si une date de fermeture des candidatures ET/ OU une date de fermeture des plannings est paramétrée et la date la plus tôt n'est pas dépassé
    if ((isApplicationsClosingDefined || isInteractionsClosingDefined) && (canCandidateApplyToOffers || canCandidateInteractWithRecruiter) &&
      ([CANDIDATE_JD_VIEWS.jobs, CANDIDATE_JD_VIEWS.informal1to1].includes(currentView) || ignoreView)) {
      return <InteractionsAvailable href={href} authUser={authUser} currentView={currentView} dateInteractionAvailable={dateInteractionAvailable} />
    }
  }


  if ([EVENT_FORMAT.HYBRID].includes(format) && hybridDateToDisplay) {
    const isCandidateParticipatingToAllHybridFormats = isParticipatingToAllHybridFormats(participant.keyMomentFormats);
    const onlyOneInteractionClosingIsPast =
      (!hybridDateToDisplay.virtual.interactionClosing.isPast && hybridDateToDisplay.physical.interactionClosing.isPast) ||
      (hybridDateToDisplay.virtual.interactionClosing.isPast && !hybridDateToDisplay.physical.interactionClosing.isPast);
    const bothInteractionClosingArePast =  hybridDateToDisplay.physical.interactionClosing.isPast && hybridDateToDisplay.virtual.interactionClosing.isPast;
    const atLeastOneClosingDateToDisplayIsUpcomping =  !hybridDateToDisplay.physical.isPast || !hybridDateToDisplay.virtual.isPast

    if (isCandidateParticipatingToAllHybridFormats) {
      if (atLeastOneClosingDateToDisplayIsUpcomping &&
        ([CANDIDATE_JD_VIEWS.jobs, CANDIDATE_JD_VIEWS.informal1to1].includes(currentView) || ignoreView)) {
        return <HybridClosingDatesInfos hybridDateToDisplay={hybridDateToDisplay} authUser={authUser} href={href} currentView={currentView} /> // bandeau 1
      }

      if ((hybridDateToDisplay.physical.isPast || hybridDateToDisplay.virtual.isPast) &&
        ([CANDIDATE_JD_VIEWS.jobs, CANDIDATE_JD_VIEWS.informal1to1].includes(currentView) || ignoreView)) {
        return <HybridApplicationsAreClosed href={href} authUser={authUser} currentView={currentView} /> // bandeau 3
      }

      if (bothInteractionClosingArePast && [CANDIDATE_JD_VIEWS.appointments].includes(currentView)) {
        return <HybridInteractionsAreClosed hybridDateToDisplay={hybridDateToDisplay} href={href} authUser={authUser} currentView={currentView} /> // bandeau 5
      }

      if (onlyOneInteractionClosingIsPast
        && [CANDIDATE_JD_VIEWS.appointments].includes(currentView)) {
        return <HybridOnlyOneInteractionDateIsClosed hybridDateToDisplay={hybridDateToDisplay} href={href} authUser={authUser} /> // bandeau 4
      }
    }

    if (!isCandidateParticipatingToAllHybridFormats) {
      const date = hybridDateToDisplay[participant.keyMomentFormats[0]];

      if (!date.isPast && ( date.interactionClosing.isDefined || date.applicationsClosing.isDefined) &&
        ([CANDIDATE_JD_VIEWS.jobs, CANDIDATE_JD_VIEWS.informal1to1].includes(currentView) || ignoreView)) {
        return <InteractionsAvailable href={href} authUser={authUser} currentView={currentView} dateInteractionAvailable={hybridDateToDisplay[participant.keyMomentFormats[0]].date} />
      }

      if (date.isPast &&
        ([CANDIDATE_JD_VIEWS.jobs, CANDIDATE_JD_VIEWS.informal1to1].includes(currentView) || ignoreView)) {
        return <HybridApplicationsAreClosed href={href} authUser={authUser} currentView={currentView} />
      }

      if (date.interactionClosing.isPast && [CANDIDATE_JD_VIEWS.appointments].includes(currentView)) {
        return <HybridInteractionsAreClosed hybridDateToDisplay={hybridDateToDisplay} href={href} authUser={authUser} currentView={currentView} /> // bandeau 5
      }
    }
  }

  return null;
}

DateClosingCardInfos.propTypes = {
  event: object,
  match: object,
  authUser: object,
}

export { DateClosingCardInfos }
