import React, { useState } from 'react';
import { object, func } from 'prop-types';
import { Form } from 'antd';
import { Button } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import {
  NotifyAgendaSeekube,
  NotifyJDOpening,
  NotifyJDOverview,
  NotifyPreparationOverview } from './components';


function Recruiter ({ authUser, handleSaveUserKey, form }) {
  const t = useFormatMessage();
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    handleSaveUserKey({
      calendarSync: form.getFieldValue('calendarSync'),
      notifs: form.getFieldsValue(),
      callback: () => setLoading(false),
    });
  };

  return (
    <Form onSubmit={handleSubmit} >
      <NotifyAgendaSeekube {...form} {...authUser} />
      <NotifyJDOverview {...form} {...authUser} />
      <NotifyJDOpening {...form} {...authUser} />
      <NotifyPreparationOverview {...form} {...authUser} />

      <Button type="submit" loading={loading}>
        {t({ id: 'save' })}
      </Button>
    </Form>
  );
}

Recruiter.propTypes = {
  authUser: object,
  handleSaveUserKey: func,
  form: object,
};

export default Form.create()(Recruiter);
