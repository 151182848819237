import React from 'react';
import classnames from 'classnames';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';
import { object, string } from 'prop-types';

import logo from '@/assets/images/mainLogo.svg';
import logoColor from '@/assets/images/logo-color.svg';

import { EVENT_STATUS } from '@/utils/constants';
import Spotlight from '@/scenes/Admin/components/Spotlight';
import { If } from '@/components/If';
import { Agenda, Auth, EventInfos, SeekubeLogo } from '../components';
import messages from './messages.js';
import styles from '../../styles.less';

const BasicRecruiterHeader = (props) => {
  const showEvent = location.href.indexOf('candidate/preparation/onboarding') === -1 && props.context !== 'landing';

  return (
    <div className={styles.headerStructure}>
      <div className={styles.leftSide}>
        <SeekubeLogo
          logo={logo}
          logoColor={logoColor}
          context="default"
        />
        {showEvent &&
          <div className={classnames(styles.colEvent, styles.basicRecruiter)}>
            <EventInfos {...props} />
            <div className={styles.colNav}>
              <If condition={location.pathname.includes('/recruiter')}>
                <ClientNav {...props.event} />
              </If>
            </div>
          </div>
        }
      </div>
      <div className={styles.rightSide}>
        <div className={styles.colRight}>
          <div className={styles.colAuth}>
            <Auth {...props} />
          </div>

          {!isEmpty(props.event) &&
          [EVENT_STATUS.OPEN, EVENT_STATUS.IN_PROGRESS, EVENT_STATUS.HUNT_POST_EVENT].includes(props.event.dateStatus) ? <Agenda event={props.event} /> : null}
          <Spotlight className={styles.colAuth} />
        </div>
      </div>
    </div>
  );
};

const ClientNav = ({ slug, modules }) => (
  <div id="clientNav" className={styles.clientNav}>
    <NavLink to={`/${slug}/recruiter/preparation`} activeClassName="itemSelected"><FormattedMessage {...messages.navPreparation} tagName="div" /></NavLink>
    <NavLink to={`/${slug}/recruiter/jobdating`} activeClassName="itemSelected"><FormattedMessage {...messages.navJobdating} tagName="div" /></NavLink>
    <If condition={modules && modules.live && modules.live.recruiterEnable}>
      <NavLink to={`/${slug}/recruiter/conferences/discovery?page=1&archive=false`} activeClassName="itemSelected">
        {modules.live && modules.live.labelMultiple ? (
          <div>{modules.live.labelMultiple}</div>
        ) : (
          <FormattedMessage {...messages.navLive} values={{ count: 2 }} tagName="div" />
        )}
      </NavLink>
    </If>
  </div>
);

ClientNav.propTypes = {
  slug: string,
  modules: object,
};

BasicRecruiterHeader.propTypes = {
  event: object,
  intl: object,
  context: string,
};

export { BasicRecruiterHeader };
