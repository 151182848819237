import React from 'react';
import { array } from 'prop-types';
import { size } from 'lodash';
import Icon from '@/components/Icon';
import { StatBloc } from '@/components/Stats/StatBloc';

import styles from './styles.less';

const Funnel = ({ dataSources = [] }) => {
  const total = size(dataSources);

  return (
    <div className={styles.container}>
      {
        dataSources.map((dataSource, i) => {
          const withSeparator = i + 1 < total;
          return (
            <StatBloc
              key={i}
              {...dataSource}
              title={dataSource.title}
              description={dataSource.description}
              withFunnel
              separator={withSeparator && <Icon name="funnelSeparator" />}
              classContainer={withSeparator && styles.withSeparator}
            />
          );
        })
      }
    </div>
  );
};

Funnel.propTypes = {
  dataSources: array,
};

export { Funnel };
