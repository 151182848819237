import { useQuery } from 'react-query';
import request from '@/utils/request';

export function useGetUserEventsParticipation({ userId, ...props }) {
  const requestUrl = `${process.env.FRONT_API_URL}/users/${userId}/events/participations`;

  const queryFn = () => request(requestUrl, { method: 'GET', });

  const query = useQuery({
    queryKey: ['user', 'hasParticipations', userId],
    queryFn,
    refetchOnWindowFocus: false,
    ...props
  });
  return query;
}
