import React from 'react';
import { func, string, object } from 'prop-types';
import { Form } from 'antd';
import WysiwygEditor from '@/components/WysiwygEditor';

import messages from '../../messages';

const FormItem = Form.Item;

const OfferDescriptionForm = ({ initialValue, handleOnDescriptionChange, showHelper, intl }) => {// eslint-disable-line
  const onDescriptionChange = (value) => handleOnDescriptionChange(value);

  return (
    <FormItem label={intl.formatMessage(messages.descriptionLabel)} required>
      <WysiwygEditor
        initialValue={initialValue}
        required
        showHelper={showHelper}
        onChange={onDescriptionChange}
      />
    </FormItem>
  );
};

OfferDescriptionForm.propTypes = {
  initialValue: string,
  showHelper: func,
  handleOnDescriptionChange: func,
  intl: object,
};

export { OfferDescriptionForm };
