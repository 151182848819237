import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { Form } from 'antd';
const FormItem = Form.Item;

export const UserResumeUrl = ({ form, user, cvIsRequired }) => {
  const t = useFormatMessage();

  return (
    <>
      <FormItem className="cvItem">
        {form.getFieldDecorator('resumeS3Key', {
          initialValue: user?.resumeS3Key || user?.resumeUrl || '',
          rules: [{ required: cvIsRequired, message: t({ id: 'form.resume.mandatory' }) }],
        })(
          <input type="hidden" />
        )}
      </FormItem>
      <FormItem className="cvItem">
        {form.getFieldDecorator('resumeTitle', {
          initialValue: user?.resumeTitle || '',
          rules: [{ required: cvIsRequired, message: ' ' }],
        })(
          <input type="hidden" />
        )}
      </FormItem>
    </>
  )
}
