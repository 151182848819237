import { defineMessages } from 'react-intl';

export default defineMessages({
  duplicate: {
    id: 'duplicate',
  },
  edit: {
    id: 'offer.update',
  },
  delete: {
    id: 'delete',
  },
  eventDeletionModalConfirm: {
    id: 'event.deletion.modal.confirm',
  },
  confirm: {
    id: 'btn.confirm',
  },
  cancel: {
    id: 'cancel',
  },
  eventDeletionNotificationSuccess: {
    id: 'event.deletion.notification.success',
  },
});
