import React from 'react';
import { string, array, object, bool } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';
import { Tooltip } from '@/components/Tooltip';
import { ValueWithWarning } from '@/components/ValueWithWarning';
import { MemberColumn } from '@/scenes/Admin/scenes/Organizations/scenes/Organization/components/memberColumn';
import {
  MoreActions
} from '@/scenes/Event/scenes/Owner/scenes/OnBoarding/containers/Dashboard/components/Recruiters/MoreActions';

const SubColumnsUi = (event, contexts, handleRecruiterAction, exponent, switchAuth) => (
  [
    {
      id: 'members',
      Header: <FormattedMessage id="recruiter.preparation.team" />,
      disableSortBy: true,
      accessor: (record) => (
          <MemberColumn
            name={record.name}
            authUser={record.authUser}
            record={{ ...record._user, role: record.role }}
            context={record.context}
            onActionClick={() => { switchAuth(record._user); }}
            organization={exponent._organization}
          />
        ),
      },
    {
      id: 'offers',
      Header: <FormattedMessage id="offers" values={{ count: 2 }} />,
      disableSortBy: true,
      className: 'text-center',
      accessor: ({ countOffersPublished }) =>
        <span style={{ display: 'flex', justifyContent: 'center' }}>
          <ValueWithWarning value={countOffersPublished || 0} />
        </span>
    },
    {
      id: 'lives',
      Header: <FormattedMessage id="live.pl" values={{ count: 2 }} />,
      disableSortBy: true,
      accessor: (record) => (
        <span style={{ display: 'flex', justifyContent: 'center' }}>
          {
            record.countConferences !== null && (
              get(event, 'modules.live.recruiterEnable') ? <ValueWithWarning value={record.countConferences || 0} />
                : <Tooltip color="dark" placement="bottom"
                  title={<FormattedMessage id="client.member.tooltip.not.allow.to.publish.live" />}>-</Tooltip>
            )
          }
        </span>
      )
    },
    {
      id: 'physicalCountAppointment',
      Header: <FormattedMessage id="interviews.slots" />,
      SubHeaders: [<FormattedMessage id="available.pl" values={{ count: 1 }} />, <FormattedMessage id="booked.plgr" values={{ count: 1, gender: 'male' }} />],
      accessor: ({ countSlotsAvailable, countAppointmentInterview }) => [
        <ValueWithWarning value={countSlotsAvailable} />,
        <ValueWithWarning value={countAppointmentInterview} />
      ],
      disableSortBy: true,
    },
    {
      id: 'countInformal',
      Header: <FormattedMessage id="slots.informal1to1" />,
      SubHeaders: [<FormattedMessage id="available.pl" values={{ count: 1 }} />, <FormattedMessage id="booked.plgr" values={{ count: 1, gender: 'male' }} />],
      accessor: ({ countInformalSlotsAvailable, countAppointmentInformal }) => [
        <ValueWithWarning enableValue={event?.modules?.informal1to1?.enable} value={countInformalSlotsAvailable} />,
        <ValueWithWarning enableValue={event?.modules?.informal1to1?.enable} value={countAppointmentInformal} />
      ],
      disableSortBy: true,
    },
    {
      id: 'actions',
      Header: '',
      disableSortBy: true,
      accessor: (record) => (
        record._user ?
          <MoreActions
            _user={record._user}
            contexts={contexts}
            onActionClick={(type) => handleRecruiterAction(type, record, exponent)}
            exponentUser={record}
            role={record.role}
            organization={exponent._organization}
            authUser={record.authUser}
          /> : null
      )
    }
  ]
);

SubColumnsUi.propTypes = {
  tool: object,
  createdAt: string,
  _organization: object,
  users: array,
  locale: string,
  event: object,
  isHybrid: bool,
};

export default SubColumnsUi;
