import React, { useState } from 'react';
import { string, node, oneOfType, bool, oneOf, func } from 'prop-types';
import classnames from 'classnames';
import { capitalize } from 'lodash';

import { If } from '@/components/If';

// Styles & Translations

import styles from './styles.less';

const Card = ({
  closable = false,
  title,
  children,
  footer,
  borderPosition = 'top',
  headerClassName,
  containerClassName,
  noBorder,
  contentClassName,
  cacheName,
  bodyContainerClassName,
  onClick
}) => {
  const [isClosing, setIsClosing] = useState(false);

  const closeCard = () => {
    setIsClosing(true);

    if (cacheName) {
      window.localStorage.setItem(cacheName, 1);
    }
  }

  return (
    <div
      style={borderPosition !== 'none' ? { [`padding${capitalize(borderPosition)}`]: 5 } : null}
      className={classnames(styles.cardContainer, containerClassName, noBorder ? styles.noBorder : '', isClosing ? styles.isClosing : '')}
      onClick={onClick}
      role="banner"
    >
      <If condition={closable === true}>
        <a role="button" className="ant-alert-close-icon" onClick={closeCard} tabIndex={0}>
          <i className="anticon anticon-cross" />
        </a>
      </If>

      <If condition={typeof title === 'string'}>
        <div className={classnames(styles.header, headerClassName)}>
          { title }
        </div>
      </If>
      <div className={classnames(styles.bodyContainer, bodyContainerClassName)}>
        <div className={classnames(styles.body, contentClassName)}>
          { children }
        </div>
      </div>

      { footer }
    </div>
  );
}

Card.propTypes = {
  title: string,
  containerClassName: string,
  headerClassName: string,
  contentClassName: string,
  bodyContainerClassName: string,
  noBorder: bool,
  closable: bool,
  children: oneOfType([string, node]),
  footer: oneOfType([string, node]),
  borderPosition: oneOf(['top', 'bottom', 'none']),
  cacheName: string,
  onClick: func,
};

export default Card;
