import React from 'react';
import { shape, func, any, object, string, number } from 'prop-types';
import { isUndefined, isEmpty, isEqual } from 'lodash';
import moment from 'moment/moment';
import uuidv1 from 'uuid/v1';
import { injectIntl } from 'react-intl';
import PhoneInput from 'react-phone-number-input';
import { Col, Form, Row, Select as SelectAntd } from 'antd/lib/index';
import { Button } from '@seekube-tech/ui-kit';
import { ANALYTICS_CANDIDATE } from '@/utils/constants';
import { formatName } from '@/utils/format';
import request from '@/utils/request';
import { track } from '@/utils/analytics';
import 'react-phone-number-input/style.css';

// components
import { H3 } from '@/components/Title/index';
import AvatarEditorDnd from '@/components/AvatarEditorDnd';
import Select from '@/components/Form/Select/index';
import InputMaterial from '@/components/Input/InputMaterial/index';
import SocialLoginButton from '@/components/SocialLoginButton';
import Icon from '@/components/Icon';
import { Tooltip } from '@/components/Tooltip';
import messages from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/Signup/components/SignupModal/components/Identity/messages';
import styles from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/Signup/components/SignupModal/components/Identity/styles.less';
import requestExternal from '@/utils/requestExternal';
import { trackGtmEvent } from "@/utils/pixels/helloWork";
import FormFooter from '../FormFooter';

const { Option } = SelectAntd;
const FormItem = Form.Item;

const hasErrors = (fieldsError) => Object.keys(fieldsError).some((field) => fieldsError[field]);

/**
 * <Identity />
 *
 * @description
 * Collect user identity data
 */
class Identity extends React.PureComponent {
  static propTypes = {
    form: shape({
      getFieldDecorator: func,
      getFieldsError: func,
      getFieldError: func,
      isFieldTouched: func,
    }),
    handleDataUpdate: func,
    currentStep: any,
    user: object,
    event: object,
    intl: object,
    context: string,
    stepPosition: number,
    countEnabledSteps: number,
    eventSteps: object,
  };

  static defaultProps = {
    context: 'event',
  };

  constructor(props) {
    super(props);
    this.state = {
      pictureUrl: !isUndefined(props.user.pictureUrl) ? props.user.pictureUrl : null,
      uploadUrl: null,
      uploadingProfilePicture: null,
      linkedinStatus: null,
      value: null,
    };
  }

  componentDidMount() {
    const { form: { setFieldsValue, validateFields }, user, event } = this.props;

    setFieldsValue({
      gender: user.gender || window.localStorage.getItem('signup-civility'),
      firstName: user.firstName || window.localStorage.getItem('signup-fn'),
      lastName: user.lastName || window.localStorage.getItem('signup-ln'),
      pictureUrl: user.pictureUrl,
      phone: user.phone || window.localStorage.getItem('signup-phone'),
    });

    validateFields();

    if (event) {
      trackGtmEvent('onboarding_start', {
        'page_category': 'inscription',
        'page_subject': 'onboarding',
        'user_id_back': user._id,
        'flow_name': 'inscription_jobdating',
        'flow_step': 1,
        'flow_step_total': event.countOnboardingSteps,
        'flow_modules_list': event.onboardingSteps,
        'flow_step_name': 'identity',
        'jobdating_name': event.slug,
      });
    }
  }

  /**
   * UpdateInput
   *
   * @description
   * Set input value to the new file url
   */
  updateInput = () => {
    const { form: { setFieldsValue } } = this.props;

    setFieldsValue({
      pictureUrl: this.state.pictureUrl,
    });
  }

  /**
   * SaveFile
   *
   * @description
   * Save file url on state
   */
  saveFile = (fileUrl) => {
    const { updateInput } = this;

    this.setState({
      pictureUrl: fileUrl,
      uploadUrl: null,
      uploadingProfilePicture: null,
    }, () => {
      updateInput();
    });
  };

  handleOnSubmit = (e) => {
    e.preventDefault();
    const {
      props: { form: { validateFields, getFieldsValue }, user, handleDataUpdate, event, context, intl },
    } = this;

    const values = getFieldsValue();

    return validateFields((err) => {
      if (!err) {
        track({
          name: ANALYTICS_CANDIDATE.TOUR_STEP1_SUBMIT,
          event,
          user,
          properties: {
            hasPhoto: typeof getFieldsValue().pictureUrl === 'string',
          },
        });

        if (event) {
          trackGtmEvent('onboarding_presentation_complete', {
            'page_category': 'inscription',
            'page_subject': 'onboarding',
            'user_id_back': user._id,
            'flow_name': 'inscription_jobdating',
            'flow_step': 1,
            'flow_step_total': event.countOnboardingSteps,
            'flow_modules_list': event.onboardingSteps,
            'flow_step_name': 'presentation',
            'jobdating_name': event.slug,
          });
        }

        values.dateOfBirth = moment(`${values.birthyear}-03-03`).toDate();
        values.lastPresentationUpdate = new Date();

        if (context === 'settings' && values.email !== user.username) {
          values.username = user.username;
          values.usernameTemp = values.email;
          values.context = 'checkUsername';
          values.url = window.location.href;
          values.notificationParams = {
            success: {
              message: intl.formatMessage(messages.emailConfirm, { email: user.username }),
              kind: 'info',
              style: {
                bottom: '5%',
                top: 'inherit',
              },
            },
          };
        }
        handleDataUpdate(values, 1);
      }
    });
  };

  /**
   * UpdateInput
   *
   * @description
   * Set input value to the new file url
   */
  updateInput = () => {
    const { form: { setFieldsValue } } = this.props;

    setFieldsValue({
      pictureUrl: this.state.pictureUrl,
    });
  };

  handleRemoveProfilePicture = () => {
    this.setState({
      pictureUrl: null,
      linkedinStatus: this.state.linkedinStatus === 'success' ? null : this.state.linkedinStatus,
    }, () => this.updateInput());
  };

  /**
   * validatePhoneNumber
   *
   * @description : Validate that the phone number is an integer
   *
   */
  validatePhoneNumber = (rule, value, callback) => {
    const { props: { intl } } = this;
    const result = parseInt(value, 10);

    if (Number.isInteger(result) && value && value.length >= 6) {
      callback();
    }

    callback(intl.formatMessage(messages.signupPhoneMandatory));
  };

  handleOnSocialLoginFailure = () => {
    this.setState({ linkedinStatus: 'error' });
  };

  /**
   * HandleSocialLogin
   *
   * @description
   * Get provider user info for set fields values & pictureUrl
   *
   */
  handleSocialLogin = (user) => {
    const { props: { event, form: { setFieldsValue } } } = this;

    this.setState({ linkedinStatus: 'loading' });

    return new Promise((resolve, reject) => {
      request(`${process.env.FRONT_API_URL}/users/oauth/infos`, {
        method: 'POST',
        body: JSON.stringify({
          token: user._token.accessToken,
          provider: 'linkedin',
        }),
      })
        .then((result) => {
          this.setState({ linkedinStatus: 'success' });

          const tempUser = {
            firstName: result.infos.firstName,
            lastName: result.infos.lastName,
            title: result.infos.headline,
            description: result.infos.summary,
            linkedinProfileUrl: result.infos.publicProfileUrl,
          };

          setFieldsValue({
            firstName: result.infos.firstName,
            lastName: result.infos.lastName,
          });

          // this.setState({ profilePicture: result.pictureUrl });
          this.uploadExternalProfilePicture(result.infos.pictureUrl);

          // success
          track({
            user: Object.assign(this.props.user, tempUser),
            name: ANALYTICS_CANDIDATE.IMPORT_LINKEDIN,
            event,
            properties: {
              step: 'Sign up',
            },
          });
        })
        .catch((e) => {
          this.setState({ linkedinStatus: 'error' });
          reject(e);
        });
    });
  };

  /**
   * uploadExternalProfilePicture
   *
   * @description
   * Handle upload an external picture from provider to S3
   *
   */
  uploadExternalProfilePicture = (pictureUrl) => {
    const { updateInput, props: { user } } = this;

    return new Promise((resolve, reject) => {
      // Fetch the blob
      fetch(pictureUrl)
        .then((result) => result.blob())
        .then((blob) => {
          // ask a signed url to S3
          request(`${process.env.FRONT_API_URL}/users/${user._id}/upload?fileName=${uuidv1()}.jpg`)
            .then((response) => {
              const fileUrl = response.url;

              // then upload the blob to s3
              requestExternal(response.signedRequest, {
                method: 'PUT',
                headers: {
                  'Content-Type': blob.type,
                },
                body: blob,
              })
                .then(() => {
                  this.setState({
                    pictureUrl: fileUrl,
                    pictureFromProvider: true,
                  }, () => {
                    updateInput();
                  });
                })
                .catch((uploaderror) => console.error(uploaderror));
            })
            .catch((awserror) => console.error(awserror));
        })
        .catch((bloberr) => reject(bloberr));
    });
  };

  render() {
    const {
      props: { user, form, intl, context, event, currentStep, eventSteps, countEnabledSteps, stepPosition },
      state: { pictureUrl, linkedinStatus },
      handleOnSubmit, saveFile, validatePhoneNumber,
    } = this;

    const { getFieldDecorator, getFieldError, isFieldTouched, getFieldValue } = form;
    const firstNameError = isFieldTouched('firstName') && getFieldError('firstName');
    const lastNameError = isFieldTouched('lastName') && getFieldError('lastName');
    const genderError = isFieldTouched('gender') && getFieldError('gender');
    const emailError = isFieldTouched('email') && getFieldError('email');
    const phoneError = isFieldTouched('phone') && getFieldError('phone');
    const btnPictureText = isEmpty(user) || !user.pictureUrl ? intl.formatMessage(messages.signupIdentityPictureUrlChoose) : intl.formatMessage(messages.signupIdentityPictureUrlUpdate);
    let formIsModify = false;

    if (user) {
      formIsModify = !isEqual(user.firstName, getFieldValue('firstName')) || !isEqual(user.lastName, getFieldValue('lastName')) ||
        !isEqual(user.gender, getFieldValue('gender')) || !isEqual(moment(user.dateOfBirth).format('YYYY'), getFieldValue('birthyear')) ||
        !isEqual(user.phone, getFieldValue('phone')) || !isEqual(user.username, getFieldValue('email')) ||
        !isEqual(user.pictureUrl, pictureUrl) || !isEqual(user.studentId, getFieldValue('studentId'));
    }
    const regexCriteria = event && event._criteria.find((criteria) => criteria.key === 'REGEXID');
    const title = event?.participants?.onboarding?.find((o) => o.name === 'presentation')?.[`title_${intl.locale}`];
    const createTitle = !isEmpty(title) ? title : intl.formatMessage(messages.signupIdentityTitle);
    return (
      <div className="commonStep">
        {context === 'event' ? (
          <H3 className="presentationTitle" bold>{isEmpty(user) || !user.firstName ? createTitle : intl.formatMessage(messages.signupIdentityTitleModify)} <span role="img" aria-label="Hello"> 👋</span></H3>
        ) : (
          <H3 className="presentationTitle" bold>{!isEmpty(title) ? title : intl.formatMessage(messages.presentationTitle)} <span role="img" aria-label="Hello" style={{ marginLeft: 10 }}> 👋</span></H3>
        )}

        <div className="mb-40">
          <div className="insideProgressBar" />
          <SocialLoginButton
            type="link"
            provider="linkedin"
            appId={process.env.FRONT_LINKEDIN_CLIENT_ID}
            onLoginSuccess={this.handleSocialLogin}
            onLoginFailure={this.handleOnSocialLoginFailure}
          >
            <Button size="large" color={linkedinStatus === 'error' ? 'error' : 'neutral'} loading={linkedinStatus === 'loading'}>
              <Icon name="linkedin" className="mr-10" />
              {[null, 'loading'].includes(linkedinStatus) && intl.formatMessage(messages.signupIdentityLinkedinImport)}
              {linkedinStatus === 'success' && intl.formatMessage(messages.signupIdentityLinkedinSuccess)}
              {linkedinStatus === 'error' && intl.formatMessage(messages.signupIdentityLinkedinError)}
            </Button>
          </SocialLoginButton>
        </div>

        <Form className="form" onSubmit={handleOnSubmit}>
          <div className="pictureContainer">
            <div className="picture">
              <AvatarEditorDnd
                src={pictureUrl || undefined}
                onSave={saveFile}
                onRemove={this.handleRemoveProfilePicture}
                objectId={user._id}
                size={130}
              >
                <div className="pictureBtnAction">
                  <Button className="pictureBtn" variant="outline">{btnPictureText}</Button>

                  <p className="upload-infos">
                    {intl.formatMessage(messages.signupIdentityPictureUrlFormat)}
                  </p>
                </div>
              </AvatarEditorDnd>
            </div>
          </div>

          <FormItem className="pictureItem">
            {getFieldDecorator('pictureUrl', {
              rules: [{ required: false }],
            })(
              <input type="hidden" />
            )}
          </FormItem>

          <div className="fieldsContainer">
            <Row gutter={16}>
              <Col xs={16}>
                <FormItem
                  validateStatus={genderError ? 'error' : ''}
                  help={genderError || ''}
                  className="gender"
                >
                  {getFieldDecorator('gender', {
                    rules: [{ required: true, message: intl.formatMessage(messages.signupIdentityGenderMandatory) }],
                  })(
                    <Select
                      addOnIcon="gender"
                      placeholder={intl.formatMessage(messages.signupIdentityGender)}
                      label={intl.formatMessage(messages.signupIdentityGender)}
                      required
                    >
                      <Option key="1">{intl.formatMessage(messages.signupIdentityMadam)}</Option>
                      <Option key="2">{intl.formatMessage(messages.signupIdentitySir)}</Option>
                    </Select>
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={16}>
                <FormItem
                  validateStatus={firstNameError ? 'error' : ''}
                  help={firstNameError || ''}
                >
                  {getFieldDecorator('firstName', {
                    rules: [{ required: true, message: 'Le prénom est obligatoire !' }],
                  })(
                    <InputMaterial
                      addOnIcon="user"
                      label={intl.formatMessage(messages.signupIdentityFirstName)}
                      placeholder={intl.formatMessage(messages.signupIdentityFirstName)}
                      type="text"
                      validations={['required']}
                      applyFormatValue={formatName}
                    />
                  )}
                </FormItem>

              </Col>
              <Col xs={16}>
                <FormItem
                  validateStatus={lastNameError ? 'error' : ''}
                  help={lastNameError || ''}
                >
                  {getFieldDecorator('lastName', {
                    rules: [{ required: true, message: 'Le nom est obligatoire !' }],
                  })(
                    <InputMaterial
                      addOnIcon="user"
                      label={intl.formatMessage(messages.signupIdentityLastName)}
                      placeholder={intl.formatMessage(messages.signupIdentityLastName)}
                      type="text"
                      validations={['required']}
                      applyFormatValue={formatName}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>

            <FormItem
              validateStatus={phoneError ? 'error' : ''}
              help={phoneError || ''}
            >
              {getFieldDecorator('phone', {
                rules: [
                  { validator: validatePhoneNumber, required: true },
                ],
              })(
                <PhoneInput
                  defaultCountry={intl.locale === 'en' ? 'GB' : 'FR'}
                  className={styles.inputPhone}
                  validations={['required']}
                  label={intl.formatMessage(messages.signupIdentityPhone)}
                  placeholder={`${intl.formatMessage(messages.signupIdentityPhone)} *`}
                  value={this.state.value}
                  onChange={(value) => { this.setState({ value }); }}
                />
              )}
            </FormItem>
            {context === 'settings' ? (
              <FormItem
                validateStatus={emailError ? 'error' : ''}
                help={emailError || ''}
              >
                {getFieldDecorator('email', {
                  initialValue: user.username,
                  rules: [
                    { required: true, type: 'email', message: intl.formatMessage(messages.emailInvalid) },
                  ],
                })(
                  <InputMaterial
                    addOnIcon="mail"
                    label={intl.formatMessage(messages.emailLabel)}
                    placeholder={intl.formatMessage(messages.emailLabel)}
                    type="mail"
                    validations={['required']}
                    getError={!isEmpty(getFieldError('email'))}
                  />
                )}
              </FormItem>
            ) : ''}

            {
              regexCriteria && regexCriteria.modules.onboarding.isVisible ?
                <FormItem
                  validateStatus={(isFieldTouched('studentId') && getFieldError('studentId')) ? 'error' : ''}
                  help={(isFieldTouched('studentId') && getFieldError('studentId')) || ''}
                  className={styles.studentId}
                >
                  {getFieldDecorator('studentId', {
                    initialValue: user.studentId,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage(messages.formInvalid, { name: regexCriteria.modules.onboarding[user.locale === 'en' ? 'label_en' : 'label'] }),
                        pattern: new RegExp(regexCriteria.value)
                      },
                    ],
                  })(
                    <InputMaterial
                      maxLength="100"
                      addOnIcon={regexCriteria.icon}
                      label={regexCriteria.modules.onboarding[user.locale === 'en' ? 'label_en' : 'label']}
                      placeholder={regexCriteria.modules.onboarding[user.locale === 'en' ? 'label_en' : 'label']}
                      type="text"
                      validations={['required']}
                      getError={isFieldTouched('studentId') && !isEmpty(getFieldError('studentId'))}
                    />
                  )}
                  <Tooltip className={styles.hint} isVisible={regexCriteria.isHintVisible} title={regexCriteria[user.locale === 'en' ? 'hint_en' : 'hint']} placement="right" color="dark" key={0}>
                    <span><Icon name="info-circle" /></span>
                  </Tooltip>
                </FormItem> : null
            }

            {context === 'settings' && (
              <Button
                className="changeStep settings"
                disabled={hasErrors(form.getFieldsError())}
                type="submit"
                onClick={handleOnSubmit}
              >
                {intl.formatMessage(messages.save)}
              </Button>
            )}
          </div>
        </Form>

        {context !== 'settings' && (
          <FormFooter
            form={form}
            currentStep={currentStep}
            eventSteps={eventSteps}
            stepPosition={stepPosition}
            countEnabledSteps={countEnabledSteps}
            onSubmit={this.handleOnSubmit}
          />
        )}
      </div>
    );
  }
}

export default Form.create()(injectIntl(Identity));
