import React from 'react';
import { object, func, array, bool } from 'prop-types';
import { Grid, Typography, Avatar as AvatarBusiness } from '@seekube-tech/ui';
import colors from '@seekube-tech/ui-kit/dist/colors';
import { useFormatMessage } from 'react-intl-hooks';
import LinesEllipsis from 'react-lines-ellipsis';
import { cropText } from '@/utils/text';
import Avatar from '@/components/Avatar';
import Icon from '@/components/Icon';
import Actions from './components/Actions';

import styles from './styles.less';

function Informal1to1Card({
  exponentUser,
  event,
  createInformal1To1,
  participant,
  interactions,
  cancelAppointment,
  updateAppointment,
  disableAction,
  isLight
}) {
  /* Vars */
  const t = useFormatMessage();
  const interaction = interactions.find(interaction => interaction._recruiter._id === exponentUser._user._id && interaction.type === 'informal1to1');

  const handleCreateInformal1To1 = () => {
    createInformal1To1({
      participantId: participant._id,
      eventId: event._id,
      recruiterId: exponentUser._user._id,
      organizationId: exponentUser._organization._id,
    });
  };

  const handleCancelAppointment = () => {
    cancelAppointment({
      appointmentId: interaction._appointment._id,
      eventId: event._id,
      userId: exponentUser._user._id,
      timeslotId: null,
      context: 'informal1to1',
      notificationParams: {
        success: {
          message: t({ id: 'event.candidate.appointments.card.interview.canceled.by.candidate' }),
          kind: 'success',
          style: {
            bottom: '5%',
            top: 'inherit',
          },
        },
        error: false,
      },
    });
  };

  const handleUpdateAppointment = () => {
    updateAppointment(interaction._appointment._id);
  };

  const getColor = (status) => {
    switch (status) {
      case 'pending':
        return colors.warning['500'];

      case 'canceled':
      case 'refused':
        return colors.error['500'];

      case 'accepted':
      case 'unconfirmed':
        return colors.success['500'];

      default:
        return colors.basic.white;
    }
  };

  /* Render */

  return (
    <Grid container direction="column" justify="space-between" className={`${styles.root}`}>
      <Grid item className={styles.itemContainer}>
        <Grid container spacing={3} className={styles.infoContainer} wrap="nowrap">
          <Grid item className={styles.avatarContainer}>
            <Avatar
              size={89}
              user={exponentUser._user}
              src={exponentUser._user?.pictureUrl}
            />

            <AvatarBusiness
              variant="business"
              size="small"
              src={exponentUser._organization?.profile?.pictureUrl}
              color={exponentUser.isPartner ? event.modules.partner?.color : 'default'}
              badge={exponentUser.isPartner ? event.modules.partner?.icon : null}
              tooltip={exponentUser.isPartner ? event.modules.partner?.label_fr : null}
              className={styles.avatarBusiness}
            />
          </Grid>

          <Grid item>
            <Typography variant="h5" className={styles.userName}>
              {exponentUser._user.shortName}
            </Typography>

            <Typography variant="body2" className={styles.userPosition}>
              <LinesEllipsis
                text={exponentUser._user.title}
                maxLine={2}
              />
            </Typography>

            <Typography variant="caption1" color="neutral[300]" className={styles.organizationName}>
              {exponentUser._organization.name}
            </Typography>
          </Grid>
        </Grid>

        {!isLight &&
          (interaction?._appointment ? (
            <span className={styles.badge}>
              <div className={styles.icon} style={{ color: getColor(interaction._appointment.status) }} />

              {interaction._appointment.status === 'unconfirmed' ?
                t({ id: 'accepted' }) :
                t({ id: interaction._appointment.status })
              }
            </span>
          ) : (
            <Typography
              variant="body2"
              color={exponentUser.countInformal1to1Slots > 0 ? 'success.500' : 'warning.500'}
              className={styles.freeSlot}
            >
              <Icon name="calendar" /> {t({ id: 'count.free.slot.pl' }, { count: exponentUser.countInformal1to1Slots })}
            </Typography>
          ))
        }
        <Typography
          variant="body2"
          className={styles.description}
        >
          {cropText(exponentUser.informalDescription, 240)}
        </Typography>
      </Grid>

      {!isLight &&
        <Grid item className={styles.actions}>
          <Actions
            exponentUser={exponentUser}
            keyDates={event?.keyDates}
            createInformal1To1={handleCreateInformal1To1}
            cancelAppointment={handleCancelAppointment}
            interaction={interaction}
            skipAppointmentValidation={event?.skipAppointmentValidation}
            updateAppointment={handleUpdateAppointment}
            disableAction={disableAction}
            event={event}
            participant={participant}
          />
        </Grid>
      }
    </Grid>
  );
};

Informal1to1Card.propTypes = {
  exponentUser: object,
  event: object,
  participant: object,
  createInformal1To1: func,
  interactions: array,
  cancelAppointment: func,
  updateAppointment: func,
  disableAction: bool,
  isLight: bool
};

Informal1to1Card.defaultProps = {
  disableAction: false
};

export default Informal1to1Card;
