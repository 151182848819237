import React from 'react';
import { Field } from 'react-final-form';
import { array, object, string } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { Form } from 'antd';
import SearchMatchingSelector
  from '@/components/SearchMatchingSelector';
import useValidations from '@/utils/validations/useValidations';

const FormItem = Form.Item;
const MatchingTargets = ({
  options,
  form,
  formType = 'final-form',
  initialValue
}) => {
  const t = useFormatMessage();
  const { isFilled } = useValidations();

  if (formType === 'final-form') {
    return (
      <Field name="targets" validate={(value) => isFilled(value)}>
        {props => (
          <SearchMatchingSelector
            options={options}
            placeholder={t({ id: 'criteria[TARGETS]' })}
            icon="star"
            name="targets"
            onChange={props.input.onChange}
            valueSelected={props.input.value}
            hasError={props.meta.error}
          />
        )}
      </Field>
    )
  }

  return (
    <FormItem>
      {form.getFieldDecorator('matching.targets', {
        initialValue,
        rules: [{
        required: true, message: t({ id: 'form.mandatory' }),
      }]
      })(
        <SearchMatchingSelector
          options={options}
          placeholder={t({ id: 'criteria[TARGETS]' })}
          icon="star"
          name="targets"
          onChange={(value) => form.setFieldsValue({ 'matching.targets': value })}
          valueSelected={form.getFieldValue('matching.targets') ?? []}
          hasError={form.getFieldError('matching.targets')}
        />
      )}
    </FormItem>
  )
}

MatchingTargets.propTypes = {
  form: object,
  options: array,
  formType: string,
  initialValue: object
}

export default MatchingTargets;
