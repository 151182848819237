import React from 'react';
import { node, string, oneOfType } from 'prop-types';

// Styles & Translations
import styles from './styles.less';

/**
 * ResultsContainer
 * @param {object} props
 */
const ResultsContainer = (props) => (
  <div className={styles.resultsContainer} {...props}>
    {props.children}
  </div>
);

ResultsContainer.propTypes = {
  children: oneOfType([string, node]).isRequired,
};

export default ResultsContainer;
