import { func, object } from 'prop-types';
import { Field, Form } from 'react-final-form';
import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import classnames from 'classnames';
import { Button, H5, IconContract, IconMail, IconUser } from '@seekube-tech/ui-kit';
import { isEmpty } from 'lodash';
import useValidations from '@/utils/validations/useValidations';
import wrapperStyles from '@/scenes/Settings/styles.less';
import {
  ImportLinkedinInformations
} from '@/scenes/Settings/components/IdentityV2/components/ImportLinkedinInformations';
import { AvatarEdition } from '@/scenes/Settings/components/IdentityV2/components/AvatarEdition';
import styles from './styles.less';
import { getModifiedValues } from '@/utils/forms/final-form/helpers';
import { FormTextField } from '@/components/FinalFormFields/FormTextField';
import { FormPhoneField } from '@/components/FinalFormFields/FormPhoneField';
import { FormTextAreaField } from '@/components/FinalFormFields/FormTextAreaField';

export const IdentityV2 = ({
  authUser,
  userInfos,
  onSave
}) => {
  const t = useFormatMessage();
  const { isEmail } = useValidations();
  
  const handleSubmit = () => ((values, form) => {
    const state = form.getState();
    const modifiedValues = getModifiedValues(state.initialValues, values);
    
    onSave({ 
      ...modifiedValues,
    });
  })
  
  return (
    <div className={classnames(wrapperStyles.emailFormWrapper, wrapperStyles.recruiterForm)}>
      <Form
        onSubmit={handleSubmit()}
        initialValues={userInfos}
        render={({ handleSubmit, form }) => {
          const state = form.getState();
          return (
            <form onSubmit={handleSubmit}>
            <div className={styles.inputsContainer}>
              <H5>{t({ id: 'profile.settings.general.identity.title' })}</H5>
                
              <ImportLinkedinInformations authUser={authUser} />
  
              <Field
                name="pictureUrl"
                id="pictureUrl"
              >
                {({ input }) => (
                  <AvatarEdition
                    authUser={authUser}
                    onChange={(pictureUrl) => {
                      form.change('pictureUrl', pictureUrl)
                    }}
                    pictureUrl={input.value} />
                  )}
              </Field>
  
              <FormTextField
                name="firstName"
                id="firstName"
                label={t({ id: 'firstName' })}
                leftIcon={<IconUser />}
                required
              />
              <FormTextField
                name="lastName"
                id="lastName"
                label={t({ id: 'lastName' })}
                leftIcon={<IconUser />}
                required
              />
              <FormTextField
                name="title"
                id="title"
                label={t({ id: 'event.recruiter.preparation.signup.identity.form.jobPosition.label' })}
                leftIcon={<IconContract />}
                required
              />
              <FormTextField
                name="username"
                id="username"
                label={t({ id: 'event.recruiter.preparation.signup.login.form.username.label' })}
                leftIcon={<IconMail />}
                validate={(value) => isEmail(value)}
                required
              />
              <FormPhoneField
                name="phone"
                id="phone"
                label={t({ id: 'phone' })}
              />
              <FormTextAreaField
                name="description"
                id="description"
                label={t({ id: 'event.recruiter.preparation.signup.identity.form.description.label' })}
                maxLength="1000"
              />
              
              <div>
                <Button type="submit" disabled={state.invalid}>
                  {t({ id: 'save' })}
                </Button>
              </div>
            </div>
          </form>
          )
        }}
      />
    </div>
  )
}

IdentityV2.propTypes = {
  authUser: object,
  userInfos: object,
  onSave: func
}