import React, { useEffect, useState } from 'react';
import { object } from 'prop-types';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory, withRouter } from 'react-router-dom';
import { toString } from 'lodash';

// Containers
import { withLayout } from '@/containers/Layout';

// Components
import { objectToParams, queryStringToObject } from '@/utils/url';
import { toJS } from '@/utils/index';
import MobileMenu from '@/containers/Layout/containers/MobileMenu';

import MobileHeader from '@/containers/Layout/containers/MobileHeader';
import { useGetConferences } from '@/queries/conferences/useGetConferences';
import { patchMe } from '@/store/user/actions';
import { Nav } from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Conferences/Nav';
import { getAuthUser } from '@/store/auth/selectors';
import { getCurrentEvent } from '@/store/event/selectors';
import ConferencesList from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Conferences/ConferencesList';
import {
  UpdateArchiveSelect
} from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Conferences/UpdateArchiveSelect';
import { getLocale } from '@/utils/localStorage';
import ConferenceScene from './scenes/Conference';

const DEFAULT_SEARCH = {
  page: 1,
  offset: 0,
  archive: false,
  keywords: '',
}

const CandidateJobdatingConferencesScene = ({ match }) => {
  const authUser = useSelector(getAuthUser)?.toJS();
  const event = useSelector(getCurrentEvent)?.toJS();
  const history = useHistory();
  const dispatch = useDispatch();

  const [search, setSearch] = useState({ ...DEFAULT_SEARCH, ...queryStringToObject(history.location.search) });
  const listContext = history.location.pathname.includes('discovery') ? 'discovery' : 'confirmed';

  const conferencesQuery = useGetConferences({
    eventId: event._id,
    limit: 12,
    search,
    participate: listContext === 'confirmed'
  });

  if (match.isExact) {
    history.replace(`${match.url}/discovery`);
  }

  useEffect(() => {
    dispatch(patchMe({
      userParams: { lastViewConferences: new Date() },
      notificationParams: {
        success: null,
        error: true,
      },
    }))
  }, []);

  const handleNavClick = (name) => {
    history.push(`${match.url}/${name}?page=1&archive=false`);
    setSearch(DEFAULT_SEARCH)
  }

  const updateSearchAndURL = (searchChanges, shouldUpdateUrl = true) => {
    const newSearch = { ...search, ...searchChanges };

    setSearch(newSearch);

    if (shouldUpdateUrl) {
      updateURLWithSearchChanges(newSearch)
    }
  }

  const updateURLWithSearchChanges = (searchToUpdate) => {
    history.push(`${history.location.pathname}?${objectToParams(searchToUpdate)}`)
  }

  return (
    <>
      {(history.location.pathname.includes('discovery') || history.location.pathname.includes('confirmed')) && (
        <div className="layout-large">
          <div className="flex items-center justify-between flex-wrap">
            <Nav
              conferencesQuery={conferencesQuery}
              handleClick={handleNavClick}
              defaultIndex={history.location.pathname.includes('discovery') ? 'discovery' : 'confirmed'}
            />
            {listContext === 'confirmed' &&
              <UpdateArchiveSelect
                onSelect={(value) => {
                  updateSearchAndURL({ archive: toString(value === 'archive') })
                }}
              />
            }
          </div>
        </div>
      )}
      <Switch>
        <Route
          path={`${match.path}/confirmed`}
          render={() => (<ConferencesList
            onSearch={updateSearchAndURL}
            listContext={listContext}
            conferencesQuery={conferencesQuery}
            event={event}
          />)}
        />
        <Route
          path={`${match.path}/discovery`}
          render={() => (<ConferencesList
            onSearch={updateSearchAndURL}
            listContext={listContext}
            conferencesQuery={conferencesQuery}
            event={event}
          />)}
        />
        <Route
          path={`${match.path}/conference/:conferenceID`}
          render={(props) => <ConferenceScene {...props} />}
        />
      </Switch>

      <MobileHeader event={event} />


      <MobileMenu
        displayInformal={event.modules?.informal1to1?.enable}
        displayLive={event.modules.live && event.modules.live.participantEnable}
        liveLabel={event.modules.live && event.modules.live[`labelMultiple_${getLocale()}`]}
      />
    </>
  );
}

CandidateJobdatingConferencesScene.propTypes = {
  match: object,
};


export default compose(
  withLayout({ size: 'full', withHeader: false, navType: 'candidate' }),
  withRouter,
)(toJS(CandidateJobdatingConferencesScene));
