import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import times from 'lodash/times';
import moment from 'moment';
import { isSlotBooked } from './isSlotBooked';

/**
 * @description
 * A slot is booked if it contains an appointment
 */
export function setDuration(slots, timeslots, duration) {
  let newSlots = [];

  slots
    .sort((slot1, slot2) => moment(slot1.beginAt).toDate() - moment(slot2.beginAt).toDate())
    .forEach((slot, index) => {
      let currentSlot = slot;
      if (!isEmpty(slot._id)) return null;

      const isBooked = isSlotBooked(currentSlot);
      currentSlot = isBooked ? currentSlot : { ...currentSlot, duration };
      // Is the first slot
      if (isEmpty(newSlots) && (duration > slot.duration)) {
        newSlots = [currentSlot];
        // Is new duration lower than current duration
      } else if ((duration < slot.duration) && (!isBooked)) {
        // Fill with slots, example: if duration is set from 45 to 15, it will be 3 slots of 15 slots
        const gcd = slot.duration % duration;

        const canFill = gcd === 0;

        const fillWithNewSlots = times(canFill ?
          (slot.duration / duration) : 1, (a) => (
          { ...currentSlot, beginAt: moment(currentSlot.beginAt).add(a * duration, 'minute') }));

        newSlots = [...newSlots, ...fillWithNewSlots];

        return true;

        // Is the last slot
      } else if (index === timeslots.length - 1) {
        const prevSlot = last(newSlots);
        if (moment(currentSlot.beginAt).isSameOrAfter(moment(prevSlot.beginAt).add(prevSlot.duration, 'minute'))) {
          newSlots = [...newSlots, currentSlot];
        }
      } else {
        const prevSlot = last(newSlots);

        if (moment(currentSlot.beginAt).isSameOrAfter(moment(prevSlot.beginAt).add(prevSlot.duration, 'minute'))) {
          newSlots = [...newSlots, currentSlot];
        } else if (isBooked) {
          newSlots.pop();
          newSlots = [...newSlots, currentSlot];
        }
      }
      return true;
    });

  return newSlots;
}
