import AvatarEditor from 'react-avatar-editor';
import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { Button, H3 } from '@seekube-tech/ui-kit';
import { bool, func, string, object } from 'prop-types';
import styles from '@/forms/Stand/components/CoverEditor/styles.less';
import ModalV2 from '@/components/ModalV2';

const CropModal = ({
  imageUrl,
  isVisible,
  handleSave,
  editor,
  onClose,
  isLoading
}) => {
  const t = useFormatMessage();

  return (
    <ModalV2
      template="default"
      visible={isVisible}
      closable={false}
      width="80%"
    >
      <H3 className="mb-32">{t({id: 'stand.modify.cover'})}</H3>
      <div className={styles.overlayButton}>
        {isLoading && (
          <Button>{t({ id: 'save.loading' })}</Button>
        )}
      </div>
      <div className={styles.cropper}>
        <AvatarEditor
          ref={editor}
          image={imageUrl}
          width={1312}
          height={280}
          border={0}
          color={[255, 255, 255, 0.6]} // RGBA
          rotate={0}
          scale={1}
        />

        <span className={styles.btnPosition}>
          <span role="img" aria-label="drag">↕️</span> {t({ id: 'coverEditor.crop.help' })}
        </span>

        <div className="confirm-actions">
          <Button
            color="neutral"
            variant="outline"
            onClick={onClose}
          >
            {t({ id: 'cancel' })}
          </Button>
          <Button
            color="primary"
            onClick={handleSave}
          >
            {t({ id: 'save' })}
          </Button>
        </div>
      </div>
    </ModalV2>
  )
}

CropModal.propTypes = {
  imageUrl: string,
  isVisible: bool,
  handleSave: func,
  editor: object,
  onClose: func,
  isLoading: bool
}

export default CropModal;
