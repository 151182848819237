import {useQuery} from 'react-query';
import request from '@/utils/request';

export function useGetFeeds({ ...props }) {
  const requestUrl = `${process.env.FRONT_API_URL}/statistics/kpi/feeds`;
  const queryFn = () => request(requestUrl, { method: 'GET' });

  return useQuery({
    queryKey: ['feeds'],
    queryFn,
    refetchOnWindowFocus: false,
    cacheTime: 3000,
    ...props,
  });
}
