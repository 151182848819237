import { defineMessages } from 'react-intl';

export default defineMessages({
  groupedHintPageSelected: {
    id: 'grouped.hint.pageSelected',
  },
  groupedProfileSelection: {
    id: 'grouped.hint.profileSelection',
  },
  clearSelection: {
    id: 'event.recruiter.preparation.offer.clearSelection',
  },
  groupedHintPageSelectedOffers: {
    id: 'grouped.hint.pageSelected.offers',
  },
  groupedProfileSelectionOffers: {
    id: 'grouped.hint.profileSelection.offers',
  },
});
