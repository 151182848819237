import React from 'react';
import { injectIntl, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { object } from 'prop-types';

// component
import Avatar from '@/components/Avatar';
import Icon from '@/components/Icon';
import P from '@/components/P';
import { H3 } from '@/components/Title';

// Styles & Transaltions
import './styles.less';
import messages from '../../messages';

const appointmentMedium = (medium, intl) => {
  switch (medium) {
    case 'phone':
      return intl.formatMessage(messages.phone);
    case 'visio':
      return intl.formatMessage(messages.visio);
    case 'physical':
      return intl.formatMessage(messages.physical);
    default:
      return intl.formatMessage(messages.none);
  }
};

const AppointmentDetails = ({ appointment, intl }) => (
  <div className="appointment-details">
    <div className="avatar-wrapper">
      <Avatar size={100} src={appointment._user.pictureUrl} />
      <H3 bold>{intl.formatMessage(messages.appointmentAbout)}</H3>
    </div>

    <ul className="infos">
      <li>
        <Icon name="rdv-video" style={{ width: '22px', height: '22px' }} /> {intl.formatMessage(messages.medium)}{appointmentMedium(appointment.settings.medium, intl)}
      </li>
      <li>
        <Icon name="slots-calendar" style={{ width: '22px', height: '18px' }} /> {intl.formatMessage(messages.duration)}{appointment._timeslot ? appointment._timeslot.duration : 30} min
      </li>
    </ul>

    <P className="advise">
      <FormattedMessage {...messages.aboutCandidate} values={{ candidate: appointment._user.fullName }} />
    </P>
    <P>
      <FormattedHTMLMessage
        {...messages.resumeConsultation}
        values={{ candidate: appointment._user.fullName }}
      />
      <a href={appointment._user.resumeUrl} target="_blank">lien</a>.
    </P>
  </div>
);

AppointmentDetails.propTypes = {
  appointment: object,
  intl: object,
};

export default injectIntl(AppointmentDetails);
