import { get, head, last } from 'lodash';
import moment from 'moment';
import { getId } from '@/utils/global';
import { track } from '@/utils/analytics/helpers';
import { getSlugsWhereSlotsAreAdded } from '@/features/agenda/recruiter/helpers';

export const trackRecruiterCreatedSlots = ({
  authUser,
  selectedUser,
  settings: { medium, duration, isMultiEvents },
  currentEvent,
  currentUserInExponent,
  newTimeslots,
  events = [],
}) => {
  const firstSlot = head(newTimeslots);
  const lastSlot = last(newTimeslots);
  const slugs = getSlugsWhereSlotsAreAdded({ slots: newTimeslots, events, currentEvent, isMultiEvents });

  return track({
    user: authUser,
    event: currentEvent,
    properties: {
      'User Calendar': get(currentUserInExponent, '_user.username'),
      isOwner: selectedUser === getId(authUser),
      date: moment(firstSlot).format('DD MMM Y'),
      medium,
      duration,
      'Start hour': moment(firstSlot.beginAt).format('HH:mm'),
      'End hour': moment(lastSlot.beginAt).add(lastSlot.duration, 'minute').format('HH:mm'),
      isShared: slugs.length > 1,
      countEvents: slugs.length,
      slugs,
    },
    intercom: true,
  });
};
