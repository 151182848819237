import React from 'react';
import { object, func } from 'prop-types';
import { compose } from 'redux';
import classnames from 'classnames';
import { isEmpty, upperFirst, get } from 'lodash'
import uuidv1 from 'uuid/v1';
import { injectIntl } from 'react-intl';
import { Form } from 'antd';
import PhoneInput from 'react-phone-number-input';
import { Button } from '@seekube-tech/ui-kit';
import { track } from '@/utils/analytics';
import { formatName, removeWhitespace } from '@/utils/format'
import request from '@/utils/request';
import { ANALYTICS_RECRUITER } from '@/utils/constants';
import { emailValidator } from '@/utils/text';

import { H3 } from '@/components/Title';
import Textarea from '@/components/Textarea';
import InputMaterial from '@/components/Input/InputMaterial';
import SocialLoginButton from '@/components/SocialLoginButton';
import Icon from '@/components/Icon';
import AvatarEditorDnd from '@/components/AvatarEditorDnd';
import styles from '@/scenes/Settings/styles.less';
import '@/scenes/Auth/scenes/Invit/components/SignupModal/styles.less';
import requestExternal from '@/utils/requestExternal';
import fetchService from '@/services/fetch';

const FormItem = Form.Item;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

class Identity extends React.PureComponent {
  static propTypes = {
    authUser: object,
    form: object,
    intl: object,
    onSave: func,
  };

  state = {
    phoneValue: this.props.authUser.phone,
    pictureUrl: get(this.props, 'authUser.pictureUrl') || null,
    linkedinStatus: null,
  };

  componentDidMount() {
    this.props.form.validateFields();
  }

  handleSaveProfilePicture = (fileUrl) => {
    this.setState({ pictureUrl: fileUrl });
  };

  updateInput = () => {
    const { form: { setFieldsValue } } = this.props;

    setFieldsValue({
      pictureUrl: this.state.pictureUrl,
    });
  }

  handleRemoveProfilePicture = () => {
    this.setState({
      pictureUrl: null,
    }, () => this.updateInput());
  }

  handleOnSocialLoginFailure = () => {
    this.setState({ linkedinStatus: 'error' });
  };

  handleSocialLogin = (user) => {
    const { props: { form: { setFieldsValue } } } = this;

    this.setState({ linkedinStatus: 'loading' });

    return new Promise((resolve, reject) => {
      request(`${process.env.FRONT_API_URL}/users/oauth/infos`, {
        method: 'POST',
        body: JSON.stringify({
          token: user._token.accessToken,
          provider: 'linkedin',
        }),
      })
        .then((result) => {
          this.setState({ linkedinStatus: 'success' });

          const tempUser = {
            firstName: result.infos.firstName,
            lastName: result.infos.lastName,
            title: result.infos.headline,
            description: result.infos.summary,
            linkedinProfileUrl: result.infos.publicProfileUrl,
          };

          setFieldsValue(tempUser);
          this.uploadExternalProfilePicture(result.infos.pictureUrl);

          track({
            user: Object.assign(this.props.authUser, tempUser),
            name: ANALYTICS_RECRUITER.IMPORT_LINKEDIN,
            properties: {
              step: 'Setting',
            },
          });
        })
        .catch((e) => {
          this.setState({ linkedinStatus: 'error' });
          reject(e);
        });
    });
  };

  uploadExternalProfilePicture = (pictureUrl) => {
    const { updateInput, props: { authUser } } = this;

    return new Promise((resolve, reject) => {
      fetchService(pictureUrl)
        .then((result) => result.blob())
        .then((blob) => {
          request(`${process.env.FRONT_API_URL}/users/${authUser._id}/upload?fileName=${uuidv1()}.jpg`)
            .then((response) => {
              const fileUrl = response.url;

              requestExternal(response.signedRequest, {
                method: 'PUT',
                headers: {
                  'Content-Type': blob.type,
                },
                body: blob,
              })
                .then(() => {
                  this.setState({
                    pictureUrl: fileUrl,
                  }, () => {
                    updateInput();
                  });
                })
                .catch((uploaderror) => console.log(uploaderror)); // eslint-disable-line
            })
            .catch((awserror) => console.log(awserror)); // eslint-disable-line
        })
        .catch((bloberr) => reject(bloberr));
    });
  };

  handleOnSubmit = (e) => {
    e.preventDefault();
    const {
      props: { onSave, form, intl, authUser },
      state: { pictureUrl },
    } = this;

    form.validateFields((err, values) => {
      if (!err) {
        if (values.username !== authUser.username) {
          values.usernameTemp = values.username;
          values.context = 'checkUsername';
        }

        onSave({
          ...values,
          phone: this.state.phoneValue || null,
          pictureUrl,
          notificationParams: {
            success: {
              message: ` ${intl.formatMessage({ id: 'form.information.success' })}`,
              kind: 'info',
              style: {
                bottom: '5%',
                top: 'inherit',
              },
            },
          },
        });
      }
    });
  };

  validatePhoneNumber = (rule, value, callback) => {
    const result = parseInt(value, 10);

    if (Number.isInteger(result) && value && value.length > 0 && value.length >= 6) {
      callback();
    }
    callback();
  };

  render() {
    const {
      props: { authUser, intl, form: { getFieldDecorator, getFieldsError, getFieldValue, getFieldError } },
      state: { pictureUrl, linkedinStatus, phoneValue },
      handleOnSubmit, handleSaveProfilePicture, validatePhoneNumber,
    } = this;

    const formHasChanged =
      authUser.firstName !== getFieldValue('firstName') ||
      authUser.lastName !== getFieldValue('lastName') ||
      authUser.title !== getFieldValue('title') ||
      authUser.title !== getFieldValue('description') ||
      (authUser.pictureUrl !== undefined && authUser.pictureUrl !== pictureUrl) ||
      ((authUser.pictureUrl === null || authUser.pictureUrl === undefined) && pictureUrl !== null);

    return (
      <Form className={classnames(styles.emailFormWrapper, styles.recruiterForm)} onSubmit={handleOnSubmit}>
        <div>
          <div className={styles.picture}>
            <H3 bold>{intl.formatMessage({ id: 'profile.settings.general.identity.title' })}</H3>

            <SocialLoginButton
              type="link"
              provider="linkedin"
              appId={process.env.FRONT_LINKEDIN_CLIENT_ID}
              onLoginSuccess={this.handleSocialLogin}
              onLoginFailure={this.handleOnSocialLoginFailure}
            >
              <Button size="large" color={linkedinStatus === 'error' ? 'error' : 'neutral'} loading={linkedinStatus === 'loading'}>
                <Icon name="linkedin" className="mr-10" />
                {[null, 'loading'].includes(linkedinStatus) && intl.formatMessage({ id: 'event.recruiter.preparation.signup.identity.linkedin.import' })}
                {linkedinStatus === 'success' && intl.formatMessage({ id: 'event.recruiter.preparation.signup.identity.linkedin.success' })}
                {linkedinStatus === 'error' && intl.formatMessage({ id: 'event.recruiter.preparation.signup.identity.linkedin.error' })}
              </Button>
            </SocialLoginButton>

            <p className={`help-linkedin ${styles.text}`} style={{ fontSize: '12px' }}>{intl.formatMessage({ id: 'event.recruiter.preparation.signup.identity.linkedin.security' })} <span role="img" aria-label="Smiling Face With Halo"> 😇</span></p>

            <AvatarEditorDnd
              src={pictureUrl || undefined}
              onSave={handleSaveProfilePicture}
              onRemove={this.handleRemoveProfilePicture}
              objectId={authUser._id}
              size={130}
              user={authUser}
            >
              <div>
                <Button variant="outline" style={{ display: 'block' }}>
                  {`${intl.formatMessage({ id: 'pictureUrl.choose' })} (${intl.formatMessage({ id: 'event.recruiter.preparation.signup.identity.pictureUrl.recommendation' })})`}
                </Button>

                <p className="upload-infos">
                  {intl.formatMessage({ id: 'event.recruiter.preparation.signup.identity.pictureUrl.help' })}
                </p>
              </div>
            </AvatarEditorDnd>
          </div>
        </div>
        <FormItem>
          {getFieldDecorator('firstName', {
            initialValue: authUser.firstName,
            rules: [
              { required: true, message: intl.formatMessage({ id: 'form.error.required' }) },
            ],
          })(
            <InputMaterial
              addOnIcon="user"
              label={intl.formatMessage({ id: 'firstName' })}
              placeholder={intl.formatMessage({ id: 'firstName' })}
              type="text"
              getError={!isEmpty(getFieldError('firstName'))}
              applyFormatValue={formatName}
            />
          )}
        </FormItem>
        <FormItem className={styles.formItem}>
          {getFieldDecorator('lastName', {
            initialValue: authUser.lastName,
            rules: [
              { required: true, message: intl.formatMessage({ id: 'form.error.required' }) },
            ],
          })(
            <InputMaterial
              addOnIcon="user"
              label={intl.formatMessage({ id: 'lastName' })}
              placeholder={intl.formatMessage({ id: 'lastName' })}
              type="text"
              getError={!isEmpty(getFieldError('lastName'))}
              applyFormatValue={formatName}
            />
          )}
        </FormItem>

        <FormItem className={styles.formItem}>
          {getFieldDecorator('title', {
            initialValue: authUser.title,
            rules: [
              { required: true, message: intl.formatMessage({ id: 'form.error.required' }) },
            ],
          })(
            <InputMaterial
              addOnIcon="jobPosition"
              label={intl.formatMessage({ id: 'event.recruiter.preparation.signup.identity.form.jobPosition.label' })}
              placeholder={intl.formatMessage({ id: 'event.recruiter.preparation.signup.identity.form.jobPosition.label' })}
              type="text"
              getError={!isEmpty(getFieldError('title'))}
              applyFormatValue={upperFirst}
            />
          )}
        </FormItem>

        <FormItem>
          {getFieldDecorator('username', {
            initialValue: authUser ? authUser.username : '',
            normalize: removeWhitespace,
            rules: [
              { validator: emailValidator },
              { required: true, message: intl.formatMessage({ id: 'field.required' }) },
              { required: true, message: intl.formatMessage({ id: 'field.required' }) },
            ],
          })(
            <InputMaterial
              addOnIcon="mail"
              label={intl.formatMessage({ id: 'event.recruiter.preparation.signup.login.form.username.label' })}
              placeholder="johndoe@email.com"
              type="email"
              validations={['email', 'required']}
            />
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('phone', {
            initialValue: phoneValue,
            rules: [
              { validator: validatePhoneNumber, required: false },
            ],
          })(
            <PhoneInput
              defaultCountry={isEmpty(this.state.phoneValue) && intl.locale === 'en' ? 'GB' : isEmpty(this.state.phoneValue) ? 'FR' : null}
              className="inputPhone"
              label={intl.formatMessage({ id: 'phone' })}
              placeholder={intl.formatMessage({ id: 'phone' })}
              onChange={(phoneValue) => { this.setState({ phoneValue }); }}
            />
          )}
        </FormItem>
        <FormItem
          className="textareaWrapper"
        >
          {getFieldDecorator('description', {
            initialValue: authUser ? authUser.description : '',
            rules: [{ required: false }],
          })(
            <Textarea
              label={intl.formatMessage({ id: 'event.recruiter.preparation.signup.identity.form.description.label' })}
              placeholder=""
              maxLength="1000"
              style={{ height: '8em' }}
            />
          )}
        </FormItem>
        {getFieldDecorator('linkedinProfileUrl', {
          initialValue: '',
          rules: [{ required: false }],
        })(
          <input type="hidden" />
        )}

        <Button type="submit" disabled={hasErrors(getFieldsError()) || !formHasChanged}>
          {intl.formatMessage({ id: 'save' })}
        </Button>
      </Form>
    );
  }
}
const withForm = Form.create();
export default compose(
  withForm,
  injectIntl)(Identity);
