import React from 'react';

// components
import { func, object, string, oneOf, any, bool } from 'prop-types';
import { Form, Select as SelectAnt } from 'antd';
import { TEMPLATE_CRITERIA_ID } from '@/utils/global';
import { Select, Selector } from '@/components/Form';
import { isEmpty } from 'lodash';
import { useFormatMessage } from 'react-intl-hooks';

const FormItem = Form.Item;
const { Option } = SelectAnt;

export const Custom = ({ props, state, handleCriterionOnChange, authUser }) => {// eslint-disable-line
  const t = useFormatMessage();

  const {
    context,
    fieldDecoratorKey,
    getFieldDecorator,
    initialValue,
    criterion,
    validatorCallback,
    withInputErrorStyle,
    noIcon,
    showHelper,
    onChange,
    form: { getFieldError },
    disableDuration,
    required
  } = props;

  const { selectedValues } = state;

  const isSingleSelect = criterion.modules[context].choiceMax < 2;

  let options;

  const onCriterionSelect = (value) => {
    handleCriterionOnChange([...selectedValues, value]);
  };

  const onCriterionDeSelect = (value) => {
    const valueIndex = selectedValues.findIndex((item) => item === value);
    selectedValues.splice(valueIndex, 1);
    handleCriterionOnChange(selectedValues);
  };


  if (criterion.key === 'JOBS') {
    options = criterion._jobs.map((job) => (
      <Option key={`customFilterChoice${job._id}`} value={job._id && job._id.toString()}>{job.name}</Option>
    ));
  } else if (criterion.key === 'CONTRACT') {
    options = criterion._choices
      .filter((choice) => choice.enable)
      .map((choice) => {
          if (authUser.locale === 'fr') {
            return (
              <Option key={`customFilterChoice${choice._id.toString()}`} value={choice._id.toString()}>
                {choice.label}
              </Option>
            );
          } if (authUser.locale === 'en' && choice.label_en) {
            return (
              <Option key={`customFilterChoice${choice._id.toString()}`} value={choice._id.toString()}>
                {choice.label_en}
              </Option>
            );
          } return null;
        }
      );
  } else {
    options = criterion._choices
      .filter((choice) => choice.enable)
      .map((choice) => {
        if (authUser.locale === 'fr') {
          return (
            <Option key={`customFilterChoice${choice._id.toString()}`} value={choice._id.toString()}>
              {choice.label}
            </Option>
          );
        } if (authUser.locale === 'en' && choice.label_en) {
          return (
            <Option key={`customFilterChoice${choice._id.toString()}`} value={choice._id.toString()}>
              {choice.label_en}
            </Option>
          );
        } return null;
      }
      );
  }

  const isRequired = required ?? (criterion.key === 'DURATION' ? criterion.modules[context].choiceMin > 0 && !disableDuration : criterion.modules[context].choiceMin > 0);
  let rules = [];

  if (criterion.key === 'CONTRACT' && !criterion.isCustomContract) {
    rules = [{
      required: isRequired, message: t({ id: 'form.mandatory' }),
    }];

    if (validatorCallback) {
      rules.push({ validator: validatorCallback });
    }
  } else {
    rules = [{ required: isRequired, message: t({ id: `form.mandatory` }) }];
  }

  const maxItems = isSingleSelect ? 1 : criterion.modules[context].choiceMax;

  return (
    <div id={fieldDecoratorKey} style={{ display: 'inline' }}>
      <FormItem>
        {getFieldDecorator(fieldDecoratorKey, {
          initialValue: isSingleSelect && initialValue && typeof initialValue !== 'string' ? initialValue[0] : initialValue,
          rules,
        })(
          (criterion.key === 'MOBILITY' && context !== 'offer') || criterion.key === 'POSITION' ? (
            <Selector
              label={authUser.locale === 'fr' ? criterion.modules[context].label : (authUser.locale === 'en' && criterion.modules[context].label_en ? criterion.modules[context].label_en : criterion.modules[context].label)}
              noLabel="No label"
              isLocation={criterion.key === 'MOBILITY'}
              isTree={!!criterion._choices.find((o) => o._parent)}
              limit={criterion.key === 'POSITION' ? 6 : 99999}
              selectAll={criterion.key !== 'POSITION'}
              defaultExpandAll={criterion.key === 'POSITION'}
              isInput
              icon={criterion.icon}
              required
              noBadge
              options={authUser.locale === 'fr' ? criterion._choices.filter((c) => c.enable).map((o) => ({ value: o._id, label: o.label, parent: o._parent, badgeCount: 1 })) : criterion._choices.filter((c) => c.enable).map((o) => ({ value: o._id, label: o.label_en ? o.label_en : o.label, parent: o._parent, badgeCount: 1 }))}
              values={typeof selectedValues !== 'string' ? selectedValues : [selectedValues]}
              hasError={withInputErrorStyle && getFieldError(fieldDecoratorKey)}
              onChange={(e) => {
                handleCriterionOnChange(e);
                if (onChange) onChange(fieldDecoratorKey, e);
              }}
            />
          ) : (
            <Select
              showSearch={(criterion._choices && criterion._choices.length > 10) || (criterion._jobs && criterion._jobs.length > 10)}
              mode={isSingleSelect ? 'default' : 'multiple'}
              addOnIcon={!noIcon ? criterion.icon : null}
              placeholder={authUser.locale === 'fr' ? criterion.modules[context].label : (authUser.locale === 'en' && criterion.modules[context].label_en ? criterion.modules[context].label_en : criterion.modules[context].label)}
              optionFilterProp="children"
              required={isRequired}
              filterOption={(input, option) => option.props.children && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              getPopupContainer={() => document.getElementById(fieldDecoratorKey)}
              hasError={getFieldError(fieldDecoratorKey)}
              withInputErrorStyle={withInputErrorStyle}
              showHelper={showHelper}
              onChange={(values) => onChange ? onChange(fieldDecoratorKey, values) : ''}
              onSelect={onCriterionSelect}
              onDeselect={onCriterionDeSelect}
              disabled={(criterion.key === 'DURATION' && disableDuration) || props.isDisabled}
              showDropdown={selectedValues.length < criterion.modules[context].choiceMax || isSingleSelect}
              allowClear={isSingleSelect}
              maxItems={maxItems}
            >
              {options}
            </Select>
          )
        )}
        {criterion.modules[context].help ? <p className="help" style={{ paddingTop: '8px', lineHeight: '1.2em', fontStyle: 'italic', fontSize: '0.9em', marginBottom: 0 }}>
          {authUser.locale === 'fr' ? criterion.modules[context].help : (authUser.locale === 'en' && criterion.modules[context].help_en ? criterion.modules[context].help_en : criterion.modules[context].help)}
        </p> : null}
      </FormItem>
    </div>
  );
};

Custom.propTypes = {
  authUser: object,
  props: object,
  state: object,
  criterion: object,
  getFieldDecorator: func,
  fieldDecoratorKey: string,
  context: oneOf(['offer', 'onboarding']),
  initialValue: any,
  validatorCallback: func,
  showHelper: func,
  onChange: func,
  disableDuration: bool,
  form: object,
  withInputErrorStyle: bool,
  noIcon: bool,
  required: bool
};
