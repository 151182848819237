import React from 'react';
import classnames from 'classnames';
import { bool, func, number, object, string } from 'prop-types';
import { debounce } from 'lodash';
import { useFormatMessage } from 'react-intl-hooks';
import { H2 } from '@/components/Title';
import InlineEditor from '@/components/InlineEditor';
import WysiwygEditor, { isWysiwygFieldCompleted } from '@/components/WysiwygEditor';
import Html from '@/components/Html';
import { If } from '@/components/If';
import styles from './styles.less';

/**
 * Title Component used for each content in DefaultRedirection
 */
const Title = ({
  event,
  contentIndex,
  editMode,
  onChange,
  whiteColor,
  locale,
}) => {
  const t = useFormatMessage()
  const isRoadmap = event._contents[contentIndex].name === 'roadmap'
  return (
    <div className={styles.blocContainer}>
      <div className={styles.titleContainer}>
        <H2 bold className={classnames(whiteColor ? styles.whiteColor : null)}>
          <InlineEditor
            editorEnabled={editMode}
            value={event._contents[contentIndex][locale].h2}
            onChange={onChange(['_contents', contentIndex, locale, 'h2'])}
          />
        </H2>
      </div>
      {editMode ? (
        <div className={styles.wysiwygEditorContainer}>
          <WysiwygEditor
            initialValue={isRoadmap ? '' : event._contents[contentIndex][locale].small}
            onChange={(val) => typeof val === 'string' ? debounceFunc(() => onChange(['_contents', contentIndex, locale, 'small'])(val)) : null}
          />
        </div>
      ) : (
        <If condition={event._contents[contentIndex][locale].small !== t({id: 'event.content.text.small'}) && isWysiwygFieldCompleted(event._contents[contentIndex][locale].small)}>
          <Html value={event._contents[contentIndex][locale].small} />
        </If>
      )
      }
    </div>
)};

const debounceFunc = debounce((fct) => fct(), 1500);

Title.propTypes = {
  event: object,
  contentIndex: number,
  whiteColor: bool,
  editMode: bool,
  onChange: func,
  locale: string,
};

Title.defaultProps = {
  locale: 'fr',
};

export default Title;
