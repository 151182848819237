import React from 'react';
import { object, bool } from 'prop-types';
import { Avatar } from '@seekube-tech/ui';
import { useFormatMessage } from 'react-intl-hooks';
import { EVENT_TYPE } from '@/utils/constants';
import { getEventPhases, isEventInPreregistration } from '@/store/availableActions/utils/event';
import styles from './styles.less';

const OrganizationCard = ({
  exponent,
  stats,
  withOpportunities,
  withJobdatings,
  event,
}) => {
  const t = useFormatMessage();
  const eventPhases = getEventPhases(event);
  const preregistration = isEventInPreregistration(eventPhases);
  const organization = exponent._organization ? exponent._organization : exponent;
  const countUsers = exponent.users ? exponent.users.length : exponent.countUsers;
  const countOffers = stats ? stats.offers : 0;
  const countJobdating = exponent.countExponents;

  const renderRecruiterAndOpportunities = () => {
    if (preregistration) return null;
    const renderOpportunities = countOffers && withOpportunities ? `• ${countOffers} ${t({ id: 'opportunities' }, { count: countOffers })} ` : '';
    const renderJobdatings = countJobdating >= 0 && withJobdatings ? `• ${countJobdating} ${t({ id: 'organization.jobdating' }, { count: countJobdating })} ` : '';

    if (event?.type === EVENT_TYPE.jpo) {
     const renderPresenters = `${t({ id: 'dashboard.realtime.liveCard.presenters'}, { countPresenters: countUsers })} `
     return `${renderPresenters} ${renderJobdatings}`;
   }
    return `${countUsers} ${t({ id: 'recruiters' }, { count: countUsers })} ${renderOpportunities} ${renderJobdatings}`;
  };

  return (
    <div className={`${styles.organizationCard}`}>
      <div className={styles.defaultCard}>
        {exponent.profile?.pictureUrl || organization.profile?.pictureUrl ? (
          <div className={styles.picture}>
            <Avatar
              variant="business"
              src={exponent.profile?.pictureUrl || organization.profile.pictureUrl}
              color={exponent?.isPartner ? event?.modules?.partner?.color : 'default'}
              badge={exponent?.isPartner ? event?.modules?.partner?.icon : null}
            />
          </div>
        ) : (
          <div className={styles.noPicture} />
        )}

        <div className={styles.name}>
          <h3>{organization.name}</h3>
          <p>{renderRecruiterAndOpportunities()}</p>
        </div>
      </div>
    </div>
  );
};

OrganizationCard.propTypes = {
  exponent: object,
  stats: object,
  withOpportunities: bool,
  withJobdatings: bool,
  event: object,
};

export default OrganizationCard;
