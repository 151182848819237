import React from 'react';
import { oneOf, string } from 'prop-types';
import 'moment-business-days';
import 'moment-countdown';
import classNames from 'classnames';

// Components
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

// Styles & Translations
import styles from './styles.less';
import messages from './messages';

/**
 * Countdown
 */
class Progress extends React.PureComponent { // eslint-disable-line
  static propTypes = {
    activeItem: oneOf(['preparation', 'jobdating', 'report']),
    eventSlug: string,
    role: string,
  };

  static defaultProps = {
    activeItem: 'preparation',
  };

  /**
   * Render
   */
  render() {
    const {
      props: { activeItem, eventSlug, role },
    } = this;

    return (
      <div className={styles.progressContainer}>
        <div className={classNames(styles.progressItem, activeItem === 'preparation' ? styles.activeItem : '')}>
          <span className={styles.bullet} />
          <Link to={`/${eventSlug}/${role}/preparation`}>
            <var className={styles.stepLabel}><FormattedMessage {...messages.stepPreparationLabel} /></var>
          </Link>
          <div className={styles.helpPanel}>
            <p><FormattedMessage {...messages.stepPreparationHelp} /></p>
          </div>
        </div>
        <div className={styles.progressBar} />
        <div className={classNames(styles.progressItem, activeItem === 'jobdating' ? styles.activeItem : '')}>
          <span className={styles.bullet} />
          <Link to={`/${eventSlug}/${role}/jobdating`}>
            <var className={styles.stepLabel}><FormattedMessage {...messages.stepFairLabel} /></var>
          </Link>
          <div className={styles.helpPanel}>
            <p><FormattedMessage {...messages.stepFairHelp} /></p>
          </div>
        </div>
      </div>
    );
  }
}

export default Progress;
