import { makeStyles } from '@seekube-tech/ui';

export default makeStyles(theme => ({
  root: {
    padding: theme.spacing(4, 6),
    width: '100%',
  },
  description: {
    marginTop: theme.spacing(0.5),
  },
  card: {
    marginTop: theme.spacing(6),
  },
  required: {
    marginTop: theme.spacing(2),
  },
  asterisk: {
    color: theme.palette.error[500],
  },
  field: {
    marginTop: theme.spacing(1.5),
  },
  samplesContainer: {
    marginTop: theme.spacing(4),
  },
  samples: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(1.5),
    backgroundColor: theme.palette.neutral[100],
  },
  sendBtn: {
    marginTop: theme.spacing(4.5),
  },
  divider: {
    margin: theme.spacing(6.5, 0),
  },
}));