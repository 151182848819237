import { fromJS } from 'immutable';
import { keyBy } from 'lodash';
import { handleActions } from 'redux-actions';
import * as types from './types';

const initialState = fromJS({
  isFetching: false,
  byUserId: {},
  byKey: {},
  list: [],
});

export default handleActions({
  [types.GET_ROLES]: (state) => state.set('isFetching', true),
  [types.GET_ROLES_SUCCESS]: (state, { payload }) => (
      state
      .set('byKey', keyBy(payload.items, 'key'))
      .set('byId', keyBy(payload.items, '_id'))
      .set('isFetching', false)
    ),
}, initialState);
