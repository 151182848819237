import React from 'react';
import { array } from 'prop-types';
import { isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';

import { Tag, IconLink } from '@seekube-tech/ui-kit';

import styles from './styles.less';
import messages from './messages';

const MAX_CHARACTER = 70;

const renderOffers = (offers) => (
  offers.filter((o) => !!o).map((o, index) => <Tag key={`${index}${o.title}`} variant="outline" color="grey" className={styles.fontSize} icon={<IconLink size={15} />}>{sliceLongText(o.title || '')}</Tag>)
);

const OffersApplied = ({ offers }) => {
  if (isEmpty(offers)) return null;

  return (
    <div className={styles.offersApplied}>
      <h5 className="profile-section-title"><FormattedMessage {...messages.headingApply} /></h5>
      {renderOffers(offers)}
    </div>
  );
};

const sliceLongText = (text) => text.length > MAX_CHARACTER ? `${text.slice(0, MAX_CHARACTER)}...` : text;

OffersApplied.propTypes = {
  offers: array,
};

export { OffersApplied };
