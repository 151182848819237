import { isEmpty } from 'lodash';
import moment from 'moment';

export const timeslotUtils = {
  /**
   * getTimeslotsComputedDataByDay
   *
   * @description Get the slots for the selected day and create a day navigation
   * @param timeslots
   * @param day
   * @return {timeslots, timeslotsAM, timeslotsPM, lastDay}
   */
  getTimeslotsComputedDataByDay: (timeslots, day) => {
    if (isEmpty(timeslots)) return null;

    // We defined the start and end of the selected day (current day by default)
    const startDay = window.moment(day).startOf('day');
    const endDay = window.moment(day).endOf('day');

    // Reorder slots by date
    timeslots.sort(reorderTimeslotsByDate);

    // We need the last day of timeslots for day navigation
    const lastDay = timeslots[timeslots.length - 1];

    // Get the slots of the day
    const daySlots = timeslots
    .filter((slot) => window.moment(slot.beginAt).isSameOrAfter(startDay) && window.moment(slot.beginAt).isBefore(endDay));

    // Divide slots by meridiem
    const timeslotsAM = _assignTimeslotsByMeridiem(daySlots, 0, 12);
    const timeslotsPM = _assignTimeslotsByMeridiem(daySlots, 12, 24);

    return {
      timeslots: daySlots,
      timeslotsAM,
      timeslotsPM,
      lastDay,
    };
  },
  /**
   * hasAvailableSlots
   *
   * @description Tests whether at least one timeslot in the array doesn't have appointment and is available
   * @param timeslots
   * @return boolean
   */
  hasAvailableSlots: (timeslots) => timeslots.some((slot) => isEmpty(slot._appointment) && slot.available),
  /**
   * getTimeslotsByEvents
   *
   * @description Get timeslots list for every user events
   * @param timeslots
   * @param events
   */
  getTimeslotsByEvents: (timeslots, events) => {
    const timeslotsByEvents = {};
    const now = moment();

    events.forEach((event) => {
      timeslotsByEvents[event.id] = timeslots.filter((timeslot) => (
        isEmpty(timeslot._appointment) &&
        moment(timeslot.beginAt) >= now &&
        moment(timeslot.beginAt) >= moment(event.keyDates.jobfair.beginAt) &&
        moment(timeslot.beginAt) <= moment(event.keyDates.jobfair.endAt) &&
        (isEmpty(timeslot._event) || timeslot._event._id === event.id)
      ));
    });

    return timeslotsByEvents;
  },
};

/**
 * reorderTimeslotsByDate
 *
 * @description : reorder timeslots by date (beginAt)
 */
export const reorderTimeslotsByDate = (a, b) => {
  if (a.beginAt < b.beginAt) {
    return -1;
  }

  if (a.beginAt > b.beginAt) {
    return 1;
  }
  return 0;
};

/**
 * _assignTimeslotsByMeridiem
 *
 * @description : divide slots by meridiem (am/pm)
 */
const _assignTimeslotsByMeridiem = (slots, startHour, endHour) => (
  slots
    .filter((slot) => window
      .moment(slot.beginAt)
      .format('HH') >= startHour && window.moment(slot.beginAt).format('HH') < endHour
    )
);
