import { createAction } from 'redux-actions';
import * as types from './types';

/**
 * Set current user
 */
export const setCurrentUser = createAction(types.SET_CURRENT_USER);

/**
 * Get an user
 */
export const getUser = createAction(types.GET_USER);
export const getUserSuccess = createAction(types.GET_USER_SUCCESS);
export const getUserError = createAction(types.GET_USER_ERROR);

/**
 * Actions for user
 */
export const patchUser = createAction(types.PATCH_USER);
export const patchMe = createAction(types.PATCH_ME);
export const patchUserSuccess = createAction(types.PATCH_USER_SUCCESS);
export const putUserAfterSignUp = createAction(types.PUT_USER_AFTER_SIGNUP);
export const patchUserBeforeSignUp = createAction(types.PATCH_USER_BEFORE_SIGNUP);
export const deleteUser = createAction(types.DELETE_USER);
export const deleteUserSuccess = createAction(types.DELETE_USER_SUCCESS);

export const patchUsers = createAction(types.PATCH_USERS);
export const patchUsersSuccess = createAction(types.PATCH_USERS_SUCCESS);

/**
 * Actions for get all users in an organizations
 */
export const getUsersOrganization = createAction(types.GET_USERS_ORGANIZATION);
export const getUsersOrganizationSuccess = createAction(types.GET_USERS_ORGANIZATION_SUCCESS);
export const getUsersOrganizationError = createAction(types.GET_USERS_ORGANIZATION_ERROR);
export const deleteUserFromOrganization = createAction(types.DELETE_USER_FROM_ORGANIZATION);

/**
 * Actions for post new user link to a organization
 */
export const postUserOrganization = createAction(types.POST_USER_ORGANIZATION);
export const postOrganizationUserAndAddToExponent = createAction(types.POST_USER_ORGANIZATION_AND_ADD_TO_EXPONENT);
export const postUserOrganizationSuccess = createAction(types.POST_USER_ORGANIZATION_SUCCESS);
export const postOrganizationError = createAction(types.POST_USER_ORGANIZATION_ERROR);

/**
 * Get an user
 */
export const getUserLastParticipation = createAction(types.GET_USER_LAST_PARTICIPATION);
export const getUserLastParticipationSuccess = createAction(types.GET_USER_LAST_PARTICIPATION_SUCCESS);
export const getUserLastParticipationError = createAction(types.GET_USER_LAST_PARTICIPATION_ERROR);

export const resetDemonstration = createAction(types.RESET_DEMONSTRATION);
export const resendInvitationEmail = createAction(types.RESEND_INVITATION_EMAIL);
export const switchOrganization = createAction(types.SWITCH_ORGANIZATION);

export const getSyncConnectUrl = createAction(types.GET_SYNC_CONNECT_URL);
export const getSyncDisconnectUrl = createAction(types.GET_SYNC_DISCONNECT_URL);
export const postSyncCode = createAction(types.POST_SYNC_CODE);
export const setCalendars = createAction(types.SET_CALENDARS);
export const getCalendars = createAction(types.GET_CALENDARS);
