import { defineMessages } from 'react-intl';

export default defineMessages({
  'results.applications': {
    id: 'participants.results.applications',
  },
  'results.sourcing': {
    id: 'participants.results.sourcing',
  },
  'results.selection': {
    id: 'participants.results.selection',
  },
  'results.all': {
    id: 'participants.results.all',
  },
  'results.top': {
    id: 'participants.results.top',
  },
  'results.save': {
    id: 'participants.results.save',
  },
  'results.interview': {
    id: 'participants.results.interview',
  },
  'results.refuse': {
    id: 'participants.results.refuse',
  },
  appointmentGroupedConfirm: {
    id: 'appointment.grouped.confirm',
  },
  appointmentGroupedRefuse: {
    id: 'appointment.grouped.refuse',
  },
  appointmentGroupedPropose: {
    id: 'appointment.grouped.propose',
  },
  appointmentGroupedHide: {
    id: 'appointment.grouped.hide',
  },
  appointmentGroupedDownload: {
    id: 'appointment.grouped.download',
  },
  participantsExportAllCvs: {
    id: 'owner.participants.export.all.cvs',
  },
  participantsExportContextCvs: {
    id: 'owner.participants.export.context.cvs',
  },
  participantsExportContextList: {
    id: 'owner.participants.export.context.list',
  },
  participantsExportList: {
    id: 'owner.participants.export.list',
  },
  candidates: {
    id: 'candidates',
  },
  groupedPagination: {
    id: 'grouped.pagination',
  },
  sortBy: {
    id: 'sortBy',
  },
  newApplications: {
    id: 'newApplications',
  },
  newCandidates: {
    id: 'new.candidates',
  },
  newParticipants: {
    id: 'newParticipants',
  },
  pertinence: {
    id: 'pertinence',
  },
  addDate: {
    id: 'addDate',
  },
  page: {
    id: 'page',
  },
  syncModalIntercomTitle: {
    id: 'synchronization.modal.intercom.title',
  },
  syncModalIntercomSubtitle: {
    id: 'synchronization.modal.intercom.subtitle',
  },
  syncModalIntercomSuccess: {
    id: 'synchronization.modal.intercom.success',
  },
  syncModalIntercomFailure: {
    id: 'synchronization.modal.intercom.failure',
  },
  syncIntercomSubmit: {
    id: 'synchronization.intercom.submit',
  },
  exportProfileBtn: {
    id: 'admin.candidate.export.profile.btn',
  },
  appointmentPropose: {
    id: 'recruiter.participantsList.appointment.propose',
  },
  hide: {
    id: 'hide',
  },
  move: {
    id: 'recruiter.participantsList.move',
  },
  addToInterview: {
    id: 'recruiter.participantsList.add.to.interview',
  },
  addTo_save: {
    id: 'recruiter.participantsList.add.to.save',
  },
  addTo_top: {
    id: 'recruiter.participantsList.add.to.top',
  },
  addTo_refuse: {
    id: 'recruiter.participantsList.add.to.refuse',
  },
  selectAll: {
    id: 'event.recruiter.preparation.offer.selectAll',
  },
  groupedActions: {
    id: 'groupedActions',
  },
  downloadCvs: {
    id: 'download.cvs',
  },
  offers: {
    id: 'offers',
  },
});
