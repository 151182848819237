import { useQuery } from 'react-query';
import { isEmpty } from 'lodash';
import request from '@/utils/request';
import { getId } from '@/utils/global';

export function useGetOrganizationUserOffers(
  {
    recruiterId = null,
    search = {},
    status = 'published',
    keywords = '',
    checkValidateFields = [],
    authUser,
  }) {
  const params = `?offset=${search.offset}&limit=${search.limit}&page=${search.page}&status=${status}&keywords=${keywords}`;
  let additionalParam = '';

  if (search.allUsers === true) {
    additionalParam += '&allUsers=true';
  }

  if (!isEmpty(search.updatedAt)) {
    additionalParam += `&updatedAt=${search.updatedAt}`;
  }

  if (!isEmpty(search.recruiterSelectedIds)) {
    if (typeof search.recruiterSelectedIds === 'string') {
      additionalParam += `&userIds=${search.recruiterSelectedIds}`;
    } else {
      additionalParam += `&userIds=${encodeURIComponent(search.recruiterSelectedIds.join(','))}`;
    }
  }

  if (!isEmpty(search.eventSelectedIds)) {
    if (typeof search.eventSelectedIds === 'string') {
      additionalParam += `&eventIds=${search.eventSelectedIds}`;
    } else {
      additionalParam += `&eventIds=${encodeURIComponent(search.eventSelectedIds.join(','))}`;
    }
  }

  if (!isEmpty(search.contractSelectedIds)) {
    if (typeof search.contractSelectedIds === 'string') {
      additionalParam += `&contractIds=${search.contractSelectedIds}`;
    } else {
      additionalParam += `&contractIds=${encodeURIComponent(search.contractSelectedIds.join(','))}`;
    }
  }

  if (!isEmpty(checkValidateFields)) {
    if (typeof checkValidateFields === 'string') {
      additionalParam += `&checkValidateFields=${checkValidateFields}`;
    } else {
      additionalParam += `&checkValidateFields=${encodeURIComponent(checkValidateFields.join(','))}`;
    }
  }

  const organizationId = getId(authUser._currentOrganization);
  const authUserId = getId(authUser);
  const requestUrl = `${process.env.FRONT_API_URL}/organizations/${organizationId}/users/${recruiterId || authUserId}/offers${params}${additionalParam}`;

  const queryFn = () => request(requestUrl, { method: 'GET' });

  const query = useQuery({
    queryKey: ['organizationUserOffers', organizationId, keywords, search.page, search.offset, search.updatedAt, search.recruiterSelectedIds, search.contractSelectedIds, search.eventSelectedIds, status],
    queryFn,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });

  return query;
}
