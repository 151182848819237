import React from 'react';
import { array, object } from 'prop-types';
import { map } from 'lodash';
import { Table } from '@seekube-tech/ui-kit';


import Columns from './Columns';

const StatsTeamActivity = ({ activities, authUser }) => (
    <Table
      getCustomTableProps={() => ({ style: { width: '100%' } })}
      data={map(activities, (activity, key) => ({ key, ...activity }))}
      columns={Columns({ authUser })}
    />
);

StatsTeamActivity.propTypes = {
  activities: array,
  authUser: object,
};

export { StatsTeamActivity };
