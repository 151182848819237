import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { array, bool, func, number, object, string } from 'prop-types';
import { compose } from 'redux';
import { size, get } from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import { Modal, Form, Select as SelectAnt } from 'antd';
import { useFormatMessage } from 'react-intl-hooks';
import { Button, Alert } from '@seekube-tech/ui-kit';
import { toJS } from '@/utils';
import { isRecruiterLimitExceeded } from '@/utils/pipeDrive';

// components
import Icon from '@/components/Icon';
import Select from '@/components/Form/Select';

// Store
import { eventSelectors } from '@/store/event';

// styles
import { EVENT_FORMAT } from '@/utils/constants';
import styles from '../../../../styles.less';
import messages from '../../../../messages';

const FormItem = Form.Item;
const { Option } = SelectAnt;

const AddJobdatingModal = ({
  form,
  visible,
  handleClose,
  intl,
  events,
  existEvents,
  exponents,
  onSubmit,
  maxRecruiter,
  countMemberSelected,
  context,
  user
}) => {

  const t = useFormatMessage();
  const [jobdatings, setJobdatings] = useState([]);
  const [keyMomentFormatsByEvent, setKeyMomentFormatsByEvent] = useState({});
  const [errorParticipationMode, setErrorParticipationMode] = useState([]);

  // Filter events from /events
  const availableEvents = events
  .filter((event) => event.keyDates && moment(event.keyDates.jobfair.endAt) > moment())
  .map((event) => ({ ...event, recruiterMax: maxRecruiter }));

  // If there are exponents, pick events from exponents, otherwise pick events from events list
  const finalEvents = exponents.length ?
    exponents
      /**
       * We filter out events:
       * - without keyDates
       * - that are forthcoming
       * - that our user doesn't attend to already
       */
      .filter((exponent) => (
      exponent._event.keyDates
      && moment(exponent._event.keyDates.jobfair.endAt) > moment()
      && !exponent?.users?.find(exponentUserUser => exponentUserUser?._user?._id === user?._id))
    )
    .map((exponent) => ({ ...exponent._event, countUsers: size(exponent.users), recruiterMax: get(exponent, 'limits.recruiterMax') }))
    : availableEvents;

  useEffect(() => setJobdatings([]), [])

  const handleChange = (key, value) => {
    if (!jobdatings.includes({ key, name: value.props.children })) {
      setJobdatings(jobdatings.concat({ key, name: value.props.children }));
      form.resetFields();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errorList =
      events
      .filter((_event) => jobdatings.find(job => job.key === _event._id) && _event.format === EVENT_FORMAT.HYBRID && !keyMomentFormatsByEvent[_event._id])
      .map(_event => _event.id)
    setErrorParticipationMode(errorList);

    if (!errorList.length) {
      const ids = jobdatings.map((_jobdating) => _jobdating.key);

      onSubmit(ids, keyMomentFormatsByEvent);
      setJobdatings([]);
      setErrorParticipationMode([]);
    }
  };

  const shouldDisplayRecruiterLimitWarning = () => {
    if (jobdatings.length === 0 || maxRecruiter < 100) return false;

    const jobdatingsSelectedIds = jobdatings.map((jobdating) => jobdating.key.toString());

    const exponentsSelected = exponents.filter((exponent) =>
      jobdatingsSelectedIds.indexOf(exponent._event._id) > -1
      && (exponent, exponent.users.length)
    );

    return exponentsSelected.length;
  };

  const handleKeyMomentFormatByEventChange = (eventId, value) => {
    setKeyMomentFormatsByEvent({...keyMomentFormatsByEvent, [eventId]: value });
  }

  const renderJobdatings = () => {
    let exponentSelected;
    let recruiterCount;
    let eventSelected;

    if (jobdatings.length === 0) {
      return [];
    }

    return jobdatings.map(
      (jobdating, i) => {
        exponentSelected = exponents.find((exponent) => exponent._event._id.toString() === jobdating.key);
        recruiterCount = exponentSelected ? exponentSelected.users.length : undefined;
        eventSelected = finalEvents.find((event) => event._id.toString() === jobdating.key)

        return (
          <div key={jobdating.key} className={styles.jobdatingCardContainer}>
            <div className={styles.jobdatingCard}>
              <span className={styles.jobdatingName}>{jobdating.name}</span>
              <span>
                {isRecruiterLimitExceeded(exponentSelected, recruiterCount) ?
                  <Icon
                    className={styles.warningCircle}
                    name="warning-circle"
                  /> : null}
                <a
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    const newJobdatings = [...jobdatings];
                    newJobdatings.splice(i, 1);
                    setJobdatings(newJobdatings);
                  }}
                >
                  <Icon
                    className={styles.removeIcon}
                    name="trash"
                  /></a>
              </span>
            </div>
            {eventSelected.format === EVENT_FORMAT.HYBRID &&
              <div className={styles.participationModeContainer}>
                <p className={styles.label}>Mode de participation <span>*</span></p>
                <div className={styles.radioButtonOptions}>
                  <div className={styles.radioButtonContainer} onClick={() => handleKeyMomentFormatByEventChange(jobdating.key,['physical'])} >
                    <div className={`${styles.radioButton} ${keyMomentFormatsByEvent?.[jobdating.key]?.[0] === 'physical' && keyMomentFormatsByEvent?.[jobdating.key]?.length === 1 && styles.radioButtonSelected}`}>
                      <div className={styles.radioButtonFill} />
                    </div>
                    <p className={styles.radioLabel}>{t({ id: 'event.owner.exponents.input.participationMode.optionPhysical' })}</p>
                  </div>
                  <div className={styles.radioButtonContainer} onClick={() => handleKeyMomentFormatByEventChange(jobdating.key,['virtual'])}>
                    <div className={`${styles.radioButton} ${keyMomentFormatsByEvent?.[jobdating.key]?.[0] === 'virtual' && styles.radioButtonSelected}`}>
                      <div className={styles.radioButtonFill} />
                    </div>
                    <p className={styles.radioLabel}>{t({ id: 'event.owner.exponents.input.participationMode.optionVirtual' })}</p>
                  </div>
                  <div className={styles.radioButtonContainer} onClick={() => handleKeyMomentFormatByEventChange(jobdating.key,['physical','virtual'])}>
                    <div className={`${styles.radioButton} ${keyMomentFormatsByEvent?.[jobdating.key]?.length > 1 && styles.radioButtonSelected}`}>
                      <div className={styles.radioButtonFill} />
                    </div>
                    <p className={styles.radioLabel}>{t({ id: 'event.owner.exponents.input.participationMode.optionHybrid' })}</p>
                  </div>
                </div>
              </div>
            }
            {errorParticipationMode.includes(jobdating.key) &&
              <p className={styles.errorParticipationMode}>{t({ id: 'event.owner.exponents.participationMode.errorMessage' })}</p>
            }
          </div>
        );
      }
    );
  };

  const renderForm = () => {
    if (!visible) return null;

    const { getFieldDecorator } = form;
    const selectedEventIds = jobdatings.map((j) => j.key.toString());

    const filtredEvents = existEvents ? finalEvents.filter((e) => !existEvents.includes(e._id)) : finalEvents;
    const hasOverflowEvent = size(filtredEvents.filter((e) => ((e.countUsers + countMemberSelected)) >= e.recruiterMax)) > 0;

    return (
      <div id="jobdatingModalForm">
        <span className={styles.title}><FormattedMessage {...messages.addOnJobdating} /></span>
        <Form onSubmit={(e) => handleSubmit(e)} style={{ marginTop: '20px' }}>
          {getFieldDecorator('locale', {
            rules: [{ required: true, message: t({ id: 'admin.organizations.members.actions.add.on.jobdating' }) }],
          })(
            <Select
              showSearch
              onChange={handleChange}
              addOnIcon="add-plus-rounded"
              label={t({ id: 'admin.organizations.members.actions.add.on.jobdating' })}
              placeholder={t({ id: 'admin.organizations.members.actions.add.on.jobdating' })}
              optionFilterProp="children"
              onSearch={() => { }}
              getPopupContainer={() => document.getElementById('jobdatingModalForm')}
              filterOption={(input, option) => option.props && option.props.children && option.props.children.props && option.props.children.props.id.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {size(filtredEvents) === 0 ? (<Option key="empty" disabled>{t({ id: 'admin.organizations.jobdating.add.noResult' })}</Option>) : filtredEvents.sort((a, b) => new Date(b.keyDates.discovering.beginAt) - new Date(a.keyDates.discovering.beginAt)).map((event) => (
                <Option key={event.id} disabled={((event.countUsers + countMemberSelected)) > event.recruiterMax || (window.location.pathname.indexOf('admin') > -1 && !event.enableAddingExponent) || selectedEventIds.includes(event._id.toString())}>
                  <div id={event.name} style={{ display: 'flex' }}>
                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', opacity: ((event.countUsers + countMemberSelected)) > event.recruiterMax || (window.location.pathname.indexOf('admin') > -1 && !event.enableAddingExponent) ? 0.6 : 1 }}>
                      <span style={{ color: '#385077' }}>{event.name}</span>
                      <span style={{ color: '#949AA6', fontSize: '12px' }}>{`Du ${moment(event.keyDates.discovering.beginAt).format('DD/MM/Y')} Au ${moment(event.keyDates.discovering.endAt).format('DD/MM/Y')}`}</span>
                    </div>
                    {event.enableAddingExponent || window.location.pathname.indexOf('admin') === -1 ? '' : <p className={styles.notValidated}>{t({ id: 'admin.organizations.jobdating.add.invalid' })}</p>}
                    {
                      ((event.countUsers + countMemberSelected)) > event.recruiterMax ? (
                        <div className={styles.optionWarning}>
                          <div>
                            <Icon
                              className={styles.warningCircle}
                              name="warning-circle"
                            />
                          </div>
                        </div>
                      ) : ''
                    }
                  </div>
                </Option>))}
            </Select>
          )}
          {hasOverflowEvent && context !== 'jobdating' ? (
            <div className={styles.overflowLegend}>
              <p><Icon
                className={styles.warningCircle}
                name="warning-circle"
              /> <FormattedMessage id="event.recruiter.preparation.team.maxRecruiterHelp" /></p>
            </div>
          ) : ''}

          <div style={{ overflow: 'auto', maxHeight: '300px' }}>
            {renderJobdatings()}
          </div>

          <FormItem>
            {shouldDisplayRecruiterLimitWarning() &&
              <Alert color="warning" className="mt-10">
                {t({ id: 'admin.organizations.members.add.jobdating.warning.max' })}
              </Alert>
              }
            {jobdatings.length > 0 && <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Button className="w-full" type="submit"><FormattedMessage id="admin.organizations.members.actions.add.on.jobdating.number" values={{ count: jobdatings.length }} /></Button>
            </div>}
          </FormItem>
        </Form>
      </div>
    );
  }

  return (
    <Modal
      visible={visible}
      keyboard={false}
      onCancel={() => handleClose(null)}
      footer={false}
      maskClosable
      closable={false}
      width={654}
      className="customConfirm collaboratorModal"
    >
      <a role="button" tabIndex="0" className={styles.modalClose} onClick={(e) => handleClose(e)}><Icon name="close-modal" /></a>
      {renderForm()}
    </Modal>
  );
}

AddJobdatingModal.propTypes = {
  form: object,
  visible: bool,
  handleClose: func,
  intl: object,
  events: array,
  getEvents: func,
  existEvents: array,
  exponents: array,
  onSubmit: func,
  maxRecruiter: number,
  countMemberSelected: number,
  context: string,
  user: object
};

const mapStateToProps = createStructuredSelector({
  events: eventSelectors.getEvents,
});

const mapDispatchToProps = {
};

const withForm = Form.create()(AddJobdatingModal);
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  injectIntl,
)(toJS(withForm));

