import { useQuery } from 'react-query';
import request from '@/utils/request';

export function useGetTemplates(props = {}) {
  const requestUrl = `${process.env.FRONT_API_URL}/users/${props.userId}/templates`;

  const queryFn = () => request(requestUrl, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
  });

  const query = useQuery({
    queryKey: ['templates'],
    queryFn,
    refetchOnWindowFocus: false,
    ...props,
  });
  return query;
}
