import React from 'react';
import { number } from 'prop-types';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { StatBloc } from '@/components/Stats/StatBloc';

import messages from '../../messages';

const StatsAttendees = ({ conferences }) => (
  <StatBloc
    title={<FormattedMessage {...messages.attendeeTitle} />}
    description={<FormattedMessage {...messages.attendeeText} />}
    count={get(conferences, 'participants')}
  />
);

StatsAttendees.propTypes = {
  conferences: number,
};

export { StatsAttendees };
