import React from 'react';
import { NavLink } from 'react-router-dom';
import { node, string, oneOfType, array } from 'prop-types';
import classnames from 'classnames';

// Components
import Icon from '@/components/Icon';
import { Tooltip } from '@/components/Tooltip';

// Styles & Translations
import styles from '../styles.less';


const NavEvent = ({ items, children }) => {
  const renderItems = [];

  items.forEach((item, index) => {
    const disabledClass = item.disabled ? styles.disabled : null;

    renderItems.push(
      <Tooltip
        color="dark"
        title={item.disabled && item.tooltipMessage}
        isVisible
        key={`tp${index.toString()}`}
      >
        <div className={classnames(styles.navEventItem, styles[item.type], disabledClass)} key={`navEventItem${index.toString()}`}>
          <NavLink to={item.url} activeClassName="activeNavLink" disabled={item.disabled} onClick={item.onClick || (() => {})}>
            <Icon name={item.icon} />
            <span>{item.label}</span>
          </NavLink>
        </div></Tooltip>);
  });

  return (
    <div className={styles.container}>
      <div className={styles.navEventContainer}>
        <div className={styles.navEvent}>
          {renderItems}
        </div>
      </div>
      <div className={styles.actions}>
        {children}
      </div>
    </div>
  );
};

NavEvent.propTypes = {
  items: array,
  children: oneOfType([string, node]),
};

export default NavEvent;
