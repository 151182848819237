import { defineMessages } from 'react-intl';

export default defineMessages({
  save: {
    id: 'save',
  },
  edit: {
    id: 'edit',
  },
  french: {
    id: 'french',
  },
  english: {
    id: 'english',
  },
  eventLocalizationVirtual: {
    id: 'event.localization.virtual',
  },
  eventLocalizationPhysic: {
    id: 'event.localization.physic',
  },
  modulesJobDating: {
    id: 'modules.jobdating',
  },
  modulesLive: {
    id: 'modules.live',
  },
  modulesHunt: {
    id: 'modules.hunt',
  },
  modulesFeedback: {
    id: 'modules.feedback',
  },
  modulesOffer: {
    id: 'event.modules.offer',
  },
  offers: {
    id: 'offers',
  },
  companies: {
    id: 'company',
  },
  total: {
    id: 'total',
  },
  average: {
    id: 'average',
  },
  refusedPlgr: {
    id: 'refused.plgr',
  },
  acceptedPlgr: {
    id: 'accepted.plgr',
  },
  expiredPlgr: {
    id: 'expired.plgr',
  },
  appointmentsCanceledPl: {
    id: 'appointment.canceled.pl',
  },
  yes: {
    id: 'yes',
  },
  no: {
    id: 'no',
  },
  maybe: {
    id: 'maybe',
  },
});
