import { useFormatMessage } from 'react-intl-hooks';
import { object } from 'prop-types';
import { H3, } from '@seekube-tech/ui-kit';
import React from 'react';

import { Stats } from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Dashboard/components/Cover/Stats';
import { Planners } from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Dashboard/components/Cover/Planners';
import { EVENT_TYPE } from '@/utils/constants';
import styles from './styles.less';


export const Cover = ({ event, participant }) => {
  const t = useFormatMessage();
  const isJpo = event?.type === EVENT_TYPE.jpo;
  const background = `linear-gradient(359.13deg, rgba(0, 0, 0, 0.5) 0.69%, rgba(0, 0, 0, 0) 99.2%), linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${event.theme.coverPictureUrl })`;

  return (
    <div
      className={styles.cover}
      style={{ background }}>
      <div className="flex justify-between mb-45">
        <div>
          <H3 color="white" fontWeight={400}>
            {t(
              { id: 'welcome.message' }, {
                message: <span className={styles.title}>{participant?._user?.firstName}</span>
              })}
          </H3>
        </div>
        <Planners
          organizations={event.planners._organizations}
          event={event} avatarSize="small"
        />
      </div>

      {!isJpo && <Stats event={event} participant={participant} />}
    </div>
  )
}

Cover.propTypes = {
  event: object,
  participant: object,
}
