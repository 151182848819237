import moment from 'moment/moment';
import { IconContract } from '@seekube-tech/ui-kit';
import { number } from 'prop-types';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

function CvThequeDate({ daysBeforeCvthequeIsClosed }) {
  if (daysBeforeCvthequeIsClosed < 0 || daysBeforeCvthequeIsClosed > 999) return null;

  const CVTHEQUEDateString = `${moment().add(daysBeforeCvthequeIsClosed, 'days').format('D MMM YYYY')}`;

  return (
    <span key="dbDate">
      <IconContract size={16} />
      <FormattedMessage values={{ date: CVTHEQUEDateString }} id="events.databaseAvailableUntil" />
    </span>
  );
};

CvThequeDate.propTypes = {
  daysBeforeCvthequeIsClosed: number
}

export default CvThequeDate;
