import React from 'react';
import { bool, func } from 'prop-types';
import { Button, H4 } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import Modal from '@/components/Modal';
import styles from '@/scenes/Event/scenes/Owner/scenes/Dashboard/components/AddActivity/styles.less';

export const ModalQuitWithoutSaving = ({ isVisible, onCancel, onConfirm }) => {
  const t = useFormatMessage();
  return (
    <Modal
      width={672}
      visible={isVisible}
      footer={null}
      onCancel={onCancel}
    >

      <div className={styles.headerModal}>
        <H4>
          {t({ id: 'event.recruiter.preparation.offer.published.form.update' })}
        </H4>
        <div className={styles.buttonsContainer}>
          <Button variant='outline' color='neutral' onClick={onCancel}>
            {t({ id: 'cancel' })}
          </Button>
          <Button color='error' onClick={onConfirm}>
            {t({ id: 'confirm' })}
          </Button>

        </div>
      </div>
    </Modal>
  )
}

ModalQuitWithoutSaving.propTypes = {
  isVisible: bool,
  onCancel: func,
  onConfirm: func,
}
