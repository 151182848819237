import { makeStyles } from '@seekube-tech/ui';

export default makeStyles(theme => ({
  asterisk: {
    color: theme.palette.error[500],
  },
  buttons: {
    marginTop: theme.spacing(0.5),
  },
  button: {
    height: 50,
    width: 50,
    border: `1px solid ${theme.palette.neutral[300]}`,
    borderRadius: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.neutral[300],
    cursor: 'pointer',

    '&:hover': {
      color: theme.palette.neutral[400],
    },
  },
  selected: {
    borderColor: theme.palette.primary[500],
    color: theme.palette.primary[500],

    '&:hover': {
      color: theme.palette.primary[500],
    },
  },
}));