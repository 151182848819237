import React, { useState } from 'react';
import { object } from 'prop-types';
import moment from 'moment';
import { useFormatMessage } from 'react-intl-hooks';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { AvatarCandidate } from '@seekube-tech/ui-kit';
import { IconLocalisationMap, IconPhone, IconVideo } from '@seekube-tech/ui-kit';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import Avatar from '@/components/Avatar';
import ModalFull from '@/components/ModalFull';
import ParticipantCards from '@/components/ParticipantCards';
import { getCdnUrl } from '@/components/ImgCdn';
import { authSelectors } from '@/store/auth';
import { toJS } from '@/utils';
import Icon from '@/components/Icon';
import { EVENT_FORMAT } from '@/utils/constants';
import { useGetParticipant } from '@/queries/participants/useGetParticipant';
import styles from './styles.less'

const MeetingElement = ({appointment, event, authUser }) => {

  const t = useFormatMessage();
  const [modalOpen, setModalOpen] = useState(false);
  const { data: participant } = useGetParticipant({ eventId: event.slug, userId: appointment._user._id })

  return (
    <div className={styles.meetingElementContainer}>
      <div className={styles.recruiterColumn}>
        <Avatar user={appointment._organizationUser} src={appointment._organizationUser.pictureUrl} size={40} />
        <div className={styles.recruiterInfos}>
          <p className={styles.recruiterName}>{appointment._organizationUser.fullName}</p>
          <p className={styles.recruiterTitle}>{appointment._organizationUser.title} @{appointment._organization.name}</p>
        </div>
      </div>
      <div className={styles.candidateColumn}>
        <div className={styles.candidateAvatar} >
          <AvatarCandidate
            size={40}
            user={appointment._user}
            pictureUrl={getCdnUrl(appointment._user.pictureUrl, 80) ?? ''}
          />
        </div>
        <p className={styles.candidateName} onClick={() => setModalOpen(true)} >{appointment._user.fullName}</p>
          {modalOpen &&
            <ModalFull
              id="participantModal"
              visible={modalOpen}
              footer={null}
              onCancel={() => setModalOpen(false)}
              keyboard
            >
              <a role="button" tabIndex={0} className="modal-close" onClick={() => setModalOpen(false)}>
                <Icon name="close" className="modal-close-icon" />
              </a>
              <ParticipantCards
                component="big"
                areInteractionsClosed={event.areInteractionsClosed}
                participant={participant}
                actions=""
                viewBy={authUser}
                onSave={() => {}}
                handleSaveUser={() => {}}
                eventSlug={event.slug}
                status="none"
                context="adminall"
                currentUser={authUser}
                isHybrid={event.format === EVENT_FORMAT.HYBRID}
              />
            </ModalFull>
          }
      </div>
      <div className={styles.detailsColumn}>
        <div className={styles.topContent}>
          {event.modules.informal1to1?.enable && <div className={appointment.origin !== 'informal1to1' ? styles.typeOfAppointmentContainer : styles.typeOfAppointmentContainerInformal}>{appointment.origin !== 'informal1to1' ? 'Entretien' : '1/1 Chat'}</div>}
          <p>{moment(appointment.date).format('HH:mm')} - {moment(appointment.date).add(appointment._timeslot?.duration, 'minutes').format('HH:mm')}</p>
        </div>
        {appointment._timeslot?.medium === 'visio' &&
          <div className={styles.mediumContainer}>
            <IconVideo size={12} />
            <p>{t({ id: `dashboard.realtime.meetingElement.medium.visio`})}</p>
          </div>
        }
        {appointment._timeslot?.medium === 'phone' &&
          <div className={styles.mediumContainer}>
            <IconPhone size={12} />
            <p>{t({ id: `dashboard.realtime.meetingElement.medium.phone`})}</p>
          </div>
        }
        {appointment._timeslot?.medium === 'physical' &&
          <div className={styles.mediumContainer}>
            <IconLocalisationMap size={12} />
            <p>{t({ id: `appointment.medium.physical`})}</p>
          </div>
        }
        {appointment._offer &&
          <Link to={`/${event.slug}/owner/offers/${appointment._offer._id}/preview`} target="_blank">
            <button className={styles.buttonToOffer}>{t({ id: `dashboard.realtime.meetingElement.button.offer`})}</button>
          </Link>
        }
      </div>
    </div>
  )
}

MeetingElement.propTypes = {
  appointment: object,
  event: object,
  authUser: object,
};

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser,
});

const withConnect = connect(mapStateToProps, null);

export default compose(
  withConnect,
)(toJS(MeetingElement));
