import React, { useState } from 'react';
import { object, string, func, bool, oneOf } from 'prop-types';
import classnames from 'classnames';

// Components
import Icon from '@/components/Icon';
import ReactPlayer from 'react-player';

import styles from '../../styles.less';
import videoStyles from './styles.less';

const Video = ({
  element,
  className,
  style,
  withSlideshow,
  onClick,
  template = 'default',
  options = {
    light: true,
  },
}) => {
  const [playing, setPlaying] = useState(false);

  return (
    <div>
      <div style={withSlideshow ? { position: 'relative', ...style } : { position: 'relative' }} className="gallery-video-item">
        {withSlideshow ? <div className={styles.overlay} role="button" tabIndex={0} onClick={onClick} /> : ''}
        <div className={classnames(className, options.light && template === 'default' ? videoStyles.defaultClass : null)} style={{ position: 'relative', overflow: 'hidden', ...style }}>
          {element.name && !playing ? <div className="gallery-video-name"><Icon name="playVideo" /> {element.name}</div> : ''}
          <ReactPlayer
            url={element.url}
            onPlay={() => { if (template === 'default') setPlaying(true); }}
            controls
            width="100%"
            style={element.style}
            {...options}
            light={element.url.indexOf('youtu') > -1 ? false : options.light}
            config={{
              youtube: {
                playerVars: { showinfo: 0, iv_load_policy: 3 },
                embedOptions: { showinfo: 0 },
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

Video.propTypes = {
  style: object,
  element: object,
  options: object,
  className: string,
  template: oneOf(['default']),
  withSlideshow: bool,
  onClick: func,
};

export default Video;
