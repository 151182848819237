import React from 'react';
import { object, string } from 'prop-types';
import { H4, Subtitle1 } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { Row } from 'antd';
import useInfiniteGetLandingInformal1to1s from '@/queries/landing/useInfiniteGetLandingInformal1to1s';
import { getLocale } from '@/utils/localStorage';
import {
  ShowMoreButton
} from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Exponents/scenes/Exponent/components/ShowMoreButton';
import CardInformal1to1s from '@/scenes/Event/containers/Landing/components/Contents/components/Informal1to1s/CardInformal1to1s';
import styles from './styles.less';

const Informal1to1s = ({
  event,
  redirectionHref
}) => {
  const t = useFormatMessage();
  const informal1to1sInfiniteQuery = useInfiniteGetLandingInformal1to1s({
    eventId: event?._id
  });


  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <H4>{event.modules?.informal1to1[`labelMultiple_${getLocale()}`]}</H4>
        <Subtitle1 className={styles.subtitle}>
          {t({ id: 'landing.Informal1to1s.subtitle' })}
        </Subtitle1>
      </div>
      <Row gutter={[24, 24]}>
        <CardInformal1to1s
          informal1to1s={informal1to1sInfiniteQuery}
          event={event}
          redirection={redirectionHref}
        />
      </Row>
      <ShowMoreButton entityInfiniteQuery={informal1to1sInfiniteQuery} />
    </div>
  );
}

Informal1to1s.propTypes = {
  event: object,
  redirectionHref: string,
}


export { Informal1to1s };
