import React from 'react';
import { object, number, func, string } from 'prop-types';
import { size } from 'lodash';

// Components
import { Button, Toggle } from '@seekube-tech/ui-kit';


const Roadmap = ({ event, contentIndex, onChange, onDelete, locale }) => {
  const deleteLastElement = () => {
    const { content } = event._contents[contentIndex][locale];

    content.pop();

    onChange(['_contents', contentIndex, locale, 'content'], content);
  };

  const addNewElement = () => {
    const content = event._contents[contentIndex][locale].content || [];

    if (size(content) >= 6) { // Max 6 elements in the timeline
      return null;
    }

    const contentUpdated = content ? [...content, { color: 'blue', date: 'Date', label: 'Label', content: 'Text' }] : [{ color: 'blue', date: 'Date', label: 'Label', content: 'Text' }];

    return onChange(['_contents', contentIndex, locale, 'content'], contentUpdated);
  };

  return (
    <div>
      {/* Display Label */}
      <div>
        <label htmlFor="mainColor">Afficher label dans le menu :</label>
        <Toggle
          size="small"
          checked={event._contents[contentIndex].inNav}
          onChange={(checked) => onChange(['_contents', contentIndex, 'inNav'], checked)}
        />
      </div>

      {/* Buttons */}
      <div>
        <label htmlFor="actions">Actions :</label>
        <Button color="error" onClick={deleteLastElement}>Supprimer le dernier élément</Button>
        <Button onClick={addNewElement}>Ajouter un élement</Button>
        <Button color="error" onClick={() => onDelete(contentIndex)}>Supprimer le bloc</Button>
      </div>
    </div>
  );
}

Roadmap.propTypes = {
  event: object,
  contentIndex: number,
  onChange: func,
  onDelete: func,
  locale: string,
};

export default Roadmap;
