import React from 'react';
import { string } from 'prop-types';

/**
 * HP
 * @param {object} props
 */
const Nl2br = (props) => props.text.split('\n').map((item, key) => <span key={key}>{item}<br /></span>);

Nl2br.propTypes = {
  text: string,
};

export default Nl2br;
