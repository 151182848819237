import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useHistory } from 'react-router';
import { Hidden, Banner } from '@seekube-tech/ui';
import { Body1, H2, H3, Subtitle1 } from '@seekube-tech/ui-kit';
import colors from '@seekube-tech/ui-kit/dist/colors';
import { useFormatMessage } from 'react-intl-hooks';
import classnames from 'classnames';
import { toJS } from '@/utils';

// Store
import { eventSelectors } from '@/store/event';
import { authSelectors } from '@/store/auth';

import { isParticipatingToAllHybridFormats as getIsParticipatingToAllHybridFormats } from '@/helpers/participant/isParticipatingToAllHybridFormats';
import { EVENT_FORMAT } from '@/utils/constants';
import { getTimezoneText } from '@/utils/format';
import { CONTEXT_ROLES } from '../../utils/constants';
import convertKeyDatesToArray from '../../utils/convertKeyDatesToArray';
import convertKeyMomentDatesToArray from '../../utils/convertKeyMomentDatesToArray';

import KeyDate from './components/KeyDate';

import useStyles from './styles';

function KeyDates({ event, context, authUser, userKeyMomentFormats = [] }) {
  const t = useFormatMessage();
  const styles = useStyles();
  const history = useHistory();
  const [isBannerVisible, setIsBannerVisible] = useState(context === CONTEXT_ROLES.OWNER);

  const getAuthUserKeyMomentFormats = () => {
    switch (context) {
      case CONTEXT_ROLES.CANDIDATE: return userKeyMomentFormats;
      case CONTEXT_ROLES.RECRUITER: return userKeyMomentFormats;
      case CONTEXT_ROLES.OWNER: return [EVENT_FORMAT.VIRTUAL, EVENT_FORMAT.PHYSICAL]

      default: return []
    }
  }
  const coverUrl = event?.theme?.coverPictureUrl || '';
  const authUserKeyMomentFormats = getAuthUserKeyMomentFormats()
  const isParticipatingToAllHybridFormats = getIsParticipatingToAllHybridFormats(authUserKeyMomentFormats);

  const keyDates = event.format === EVENT_FORMAT.HYBRID ?
    convertKeyMomentDatesToArray(event?.keyMoments, event?.modules, authUserKeyMomentFormats, context)
    : convertKeyDatesToArray(event?.keyDates, event?.modules, context);


  const handleCloseBanner = () => {
    setIsBannerVisible(false);
  };

  if (event.keyDatesTimelineIsHidden === true) {
    return history.push(`/${event.slug}`);
  }

  return (
    <div
      className={classnames(styles.root, {
        [styles.marginRoot]: [CONTEXT_ROLES.OWNER, CONTEXT_ROLES.RECRUITER].includes(context),
      })}
    >
      <Hidden smDown>
        <Banner
          visible={isBannerVisible}
          variant="info"
          onClose={handleCloseBanner}
          text={ t({ id: 'event.keydates.banner' })}
        />

        <div className={styles.cover} style={{ backgroundImage: `url(${coverUrl})` }}>
          <div className={styles.overlay}>
            <div className={styles.wrapper}>
              <H2 color={colors.basic.white}>
                {event?.name}
              </H2>
            </div>
          </div>
        </div>
      </Hidden>

      <div className={styles.container}>
        <Hidden smUp>
          <Body1 color={colors.neutral['400']} className={styles.mobileTitle}>
            {event?.name}
          </Body1>
        </Hidden>

        <Hidden smDown>
          <H2>
            {t({ id: 'event.keydates.title' })}
          </H2>
        </Hidden>

        <Hidden smUp>
          <H3>
            {t({ id: 'event.keydates.title' })}
          </H3>
        </Hidden>

        <Subtitle1 color={colors.neutral['500']} className={styles.subtitle}>
          {t({ id: 'event.keydates.subtitle' })}
        </Subtitle1>

        <Body1 className={styles.timezone}>
          {getTimezoneText(authUser?.timezone)}
        </Body1>

        <div className={styles.dates}>
          {keyDates.map(keyDate => (
            <KeyDate key={keyDate._id} dates={keyDate} context={context} isUserParticipatingToAllHybridFormats={isParticipatingToAllHybridFormats} />
          ))}
        </div>
      </div>
    </div>
  );
}

KeyDates.propTypes = {
  event: PropTypes.object,
  context: PropTypes.oneOf(Object.values(CONTEXT_ROLES)),
  authUser: PropTypes.object,
  userKeyMomentFormats: PropTypes.array,
};

KeyDates.defaultProps = {
  event: null,
  context: null,
  authUser: null,
};

const mapStateToProps = createStructuredSelector({
  event: eventSelectors.getCurrentEvent,
  authUser: authSelectors.getAuthUser,
});

const mapDispatchToProps = {};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  toJS,
)(KeyDates);
