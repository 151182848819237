import React from 'react';
import styles from '@/components/FinalFormFields/CountrySelector/styles.less';
import { IconPhone } from '@seekube-tech/ui-kit';
import { func, string } from 'prop-types';
import labels from 'react-phone-number-input/locale/en'
import { getCountries, getCountryCallingCode } from 'react-phone-number-input';

const CountrySelector = ({
  value, 
  onChange, 
}) => {
  
  const getIcon = () => `https://purecatamphetamine.github.io/country-flag-icons/3x2/${value}.svg`
  
  return (
      <div className={styles.selectorWrapper}>
        <select
          className={styles.selector}
          value={value}
          onChange={event => onChange(event.target.value || undefined)}>
          <option value="">
            {labels[value]}
          </option>
          {getCountries().map((country) => (
            <option key={country} value={country}>
              {labels[country]} + {getCountryCallingCode(country)}
            </option>
          ))}
        </select>
        {!value ? <IconPhone /> : 
          <div className={styles.flagWrapper}>
            <img className={styles.flagImg} alt="countryCodeFlag" src={getIcon()}/>
          </div>
        }
      </div>
    )
}

CountrySelector.propTypes = {
  value: string,
  onChange: func,
}

export { CountrySelector };