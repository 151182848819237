import request from '@/utils/request';
import { useInfiniteQuery } from 'react-query';

const defaultSearch = {
  limit: 15,
  offset: 0,
  keywords: '',
  page: 1
}

export function useInfiniteGetOrganizationProfilesPreviewsConferences({ organizationId, eventId, limit = 15 }) {
  const queryFn = (pageParams) => {
    const { pageParam: page } = pageParams;
    const params = `?eventId=${eventId}&limit=${limit}&page=${page}`;
    const requestUrl = `${process.env.FRONT_API_URL}/organizations/${organizationId}/profiles/previews/conferences${params}`;

    return request(requestUrl,
      { method: 'GET' });
  };

  const query = useInfiniteQuery({
    queryKey: ['organizationProfilesPreviewsConferences', eventId, organizationId],
    keepPreviousData: true,
    getNextPageParam: (lastPage) => {
      if ((lastPage.page * lastPage.limit) <= lastPage.total) {
        return (lastPage.page + 1);
      }
    },
    select: (data) => ({
      pages: [...data.pages],
      pageParams: [...data.pageParams],
    }),
    queryFn,
  });
  return query;
}
