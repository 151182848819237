import React from 'react';
import { func, string, object, bool } from 'prop-types';
import { isEmpty } from 'lodash';
import { useFormatMessage } from 'react-intl-hooks';
import SelectEvent from '@/features/agenda/recruiter/components/CalendarHeader/SelectEvent';
import { SelectRecruiter } from '@/features/agenda/recruiter/components/CalendarHeader/SelectRecruiter';
import styles from '@/features/agenda/recruiter/components/styles.less';
import { AddSlotBtn } from '@/features/agenda/recruiter/components/CalendarHeader/AddSlotBtn';

export const CalendarHeader = ({
  handleOnOpenSlotSetting,
  handleSelectRecruiter,
  eventsSelectQuery,
  usersSelectQuery,
  handleSelectEvent,
  exponentFormat,
  currentEvent,
  selectedUserId,
  settings,
  isDeleteModeActive,
}) => {
  const t = useFormatMessage();

  return <div className={styles.selectsContainer} id="selectsContainer">
    <div className="flex grow">
      <AddSlotBtn
        settings={settings}
        handleOnOpenSlotSetting={handleOnOpenSlotSetting}
        isDeleteModeActive={isDeleteModeActive}
      />
      <div className={styles.eventsList}>
        <div id="events">
          <SelectEvent
            defaultValue={!isEmpty(eventsSelectQuery?.data?.docs) ? currentEvent?._id : undefined}
            value={!isEmpty(eventsSelectQuery?.data?.docs) ? currentEvent?._id : undefined}
            placeholder={t({id: 'selectEvent'})}
            onSelect={handleSelectEvent}
            events={eventsSelectQuery?.data?.docs}
            exponentFormat={exponentFormat}
            filterOption={(input, option) => option.props.title && option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          />
        </div>
      </div>
    </div>
    <div className={styles.userContainer}>
      <SelectRecruiter
        handleSelectRecruiter={handleSelectRecruiter}
        users={usersSelectQuery?.data?.docs}
        selectedUser={selectedUserId}
      />
    </div>
  </div>
}

CalendarHeader.propTypes = {
  settings: object,
  handleOnOpenSlotSetting: func,
  handleSelectRecruiter: func,
  eventsSelectQuery: func,
  usersSelectQuery: func,
  handleSelectEvent: func,
  exponentFormat: func,
  currentEvent: func,
  selectedUserId: string,
  isDeleteModeActive: bool,
}
