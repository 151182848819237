import { Col, Row } from 'antd';
import styles from '@/features/agenda/recruiter/components/BigCalendar/BigCalendarToolbar/styles.less';
import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { Tag, Caption2 } from '@seekube-tech/ui-kit';

export const HybridVirtualSlotsNumber = ({
  exponentFormat,
  isModuleOfferEnable,
  isModuleInformal1to1Enable,
  interviewNumberOfTimeslotAvailable,
  informal1to1NumberOfTimeslotAvailable,
}) => {
  const t = useFormatMessage();

  if (exponentFormat === 'physical') return null;

  const hasTimeslotAvailable = interviewNumberOfTimeslotAvailable.virtual > 0;
  const hasInformal1to1TimeslotAvailable = informal1to1NumberOfTimeslotAvailable.virtual > 0;

  return (
    <Col style={{marginRight: "24px"}}>
      {exponentFormat === 'hybrid' &&
        <Row type="flex" align="middle" className="mb-4">
          <Tag variant="outline" color="neutral" className="w-full justify-center">
            <span className={styles.formatTag}>
              {t({ id: 'calendar.settingsSlot.participationMode.virtual' })}
              </span>
          </Tag>
        </Row>
      }
      <Row type="flex" align="middle">
        {isModuleOfferEnable && (
          <>
            <Tag
              className="mr-4"
              variant="tonal"
              color={hasTimeslotAvailable ? 'neutral' : 'warning' }
            >
              {`${interviewNumberOfTimeslotAvailable.virtual}`}
            </Tag>
            <Caption2 className={hasTimeslotAvailable ? 'text-neutral-500' : 'text-warning-500'}>
              {t({ id: 'components.bigCalendar.toolbar.available-timeslot-interview' },
                { count: interviewNumberOfTimeslotAvailable.virtual })}
            </Caption2>
          </>
        )}
        {isModuleInformal1to1Enable &&
          <>
            <div className={styles.separator}></div>
            <Tag
              className="mr-4"
              variant="tonal"
              color={hasInformal1to1TimeslotAvailable ? 'neutral' : 'warning' }
            >
              {`${informal1to1NumberOfTimeslotAvailable.virtual}`}
            </Tag>
            <Caption2 className={hasInformal1to1TimeslotAvailable ? 'text-neutral-500' : 'text-warning-500'}>
              {t({ id: `components.bigCalendar.toolbar.available-timeslot${isModuleOfferEnable ? '-informal1to1' : ''}`},
                { count: informal1to1NumberOfTimeslotAvailable.virtual } )}
            </Caption2>
          </>
        }
      </Row>
    </Col>
  )
}
