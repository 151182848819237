import React from 'react';
import { array, func } from 'prop-types';
import { map, size } from 'lodash';
import { Grid } from '@seekube-tech/ui';
import { Report } from '@/scenes/Event/scenes/Owner/scenes/Setting/scenes/Reporting/components/EmailingSchedule/components/Report/Report';
import useStyles from '@/scenes/Event/scenes/Owner/scenes/Setting/scenes/Reporting/components/EmailingSchedule/components/Report/styles';

export const Reports = ({ reports, onChange, onRemove }) => {
  const classes = useStyles();
  const totalReportNb = size(reports)

  return map(reports, (report, i) => (
    <div className={classes.root} key={report._id}>
      <Report report={report} handleChange={onChange} handleRemove={onRemove} currentIndex={i} totalIndex={totalReportNb} />
    </div>
  ))
}

Reports.propTypes = {
  reports: array,
  onChange: func,
  onRemove: func,
}
