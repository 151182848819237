import React from 'react';
import { oneOf, func, object } from 'prop-types';

import { FormattedMessage } from 'react-intl';
import { Menu } from 'antd';
import { IconEdit2, IconFiles, IconVideo } from '@seekube-tech/ui-kit';
import Icon from '@/components/Icon';
import MoreDropdown from '@/components/MoreDropdown';


const MoreActions = ({ handleAction, context, conference }) => (
  ['client', 'nonClient', 'owner'].includes(context) &&
  <div style={{ position: 'absolute', right: '17px', top: '12px', zIndex: 1 }}>
    <MoreDropdown variant="backgroundWhite" overlay={renderPopoverContent(context, handleAction, conference)}/>
  </div>
);

const renderPopoverContent = (context, handleAction, conference) => {
  if (['client', 'nonClient', 'owner'].includes(context)) {
    return (
      <Menu>
        {(conference.liveUrl || conference.providerUrl) &&
          <Menu.Item>
            <a href={conference.liveUrl || conference.providerUrl} target='_blank'>
              <IconVideo /><FormattedMessage id="livecard.actions.goToLive" />
            </a>
          </Menu.Item>
        }
        <Menu.Item>
          <a role="button" className="popover-item" tabIndex="0" onClick={() => handleAction('editLive')}>
            <IconEdit2 size={16} /><FormattedMessage id="livecard.actions.edit" />
          </a>
        </Menu.Item>
        <Menu.Item>
          <a role="button" className="popover-item" tabIndex="0" onClick={() => handleAction('duplicateLive')}>
            <IconFiles size={16} /><FormattedMessage id="livecard.actions.duplicate" />
          </a>
        </Menu.Item>
        <Menu.Item>
          <a role="button" className="popover-item" tabIndex="0" onClick={() => handleAction('removeLive')}>
            <Icon name="refuse" /><FormattedMessage id="livecard.actions.delete" />
          </a>
        </Menu.Item>
      </Menu>
    );
  }

  return null;
}


MoreActions.propTypes = {
  handleAction: func,
  context: oneOf(['owner', 'client', 'nonClient', 'candidate']),
  conference: object
}

export { MoreActions }
