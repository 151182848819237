import React from 'react';
import { object } from 'prop-types';
import { Link1 } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { CardInfo } from '@seekube-tech/ui';
import { trackRecruiterClickedViewKeyDates } from '@/utils/analytics';
import { EVENT_TYPE } from '@/utils/constants';

import Icon from '@/components/Icon';


const InteractionsClosing = ({ type, dateInteractionAvailable, href, authUser }) => {
  const t = useFormatMessage();
  if (!dateInteractionAvailable._isValid) return null;

  return (
      <CardInfo
        action={
          type !== EVENT_TYPE.jpo && <Link1
            onClick={() => trackRecruiterClickedViewKeyDates({ authUser })}
            href={href}
            target="_blank"
          >
            {t({ id: 'see.keyDates' })}
          </Link1>
        }
        title={t({ id: 'recruiter.infoCard.interaction.available.title' }, { date: dateInteractionAvailable.format('DD/MM'), time: dateInteractionAvailable.format('H[h]mm')})}
        description={t({ id: 'recruiter.infoCard.interaction.available.description' })}
        icon={<Icon style={{ width: '100%', height: '100%' }} name="illuHourglass" />}
      />
  );
};

InteractionsClosing.propTypes = {
  dateInteractionAvailable: object.isRequired,
  authUser: object.isRequired,
  href: object.isRequired,
  type: object.isRequired,
};


export { InteractionsClosing }
