import React from 'react';
import { object, func } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';
import { toJS } from '@/utils';

// Store
import { eventActions, eventSelectors } from '@/store/event';

// Components
import { Form, OffersGoals, CoreTarget } from './components';

import messages from './messages';
import styles from '../../styles.less';

const GoalsScene = ({ event, intl, patchEvent }) => {
  if (!event) return null;

  const handleSubmit = (err, values, formType) => {
    const notificationParams = {
      success: {
        message: `🙌 ${intl.formatMessage(messages.modificationSuccess)}`,
        kind: 'info',
        style: {
          bottom: '5%',
          top: 'inherit',
        },
      },
    };
    if (!err) {
      const { _criteria, targetSchools } = values;

      const eventParams = formType === '_criteria' ? { _criteria, targetSchools } : values;

      patchEvent({
        eventId: event.slug,
        event: eventParams,
        notificationParams,
      });
    }
  };

  return (
    <div className={styles.formWrapper}>
      <Form
        event={event}
        onSubmit={handleSubmit}
      />

      <CoreTarget event={event} />

      <OffersGoals
        event={event}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

GoalsScene.propTypes = {
  event: object,
  intl: object,
  patchEvent: func,
};

const mapStateToProps = createStructuredSelector({
  event: eventSelectors.getCurrentEvent,
});

const mapDispatchToProps = {
  patchEvent: eventActions.patchEvent,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  injectIntl,
)(toJS(GoalsScene));
