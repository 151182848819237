import React, { useEffect, useRef, useState } from 'react';
import { bool, func, object } from 'prop-types';
import { Field } from 'react-final-form';
import { Autocomplete } from '@seekube-tech/ui';
import GoogleMapLoader from 'react-google-maps-loader';

import useValidations from '@/utils/validations/useValidations';

import Icon from '@/components/Icon';

import { getCriterionLabel } from '../utils';

import styles from '../../../styles.less';

function MobilityField({ criterion, googleMaps, showHelper, disabled = true, required = true }) {
  const { isFilled } = useValidations();
  const [options, setOptions] = useState([]);
  const timeout = useRef();

  const getGoogleMapAddress = (address) => {
    if (googleMaps) {
      const autoCompleteService = new googleMaps.places.AutocompleteService();

      if (autoCompleteService) {
        autoCompleteService.getPlacePredictions(
          {
            input: address,
            location: new googleMaps.LatLng(0, 0),
            radius: 20,
            types: ['(cities)'],
            componentRestrictions: { country: '' },
          },
          (googleSuggests) => {
            const formatedResults = googleSuggests
              .filter((suggest) =>
                suggest.types.includes('sublocality') === false &&
                suggest.types.includes('postal_code') === false
              )
              .map((suggest) => ({
                name: suggest.description,
                placeId: suggest.place_id,
              }));

            setOptions(formatedResults);
          },
        );
      }
    }
  };

  const handleInputChange = (_, value) => {
    if (value.length >= 3) {

      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }

      timeout.current = setTimeout(() => {
        getGoogleMapAddress(value);
      }, 500)
    }
  };

  useEffect(() => {
    getGoogleMapAddress('Paris');
  }, [googleMaps]);

  return (
    <div className={styles.marginTop}>
      <Field
        name="locations"
        id="locations"
        disabled={disabled}
        label={getCriterionLabel(criterion)}
        icon={<Icon name={criterion.icon} />}
        component={Autocomplete}
        required={required}
        isMultiple
        options={options}
        onInputChange={handleInputChange}
        fullWidth
        getOptionLabel={(option) => option.name}
        validate={value => isFilled(value)}
        showHelper={showHelper}
      />
    </div>
  );
}

MobilityField.propTypes = {
  criterion: object,
  googleMaps: object,
  showHelper: func,
  disabled: bool
};

MobilityField.defaultProps = {
  criterion: {},
  googleMaps: {},
};

export default GoogleMapLoader(MobilityField, {
  libraries: ['places'],
  key: process.env.FRONT_GOOGLE_MAPS_API_KEY,
});
