import React from 'react';
import { node, bool, func } from 'prop-types';
import keydown, { Keys } from 'react-keydown';

// Components
import { Modal } from 'antd';

// Styles
import styles from './styles.less';

const { ESC } = Keys;

/**
 * ModalBig
 */
class ModalFull extends React.PureComponent {
  static propTypes = {
    visible: bool,
    children: node,
    keyboard: bool,
    onCancel: func,
  };

  /**
   * Close with ESC keydown
   */
  @keydown(ESC)
  closeWithKey() {
    if (this.props.keyboard) {
      this.props.onCancel();
    }
  }

  /**
   * Render
   */
  render() {
    const {
      props: { visible, children },
    } = this;

    return (
      <Modal
        visible={visible}
        wrapClassName={styles.modalFull}
        width={1140}
        style={{ maxWidth: '80%' }}
        {...this.props}
      >
        { children }
      </Modal>
    );
  }
}

export default ModalFull;
