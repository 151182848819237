import React, { useEffect } from 'react';
import { object, func, array } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { replace } from 'connected-react-router';
import { createStructuredSelector } from 'reselect';
import { isEmpty, toString } from 'lodash';
import moment from 'moment';
import { Typography } from '@seekube-tech/ui';
import { useFormatMessage } from 'react-intl-hooks';
import { toJS } from '@/utils';
import { EVENT_STATUS } from '@/utils/constants';

// Store
import { eventSelectors } from '@/store/event';
import { participantSelectors } from '@/store/participant';
import { appointmentSelectors } from '@/store/appointment';
import { exponentSelectors } from '@/store/exponent';
import { authSelectors } from '@/store/auth';

// Components
import Separator from '@/components/Separator';
import BlankState from '@/components/BlankState';
import NavJobdating from './components/NavJobdating';

// Pages
import RecruiterJobdatingDashboardPage from './scenes/Dashboard';
import RecruiterJobdatingApplicationsPage from './scenes/Applications';
import RecruiterJobdatingSourcingPage from './scenes/Sourcing';
import RecruiterJobdatingSelectionPage from './scenes/Selection';
import RecruiterJobdatingInterviewPage from './scenes/Interview';
import RecruiterJobdatingTopPage from './scenes/Top';
import RecruiterJobdatingSavePage from './scenes/Save';
import RecruiterJobdatingRefusePage from './scenes/Refuse';
import RecruiterJobdatingAppointmentPage from './scenes/Appointment';

const RecruiterJobdatingScene = ({
  match,
  stats,
  exponent,
  replace,
  currentEvent,
  history,
  userAppointments,
  authUser,
}) => {
  const t = useFormatMessage();

  useEffect(() => {
    history.listen(() => { });

    if (match.isExact) {
      replace(`${match.url}/sourcing`);
    }
  }, []);


  function isCvthequeClosed() {
    if (isEmpty(exponent)) return false;

    const closeDate = !isEmpty(toString(currentEvent.keyDates.hunt.endAt)) ? moment(currentEvent.keyDates.hunt.endAt) : moment(currentEvent.keyDates.jobfair.endAt);
    const cvthequeClosingCountDown = exponent.limits.cvtheque || currentEvent.limits.cvtheque;

    return (cvthequeClosingCountDown === -1 || cvthequeClosingCountDown > 999 ? 1000 : moment(closeDate.add(cvthequeClosingCountDown, 'days')).isBefore(moment()));
  }

  const appointmentIds = userAppointments?.map((appointment) => appointment?._id?.toString()) || [];
  const locationHref = location.href;
  const isHuntClosed = moment().isAfter(moment(currentEvent.keyDates.hunt.endAt)) || moment().isBefore(moment(currentEvent.keyDates.hunt.beginAt));

  if (currentEvent &&
    (!locationHref.includes('/appointment') || (
      locationHref.includes('/appointment') &&
      !appointmentIds.includes(locationHref.split('/')[locationHref.split('/').length - 1].toString())
    )) && isHuntClosed && currentEvent.dateStatus !== EVENT_STATUS.HUNT_POST_EVENT && currentEvent.dateStatus !== EVENT_STATUS.CLOSED && !authUser.isAdmin) {
    const blankStateContent = (
      <>
        <div>
          <Typography variant="body1" component="div">
            {t({ id: 'event.recruiter.jobdating.not.open' })}
          </Typography>
          <Typography
            variant="link1"
            target="_blank"
            component="a"
            href={`${window.location.origin}/${match.params.eventSlug}/recruiter/keydates`}
          >
            {t({ id: 'see.keyDates' })}
          </Typography>
        </div>
        <Separator height={20} />
      </>
    );

    return (
      <>
        <Separator height={70} />
        <BlankState
          title=""
          content={blankStateContent}
          icon="clockPokemon"
        />
      </>
    );
  }

  if (isHuntClosed && isCvthequeClosed() && !authUser.isAdmin) {
    return (
      <BlankState
        title={<>{t({ id: 'event.card.cta.label[2]' })}</>}
        icon="clockPokemon"
        style={{ marginTop: '80px' }}
      />
    );
  }

  if (match.isExact) {
    replace(`${match.url}/sourcing`);
  }

  return (
    <div>
      <NavJobdating match={match} stats={stats} event={currentEvent} />
      <Separator height={10} />
      <Switch>
        <Route path={`${match.path}/dashboard`} component={RecruiterJobdatingDashboardPage} />
        <Route path={`${match.path}/applications`} component={RecruiterJobdatingApplicationsPage} />
        <Route path={`${match.path}/sourcing`} component={RecruiterJobdatingSourcingPage} />
        <Route path={`${match.path}/selection`} component={RecruiterJobdatingSelectionPage} />
        <Route path={`${match.path}/interview`} component={RecruiterJobdatingInterviewPage} />
        <Route path={`${match.path}/refuse`} component={RecruiterJobdatingRefusePage} />
        <Route path={`${match.path}/top`} component={RecruiterJobdatingTopPage} />
        <Route path={`${match.path}/save`} component={RecruiterJobdatingSavePage} />
        <Route path={`${match.path}/appointment/:appointmentID`} component={RecruiterJobdatingAppointmentPage} />
      </Switch>
    </div>
  );
}

RecruiterJobdatingScene.propTypes = {
  match: object,
  stats: object,
  exponent: object,
  replace: func,
  currentEvent: object,
  history: object,
  location: object,
  userAppointments: array,
  authUser: object,
};

const mapStateToProps = createStructuredSelector({
  currentEvent: eventSelectors.getCurrentEvent,
  stats: participantSelectors.getParticipantsStats,
  userAppointments: appointmentSelectors.getUserAppointments,
  authUser: authSelectors.getAuthUser,
  exponent: exponentSelectors.getCurrentExponent,

});

const mapDispatchToProps = {
  replace,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(toJS(RecruiterJobdatingScene));
