import ModalV2 from '@/components/ModalV2';
import { Body1, Button, H4 } from '@seekube-tech/ui-kit';
import colors from '@seekube-tech/ui-kit/dist/colors';
import React from 'react';
import { trackRecruiterDraftOffer } from '@/utils/analytics';
import { useFormatMessage } from 'react-intl-hooks';
import { useSelector } from 'react-redux';
import { getAuthUser } from '@/store/auth/selectors';
import {  useUpdateOfferByOwner } from '@/queries/offers/useUpdateOfferByOwner';

export const ModalOfferInDraft = ({ offer, onClose, isVisible, exponent }) => {
  const t = useFormatMessage();
  const authUser = useSelector(getAuthUser)?.toJS();

  const mutateUpdateStatusOfferByOwner = useUpdateOfferByOwner({
    eventSlug: exponent?._event?.slug,
    organizationId: exponent?._organization?._id,
    onSuccess: () => {
      trackRecruiterDraftOffer({ authUser, event: exponent?.event })
      onClose();
    }})

  return (
    <ModalV2
      visible={isVisible}
      onClose={onClose}
    >
      <div className="p-24">
        <H4 color={colors.neutral['500']} className="mb-20">
          {t({ id: 'modal.offer.in.draft.title' })}
        </H4>
        <div>
          <Body1 className="ml-4" color={colors.neutral['500']}>
            {t({ id: 'modal.offer.in.draft.bullet1' })}<br/>
            {t({ id: 'modal.offer.in.draft.bullet2' }, { name: exponent?._organization?.name })}
          </Body1>
        </div>


        <div className="confirm-actions mt-24">
          <Button
            color="neutral"
            variant="outline"
            className="mr-6"
            onClick={onClose}
          >
            {t({ id: 'cancel' })}
          </Button>
          <Button
            loading={mutateUpdateStatusOfferByOwner.isLoading}
            color="error"
            onClick={() => {
              mutateUpdateStatusOfferByOwner.mutate({ status: 'draft',  offerId: offer._id });
            }}
          >
            {t({ id: 'delete' })}
          </Button>
        </div>
      </div>
    </ModalV2>
  )
}