import React from 'react';
import { object } from 'prop-types';
import { IconLink, IconFile, Link2 } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { getUserMediaUrl } from '@/helpers/resume';
import styles from './styles.less';

export function ExternalMedia({ participant }) {
  const t = useFormatMessage();
  const links = participant._user?.links?.filter(link => !isEmpty(link.url));
  const mediaItems = participant._user?.mediaItems?.filter(media => !isEmpty(media.fileKey));

  if (isEmpty(mediaItems) &&  isEmpty(links)) return null;

  return (
    <div className={styles.externalLinksContainer}>
      <h5 className="sectionTitle">{t({ id: 'external.element.pl'}, { count: 2 })}</h5>

      {links?.map((link, key) => (
          <div className="flex text-primary-500 mb-8 items-end" key={key}>
            <span className="mr-12 text-neutral-400" ><IconLink size={20}/></span>
            <Link2 className="truncate" target="_blank" href={link.url}>{link.url}</Link2>
          </div>
        ))}

      {mediaItems?.map((media, key) => (
        <div className="flex text-primary-500 mb-8 items-end" key={key}>
          <span className="mr-12 text-neutral-400" ><IconFile size={20}/></span>
          <Link2 className="truncate" target="_blank" href={getUserMediaUrl(media.fileKey)}>{media.title}</Link2>
        </div>
        ))}
    </div>
  );
}

ExternalMedia.propTypes = {
  participant: object,
};
