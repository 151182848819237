import React, { useState } from 'react';
import { bool, string, func } from 'prop-types';
import classnames from 'classnames';
import { useFormatMessage } from 'react-intl-hooks';
import { ANALYTICS_RECRUITER } from '@/utils/constants';
import { track } from '@/utils/analytics';
import Icon from '@/components/Icon';
import InlineEditor from '../InlineEditor';
import styles from './styles.less';

const Contacts = ({
  email,
  phone,
  displayContacts = true,
  onDisplay,
  appointmentStatus = '',
  containerClassName,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const t = useFormatMessage();

  const toggleContacts = () => {
    if (typeof onDisplay === 'function') {
      onDisplay();
    }
    if (!displayContacts) {
      setIsVisible(!isVisible);
    }

    track({
      name: ANALYTICS_RECRUITER.DISPLAYED_CONTACT_INFO,
      properties: {
        'Appointment Planned': appointmentStatus,
      },
    });
  };


  return (
    <div className={classnames(styles.contactInfosContainer, containerClassName)}>
      {!isVisible ? (
        <button className={styles.displayContactBtn} onClick={toggleContacts}>
          <Icon name="phone" /> {t({ id: 'display.info.contact' })}
        </button>
      ) : (
        <div>
          <div className={classnames(styles.contactInfo, 'phone')}>
            <Icon name="icon-tel" />
            <InlineEditor
              value={phone}
              content={<a href={`tel:${phone}`} />}
              tooltipPlacement="bottom"
            />
          </div>
          <div className={classnames(styles.contactInfo, 'mail')}>
            <Icon name="message" />
            <InlineEditor
              value={email}
              content={<a href={`mailto:${email}`} />}
              tooltipPlacement="bottom"
            />
          </div>
        </div>
      )
      }
    </div>
  );
}


Contacts.propTypes = {
  email: string,
  phone: string,
  displayContacts: bool,
  onDisplay: func,
  appointmentStatus: string,
  containerClassName: string,
};

export default Contacts;
