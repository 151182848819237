import React from 'react';
import { object } from 'prop-types';

// Components
import Icon from '@/components/Icon';

// Styles & Translations
import styles from './styles.less';

const iconMatchLinkType = {
  portfolio: 'link',
  blog: 'link',
  website: 'link',
};

/**
 * Link
 */
const Link = ({ link }) => <a href={link.url} target="_blank" className={styles.baseLink}><Icon name={iconMatchLinkType[link.label]} className={styles.icon} /> <span>{link.href}</span></a>;

Link.propTypes = {
  link: object,
};

export default Link;
