import React, { Fragment, useState } from 'react';
import { without } from 'lodash';
import { array, func, bool, oneOf, object } from 'prop-types';
import { getId } from '@/utils/global';
import { EventCard } from './components';
import styles from './styles.less';

const Events = ({
  defaultValue = [],
  context,
  events,
  exponents,
  handleEventsSelected,
  multi = true,
  offer
}) => {
  const [selectedEvents, setSelectedEvents] = useState(defaultValue);

  const onEventSelected = (isChecking, eventId) => {
    let res = [];

    if (multi) {
      if (isChecking) {
        res = [...selectedEvents, eventId];
      } else {
        res = without(selectedEvents, eventId);
      }
    } else { res = [eventId]; }

    setSelectedEvents(res);
    handleEventsSelected(res);
  };

  const isDefaultChecked = ({ _id }) => selectedEvents.indexOf(_id) > -1;

  return (
    <div className={styles.eventsContainer}>
      {events.map(
        (event, key) => (
          <Fragment key={key}>
            <EventCard
              key={key}
              context={context}
              defaultChecked={isDefaultChecked(event)}
              exponent={exponents.find((exponent) => getId(exponent._event) === getId(event._id))}
              event={event}
              onEventSelected={onEventSelected}
              offer={offer}
            />
          </Fragment>
        )
      )}
    </div>
  );
};

Events.propTypes = {
  defaultValue: array,
  context: oneOf(['offer', 'live']),
  events: array,
  exponents: array,
  handleEventsSelected: func,
  multi: bool,
  offer: object
};


export { Events };
