import { defineMessages } from 'react-intl';

export default defineMessages({
  registrationTitle: {
    id: 'event.owner.statistics.participants.registration.title',
  },
  registrationTooltip: {
    id: 'event.owner.statistics.participants.registration.tooltip',
  },
  registrationPercentageTooltip: {
    id: 'event.owner.statistics.participants.registration.percentage.tooltip',
  },
  applicationTitle: {
    id: 'event.owner.statistics.participants.application.title',
  },
  applicationApplyTitle: {
    id: 'event.owner.statistics.participants.application.apply.title',
  },
  applicationApplyTooltip: {
    id: 'event.owner.statistics.participants.application.apply.tooltip',
  },
  applicationApplyPercentageTooltip: {
    id: 'event.owner.statistics.participants.application.apply.percentage.tooltip',
  },
  applicationAverageTooltip: {
    id: 'event.owner.statistics.participants.application.average.tooltip',
  },
  propositionTitle: {
    id: 'event.owner.statistics.participants.proposition.title',
  },
  propositionSourcingAppointmentsTitle: {
    id: 'event.owner.statistics.participants.proposition.sourcingAppointments.title',
  },
  propositionSourcingAppointmentsTooltip: {
    id: 'event.owner.statistics.participants.proposition.sourcingAppointments.tooltip',
  },
  propositionSourcingAppointmentsPercentageTooltip: {
    id: 'event.owner.statistics.participants.proposition.sourcingAppointments.percentage.tooltip',
  },
  propositionAverageTooltip: {
    id: 'event.owner.statistics.participants.proposition.average.tooltip',
  },
  interviewTitle: {
    id: 'event.owner.statistics.stats.jobdating.interview.title',
  },
  candidateInterviewTitle: {
    id: 'event.owner.statistics.stats.candidate.interview.title',
  },
  candidateInterviewTooltip: {
    id: 'event.owner.statistics.stats.candidate.interview.tooltip',
  },
  candidateInterviewPercentageTooltip: {
    id: 'event.owner.statistics.stats.candidate.interview.percentage.tooltip',
  },
  candidateInterviewAverage: {
    id: 'event.owner.statistics.stats.candidate.interview.average',
  },
});
