import React, { memo } from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { useHistory } from 'react-router-dom';
import { objectToParams } from '@/utils/url';
import styles from './styles.less';
import EventsSearching from '@/components/SearchEvents';
import { getLocale } from '@/utils/localStorage';
import useEventsSearchEngine from '@/utils/hooks/useEventsSearchEngine';
import { object } from 'prop-types';
import { Actions } from '@/components/SearchEvents/Results/components/Actions';
import useSearchEngineTracker from '@/utils/analytics/trackers/category/eventSearchEngine/useSearchEngineTracker';

const SearchEvents = ({
  authUser
}) => {
  const t = useFormatMessage();
  const locale = getLocale();
  const history = useHistory();
  const {
    events,
    suggestedEvents,
    hasFoundEvents,
    shouldShowNoResults,
    contracts,
    localisations,
    search,
    isEmptyFilters,
    keyword
  } = useEventsSearchEngine({ authUser, enableSuggestionSearch: true });
  const { 
  } = useSearchEngineTracker({ 
    events,
    contracts,
    localisations,
    keyword
  });
  const shouldShowSuggestedEvents = !hasFoundEvents || isEmptyFilters;
  const eventsInfiniteQuery = shouldShowSuggestedEvents ? suggestedEvents : events;
  
  const onSubmit = (filters, facetKey = '') => {
    history.push({
      search: objectToParams({ ...filters, facetKey })
    });
  }
  
  const getActionComponent = (event) => {
    return <Actions event={event} authUser={authUser} />;
  }
  
  const getResultsTitle = () => {
    if (isEmptyFilters) {
      return;
    }
    if (!hasFoundEvents) {
      return t({ id: 'searchEngine.list.suggestions' });
    }
    return t({ id: 'searchEngine.list.results.title.pl' }, { count: events.data?.pages?.[0]?.total });
  }
  
  return (
    <div className={styles.container}>
      <EventsSearching.SearchEngine
        contracts={contracts}
        localisations={localisations}
        keywords={search?.keywords || ''}
        facets={events?.data?.pages?.[0]?.offersFacets || {}}
        onFilterChange={onSubmit}
        onSubmit={onSubmit}
      />
      <EventsSearching.Results
        authUser={authUser}
        locale={locale}
        shouldShowNoResults={shouldShowNoResults && !isEmptyFilters}
        isLoading={events.isFetching || suggestedEvents.isLoading}
        eventsInfiniteQuery={eventsInfiniteQuery}
        filters={[...contracts, ...localisations]}
        keywords={search?.keywords || ''}
        title={getResultsTitle()}
        actions={getActionComponent}
        shouldHideOffersCount={isEmptyFilters || !hasFoundEvents}
      />
    </div>
  )
}

SearchEvents.propTypes = {
  authUser: object
}

export default memo(SearchEvents);