import React from 'react';
import { bool, string } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { Button } from '@seekube-tech/ui-kit';

import { STATUS } from '../../utils/constants';

function PublishButton({ disabledFields = false }) {
  /* Vars */

  const t = useFormatMessage();
  /* Functions */

  /* Render */
  return (
    <Button
      type="submit"
      color="primary"
      disabled={disabledFields}
    >
      {t({ id: 'publish' })}
    </Button>
  );
}

PublishButton.propTypes = {
  status: string.isRequired,
  pristine: bool.isRequired,
};

export default PublishButton;
