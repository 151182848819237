import React from 'react';
import { TapBarText } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { func, string } from 'prop-types';
import { Menu } from '@/scenes/Settings/MenuSettings';

export const MenuMobile = ({ handleOnNavigate, currentView }) => {
  const t = useFormatMessage();

  const menuElements = [{
    text: t({id: 'header.userDropdown.profile'}),
    onClick: (e) => handleOnNavigate(e, 'profile', 'bigModalMainPanel'),
    id: 'profile',
  },{
    text: t({id: 'event.owner.settings'}),
    onClick: (e) => handleOnNavigate(e, 'settings', 'userSettings'),
    id: 'settings',
  }];

  return (
    <Menu>
      <TapBarText menuElements={menuElements} activeTab={currentView} />
    </Menu>

  )
}

MenuMobile.propTypes = {
  currentView: string,
  handleOnNavigate: func,
}
