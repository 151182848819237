import React from 'react';
import { bool, func, object } from 'prop-types';
import { Button, H4 } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import Modal from '@/components/Modal';
import styles from '@/scenes/Event/scenes/Owner/scenes/Dashboard/components/AddActivity/styles.less';

export const ModalDraftActivity = ({ isVisible, onCancel, onConfirm, mutateUpdateActivity }) => {
  const t = useFormatMessage();

  return (
    <Modal
      width={672}
      visible={isVisible}
      footer={null}
      onCancel={onCancel}
    >

      <div className={styles.headerModal}>
        <H4>
          {t({ id: 'modal.draft.activity.title' })}
        </H4>
        <div className={styles.buttonsContainer}>
          <Button variant='outline' color='neutral' onClick={onCancel}>
            {t({ id: 'cancel' })}
          </Button>
          <Button
            color='error'
            onClick={onConfirm}
            disabled={mutateUpdateActivity.isLoading || mutateUpdateActivity.isSuccess}
            loading={mutateUpdateActivity.isLoading}
          >
            {t({ id: 'btn.confirm' })}
          </Button>

        </div>
      </div>
    </Modal>
  )
}

ModalDraftActivity.propTypes = {
  isVisible: bool,
  onCancel: func,
  onConfirm: func,
  mutateUpdateActivity: object,
}
