import React, { useEffect, useState } from 'react';
import { H5, Caption1, Toggle } from '@seekube-tech/ui-kit';
import colors from '@seekube-tech/ui-kit/dist/colors';
import { useFormatMessage } from 'react-intl-hooks';
import { func } from 'prop-types';
import FormToApi
  from '@/scenes/Event/scenes/Owner/scenes/Setting/scenes/Modules/services/formMapping/FormToApi';
import { Bloc, BlocHeader } from '@/components/Bloc';
import { useSelector } from 'react-redux';
import { getCurrentEvent } from '@/store/event/selectors';
import { track } from '@/utils/analytics';
import { getAuthUser } from '@/store/auth/selectors';
import { ANALYTICS_CANDIDATE, ANALYTICS_ORGA } from '@/utils/constants';


export const EnableReporting = ({ handleSubmit }) => {
  const t = useFormatMessage();
  const event = useSelector(state => getCurrentEvent(state)?.toJS())
  const [checked, setChecked] = useState(!!event?.modules?.reports?.enable);
  const authUser = useSelector(getAuthUser);

  const onChange = (val) => {
    if (event.keyDatesOk) {
      setChecked(val);
    }
  }

  useEffect(() => {
    if (event && event.modules?.reports?.enable !== checked) {
      handleSubmit({ modules: FormToApi.modules(event.modules, 'reports', { enable: checked }) });
      track({
        name: checked ? ANALYTICS_ORGA.ENABLE_REPORTINGS : ANALYTICS_ORGA.DISABLE_REPORTINGS,
        user: authUser,
        event,
        properties: {
          page: 'settings/reportings'
        }
      })
    }
  }, [checked, event])

  return (
    <Bloc>
      <div>
        <BlocHeader>
          <H5 color={colors.neutral['500']}>
            {t({ id: 'owner.reporting.activate' })}
          </H5>
          <Toggle
            onChange={onChange}
            checked={checked}
          />
        </BlocHeader>
        {
          !event.keyDatesOk &&
          <Caption1 className="text-warning-500 mt-5">
            {t({ id: 'owner.reporting.warning.no.retroPlanning' })}
          </Caption1>

        }
      </div>
    </Bloc>
  );
}

EnableReporting.propTypes = {
  handleSubmit: func,
};
