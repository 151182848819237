import { useMutation, useQueryClient } from 'react-query';
import { filter } from 'lodash';
import request from '@/utils/request';

export function useDeleteTimeslots({ userId, eventId, onSuccess = () => {}, }) {
  const queryClient = useQueryClient();

  const mutationFn = ({ timeslots }) => {
    const requestUrl = `${process.env.FRONT_API_URL}/users/${userId}/timeslots`;

    return (
      request(requestUrl, {
        method: 'DELETE',
        body: JSON.stringify(timeslots),
      })
    )
  }

  return useMutation({
    mutationFn,
    onSuccess: async (_, { timeslots: timeslotIdsDeleted }) => {
      const timeslots = queryClient.getQueryData(['timeslots', userId, eventId]);
      const newTimeslots = filter(timeslots, (prevTimeslots) => !timeslotIdsDeleted.includes(prevTimeslots._id));

      queryClient.setQueryData(['timeslots', userId, eventId], newTimeslots);
      onSuccess();
    },
    refetchOnWindowFocus: false,
  });
}
