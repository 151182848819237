import React from 'react';
import { object, string } from 'prop-types';
import classnames from 'classnames';

// Components
import GoogleMapLoader from 'react-google-maps-loader';

// Styles & Translations
import styles from './styles.less';

/**
 * Location
 */
class Location extends React.PureComponent {
  static propTypes = {
    googleMaps: object,
    element: object,
    className: string,
  };

  /**
   * Constructor
   *
   * @param {object} props
   */
  constructor(props) {
    super(props);

    this.state = {
      map: null,
    };
  }

  /**
   * Send default value to the parent & init the map
   */
  componentDidMount() {
    const { props: { googleMaps }, initMap } = this;

    if (googleMaps) {
      initMap();
    }
  }

  /**
   * Init the map when GoogleMaps is loaded
   *
   * @param {object}prevProps
   */
  componentDidUpdate(prevProps) {
    const { props: { googleMaps }, initMap } = this;

    if (!prevProps.googleMaps && googleMaps) {
      initMap();
    }
  }

  /**
   * Create the google map
   */
  initMap = () => {
    const { props: { googleMaps: GoogleMaps, element }, mapElement } = this;

    // Event position used for center the map and create a marker
    const eventPosition = {
      lng: element.address.geometry.coordinates[0],
      lat: element.address.geometry.coordinates[1],
    };

    // Map options
    const mapOptions = { // Create options for the map
      center: eventPosition,
      zoom: 15,
      draggable: false,
      disableDefaultUI: true,
    };

    // Create the map
    const map = new GoogleMaps.Map(mapElement, mapOptions);

    // Create a marker
    const marker = new GoogleMaps.Marker({ // eslint-disable-line
      position: eventPosition,
      map,
    });

    this.setState({ map });
  };

  /**
   * Render
   */
  render() {
    const { props: { element, className } } = this;

    return (
      <div className={classnames(styles.mapContainer, className)}>
        <div className={styles.mapInfoContainer}>
          { element.address.formattedAdress }
        </div>
        <div
          ref={(ref) => { this.mapElement = ref; }}
          style={{ width: '100%', height: element.height }}
        />
      </div>
    );
  }
}

export default GoogleMapLoader(Location, {
  libraries: ['places', 'geometry'],
  key: process.env.FRONT_GOOGLE_MAPS_API_KEY,
});
