import React from 'react';
import { object, func, bool } from 'prop-types';
import { map, get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { StatBloc } from '@/components/Stats/StatBloc';
import { Tooltip } from '@/components/Tooltip';

import styles from './styles.less';
import messages from '../../messages';
import thisStyles from '../StatsConversion/styles.less';

const StatsOffer = ({ data, loading, statBlocProperties = {}, onActionClick }) => {
  const stats = map(get(data, 'docs'), (offer) => ({ item: offer.title }));

  const statsModal = map(get(data, 'docs'), (offer) => (
    {
      item: (
        <div className={styles.row}>
          <Tooltip
            title={offer.title}
            color="dark"
            placement="bottom"
          >
            <span>{offer.title}</span>
          </Tooltip>
          <div className={thisStyles.columnStats}>
            <span>{offer.countEvents}</span><span>{offer.countApplications}</span></div>
        </div>
      ),
    })
  );

  return (
    <StatBloc
      title={<FormattedMessage {...messages.offers} values={{ count: 2 }} />}
      withList
      classContainer={styles.statsOffer}
      withAction
      onActionClick={onActionClick}
      count={get(data, 'total') || 0}
      loading={loading}
      dataSource={stats}
      modalProperties={{
        header: (
          <div className={styles.extraContent}>
            <span><span style={{ textAlign: 'left' }}> <FormattedMessage {...messages.offerPublish} values={{ count: 2 }} /></span></span>
            <span style={{ display: 'flex', justifyContent: 'flex-end' }} ><span style={{ width: '84px' }}><FormattedMessage {...messages.offerEventNumber} /></span><span style={{ width: '84px' }}><FormattedMessage {...messages.offerApplications} /></span>
            </span>
          </div>
        ),
        dataSource: statsModal,
        title: <FormattedMessage {...messages.offerTitle} />,
      }}
      {...statBlocProperties}
    />
  );
};

StatsOffer.propTypes = {
  data: object,
  loading: bool,
  statBlocProperties: object,
  onActionClick: func,
};

export { StatsOffer };
