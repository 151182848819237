import moment from 'moment';
import { get } from 'lodash';

/**
 * @description
 * it takes all slots and remove slots that is not inside the event selected
 *
 */
export function getSlotsBoundedByEvent(slots, currentEvent) {
  return slots
    .filter((timeslot) => (
      moment(timeslot.beginAt) >= moment(get(currentEvent, 'keyDates.jobfair.beginAt')) &&
      moment(timeslot.beginAt) <= moment(get(currentEvent, 'keyDates.jobfair.endAt')))
    ).map((slot) => [slot]);
}
