import { denormalize } from 'normalizr';
import { createSelector } from 'reselect';
import { offerListSchema, offerSchema } from './schema';
import { sectorListSchema } from '../sector/schema';

const getCrossEventsReducer = (state) => state.get('offer');
const getOfferReducer = (state) => state.get('offer');
const getEntities = (state) => state.get('entities');
const getFacets = (state) => state.get('offer').get('facets');

/**
 * Return all offers
 */
const getOffers = createSelector(
  getOfferReducer,
  getEntities,
  getFacets,
  (offerState, entities) => {
    const allIds = offerState.get('allIds');
    const offersList = allIds.map((id) => entities.getIn(['offers', id]));
    return denormalize(offersList, offerListSchema, entities);
  }
);

/**
 * Return crossForumOffers
 */
const getCrossForumOffer = createSelector(
  getCrossEventsReducer,
  getEntities,
  getFacets,
  (offerState) => offerState.get('crossEventsIds')
);

export const getOfferPagination = createSelector(
  getOfferReducer,
  (offerState) => offerState.get('pagination'),
);

export const getStats = createSelector(
  getOfferReducer,
  (offerState) => offerState.get('stats'),
);


/**
 * Return an offer
 */
export const getParticipantSelectedOffer = createSelector(
  getOfferReducer,
  getEntities,
  (offerState, entities) => {
    const offer = entities.getIn(['offers', offerState.get('participantSelectedOffer')]);

    return denormalize(offer, offerSchema, entities);
  }
);

export const getSectors = createSelector(
  getOfferReducer,
  getEntities,
  getFacets,
  (exponentState, entities, facets) => {
    const sectors = facets ? facets['organizationProfile.sectors._id'] : null;

    let sectorList = [];

    if (sectors) {
      sectorList = Object.keys(sectors).map((id) => entities.getIn(['sectors', id]));
    }

    return denormalize(sectorList, sectorListSchema, entities);
  }
);

/**
 * Get loader
 */
export const getOffersFetching = createSelector(
  getOfferReducer,
  (offerState) => offerState.get('isFetching'),
);

export const getTotalOffers = createSelector(
  getOfferReducer,
  (offerState) => offerState.get('totalOffers'),
);

export const getCountSlots = createSelector(
  getOfferReducer,
  (offerState) => offerState.get('countSlots'),
);

/**
 * Get participant last search offers
 */
export const getParticipantOffersLastSearch = createSelector(
  getOfferReducer,
  (offerState) => offerState.get('lastParticipantOffersSearch'),
);

export {
  getCrossForumOffer,
  getOffers,
  getFacets,
};
