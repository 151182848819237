import React from 'react';
import { H1 } from '@/components/Title';
import styles from '../../styles.less';

class ValidationScene extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  render() {
    return (
      <div className={styles.formWrapper}>
        <H1 color="black">Validation</H1>
      </div>
    );
  }
}

export default ValidationScene;
