import React, { useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { NavLink } from 'react-router-dom';
import { string, number, array, object, any, oneOf, bool, func } from 'prop-types';
import { size } from 'lodash';
import { useFormatMessage } from 'react-intl-hooks';
import { push } from 'connected-react-router';
import { createStructuredSelector } from 'reselect';
import { COLORS } from '@/utils/global';


import Icon from '@/components/Icon';
import { authSelectors } from '@/store/auth';
import { toJS } from '@/utils';
import styles from '../styles.less';

function InfosList({
  _id,
  duration,
  beginAt,
  maxAttendees,
  countAttendees,
  presenters,
  handleOpenLinksModal,
  _event,
  context,
  hideParticipants,
  authUser,
}) {
  const t = useFormatMessage();
  const [isPresentersModalVisible, setIsPresentersModalVisible] = useState(false);

  return (
    <div className={styles.infoList}>
      <span>
        <Icon name="calendar" />{`${moment.tz(beginAt, authUser?.timezone.utc[0]).format('DD/MM - HH:mm')} (${duration} min)`}
      </span>
      {['candidate'].includes(context) && (
        <span>
          <Icon name="user" />{t({ id: 'cardLive.seats' }, { count: maxAttendees })}
        </span>
      )}
      {['nonClient', 'client'].includes(context) && (
        <span>
          <Icon name="house" />
          <span>
            {_event.name}
          </span>
        </span>
      )}
      {['owner', 'nonClient', 'client'].includes(context) && (
        <span>
          <Icon name="user" />
          <span style={{ color: (maxAttendees === countAttendees) && COLORS.error }}>
            {t({ id: 'livecard.attendeed.count' }, { count: countAttendees, total: maxAttendees })}
          </span>
          {hideParticipants !== true && (
            <NavLink to={['owner', 'client'].includes(context) ? `${_id}/all` : `conferences/${_id}/all`}>
              {t({ id: 'livecard.actions.see.participants' })}
            </NavLink>
          )}
        </span>
      )}
      {['owner', 'nonClient', 'client'].includes(context) && (
        <span>
          <Icon name="micro" />
          <span style={{ color: (maxAttendees === countAttendees) }}>
            {t({ id: 'presenter.pl' }, { count: size(presenters) })}
          </span>
          <a role="button" tabIndex={0} onClick={handleOpenLinksModal}>{t({ id: 'see.links' })}</a>
        </span>
      )}
    </div>
  );
}

InfosList.propTypes = {
  duration: number,
  beginAt: string,
  maxAttendees: number,
  countAttendees: number,
  presenters: array,
  _event: object,
  _id: any,
  hideParticipants: bool,
  handleOpenLinksModal: func,
  context: oneOf(['owner', 'client', 'nonClient', 'candidate']),
  authUser: object,
};

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser
})

const mapDispatchToProps = {
  push,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(toJS(InfosList));
