import { useQuery } from 'react-query';
import request from '@/utils/request';

export function useGetSpotlight({ keywords = '', limit = 15, ...props  }) {
  const requestUrl = `${process.env.FRONT_API_URL}/spotlight?keywords=${keywords}&limit=${limit}`;
  const queryFn = () => request(requestUrl, { method: 'GET' });

  const query = useQuery({
    queryKey: ['spotlight', keywords],
    queryFn,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    ...props,
  });
  return query;
}
