import React from 'react';
import { node, string, oneOfType } from 'prop-types';
import omit from 'lodash/omit';
import classNames from 'classnames';

// Styles & Translations
import styles from './styles.less';

/**
 * Carousel
 * @param {object} props
 */
const Nav = (props) => (
  <div {...omit(props, ['className'])} className={classNames(styles.nav, props.className)}>
    {props.children}
  </div>
);

Nav.propTypes = {
  children: oneOfType([string, node]),
  className: string,
};

export default Nav;
