import React from 'react';
import { object, func } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { isEmpty, isEqual } from 'lodash';
import { injectIntl } from 'react-intl';

// Store
import { offerActions, offerSelectors } from '@/store/offer';
import { participantActions, participantSelectors } from '@/store/participant';
import { authSelectors } from '@/store/auth';
import { eventSelectors } from '@/store/event';
import { criterionActions, criterionSelectors } from '@/store/criterion';
import { userActions } from '@/store/user';

// containers
import StaticOffers from '@/scenes/Event/components/StaticOffers';
import { withLayout } from '@/containers/Layout';
import { toJS } from '@/utils';

// components
import Wrapper from '@/components/Wrapper';
import PreregistrationModal from './components/PreregistrationModal';

/**
 * RecruiterPreparationTeamScene
 */
class CandidatePreparationParticipationScene extends React.PureComponent { // eslint-disable-line
  static propTypes = {
    event: object,
    authUser: object,
    postPreregisteredParticipant: func,
    history: object,
    getParticipant: func,
  };

  state = {
    initialValues: {},
    matchingModalIsVisible: false,
    preregistrationModalIsVisible: false,
  };


  componentDidMount() {
    const { props: { event, getParticipant, authUser } } = this;

    if (!isEmpty(event) && !isEmpty(authUser)) {
      getParticipant({
        eventId: event._id,
        userId: authUser._id,
      });
    }

    this.checkEventDate(event);
  }

  componentWillReceiveProps({ event, authUser, getParticipant }) {
    this.checkEventDate(event);
    if (!isEmpty(event) && !isEqual(event, this.props.event) && !isEmpty(authUser)) {
      getParticipant({
        eventId: event._id,
        userId: authUser._id,
      });
    }
  }

  checkEventDate(event) {
    if (event && event.dateStatus === 'preregistration') {
      this.setState({ preregistrationModalIsVisible: true, matchingModalIsVisible: false });
    } else {
      this.setState({ preregistrationModalIsVisible: false, matchingModalIsVisible: true });
    }
  }

  handleOnPreregistration = (err, values) => {
    const {
      props: { postPreregisteredParticipant, event },
    } = this;

    postPreregisteredParticipant({ eventId: event.slug, toPost: values });
  }

  /**
   * Render
   */
  render() {
    const {
      props: { event, authUser, history },
      state: { preregistrationModalIsVisible },
      handleOnPreregistration,
    } = this;

    if (isEmpty(event)) {
      return null;
    }

    return (
      <Wrapper>
        <StaticOffers />
        <PreregistrationModal
          visible={preregistrationModalIsVisible}
          event={event}
          authUser={authUser}
          email={authUser && authUser.username}
          onSubmit={handleOnPreregistration}
          history={history}
        />
      </Wrapper>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser,
  participant: participantSelectors.getCurrentParticipant,
  event: eventSelectors.getCurrentEvent,
  totalOffers: offerSelectors.getTotalOffers,
  defaultCriteria: criterionSelectors.getDefaultCriteria,
});

const mapDispatchToProps = {
  getParticipant: participantActions.getParticipant,
  patchParticipant: participantActions.patchParticipant,
  postParticipant: participantActions.postParticipant,
  postPreregisteredParticipant: participantActions.postPreregisteredParticipant,
  getParticipantOffers: offerActions.getParticipantOffers,
  getDefaultCriteria: criterionActions.getDefaultCriteria,
  patchUser: userActions.patchUser,
  setLastParticipantOfferSearch: offerActions.setLastParticipantOfferSearch,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withLayout({ size: 'default', withHeader: false, withBlur: true }),
  withConnect,
  injectIntl,
)(toJS(CandidatePreparationParticipationScene));
