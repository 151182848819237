import { defineMessages } from 'react-intl';

export default defineMessages({
  duplicate: {
    id: 'duplicate',
  },
  duplicateEventTitle: {
    id: 'event.menu.duplicate',
  },
  create: {
    id: 'create',
  },
  edit: {
    id: 'offer.update',
  },
  createEventTitle: {
    id: 'admin.createEventModal.title.create',
  },
  inputNameLabel: {
    id: 'admin.createEventModal.name.label',
  },
  jobDatingTypeLabel: {
    id: 'event.basic.type.label',
  },
  jobDatingTypeSchool: {
    id: 'event.basic.type.school',
  },
  jobDatingTypeCompany: {
    id: 'event.basic.type.company',
  },
  targetsLabel: {
    id: 'event.basic.targets.label',
  },
  targetsEngineer: {
    id: 'engineer.short',
  },
  targetsTech: {
    id: 'technical.short',
  },
  targetsBusiness: {
    id: 'business',
  },
  targetsOther: {
    id: 'other',
  },
  errorFieldRequired: {
    id: 'form.error.required',
  },
  yes: {
    id: 'yes',
  },
  no: {
    id: 'no',
  },
  eventOpenB2b: {
    id: 'event.open.b2b',
  },
});
