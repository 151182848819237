import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { object, string, oneOf, func, bool, instanceOf, array } from 'prop-types';
import { isEmpty } from 'lodash';

import { Languages, Custom, Experience, Availability, Licence } from './components';

const FILTERS_KEYS = {
  CUSTOM: ['CONTRACT', 'LEVEL', 'JOBS', 'DURATION', 'MOBILITY', 'POSITION', 'FUNCTION', 'TARGETS', 'TECHNOS']
};

const MatchingFilterFormItem = (props) => {
  const [selectedValues, setSelectedValues] = useState(props.initialValue);
  const [license, setLicense] = useState(props.matchingData && props.matchingData.license ? props.matchingData.license : false);
  const [languageItems, setLanguageItems] = useState(props.matchingData && (props.context === 'offer' || props.context === 'onboarding') && props.matchingData.languages ?
    props.matchingData.languages : [{ lang: '', level: '' }]);
  const isCustomFilter = props.criterion.key === undefined || FILTERS_KEYS.CUSTOM.indexOf(props.criterion.key) !== -1;

  useEffect(() => {
    if (props.criterion.key === 'LANGUAGES' && props.context === 'onboarding') {
      const defaultLanguages = [{ lang: '', level: '' }];

      if (!isEmpty(languageItems)) {
        return;
      }
      if (!isEmpty(props.matchingData)) {
        setLanguageItems(isEmpty(props.matchingData.languages) ? defaultLanguages : props.matchingData.languages);
      }
      if (props.matchingData === null) {
        setLanguageItems(defaultLanguages);
      }
    }
  }, [props.criterion, props.context, props.matchingData]);

  const handleLanguageChange = (languageItems) => {
    setLanguageItems(languageItems);
    this.handleEditLanguage(languageItems);
  };

  const handleEditLanguage = (languageItems) => {
    props.onChange?.(languageItems);
  };

  const handleEditLicense = (value) => {
    setLicense(value);
    props.onChange?.(value);
  };

  const handleCriterionOnChange = (selectedValues) => {
    setSelectedValues(selectedValues);

    if (typeof props.checkContractActiveOptions === 'function') {
      props.checkContractActiveOptions(selectedValues);
    }
  };

  const renderLanguagesFilter = () => (
      <Languages
        languageItems={languageItems}
        fieldDecoratorKey={props.fieldDecoratorKey}
        getFieldDecorator={props.getFieldDecorator}
        showHelper={props.showHelper}
        context={props.context}
        criterion={props.criterion}
        handleRemoveLanguage={handleLanguageChange}
        handleEditLanguage={handleEditLanguage}
        {...props.form}
      />
    );

  const renderExperienceFilter = () => (
      <Experience
        criterion={props.criterion}
        getFieldDecorator={props.getFieldDecorator}
        showHelper={props.showHelper}
        matchingData={props.matchingData}
        context={props.context}
        fieldDecoratorKey={props.fieldDecoratorKey}
        onChange={props.onChange}
        withInputErrorStyle={props.withInputErrorStyle}
        {...props.form}
      />
    );

  const renderLicenceFilter = () => (
      <Licence
        criterion={props.criterion}
        getFieldDecorator={props.getFieldDecorator}
        matchingData={props.matchingData}
        context={props.context}
        fieldDecoratorKey={props.fieldDecoratorKey}
        initialValue={license}
        handleLicenseChange={handleEditLicense}
        {...props.form}
        authUser={props.authUser}
      />
    );

  const renderAvailabilityFilter = () => (
      <Availability
        criterion={props.criterion}
        availabilityKey={props.availabilityKey || 'matching-date'}
        endDate={props.endDate}
        context={props.context}
        getFieldDecorator={props.getFieldDecorator}
        matchingData={props.matchingData}
        showHelper={props.showHelper}
        withInputErrorStyle={props.withInputErrorStyle}
        {...props.form}
        authUser={props.authUser}
        onChange={props.onChange}
      />
    );

  const renderCustomFilter = () => (
      <Custom
        state={{ selectedValues }}
        props={props}
        handleCriterionOnChange={handleCriterionOnChange}
        authUser={props.authUser}
      />
    );

  const renderDefaultFilter = () => {
    switch (props.criterion.key) {
      case 'LANGUAGES':
        return renderLanguagesFilter();

      case 'EXPERIENCE':
        return renderExperienceFilter();

      case 'LICENSE':
        return renderLicenceFilter();

      case 'AVAILABILITY':
        return renderAvailabilityFilter();

      case 'RQTH':
      case 'SALARY':
      case 'REMOTE':
      case 'DRIVING_LICENSE':
      case 'STATUS':
      case 'ACQUISITION':
      case 'ENGLISH':
        return renderCustomFilter();

      default:
        return '';
    }
  }

    return isCustomFilter ? renderCustomFilter() : renderDefaultFilter();
}

MatchingFilterFormItem.propTypes = {
    criterion: object,
    matchingData: object,
    getFieldDecorator: func,
    fieldDecoratorKey: string,
    availabilityKey: string,
    context: oneOf(['offer', 'onboarding']),
    showHelper: func,
    onChange: func,
    form: object,
    endDate: instanceOf(Date),
    withInputErrorStyle: bool,
    checkContractActiveOptions: func,
    initialValue: array,
    authUser: object,
}

export default injectIntl(MatchingFilterFormItem);
