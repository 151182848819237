import React, { useEffect } from 'react';
import { compose } from 'redux';
import {  useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toJS } from '@/utils';
import { withLayout } from '@/containers/Layout';
import Wrapper from '@/components/Wrapper';
import { getAuthUser } from '@/store/auth/selectors';
import ResetPasswordModal from './components/ResetPasswordModal';

const ResetPasswordScene = () => {
  const authUser = useSelector(getAuthUser)?.toJS();
  const history = useHistory();

  useEffect(() => {
    if (authUser) {
      history.push('/events');
    }
  }, []);


  const handleOnCloseModal = (e) => {
    if (e) e.preventDefault();

    history.push('/');
  };

  return (
    <Wrapper>
      <ResetPasswordModal
        modalVisible
        handleCloseModal={handleOnCloseModal}
      />
    </Wrapper>
  );
}


export default compose(
  withLayout({ size: 'default', withHeader: false, withBlur: true }),
)(toJS(ResetPasswordScene));
