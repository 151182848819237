import React, { useState } from 'react';
import { object, func, any, oneOf } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useFormatMessage } from 'react-intl-hooks';
import { Form, Radio, Modal } from 'antd';
import { Button } from '@seekube-tech/ui-kit';
import { toJS } from '@/utils';

// Store
import { eventActions } from '@/store/event';
import { authSelectors } from '@/store/auth';
import { getAvailableActions } from '@/store/availableActions/selectors';

// Components
import Checkbox, { CheckboxGroup } from '@/components/Checkbox';
import { Input } from '@/components/Input';
import Icon from '@/components/Icon';
import { Properties } from './components';

// Styles & Translations
import styles from './styles.less';

// Antd
const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;

/**
 * CreateEventModal
 */
function CreateEventModal({
  form: { validateFields, getFieldDecorator },
  postEvent,
  duplicateEvent,
  authUser,
  children,
  defaultValues = {},
  mode = 'duplicate',
  availableActions
}) {
  const t = useFormatMessage();
  const [loading, setLoading] = useState(false)
  const [modalIsVisible, setModalIsVisible] = useState(false)


  const toggleModal = () => {
    setModalIsVisible(!modalIsVisible)
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true)

    validateFields((err, values) => {
      if (!err) {
        switch (mode) {
          case 'new': return postEvent({
            event: {
              ...values,
              _organizations: [authUser._currentOrganization._id],
              planners: { _organizations: [authUser._currentOrganization._id], users: [] },
            },
            callback: () => {
              toggleModal();
              setLoading(false)
            },
            notificationParams: {
              success: {
                message: t({ id: 'toasters.addEvent.success' }, { count: 1 })
              },
              error: {
                message: t({ id: 'toasters.addEvent.error' })
              }
            }
          });
          case 'duplicate': return duplicateEvent({
            event: {
              _id: defaultValues._id,
              ...values,
            },
            callback: () => {
              toggleModal();
              setLoading(false)
            },
            notificationParams: {
              success: {
                message: t({ id: 'toasters.duplicateEvent.success' })
              },
              error: {
                message: t({ id: 'toasters.duplicateEvent.error' })
              }
            }
          });
          default: return null;
        }
      } return setLoading(false);
    });
  };

  const renderButton = () => React.cloneElement(children, { onClick: toggleModal });

  const property = Properties({ mode });
  const { name, type, format, targets, openB2b, errorFieldMessage } = Properties({ mode: 'default' });

  return (
    <div className={styles.createEventModal}>
      <Modal
        visible={modalIsVisible}
        onCancel={toggleModal}
        className="appointmentModal"
        wrapClassName={styles.createEventModal}
        footer={false}
      >
        <a role="button" tabIndex={0} className="modal-close" onClick={toggleModal}>
          <Icon name="close" className="modal-close-icon" />
        </a>
        <div className="participantHeader">
          <h3>{property.title.text}</h3>
        </div>
        <div className="formWrapper">
          <Form layout="vertical" onSubmit={handleSubmit}>
            <FormItem label={name.label}>
              {
                getFieldDecorator(name.name, {
                  rules: [{ required: true, message: errorFieldMessage }],
                  initialValue: defaultValues.name,
                })(
                  <Input placeholder={name.html.placeholderNode} />
                )
              }
            </FormItem>
            <FormItem label={type.label}>
              {getFieldDecorator(type.name, {
                rules: [
                  { required: true, message: errorFieldMessage },
                ],
                initialValue: defaultValues.type,
              })(
                <RadioGroup>
                  {type.html.map((radio) => <RadioButton id={`btnType${radio.value}`} key={radio.value} value={radio.value} >{radio.text}</RadioButton>)}
                </RadioGroup>
              )}
            </FormItem>
            <FormItem label={format.label}>
              {getFieldDecorator(format.name, {
                rules: [
                  { required: true, message: errorFieldMessage, type: format.type },
                ],
                initialValue: defaultValues.format,
              })(
                <RadioGroup>
                  {format.html.filter(button => availableActions.updateEventType?.isTypeEnabled(button.value)).map((button) => <RadioButton id={`btnFormat${button.value}`} key={button.value} value={button.value}>{button.text}</RadioButton>)}
                </RadioGroup>
              )}
            </FormItem>
            <FormItem label={openB2b.label}>
              {getFieldDecorator(openB2b.name, {
                rules: [
                  { required: true, message: errorFieldMessage, type: openB2b.type },
                ],
                initialValue: defaultValues.openB2b,
              })(
                <RadioGroup>
                  {openB2b.html.map((button) => <RadioButton id={`btnB2B${button.value}`} key={button.value} value={button.value} >{button.text}</RadioButton>)}
                </RadioGroup>
              )}
            </FormItem>
            <FormItem label={targets.label}>
              {getFieldDecorator(targets.name, {
                rules: [
                  { required: true, message: errorFieldMessage, type: 'array' },
                ],
                initialValue: defaultValues.targets,
              })(
                <CheckboxGroup>
                  {targets.html.map((checkbox) => <Checkbox id={`btnTargets${checkbox.value}`} key={checkbox.value} value={checkbox.value}>{checkbox.text}</Checkbox>)}
                </CheckboxGroup>
              )}
            </FormItem>
            <div>
              <Button
                id="submitCreateEvent"
                type="submit"
                loading={loading}
                imageComponentLeft="🚀"
              >
                {property.cta.text}
              </Button>
            </div>
          </Form>
        </div>
      </Modal>

      {renderButton()}
    </div >
  );
}

CreateEventModal.propTypes = {
  form: object,
  postEvent: func,
  duplicateEvent: func,
  authUser: object,
  children: any.isRequired,
  defaultValues: object,
  mode: oneOf(['duplicate', 'new']),
  availableActions: object
}

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser,
  availableActions: getAvailableActions
});

const mapDispatchToProps = {
  postEvent: eventActions.postEvent,
  duplicateEvent: eventActions.duplicateEvent,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withForm = Form.create();

export default compose(
  withConnect,
  withForm,
)(toJS(CreateEventModal));
