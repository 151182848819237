import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bool, func } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Modal } from 'antd';
import { Button } from '@seekube-tech/ui-kit';
import { userSelectors } from '@/store/user';

// components
import Icon from '@/components/Icon';

import styles from '../../../../styles.less';
import messages from '../../../../messages';


const DeleteMemberModal = ({
  visible,
  handleClose,
  onDeleteUserFromOrganization,
  onDeleteUser,
}) => (
  <Modal
    visible={visible}
    keyboard={false}
    onCancel={() => handleClose(null)}
    footer={null}
    maskClosable
    closable={false}
    width={654}
    className="customConfirm collaboratorModal"
  >
    <a role="button" tabIndex="0" className={styles.modalClose} onClick={(e) => handleClose(e)}>
      <Icon name="close-modal" />
    </a>
    <div className="formWrapper">
      <span className={styles.modalTitles}><FormattedMessage {...messages.deleteMember} /></span>
      <Button style={{ marginBottom: '10px', marginTop: '30px' }} onClick={() => onDeleteUserFromOrganization()} className="w-full" type="submit">
        <FormattedMessage {...messages.detachFromComany} />
      </Button>
      <Button className="w-full" onClick={() => onDeleteUser()}>
        <FormattedMessage {...messages.finalDelete} />
      </Button>
      <hr style={{ marginTop: '26px', marginBottom: '20px' }} color="black" />
      <div className={styles.deleteDescriptionContainer}>
        <span className={styles.deleteDescriptiontext}><FormattedMessage {...messages.descriptionDelete} /></span>
      </div>
    </div>
  </Modal>
);

DeleteMemberModal.propTypes = {
  visible: bool,
  isDeletingUser: bool,
  handleClose: func,
  onDeleteUserFromOrganization: func,
  onDeleteUser: func,
};

const mapStateToProps = createStructuredSelector({
  isDeletingUser: userSelectors.getUsersIsFetching,
});

const withConnect = connect(mapStateToProps, null);

export default compose(
  injectIntl,
  withConnect,
)(DeleteMemberModal);

