import uploadReducer from './reducer';
import * as uploadTypes from './types';
import * as uploadActions from './actions';

export {
  uploadTypes,
  uploadActions,
};

export default uploadReducer;
