import { EVENT_FORMAT } from '@/utils/constants';
import { PHASES, PHASE_STATE } from '../../utils/const';
import {
  getEventPhases,
  isAdmin,
  isEventClosed,
  isInteractionClosed,
  isJobfairPast,
  isRoleCandidate,
  isRoleOwner,
  isRoleRecruiter
} from '../../utils/event';


import {
  isParticipantFormatPhysical,
  isParticipantFormatVirtual,
} from '../../utils/user';

function isDisabled({
  event,
  user,
  participant,
  role,
  appointment,
}) {
  const eventPhases = getEventPhases(event);
  const exponentKeyMomentFormats = appointment.keyMomentFormats || [];

  if (event.isAuthUserBanned) {
    return true;
  }

  if (isRoleOwner(role) || isRoleRecruiter(role) || isAdmin(user)) {
    return true;
  }

  if (isRoleCandidate(role)) {
    if (isEventClosed(eventPhases)) {
      return true;
    }

    if (isParticipantFormatPhysical(participant) && isParticipantFormatVirtual(participant)) {
      if (exponentKeyMomentFormats.length > 1) {
        if (isInteractionClosed(eventPhases, EVENT_FORMAT.PHYSICAL)  && isInteractionClosed(eventPhases, EVENT_FORMAT.VIRTUAL)) {
          return true;
        }

        if (isInteractionClosed(eventPhases, EVENT_FORMAT.PHYSICAL) && (!eventPhases.interactionsClosing.find(e => e.format === EVENT_FORMAT.VIRTUAL) && isJobfairPast(eventPhases, EVENT_FORMAT.VIRTUAL))) {
          return true;
        }

        if (isInteractionClosed(eventPhases, EVENT_FORMAT.VIRTUAL) && (!eventPhases.interactionsClosing.find(e => e.format === EVENT_FORMAT.PHYSICAL) && isJobfairPast(eventPhases, EVENT_FORMAT.PHYSICAL))) {
          return true;
        }

        return false;
      }
      if (exponentKeyMomentFormats[0] === EVENT_FORMAT.PHYSICAL) {
        if (isInteractionClosed(eventPhases, EVENT_FORMAT.PHYSICAL)) {
          return true;
        }

        if (!eventPhases.interactionsClosing.find(e => e.format === EVENT_FORMAT.PHYSICAL) && isJobfairPast(eventPhases, EVENT_FORMAT.PHYSICAL)) {
          return true;
        }
        return false;
      }
      if (exponentKeyMomentFormats[0] === EVENT_FORMAT.VIRTUAL) {
        if (isInteractionClosed(eventPhases, EVENT_FORMAT.VIRTUAL)) {
          return true;
        }

        if (!eventPhases.interactionsClosing.find(e => e.format === EVENT_FORMAT.VIRTUAL) && isJobfairPast(eventPhases, EVENT_FORMAT.VIRTUAL)) {
          return true;
        }
        return false;
      }
      return false;
    }
    if (isParticipantFormatPhysical(participant)) {
      if (isInteractionClosed(eventPhases, EVENT_FORMAT.PHYSICAL)) {
        return true;
      }

      if (!eventPhases.interactionsClosing.find(e => e.format === EVENT_FORMAT.PHYSICAL) && isJobfairPast(eventPhases, EVENT_FORMAT.PHYSICAL)) {
        return true;
      }
      return false
    }
    if (isParticipantFormatVirtual(participant)) {
      if (isInteractionClosed(eventPhases, EVENT_FORMAT.VIRTUAL)) {
        return true;
      }

      if (!eventPhases.interactionsClosing.find(e => e.format === EVENT_FORMAT.VIRTUAL) && isJobfairPast(eventPhases, EVENT_FORMAT.VIRTUAL)) {
        return true;
      }

      return false;
    }
    if (isInteractionClosed(eventPhases)) {
      return true;
    }
  }

  return false;
}

/**
 * Do not forget to specify strict function params (instead of destructuring) to avoid issues when calling
 */
export default {
  isAvailable: [
    [
      {
        phase: PHASES.ALL,
        state: PHASE_STATE.ALL
      },
    ],
  ],
  generateActions: ({ authUser }) => {
    const user = authUser?.toJS();

    return {
      isDisabled: ({ event, role, participant, appointment }) => isDisabled({ event, user, role, participant, appointment }),
    }
  }
}
