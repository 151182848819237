import React from 'react';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Col, Row, Form, Select as SelectAntd } from 'antd';
import { object, func, any, bool } from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { debounce } from 'lodash';

// Store
import { Button, Pagination } from '@seekube-tech/ui-kit';
import { organizationSelectors, organizationActions } from '@/store/organization';
import { toJS } from '@/utils';

// Containers
import { withLayout } from '@/containers/Layout';

// Components
import InputMaterial from '@/components/Input/InputMaterial';
import LoadingIndicator from '@/components/LoadingIndicator';
import AddOrganizationModal from './scenes/Organization/components/addOrganizationModal';
import OrganizationCard from "../../../Event/containers/Landing/components/Contents/components/GeneralInfos/components/LandingContent/components/Organizations/components/OrganizationCard";
// Styles & Translations
import messages from './messages';
import styles from './styles.less';
import Select from '../../../../components/Form/Select';

const { Option } = SelectAntd;
// TODO: Refaire la pagination et le composant
class OrganizationsList extends React.PureComponent { // eslint-disable-line
  static propTypes = {
    intl: object,
    getAllOrganizationsList: func,
    organizationsList: any,
    form: object,
    organizationPagination: object,
    currentOrganization: object,
    isFetching: bool,
  };

  constructor(props) {
    super(props);

    const { props: { getAllOrganizationsList } } = this;

    this.state = {
      modalIsVisible: false,
      search: null,
      userPagination: {
        page: 1,
        pageSize: this.pageSize,
        total: 0,
      },
      onlyClient: false,
    };

    getAllOrganizationsList({
      search: null,
      limit: this.pageSize,
      offset: 0,
      page: 1,
      loader: true,
    });
  }

  onSearchChanged = debounce(() => {
    const { form: { getFieldsValue } } = this.props;

    const val = getFieldsValue(['search']);
    const { onlyClient } = getFieldsValue(['onlyClient']);
    const { search } = val;

    this.setState({ search });

    this.props.getAllOrganizationsList({
      search,
      onlyClient,
      limit: this.pageSize,
      offset: 0,
      page: 1,
      loader: true,
    });
  }, 500);

  onFilterChanged = (onlyClient) => {
    const { search } = this.state;
    this.setState({ onlyClient })

    this.props.getAllOrganizationsList({
      search,
      onlyClient,
      limit: this.pageSize,
      offset: 0,
      page: 1,
      loader: true,
    });
  };

  pageSize = 18;

  handleOnChangePagination = (pagination) => {
    this.setState({ userPagination: pagination });
    this.reloadOrganization(pagination);
  };

  reloadOrganization = (pagination) => {
    const { pageSize, state: { search, userPagination, onlyClient } } = this;

    this.props.getAllOrganizationsList({
      search,
      limit: pageSize,
      offset: ((pagination || userPagination) - 1) * pageSize,
      page: (pagination || userPagination),
      onlyClient,
      loader: true,
    });
  }

  renderOrganizations = () => {
    const { props: { organizationsList, isFetching } } = this;

    if (isFetching) {
      return (<LoadingIndicator />);
    }

    return organizationsList
      .map((organization, index) => (
        <Col xs={12} sm={12} md={6} lg={4} key={index}>
          <Link target="_blank" to={`/admin/organizations/${organization._id}`}>
            <OrganizationCard exponent={organization} withOpportunities={false} withHover={false} withJobdatings />
          </Link>
        </Col>
      ));
  };

  render() {
    const { props: { intl, organizationPagination, currentOrganization, form: { getFieldDecorator } },
      onSearchChanged, renderOrganizations, handleOnChangePagination,
      state: { userPagination, modalIsVisible } } = this;

    return (
      <div className={styles.usersContainer}>
        <div className={styles.topContainer}>
          <h1 className="mainTitle" style={{ marginBottom: 0 }}>
            <FormattedMessage {...messages.organizationsTitle} values={{ count: organizationPagination.total }} />
          </h1>
          <div className={styles.actions}>
            <Button type="primary" onClick={() => this.setState({ modalIsVisible: true })}>
              {intl.formatMessage(messages.createOrganization)}
            </Button>
          </div>
        </div>

        <div className={styles.marginItem}>
          <div className={styles.searchOrganization}>
            {getFieldDecorator('search', {
              initialValue: '',
            })(
              <InputMaterial
                type="string"
                placeholder={intl.formatMessage(messages.searchByKeyword)}
                addOnIcon="search"
                onChange={onSearchChanged}
              />
            )}
            {getFieldDecorator('onlyClient', {
              initialValue: 'false',
            })(
              <Select
                className={styles.selectIsClient}
                label={intl.formatMessage({ id: 'admin.organizations.filter.isClient' })}
                placeholder={intl.formatMessage({ id: 'admin.organizations.filter.isClient' })}
                onChange={this.onFilterChanged}
              >
                <Option value="false">{intl.formatMessage({ id: 'no' })}</Option>
                <Option value="true">{intl.formatMessage({ id: 'yes' })}</Option>
              </Select>
            )}
          </div>
        </div>
        <Row type="flex" justify="space-around" className={styles.organizationCardContainer}>
          {renderOrganizations()}
        </Row>

        {organizationPagination && organizationPagination.total > this.pageSize ? (
          <Pagination
            className="flex justify-center"
            current={userPagination ? parseInt(userPagination.page || userPagination, 10) : 1}
            pageSize={organizationPagination.pageSize}
            total={organizationPagination.total}
            onChange={handleOnChangePagination}
          />
        ) : ''}
        <AddOrganizationModal
          currentOrganization={currentOrganization && currentOrganization._id}
          visible={modalIsVisible}
          handleClose={() => {
            this.setState({ modalIsVisible: false });
          }} />
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  organizationsList: organizationSelectors.getAllOrganizationsList,
  organizationPagination: organizationSelectors.getOrganizationPagination,
  currentOrganization: organizationSelectors.getCurrentOrganization,
  isFetching: organizationSelectors.getOrganizationFetching,
});

const mapDispatchToProps = {
  getAllOrganizationsList: organizationActions.getAllOrganizationsList,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withForm = Form.create();

export default compose(
  withLayout({ size: 'large', withHeader: true, withNav: false }),
  withConnect,
  withForm,
  injectIntl,
)(toJS(OrganizationsList));
