import { TEMPLATE_CRITERIA_ID } from '@/utils/global';
import { CONTRACTS_WITHOUT_DURATION } from '@/utils/constants';

const hasDurationContract = (selectedContracts = []) => {
  const durationContract = selectedContracts
    .filter((choice) => ![...CONTRACTS_WITHOUT_DURATION, TEMPLATE_CRITERIA_ID.cdi].includes(choice));

  return selectedContracts.filter((choice) => durationContract.includes(choice)).length > 0;
}

export default hasDurationContract;
