import React from 'react';

// components
import { func, array, oneOfType, string, object } from 'prop-types';
import { Form } from 'antd';
import { useFormatMessage } from 'react-intl-hooks';

const FormItem = Form.Item;


const PictureUrl = ({ isFieldTouched, getFieldError, getFieldDecorator, initialValue }) => {// eslint-disable-line
  const t = useFormatMessage();

  const error = isFieldTouched('pictureUrl') && getFieldError('pictureUrl');

  return (
    <FormItem
      validateStatus={error ? 'error' : ''}
      help={error || ''}
    >
      {getFieldDecorator('pictureUrl', {
        initialValue,
        rules: [
          { required: true, message: t({ id: 'event.recruiter.preparation.stand.item.pictureUrl.error' }) },
        ],
      })(
        <input type="hidden" />
      )}
    </FormItem>
  );
};

PictureUrl.propTypes = {
  initialValue: oneOfType([array, string]),
  getFieldDecorator: func,
  intl: object,
};

export { PictureUrl };
