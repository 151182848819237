import React from 'react';
import { object, string, number, func, array } from 'prop-types';
import { Button, IconTrash2 } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  FreeSlotsNumber
} from '@/features/agenda/recruiter/components/CalendarFooter/FreeSlotsNumber';
import styles from './styles.less';
import { getCurrentEvent } from '@/store/event/selectors';
import { getLocalStorage, LOCAL_STORAGE } from '@/utils/localStorage';

const Footer = ({
  event,
  exponentFormat,
  calendarSlots,
  indexWeek,
  exponentUser,
  setIsDeleteModeActive,
  settings,
  setModalContext,
}) => {
  const t = useFormatMessage();
  const history = useHistory();
  const currentEvent = useSelector(getCurrentEvent)?.toJS();
  const isEditingCalendar = JSON.parse(getLocalStorage(LOCAL_STORAGE.recruiter.isEditingCalendar));

  return (
    <div className={styles.footer}>
      <div className={styles.left}>
        <FreeSlotsNumber
          indexWeek={indexWeek}
          calendarSlots={calendarSlots}
          event={event}
          exponentFormat={exponentFormat}
          exponentUser={exponentUser}
        />
        {!settings.isVisible &&
          <Button
            onClick={() => setIsDeleteModeActive(true)}
            variant="tonal"
            size="small"
            color="error"
            imageComponentLeft={<IconTrash2 size={16}/>}
          >
            {t({id: 'delete.slots'})}
          </Button>}
      </div>
      {currentEvent &&
        <Button
          onClick={() => {
            if (!isEditingCalendar) return history.goBack();

            return setModalContext({
              isVisible: true,
              context: 'quitWithoutSavingDelete',
              onSubmit: () => history.goBack(),
            })
          }}
          color="neutral">
          {t({ id: 'back.to.my.jobdating' })}
        </Button>}
    </div>
  )
}

Footer.propTypes = {
  event: object,
  settings: object,
  exponentFormat: string,
  calendarSlots: array,
  indexWeek: number,
  exponentUser: object,
  setIsDeleteModeActive: func,
  setModalContext: func,
}

export { Footer }
