import React from 'react';
import { oneOfType, func, object, string, any } from 'prop-types';
import { FormattedHTMLMessage } from 'react-intl';
import { isEmpty, includes } from 'lodash';
import classnames from 'classnames';
import moment from 'moment';
import { APPOINTMENT_ORIGIN } from '@/utils/constants';

import { useFormatMessage } from 'react-intl-hooks'

import Icon from '@/components/Icon';
import Avatar from '@/components/Avatar';
import ShareVisioLink from '@/components/ShareVisioLink';
import { getApiUrl } from '@/utils/url';
import { InteractionTag } from '../InteractionTag';

// Styles & Translations
import styles from './styles.less';
import messages from '../../messages';

const Appointment = ({ appointment, context, className }) => {
  const t = useFormatMessage();
  const appointmentToDisplay = appointment;

  if (isEmpty(appointmentToDisplay) || isEmpty(appointmentToDisplay._timeslot)) return null;


  const slotDate = appointmentToDisplay._timeslot ? appointmentToDisplay._timeslot.beginAt : appointment.date;
  const appointmentDate = includes(['accepted', 'unconfirmed'], appointmentToDisplay.status) && slotDate ?
    moment(slotDate) : null;
  const appointmentStatus = appointmentToDisplay.status;

  return (
    <div className={classnames(styles.appointment, styles[appointmentStatus], className)}>
      <div className={styles.appointmentHeader}>
        <h5>
          {
            APPOINTMENT_ORIGIN.informal1to1 === appointment.origin ?
              t({ id: 'chat.oneToOne.pl' }, { count: 1 })
              : t(messages.appointmentSectionTitle)
          }
        </h5>
      </div>
      <div className={styles.tag}>
        {appointmentStatus && includes(['interview', 'save', 'top', 'confirmed', 'unconfirmed', 'refused', 'pending'], context) ? (
          <InteractionTag appointment={appointmentToDisplay} />
        ) : ''}
      </div>
      <div className="details">
        { /* Appointment date */ }
        {(appointment.status === 'accepted' || appointment.status === 'unconfirmed') && appointmentDate ? (
          <div className={styles.appointmentDate}>
            <div className={styles.iconContainer}><Icon name="calendar" /></div>
            <div className={styles.appointmentContent}>
              <FormattedHTMLMessage
                {...messages.appointmentDateFormatted}
                values={{
                  day: appointmentDate.format('DD'),
                  months: appointmentDate.format('MMMM'),
                  beginHour: appointmentDate.format('HH[h]mm'),
                  endHour: appointmentDate.add(appointmentToDisplay._timeslot ? appointmentToDisplay._timeslot.duration : appointmentToDisplay.settings.duration || 15, 'minutes').format('HH[h]mm'),
                }}
              />
            </div>
          </div>
        ) : ''}
        { /* Appointment medium */ }
        {appointment.settings.medium !== 'physical' ? (
          <div className={styles.appointmentMedium}>
            <div className={styles.iconContainer}><Icon name={appointmentToDisplay.settings.medium === 'phone' ? 'phone' : 'camera'} /></div>
            <div className={styles.appointmentContent}>
              {appointmentToDisplay.settings.medium === 'phone' ? (
                <span>{t(messages.appointmentMediumPhone)}</span>
              ) : (
                <a href={getApiUrl(appointmentToDisplay.recruiterVisioUrl)} target="_blank">
                  {t(messages.appointmentMediumSeeklive)}
                </a>
              )}
            </div>
          </div>
        ) : (
          <div className={styles.appointmentAddress}>
            <div className={styles.iconContainer}><Icon name="location" /></div>
            <div className={styles.appointmentContent}>
              <span>{appointmentToDisplay.settings.location}</span>
            </div>
          </div>
        )}
        {appointmentToDisplay.settings.medium === 'visio' ? (
          <div className={styles.appointmentMedium}>
            <div className={styles.iconContainer}><Icon name="share" /></div>
            <div className={styles.appointmentContent}>
              <ShareVisioLink
                url={getApiUrl(appointment.shareVisioUrl)}
              >
                {t(messages.shareVisio)}
              </ShareVisioLink>
            </div>
          </div>
        ) : null}

        <div className={styles.appointmentOwner}>
          <Avatar
            style={{ marginRight: '11px' }}
            src={appointmentToDisplay._organizationUser.pictureUrl}
            user={appointmentToDisplay._organizationUser}
            size={24}
          />
          <div className={styles.appointmentContent}>
            <FormattedHTMLMessage
              {...messages.appointmentOwner}
              values={{ user: appointmentToDisplay._organizationUser.shortName }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

Appointment.propTypes = {
  context: string,
  className: any,
  appointment: object,
  actions: oneOfType([object, func]),
};

export default Appointment;
