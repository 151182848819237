import React from 'react';
import { H3 } from '@seekube-tech/ui-kit';
import { bool, object } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import BlankState from '@/components/BlankState';
import Wrapper from '@/components/Wrapper';
import LoadingIndicator from '@/components/LoadingIndicator';

export const BlankUserDoesNotParticipateInEvent = ({
  isLoading,
  user,
  isExponentUser,
}) => {
  const t = useFormatMessage();

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (!isExponentUser) return null;

  return (
    <Wrapper>
      <BlankState
        largeIcon
        style={{ marginTop: '80px' }}
        icon="blank-state-cactus"
      >
        <H3>
          {t({ id: 'calendar.slot.blankExponentUser' }, { recruiter: user?.fullName })}
        </H3>
      </BlankState>
    </Wrapper>
  )
}

BlankUserDoesNotParticipateInEvent.propTypes = {
  isExponentUser: bool,
  isLoading: bool,
  user: object
}
