import * as React from 'react';
import PropTypes from 'prop-types';
import { useDrag, useDrop } from 'react-dnd';
import DraggableItem from '@/components/DraggableItem';
import { useState } from 'react';

function BodyRow({ className, style, index, findInitialRow, isDragging, startDragging, stopDragging, hoverRow, item, ...restProps }) {
  const [isOver, setIsOver] = useState(false);
  const [draggingIndex, setDraggingIndex] = useState(false);
  const localStyle = { style, cursor: 'move' };

  let isHoveringClass = '';

  if (isOver) {
    if (restProps.index > draggingIndex) {
      isHoveringClass += ' drop-over-downward';
    }
    if (restProps.index < draggingIndex) {
      isHoveringClass += ' drop-over-upward';
    }
  }

  const isDraggingClass = isDragging ? ' dragging' : '';

  const onDragStart = (dragIndex) => {
    setDraggingIndex(dragIndex);
    startDragging();
  }

  return <DraggableItem
    index={index}
    moveItem={hoverRow}
    itemType="table"
    onDragEnd={stopDragging}
    onDragStart={onDragStart}
    onOver={setIsOver}
  >
    <tr {...restProps} className={className + isDraggingClass + isHoveringClass} style={localStyle} />
  </DraggableItem>
}

BodyRow.propTypes = {
  style: PropTypes.string,
  className: PropTypes.string,
  startDragging: PropTypes.func,
  stopDragging: PropTypes.func,
  findInitialRow: PropTypes.func,
  hoverRow: PropTypes.func,
  index: PropTypes.number,
  item: PropTypes.object
}

export default BodyRow;
