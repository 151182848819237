import React, { useState } from 'react';
import { object, number, func } from 'prop-types';
import { Input } from 'antd';
import Picker from 'emoji-picker-react';
import { Button, Toggle } from '@seekube-tech/ui-kit';
import { If } from '@/components/If';
import { useFormatMessage } from 'react-intl-hooks';
import { getLocale } from '@/utils/localStorage';

const Organizations = ({
  event, // eslint-disable-line
  contentIndex, // eslint-disable-line
  onChange, // eslint-disable-line
  onDelete,
}) => {
  const t = useFormatMessage();
  const locale = getLocale();
  const [cta, setCta] = useState(event._contents[contentIndex][locale].cta || t({ id: 'event.landing.opportunities' }));
  const [emoji, setEmoji] = useState(event._contents[contentIndex][locale].emoji || '😎');
  const [emojiPicker, setEmojiPicker] = useState(false);

  return (
    <div className="menuItemContent">
      {/* Display Label */}
      <div>
        <label htmlFor="mainColor">Afficher label dans le menu :</label>
        <Toggle
          size="small"
          checked={event._contents[contentIndex].inNav}
          onChange={(checked) => onChange(['_contents', contentIndex, 'inNav'], checked)}
        />
      </div>

      {/* CTA */}
      {event.modules.offer.enable && <div>
        <label htmlFor="actions">Emoji & texte du bouton</label>
        <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
          <Button
            className="w-full"
            variant="tonal"
            style={{ flex: 1, marginRight: '10px' }}
            onClick={() => setEmojiPicker(!emojiPicker)}
          >{emoji}</Button>

          <Input
            style={{ flex: 5, height: '40px' }}
            value={cta}
            onBlur={() => onChange(['_contents', contentIndex, locale, 'cta'], cta)}
            onChange={(e) => setCta(e.currentTarget.value)}
          />
        </div>
        <If condition={emojiPicker}>
          <Picker
            disableSkinTonePicker
            onEmojiClick={(e, emo) => {
              const { emoji } = emo;

              setEmoji(emoji);
              onChange(['_contents', contentIndex, locale, 'emoji'], emoji);
            }}
          />
        </If>
      </div>}

      {/* Button for delete the bloc */}
      <div>
        <label htmlFor="actions">Actions :</label>
        <Button color="error" onClick={() => onDelete(contentIndex)}>Supprimer le bloc</Button>
      </div>
    </div>
  );
};

Organizations.propTypes = {
  event: object,
  contentIndex: number,
  onChange: func,
  onDelete: func,
};

export default Organizations;
