import { useQuery } from 'react-query';

import request from '@/utils/request';
import { objectToParams } from '@/utils/url';

export function useGetInteractions({ eventSlug, body, ...props }) {

  const params = objectToParams({ ...body });
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventSlug}/interactions?${params}`;

  const queryFn = () => request(requestUrl, { method: 'GET' });

  const query = useQuery({
    queryKey: ['interactions', eventSlug],
    queryFn,
    ...props,
  });

  return query;
}
