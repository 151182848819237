import React from 'react';
import { object, array } from 'prop-types';

import { useFormatMessage } from 'react-intl-hooks';
import { pick, keys, map, omit } from 'lodash';
import { percentage } from '@/utils/math';

import { Stats, WithPercentage } from '@/components/Stats';


const ExponentsStats = ({ stats, omitStats = [] }) => {
  const t = useFormatMessage();

  const pageStats = {
    countExponents: {
      title: t({ id: 'stats.exponents' }),
      icon: 'building',
      count: 0,
    },
    countExponentsReady: {
      title: t({ id: 'stats.exponentsReady' }),
      icon: 'like',
      count: 0,
      additionalText: <WithPercentage value={percentage(stats?.countExponentsReady || 0, stats?.countExponents || 1)} warningFrom={80} />,
    },
    countRecruiters: {
      title: t({ id: 'stats.recruiters' }),
      icon: 'user',
      count: 0,
    },
    countRecruitersWithOffer: {
      title: t({ id: 'stats.recruitersWithOffer' }),
      noSeparator: true,
      count: 0,
      additionalText: <WithPercentage value={percentage(stats?.countRecruitersWithOffer || 0, stats?.countRecruiters || 1)} />,
    },
    countRecruitersWithAvailableSlot: {
      title: t({ id: 'stats.recruitersWithSlot' }),
      noSeparator: true,
      count: 0,
      additionalText: <WithPercentage value={percentage(stats?.countRecruitersWithAvailableSlot || 0, stats?.countRecruiters || 1)} />,
    },
    countOffers: {
      title: t({ id: 'stats.offers' }),
      icon: 'star',
      count: 0,
    },
    countConferences: {
      title: t({ id: 'stats.conferences' }),
      icon: 'playVideo2',
      count: 0,
    },
  }

  const statsList = keys(omit(pageStats, omitStats));
  const statsFound = keys(pick(stats, statsList));

  const result = map(statsFound, (key) => ({ ...pageStats[key], count: stats[key] }));

  return <Stats list={result} />;
};

ExponentsStats.propTypes = {
  stats: object,
  omitStats: array,
};

export { ExponentsStats };
