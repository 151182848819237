import React from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classnames from 'classnames';

import Modal from '@/components/ModalV2';
import Icon from '@/components/Icon';
import { Button } from '@seekube-tech/ui-kit';

import styles from './styles.less';

const CancelModal = ({ visible, intl, count, event, onCancel, onOk }) => (
  visible ? <Modal
    visible={visible}
    footer={false}
    maskClosable
    width={720}
    className={classnames('customConfirm', styles.bodyModal)}
    template="default"
    onCancel={onCancel}
  >
    <a role="button" tabIndex={0} className="modal-close" onClick={onCancel}>
      <Icon name="close" className="modal-close-icon" />
    </a>
    <div className={styles.modalHeader}>
      <h4>{intl.formatMessage({ id: 'recruiter.offers.spread.modal.cancel.title' })}</h4>
    </div>
    <div className={styles.modalContent}>
      <p>{intl.formatMessage({ id: 'recruiter.offers.spread.modal.cancel.content1' }, { count, event })}</p>
    </div>
    <div className="confirm-actions" style={{ justifyContent: 'flex-start' }}>
      <Button className="mr-6" onClick={onOk}>{intl.formatMessage({ id: 'yes' })}</Button>
      <Button variant="tonal" onClick={onCancel}>{intl.formatMessage({ id: 'recruiter.offers.spread.publish.offers.modal.continue' })}</Button>
    </div>
  </Modal> : null
);

CancelModal.propTypes = {
  visible: bool,
  intl: object,
  count: number,
  event: string,
  onCancel: func,
  onOk: func,
};

export { CancelModal };
