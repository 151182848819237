import { Bloc, BlocHeader } from '@/components/Bloc';
import { H4, Toggle, Link2, Subtitle2 } from '@seekube-tech/ui-kit';
import colors from '@seekube-tech/ui-kit/dist/colors';
import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { eventActions } from '@/store/event';

import { useDispatch } from 'react-redux';
import styles from '@/scenes/Event/scenes/Owner/scenes/Setting/styles.less';

const Informal1to1 = ({ event }) => {
  const t = useFormatMessage();
  const dispatch = useDispatch();

  return <div className={styles.formWrapper}>
    <Bloc>
      <BlocHeader className="mb-24">
        <H4>
          {t({ id: 'event.owner.setting.limits.candidates.informal1to1.title' })}
        </H4>
        <Toggle checked={event?.limits?.informal1to1To1RecruiterFromSameOrga} onChange={(informal1to1To1RecruiterFromSameOrga) => {
          dispatch(eventActions.patchEvent({
            event: { limits: { ...event.limits, informal1to1To1RecruiterFromSameOrga  } },
            notificationParams: {
              success: {
                message: t({ id: 'notifications.update.success' }),
                kind: 'success',
              },
            }
          })); }} />
      </BlocHeader>

      <div className="flex items-center">
        <Subtitle2 color={colors.grey['500']}>
          {t({ id: 'event.owner.setting.limits.candidates.informal1to1.description' }, {
            clickHere:
              <Link2 className="lowercase" target="_blank" href="https://www.notion.so/seekube/Gestion-des-limitations-d-un-event-d0803947f2a94e7dba4f5f79248edead">
                {t({ id: 'click.here' })}
              </Link2>
          })}
        </Subtitle2>
      </div>
    </Bloc>
  </div>
}

export { Informal1to1 }
