import React from 'react';
import { object, func, bool } from 'prop-types';

import { isObject, uniqBy } from 'lodash';

import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Menu } from 'antd';
import { track } from '@/utils/analytics/helpers';
import { ANALYTICS_RECRUITER, LINKS } from '@/utils/constants';
import Avatar from '@/components/Avatar';

import Icon from '@/components/Icon';
import messages from '../../messages';
import styles from './styles.less';

const { Item, SubMenu } = Menu;

const RecruiterDropdown = ({ authUser, exponent, intl, push, switchOrganization, event, onEvent }) => {
  const handleSwitchOrganization = (organization) => {
    if (authUser._currentOrganization._id !== organization._id) {
      track({
        name: ANALYTICS_RECRUITER.SWITCH_ACCOUNT,
        user: authUser,
        properties: {
          'Current Organization': authUser._currentOrganization.name,
          'New Organization': organization.name,
        },
      });

      switchOrganization({
        organizationId: organization._id,
        notificationParams: {
          success: {
            message: `🙌 ${intl.formatMessage(messages.switchOrganizationSuccess, { organization: organization.name })}`,
            kind: 'info',
            style: {
              bottom: '5%',
              top: 'inherit',
            },
          },
          error: true,
        },
        callback: () => {
          push('/events');
          window.location.reload();
        },
      });
    }
  };

  const getExponentRole = () => {
    if (authUser.isAdmin) return false;

    const currentExponent
      = isObject(exponent)
      && exponent.users
      && exponent.users.find((user) => user._user && user._user._id === authUser._id);

    return currentExponent ? currentExponent.role : false;
  };


  const isManager = getExponentRole() === 'manager';
  const { isAdmin } = authUser;
  const recruiterSubMenuTitle = (
    <>
      <Icon name="building" />
      <span><FormattedMessage {...messages.recruiterTitle} /></span>
    </>
  );

  const orgs = uniqBy([authUser._currentOrganization, ...authUser._organizations], (e) => e ? e._id : '').sort((a, b) => a?.name?.localeCompare(b.name));

  return (
    <Menu className={styles.dropdownMenu}>
      {event && onEvent ? (
        <Item className={styles.boardContainer}>
          <div className={styles.eventName}>
            <h3>{event.name}</h3>
          </div>
          <div>
            <Avatar style={{ float: 'right' }} imageFit="cover" size={40} type="rounded" src={event.theme ? event.theme.pictureUrl : null} />
          </div>
        </Item>) : ''}

      {event && onEvent ? (
        <SubMenu title={recruiterSubMenuTitle} className="dropdownUserSubmenu">
          {isManager || isAdmin ?
            <Item className={styles.noBorder}>
              <NavLink to={`/${event.slug}/recruiter/preparation/team`}><Icon name="users" /> <FormattedMessage {...messages.team} /></NavLink>
            </Item>
            : null
          }
          <Item className={styles.noBorder}>
            <NavLink to={`/${event.slug}/recruiter/preparation/offers`}><Icon name="offers" /> <FormattedMessage {...messages.offers} /></NavLink>
          </Item>
          <Item className={styles.noBorder}>
            <NavLink to={`/${event.slug}/recruiter/agenda`}><Icon name="calendar" /> <FormattedMessage {...messages.timeslots} /></NavLink>
          </Item>
          {isManager || isAdmin ?
            <Item className={styles.noBorder}>
              <NavLink to={`/${event.slug}/recruiter/preparation/stand`}><Icon name="building" /> <FormattedMessage {...messages.stand} /></NavLink>
            </Item>
            : null
          }
        </SubMenu>) : ''}

      {event && onEvent ? (
        <Item className={styles.withBorder}>
          <a role="button" tabIndex={0} onClick={() => push('/events')}>
            <Icon name="house" />
            <FormattedMessage {...messages.changeEvent} />
          </a>
        </Item>) :
        (<Item className={styles.noBorder}>
          <a role="button" tabIndex={0} onClick={() => push('/events')}>
            <Icon name="house" />
            <FormattedMessage {...messages.events} />
          </a>
        </Item>)}

      <Item className={styles.noBorder}>
        <NavLink to="?settings" tabIndex={0}>
          <Icon name="settings" />
          <FormattedMessage {...messages.settings} />
        </NavLink>
      </Item>
      <Item className={styles.noBorder}>
        <a role="button" target="_blank" href={LINKS.intercom_help_recruiter[authUser.locale]} tabIndex={0}>
          <Icon name="info-circle" />
          <FormattedMessage {...messages.help} />
        </a>
      </Item>
      <Item className={styles.separator}>
        <NavLink to="/auth/logout" activeClassName="activeNavLink">
          <Icon name="exit" />
          <FormattedMessage {...messages.logout} />
        </NavLink>
      </Item>

      { orgs.length > 0 ? (
        <Item className={[styles.switch, styles.separator]}>
          {orgs.map((o, index) => (
            <a role="button" tabIndex={0} key={index} onClick={() => handleSwitchOrganization(o)}>
              <Avatar className={styles.avatarDropdown} user={authUser} src={o.profile ? o.profile.pictureUrl : ''} size={35} type="rounded" />
              <strong>{o.name}</strong>
              {orgs.length > 1 && o._id === authUser._currentOrganization._id ? (<small> - {intl.formatMessage(messages.current)}</small>) : ''}
            </a>
          ))}
        </Item>
      ) : ''}
    </Menu>
  );
};

RecruiterDropdown.propTypes = {
  authUser: object,
  intl: object,
  exponent: object,
  push: func,
  switchOrganization: func,
  event: object,
  onEvent: bool,
};

export { RecruiterDropdown };
