import React from 'react';
import { string, object } from 'prop-types';
import { Video } from 'react-feather'
import { useFormatMessage } from 'react-intl-hooks';
import { toJS } from '@/utils';
import { useGetConferences } from '@/queries/conferences/useGetConferences';
import styles from './styles.less'
import LiveCardStat from './LiveCardStat';
import LiveBlankStateCard from './LiveBlankStateCard';

const LivesOfTheDay = ({ event, view }) => {
  const t = useFormatMessage();
  const { data: conferencesStats } = useGetConferences({
    eventId: event._id, today: true, eventFormat: view })

  return (
    <div className={styles.livesOfTheDayContainer}>
      <div className={styles.header}>
        <div className={styles.leftContent}>
          <p className={styles.title}>{t({ id: `dashboard.realtime.liveOfTheDay.title`})}</p>
          <p className={styles.subtitle}>{t({ id: `dashboard.realtime.liveOfTheDay.subtitle`})} <span>{conferencesStats?.countAll}</span></p>
        </div>
        <div className={styles.numberOfLive}>
          <Video size={16} />
          <p>{conferencesStats?.total}</p>
          </div>
      </div>
      <div className={styles.listContainer} id="listLivesCardsContainer">
        {conferencesStats?.total === 0 && <LiveBlankStateCard event={event} /> }
        {conferencesStats?.docs?.map((live, key) => (
            <LiveCardStat live={live} focusOn={conferencesStats?.total - conferencesStats?.countUpcoming - conferencesStats?.countOngoing === key} event={event} key={key}/>
          ))}
      </div>
    </div>
  )
}

LivesOfTheDay.propTypes = {
  event: object,
  view: string,
};

export default toJS(LivesOfTheDay);
