import React from 'react';
import omit from 'lodash/omit';
import { oneOf, string, bool, object } from 'prop-types';
import classnames from 'classnames';

// Components
import { Button as ButtonAntd } from 'antd';

// Styles & Translations
import styles from './styles.less';

/**
 * Button
 */
const Button = (props) => {
  const buttonClass = classnames(
    styles[`${props.type}Btn`] || styles.default,
    styles[props.shape],
    props.outline ? styles[`${props.type}Outline`] : null,
    props.full ? styles.full : null,
    props.className,
    styles[props.size]
  );

  // Options for add custom color
  const style = props.style || {};

  if (props.color) {
    style.color = 'white';
    style.backgroundColor = props.color;
    style.borderColor = props.color;
  }

  // Render
  return (
    <ButtonAntd
      className={buttonClass}
      style={style}
      {...omit(props, ['className', 'shape', 'outline', 'type', 'style'])}
    />
  );
};

Button.propTypes = {
  type: oneOf(['default', 'primary', 'secondary', 'black', 'success', 'warning', 'danger', 'dropdown', 'white']),
  shape: oneOf(['hard', 'rounded', 'circle']),
  size: oneOf(['small', 'medium', 'large']),
  className: string,
  style: object,
  color: string,
  outline: bool,
  full: bool,
};

Button.defaultProps = {
  type: 'default',
};

export const ButtonGroup = ButtonAntd.Group;
export default React.memo(Button);
