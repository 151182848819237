import { isEmpty } from 'lodash';

export default (schools, checkedSchools, { handleEditName, handleMergeClick, onToggleSchool, handleReferentClick }) => (
  schools.map(
    (school, key) => ({
      key,
      school,
      checkedSchools,
      isChecked: !isEmpty(checkedSchools.find((checkedSchool) => checkedSchool._id.toString() === school._id.toString())),
      handleEditName,
      onToggleSchool,
      handleMergeClick,
      handleReferentClick,
    })
  ));
