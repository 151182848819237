import { defineMessages } from 'react-intl';

export default defineMessages({
  size0: {
    id: 'organization.size[0]',
  },
  size1: {
    id: 'organization.size[1]',
  },
  size2: {
    id: 'organization.size[2]',
  },
  size3: {
    id: 'organization.size[3]',
  },
  size4: {
    id: 'organization.size[4]',
  },
  recruitersTitle: {
    id: 'candidate.jobdating.exponent.recruitersTitle',
  },
  pictures: {
    id: 'candidate.jobdating.exponent.pictures',
  },
  videos: {
    id: 'candidate.jobdating.exponent.videos',
  },
  extra: {
    id: 'candidate.jobdating.exponent.extra',
  },
  showWebsite: {
    id: 'candidate.jobdating.exponent.showWebsite',
  },
  showWebsiteCareer: {
    id: 'candidate.jobdating.exponent.showWebsiteCareer',
  },
  about: {
    id: 'about',
  },
  employees: {
    id: 'employees',
  },
  noOffer: {
    id: 'event.recruiter.preparation.stand.preview.noOffer',
  },
  btnCompanies: {
    id: 'event.recruiter.preparation.stand.preview.btn.companies',
  },
  btnOffers: {
    id: 'event.recruiter.preparation.stand.preview.btn.offers',
  },
  btnPictures: {
    id: 'event.recruiter.preparation.stand.preview.btn.pictures',
  },
  noOffers: {
    id: 'event.recruiter.preparation.stand.preview.noOffers',
  },
  blankTitle: {
    id: 'event.recruiter.preparation.stand.preview.blankTitle',
  },
  blankContent: {
    id: 'event.recruiter.preparation.stand.preview.blankContent',
  },
});
