import React from 'react';
import { injectIntl } from 'react-intl';
import { useFormatMessage } from 'react-intl-hooks';
import { object, func } from 'prop-types';
import { isEmpty } from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { toJS } from '@/utils';

// Store
import { eventActions, eventSelectors } from '@/store/event';

// Components
import Form from './components/Form/index';

// Styles & Translations
import styles from '../../../../styles.less';
import FormToApi from '../../services/formMapping/FormToApi';


function LandingScene({
  event,
  patchEvent,
  intl,
}) {
  const t = useFormatMessage();
  const handleOnSubmit = (err, values, moduleName) => {
    if (!err) {
      let paramsToSubmit = {};

      if (!isEmpty(moduleName)) {
        paramsToSubmit.modules = FormToApi.modules(event.modules, moduleName, values);
      } else {
        paramsToSubmit = values;
      }

      patchEvent({
        event: { ...paramsToSubmit },
        notificationParams: {
          success: {
            message: t({ id: 'notifications.update.success' }),
            kind: 'info',
            style: {
              bottom: '5%',
              top: 'inherit',
            },
          },
        }
      });
    }
  };


  return (
    <div className={styles.formWrapper}>
      <Form
        event={event}
        intl={intl}
        onSubmit={(err, values) => handleOnSubmit(err, values)}
      />
    </div>
  );
}

LandingScene.propTypes = {
  event: object,
  intl: object,
  patchEvent: func,
};

const mapStateToProps = createStructuredSelector({
  event: eventSelectors.getCurrentEvent,
});

const mapDispatchToProps = {
  patchEvent: eventActions.patchEvent,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  injectIntl,
  toJS,
)(LandingScene);
