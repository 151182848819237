import React from 'react';
import { node, string } from 'prop-types';
import classnames from 'classnames';
import styles from './styles.less';

const Bloc = ({ children, className, maxWidth = 600 }) => (
  <div className={classnames({ [styles.bloc]: true }, className)}>
    <div style={{ maxWidth }}>
      {children}
    </div>
  </div>
);

const BlocHeader = ({ children, className = ''}) => (
  <div className={classnames(styles.blocHeader, className)}>
    {children}
  </div>
);

Bloc.propTypes = {
  children: node,
  className: string,
};

BlocHeader.propTypes = {
  children: node,
  className: string,
};

export { Bloc, BlocHeader };
