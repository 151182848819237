import React from 'react';
import { string, func, bool, any, object, oneOf, number } from 'prop-types';
import { isEmpty } from 'lodash';
import classNames from 'classnames';

// Components
import { Select as SelectAntd } from 'antd';
import Icon from '@/components/Icon';

// Styles
import styles from '../styles.less';

const { Option } = SelectAntd;

const getHTMLFirstElementByClassName = (className, fromElement) => {
  const rootElement = fromElement || document;
  const elements = rootElement.getElementsByClassName(className);

  if (!elements || !elements.length) {
    return null;
  }

  return elements[0];
};

/**
 * Input
 *
 * @param {object} props
 */
class Select extends React.PureComponent {
  static propTypes = {
    addOnIcon: any,
    value: any,
    children: any,
    variant: oneOf(['transparent']),
    className: any,
    helpOption: any,
    defaultValue: any,
    onSearch: func,
    getPopupContainer: func,
    mode: string,
    noFloatingLabel: bool,
    rounded: bool,
    placeholderIcon: string,
    placeholder: any,
    onBlur: func,
    suffixIconStyle: object,
    withInputErrorStyle: bool,
    hasError: bool,
    required: bool,
    size: oneOf(['default', 'small', 'medium', 'large']),
    showHelper: func,
    id: string,
    onFocus: func,
    showDropdown: bool,
    allowClear: bool,
    maxItems: number,
    disabled: bool
  };

  static defaultProps = {
    rounded: false,
    size: null,
    addOnIcon: null,
    placeholderIcon: null,
    required: false,
    withInputErrorStyle: true,
    id: 'select',
    onFocus: () => { },
    showDropdown: true,
    maxItems: null,
    disabled: false
  };

  static getDerivedStateFromProps({ value, defaultValue }, state) {
    const hasValue = typeof value === 'number' || !isEmpty(value) || !isEmpty(defaultValue);

    if (state.hasValue !== hasValue) {
      return { hasValue };
    }

    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      hasValue: !isEmpty(props.value) || !isEmpty(props.defaultValue),
      hasFocus: false,
      hasSearchValue: false,
    };

    this.onBlur = this.onBlur.bind(this);
    this.onSearch = this.onSearch.bind(this);
  }

  onEscKeyPress = (event) => {
    const { maxItems, value } = this.props
    if (maxItems && value.length === maxItems) {
      event.preventDefault()
    }
    if (event.keyCode === 27) {
      this.select.blur();
    }
  };

  onBlur(value) {
    this.setState({ hasValue: !isEmpty(value), hasFocus: false }, () => {
      if (typeof this.props.onBlur === 'function') {
        this.props.onBlur(value);
      }
    });
  }

  onSearch(value) {
    this.setState({ hasSearch: Boolean(value) });

    if (this.props.onSearch) {
      this.props.onSearch(value);
    }
  }

  getHelper = () => {
    const {
      props: { id, showHelper, placeholder, onFocus },
    } = this;

    this.setState({ hasFocus: true });

    if (showHelper) {
      showHelper(id, placeholder);
    }

    onFocus();
  };

  /**
   * Return root of select element (div id="id")
   * @returns {HTMLElement|null}
   */
  getSelectMultipleRoot = () => {
    const { id, mode } = this.props;
    if (mode !== 'multiple') {
      return null;
    }

    return document.getElementById(id);
  };

  /**
   * Get .ant-select-selection__rendered item from selectElement. This element contains the placeholder
   * @param selectElement
   * @returns {null|*}
   */
  getPlaceholderContainer = (selectElement) => {
    if (!selectElement) {
      return null;
    }
    return getHTMLFirstElementByClassName('ant-select-selection__rendered', selectElement);
  };

  /**
   * Get .ant-select-selection__placeholder item from .ant-select-selection__rendered
   * @param placeholderContainer
   * @returns {null|*}
   */
  getPlaceholderFromContainer = (placeholderContainer) => {
    if (!placeholderContainer) {
      return null;
    }
    return getHTMLFirstElementByClassName('ant-select-selection__placeholder', placeholderContainer);
  }

  /**
   * When ant-select-selection__placeholder width is greater than the container .ant-select-selection__rendered, we crop the placeholder to keep it inside the select input
   * The crop hide the mandatory mark so we have to generate a new one outside ant-select-selection__placeholder to display it
   * @param selectElement
   * @param placeholderContainer
   * @returns {null}
   */
  insertMandatoryMarkInAbsoluteAfterPlaceholder = (selectElement, placeholderContainer) => {
    if (placeholderContainer) {
      const placeholderId = `${this.props.id}-placeholder`;
      // span#id has the full text size without the crop
      const placeholderTextElement = document.getElementById(placeholderId);

      if (placeholderTextElement) {
        // Generate mandatory mark, float right and align in the select
        const mandatorySymbolElement = document.createElement('span');
        mandatorySymbolElement.appendChild(document.createTextNode(' * '));
        mandatorySymbolElement.classList.add('mandatory-symbol', 'align-right');

        // ant-select-selection__placeholder is cropped if span#id width > .ant-select-selection__rendered width
        if (placeholderTextElement.offsetWidth > placeholderContainer.offsetWidth) {
          this.alignMandatoryMarkVerticallyOnFocus(placeholderContainer);
          this.resizePlaceholder(selectElement);
          // React rendered multiple times and insert too much mandatory mark. We prevent that by checking we don't have more than 2 mandatory marks
          // The 1st mandatory mark is the one which is cropped, the 2nd is the one we generate
          const existingMandatorySymbolElements = placeholderContainer.getElementsByClassName('mandatory-symbol');
          if (existingMandatorySymbolElements && existingMandatorySymbolElements.length < 2) {
            placeholderContainer.insertBefore(mandatorySymbolElement, placeholderContainer.lastChild);
          }
        }
      }
    }
  };

  /**
   * The generate mandatory mark is in position absolute but on select focus, the placeholder moves vertically
   * So we have to move the mandatory mark to keep it align with the placeholder
   * @param placeholderContainer
   */
  alignMandatoryMarkVerticallyOnFocus = (placeholderContainer) => {
    const { hasFocus, hasValue } = this.state;

    if (placeholderContainer) {
      // Get generated mandatory mark
      const mandatoryMark = getHTMLFirstElementByClassName('mandatory-symbol align-right', placeholderContainer);

      if (mandatoryMark) {
        // On focus or when a value is selected, we have to align correctly the mandatory mark
        if (hasFocus || hasValue) {
          mandatoryMark.classList.add('align-top');
        } else {
          mandatoryMark.classList.remove('align-top');
        }
      }
    }
  };

  /**
   * On focus, placeholder longer than Select input goes out the input
   * We resize the placeholder container to force the crop on the placeholder
   * @param selectElement
   */
  resizePlaceholder = (selectElement) => {
    const { hasFocus, hasValue } = this.state;

    if (selectElement) {
      // select-wrapper has the exact size of Icon + Select input
      const selectWrapper = getHTMLFirstElementByClassName('select-wrapper', selectElement);

      if (selectWrapper) {
        // ant-select-selection--multiple is the element we will resize
        const placeholderWrapper = getHTMLFirstElementByClassName('ant-select-selection--multiple', selectWrapper);

        if (placeholderWrapper) {
          const selectIcon = getHTMLFirstElementByClassName('ant-input-group-addon', selectWrapper);

          if (selectIcon) {
            // We keep the writable part of the Select
            const exactSelectSize = selectWrapper.offsetWidth - selectIcon.offsetWidth;

            // Now we subtract the surplus from the placeholder size on focus
            if (hasFocus || hasValue) {
              placeholderWrapper.style.maxWidth = `${exactSelectSize - 2}px`;
            } else {
              placeholderWrapper.style.maxWidth = '100%';
            }
          }
        }
      }
    }
  };

  render() {
    const {
      props,
      props: { variant, addOnIcon, mode, size, noFloatingLabel, suffixIconStyle, rounded, placeholderIcon, required, withInputErrorStyle, hasError, className, helpOption, showDropdown, allowClear, id },
      state: { hasValue, hasSearchValue, hasFocus },
      onEscKeyPress,
      getHelper,
    } = this;

    let { getPopupContainer } = props;

    const isIE = (Modernizr && Modernizr.flexboxlegacy === false) || navigator.userAgent.indexOf('Edge') > -1; // eslint-disable-line

    if (isIE && typeof getPopupContainer === 'function') {
      getPopupContainer = () => document.getElementById('app');
    }

    const placeholderId = `${id}-placeholder`;
    const placeholder = placeholderIcon !== null ?
      (<span id={placeholderId}><Icon name={placeholderIcon} />{props.placeholder} {required ? (<span className="mandatory-symbol"> * </span>) : ''}</span>) :
      (<span id={placeholderId}>{required ? (<>{props.placeholder} <span className="mandatory-symbol"> * </span></>) : props.placeholder}</span>);

    // Insert custom mandatory mark for cropped Select placeholder and resize placeholder to keep it inside the container
    if (required) {
      const selectRootElement = this.getSelectMultipleRoot();
      const placeholderContainer = this.getPlaceholderContainer(selectRootElement);
      this.insertMandatoryMarkInAbsoluteAfterPlaceholder(selectRootElement, placeholderContainer);
    }

    const selectClasses = classNames(hasValue ? styles.hasValue : styles.noValue,
      hasSearchValue ? styles.hasSearch : '',
      noFloatingLabel ? styles.noFloatingLabel : '',
      styles[mode],
      size === 'medium' ? styles.medium : '',
      rounded ? styles.rounded : '',
      hasFocus ? styles.hasFocus : '',
      className,
      variant && styles[variant],
      'select-wrapper');

    const children = helpOption !== null ? [(
      <Option disabled key="helpOption" className="helpOption">
        {helpOption}
      </Option>
    ), props.children] : props.children;

    if (addOnIcon !== null) {
      return (
        <div className={selectClasses}>
          <div className={styles.addOn}>
            <span className="ant-input-group-wrapper">
              <span
                className={
                  classNames(
                    'ant-input-wrapper',
                    'ant-input-group',
                    hasError && withInputErrorStyle ? styles.inputErrorStyle : null
                  )
                }
              >
                <span className="ant-input-group-addon">
                  {typeof addOnIcon === 'string' ?
                    <Icon name={addOnIcon} className={styles.inputIcon} /> : addOnIcon
                  }
                </span>
                <span className="ant-input-affix-wrapper">
                  <SelectAntd
                    suffixIcon={<Icon name="chevron" style={{ fill: '#33A8EB', ...suffixIconStyle }} />}
                    {...props}
                    size={props.size === 'medium' ? 'default' : props.size}
                    getPopupContainer={getPopupContainer}
                    placeholder={placeholder}
                    onSearch={this.onSearch}
                    onBlur={this.onBlur}
                    ref={(ref) => { this.select = ref }}
                    onInputKeyDown={onEscKeyPress}
                    onFocus={getHelper}
                    dropdownStyle={showDropdown && hasFocus ? {} : { display: 'none' }}
                  >
                    {children}
                  </SelectAntd>
                </span>
              </span>
            </span>
          </div>
        </div>
      );
    }

    return (
      <div className={selectClasses}>
        <SelectAntd
          {...props}
          size={props.size === 'medium' ? 'default' : props.size}
          getPopupContainer={getPopupContainer}
          suffixIcon={<Icon name="chevron" style={{ fill: '#33A8EB', ...suffixIconStyle }} />}
          placeholder={placeholder}
          onSearch={this.onSearch}
          onBlur={this.onBlur}
          onFocus={getHelper}
          dropdownStyle={showDropdown && hasFocus ? {} : { display: 'none' }}
          allowClear={!!allowClear}
        />
      </div>
    );
  }
}

export default Select;
