import React, { useState } from 'react';
import { object, func, bool, array, number } from 'prop-types';
import { isEmpty, get } from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Modal } from 'antd';
import { Alert } from '@seekube-tech/ui';
import { Button } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { toJS } from '@/utils';
import { track } from '@/utils/analytics';
import { ANALYTICS_RECRUITER, TEMPLATES } from '@/utils/constants';
import { authActions } from '@/store/auth';
import { eventSelectors } from '@/store/event';
import { exponentActions, exponentSelectors } from '@/store/exponent';
import { timeslotActions, timeslotSelectors } from '@/store/timeslot';
import Textarea from '@/components/Textarea';
import Icon from '@/components/Icon';
import UserCard from '@/components/UserCard';
import { getAvailableActions } from '@/store/availableActions/selectors';
import { getId } from '@/utils/global';
import { SelectTemplate } from '@/components/SelectTemplate';
import { useGetRecruiterStats } from '@/queries/recruiters/useGetRecruiterStats';
import { getHasRecruiterAvailableSlots } from '@/store/availableActions/utils/user/getHasRecruiterAvailableSlots';
import styles from './styles.less';
import { SearchOffer, ModalHeader, SelectRecruiter } from './components';


const ModalContact = ({
  withRestriction = true,
  countUsers = 0,
  visible,
  properties,
  offers,
  recruiter,
  participant ,
  user ,
  onClose,
  getUserTimeslots,
  event,
  exponent,
  authUser,
  interactions,
  availableActions,
  appointment,
  noAppointmentOffers,
  onOk,
}) => {
  const t = useFormatMessage();
  const [currentUserInExponent, setCurrentUserInExponent] = useState(!properties.special && exponent.users ? exponent.users.find((user) => getId(appointment?._organizationUser ?? recruiter ?? authUser._id) === user._user._id) : null);
  const [templateSelected, setTemplateSelected] = useState(null);
  const [selectedOffer, setSelectedOffer] = useState(get(appointment, '_offers[0]._id') || get(appointment, '_offers[0]') || noAppointmentOffers?.[0]);
  const [message, setMessage] = useState(properties.defaultMessage || '');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const recruiterStatsQuery = useGetRecruiterStats({ userId: getId(currentUserInExponent?._user), eventId: event._id });
  const hasRecruiterAvailableSlots = !properties.special && getHasRecruiterAvailableSlots({ recruiterStatsQuery, availableActions, event, participant, exponent });

  function analyticsUsedDefaultTemplate() {
    if (!templateSelected) {
      return;
    }

    const isTemplateEdited = message !== replaceVariable(templateSelected.fr);
    track({
      name: ANALYTICS_RECRUITER.USED_TEMPLATE,
      user: authUser,
      event: exponent._event,
      properties: {
        templateType: templateSelected._user ? 'other' : 'default',
        modified: isTemplateEdited,
      },
    });
  };

  const handleOnOfferChange = (selectedOffer) => setSelectedOffer(selectedOffer);

  const handleSelectRecruiter = (currentUserInExponent) => {
    setCurrentUserInExponent(currentUserInExponent);

    getUserTimeslots({ currentUser: currentUserInExponent._user, currentEvent: event, context: 'modal' });
  }

  const handleSelectTemplate = (templateSelected) => {
    if (templateSelected) {
      const message = replaceVariable(templateSelected.fr);
      setTemplateSelected(templateSelected);
      setMessage(message);
    }
  };

  function getHasInteractionWithCollaborator () {
    return interactions.findIndex((int) => getId(int._candidate) === getId(user._id) && getId(int._recruiter) !== getId(authUser._id)) > -1;
  }

  const buildComponents = (components) => (
    components.map(renderComponent)
  );

  const selectRecruiter = (component) => {
    const hasInteractionWithCollaborator = getHasInteractionWithCollaborator();

    return (
      <SelectRecruiter
        hasInteractionWithCollaborator={hasInteractionWithCollaborator}
        candidateId={getId(user)}
        participant={participant}
        interactions={interactions}
        exponent={exponent}
        hasRecruiterAvailableSlots={hasRecruiterAvailableSlots}
        onRecruiterSelected={handleSelectRecruiter}
        currentUserInExponent={currentUserInExponent}
        isDisabled={properties.disabledComponents.indexOf(component) !== -1}
        event={event}
      />
    );
  };

  const selectOffers = () => (
      event.withOfferModule && offers?.length > 0 ? (
        <SearchOffer
          offers={offers}
          selectedOffer={selectedOffer}
          event={event}
          currentUserInExponent={currentUserInExponent ?? recruiter}
          onOfferSelected={handleOnOfferChange}
        />
      ) : '');

  function replaceVariable(text) {
    return text.replace('{{first_name}}', user.firstName).replace('{{last_name}}', user.lastName);
  }

  const formatTemplateMessage = (e) => {
    const message = replaceVariable(e.currentTarget.value);

    setMessage(message);
  }

  const selectTemplate = (component) => (
    <SelectTemplate
      onTemplateSelected={handleSelectTemplate}
      isDisabled={properties.disabledComponents.indexOf(component) !== -1}
      type={properties.type}
    />
  );

  const enterMessage = () => (
    <div className="formItem" style={{ marginBottom: '32px' }}>
      <Textarea
        value={message}
        onChange={formatTemplateMessage}
        containerClassName={styles.textareaContainer}
        placeholder={t({ id: 'event.recruiter.appointmentModal.help' })}
        label=""
      />
    </div>
  );

  const signatureRecruiter = () => {
    let recruiterSignature = currentUserInExponent ? currentUserInExponent._user : null;
    recruiterSignature = properties.type === TEMPLATES.EDIT_OWNER ? authUser : recruiterSignature;

    return (
      <div className={styles.recruiterSignature}>
        <UserCard
          user={recruiterSignature}
          className={styles.recruiterSignature}
          userSize={56}
        />
      </div>
    );
  };

  const alert = () => {
    const { alert: { text } } = properties;

    return <Alert className={styles.alert} severity="info">{t(text)}</Alert>;
  };

  const handleSubmitAppointment = () => {
    if (properties.withLoading) { setIsSubmitting(true) }

    if (properties.special) {
      onOk({
        message: message ? message.replace(user.firstName, '{{first_name}}') : '',
        countUsers,
      });
    } else {
      onOk({
        message,
        medium: null,
        user: currentUserInExponent,
        offer: selectedOffer,
      });

      analyticsUsedDefaultTemplate();
    }
  };

  function handleClose () {
    onClose();
  }

  function shouldRender () {
    return !((withRestriction && isEmpty(event)) || !user);
  }

  const renderComponent = (component) => {
    if (typeof component === 'object') {
      return <p style={{ fontSize: '16px', marginBottom: '50px' }}>{t(component.subtitle)}</p>;
    }

    switch (component) {
      case 'recruiter': return selectRecruiter(component);
      case 'offer': return selectOffers(component);
      case 'template': return selectTemplate(component);
      case 'message': return enterMessage(component);
      case 'signature': return signatureRecruiter(component);
      case 'alert': return alert(component);
      default: return '';
    }
  };


  if (!shouldRender()) {
    return null;
  }

  return (
    <Modal
      visible={visible}
      footer={false}
      className="appointmentModal"
    >
      <a role="button" tabIndex={0} className="modal-close" onClick={handleClose}>
        <Icon name="close" className="modal-close-icon" />
      </a>
      <div className={styles.recruiterAppointmentModal}>
        <ModalHeader user={user} count={countUsers - 1} title={countUsers > 1 ? properties.titleMultiple : properties.title} />
        <div className="formWrapper recruiterAppointmentFormWrapper">
          {buildComponents(properties.components)}
          <div className={styles.footer}>
            <Button
              onClick={handleSubmitAppointment}
              loading={isSubmitting}
              disabled={(properties.cta.canBeDisabled) && (!message || !currentUserInExponent || !hasRecruiterAvailableSlots)}
            >
              <span style={{ paddingRight: '6px' }} role="img" aria-label="submit">{properties.cta.emoji}</span> {t(properties.cta.text)}
            </Button>
            <a target="_blank" href={`${window.location.pathname}?editTemplate=true`}>
              {t({ id: 'event.recruiter.appointmentModal.editTemplate' })}
            </a>
          </div>
        </div>
      </div>
    </Modal>
  );
}

ModalContact.propTypes = {
  properties: object,
  appointment: object,
  exponent: object,
  event: object,
  user: object,
  authUser: object,
  countUsers: number,
  onOk: func,
  visible: bool,
  offers: array,
  interactions: array,
  getUserTimeslots: func,
  onClose: func,
  withRestriction: bool,
  withLoading: bool,
  availableActions: object,
  participant: object,
  noAppointmentOffers: object,
  recruiter: object
};

const mapStateToProps = createStructuredSelector({
  event: eventSelectors.getCurrentEvent,
  timeslots: timeslotSelectors.getModalUserTimeslots,
  currentExponent: exponentSelectors.getCurrentExponent,
  availableActions: getAvailableActions,
});

const mapDispatchToProps = {
  setAgendaView: authActions.setAgendaView,
  getUserTimeslots: timeslotActions.getUserTimeslots,
  setCurrentExponent: exponentActions.setCurrentExponent,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(toJS(ModalContact));
