import React from 'react';
import { compose } from 'redux';
import { toJS } from '@/utils/index';
import { injectIntl } from 'react-intl';
import { func, shape } from 'prop-types';
import { isEmpty } from 'lodash';

// Components
import { Form, Select as SelectAntd } from 'antd/lib/index';
import Select from '@/components/Form/Select/index';

import { useFormatMessage } from 'react-intl-hooks';
import { getLocale } from '@/utils/localStorage';

const { Option } = SelectAntd;
const FormItem = Form.Item;

const EducationLevel = (props) => {
  const { form, onChange } = props;
  const t = useFormatMessage();
  const locale = getLocale();
  const educationLevels =  props.defaultCriteria?.find((c) => c?.key === 'LEVEL')?._choices || []
  const initialValue = props.matching.level;

  const handleOnChange = (e) => {
    if (typeof onChange === 'function') onChange(e, initialValue);

    form.setFieldsValue({ educationLevel: e });
  };


  const educationLevelName = 'educationLevel';

  return (
    <div id="educationLevelFormItemWrapper">
      <p className="help" style={{ fontSize: '14px' }}>{t({ id: 'education.level.help' })}</p>
      <FormItem>
        {form.getFieldDecorator(educationLevelName, {
          initialValue,
          rules: [{ required: true, message: t({ id: 'education.level.mandatory' }) }],
        })(
          <Select
            addOnIcon="skill"
            getPopupContainer={() => document.getElementById('educationLevelFormItemWrapper')}
            placeholder={<span>{t({ id: 'education.level' })} <span className="mandatory">*</span></span>}
            label={t({ id: 'education.level' })}
            handleOnChange={handleOnChange}
          >
            {
              educationLevels
              .filter((l) => (locale === 'en' && !isEmpty(l.label_en)) || (locale === 'fr'))
              .map((level) => {
                if (locale === 'fr') { return (<Option key={level._id}>{level.label}</Option>); }

                return (<Option key={level._id}>{level.label_en}</Option>);
              })
            }
          </Select>
        )}
      </FormItem>
    </div>
  );
}

EducationLevel.propTypes = {
  form: shape({
    getFieldDecorator: func,
    getFieldError: func,
    isFieldTouched: func,
  }),
  onChange: func,
};

export default compose(
  injectIntl,
)(toJS(EducationLevel));
