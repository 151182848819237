import { useQuery } from 'react-query';
import request from '@/utils/request';

export function useGetParticipantLimits({ event, participant, ...props }) {
  const requestUrl = `${process.env.FRONT_API_URL}/events/${event?._id}/participants/${participant?._id}/applicationsCounter`;
  const queryFn = () => request(requestUrl, { method: 'GET' });

  const query = useQuery({
    queryKey: ['participantLimits', event?._id, participant?._id],
    queryFn,
    refetchOnWindowFocus: false,
    enabled: !!event?._id && !!participant?._id,
    ...props
  });
  return query;
}
