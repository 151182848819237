import { Tab } from '@/components/Tab';
import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';

export const Nav = ({ conferencesQuery, handleClick }) => {
  const t = useFormatMessage();
  const indexSelected = location.pathname.includes('confirmed')  ? 1 : 0;

  return (
    <Tab
      items={[
        {
          label: t({ id: 'event.candidate.jobdating.conference.discover' }),
          count: conferencesQuery?.data?.countAll,
          onClick: () => handleClick('discovery')
        },
        {
          label: t({ id: 'event.candidate.jobdating.conference.my.participations' }),
          count: conferencesQuery?.data?.countParticipations,
          onClick: () =>  handleClick('confirmed')
        },
      ]}
      defaultIndex={indexSelected}
      index={indexSelected}
    />
  )
}
