import React from 'react';
import { FormattedMessage } from 'react-intl';
import { mapAndJoin } from '@/utils/text';
import { getExtremumPeriod } from '@/helpers/event/getExtremumPeriod';
import { dateFormat } from '@/utils/format';
import styles from '@/scenes/Admin/scenes/Dashboard/styles.less';
import { Tooltip } from '@/components/Tooltip';
import messages from '@/scenes/Admin/scenes/Dashboard/containers/EventList/messages';
import Icon from '@/components/Icon';
import CreateEventModal from '@/scenes/Admin/scenes/Dashboard/containers/CreateEventModal';
import { EVENT_STATUS } from '@/utils/constants';

export const Columns = ({  actions }) => (
  [
    {
      Header: 'Nom',
      id: 'name',
      disableSortBy: true,
      accessor: (record) => <div tabIndex={0} role="button" onClick={() => actions.handleClickEdit(record)}> {record.name} </div>,
    },
    {
      Header: 'Organisateurs',
      id: 'planner',
      disableSortBy: true,
      accessor: ({ planners }) => mapAndJoin(planners._organizations, 'name', ', '),
    },
    {
      Header: 'Date',
      id: 'endAt',
      className: "whitespace-nowrap",
      disableSortBy: true,
      accessor: (record) => {
        const [beginAt, endAt] = getExtremumPeriod(record, 'jobfair');

        return <div tabIndex={0} role="button" onClick={() => actions.handleClickEdit(record)}> {dateFormat(beginAt, endAt)} </div>;
      }
    },
    {
      Header: 'Etat',
      disableSortBy: true,
      id: 'status',
      className: "whitespace-nowrap",
      accessor: (record) => <div tabIndex={0} role="button" onClick={() => actions.handleClickEdit(record)}> {record.status} </div>,
    },
    {
      Header: 'Action',
      id: 'action',
      disableSortBy: true,
      accessor: (record) => (
        <div style={{ display: 'inline-flex' }} className={styles.action}>
          <span style={{ marginRight: '10px' }}>
            <Tooltip placement="top" title={<FormattedMessage {...messages.edit} />} color="dark" key={0}>
              <a role="button" tabIndex={0} onClick={() => actions.handleClickEdit(record)}><Icon name="edit-circle " /></a>
            </Tooltip>
          </span>
          <span>
            <CreateEventModal
              mode="duplicate"
              defaultValues={record}
            >
              <Tooltip placement="top" title={<FormattedMessage {...messages.duplicate} />} color="dark" key={0}>
                <a type="primary" className={styles.btnCreateEvent}><Icon name="duplicate" /></a>
              </Tooltip>
            </CreateEventModal>
          </span>
          {record.dateStatus === EVENT_STATUS.PREREGISTRATION && (
            <span style={{ marginLeft: '26px' }}>
              <Tooltip placement="top" title={<FormattedMessage {...messages.delete} />} color="dark" key={0}>
                <a role="button" tabIndex={0} onClick={actions.openDeleteModal}>
                  <Icon name="trash" className={styles.removeIcon} />
                </a>
              </Tooltip>
            </span>
          )}
        </div>
      ),
    },
  ]
)