import React, { memo } from 'react';
import { object } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { percentage } from '@/utils/math';

import { pick, keys, map, slice } from 'lodash';
import { Stats, WithPercentage } from '@/components/Stats';
import { If } from '@/components/If';

import commonMessages from '@/messages';
import messages from './messages';

import styles from './styles.less';


const getPageStats = (stats) => ({
  // Inscriptions
  countExponents: { // GOOD
    title: <FormattedMessage {...commonMessages.companies} values={{ count: 2 }} />,
    count: 0,
  },
  countExponentsReady: { // GOOD
    title: <FormattedMessage {...messages.registrationCompaniesReady} />,
    count: 0,
    additionalText: <WithPercentage value={percentage(stats.countExponentsReady, stats.countExponents)} />,
  },
  // Consultations des profils
  totalVisitParticipants: { // GOOD
    title: <FormattedMessage {...commonMessages.total} />,
    tooltip: <FormattedMessage {...messages.consultationTotalTooltip} />,
    count: 0,
  },
  visitedAtLeastOneParticipant: { // GOOD
    title: <FormattedMessage {...commonMessages.companies} values={{ count: 2 }} />,
    tooltip: <FormattedMessage {...messages.consultationCompaniesTooltip} />,
    count: 0,
    additionalText: <WithPercentage tooltip={<FormattedMessage {...messages.consultationCompaniesPercentageTooltip} />} value={percentage(stats.visitedAtLeastOneParticipant, stats.countExponents)} />,
  },
  averageTotalVisitAndOneParticipant: { // GOOD
    title: <FormattedMessage {...commonMessages.average} />,
    tooltip: <FormattedMessage {...messages.consultationAverageTooltip} />,
    count: percentage(stats.totalVisitParticipants, stats.visitedAtLeastOneParticipant * 100, 1),
  },
  // Proposition de rdv
  sourcingAppointments: { // GOOD
    title: <FormattedMessage {...commonMessages.total} />,
    tooltip: <FormattedMessage {...messages.propositionTotalTooltip} />,
    count: 0,
  },
  exponentsThatSourcedOnce: { // GOOD
    title: <FormattedMessage {...commonMessages.companies} values={{ count: 2 }} />,
    tooltip: <FormattedMessage {...messages.propositionCompaniesTooltip} />,
    count: 0,
    additionalText: <WithPercentage tooltip={<FormattedMessage {...messages.propositionCompaniesPercentageTooltip} />} value={percentage(stats.exponentsThatSourcedOnce, stats.countExponents)} />,
  },
  averageExponentsThatSourcedOnce: {  // GOOD
    title: <FormattedMessage {...commonMessages.average} />,
    tooltip: <FormattedMessage {...messages.propositionAverageTooltip} />,
    count: percentage(stats.sourcingAppointments, stats.exponentsThatSourcedOnce * 100, 1),
  },
  // Cv téléchargés
  totalCvUnique: { // GOOD
    title: <FormattedMessage {...commonMessages.total} />,
    tooltip: <FormattedMessage {...messages.cvDownloadedTotalTooltip} />,
    count: 0,
  },
  exponentWhoDownloadCv: { // GOOD
    title: <FormattedMessage {...commonMessages.companies} values={{ count: 2 }} />,
    tooltip: <FormattedMessage {...messages.cvDownloadedCompaniesTooltip} />,
    additionalText: <WithPercentage tooltip={<FormattedMessage {...messages.cvDownloadedCompaniesPercentageTooltip} />} value={percentage(stats.exponentWhoDownloadCv, stats.countExponents)} />,
    count: 0,
  },
  averageCvDownloadedByExponent: { // GOOD
    title: <FormattedMessage {...commonMessages.average} />,
    tooltip: <FormattedMessage {...messages.cvDownloadedAverageTooltip} />,
    count: percentage(stats.totalCvUnique, stats.exponentWhoDownloadCv * 100, 1),
  },
  // traitement des candidatures
  pendingAppointments: { // GOOD
    title: <FormattedMessage {...messages.processingApplicationsPendingTitle} />,
    tooltip: <FormattedMessage {...messages.processingApplicationsPendingTooltip} />,
    additionalText: <WithPercentage tooltip={<FormattedMessage {...messages.processingApplicationsPendingPercentageTooltip} />} value={percentage(stats.pendingAppointments, stats.allAppointments)} />,
    count: 0,
  },
  refusedAppointments: { // GOOD
    title: <FormattedMessage {...commonMessages.refusedPlgr} values={{ gender: 'female', count: 2 }} />,
    tooltip: <FormattedMessage {...messages.processingApplicationsRefusedTooltip} />,
    additionalText: <WithPercentage tooltip={<FormattedMessage {...messages.processingApplicationsRefusedPercentageTooltip} />} value={percentage(stats.refusedAppointments, stats.allAppointments)} />,
    count: 0,
  },
  acceptedAppointments: { // GOOD
    title: <FormattedMessage {...commonMessages.acceptedPlgr} values={{ gender: 'female', count: 2 }} />,
    tooltip: <FormattedMessage {...messages.processingApplicationsAcceptedTooltip} />,
    additionalText: <WithPercentage tooltip={<FormattedMessage {...messages.processingApplicationsAcceptedPercentageTooltip} />} value={percentage(stats.acceptedAppointments, stats.allAppointments)} />,
    count: 0,
  },
  // AUTO - CRENEAUX
  totalSlotsCreated: { // GOOD
    title: <FormattedMessage {...messages.timeslotsCreatedTitle} />,
    count: 0,
  },
  freeSlots: { // GOOD
    title: <FormattedMessage {...messages.timeslotsEnableTitle} />,
    additionalText: <WithPercentage tooltip={<FormattedMessage {...messages.timeslotsEnablePercentageTooltip} />} value={percentage(stats.freeSlots, stats.totalSlotsCreated)} />,
    count: 0,
  },
  // AUTO - RDVS VALIDES
  totalAccepted: { // GOOD
    title: <FormattedMessage {...commonMessages.total} />,
    count: 0,
  },
  organizationAccepted: { // GOOD
    title: <FormattedMessage {...commonMessages.companies} values={{ count: 2 }} />,
    tooltip: <FormattedMessage {...messages.interviewCompaniesTooltip} />,
    additionalText: <WithPercentage tooltip={<FormattedMessage {...messages.cvDownloadedCompaniesPercentageTooltip} />} value={percentage(stats.organizationAccepted, stats.countExponents)} />,
    count: 0,
  },
  averageInterview: { // GOOD
    title: <FormattedMessage {...commonMessages.average} />,
    tooltip: <FormattedMessage {...messages.interviewCompaniesAverageTooltip} />,
    count: percentage(stats.totalAccepted, stats.organizationAccepted * 100, 1),
  },
  // AUTO - RDVS ANNULES
  totalCanceledByRecruiters: { // GOOD
    title: <FormattedMessage {...commonMessages.total} />,
    count: 0,
  },
  exponentsThatCanceledInterviewOnce: { // GOOD
    title: <FormattedMessage {...commonMessages.companies} values={{ count: 2 }} />,
    tooltip: <FormattedMessage {...messages.interviewCanceledCompaniesTooltip} />,
    additionalText: <WithPercentage tooltip={<FormattedMessage {...messages.interviewCanceledCompaniesPercentageTooltip} />} value={percentage(stats.exponentsThatCanceledInterviewOnce, stats.countExponents)} />,
    count: 0,
  },
  averageInterviewCanceled: { // GOOD
    title: <FormattedMessage {...commonMessages.average} />,
    tooltip: <FormattedMessage {...messages.interviewCanceledCompaniesAverageTooltip} />,
    count: percentage(stats.totalCanceledByRecruiters, stats.exponentsThatCanceledInterviewOnce * 100, 1),
  },
});

const ExponentsStats = memo(
  ({ stats = {}, event: { skipAppointmentValidation } }) => {
    const pageStats = getPageStats(stats);

    const statsList = keys(pageStats);
    const statsFound = keys(pick(stats, statsList));

    const result = map(statsFound, (key) => ({ ...pageStats[key], count: pageStats[key].count || stats[key], noSeparator: true }));

    return (
      <div className={styles.participantsStats}>
        <div>
          <FormattedMessage {...messages.registrationTitle} tagName="h2" />
          <Stats list={slice(result, 0, 2)} containerClass={styles.noMarginBottom} />
        </div>
        <If condition={skipAppointmentValidation === false}>
          <div>
            <FormattedMessage {...messages.consultationTitle} tagName="h2" />
            <Stats list={slice(result, 2, 5)} containerClass={styles.noMarginBottom} />
          </div>

          <div>
            <FormattedMessage {...messages.propositionTitle} tagName="h2" />
            <Stats list={slice(result, 5, 8)} containerClass={styles.noMarginBottom} />
          </div>
          <div>
            <FormattedMessage {...messages.cvDownloadedTitle} tagName="h2" />
            <Stats list={slice(result, 8, 11)} containerClass={styles.noMarginBottom} />
          </div>
          <div>
            <FormattedMessage {...messages.processingApplicationsTitle} tagName="h2" />
            <Stats list={slice(result, 11, 14)} containerClass={styles.noMarginBottom} />
          </div>
        </If>
        <If condition={skipAppointmentValidation}>
          <div>
            <FormattedMessage {...messages.consultationTitle} tagName="h2" />
            <Stats list={slice(result, 2, 4)} containerClass={styles.noMarginBottom} />
          </div>
          <div>
            <FormattedMessage {...messages.cvDownloadedTitle} tagName="h2" />
            <Stats list={slice(result, 8, 10)} containerClass={styles.noMarginBottom} />
          </div>
          <div>
            <FormattedMessage {...messages.timeslotsTitle} tagName="h2" />
            <Stats list={slice(result, 14, 16)} containerClass={styles.noMarginBottom} />
          </div>
          <div>
            <FormattedMessage {...messages.appointmentsValidatedTitle} tagName="h2" />
            <Stats list={slice(result, 16, 19)} containerClass={styles.noMarginBottom} />
          </div>
          <div>
            <FormattedMessage {...commonMessages.appointmentsCanceledPl} values={{ count: 2 }} tagName="h2" />
            <Stats list={slice(result, 19, 22)} containerClass={styles.noMarginBottom} />
          </div>
        </If>
      </div>
    );
  });

ExponentsStats.propTypes = {
  stats: object,
  event: object,
};

export { ExponentsStats as Stats };
