import React, { useEffect } from 'react';
import { object } from 'prop-types';
import { compose } from 'redux';
import { Switch, Route, useHistory } from 'react-router-dom';
import { toJS } from '@/utils';
import { USERS_EVENT_ROLES } from '@/utils/constants';
import { withLayout } from '@/containers/Layout';
import EventRouter from '@/components/EventRouter';
import CandidatePreparationParticipationPage from './scenes/Participation';
import ParticipantPage from './scenes/Participant';
import CandidatePreparationOnboardingPage from './scenes/Onboarding';
import './styles.less';
import ResetPasswordPage from '@/scenes/ResetPassword';


const CandidatePreparationScene = ({ match }) => {
  const history = useHistory();

  useEffect(() => {
    if (match.isExact) {
      history.replace(`${match.url}/onboarding`);
    }
  }, [])


  return (
    <Switch>
      <Route path={`${match.path}/participation`} component={CandidatePreparationParticipationPage} />
      <Route path={`${match.path}/participant/:participantId`} component={ParticipantPage} />
      <EventRouter
        path={`${match.path}/onboarding`}
        component={CandidatePreparationOnboardingPage}
        isOnboarding
        scope={[USERS_EVENT_ROLES.participant]}
      />
      <Route path={`${match.path}/onboarding`} component={CandidatePreparationOnboardingPage} />
      <Route path={`${match.path}/resetpassword`} component={ResetPasswordPage} />
    </Switch>
  );
}

CandidatePreparationScene.propTypes = {
  match: object,
}

export default compose(
  withLayout({ size: 'full', navType: 'candidate' }),
)(toJS(CandidatePreparationScene));
