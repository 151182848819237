import React from 'react';
import { isEmpty } from 'lodash';
import { object, string, func } from 'prop-types';
import { H5 } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { useSelector } from 'react-redux';
import { Bloc } from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/components/Bloc';
import MatchingFilterFormItem from '@/components/MatchingFilterFormItem';
import LoadingIndicator from '@/components/LoadingIndicator';
import { getDefaultCriteria } from '@/store/criterion/selectors';
import { EVENT_TYPE } from '@/utils/constants';
import MatchingContracts from '@/forms/candidate/MatchingContracts';
import MatchingTargets from '@/forms/candidate/MatchingTargets';

const Search = (props) => {
  const { form, user, event } = props;
  const isJpo = event?.type === EVENT_TYPE.jpo;
  const t = useFormatMessage();
  const defaultCriteria = useSelector(getDefaultCriteria)?.toJS();

  const getTargetCriterion = () => {
    const targetsCriterion = defaultCriteria.find((criterion) => criterion.key === 'TARGETS');
    const _choices = targetsCriterion._choices.map((choice) => ({
      ...choice,
      _id: choice.id,
    }));

    return ({
        ...targetsCriterion,
        _choices
      }
    )
  }

  const getContractCriterion = () => {
    const targetsCriterion = defaultCriteria.find((criterion) => criterion.key === 'CONTRACT');
    const _choices = targetsCriterion._choices.filter((choice) => choice.enable);
    const modules ={
      ...targetsCriterion.modules,
      onboarding: {
        ...targetsCriterion.modules.onboarding,
        choiceMax: 2,
      }
    };

    return ({
        ...targetsCriterion,
        modules,
        isCustomContract: true,
        _choices
      }
    )
  }

  if (isEmpty(defaultCriteria)) {
    return <LoadingIndicator />;
  }

  return (
    !isJpo && (
      <Bloc>
        <div className="commonStep">
          <div className="fieldsContainer">
            <H5 className="mb-24">{t({ id: 'signup.search.title' })}</H5>
            <MatchingTargets
              initialValue={user.matching.targets}
              form={form}
              formType="antd"
              options={getTargetCriterion()}
            />
            <MatchingContracts
              initialValue={user.matching.filters}
              form={form}
              formType="antd"
              options={getContractCriterion()}
            />
          </div>
        </div>
      </Bloc>
    )
  );
}

Search.propTypes = {
  form: object,
  user: object,
  event: object,
};

export default Search;
