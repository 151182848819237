import React, { useEffect, useRef, useState } from 'react';
import { func, shape, object, bool } from 'prop-types';
import { isEmpty } from 'lodash';
import { Form, Row } from 'antd';
import { Body1, H3, Button, IconAtSign } from '@seekube-tech/ui-kit';
import colors from '@seekube-tech/ui-kit/dist/colors';
import { useFormatMessage } from 'react-intl-hooks';
import classNames from 'classnames';
import { Link, useHistory } from 'react-router-dom';
import { compose } from 'redux';
import { useDispatch } from 'react-redux';
import InputMaterial from '@/components/Input/InputMaterial/index';
import styles from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/SigninDev/styles.less';
import { Bloc } from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/components/Bloc';
import { authLogin } from '@/store/auth/actions';
import { track } from '@/utils/analytics';
import { ANALYTICS_ALL, ANALYTICS_CANDIDATE, API_CUSTOM_ERRORS } from '@/utils/constants';
import Wrapper from '@/components/Wrapper';
import { Password } from '@/forms/components';
import styles2 from './styles.less';
import { RememberMe } from '@/scenes/Auth/scenes/Login/components/RememberMe';
import { Captcha } from '@/scenes/Auth/scenes/Login/components/Captcha';
import { story } from '@/utils/manageVisibilityStory';
import { queryStringToObject } from '@/utils/url';
import { trackLogin } from '@/utils/analytics/trackers/category/eventOnboarding';

const FormItem = Form.Item;

const SigninDev = ({
  event,
  form,
  jpoStep,
  authUser,
}) => {
  const t = useFormatMessage();
  const dispatch = useDispatch();
  const history = useHistory();
  const [defaultEmail, setDefaultEmail] = useState(null)
  const [errorMessage, setErrorMessage] = useState()
  const isSignUpInSeveralStep = event.slug === 'school-dating-mai-2023-1';
  
  const captchaWidget = useRef();
  const [catchpa, setCaptcha] = useState({
    success: false,
    solution: null
  });
  const [rememberMe, setRememberMe] = useState(false);
  const isCaptchaVisible = story.captcha.isVisible;
  
  useEffect(() => {
    if (window.localStorage.getItem('signup-email')) {
      setDefaultEmail(window.localStorage.getItem('signup-email'));
    }
  }, [])

  /**
   * HandleOnSubmitRegistration
   *
   * @description
   * Pass form data to parent components when user validate signup form
   */
  const handleOnSubmitRegistration = (e) => {
    e.preventDefault();


    return form.validateFields((err, values) => {
      if (!err) {
        if (!isEmpty(authUser)) {
          const {analytics} = window;

          if (analytics) {
            analytics.reset();
            analytics.alias(authUser._id);
            analytics.identify(authUser._id, authUser);
            analytics.track(ANALYTICS_ALL.AUTHENTICATION, {
              forum: match.params.eventSlug,
              method: authUser.provider || null,
            });
          }
        }

        dispatch(authLogin({
          ...values,
          solution: catchpa.solution,
          rememberMe,
          eventSlug: event.slug, eventId: event._id, event,
          callback: ({ participant, user, ...response }) => {
            
            if (response.message === 'blocked_account') {
              setErrorMessage(t({ id: `api.error.${API_CUSTOM_ERRORS.blockedAccount}` }));
            }

            if ([
              API_CUSTOM_ERRORS.wrongPassword, 
              API_CUSTOM_ERRORS.userNotFound, 
              API_CUSTOM_ERRORS.invalidCaptcha
            ].includes(response.message)) {
              setErrorMessage(t({ id: 'signin.error' }));
            }

            if (isEmpty(response?.error)) {
              return history.replace(queryStringToObject(window.location.search).redirect)
            }
            captchaWidget.current?.reset?.();
            captchaWidget.current?.start?.();
            setCaptcha({ ...catchpa, success: false, solution: null });

            track({
              user: authUser,
              name: ANALYTICS_CANDIDATE.FILLED_ONBOARDING_SIGNUP,
              event,
              properties: {
                eventSlug: event?.slug,
                username: values.username,
                email: event.username,
                origin: 'signin'
              },
            });

            trackLogin(authUser || user, event);
            
            if (!participant?.isActive && user?.isActive) {
              history.push('profile-check');
            } else {
              history.push('profile');
            }
          },
          notificationParams: {
            error: {
              message: t({ id: "signin.error" })
            }
          }
        }))
      }
    });
  };


  const mailInput = (visible) => (
      <FormItem className={!visible && styles.hiddenInput}>
        {form.getFieldDecorator('username', {
          initialValue: defaultEmail,
          rules: [{ required: true, type: 'email', message: t({ id: 'form.mandatory' }) }],
          validateTrigger: 'onSubmit',
        })(
          <InputMaterial addOnIcon={<IconAtSign size={24} />} label={t({ id: 'form.email' })} placeholder={t({ id: 'form.email' })} type="mail" validations={['required']} />
        )}
      </FormItem>
    )

  return (
    <Wrapper size="large">
      <div className={styles2.container}>
        <Bloc maxWidth={500}>
          <div className={styles2.blocContent}>
            <Form className={styles2.signupForm} onSubmit={handleOnSubmitRegistration}>
              <H3 className={styles2.title}>{t({ id: 'login' })}</H3>

              <Row>
                {mailInput(((isSignUpInSeveralStep && jpoStep === 0) || !isSignUpInSeveralStep))}
              </Row>
              <Row>
                {((isSignUpInSeveralStep && jpoStep === 1) || !isSignUpInSeveralStep) && <Password form={form} />}
              </Row>

              {errorMessage &&
                <Body1 className="mb-20" color={colors.error['500']}>
                  {errorMessage}
                </Body1>
              }
              
              <Row>
                <FormItem className="mb-20 mt-20">
                  {form.getFieldDecorator('rememberMe', {
                    initialValue: false,
                  })(
                    <RememberMe
                      onChange={setRememberMe}
                      checked={rememberMe}
                    />
                  )}
                </FormItem>
              </Row>

              { 
                isCaptchaVisible && <Row>
                  <Captcha
                    onSuccess={(solution) => {
                      setCaptcha({ ...catchpa, success: true, solution })
                    }}
                    onError={() => {
                      setCaptcha({ ...catchpa, success: false })
                    }}
                    widget={captchaWidget}
                  />
                </Row>
              }

              <div className={classNames("mb-20", !isSignUpInSeveralStep && 'mt-20')}>
                <Button
                  type="submit"
                  className="w-full mt-8"
                  disabled={isCaptchaVisible && !catchpa.success}
                >
                  {t({ id: 'login' })}
                </Button>
              </div>
            </Form>
            <div className={styles2.footer}>
              <div className={styles2.changeMode}>
                <Body1 color={colors.neutral['400']} className={styles2.notAccountText}>{t({ id: 'signin.noAccount' })} </Body1>
                <Link to={`/${event.slug}/candidate/preparation/onboarding/signup`}>
                  {t({ id: 'signin.signup' })}
                </Link>
              </div>
              <div>
                <Link to={`/${event.slug}/candidate/preparation/resetpassword`}>
                  <span className="desktop">{t({ id: 'signin.forgotPassword' })}</span>
                </Link>
              </div>
            </div>
          </div>
        </Bloc>
      </div>
    </Wrapper>
  )
}

SigninDev.propTypes = {
  event: object,
  form: shape({
    getFieldDecorator: func,
    getFieldsError: func,
    getFieldError: func,
    isFieldTouched: func,
  }),
  jpoStep: bool,
}

const withForm = Form.create();

export default compose(
  withForm,
)(SigninDev);
