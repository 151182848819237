import { useQuery } from 'react-query';
import request from '@/utils/request';

export function useGetEvent({ slug }) {
  const requestUrl = `${process.env.FRONT_API_URL}/events/${slug}`;
  const queryFn = () => request(requestUrl, { method: 'GET', });

  const query = useQuery({
    queryKey: ['event', slug],
    queryFn,
    refetchOnWindowFocus: false,
    enabled: !!slug,
  });
  return query;
}
