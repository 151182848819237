/*
** https://hellowork.atlassian.net/wiki/spaces/Logiciels/pages/3206709317/Infos+g+n+riques 
*/
import { USER_ROLE, USERS_EVENT_ROLES, USERS_TYPE } from '@/utils/constants';
import { getUserRoleFromUserContext } from '@/utils/event';
import { isEmpty } from 'lodash';

const trackingUserEventData = (skEvent, user, participations = {}) => {
  const userJobdatingSignup = !!skEvent?.userContext?.roles?.includes(USER_ROLE.PARTICIPANT);
  let userCategory = '';
  const { hasPastParticipations, hasFutureParticipations } = participations;
  
  if (!isEmpty(skEvent)) {
    const userRole = getUserRoleFromUserContext(skEvent);
  
    if ([USERS_EVENT_ROLES.participant, USERS_EVENT_ROLES.guest].includes(userRole)) {
      userCategory = 'candidate';
    } else {
      userCategory = userRole;
    }
  } else if (!isEmpty(user)) {
    userCategory = isEmpty(user?._currentOrganization) ? USERS_TYPE.candidate : USERS_TYPE.recruiter;
  }
  
  const userData = {
    user_id_back: user?._id || '',
    user_jobdating_signup: userJobdatingSignup,
    user_category: userCategory,
    user_known: hasPastParticipations || hasFutureParticipations || false,
    jobdating_type: skEvent?.type || '',
  }
  
  return userData;
}

export {
  trackingUserEventData
}