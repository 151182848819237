import { defineMessages } from 'react-intl';

export default defineMessages({
  profilePicture: {
    id: 'profilePicture',
  },
  errorImgFormat: {
    id: 'error.img.format',
  },
});
