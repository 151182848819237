import React from 'react';
import { array, func } from 'prop-types';
import { Grid } from '@seekube-tech/ui';
import { without } from 'lodash';
import {
  RecipientItem
} from '@/scenes/Event/scenes/Owner/scenes/Setting/scenes/Reporting/components/EmailingSchedule/components/Recipients/components/RecipientItem';
import useStyles
  from '@/scenes/Event/scenes/Owner/scenes/Setting/scenes/Reporting/components/EmailingSchedule/components/Recipients/components/styles';

export const RecipientsList = ({ recipientsMails = [], handleRemoveRecipient }) => {
  const classes = useStyles();

  const onRemoveRecipient = (email) => {
    const emails = without(recipientsMails, email);

    handleRemoveRecipient(emails);
  }

  return (
    <Grid className={classes.recipientsList} container direction="row" spacing={2} wrap>
      {
        recipientsMails.map((email) => (
          <Grid item key={email}>
            <RecipientItem email={email} handleRemoveRecipient={() => onRemoveRecipient(email)} />
          </Grid>
        ))
      }
    </Grid>
  )
}

RecipientsList.propTypes = {
  recipientsMails: array,
  handleRemoveRecipient: func,
}