import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import * as types from './types';

// Initial State
const initialReducer = fromJS({
  events: [],
  contacts: [],
});

// Events reducer
const v1KpiReducer = handleActions({
  [types.GET_EVENTS_SUCCESS]: (state, { payload }) =>
    state.set('events', payload),
  [types.GET_CONTACTS_SUCCESS]: (state, { payload }) =>
    state.set('contacts', payload),
}, initialReducer);

export default v1KpiReducer;

