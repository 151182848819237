import { Body1 } from '@seekube-tech/ui-kit';
import colors from '@seekube-tech/ui-kit/dist/colors';
import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { object } from 'prop-types';
import { Form } from 'antd';
import Checkbox from '@/components/Checkbox';

const FormItem = Form.Item;

export const Rgpd = ({ form }) => {
  const t = useFormatMessage();

  return (
    <>
      <FormItem className="mb-20">
        <div className="mb-20 leading-[15px]">
          {form.getFieldDecorator('rgpd', {
            rules: [{ required: true, message: t({ id: 'form.mandatory' }) }],
            validateTrigger: 'onSubmit',
          })(
            <Checkbox validations={['required']}>
              <Body1 color={colors.neutral['500']} className="inline">
                {t({ id: 'signup.rgpdJobdating' })}
              </Body1>
            </Checkbox>
          )}
        </div>
      </FormItem>
    </>
  )
}

Rgpd.propTypes = {
  form: object
}
