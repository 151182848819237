import React, {useState} from 'react';
import { object, string, func, array } from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { useFormatMessage } from 'react-intl-hooks';
import { createStructuredSelector } from 'reselect';
import { toJS } from '@/utils';

import { authSelectors } from '@/store/auth';
import Icon from '@/components/Icon';

import { Popover } from 'antd';
import Avatar from '@/components/Avatar';
import { Button } from '@seekube-tech/ui-kit';
import styles from './styles.less';
import { getParticipant as getParticipantAction } from '../../../../../../../../../store/participant/actions';
import { notificationActions } from '../../../../../../../../../store/notification';
import Details from '../Details';
import EditInformal1to1Description from '../../EditInformal1to1Description/EditInformal1to1Description';

function ConferenceDetails({
  event,
  appointmentColor,
  getParticipant,
  timezone,
  closePopover,
  authUser,
  jobfairs,
  handleOnInformalDescriptionChange,
  currentEvent,
}) {
  const t = useFormatMessage();
  const [isPopoverVisible, setPopoverVisible] = useState(false);

  const jobfair = jobfairs.find(jobfair => jobfair.id === currentEvent._id);
  const conference = event._conference;
  const conferenceStart = moment.tz(event.start, timezone);
  const conferenceEnd = moment.tz(event.end, timezone);
  const today = moment.tz(new Date(), timezone);
  const canSeeProfile = jobfair
    && moment.tz(jobfair.keyDates.hunt.beginAt, timezone).isBefore(today)
    && moment.tz(jobfair.keyDates.jobfair.endAt, timezone).isAfter(today);

  function openProfileInNewTab() {
    getParticipant({
      eventId: conference._event.slug,
      userId: conference._user.id,
      noLoader: true,
      openProfile: true,
      redirectUrl: `/${conference._event.slug}/recruiter/jobdating/`,
    });
  }
  /**
   * Handle popover visibility
   * @param {Boolean} visibility
   */
  const handleTogglePopover = (visibility = false) => {
    setPopoverVisible(visibility);
  };

  return (
    <Popover
      placement="right"
      content={
        <EditInformal1to1Description description={event.informalDescription} closePopover={() => handleTogglePopover(false)} event={event} handleOnInformalDescriptionChange={handleOnInformalDescriptionChange} isPopoverVisible={isPopoverVisible}/>
      }
      visible={isPopoverVisible}
      onVisibleChange={handleTogglePopover}
      trigger="click"
      zIndex={1000} // mandatory because the Agenda Modal is in the hundreds
    >
      <Details type={event.type} closePopover={closePopover} title={conference.title}>
        {conference.presenters.map((presenter) => (
            <div className={styles.row}>
                <div>
                  <Avatar size={24} user={presenter._user} src={presenter._user?.pictureUrl} />
                </div>
                <p>{presenter._user ? `${presenter.firstName} ${presenter.lastName}` : presenter.email}</p>
            </div>
          ))}
        <div className={styles.row}>
          <div>
            <div className={styles.colorBadge} style={{ backgroundColor: appointmentColor }} />
          </div>
          <p>{event.eventName}</p>
        </div>
        <div className={styles.row}>
          <div>
            <Icon name="clock" className={styles.icon} style={{ width: 16, height: 16 }} />
          </div>
          <div>
            <p>{conferenceStart.format('dddd DD MMMM YYYY')}</p>
            <p className={styles.appointmentHours}>{conferenceStart.format('HH:mm')} - {conferenceEnd.format('HH:mm')}</p>
          </div>
        </div>
        <div className={styles.buttonToLive}>
          <a href={conference.providerUrl} target="_blank">
            <Button variant='fill' color='primary'>{t({id: 'livecard.actions.goToLive'})}</Button>
          </a>
        </div>
      </Details>
    </Popover>
  );
}

ConferenceDetails.propTypes = {
  event: object.isRequired,
  appointmentColor: string.isRequired,
  timezone: string.isRequired,
  getParticipant: func,
  closePopover: func.isRequired,
  sendNotification: func,
  authUser: object,
  jobfairs: array.isRequired,
  handleOnInformalDescriptionChange: func,
  informalDescription: string,
  currentEvent: object,
};

const mapDispatchToProps = {
  getParticipant: getParticipantAction,
  sendNotification: notificationActions.sendNotification,
};

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser,
});

export default connect(mapStateToProps, mapDispatchToProps)(toJS(ConferenceDetails));
