import React from 'react';
import { func, array, object } from 'prop-types';
import { isEmpty } from 'lodash';
import { Form } from 'antd';
import { Button } from '@seekube-tech/ui-kit';
import request from '@/utils/request';

// Components
import InputMaterial from '@/components/Input/InputMaterial';
import LoadingIndicator from '@/components/LoadingIndicator';

// Styles & Translations
import styles from '../../styles.less';

const FormItem = Form.Item;

class MergeNewItem extends React.PureComponent { // eslint-disable-line
  static propTypes = {
    suggestions: array,
    item: object,
    onMerge: func,
  };

  state = {
    searchItems: [],
    selectedItem: null,
    search: '',
  };

  handleSelectItem = (item) => {
    this.setState({ selectedItem: item });
  }

  handleMerge = () => {
    const {
      props: { item, onMerge },
      state: { selectedItem },
    } = this;

    this.setState({ selectedItem: null, searchItems: [], value: '' });

    onMerge(item, selectedItem);
  }

  handleSkip = () => {
    const { props: { item, onMerge } } = this;

    this.setState({ selectedItem: null, searchItems: [], value: '' });

    onMerge(item, null);
  }

  handleSearch = (e) => {
    const value = typeof e === 'object' ? e.currentTarget.value : e;
    this.setState({ isFetching: true, search: value });

    if (!isEmpty(value)) {
      request(`${process.env.FRONT_API_URL}/organizations/search?name=${value}&type=school`).then((results) => {
        this.setState({
          searchItems: results.docs,
          isFetching: false,
        });
      });
    } else {
      this.setState({
        searchItems: [],
        isFetching: false,
      });
    }
  }

  render() {
    const {
      props: { item, suggestions },
      state: { selectedItem, search, searchItems },
      handleSelectItem, handleMerge, handleSkip, handleSearch,
    } = this;

    if (!item) {
      return <LoadingIndicator />;
    }

    return (
      <div className={styles.mergeNewItemComponent}>
        <h1 className="mainTitle">NOUVELLE ÉCOLE À TRAITER</h1>
        <div className={styles.itemTitle}>{item.name} <small>{`(${item.occurrences})`}</small></div>
        <div className={styles.blocActionContainer}>
          <div className={styles.blocAction}>
            <p>Cette entrée ne doit pas être fusionner avec une existante</p>
            <Button onClick={() => handleSkip()}>Ne pas fusionner</Button>
          </div>
          <div className={styles.blocAction}>
            {selectedItem ? (
              <>
                <p>Fusionner <b>{item.name}</b> avec<br /><b>{selectedItem.name}</b> ?</p>
                <Button onClick={() => handleMerge()}>Fusionner</Button>
              </>
            ) : ''}
          </div>
        </div>
        <div className={styles.suggestions}>
          <h2 className="mainTitle">Suggestion de rapprochement</h2>

          {suggestions.filter((s) => s._id !== item._id).map((s) => (
            <Button
              onClick={() => handleSelectItem(s)}
              type={selectedItem && selectedItem.name === s.name ? 'primary' : 'default'}
            >{s.name} <small>{`(${s.occurrences})`}</small></Button>
          ))}

          <Form className={styles.inputSuggestion}>
            <FormItem>
              <InputMaterial
                value={search}
                addOnIcon="user"
                label="Chercher une école"
                type="string"
                onChange={handleSearch}
              />
            </FormItem>
          </Form>

          <ul>
            {searchItems.filter((s) => s._id !== item._id).map((s) => (
              <li>
                <Button
                  onClick={() => handleSelectItem(s)}
                  type={selectedItem && selectedItem.name === s.name ? 'primary' : 'default'}
                >{s.name} <small>{`(${s.occurrences})`}</small></Button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default MergeNewItem;
