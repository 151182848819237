import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'client.create.offer.matching.title',
  },
  titlePublishOffer: {
    id: 'client.offers.title.published',
  },
  titleDraftOffer: {
    id: 'client.offers.title.draft',
  },
  subtitle: {
    id: 'client.create.offer.matching.subtitle',
  },
  searchPlaceholder: {
    id: 'client.offers.search',
  },
  addRecruiter: {
    id: 'client.offers.add.recruiter',
  },
  removeOfferFromJobdating: {
    id: 'client.offers.remove.from.jobdating',
  },
  publishOfferOnJobdating: {
    id: 'client.offers.publish.on.jobdating',
  },
  selectAllRecruiters: {
    id: 'client.offers.select.all.recruiters',
  },
  createOffer: {
    id: 'blankState.preparation.offer.btn',
  },
  confirmRemove: {
    id: 'client.offers.offer.remove.confirm',
  },
  removeOffersTitlePl: {
    id: 'client.offers.offer.remove.title.pl',
  },
  draftOffersSubtitlePl: {
    id: 'client.offers.offer.draft.subtitle.pl',
  },
  draftOffersTitlePl: {
    id: 'event.recruiter.preparation.offer.btn.draft',
  },
  empty: {
    id: 'empty',
  },
  preview: {
    id: 'preview',
  },
  cancel: {
    id: 'cancel',
  },
  confirm: {
    id: 'btn.confirm',
  },
});
