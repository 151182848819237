import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { getAuthUser } from '@/store/auth/selectors';
import { authSSO } from '@/store/auth/actions';
import Separator from '@/components/Separator';
import OauthCover from '@/components/OauthCover';
import { IconSeekube } from '@seekube-tech/ui-kit';

const SSO = () => {
  const { host, pathname, protocol } = window.location;
  const dispatch = useDispatch();
  const { search } = useLocation();
  const authUser = useSelector(getAuthUser);
  const code = new URLSearchParams(search).get('code');
  const eventSlug = new URLSearchParams(search).get('state');

  useEffect(() => {
    if (authUser || isEmpty(code)) {
      // Redirect connected users
      window.location.href = `/${eventSlug || ''}`;
    } else {
      const params = {
        eventSlug,
        redirect_uri: `${protocol}//${host}${pathname}`,
        code,
      }

      // Post action here
      console.log('[POST] /users/sso', params);
      dispatch(authSSO(params));
    }
  }, []);

  return (
    <OauthCover>
      <div style={{ textAlign: 'center', paddingTop: '35vh', color: '#FFF' }}>
        <IconSeekube size={52} color="#FFF" />
        <Separator height={32} />
        <h4 style={{ color: '#FFF' }}>Authentification en cours...</h4>
      </div>
    </OauthCover>
  )
}

export default SSO;
