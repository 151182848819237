import request from '@/utils/request';
import { getId } from '@/utils/global';
import { getNavigator } from '@/utils/navigators';

const postContact = async ({ authUser, body, callback }) => {
  const requestUrl = `${process.env.FRONT_API_URL}/apps/contact`;
  await request(requestUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ ...body, authUser: getId(authUser), navigator: getNavigator() }),
  });

  return callback();
};

export { postContact };
