import React from 'react';
import classnames from 'classnames';
import { string } from 'prop-types';

import useStyles from './styles';

function Divider({ className }) {
  /* Vars */

  const styles = useStyles();

  /* Render */

  return <div className={classnames(styles.root, className)} />
}

Divider.propTypes = {
  className: string,
};

Divider.defaultProps = {
  className: '',
};

export default Divider;
