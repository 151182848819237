import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { array, object } from 'prop-types';
import moment from 'moment';
import { uniqueId, isEmpty, get, filter, find, includes, isNull } from 'lodash';
import classnames from 'classnames';
import { Timeline } from 'antd';
import { animateScroll as scroll, Element } from 'react-scroll';
import { useFormatMessage } from 'react-intl-hooks';
import { getId } from '@/utils/global';
import { INTERACTION_TYPE } from '@/utils/constants'
import Icon from '@/components/Icon';
import styles from './styles.less';

const containerId = uniqueId('container');
const Activity = ({ interactions, participant, organization }) => {
  const t = useFormatMessage();


  const getOfferTitle = ({ _offers, _offer }) => {
    let offerTitle = '';

    if (isEmpty(_offer)) {
      if (!isEmpty(_offers)) {
        offerTitle = (_offers && !isEmpty(_offers) && _offers.length === 1) ? _offers[0] && _offers[0].title : '';
      }
    } else {
      offerTitle = (_offer && _offer.title) || '';
    }

    return offerTitle?.length ? t({ id: 'activity.forOffer' }, { offer: offerTitle }) : null;
  };

  const handleScroll = (direction) => {

    scroll.scrollMore(direction === 'top' ? -175 : 175, {
      containerId,
      delay: 150,
      smooth: true,
    });
  };

  const getLastVisitStand = () => find(participant.visits,
      { type: 'visit-stand', _organization: organization?._id || '' }) ?
      [find(participant.visits, {
        type: 'visit-stand',
        _organization: organization?._id || '' })]
      : []

  const getInteractionInteractionsItem = () => (
    [
      ...filter(interactions,
        (interaction) => interaction.enable
          && getId(interaction?._organization) === getId(organization)),
      ...getLastVisitStand(),
    ]
  )

  const computeInteractions = () => {
    const  { _user, _event } = participant;
    const candidateShortName = _user.shortName;
    const interactions = [];

    const interactionItems = getInteractionInteractionsItem();

    interactionItems.forEach((interaction) => {
      const userName = `${interaction?._recruiter?.firstName} ${interaction?._recruiter?.lastName?.charAt(0) || ''}.`;
      const isTransferred = getId(interaction._owner) !== getId(interaction._recruiter);
      const ownerName = `${interaction?._owner?.firstName} ${interaction?._owner?.lastName?.charAt(0) || ''}.`;
      const messageValues = {
        candidate: <span className={styles.highlight}>{candidateShortName}</span>,
        recruiter: <span className={styles.highlight}>{userName}</span>,
        owner: <span className={styles.highlight}>{ownerName}</span>
      };

      const date = moment(interaction.updatedAt);
      const object = {
        date: date.fromNow(),
        time: date.unix(),
        type: interaction.type,
      };

      const status = get(interaction, '_appointment.status');

      if (interaction.type === 'visit-stand') {
        object.icon = 'icon-seen';
        object.subtitle = t({ id: 'activity.visit.stand' });
      }

      // informal1to1 appointment accepted
      if (
        includes([INTERACTION_TYPE.informal1to1], interaction.type) && status === 'accepted'
      ) {
        const applicationDate = moment(interaction._appointment.acceptedAt);

        // appointment pending
        interactions.push({
          time: applicationDate.unix(),
          date: applicationDate.fromNow(),
          icon: 'iconAccept',
          subtitle: <>
            <FormattedMessage
              id="activity.informal1to1.accepted"
              values={messageValues} />
          </>,
        });
      }

      // informal1to1 appointment classique accepted by recruiter
      if (!_event.skipAppointmentValidation && includes([INTERACTION_TYPE.informal1to1], interaction.type) && status === 'pending' && interaction._appointment.acceptedAt
      ) {
        const applicationDate = moment(interaction._appointment.acceptedAt);

        // appointment pending
        interactions.push({
          time: applicationDate.unix(),
          date: applicationDate.fromNow(),
          icon: 'iconAccept',
          subtitle: <>
            <FormattedMessage
              id="activity.informal1to1.classic.accepted.by.recruiter"
              values={messageValues} />
          </>,
        });
      }

      // informal1to1 application
      if (includes([INTERACTION_TYPE.informal1to1], interaction.type) && !isTransferred) {
        const applicationDate = moment(interaction._appointment.acceptedAt);

        if (!_event.skipAppointmentValidation && status === 'pending' && isEmpty(interaction._appointment.acceptedAt)) {
          // appointment pending
          interactions.push({
            time: applicationDate.unix(),
            date: applicationDate.fromNow(),
            icon: 'proposition',
            subtitle: <>
              <FormattedMessage
                id="activity.informal1to1.application"
                values={messageValues} />
            </>,
          });
        }

        if (_event.skipAppointmentValidation && ['pending', 'unconfirmed'].includes(status)) {
          // appointment unconfirmed
          interactions.push({
            time: applicationDate.unix(),
            date: applicationDate.fromNow(),
            icon: 'proposition',
            subtitle: <>
              <FormattedMessage
                id="activity.informal1to1.unconfirmed"
                values={messageValues} />
            </>,
          });
        }
      }

      // informal1to1 appointment refused
      if (includes([INTERACTION_TYPE.informal1to1], interaction.type) && ['refused'].includes(status)) {
        const applicationDate = moment(interaction._appointment.acceptedAt);

        // appointment pending
        interactions.push({
          time: applicationDate.unix(),
          date: applicationDate.fromNow(),
          icon: 'refuse',
          subtitle: <>
            <FormattedMessage
              id={`activity.informal1to1.refused.by.${interaction._appointment.refusedBy}`}
              values={messageValues} />
          </>,
        });
      }

      // informal1to1 appointment canceled by candidate or recruiter
      if (includes([INTERACTION_TYPE.informal1to1], interaction.type) && ['canceled'].includes(status)) {
        const applicationDate = moment(interaction._appointment.acceptedAt);

        interactions.push({
          time: applicationDate.unix(),
          date: applicationDate.fromNow(),
          icon: 'refuse',
          subtitle: <>
            <FormattedMessage
              id={`activity.informal1to1.canceled.by.${interaction._appointment.canceledBy}`}
              values={messageValues} />
          </>,
        });
      }
      // informal1to1 appointment canceled by candidate or recruiter
      if (includes([INTERACTION_TYPE.informal1to1], interaction.type) && status === 'pending' && isTransferred) {
        const applicationDate = moment(interaction._appointment.acceptedAt);

        interactions.push({
          time: applicationDate.unix(),
          date: applicationDate.fromNow(),
          icon: 'proposition',
          subtitle: <>
            <FormattedMessage
              id="activity.informal1to1.transfered"
              values={messageValues} />
          </>,
        });
      }


      // application  refused
      if (includes(['proposition', 'application'], interaction.type) && (isEmpty(status) && !isNull(interaction.messageRefused))) {
        const applicationDate = moment(interaction.messageUpdatedAt);

        interactions.push({
          time: applicationDate.unix(),
          date: applicationDate.fromNow(),
          icon: 'refuse',
          subtitle: <>
            <FormattedMessage
              id="activity.application.refused.by.recruiter"
              values={messageValues}
            />
            {getOfferTitle(interaction)}
          </>,
        });
      }

      // appointment transferred
      if (includes(['proposition', 'application'], interaction.type) && status === 'pending' && isTransferred) {
        const applicationDate = moment(interaction._appointment.updatedAt);

        interactions.push({
          time: applicationDate.unix(),
          date: applicationDate.fromNow(),
          icon: 'proposition',
          subtitle:
            <>
              <FormattedMessage
                id="activity.transferred"
                values={messageValues}
              />
              {getOfferTitle(interaction)}
            </>,
        });
      }

// renderAppointmentPropositionPending
      if (includes(['proposition'], interaction.type) && status === 'pending' && !isTransferred) {
        const applicationDate = moment(interaction._appointment.updatedAt);

        // appointment pending
        interactions.push({
          time: applicationDate.unix(),
          date: applicationDate.fromNow(),
          icon: 'proposition',
          subtitle: <>
            <FormattedMessage
              id="activity.proposition"
              values={messageValues} />
            {getOfferTitle(interaction)}
          </>,
        });
      }

// renderAppointmentApplicationPending
      if (includes(['application'], interaction.type) && status === 'pending') {
        const applicationDate = moment(interaction._appointment.updatedAt);

        // appointment pending - application
        interactions.push({
          time: applicationDate.unix(),
          date: applicationDate.fromNow(),
          icon: 'proposition',
          subtitle: <>
            <FormattedMessage
              id="activity.application.accepted.by.recruiter"
              values={messageValues} />
            {getOfferTitle(interaction)}
          </>,
        });
      }

// renderAppointmentAccepted
      if (includes(['proposition', 'application'], interaction.type)
        && status === 'accepted') {
        const applicationDate = moment(interaction._appointment.updatedAt);

        interactions.push({
          time: applicationDate.unix(),
          date: applicationDate.fromNow(),
          title: '',
          icon: 'iconAccept',
          subtitle: <>
            <FormattedMessage
              id="activity.appointment.accepted"
              values={messageValues} />
            {getOfferTitle(interaction)}
          </>,
        });
      }

// Appointment unconfirmed
      if (includes(['application-auto', 'application'], interaction.type)
        && status === 'unconfirmed') {
        const applicationDate = moment(interaction._appointment.updatedAt);

        // accepted status
        interactions.push({
          time: applicationDate.unix(),
          date: applicationDate.fromNow(),
          title: '',
          icon: 'proposition',
          subtitle:
            <>
              <FormattedMessage
                id="activity.appointment.unconfirmed"
                values={messageValues} />
              {getOfferTitle(interaction)}
            </>,
        });
      }

      // appointment refused
      if (includes(['proposition', 'application'], interaction.type)
        && includes(['canceled', 'refused'], status)) {
        const refuseDate = moment(interaction._appointment.refusedAt);
        const { status } = interaction._appointment;
        const actionOwner = interaction._appointment[`${status}By`] || 'recruiter';


        interactions.push({
          icon: 'refuse',
          time: refuseDate.unix(),
          date: refuseDate.fromNow(),
          title: '',
          subtitle: <>
            <FormattedMessage
              id={`activity.${status}.by.${actionOwner}`}
              values={messageValues} />
            {getOfferTitle(interaction)}
          </>,
        });
      }

      if (interaction.type !== 'application' && !isEmpty(object.subtitle)) { interactions.push(object); }

    });

    return interactions.sort((a, b) => a.time < b.time ? 1 : -1);
  };


  const timelineItems = computeInteractions();
  const classNames = classnames(styles.timeline, timelineItems.length < 4 ? styles.smallTimeline : '');

  return (
    <div className={styles.activity}>
      <h5 className="profile-section-title">{t({ id: 'activity.title' })}</h5>
      {timelineItems.length > 0 ? <div className={classNames}>
          {timelineItems.length > 3 ? (
            <ul className="ant-timeline">
              <li className="ant-timeline-item first">
                <div className="ant-timeline-item-tail" />
                <div
                  className="ant-timeline-item-head btn-scroll"
                  onClick={() => handleScroll('top')}
                  role="button"
                  tabIndex={0}
                >
                  <Icon name="arrow-up" />
                </div>
              </li>
            </ul>
          ) : ''}

          <Element name="container" className={classnames(styles.timelineContainer, 'element')} id={containerId} style={{ height: '100%' }}>
            <Timeline className={styles.timelineContent}>
              {timelineItems.map((item, index) => (
                <Timeline.Item
                  key={index}
                  className={styles[item.icon]}
                  color="default"
                  dot={item.icon ? (<Icon className={styles[item.icon]} name={item.icon} />) : null}
                >
                  <div className={styles.interaction}><strong>{item.title}</strong> {item.subtitle}</div>
                  <div className={styles.date}>{`${item.date.charAt(0).toUpperCase()}${item.date.slice(1)}`}</div>
                </Timeline.Item>
              ))}
            </Timeline>
          </Element>

          {timelineItems.length > 3 ? (
            <ul className="ant-timeline" style={{ marginTop: '-5px' }}>
              <li className="ant-timeline-item last">
                <div className="ant-timeline-item-tail" />
                <div
                  className="ant-timeline-item-head btn-scroll"
                  onClick={() => handleScroll('bottom')}
                  role="button"
                  tabIndex={0}
                >
                  <Icon name="arrow-down" />
                </div>
              </li>
            </ul>
          ) : ''}
        </div>
        : (
          <span className={styles.blankState}>
              {t({ id: 'activity.blankState'})}
            </span>)
      }
    </div>
  );
}

Activity.propTypes = {
  interactions: array,
  participant: object,
  organization: object,
  intl: object,
};

export default injectIntl(Activity);
