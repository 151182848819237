import * as React from 'react';
import { FormattedHTMLMessage } from "react-intl";
import { PHASES, PHASE_STATE } from "../utils/const";

export default {
  isAvailable: [
    [
      {
        phase: PHASES.NONE,
        state: PHASE_STATE.NONE
      }
    ],
    [
      {
        phase: PHASES.DISCOVERING,
        state: PHASE_STATE.IS_UPCOMING,
      },
    ]
  ],
  generateActions: () => ({
    getText: () => <FormattedHTMLMessage id="event.deletion.cta" />
  })
}
