import React from 'react';
import { object } from 'prop-types';
import { H3 } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import BlankState from '@/components/BlankState';
import Wrapper from '@/components/Wrapper';

export const BlankNoParticipation = ({
  selectedUser
}) => {
  const t = useFormatMessage();

  return (
    <Wrapper>
      <BlankState
        largeIcon
        style={{ marginTop: '80px' }}
        icon="blank-state-cactus"
      >
        <H3>
          {t({ id: 'calendar.slot.blankExponentUser' }, { recruiter: selectedUser?.fullName })}
        </H3>
      </BlankState>
    </Wrapper>
  )
}

BlankNoParticipation.propTypes = {
  selectedUser: object
}
