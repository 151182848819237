import React from 'react';
import { H6, Body1 } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import moment from 'moment';
import { isEmpty } from 'lodash';

export const Educations = ({ participant }) => {
  if (isEmpty(participant._user.educations)) { return null }

  const t = useFormatMessage();

  function getPeriodDate (education) {
    const isCurrentStr = education.isCurrent ? `(${t({ id: 'current' })})` : ''
    if (moment(education.beginAt).year() === moment(education.endAt).year()) {
      return `${moment(education.beginAt).format('YYYY')} ${isCurrentStr}`;
    }
    return `${moment(education.beginAt).format('YYYY')} - ${moment(education.endAt).format('YYYY')} ${isCurrentStr}`
  }

  return (
    <div>
      <H6 className="text-neutral-500 mb-8">{t({ id: 'educations' })}</H6>
      {participant._user.educations.map((education) =>
        <Body1 className="text-neutral-400" key={education.title}>
          {education.title}
          <span className="text-neutral-500 mx-5"> {`@${education._organization.name}`}</span>
          {getPeriodDate(education)}
        </Body1>
      )}
    </div>
  )
}