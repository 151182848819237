import moment from 'moment';
import { isEmpty } from 'lodash';
import { isKeyDateAPeriod } from '@/helpers/event/isKeyDateAPeriod';

/**
 * Class for transform data from the API to the form
 */
export default class ApiToForm {
  /**
   * Return new Array with all organizations
   *
   * @param {Array} organizations
   * @param {string} type
   *
   * @returns {Array}
   */
  static organizations = (organizations = [], type = 'ids') => organizations
    .reduce((acc, organization) => {
      if (type === 'ids') {
        acc.push(organization._id || organization);
      } else {
        acc.push({
          key: organization._id,
          label: organization.name,
          title: organization.name,
        });
      }
      return acc;
    }, []);

  /**
   * Return new Array with all modules enable
   *
   * @param {Array|object} eventModules
   *
   * @returns {Array}
   */
  static modules = (eventModules) => {
    const modules = ['jobdating', 'live', 'hunt', 'feedback', 'offer'];

    // POST
    if (isEmpty(eventModules)) {
      return modules;
    }

    // PATCH
    return Object
      .keys(eventModules)
      .reduce((acc, moduleKey) => {
        if (eventModules[moduleKey].enable) {
          acc.push(moduleKey);
        }
        return acc;
      }, []);
  };

  /**
   * Return array with the beginAt and endAt for a specific keyDate
   * @description Convert all date in jobdating timezone to local timezone
   *
   * @param {Array} keyDates
   * @param {string} keyName
   * @param {string} type
   * @param {string} timezone
   */
  static keyDates = (keyDates = {}, keyName, type = 'range', timezone) => {
    if (isEmpty(keyDates[keyName])) return type === 'range' ? [] : null;

    return type === 'range' ? [
      moment.tz(keyDates[keyName].beginAt, timezone),
      moment.tz(keyDates[keyName].endAt, timezone),
    ] : moment.tz(keyDates[keyName].beginAt, timezone);
  };

  /**
   * Localization
   */
  static localizationType = (location = {}) => isEmpty(location) ? 'virtual' : 'physique';

  static localizationObject = (location = {}) => isEmpty(location) ? {} : location;

  static localizationName = (location = {}) => isEmpty(location) ? '' : location.formattedAdress;
}

/**
 * Key moments are for Hybrid forums
 * 
 * XXX: For now, moments have strictly one of each format max. See API schema for more information
 */
export function keyMomentsApiToForm(event, momentName, momentFormat) {
  if (!event.keyMoments) {
    return undefined;
  }
  if (isEmpty(event.keyMoments[momentName].find(keyMoment => keyMoment.format === momentFormat))) {
    return undefined;
  }

  const { beginAt, endAt } = event.keyMoments[momentName].find(keyMoment => keyMoment.format === momentFormat);

  if (isKeyDateAPeriod({ beginAt, endAt })) return [moment(beginAt), moment(endAt)];

  return moment(beginAt);
}
