import React, {useState } from 'react';
import { object, string, func, array } from 'prop-types';

import moment from 'moment';
import { AlignLeft } from 'react-feather';
import { Popover } from 'antd';
import AppointmentDetails from './AppointmentDetails';
import Details from './Details';
import styles from './styles.less';
import EditInformal1to1Description from '../EditInformal1to1Description/EditInformal1to1Description';
import ConferenceDetails from './ConferenceDetails';
import { Body1, Caption1, IconMapPin } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';

const TimeslotDetails =({
  event,
  appointmentColor,
  timezone,
  closePopover,
  jobfairs,
  handleOnInformalDescriptionChange,
  informalDescription,
  currentEvent,
}) => {
  const t = useFormatMessage();
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const appointment = event._appointment;
  const conference = event._conference;
  const appointmentStart = moment.tz(event.start, timezone);
  const appointmentEnd = moment.tz(event.end, timezone);

  /**
   * Handle popover visibility
   * @param {Boolean} visibility
   */
   const handleTogglePopover = (visibility = false) => {
    setIsPopoverVisible(visibility);
  };

  if (appointment) {
    return <AppointmentDetails
      event={event}
      appointmentColor={appointmentColor}
      timezone={timezone}
      closePopover={closePopover}
      handleOnInformalDescriptionChange={handleOnInformalDescriptionChange}
      informalDescription={informalDescription}
    />
  }

  if (conference) {
    return <ConferenceDetails
      event={event}
      jobfairs={jobfairs}
      appointmentColor={appointmentColor}
      timezone={timezone}
      closePopover={closePopover}
      handleOnInformalDescriptionChange={handleOnInformalDescriptionChange}
      informalDescription={informalDescription}
      currentEvent={currentEvent}
    />
  }

  return (
    <Popover
      placement="right"
      content={
        <EditInformal1to1Description description={event.informalDescription} closePopover={() => handleTogglePopover(false)} event={event} handleOnInformalDescriptionChange={handleOnInformalDescriptionChange} isPopoverVisible={isPopoverVisible}/>
      }
      visible={isPopoverVisible}
      onVisibleChange={handleTogglePopover}
      trigger="click"
      zIndex={1000} // mandatory because the Agenda Modal is in the hundreds
    >
      <Details type={event.type} closePopover={closePopover}>
        {/* FIXME: Dirty hack for now, we only show events color for dedicated timeslots */}
        <Body1 className="text-neutral-400">
          <strong className="mr-4">{appointmentStart.format('dddd DD MMMM')} {t({ id: 'time.at' })}</strong>
          {appointmentStart.format('HH:mm')} - {appointmentEnd.format('HH:mm')}
        </Body1>
        { event.eventName && (
          <div className="mt-8 flex">
            <div className={styles.colorBadge} style={{ backgroundColor: appointmentColor }} />
            <Caption1 className="text-neutral-500">{event.eventName}</Caption1>
          </div>
        )}
        {
          event.medium === 'physical' && (
            <div className={styles.row}>
              <div>
                <IconMapPin className="text-neutral-300" size={12} />
              </div>
              <div>
                <Body1 className="text-neutral-500">{event.location}</Body1>
              </div>
            </div>
          )
        }
        {
          event.type === "informal1to1" && (
            <>
              <div className={styles.row}>
                <div>
                  <AlignLeft styles={styles.icon} style={{ width: 16, height: 16 }} />
                </div>
                <div>
                  <p>{informalDescription || event.informalDescription}</p>
                </div>
              </div>
              <div className={styles.editDesciptionBtnContainer}>
                <button className={styles.editDesciptionBtn} onClick={() => handleTogglePopover(true)}>Modifier la description</button>
              </div>
            </>
          )
        }
      </Details>
    </Popover>
  )
}

TimeslotDetails.propTypes = {
  event: object.isRequired,
  appointmentColor: string.isRequired,
  timezone: string.isRequired,
  closePopover: func.isRequired,
  jobfairs: array.isRequired,
  handleOnInformalDescriptionChange: func,
  informalDescription: string,
  currentEvent: object,
};

export default TimeslotDetails;
