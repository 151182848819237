import React from 'react';
import { object, func } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { toJS } from '@/utils';

// Store
import { eventActions, eventSelectors } from '@/store/event';

// Components
import { injectIntl } from 'react-intl';
import Form from './components/Form';

// Services
import FormToApi from '../../services/formMapping/FormToApi';
import styles from '../../../../styles.less';

class LivetalkScene extends React.PureComponent {
  propTypes = {
    patchEvent: func.isRequired,
    event: object,
    intl: object,
  }

  handleOnSubmit = (err, values, moduleName) => {
    const { event: { modules }, patchEvent } = this.props;

    if (!err) {
      const eventParams = {
        modules: FormToApi.modules(modules, moduleName, values),
      };

      patchEvent({
        event: eventParams,
        notificationParams: {
          success: {
            message: this.props.intl.formatMessage({ id: 'notifications.update.success' }),
            kind: 'info',
            style: {
              bottom: '5%',
              top: 'inherit',
            },
          },
        }
      });
    }
  };

  render() {
    const { event } = this.props;

    return (
      <div style={{ width: '100%', flex: 1, paddingTop: '20px' }}>
        <div className={styles.formWrapper}>
          <Form
            initialValues={event}
            onSubmit={(err, values) => this.handleOnSubmit(err, values, 'live')}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  event: eventSelectors.getCurrentEvent,
});

const mapDispatchToProps = {
  patchEvent: eventActions.patchEvent,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  injectIntl,
  toJS,
)(LivetalkScene);
