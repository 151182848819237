import { defineMessages } from 'react-intl';

export default defineMessages({
  showOpportunities: {
    id: 'event.candidate.exponent.showOpportunities',
  },
  showStand: {
    id: 'event.candidate.exponent.showStand',
  },
  knowMore: {
    id: 'knowMore',
  },
  matchOpportunities: {
    id: 'event.candidate.exponent.matchOpportunities',
  },
});
