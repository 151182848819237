import React from 'react';
import { object, string } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { CardInfo, Link1 } from '@seekube-tech/ui-kit';
import Icon from '@/components/Icon';
import { trackCandidateClickedViewKeyDates } from '@/utils/analytics';
import { CANDIDATE_JD_VIEWS } from '@/utils/constants';

const ApplicationsDisabled = ({ authUser, href, currentView }) => {
  const t = useFormatMessage();

  return (
    <CardInfo
      isClosable={false}
      title={t({ id: 'candidate.infoCard.application.date.is.past.title' })}
      description={ t({ id: [CANDIDATE_JD_VIEWS.informal1to1, CANDIDATE_JD_VIEWS.informal1to1s].includes(currentView) ? 'candidate.infoCard.interaction.date.is.past.description.informal1to1' : 'candidate.infoCard.application.date.is.past.description' })}
      icon={<Icon style={{ width: '100%', height: '100%' }} name="illuHourglassEnd" />}
      visible
      action={href ? (<Link1
        onClick={() => trackCandidateClickedViewKeyDates({ authUser })}
        href={href} target="_blank">{t({ id: 'see.keyDates' })}
      </Link1>) :null}
    />
  );
}

ApplicationsDisabled.propTypes = {
  authUser: object,
  href: string,
  currentView: string,
}

export { ApplicationsDisabled }
