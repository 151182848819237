import React from 'react';
import { string, object, number } from 'prop-types';
import Card from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Exponents/scenes/Exponent/components/Card';
import styles from "@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Exponents/scenes/Exponent/components/Home/Sidebar/Cards/styles.less";
import CardInformal1to1 from './CardInformal1to1'
import CardInformal1to1Footer from './CardInformal1to1Footer';

const CardInformal1to1s = ({
  informal1to1s,
  informal1to1s_count,
  link,
  profile,
  labels
}) => <Card>
    {informal1to1s.map((chat, index) => (
      <div key={chat._id}>
        <CardInformal1to1 informal1to1={chat}/>
        {index < informal1to1s.length - 1 ? <hr className={styles.separator}/> : <></>}
      </div>
    ))}
    <CardInformal1to1Footer
      link={link}
      informal1to1_count={informal1to1s_count}
      organizationName={profile?.name}
      labels={labels}
    />
  </Card>

CardInformal1to1s.propTypes = {
  informal1to1s: object,
  informal1to1s_count: number,
  link: string,
  profile: object,
  labels: object
}

export default CardInformal1to1s;
