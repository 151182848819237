import { useMutation, useQueryClient } from 'react-query';
import { map } from 'lodash';
import request from '@/utils/request';

export function useUpdatePost(
  {
    onSuccess = () => {},
    eventId,
    page,
  }) {
  const queryClient = useQueryClient();

  const mutationFn = ({ _id, activityToUpdate }) => {
    const requestUrl = `${process.env.FRONT_API_URL}/posts/${_id}`;

    return (
      request(requestUrl, {
        method: 'PATCH',
        body: JSON.stringify(activityToUpdate),
      })
    )
  }

  return useMutation({
    mutationFn,
    onMutate: async (post) => {
      const posts = queryClient.getQueryData(['owner', 'dashboard', 'posts', eventId, page]);

      const newPosts = map(posts, (prevPost) => prevPost._id === post._id ? post : prevPost);

      queryClient.setQueryData(['owner', 'dashboard', 'posts', eventId, page], newPosts);

      return post;
    },
    onSuccess
  });
}
