import React from 'react'
import { number } from 'prop-types';
import { Typography } from '@seekube-tech/ui'
import {
  ArrowDownRight as IconArrowDownRight,
  ArrowUpRight as IconArrowUpRight,
} from 'react-feather'

import useStyles from './styles'

const Progress = ({ count }) => {
  const classes = useStyles();

  if (count === 0 || !Number.isInteger(count)) return null;

  if (count > 0) {
    return (
      <div className={classes.increase}>
        <IconArrowUpRight />
        <Typography variant="caption1">
          {count}%
        </Typography>
      </div>
    )
  }

  return(
    <div className={classes.decrease}>
      <IconArrowDownRight />
      <Typography variant="caption1">
        {Math.abs(count)}%
      </Typography>
    </div>
  )
}

Progress.propTypes = {
  count: number
}

export { Progress }