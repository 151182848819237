import * as React from 'react';
import { isEmpty } from 'lodash';
import moment from 'moment/moment';
import { object } from 'prop-types';
import { IconClock } from '@seekube-tech/ui-kit';
import { FormattedHTMLMessage } from 'react-intl';

function CandidateApplicationsClosing({ event }) {
  const { keyDates } = event;
  const keydate = keyDates?.applicationsClosing?.beginAt || keyDates?.interactionsClosing?.beginAt;
  const date = moment(keyDates?.applicationsClosing?.beginAt || keyDates?.interactionsClosing?.beginAt)
  const beginAtMonth = date.format('MMM').slice(0, 3);
  const beginAt = <strong>{date.format('D')}</strong>;

  return (!isEmpty(keydate)) && (
    <span key="applicationsClosing">
      <IconClock size={16} />
      <FormattedHTMLMessage
        id="events.stats.candidate.applications.available.until" />  {beginAt} {beginAtMonth}
    </span>
  );
}

CandidateApplicationsClosing.propTypes = {
  event: object
}

export default CandidateApplicationsClosing;
