import React from 'react';
import { node, string, func, any, oneOf, oneOfType, object, bool } from 'prop-types';
import { isUndefined, isEmpty } from 'lodash';
import classnames from 'classnames';

// components
import { Button } from '@seekube-tech/ui-kit';
import { H1 } from '@/components/Title';
import P from '@/components/P';
import ButtonInline from '@/components/ButtonInline';
import Icon from '@/components/Icon';

import styles from './styles.less';

const BlankState = ({ icon, title, content, children, largeIcon, buttonLabel, handleAction, buttonType, style, customIcon, className }) => (
  <div className={classnames(styles.blankStateWrapper, className, largeIcon ? styles.largeIcon : null)} style={style}>
    {icon ? <Icon name={icon} /> : customIcon}

    {!isUndefined(title) ? (<H1 bold>{title}</H1>) : ''}

    <P>{content}</P>

    {
      !isEmpty(buttonLabel) && buttonType === 'default' ?
        (<Button className="inline-flex" onClick={handleAction}>{buttonLabel}</Button>) : ''
    }

    {
      !isEmpty(buttonLabel) && buttonType === 'inline' ?
        (<ButtonInline onClick={handleAction} text={buttonLabel} className={styles.btnInlineCenter} icon="" />) : ''
    }

    <div className={styles.extraContent}>{children}</div>
  </div>
);

BlankState.propTypes = {
  title: any,
  content: any,
  customIcon: any,
  children: node,
  largeIcon: bool,
  icon: string,
  style: object,
  handleAction: func,
  buttonLabel: oneOfType([string, node]),
  buttonType: oneOf(['default', 'inline']),
  className: string,
};

BlankState.defaultProps = {
  buttonType: 'default',
  content: '',
  style: {},
  icon: '',
};

export default BlankState;
