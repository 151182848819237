import { useQuery } from 'react-query';
import request from '@/utils/request';

export function useGetAppointmentsMade({ eventId, view }) {
  const requestUrl = `${process.env.FRONT_API_URL}/statistics/kpi/appointments?eventId=${eventId}&eventFormat=${view}`;

  const queryFn = () => request(requestUrl, { method: 'GET' });

  const query = useQuery({
    queryKey: ['stats', 'kpi', 'appointments', eventId, view],
    queryFn,
    refetchOnWindowFocus: false,
    cacheTime: 3000,
    refetchInterval: 900000,
  });
  return query;
}
