import { EVENT_FORMAT, DASHBOARD_MODE } from '@/utils/constants';
import { PHASES, PHASE_STATE } from '../../utils/const';
import {
  getEventPhases,
  isEventInProgress,
} from '../../utils/event';

export function getNewDashboardMode({
  event,
  view,
  dashboardMode
}) {
  const eventPhases = getEventPhases(event);

  // Both phases ongoing
  if (isEventInProgress(eventPhases, EVENT_FORMAT.PHYSICAL) && isEventInProgress(eventPhases, EVENT_FORMAT.VIRTUAL)) {
    if (dashboardMode === DASHBOARD_MODE.DYNAMIC) {
      return DASHBOARD_MODE.DYNAMIC
    }
    return DASHBOARD_MODE.STATIC
  }
  // Physical Phase Only
  if (isEventInProgress(eventPhases, EVENT_FORMAT.PHYSICAL)) {
    if (dashboardMode === DASHBOARD_MODE.DYNAMIC && view === EVENT_FORMAT.PHYSICAL) {
      return DASHBOARD_MODE.DYNAMIC
    }
    return DASHBOARD_MODE.STATIC
  }
  // Virtual Phase Only
  if (isEventInProgress(eventPhases, EVENT_FORMAT.VIRTUAL)) {
    if (dashboardMode === DASHBOARD_MODE.DYNAMIC && view === EVENT_FORMAT.VIRTUAL) {
      return DASHBOARD_MODE.DYNAMIC
    }
    return DASHBOARD_MODE.STATIC
  }
  return DASHBOARD_MODE.STATIC
}

export function isLinkVisible({
  event,
  view,
}) {
  const eventPhases = getEventPhases(event);

  // Both phases ongoing
  if (isEventInProgress(eventPhases, EVENT_FORMAT.PHYSICAL) && isEventInProgress(eventPhases, EVENT_FORMAT.VIRTUAL)) {
    return true;
  }
  // Physical Phase Only
  if (isEventInProgress(eventPhases, EVENT_FORMAT.PHYSICAL)) {
    if (view === EVENT_FORMAT.VIRTUAL) {
      return false
    }
    return true
  }
  // Virtual Phase Only
  if (isEventInProgress(eventPhases, EVENT_FORMAT.VIRTUAL)) {
    if (view === EVENT_FORMAT.PHYSICAL) {
      return false
    }
    return true
  }
  return false
}

/**
 * Do not forget to specify strict function params (instead of destructuring) to avoid issues when calling
 */
export default {
  isAvailable: [
    [
      {
        phase: PHASES.ALL,
        state: PHASE_STATE.ALL
      },
    ],
  ],
  generateActions: () => ({
      getNewDashboardMode: ({ event, view, dashboardMode }) => getNewDashboardMode({ event, view, dashboardMode }),
      isLinkVisible: ({ event, view }) => isLinkVisible({ event, view }),
    })
}
