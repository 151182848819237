import React from 'react';
import { FormattedMessage } from 'react-intl';
import BlankState from '@/components/BlankState';

import styles from './styles.less';
import messages from './messages';


export const PageBlank = () => (
  <div className={styles.pageBlank}>
    <BlankState
      className={styles.cactus}
      content={<FormattedMessage {...messages.offerBadSearch} />}
      icon="blank-state-cactus"
    />
  </div>
);
