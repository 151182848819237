import React from 'react';
import {
  EmailSentSuccessFully
} from '@/scenes/ResetPassword/components/ResetPasswordModal/components/Email/EmailSentSuccessfully';
import EmailForm from '@/scenes/ResetPassword/components/ResetPasswordModal/components/Email/EmailForm';
import { usePostResetPassword } from '@/queries/users/usePostResetPassword';


const Email = () => {
  const resetPasswordQuery = usePostResetPassword();

  return (
    resetPasswordQuery.data?.status === 'ok' ?
      <EmailSentSuccessFully />
      : <EmailForm resetPasswordQuery={resetPasswordQuery} />
  );
}


export { Email };
