import { defineMessages } from 'react-intl';

export default defineMessages({
  modificationSuccess: {
    id: 'toaster.edit.success',
  },
  appointmentConfirm: {
    id: 'appointment.confirm',
  },
  profileConfirmed: {
    id: 'profile.confirmed',
  },
  scoreConfirmed: {
    id: 'score.confirmed',
  },
  addToSelectionConfirmed: {
    id: 'add.to.selection.confirmed',
  },
  profileHide: {
    id: 'profile.hide',
  },
  profileSelectionConfirmed: {
    id: 'profile.selection.confirmed',
  },
  blankStateApplicationsManageTitle: {
    id: 'blankState.applications.manage.title',
  },
  blankStateApplicationsManageContent: {
    id: 'blankState.applications.manage.content',
  },
  blankStateApplicationsManageBtn: {
    id: 'blankState.applications.manage.btn',
  },
  blankStateApplicationsEmptyTitle: {
    id: 'blankState.applications.empty.title',
  },
  blankStateApplicationsEmptyContent: {
    id: 'blankState.applications.empty.content',
  },
  blankStateApplicationsEmptyBtn: {
    id: 'blankState.applications.empty.btn',
  },
  blankStateTitle: {
    id: 'blankState.all.title',
  },
  blankStateSearchTitle: {
    id: 'blankState.search.title',
  },
  blankStateSearchContentEmptySearch: {
    id: 'blankState.search.content.empty-search',
  },
  blankStateSearchContentBadSearch: {
    id: 'blankState.search.content.bad-search',
  },
  blankStateSourcingManageTitle: {
    id: 'blankState.sourcing.manage.title',
  },
  blankStateSourcingManageContent: {
    id: 'blankState.sourcing.manage.content',
  },
  blankStateSourcingManageBtn: {
    id: 'blankState.sourcing.manage.btn',
  },
  blankStateSaveManageTitle: {
    id: 'blankState.save.manage.title',
  },
  blankStateSaveManageContent: {
    id: 'blankState.save.manage.content',
  },
  blankStateSaveManageBtn: {
    id: 'blankState.save.manage.btn',
  },
  blankStateInterviewManageTitle: {
    id: 'blankState.interview.manage.title',
  },
  blankStateInterviewManageContent: {
    id: 'blankState.interview.manage.content',
  },
  blankStateInterviewManageBtn: {
    id: 'blankState.interview.manage.btn',
  },
  blankStateTopManageTitle: {
    id: 'blankState.top.manage.title',
  },
  blankStateTopManageContent: {
    id: 'blankState.top.manage.content',
  },
  blankStateTopManageBtn: {
    id: 'blankState.top.manage.btn',
  },
  blankStateRefuseManageTitle: {
    id: 'blankState.refuse.manage.title',
  },
  blankStateRefuseManageContent: {
    id: 'blankState.refuse.manage.content',
  },
  blankStateRefuseManageBtn: {
    id: 'blankState.refuse.manage.btn',
  },
  offerDuplicateExisting: {
    id: 'offer.duplicate.existing',
  },
  searchByKeyword: {
    id: 'search.by.keyword',
  },
  participantSelectAllBtn: {
    id: 'participant.select.all.btn',
  },
  participantSelectAllContent: {
    id: 'participant.select.all.content',
  },
  countNewSourcing: {
    id: 'participants.countNew',
  },
  countNewApplications: {
    id: 'applicants.countNew',
  },
  cancel: {
    id: 'cancel',
  },
  candidates: {
    id: 'event.owner.candidates',
  },
  errorCannotBanParticipant: {
    id: 'event.owner.candidates.cannotBan.modal.title',
  },
  syncLoader: {
    id: 'synchronization.loader',
  },
  moveConfirm: {
    id: 'modal.groupedActions.move.text.c',
  },
  moveConfirmTitle2: {
    id: 'modal.groupedActions.move.title.b',
  },
  moveConfirmTitle: {
    id: 'modal.groupedActions.move.title',
  },
  hideConfirm: {
    id: 'modal.groupedActions.hide.text',
  },
  hideConfirmTitle: {
    id: 'modal.groupedActions.hide.title',
  },
  move_top: {
    id: 'recruiter.participantsList.add.to.top',
  },
  move_refuse: {
    id: 'recruiter.participantsList.add.to.refuse',
  },
  move_save: {
    id: 'recruiter.participantsList.add.to.save',
  },
  move_interview: {
    id: 'recruiter.participantsList.add.to.interview',
  },
  multipleLoader: {
    id: 'talent.multipleLoader',
  },
  interactionClosedAlert: {
    id: 'recruiter.participantsView.interactionsClosed.alert',
  },
  notDisplayMessage: {
    id: 'do.not.display.message',
  },
});
