
import { call, put, takeLatest } from 'redux-saga/effects';
import request from '@/utils/request';
import * as actions from './actions';
import * as types from './types';
import { trackError } from '../../utils/analytics/helpers';

/**
 * GET events in progress v1Kpi
 */
function* getEvents() {
  const requestUrl = `${process.env.FRONT_API_URL}/kpi/v1/events`;

  try {
    const result = yield call(request, requestUrl);

    // Save entities
    yield put(actions.getEventsSuccess(result));
  } catch (err) {
    trackError(err);
  }
}

/**
 * GET events in progress v1Kpi
 */
function* getContacts() {
  const requestUrl = `${process.env.FRONT_API_URL}/kpi/v1/contacts`;

  try {
    const result = yield call(request, requestUrl);

    // Save entities
    yield put(actions.getContactsSuccess(result));
  } catch (err) {
    trackError(err);
  }
}

/**
 * Listen Actions
 */
export default [
  takeLatest(types.GET_EVENTS, getEvents),
  takeLatest(types.GET_CONTACTS, getContacts),
];

