import React from 'react';
import { bool, func, object } from 'prop-types';
import { Field } from 'react-final-form';
import { Tree } from '@seekube-tech/ui';
import { createStructuredSelector } from 'reselect';
import { authSelectors } from '@/store/auth';
import { connect } from 'react-redux';
import { compose } from 'redux';

import useValidations from '@/utils/validations/useValidations';
import { getLocale } from '@/utils/localStorage';
import { LOCALES } from '@/utils/constants';

import Icon from '@/components/Icon';


import { toJS } from '@/utils';
import styles from '../../../styles.less';
import { getCriterionLabel } from '../utils';

function PositionField({ criterion, showHelper, authUser, disabled = false, required = true }) {
  /* Vars */

  const { isFilled } = useValidations();
  const locale = getLocale();

  /* Functions */

  /**
   * Transform options for Tree field
   * @returns {Array<Object>}
   */
  const getOptions = () => {
    const { _choices } = criterion;

    const choices = _choices.filter((c) => c?.enable);

    let hashMap = {};
    const childrens = [];

    choices.forEach((choice) => {
      if (choice._parent) {
        childrens.push(choice);
      } else {
        hashMap = {
          ...hashMap,
          [choice._id]: {
            disabled: true,
            label: authUser.locale === LOCALES.en && choice.label_en ? choice.label_en : choice.label,
            id: choice._id,
            childrens: [],
          },
        };
      }
    });

    childrens.forEach((child) => {
      if (hashMap[child._parent]) {
        hashMap[child._parent].childrens.push({
          disabled: child.enable === false,
          label: authUser.locale === LOCALES.en && child.label_en ? child.label_en : child.label,
          id: child._id,
        });
      }
    });

    return Object.values(hashMap);
  };

  /* Derived vars */

  const label = getCriterionLabel(criterion);

  /* Render */

  return (
    <div className={styles.marginTop}>
      <Field
        label={label}
        name={criterion.key}
        id={criterion.key}
        disabled={disabled}
        component={Tree}
        fullWidth
        required={required}
        options={getOptions()}
        defaultExpanded
        getOptionValue={option => option.id}
        icon={<Icon name={criterion.icon} />}
        validate={value => isFilled(value)}
        showHelper={(id) => showHelper(id, label)}
      />
    </div>
  );
}

PositionField.propTypes = {
  criterion: object,
  showHelper: func,
  authUser: object,
  disabled: bool
};

PositionField.defaultProps = {
  criterion: {},
};

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser
})

const mapDispatchToProps = {};

const withConnect = connect(mapStateToProps, mapDispatchToProps);


export default compose(
  withConnect
)(toJS(PositionField));
