import React from 'react';
import { Button, Popover, PopoverTrigger, PopoverContent, Tooltip, TooltipTrigger, TooltipContent, IconChevronDown, Body1, Caption1, IconCheck } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { string, func, object } from 'prop-types';
import { useLocation } from 'react-router';
import { getLocaleName, roles, useUserAclHandler, getRole } from '@/store/role/helpers';
import styles from './styles.less';

const CONTEXT_URL = { memberByEvent: 'member/by-event', memberByCollaborator: 'member/by-collaborator' };

export const SetRoleButton = ({ selectedRoleId, onRoleChange, authUser, user, organization }) => {
  const t = useFormatMessage();
  const { pathname } = useLocation();

  const isAdminContext = pathname.indexOf('admin') > -1 && authUser.isAdmin;
  const isAdminExponentsContext = isAdminContext && pathname.indexOf('exponents') > -1;
  const isSelectorDisabled = (pathname.includes(CONTEXT_URL.memberByEvent) || isAdminExponentsContext) && user._id;
  const userAclHandler = useUserAclHandler(user, isAdminContext ? organization : authUser._currentOrganization);
  const { role: authUserRole } = useUserAclHandler(authUser);
  const role = getRole(selectedRoleId) || userAclHandler.role;

  const clientRoles = [roles.admin, roles.supervisor, roles.member]
  .map((role) => getRole(role))
  .filter((role) => authUserRole.priority >= role.priority || isAdminContext);

  return (
    <Popover asChild>
      <Tooltip>
        <PopoverTrigger>
          <TooltipTrigger>
            <Button style={{ width: '170px'}} disabled={isSelectorDisabled} color="neutral" variant="tonal" imageComponentRight={<IconChevronDown size={16}/>}>
              {getLocaleName(role)}
            </Button>
          </TooltipTrigger>
        </PopoverTrigger>

        {isSelectorDisabled &&
          <TooltipContent zIndex={1000}>
            <Body1 className="w-[270px]">
              {t({ id: 'preparation.team.recruiter.add.select.role.disabled.tooltip' })}
            </Body1>
          </TooltipContent>}
      </Tooltip>

      {!isSelectorDisabled &&
        <PopoverContent zIndex={1000} className={styles.popoverContent}>
          <ul>
            {
              clientRoles.map((clientRole) => (
                <React.Fragment key={clientRole._id}>
                  <RoleList
                    role={clientRole}
                    roleSelected={role}
                    selected={clientRole._id === role._id}
                    onRoleClick={onRoleChange}
                  />
                </React.Fragment>
              ))
            }
          </ul>
        </PopoverContent>}
    </Popover>
  )
}

const RoleList = ({ role, roleSelected, onRoleClick }) => {
  const t = useFormatMessage();
  const selected = roleSelected.key === role.key;

  return (
    <li key={role._id} className={selected ? 'bg-primary-100 ' : ''} tabIndex={0} role="button" onClick={() => onRoleClick(role._id || roleSelected._id)}>
      <div className="flex items-center">
        <div>
          <div>
            <Body1 className="text-neutral-500" fontWeight={600}>{getLocaleName(role)}</Body1>
          </div>
          <Caption1 className="text-neutral-400">
            {t({ id: `role.client.${role.key}.description` })}
          </Caption1>
        </div>
        <div className="mx-16 text-primary-500">
          <IconCheck className={selected ? '' : 'opacity-0'}  size={24} />
        </div>
      </div>
    </li>
  );
}

RoleList.propTypes = {
  role: object,
  roleSelected: object,
  onRoleClick: func,
}

SetRoleButton.propTypes = {
  selectedRoleId: string,
  onRoleChange: func,
  authUser: object,
  user: object,
  organization: object,
}